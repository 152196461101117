
const maxByteSize = 1024

export function bytesToSize(size: number, length = 2) {
  const value = Math.floor(Math.log(size) / Math.log(maxByteSize))
  return 0 === size
    ? '0 Bytes'
    : `${Number.parseFloat((size / Math.pow(maxByteSize, value)).toFixed(Math.max(0, length)))} 
    ${['KB', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][value]}`
}
