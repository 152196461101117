import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import { Button, Stack, Typography, useTheme } from '@mui/material'
import Confetti from 'react-confetti'
import { useNavigate } from 'react-router'

import type Project from 'src/models/Project'

type Props = {
  close: () => void
  project?: Project
}

const CompleteUpdate = (props: Props) => {
  const themeColors = useTheme()
  const history = useNavigate()
  const height = window.innerHeight
  const width = window.innerWidth

  const finish = () => {
    history('/projects/projects')
    props.close()
  }
  return (
    <>
      <Confetti
        height={height}
        numberOfPieces={280}
        recycle={false}
        width={width}
      />
      <Stack
        alignItems='center'
        direction='column'
        height={1}
        justifyContent='center'
        minHeight={300}
        spacing={4}
        width='100%'
      >
        <CheckCircleOutlineRoundedIcon color='success' sx={{ fontSize: '144px' }} />
        <Typography color={themeColors.palette.text.secondary} variant='subtitle2'>
          {`You have successfully updated the metadata for "${props.project?.title}"`}
        </Typography>
        <Button
          onClick={() => finish()}
        >
          Go back to projects
        </Button>
      </Stack>
    </>
  )
}

export default CompleteUpdate
