import SearchIcon from '@mui/icons-material/SearchRounded'
import type { TextFieldProps } from '@mui/material'
import { InputAdornment, TextField } from '@mui/material'

const SearchField = (props: TextFieldProps) =>
  <TextField
    InputProps={{
      startAdornment: <InputAdornment position='start'>
        <SearchIcon sx={{ color: 'action.active' }} />
      </InputAdornment>
      ,
    }}
    placeholder='Search'
    size='medium'
    sx={{
      marginTop: 0,
      '& .MuiFilledInput-root': {
        backgroundColor: 'surface.input',
      },
    }}
    {...props}
  />

export default SearchField
