/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable complexity */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable max-lines */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable react-hooks/exhaustive-deps */
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CloseIcon from '@mui/icons-material/Close'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import { LoadingButton } from '@mui/lab'
import { Autocomplete, Avatar, Badge, Box, Button, Checkbox, Dialog, Divider, FormControlLabel, IconButton, InputAdornment, ListItemText, Paper, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { NumericFormat } from 'react-number-format'
import { v4 } from 'uuid'

import { addTransactionProject } from 'src/api/projects'
import { addTransactionRecording } from 'src/api/recordings'
import TransactionCard from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/AccountingTab/TransactionCard'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { Organisation } from 'src/models/Organisation'
import type Project from 'src/models/Project'
import type Recording from 'src/models/Recording'
import { Recoupment } from 'src/models/Recording'

type Props = {
  close: () => void
  open: boolean
  project: Project
  orgs: Organisation[]
  recordings: Recording[]
  setProject: React.Dispatch<React.SetStateAction<Project | undefined>>
  setRecordings: React.Dispatch<React.SetStateAction<Recording[]>>
}

const transactionTypeOptions = [
  'Income',
  'Expense',
  'Payout',
  'Advance',
]

const transactionDescription = [
  'Income transactions represent when a recording or album receives earnings that are accounted to rightsholders.',
  `Expense transcactions represent when the product owner has spent money on an asset which needs to be
  recouped equally amongst rightsholders.`,
  `Payout transactions represent when the product owner pays out royalties to a rightsholder. Payouts are meant to be
  used to bring positive balances down to zero.`,
  `Advance transactions represent when the product owner pays out a royalty advance to a rightsholder. Advances are 
  meant to bring a rightsholder's balance below zero.`,
]

const transactionExamples = [
  'Some expample income transactions are streaming royalties, CD sales, master sync licensing fees.',
  'Some example expense transactions are artwork costs, mastering costs, marketing costs.',
  `If a rightsholder has a positive balance, a payout for the balance's amount should be paid 
  to the rightsholder. Distribution Cloud will pay the rightsholder's share of royaltyies to the product owner until
  their balance is of zero.`,
  `If a product owner pays a royalty advance to a collaborator, Distribution Cloud will pay the rightsholder's share of
  royaltyies to the product owner until their balance is of zero.`,
]

const AddProjectRecoupModal = (props: Props) => {
  const { currentOrganisation } = useAuth()
  const themeColors = useTheme()
  const fullScreen = useMediaQuery(themeColors.breakpoints.down('md'))

  const [amount, setAmount] = useState<number>()
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [orgInputValue, setOrgInputValue] = useState('')
  const [typeKey, setTypeKey] = useState(v4())
  const [transactionType, setType] = useState('Income')
  const [loading, setLoading] = useState(false)

  const [organisation, setOrganisation] = useState<Organisation>()
  const [recording, setRecording] = useState<Recording>()

  const [checkProject, setCheckProject] = useState(true)

  const indexSelected = transactionTypeOptions.indexOf(transactionType)

  const uniqueIds: string[] = []
  const allMasterRightsHoldersUnique = recording
    ? [...new Set(recording.masterRightsHolders
      .flatMap(({ account }) => account))].filter(element => {
      const isDuplicated = uniqueIds.includes(element.id)
      if (!isDuplicated) {
        uniqueIds.push(element.id)
        return true
      }
      return false
    })
    : [...new Set(props.recordings
      .flatMap(({ masterRightsHolders }) => masterRightsHolders)
      .flatMap(({ account }) => account))].filter(element => {
      const isDuplicated = uniqueIds.includes(element.id)
      if (!isDuplicated) {
        uniqueIds.push(element.id)
        return true
      }
      return false
    })

  useEffect(() => {
    setRecording(undefined)
  }, [checkProject])

  useEffect(() => {
    setOrganisation(undefined)
    setOrgInputValue('')
    setTypeKey(v4())
  }, [transactionType, recording])

  const handleSubmit = async () => {
    setLoading(true)
    const transaction = new Recoupment({
      title,
      description,
      amount,
      transactionType,
      organisationAdvanceId: organisation?.id ? organisation.id : '',
    })
    await (recording
      ? addTransactionRecording(recording.id, transaction)
        .then(saved => {
          props.setRecordings(oldStatus => oldStatus.map(item => item.id === saved.id
            ? saved
            : item))
          setLoading(false)
          props.close()
        })
      : addTransactionProject(props.project.id, transaction)
        .then(saved => {
          setLoading(false)
          props.setProject(saved)
          props.close()
        }))
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='lg'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Stack
        direction={{
          xl: 'row',
          lg: 'row',
          md: 'row',
          sm: 'column',
          xs: 'column',
        }}
        height={1}
        width={1}
      >
        <Stack paddingX={3} paddingY={3} width={1}>
          <Stack
            alignItems='center'
            direction='row'
            justifyContent='space-between'
            paddingBottom={2}
          >
            <Typography variant='h3'>
              Add a Transaction
            </Typography>
            <IconButton onClick={() => props.close()}>
              <CloseIcon color='disabled' />
            </IconButton>
          </Stack>
          <Stack marginTop={2} spacing={4}>
            <TextField
              fullWidth
              label='Title'
              onChange={event_ => setTitle(event_.target.value)}
              placeholder='Give your transaction a title'
              value={title}
            />

            <NumericFormat
              InputProps={{
                startAdornment:
  <InputAdornment position='start'>
    <AttachMoneyRoundedIcon color='disabled' fontSize='small' />
  </InputAdornment>,
              }}
              customInput={TextField}
              decimalScale={2}
              decimalSeparator='.'
              displayType='input'
              label='Amount'
              onValueChange={values => {
                setAmount(values.floatValue)
              }}
              thousandSeparator=','
              thousandsGroupStyle='thousand'
              value={amount}
            />

            <TextField
              fullWidth
              label='Description'
              minRows={4}
              multiline
              onChange={event_ => setDescription(event_.target.value)}
              placeholder='Explain what the transaction is in detail as it is shared with shareholders'
              value={description}
            />

            <Autocomplete
              autoHighlight
              autoSelect
              disabled={transactionType !== 'Advance' && transactionType !== 'Payout'}
              fullWidth
              getOptionLabel={option => option.name}
              inputValue={orgInputValue}
              key={typeKey}
              onChange={(_, newValue) => setOrganisation(newValue ?? undefined)}
              onInputChange={(event, value) => setOrgInputValue(value)}
              options={recording
                ? recording.masterRightsHolders.map(item => item.account)
                  .filter(holder => holder.id !== currentOrganisation?.id)
                : props.orgs.filter(holder => holder.id !== currentOrganisation?.id)}
              renderInput={params =>
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: <InputAdornment position='start'>
                      <Avatar
                        src={organisation?.logoUrl}
                        sx={{
                          height: 28,
                          width: 28,
                        }}
                      />
                    </InputAdornment>,
                  }}
                  label='Payee'
                  placeholder='Select a payee'
                  sx={{
                    marginTop: 0,
                  }}
                />}
              renderOption={(renderProps, option) =>
                <Box component='li' {...renderProps} >
                  <Stack alignItems='center' direction='row' spacing={1}>
                    <Avatar
                      src={option.logoUrl}
                    />
                    <ListItemText
                      primary={option.name}
                      secondary={option.organisationEmail}
                    />
                  </Stack>
                </Box>}
              sx={{ marginTop: 0, height: 42 }}
              value={organisation}
            />

            <Autocomplete
              autoHighlight
              disabled={checkProject}
              fullWidth
              getOptionLabel={option => option.title}
              onChange={(_, newValue) => setRecording(newValue ?? undefined)}
              options={props.recordings}
              renderInput={params =>
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: <InputAdornment position='start'>
                      <Avatar
                        src={recording?.artworkPreviewUrl}
                        sx={{
                          borderRadius: '8px!important',
                          height: '28px!important',
                          width: '28px!important',
                          fontSize: '24px',
                        }}
                        variant='rounded'
                      >
                        <MusicNoteRoundedIcon />
                      </Avatar>
                    </InputAdornment>,
                  }}
                  disabled={checkProject}
                  label='Recording'
                  placeholder='Select a recording'
                  sx={{
                    marginTop: 0,
                  }}
                />}
              renderOption={(renderProps, option) =>
                <Box component='li' {...renderProps} >
                  <Stack alignItems='center' direction='row' spacing={1}>
                    <Avatar
                      src={option.artworkPreviewUrl}
                      sx={{
                        borderRadius: '8px!important',
                        height: '28px!important',
                        width: '28px!important',
                        fontSize: '24px',
                      }}
                      variant='rounded'
                    >
                      <MusicNoteRoundedIcon />
                    </Avatar>
                    <ListItemText
                      primary={option.title}
                      secondary={option.primaryArtists.map(item => item.name).join(', ')}
                    />
                  </Stack>
                </Box>}
              sx={{ marginTop: 0, height: 42 }}
              value={recording}
            />

            <FormControlLabel
              control={<Checkbox
                checked={checkProject}
                onChange={event => setCheckProject(event.target.checked)}
              />}
              label='Apply to Project'
              sx={{
                marginTop: '8px!important',
              }}
            />

            <Stack direction='row' marginTop='12px!important' spacing={1}>
              {transactionTypeOptions.map(type =>
                <TransactionCard
                  key={type}
                  selected={transactionType}
                  setTransactionType={setType}
                  transactionType={type}
                />)}
            </Stack>

          </Stack>
          <Stack marginTop={2} spacing={2} width={1}>
            <Stack direction='row' justifyContent='flex-end' marginTop={4} spacing={1} width={1}>
              <Button onClick={() => props.close()} variant='text'>
                Cancel
              </Button>
              <LoadingButton
                disabled={title.length === 0 ||
                description.length === 0 ||
                amount === 0 ||
                !amount ||
                transactionType.length === 0 ||
                ((transactionType === 'Advance' || transactionType === 'Payout') && organisation === undefined)}
                loading={loading}
                onClick={handleSubmit}
                variant='contained'
              >
                Add transaction
              </LoadingButton>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          height='auto'
          justifyContent='center'
          padding={2}
          spacing={1}
          sx={{
            background: theme => theme.palette.background.input,
          }}
          width={1}
        >
          <Paper
            elevation={0}
            sx={{
              borderRadius: 8,
              width: 100,
              height: 100,
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              color: theme => `${theme.palette.primary.contrastText}!important`,
              border: '1px solid',
              borderColor: transactionType === 'Income'
                ? theme => theme.palette.success.main
                : transactionType === 'Expense'
                  ? theme => theme.palette.error.main
                  : transactionType === 'Payout'
                    ? theme => theme.palette.secondary.main
                    : theme => theme.palette.primary.main,
              background: transactionType === 'Income'
                ? theme => theme.palette.success.main
                : transactionType === 'Expense'
                  ? theme => theme.palette.error.main
                  : transactionType === 'Payout'
                    ? theme => theme.palette.secondary.main
                    : theme => theme.palette.primary.main,
            }}
          >
            <AttachMoneyRoundedIcon
              sx={{
                fontSize: 64,
                color: theme => theme.palette.primary.contrastText,
              }}
            />
          </Paper>
          <Typography variant='h2'>
            {transactionType}
          </Typography>
          <Typography
            variant='body1'
          >
            {transactionDescription[indexSelected]}
          </Typography>
          <Typography
            color={themeColors.palette.text.label}
            variant='body2'
          >
            {transactionExamples[indexSelected]}
          </Typography>
          <Divider
            sx={{
              marginTop: '16px!important',
              marginBottom: '16px!important',
            }}
          />
          {transactionType === 'Income' || transactionType === 'Expense'
            ? <Stack spacing={2} width={1}>
              <Typography variant='subtitle1'>
                Affects
              </Typography>
              {allMasterRightsHoldersUnique.filter(holder => holder.id !== currentOrganisation?.id).map(item =>
                <Stack alignItems='center' direction='row' key={item.id} spacing={2} width={1}>
                  <Badge
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    badgeContent={<CheckCircleRoundedIcon
                      color='secondary'
                      fontSize='small'
                      sx={{
                        backgroundColor: 'white',
                        borderRadius: '50%',
                      }}
                    />}
                    overlap='circular'
                    sx={{
                      '.MuiBadge-badge': {
                        display: item.verified ? undefined : 'none',
                      },
                    }}
                  >
                    <Avatar
                      src={item.logoUrl}
                    />
                  </Badge>
                  <Stack direction='column'>
                    <Typography variant='body1'>
                      {item.name}
                    </Typography>
                    <Typography color={themeColors.palette.text.label} variant='body2'>
                      {item.organisationEmail}
                    </Typography>
                  </Stack>
                </Stack>)}
            </Stack>
            : <Stack spacing={2} width={1}>
              <Typography variant='subtitle1'>
                Affects
              </Typography>
              {organisation &&
              <Stack alignItems='center' direction='row' key={organisation.id} spacing={2} width={1}>
                <Badge
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  badgeContent={<CheckCircleRoundedIcon
                    color='secondary'
                    fontSize='small'
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '50%',
                    }}
                  />}
                  overlap='circular'
                  sx={{
                    '.MuiBadge-badge': {
                      display: organisation.verified ? undefined : 'none',
                    },
                  }}
                >
                  <Avatar
                    src={organisation.logoUrl}
                  />
                </Badge>
                <Stack direction='column'>
                  <Typography variant='body1'>
                    {organisation.name}
                  </Typography>
                  <Typography color={themeColors.palette.text.label} variant='body2'>
                    {organisation.organisationEmail}
                  </Typography>
                </Stack>
              </Stack>}
            </Stack>}
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default AddProjectRecoupModal
