/* eslint-disable unicorn/prevent-abbreviations */
/* eslint-disable max-len */
import type { SvgIconProps } from '@mui/material'
import { SvgIcon } from '@mui/material'

const GoogleDocsLogo = (props: SvgIconProps) =>
  <SvgIcon {...props} viewBox='0 0 47 65' >
    <>
      <defs>
        <path d='M29.375,0 L4.40625,0 C1.9828125,0 0,1.99431818 0,4.43181818 L0,60.5681818 C0,63.0056818 1.9828125,65 4.40625,65 L42.59375,65 C45.0171875,65 47,63.0056818 47,60.5681818 L47,17.7272727 L29.375,0 Z' id='path-1' />
        <path d='M29.375,0 L4.40625,0 C1.9828125,0 0,1.99431818 0,4.43181818 L0,60.5681818 C0,63.0056818 1.9828125,65 4.40625,65 L42.59375,65 C45.0171875,65 47,63.0056818 47,60.5681818 L47,17.7272727 L29.375,0 Z' id='path-3' />
        <linearGradient id='linearGradient-5' x1='50.0053945%' x2='50.0053945%' y1='8.58610612%' y2='100.013939%'>
          <stop offset='0%' stopColor='#1A237E' stopOpacity='0.2' />
          <stop offset='100%' stopColor='#1A237E' stopOpacity='0.02' />
        </linearGradient>
        <path d='M29.375,0 L4.40625,0 C1.9828125,0 0,1.99431818 0,4.43181818 L0,60.5681818 C0,63.0056818 1.9828125,65 4.40625,65 L42.59375,65 C45.0171875,65 47,63.0056818 47,60.5681818 L47,17.7272727 L29.375,0 Z' id='path-6' />
        <path d='M29.375,0 L4.40625,0 C1.9828125,0 0,1.99431818 0,4.43181818 L0,60.5681818 C0,63.0056818 1.9828125,65 4.40625,65 L42.59375,65 C45.0171875,65 47,63.0056818 47,60.5681818 L47,17.7272727 L29.375,0 Z' id='path-8' />
        <path d='M29.375,0 L4.40625,0 C1.9828125,0 0,1.99431818 0,4.43181818 L0,60.5681818 C0,63.0056818 1.9828125,65 4.40625,65 L42.59375,65 C45.0171875,65 47,63.0056818 47,60.5681818 L47,17.7272727 L29.375,0 Z' id='path-10' />
        <path d='M29.375,0 L4.40625,0 C1.9828125,0 0,1.99431818 0,4.43181818 L0,60.5681818 C0,63.0056818 1.9828125,65 4.40625,65 L42.59375,65 C45.0171875,65 47,63.0056818 47,60.5681818 L47,17.7272727 L29.375,0 Z' id='path-12' />
        <path d='M29.375,0 L4.40625,0 C1.9828125,0 0,1.99431818 0,4.43181818 L0,60.5681818 C0,63.0056818 1.9828125,65 4.40625,65 L42.59375,65 C45.0171875,65 47,63.0056818 47,60.5681818 L47,17.7272727 L29.375,0 Z' id='path-14' />
        <radialGradient cx='3.16804688%' cy='2.71744318%' fx='3.16804688%' fy='2.71744318%' gradientTransform='translate(0.031680,0.027174),scale(1.000000,0.723077),translate(-0.031680,-0.027174)' id='radialGradient-16' r='161.248516%'>
          <stop offset='0%' stopColor='#FFFFFF' stopOpacity='0.1' />
          <stop offset='100%' stopColor='#FFFFFF' stopOpacity='0' />
        </radialGradient>
      </defs>
      <g fill='none' fillRule='evenodd' id='Page-1' stroke='none' strokeWidth='1'>
        <g id='Consumer-Apps-Docs-Large-VD-R8' transform='translate(-451.000000, -463.000000)'>
          <g id='Hero' transform='translate(0.000000, 63.000000)'>
            <g id='Personal' transform='translate(277.000000, 309.000000)'>
              <g id='Docs-icon' transform='translate(174.000000, 91.000000)'>
                <g id='Group'>
                  <g id='Clipped'>
                    <mask fill='white' id='mask-2'>
                      <use xlinkHref='#path-1' />
                    </mask>
                    <g id='SVGID_1_' />
                    <path d='M29.375,0 L4.40625,0 C1.9828125,0 0,1.99431818 0,4.43181818 L0,60.5681818 C0,63.0056818 1.9828125,65 4.40625,65 L42.59375,65 C45.0171875,65 47,63.0056818 47,60.5681818 L47,17.7272727 L36.71875,10.3409091 L29.375,0 Z' fill='#4285F4' fillRule='nonzero' id='Path' mask='url(#mask-2)' />
                  </g>
                  <g id='Clipped'>
                    <mask fill='white' id='mask-4'>
                      <use xlinkHref='#path-3' />
                    </mask>
                    <g id='SVGID_1_' />
                    <polygon fill='url(#linearGradient-5)' fillRule='nonzero' id='Path' mask='url(#mask-4)' points='30.6638281 16.4309659 47 32.8582386 47 17.7272727' />
                  </g>
                  <g id='Clipped'>
                    <mask fill='white' id='mask-7'>
                      <use xlinkHref='#path-6' />
                    </mask>
                    <g id='SVGID_1_' />
                    <path d='M11.75,47.2727273 L35.25,47.2727273 L35.25,44.3181818 L11.75,44.3181818 L11.75,47.2727273 Z M11.75,53.1818182 L29.375,53.1818182 L29.375,50.2272727 L11.75,50.2272727 L11.75,53.1818182 Z M11.75,32.5 L11.75,35.4545455 L35.25,35.4545455 L35.25,32.5 L11.75,32.5 Z M11.75,41.3636364 L35.25,41.3636364 L35.25,38.4090909 L11.75,38.4090909 L11.75,41.3636364 Z' fill='#F1F1F1' fillRule='nonzero' id='Shape' mask='url(#mask-7)' />
                  </g>
                  <g id='Clipped'>
                    <mask fill='white' id='mask-9'>
                      <use xlinkHref='#path-8' />
                    </mask>
                    <g id='SVGID_1_' />
                    <g id='Group' mask='url(#mask-9)'>
                      <g transform='translate(26.437500, -2.954545)'>
                        <path d='M2.9375,2.95454545 L2.9375,16.25 C2.9375,18.6985795 4.90929688,20.6818182 7.34375,20.6818182 L20.5625,20.6818182 L2.9375,2.95454545 Z' fill='#A1C2FA' fillRule='nonzero' id='Path' />
                      </g>
                    </g>
                  </g>
                  <g id='Clipped'>
                    <mask fill='white' id='mask-11'>
                      <use xlinkHref='#path-10' />
                    </mask>
                    <g id='SVGID_1_' />
                    <path d='M4.40625,0 C1.9828125,0 0,1.99431818 0,4.43181818 L0,4.80113636 C0,2.36363636 1.9828125,0.369318182 4.40625,0.369318182 L29.375,0.369318182 L29.375,0 L4.40625,0 Z' fill='#FFFFFF' fillOpacity='0.2' fillRule='nonzero' id='Path' mask='url(#mask-11)' />
                  </g>
                  <g id='Clipped'>
                    <mask fill='white' id='mask-13'>
                      <use xlinkHref='#path-12' />
                    </mask>
                    <g id='SVGID_1_' />
                    <path d='M42.59375,64.6306818 L4.40625,64.6306818 C1.9828125,64.6306818 0,62.6363636 0,60.1988636 L0,60.5681818 C0,63.0056818 1.9828125,65 4.40625,65 L42.59375,65 C45.0171875,65 47,63.0056818 47,60.5681818 L47,60.1988636 C47,62.6363636 45.0171875,64.6306818 42.59375,64.6306818 Z' fill='#1A237E' fillOpacity='0.2' fillRule='nonzero' id='Path' mask='url(#mask-13)' />
                  </g>
                  <g id='Clipped'>
                    <mask fill='white' id='mask-15'>
                      <use xlinkHref='#path-14' />
                    </mask>
                    <g id='SVGID_1_' />
                    <path d='M33.78125,17.7272727 C31.3467969,17.7272727 29.375,15.7440341 29.375,13.2954545 L29.375,13.6647727 C29.375,16.1133523 31.3467969,18.0965909 33.78125,18.0965909 L47,18.0965909 L47,17.7272727 L33.78125,17.7272727 Z' fill='#1A237E' fillOpacity='0.1' fillRule='nonzero' id='Path' mask='url(#mask-15)' />
                  </g>
                </g>
                <path d='M29.375,0 L4.40625,0 C1.9828125,0 0,1.99431818 0,4.43181818 L0,60.5681818 C0,63.0056818 1.9828125,65 4.40625,65 L42.59375,65 C45.0171875,65 47,63.0056818 47,60.5681818 L47,17.7272727 L29.375,0 Z' fill='url(#radialGradient-16)' fillRule='nonzero' id='Path' />
              </g>
            </g>
          </g>
        </g>
      </g>
    </>
  </SvgIcon>

export default GoogleDocsLogo
