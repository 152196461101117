/* eslint-disable complexity */
/* eslint-disable max-lines */
import AddIcon from '@mui/icons-material/AddRounded'
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded'
import ChatIcon from '@mui/icons-material/ChatRounded'
import LibraryMusicRoundedIcon from '@mui/icons-material/LibraryMusicRounded'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import NotificationsIcon from '@mui/icons-material/NotificationsRounded'
import PhonelinkRoundedIcon from '@mui/icons-material/PhonelinkRounded'
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded'
import { AppBar, Avatar, Fab, ListItemIcon, Menu, MenuItem, Stack, Toolbar, Typography } from '@mui/material'
import { useState } from 'react'

import HeaderSearchField from 'src/components/form-elements/HeaderSearchField'
import { IconNavLinkTop } from 'src/components/pages/Menu/MenuNavLink'
import ReleeseLogo from 'src/components/ReleeseLogo'

type Props = {
  leftNavigationWidth: string
}

const FakeHeader = (props: Props) => {
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const openMenu = Boolean(anchorElement)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorElement(null)
  }

  return <AppBar
    elevation={4}
    position='sticky'
    sx={{
      background: 'transparent',
      height: 56,
      display: {
        xl: 'flex',
        lg: 'flex',
        md: 'flex',
        sm: 'none',
        xs: 'none',
      },
    }}
  >
    <Toolbar
      disableGutters
      sx={{
        minHeight: '56px!important',
      }}
    >
      <Stack alignItems='center' direction='row' justifyContent='space-between' width='100%'>

        <Stack alignItems='center' direction='row' spacing={1}>
          <ReleeseLogo width={props.leftNavigationWidth} />
          <Stack alignItems='center' direction='row' spacing={2}>
            <HeaderSearchField />
            <Fab id='create' onClick={handleClick} size='small' sx={{ boxShadow: 0 }}>
              <AddIcon />
            </Fab>
          </Stack>
        </Stack>
        <Menu
          anchorEl={anchorElement}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          onClick={handleClose}
          onClose={handleClose}
          open={openMenu}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
          <MenuItem >
            <ListItemIcon>
              <LibraryMusicRoundedIcon fontSize='medium' />
            </ListItemIcon>
            Project
          </MenuItem>
          <MenuItem >
            <ListItemIcon>
              <TaskAltRoundedIcon fontSize='medium' />
            </ListItemIcon>
            Task
          </MenuItem>
          <MenuItem >
            <ListItemIcon>
              <AssignmentRoundedIcon fontSize='medium' />
            </ListItemIcon>
            Taskboard
          </MenuItem>
          <MenuItem >
            <ListItemIcon>
              <PhonelinkRoundedIcon fontSize='medium' />
            </ListItemIcon>
            Landing Page
          </MenuItem>
        </Menu>

        <Stack alignItems='center' direction='row' height={1} paddingX={2} spacing={1} zIndex='1201'>
          <IconNavLinkTop
            id='profile'
            isActive={false}
            sx={{
              maxWidth: 300,
            }}
            to='/test'
          >
            <Avatar
              sx={{ height: 32, width: 32 }}
            />
            <Typography
              noWrap
              sx={{
                textTransform: 'none',
                marginRight: 1,
                marginLeft: 1,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                width: 1,
              }}
              variant='button'
            >
              My New Team
            </Typography>
          </IconNavLinkTop>
          <IconNavLinkTop
            isActive={false}
            size='small'
            tooltip='Menu'
          >
            <MenuRoundedIcon />
          </IconNavLinkTop>
          <IconNavLinkTop
            isActive={false}
            size='small'
            tooltip='Messages'
          >
            <ChatIcon />
          </IconNavLinkTop>
          <IconNavLinkTop
            isActive={false}
            size='small'
            tooltip='Notifications'
          >

            <NotificationsIcon />
          </IconNavLinkTop>
        </Stack>
      </Stack>
    </Toolbar>
  </AppBar>
}
export default FakeHeader
