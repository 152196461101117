/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Container, Paper, Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'

import { getProjectPublic } from 'src/api/projects'
import Loading from 'src/components/Loading'
import AudioPlayerItem from 'src/components/pages/Menu/AudioPlayerItem'
import PageNotFound from 'src/components/pages/PageNotFound'
import ShareProjectDetails from 'src/components/pages/Share/ShareComponents/ProjectDetails'
import ShareProjectRecordings from 'src/components/pages/Share/ShareComponents/ShareProjectRecordings'
import ReleeseLogo from 'src/components/ReleeseLogo'
import type Project from 'src/models/Project'

const ShareProject = () => {
  const { id } = useParams<{ id: string }>()
  const [project, setProject] = useState<Project | undefined>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    if (id) {
      setLoading(true)
      getProjectPublic(id)
        .then(data => {
          setProject(data)
          setError(false)
        })
        .catch(() => setError(true))
        .finally(() => setLoading(false))
    }
  }, [id])

  return !error
    ? loading
      ? <Loading />
      : <>
        <Container
          sx={{
            display: 'flex',
            alignItems: 'center',
            minHeight: 1,
            width: '100%!important',
            padding: {
              xl: 4,
              lg: 4,
              md: 4,
              sm: 2,
              xs: 2,
            },
            height: '100%',
            overflowY: 'auto',
            margin: 0,
            flexDirection: 'column',
            maxWidth: '100%!important',
            backgroundColor: theme => theme.palette.background.input,
          }}
        >
          <Paper sx={{ width: 1, maxWidth: 'lg' }}>
            <Stack width={1}>
              <ShareProjectDetails project={project} />
              {project &&
              <ShareProjectRecordings project={project} />}
            </Stack>
          </Paper>
          <Stack paddingY={4}>
            <ReleeseLogo full />
          </Stack>
        </Container>
        <AudioPlayerItem />
      </>
    : <Stack
      alignItems='flex-start'
      direction='row'
      height={1}
      width={1}
    >
      <PageNotFound />
    </Stack>
}

export default ShareProject
