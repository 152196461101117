export const proList = [
  'ASCAP',
  'BMI',
  'SESAC',
  'SOCAN',
  'GEMA',
  'ONDA',
  'SACEM',
  'ECCO',
  'SADAIC',
  'ARMAUTHOR',
  'BUMA',
  'PRSfM',
  'APRA',
  'AKM',
  'SPA',
  'COSCAP',
  'TONO',
  'NCIP',
  'SABAM',
  'BSCAP',
  'BUBEDRA',
  'SOBODAYCOM',
  'AMUS',
  'SAMRO',
  'UBC',
  'ABRAMUS',
  'AMAR',
  'ASSIM',
  'SADEMBRA',
  'SBACEM',
  'SICAM',
  'SOCINPRO',
  'MUSICAUTOR',
  'BBDA',
  'SOCINADA',
  'BUCADA',
  'SCD',
  'MCSC',
  'SAYCO',
  'BCDA',
  'SONECA',
  'ACAM',
  'HDS',
  'ACDAM',
  'OSA',
  'KODA',
  'SAYCE',
  'SACERAU',
  'SACIM',
  'EAU',
  'TEOSTO',
  'SPACEM',
  'GCA',
  'GHAMRO',
  'AUTODIA',
  'AEI-GUATEMALA',
  'BGDA',
  'AACIMH',
  'CASH',
  'ARTISJUS',
  'STEF',
  'IPRS',
  'WAMI',
  'IMRO',
  'ACUM',
  'SIAE',
  'BURIDA',
  'JACAP',
  'JASRAC',
  'KazAK',
  'MCSK',
  'KOMCA',
  'AKKA/LAA',
  'SUISA',
  'LATGA',
  'MACA',
  'ZAMP',
  'OMDA',
  'COSOMA',
  'MACP',
  'BUMDA',
  'MASA',
  'SACM',
  'ANCO',
  'MOSCAP',
  'PAMCG',
  'BMDA',
  'NASCAM',
  'MRCSN',
  'BNDA',
  'COSON',
  'COMP',
  'SPAC',
  'APA',
  'APDAYC',
  'FILSCAP',
  'ZAIKS',
  'UCMR-ADA',
  'RAO',
  'BSDA',
  'SOKOJ',
  'COMPASS',
  'SOZA',
  'SAZAS',
  'SGAE',
  'UNISON',
  'STIM',
  'MUST',
  'MCT',
  'BUTODRA',
  'COTT',
  'MESAM',
  'UACRR',
  'AGADU',
  'SACVEN',
  'VCPMC',
  'ZAMCOPS',
  'ZIMURA',
] as const
