import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, IconButton, Stack, Typography } from '@mui/material'
import { useState } from 'react'

import { deleteProject } from 'src/api/projects'
import DeleteItemComponent from 'src/components/DeleteItemComponent'
import type Project from 'src/models/Project'

type Props = {
  readonly close: () => void
  readonly open: boolean
  readonly project: Project
  readonly updateProjects: () => Promise<void>
}

const DeleteProjectModal = (props: Props) => {
  const [loading, setLoading] = useState(false)
  const deleteProjectAction = async ()  => {
    setLoading(true)
    await deleteProject(props.project)
      .then(props.updateProjects)
      .then(props.close)
      .finally(() => setLoading(false))
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      onClose={props.close}
      open={props.open}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        overflow: 'scroll',
      }}
    >
      <Stack paddingX={3} paddingY={3} spacing={4} width={430}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          width='100%'
        >
          <Typography variant='h3'>
            Delete Release
          </Typography>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>
        <DeleteItemComponent
          primary='Are you sure?'
          secondary='This action cannot be undone'
        />
        <Stack direction='row' justifyContent='flex-end' spacing={1} width='100%'>
          <Button onClick={() => props.close()} variant='text'>
            Cancel
          </Button>
          <LoadingButton
            color='error'
            loading={loading}
            onClick={deleteProjectAction}
            variant='contained'
          >
            Delete Release
          </LoadingButton>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default DeleteProjectModal
