/* eslint-disable no-restricted-imports */
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import type { SelectChangeEvent } from '@mui/material'
import { Avatar, Badge, Box, FilledInput, ListItem, ListItemAvatar, ListItemText, MenuItem, Select, Stack } from '@mui/material'
import { useEffect, useState } from 'react'

import { getUsersOrganisations } from 'src/api/organisation'
import Loading from 'src/components/Loading'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { Organisation } from 'src/models/Organisation'

const OrganizationSelect = () => {
  const { switchCurrentOrganisation, currentOrganisation } = useAuth()

  const [organisations, setOrganisations] = useState<Organisation[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    getUsersOrganisations().then(data => setOrganisations(data))
      .finally(() => setLoading(false))
  }, [])

  const handleChange = async (event: SelectChangeEvent) => {
    setLoading(true)
    await switchCurrentOrganisation(organisations
      .find(organisation => organisation.id === event.target.value)?.id ?? '')
      .finally(() => setLoading(false))
  }

  return (
    <Select
      fullWidth
      id='demo-multiple-checkbox'
      input={<FilledInput sx={{ height: 64 }} />}
      onChange={handleChange}
      renderValue={
        selected => loading ? <Loading />  : <Box sx={{ display: 'flex', flexWrap: 'wrap', height: 64, width: 1 }}>
          <Stack alignItems='center' direction='row' height={1} key={selected} spacing={1} width={1}>
            <ListItem>
              <ListItemAvatar>
                <Badge
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  badgeContent={<CheckCircleRoundedIcon
                    color='secondary'
                    fontSize='small'
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: '50%',
                    }}
                  />}
                  overlap='circular'
                  sx={{
                    '.MuiBadge-badge': {
                      display: currentOrganisation?.verified ? undefined : 'none',
                    },
                  }}
                >
                  <Avatar
                    src={currentOrganisation?.logoUrl}
                  />
                </Badge>
              </ListItemAvatar>
              <ListItemText
                primary={currentOrganisation?.name}
                secondary='Current team'
                sx={{ wordBreak: 'break-word' }}
              />
            </ListItem>
          </Stack>
        </Box>
      }
      sx={{ height: 64 }}
      value={currentOrganisation?.id ?? ''}
      variant='outlined'
    >
      {organisations.map(organisation => <MenuItem key={organisation.id} value={organisation.id}>
        <ListItem sx={{ maxWidth: 300 }}>
          <ListItemAvatar>
            <Badge
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              badgeContent={<CheckCircleRoundedIcon
                color='secondary'
                fontSize='small'
                sx={{
                  backgroundColor: 'white',
                  borderRadius: '50%',
                }}
              />}
              overlap='circular'
              sx={{
                '.MuiBadge-badge': {
                  display: organisation.verified ? undefined : 'none',
                },
              }}
            >
              <Avatar
                src={organisation.logoUrl}
              />
            </Badge>
          </ListItemAvatar>
          <ListItemText
            primary={organisation.name}
            secondary={`${organisation.seats.length} member${organisation.seats.length > 1 ? 's' : ''}`}
            sx={{ wordBreak: 'break-all' }}
          />
        </ListItem>
      </MenuItem>)}
    </Select>
  )
}

export default OrganizationSelect
