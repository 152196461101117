import PermIdentityRoundedIcon from '@mui/icons-material/PermIdentityRounded'
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded'
import { TabContext, TabList } from '@mui/lab'
import { Divider, Stack, Tab } from '@mui/material'
import { useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'

import PersonalInformation from 'src/components/pages/Settings/Account/AccountTabs/PersonalInformation'
import Security from 'src/components/pages/Settings/Account/AccountTabs/Security'
import { NavLink } from 'src/components/SafeRouterLink'

const Account = () => {
  const location = useLocation().pathname
  const [currentTab, setCurrentTab] = useState(location)
  return (
    <TabContext value={currentTab}>
      <Stack
        direction='column'
        padding={0}
        width={1}
      >
        <Stack
          direction='row'
          width={1}
        >
          <TabList
            aria-label='Current account tab'
            onChange={(event, value: string) => setCurrentTab(value)}
          >
            <Tab
              component={NavLink}
              icon={<PermIdentityRoundedIcon />}
              label='Personal Information'
              to='/settings/account/personalinformation'
              value='/settings/account/personalinformation'
            />
            <Tab
              component={NavLink}
              icon={<SecurityRoundedIcon />}
              label='Security'
              to='/settings/account/security'
              value='/settings/account/security'
            />
          </TabList>
        </Stack>
        <Divider />
      </Stack>
      <Stack height={1} overflow='auto' width={1}>
        <Routes>
          <Route element={<PersonalInformation />} path='/personalinformation' />
          <Route element={<Security />} path='/security' />
        </Routes>
      </Stack>
    </TabContext>
  )
}
export default Account
