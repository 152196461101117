import DoDisturbAltRoundedIcon from '@mui/icons-material/DoDisturbAltRounded'
import { Card, CardActionArea, Stack, Typography } from '@mui/material'

type Props = {
  readonly activeSelection: number
  readonly handleSelection: (value: number) => () => void
}

const NotUsing = (props: Props) => {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (props.activeSelection) {
    case 4:
      return <Card
        elevation={0}
        sx={{ borderColor: theme => `${theme.palette.error.main}!important`, height: 1 }}
        variant='outlined'

      >
        <CardActionArea onClick={props.handleSelection(4)} sx={{ height: 1 }} >
          <Stack alignItems='center' height={1} padding={3} spacing={1}>
            <DoDisturbAltRoundedIcon
              sx={{
                fontSize: 64,
                color: theme => theme.palette.error.main,
              }}
            />
            <Typography textAlign='center' variant='h6'>
              Project is over
            </Typography>
          </Stack>
        </CardActionArea>
      </Card>
    default:
      return <Card elevation={0} sx={{ height: 1 }} variant='outlined'>
        <CardActionArea onClick={props.handleSelection(4)} sx={{ height: 1 }} >
          <Stack alignItems='center' height={1} padding={3} spacing={1}>
            <DoDisturbAltRoundedIcon
              sx={{
                fontSize: 64,
                color: theme => theme.palette.error.main,
              }}
            />
            <Typography textAlign='center' variant='h6'>
              Project is over
            </Typography>
          </Stack>
        </CardActionArea>
      </Card>
  }
}

export default NotUsing
