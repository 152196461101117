/* eslint-disable sonarjs/no-duplicate-string */
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import AlternateEmailRoundedIcon from '@mui/icons-material/AlternateEmailRounded'
import VerticalAlignBottomRoundedIcon from '@mui/icons-material/VerticalAlignBottomRounded'
import VerticalAlignTopRoundedIcon from '@mui/icons-material/VerticalAlignTopRounded'
import { Button, Card, CardActionArea, Checkbox, FormControlLabel, IconButton, Menu, MenuItem, Radio, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import type { DropResult } from 'react-beautiful-dnd'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import EditLinkSocialItem from 'src/components/pages/Links/EditLink/EditLinkComponents/EditLinkSocialItem'
import EditSocialOrgModal from 'src/components/pages/Links/EditLink/EditLinkModals/EditOrgSocialModal'
import GetIcon from 'src/components/pages/SmartLink/Content/GetIcon'
import { useAuth } from 'src/components/providers/AuthProvider'
import { SmartLinkSocial } from 'src/data/smartLinkData'
import type { MarketingLink, SocialList } from 'src/models/Marketing'

const reorder = (list: SocialList[], startIndex: number, endIndex: number) => {
  const result = [...list]
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

type Props = {
  smartLinkData: MarketingLink
  setLinkData: React.Dispatch<React.SetStateAction<MarketingLink>>
}

const EditLinkSocial = (props: Props) => {
  const { currentOrganisation } = useAuth()
  const inputList = props.smartLinkData.useOrgDefaultSocialIcons
    ? currentOrganisation?.defaultSocialList ?? []
    : props.smartLinkData.socialList

  const [openEditModal, setOpenEditModal] = useState(false)
  const filteredInputs = SmartLinkSocial.filter(item => !inputList.some(selected => selected.label === item.label))

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const openMenu = Boolean(anchorElement)

  const handleClose = () => {
    setAnchorElement(null)
  }

  const findInputIndex = (labelFound: string) => inputList.findIndex(element => element.label === labelFound)

  const onDeleteByIndex = (event: React.MouseEvent<HTMLElement>) => {
    const index = findInputIndex(event.currentTarget.id)
    props.setLinkData(old =>
      ({ ...old, socialList: [...old.socialList.filter((item, indexItem) => indexItem !== index)] }))
  }

  const handleValueChange = (value: SocialList, index: number) => {
    props.smartLinkData.socialList[index] = value
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    } else if (result.destination.droppableId) {
      props.setLinkData(old =>
        ({
          ...old,
          socialList: reorder(props.smartLinkData.socialList, result.source.index, result.destination?.index ?? 0) }
        ))
    }
  }

  const handleDefaultIcons = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setLinkData(old => ({ ...old, useOrgDefaultSocialIcons: event.target.checked }))
  }

  return (
    <Stack direction='column' maxWidth={896} spacing={2} width={1}>
      {openEditModal &&
        <EditSocialOrgModal
          close={() => setOpenEditModal(false)}
          open={openEditModal}
        />}
      <Stack direction='column' width={1}>
        <Stack
          paddingTop={2}
          width={1}
        >
          <Typography variant='h3'>
            Social icons
          </Typography>
        </Stack>
        <Card
          variant='outlined'
        >
          <Stack direction='column' padding={2} spacing={1} width={1}>
            <Stack>
              <Typography lineHeight={1.4} variant='h4'>
                Put yourself out there
              </Typography>
              <Typography color='text.secondary' variant='body1'>
                Make yourself easy to find by adding your social links to your smart link
              </Typography>
            </Stack>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.smartLinkData.useOrgDefaultSocialIcons}
                  onChange={event => handleDefaultIcons(event)}
                />
              }
              label='Use my default icons'
            />

            {props.smartLinkData.type === 'Biolink' &&
            <>
              <Typography variant='h4'>
                Icon position
              </Typography>
              <Stack direction='row' paddingBottom={1} spacing={2}>
                <Card
                  sx={{
                    width: 'fit-content',
                    background: 'transparent',
                    borderColor: theme => !props.smartLinkData.socialPositionBottom
                      ? theme.palette.primary.main
                      : undefined,
                  }}
                  variant='outlined'
                >
                  <CardActionArea
                    onClick={() => props.setLinkData(old => ({ ...old, socialPositionBottom: false }))}
                  >
                    <Stack
                      alignItems='center'
                      justifyContent='center'
                      minWidth={104}
                      paddingBottom={1}
                      paddingTop={2}
                      paddingX={2}
                    >
                      <Stack
                        sx={{
                          height: 48,
                          width: 48,
                          justifyContent: 'center',
                          alignItems: 'center',
                          background: theme => theme.palette.action.active,
                          borderRadius: 1,
                        }}
                      >
                        <VerticalAlignTopRoundedIcon
                          sx={{
                            fontSize: '2rem',
                            color: theme => theme.palette.background.default,
                            background: theme => theme.palette.action.active,
                          }}
                        />
                      </Stack>
                      <Typography
                        variant='h6'
                      >
                        Top
                      </Typography>
                      <Radio
                        checked={!props.smartLinkData.socialPositionBottom}
                      />
                    </Stack>
                  </CardActionArea>
                </Card>
                <Card
                  sx={{
                    width: 'fit-content',
                    background: 'transparent',
                    borderColor: theme => props.smartLinkData.socialPositionBottom
                      ? theme.palette.primary.main
                      : undefined,
                  }}
                  variant='outlined'
                >
                  <CardActionArea
                    onClick={() => props.setLinkData(old => ({ ...old, socialPositionBottom: true }))}
                  >
                    <Stack
                      alignItems='center'
                      justifyContent='center'
                      minWidth={104}
                      paddingBottom={1}
                      paddingTop={2}
                      paddingX={2}
                    >
                      <Stack
                        sx={{
                          height: 48,
                          width: 48,
                          justifyContent: 'center',
                          alignItems: 'center',
                          background: theme => theme.palette.action.active,
                          borderRadius: 1,
                        }}
                      >
                        <VerticalAlignBottomRoundedIcon
                          sx={{
                            fontSize: '2rem',
                            color: theme => theme.palette.background.default,
                            background: theme => theme.palette.action.active,
                          }}
                        />
                      </Stack>
                      <Typography
                        variant='h6'
                      >
                        Bottom
                      </Typography>
                      <Radio
                        checked={props.smartLinkData.socialPositionBottom}
                      />
                    </Stack>
                  </CardActionArea>
                </Card>
              </Stack>
            </>}

            <Button
              color='secondary'
              onClick={() => setOpenEditModal(true)}
              startIcon={<AlternateEmailRoundedIcon />}
              sx={{
                width: 'fit-content',
              }}
            >
              Manage my default icons
            </Button>
          </Stack>
        </Card>
      </Stack>

      {!props.smartLinkData.useOrgDefaultSocialIcons &&
        <Stack alignItems='center' paddingTop={2}>
          <Button
            disabled={props.smartLinkData.useOrgDefaultSocialIcons}
            fullWidth
            onClick={event => setAnchorElement(event.currentTarget)}
            rounded
            size='large'
            startIcon={<AddRoundedIcon />}
            sx={{
              height: 48,
              fontSize: '1rem',
            }}
          >
            Add Social Icon
          </Button>
        </Stack>}

      <Stack direction='column' maxWidth={896} spacing={2}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='presaves' key='presaves'>
            {provided =>
              <Stack {...provided.droppableProps} direction='column' ref={provided.innerRef} spacing={1}>
                {inputList.map((element, index) =>
                  <EditLinkSocialItem
                    element={element}
                    handleValueChange={handleValueChange}
                    index={index}
                    key={element.label}
                    onDeleteByIndex={onDeleteByIndex}
                    smartLinkData={props.smartLinkData}
                  />)}
                {provided.placeholder}
              </Stack>}
          </Droppable>
        </DragDropContext>

        <Menu
          anchorEl={anchorElement}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          onClick={handleClose}
          onClose={handleClose}
          open={openMenu}
          sx={{
            '& .MuiPaper-root': {
              maxHeight: '240px',
              minWidth: '400px',
            },
          }}
          transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        >
          {filteredInputs.map(option =>
            <MenuItem
              key={option.label}
              onClick={() => {
                props.setLinkData(old => ({ ...old, socialList: [...old.socialList, option] }))
              }}
            >
              <Stack alignItems='center' direction='row' spacing={1}>
                <IconButton
                  color='default'
                  disableRipple
                  disabled={props.smartLinkData.useOrgDefaultSocialIcons}
                >
                  {GetIcon(option.label)}
                </IconButton>
                <Typography
                  sx={{ color: theme => theme.palette.text.secondary }}
                  variant='body2'
                >
                  {option.label}
                </Typography>
              </Stack>
            </MenuItem>)}
        </Menu>

      </Stack>
    </Stack>

  )
}

export default EditLinkSocial
