import AlbumRoundedIcon from '@mui/icons-material/AlbumRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import MicExternalOnRoundedIcon from '@mui/icons-material/MicExternalOnRounded'
import { LoadingButton } from '@mui/lab'
import { Avatar, Chip, Dialog, IconButton, Paper, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'

import type { SongStatsLabels } from 'src/models/Analytics'
import type { ArtistObject } from 'src/models/Distribution'

type Props = {
  close: () => void
  open: boolean
  selectProfile: () => Promise<void>
  artistView: boolean
  selectedLabel: SongStatsLabels | null | undefined
  selectedArtist: ArtistObject | null | undefined
  loading: boolean
}

const AnalyticsOnboardModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='sm'
      onClose={props.close}
      open={props.open}
      sx={{
        '& .MuiPaper-root': {
          overflow: 'hidden',
        },
      }}
    >
      <Stack overflow='auto' width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          paddingLeft={2}
          paddingRight={2}
          paddingTop={2}
          width={1}
        >
          <Stack
            alignItems='center'
            direction='row'
            spacing={2}
            width={1}
          >
            <Typography variant='h3'>
              Analytics onboarding
            </Typography>
          </Stack>
          <IconButton onClick={props.close}>
            <CloseRoundedIcon />
          </IconButton>
        </Stack>
        <Stack alignItems='center' padding={2} spacing={2} width={1}>
          {props.artistView
            ? <Paper
              elevation={0}
              sx={{
                backgroundColor: theme => theme.palette.primary.main,
                borderRadius: 16,
              }}
            >
              <Stack
                alignItems='center'
                justifyContent='center'
                padding={4}
              >
                <MicExternalOnRoundedIcon
                  sx={{
                    color: theme => theme.palette.primary.contrastText,
                    fontSize: '64px',
                  }}
                />
              </Stack>
            </Paper>
            : <Paper
              elevation={0}
              sx={{
                backgroundColor: theme => theme.palette.info.main,
                borderRadius: 16,
              }}
            >
              <Stack
                alignItems='center'
                justifyContent='center'
                padding={4}
              >
                <AlbumRoundedIcon
                  sx={{
                    color: theme => theme.palette.primary.contrastText,
                    fontSize: '64px',
                  }}
                />
              </Stack>
            </Paper>}

          <Typography textAlign='center' variant='body2'>
            Once you have claimed an artist or label you cannot claim another one, please make sure you are
            selecting the right profile.
          </Typography>

          {props.selectedArtist && props.artistView &&
          <Chip
            avatar={<Avatar
              src={props.selectedArtist.images?.[0]?.url ? props.selectedArtist.images[0].url : ''}
            />}
            label={props.selectedArtist.name}
            size='medium'
            sx={{
              width: 'fit-content',
            }}
          />}

          {props.selectedLabel && !props.artistView &&
            <Chip
              avatar={<Avatar src={props.selectedLabel.avatar} />}
              label={props.selectedLabel.name}
              size='medium'
              sx={{
                width: 'fit-content',
              }}
            />}

          <LoadingButton
            color='primary'
            fullWidth
            loading={props.loading}
            onClick={() => void props.selectProfile()}
            variant='contained'
          >
            {props.artistView
              ? 'Confirm and claim this artist'
              : 'Confirm and claim this label'}
          </LoadingButton>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default AnalyticsOnboardModal
