import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded'
import BorderAllRoundedIcon from '@mui/icons-material/BorderAllRounded'
import LinkRoundedIcon from '@mui/icons-material/LinkRounded'
import YouTubeIcon from '@mui/icons-material/YouTube'

import AirtableLogo from 'src/components/Icons/AirtableLogo'
import DiscoLogo from 'src/components/Icons/DiscoLogo'
import GoogleCalendarLogo from 'src/components/Icons/GoogleCalendarLogo'
import GoogleDocsLogo from 'src/components/Icons/GoogleDocsLogo'
import GoogleFormsLogo from 'src/components/Icons/GoogleFormsLogo'
import GoogleSheetLogo from 'src/components/Icons/GoogleSheetLogo'
import SoundCloudIcon from 'src/components/Icons/SoundCloudIcon'
import SpotifyIcon from 'src/components/Icons/SpotifyIcon'

const GetIconTaskSmall = (LinkName: string) => {
  switch (LinkName.toLowerCase()) {
    case 'releese doc':
      return <ArticleRoundedIcon
        color='info'
      />
    case 'releese sheet':
      return <BorderAllRoundedIcon
        color='success'
      />
    case 'spotify':
      return <SpotifyIcon
        sx={{
          color: theme => theme.palette.spotify,
        }}
      />
    case 'soundcloud':
      return <SoundCloudIcon
        sx={{
          color: theme => theme.palette.soundcloud,
        }}
      />
    case 'youtube':
      return <YouTubeIcon
        sx={{
          color: theme => theme.palette.youtube,
        }}
      />
    case 'disco':
      return <DiscoLogo />
    case 'google form':
      return <GoogleFormsLogo />
    case 'google doc':
      return <GoogleDocsLogo />
    case 'google calendar':
      return <GoogleCalendarLogo />
    case 'airtable base':
      return <AirtableLogo />
    case 'google sheet':
      return <GoogleSheetLogo />
    default:
      return <LinkRoundedIcon
        sx={{
          color: theme => theme.palette.action.disabled,
        }}
      />
  }
}

export default GetIconTaskSmall
