/* eslint-disable complexity */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines */
import { TabContext, TabPanel } from '@mui/lab'
import { Stack, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'

import UpdateRecordingContentTab from 'src/components/pages/Projects/UpdateModal/DistributeModalComponents/RecordingsUpdateTabs/ContentTab'
import DistributionRecordingDetailsTab from 'src/components/pages/Projects/UpdateModal/DistributeModalComponents/RecordingsUpdateTabs/DetailsTab'
import UpdateRecordingPublishingTab from 'src/components/pages/Projects/UpdateModal/DistributeModalComponents/RecordingsUpdateTabs/PubTab'
import type Project from 'src/models/Project'
import type Recording from 'src/models/Recording'

export type EditRecordingFormProps = {
  recording: Recording
  onSave: (recording: Recording) => void
  saveProgress: (recording: Recording) => void
  saving: boolean
  project?: Project
}

type Props = {
  setRecording: React.Dispatch<React.SetStateAction<Recording>>
  recording?: Recording
  project?: Project
  recordingFile?: File
  done?: boolean
  setNewRecordings?: React.Dispatch<React.SetStateAction<File[]>>
  setRecordings?: React.Dispatch<React.SetStateAction<Recording[]>>
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  setAudioPeaks: React.Dispatch<React.SetStateAction<number[] | undefined>>
  refresh?: React.Dispatch<React.SetStateAction<boolean>>
  currentTab: string
  setLoadingUpload: React.Dispatch<React.SetStateAction<boolean>>
  distributing?: boolean
}

export const generateTitleRecording = (recordingFile?: File, recording?: Recording) => {
  if (recordingFile) {
    return recordingFile.name
  } else if (recording) {
    const subtitle = recording.subTitle && `(${recording.subTitle})`
    const features = recording.featuredArtists.length > 0
      ? `(Feat. ${recording.featuredArtists.map(item => item.name).join(', ')})`
      : ''
    const remixers = recording.remixArtists.length > 0
      ? `(${recording.remixArtists.map(item => item.name).join(', ')} Remix)`
      : ''
    return `${recording.title} ${subtitle} ${features} ${remixers}`
  } else {
    return ''
  }
}

const EditRecordingDistribution = (props: Props) => {
  const themeColors = useTheme()
  const matches = useMediaQuery(themeColors.breakpoints.down('md'))

  const [done, setDone] = useState(props.done ?? false)
  const [saveLoading, setSaveLoading] = useState(false)

  const [currentRecording, setCurrentRecording] = useState<Recording | undefined>(props.recording)

  const handleSave = (recording: Recording) => {
    props.setRecording(recording)
    props.setAudioPeaks(recording.audioWaveform?.data)
    setCurrentRecording(recording)
    props.setLoadingUpload(false)
    props.setLoading(true)
    setDone(true)
    setSaveLoading(false)
    if (props.refresh) {
      props.refresh(old => !old)
    }
  }

  useEffect(() => {
    if (done && props.recordingFile && props.setNewRecordings && props.setRecordings) {
      props.setNewRecordings(oldRecordings => oldRecordings.filter(item => item.name !== props.recordingFile?.name))
      if (currentRecording) {
        props.setRecordings(records => [...records, currentRecording])
      }
    }
  }, [done])

  useEffect(() => {
    setCurrentRecording(props.recording)
  }, [props.recording])

  return (
    <Stack>
      <TabContext value={props.currentTab}>
        <Stack sx={{ margin: 0, padding: 0 }}>
          <TabPanel sx={{ minHeight: 500, height: matches ? 1 : 500, overflow: 'auto' }} value='1'>
            {currentRecording &&
              <DistributionRecordingDetailsTab
                distributing={props.distributing}
                onSave={handleSave}
                recording={currentRecording}
                saveProgress={(recording: Recording) => setCurrentRecording(recording)}
              />}
          </TabPanel >
          <TabPanel sx={{ minHeight: 500, height: matches ? 1 : 500, overflow: 'auto' }} value='2'>
            {currentRecording &&
              <UpdateRecordingPublishingTab
                onSave={handleSave}
                project={props.project}
                recording={currentRecording}
                saveProgress={(recording: Recording) => setCurrentRecording(recording)}
                saving={saveLoading}

              />}
          </TabPanel>
          <TabPanel sx={{ minHeight: 500, height: matches ? 1 : 500, overflow: 'auto' }} value='3'>
            {currentRecording &&
              <UpdateRecordingContentTab
                onSave={handleSave}
                project={props.project}
                recording={currentRecording}
                saveProgress={(recording: Recording) => setCurrentRecording(recording)}
                saving={saveLoading}
              />}
          </TabPanel>
        </Stack>
      </TabContext>
    </Stack>
  )
}

export default EditRecordingDistribution
