/* eslint-disable max-len */
import type { Components } from '@mui/material'

const MuiCardActionArea: Components['MuiCardActionArea'] = {
  styleOverrides: {
    root: {
      fontFamily: 'Matter,sans-serif,Moderna,Helvetica Neue,Droid Sans,Fira Sans,Cantarell,Ubuntu,Inter,Oxygen,Roboto,Segoe UI,BlinkMacSystemFont,-apple-system!important',
      fontSize: 'inherit',
      fontWeight: 'inherit',
    },
  },
}

export default MuiCardActionArea
