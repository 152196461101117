/* eslint-disable unicorn/filename-case */
import type { TComboboxItem } from '@udecode/plate'

export const BLOCK_TYPES: TComboboxItem[] = [
  { key: 'p', text: 'Paragraph' },
  { key: 'h1', text: 'Large title' },
  { key: 'h2', text: 'Medium title' },
  { key: 'h3', text: 'Small title' },
  { key: 'blockquote', text: 'Quote' },
  { key: 'checklist', text: 'Todo list' },
  { key: 'numbered-list', text: 'Numbered list' },
  { key: 'bulleted-list', text: 'Bulleted list' },
  { key: 'alert', text: 'Red banner' },
  { key: 'warning', text: 'Yellow banner' },
  { key: 'info', text: 'Blue banner' },
  { key: 'success', text: 'Green banner' },
  { key: 'table2x2', text: '2x2 Table' },
  { key: 'table3x3', text: '3x3 Table' },
  { key: 'table4x4', text: '4x4 Table' },
  { key: 'image', text: 'Image' },
  { key: 'video', text: 'Video' },
  { key: 'youtube', text: 'YouTube' },
  { key: 'divider', text: 'Divider' },
  { key: 'taskboard', text: 'Taskboard' },
]
