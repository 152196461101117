import { Card, CardActionArea, Paper, Stack, Typography } from '@mui/material'

import { GetIconTask } from 'src/components/pages/Task/TaskViews/TaskViewComponents/GetIconTask'

type Props = {
  embedOption: string
  setEmbedType: React.Dispatch<React.SetStateAction<string>>
  selected: string
}

const EmbedCard = (props: Props) =>
  <Card
    elevation={0}
    sx={{
      background: 'transparent',
      width: 160,
      marginX: 1,
      borderColor: props.selected === props.embedOption ? null : 'transparent',
    }}
    variant='outlined'
  >
    <CardActionArea
      onClick={() => props.setEmbedType(props.embedOption)}
      sx={{
        padding: 2,
        height: 1,
      }}
    >
      <Paper
        elevation={0}
        sx={{
          borderRadius: 1,
          width: 126,
          height: 126,
        }}
      >
        {GetIconTask(props.embedOption)}
      </Paper>
      <Stack alignItems='center' paddingTop={2}>
        <Typography sx={{ wordBreak: 'break-word' }} textAlign='center' variant='subtitle2'>
          {props.embedOption}
        </Typography>
      </Stack>
    </CardActionArea>
  </Card>

export default EmbedCard
