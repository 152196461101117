/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable max-lines */
/* eslint-disable id-length */
/* eslint-disable sonarjs/no-duplicate-string */
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import SendRoundedIcon from '@mui/icons-material/SendRounded'
import { Card, Divider, IconButton, Popper, Stack, TextField, Tooltip } from '@mui/material'
import {
  getMarks,
  getSelectionBoundingClientRect,
  isCollapsed,
  useEditorState,
} from '@udecode/plate'
import {
  getCommentKeyId,
  isCommentKey,
  MARK_COMMENT,
  removeCommentMark,
  unsetCommentNodesById,
  useAddComment,
  useCommentsActions,
  useCommentsSelectors,
  useRemoveComment,
  useUpdateComment,
} from '@udecode/plate-comments'
import { useEffect, useRef, useState } from 'react'
import { v4 } from 'uuid'

import CommentItemNotes from 'src/components/pages/Notes/Modern/comments/CommentItem'
import { useAuth } from 'src/components/providers/AuthProvider'
import { useNotesComments } from 'src/components/providers/NotesCommentsProvider'

type VirtualElement = {
  getBoundingClientRect: () => DOMRect
  clientWidth: number
  clientHeight: number
}

export const FloatingComments = () => {
  const ref = useRef<HTMLDivElement | null>(null)
  const editor = useEditorState()
  const { currentAccount } = useAuth()
  const { notesComments } = useNotesComments()
  const updateComment = useUpdateComment()
  const deleteComment = useRemoveComment()
  const addComment = useAddComment()
  const onCommentAdd = useCommentsSelectors().onCommentAdd()
  const onCommentUpdate = useCommentsSelectors().onCommentUpdate()
  const onCommentDelete = useCommentsSelectors().onCommentDelete()
  const unSelect = useCommentsActions().activeCommentId()

  const commentItem = useCommentsSelectors().activeCommentId()

  const [anchorElement, setAnchorElement] = useState<VirtualElement | null>(null)

  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')

  const domSelection = window.getSelection()

  const selection = editor.selection

  const activeCommentItem = getMarks(editor)?.[MARK_COMMENT] === true
  const mappedComments = notesComments
    .filter(item => item.id === commentItem || item.parentId === commentItem)

  const isResolved = mappedComments.some(item => !item.parentId && item.isResolved)

  useEffect(() => {
    const returnItem = getSelectionBoundingClientRect()
    setMessage('')

    if (!isCollapsed(selection) ||
      !editor.selection ||
      !activeCommentItem ||
      isResolved) {
      setOpen(false)
      unSelect(null)
      return
    }

    const marks = getMarks(editor)

    if (marks) {
      for (const key of Object.keys(marks)) {
        if (!isCommentKey(key)) continue
        const id = getCommentKeyId(key)
        unSelect(id)
      }
    }

    setOpen(true)
    const getRect = () => ({
      width: returnItem.width,
      height: returnItem.height,
      x: returnItem.x,
      y: returnItem.y,
      bottom: returnItem.bottom,
      left: returnItem.left,
      top: returnItem.top,
      right: returnItem.right,
    }) as DOMRect

    if (returnItem.x !== 0 && returnItem.y !== 0) {
      setAnchorElement({
        clientWidth: returnItem.width,
        clientHeight: returnItem.height,
        getBoundingClientRect: getRect,
      })
    }
  }, [editor, selection, domSelection, activeCommentItem, commentItem, isResolved])

  return (
    <Popper
      anchorEl={anchorElement}
      id='hover-toolbar-comments'
      open={open}
      placement='top-start'
      ref={ref}
      sx={{
        zIndex: 1101,
      }}
    >
      <Card
        elevation={4}
      >
        <Stack
          alignItems='center'
          direction='column'
          height={1}
          width={400}
        >
          {mappedComments.length > 0 &&
          <>
            <Stack direction='row' justifyContent='flex-end' padding={2} spacing={1} width={1}>
              <Tooltip sx={{ zIndex: 9999 }} title='Complete'>
                <div>
                  <IconButton
                    color='success'
                    onClick={event => {
                      event.preventDefault()
                      if (commentItem) {
                        const updatedItem = {
                          id: commentItem,
                          isResolved: true,
                        }
                        updateComment(updatedItem)
                        if (onCommentUpdate) {
                          onCommentUpdate(updatedItem)
                        }
                        deleteComment(commentItem)
                        if (onCommentDelete) {
                          onCommentDelete(commentItem)
                        }
                        unsetCommentNodesById(editor, { id: commentItem })
                        removeCommentMark(editor)
                        setOpen(false)
                      }
                    }}
                  >
                    <CheckRoundedIcon />
                  </IconButton>
                </div>
              </Tooltip>
            </Stack>

            <Divider sx={{ width: 1 }} />

            <Stack direction='column-reverse' height={360} overflow='auto' padding={2} spacing={1} width={1}>
              {mappedComments.reverse()
                .map(item =>
                  <Stack direction='column' key={item.id} width={1}>
                    <CommentItemNotes
                      account={item.userId}
                      message={item.value?.[0]?.children?.[0]?.text}
                      time={item.createdAt}
                    />
                  </Stack>)}
            </Stack>

            <Divider sx={{ width: 1 }} />
          </>}
          <form
            onSubmit={() => {
              if (message.length > 0) {
                const newCommentItem = {
                  id: v4(),
                  value: [
                    {
                      type: 'p',
                      children: [{
                        text: message,
                      }],
                    },
                  ],
                  createdAt: Date.now(),
                  parentId: commentItem ?? undefined,
                  isResolved: false,
                  userId: currentAccount.id,
                }
                addComment(newCommentItem)
                if (onCommentAdd) {
                  onCommentAdd(newCommentItem)
                }
                setMessage('')
              }
            }}
            style={{
              width: '100%',
            }}
          >
            <Stack alignItems='center' direction='row' padding={2} spacing={1} width={1}>
              <TextField
                fullWidth
                onChange={event => {
                  setMessage(event.target.value)
                }}
                placeholder='Aa'
                sx={{
                  marginTop: 0,
                }}
                value={message}
              />
              <IconButton
                color='primary'
                disabled={message.length === 0}
                onClick={() => {
                  const newCommentItem = {
                    id: v4(),
                    value: [
                      {
                        type: 'p',
                        children: [{
                          text: message,
                        }],
                      },
                    ],
                    createdAt: Date.now(),
                    parentId: commentItem ?? undefined,
                    isResolved: false,
                    userId: currentAccount.id,
                  }
                  addComment(newCommentItem)
                  if (onCommentAdd) {
                    onCommentAdd(newCommentItem)
                  }
                  setMessage('')
                }}
                type='submit'
              >
                <SendRoundedIcon />
              </IconButton>
            </Stack>
          </form>
        </Stack>
      </Card>
    </Popper>
  )
}
