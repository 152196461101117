/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import 'src/styles/Custom/cookielaw.css'

import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import { Card, CardActionArea, CardMedia, Stack, Typography } from '@mui/material'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'

import type { MarketingLink } from 'src/models/Marketing'
import theme, * as darkTheme from 'src/styles/dark.theme'
import * as lightTheme from 'src/styles/light.theme'

type Props = {
  link: MarketingLink
  theme: string
}

const AllReleasesItem = (props: Props) => {
  const activeTheme = props.theme === 'Light'
    ? lightTheme
    : darkTheme

  dayjs.extend(advancedFormat)

  return (
    <Card
      sx={{
        background: activeTheme.default.palette.background.default,
        borderColor: activeTheme.default.palette.border,
        width: 200,
        height: 'fit-content',
      }}
      variant='outlined'
    >
      <CardActionArea
        sx={{
          height: 1,
        }}
      >
        {props.link.artworkUrl
          ? <CardMedia
            alt={`${props.link.artworkUrl ? 'Invalid' : 'Missing'} artwork`}
            component='img'
            height='auto'
            image={props.link.artworkUrl}
            sx={{
              aspectRatio: '1/1',
            }}
            width={1}
          />
          : <Card
            elevation={0}
            sx={{
              borderRadius: '8px!important',
              background: theme.palette.background.input,
              height: 'auto',
              width: 1,
              aspectRatio: '1/1',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <MusicNoteRoundedIcon
              sx={{
                fontSize: 250,
                color: theme.palette.action.disabled,
              }}
            />
          </Card>}
        <Stack alignItems='center' padding={2} width={1}>
          <Typography
            color={activeTheme.default.palette.text.primary}
            fontSize='1rem'
            lineHeight={1.3}
            textAlign='center'
            variant='h6'
          >
            {props.link.title}
          </Typography>
          {props.link.artists && props.link.artists.length > 0 &&
            <Typography
              color={activeTheme.default.palette.text.secondary}
              fontSize='1em'
              textAlign='center'
              variant='body2'
            >
              {props.link.artists.join(', ')}
            </Typography>}
          {props.link.type === 'Presave' &&
            <Typography
              color={activeTheme.default.palette.text.label}
              fontSize='0.85em'
              textAlign='center'
              variant='helperText'
            >
              {`Out ${dayjs(props.link.releaseDate).format('MMMM Do')}`}
            </Typography>}
        </Stack>
      </CardActionArea>
    </Card>
  )
}

export default AllReleasesItem
