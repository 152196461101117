import { Card, CardActionArea, Checkbox, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'

type Props = {
  template: string
  updateTemplate: (theme: string) => void
  colors: string[]
  artworkPreview: string | null | undefined
}

const EditLinkTemplate = (props: Props) => {
  const themeColors = useTheme()
  const matches = useMediaQuery(themeColors.breakpoints.down('md'))
  const bgColor = props.colors.map((color, index) =>
    // eslint-disable-next-line max-len
    `linear-gradient(90deg, ${color} ${(100 / props.colors.length * (index + 1)).toFixed(0)}%, rgba(0,0,0,0) ${(100 / props.colors.length * (index + 1)).toFixed(0)}%)`)
  return (
    <Stack direction={matches ? 'column' : 'row'} maxWidth={600} spacing={1} width={1}>
      <Card
        sx={{
          background: 'transparent',
          overflow: 'hidden!important',
        }}
        variant='outlined'
      >
        <CardActionArea
          onClick={() => props.updateTemplate('Subtle')}
          sx={{
            overflow: 'hidden!important',
          }}
        >
          <Stack
            alignItems='flex-start'
            justifyContent='flex-start'
            minWidth={280}
            width={1}
          >
            <Stack alignItems='center' direction='row' justifyContent='space-between' padding={2} width={1}>
              <Stack width={1}>
                <Typography
                  lineHeight={1.3}
                  variant='h4'
                >
                  Glass
                </Typography>
                <Typography color={themeColors.palette.text.secondary} variant='body2'>
                  Elegant, classic, and modern
                </Typography>
              </Stack>
              <Checkbox
                checked={props.template === 'Subtle'}
              />
            </Stack>
            <Stack
              sx={{
                height: 32,
                width: 1,
                background: props.artworkPreview && props.artworkPreview.length > 0
                  ? `url(${props.artworkPreview})`
                  : '#AAA9A9',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              <Stack
                sx={{
                  height: 32,
                  width: 1,
                  background: 'rgba(255, 255, 255, 0.1)',
                  backdropFilter: 'blur(16px)',
                  overflow: 'hidden',
                  borderRadius: '0px 0px 16px 16px',
                }}
              />
            </Stack>
          </Stack>
        </CardActionArea>
      </Card>
      <Card
        sx={{
          background: 'transparent',
          overflow: 'hidden',
        }}
        variant='outlined'
      >
        <CardActionArea
          onClick={() => props.updateTemplate('Vibrant')}
          sx={{
            overflow: 'hidden',
          }}
        >
          <Stack
            alignItems='flex-start'
            justifyContent='flex-start'
            minWidth={280}
            width={1}
          >
            <Stack alignItems='center' direction='row' justifyContent='space-between' padding={2} width={1}>
              <Stack width={1}>
                <Typography
                  lineHeight={1.3}
                  variant='h4'
                >
                  Minimal
                </Typography>
                <Typography color={themeColors.palette.text.secondary} variant='body2'>
                  Hip, colorful, and stylish
                </Typography>
              </Stack>
              <Checkbox
                checked={props.template === 'Vibrant'}
              />
            </Stack>
            <Stack
              sx={{
                height: 32,
                width: 1,
                background: bgColor.join(', '),
              }}
            />
          </Stack>
        </CardActionArea>
      </Card>
    </Stack>
  )
}

export default EditLinkTemplate
