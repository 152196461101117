import SimpleTour from 'src/components/pages/Tour/TourComponents/SimpleTour'

const ScheduleSyncTour = () =>
  <SimpleTour
    description='You can sync your auto-populated release schedule with Google, Apple, or Outlook calendar.'
    desktopDirection='left'
    mobileDirection='bottom'
    onboardingKey='scheduleSync'
    selector='sync-schedule'
    title='Keep things in sync'
  />

export default ScheduleSyncTour
