/* eslint-disable react/no-unused-prop-types */

import { Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'

import LinksItemLoading from 'src/components/pages/Links/LinksList/LinksListComponents/LinksItemLoading'

const LinksLoading = () =>
  <Stack height={1}>
    <TableContainer>
      <Table
        aria-label='projects table'
        size='small'
        sx={{
          tableLayout: 'fixed',
        }}
      >
        <TableHead sx={{
          '.MuiTableCell-root':
          {
            borderBottom: 2,
            borderBottomColor: 'divider',
            color: 'text.secondary',
          },
        }}
        >
          <TableRow>
            <TableCell align='left' sx={{ width: 96 }}>Artwork</TableCell>
            <TableCell align='left' >Title</TableCell>
            <TableCell align='left'>Type</TableCell>
            <TableCell align='left'>Created at</TableCell>
            <TableCell align='left' >Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <LinksItemLoading />
          <LinksItemLoading />
          <LinksItemLoading />
          <LinksItemLoading />
          <LinksItemLoading />
          <LinksItemLoading />
          <LinksItemLoading />
          <LinksItemLoading />
          <LinksItemLoading />
          <LinksItemLoading />
          <LinksItemLoading />
          <LinksItemLoading />
          <LinksItemLoading />
          <LinksItemLoading />
          <LinksItemLoading />
          <LinksItemLoading />
        </TableBody>
      </Table>
    </TableContainer>
  </Stack>

export default LinksLoading
