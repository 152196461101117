/* eslint-disable sonarjs/no-duplicated-branches */
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { Dialog, IconButton, Stack, Typography } from '@mui/material'
import { useState } from 'react'

import MfaAskCode from 'src/components/pages/Settings/Account/AccountTabs/AccountComponents/MfaAskCode'
import MfaAskPhone from 'src/components/pages/Settings/Account/AccountTabs/AccountComponents/MfaAskPhone'
import MfaDone from 'src/components/pages/Settings/Account/AccountTabs/AccountComponents/MfaDone'

type Props = {
  close: () => void
  open: boolean
}

const MfaModal = (props: Props) => {
  const [step, setStep] = useState('phone')
  const [phoneNumber, setPhoneNumber] = useState('')

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullWidth
      keepMounted={false}
      maxWidth='xs'
      onClose={props.close}
      open={props.open}
    >
      <Stack width={1}>
        <Stack direction='row' justifyContent='space-between' padding={2} width={1}>
          <Typography variant='h3'>
            Security
          </Typography>
          <IconButton
            aria-label='close'
            onClick={props.close}
          >
            <CloseRoundedIcon />
          </IconButton>
        </Stack>
        <Stack alignItems='center' width={1}>
          <Stack
            direction='column'
            height={1}
            paddingBottom={3}
            paddingX={3}
            spacing={1}
            width={1}
          >
            {(() => {
              switch (step) {
                case 'phone':
                  return <MfaAskPhone
                    setPhone={setPhoneNumber}
                    setStep={setStep}
                  />
                case 'code':
                  return <MfaAskCode
                    phoneNumber={phoneNumber}
                    setStep={setStep}
                  />
                case 'done':
                  return <MfaDone
                    close={props.close}
                  />
                default:
                  return <MfaAskPhone
                    setPhone={setPhoneNumber}
                    setStep={setStep}
                  />
              }
            })()}
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default MfaModal
