/* eslint-disable unicorn/filename-case */
import type { EElementOrText, PlateEditor, Value } from '@udecode/plate'
import { findNodePath, insertNode } from '@udecode/plate'
import { Transforms } from 'slate'
import { v4 } from 'uuid'

import { sendImage } from 'src/api/conversations'
import { KEY_CLOUD } from 'src/components/pages/Notes/Modern/image/createImagePlugin'

export const uploadFile = async <V extends Value = Value>(editor: PlateEditor<V>, file: File) => {
  const id = `#${v4()}`

  const nodeItem = {
    type: KEY_CLOUD,
    children: [
      {
        text: '',
      },
    ],
    loading: true,
    idItem: id,
  }

  insertNode(editor, nodeItem as EElementOrText<V>)

  await sendImage(file)
    .then(newUrl => {
      const path = findNodePath(editor, nodeItem)
      const newProperties = {
        url: newUrl,
        loading: false,
      }
      Transforms.setNodes(editor, newProperties, {
        at: path,
      })
    })
}

export const uploadFiles = <V extends Value = Value>(
  editor: PlateEditor<V>,
  files: Iterable<File>
) => {
  for (const file of files) {
    if ((/\.(jpg|png|jpeg|webp)$/i).test(file.name)) {
      void uploadFile(editor, file)
    }
  }
}
