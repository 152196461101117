import CircleIcon from '@mui/icons-material/Circle'
import ColorLensRoundedIcon from '@mui/icons-material/ColorLensRounded'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import { Avatar, Card, CardActionArea, IconButton, ListItemIcon, Menu, MenuItem, Stack, Typography, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import _ from 'lodash'
import { useState } from 'react'

import DeleteTaskTemplatedModal from 'src/components/pages/Task/TaskMenu/TaskMenuComponents/DeleteTaskTemplateModal'
import UpdateTaskTemplatedModal from 'src/components/pages/Task/TaskMenu/TaskMenuComponents/UpdateTemplateModal'
import type { TaskBoardTemplate } from 'src/models/Taskboard'

type Props = {
  index: number
  template: TaskBoardTemplate
  handleSwitchCustom: (index: number) => void
  customIndexTemplate: number
  taskboardTemplateRefresh: () => void
}

const CardTemplateItem = (props: Props) => {
  const themeItem = useTheme()
  const [deleteModal, setDeleteModal] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const openMenu = Boolean(anchorElement)

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget)
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
  }

  const handleClose = () => {
    setAnchorElement(null)
  }

  return (
    <>
      <Card
        key={`${props.template.title}${props.index.toString()}`}
        onClick={() => props.handleSwitchCustom(props.index)}
        sx={{
          borderColor: props.customIndexTemplate === props.index
            ? theme => `${theme.palette.primary.main}!important`
            : 'default',
          width: 1,
          flexShrink: 0,
        }}
        variant='outlined'
      >
        <CardActionArea>
          <Stack alignItems='center' direction='row' padding={2} spacing={2}>
            <Avatar
              sx={{
                width: 30,
                height: 30,
                bgcolor: props.template.color,
                borderRadius: 0.5,
              }}
            >
              <CircleIcon
                sx={{
                  color: props.template.color,
                }}
              />
            </Avatar>
            <Stack direction='column' width={1}>
              <Typography lineHeight={1} variant='h4'>
                {props.template.title}
              </Typography>
              <Typography variant='body2'>
                {props.template.description}
              </Typography>
              <Typography color={themeItem.palette.text.label} variant='body2'>
                {'Created '}
                {dayjs(new Date(props.template.createdAt)).format('LL')}
              </Typography>
            </Stack>
            <IconButton
              onClick={event => handleMenuClick(event)}
              size='small'
            >
              <MoreVertRoundedIcon />
            </IconButton>
          </Stack>
        </CardActionArea>
      </Card>
      <Menu
        anchorEl={anchorElement}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        onClick={handleClose}
        onClose={handleClose}
        open={openMenu}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        <MenuItem onClick={() => setEditModalOpen(true)}>
          <ListItemIcon>
            <ColorLensRoundedIcon fontSize='medium' />
          </ListItemIcon>
          Edit template
        </MenuItem>
        <MenuItem onClick={() => setDeleteModal(true)}>
          <ListItemIcon>
            <DeleteRoundedIcon color='error' fontSize='medium' />
          </ListItemIcon>
          Delete template
        </MenuItem>
      </Menu>
      <DeleteTaskTemplatedModal
        close={() => setDeleteModal(false)}
        open={deleteModal}
        refreshBoards={() => props.taskboardTemplateRefresh()}
        taskboardGroup={props.template}
      />
      <UpdateTaskTemplatedModal
        close={() => setEditModalOpen(false)}
        open={editModalOpen}
        refreshBoards={() => props.taskboardTemplateRefresh()}
        taskboardGroup={props.template}
      />
    </>
  )
}

export default CardTemplateItem
