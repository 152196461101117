/* eslint-disable max-len */
import type { SvgIconProps } from '@mui/material/SvgIcon'
import SvgIcon from '@mui/material/SvgIcon'

const ZingMp3Logo = (props: SvgIconProps) =>
  <SvgIcon
    {...props}
    sx={{
      '.st0': {
        fill: '#FFFFFF',
      },
      '.st1': {
        fill: '#FFFFFF',
      },
      '.st2': {
        fill: '#FFFFFF',
      },
      '.st3': {
        fill: '#FFFFFF',
      },
      '.st4': {
        fill: '#FFFFFF',
      },
      '.st5': {
        fill: '#FFFFFF',
      },
      '.st6': {
        fill: '#FFFFFF',
      },
    }}
    viewBox='0 0 309 161'
  >
    <g id='layer1'>
      <path
        className='st2'
        d='M148.7,53.6v46.7
		c0,0,0.2,9.6,9,9.1c8.7-0.5,9-8.6,9-8.6V68.1c0,0,1.9-8.5,10.7-8.6c8.7-0.1,10.2,9.7,10.2,9.7l0,32.8c0,0,0.7,7.3,8.4,7.5
		c7.6,0.2,9-6.3,9-9.2V63.9c0,0-2.7-19.1-21.9-19c0,0-11-1-17.6,6.5c0,0-0.7-5.7-7.8-5.8C149.9,45.3,148.7,53.6,148.7,53.6
		L148.7,53.6z'
        id='path905'
      />
      <path
        className='st3'
        d='M119.1,23.4c-4.9,0-8.9,4-8.9,8.9c0,4.9,4,8.9,8.9,8.9
		c4.9,0,8.9-4,8.9-8.9c0,0,0,0,0,0C127.9,27.4,124,23.4,119.1,23.4C119.1,23.4,119.1,23.4,119.1,23.4z M118.7,46
		c-4.7,0-8.4,3.7-8.4,8.4V101c0,4.7,3.7,8.4,8.4,8.4s8.4-3.7,8.4-8.4V54.4C127.1,49.7,123.4,46,118.7,46z'
        id='path919'
      />
      <path
        className='st4'
        d='M80.3,25.1H32.7
		c0,0-7.3,1.4-7.1,8.3c0.2,8.1,7.3,8.1,7.3,8.1l31.4,0L27,93.4c0,0-3.8,3.5-1.1,10.4c0,0,1.7,4.9,8.4,4.9l47.7,0
		c0,0,7.1-1.1,7.7-8.3c0.6-7.1-8.8-8.5-8.8-8.5l-32.1,0l37.1-51.1C85.9,40.8,92.9,29.6,80.3,25.1z'
        id='path927'
      />
      <path
        className='st5'
        d='M253.7,45c-3,0-6.7,0.5-11,1.8c0,0-21.3,6.2-17.4,38.1
		c0,0,1.5,25.1,26,25.3c0,0,11.5-0.6,16.1-6.9c0,0,2.8,14.8-10.1,18.5h-24.8c0,0-6.2,1.1-6.2,8.3c0,7.1,7.7,7.1,7.7,7.1h21.6
		c16.4-0.3,29.3-9.5,29.8-30.8V54.1c0,0-0.8-8.5-8.4-8.5c-7.6,0-8.7,4.2-9.4,6.6C267.8,52.1,264.6,45.1,253.7,45L253.7,45z
		 M255.8,60.6c10.4,0.2,11.6,15.6,11.6,15.6c0.7,4.9,0,17.5-12.2,17.4c-12.2-0.1-13-14.3-13-14.3c0.1-4.9,1.3-17.8,12.6-18.6
		C255.1,60.6,255.5,60.6,255.8,60.6L255.8,60.6z'
        id='path935'
      />
    </g>
  </SvgIcon>

export default ZingMp3Logo
