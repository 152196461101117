/* eslint-disable max-len */
import { Icon } from '@mui/material'

import allsaintsImage from 'src/components/Icons/allsaintslogo.png'

const AllSaintsLogo = () =>
  <Icon sx={{ height: 26, width: 26, fontSize: '1rem' }}>
    <img alt='AllSaints Logo' height='100%' src={allsaintsImage} width='100%' />
  </Icon>

export default AllSaintsLogo
