/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable max-lines */
/* eslint-disable complexity */
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import DataObjectRoundedIcon from '@mui/icons-material/DataObjectRounded'
import FacebookIcon from '@mui/icons-material/Facebook'
import GoogleIcon from '@mui/icons-material/Google'
import PhonelinkRoundedIcon from '@mui/icons-material/PhonelinkRounded'
import { Alert, Avatar, Button, Card, Checkbox, Chip, FormControlLabel, IconButton, Stack, TextField, Typography } from '@mui/material'
import { useState } from 'react'

import SnapchatIcon from 'src/components/Icons/SnapchatIcon'
import TikTokIcon from 'src/components/Icons/TikTokIcon'
import BillingModal from 'src/components/pages/Account/Billing/BillingModal'
import EditPixelOrgModal from 'src/components/pages/Links/EditLink/EditLinkModals/EditOrgPixelModal'
import MarketingSettingsModal from 'src/components/pages/Links/LinksList/LinksListModal/MarketingCloudSettingsModal'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { MarketingLink } from 'src/models/Marketing'

type Props = {
  smartLinkData: MarketingLink
  setLinkData: React.Dispatch<React.SetStateAction<MarketingLink>>
}

const EditLinkTracking = (props: Props) => {
  const { currentOrganisation } = useAuth()
  const notRightTier =
    currentOrganisation?.membershipTier !== 'CREATOR_PRO' &&
    currentOrganisation?.membershipTier !== 'CREATOR_PRO_YEARLY' &&
    currentOrganisation?.membershipTier !== 'ENTERPRISE' &&
    currentOrganisation?.membershipTier !== 'ENTERPRISE_YEARLY'
  const [settings, setSettings] = useState(false)
  const [billingModal, setBillingModalOpen] = useState(false)
  const [openEditModal, setOpenEditModal] = useState(false)

  const handleFacebookPixelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setLinkData(old => ({ ...old, facebookPixel: event.target.value.replace(/\s/g, '') }))
  }

  const handleTikTokPixelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setLinkData(old => ({ ...old, tiktokPixel: event.target.value.replace(/\s/g, '') }))
  }

  const handleGTMChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setLinkData(old => ({ ...old, googleTagManager: event.target.value.replace(/\s/g, '') }))
  }

  const handleSnapchatPixelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setLinkData(old => ({ ...old, snapchatPixel: event.target.value.replace(/\s/g, '') }))
  }

  return (
    <Stack direction='column' maxWidth={896} spacing={4}>
      <MarketingSettingsModal
        close={() => setSettings(false)}
        open={settings}
      />
      {openEditModal &&
      <EditPixelOrgModal
        close={() => setOpenEditModal(false)}
        open={openEditModal}
      />}
      {billingModal && currentOrganisation &&
      <BillingModal
        account={currentOrganisation}
        close={() => setBillingModalOpen(false)}
        open={billingModal}
      />}
      <Stack direction='column' spacing={4}>
        <Stack width={1}>
          <Stack
            alignItems='flex-start'
            direction='row'
            justifyContent='space-between'
            paddingTop={2}
            width={1}
          >
            <Typography variant='h3'>
              {'Tracking pixels '}
              {notRightTier &&
              <Chip
                color='success'
                label='Pro+'
                size='small'
              />}
            </Typography>
          </Stack>
          <Card
            variant='outlined'
          >
            <Stack direction='column' padding={2} spacing={2} width={1}>
              <Stack direction='column'>
                <Typography lineHeight={1.4} variant='h4'>
                  Track your data
                </Typography>
                <Typography color='text.secondary' variant='body1'>
                  Collect data on your fans to retarget ads to them and reduce your advertising costs.
                </Typography>
                <Stack paddingTop={1} spacing={1} width={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={props.smartLinkData.useDefaultPixels}
                        disabled={notRightTier}
                        onChange={event => props.setLinkData(old =>
                          ({ ...old, useDefaultPixels: event.target.checked }))}
                      />
                    }
                    label='Use my default pixels'
                  />
                  <Button
                    color='secondary'
                    disabled={notRightTier}
                    onClick={() => setOpenEditModal(true)}
                    startIcon={<DataObjectRoundedIcon />}
                    sx={{
                      width: 'fit-content',
                    }}
                  >
                    Manage my default pixels
                  </Button>
                </Stack>
              </Stack>
              <Stack direction='column' justifyContent='space-between' spacing={2}>
                <Stack direction='row' justifyContent='space-between' paddingTop={2} spacing={2}>
                  <IconButton
                    disableRipple
                    sx={{
                      backgroundColor: theme => theme.palette.facebook,
                      color: 'white',
                      ':hover': {
                        backgroundColor: theme => theme.palette.facebook,
                        cursor: 'default',
                      },
                    }}
                  >
                    <FacebookIcon />
                  </IconButton>
                  <TextField
                    autoComplete='new-password'
                    disabled={notRightTier || props.smartLinkData.useDefaultPixels}
                    fullWidth
                    id='facebookpixel'
                    label='Facebook pixel'
                    onChange={handleFacebookPixelChange}
                    placeholder='Enter your Facebook Pixel ID'
                    sx={{ marginTop: 0 }}
                    value={props.smartLinkData.useDefaultPixels
                      ? currentOrganisation?.defaultFbTag ?? ''
                      : props.smartLinkData.facebookPixel}
                  />
                </Stack>
                <Stack direction='row' justifyContent='space-between' paddingTop={2} spacing={2}>
                  <IconButton
                    disableRipple
                    sx={{
                      backgroundColor: theme => theme.palette.google,
                      color: 'white',
                      ':hover': {
                        backgroundColor: theme => theme.palette.google,
                        cursor: 'default',
                      },
                    }}
                  >
                    <GoogleIcon />
                  </IconButton>
                  <TextField
                    autoComplete='new-password'
                    disabled={notRightTier || props.smartLinkData.useDefaultPixels}
                    fullWidth
                    id='gtmpixel'
                    label='Google tag manager'
                    onChange={handleGTMChange}
                    placeholder='Enter your GTM code'
                    sx={{ marginTop: 0 }}
                    value={props.smartLinkData.useDefaultPixels
                      ? currentOrganisation?.defaultGTMTag ?? ''
                      : props.smartLinkData.googleTagManager}
                  />
                </Stack>
                <Stack direction='row' justifyContent='space-between' paddingTop={2} spacing={2}>
                  <IconButton
                    disableRipple
                    sx={{
                      backgroundColor: theme => theme.palette.tiktok,
                      color: 'white',
                      ':hover': {
                        backgroundColor: theme => theme.palette.tiktok,
                        cursor: 'default',
                      },
                    }}
                  >
                    <TikTokIcon />
                  </IconButton>
                  <TextField
                    autoComplete='new-password'
                    disabled={notRightTier || props.smartLinkData.useDefaultPixels}
                    fullWidth
                    id='tiktokpixel'
                    label='TikTok pixel'
                    onChange={handleTikTokPixelChange}
                    placeholder='Enter your TikTok Pixel ID'
                    sx={{ marginTop: 0 }}
                    value={props.smartLinkData.useDefaultPixels
                      ? currentOrganisation?.defaultTikTokTag ?? ''
                      : props.smartLinkData.tiktokPixel}
                  />
                </Stack>
                <Stack direction='row' justifyContent='space-between' paddingTop={2} spacing={2}>
                  <IconButton
                    disableRipple
                    sx={{
                      backgroundColor: '#FFFC00',
                      color: 'white',
                      ':hover': {
                        backgroundColor: '#FFFC00',
                        cursor: 'default',
                      },
                    }}
                  >
                    <SnapchatIcon />
                  </IconButton>
                  <TextField
                    autoComplete='new-password'
                    disabled={notRightTier || props.smartLinkData.useDefaultPixels}
                    fullWidth
                    id='snapchat'
                    label='Snapchat pixel'
                    onChange={handleSnapchatPixelChange}
                    placeholder='Enter your Snapchat Pixel ID'
                    sx={{ marginTop: 0 }}
                    value={props.smartLinkData.useDefaultPixels
                      ? currentOrganisation?.defaultSnapchatTag ?? ''
                      : props.smartLinkData.snapchatPixel}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Card>
        </Stack>

        <Alert
          icon={false}
          severity='success'
          sx={{
            color: 'white!important',
            background: '#26252a',
          }}
          variant='filled'
        >
          <Stack direction='row' spacing={2}>
            <Avatar
              sx={{
                color: '#26252a',
                background: 'white',
                width: 'fit-content',
                height: 'fit-content',
                padding: 1,
              }}
            >
              <DataObjectRoundedIcon sx={{ fontSize: '3rem' }} />
            </Avatar>
            <Stack direction='column'>
              <Typography lineHeight={1.4} variant='h3'>
                Make social media platforms work for you
                {notRightTier &&
                <Chip
                  color='success'
                  label='Pro+'
                  size='small'
                  sx={{
                    marginLeft: 1,
                  }}
                />}
              </Typography>
              <Typography variant='body2'>
                Tracking pixels let you measure, build, and optimize audiences for your ad campaigns. You can
                think of them as analytics tools that allow you to scale your ad results
              </Typography>
              {notRightTier &&
              <Button
                onClick={() => setBillingModalOpen(true)}
                startIcon={<AutoAwesomeRoundedIcon />}
                sx={{
                  width: 'fit-content',
                  marginTop: 1,
                  flexShrink: 0,
                  color: 'white!important',
                  background: '#FFA500',
                  ':hover': {
                    background: '#c47f00',
                  },
                }}
              >
                Upgrade
              </Button>}
            </Stack>
          </Stack>
        </Alert>

        <Stack width={1}>
          <Stack
            alignItems='flex-start'
            direction='row'
            justifyContent='space-between'
            width={1}
          >
            <Typography variant='h3'>
              {'Backend pixels '}
              {notRightTier &&
              <Chip
                color='success'
                label='Pro+'
                size='small'
              />}
            </Typography>
          </Stack>
          <Card
            variant='outlined'
          >
            <Stack direction='column' padding={2} spacing={2} width={1}>
              <Stack direction='column'>
                <Typography lineHeight={1.4} variant='h4'>
                  Go server side
                </Typography>
                <Typography color='text.secondary' variant='body1'>
                  Bypass adblockers using Releese&apos;s servers to send data to Meta Events Manager & TikTok
                  Events Manager
                </Typography>
                {notRightTier &&
                <Button
                  onClick={() => setBillingModalOpen(true)}
                  startIcon={<AutoAwesomeRoundedIcon />}
                  sx={{
                    width: 'fit-content',
                    marginTop: 1,
                    flexShrink: 0,
                    color: 'white!important',
                    background: '#FFA500',
                    ':hover': {
                      background: '#c47f00',
                    },
                  }}
                >
                  Upgrade
                </Button>}
                {!notRightTier &&
                <Button
                  color='secondary'
                  onClick={() => setSettings(true)}
                  startIcon={<PhonelinkRoundedIcon />}
                  sx={{
                    width: 'fit-content',
                    marginTop: 1,
                  }}
                >
                  Manage server pixels
                </Button>}
                <Stack paddingTop={2} spacing={2}>
                  {currentOrganisation &&
        currentOrganisation.conversionApiAccessToken.length > 0 &&
        !notRightTier
                    ? <Typography color='text.label' variant='body2'>
                      <CheckCircleRoundedIcon color='success' sx={{ fontSize: '16px' }} />
                      {' Meta Conversions API is being used'}
                    </Typography>
                    : <Typography color='text.label' variant='body2'>
                      <CancelRoundedIcon color='error' sx={{ fontSize: '16px' }} />
                      {' Meta Conversions API is not setup on your account'}
                    </Typography>}
                  {currentOrganisation &&
        currentOrganisation.tiktokEventsApiAccessToken.length > 0 &&
        !notRightTier
                    ? <Typography color='text.label' variant='body2'>
                      <CheckCircleRoundedIcon color='success' sx={{ fontSize: '16px' }} />
                      {' TikTok Events API is being used'}
                    </Typography>
                    : <Typography color='text.label' variant='body2'>
                      <CancelRoundedIcon color='error' sx={{ fontSize: '16px' }} />
                      {' TikTok Events API is not setup on your account'}
                    </Typography>}
                </Stack>
              </Stack>
            </Stack>
          </Card>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default EditLinkTracking
