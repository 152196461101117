import { Stack } from '@mui/material'

import DistributeProject from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/DistributionTab/DistributeProject'
import ViewDistribution from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/DistributionTab/ViewDistribution'
import type { Distribution } from 'src/models/Distribution'
import type Project from 'src/models/Project'

type Props = {
  readonly project: Project
  readonly distributionItem: Distribution | undefined
  readonly updateRecordingsSubtle: () => Promise<void>
  readonly setProject: React.Dispatch<React.SetStateAction<Project | undefined>>
  readonly updateProjectFunction: () => void
}

const ViewProjectDistribution = (props: Props) =>
  <Stack height={1} width={1}>
    {props.project.distributed
      ? <Stack height={1} width={1}>
        <ViewDistribution
          distributionItem={props.distributionItem}
          project={props.project}
        />
      </Stack>
      : <DistributeProject
        project={props.project}
        setProject={props.setProject}
        updateProjectFunction={props.updateProjectFunction}
        updateRecordingsSubtle={props.updateRecordingsSubtle}
      />}
  </Stack>

export default ViewProjectDistribution
