import 'src/styles/Custom/quill.css'

import { Stack } from '@mui/material'
import { useState } from 'react'
import ReactQuill from 'react-quill'

import RichInputToolbar from 'src/components/pages/Task/TaskDrawer/ToolBarQuill'
import QuillComponentTheme from 'src/styles/Custom/quillComponentStyle'

type Props = {
  description: string
  handleDescriptionChangeModern: (value: string) => void
}

export const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'align',
  'strike',
  'script',
  'blockquote',
  'background',
  'list',
  'bullet',
  'indent',
  'link',
  'code-block',
]

const TaskDrawerDescription = (props: Props) => {
  const [description, setDescription] = useState(props.description)
  return (
    <Stack direction='column' marginTop='32px!important' spacing={2}>
      <QuillComponentTheme />
      <ReactQuill
        formats={formats}
        modules={{
          toolbar: {
            container: '#toolbarItem',
          },
        }}
        onBlur={() => props.handleDescriptionChangeModern(description)}
        onChange={value => setDescription(value)}
        placeholder='Type a description'
        theme='snow'
        value={description}
      />
      <RichInputToolbar />
    </Stack>
  )
}
export default TaskDrawerDescription
