import CloseIcon from '@mui/icons-material/Close'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import { LoadingButton } from '@mui/lab'
import { Avatar, Button, Card, Dialog, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'

import { takedownDistribution } from 'src/api/distribution'
import type { Distribution } from 'src/models/Distribution'

type Props = {
  close: () => void
  open: boolean
  distribution: Distribution
}

const TakedownModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))
  const [loading, setLoading] = useState(false)

  const handleClick = async () => {
    if (props.distribution.id) {
      setLoading(true)
      await takedownDistribution(props.distribution.id)
        .then(() => props.close())
        .finally(() => setLoading(false))
    }
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='xs'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Stack paddingX={3} paddingY={3} spacing={4} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          width={1}
        >
          <Typography variant='subtitle1'>
            {`Takedown ${props.distribution.title}`}
          </Typography>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>
        <Stack spacing={1} width={1}>
          <Card
            elevation={0}
            sx={{
              background: theme => theme.palette.background.input,
              padding: 2,
            }}
          >
            <Stack alignItems='center' spacing={2} width={1}>
              <Avatar
                src={props.distribution.artworkUrl}
                sx={{
                  width: 128,
                  height: 128,
                }}
                variant='rounded'
              >
                <MusicNoteRoundedIcon color='disabled' sx={{ fontSize: 128 }} />
              </Avatar>
              <Typography
                color={themeItem.palette.text.secondary}
                textAlign='center'
                variant='body2'
              >
                {props.distribution.title}
              </Typography>
            </Stack>
          </Card>
          <Stack alignItems='center' direction='column'>
            <CloseIcon color='error' sx={{ fontSize: 64 }} />
            <Typography color='text.secondary' variant='h4'>
              Are you sure?
            </Typography>
            <Typography color='text.label' textAlign='center' variant='subtitle1'>
              Takedowns cannot be undone, once your project is taken down from stores we cannot re-upload it
            </Typography>
          </Stack>
        </Stack>
        <Stack direction='row' justifyContent='flex-end' spacing={1} width={1}>
          <Button onClick={props.close} variant='text'>
            Cancel
          </Button>
          <LoadingButton
            color='error'
            loading={loading}
            onClick={() => handleClick()}
            variant='contained'
          >
            Takedown project
          </LoadingButton>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default TakedownModal
