import CloseIcon from '@mui/icons-material/Close'
import PublishRoundedIcon from '@mui/icons-material/PublishRounded'
import { Button, Card, Dialog, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'

type Props = {
  close: () => void
  open: boolean
}

const StatusModalDistribution = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='sm'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Stack paddingX={3} paddingY={3} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          width={1}
        >
          <Typography variant='h3'>
            Statuses
          </Typography>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>
        <Typography color='textSecondary' variant='body1'>
          Your release goes through various statuses during the distribution process. You can read more about them here.
        </Typography>
        <Stack marginTop={2} spacing={2} width={1}>
          <Card
            elevation={0}
            sx={{
              background: themeItem.palette.background.elevatedCard,
              borderLeft: `8px ${themeItem.palette.primary.main} solid`,
            }}
          >
            <Stack padding={1} spacing={1} width={1}>
              <Stack alignItems='center' direction='row' spacing={0.5} width={1}>
                <Typography variant='h4'>
                  Under Review
                </Typography>
                <PublishRoundedIcon color='primary' fontSize='small' />
              </Stack>
              <Typography color='textSecondary' variant='body1'>
                Our moderation team is reviewing your songs, metadata and artwork to your release
                complies with platform&apos;s rules and guidelines.
              </Typography>
              <Typography color='textLabel' variant='body2'>
                Takes 24-72 business hours
              </Typography>
            </Stack>
          </Card>

          <Card
            elevation={0}
            sx={{
              background: themeItem.palette.background.elevatedCard,
              borderLeft: `8px ${themeItem.palette.warning.main} solid`,
            }}
          >
            <Stack padding={1} spacing={1} width={1}>
              <Stack alignItems='center' direction='row' spacing={0.5} width={1}>
                <Typography variant='h4'>
                  Changes Requested
                </Typography>
                <PublishRoundedIcon color='warning' fontSize='small' />
              </Stack>
              <Typography color='textSecondary' variant='body1'>
                Our moderation team has reviewed your release and requires changes and/or information. Please check your
                email for instructions, a customer support agent has reached out to you.
              </Typography>
              <Typography color='textLabel' variant='body2'>
                Additional revisions take 24-48 business hours.
              </Typography>
            </Stack>
          </Card>
          <Card
            elevation={0}
            sx={{
              background: themeItem.palette.background.elevatedCard,
              borderLeft: `8px ${themeItem.palette.success.main} solid`,
            }}
          >
            <Stack padding={1} spacing={1} width={1}>
              <Stack alignItems='center' direction='row' spacing={0.5} width={1}>
                <Typography variant='h4'>
                  Distributed
                </Typography>
                <PublishRoundedIcon color='success' fontSize='small' />
              </Stack>
              <Typography color='textSecondary' variant='body1'>
                Your relase was approved by our moderation team and was submitted to all stores you have selected.
              </Typography>
              <Typography color='textLabel' variant='body2'>
                Takes 12-24 hours to reflect in stores
              </Typography>
            </Stack>
          </Card>
          <Card
            elevation={0}
            sx={{
              background: themeItem.palette.background.elevatedCard,
              borderLeft: `8px ${themeItem.palette.error.main} solid`,
            }}
          >
            <Stack padding={1} spacing={1} width={1}>
              <Stack alignItems='center' direction='row' spacing={0.5} width={1}>
                <Typography variant='h4'>
                  Taken Down
                </Typography>
                <PublishRoundedIcon color='error' fontSize='small' />
              </Stack>
              <Typography color='textSecondary' variant='body1'>
                Your release has been taken down by the release owner or by our moderation team.
              </Typography>
              <Typography color='textLabel' variant='body2'>
                Takes 24-96 hours to reflect in stores
              </Typography>
            </Stack>
          </Card>
        </Stack>
        <Stack direction='row' justifyContent='flex-end' marginTop={2} spacing={1} width={1}>
          <Button
            onClick={props.close}
            variant='text'
          >
            Close
          </Button>
          <Button
            color='primary'
            onClick={() => props.close()}
            variant='contained'
          >
            Got it
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default StatusModalDistribution
