import 'react-pdf/dist/esm/Page/AnnotationLayer.css'

import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded'
import { AppBar, Dialog, Divider, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import type { PDFDocumentProxy } from 'pdfjs-dist'
import { useState } from 'react'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import ReactPlayer from 'react-player'

import { baseUrlLinksApi } from 'src/api/files'
import Loading from 'src/components/Loading'
import { renderIcon } from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/FilesTab/FileObject'
import FallbackFile from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/FilesTab/PreviewFileFallback'

type Props = {
  image: string
  fileType: string
  close: () => void
  open: boolean
  text: string
}

export const msDocumentFiles = new Set([
  'doc',
  'docx',
  'xls',
  'xlsx',
  'xslx',
  'ppt',
  'pptx',
])

export const mediaFiles = new Set([
  'mp4',
])

export const pdfFiles = new Set([
  'pdf',
])

export const audioFiles = new Set([
  'mp3',
  'wav',
  'ogg',
  'aiff',
])

export const imageFiles = new Set([
  'jpg',
  'jpeg',
  'png',
  'webp',
])

const options = {
  standardFontDataUrl: 'standard_fonts/',
}

const PreviewFile = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  const [numberPages, setNumberPages] = useState(0)

  const onDocumentLoadSuccess = (pdf: PDFDocumentProxy) => {
    setNumberPages(pdf.numPages)
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='md'
      onClose={props.close}
      open={props.open}
      sx={{
        alignItems: 'center',
        height: 1,
      }}
    >
      <Stack height={1} overflow='auto' width={1}>
        <AppBar elevation={5} sx={{ position: 'relative' }}>
          <Stack alignItems='center' direction='row' justifyContent='space-between' padding={2}>
            <Stack alignItems='center' direction='row' spacing={1}>
              {renderIcon(props.fileType)}
              <Typography color={themeItem.palette.text.secondary} variant='body2'>
                {props.text}
              </Typography>
            </Stack>
            <Stack alignItems='center' direction='row' spacing={1}>
              <IconButton
                href={`${baseUrlLinksApi}/files/download?fileName=${encodeURIComponent(props.image)}`}
                target='_blank'
              >
                <DownloadRoundedIcon />
              </IconButton>
              <IconButton onClick={() => props.close()}>
                <CloseRoundedIcon />
              </IconButton>
            </Stack>
          </Stack>
        </AppBar>
        <Divider sx={{ width: 1 }} />
        <Stack
          height={1}
          sx={{
            background: theme => theme.palette.background.input,
          }}
        >
          {/* MSDOCS */}
          {msDocumentFiles.has(props.fileType.toLowerCase()) &&
          <iframe
            frameBorder='0'
            id='msdoc-iframe'
            sandbox='allow-scripts allow-forms allow-same-origin'
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${props.image}`}
            style={{
              height: '100%',
              minHeight: '700px',
            }}
            title='msdoc-iframe'
          />}

          {/* VIDEO AUDIO */}
          {mediaFiles.has(props.fileType.toLowerCase()) &&
          <ReactPlayer
            url={props.image}
          />}

          {/* IMAGES */}
          {imageFiles.has(props.fileType.toLowerCase()) &&
          <img
            alt={props.image}
            src={props.image}
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
            }}
          />}

          {/* PDF */}
          {pdfFiles.has(props.fileType.toLowerCase()) &&
          <Document
            file={props.image}
            loading={<Stack alignItems='center' height={1000} justifyContent='center' width={1}>
              <Loading />
            </Stack>}
            onLoadSuccess={onDocumentLoadSuccess}
            options={options}
          >
            {[...Array.from({ length: numberPages })].map((_, index) =>
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
              />)}
          </Document>}

          {/* REST */}
          {!pdfFiles.has(props.fileType.toLowerCase()) &&
        !imageFiles.has(props.fileType.toLowerCase()) &&
        !mediaFiles.has(props.fileType.toLowerCase()) &&
        !msDocumentFiles.has(props.fileType.toLowerCase()) &&
        <FallbackFile
          file={props.image}
        />}
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default PreviewFile
