/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable complexity */
import { LoadingButton } from '@mui/lab'
import { Alert, AlertTitle, Autocomplete, Box, Button, Divider, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Snackbar, Stack, TextField, Typography, useTheme } from '@mui/material'
import { useState } from 'react'

import { useAuth } from 'src/components/providers/AuthProvider'
import { countries } from 'src/data/countriesData'
import type { Organisation } from 'src/models/Organisation'
import { Permissions } from 'src/models/Organisation'

const Details = () =>  {
  const themeItem = useTheme()
  const { currentOrganisation, updateCurrentOrganisation, refreshCurrentOrganisation } = useAuth()

  const [errorOpen, setErrorOpen] = useState(false)
  const [saved, setSaved] = useState(false)
  const [loading, setLoading] = useState(false)
  const [business, setBusiness] = useState(currentOrganisation?.business ?? false)
  const [businessName, setBusinessName] = useState(currentOrganisation?.businessName ?? '')
  const [organisationEmail, setOrganisationEmail] = useState(currentOrganisation?.organisationEmail ?? '')
  const [firstName, setFirstName] = useState(currentOrganisation?.firstName ?? '')
  const [lastName, setLastName] = useState(currentOrganisation?.lastName ?? '')
  const [addressLine1, setAddressLine1] = useState(currentOrganisation?.addressLine1 ?? '')
  const [addressLine2, setAddressLine2] = useState(currentOrganisation?.addressLine2 ?? '')
  const [city, setCity] = useState(currentOrganisation?.city ?? '')
  const [postalCode, setPostalCode] = useState(currentOrganisation?.postalCode ?? '')
  const [country, setCountry] = useState(currentOrganisation?.country ?? '')
  const [region, setRegion] = useState(currentOrganisation?.region ?? '')

  const selectedCountry = countries.find(
    item => item.countryShortCode === country
  )

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === 'business') {
      setBusiness(true)
    } else {
      setBusiness(false)
    }
  }

  const saveOwnerDetails = async () => {
    if (!currentOrganisation?.seats
      .find(seat => seat.role.permissions
        .includes(Permissions.EDIT_ORGANISATION_SETTINGS))) {
      // you are not the organisation owner
      return
    }
    setLoading(true)
    const account: Organisation = {
      ...currentOrganisation,
      firstName,
      lastName,
      organisationEmail,
      businessName,
      business,
      addressLine1,
      addressLine2,
      city,
      country,
      region,
      postalCode,
    }
    await updateCurrentOrganisation(account)
      .then(() => setLoading(false))
      .then(refreshCurrentOrganisation)
      .then(() => setSaved(true))
      .finally(() => setLoading(false))
      .catch(() => setErrorOpen(true))
  }

  return (
    <>
      <Snackbar
        autoHideDuration={5000}
        message='Error Org'
        onClose={() => setErrorOpen(false)}
        open={errorOpen}
      >
        <Alert onClose={() => setErrorOpen(false)} severity='warning' variant='filled'>
          <AlertTitle sx={{ fontWeight: 'bold' }}>
            This email is already in use
          </AlertTitle>
          Please use another email address and try again
        </Alert>
      </Snackbar>

      <Snackbar
        autoHideDuration={5000}
        message='Saved'
        onClose={() => setSaved(false)}
        open={saved}
      >
        <Alert onClose={() => setSaved(false)} severity='success' variant='filled'>
          <AlertTitle sx={{ fontWeight: 'bold' }}>
            Saved
          </AlertTitle>
          Your changes were successfully saved
        </Alert>
      </Snackbar>

      <Stack direction='column' maxWidth={1096} padding={4} spacing={4} width={1}>
        <Stack direction='column' >
          <Typography lineHeight={1.2} variant='h3'>
            Organization details
          </Typography>
          <Typography color={themeItem.palette.text.label} variant='body1'>
            These settings cannot be changed once your organization is verified
          </Typography>
        </Stack>
        <FormControl component='fieldset'>
          <RadioGroup
            aria-label='orgtype'
            name='orgtype'
            onChange={handleRadioChange}
            row
          >
            <FormControlLabel
              control={<Radio checked={business} />}
              disabled={currentOrganisation?.verified || currentOrganisation?.verificationSubmitted}
              label='Business'
              value='business'
            />
            <FormControlLabel
              control={<Radio checked={!business} />}
              disabled={currentOrganisation?.verified || currentOrganisation?.verificationSubmitted}
              label='Individual'
              value='individual'
            />
          </RadioGroup>
        </FormControl>
        <Grid columnSpacing={3} container rowSpacing={2} width={1}>
          {business &&
          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <TextField
              disabled={currentOrganisation?.verified || currentOrganisation?.verificationSubmitted}
              fullWidth
              id='businessname'
              label='Business name'
              onChange={event => setBusinessName(event.target.value)}
              placeholder='Business legal name'
              value={businessName}
            />
          </Grid>}
          {!business && <>
            <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
              <TextField
                disabled={currentOrganisation?.verified || currentOrganisation?.verificationSubmitted}
                fullWidth
                id='firstname'
                label='First name'
                onChange={event => setFirstName(event.target.value)}
                placeholder='First name'
                value={firstName}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
              <TextField
                disabled={currentOrganisation?.verified || currentOrganisation?.verificationSubmitted}
                fullWidth
                id='lastname'
                label='Last name'
                onChange={event => setLastName(event.target.value)}
                placeholder='Last name'
                value={lastName}
              />
            </Grid>
          </>}
          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <TextField
              disabled={currentOrganisation?.verified || currentOrganisation?.verificationSubmitted}
              fullWidth
              id='email'
              label='Email address'
              onChange={event => setOrganisationEmail(event.target.value)}
              placeholder='Email address'
              value={organisationEmail}
            />
          </Grid>
          <Divider />
          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <TextField
              disabled={currentOrganisation?.verified || currentOrganisation?.verificationSubmitted}
              fullWidth
              id='address1'
              label='Address line 1'
              onChange={event => setAddressLine1(event.target.value)}
              placeholder='Address line 1'
              value={addressLine1}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <TextField
              disabled={currentOrganisation?.verified || currentOrganisation?.verificationSubmitted}
              fullWidth
              id='address2'
              label='Address line 2'
              onChange={event => setAddressLine2(event.target.value)}
              placeholder='Address line 2'
              value={addressLine2}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <Autocomplete
              autoHighlight
              autoSelect
              disabled={currentOrganisation?.verified || currentOrganisation?.verificationSubmitted}
              fullWidth
              getOptionLabel={option => option.countryName}
              onChange={(_, newValue) => setCountry(newValue?.countryShortCode ?? '')}
              options={countries}
              renderInput={params =>
                <TextField
                  {...params}
                  label='Country'
                  placeholder='Select a country'
                  sx={{ height: 42 }}
                />}
              renderOption={(renderProps, option) =>
                <Box component='li' {...renderProps}>
                  {option.countryName}
                </Box>}
              value={countries.find(
                countryObject => countryObject.countryShortCode === country
              )}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <Autocomplete
              autoHighlight
              autoSelect
              disabled={currentOrganisation?.verified || currentOrganisation?.verificationSubmitted}
              fullWidth
              getOptionLabel={option => option.name}
              onChange={(_, newValue) => setRegion(newValue?.shortCode ?? '')}
              options={selectedCountry?.regions ?? []}
              renderInput={params =>
                <TextField
                  {...params}
                  label='Region'
                  placeholder='Select a region'
                  sx={{ height: 42 }}
                />}
              renderOption={(renderProps, option) =>
                <Box component='li' {...renderProps}>
                  {option.name}
                </Box>}
              value={selectedCountry?.regions.find(
                regionObject => regionObject.shortCode === region
              )}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <TextField
              disabled={currentOrganisation?.verified || currentOrganisation?.verificationSubmitted}
              fullWidth
              id='city'
              label='City'
              onChange={event => setCity(event.target.value)}
              placeholder='City'
              value={city}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <TextField
              disabled={currentOrganisation?.verified || currentOrganisation?.verificationSubmitted}
              fullWidth
              id='postal'
              label='Postal code'
              onChange={event => setPostalCode(event.target.value)}
              placeholder='ZIP'
              value={postalCode}
            />
          </Grid>
        </Grid>

        <Stack direction='row' justifyContent='flex-end' spacing={2}>
          <Button variant='text'>
            Cancel
          </Button>
          <LoadingButton
            disabled={currentOrganisation?.verified || currentOrganisation?.verificationSubmitted}
            loading={loading}
            onClick={() => saveOwnerDetails()}
            variant='contained'
          >
            Save changes
          </LoadingButton>
        </Stack>
      </Stack>
    </>
  )
}

export default Details
