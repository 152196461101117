import { CircularProgress, Grid, Stack, Typography } from '@mui/material'

const CancelSubscriptionComponent = () => <Grid height={1} item width={1}>
  <Stack alignItems='center' height={1} justifyContent='center' spacing={2} width={1}>
    <CircularProgress color='primary' />
    <Typography>Cancelling subscription, please wait</Typography>
  </Stack>
</Grid>

export default CancelSubscriptionComponent
