
import type { SelectChangeEvent } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { Box, Chip, FilledInput, MenuItem, Select, Stack, Typography, useTheme } from '@mui/material'

type StatusSearch = {
  label: string
  color: 'default' | 'error' | 'info' | 'primary' | 'secondary' | 'success' | 'warning' | undefined
  value: string
}
const statuses: readonly StatusSearch[] = [
  { label: 'All', color: 'default', value: '' },
  { label: 'Not started', color: 'primary', value: 'Not started' },
  { label: 'In progress', color: 'warning', value: 'In progress' },
  { label: 'Done', color: 'success', value: 'Done' },
  { label: 'Not approved', color: 'error', value: 'Not approved' },
]

type Props = {
  setInputSelected: (item: string) => void
  statusFilter: string
}

const FilterStatus = (props: Props) => {
  const themeItem = useTheme()
  const handleChange = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event
    props.setInputSelected(value)
  }
  const findValue = (labelFound: string) => statuses.find(element => element.value === labelFound)

  return (
    <Stack direction='column' spacing={1} sx={{ width: 200 }} >
      <Select
        displayEmpty
        fullWidth
        id='demo-multiple-checkbox'
        input={<FilledInput />}
        onChange={handleChange}
        renderValue={selected =>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', height: 1 }}>
            <Stack alignItems='center' direction='row' key={selected} spacing={1}>
              <Typography color={themeItem.palette.text.label} variant='inputText'>Status</Typography>
              {findValue(selected)?.color &&
              <Chip
                color={findValue(selected)?.color}
                label={findValue(selected)?.label}
                sx={{ '&:hover': { cursor: 'pointer' }  }}
                variant='filled'
              />}
            </Stack>
          </Box>}
        value={props.statusFilter}
      >
        {statuses.map(status => <MenuItem key={status.value} value={status.value}>
          <Stack alignItems='center' direction='row' spacing={1}>
            <Chip
              color={status.color}
              label={status.label}
              sx={{ '&:hover': { cursor: 'pointer' }  }}
              variant='filled'
            />
          </Stack>
        </MenuItem>)}
      </Select>
    </Stack>
  )
}

export default FilterStatus
