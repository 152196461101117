/* eslint-disable react/jsx-props-no-spreading */
import type { PlateRenderElementProps, TElement } from '@udecode/plate'
import { createPluginFactory } from '@udecode/plate'

import type { MyValue } from 'src/components/pages/Notes/Modern/typescript/plateTypes'

export const ELEMENT_BULLETED_LIST = 'bulleted-list'

export const BulletedListElement = (props: PlateRenderElementProps<MyValue, TElement>) =>
  <ul
    {...props.attributes}
    {...props.nodeProps}
    className={props.className}
  >
    {props.children}
  </ul>

export const createBulletedListPlugin = createPluginFactory({
  key: ELEMENT_BULLETED_LIST,
  isElement: true,
  component: BulletedListElement,
})
