/* eslint-disable unicorn/filename-case */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import type { AutoformatPlugin } from '@udecode/plate'

import { autoformatRules } from './autoformatRules'
import type { MyEditor, MyPlatePlugin, MyValue } from 'src/components/pages/Notes/Modern/typescript/plateTypes'

export const autoformatPlugin: Partial<
  MyPlatePlugin<AutoformatPlugin<MyValue, MyEditor>>
> = {
  options: {
    rules: autoformatRules as any,
    enableUndoOnDelete: true,
  },
}
