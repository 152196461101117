/* eslint-disable complexity */
/* eslint-disable no-restricted-imports */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable max-lines */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded'
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import BrushRoundedIcon from '@mui/icons-material/BrushRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CloseIcon from '@mui/icons-material/Close'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import DataObjectRoundedIcon from '@mui/icons-material/DataObjectRounded'
import InsertLinkRoundedIcon from '@mui/icons-material/InsertLinkRounded'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded'
import { LoadingButton, TabContext, TabList } from '@mui/lab'
import { AppBar, Button, Dialog, Divider, Fab, FormControlLabel, IconButton, MenuItem, Select, Slide, Stack, Switch, Tab, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material'
import type { ChangeEvent } from 'react'
import { useEffect, useState } from 'react'
import ReactDevicePreview from 'react-device-preview'
import { usePalette } from 'react-palette'

import { savePartialMarketingLink } from 'src/api/links'
import { getBasicOrganisation } from 'src/api/organisation'
import { getProject, getProjects } from 'src/api/projects'
import SmartLinkNewLinkSteps from 'src/components/pages/Links/EditLink/EditLinkNewLinkSteps'
import EditLinkRightTab from 'src/components/pages/Links/EditLink/EditLinkRightTab'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { MarketingLink } from 'src/models/Marketing'
import type { BasicOrganisation } from 'src/models/Organisation'
import { Permissions } from 'src/models/Organisation'
import type Project from 'src/models/Project'
import * as darkTheme from 'src/styles/dark.theme'
import * as lightTheme from 'src/styles/light.theme'

// eslint-disable-next-line unicorn/no-unsafe-regex
const checkCname = /(?:[\dA-Za-z]{2,61}\.){2}[\dA-Za-z]{2,61}/

type Props = {
  readonly close: () => void
  readonly open: boolean
  readonly updateLinks: () => Promise<void>
  readonly link: MarketingLink
  readonly tab: number
}

const EditLink = (props: Props) => {
  const { currentAccountPermissions, refreshCurrentOrganisation } = useAuth()
  const themeColors = useTheme()
  const matches = useMediaQuery(themeColors.breakpoints.down('md'))
  const fullScreen = useMediaQuery(themeColors.breakpoints.down('sm'))

  const [previewModal, setPreviewModal] = useState(false)

  const [linkData, setLinkData] = useState<MarketingLink>(props.link)

  const [activeStep, setActiveStep] = useState(props.tab)
  const [artwork, setArtwork] = useState<File | undefined>()
  const [artworkPreview, setArtworkPreview] = useState<string | null | undefined>(props.link.artworkUrl)
  const [uniqueLinkBoolean, setUniqueLinkBoolean] = useState(true)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [saveError, setSaveError] = useState('')
  const [projects, setProjects] = useState<Project[]>()
  const [project, setProject] = useState<Project>()
  const [colors, setColors] = useState<string[]>([])
  const primaryColors = usePalette(artworkPreview ?? '')
  const [organisation, setOrganisation] = useState<BasicOrganisation | null>(null)

  const activeTheme = linkData.palette === 'Light'
    ? lightTheme
    : darkTheme

  useEffect(() => {
    setColors([
      primaryColors.data.darkMuted ?? '#000000',
      primaryColors.data.darkVibrant ?? '#D8D8D8',
      primaryColors.data.muted ?? '#AAA9A9',
      primaryColors.data.vibrant ?? '#7E7D7D',
      primaryColors.data.lightMuted ?? '#404040',
      primaryColors.data.lightVibrant ?? '#ffffff',
    ])
  }, [primaryColors.loading,
    primaryColors.data])

  useEffect(() => {
    if (colors.length === 6 && linkData.backgroundColor === '#ffffff') {
      setLinkData(old => ({ ...old, backgroundColor: colors[0] }))
    }
  }, [artworkPreview, colors])

  useEffect(() => {
    if (linkData.organisationId) {
      void getBasicOrganisation(linkData.organisationId)
        .then(org => setOrganisation(org))
    }
  }, [linkData.organisationId])

  useEffect(() => {
    void getProjects()
      .then(setProjects)
    if (linkData.projectId && linkData.projectId !== undefined) {
      void getProject(linkData.projectId)
        .then(setProject)
        .catch(() => null)
    }
  }, [linkData.projectId])

  useEffect(() => {
    if (linkData.artworkUrl) {
      setArtworkPreview(linkData.artworkUrl)
    }
  }, [linkData.artworkUrl])

  useEffect(() => {
    if (artwork) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setArtworkPreview(reader.result as string)
      }
      reader.readAsDataURL(artwork)
    }
  }, [artwork])

  const handleArtworkUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]

    if (file === undefined) return

    if (file.type.startsWith('image')) {
      setArtwork(file)
      setLinkData(old => ({ ...old, artwork: file }))
    } else {
      setArtwork(undefined)
    }
  }

  const handleClickStep = (step: number) => {
    setActiveStep(step)
  }

  const missingSubdomain = linkData.subdomain.length === 0 && !checkCname.test(linkData.domain)
  const missingUrl = linkData.url.length === 0

  const createSmartLink = async () => {
    if (!missingUrl &&
        (!missingSubdomain || linkData.type === 'Biolink') &&
        uniqueLinkBoolean) {
      setLoadingSubmit(true)
      const linkTemporary = linkData
      if (artwork) {
        linkTemporary.artwork = artwork
      }
      await savePartialMarketingLink(
        {
          id: linkData.id ?? '',
          ...linkTemporary,
        }
      )
        .then(async () => refreshCurrentOrganisation())
        .then(async () => props.updateLinks())
        .then(() => props.close())
        .catch(() => {
          setSaveError('Your image upload failed, it might contain explicit content')
          setLoadingSubmit(false)
        })
        .finally(() => setLoadingSubmit(false))
    }
  }

  return (
    <>
      <Dialog
        aria-describedby='modal-modal-description'
        aria-labelledby='modal-modal-title'
        fullScreen={fullScreen}
        fullWidth
        maxWidth='sm'
        onClose={() => setPreviewModal(false)}
        open={previewModal}
        sx={{
          '& .MuiPaper-root': {
            zIndex: 9999,
          },
          justifyContent: 'center',
        }}
      >
        <Stack alignItems='center' justifyContent='flex-start' width={1}>
          <Stack
            alignItems='center'
            direction='row'
            justifyContent='space-between'
            paddingX={3}
            paddingY={3}
            width={1}
          >
            <Typography variant='h4'>
              Preview
            </Typography>
            <IconButton onClick={() => setPreviewModal(false)}>
              <CloseIcon color='disabled' />
            </IconButton>
          </Stack>
          <Divider sx={{ width: 1 }} />
          <Stack
            alignItems='flex-start'
            direction='row'
            flexShrink={0}
            height='100%'
            paddingBottom={3}
            paddingX={3}
            position='relative'
            right={0}
            width={387}
          >
            <Stack
              height={665}
              marginTop={4}
              width={340}
            >
              <ReactDevicePreview
                device='iphonex'
                scale='0.8'
              >
                {linkData.theme === 'Subtle' &&
                <Stack
                  sx={{
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                    background: `url(${artworkPreview})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    filter: 'blur(60px)',
                    zIndex: 2,
                    transform: 'scale(1.5)',
                    opacity: 0.6,
                    minWidth: 1920,
                    left: 'calc(50% - 960px)',
                    backgroundPositionX: 'center',
                    backgroundPositionY: 'center',
                    backgroundRepeat: 'no-repeat',
                  }}
                />}
                <Stack
                  alignItems='center'
                  flexGrow={0}
                  flexShrink={0}
                  height={750}
                  position='relative'
                  sx={{
                    background: linkData.theme === 'Subtle'
                      ? activeTheme.default.palette.background.default
                      : linkData.backgroundColor,
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    '::-webkit-scrollbar': {
                      display: 'none',
                    },
                  }}
                  top={0}
                  width={1}
                >
                  <EditLinkRightTab
                    artworkPreview={artworkPreview}
                    organisation={organisation}
                    smartLinkData={linkData}
                  />
                </Stack>
              </ReactDevicePreview>
            </Stack>
          </Stack>
        </Stack>
      </Dialog>

      <Dialog
        BackdropProps={{
          timeout: 500,
        }}
        closeAfterTransition
        fullScreen
        onClose={props.close}
        open={props.open}
        sx={{
          '& .MuiPaper-root': {
            transform: 'none!important',
            zIndex: 99,
          },
        }}
      >
        <Slide direction='up' in={props.open}>
          <Stack
            alignItems='flex-start'
            direction='column'
            sx={{
              height: 1,
              background: theme => theme.palette.background.input,
            }}
            width={1}
          >
            <Stack
              paddingX={2}
              top={2}
              width={1}
            >
              <AppBar
                position='fixed'
                sx={{
                  background: theme => theme.palette.background.default,
                  borderRadius: 2,
                  zIndex: '99999!important',
                  top: 16,
                  marginLeft: 2,
                  marginRight: 2,
                  paddingX: 2,
                  width: 'calc(100% - 32px)',
                }}
                variant='outlined'
              >
                <Toolbar
                  sx={{
                    justifyContent: 'space-between',
                    paddingX: '8px!important',
                  }}
                >
                  <Stack
                    alignItems='center'
                    direction='row'
                    spacing={2}
                    width='calc(100% - 40px)'
                  >
                    {!matches &&
                      <Typography variant='h3'>
                        Edit Smart Link
                      </Typography>}

                    {matches &&
                    <Select
                      onChange={event => handleClickStep(Number(event.target.value))}
                      value={activeStep.toString()}
                    >
                      <MenuItem value='0'>
                        Links
                      </MenuItem>
                      <MenuItem value='1'>
                        Appearance
                      </MenuItem>
                      <MenuItem value='2'>
                        Tracking
                      </MenuItem>
                      <MenuItem value='3'>
                        Settings
                      </MenuItem>
                      <MenuItem value='4'>
                        Analytics
                      </MenuItem>
                    </Select>}
                    {!matches &&
                    <TabContext value={activeStep.toString()}>
                      <TabList
                        aria-label='project view'
                        onChange={(event, value: string) => handleClickStep(Number(value))}
                        sx={{
                          minHeight: '32px',
                          '.MuiTab-root': {
                            borderRadius: 16,
                          },
                          '.MuiTabs-indicator': {
                            visibility: 'hidden',
                          },
                        }}
                        variant='scrollable'
                      >
                        {currentAccountPermissions?.includes(Permissions.CREATE_EDIT_REMOVE_MARKETING_LINKS) &&
                        <Tab
                          icon={<InsertLinkRoundedIcon />}
                          label='Links'
                          sx={{ minHeight: 32 }}
                          value='0'
                        />}
                        {currentAccountPermissions?.includes(Permissions.CREATE_EDIT_REMOVE_MARKETING_LINKS) &&
                        <Tab
                          icon={<BrushRoundedIcon />}
                          label='Appearance'
                          sx={{ minHeight: 32 }}
                          value='1'
                        />}
                        {currentAccountPermissions?.includes(Permissions.CREATE_EDIT_REMOVE_MARKETING_LINKS) &&
                        <Tab
                          icon={<DataObjectRoundedIcon />}
                          label='Tracking'
                          sx={{ minHeight: 32 }}
                          value='2'
                        />}
                        {currentAccountPermissions?.includes(Permissions.CREATE_EDIT_REMOVE_MARKETING_LINKS) &&
                        <Tab
                          icon={<SettingsRoundedIcon />}
                          label='Settings'
                          sx={{ minHeight: 32 }}
                          value='3'
                        />}
                        <Tab
                          icon={<AnalyticsRoundedIcon />}
                          label='Analytics'
                          sx={{ minHeight: 32 }}
                          value='4'
                        />
                      </TabList>
                    </TabContext>}
                  </Stack>
                  <Stack
                    alignItems='center'
                    direction='row'
                    flexShrink={0}
                  >
                    {linkData.type !== 'Biolink' &&
                    <FormControlLabel
                      control={<Switch
                        checked={linkData.active}
                        onChange={event => setLinkData(old => ({ ...old, active: event.target.checked }))}
                        sx={{
                          marginRight: 1,
                        }}
                      />}
                      label='Active'
                      sx={{
                        width: 'fit-content',
                        flexShrink: 0,
                      }}
                    />}
                    <IconButton
                      aria-label='close'
                      disabled={loadingSubmit}
                      onClick={props.close}
                    >
                      <CloseRoundedIcon />
                    </IconButton>
                  </Stack>
                </Toolbar>
              </AppBar>
            </Stack>

            <Stack
              alignItems='flex-start'
              direction='row'
              height={1}
              justifyContent='center'
              overflow='auto'
              width={1}
            >
              <Stack
                direction='row'
                justifyContent='space-between'
                maxWidth='lg'
                paddingY={8}
                spacing={2}
                width={1}
              >
                <Stack maxWidth={800} paddingX={2} paddingY={4} spacing={2} width={1}>
                  <SmartLinkNewLinkSteps
                    artworkPreview={artworkPreview}
                    colors={colors}
                    handleArtworkUpload={handleArtworkUpload}
                    project={project}
                    projects={projects}
                    saveError={saveError}
                    setLinkData={setLinkData}
                    setProject={setProject}
                    setUniqueLinkBoolean={setUniqueLinkBoolean}
                    smartLinkData={linkData}
                    step={activeStep}
                    uniqueLinkBoolean={uniqueLinkBoolean}
                  />
                </Stack>

                {linkData.type !== 'Shortlink' && !matches &&
                  <Stack
                    alignItems='flex-start'
                    direction='row'
                    flexShrink={0}
                    height='100%'
                    position='relative'
                    right={0}
                    width={340}
                  >
                    <Stack
                      height={665}
                      marginTop={6}
                      position='fixed'
                      width={340}
                    >
                      <ReactDevicePreview
                        device='iphonex'
                        scale='0.8'
                      >
                        {linkData.theme === 'Subtle' &&
                        <Stack
                          sx={{
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                            background: `url(${artworkPreview})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            filter: 'blur(60px)',
                            zIndex: 2,
                            transform: 'scale(1.5)',
                            opacity: 0.6,
                            minWidth: 1920,
                            left: 'calc(50% - 960px)',
                            backgroundPositionX: 'center',
                            backgroundPositionY: 'center',
                            backgroundRepeat: 'no-repeat',
                          }}
                        />}
                        <Stack
                          alignItems='center'
                          flexGrow={0}
                          flexShrink={0}
                          height={750}
                          position='relative'
                          sx={{
                            background: linkData.theme === 'Subtle'
                              ? activeTheme.default.palette.background.default
                              : linkData.backgroundColor,
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            '::-webkit-scrollbar': {
                              display: 'none',
                            },
                          }}
                          top={0}
                          width={1}
                        >

                          <EditLinkRightTab
                            artworkPreview={artworkPreview}
                            organisation={organisation}
                            smartLinkData={linkData}
                          />
                        </Stack>
                      </ReactDevicePreview>
                    </Stack>
                  </Stack>}
              </Stack>
            </Stack>

            <AppBar
              position='fixed'
              sx={{
                background: theme => theme.palette.background.default,
                borderRadius: 2,
                zIndex: '99999!important',
                bottom: 16,
                marginLeft: 2,
                marginRight: 2,
                paddingX: 2,
                width: 'fit-content',
                top: 'auto',
              }}
              variant='outlined'
            >
              <Toolbar
                sx={{
                  justifyContent: 'flex-end',
                  paddingX: '8px!important',
                }}
              >
                <Stack alignItems='center' direction='row' justifyContent='flex-end' spacing={1}>
                  <Button
                    color='inherit'
                    disabled={activeStep === 0}
                    onClick={() => setActiveStep(old => old - 1)}
                    rounded
                    startIcon={<ArrowBackIosRoundedIcon />}
                  >
                    Previous
                  </Button>
                  <Button
                    color='inherit'
                    disabled={activeStep === 3}
                    endIcon={<ArrowForwardIosRoundedIcon />}
                    onClick={() => setActiveStep(old => old + 1)}
                    rounded
                  >
                    Continue
                  </Button>
                  <LoadingButton
                    color='success'
                    disabled={missingUrl ||
                        (linkData.type !== 'Biolink' && missingSubdomain) ||
                      !uniqueLinkBoolean}
                    endIcon={<CheckCircleRoundedIcon />}
                    loading={loadingSubmit}
                    onClick={() => createSmartLink()}
                    variant='contained'
                  >
                    Save
                  </LoadingButton>
                </Stack>
              </Toolbar>
            </AppBar>
          </Stack>
        </Slide>
      </Dialog>
      {matches &&
      !previewModal &&
      linkData.type !== 'Shortlink' &&
      <Fab
        color='default'
        onClick={() => setPreviewModal(true)}
        rounded
        sx={{
          position: 'fixed',
          bottom: 80,
          zIndex: 9998,
          marginX: 'auto',
          width: 'fit-content',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
        variant='extended'
      >
        <VisibilityRoundedIcon sx={{ mr: 1 }} />
        Preview
      </Fab>}
    </>
  )
}
export default EditLink
