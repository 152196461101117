/* eslint-disable max-len */
import type { SvgIconProps } from '@mui/material/SvgIcon'
import SvgIcon from '@mui/material/SvgIcon'

const LineMusicLogo = (props: SvgIconProps) =>
  <SvgIcon {...props} viewBox='0 0 180.433 68.195'>
    <path clipRule='evenodd' d='M7.228 68.066H35.18c3.976 0 7.228-3.253 7.228-7.229v-.603c0-3.976-3.252-7.228-7.228-7.228H15.059V7.228C15.059 3.252 11.807 0 7.831 0h-.603C3.252 0 0 3.252 0 7.228v53.609c0 3.976 3.252 7.229 7.228 7.229zm173.205-33.604v-.603c0-3.976-3.253-7.228-7.229-7.228h-20.12V15.187h20.12c3.976 0 7.229-3.252 7.229-7.228v-.603c0-3.976-3.253-7.228-7.229-7.228h-27.952c-3.976 0-7.228 3.252-7.228 7.228v53.609c0 3.976 3.252 7.229 7.228 7.229h27.952c3.976 0 7.229-3.253 7.229-7.229v-.603c0-3.976-3.253-7.228-7.229-7.228h-20.12V41.69h20.12c3.976 0 7.229-3.252 7.229-7.228zm-53.755 31.449l.002-.003a7.207 7.207 0 0 0 2.09-5.07V7.228c0-3.976-3.252-7.228-7.229-7.228h-.603c-3.976 0-7.228 3.252-7.228 7.228v31.469L87.585 3.655C86.337 1.477 83.987 0 81.309 0h-.603c-3.976 0-7.229 3.252-7.229 7.228v53.609c0 3.976 3.252 7.229 7.229 7.229h.603c3.976 0 7.228-3.253 7.228-7.229V28.78l26.314 35.941c.162.252.339.494.53.724l.001.002c.723.986 1.712 1.662 2.814 2.075.847.35 1.773.544 2.742.544h.603a7.162 7.162 0 0 0 3.377-.844c.722-.345 1.331-.788 1.76-1.311zM55.47 68.066h.603c3.976 0 7.228-3.253 7.228-7.229V7.228C63.3 3.252 60.048 0 56.072 0h-.602c-3.976 0-7.229 3.252-7.229 7.228v53.609c0 3.976 3.253 7.229 7.229 7.229z' />
  </SvgIcon>

export default LineMusicLogo
