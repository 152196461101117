/* eslint-disable react-hooks/exhaustive-deps */
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab'
import { AppBar, Button, Dialog, Divider, IconButton, Slide, Stack, Tab, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'

import { createPitch } from 'src/api/pitch'
import { getRecordingsByProjectId } from 'src/api/recordings'
import PitchComplete from 'src/components/pages/Projects/PitchModal/PitchComplete'
import PitchDetails from 'src/components/pages/Projects/PitchModal/PitchDetails'
import SubmitPitch from 'src/components/pages/Projects/PitchModal/PitchSubmit'
import type { ArtistObject } from 'src/models/Distribution'
import type { Pitch } from 'src/models/Pitch'
import type Project from 'src/models/Project'
import type Recording from 'src/models/Recording'

type Props = {
  readonly close: () => void
  readonly open: boolean
  readonly project: Project
  readonly setPitchItem: React.Dispatch<React.SetStateAction<Pitch | undefined>>
}

const PitchModal = (props: Props) => {
  const themeColors = useTheme()
  const matches = useMediaQuery(themeColors.breakpoints.down('md'))

  const [currentTab, setCurrentTab] = useState(1)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [marketing, setMarketing] = useState('')
  const [artist, setArtist] = useState('')
  const [pitchItem, setPitchItem] = useState<Pitch>()

  const [priorityTitle, setPriorityTitle] = useState('')
  const [country, setCountry] = useState('')
  const [fanbaseLocation, setFanbaseLocation] = useState('')
  const [genre, setGenre] = useState(props.project.genre)
  const [similarArtists, setSimilarArtists] = useState<ArtistObject[]>([])
  const [recordings, setRecordings] = useState<Recording[]>([])

  const submitPitch = async () => {
    setLoadingSubmit(true)
    await createPitch({
      projectId: props.project.id,
      marketing,
      artist,
      priorityTitle,
      country,
      fanbaseLocation,
      genre,
      similarArtists: similarArtists.map(item => item.name).join(', '),
    })
      .then(pitchObject => {
        setPitchItem(pitchObject)
        setCurrentTab(3)
      })
      .finally(() => {
        setLoadingSubmit(false)
        setCurrentTab(3)
      })
      .catch(() => null)
  }

  const close = () => {
    if (pitchItem) {
      props.setPitchItem(pitchItem)
    }
    props.close()
  }

  useEffect(() => {
    void updateRecordings()
  }, [props.project.id])

  const updateRecordings = async () => {
    setRecordings([])
    await getRecordingsByProjectId(props.project.id)
      .then(recordingItems => {
        const newArray = recordingItems.sort((a, b) =>
          props.project.recordings.indexOf(a.id) - props.project.recordings.indexOf(b.id))
        setRecordings(newArray)
      })
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen
      onClose={props.close}
      open={props.open}
      sx={{
        '& .MuiPaper-root': {
          transform: 'none!important',
        },
        '.MuiDialog-paper': {
          height: '100%',
        },
      }}
    >
      <Slide direction='up' in={props.open}>
        <Stack
          height={1}
          overflow='auto'
          sx={{
            background: theme => theme.palette.background.input,
          }}
        >
          <TabContext value={currentTab.toString()}>
            <AppBar
              position='fixed'
              sx={{
                background: theme => theme.palette.background.default,
                borderRadius: 2,
                zIndex: '99999!important',
                top: 16,
                marginLeft: 2,
                marginRight: 2,
                paddingX: 2,
                width: 'calc(100% - 32px)',
              }}
              variant='outlined'
            >
              <Toolbar
                sx={{
                  justifyContent: 'space-between',
                  paddingX: '8px!important',
                }}
              >
                <Stack
                  alignItems='center'
                  direction='row'
                  spacing={2}
                  width='calc(100% - 40px)'
                >
                  {!matches &&
                  <Typography variant='h3'>
                    Pitch your Release
                  </Typography>}
                  <TabList
                    aria-label='project view'
                    onChange={(event, value: string) => setCurrentTab(Number(value))}
                    sx={{
                      minHeight: '32px',
                      '.MuiTab-root': {
                        borderRadius: 16,
                      },
                      '.MuiTabs-indicator': {
                        visibility: 'hidden',
                      },
                    }}
                    variant='scrollable'
                  >
                    <Tab
                      icon={<CheckCircleOutlineRoundedIcon />}
                      label='Information'
                      sx={{ minHeight: 32 }}
                      value='1'
                    />
                    <Tab
                      icon={<CheckCircleOutlineRoundedIcon />}
                      label='Submit'
                      sx={{ minHeight: 32 }}
                      value='2'
                    />
                  </TabList>
                </Stack>
                <IconButton
                  aria-label='close'
                  onClick={() => close()}
                >
                  <CloseRoundedIcon />
                </IconButton>
              </Toolbar>
            </AppBar>

            <Stack
              alignItems='center'
              paddingTop={11}
              width={1}
            >
              <Stack
                height={1}
                maxWidth='lg'
                paddingBottom={8}
                paddingX={1}
                width={1}
              >
                <TabPanel sx={{ width: 1, height: 1 }} value='1'>
                  <PitchDetails
                    artist={artist}
                    country={country}
                    fanbaseLocation={fanbaseLocation}
                    genre={genre}
                    marketing={marketing}
                    priorityTitle={priorityTitle}
                    project={props.project}
                    recordings={recordings}
                    setArtist={setArtist}
                    setCountry={setCountry}
                    setFanbaseLocation={setFanbaseLocation}
                    setGenre={setGenre}
                    setMarketing={setMarketing}
                    setPriorityTitle={setPriorityTitle}
                    setSimilarArtists={setSimilarArtists}
                    similarArtists={similarArtists}
                  />
                </TabPanel>
                <TabPanel sx={{ width: 1, height: 1 }} value='2'>
                  <SubmitPitch
                    artist={artist}
                    country={country}
                    fanbaseLocation={fanbaseLocation}
                    genre={genre}
                    marketing={marketing}
                    priorityTitle={priorityTitle}
                    project={props.project}
                    similarArtists={similarArtists.map(item => item.name).join(', ')}
                  />
                </TabPanel>
                <TabPanel sx={{ width: 1, height: 1 }} value='3'>
                  <PitchComplete close={close} project={props.project} />
                </TabPanel>
              </Stack>
            </Stack>
          </TabContext>
          <AppBar
            position='fixed'
            sx={{
              top: 'auto',
              bottom: 0,
              background: theme => theme.palette.background.default,
              zIndex: theme => theme.zIndex.drawer - 1,
            }}
          >
            <Divider sx={{ width: 1 }} />
            <Toolbar sx={{ justifyContent: 'flex-end' }}>
              <Stack direction='row' justifyContent='flex-end' spacing={1} width={1}>
                <Button
                  disabled={currentTab === 1 || loadingSubmit || currentTab === 3}
                  onClick={() => setCurrentTab(currentTab - 1)}
                  startIcon={<ArrowBackIosNewRoundedIcon />}
                  variant='subtle'
                >
                  Previous
                </Button>

                <Button
                  disabled={currentTab === 2 ||
                    loadingSubmit ||
                    currentTab === 3 ||
                  marketing.length === 0 ||
                  artist.length === 0}
                  endIcon={<ArrowForwardIosRoundedIcon />}
                  onClick={() => setCurrentTab(currentTab + 1)}
                >
                  Continue
                </Button>
                <LoadingButton
                  disabled={currentTab !== 2 ||
                    marketing.length === 0 ||
                    artist.length === 0}
                  endIcon={<CampaignRoundedIcon />}
                  loading={loadingSubmit}
                  onClick={() => submitPitch()}
                  variant='contained'
                >
                  Submit Pitch
                </LoadingButton>
              </Stack>
            </Toolbar>
          </AppBar>
        </Stack>
      </Slide>
    </Dialog>
  )
}

export default PitchModal
