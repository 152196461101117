/* eslint-disable sonarjs/no-duplicate-string */
import { v4 as uuidv4 } from 'uuid'

import { colorSelection } from 'src/components/pages/Task/TaskMenu/TaskMenuComponents/ColorsForPicker'
import type { TaskGroup, TaskItem } from 'src/models/Taskboard'
import type { TaskBoardTemplate } from 'src/models/Taskboard'

const taskGroupPostRelease: TaskGroup = {
  id: uuidv4(),
  tasks: [] as TaskItem[],
  title: 'My new section',
  color: colorSelection[2],
}

const taskGroups: TaskGroup[] = [taskGroupPostRelease]

export const taskboardEmptyNew: TaskBoardTemplate = {
  title: 'Empty Taskboard',
  color: colorSelection[1],
  taskGroups,
  description: `An empty taskboard that can become anything you can dream of, use this clean slate
  as you desire.`,
  id: uuidv4(),
  customFields: [],
  fieldOrder: [],
  createdAt: new Date(),
  icon: 0,
  hiddenFields: [],
}
