import axios from 'axios'

import { allowNotFoundAndUnauthorized } from 'src/api/axios'
import type { AccountDto, NewAccount } from 'src/models/Account'
import Account from 'src/models/Account'
import type { NotificationDto } from 'src/models/Notification'
import Notification from 'src/models/Notification'
import { toFormData } from 'src/utils/objectUtils'

const PATH = '/accounts' as const

// Account
// Note: Explicitely splitting get all accounts from account filtering
export const getAllAccounts = (name: string) =>
  axios.get<AccountDto[]>(`${PATH}?name=${name}`)
    .then(response => response.data.map(dto => new Account(dto)))

export const getAccounts = (params: Record<string, string>) =>
  axios.get<AccountDto[]>(`${PATH}`, params)
    .then(response => response.data.map(dto => new Account(dto)))

export const getAccount = (id: string) =>
  axios.get<AccountDto | null>(`${PATH}/${id}`)
    .then(response => response.data ? new Account(response.data) : null)
    .catch(allowNotFoundAndUnauthorized)

export const getIcalHashAccount = () =>
  axios.get<string>(`${PATH}/ical`)
    .then(response => response.data)

export const savePartialAccount = ({ query, ...account }: Partial<NewAccount> & { query: string }) =>
  axios.patch<AccountDto>(`${PATH}/${query}`, toFormData(account))
    .then(response => new Account(response.data))

export const createAccount = ({ ...account }: Partial<NewAccount>) =>
  axios.post<AccountDto>(PATH, account).then(response => new Account(response.data))

export const getCurrentAccount = () =>
  axios.get<AccountDto>(`${PATH}/current`)
    .then(response => new Account(response.data))

export const getNotifications = () =>
  axios.get<NotificationDto[]>(`${PATH}/notifications`)
    .then(response => response.data.map(dto => new Notification(dto)))

export const resetPassword = (email: string) =>
  axios.post<boolean>(`${PATH}/resetPassword`, { email })
    .then(response => response.data)

export const verifyEmail = (email: string) =>
  axios.post<boolean>(`${PATH}/verifyEmail`, { email })
    .then(response => response.data)
