/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable no-restricted-imports */
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded'
import { Avatar, Button, Divider, List, ListItemIcon, ListItemText, Menu, MenuItem, Select, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'

import SearchField from 'src/components/form-elements/SearchField'
import MyTasks from 'src/components/pages/Task/TaskMenu/TaskMenuComponents/MyTasks'
import AddEmbedModalWorkspace from 'src/components/pages/Task/TaskMenu/TaskMenuModals/AddEmbedWorkspace'
import ManageWorkspacesModal from 'src/components/pages/Task/TaskMenu/TaskMenuModals/ManageWorkspacesModal'
import NewDocumentModal from 'src/components/pages/Task/TaskMenu/TaskMenuModals/NewDocumentModal'
import NewTaskboardModal from 'src/components/pages/Task/TaskMenu/TaskMenuModals/NewTaskboardModal'
import NewWorkspaceModal from 'src/components/pages/Task/TaskMenu/TaskMenuModals/NewWorkspaceModal'
import ViewArchiveModal from 'src/components/pages/Task/TaskMenu/TaskMenuModals/ViewArchiveModal'
import Boards from 'src/components/pages/Task/TaskMenu/TaskMenuTabs/Boards'
import { EMBED_TYPES } from 'src/components/pages/Task/TaskViews/TaskViewComponents/AddEmbedTaskboard'
import GetIconTaskSmall from 'src/components/pages/Task/TaskViews/TaskViewComponents/GetIconTaskSmall'
import { taskboardIcons } from 'src/components/pages/Task/TaskViews/TaskViewComponents/TaskboardIcons'
import type { TaskboardGroup, Workspace } from 'src/models/Taskboard'

type Props = {
  readonly boardGroups: TaskboardGroup[] | undefined
  readonly refreshBoards: () => void
  readonly workspaces: Workspace[]
  readonly activeWorkspace: Workspace | undefined
  readonly setActiveWorkspace: React.Dispatch<React.SetStateAction<Workspace | undefined>>
}

const TaskMenu = (props: Props) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))
  const [searchQuery, setSearchQuery] = useState('')
  const [newBoardModalModal, setNewBoardModalOpen] = useState(false)
  const [newDocumentModalOpen, setNewDocumentModalOpen] = useState(false)
  const [newWorkspaceModalOpen, setNewWorkspaceModalOpen] = useState(false)
  const [manageWorkspaceModalOpen, setManageWorkspaceModalOpen] = useState(false)
  const [viewArchiveModal, setViewArchiveModal] = useState(false)

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const openMoreMenu = Boolean(anchorElement)

  const handleMoreMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget)
  }
  const handleMoreMenuClose = () => {
    setAnchorElement(null)
  }

  const [newEmbedModalOpen, setNewEmbedModalOpen] = useState(false)
  const [embedType, setEmbedType] = useState('HTML')

  return (
    <>
      <Menu
        anchorEl={anchorElement}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClick={handleMoreMenuClose}
        onClose={handleMoreMenuClose}
        open={openMoreMenu}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        <MenuItem onClick={() => setNewBoardModalOpen(true)}>
          <ListItemIcon color='success'>
            <TaskAltRoundedIcon color='success' fontSize='medium' />
          </ListItemIcon>
          Taskboard
        </MenuItem>
        <MenuItem onClick={() => setNewDocumentModalOpen(true)}>
          <ListItemIcon color='info'>
            <ArticleRoundedIcon color='info' fontSize='medium' />
          </ListItemIcon>
          Doc
        </MenuItem>
        <Divider sx={{ borderColor: theme.palette.text.label }} />
        {EMBED_TYPES.map(item =>
          <MenuItem
            key={item}
            onClick={() => {
              setEmbedType(item)
              setNewEmbedModalOpen(true)
            }}
          >
            <ListItemIcon
              sx={{
                fontSize: '24px',
                minWidth: '24px',
              }}
            >
              {GetIconTaskSmall(item)}
            </ListItemIcon>
            {item}
          </MenuItem>)}
      </Menu>
      {newBoardModalModal &&
      <NewTaskboardModal
        close={() => setNewBoardModalOpen(false)}
        open={newBoardModalModal}
        refreshBoards={props.refreshBoards}
      />}
      {newDocumentModalOpen &&
      <NewDocumentModal
        close={() => setNewDocumentModalOpen(false)}
        open={newDocumentModalOpen}
        refreshBoards={props.refreshBoards}
      />}
      {newWorkspaceModalOpen &&
      <NewWorkspaceModal
        close={() => setNewWorkspaceModalOpen(false)}
        open={newWorkspaceModalOpen}
        refreshBoards={props.refreshBoards}
      />}

      {newEmbedModalOpen &&
      <AddEmbedModalWorkspace
        close={() => setNewEmbedModalOpen(false)}
        open={newEmbedModalOpen}
        refreshBoards={props.refreshBoards}
        type={embedType}
        workspace={props.activeWorkspace?.id}
      />}

      <ManageWorkspacesModal
        close={() => setManageWorkspaceModalOpen(false)}
        open={manageWorkspaceModalOpen}
        refreshBoards={props.refreshBoards}
        workspaces={props.workspaces}
      />
      {viewArchiveModal &&
      <ViewArchiveModal
        close={() => setViewArchiveModal(false)}
        open={viewArchiveModal}
        refreshBoards={props.refreshBoards}
      />}

      <Stack
        alignItems='center'
        direction='column'
        height={1}
        justifyContent='space-between'
        paddingBottom={2}
        paddingTop={2}
        sx={{ flexShrink: 0, maxHeight: '100%', overflow: 'auto' }}
        width={matches ? 1 : 280}
      >
        <Stack paddingBottom={2} width={1}>
          <Stack paddingBottom={1} paddingX={2} width={1}>
            <Select
              displayEmpty
              id='workspace'
              onChange={event => {
                const foundItem = props.workspaces.find(item => item.id === event.target.value)
                props.setActiveWorkspace(foundItem)
              }}
              renderValue={_value => <Stack alignItems='center' direction='row' spacing={1}>
                <Avatar
                  color={props.activeWorkspace?.color ?? theme.palette.primary.main}
                  sx={{
                    background: props.activeWorkspace?.color ?? theme.palette.primary.main,
                    color: `${theme.palette.primary.contrastText}!important`,
                    '& .MuiSvgIcon-root': {
                      color: 'white!important',
                    },
                    borderRadius: 0.5,
                  }}
                  variant='rounded'
                >
                  {props.activeWorkspace?.icon
                    ? taskboardIcons[props.activeWorkspace.icon]
                    : <HomeRoundedIcon />}
                </Avatar>
                <Typography color={theme.palette.text.secondary} variant='body1'>
                  {props.activeWorkspace?.title ?? 'Main Workspace'}
                </Typography>
              </Stack>}
              sx={{
                height: 64,
                '.MuiFilledInput-input': {
                  height: '64px!important',
                  alignItems: 'center',
                  display: 'flex',
                },
              }}
              value={props.activeWorkspace?.id ?? 'Home'}
            >
              <MenuItem value='Home'>
                <ListItemIcon>
                  <Avatar
                    color={theme.palette.primary.main}
                    sx={{
                      background: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                      width: 32,
                      height: 32,
                      borderRadius: 0.5,
                      marginRight: 2,
                    }}
                    variant='rounded'
                  >
                    <HomeRoundedIcon />
                  </Avatar>
                </ListItemIcon>
                <ListItemText>
                  Main Workspace
                </ListItemText>
              </MenuItem>
              {props.workspaces.map(item =>
                <MenuItem key={item.id} value={item.id}>
                  <ListItemIcon>
                    <Avatar
                      sx={{
                        width: 32,
                        height: 32,
                        borderRadius: 0.5,
                        bgcolor: item.color,
                        color: theme.palette.primary.contrastText,
                        marginRight: 2,
                      }}
                      variant='rounded'
                    >
                      {taskboardIcons[item.icon]}
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText>
                    {item.title}
                  </ListItemText>
                </MenuItem>)}
              <Divider sx={{ borderColor: theme.palette.text.label }} />
              <MenuItem onClick={() => setNewWorkspaceModalOpen(true)}>
                <ListItemIcon>
                  <AddRoundedIcon />
                </ListItemIcon>
                <ListItemText>
                  <Typography color={theme.palette.text.secondary} variant='body2'>
                    Add workspace
                  </Typography>
                </ListItemText>
              </MenuItem>
              <MenuItem onClick={() => setManageWorkspaceModalOpen(true)}>
                <ListItemIcon>
                  <SettingsRoundedIcon />
                </ListItemIcon>
                <ListItemText>
                  <Typography color={theme.palette.text.secondary} variant='body2'>
                    Manage workspaces
                  </Typography>
                </ListItemText>
              </MenuItem>
            </Select>
          </Stack>

          <Stack direction='column' justifyContent='flex-start' width={1}>
            <List dense>
              <MyTasks
                handleMoreMenuOpen={handleMoreMenuOpen}
              />
            </List>
            <Stack paddingBottom={1} paddingTop={1} paddingX={2} width={1}>
              <SearchField
                fullWidth
                onChange={event => setSearchQuery(event.target.value)}
                sx={{
                  marginTop: 0,
                  '.MuiInputBase-root': {
                    paddingY: 0,
                    marginTop: 0,
                  },
                  '.MuiInputBase-input': {
                    paddingX: '0px!important',
                  },
                }}
                value={searchQuery}
              />
            </Stack>
          </Stack>

          <Divider
            sx={{
              width: 1,
              marginTop: 2,
              marginBottom: 1,
            }}
          />

          <Boards
            activeWorkspace={props.activeWorkspace}
            boardGroups={props.boardGroups}
            refreshBoards={props.refreshBoards}
            searchQuery={searchQuery}
          />

        </Stack>
        <Button
          color='inherit'
          onClick={() => setViewArchiveModal(true)}
          startIcon={<DeleteRoundedIcon />}
          sx={{
            color: theme.palette.action.active,
            borderColor: theme.palette.action.active,
            opacity: 0.5,
          }}
          variant='outlined'
        >
          View Archive
        </Button>
      </Stack>
    </>
  )
}

export default TaskMenu
