import { Avatar, ListItemIcon, ListItemText, Stack } from '@mui/material'
import type {
  ComboboxItemProps } from '@udecode/plate'

import type Account from 'src/models/Account'

type Props = {
  item: ComboboxItemProps<undefined>
  members: Account[]
}

const SelectMention = (props: Props) => {
  const text = props.item.item.text as string
  const id = props.item.item.key
  const account = props.members.find(item => item.id === id)

  return (
    <Stack alignItems='center' direction='row' paddingY={1} width={1}>
      <ListItemIcon
        sx={{
          fontSize: 30,
        }}
      >
        <Avatar
          src={account?.profilePictureUrl}
        />
      </ListItemIcon>
      <ListItemText
        primary={text}
        primaryTypographyProps={{
          sx: {
            color: theme => theme.palette.text.primary,
            fontSize: '16px!important',
          },
        }}
      />
    </Stack>
  )
}

export default SelectMention
