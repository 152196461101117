/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import { Card, CardActionArea, Stack } from '@mui/material'
import { Droppable } from 'react-beautiful-dnd'

import { NavLink } from 'src/components/SafeRouterLink'

type Props = {
  parent: string
  setLocation?: React.Dispatch<React.SetStateAction<string>>
}

const FolderBack = (props: Props) =>
  props.setLocation && props.setLocation !== undefined
    ? <Droppable droppableId={props.parent === '' ? 'home' : props.parent} key={props.parent}>
      {provided => <Card
        sx={{ border: 'none' }}
        variant='outlined'
        {...provided.droppableProps}
        ref={provided.innerRef}
      >
        <CardActionArea onClick={() => props.setLocation && props.setLocation(props.parent)}>
          <Stack
            alignItems='center'
            justifyContent='center'
            padding={2}
          >
            <ArrowBackIosNewRoundedIcon
              color='disabled'
              sx={{
                height: 64,
                width: 64,
              }}
            />
            {provided.placeholder}
          </Stack>
        </CardActionArea>
      </Card>}
    </Droppable>
    : <Droppable droppableId={props.parent === '' ? 'home' : props.parent} key={props.parent}>
      {provided => <Card
        sx={{ border: 'none' }}
        variant='outlined'
        {...provided.droppableProps}
        ref={provided.innerRef}
      >
        <CardActionArea component={NavLink} to={`/files/${props.parent}`}>
          <Stack
            alignItems='center'
            justifyContent='center'
            padding={2}
          >
            <ArrowBackIosNewRoundedIcon
              color='disabled'
              sx={{
                height: 64,
                width: 64,
              }}
            />
            {provided.placeholder}
          </Stack>
        </CardActionArea>
      </Card>}
    </Droppable>

export default FolderBack
