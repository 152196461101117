import LeaderboardRoundedIcon from '@mui/icons-material/LeaderboardRounded'
import { Avatar } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import dayjs from 'dayjs'
import { useState } from 'react'

import SocialAssetModal from 'src/components/pages/SocialAssets/SocialAssetModal'

type Props = {
  row: {
    name: string
    chart_type: string
    external_url: string
    top_position: number
    top_position_date: string
    current_position: number
    added_at: string
    removed_at: string
  }
  currentTab: string
  artworkUrl: string
}

const TrackChartItem = (props: Props) => {
  const [socialDrawer, setSocialDrawer] = useState(false)
  return (
    <>
      <TableRow
        hover
        onClick={() => setSocialDrawer(true)}
        role='checkbox'
        sx={{
          '.MuiTableCell-root': { borderBottomColor: 'divider', paddingY: 2 },
        }}
        tabIndex={-1}
      >
        <TableCell
          component='th'
          padding='normal'
          scope='row'
          width={64}
        >
          <Avatar
            sx={{
              width: 64,
              height: 64,
              background: theme => theme.palette.error.main,
            }}
            variant='rounded'
          >
            <LeaderboardRoundedIcon />
          </Avatar>
        </TableCell>
        <TableCell align='left'>{props.row.name}</TableCell>
        <TableCell align='left'>{props.row.current_position}</TableCell>
        <TableCell align='left'>{dayjs(props.row.added_at).format('LL')}</TableCell>
        <TableCell align='left'>{props.row.top_position}</TableCell>
      </TableRow>
      {socialDrawer &&
      <SocialAssetModal
        close={() => setSocialDrawer(false)}
        imageMainUrl={props.artworkUrl}
        imageSecondaryUrl=''
        open={socialDrawer}
        platform={props.currentTab}
        text={`#${props.row.top_position} in ${props.row.name}`}
      />}
    </>
  )
}

export default TrackChartItem
