import 'src/components/pages/Projects/ViewProject/ViewProjectTabs/FilesTab/FilePreview.css'

import BrokenImageIcon from '@mui/icons-material/BrokenImage'
import { Button, Stack, Typography } from '@mui/material'

type Props = {
  file: string
}

const FallbackFile = (props: Props) =>
  <Stack
    alignItems='center'
    height={1}
    justifyContent='center'
    minHeight={500}
    padding={2}
    spacing={2}
    width='100%'
  >
    <Stack alignItems='center' direction='column'>
      <BrokenImageIcon sx={{ color: 'action.disabled', fontSize: '144px' }} />
      <Typography color='text.label' variant='h4'>Could not load preview</Typography>
      <Typography color='text.disabled' variant='subtitle1' >Download the file to view content</Typography>
    </Stack>
    <Button
      download
      href={props.file}
      target='_blank'
    >
      Download
    </Button>
  </Stack>

export default FallbackFile
