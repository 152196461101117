import { Grid, Pagination, Stack } from '@mui/material'
import { useEffect, useState } from 'react'

import { getProjects } from 'src/api/projects'
import ProjectGridItem from 'src/components/pages/Projects/ProjectTabs/ProjectsTab/ProjectGridItem'
import ProjectsGridLoading from 'src/components/pages/Projects/ProjectTabs/ProjectsTab/ProjectsGridLoading'
import ProjectsTableEmpty from 'src/components/pages/Projects/ProjectTabs/ProjectsTab/ProjectsTableEmpty'
import { useAuth } from 'src/components/providers/AuthProvider'
import { useNotifications } from 'src/components/providers/NotificationProvider'
import type Project from 'src/models/Project'

type Props = {
  searchQuery: string
  statusQuery: string
  formatQuery: string
  genreQuery: string
  orderBy: string
  filterTags: string[]
  filterArtists: string[]
}

const ProjectsGrid = (props: Props) => {
  const { currentOrganisation } = useAuth()
  const { notifications } = useNotifications()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<Project[]>([])
  const [page, setPage] = useState(1)

  const filterData = data.filter(project =>
    (project.title.toLowerCase().includes(props.searchQuery.toLowerCase()) ||
    project.UPC.toLowerCase().includes(props.searchQuery.toLowerCase()) ||
    project.recordLabel.toLowerCase().includes(props.searchQuery.toLowerCase()) ||
    (project.primaryArtists.map(artist => artist.name.toLowerCase().includes(props.searchQuery.toLowerCase()))
      .includes(true) ||
    project.featuredArtists.map(artist => artist.name.toLowerCase().includes(props.searchQuery.toLowerCase()))
      .includes(true))) &&
    (props.filterTags.length === 0 || project.tags.some(value => props.filterTags.includes(value))) &&
    (props.filterArtists.length === 0 ||
      project.featuredArtists.map(item => item.name).some(value => props.filterArtists.includes(value)) ||
      project.primaryArtists.map(item => item.name).some(value => props.filterArtists.includes(value))) &&
    project.status.toLowerCase().includes(props.statusQuery.toLowerCase()) &&
    project.format.toLowerCase().includes(props.formatQuery.toLowerCase()) &&
    project.genre.toLowerCase().includes(props.genreQuery.toLowerCase()))
    .sort((a, b) => {
      switch (props.orderBy) {
        case 'Most recent':
          return Number(new Date(b.createdAt)) - Number(new Date(a.createdAt))
        case 'Oldest':
          return Number(new Date(a.createdAt)) - Number(new Date(b.createdAt))
        case 'Newest release date':
          return Number(new Date(b.releaseDate)) - Number(new Date(a.releaseDate))
        case 'Oldest release date':
          return Number(new Date(a.releaseDate)) - Number(new Date(b.releaseDate))
        default:
          return Number(new Date(b.createdAt)) - Number(new Date(a.createdAt))
      }
    })

  const currentPageData = filterData.slice(((page * 100) / 5) - 20, (page * 100) / 5)

  useEffect(() => {
    setLoading(true)
    updateProjects()
      .finally(() => setLoading(false))
  }, [currentOrganisation?.id])

  useEffect(() => {
    void updateProjects()
  }, [notifications.length])

  useEffect(() => {
    setPage(1)
  }, [filterData.length])

  const updateCurrentDisplayData = (currentPage: number) => {
    setPage(currentPage)
  }

  const updateProjects = async () => {
    await getProjects()
      .then(setData)
      .finally(() => setLoading(false))
  }

  return (
    <>
      {
        loading ? <ProjectsGridLoading />
          : <Stack alignItems='center' height={1} overflow='auto' padding={1} width={1}>
            {filterData.length > 0
              ? <Stack maxWidth={1332} width={1}>
                <Grid
                  container
                  spacing={0}
                  width={1}
                >
                  {currentPageData.map(project =>
                    <Grid
                      item
                      key={project.id}
                      lg={2.4}
                      md={3}
                      sm={3}
                      xl={2.4}
                      xs={6}
                    >
                      <ProjectGridItem
                        key={project.id}
                        project={project}
                        updateProjects={updateProjects}
                      />
                    </Grid>)}
                </Grid>
                <Stack>
                  {
                    filterData.length > 20 &&
                    <Pagination
                      count={filterData.length > 0 ? Math.ceil(filterData.length / 20) : 1}
                      onChange={(event, value: number) => {
                        updateCurrentDisplayData(value)
                      }}
                      page={page}
                      sx={{ marginTop: 3, marginBottom: 4, marginLeft: 'auto' }}
                    />
                  }
                </Stack>
              </Stack>
              : <ProjectsTableEmpty />}
          </Stack>
      }
    </>
  )
}

export default ProjectsGrid
