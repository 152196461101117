export const colorSelection: string[] =
[ '#828DF8',
  '#C084FC',
  '#F472B6',
  '#FB7185',
  '#FBBF24',
  '#A3E635',
  '#2DD4BF',
  '#38BDF8',
  '#5048E5',
  '#9333EA',
  '#DB2777',
  '#E11D48',
  '#D97706',
  '#65A30D',
  '#0D9488',
  '#0284C7',
]
