
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'
import AlternateEmailRoundedIcon from '@mui/icons-material/AlternateEmailRounded'
import CodeRoundedIcon from '@mui/icons-material/CodeRounded'
import DomainVerificationRoundedIcon from '@mui/icons-material/DomainVerificationRounded'
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Divider, Stack, Tab } from '@mui/material'
import { useState } from 'react'

import TikTokIcon from 'src/components/Icons/TikTokIcon'
import Pixels from 'src/components/pages/Settings/Marketing/DefaultPixels/Pixels'
import Domain from 'src/components/pages/Settings/Marketing/Domain/Domain'
import Profile from 'src/components/pages/Settings/Marketing/Profile/MarketingProfile'
import Social from 'src/components/pages/Settings/Marketing/SocialIcons/SocialIcons'
import Targeting from 'src/components/pages/Settings/Marketing/Targeting/Targeting'
import TikTokEventsApi from 'src/components/pages/Settings/Marketing/TikTok/TikTokEventsApi'

type Props = {
  tab?: string
}

const MarketingSettingsComponent = (props: Props) => {
  const [currentTab, setCurrentTab] = useState(props.tab ?? 'profile')
  return (
    <TabContext value={currentTab}>
      <Stack
        direction='column'
        width={1}
      >
        <Stack
          direction='row'
          width={1}
        >
          <TabList
            aria-label='Current team tab'
            onChange={(event, value: string) => setCurrentTab(value)}
          >
            <Tab
              icon={<AccountCircleRoundedIcon />}
              label='Profile'
              value='profile'
            />
            <Tab
              icon={<DomainVerificationRoundedIcon />}
              label='Custom Domain'
              value='domain'
            />
            <Tab
              icon={<FacebookRoundedIcon />}
              label='Meta Conversions API'
              value='pixel'
            />
            <Tab
              icon={<TikTokIcon />}
              label='TikTok Events API'
              value='tiktok'
            />
            <Tab
              icon={<AlternateEmailRoundedIcon />}
              label='Social Icons'
              value='social'
            />
            <Tab
              icon={<CodeRoundedIcon />}
              label='Tracking Pixels'
              value='pixels'
            />
          </TabList>
        </Stack>
        <Divider />
      </Stack>
      <Stack height={1} overflow='auto' width={1}>
        <TabPanel sx={{ padding: 0 }} value='profile'>
          <Profile />
        </TabPanel>
        <TabPanel sx={{ padding: 0 }} value='domain'>
          <Domain />
        </TabPanel>
        <TabPanel sx={{ padding: 0 }} value='pixel'>
          <Targeting />
        </TabPanel>
        <TabPanel sx={{ padding: 0 }} value='social'>
          <Social />
        </TabPanel>
        <TabPanel sx={{ padding: 0 }} value='tiktok'>
          <TikTokEventsApi />
        </TabPanel>
        <TabPanel sx={{ padding: 0 }} value='pixels'>
          <Pixels />
        </TabPanel>
      </Stack>
    </TabContext>
  )
}
export default MarketingSettingsComponent
