/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable complexity */
/* eslint-disable prefer-const */
/* eslint-disable max-lines */
/* eslint-disable react-hooks/exhaustive-deps */
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded'
import CloseIcon from '@mui/icons-material/Close'
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded'
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded'
import { LoadingButton } from '@mui/lab'
import { Autocomplete, Button, Card, CardActionArea, Chip, Dialog, FormControl, FormControlLabel, FormGroup, IconButton, ListItemAvatar, ListItemText, MenuItem, Radio, RadioGroup, Stack, Switch, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import domtoimage from 'dom-to-image'
import { createRef, useEffect, useState } from 'react'
import type { ColorResult } from 'react-color'
import { CirclePicker } from 'react-color'
import { usePalette } from 'react-palette'
import { v4 } from 'uuid'

import { GetColorLink } from 'src/components/pages/SmartLink/Content/GetColorLink'
import GetIcon from 'src/components/pages/SmartLink/Content/GetIcon'
import { formatIcons } from 'src/components/pages/SocialAssets/FormatIcons'
import PreviewAsset from 'src/components/pages/SocialAssets/PreviewAsset'
import PreviewAssetLandscape from 'src/components/pages/SocialAssets/PreviewAssetLandscape'
import PreviewAssetSquare from 'src/components/pages/SocialAssets/PreviewAssetSquare'
import { storesSocialAssets } from 'src/components/pages/SocialAssets/StoresSocial'
import { useAuth } from 'src/components/providers/AuthProvider'
import type Project from 'src/models/Project'

const dataURLtoFile = (dataurl: string, filename: string) => {
  let array = dataurl.split(','),
    mime = (/:(.*?);/).exec(array[0])?.[1],
    bstr = atob(array[1]),
    counter = bstr.length,
    u8array = new Uint8Array(counter)

  while (counter--) {
    // eslint-disable-next-line unicorn/prefer-code-point
    u8array[counter] = bstr.charCodeAt(counter)
  }

  return new File([u8array], filename, { type: mime })
}

type Props = {
  close: () => void
  open: boolean
  text: string
  imageMainUrl: string
  imageSecondaryUrl: string
  platform: string
  project?: Project
}

const layouts = [
  'Portrait',
  'Landscape',
  'Square',
]

const templates = [
  'Vibrant',
  'Subtle',
]

const SocialAssetModal = (props: Props) => {
  const { currentOrganisation } = useAuth()
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))
  const componentRef = createRef<HTMLDivElement>()
  const [loading, setLoading] = useState(false)

  const [text, setText] = useState(props.text)
  const [platform, setPlatform] = useState(props.platform)
  const [template, setTemplate] = useState(templates[0])
  const [color, setColor] = useState('')
  const [format, setFormat] = useState(0)
  const [invertImages, setInvertImages] = useState(false)
  const [mainImage, setMainImage] = useState(props.imageMainUrl)
  const [smallImage, setSmallImage] = useState(props.imageSecondaryUrl)
  const primaryColors = usePalette(props.imageMainUrl)
  const secondaryColors = usePalette(props.imageSecondaryUrl)
  const [colors, setColors] = useState<string[]>()
  const [disableWatermark, setDisableWatermark] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const disableShare = navigator.canShare?.({ text: 'Can Share' })

  const desiredWidth = format === 0
    ? 1080
    : 1920
  const desiredheight = format === 0
    ? 1920
    : format === 1
      ? 1080
      : 1920

  const handleColorChange = (colorItem: ColorResult) => {
    setColor(colorItem.hex)
  }

  useEffect(() => {
    if (colors) {
      setColor(colors[0])
    }
  }, [template, colors])

  useEffect(() => {
    if (invertImages) {
      setMainImage(props.imageSecondaryUrl)
      setSmallImage(props.imageMainUrl)
      setColors([
        secondaryColors.data.darkMuted ?? themeItem.palette.primary.main,
        secondaryColors.data.darkVibrant ?? themeItem.palette.secondary.main,
        secondaryColors.data.muted ?? themeItem.palette.error.main,
        secondaryColors.data.vibrant ?? themeItem.palette.success.main,
        secondaryColors.data.lightMuted ?? themeItem.palette.info.main,
        secondaryColors.data.lightVibrant ?? themeItem.palette.rating,
      ])
      setColor(secondaryColors.data.darkMuted ?? '')
    } else {
      setMainImage(props.imageMainUrl)
      setSmallImage(props.imageSecondaryUrl)
      setColors([
        primaryColors.data.darkMuted ?? themeItem.palette.primary.main,
        primaryColors.data.darkVibrant ?? themeItem.palette.secondary.main,
        primaryColors.data.muted ?? themeItem.palette.error.main,
        primaryColors.data.vibrant ?? themeItem.palette.success.main,
        primaryColors.data.lightMuted ?? themeItem.palette.info.main,
        primaryColors.data.lightVibrant ?? themeItem.palette.rating,
      ])
      setColor(primaryColors.data.darkMuted ?? '')
    }
  }, [invertImages,
    primaryColors.loading,
    primaryColors.data,
    secondaryColors.loading,
    secondaryColors.data])

  const save = async () => {
    const nodeItem = document.getElementById('assetItem')
    if (nodeItem) {
      setLoading(true)
      const baseHeight = nodeItem.offsetHeight

      const multiplier = desiredheight / baseHeight

      await domtoimage.toJpeg(nodeItem,
        {
          quality: 0.95,
          height: desiredheight,
          width: desiredWidth,
          style: {
            height: desiredheight,
            backgroundColor: 'white',
            width: desiredWidth,
            transformOrigin: 'top left',
            transform: `scale(${multiplier})`,
          },
        })
        .then(async () => {
          await domtoimage.toJpeg(nodeItem,
            {
              quality: 0.95,
              height: desiredheight,
              width: desiredWidth,
              style: {
                height: desiredheight,
                backgroundColor: 'white',
                width: desiredWidth,
                transformOrigin: 'top left',
                transform: `scale(${multiplier})`,
              },
            }).then(dataUrl2 => {
            const link = document.createElement('a')
            link.download = `asset-${v4()}.jpeg`
            link.href = dataUrl2
            link.click()
          })
        })
        .finally(() => setLoading(false))
    }
  }

  const share = async () => {
    const nodeItem = document.getElementById('assetItem')
    if (nodeItem) {
      const baseHeight = nodeItem.offsetHeight

      const multiplier = desiredheight / baseHeight

      await domtoimage.toJpeg(nodeItem,
        {
          quality: 0.95,
          height: desiredheight,
          width: desiredWidth,
          style: {
            height: desiredheight,
            backgroundColor: 'white',
            width: desiredWidth,
            transformOrigin: 'top left',
            transform: `scale(${multiplier})`,
          },
        })
        .then(async () => {
          await domtoimage.toJpeg(nodeItem,
            {
              quality: 0.95,
              height: desiredheight,
              width: desiredWidth,
              style: {
                height: desiredheight,
                backgroundColor: 'white',
                width: desiredWidth,
                transformOrigin: 'top left',
                transform: `scale(${multiplier})`,
              },
            }).then(async dataUrl2 => {
            const file = dataURLtoFile(dataUrl2, `asset-${v4()}.jpeg`)

            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            await navigator.share?.(
              {
                files: [file],
              }
            )
              .catch(() => null)
          })
        })
    }
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='lg'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
        width: 1,
        overflow: 'hidden',
        '& .MuiPaper-root': {
          overflow: 'hidden',
        },
      }}
    >
      <Stack
        overflow='auto'
        paddingX={3}
        paddingY={3}
        spacing={2}
        width={1}
      >
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          width='100%'
        >
          <Stack alignItems='center' direction='row' spacing={1}>
            <Typography variant='h3'>
              Generate Assets
            </Typography>
          </Stack>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>
        <Stack
          direction={fullScreen ? 'column' : 'row'}
          spacing={2}
          sx={{
            userSelect: 'none',
          }}
          width={1}
        >
          <Stack
            alignItems='center'
            justifyContent='center'
            sx={{
              overflowX: 'hidden',
            }}
            width={1}
          >
            {format === 0 &&
            <Stack
              sx={{
                borderRadius: 1,
                overflow: 'hidden',
              }}
            >
              <Stack id='assetItem' ref={componentRef}>
                {colors && !primaryColors.loading &&
                <PreviewAsset
                  color={color}
                  colors={colors}
                  disableWatermark={disableWatermark}
                  format={format}
                  mainImage={mainImage}
                  platform={platform}
                  smallImage={smallImage}
                  template={template}
                  text={text}
                />}
              </Stack>
            </Stack>}
            {format === 1 &&
            <Stack
              sx={{
                borderRadius: 1,
                overflow: 'hidden',
              }}
            >
              <Stack id='assetItem' ref={componentRef}>
                {colors && !primaryColors.loading &&
                <PreviewAssetLandscape
                  color={color}
                  colors={colors}
                  disableWatermark={disableWatermark}
                  format={format}
                  mainImage={mainImage}
                  platform={platform}
                  smallImage={smallImage}
                  template={template}
                  text={text}
                />}
              </Stack>
            </Stack>}
            {format === 2 &&
            <Stack
              sx={{
                borderRadius: 1,
                overflow: 'hidden',
              }}
            >
              <Stack id='assetItem' ref={componentRef}>
                {colors && !primaryColors.loading &&
                <PreviewAssetSquare
                  color={color}
                  colors={colors}
                  disableWatermark={disableWatermark}
                  format={format}
                  mainImage={mainImage}
                  platform={platform}
                  smallImage={smallImage}
                  template={template}
                  text={text}
                />}
              </Stack>
            </Stack>}
          </Stack>
          <Stack spacing={2} width={fullScreen ? 1 : 0.5}>
            {props.project &&
            <Stack width={1}>
              <TextField
                label='Text'
                onChange={event => setText(event.target.value)}
                value={text}
              />
            </Stack>}
            {props.project &&
            <Stack width={1}>
              <Autocomplete
                id='platform-select'
                onChange={(event, value) => setPlatform(value ?? '')}
                options={storesSocialAssets.map(item => item.name)}
                renderInput={params =>
                  <TextField
                    {...params}
                    label='Platform'
                    placeholder='Search for a platform'
                  />}
                renderOption={(renderProps, option) =>
                  <MenuItem
                    {...renderProps}
                    key={option}
                    sx={{
                      paddingY: 0.5,
                    }}
                  >
                    <ListItemAvatar>
                      <IconButton
                        disableRipple
                        size='small'
                        sx={{
                          backgroundColor: GetColorLink(option),
                          color: 'white!important',
                          ':hover': {
                            backgroundColor: GetColorLink(option),
                            cursor: 'default',
                          },
                          '& .MuiSvgIcon-root': {
                            color: 'white!important',
                          },
                        }}
                      >
                        {GetIcon(option)}
                      </IconButton>
                    </ListItemAvatar>
                    <ListItemText
                      primary={option}
                    />
                  </MenuItem>}
                value={platform}
              />
            </Stack>}
            <Stack width={1}>
              <Typography sx={{ marginTop: 0 }} variant='inputLabel'>
                Template
              </Typography>
              <FormControl>
                <RadioGroup
                  onChange={(_, value) => setTemplate(value)}
                  value={template}
                >
                  {templates.map(item =>
                    <FormControlLabel
                      control={<Radio />}
                      key={item}
                      label={item}
                      value={item}
                    />)}
                </RadioGroup>
              </FormControl>
            </Stack>
            <Stack spacing={1} width={1}>
              <Stack direction='row' width={1}>
                <Typography sx={{ marginTop: 0 }} variant='inputLabel'>
                  Layout
                </Typography>
              </Stack>
              <Stack direction='row' flexWrap='wrap' width={1}>
                {formatIcons.map((icon, index) =>
                  <Card
                    key={v4()}
                    sx={{
                      borderColor: theme => format === index
                        ? `${theme.palette.primary.main}!important`
                        : 'transparent',
                      borderCollapse: 'collapse',
                      marginRight: 1,
                    }}
                    variant='outlined'
                  >
                    <CardActionArea
                      onClick={() => setFormat(index)}
                    >
                      <Stack alignItems='center' direction='column' padding={1} spacing={1}>
                        <IconButton
                          disableFocusRipple
                          disableRipple
                          disableTouchRipple
                        >
                          {icon}
                        </IconButton>
                        <Typography
                          color={themeItem.palette.text.secondary}
                          textAlign='center'
                          variant='body2'
                        >
                          {layouts[index]}
                        </Typography>
                      </Stack>
                    </CardActionArea>
                  </Card>)}
              </Stack>
            </Stack>
            {props.imageSecondaryUrl.length > 0 &&
            <Stack spacing={1}>
              <Typography sx={{ marginTop: 0 }} variant='inputLabel'>
                Inverse images
              </Typography>
              <FormGroup>
                <Switch
                  checked={invertImages}
                  onChange={(_, checked) => setInvertImages(checked)}
                />
              </FormGroup>
            </Stack>}
            {template === templates[0] &&
            <Stack spacing={1}>
              <Typography sx={{ marginTop: 0 }} variant='inputLabel'>
                Color
              </Typography>
              {colors &&
              <CirclePicker
                color={color}
                colors={colors}
                onChangeComplete={handleColorChange}
                width='100%'
              />}
            </Stack>}

            {(currentOrganisation?.membershipTier === 'FREE' ||
            currentOrganisation?.membershipTier === 'CREATOR' ||
            currentOrganisation?.membershipTier === 'CREATOR_YEARLY') &&
            <Stack spacing={1}>
              <Stack alignItems='center' direction='row' spacing={1}>
                <Typography sx={{ marginTop: 0 }} variant='inputLabel'>
                  Remove watermark
                </Typography>
                <Chip
                  color='success'
                  icon={<AutoAwesomeRoundedIcon />}
                  label='Pro'
                  size='small'
                />
              </Stack>
              <FormGroup>
                <Switch
                  checked={false}
                  color='success'
                  disabled
                />
              </FormGroup>
            </Stack>}

            {currentOrganisation?.membershipTier !== 'FREE' &&
            currentOrganisation?.membershipTier !== 'CREATOR' &&
            currentOrganisation?.membershipTier !== 'CREATOR_YEARLY' &&
            <Stack spacing={1}>
              <Typography sx={{ marginTop: 0 }} variant='inputLabel'>
                Remove watermark
              </Typography>
              <FormGroup>
                <Switch
                  checked={disableWatermark}
                  color='success'
                  onChange={event => setDisableWatermark(event.target.checked)}
                />
              </FormGroup>
            </Stack>}

            <Stack direction='row' justifyContent='flex-end' spacing={1} width='100%'>
              <Button
                onClick={() => props.close()}
                variant='text'
              >
                Close
              </Button>
              <Button
                disabled={!disableShare}
                onClick={() => void share()}
                startIcon={<IosShareRoundedIcon />}
                variant='contained'
              >
                Share
              </Button>
              <LoadingButton
                loading={loading}
                onClick={() => void save()}
                startIcon={<DownloadRoundedIcon />}
                variant='contained'
              >
                Download
              </LoadingButton>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default SocialAssetModal
