import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

import NestedTableItem from 'src/components/pages/Projects/ProjectTabs/ProjectsTab/NestedTableItem'
import type Recording from 'src/models/Recording'

type Props = {
  recordings?: Recording[]
}

const NestedTable = (props: Props) =>
  <Table aria-label='projects table' size='small'>
    <TableHead sx={{
      '.MuiTableCell-root':
      {
        borderBottom: 2,
        borderBottomColor: 'divider',
        color: 'text.secondary',
      },
    }}
    >
      <TableRow>
        <TableCell align='left'>Title</TableCell>
        <TableCell align='left' >Artists</TableCell>
        <TableCell align='left' >ISRC</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {
        props.recordings?.map(recording =>
          <NestedTableItem key={`recording-${recording.id}`} recording={recording} />)
      }
    </TableBody>
  </Table>

export default NestedTable
