import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import StarRoundedIcon from '@mui/icons-material/StarRounded'
import { Button, Card, CardActionArea, Divider, Stack, Typography, useTheme } from '@mui/material'

type Props = {
  readonly handleSelection: () => void
  readonly title: string
  readonly text: string
  readonly free: boolean
  readonly features: string[]
  readonly featuresCallout: string
}

const SetupCard = (props: Props) => {
  const themeItem = useTheme()
  return (
    <Card
      elevation={0}
      sx={{
        width: 1,
        height: 'fit-content',
        background: props.free
          ? 'transparent'
          : null,
        border: theme => props.free
          ? null
          : `1px solid ${theme.palette.primary.main}!important`,
      }}
      variant='outlined'
    >
      <CardActionArea
        onClick={() => props.handleSelection()}
        sx={{
          width: 1,
        }}
      >
        <Stack alignItems='flex-start' justifyContent='flex-start' padding={2} spacing={2} width={1}>
          <Stack spacing={1} width={1}>
            <Typography lineHeight={1.2} variant='h2'>
              {!props.free &&
              <StarRoundedIcon sx={{ color: theme => theme.palette.rating }} />}
              {props.title}
            </Typography>
          </Stack>

          <Typography
            color={themeItem.palette.text.secondary}
            variant='body2'
          >
            {props.text}
          </Typography>

          <Button
            color={props.free ? 'inherit' : 'primary'}
            fullWidth
          >
            {props.free ? 'No thanks, I don\'t want new tools' : 'Supercharge my music'}
          </Button>

          <Stack direction='column'>
            <Typography lineHeight={1.4} variant='h3'>
              Features
            </Typography>
            <Typography color={themeItem.palette.text.secondary} variant='body2'>
              {props.featuresCallout}
            </Typography>
          </Stack>

          <Divider sx={{ width: 1 }} />

          {props.features.map(item =>
            <Typography
              color='text.secondary'
              key={item}
              textAlign='left'
              variant='body1'
            >
              <CheckCircleRoundedIcon
                color={props.free ? 'inherit' : 'success'}
                sx={{
                  marginRight: 1,
                }}
              />
              {`${item}`}
            </Typography>)}
        </Stack>
      </CardActionArea>
    </Card>
  )
}

export default SetupCard
