/* eslint-disable sonarjs/no-identical-functions */
import CloseIcon from '@mui/icons-material/Close'
import { Button, Checkbox, Dialog, FormControlLabel, FormGroup, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'
import { v4 } from 'uuid'

import { savePartialTaskboard } from 'src/api/taskboards'
import { sendTasksboardUpdate } from 'src/api/webSocket'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { TaskBoard } from 'src/models/Taskboard'
import { HIDDEN_FIELDS, TaskBoardEdit } from 'src/models/Taskboard'

type Props = {
  close: () => void
  open: boolean
  taskboard: TaskBoard
}

const HideFieldsModal = (props: Props) => {
  const themeItem = useTheme()
  const { currentAccount } = useAuth()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  const [hiddenFields, setHiddenFields] = useState(props.taskboard.hiddenFields)

  const handleClick = async () => {
    if (props.taskboard.id) {
      props.taskboard.hiddenFields = hiddenFields
      await savePartialTaskboard({ id: props.taskboard.id, ...props.taskboard })
        .then(() => {
          const taskUpdateItem = new TaskBoardEdit({
            id: v4(),
            taskboardId: props.taskboard.id,
            taskId: '',
            accountId: currentAccount.id,
            modificationType: 'Changed hidden fields',
            itemType: 'Field',
            title: 'Changed hidden fields',
          })
          sendTasksboardUpdate(taskUpdateItem)
        })
        .then(() => props.close())
    }
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='xs'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Stack marginX='auto' maxWidth='lg' paddingX={3} paddingY={3} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          width='100%'
        >
          <Typography variant='h3'>
            Default Fields
          </Typography>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>
        <Stack spacing={2} width={1}>
          <FormGroup>
            {HIDDEN_FIELDS.map(field =>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!hiddenFields.includes(field)}
                    onChange={() => {
                      if (hiddenFields.includes(field)) {
                        setHiddenFields(old => old.filter(item => item !== field))
                      } else {
                        setHiddenFields(old => [...old, field])
                      }
                    }}
                  />
                }
                key={field}
                label={field}
              />)}
          </FormGroup>
          <Stack direction='row' justifyContent='flex-end' marginLeft='auto' marginTop={2} spacing={1} width={1}>
            <Button
              onClick={() => props.close()}
              variant='text'
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleClick()}
            >
              Save changes
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default HideFieldsModal
