import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import { Avatar, Card, CardActionArea, Chip, IconButton, ListItemText, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { useState } from 'react'

import { setAnalyticsTitle } from 'src/components/pages/Analytics/AnalyticsDistribution/AnalyticsDistributionAppbar'
import CatalogRowModal from 'src/components/pages/Audience/Components/Catalog/CatalogTrackModal'
import { GetColorLink } from 'src/components/pages/SmartLink/Content/GetColorLink'
import GetIcon from 'src/components/pages/SmartLink/Content/GetIcon'
import SocialAssetModal from 'src/components/pages/SocialAssets/SocialAssetModal'

type Props = {
  source: string
  activity_text: string
  activity_date: string
  activity_avatar: string
  title: string
  artists: string
  track_avatar: string
  track?: boolean
  songstats_track_id: string
  activity_type: string
}

const ActivityRowItem = (props: Props) => {
  const themeItem = useTheme()
  const mobile = useMediaQuery(themeItem.breakpoints.down('md'))

  const row = {
    source: props.source,
    activity_text: props.activity_text,
    activity_date: props.activity_date,
    activity_avatar: props.activity_avatar,
    title: props.title,
    artists: props.artists,
    track_avatar: props.track_avatar,
    songstats_track_id: props.songstats_track_id,
    activity_type: props.activity_type,
  }

  const [open, setOpen] = useState(false)
  const [trackOpen, setTrackOpen] = useState(false)

  const labelId = `enhanced-table-activity-checkbox-${row.activity_text}`
  return (
    <>
      <Card
        elevation={0}
        key={labelId}
        sx={{
          background: theme => theme.palette.background.elevatedCard,
          borderLeft: `4px solid ${GetColorLink(row.source)}`,
        }}
      >
        <Stack padding={2} spacing={1} width={1}>
          <Stack alignItems='center' direction='row' height={1} spacing={2}>
            <Avatar
              src={row.activity_avatar}
              sx={{
                width: 92,
                height: 92,
                background: theme => theme.palette.background.input,
              }}
              variant='rounded'
            >
              <MusicNoteRoundedIcon
                sx={{
                  fontSize: 64,
                  color: theme => theme.palette.action.disabled,
                }}
              />
            </Avatar>
            <Stack spacing={0.5} width={1}>
              <ListItemText
                primary={row.activity_text}
                secondary={dayjs(row.activity_date).format('LL')}
              />
              <Chip
                icon={GetIcon(row.source)}
                label={setAnalyticsTitle(row.source)}
                size='small'
                sx={{
                  color: 'white',
                  background: GetColorLink(row.source),
                  '.MuiSvgIcon-root': {
                    color: 'white',
                    marginLeft: '8px',
                  },
                  borderRadius: 2,
                  width: 'fit-content',
                }}
              />
            </Stack>
            <IconButton onClick={() => setOpen(true)}>
              <IosShareRoundedIcon />
            </IconButton>
          </Stack>

          {!props.track &&
            <Card
              elevation={0}
              sx={{
                background: theme => theme.palette.background.default,
              }}
            >
              <CardActionArea
                onClick={event => {
                  event.stopPropagation()
                  event.preventDefault()
                  setTrackOpen(true)
                }}
              >
                <Stack alignItems='center' direction='row' paddingRight={1} width={1}>
                  <Avatar
                    src={row.track_avatar}
                    sx={{
                      height: 66,
                      width: 66,
                      background: theme => theme.palette.background.input,
                      borderRadius: '0px',
                    }}
                    variant='rounded'
                  >
                    <MusicNoteRoundedIcon
                      sx={{
                        color: theme => theme.palette.action.disabled,
                      }}
                    />
                  </Avatar>
                  <Stack padding={1} width={mobile ? 'calc(100% - 66px)' : 'calc(100% - 176px)'}>
                    <ListItemText
                      primary={row.title}
                      secondary={row.artists}
                      sx={{
                        '.MuiTypography-root': {
                          textOverflow: 'ellipsis!important',
                          overflow: 'hidden!important',
                          whiteSpace: 'nowrap!important',
                        },
                      }}
                    />
                  </Stack>
                  {!mobile &&
                  <ListItemText
                    primary={row.activity_type.replaceAll('_', ' ')}
                    secondary={<Typography color={themeItem.palette.text.link} variant='body2'>
                      View Analytics
                    </Typography>}
                    sx={{
                      textTransform: 'capitalize',
                    }}
                  />}
                </Stack>
              </CardActionArea>
            </Card>}
        </Stack>
      </Card>
      {open &&
      <SocialAssetModal
        close={() => setOpen(false)}
        imageMainUrl={props.activity_avatar.length > 0 ? props.activity_avatar : props.track_avatar}
        imageSecondaryUrl={props.activity_avatar.length > 0 ? props.track_avatar : ''}
        open={open}
        platform={props.source}
        text={props.activity_text}
      />}
      {trackOpen &&
      <CatalogRowModal
        artists={row.artists}
        avatar={row.track_avatar}
        close={() => setTrackOpen(false)}
        idItem={row.songstats_track_id}
        open={trackOpen}
        title={row.title}
      />}
    </>
  )
}

export default ActivityRowItem
