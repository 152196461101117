/* eslint-disable react/no-unused-prop-types */

import { Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'

import ProjectsTableItemEmpty from 'src/components/pages/Projects/ProjectTabs/ProjectsTab/ProjectTableItemEmpty'

const ProjectsTableLoading = () =>
  <Stack height={1}>
    <TableContainer>
      <Table aria-label='projects table' size='small'>
        <TableHead sx={{
          '.MuiTableCell-root':
          {
            borderBottom: 2,
            borderBottomColor: 'divider',
            color: 'text.secondary',
          },
        }}
        >
          <TableRow>
            <TableCell />
            <TableCell />
            <TableCell align='left'>Title</TableCell>
            <TableCell align='left' >Status</TableCell>
            <TableCell align='left'>Release Date</TableCell>
            <TableCell align='left'>Collaborators</TableCell>
            <TableCell align='left' sx={{ width: 160 }} >Last Modified</TableCell>
            <TableCell align='left' >Tags</TableCell>
            <TableCell align='left' >Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <ProjectsTableItemEmpty />
          <ProjectsTableItemEmpty />
          <ProjectsTableItemEmpty />
          <ProjectsTableItemEmpty />
          <ProjectsTableItemEmpty />
          <ProjectsTableItemEmpty />
          <ProjectsTableItemEmpty />
          <ProjectsTableItemEmpty />
          <ProjectsTableItemEmpty />
          <ProjectsTableItemEmpty />
          <ProjectsTableItemEmpty />
          <ProjectsTableItemEmpty />
          <ProjectsTableItemEmpty />
          <ProjectsTableItemEmpty />
          <ProjectsTableItemEmpty />
          <ProjectsTableItemEmpty />
          <ProjectsTableItemEmpty />
          <ProjectsTableItemEmpty />
          <ProjectsTableItemEmpty />
          <ProjectsTableItemEmpty />
        </TableBody>
      </Table>
    </TableContainer>
  </Stack>

export default ProjectsTableLoading
