/* eslint-disable @typescript-eslint/no-dynamic-delete */
/* eslint-disable switch-case/no-case-curly */
/* eslint-disable max-lines */
import { Stack, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import { visuallyHidden } from '@mui/utils'
import _ from 'lodash'
import type { Key } from 'react'
import { useState } from 'react'

import PlaylistNoFollowerItem from 'src/components/pages/Audience/Components/Playlists/PlaylistItemNoFollowers'
import type { PlaylistsEntity } from 'src/models/Analytics'

type Data = {
  name: string
  followers_count: number
  external_url: string
  artwork: string
  top_position: number
  current_position: number
  added_at: string
  removed_at: string
  type: string
  region: string
  playlist_country_code: string
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

type Order = 'asc' | 'desc'

const getComparator = (
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
  ) => number => order === 'desc'
  ? (a, b) => descendingComparator(a, b, orderBy)
  : (a, b) => -descendingComparator(a, b, orderBy)

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((element, index) => [element, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map(element => element[0])
}

type HeadCell = {
  disablePadding: boolean
  id: keyof Data
  label: string
  numeric: boolean
}

const headCells: readonly HeadCell[] = [
  {
    id: 'artwork',
    numeric: false,
    disablePadding: false,
    label: 'Artwork',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'region',
    numeric: false,
    disablePadding: false,
    label: 'Region',
  },
  {
    id: 'current_position',
    numeric: true,
    disablePadding: false,
    label: 'Current Position',
  },
  {
    id: 'top_position',
    numeric: true,
    disablePadding: false,
    label: 'Top Position',
  },
  {
    id: 'added_at',
    numeric: false,
    disablePadding: false,
    label: 'Added On',
  },
]

type EnhancedTableProps = {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void
  order: Order
  orderBy: string
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { order, orderBy,  onRequestSort } =
    props
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  return (
    <TableHead sx={{
      '.MuiTableCell-root': {
        borderBottom: 2,
        borderBottomColor: 'divider',
        color: 'text.secondary',
      },
    }}
    >
      <TableRow>
        {headCells.map(headCell =>
          <TableCell
            align='left'
            key={headCell.id}
            padding='normal'
            sortDirection={orderBy === headCell.id ? order : false}
            width={headCell.id === 'artwork' ? 100 : 'auto'}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id
                ? <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
                : null}
            </TableSortLabel>
          </TableCell>)}
      </TableRow>
    </TableHead>
  )
}

type Props = {
  chartItems: PlaylistsEntity[]
  currentTab: string
  artworkUrl: string
}

const PlaylistsTableNoFollowers = (props: Props) => {
  const rows =  props.chartItems.map(objectItem => ({
    followers_count: objectItem.followers_count ?? 0,
    name: objectItem.name,
    type: objectItem.type ?? '',
    external_url: objectItem.external_url,
    top_position: objectItem.top_position,
    top_position_date: objectItem.top_position_date,
    current_position: objectItem.current_position ?? 0,
    added_at: objectItem.added_at,
    removed_at: objectItem.removed_at ?? '',
    artwork: objectItem.artwork,
    region: objectItem.region ?? objectItem.playlist_country_code ?? 'WW',
    owner_name: objectItem.owner_name ?? '',
  }))

  const [order, setOrder] = useState<Order>('desc')
  const [orderBy, setOrderBy] = useState<keyof Data>('followers_count')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number.parseInt(event.target.value, 10))
    setPage(0)
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

  return (
    <Box width={1}>
      <Stack sx={{ width: '100%', mb: 2 }}>
        <Typography variant='h3'>
          Current Playlists
        </Typography>
        <TableContainer>
          <Table
            aria-labelledby='tableTitle'
            size='medium'
            sx={{
              minWidth: 750,
              tableLayout: 'fixed',
            }}
          >
            <EnhancedTableHead
              onRequestSort={handleRequestSort}
              order={order}
              orderBy={orderBy}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`
                  return (
                    <PlaylistNoFollowerItem
                      artworkUrl={props.artworkUrl}
                      currentTab={props.currentTab}
                      key={labelId}
                      row={row}
                    />
                  )
                })}
              {emptyRows > 0 &&
                <TableRow
                  style={{
                    height: 33 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component='div'
          count={rows.length}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 50]}
        />
      </Stack>
    </Box>
  )
}

export default PlaylistsTableNoFollowers
