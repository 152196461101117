/* eslint-disable unicorn/filename-case */
/* eslint-disable max-lines */
const pricingFeaturesList = [
  {
    title: 'Features',
    featureItem: [
      {
        title: 'Storage',
        free: '5 Gb',
        creator: '1 Tb',
        creatorPro: '5 Tb',
        enterprise: '10 Tb',
        enterprisePlus: 'Custom',
        freeIcon: false,
        creatorIcon: false,
        creatorProIcon: false,
        enterpriseIcon: false,
        enterprisePlusIcon: false,
      },
      {
        title: 'Distribution Commission',
        free: '15%',
        creator: '9.9%',
        creatorPro: '4.9%',
        enterprise: '2.9%',
        enterprisePlus: 'Custom',
        freeIcon: false,
        creatorIcon: false,
        creatorProIcon: false,
        enterpriseIcon: false,
        enterprisePlusIcon: false,
      },
      {
        title: 'Publishing & Neighboring Commission',
        free: '15%',
        creator: '15%',
        creatorPro: '15%',
        enterprise: '15%',
        enterprisePlus: '15%',
        freeIcon: false,
        creatorIcon: false,
        creatorProIcon: false,
        enterpriseIcon: false,
        enterprisePlusIcon: false,
      },
      {
        title: 'Included Users',
        free: '1',
        creator: '1',
        creatorPro: '2',
        enterprise: '3',
        enterprisePlus: 'Custom',
        freeIcon: false,
        creatorIcon: false,
        creatorProIcon: false,
        enterpriseIcon: false,
        enterprisePlusIcon: false,
      },
      {
        title: 'Cost Per Additional User',
        free: '',
        creator: '',
        creatorPro: '19$ / month',
        enterprise: '19$ / month',
        enterprisePlus: 'Custom',
        freeIcon: false,
        creatorIcon: false,
        creatorProIcon: false,
        enterpriseIcon: false,
        enterprisePlusIcon: false,
      },
      {
        title: 'Release Planning',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        enterprisePlus: '',
        freeIcon: true,
        creatorIcon: true,
        creatorProIcon: true,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Metadata Management',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: true,
        creatorIcon: true,
        creatorProIcon: true,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Task Management',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: true,
        creatorIcon: true,
        creatorProIcon: true,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Unlimited Projects',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: true,
        creatorProIcon: true,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Music Distribution',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: true,
        creatorProIcon: true,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Distribute unlimited artists',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: true,
        creatorProIcon: true,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Distribute unlimited labels',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: true,
        creatorProIcon: true,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: '1 Analytics Cloud Label or Artist profile',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: true,
        creatorProIcon: true,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Daily Spotify Streams',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: true,
        creatorProIcon: true,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Playlist Analytics',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: true,
        creatorProIcon: true,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Chart Analytics',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: true,
        creatorProIcon: true,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'TikTok and Instagram Video Analytics',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: true,
        creatorProIcon: true,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Publishing Rights Collection',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: true,
        creatorProIcon: true,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Neighboring Rights Collection',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: true,
        creatorProIcon: true,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Custom Workflows',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: true,
        creatorProIcon: true,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Task Manager Analytics',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: true,
        creatorProIcon: true,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Custom Link Subdomain',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: true,
        creatorProIcon: true,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Smart Links',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: true,
        creatorProIcon: true,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Short Links',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: true,
        creatorProIcon: true,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Pre-save Links',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: true,
        creatorProIcon: true,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Collect Fan Emails',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: true,
        creatorProIcon: true,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Export Fan Data',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: true,
        creatorProIcon: true,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Advanced Task Assignment',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: true,
        creatorProIcon: true,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Share analytics without branding',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: false,
        creatorProIcon: true,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Tracking Pixels',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: false,
        creatorProIcon: true,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Custom Marketing Link Domain',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: false,
        creatorProIcon: true,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Meta Conversions API Integration',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: false,
        creatorProIcon: true,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'TikTok Events API Integration',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: false,
        creatorProIcon: true,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Royalty Recoups',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: false,
        creatorProIcon: false,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Expense Tracking',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: false,
        creatorProIcon: false,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Territory Carving',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: false,
        creatorProIcon: false,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Custom User Roles',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: false,
        creatorProIcon: false,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Personalized Onboarding',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: false,
        creatorProIcon: false,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Dedicated Account Manager',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: false,
        creatorProIcon: false,
        enterpriseIcon: false,
        enterprisePlusIcon: true,
      },
    ],
  },
]

export default pricingFeaturesList
