import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, IconButton, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'

import { renameFile } from 'src/api/files'
import type { FileItem } from 'src/models/File'

type Props = {
  close: () => void
  open: boolean
  file: FileItem
  setFiles: React.Dispatch<React.SetStateAction<FileItem[]>>
  setFile: React.Dispatch<React.SetStateAction<FileItem | undefined>>
}

const RenameFileModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  const [title, setTitle] = useState(props.file.title)
  const [loading, setLoading] = useState(false)

  const renameFileFunction = async () => {
    if (props.file.id) {
      setLoading(true)

      await renameFile(title, props.file.id)
        .then(newFile => {
          props.setFile(newFile)
          props.setFiles(oldFiles => oldFiles.map(file => file.id === newFile.id
            ? newFile
            : file))
        })
        .then(() => props.close())
        .finally(() => setLoading(false))
    }
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='xs'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <form
        onSubmit={event => {
          event.preventDefault()
          void renameFileFunction()
        }}
      >
        <Stack height={1} paddingX={3} paddingY={3} spacing={2} width={1}>
          <Stack
            alignItems='center'
            direction='row'
            justifyContent='space-between'
            width={1}
          >
            <Typography variant='h3'>
              Rename File
            </Typography>
            <IconButton onClick={() => props.close()}>
              <CloseIcon color='disabled' />
            </IconButton>
          </Stack>

          <Stack>
            <TextField
              label='Title'
              onChange={event => setTitle(event.target.value)}
              placeholder='Folder Name'
              value={title}
            />
          </Stack>

          <Stack direction='row' justifyContent='flex-end' spacing={1} width={1}>
            <Button
              onClick={props.close}
              variant='text'
            >
              Cancel
            </Button>
            <LoadingButton
              disabled={title.length === 0}
              loading={loading}
              type='submit'
              variant='contained'
            >
              Update
            </LoadingButton>
          </Stack>
        </Stack>
      </form>
    </Dialog>
  )
}
export default RenameFileModal
