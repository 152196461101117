/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable max-lines */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable complexity */
/* eslint-disable react/forbid-component-props */
import 'src/styles/Custom/waveform.css'

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import DataArrayRoundedIcon from '@mui/icons-material/DataArrayRounded'
import DragIndicatorRoundedIcon from '@mui/icons-material/DragIndicatorRounded'
import GraphicEqRoundedIcon from '@mui/icons-material/GraphicEqRounded'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import PauseCircleRoundedIcon from '@mui/icons-material/PauseCircleRounded'
import PlayCircleFilledWhiteRoundedIcon from '@mui/icons-material/PlayCircleFilledWhiteRounded'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import { TabContext, TabList } from '@mui/lab'
import { Button, Checkbox, Chip, Collapse, FormControlLabel, IconButton, LinearProgress, Paper, Stack, Tab, Typography, useMediaQuery, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import type { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import WaveSurfer from 'wavesurfer.js'

import { isrcValidation } from 'src/components/pages/Projects/EditProject/EditProjectTabs/RecordingsTab/RecordingTabs/PublishingTab/RecordingPublishingTab'
import EditRecordingDistribution from 'src/components/pages/Projects/UpdateModal/DistributeModalComponents/UpdateRecordingDistribution'
import { generateTitleRecording } from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/RecordingsTab/Components/EditRecordingComponent'
import { useAuth } from 'src/components/providers/AuthProvider'
import { usePlayer } from 'src/components/providers/MusicProvider'
import type Project from 'src/models/Project'
import type Recording from 'src/models/Recording'
import { RECORDING_VERSIONS } from 'src/models/Recording'

type Props = {
  recording: Recording
  distribution?: boolean
  rights?: boolean
  selectedData?: Recording[]
  type?: string
  editing?: boolean
  inProject?: boolean
  draggable?: boolean
  distributed?: boolean
  handleCheck?: (event: React.ChangeEvent<HTMLInputElement>, recording: Recording) => void
  updateRecordings?: () => Promise<void>
  refresh?: React.Dispatch<React.SetStateAction<boolean>>
  dragHandleProps?: DraggableProvidedDragHandleProps | null | undefined
  updateRecordingsSubtle?: () => Promise<void>
  project: Project
  setRecordings: React.Dispatch<React.SetStateAction<Recording[]>>
}

function getColorRecording(selected: string) {
  switch (selected) {
    case RECORDING_VERSIONS[0].toString():
      return 'success'
    case RECORDING_VERSIONS[1].toString():
      return 'secondary'
    case RECORDING_VERSIONS[2].toString():
      return 'primary'
    case RECORDING_VERSIONS[3].toString():
      return 'warning'
    case RECORDING_VERSIONS[4].toString():
      return 'error'
    case RECORDING_VERSIONS[5].toString():
      return 'info'
    case RECORDING_VERSIONS[6].toString():
      return 'info'
    case RECORDING_VERSIONS[7].toString():
      return 'info'
    default:
      return 'primary'
  }
}

const delay = (ms: number) => new Promise(response => setTimeout(response, ms))

const UpdateRecordingItem = (props: Props) => {
  const { currentOrganisation } = useAuth()
  const themeColors = useTheme()
  const matches = useMediaQuery(themeColors.breakpoints.down('md'))
  const { play, currentTime, playing, timeAttempt, setPlaying, pausePlay, requestTime } = usePlayer()

  const [loadingUpload, setLoadingUpload] = useState(false)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(true)

  const project = props.project
  const [recording, setRecording] = useState(props.recording)
  const [timeLocal, setTimeLocal] = useState(0)
  const [currentTab, setCurrentTab] = useState('1')
  const [audioPeaks, setAudioPeaks] = useState(props.recording.audioWaveform?.data)
  const [wavesurfer, setWavesurfer] = useState<WaveSurfer>()
  const containerName = props.distribution ? `waveform-${recording.id}` : `waveformdistro-${recording.id}`
  const containerGet = props.distribution ? `#waveform-${recording.id}` : `#waveformdistro-${recording.id}`

  useEffect(() => {
    props.setRecordings(old => old.map(item =>
      item.id === recording.id
        ? recording
        : item))
  }, [recording])

  // General
  const checkTitle = !recording.title || recording.title.length === 0
  const checkPrimaryArtists = recording.primaryArtists.length === 0 || recording.primaryArtists.length > 5
  const checkType = !recording.trackType || recording.trackType.length === 0
  const checkCoverData = recording.trackType === 'Cover'
    // eslint-disable-next-line no-unneeded-ternary, @typescript-eslint/prefer-optional-chain
    ? recording.coverItem && recording.coverItem.name && recording.coverItem.name.length > 0
      ? false
      : true
    : false

  // Metadata
  const checkISRC = !isrcValidation.test(recording.ISRC) && recording.ISRC.length > 0
  const checkProducedBy = recording.producedBy.length === 0 || recording.producedBy.length > 15

  const checkWrittenBy = !recording.writtenBy.map(writer => writer.split(' ').length > 1)
    .every(element => element) ||
    recording.writtenBy.length === 0 ||
    recording.writtenBy.length > 15
  const checkComposedBy = !recording.composedBy.map(composer => composer.split(' ').length > 1)
    .every(element => element) ||
    recording.composedBy.length === 0 ||
    recording.composedBy.length > 15

  // Content
  const checkLanguage = !recording.language || recording.language.length === 0

  // Splits
  const checkMasterOwnership = Number(recording.masterRightsHolders.map(holder =>
    Number(holder.ownership.toFixed(2)))
    .reduce((a, b) => a + b, 0).toFixed(2)) !== 100
  const checkPublishingOwnership = Number(recording.publisherRightsHolders.map(holder =>
    Number(holder.ownership.toFixed(2)))
    .reduce((a, b) => a + b, 0).toFixed(2)) !== 100
  const rightsHolderMaster = recording.masterRightsHolders.find(rightsholder =>
    rightsholder.account.id === currentOrganisation?.id)
  const publishingRightsHolder = recording.publisherRightsHolders.find(rightsholder =>
    rightsholder.account.id === currentOrganisation?.id)

  const checkMasterLocked = recording.masterRightsHolders.map(holder =>
    holder.status === 'Locked')
    .every(element => element)

  const checkPubLocked = recording.publisherRightsHolders.map(holder =>
    holder.status === 'Locked')
    .every(element => element)

  const startYearRegex = /^[12]\d{3}/
  const endYearRegex = /[12]\d{3}$/
  const endsWithNumber = /\d$/
  const startsWithNumber = /^\d/
  const matchVolume = /Vol\. \d+/

  const errorTitlesRecordings = endsWithNumber.test(recording.title.trim()) &&
      !endYearRegex.test(recording.title.trim()) &&
      !matchVolume.test(recording.title.trim())
  const recordingTitlesStartWithNumbers = startsWithNumber.test(recording.title.trim()) &&
      !startYearRegex.test(recording.title.trim())

  const errorTitle = errorTitlesRecordings || recordingTitlesStartWithNumbers

  // eslint-disable-next-line @typescript-eslint/no-extra-parens
  const checkTitleVersionErrors = (!recording.title.includes('(') && !recording.title.includes(')'))
  // eslint-disable-next-line @typescript-eslint/no-extra-parens
  const checkTitleVersionVersionErrors = (!recording.subTitle.includes('(') && !recording.subTitle.includes(')'))

  // Distribution
  const allChecksPassedDistribution = !checkTitle && !checkPrimaryArtists && !checkType &&
  !checkISRC && !checkWrittenBy && !checkComposedBy && !checkProducedBy &&
  !checkLanguage && !checkMasterOwnership && checkMasterLocked && !errorTitle && !checkCoverData &&
  checkTitleVersionErrors && checkTitleVersionVersionErrors

  // Publishing
  const allChecksPassedPublishing = !checkTitle && !checkPrimaryArtists && !checkType &&
  !checkWrittenBy && !checkComposedBy && !checkProducedBy &&
   !checkLanguage && !checkPublishingOwnership && publishingRightsHolder && checkPubLocked &&
  props.type === 'Publishing' && isrcValidation.test(recording.ISRC)

  // Neighboring
  const allChecksPassedNeighboring = !checkTitle && !checkPrimaryArtists && !checkType &&
  !checkISRC && !checkWrittenBy && !checkComposedBy && !checkProducedBy &&
   !checkLanguage && !checkMasterOwnership && rightsHolderMaster && props.type === 'Neighboring' &&
  checkMasterLocked

  const [checkItem, setCheckItem] = useState(false)

  const checkRefreshFromServer = async () => {
    await delay(5000)
      .then(async () => {
        if (props.updateRecordingsSubtle) {
          await props.updateRecordingsSubtle()
            .then(() => setCheckItem(old => !old))
        }
      })
  }

  useEffect(() => {
    setRecording(props.recording)
    setAudioPeaks(props.recording.audioWaveform?.data)
  }, [props.recording])

  useEffect(() => {
    if (!recording.audioFileStreamingUrl ||
      recording.audioFileStreamingUrl.length === 0 ||
      !recording.audioFileUrl ||
      recording.audioFileUrl.length === 0
    ) {
      void checkRefreshFromServer()
    }
  }, [recording.audioFileStreamingUrl, recording.audioFileUrl, checkItem])

  useEffect(() => {
    if (recording.audioFileStreamingUrl &&
      recording.audioFileStreamingUrl.length > 0 &&
      recording.audioFileUrl &&
      recording.audioFileUrl.length > 0 &&
      recording.audioWaveform &&
      wavesurfer === undefined &&
      !wavesurfer &&
      !loadingUpload &&
      loading) {
      setWavesurfer(WaveSurfer.create({
        container: containerGet,
        barRadius: 1,
        barGap: 0.5,
        mediaControls: false,
        barWidth: 2,
        cursorWidth: 0,
        height: 64,
        normalize: true,
        mediaType: 'audio',
        hideScrollbar: true,
        progressColor: themeColors.palette.primary.main,
        responsive: true,
        waveColor: themeColors.palette.waveform,
        backend: 'MediaElement',
      }))
    }
    if (recording.audioFileStreamingUrl &&
      recording.audioFileUrl &&
      recording.audioFileUrl.length > 0 &&
      recording.audioFileStreamingUrl.length > 0 &&
      recording.audioWaveform &&
      wavesurfer &&
      !loadingUpload &&
      loading) {
      wavesurfer.load(recording.audioFileStreamingUrl.length > 0
        ? recording.audioFileStreamingUrl
        : recording.audioFileUrl
      ,
      audioPeaks)
      wavesurfer.setVolume(0)
      setLoading(false)
      wavesurfer.on('seek', (progress: number) => {
        if (playing !== recording.id && !wavesurfer.isPlaying()) {
          setPlaying(recording)
        }
        requestTime(recording.duration * progress)
      })
      setLoading(false)
    }
    if (wavesurfer && playing === props.recording.id && timeLocal !== timeAttempt) {
      void wavesurfer.play(timeAttempt)?.catch(() => null).finally(() => setTimeLocal(timeAttempt))
    }
    if (wavesurfer && playing === props.recording.id && play && !wavesurfer.isPlaying()) {
      void wavesurfer.playPause()?.catch(() => null)
    }
    if (wavesurfer && (playing !== props.recording.id || !play) && wavesurfer.isPlaying()) {
      void wavesurfer.playPause()?.catch(() => null)
    }
  }, [playing,
    play,
    timeAttempt,
    wavesurfer,
    currentTime,
    timeLocal,
    loadingUpload,
    loading,
    audioPeaks,
    recording.audioFileStreamingUrl])

  const handlePlaying = () => {
    if (playing === props.recording.id) {
      pausePlay(!play)
    } else {
      pausePlay(true)
      setPlaying(recording)
      requestTime(0)
    }
  }

  return (
    <Paper
      sx={{
        width: 1,
        marginX: 'auto!important',
        maxWidth: 900,
      }}
      variant='outlined'
    >
      <Stack direction='column' width={1}>

        <Stack alignItems='center' direction='row' padding={2} spacing={2} width={1}>

          {!matches &&
            <Stack alignItems='center' direction='row' spacing={2}>
              {props.draggable && props.dragHandleProps &&
              <IconButton {...props.dragHandleProps} size='medium'>
                <DragIndicatorRoundedIcon
                  color='action'
                  fontSize='small'
                />
              </IconButton>}

              {project?.artworkPreviewUrl
                ? <img
                  alt={recording.title}
                  height={104}
                  src={project.artworkPreviewUrl}
                  style={{
                    borderRadius: 16,
                    objectFit: 'cover',
                    aspectRatio: '120 / 120',
                  }}
                  width={104}
                />
                : <Paper
                  elevation={0}
                  sx={{
                    background: theme => theme.palette.background.input,
                  }}
                >
                  <MusicNoteRoundedIcon color='disabled' sx={{ fontSize: 104 }} />
                </Paper>}
            </Stack>}

          <Stack direction='column' width={1}>
            <Stack direction='row' justifyContent='space-between'>
              <Stack direction='column'>
                <Typography
                  color={themeColors.palette.text.label}
                  lineHeight={1}
                  variant='body1'
                >
                  {recording.primaryArtists.map((artist, index) =>
                    `${artist.name}${recording.primaryArtists.length === index + 1 ? '' : ', '}`)}
                </Typography>
                <Typography
                  fontSize='1.15rem'
                  lineHeight={1.5}
                  variant='h4'
                >
                  {generateTitleRecording(undefined, recording)}
                </Typography>
              </Stack>
              <Stack alignItems='center' direction='row' spacing={1}>
                {!matches && !props.distribution && !props.rights &&
                <Chip
                  color={getColorRecording(recording.recordingVersion)}
                  label={recording.recordingVersion}
                />}
                {!matches && props.distribution && !allChecksPassedDistribution &&
                  <Chip
                    color='error'
                    icon={<WarningRoundedIcon />}
                    label='Errors found'
                    variant='filled'
                  />}
                {!matches && props.distribution && allChecksPassedDistribution &&
                  <Chip
                    color='success'
                    icon={<CheckCircleRoundedIcon />}
                    label='Ready'
                    variant='filled'
                  />}
                {!matches && props.rights && props.type === 'Publishing' && !allChecksPassedPublishing &&
                  <Chip
                    color='error'
                    icon={<WarningRoundedIcon />}
                    label='Errors found'
                    variant='filled'
                  />}
                {!matches && props.rights && props.type === 'Publishing' && allChecksPassedPublishing &&
                  <Chip
                    color='success'
                    icon={<CheckCircleRoundedIcon />}
                    label='Ready'
                    variant='filled'
                  />}
                {!matches && props.rights && props.type === 'Neighboring' && !allChecksPassedNeighboring &&
                  <Chip
                    color='error'
                    icon={<WarningRoundedIcon />}
                    label='Errors found'
                    variant='filled'
                  />}
                {!matches && props.rights && props.type === 'Neighboring' && allChecksPassedNeighboring &&
                  <Chip
                    color='success'
                    icon={<CheckCircleRoundedIcon />}
                    label='Ready'
                    variant='filled'
                  />}
                {props.rights && props.type === 'Publishing' && props.selectedData && props.handleCheck &&
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={props.selectedData.includes(recording)}
                        disabled={!allChecksPassedPublishing}
                        onChange={event => props.handleCheck?.(event, recording)}
                      />
                    }
                    disabled={!allChecksPassedPublishing}
                    label='Register'
                  />}
                {props.rights && props.type === 'Neighboring' && props.selectedData && props.handleCheck &&
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={props.selectedData.includes(recording)}
                        disabled={!allChecksPassedNeighboring}
                        onChange={event => props.handleCheck?.(event, recording)}
                      />
                    }
                    disabled={!allChecksPassedNeighboring}
                    label='Register'
                  />}
              </Stack>

            </Stack>
            <Stack
              alignItems='center'
              direction={matches ? 'column' : 'row'}
              paddingTop={matches ? 2 : 0}
              width={1}
            >
              <Stack
                alignItems='center'
                direction='row'
                spacing={1}
                width={1}
              >
                <IconButton
                  color='primary'
                  onClick={handlePlaying}
                  size='large'
                  sx={{
                    padding: 0.5,
                    width: 36,
                    height: 36,
                    fontSize: 36,
                  }}
                >
                  {playing === recording.id && play
                    ? <PauseCircleRoundedIcon />
                    : <PlayCircleFilledWhiteRoundedIcon />}
                </IconButton>
                <Stack width={1}>
                  {!loadingUpload &&
                    <div
                      className='react-waves'
                      id={containerName}
                    />}
                  {!wavesurfer &&
                  <LinearProgress
                    color='primary'
                    variant='indeterminate'
                  />}
                </Stack>
                <Typography variant='textLabel' whiteSpace='nowrap'>
                  {`${dayjs(new Date(currentTime && playing === recording.id
                    ? currentTime * 1000
                    : 0)).format('mm:ss')} / 
                  ${dayjs(new Date(recording.duration ? recording.duration * 1000 : 0)).format('mm:ss')}`}
                </Typography>
              </Stack>

              <Stack
                alignItems='center'
                direction='row'
                marginLeft={matches ? 'inherit' : 'auto'}
                paddingLeft={matches ? 0 : 1}
                paddingTop={matches ? 2 : 0}
                spacing={2}
                width={matches ? 1 : 'auto'}
              >
                <Button
                  aria-controls='basic-menu'
                  aria-expanded={open ? 'true' : undefined}
                  aria-haspopup='true'
                  endIcon={<KeyboardArrowDownIcon />}
                  fullWidth={matches}
                  id='basic-button'
                  onClick={() => setOpen(!open)}
                  rounded
                  size='small'
                  variant='text'
                >
                  Details
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        <Stack width={1}>
          <Collapse in={open} sx={{ width: 1 }} timeout='auto' unmountOnExit>
            <TabContext value={currentTab}>
              <Stack
                sx={{
                  paddingLeft: 2,
                  borderBottom: 1,
                  borderColor: 'divider',
                  width: 1,
                  overflowX: 'auto',
                }}
              >
                <TabList
                  aria-label='current profile tab'
                  onChange={(event, value: string) => setCurrentTab(value)}
                  sx={{
                    minHeight: 32,
                    '.MuiTab-root':
                  {
                    textTransform: 'none',
                    minHeight: 32,
                  },
                  }}
                  variant='scrollable'
                >
                  {props.distribution || props.rights
                    ? <Tab
                      icon={!checkTitle &&
                          !checkPrimaryArtists &&
                          !checkType &&
                          !checkTitle &&
                          !errorTitle &&
                          checkTitleVersionErrors &&
                          checkTitleVersionVersionErrors &&
                          !checkCoverData
                        ? <CheckCircleRoundedIcon color='success' />
                        : <WarningRoundedIcon color='error' />}
                      label='General'
                      sx={{ minHeight: 32 }}
                      value='1'
                    />
                    : <Tab
                      icon={<MusicNoteRoundedIcon />}
                      label='General'
                      sx={{ minHeight: 32 }}
                      value='1'
                    />}
                  {props.distribution || props.rights
                    ? <Tab
                      icon={((props.distribution || props.type === 'Neighboring') &&
                        !checkISRC &&
                        !checkWrittenBy &&
                        !checkComposedBy &&
                        !checkProducedBy) ||
                        ((props.type === 'Publishing') &&
                        !checkISRC &&
                        recording.ISRC.length > 0 &&
                        !checkWrittenBy &&
                        !checkComposedBy &&
                        !checkProducedBy)
                        ? <CheckCircleRoundedIcon color='success' />
                        : <WarningRoundedIcon color='error' />}
                      label='Metadata'
                      sx={{ minHeight: 32 }}
                      value='2'
                    />
                    : <Tab
                      icon={<DataArrayRoundedIcon />}
                      label='Metadata'
                      sx={{ minHeight: 32 }}
                      value='2'
                    />}
                  {props.distribution || props.rights
                    ? <Tab
                      icon={!checkLanguage
                        ? <CheckCircleRoundedIcon color='success' />
                        : <WarningRoundedIcon color='error' />}
                      label='Content'
                      sx={{ minHeight: 32 }}
                      value='3'
                    />
                    : <Tab
                      icon={<GraphicEqRoundedIcon />}
                      label='Content'
                      sx={{ minHeight: 32 }}
                      value='3'
                    />}
                </TabList>
              </Stack>
              <EditRecordingDistribution
                currentTab={currentTab}
                distributing={props.distribution}
                done
                project={project}
                recording={props.recording}
                refresh={props.refresh}
                setAudioPeaks={setAudioPeaks}
                setLoading={setLoading}
                setLoadingUpload={setLoadingUpload}
                setRecording={setRecording}
              />
            </TabContext>
          </Collapse>
        </Stack>
      </Stack>
    </Paper>
  )
}

export default UpdateRecordingItem
