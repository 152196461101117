/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable react-hooks/exhaustive-deps */
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import CloudSyncRoundedIcon from '@mui/icons-material/CloudSyncRounded'
import DragIndicatorRoundedIcon from '@mui/icons-material/DragIndicatorRounded'
import { LoadingButton } from '@mui/lab'
import { Alert, Button, Card, Dialog, IconButton, Snackbar, Stack, TextField, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import type { DropResult } from 'react-beautiful-dnd'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { v4 } from 'uuid'

import { getLinksFromApi, savePartialProject } from 'src/api/projects'
import { GetColorProjectLink } from 'src/components/pages/Projects/ViewProject/EditDetails/GetColorProjectLink'
import GetIconLink from 'src/components/pages/Projects/ViewProject/EditDetails/GetIconLink'
import type Project from 'src/models/Project'
import type { LinkItem } from 'src/models/Project'

type Props = {
  close: () => void
  open: boolean
  project?: Project
  setProject: React.Dispatch<React.SetStateAction<Project | undefined>>
  setUpdateBoard: React.Dispatch<React.SetStateAction<boolean>>
}

const EditLinksProjectModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  const [links, setLinks] = useState(props.project?.linkItems ?? [])
  const [linkUrl, setLinkUrl] = useState('')
  const [saving, setSaving] = useState(false)
  const [loadingScan, setLoadingScan] = useState(false)
  const [saveError, setSaveError] = useState(false)

  const updateLink = (index: number, value: string) => {
    const newLinks = [...links]
    newLinks[index] =  { ...newLinks[index], link: value }
    setLinks(newLinks)
  }

  const findInputIndex = (labelFound: string) => links.findIndex(element => element.id === labelFound)

  const reorder = (list: LinkItem[], startIndex: number, endIndex: number) => {
    const result = [...list]
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    } else if (result.destination.droppableId) {
      setLinks(reorder(links, result.source.index, result.destination.index))
    }
  }

  const onDeleteByIndex = (event: React.MouseEvent<HTMLElement>) => {
    const index = findInputIndex(event.currentTarget.id)
    const temporaryInputList = [...links]
    temporaryInputList[index].link = ''
    temporaryInputList.splice(index, 1)
    setLinks(temporaryInputList)
  }

  const getLinks = async () => {
    if (props.project?.id) {
      setLoadingScan(true)
      await getLinksFromApi(props.project.id)
        .then(item => {
          setLinks(item.linkItems)
          props.setProject(item)
        })
        .catch(() => {
          setSaveError(true)
        })
        .finally(() => setLoadingScan(false))
    }
  }

  const save = async () => {
    if (props.project) {
      setSaving(true)
      await savePartialProject({
        ...props.project, linkItems: links,
      })
        .then(item => {
          props.setProject(item)
        })
        .catch(() => {
          setSaving(false)
        })
        .finally(() => {
          setSaving(false)
          props.close()
        })
    }
  }

  const handleCloseToast = (event: Event | React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setSaveError(false)
  }

  useEffect(() => {
    if (props.project?.linkItems) {
      setLinks(props.project.linkItems)
    }
  }, [props.project?.linkItems])

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='sm'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Snackbar
        autoHideDuration={5000}
        message='Autoscan failed, please try again later'
        onClose={handleCloseToast}
        open={saveError}
      >
        <Alert
          onClose={handleCloseToast}
          severity='error'
          sx={{ width: '100%' }}
          variant='filled'
        >
          Autoscan failed, please try again later
        </Alert>
      </Snackbar>
      <Stack width={1}>
        <Stack alignItems='center' direction='row' justifyContent='space-between' padding={2} width={1}>
          <Typography variant='h3'>
            Edit Links
          </Typography>
          <Stack alignItems='center' direction='row' spacing={1}>
            {!props.project?.UPC || props.project.UPC.length === 0
              ? <div>
                <Tooltip title='Please add your UPC code to your project to scan for links'>
                  <div>
                    <LoadingButton
                      disabled={saving || !props.project?.UPC || props.project.UPC.length === 0}
                      loading={loadingScan}
                      onClick={() => getLinks()}
                      rounded
                      startIcon={<CloudSyncRoundedIcon />}
                      variant='contained'
                    >
                      Scan
                    </LoadingButton>
                  </div>
                </Tooltip>
              </div>
              : <LoadingButton
                disabled={saving || !props.project.UPC || props.project.UPC.length === 0}
                loading={loadingScan}
                onClick={() => getLinks()}
                rounded
                startIcon={<CloudSyncRoundedIcon />}
                variant='contained'
              >
                Scan
              </LoadingButton>}
            <IconButton onClick={() => props.close()}>
              <CloseRoundedIcon color='disabled' />
            </IconButton>
          </Stack>
        </Stack>
        <Stack paddingBottom={2} paddingX={2} width={1}>
          <Stack alignItems='center' direction='row' spacing={1} width={1}>
            <TextField
              autoComplete='new-password'
              fullWidth
              onChange={event => setLinkUrl(event.target.value)}
              placeholder='https://releese.io'
              sx={{ marginTop: 0 }}
              value={linkUrl}
            />
            <Button
              disabled={linkUrl.length === 0}
              onClick={() => {
                setLinks(old => [...old, { id: v4(), link: linkUrl }])
                setLinkUrl('')
              }}
              startIcon={<AddRoundedIcon />}
              sx={{
                flexShrink: 0,
              }}
            >
              Add link
            </Button>
          </Stack>

          <Stack alignItems='center' direction='column' spacing={1} width={1}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId='links' key='links'>
                {provided =>
                  <Stack
                    {...provided.droppableProps}
                    direction='column'
                    ref={provided.innerRef}
                    spacing={1}
                    width={1}
                  >
                    {links.map((element, index) =>
                      <Draggable draggableId={element.id} index={index} key={element.id}>
                        {providedDrag =>
                          <Stack
                            key={element.id}
                            paddingTop={2}
                            ref={providedDrag.innerRef}
                            {...providedDrag.draggableProps}
                            {...providedDrag.dragHandleProps}
                          >
                            <Card
                              variant='outlined'
                            >
                              <Stack
                                alignItems='flex-start'
                                direction='column'
                                padding={2}
                                spacing={1}
                                width={1}
                              >
                                <Stack
                                  alignItems='center'
                                  direction='row'
                                  justifyContent='space-between'
                                  spacing={2}
                                  width={1}
                                >
                                  <DragIndicatorRoundedIcon color='disabled' fontSize='medium' />
                                  <IconButton
                                    disableRipple
                                    sx={{
                                      backgroundColor: GetColorProjectLink(links[index].link),
                                      color: 'white!important',
                                      ':hover': {
                                        backgroundColor: GetColorProjectLink(links[index].link),
                                        cursor: 'default',
                                      },
                                      '& .MuiSvgIcon-root': {
                                        color: 'white!important',
                                      },
                                    }}
                                  >
                                    {GetIconLink(links[index].link)}
                                  </IconButton>
                                  <TextField
                                    autoComplete='new-password'
                                    fullWidth
                                    id={element.link}
                                    onChange={event => updateLink(index, event.target.value)}
                                    placeholder='Insert your link'
                                    sx={{
                                      marginTop: 0,
                                    }}
                                    value={(links[index].link)}
                                  />
                                  <IconButton id={element.id} onClick={onDeleteByIndex}>
                                    <CloseRoundedIcon />
                                  </IconButton>
                                </Stack>
                              </Stack>
                            </Card>
                          </Stack>}
                      </Draggable>)}
                    {provided.placeholder}
                  </Stack>}
              </Droppable>
            </DragDropContext>
          </Stack>
          <Stack direction='row' justifyContent='flex-end' paddingTop={2} spacing={1} width={1}>
            <Button onClick={props.close} variant='text'>
              Cancel
            </Button>
            <LoadingButton
              loading={saving}
              onClick={save}
              variant='contained'
            >
              Save
            </LoadingButton>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default EditLinksProjectModal
