import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import { Button, Stack, Typography, useTheme } from '@mui/material'
import Confetti from 'react-confetti'

import type Project from 'src/models/Project'

type Props = {
  close: () => void
  project?: Project
}

const PitchComplete = (props: Props) => {
  const themeItem = useTheme()
  const height = window.innerHeight
  const width = window.innerWidth
  const finish = () => {
    props.close()
  }
  return (
    <>
      <Confetti
        height={height}
        numberOfPieces={280}
        recycle={false}
        width={width}
      />
      <Stack
        alignItems='center'
        direction='column'
        height={1}
        justifyContent='center'
        minHeight={300}
        spacing={4}
        width='100%'
      >
        <CheckCircleOutlineRoundedIcon color='success' sx={{ fontSize: '144px' }} />
        <Typography color={themeItem.palette.text.secondary} variant='subtitle2'>
          {`You have succesfully pitched "${props.project?.title}"`}
        </Typography>
        <Button onClick={() => finish()}>
          Finish
        </Button>
      </Stack>
    </>
  )
}

export default PitchComplete
