/* eslint-disable max-len */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable max-lines */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable complexity */
/* eslint-disable react-hooks/exhaustive-deps */
import { AppBar, Box, Paper, Skeleton, Stack } from '@mui/material'

import FakeHeader from 'src/components/pages/Account/InitialSetup/FakeHeader'
import FakeReleaseCard from 'src/components/pages/Account/InitialSetup/FakeReleases'
import Content from 'src/components/pages/Menu/MenuContent'
import { IconNavLink } from 'src/components/pages/Menu/MenuNavLink'
import SPACING from 'src/styles/spacing'

const spacing = 7

const leftNavigationWidth = SPACING.themeSpacing(spacing)

const items = [
  {
    title: 'My New Release',
    url: 'https://storage.googleapis.com/releese-dev-bucket/fake-images/g0059479828d057c2b53b0a9cf519f8848a1779ec40a8351c2a1abc101f52c2439165e35b51520cd83792f177a6aa4860_1280.webp',
  },
  {
    title: 'The Wave',
    url: 'https://storage.googleapis.com/releese-dev-bucket/fake-images/g0e093830c278a87834c5af4a4ac024dec9edec62d1769b8e1a82df795e3c92b69054c5a854777bce126f458915fd34fa4d78ca83adbbba4aa29cfe5e7e47840b_1280.webp',
  },
  {
    title: 'It is Love',
    url: 'https://storage.googleapis.com/releese-dev-bucket/fake-images/g1f258e3aeef3937a270ad0ae71a9f390dfabbf5d677c498eea7350de781723cca3ec724c33394e388bbce1c732fb81618daec95745faf6db0efdf698f0c2ee63_1280.webp',
  },
  {
    title: 'Fantastic Items',
    url: 'https://storage.googleapis.com/releese-dev-bucket/fake-images/g26379ff2763c10b987d35b53f7123979e78961618c9783b4eb3cee8642e6d94bdfefa5a21979b6181df129c21fc2c50b067a6fe5511be6e098722ac98e92b4c0_1280.webp',
  },
  {
    title: 'Easter Egg',
    url: 'https://storage.googleapis.com/releese-dev-bucket/fake-images/g321822db03f2ec5b3070d32676281e9067ad57fd18306b02b95a069bac4ee8b2d5baab945977579ab543442b21fd1f5b0f71e71cd2f462ee7df8fe7373ab39b7_1280.webp',
  },
  {
    title: 'Lost And Found',
    url: 'https://storage.googleapis.com/releese-dev-bucket/fake-images/g35d791a08f33311cd0cd0254236ef755af41e58aac499c6c389cc0b6886d1e933ff104bb4ea933e3ef1bace6861d1d90a4a64a08cd9661928331fedb099a8afe_1280.webp',
  },
  {
    title: 'The Track',
    url: 'https://storage.googleapis.com/releese-dev-bucket/fake-images/g3e72fae1a6902bf7508865d467217b1f23d1869067758635571fc89a3bc368686c375cbe294c9929f134fd2d558d4707cf482ba9361380bd72ef43b762f9150e_1280.webp',
  },
  {
    title: 'My Latest Hit',
    url: 'https://storage.googleapis.com/releese-dev-bucket/fake-images/g4868a7bf5464674e4464afcc2c18feb138216c360c0f1cfeddfef62e0ed1f0aeedf7f2240ebed1346aaf820d7d676477f566cc4791b6f8c6f2a579482b9ceba4_1280.webp',
  },
  {
    title: 'WIP',
    url: 'https://storage.googleapis.com/releese-dev-bucket/fake-images/g4a3cdf84c0c54dc075c162f716ea9e613518958dbb907add1f88326e34d8570e24dc0576b7d14d9447e3544bb7d9b39bd3515d38a7a2182cda3d1a6dbc67ff08_1280.webp',
  },
  {
    title: 'My Car',
    url: 'https://storage.googleapis.com/releese-dev-bucket/fake-images/g4f9379acb5369e15d8bf194f372bd8bbc4b0498c01c4f77a0a220ededb4d16d0d6b8cc8f876edf02a4abaaceea4e702eea5a899408d1090d28fd6600e0e628cc_1280.webp',
  },
  {
    title: 'My New Release',
    url: 'https://storage.googleapis.com/releese-dev-bucket/fake-images/g581ea38f8c161b0964c61397787edd9eb0210fa66cf2ecd5a879e89a8a54550306844b169329162eac5b0a4c1e38a5d4_1280.webp',
  },
  {
    title: 'My New Release',
    url: 'https://storage.googleapis.com/releese-dev-bucket/fake-images/g58f3ad69b5bffa804db31156e3a6fdc2bf01e6c77cd1645b00d2aa4fb13138d1020310517707f96eed2da6d23039f703_1280.webp',
  },
  {
    title: 'My New Release',
    url: 'https://storage.googleapis.com/releese-dev-bucket/fake-images/g6338e38834b946660740ef8915ef225a02b4aaa1dfc36916124487bbd1c068661836cecac2f55e7f3d6eedec7583b8f507e3292ba39095085af89cab63ac1e2c_1280.webp',
  },
  {
    title: 'My New Release',
    url: 'https://storage.googleapis.com/releese-dev-bucket/fake-images/g775dddffd8bd31f9fbe1beef0b5694bb61cf527bf192444544a9a52baf0197b32d8aee19a7c1393f2cb35308883c4c0a542197887ec50d6c4e5c018406204bce_1280.webp',
  },
  {
    title: 'My New Release',
    url: 'https://storage.googleapis.com/releese-dev-bucket/fake-images/g841db869e5abf5a0285947f3050078229deb1a98c3d71a9e33df96aab8fb73f0110e69257837f1c7840b1c920f496eeec54cfb5acee9972a1fbd97f0224523ab_1280.webp',
  },
  {
    title: 'My New Release',
    url: 'https://storage.googleapis.com/releese-dev-bucket/fake-images/g8e8d5e14167520ae7bc4d2441156478b7688f0b2c8a33a35da2996514239864ab9bb3847cb36744a0cfdde731d301a23d3486ce1f09dc54196ccb289aaf7bbb2_1280.webp',
  },
  {
    title: 'My New Release',
    url: 'https://storage.googleapis.com/releese-dev-bucket/fake-images/g8f058558eb132f84a9b26535f97c3ce3d8e10ad3494ca792d380ef3b807e88b5b1fa0ee418af597c76b43f2dc6e1805d922d88449c686ed68e8b05d027682632_1280.webp',
  },
  {
    title: 'My New Release',
    url: 'https://storage.googleapis.com/releese-dev-bucket/fake-images/g96133e82f2b0b3db7ac3088f2d894257bda9265a3b4e168f053c68e3db7884388242bf85aec0f131d32c411bc410d6ebd925a1277648c5a468ce424f5b6f3b88_1280.webp',
  },
  {
    title: 'My New Release',
    url: 'https://storage.googleapis.com/releese-dev-bucket/fake-images/g9adb94fd720104812109eeda5024d85d3cb81bc74225c4a9b0fee86f0534f9ccc64ced6206beaf9b8f241ec2a602d6e1bf46f01e500f7f569b1ea4cc42799e15_1280.webp',
  },
  {
    title: 'My New Release',
    url: 'https://storage.googleapis.com/releese-dev-bucket/fake-images/g9adb94fd720104812109eeda5024d85d3cb81bc74225c4a9b0fee86f0534f9ccc64ced6206beaf9b8f241ec2a602d6e1bf46f01e500f7f569b1ea4cc42799e15_1280.webp',
  },
  {
    title: 'My New Release',
    url: 'https://storage.googleapis.com/releese-dev-bucket/fake-images/ga06c149e978d9b2f400543d2546e85174d23cdce6a04f3424c614f3eeaea1110a182df1db81f7e083506eb66289a61e9ee7ec6e2275d9e70623e3ed09f1a2db0_1280.webp',
  },
  {
    title: 'My New Release',
    url: 'https://storage.googleapis.com/releese-dev-bucket/fake-images/gb37c6014943d4cf086f4a6f2ceff57166cb9a22f1dc3b82df78971159f9d94e8824468658eb17c9ee7c535a7da7f505c_1280.webp',
  },
  {
    title: 'My New Release',
    url: 'https://storage.googleapis.com/releese-dev-bucket/fake-images/gcd4a172ea7b7886eb39cb0b5956ab60c11bd9bc6bffd5ad9c8c35ecee1364fc76f55b5dd8450c6b07699ef829abd4aa8faa372221ac562ce61030dac947c9a6f_1280.webp',
  },
  {
    title: 'My New Release',
    url: 'https://storage.googleapis.com/releese-dev-bucket/fake-images/gcfbfcca800da691819772cd05f5f27534cd1d5482f1d0f76bc3106be5f51226eb0a9952af0a6f06a4af47e7083bbdbc7704bcf8c1dc1fb26be47427e0ae6458c_1280.webp',
  },
  {
    title: 'My New Release',
    url: 'https://storage.googleapis.com/releese-dev-bucket/fake-images/gcfbfcca800da691819772cd05f5f27534cd1d5482f1d0f76bc3106be5f51226eb0a9952af0a6f06a4af47e7083bbdbc7704bcf8c1dc1fb26be47427e0ae6458c_1280.webp',
  },
  {
    title: 'My New Release',
    url: 'https://storage.googleapis.com/releese-dev-bucket/fake-images/gd7cbb5f1aac79fd76ca1c15a2f3df5d813ff35a55a4f190d4d815842b0a8db6cfb5fb6ed24340aff6d4adb37da130dac2a60b49958e43bdef10877154ea8109b_1280.webp',
  },
]

const FakeUi = () => <Stack
  height={1}
  overflow='hidden'
  sx={{
    pointerEvents: 'none',
  }}
>
  <FakeHeader
    leftNavigationWidth={leftNavigationWidth}
  />
  <Stack
    direction='row'
    height={1}
    overflow='hidden'
  >
    <Stack
      alignItems='center'
      display={{
        xl: 'flex',
        lg: 'flex',
        md: 'flex',
        sm: 'none',
        xs: 'none',
      }}
      flexShrink={0}
      height={1}
      spacing={2}
      width={leftNavigationWidth}
    >
      <AppBar
        elevation={3}
        sx={{
          width: leftNavigationWidth,
          top: leftNavigationWidth,
          left: 0,
          flexShrink: 0,
          paddingRight: '0px!important',
          zIndex: 500,
          background: 'transparent',
        }}
      >
        <Stack
          alignItems='center'
          flexShrink={0}
          maxWidth={leftNavigationWidth}
          paddingTop={1}
          spacing={2}
        >
          <IconNavLink
            size='small'
            to='/'
            tooltip='Dashboard'
          >
            <Skeleton animation={false} height={32} variant='circular' width={32} />
          </IconNavLink>

          <IconNavLink
            size='small'
            to='/message'
            tooltip='Messages'
          >
            <Skeleton animation={false} height={32} variant='circular' width={32} />
          </IconNavLink>

          <IconNavLink
            id='projects'
            size='small'
            to='/projects/projects'
            tooltip='Projects'
          >
            <Skeleton animation={false} height={32} variant='circular' width={32} />
          </IconNavLink>

          <IconNavLink
            size='small'
            to='/calendar'
            tooltip='Tasks'
          >
            <Skeleton animation={false} height={32} variant='circular' width={32} />
          </IconNavLink>

          <IconNavLink
            size='small'
            to='/links'
            tooltip='Landing Pages'
          >
            <Skeleton animation={false} height={32} variant='circular' width={32} />
          </IconNavLink>

          <IconNavLink
            size='small'
            to='/earnings/overview'
            tooltip='Earnings'
          >
            <Skeleton animation={false} height={32} variant='circular' width={32} />
          </IconNavLink>

          <IconNavLink
            size='small'
            to='/analytics/overview'
            tooltip='Analytics'
          >
            <Skeleton animation={false} height={32} variant='circular' width={32} />
          </IconNavLink>

          <IconNavLink
            size='small'
            to='/rights/overview'
            tooltip='Rights'
          >
            <Skeleton animation={false} height={32} variant='circular' width={32} />
          </IconNavLink>

          <IconNavLink
            size='small'
            to='/distribution/overview'
            tooltip='Distribution'
          >
            <Skeleton animation={false} height={32} variant='circular' width={32} />
          </IconNavLink>

          <IconNavLink
            size='small'
            to='/wallet'
            tooltip='Wallet'
          >
            <Skeleton animation={false} height={32} variant='circular' width={32} />
          </IconNavLink>
        </Stack>
      </AppBar>
    </Stack>

    <Content
      sx={{
        height: 1,
        overflow: 'visible',
        borderRadius: {
          xl: 1,
          lg: 1,
          md: 1,
          sm: 0,
          xs: 0,
        },
        width: `calc(100% - ${leftNavigationWidth})`,
        backgroundColor: theme => theme.palette.background.input }}
    >
      <Box height='100%' overflow='hidden'>
        <Stack height={1} padding={1.5} width={1}>
          <Paper
            elevation={0}
            sx={{
              width: 1,
              height: 1,
              overflow: 'hidden',
            }}
          >
            <Stack alignItems='center' direction='row' flexWrap='wrap' justifyContent='center' marginX='auto'>
              {items.map(item =>
                <FakeReleaseCard
                  key={item.title}
                />)}
            </Stack>
          </Paper>
        </Stack>
      </Box>
    </Content>
  </Stack>
</Stack>

export default FakeUi
