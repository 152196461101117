import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded'
import { LoadingButton } from '@mui/lab'
import { Button, Stack, Typography } from '@mui/material'
import type { FirebaseError } from 'firebase/app'
import { applyActionCode } from 'firebase/auth'
import { useState } from 'react'

import Loading from 'src/components/Loading'
import { NavLink } from 'src/components/SafeRouterLink'
import firebaseErrorMessages from 'src/data/firebaseAuthErrorCodes'
import { auth } from 'src/utils/firebase'

type Props = {
  actionCode: string
}

const RevertSecondFactor = (props: Props) => {
  const [loading, setLoading] = useState(false)
  const [errorFB, setErrorFB] = useState<string>()
  const [success, setSuccess] = useState(false)
  const [validCode, setValidCode] = useState(false)
  const [verifiedCode, setVerifiedCode] = useState(false)

  const handleRevokeMfa = () => {
    setLoading(true)
    applyActionCode(auth, props.actionCode)
      .then(() => {
        setSuccess(true)
        setLoading(false)
      })
      .catch((error: FirebaseError) => {
        setErrorFB(firebaseErrorMessages.find(errorCode => errorCode.code === error.code)?.message)
        setLoading(false)
      })
  }

  applyActionCode(auth, props.actionCode)
    .then(() => {
      setValidCode(true)
      setVerifiedCode(true)
    })
    .catch((error: FirebaseError) => {
      setErrorFB(firebaseErrorMessages.find(errorCode => errorCode.code === error.code)?.message)
      setVerifiedCode(true)
    })

  if (!verifiedCode) {
    return <Loading />
  } else if (validCode && !success) {
    return (
      <Stack alignItems='flex-start' spacing={3}>
        <Typography variant='h3'>Revoke second factor</Typography>
        <Stack alignItems='flex-start' spacing={2} width={1}>
          <Typography variant='body1'>By revoking your second factor, your account will be less secure</Typography>
          <LoadingButton
            fullWidth
            loading={loading}
            onClick={handleRevokeMfa}
            variant='contained'
          >
            Revoke
          </LoadingButton>
        </Stack>
      </Stack>
    )
  } else if (success) {
    return (
      <Stack alignItems='center' spacing={2} >
        <CheckCircleRoundedIcon color='success' sx={{ height: 64, width: 64 }} />
        <Typography variant='body1'>Your second factor has been revoked</Typography>
        <Button component={NavLink} fullWidth to='/account/login'>Finish</Button>
      </Stack>
    )
  } else {
    return (
      <Stack alignItems='center' spacing={2}>
        <ErrorRoundedIcon color='error' sx={{ height: 64, width: 64 }} />
        <Typography variant='h3'>An error has occured</Typography>
        <Typography variant='body1'>Please try again</Typography>
        <Typography color='error' variant='body1'>{errorFB}</Typography>
        <Button component={NavLink} fullWidth to='/account/login'>Finish</Button>
      </Stack>
    )
  }
}
export default RevertSecondFactor
