
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import { Card, IconButton, Skeleton, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

import { getPaymentMethodStatus } from 'src/api/payout'
import DeletePayoutMethodModal from 'src/components/pages/Wallet/PayoutMethods/DeletePayoutMethodModal'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { PayoutMethod, WisePaymentMethodObject } from 'src/models/Payout'

type Props = {
  payoutMethod: PayoutMethod
  deletePaymentMethod: (wiseId: string) => Promise<void>
}

const PayoutMethodTableItem = (props: Props) => {
  const { currentOrganisation } = useAuth()

  const [deleteModal, setDeleteModal] = useState(false)
  const [paymentItem, setPaymentItem] = useState<WisePaymentMethodObject>()

  useEffect(() => {
    void getPaymentMethodStatus(props.payoutMethod.id)
      .then(setPaymentItem)
  }, [currentOrganisation?.id, props.payoutMethod.id])

  return (
    <>
      <DeletePayoutMethodModal
        close={() => setDeleteModal(false)}
        deletePaymentMethod={props.deletePaymentMethod}
        open={deleteModal}
        wiseId={paymentItem?.id ?? ''}
      />
      {paymentItem
        ? <Card
          elevation={0}
          sx={{
            background: theme => theme.palette.background.elevatedCard,
            backdropFilter: 'blur(25px)',
            width: 300,
          }}
          variant='outlined'
        >
          <Stack alignItems='flex-start' direction='column' padding={2}>
            <Typography
              variant='body2'
            >
              {props.payoutMethod.name}
            </Typography>
            <Stack direction='row' justifyContent='space-between' width={1}>
              <Typography
                variant='h2'
              >
                <AccountBalanceRoundedIcon sx={{ marginTop: '-4px' }} />
                {` ${paymentItem.currency}`}
              </Typography>
              <IconButton
                color='default'
                onClick={() => setDeleteModal(true)}
                size='small'
              >
                <ClearRoundedIcon />
              </IconButton>
            </Stack>

            <Typography
              sx={{
                textTransform: 'capitalize',
                color: theme => theme.palette.text.label,
              }}
              variant='body2'
            >
              {paymentItem.type}
            </Typography>
            <Typography
              lineHeight={1.2}
              variant='body1'
            >
              {paymentItem.longAccountSummary}
            </Typography>
          </Stack>
        </Card>
        : <Card
          elevation={0}
          sx={{
            background: theme => theme.palette.background.elevatedCard,
            backdropFilter: 'blur(25px)',
            width: 300,
          }}
          variant='outlined'
        >
          <Stack alignItems='flex-start' direction='column' padding={2}>
            <Typography
              variant='body2'
            >
              {props.payoutMethod.name}
            </Typography>
            <Stack direction='row' justifyContent='space-between' width={1}>
              <Typography
                sx={{
                  width: 1,
                }}
                variant='h2'
              >
                <Skeleton sx={{ width: '100%' }} />
              </Typography>
              <IconButton
                color='default'
                size='small'
              >
                <ClearRoundedIcon />
              </IconButton>
            </Stack>

            <Typography
              sx={{
                textTransform: 'capitalize',
                color: theme => theme.palette.text.label,
                width: 1,
              }}
              variant='body2'
            >
              <Skeleton sx={{ width: '100%' }} />
            </Typography>
            <Typography
              lineHeight={1.2}
              sx={{ width: 1 }}
              variant='body1'
            >
              <Skeleton sx={{ width: '100%' }} />
            </Typography>
          </Stack>
        </Card>}
    </>
  )
}

export default PayoutMethodTableItem

