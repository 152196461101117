import CloseIcon from '@mui/icons-material/Close'
import { Button, Dialog, IconButton, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import type { ChangeEvent } from 'react'
import { useState } from 'react'
import { v4 } from 'uuid'

import { updateTaskboardGroup } from 'src/api/taskboards'
import { sendWorkspaceUpdate } from 'src/api/webSocket'
import type { TaskboardGroup } from 'src/models/Taskboard'

type Props = {
  close: () => void
  open: boolean
  group: TaskboardGroup
}

const EditTaskGroupModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  const [newGroup, setNewGroup] = useState(props.group)
  const [groupName, setGroupName] = useState(props.group.title)

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setGroupName(event.target.value)
    newGroup.title = event.target.value
    setNewGroup(newGroup)
  }

  const handleClick = () => {
    if (newGroup.id) {
      void updateTaskboardGroup({ id: newGroup.id, ...newGroup })
        .then(() => sendWorkspaceUpdate(v4()))
      props.close()
      setGroupName('')
    }
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='xs'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Stack paddingX={3} paddingY={3} spacing={2} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          width='100%'
        >
          <Typography variant='h3'>Edit Folder</Typography>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>
        <Stack width='100%'>
          <TextField
            fullWidth
            inputProps={{ maxLength: 50 }}
            label='Folder name'
            onChange={handleNameChange}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                handleClick()
              }
            }}
            placeholder='Name this folder'
            value={groupName}
          />

        </Stack>
        <Stack direction='row' justifyContent='flex-end' spacing={1} width={1}>
          <Button onClick={props.close} variant='text' >
            Cancel
          </Button>
          <Button onClick={() => handleClick()} >
            Save
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default EditTaskGroupModal

