/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable react-hooks/exhaustive-deps */
import type { FC } from 'react'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import type { Socket } from 'socket.io-client'
import useSound from 'use-sound'

import { createNewConversation, getConversation, getConversations, getMessages } from 'src/api/conversations'
import type { ClientToServerEvents, ServerToClientEvents } from 'src/api/webSocket'
import { initializeConversations, newConversation, refreshConversations, sendMessageToConversation, setMessageToRead, subscribeToMessages, subscribeToNewConversations, subscribeToReadReceipts } from 'src/api/webSocket'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { Conversation, NewConversation } from 'src/models/Conversation'
import type { MessageDto } from 'src/models/Message'
import { Message } from 'src/models/Message'
import messageSound from 'src/sounds/messagesSound.mp3'

type MessengerProviderContextProps = {
  newConversationItem: NewConversation | null | undefined
  conversations: Conversation[]
  currentConversation: Conversation | null
  currentConversationMessages: Message[]
  conversationLoading: boolean
  createConversation: (conversation: NewConversation) => Promise<void>
  searchConversations: (query: string) => void
  fetchMessageBatch: (conversation: Conversation, oldMessages: Message[]) => Promise<void>
  fetchConversations: () => Promise<void>
  selectConversation: (conversation: Conversation) => void
  sendMessage: (content: string) => void
  readMessage: (messageId: string) => void
  setCurrentConversation: (conversation: Conversation | null) => void
}

export type InitProps = Omit<MessengerProviderContextProps,
'conversations' | 'currentConversation' | 'currentConversationMessages' | 'newConversation'> & {
  newConversationItem: MessengerProviderContextProps['newConversationItem'] | null | undefined
  currentConversation: MessengerProviderContextProps['currentConversation'] | null
  conversations: MessengerProviderContextProps['conversations'] | []
  currentConversationMessages: MessengerProviderContextProps['currentConversationMessages'] | []
  conversationLoading: MessengerProviderContextProps['conversationLoading']
}

const initialValue = {
  newConversationItem: null,
  currentConversation: null,
  conversations: [],
  currentConversationMessages: [],
  conversationLoading: true,
} as InitProps

const MessengerContext = createContext(initialValue as MessengerProviderContextProps)

export const useMessenger = () => useContext(MessengerContext)

export const MessengerProvider: FC = props => {
  const [playMessageSound] = useSound(messageSound)
  const { currentAccount, currentOrganisation } = useAuth()

  const [newConversationItem, setNewConversationItem] = useState<InitProps['newConversationItem']>()
  const [currentConversation, setCurrentConversation] = useState<InitProps['currentConversation']>()
  const [conversations, setConversations] = useState<InitProps['conversations']>([])
  const [currentConversationMessages, setCurrentConversationMessages] =
    useState<InitProps['currentConversationMessages']>([])
  const [conversationLoading, setConversationLoading] = useState(true)
  const [latestMessage, setLatestMessage] = useState<Message>()
  const [messageToRead, setMessageToReadSocket] = useState<Message>()
  const [socket, setSocket] = useState<Socket<ServerToClientEvents, ClientToServerEvents> | null>(null)

  const [refresh, setRefresh] = useState('')

  const getListenersWithRefresh = async () => {
    await initializeConversations()
      .then(socketItem => {
        setSocket(socketItem)
        subscribeToMessages(socketItem, (error: unknown, data?: MessageDto) => {
          if (error) return

          if (data) {
            setLatestMessage(new Message(data))
          }
        })
        subscribeToReadReceipts(socketItem, (error: unknown, data?: MessageDto) => {
          if (error) return

          if (data) {
            setMessageToReadSocket(new Message(data))
          }
        })
        subscribeToNewConversations(socketItem, (error: unknown, data?: string) => {
          if (error) return

          if (data) {
            setRefresh(data)
          }
        })
        socketItem?.io.once('reconnect', async () => {
          socketItem?.removeAllListeners('message')
          socketItem?.removeAllListeners('read')
          socketItem?.removeAllListeners('refreshConversations')
          await getListenersWithRefresh()
        })
      })
  }

  const subscribe = async () => {
    await fetchConversations()
      .then(() => void getListenersWithRefresh())
  }

  useEffect(() => {
    fetchConversations()
      .then(() => void subscribe())
      .finally(() => setConversationLoading(false))
  }, [currentOrganisation?.id])

  useEffect(() => {
    if (socket && refresh.length > 0) {
      refreshConversations(socket)
      void fetchConversations()
    }
  }, [refresh])

  useEffect(() => {
    if (latestMessage) {
      if (latestMessage.conversationId === currentConversation?.id && latestMessage.senderId !== currentAccount.id) {
        setCurrentConversationMessages(previousState => [latestMessage, ...previousState])
      }
      if (latestMessage.senderId !== currentAccount.id) {
        playMessageSound()
      }
      const foundConvoIndex = conversations.findIndex(convo => convo.id === latestMessage.conversationId)
      const temporaryConvo = conversations[foundConvoIndex]
      temporaryConvo.lastMessage = latestMessage
      conversations.splice(foundConvoIndex, 1)
      setConversations(previousState => [temporaryConvo, ...previousState])

      if (foundConvoIndex === -1) {
        void getConversation(latestMessage.conversationId)
          .then(convo => setConversations(previousState => [convo, ...previousState]))
      }
    }
  }, [latestMessage])

  useEffect(() => {
    if (messageToRead &&
        messageToRead.conversationId === currentConversation?.id &&
        currentConversationMessages &&
        currentConversationMessages[0] &&
        currentConversationMessages[0].id === messageToRead.id) {
      setCurrentConversationMessages(previousState => {
        const newarray = [...previousState]
        newarray.splice(0, 1)
        return [messageToRead, ...newarray]
      })
    }
  }, [messageToRead])

  const fetchConversations = () =>
    getConversations()
      .then(converationsFetched => setConversations(converationsFetched.sort((a, b) =>
        +b.lastMessage.createdAt - +a.lastMessage.createdAt)))

  const createConversation = async (conversation: NewConversation) => {
    await createNewConversation(conversation)
      .then(convo => {
        setNewConversationItem(null)
        setConversations([convo, ...conversations])
        setCurrentConversation(convo)
        newConversation(convo.id)
      })
  }

  const sendMessage = (content: string) => {
    const temporaryId = `${currentConversationMessages.length > 0
      ? currentConversationMessages[0].id
      : 'first'}-staging`

    const message = new Message({
      conversationId: currentConversation?.id,
      content,
      senderId: currentAccount.id,
      id: temporaryId,
      status: 'SENDING',
      createdAt: new Date().toString(),
    })
    setCurrentConversationMessages(previousState => [message, ...previousState])

    sendMessageToConversation(message, (error: unknown, data?: MessageDto) => {
      if (error) return
      if (data) {
        setLatestMessage(message)
        const alteredConversationsList = currentConversationMessages
          .filter(messages => messages.id !== temporaryId)
        setCurrentConversationMessages([new Message(data), ...alteredConversationsList])
      }
    })
  }

  const searchConversations = (query: string) => {
    setConversations(conversations.filter(conversation =>
      conversation.lastMessage.content.toLowerCase().includes(query.toLowerCase())).sort((a, b) =>
      +b.updatedAt - +a.updatedAt))
    setConversationLoading(false)
  }

  const fetchMessageBatch = (conversation: Conversation, oldMessages: Message[]) =>
    getMessages(conversation.id, oldMessages.length > 0
      ? oldMessages.slice(-1)[0].createdAt : new Date())
      .then(messages => {
        setCurrentConversationMessages(previousState => [...previousState, ...messages])
      })

  const selectConversation = (conversation: Conversation) => {
    if (conversation !== currentConversation) {
      setCurrentConversationMessages([])
      setCurrentConversation(conversation)
    }
  }

  const readMessage = (messageId: string) => {
    const updatedMessages =
    currentConversationMessages.map(message => message.id === messageId
      ? { ...message, readByAccountIds: [currentAccount.id, ...message.readByAccountIds] }
      : message)
    setMessageToRead(messageId)
    if (currentConversation) {
      const temporaryConversation = currentConversation
      temporaryConversation.lastMessage.readByAccountIds =
        [currentAccount.id, ...temporaryConversation.lastMessage.readByAccountIds]
      setCurrentConversation(temporaryConversation)
      const temporaryConversationsList = conversations
      const index = temporaryConversationsList.findIndex(convo => convo.id === temporaryConversation.id)
      temporaryConversationsList[index] = temporaryConversation
      setConversations(temporaryConversationsList)
    }
    setCurrentConversationMessages(updatedMessages)
  }

  const value = useMemo<InitProps>(
    () => ({
      fetchConversations,
      createConversation,
      searchConversations,
      fetchMessageBatch,
      selectConversation,
      sendMessage,
      readMessage,
      setCurrentConversation,
      newConversationItem: newConversationItem ?? null,
      currentConversation: currentConversation ?? null,
      conversations,
      currentConversationMessages,
      conversationLoading,
    }),
    [
      newConversationItem,
      currentConversation,
      conversations,
      currentConversationMessages,
      conversationLoading]
  ) as MessengerProviderContextProps

  return (
    <MessengerContext.Provider value={value}>
      {props.children}
    </MessengerContext.Provider>
  )
}
