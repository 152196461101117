export const everyNminutes = (timeGap: number) => {
  const result = []
  for (let hours = 0; hours < 24; hours++) {
    for (let minutes = 0; minutes < 60; minutes += timeGap) {
      let hour = ''
      let minute = ''
      hour = hours < 10 ? `0${hours}` : hours.toString()
      minute = minutes < 10 ? `0${minutes}` : minutes.toString()
      result.push(`${hour}:${minute}`)
    }
  }
  return result
}
