/* eslint-disable id-length */
/* eslint-disable @typescript-eslint/no-magic-numbers */
import { Stack, Typography, useTheme } from '@mui/material'
import type { ApexOptions } from 'apexcharts'
import { useRef } from 'react'
import Chart from 'react-apexcharts'

import { useContainerDimensions } from 'src/components/ContainerDimensions'
import { GetColorLink } from 'src/components/pages/SmartLink/Content/GetColorLink'
import type { AnalyticsMonthlyChart } from 'src/models/Analytics'

type Props = {
  data: AnalyticsMonthlyChart[]
}

const DistributionStoreChart = (props: Props) => {
  const theme = useTheme()

  const seriesStores = props.data.map(item => item.streams)
  const labelsStores = props.data.map(item =>
    item._id.slice(0, Math.max(0, item._id.includes(':') ? item._id.indexOf(':') : item._id.length)))

  const colors = props.data.map(item =>
    GetColorLink(item._id.slice(0, Math.max(0, item._id.includes(':') ? item._id.indexOf(':') : item._id.length))))

  const optionsStores: ApexOptions = {
    chart: {
      foreColor: theme.palette.text.primary,
    },
    colors,
    fill: {
      colors,
    },
    series: seriesStores,
    labels: labelsStores,
    dataLabels: {
      enabled: false,
      style: {
        colors,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: '55%',
          labels: {
            show: false,
          },
        },
      },
    },
    stroke: {
      colors: ['transparent'],
    },
    legend: {
      fontFamily: 'Inter',
      width: 150,

    },
  }

  const componentRef = useRef<HTMLDivElement>(null)
  const { width } = useContainerDimensions(componentRef)

  return (
    <Stack paddingTop={2} ref={componentRef} width={1}>
      <Stack direction='row' justifyContent='space-between' width={1}>
        <Typography variant='h3'>
          Stores
        </Typography>
      </Stack>
      <Stack alignItems='center' width={1} >
        <Chart
          height={400}
          labels={labelsStores}
          options={optionsStores}
          series={seriesStores}
          type='donut'
          width={width}
        />
      </Stack>
    </Stack>
  )
}
export default DistributionStoreChart

