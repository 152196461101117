import type { ChipProps } from '@mui/material'

import type { Serialized } from 'src/types/react-app-env'

type InvoiceStatus = 'draft' | 'open' | 'paid' | 'uncollectible' | 'void'

export const statusColor: Record<InvoiceStatus, ChipProps['color']> = {
  draft: undefined,
  open: 'info',
  paid: 'success',
  uncollectible: 'error',
  void: 'error',
}

export class Invoice {
  created: Date
  customer_email = ''
  description = ''
  hosted_invoice_url = ''
  status: InvoiceStatus = 'void'
  subscription = ''
  total = 0
  subtotal = 0
  tax = 0

  constructor(dto: InvoiceDto) {
    Object.assign(this, dto)
    this.created = new Date(dto.created * 1000)
    this.total /= 100
    this.subtotal /= 100
    this.tax /= 100
  }
}

export type InvoiceDto = Partial<Serialized<Invoice>> & { created: number }

export class PaymentMethod {
  id: string
  brand: string
  expirationMonth: number
  expirationYear: number
  last4: string
  country: string

  constructor(dto: PaymentMethodDto) {
    this.id = dto.id ?? ''
    this.brand = dto.brand ?? ''
    this.expirationMonth = dto.expirationMonth ?? 0
    this.expirationYear = dto.expirationYear ?? 0
    this.last4 = dto.last4 ?? ''
    this.country = dto.country ?? ''

    Object.assign(this, dto)
  }
}

export type PaymentMethodDto = Partial<Serialized<PaymentMethod>>
