/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable no-useless-escape */
/* eslint-disable unicorn/no-unsafe-regex */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete, Box, Card, InputAdornment, Stack, TextField, Typography } from '@mui/material'

import MarketingLinkTagsField from 'src/components/form-elements/MarketingLinkTagsField'
import ProjectItem from 'src/components/form-elements/ProjectItem'
import type { MarketingLink } from 'src/models/Marketing'
import type Project from 'src/models/Project'

type Props = {
  smartLinkData: MarketingLink
  setLinkData: React.Dispatch<React.SetStateAction<MarketingLink>>
  saveError: string
  projects: Project[] | undefined
  project: Project | undefined
  setProject: React.Dispatch<React.SetStateAction<Project | undefined>>
}

const EditLinkAdvancedSettings = (props: Props) => {
  const handleTagsChange = (values: string[]) => {
    props.setLinkData(old => ({ ...old, tags: values }))
  }

  const findProject = (valueFound: string) => props.projects?.find(element => element.id === valueFound)

  const handleProjectChange = (event: string) => {
    const test = event

    const itemFound = findProject(test)

    if (itemFound) {
      props.setProject(itemFound)
      props.setLinkData(old => ({ ...old, projectId: itemFound.id }))
    } else {
      props.setProject(undefined)
      props.setLinkData(old => ({ ...old, projectId: '' }))
    }
  }

  return (
    <Stack width={1}>
      <Stack
        alignItems='flex-start'
        direction='row'
        justifyContent='space-between'
        paddingTop={2}
        width={1}
      >
        <Typography variant='h3'>
          Advanced settings
        </Typography>
      </Stack>
      <Card
        variant='outlined'
      >
        <Stack direction='column' padding={2} spacing={2} width={1}>
          <Stack>
            <Typography lineHeight={1.4} variant='h4'>
              Details
            </Typography>
            <Typography color='text.secondary' variant='body1'>
              Add your landing page to a project to share analytics with your collaborators
            </Typography>
          </Stack>
          <Stack direction='column' spacing={2}>
            <Stack direction='row' justifyContent='space-between' paddingTop={2} spacing={2}>
              <MarketingLinkTagsField
                setInputSelected={handleTagsChange}
                tags={props.smartLinkData.tags}
              />
            </Stack>
            <Stack direction='row' justifyContent='space-between' paddingTop={2} spacing={2}>
              {props.projects &&
                ((props.smartLinkData.projectId && props.project) || !props.smartLinkData.projectId) &&
                <Autocomplete
                  autoHighlight
                  autoSelect
                  fullWidth
                  getOptionLabel={option => option.title}
                  onChange={(_, newValue) => handleProjectChange(newValue?.id ?? '')}
                  options={props.projects}
                  renderInput={params =>
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: <InputAdornment position='start'>
                          <ProjectItem
                            project={props.project}
                          />
                        </InputAdornment>,
                      }}
                      autoComplete='new-password'
                      error={props.saveError.length > 0}
                      helperText={props.saveError}
                      label='Release'
                      placeholder='Select a release'
                      sx={{
                        marginTop: 0,
                      }}
                    />}
                  renderOption={(renderProps, option) =>
                    <Box component='li' {...renderProps} >
                      <Stack alignItems='center' direction='row' spacing={1}>
                        <ProjectItem
                          project={option}
                        />
                        <Typography
                          sx={{ color: theme => theme.palette.text.secondary }}
                          variant='body2'
                        >
                          {option.title}
                        </Typography>
                      </Stack>
                    </Box>}
                  sx={{ marginTop: 0, height: 42 }}
                  value={props.project}
                />}
            </Stack>
          </Stack>
        </Stack>
      </Card>
    </Stack>
  )
}
export default EditLinkAdvancedSettings
