/* eslint-disable unicorn/filename-case */
import type { ExitBreakPlugin } from '@udecode/plate'
import { KEYS_HEADING } from '@udecode/plate'

import type { MyPlatePlugin } from 'src/components/pages/Notes/Modern/typescript/plateTypes'

export const exitBreakPlugin: Partial<MyPlatePlugin<ExitBreakPlugin>> = {
  options: {
    rules: [
      {
        hotkey: 'mod+enter',
      },
      {
        hotkey: 'mod+shift+enter',
        before: true,
      },
      {
        hotkey: 'enter',
        query: {
          start: true,
          end: true,
          allow: KEYS_HEADING,
        },
      },
    ],
  },
}
