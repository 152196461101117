/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTheme } from '@mui/material'
import type { ComboboxItemProps, PlateEditor, TComboboxItem } from '@udecode/plate'
import { insertNode, insertTable } from '@udecode/plate'
import { MentionCombobox } from '@udecode/plate-ui-mention'
import { useCallback, useEffect, useState } from 'react'

import { getOrganisation } from 'src/api/organisation'
import type { BlockItem } from 'src/components/pages/Notes/Modern/balloon-toolbar/SelectBlockType'
import { defaultCommands } from 'src/components/pages/Notes/Modern/commands/CommandItems'
import SelectItems from 'src/components/pages/Notes/Modern/commands/SelectItems'
import { fourTable, newTable, threeTable } from 'src/components/pages/Notes/Modern/dnd/DragHandleItem'
import { BLOCK_TYPES } from 'src/components/pages/Notes/Modern/mention/mentionables'
import SelectMention from 'src/components/pages/Notes/Modern/mention/SelectMention'
import type { MyValue } from 'src/components/pages/Notes/Modern/typescript/plateTypes'
import { toggleBlock } from 'src/components/pages/Notes/Plugins/Block'
import { useAuth } from 'src/components/providers/AuthProvider'
import type Account from 'src/models/Account'
import type Project from 'src/models/Project'

const onItemSelected = (editorObject: PlateEditor<MyValue>, item: TComboboxItem<any>) => {
  if (defaultCommands.includes(item.key)) {
    toggleBlock(editorObject as unknown as PlateEditor, item.key as BlockItem)
    editorObject.deleteBackward('line')
    editorObject.deleteBackward('character')
  } else switch (item.key) {
    case 'taskboard':
      insertNode(editorObject, { type: 'taskboard', children: [{ text: '' }] })
      break
    case 'table2x2':
      insertTable(editorObject, newTable)
      break
    case 'table3x3':
      insertTable(editorObject, threeTable)
      break
    case 'table4x4':
      insertTable(editorObject, fourTable)
      break
    case 'divider':
      insertNode(editorObject, { type: 'divider', children: [{ text: '' }] })
      break
    case 'youtube':
      insertNode(editorObject, { type: 'iframeplugin', children: [{ text: '' }] })
      break
    case 'image':
      insertNode(editorObject, { type: 'cloud', children: [{ text: '' }] })
      break
    case 'video':
      insertNode(editorObject, { type: 'iframeplugin', children: [{ text: '' }] })
      break
  // No default
  }
}

type Props = {
  project?: Project
}

const MentionComponents = (props: Props) => {
  const theme = useTheme()
  const { currentOrganisation } = useAuth()
  const [members, setMembers] = useState<Account[]>([])

  useEffect(() => {
    setMembers([])
    if (props.project) {
      void Promise.all(props.project.collaborators.map(member =>
        void getOrganisation(member.account.id)
          .then(account => account?.seats.map(seat =>
            setMembers(previousState =>
              previousState.some(item => item.id === seat.account.id)
                ? [...previousState]
                : [...previousState, seat.account])))))
    } else if (currentOrganisation) {
      for (const seat of currentOrganisation.seats) {
        setMembers(previousState =>
          previousState.some(item => item.id === seat.account.id)
            ? [...previousState]
            : [...previousState, seat.account])
      }
    }
  }, [props.project, currentOrganisation])

  const mentionableMembers: TComboboxItem[] =
    members.map(item => ({ key: item.id, text: `${item.firstName} ${item.lastName}` }))

  const renderItem = useCallback((propsItems: ComboboxItemProps<undefined>) =>
    <SelectItems {...propsItems} />, [])

  const renderMention = useCallback((propsItems: ComboboxItemProps<undefined>) =>
    <SelectMention item={propsItems} members={members} />, [members])

  return (
    <>
      <MentionCombobox
        items={BLOCK_TYPES}
        onRenderItem={renderItem}
        onSelectItem={(_editor, item) => onItemSelected(_editor as unknown as PlateEditor<MyValue>, item)}
        pluginKey='/'
        styles={{
          root: {
            background: theme.palette.background.default,
            backgroundColor: theme.palette.background.default,
            borderRadius: '16px',
            padding: 0,
            'overflow-x': 'hidden',
            width: '250px',
            maxHeight: '350px!important',
          },
          highlightedItem: {
            background: theme.palette.primary.contrast2,
            '&:hover': {
              background: theme.palette.background.input,
            },
            hover: {
              background: theme.palette.background.input,
            },
          },
          item: {
            background: theme.palette.background.default,
            '&:hover': {
              background: theme.palette.background.input,
            },
            hover: {
              background: theme.palette.background.input,
            },
          },
        }}
      />

      <MentionCombobox
        items={mentionableMembers}
        onRenderItem={renderMention}
        styles={{
          root: {
            background: theme.palette.background.default,
            backgroundColor: theme.palette.background.default,
            borderRadius: '16px',
            padding: 0,
            'overflow-x': 'hidden',
            width: '250px',
            maxHeight: '350px!important',
          },
          highlightedItem: {
            background: theme.palette.primary.contrast2,
            '&:hover': {
              background: theme.palette.background.input,
            },
            hover: {
              background: theme.palette.background.input,
            },
          },
          item: {
            background: theme.palette.background.default,
            '&:hover': {
              background: theme.palette.background.input,
            },
            hover: {
              background: theme.palette.background.input,
            },
          },
        }}
      />
    </>
  )
}

export default MentionComponents
