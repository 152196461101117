export function timeSince(date: number) {
  const dateConvertNumber = 1000
  const secondsPerYear = 31_536_000
  const secondsPerWeek = 604_800
  const secondsPerDay = 86_400
  const secondsPerHour = 3600
  const secondsPerMinute = 60

  const seconds = Math.floor((Date.now() - date) / dateConvertNumber)
  let interval = seconds / secondsPerYear
  interval = seconds / secondsPerWeek
  if (interval > 1) {
    return `${Math.floor(interval)}w`
  }
  interval = seconds / secondsPerDay
  if (interval > 1) {
    return `${Math.floor(interval)}d`
  }
  interval = seconds / secondsPerHour
  if (interval > 1) {
    return `${Math.floor(interval)}h`
  }
  interval = seconds / secondsPerMinute
  return `${Math.floor(interval)}m`
}
