import type { Serialized } from 'src/types/react-app-env'

export class Subscriber {
  id = ''
  createdAt: Date
  country = ''
  email = ''
  name = ''
  platform = ''
  active = false

  constructor(dto: SubscriberDto) {
    Object.assign(this, dto)
    this.createdAt = dto.createdAt ? new Date(dto.createdAt) : new Date()
  }
}

export type SubscriberDto = Partial<Serialized<Subscriber>>
