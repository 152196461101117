/* eslint-disable react/no-danger */
import CloseIcon from '@mui/icons-material/Close'
import { Dialog, IconButton, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

import { getWiseTerms } from 'src/api/payout'

type Props = {
  open: boolean
  close: () => void
}

const WalletTermsModal = (props: Props) => {
  const [terms, setTerms] = useState('')

  useEffect(() => {
    void getWiseTerms()
      .then(setTerms)
  }, [])

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      onClose={props.close}
      open={props.open}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        overflow: 'scroll',
        '& .MuiPaper-root': {
          maxWidth: 1000,
          overflow: 'hidden',
        },
      }}
    >
      <Stack height={1} overflow='auto' paddingX={3} paddingY={3} width={600}>
        <Stack spacing={2}>
          <Stack
            alignItems='center'
            direction='row'
            justifyContent='space-between'
            width={1}
          >
            <Typography variant='subtitle1'>
              Terms and conditions
            </Typography>
            <IconButton onClick={() => props.close()}>
              <CloseIcon color='disabled' />
            </IconButton>
          </Stack>
          <Stack width={1}>
            <td dangerouslySetInnerHTML={{ __html: terms }} />
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default WalletTermsModal
