import { Stack } from '@mui/material'

import ViewProjectEarnings from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/ViewProjectEarnings'
import type Project from 'src/models/Project'

type Props = {
  project?: Project
}

const ViewProjectFinances = (props: Props) =>
  <Stack height={1} width={1}>
    <ViewProjectEarnings
      project={props.project}
    />
  </Stack>

export default ViewProjectFinances
