/* eslint-disable switch-case/no-case-curly */
/* eslint-disable max-lines */
import PublicRoundedIcon from '@mui/icons-material/PublicRounded'
import { Avatar, IconButton, ListItemText, Stack, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import { visuallyHidden } from '@mui/utils'
import countries from 'i18n-iso-countries'
import type { Key } from 'react'
import { useState } from 'react'

import { GetColorLink } from 'src/components/pages/SmartLink/Content/GetColorLink'
import GetIcon from 'src/components/pages/SmartLink/Content/GetIcon'
import type { AnalyticsMonthlyChart } from 'src/models/Analytics'

type Data = {
  _id: string
  streams: number
  downloads: number
  creations: number
  views: number
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

type Order = 'asc' | 'desc'

const getComparator = (
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
  ) => number => order === 'desc'
  ? (a, b) => descendingComparator(a, b, orderBy)
  : (a, b) => -descendingComparator(a, b, orderBy)

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((element, index) => [element, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map(element => element[0])
}

type HeadCell = {
  disablePadding: boolean
  id: keyof Data
  label: string
  numeric: boolean
}

const headCells: readonly HeadCell[] = [
  {
    id: '_id',
    numeric: false,
    disablePadding: false,
    label: 'Source',
  },
  {
    id: 'streams',
    numeric: true,
    disablePadding: false,
    label: 'Streams',
  },
  {
    id: 'views',
    numeric: true,
    disablePadding: false,
    label: 'Views',
  },
  {
    id: 'downloads',
    numeric: true,
    disablePadding: false,
    label: 'Downloads',
  },
  {
    id: 'creations',
    numeric: true,
    disablePadding: false,
    label: 'Creations',
  },
]

type EnhancedTableProps = {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void
  order: Order
  orderBy: string
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { order, orderBy,  onRequestSort } =
    props
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  return (
    <TableHead sx={{
      '.MuiTableCell-root': {
        borderBottom: 2,
        borderBottomColor: 'divider',
        color: 'text.secondary',
      },
    }}
    >
      <TableRow>
        {headCells.map((headCell, index) =>
          <TableCell
            align='left'
            key={headCell.id}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              position: index === 0 ? 'sticky' : undefined,
              left: index === 0 ? 0 : undefined,
              zIndex: index === 0 ? 999 : undefined,
              background: theme => theme.palette.background.default,
              borderRight: theme => index === 0 ? `1px solid ${theme.palette.divider}` : 'none',
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id
                ? <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
                : null}
            </TableSortLabel>
          </TableCell>)}
      </TableRow>
    </TableHead>
  )
}

type Props = {
  data: AnalyticsMonthlyChart[]
  location?: boolean
  stores?: boolean
}

const AnalyticsCloudChart = (props: Props) => {
  const rows = props.data.map(item => ({
    _id: item._id,
    streams: item.streams,
    creations: item.creations,
    downloads: item.downloads,
    views: item.views,
  }))
  const [order, setOrder] = useState<Order>('desc')
  const [orderBy, setOrderBy] = useState<keyof Data>('streams')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number.parseInt(event.target.value, 10))
    setPage(0)
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

  return (
    <Box width={1}>
      <Stack sx={{ width: '100%', mb: 2 }}>
        <Typography variant='h3'>
          {props.location && 'Top Locations'}
          {props.stores && 'Top Stores'}
        </Typography>
        <TableContainer>
          <Table
            aria-labelledby='tableTitle'
            size='medium'
            sx={{
              minWidth: 750,
              tableLayout: 'fixed',
              borderCollapse: 'separate',
            }}
          >
            <EnhancedTableHead
              onRequestSort={handleRequestSort}
              order={order}
              orderBy={orderBy}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`
                  return (
                    <TableRow
                      hover
                      key={row._id}
                      onClick={() => null}
                      role='checkbox'
                      sx={{
                        '.MuiTableCell-root': {
                          borderBottomColor: 'divider',
                          paddingY: 2,
                        },
                        ':hover': {
                          '.MuiTableCell-root': {
                            background: theme => `${theme.palette.tableHoverBg}!important`,
                          },
                        },
                      }}
                      tabIndex={-1}
                    >
                      <TableCell
                        component='th'
                        id={labelId}
                        scope='row'
                        sx={{
                          position: 'sticky',
                          background: theme => theme.palette.background.default,
                          borderRight: theme => `1px solid ${theme.palette.divider}`,
                          left: 0,
                          zIndex: 999,
                        }}
                        width={400}
                      >
                        <Stack alignItems='center' direction='row' spacing={1}>
                          {props.location &&
                          <Avatar
                            src={
                              `https://purecatamphetamine.github.io/country-flag-icons/3x2/${row._id.toUpperCase()}.svg`
                            }
                            sx={{
                              background: theme => theme.palette.info.main,
                              height: 23,
                              width: 35,
                              borderRadius: '4px',
                            }}
                            variant='rounded'
                          >
                            <PublicRoundedIcon />
                          </Avatar>}
                          {props.stores &&
                          <IconButton
                            disableRipple
                            sx={{
                              backgroundColor: GetColorLink(row._id),
                              color: 'white!important',
                              ':hover': {
                                backgroundColor: GetColorLink(row._id),
                                cursor: 'default',
                              },
                              '& .MuiSvgIcon-root': {
                                color: 'white!important',
                              },
                            }}
                          >
                            {GetIcon(row._id)}
                          </IconButton>}
                          <ListItemText
                            primary={props.location
                              ? countries.getName(row._id, 'en')
                                ? countries.getName(row._id, 'en')
                                : 'Worldwide'
                              : row._id}
                          />
                        </Stack>
                      </TableCell>
                      <TableCell align='left'>
                        {row.streams.toLocaleString('en-US')}
                      </TableCell>
                      <TableCell align='left'>
                        {row.views.toLocaleString('en-US')}
                      </TableCell>
                      <TableCell align='left'>
                        {row.downloads.toLocaleString('en-US')}
                      </TableCell>
                      <TableCell align='left'>
                        {row.creations.toLocaleString('en-US')}
                      </TableCell>
                    </TableRow>
                  )
                })}
              {emptyRows > 0 &&
                <TableRow
                  style={{
                    height: 33 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component='div'
          count={rows.length}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 50]}
        />
      </Stack>
    </Box>
  )
}

export default AnalyticsCloudChart
