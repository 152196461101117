/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Badge, ListItem, ListItemAvatar, ListItemText, useTheme } from '@mui/material'
import { getApp } from 'firebase/app'
import { getDatabase, onValue, ref } from 'firebase/database'
import { useEffect, useState } from 'react'

import { timeSince } from 'src/components/pages/Messages/TimeSince'
import type Account from 'src/models/Account'

type Props = {
  account: Account
}

const AccountListItem = (props: Props) => {
  const themeItem = useTheme()
  const [connection, setConnection] = useState(false)
  const [lastOnline, setLastOnline] = useState('')
  const badgeSize = '14px'
  const app = getApp()
  const database = getDatabase(app)
  const userLastOnlineRef = ref(database, `users/${props.account.id}/lastOnline`)
  const userOnlineRef = ref(database, `users/${props.account.id}/connections`)

  useEffect(() =>
    onValue(userOnlineRef, snapshot => {
      const isOnline: boolean = snapshot.val()
      setConnection(isOnline)
    })
  , [props.account.id])

  useEffect(() =>
    onValue(userLastOnlineRef, snapshot => {
      const lastSeen: string = snapshot.val()
      setLastOnline(lastSeen)
    })
  , [props.account.id])

  const avatar = <Avatar
    alt={`${props.account.firstName} ${props.account.lastName}`}
    sx={{
      width: 42,
      height: 42,
      transition: '0.3s',
    }}
    {...props}
    srcSet={props.account.profilePictureUrl}
    variant='circular'
  >
    {`${props.account.firstName.charAt(0)}${props.account.lastName.charAt(0)}`}
  </Avatar>

  return (

    <ListItem>
      <ListItemAvatar>

        <Badge
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          color={connection ? 'success' : 'error'}
          overlap='circular'
          sx={{
            '.MuiBadge-dot': {
              display: connection ? undefined : 'none',
              width: badgeSize,
              height: badgeSize,
              border: 2,
              borderColor: 'background.paper',
              borderRadius: '50%',
            },
          }}
          variant='dot'
        >
          {avatar}
        </Badge>
      </ListItemAvatar>
      <ListItemText
        primary={`${props.account.firstName} ${props.account.lastName}`}
        secondary={connection
          ? 'Online'
          : `Last seen ${timeSince(Number(lastOnline))} ago`}
        secondaryTypographyProps={{
          color: themeItem.palette.text.label,
        }}
      />
    </ListItem>
  )
}

export default AccountListItem
