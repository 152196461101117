import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded'
import { Button, Paper, Stack, Typography, useTheme } from '@mui/material'
import MuiPhoneNumber from 'material-ui-phone-number-2'
import type { ChangeEvent } from 'react'
import { useState } from 'react'

type Props = {
  setPhone: (phone: string) => void
  setStep: (step: string) => void
}

const MfaAskPhone = (props: Props) => {
  const themeHook = useTheme()
  const [phoneNumber, setPhoneNumber] = useState('')

  const handlePhoneChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string) => {
    if (typeof event === 'string') {
      setPhoneNumber(event)
    }
  }

  const handleClick = () => {
    props.setPhone(phoneNumber)
    props.setStep('code')
  }

  return (
    <Stack alignItems='center' spacing={2} width={1}>
      <Stack alignItems='center' id='handle-login'>
        <Paper
          elevation={0}
          sx={{
            backgroundColor: theme => theme.palette.primary.main,
            borderRadius: 16,
          }}
        >
          <Stack
            alignItems='center'
            justifyContent='center'
            padding={4}
          >
            <LocalPhoneRoundedIcon
              sx={{
                color: theme => theme.palette.primary.contrastText,
                fontSize: '144px',
              }}
            />
          </Stack>
        </Paper>
        <Typography
          color={themeHook.palette.text.secondary}
          sx={{
            marginTop: 1,
          }}
          textAlign='center'
          variant='h4'
        >
          2FA Setup
        </Typography>
        <Typography
          color={themeHook.palette.text.label}
          textAlign='center'
          variant='body2'
        >
          Please enter your phone number to enroll a second factor.
        </Typography>
        <Stack direction='row' justifyItems='center' marginTop={2} spacing={1}>
          <MuiPhoneNumber
            defaultCountry='us'
            fullWidth
            onChange={handlePhoneChange}
            sx={{ marginTop: 0 }}
            value={phoneNumber}
            variant='filled'
          />
          <Button onClick={handleClick}>
            Submit
          </Button>
        </Stack>
      </Stack>

    </Stack>
  )
}

export default MfaAskPhone
