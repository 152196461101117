import AppleIcon from '@mui/icons-material/Apple'
import CloseIcon from '@mui/icons-material/Close'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import { Alert, Avatar, Button, Card, CardActionArea, Dialog, IconButton, Snackbar, Stack, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'

import { getTaskboard } from 'src/api/taskboards'
import GoogleCalendarLogo from 'src/components/Icons/GoogleCalendarLogo'
import { taskboardIconsLarge } from 'src/components/pages/Task/TaskViews/TaskViewComponents/TaskboardIcons'
import { useAuth } from 'src/components/providers/AuthProvider'
import type Project from 'src/models/Project'
import type { TaskBoard } from 'src/models/Taskboard'

type Props = {
  close: () => void
  open: boolean
  id: string
  hash: string
  type: 'account' | 'releaseSchedule' | 'taskboard'
  provider: 'apple' | 'google'
  project?: Project
}

const TaskSyncModal = (props: Props) => {
  const { currentAccount, currentOrganisation } = useAuth()
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))
  const [taskboard, setTaskboard] = useState<TaskBoard>()
  // eslint-disable-next-line max-len
  const privateString = `https://api.releese.io/api/ical/${props.type === 'account' ? 'account' : props.type === 'releaseSchedule' ? 'releases' : 'tasks'}/${props.id}/${props.hash}`
  const [linkCopiedToast, setLinkCopiedToastOpen] = useState(false)

  useEffect(() => {
    if (props.type === 'taskboard') {
      void getTaskboard(props.id)
        .then(setTaskboard)
    }
  }, [props.type, props.id])

  const copyLink = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    event.preventDefault()
    setLinkCopiedToastOpen(true)
    void navigator.clipboard.writeText(privateString)
  }

  const handleCloseToast = (event: Event | React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setLinkCopiedToastOpen(false)
  }

  return (
    <>
      <Dialog
        BackdropProps={{
          timeout: 500,
        }}
        closeAfterTransition
        fullScreen={fullScreen}
        fullWidth
        maxWidth='sm'
        onClose={props.close}
        open={props.open}
        sx={{
          justifyContent: 'center',
          width: 1,
        }}
      >
        <Stack paddingX={3} paddingY={3} spacing={2} width={1}>
          <Stack
            alignItems='center'
            direction='row'
            justifyContent='space-between'
            width={1}
          >
            <Typography variant='h3'>
              {props.type === 'account'
                ? 'My Tasks'
                : props.type === 'releaseSchedule'
                  ? 'Release Schedule'
                  : 'Taskboard'}
            </Typography>
            <IconButton onClick={() => props.close()}>
              <CloseIcon color='disabled' />
            </IconButton>
          </Stack>
          <Stack spacing={1} width={1}>
            {props.type === 'account' &&
            <Stack alignItems='center' spacing={1} width={1}>
              <Avatar src={currentAccount.profilePictureUrl} sx={{ width: 64, height: 64 }} />
              <Typography textAlign='center' variant='subtitle2'>
                My tasks
              </Typography>
            </Stack>}
            {props.type === 'releaseSchedule' &&
            <Stack alignItems='center' spacing={1} width={1}>
              <Avatar
                src={currentOrganisation?.logoUrl}
                sx={{ width: 64, height: 64, borderRadius: '6px' }}
                variant='rounded'
              />
              <Typography textAlign='center' variant='subtitle2'>
                Release schedule
              </Typography>
            </Stack>}
            {props.type === 'taskboard' && taskboard &&
            <Stack alignItems='center' spacing={1} width={1}>
              {props.project &&
              <Avatar
                src={props.project.artworkPreviewUrl}
                sx={{
                  width: 64,
                  height: 64,
                  borderRadius: '8px',
                }}
              >
                <MusicNoteRoundedIcon />
              </Avatar>}
              {!props.project &&
              <Avatar
                sx={{
                  width: 64,
                  height: 64,
                  borderRadius: 1,
                  bgcolor: taskboard.color,
                }}
              >
                {taskboardIconsLarge[taskboard.icon]}
              </Avatar>}

              <Typography textAlign='center' variant='subtitle1'>
                {taskboard.title}
              </Typography>
            </Stack>}

            <Stack width={1}>
              <Typography color={themeItem.palette.text.secondary} textAlign='center' variant='body2'>
                {props.type === 'account'
                  ? 'All tasks assigned to you with a start date and due date will appear on your synced calendar'
                  : props.type === 'releaseSchedule'
                    ? 'All projects with a release date will appear on your synced calendar'
                    : 'All tasks with a start date and due date will appear on your synced calendar'}
              </Typography>
            </Stack>
          </Stack>
          <Stack width={1}>
            <Tooltip title='Copy Calendar URL'>
              <Card
                sx={{
                  width: 1,
                  background: theme => theme.palette.background.paper,
                }}
                variant='outlined'
              >
                <CardActionArea onClick={event => copyLink(event)} sx={{ width: 1 }}>
                  <Stack padding={1} width={1}>
                    <Typography variant='body1'>
                      {privateString}
                    </Typography>
                  </Stack>
                </CardActionArea>
              </Card>
            </Tooltip>
          </Stack>
          <Stack width={1}>
            <Alert
              icon={props.provider === 'apple'
                ? <AppleIcon color='action' />
                : <GoogleCalendarLogo />}
              sx={{
                background: theme => theme.palette.background.input,
                color: theme => theme.palette.text.secondary,
              }}
            >
              {props.provider === 'apple'
                ? `Add this link to your Apple Calendar with iCloud enabled. Apple will attempt to refresh the events
              every hour. This is a one way sync, meaning editing events on iCalendar will not reflect on Releese.`
                : `Add this link to your Google Calendar. Google will attempt to refresh the events twice per day. 
              This is a one way sync, meaning editing events on Google Calendar will not reflect on Releese.`}
            </Alert>
          </Stack>
          <Stack direction='row' justifyContent='flex-end' spacing={1} width={1}>
            <Button onClick={() => props.close()} variant='text'>
              Close
            </Button>
            <Button onClick={event => copyLink(event)}>
              Copy Calendar URL
            </Button>
          </Stack>
        </Stack>
      </Dialog>
      <Snackbar
        autoHideDuration={5000}
        message='Private Calendar link copied'
        onClose={handleCloseToast}
        open={linkCopiedToast}
      >
        <Alert
          onClose={handleCloseToast}
          severity='success'
          sx={{ width: '100%' }}
          variant='filled'
        >
          Private Calendar link copied
        </Alert>
      </Snackbar>
    </>
  )
}

export default TaskSyncModal
