/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import type { AvatarProps } from '@mui/material'
import { Avatar, Badge, Link } from '@mui/material'
import { getApp } from 'firebase/app'
import { getDatabase, onValue, ref } from 'firebase/database'
import { useEffect, useState } from 'react'

import { RouterLink } from 'src/components/SafeRouterLink'

type AccountProperty =
{
  id?: string
  displayName?: string
  firstName?: string
  lastName?: string
  profilePictureUrl?: string
}

type Props = AvatarProps & {
  account: AccountProperty
  size?: number | string
  showBadge?: boolean
  isUserOnline?: boolean
  nolink?: boolean
}

const BADGE_SIZE_FRACTION = 3

const AccountAvatar = ({ account, size, showBadge, isUserOnline, nolink, ...props }: Props) => {
  const badgeSize = size ? `${Number.parseInt(size as string) / BADGE_SIZE_FRACTION}px` : '18px'
  const [connection, setConnection] = useState(false)

  if (showBadge) {
    const app = getApp()
    const database = getDatabase(app)
    const userOnlineRef = ref(database, `users/${account.id}/connections`)

    useEffect(() =>
      onValue(userOnlineRef, snapshot => {
        const isOnline: boolean = snapshot.val()
        setConnection(isOnline)
      })
    , [account.id])
  }

  const avatar = <Avatar
    alt={`${account.firstName} ${account.lastName}`}
    sx={{
      width: size,
      height: size,
      transition: '0.3s',
    }}
    {...props}
    srcSet={account.profilePictureUrl}
    variant='circular'
  >
    {`${account.firstName?.charAt(0)}${account.lastName?.charAt(0)}`}
  </Avatar>

  return <Badge
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    color={connection ? 'success' : 'error'}
    overlap='circular'
    sx={{
      '.MuiBadge-dot': {
        display: showBadge && connection ? undefined : 'none',
        width: badgeSize,
        height: badgeSize,
        border: 2,
        borderColor: 'background.paper',
        borderRadius: '50%',
      },
    }}
    variant='dot'
  >
    {nolink
      ? <>{avatar}</>
      : <Link
        component={RouterLink}
        to={`/Profile/${account.id ?? account.profilePictureUrl?.split('/').pop()}`}
        underline='none'
      >
        {avatar}
      </Link>}
  </Badge>
}

export default AccountAvatar
