import ColorLensRoundedIcon from '@mui/icons-material/ColorLensRounded'
import { Button, darken, lighten, Menu, Stack, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'
import { type ColorResult, ChromePicker, TwitterPicker } from 'react-color'
import tinycolor from 'tinycolor2'

type Props = {
  handleColorChange: (colorItem: ColorResult) => void
  colors: string[]
  backgroundColor: string
}

const EditLinkBackgroundColor = (props: Props) => {
  const themeColors = useTheme()
  const matches = useMediaQuery(themeColors.breakpoints.down('md'))

  const color = tinycolor(props.backgroundColor)
  const isColorDark = color.isDark()

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const openMoreMenu = Boolean(anchorElement)

  return (
    <>
      <Menu
        anchorEl={anchorElement}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClick={() => null}
        onClose={() => setAnchorElement(null)}
        open={openMoreMenu}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        <Stack padding={2}>
          <ChromePicker
            color={props.backgroundColor}
            onChange={colors => {
              props.handleColorChange(colors)
            }}
            onChangeComplete={colors => {
              props.handleColorChange(colors)
            }}
            styles={{
              default: {
                body: {
                  padding: '0px',
                  boxShadow: 'none',
                  border: 'none',
                  background: 'transparent',
                },
                picker: {
                  boxShadow: 'none',
                  border: 'none',
                  background: 'transparent',
                },
              },
            }}
          />
        </Stack>
      </Menu>

      <Stack
        alignItems={matches ? 'flex-start' : 'center'}
        direction={matches ? 'column' : 'row'}
        spacing={1}
        width={1}
      >
        <TwitterPicker
          color={props.backgroundColor}
          colors={props.colors}
          onChangeComplete={colors => {
            props.handleColorChange(colors)
          }}
          styles={{
            default: {
              card: {
                background: 'transparent',
                boxShadow: 'none',
                border: 'none',
                height: '30px',
              },
              body: {
                padding: '0px',
                height: '30px',
              },
              input: {
                display: 'none',
                width: '0px',
                height: '30px',
              },
              hash: {
                display: 'none',
                width: '0px',
                height: '30px',
              },
            },
          }}
          triangle='hide'
          width='220px'
        />
        <Button
          onClick={event => setAnchorElement(event.currentTarget)}
          startIcon={<ColorLensRoundedIcon />}
          sx={{
            background: props.backgroundColor,
            width: 'fit-content',
            color: isColorDark
              ? 'white'
              : 'black',
            ':hover': {
              color: isColorDark
                ? 'white'
                : 'black',
              background: isColorDark
                ? lighten(props.backgroundColor, 0.2)
                : darken(props.backgroundColor, 0.2),
            },
          }}
        >
          Pick a color
        </Button>
      </Stack>
    </>
  )
}

export default EditLinkBackgroundColor
