import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded'
import { Card, CardActionArea, Stack, Typography } from '@mui/material'

type Props = {
  readonly activeSelection: number
  readonly handleSelection: (value: number) => () => void
}

const Cost = (props: Props) => {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (props.activeSelection) {
    case 1:
      return <Card
        elevation={0}
        sx={{ borderColor: theme => `${theme.palette.success.main}!important`, height: 1 }}
        variant='outlined'
      >
        <CardActionArea onClick={props.handleSelection(1)} sx={{ height: 1 }} >
          <Stack alignItems='center' height={1} padding={3} spacing={1} >
            <MonetizationOnRoundedIcon
              sx={{
                fontSize: 64,
                color: theme => theme.palette.success.main,
              }}
            />
            <Typography textAlign='center' variant='h6'>
              Too expensive
            </Typography>
          </Stack>
        </CardActionArea>
      </Card>
    default:
      return <Card elevation={0} sx={{ height: 1 }} variant='outlined'>
        <CardActionArea onClick={props.handleSelection(1)} sx={{ height: 1 }} >
          <Stack alignItems='center' height={1} padding={3} spacing={1}>
            <MonetizationOnRoundedIcon
              sx={{
                fontSize: 64,
                color: theme => theme.palette.success.main,
              }}
            />
            <Typography textAlign='center' variant='h6'>
              Too expensive
            </Typography>
          </Stack>
        </CardActionArea>
      </Card>
  }
}

export default Cost
