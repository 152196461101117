import CloseIcon from '@mui/icons-material/Close'
import { Button, Card, CardActionArea, Dialog, IconButton, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import type { ChangeEvent } from 'react'
import { useState } from 'react'
import type { ColorResult } from 'react-color'
import { CirclePicker } from 'react-color'
import { v4 } from 'uuid'

import { savePartialTaskboard } from 'src/api/taskboards'
import { sendTasksboardUpdate } from 'src/api/webSocket'
import { colorSelection } from 'src/components/pages/Task/TaskMenu/TaskMenuComponents/ColorsForPicker'
import { taskboardIcons } from 'src/components/pages/Task/TaskViews/TaskViewComponents/TaskboardIcons'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { TaskBoard, TasksboardEditField } from 'src/models/Taskboard'
import { TaskBoardEdit } from 'src/models/Taskboard'

type Props = {
  close: () => void
  open: boolean
  taskboard: TaskBoard
  refreshBoards: () => void
}

const EditTaskboardModal = (props: Props) => {
  const { currentAccount } = useAuth()
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  const [boardColor, setBoardColor] = useState(props.taskboard.color)
  const [boardName, setBoardName] = useState(props.taskboard.title)
  const [boardIcon, setBoardIcon] = useState(props.taskboard.icon)

  const handleColorChange = (color: ColorResult) => {
    setBoardColor(color.hex)
  }

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setBoardName(event.target.value)
  }

  const handleClick = async () => {
    if (props.taskboard.id) {
      const itemClone = Object.assign({}, props.taskboard)

      const oldTitle = itemClone.title
      const oldColor = itemClone.color

      const changeTitle = oldTitle !== boardName
      const changeColor = oldColor !== boardColor

      const updatedFields: TasksboardEditField[] = []

      if (changeTitle) {
        updatedFields.push({
          fieldName: 'Title',
          oldValue: oldTitle,
          newValue: boardName,
        })
      }

      if (changeColor) {
        updatedFields.push({
          fieldName: 'Color',
          oldValue: oldColor,
          newValue: boardColor,
        })
      }

      props.taskboard.color = boardColor
      props.taskboard.title = boardName
      props.taskboard.icon = boardIcon

      await savePartialTaskboard({ id: props.taskboard.id, ...props.taskboard, icon: boardIcon })
        .then(() => {
          const taskUpdateItem = new TaskBoardEdit({
            id: v4(),
            taskboardId: props.taskboard.id,
            taskId: '',
            accountId: currentAccount.id,
            modificationType: 'Taskboard details',
            itemType: 'Field',
            title: `${itemClone.title}`,
            fields: updatedFields,
          })
          sendTasksboardUpdate(taskUpdateItem)
        })
        .then(props.refreshBoards)
        .then(() => {
          props.close()
          setBoardName('')
          setBoardColor('')
        })
    }
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      maxWidth='xs'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Stack paddingX={3} paddingY={3} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          width='100%'
        >
          <Typography variant='h3'>
            Edit Taskboard
          </Typography>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>
        <Stack alignItems='center' width='100%'>
          <Stack marginBottom={4} marginTop={3} spacing={1} width={338}>
            <CirclePicker
              color={boardColor}
              colors={colorSelection}
              onChangeComplete={
                handleColorChange
              }
              width='100%'
            />
          </Stack>
          <TextField
            fullWidth
            inputProps={{ maxLength: 50 }}
            label='Name your board'
            onChange={handleNameChange}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                void handleClick()
              }
            }}
            placeholder='Board name'
            value={boardName}
          />

          <Stack direction='row' marginTop={5} width={1}>
            <Typography variant='inputLabel'>
              Icon
            </Typography>
          </Stack>
          <Stack direction='row' flexWrap='wrap' marginTop={-1} width={1}>
            {taskboardIcons.map((icon, index) =>
              <Card
                key={v4()}
                sx={{
                  borderColor: boardIcon === index ? null : 'transparent',
                  borderCollapse: 'collapse',
                }}
                variant='outlined'
              >
                <CardActionArea onClick={() => setBoardIcon(index)}>
                  <IconButton>
                    {icon}
                  </IconButton>
                </CardActionArea>
              </Card>)}
          </Stack>

          <Stack direction='row' marginLeft='auto' marginTop={4} spacing={1}>
            <Button onClick={() => props.close()} variant='text'>Cancel</Button>
            <Button
              onClick={() => handleClick()}
            >
              Edit board
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default EditTaskboardModal
