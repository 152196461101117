import CircleIcon from '@mui/icons-material/Circle'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import SearchIcon from '@mui/icons-material/SearchRounded'
import { Autocomplete, Avatar, CircularProgress, InputAdornment, ListItemButton, ListItemText, Stack, TextField, useTheme } from '@mui/material'
import { useState } from 'react'

import { searchItems } from 'src/api/search'
import { RouterLink } from 'src/components/SafeRouterLink'
import type { Search } from 'src/models/Search'

const HeaderSearchField = () => {
  const themeItem = useTheme()
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState<Search[]>([])
  const [loading, setLoading] = useState(false)
  const [currentValue, setValue] = useState<string>()

  const filterOptions = currentValue
    ? options.filter(option => option.title.toLowerCase().includes(currentValue.toLowerCase()))
    : []

  const onInputChange = (event: React.SyntheticEvent, value: string) => {
    setValue(value)
  }

  return (
    <Autocomplete
      autoComplete={false}
      getOptionLabel={option => option.title}
      id='head-search'
      inputValue={currentValue}
      loading={loading}
      onClose={() => setOpen(false)}
      onInputChange={onInputChange}
      onOpen={async () => {
        setOpen(true)
        setLoading(true)
        await searchItems()
          .then(setOptions)
          .finally(() => setLoading(false))
      }}
      open={open}
      options={filterOptions}
      renderInput={params =>
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            autoComplete: 'new-password',
            startAdornment: <InputAdornment position='start'>
              <SearchIcon sx={{ color: 'action.active' }} />
            </InputAdornment>
            ,
            endAdornment:
  <>
    {loading
      ? <CircularProgress color='primary' size={20} />
      : null}
    {params.InputProps.endAdornment}
  </>
            ,
          }}
          autoComplete='new-password'
          placeholder='Search'
          sx={{
            marginTop: 0,
            '.MuiInputBase-root': {
              backgroundColor: themeItem.palette.mode === 'dark'
                ? '#4E4F50!important'
                : `${themeItem.palette.background.default}!important`,
              ':hover': {
                backgroundColor: themeItem.palette.mode === 'dark'
                  ? '#4E4F50'
                  : themeItem.palette.background.default,
              },
            },
          }}
        />}
      renderOption={(renderProps, option) =>
        <ListItemButton
          component={RouterLink}
          onClick={() => setOpen(false)}
          sx={{
            paddingY: 0.5,
          }}
          to={option.url}
        >
          <Stack
            sx={{
              minWidth: 48,
            }}
          >
            {!option.image || option.image.length === 0
              ? <Avatar
                sx={{
                  height: 36,
                  width: 36,
                  borderRadius: 0.5,
                  backgroundColor: theme => theme.palette.background.input,
                }}
              >
                <MusicNoteRoundedIcon
                  sx={{
                    fontSize: 36,
                    color: theme => theme.palette.action.disabled,
                  }}
                />
              </Avatar>
              : option.type !== 'Taskboard'
                ? <img
                  alt={option.title}
                  src={option.image}
                  style={{
                    width: 36,
                    height: 36,
                    borderRadius: 4,
                    objectFit: 'cover',
                  }}
                />
                : <Avatar
                  sx={{
                    width: 36,
                    height: 36,
                    borderRadius: 0.5,
                    backgroundColor: option.image,
                  }}
                >
                  <CircleIcon sx={{
                    fontSize: theme => theme.typography.body3.fontSize,
                    color: option.image,
                  }}
                  />
                </Avatar>}
          </Stack>
          <ListItemText
            primary={option.title}
            secondary={option.type}
          />
        </ListItemButton>}
      sx={{
        width: 240,
      }}
    />
  )
}

export default HeaderSearchField
