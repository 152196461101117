/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete, Avatar, createFilterOptions, ListItemText, MenuItem, Stack, TextField, Typography } from '@mui/material'
import _ from 'lodash'
import { useEffect, useState } from 'react'

import { getArtists } from 'src/api/distribution'
import type { ArtistObject } from 'src/models/Distribution'

type Props = {
  disabled?: boolean
  placeholder: string
  selectedArtists: ArtistObject | null | undefined
  setSelectedArtists: (values: ArtistObject | null | undefined) => void
  error?: boolean
}

const SearchOneArtistComponent = (props: Props) => {
  const [loading, setLoading] = useState(false)
  const [searchArtists, setSearchArtists] = useState<ArtistObject[]>([])
  const [searchQuery, setSearchQuery] = useState('')

  useEffect(() => {
    if (searchQuery && searchQuery.length > 0) {
      void searchArtistFunction(searchQuery)
    } else {
      setSearchArtists([])
    }
  }, [searchQuery])

  const searchArtistFunction = async (query: string) => {
    setLoading(true)
    await getArtists(query)
      .then(response => setSearchArtists(response))
      .catch(() => null)
      .finally(() => setLoading(false))
  }

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: ArtistObject) => `${option.name}${option.id}${option.uri}`,
  })

  return (
    <>
      <Autocomplete
        disabled={props.disabled}
        filterOptions={filterOptions}
        getOptionLabel={option => `${option.name}`}
        inputValue={searchQuery}
        loading={loading}
        onChange={(event, value) => props.setSelectedArtists(value)}
        onInputChange={(event, value) =>
          setSearchQuery(value)}
        options={searchArtists}
        renderInput={params =>
          <TextField
            {...params}
            disabled={props.disabled}
            error={props.error ?? false}
            fullWidth
            placeholder={props.placeholder}
            sx={{
              marginTop: 0,
            }}
          />}
        renderOption={(renderProps, option) => {
          if (option.name && option.name.length > 0) {
            return <MenuItem
              {...renderProps}
              key={option.uri}
              sx={{
                paddingY: 0.5,
              }}
            >
              <Stack
                sx={{
                  minWidth: 48,
                }}
              >
                <Avatar
                  src={option.images?.[0]?.url ? option.images[0].url : ''}
                  sx={{
                    height: 36,
                    width: 36,
                    borderRadius: 0.5,
                    backgroundColor: theme => theme.palette.background.input,
                  }}
                />
              </Stack>
              <ListItemText
                primary={option.name}
                secondary={option.followers && option.followers.total > 0
                  ? `${option.followers.total.toLocaleString()} followers`
                  : 'No followers'}
              />
            </MenuItem>
          }
        }}
        value={props.selectedArtists}
      />
      {props.error &&
      <Typography color='error' variant='body2'>
        Please do not include duplicate artists
      </Typography>}
    </>
  )
}

export default SearchOneArtistComponent
