/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unused-prop-types */
import { Avatar, Stack } from '@mui/material'
import Marquee, { Motion, randomIntFromInterval } from 'react-marquee-slider'

import { GetColorLink } from 'src/components/pages/SmartLink/Content/GetColorLink'
import GetIcon from 'src/components/pages/SmartLink/Content/GetIcon'

const platformNames = [
  'Amazon',
  'Apple Music',
  'Spotify',
  'Deezer',
  'Shazam',
  'TikTok',
  'Soundcloud',
  'Instagram',
  'Facebook',
  'Youtube',
  'Tidal',
  'Pandora',
  'Audiomack',
  'iheartradio',
  'melon',
  'tencent',
  'naver',
  'qobuz',
  'capcut',
]

const DistributionBubbles = () =>
  <Marquee
    direction='ltr'
    key='top'
    onFinish={({ totalTries }: { totalTries: number }) => null}
    onInit={() => null}
    resetAfterTries={200}
    scatterRandomly={false}
    velocity={5}
  >
    {platformNames.map(object =>
      <Motion
        backgroundColors={{
          earth: 'transparent',
          solarSystem: 'transparent',
          buffer: 'transparent',
        }}
        direction={Math.random() > 0.5 ? 'clockwise' : 'counterclockwise'}
        initDeg={randomIntFromInterval(0, 360)}
        key={`marquee-example-company-${object}`}
        radius={10}
        velocity={5}
      >
        <Stack marginTop={Math.floor(Math.random() * 100)}>
          <Avatar
            sx={{
              height: 64,
              width: 64,
              backgroundColor: GetColorLink(object),
              color: 'white!important',
              fontSize: '32px',
            }}
          >
            {GetIcon(object)}
          </Avatar>
        </Stack>
      </Motion>)}
  </Marquee>

export default DistributionBubbles
