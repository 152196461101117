/* eslint-disable sonarjs/no-identical-functions */
import CloseIcon from '@mui/icons-material/Close'
import { Button, Dialog, IconButton, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'
import type { ColorResult } from 'react-color'
import { CirclePicker } from 'react-color'

import { updateTaskboardTemplate } from 'src/api/taskboards'
import { colorSelection } from 'src/components/pages/Task/TaskMenu/TaskMenuComponents/ColorsForPicker'
import type { TaskBoardTemplate } from 'src/models/Taskboard'

type Props = {
  close: () => void
  open: boolean
  refreshBoards: () => void
  taskboardGroup: TaskBoardTemplate
}

const UpdateTaskTemplatedModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  const [title, setTitle] = useState(props.taskboardGroup.title)
  const [color, setColor] = useState(props.taskboardGroup.color)
  const [description, setDescription] = useState(props.taskboardGroup.description)

  const handleColorChange = (colorItem: ColorResult) => {
    setColor(colorItem.hex)
  }

  const handleClick = async () => {
    if (props.taskboardGroup.id) {
      await updateTaskboardTemplate(
        props.taskboardGroup.id,
        { ...props.taskboardGroup, title, color, description }
      )
        .then(props.refreshBoards)
      props.close()
    }
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='xs'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Stack paddingX={3} paddingY={3} spacing={4} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          width='100%'
        >
          <Typography variant='h3'>
            Update Template
          </Typography>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>

        <Stack width='100%'>
          <Stack marginBottom={3} spacing={1} width='100%'>
            <Typography variant='inputLabel'>Pick a board color</Typography>
            <CirclePicker
              color={color}
              colors={colorSelection}
              onChangeComplete={
                handleColorChange
              }
              width='100%'
            />
          </Stack>
          <TextField
            fullWidth
            inputProps={{ maxLength: 50 }}
            label='Title'
            onChange={event => setTitle(event.target.value)}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                void handleClick()
              }
            }}
            placeholder='Template title'
            value={title}
          />
          <TextField
            fullWidth
            label='Description'
            onChange={event => setDescription(event.target.value)}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                void handleClick()
              }
            }}
            placeholder='Template description'
            sx={{ marginTop: 4 }}
            value={description}
          />
        </Stack>

        <Stack direction='row' justifyContent='flex-end' spacing={1} width='100%'>
          <Button onClick={props.close} variant='text'>
            Cancel
          </Button>
          <Button
            color='primary'
            onClick={() => handleClick()}
          >
            Update template
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default UpdateTaskTemplatedModal
