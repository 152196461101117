import CloseIcon from '@mui/icons-material/Close'
import { Button, Dialog, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'

import { removeTaskboardTemplate } from 'src/api/taskboards'
import DeleteItemComponent from 'src/components/DeleteItemComponent'
import type { TaskBoardTemplate } from 'src/models/Taskboard'

type Props = {
  close: () => void
  open: boolean
  refreshBoards: () => void
  taskboardGroup: TaskBoardTemplate
}

const DeleteTaskTemplatedModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  const handleClick = async () => {
    if (props.taskboardGroup.id) {
      await removeTaskboardTemplate(props.taskboardGroup.id)
        .then(props.refreshBoards)
      props.close()
    }
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='xs'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Stack paddingX={3} paddingY={3} spacing={4} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          width='100%'
        >
          <Typography variant='h3'>
            Delete Template
          </Typography>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>
        <DeleteItemComponent
          primary='Are you sure?'
          secondary={`You will delete ${props.taskboardGroup.title}, this action cannot be undone`}
        />
        <Stack direction='row' justifyContent='flex-end' spacing={1} width='100%'>
          <Button onClick={props.close} variant='text'>
            Cancel
          </Button>
          <Button
            color='error'
            onClick={() => handleClick()}
          >
            Delete template
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default DeleteTaskTemplatedModal
