/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable max-lines */
/* eslint-disable sonarjs/no-identical-functions */
import CloseIcon from '@mui/icons-material/Close'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import { Autocomplete, Button, Chip, Dialog, IconButton, InputAdornment, ListItemIcon, Menu, MenuItem, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'
import type { ColorResult } from 'react-color'
import { CirclePicker } from 'react-color'
import { v4 } from 'uuid'

import { savePartialTaskboard } from 'src/api/taskboards'
import { sendTasksboardUpdate } from 'src/api/webSocket'
import FormulaTypeSelect from 'src/components/form-elements/FormulaTypeSelectField'
import { colorSelection } from 'src/components/pages/Task/TaskMenu/TaskMenuComponents/ColorsForPicker'
import { fieldTypes } from 'src/components/pages/Task/TaskViews/TaskViewComponents/AddCustomFieldsModal'
import { getCustomFieldIcon } from 'src/components/pages/Task/TaskViews/TaskViewComponents/CustomFieldIcon'
import { useAuth } from 'src/components/providers/AuthProvider'
import { currencyData } from 'src/data/currencyData'
import type { CustomFields, TaskBoard, TasksboardEditField } from 'src/models/Taskboard'
import { TaskBoardEdit } from 'src/models/Taskboard'

type Props = {
  close: () => void
  open: boolean
  taskboard: TaskBoard
  field: CustomFields
}

const EditCustomField = (props: Props) => {
  const { currentAccount } = useAuth()
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  const [fieldName, setFieldName] = useState(props.field.title)
  const [fieldType, setFieldType] = useState(props.field.fieldType)
  const [fieldOptions, setFieldOptions] = useState<string[]>(props.field.dropdownOptions)
  const [currency, setCurrency] = useState(props.field.currency)
  const [colorOptions, setColorOptions] = useState<string[]>(props.field.dropdownColors)
  const [currentItem, setCurrentItem] = useState(0)

  const [formulaValueOne, setFormulaValueOne] = useState<CustomFields | undefined>(props.taskboard.customFields.find(
    item => item.id === props.field.formulaValues?.[0]
  ))
  const [formulaValueTwo, setFormulaValueTwo] = useState<CustomFields | undefined>(props.taskboard.customFields.find(
    item => item.id === props.field.formulaValues?.[1]
  ))
  const [formulaType, setFormulaType] = useState<string>(props.field.formulaType ?? '')

  const [anchorElementField, setAnchorElementField] = useState<HTMLButtonElement | null>(null)
  const openMenuField = Boolean(anchorElementField)

  const handleCloseField = () => {
    setAnchorElementField(null)
  }

  const handleColorChange = (color: ColorResult) => {
    colorOptions[currentItem] = color.hex
    setColorOptions(colorOptions)
    setAnchorElementField(null)
  }

  const handleClick = async () => {
    if (props.taskboard.id) {
      const index = props.taskboard.customFields.findIndex(item => item.id === props.field.id)
      if (index !== -1) {
        const itemClone = Object.assign({}, props.taskboard.customFields[index])

        const oldTitle = itemClone.title
        const oldType = itemClone.fieldType
        const oldOptions = itemClone.dropdownOptions
        const oldCurrency = itemClone.currency

        const changeTitle = oldTitle !== fieldName
        const changeType = oldType !== fieldType
        const changeCurrency = oldCurrency !== currency
        const changeOptions = oldOptions.length !== fieldOptions.length ||
          !oldOptions.every((value, indexItem) => value === fieldOptions[indexItem])

        const updatedFields: TasksboardEditField[] = []

        if (changeTitle) {
          updatedFields.push({
            fieldName: 'Title',
            oldValue: oldTitle,
            newValue: fieldName,
          })
        }

        if (changeType) {
          updatedFields.push({
            fieldName: 'Type',
            oldValue: oldType,
            newValue: fieldType,
          })
        }

        if (changeCurrency) {
          updatedFields.push({
            fieldName: 'Currency',
            oldValue: oldCurrency,
            newValue: currency,
          })
        }

        if (changeOptions) {
          updatedFields.push({
            fieldName: 'Options',
            oldValue: oldOptions.join(', '),
            newValue: fieldOptions.join(', '),
          })
        }

        props.taskboard.customFields[index].title = fieldName
        props.taskboard.customFields[index].fieldType = fieldType
        props.taskboard.customFields[index].dropdownOptions = fieldOptions
        props.taskboard.customFields[index].currency = currency
        props.taskboard.customFields[index].formulaType = formulaType
        props.taskboard.customFields[index].formulaValues = [formulaValueOne?.id ?? '', formulaValueTwo?.id ?? '']

        await savePartialTaskboard({ id: props.taskboard.id, ...props.taskboard })
          .then(() => {
            const taskUpdateItem = new TaskBoardEdit({
              id: v4(),
              taskboardId: props.taskboard.id,
              taskId: '',
              accountId: currentAccount.id,
              modificationType: 'Taskboard field details',
              itemType: 'Field',
              title: `${itemClone.title}`,
              fields: updatedFields,
            })
            sendTasksboardUpdate(taskUpdateItem)
          })
          .then(props.close)
      }
    }
  }

  return (
    <>
      <Dialog
        BackdropProps={{
          timeout: 500,
        }}
        closeAfterTransition
        fullScreen={fullScreen}
        fullWidth
        maxWidth='xs'
        onClose={props.close}
        open={props.open}
        sx={{
          justifyContent: 'center',
        }}
      >
        <Stack marginX='auto' maxWidth='lg' paddingX={3} paddingY={3} spacing={2} width={1}>
          <Stack
            alignItems='center'
            direction='row'
            justifyContent='space-between'
            width='100%'
          >
            <Typography variant='h3'>
              Edit Custom Field
            </Typography>
            <IconButton onClick={() => props.close()}>
              <CloseIcon color='disabled' />
            </IconButton>
          </Stack>
          <Stack spacing={2} width={1}>
            <TextField
              fullWidth
              inputProps={{ maxLength: 50 }}
              label='Field name'
              onChange={event => setFieldName(event.target.value)}
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  void handleClick()
                }
              }}
              placeholder='Field Name'
              value={fieldName}
            />

            <Autocomplete
              id='tags-filled'
              onChange={(event, value) => setFieldType(value ?? 'Text')}
              options={fieldTypes}
              renderInput={params =>
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment:
  <InputAdornment position='start'>
    {getCustomFieldIcon(fieldType)}
  </InputAdornment>
                    ,
                  }}
                  label='Field Type'
                  placeholder='Select or type'
                  sx={{ marginTop: '16px' }}
                />}
              renderOption={(propsItem, option) =>
                <MenuItem {...propsItem}>
                  <ListItemIcon>
                    {getCustomFieldIcon(option)}
                  </ListItemIcon>
                  {option}
                </MenuItem>}
              value={fieldType}
            />

            {(fieldType === 'Dropdown' || fieldType === 'Status') &&
            <Autocomplete
              freeSolo
              id='fieldOptions'
              multiple
              onChange={(event, value) => setFieldOptions(value)}
              options={['An example option', 'A second option']}
              renderInput={params =>
                <TextField
                  {...params}
                  label='Dropdown options'
                  placeholder='Type and press enter to add options'
                  sx={{ marginTop: '16px' }}
                />}
              value={fieldOptions}
            />}

            {fieldType === 'Money' &&
            <Autocomplete
              id='currencyOptions'
              onChange={(event, value) => setCurrency(value ?? '')}
              options={currencyData.map(item => item.code)}
              renderInput={params =>
                <TextField
                  {...params}
                  label='Currency'
                  placeholder='Please select a currency'
                  sx={{ marginTop: '16px' }}
                />}
              value={currency}
            />}

            {fieldType === 'Status' &&
            <Stack spacing={1} width={1}>
              {fieldOptions.map((item, index) =>
                <Stack alignItems='center' direction='row' key={item} spacing={1}>
                  <Chip
                    color='primary'
                    label={item}
                    sx={{
                      background: colorOptions[index]
                        ? colorOptions[index]
                        : colorSelection[8],
                      width: 'fit-content',
                    }}
                    variant='filled'
                  />
                  <IconButton
                    onClick={event => {
                      setCurrentItem(index)
                      setAnchorElementField(event.currentTarget)
                    }}
                    size='small'
                  >
                    <EditRoundedIcon />
                  </IconButton>
                </Stack>)}
            </Stack>}

            {fieldType === 'Formula' &&
            <Stack alignItems='center' direction='row' spacing={1} width={1}>
              <Autocomplete
                fullWidth
                getOptionLabel={option => option.title}
                id='formulaOne'
                onChange={(event, value) => setFormulaValueOne(value ?? undefined)}
                options={props.taskboard.customFields.filter(field =>
                  field.fieldType === 'Money' || field.fieldType === 'Number')}
                renderInput={params =>
                  <TextField
                    {...params}
                    placeholder='Field 1'
                    sx={{
                      marginTop: '0px',
                    }}
                  />}
                value={formulaValueOne}
              />

              <FormulaTypeSelect
                formulaName={formulaType}
                setInputSelected={setFormulaType}
              />

              <Autocomplete
                fullWidth
                getOptionLabel={option => option.title}
                id='formulaTwo'
                onChange={(event, value) => setFormulaValueTwo(value ?? undefined)}
                options={props.taskboard.customFields.filter(field =>
                  field.fieldType === 'Money' || field.fieldType === 'Number')}
                renderInput={params =>
                  <TextField
                    {...params}
                    placeholder='Field 2'
                    sx={{ marginTop: '0px' }}
                  />}
                value={formulaValueTwo}
              />
            </Stack>}

            <Stack direction='row' justifyContent='flex-end' marginLeft='auto' marginTop={2} spacing={1} width={1}>
              <Button
                onClick={() => props.close()}
                variant='text'
              >
                Cancel
              </Button>
              <Button
                disabled={fieldName.length === 0 ||
                fieldType.length === 0 ||
                (fieldType === 'Dropdown' && fieldOptions.length === 0) ||
                (fieldType === 'Money' && currency.length === 0) ||
                (fieldType === 'Formula' && (
                  formulaType.length === 0 ||
                !formulaValueOne || !formulaValueTwo))}
                onClick={handleClick}
              >
                Edit custom field
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Dialog>
      <Menu
        anchorEl={anchorElementField}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={handleCloseField}
        open={openMenuField}
        sx={{
          '.MuiList-root': {
            paddingTop: 0,
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <Stack padding={2} spacing={1} width={370}>
          <Typography variant='h4'>
            Color
          </Typography>
          <CirclePicker
            color={colorOptions[currentItem] ?? colorSelection[8]}
            colors={colorSelection}
            onChangeComplete={
              handleColorChange
            }
            width='100%'
          />
        </Stack>
      </Menu>
    </>
  )
}

export default EditCustomField
