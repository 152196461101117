/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/cognitive-complexity */
import axios from 'axios'
import type { User } from 'firebase/auth'
import { updateEmail, updatePassword, updateProfile } from 'firebase/auth'
import type { FC } from 'react'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'

import { createAccount, getAccount, getCurrentAccount, savePartialAccount } from 'src/api/accounts'
import { getCurrentOrganisation, getOrganisation, savePartialOrganisation } from 'src/api/organisation'
import Loading from 'src/components/Loading'
import type { NewAccount } from 'src/models/Account'
import type Account from 'src/models/Account'
import type { Organisation, Permissions } from 'src/models/Organisation'
import type { OmitFirstArg, TypedCatchPromise } from 'src/types/react-app-env'
import type { FirebasePromise } from 'src/utils/firebase'
import { auth } from 'src/utils/firebase'

type FirebaseAction<
  T extends (...args: never) => TypedCatchPromise<unknown, unknown>
> = (...args: Parameters<T>) => FirebasePromise<ReturnType<T>>

type AuthContextProps = {
  currentFirebaseUser: (User & {
    updateEmail: FirebaseAction<OmitFirstArg<typeof updateEmail>>
    updatePassword: FirebaseAction<OmitFirstArg<typeof updatePassword>>
    updateProfile: FirebaseAction<OmitFirstArg<typeof updateProfile>>
  })
  currentAccount: Account
  setCurrentFirebaseUser: React.Dispatch<React.SetStateAction<(User & {
    updateEmail: FirebaseAction<OmitFirstArg<typeof updateEmail>>
    updatePassword: FirebaseAction<OmitFirstArg<typeof updatePassword>>
    updateProfile: FirebaseAction<OmitFirstArg<typeof updateProfile>>
  }) | null | undefined>>
  updateCurrentAccount: (account: NewAccount | (Partial<NewAccount> & { id: string })) => Promise<Account>
  updateCurrentOrganisation: (organisation: Partial<Organisation>)
  => Promise<Organisation>
  switchCurrentOrganisation: (id: string) => Promise<Organisation | null>
  refreshCurrentAccount: () => Promise<Account | null>
  refreshCurrentOrganisation: () => Promise<Organisation | null>
  currentOrganisation: Organisation | null | undefined
  // TODO replace with firebase authorization
  currentAccountPermissions: Permissions[] | null | undefined
}
export type EarlyAuth = Omit<AuthContextProps, 'currentAccount' | 'currentFirebaseUser'> & {
  currentAccount: AuthContextProps['currentAccount'] | null | undefined
  currentFirebaseUser: AuthContextProps['currentFirebaseUser'] | null
}

const initialValue = {
  currentFirebaseUser: null,
  currentAccount: undefined,
  currentOrganisation: null,
  currentAccountPermissions: null,
} as EarlyAuth
const AuthContext = createContext(initialValue as AuthContextProps)
AuthContext.displayName = 'AuthContext'

export const useAuth = () => useContext(AuthContext)

export const AuthProvider: FC = props => {
  const [currentFirebaseUser, setCurrentFirebaseUser] = useState<EarlyAuth['currentFirebaseUser']>()
  const [currentAccount, setCurrentAccount] = useState<EarlyAuth['currentAccount']>()
  const [currentOrganisation, setCurrentOrganisation] = useState<AuthContextProps['currentOrganisation']>(null)
  const [currentAccountPermissions, setCurrentAccountPermissions] =
    useState<AuthContextProps['currentAccountPermissions']>(null)

  // Refresh current Account and Firebase User when firebase Auth changes
  useEffect(() => auth.onAuthStateChanged(async user => {
    if (user) {
      await user.getIdToken(true)
    }
    setCurrentFirebaseUser(user
      ? {
        ...user,
        updateEmail: email => updateEmail(user, email),
        updatePassword: password => updatePassword(user, password),
        updateProfile: profileInfo => updateProfile(user, profileInfo),
      }
      : null)
    await getAccount('current')
      .then(setCurrentAccount)
  }), [])

  const startupOrg = async () => {
    currentAccount?.id &&
    await getCurrentOrganisation()
      .then(organisation => {
        setCurrentOrganisation(organisation)
        const permissions = organisation?.seats
          .find(seat => seat.account.id === currentAccount.id)?.role.permissions
        setCurrentAccountPermissions(permissions)
      })
      .catch(() => null)
  }

  // Refresh organization and permissions when the current account changes
  useEffect(() => {
    void startupOrg()
  }, [currentAccount?.id])

  const value = useMemo<EarlyAuth>(
    () => ({
      currentFirebaseUser: currentFirebaseUser ?? null,
      currentAccount,
      currentOrganisation,
      currentAccountPermissions,
      updateCurrentAccount: account =>
        (account.id
          ? savePartialAccount({ ...account, query: 'current' })
          : createAccount({ ...account })
        ).then(fetchedAccount => {
          const updatedAccount = { ...currentAccount, ...fetchedAccount }
          setCurrentAccount(updatedAccount)
          return updatedAccount
        }),
      updateCurrentOrganisation: async (organisation: Partial<Organisation>) => {
        axios.defaults.headers.common.CurrentOrganisationId = currentOrganisation?.id ?? '' // for all requests
        return savePartialOrganisation({ ...organisation, query: 'current' })
          .then(async savedOrg => {
            const updatedOrganisation = { ...currentOrganisation, ...savedOrg }
            const updatedOrgItem = await getCurrentOrganisation()
              .then(orgItem => {
                setCurrentOrganisation(orgItem)
                return orgItem
              })
            return { ...updatedOrganisation, ...updatedOrgItem }
          })
      },
      switchCurrentOrganisation: async (id: string) => {
        axios.defaults.headers.common.CurrentOrganisationId = id // for all requests
        const fetchedOrganisation = await getOrganisation(id)
        const updatedOrganisation = fetchedOrganisation ? { ...fetchedOrganisation } : null
        const permissions = updatedOrganisation?.seats
          .find(seat => seat.account.id === currentAccount?.id)?.role.permissions
        setCurrentAccountPermissions(permissions)
        await getCurrentAccount()
          .then(item =>
            setCurrentAccount(item))
        return getCurrentOrganisation()
          .then(orgItem => {
            setCurrentOrganisation(orgItem)
            return orgItem
          })
      },
      refreshCurrentOrganisation: async (setLoading = false) => {
        setLoading && setCurrentOrganisation(undefined)
        axios.defaults.headers.common.CurrentOrganisationId = currentOrganisation?.id ?? '' // for all requests
        const fetchedOrganisation = await getCurrentOrganisation()
        const updatedOrganisation = fetchedOrganisation ? { ...currentOrganisation, ...fetchedOrganisation } : null
        const permissions = updatedOrganisation?.seats
          .find(seat => seat.account.id === currentAccount?.id)?.role.permissions
        setCurrentAccountPermissions(permissions)
        setCurrentOrganisation(updatedOrganisation)
        return updatedOrganisation
      },
      refreshCurrentAccount: async (setLoading = false) => {
        setLoading && setCurrentAccount(undefined)
        const fetchedAccount = await getAccount('current')
        const updatedAccount = fetchedAccount ? { ...currentAccount, ...fetchedAccount } : null
        setCurrentAccount(updatedAccount)
        return updatedAccount
      },
      setCurrentFirebaseUser,
    }),
    [currentAccount, currentAccountPermissions, currentFirebaseUser, currentOrganisation]
  ) as AuthContextProps

  return currentFirebaseUser === undefined ||
    currentAccount === undefined ||
    currentOrganisation === undefined ||
    currentAccountPermissions === undefined
    ? <Loading />
    : <AuthContext.Provider value={value}>
      {props.children}
    </AuthContext.Provider>
}
