/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import './styles.css'

import { alpha } from '@mui/material'
import type { TextWithRemoteCursors } from '@slate-yjs/react'
import {
  getRemoteCaretsOnLeaf,
  getRemoteCursorsOnLeaf,
  useDecorateRemoteCursors,
  useUnsetCursorPositionOnBlur,
} from '@slate-yjs/react'
import {
  PlateEditable,
} from '@udecode/plate'
import type { RenderLeafProps } from 'slate-react'

import { editableProps } from './common/editableProps'
import Caret from 'src/components/pages/Notes/Caret'

type CursorData = {
  name: string
  color: string
}

const CustomEditable = () => {
  const decorate = useDecorateRemoteCursors()
  useUnsetCursorPositionOnBlur()

  return (
    <PlateEditable
      decorate={decorate}
      renderLeaf={renderDecoratedLeaf}
      {...editableProps}
    />
  )
}

const Leaf: React.FC<RenderLeafProps> = ({ attributes, children, leaf }) =>
  <span
    {...attributes}
    style={
      {
        position: 'relative',
        backgroundColor: leaf.alphaColor ??
          leaf.backgroundColor,
        color: leaf.alphaColor
          ? 'white'
          : leaf.color,
      }
    }
  >
    <Caret color={leaf.color} isForward={leaf.isForward} name={leaf.name} />
    {children}
  </span>

const renderDecoratedLeaf = (props: any) => {
  for (const cursor of getRemoteCursorsOnLeaf<CursorData, TextWithRemoteCursors<CursorData>>(props.leaf)) {
    if (cursor.data) {
      props.children =
        <span
          style={{
            background: alpha(cursor.data.color, 0.2),
          }}
        >
          {props.children}
        </span>
    }
  }

  for (const caret of getRemoteCaretsOnLeaf<CursorData, TextWithRemoteCursors<CursorData>>(props.leaf)) {
    if (caret.data) {
      props.children =
        <span
          style={{
            position: 'relative',
          }}
        >
          <span
            contentEditable={false}
            style={{
              position: 'absolute',
              top: '0px',
              bottom: '0px',
              left: '-1px',
              background: caret.data.color,
              width: '2px',
            }}
          />
          <span
            contentEditable={false}
            style={{
              background: caret.data.color,
              transform: 'translateY(-100%)',
              position: 'absolute',
              color: 'white',
              left: '-1px',
              top: '0px',
              whiteSpace: 'nowrap',
              borderRadius: '8px 8px 8px 0px',
              padding: '4px',
            }}
          >
            {caret.data.name}
          </span>
          {props.children}
        </span>
    }
  }

  return <Leaf {...props} />
}

export default CustomEditable
