
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded'
import { Card, CardActionArea,  Grid,  Stack, Tooltip, Typography, useTheme } from '@mui/material'

type Props = {
  readonly text: string
  readonly completed: boolean
  readonly secondaryText: string
  readonly icon: JSX.Element | null
  readonly action: () => void
  readonly disabled: boolean
  readonly xs: number
  readonly sm: number
  readonly md: number
  readonly lg: number
  readonly xl: number
  readonly disableIcon?: boolean
  readonly label?: string
}

const ProjectProgressItem = (props: Props) => {
  const themeColors = useTheme()
  return (
    <Grid
      height='auto'
      item
      lg={props.lg}
      md={props.md}
      sm={props.sm}
      xl={props.xl}
      xs={props.xs}
    >
      <Tooltip title={props.label ?? ''}>
        <Card
          elevation={0}
          sx={{
            height: 1,
            width: 1,
            background: theme => theme.palette.background.elevatedCard,
            borderRadius: 0,
          }}
        >
          <CardActionArea
            disabled={props.completed || props.disabled}
            onClick={() => props.action()}
            sx={{ height: 1 }}
          >
            <Stack
              direction='column'
              height={1}
              padding={1.5}
              spacing={0.5}
              width={1}
            >
              <Stack
                alignContent='center'
                alignItems='center'
                direction='row'
                spacing={1}
                width={1}
              >
                {!props.disableIcon &&
                <>
                  {props.completed
                    ? <CheckCircleRoundedIcon color='success' sx={{ fontSize: 16 }} />
                    : <RadioButtonUncheckedRoundedIcon color='action' sx={{ fontSize: 16 }} />}
                </>}
                <Typography
                  color={props.completed || props.disabled
                    ? themeColors.palette.text.disabled
                    : 'inherit'}
                  lineHeight={1.3}
                  sx={{
                    textDecoration: props.completed
                      ? 'line-through'
                      : 'none',
                    marginTop: 'auto',
                  }}
                  variant='h6'
                >
                  {props.text}
                </Typography>
                {props.icon}
              </Stack>
              <Typography
                color={props.completed || props.disabled
                  ? themeColors.palette.text.disabled
                  : themeColors.palette.text.label}
                variant='body2'
              >
                {props.secondaryText}
              </Typography>
            </Stack>
          </CardActionArea>
        </Card>
      </Tooltip>
    </Grid>
  )
}

export default ProjectProgressItem
