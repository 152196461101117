/* eslint-disable sonarjs/max-switch-cases */
/* eslint-disable complexity */
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkRoundedIcon from '@mui/icons-material/LinkRounded'
import TwitterIcon from '@mui/icons-material/Twitter'
import YouTubeIcon from '@mui/icons-material/YouTube'

import AmazonMusicIcon from 'src/components/Icons/AmazonMusicIcon'
import AnghamiLogo from 'src/components/Icons/AnghamiLogo'
import AppleMusicIcon from 'src/components/Icons/AppleMusicIcon'
import AudiomackLogo from 'src/components/Icons/AudiomackLogo'
import BamdcampLogo from 'src/components/Icons/BandcampLogo'
import BeatportLogo from 'src/components/Icons/BeatportLogo'
import ClaroLogo from 'src/components/Icons/ClaroLogo'
import DeezerIcon from 'src/components/Icons/DeezerIcon'
import DiscordIcon from 'src/components/Icons/DiscordIcon'
import EmusicLogo from 'src/components/Icons/eMusicLogo'
import GracenoteLogo from 'src/components/Icons/GracenoteLogo'
import IheartRadioLogo from 'src/components/Icons/IheartRadioLogo'
import ItunesLogo from 'src/components/Icons/ItunesLogo'
import JooxLogo from 'src/components/Icons/JooxLogo'
import KkboxLogo from 'src/components/Icons/KkboxLogo'
import NapsterLogo from 'src/components/Icons/NapsterLogo'
import NaverLogo from 'src/components/Icons/NaverLogo'
import NeteaseLogo from 'src/components/Icons/NeteaseLogo'
import NuudayLogo from 'src/components/Icons/NuudayLogo'
import PandoraIcon from 'src/components/Icons/PandoraIcon'
import QobuzLogo from 'src/components/Icons/QobuzLogo'
import QqIcon from 'src/components/Icons/QqIcon'
import QzoneIcon from 'src/components/Icons/QzoneIcon'
import SaavnLogo from 'src/components/Icons/SaavnLogo'
import ShazamIcon from 'src/components/Icons/ShazamIcon'
import SnapchatIcon from 'src/components/Icons/SnapchatIcon'
import SoundCloudIcon from 'src/components/Icons/SoundCloudIcon'
import SpotifyIcon from 'src/components/Icons/SpotifyIcon'
import TencentLogo from 'src/components/Icons/TencentLogo'
import TidalLogo from 'src/components/Icons/TidalLogo'
import TikTokIcon from 'src/components/Icons/TikTokIcon'
import TumblrIcon from 'src/components/Icons/TumblrIcon'
import TwitchIcon from 'src/components/Icons/TwitchIcon'
import VevoLogo from 'src/components/Icons/VevoLogo'
import VkIcon from 'src/components/Icons/VkIcon'
import WeChatIcon from 'src/components/Icons/WeChatIcon'
import WeiboIcon from 'src/components/Icons/WeiboIcon'

const GetIconAsset = (LinkName: string) => {
  switch (LinkName.toLowerCase()) {
    case 'spotify':
      return <SpotifyIcon sx={{ width: 20, height: 20 }} />
    case 'apple music':
      return <AppleMusicIcon sx={{ width: 20, height: 20 }} />
    case 'apple_music':
      return <AppleMusicIcon sx={{ width: 20, height: 20 }} />
    case 'itunes & apple music':
      return <AppleMusicIcon sx={{ width: 20, height: 20 }} />
    case 'deezer':
      return <DeezerIcon sx={{ width: 20, height: 20 }} />
    case 'amazon music':
      return <AmazonMusicIcon sx={{ width: 20, height: 20 }} />
    case 'amazon':
      return <AmazonMusicIcon sx={{ width: 20, height: 20 }} />
    case 'soundcloud':
      return <SoundCloudIcon sx={{ width: 20, height: 20 }} />
    case 'tiktok':
      return <TikTokIcon sx={{ width: 20, height: 20 }} />
    case 'youtube':
      return <YouTubeIcon sx={{ width: 20, height: 20 }} />
    case 'itunes':
      return <ItunesLogo sx={{ width: 20, height: 20 }} />
    case 'tidal':
      return <TidalLogo sx={{ width: 20, height: 20 }} />
    case 'pandora':
      return <PandoraIcon sx={{ width: 20, height: 20 }} />
    case 'audiomack':
      return <AudiomackLogo sx={{ width: 20, height: 20 }} />
    case 'iheartradio':
      return <IheartRadioLogo sx={{ width: 20, height: 20 }} />
    case 'vevo':
      return <VevoLogo sx={{ width: 20, height: 20 }} />
    case 'joox':
      return <JooxLogo sx={{ width: 20, height: 20 }} />
    case 'kkbox':
      return <KkboxLogo sx={{ width: 20, height: 20 }} />
    case 'napster':
      return <NapsterLogo sx={{ width: 20, height: 20 }} />
    case 'youtube music':
      return <YouTubeIcon sx={{ width: 20, height: 20 }} />
    case 'youtube content id':
      return <YouTubeIcon sx={{ width: 20, height: 20 }} />
    case 'beatport':
      return <BeatportLogo sx={{ width: 20, height: 20 }} />
    case 'bandcamp':
      return <BamdcampLogo sx={{ width: 20, height: 20 }} />
    case 'anghami':
      return <AnghamiLogo sx={{ width: 20, height: 20 }} />
    case 'facebook':
      return <FacebookIcon sx={{ width: 20, height: 20 }} />
    case 'instagram':
      return <InstagramIcon sx={{ width: 20, height: 20 }} />
    case 'twitter':
      return <TwitterIcon sx={{ width: 20, height: 20 }} />
    case '7digital':
    case 'nuuday':
      return <NuudayLogo sx={{ width: 20, height: 20 }} />
    case 'claro-musica':
      return <ClaroLogo sx={{ width: 20, height: 20 }} />
    case 'naver':
      return <NaverLogo sx={{ width: 20, height: 20 }} />
    case 'gracenote':
      return <GracenoteLogo sx={{ width: 20, height: 20 }} />
    case 'qobuz':
      return <QobuzLogo sx={{ width: 20, height: 20 }} />
    case 'tencent':
      return <TencentLogo sx={{ width: 20, height: 20 }} />
    case 'emusic':
      return <EmusicLogo sx={{ width: 20, height: 20 }} />
    case 'netease':
      return <NeteaseLogo sx={{ width: 20, height: 20 }} />
    case 'saavn':
      return <SaavnLogo sx={{ width: 20, height: 20 }} />
    case 'discord':
      return <DiscordIcon sx={{ width: 20, height: 20 }} />
    case 'qq':
      return <QqIcon sx={{ width: 20, height: 20 }} />
    case 'qzone':
      return <QzoneIcon sx={{ width: 20, height: 20 }} />
    case 'snapchat':
      return <SnapchatIcon sx={{ width: 20, height: 20 }} />
    case 'tumblr':
      return <TumblrIcon sx={{ width: 20, height: 20 }} />
    case 'twitch':
      return <TwitchIcon sx={{ width: 20, height: 20 }} />
    case 'vk':
      return <VkIcon sx={{ width: 20, height: 20 }} />
    case 'weibo':
      return <WeiboIcon sx={{ width: 20, height: 20 }} />
    case 'wechat':
      return <WeChatIcon sx={{ width: 20, height: 20 }} />
    case 'shazam':
      return <ShazamIcon sx={{ width: 20, height: 20 }} />
    default:
      return <LinkRoundedIcon sx={{ width: 20, height: 20 }} />
  }
}

export default GetIconAsset
