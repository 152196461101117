import { Button, Card, Grid, Stack, TextField, Typography, useTheme } from '@mui/material'

type Props = {
  readonly handleDowngrade: () => void
  readonly reason: string
  readonly setReason: React.Dispatch<React.SetStateAction<string>>
}

const NotUsingConfirm = (props: Props) => {
  const themeItem = useTheme()
  return (
    <Grid height={1} item width={1}>
      <Card elevation={0} sx={{ backgroundColor: theme => theme.palette.background.input }}>
        <Stack alignItems='flex-start' height={1} justifyContent='flex-start' padding={4} spacing={2} width={1}>
          <Typography lineHeight={1.2} variant='h3'>
            Explore ways releese can work for you, when you&apos;re ready
          </Typography>
          <Typography color={themeItem.palette.text.secondary} variant='body1' >
            Not every music business is ready for a releese account - and that&apos;s okay. What matters most is that
            you keep moving forward. Can we contact you in 6 months to see how things are going?
          </Typography>
          <Button color='primary' onClick={props.handleDowngrade}>Yes, please and cancel my subscription</Button>
          <TextField
            fullWidth
            label='Tell us why'
            minRows={4}
            multiline
            onChange={event => props.setReason(event.target.value)}
            placeholder='Details'
            sx={{
              marginTop: '32px!important',
              '.MuiInputBase-root': {
                backgroundColor: theme => `${theme.palette.background.default}!important`,
              },
            }}
            value={props.reason}
          />
          <Button
            color='error'
            onClick={() => props.handleDowngrade()}
          >
            Cancel subscription
          </Button>
        </Stack>
      </Card>
    </Grid>
  )
}

export default NotUsingConfirm
