import CloseIcon from '@mui/icons-material/Close'
import { Paper, Stack, Typography } from '@mui/material'

type Props = {
  readonly primary: string
  readonly secondary: string
}

const DeleteItemComponent = (props: Props) =>
  <Stack width={1}>
    <Stack alignItems='center' direction='column' spacing={2}>
      <Paper
        elevation={0}
        sx={{
          backgroundColor: theme => theme.palette.error.main,
          borderRadius: 16,
        }}
      >
        <Stack
          alignItems='center'
          justifyContent='center'
          padding={2}
        >
          <CloseIcon
            sx={{
              color: theme => theme.palette.primary.contrastText,
              fontSize: '64px',
            }}
          />
        </Stack>
      </Paper>
      <Stack width={1}>
        <Typography
          color='text.primary'
          lineHeight={1.4}
          textAlign='center'
          variant='h3'
        >
          {props.primary}
        </Typography>
        <Typography
          color='text.secondary'
          textAlign='center'
          variant='body1'
        >
          {props.secondary}
        </Typography>
      </Stack>
    </Stack>
  </Stack>

export default DeleteItemComponent
