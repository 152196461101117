import AddRoundedIcon from '@mui/icons-material/AddRounded'
import AlternateEmailRoundedIcon from '@mui/icons-material/AlternateEmailRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import DragIndicatorRoundedIcon from '@mui/icons-material/DragIndicatorRounded'
import { Button, Card, Checkbox, FormControlLabel, IconButton, Menu, MenuItem, Stack, TextField, Typography } from '@mui/material'
import { useState } from 'react'
import type { DropResult } from 'react-beautiful-dnd'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

import EditSocialOrgModal from 'src/components/pages/Links/EditLink/EditLinkModals/EditOrgSocialModal'
import GetIcon from 'src/components/pages/SmartLink/Content/GetIcon'
import { useAuth } from 'src/components/providers/AuthProvider'
import { SmartLinkSocial } from 'src/data/smartLinkData'
import type { SocialList } from 'src/models/Marketing'

const reorder = (list: SocialList[], startIndex: number, endIndex: number) => {
  const result = [...list]
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

type Props = {
  setPresaveDefaultSocialIcons: React.Dispatch<React.SetStateAction<boolean>>
  presaveDefaultSocialIcons: boolean
  socialList: SocialList[]
  setSocialList: React.Dispatch<React.SetStateAction<SocialList[]>>
}

const PreSaveSocials = (props: Props) => {
  const { currentOrganisation } = useAuth()
  const inputList = props.presaveDefaultSocialIcons
    ? currentOrganisation?.defaultSocialList ?? []
    : props.socialList

  const [openEditModal, setOpenEditModal] = useState(false)
  const filteredInputs = SmartLinkSocial.filter(item => !inputList.some(selected => selected.label === item.label))

  const findInputIndex = (labelFound: string) => inputList.findIndex(element => element.label === labelFound)

  const onDeleteByIndex = (event: React.MouseEvent<HTMLElement>) => {
    const index = findInputIndex(event.currentTarget.id)
    props.setSocialList(old => [...old.filter((item, indexItem) => indexItem !== index)])
  }

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const openMenu = Boolean(anchorElement)

  const handleClose = () => {
    setAnchorElement(null)
  }

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const index = findInputIndex(event.target.id)
    props.setSocialList(old =>
      old.map((item, indexItem) => indexItem === index
        ? { ...item, value: event.target.value }
        : item))
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    } else if (result.destination.droppableId) {
      props.setSocialList(old => reorder(old, result.source.index, result.destination?.index ?? 0))
    }
  }

  const handleDefaultIcons = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setPresaveDefaultSocialIcons(event.target.checked)
  }

  return (
    <Stack direction='column' maxWidth={896} spacing={2}>
      {openEditModal &&
        <EditSocialOrgModal
          close={() => setOpenEditModal(false)}
          open={openEditModal}
        />}
      <Stack width={1}>
        <Typography variant='h3'>
          Social icons
        </Typography>
        <Card variant='outlined'>
          <Stack padding={2} width={1}>
            <Stack direction='column' spacing={1}>
              <Stack direction='column'>
                <Typography lineHeight={1.4} variant='h4'>
                  Put yourself out there
                </Typography>
                <Typography color='text.secondary' variant='body1'>
                  Make yourself easy to find by adding your social links to your smart link
                </Typography>
              </Stack>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.presaveDefaultSocialIcons}
                    onChange={event => handleDefaultIcons(event)}
                  />
                }
                label='Use my default icons'
              />
              <Button
                color='secondary'
                onClick={() => setOpenEditModal(true)}
                startIcon={<AlternateEmailRoundedIcon />}
                sx={{
                  width: 'fit-content',
                }}
              >
                Manage my default icons
              </Button>
            </Stack>
          </Stack>
        </Card>
      </Stack>

      <Stack direction='column' maxWidth={896} spacing={2}>
        {!props.presaveDefaultSocialIcons &&
        <Stack alignItems='center' paddingTop={2}>
          <Button
            disabled={props.presaveDefaultSocialIcons}
            fullWidth
            onClick={event => setAnchorElement(event.currentTarget)}
            rounded
            size='large'
            startIcon={<AddRoundedIcon />}
            sx={{
              height: 48,
              fontSize: '1rem',
            }}
          >
            Add Social Icon
          </Button>
        </Stack>}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='presaves' key='presaves'>
            {provided =>
              <Stack {...provided.droppableProps} direction='column' ref={provided.innerRef} spacing={1}>
                {inputList.map((element, index) =>
                  <Draggable
                    draggableId={element.label}
                    index={index}
                    isDragDisabled={props.presaveDefaultSocialIcons}
                    key={element.label}
                  >
                    {providedDrag =>
                      <Stack
                        key={element.label}
                        paddingTop={2}
                        ref={providedDrag.innerRef}
                        {...providedDrag.draggableProps}

                      >
                        <Card
                          variant='outlined'
                        >
                          <Stack
                            direction='column'
                            padding={2}
                            spacing={2}
                          >
                            <Stack
                              alignItems='center'
                              direction='row'
                              justifyContent='space-between'
                              spacing={2}
                            >
                              <IconButton
                                color='default'
                                {...providedDrag.dragHandleProps}
                                disableRipple
                              >
                                <DragIndicatorRoundedIcon color='disabled' fontSize='medium' />
                              </IconButton>
                              <IconButton
                                color='default'
                                disableRipple
                                disabled={props.presaveDefaultSocialIcons}
                              >
                                {GetIcon(element.label)}
                              </IconButton>
                              <TextField
                                autoComplete='new-password'
                                disabled={props.presaveDefaultSocialIcons}
                                fullWidth
                                id={element.label}
                                onChange={handleValueChange}
                                placeholder={element.label}
                                sx={{ marginTop: 0 }}
                                value={(inputList[index].value)}
                              />
                              <IconButton
                                color='error'
                                disabled={props.presaveDefaultSocialIcons}
                                id={element.label}
                                onClick={onDeleteByIndex}
                              >
                                <CloseRoundedIcon />
                              </IconButton>
                            </Stack>
                            {!inputList[index].value.startsWith('https://') &&
                            <Typography color='error' variant='helperText'>
                              Please make sure your link starts with https://
                            </Typography>}
                          </Stack>
                        </Card>
                      </Stack>}
                  </Draggable>)}
                {provided.placeholder}
              </Stack>}
          </Droppable>
        </DragDropContext>

        <Menu
          anchorEl={anchorElement}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          onClick={handleClose}
          onClose={handleClose}
          open={openMenu}
          sx={{
            '& .MuiPaper-root': {
              maxHeight: '240px',
              minWidth: '400px',
            },
          }}
          transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        >
          {filteredInputs.map(option =>
            <MenuItem
              key={option.label}
              onClick={() => {
                props.setSocialList(old => [...old, option])
              }}
            >
              <Stack alignItems='center' direction='row' spacing={1}>
                <IconButton
                  color='default'
                  disableRipple
                  disabled={props.presaveDefaultSocialIcons}
                >
                  {GetIcon(option.label)}
                </IconButton>
                <Typography
                  sx={{ color: theme => theme.palette.text.secondary }}
                  variant='body2'
                >
                  {option.label}
                </Typography>
              </Stack>
            </MenuItem>)}
        </Menu>

      </Stack>
    </Stack>
  )
}

export default PreSaveSocials
