import { Grid, Stack, Typography } from '@mui/material'
import { useState } from 'react'

import AddOnItem from 'src/components/pages/Projects/DistributeModal/DistributeModalComponents/AddOnItem'
import AppleDigitalMasterModal from 'src/components/pages/Projects/DistributeModal/DistributeModalComponents/AppleDigitalMasterModal'
import AppleMotionModal from 'src/components/pages/Projects/DistributeModal/DistributeModalComponents/AppleMotionModal'
import AtmosModal from 'src/components/pages/Projects/DistributeModal/DistributeModalComponents/AtmosModal'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { AtmosSubmission } from 'src/models/Distribution'
import type Project from 'src/models/Project'
import type Recording from 'src/models/Recording'

type Props = {
  recordings: Recording[]
  project: Project
  presaveAddOn: boolean
  setPresaveAddOn: React.Dispatch<React.SetStateAction<boolean>>
  publishingAddOn: boolean
  setPublishingAddOn: React.Dispatch<React.SetStateAction<boolean>>
  neighboringAddOn: boolean
  setNeighboringAddOn: React.Dispatch<React.SetStateAction<boolean>>
  pubDisabled: boolean
  checkPubCover: boolean
  checkPubSplits: boolean
  appleDigitalAddOn: boolean
  setAppleDigitalAddOn: React.Dispatch<React.SetStateAction<boolean>>
  appleEmail: string
  setAppleEmail: React.Dispatch<React.SetStateAction<string>>
  atmosAddOn: boolean
  setAtmosAddOn: React.Dispatch<React.SetStateAction<boolean>>
  atmosFiles: AtmosSubmission[]
  setAtmosFiles: React.Dispatch<React.SetStateAction<AtmosSubmission[]>>
  appleMotionAddOn: boolean
  setAppleMotionAddOn: React.Dispatch<React.SetStateAction<boolean>>
  folderLink: string
  setFolderLink: React.Dispatch<React.SetStateAction<string>>
}

const motionDisabledTiers = new Set([
  undefined,
  'FREE',
  'CREATOR',
  'CREATOR_YEARLY',
  'NEW_CREATOR',
  'NEW_CREATOR_YEARLY',
])

const AddOnsDistribute = (props: Props) => {
  const { currentOrganisation } = useAuth()
  const upc = props.project.UPC.length === 0
  const isrc = props.recordings.some(item => item.ISRC.length === 0)
  const motionDisabled = motionDisabledTiers.has(currentOrganisation?.membershipTier)

  const tooltipPubDisabled = !props.checkPubCover
    ? 'Publishing is disabled when one or more of your recordings is a cover.'
    : !props.checkPubSplits
      ? 'Publishing requires your recording\'s splits to be locked and add up to 100%.'
      : undefined

  const [atmosModal, setAtmosModal] = useState(false)
  const [appleMaster, setAppleMaster] = useState(false)
  const [appleMotion, setAppleMotion] = useState(false)

  return (
    <>
      <Stack spacing={2} width={1}>
        <Stack width={1}>
          <Typography variant='h3'>
            Add-ons
          </Typography>
        </Stack>
        <Stack width={1}>
          <Grid container spacing={2}>
            <AddOnItem
              disabled={!upc}
              mediaSource='https://cdn.releese.io/onboarding/upctwo.webp'
              selected={upc}
              subtitle='Releese will generate and assign a UPC code to your project.'
              title='Generate UPC Code'
              tooltipDisabled={!upc
                ? 'UPC generation is disabled when you provide a UPC code in your project\'s metadata.'
                : undefined}
              updateSelection={() => null}
            />
            <AddOnItem
              disabled={!isrc}
              mediaSource='https://cdn.releese.io/onboarding/isrctwo.webp'
              selected={isrc}
              subtitle='Releese will generate and assign ISRC codes to your recordings.'
              title='Generate ISRC Codes'
              tooltipDisabled={!isrc
                ? 'ISRC generation is disabled when you provide a ISRC code in your recording\'s metadata.'
                : undefined}
              updateSelection={() => null}
            />
            <AddOnItem
              mediaSource='https://cdn.releese.io/onboarding/presave2.webp'
              selected={props.presaveAddOn}
              subtitle='Let your fans add your release to their library before it is available.'
              title='Pre-save Campaign'
              updateSelection={() => props.setPresaveAddOn(old => !old)}
            />
            <AddOnItem
              disabled={props.pubDisabled}
              mediaSource='https://cdn.releese.io/onboarding/pub.webp'
              selected={props.publishingAddOn}
              subtitle='Register your song on PROs and collect the publisher&apos;s share of earnings.'
              title='Publishing Admin'
              tooltipDisabled={tooltipPubDisabled}
              updateSelection={() => props.setPublishingAddOn(old => !old)}
            />
            <AddOnItem
              mediaSource='https://cdn.releese.io/onboarding/neighb.webp'
              selected={props.neighboringAddOn}
              subtitle='Collect SoundExchange and neighboring earnings from your songs.'
              title='Neighboring Admin'
              updateSelection={() => props.setNeighboringAddOn(old => !old)}
            />
            <AddOnItem
              mediaSource='https://cdn.releese.io/onboarding/adm.webp'
              selected={props.appleDigitalAddOn && props.appleEmail.length > 0}
              subtitle='Distribute your Apple Digital Masters. Studio-quality sound for everyone.'
              title='Apple Digital Masters'
              updateSelection={() => setAppleMaster(true)}
            />
            <AddOnItem
              mediaSource='https://cdn.releese.io/onboarding/atmos.webp'
              selected={props.atmosAddOn && props.atmosFiles.every(item => item.atmosFileLink.length > 0)}
              subtitle='Distribute your Atmos masters to Apple Music and Tidal.'
              title='Dolby Atmos'
              updateSelection={() => setAtmosModal(true)}
            />
            <AddOnItem
              disabled={motionDisabled}
              mediaSource='https://cdn.releese.io/onboarding/applemotion.webp'
              selected={props.appleMotionAddOn && props.folderLink.length > 0}
              subtitle='Album Motion makes your album artwork come alive on Apple Music.'
              title='Album Motion'
              tooltipDisabled={motionDisabled
                ? 'Album Motion on Apple Music is only available on the Pro and Business plans.'
                : undefined}
              updateSelection={() => setAppleMotion(true)}
            />
          </Grid>
        </Stack>
      </Stack>
      <AtmosModal
        atmosAddOn={props.atmosAddOn}
        atmosFiles={props.atmosFiles}
        close={() => setAtmosModal(false)}
        open={atmosModal}
        setAtmosAddOn={props.setAtmosAddOn}
        setAtmosFiles={props.setAtmosFiles}
      />
      <AppleDigitalMasterModal
        appleDigitalAddOn={props.appleDigitalAddOn}
        appleEmail={props.appleEmail}
        close={() => setAppleMaster(false)}
        open={appleMaster}
        setAppleDigitalAddOn={props.setAppleDigitalAddOn}
        setAppleEmail={props.setAppleEmail}
      />
      <AppleMotionModal
        appleMotionAddOn={props.appleMotionAddOn}
        close={() => setAppleMotion(false)}
        folderLink={props.folderLink}
        open={appleMotion}
        setAppleMotionAddOn={props.setAppleMotionAddOn}
        setFolderLink={props.setFolderLink}
      />
    </>
  )
}

export default AddOnsDistribute
