import CloseIcon from '@mui/icons-material/Close'
import { Button, Dialog, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { v4 } from 'uuid'

import { savePartialTaskboard } from 'src/api/taskboards'
import { sendTasksboardUpdate } from 'src/api/webSocket'
import DeleteItemComponent from 'src/components/DeleteItemComponent'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { TaskBoard } from 'src/models/Taskboard'
import { TaskBoardEdit } from 'src/models/Taskboard'

type Props = {
  close: () => void
  open: boolean
  taskboard: TaskBoard
  fieldId: string
}

const DeleteCustomFieldModalItem = (props: Props) => {
  const { currentAccount } = useAuth()
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  const handleClick = async (transferData: string) => {
    if (props.taskboard.id) {
      const title = { ...props.taskboard.customFields.find(group => group.id === transferData) }
      props.taskboard.customFields.splice(props.taskboard.customFields
        .findIndex(group => group.id === transferData), 1)
      props.taskboard.fieldOrder.splice(props.taskboard.fieldOrder
        .indexOf(transferData), 1)
      await savePartialTaskboard({ id: props.taskboard.id, ...props.taskboard })
        .then(() => {
          const taskUpdateItem = new TaskBoardEdit({
            id: v4(),
            taskboardId: props.taskboard.id,
            taskId: '',
            accountId: currentAccount.id,
            modificationType: 'Taskboard field removed',
            itemType: 'Field',
            title: `${title.title} of type ${title.fieldType}`,
          })
          sendTasksboardUpdate(taskUpdateItem)
        })
        .then(() => props.close())
    }
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='xs'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Stack paddingX={3} paddingY={3} spacing={4} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          width='100%'
        >
          <Typography variant='h3'>
            Delete Custom Field
          </Typography>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>
        <DeleteItemComponent
          primary='Are you sure?'
          secondary='This action cannot be undone'
        />
        <Stack direction='row' justifyContent='flex-end' spacing={1} width='100%'>
          <Button onClick={() => props.close()} variant='text'>Cancel</Button>
          <Button
            color='error'
            onClick={() => handleClick(props.fieldId)}
          >
            Delete custom field
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default DeleteCustomFieldModalItem
