/* eslint-disable max-lines */
/* eslint-disable sonarjs/no-duplicated-branches */
import AlbumRoundedIcon from '@mui/icons-material/AlbumRounded'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded'
import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded'
import EmojiPeopleRoundedIcon from '@mui/icons-material/EmojiPeopleRounded'
import LibraryMusicRoundedIcon from '@mui/icons-material/LibraryMusicRounded'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded'
import ScheduleSendRoundedIcon from '@mui/icons-material/ScheduleSendRounded'
import { LoadingButton } from '@mui/lab'
import { Button, Card, CardActionArea, Chip, Grid, Menu, Stack, TextField, Typography } from '@mui/material'
import { useState } from 'react'
import { v4 } from 'uuid'

import { gptTasks, savePartialTaskboard } from 'src/api/taskboards'
import { sendTasksboardUpdate } from 'src/api/webSocket'
import BillingModal from 'src/components/pages/Account/Billing/BillingModal'
import { albumRelease } from 'src/components/pages/Task/TaskboardTemplates/AlbumRelease'
import { taskboardEmptyNew } from 'src/components/pages/Task/TaskboardTemplates/EmptyNew'
import { taskboardFanEngagement } from 'src/components/pages/Task/TaskboardTemplates/FanEngagement'
import { taskboardMusicRelease } from 'src/components/pages/Task/TaskboardTemplates/MusicRelease'
import { taskboardPitchingSchedule } from 'src/components/pages/Task/TaskboardTemplates/PitchingSchedule'
import { taskboardReleaseRollout } from 'src/components/pages/Task/TaskboardTemplates/ReleaseRollout'
import { singleRelease } from 'src/components/pages/Task/TaskboardTemplates/SingleRelease'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { TaskGroup } from 'src/models/Taskboard'
import { type TaskBoard, type TaskBoardTemplate, TaskBoardEdit, TaskItem } from 'src/models/Taskboard'

type Props = {
  taskboard: TaskBoard
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>
  marginTop?: number
}

const TaskCardEmpty = (props: Props) => {
  const { currentAccount, currentOrganisation } = useAuth()
  const templates = [
    taskboardEmptyNew,
    taskboardMusicRelease,
    taskboardFanEngagement,
    taskboardPitchingSchedule,
    taskboardReleaseRollout,
    albumRelease,
    singleRelease,
  ]

  const fontSize = 120

  const [billingModal, setBillingModalOpen] = useState(false)
  const [anchorElementAi, setAnchorElementAi] = useState<HTMLElement | null>(null)
  const [prompt, setPrompt] = useState('')
  const openMenuAi = Boolean(anchorElementAi)
  const [loadingGpt, setLoadingGpt] = useState(false)

  const handleCloseAi = () => {
    setAnchorElementAi(null)
  }

  const [aiError, setAiError] = useState(false)

  const handleSubmit = async (templateItem: TaskBoardTemplate) => {
    if (props.taskboard.id) {
      const oldDate = new Date(templateItem.createdAt)
      const distanceDates = (Date.now() - oldDate.getTime()) /
      (1000 * 3600 * 24)

      for (const taskgroup of templateItem.taskGroups) {
        for (const taskItem of taskgroup.tasks) {
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          if (taskItem.dueDate && taskItem.dueDate !== null) {
            taskItem.dueDate =
              new Date(new Date(taskItem.dueDate).setDate(new Date(taskItem.dueDate).getDate() + distanceDates))
          }
        }
      }

      props.taskboard.taskGroups = templateItem.taskGroups
      props.taskboard.customFields = templateItem.customFields
      await savePartialTaskboard({ id: props.taskboard.id, ...props.taskboard })
        .then(() => {
          const taskUpdateItem = new TaskBoardEdit({
            id: v4(),
            taskboardId: props.taskboard.id,
            taskId: '',
            accountId: currentAccount.id,
            modificationType: 'Import template',
            itemType: 'Field',
            title: `${templateItem.title}`,
          })
          sendTasksboardUpdate(taskUpdateItem)
        })
        .then(() => {
          props.setRefresh(old => !old)
        })
    }
  }

  function iconRender(index: number) {
    switch (index) {
      case 0:
        return <CheckBoxOutlineBlankRoundedIcon
          sx={{
            color: theme => theme.palette.primary.contrastText,
            fontSize,
          }}
        />
      case 1:
        return <MusicNoteRoundedIcon
          sx={{
            color: theme => theme.palette.primary.contrastText,
            fontSize,
          }}
        />
      case 2:
        return <ScheduleSendRoundedIcon
          sx={{
            color: theme => theme.palette.primary.contrastText,
            fontSize,
          }}
        />
      case 3:
        return <ScheduleRoundedIcon
          sx={{
            color: theme => theme.palette.primary.contrastText,
            fontSize,
          }}
        />
      case 4:
        return <EmojiPeopleRoundedIcon
          sx={{
            color: theme => theme.palette.primary.contrastText,
            fontSize,
          }}
        />
      case 5:
        return <LibraryMusicRoundedIcon
          sx={{
            color: theme => theme.palette.primary.contrastText,
            fontSize,
          }}
        />
      case 6:
        return <AlbumRoundedIcon
          sx={{
            color: theme => theme.palette.primary.contrastText,
            fontSize,
          }}
        />
      default:
        return <MusicNoteRoundedIcon
          sx={{
            color: theme => theme.palette.primary.contrastText,
            fontSize,
          }}
        />
    }
  }

  const generateAiTasks = async () => {
    setLoadingGpt(true)
    setAiError(false)
    await gptTasks(prompt)
      .then(async items => {
        if (props.taskboard.id) {
          const emptyTaskGroup: TaskGroup = {
            id: v4(),
            title: 'Ai Prompt',
            color: '#828DF8',
            tasks: [],
          }
          for (const item of items) {
            if (item.length > 0) {
              const stringItem = item.replace(/^\d+\. /m, '').replace('- ', '')
              const singleTasks = new TaskItem({ title: stringItem, id: v4(), assignee: [] })
              emptyTaskGroup.tasks.push(singleTasks)
            }
          }
          props.taskboard.taskGroups.push(emptyTaskGroup)
          await savePartialTaskboard({ id: props.taskboard.id, ...props.taskboard })
          const taskUpdateItem = new TaskBoardEdit({
            id: v4(),
            taskboardId: props.taskboard.id,
            taskId: '',
            accountId: currentAccount.id,
            modificationType: 'Task created',
            itemType: 'Field',
          })
          sendTasksboardUpdate(taskUpdateItem)
        }
      })
      .finally(() => {
        setLoadingGpt(false)
        handleCloseAi()
        setPrompt('')
      })
      .catch(() => {
        setAiError(true)
      })
  }

  return (
    <>
      {billingModal && currentOrganisation &&
      <BillingModal
        account={currentOrganisation}
        close={() => setBillingModalOpen(false)}
        open={billingModal}
      />}
      {currentOrganisation?.membershipTier !== 'FREE' &&
      <Menu
        anchorEl={anchorElementAi}
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        onClose={handleCloseAi}
        open={openMenuAi}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
      >
        <Stack padding={2} spacing={2} width={360}>
          <Stack marginBottom={2} width={1}>
            <Stack alignItems='center' direction='row' justifyContent='space-between' width={1}>
              <Typography lineHeight={1.3} variant='h3'>
                AI Generate
              </Typography>
            </Stack>
            <Typography variant='body2'>
              Generate tasks from a simple text query and populate your Releese Taskboard.
            </Typography>
            {aiError &&
            <Typography color='error' variant='body2'>
              There was a problem generating your tasks, please try again
            </Typography>}
          </Stack>
          <TextField
            minRows={4}
            multiline
            onChange={event => setPrompt(event.target.value)}
            placeholder='Generate a marketing plan for a pop music release on streaming platforms'
            value={prompt}
          />
          <LoadingButton
            color='primary'
            loading={loadingGpt}
            onClick={() => generateAiTasks()}
            startIcon={<AutoAwesomeRoundedIcon />}
            variant='contained'
          >
            Generate tasks
          </LoadingButton>
        </Stack>
      </Menu>}

      {currentOrganisation?.membershipTier === 'FREE' &&
      <Menu
        anchorEl={anchorElementAi}
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        onClose={handleCloseAi}
        open={openMenuAi}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <Stack padding={2} spacing={2} width={360}>
          <Stack width={1}>
            <Stack alignItems='center' direction='row' justifyContent='space-between' width={1}>
              <Typography variant='h2'>
                AI Assist
              </Typography>
              <Chip
                color='success'
                label='Beta'
                variant='subtle'
              />
            </Stack>
            <Typography variant='body2'>
              Generate tasks from a simple text query and populate your Releese Taskboard using the GPT-3 model.
              Available on all paid plans
            </Typography>

          </Stack>
          <Button
            color='info'
            onClick={() => setBillingModalOpen(true)}
            startIcon={<AutoAwesomeRoundedIcon />}
          >
            Upgrade plan
          </Button>
        </Stack>
      </Menu>}
      <Stack
        alignItems='center'
        justifyContent='flex-start'
        marginTop={props.marginTop ?? 0}
        paddingBottom={2}
        paddingX={2}
        width={1}
      >
        <Grid container spacing={2}>
          <Grid item key='ai-generate' lg={4} md={4} sm={6} xl={3} xs={12}>
            <Card
              elevation={0}
              sx={{
                background: theme => theme.palette.background.elevatedCard,
              }}
            >
              <CardActionArea onClick={event => setAnchorElementAi(event.currentTarget)}>
                <Stack
                  height={160}
                  sx={{
                    background: theme => theme.palette.info.main,
                    fontSize: '150px!important',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  width={1}
                >
                  <AutoAwesomeRoundedIcon
                    sx={{
                      color: theme => theme.palette.primary.contrastText,
                      fontSize,
                    }}
                  />
                </Stack>
                <Stack padding={2} spacing={1} width={1}>
                  <Typography
                    lineHeight={1}
                    variant='h4'
                  >
                    {'AI Generate '}
                    <Chip
                      color='success'
                      label='New'
                      size='small'
                      variant='filled'
                    />
                  </Typography>
                  <Typography
                    variant='body2'
                  >
                    Generate tasks from a simple text query and populate your Releese Taskboard according to your needs.
                  </Typography>
                  <Button
                    color='info'
                    endIcon={<AutoAwesomeRoundedIcon />}
                    size='small'
                    variant='text'
                  >
                    Generate tasks
                  </Button>
                </Stack>
              </CardActionArea>
            </Card>
          </Grid>
          {templates.map((template, index) =>
            <Grid item key={template.id} lg={4} md={4} sm={6} xl={3} xs={12}>
              <Card
                elevation={0}
                sx={{
                  background: theme => theme.palette.background.elevatedCard,
                }}
              >
                <CardActionArea onClick={() => handleSubmit(template)}>
                  <Stack
                    height={160}
                    sx={{
                      background: template.color,
                      fontSize: '150px!important',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    width={1}
                  >
                    {iconRender(index)}
                  </Stack>
                  <Stack padding={2} spacing={1} width={1}>
                    <Typography
                      lineHeight={1}
                      variant='h4'
                    >
                      {template.title}
                    </Typography>
                    <Typography
                      variant='body2'
                    >
                      {template.description}
                    </Typography>
                    <Button
                      endIcon={<ArrowForwardIosRoundedIcon />}
                      size='small'
                      variant='text'
                    >
                      Import template
                    </Button>
                  </Stack>
                </CardActionArea>
              </Card>
            </Grid>)}
        </Grid>
      </Stack>
    </>
  )
}

export default TaskCardEmpty
