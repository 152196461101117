import { Card, Stack, Typography } from '@mui/material'

type Props = {
  title: string
  subtitle: string
  children: JSX.Element
  color: string
}

const SignUpItem = (props: Props) =>

  <Stack alignItems='center' justifyContent='center' >
    <Card elevation={0}>
      <Stack alignItems='center' direction='row' height={1} maxWidth={300} padding={2} spacing={2}>
        <Stack
          height='fit-content'
          padding={1}
          sx={{
            background: props.color,
            borderRadius: 1,
          }}
        >
          {props.children}
        </Stack>
        <Stack direction='column' height={1} justifyContent='center'>
          <Stack alignItems='center' direction='row' spacing={1}>
            <Typography color='text.primary' lineHeight={1.2} variant='h3'>
              {props.title}
            </Typography>
          </Stack>
          <Typography variant='textLabel'>
            {props.subtitle}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  </Stack>

export default SignUpItem
