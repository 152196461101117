import Autocomplete from '@mui/material/Autocomplete'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import type { SyntheticEvent } from 'react'

type Props = {
  setInputSelected: (item: string[]) => void
  tags: string[]
}

const MarketingLinkTagsField = (props: Props) => {
  const handleChange = (event: SyntheticEvent, value: string[]) => {
    props.setInputSelected(value)
  }
  return (
    <Stack direction='column' spacing={1} sx={{ width: 1 }} >
      <Autocomplete
        freeSolo
        id='tags-filled'
        limitTags={3}
        multiple
        onChange={handleChange}
        options={tagsList.map(option => option.label)}
        renderInput={params =>
          <TextField
            sx={{ marginTop: 0 }}
            {...params}
            label='Marketing link tags'
            placeholder='Select or type'
          />}
        value={props.tags}
      />
    </Stack>
  )
}

export default MarketingLinkTagsField

const tagsList = [
  { label: 'Production' },
  { label: 'Social media' },
  { label: 'Content creation' },
  { label: 'Blog' },
  { label: 'Music video' },
]
