import SimpleTour from 'src/components/pages/Tour/TourComponents/SimpleTour'

const OpenRecordingsMetadataTour = () =>
  <SimpleTour
    description='Open your metadata to add your credits and splits.'
    desktopDirection='left'
    mobileDirection='bottom'
    onboardingKey='recordingMetadata'
    selector='open-metadata'
    title='Setup your recordings'
  />

export default OpenRecordingsMetadataTour
