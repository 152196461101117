import CloseIcon from '@mui/icons-material/Close'
import { Button, Card, CardActionArea, Dialog, IconButton, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import type { ChangeEvent } from 'react'
import { useState } from 'react'
import type { ColorResult } from 'react-color'
import { CirclePicker } from 'react-color'
import { v4 } from 'uuid'

import { updateWorkspace } from 'src/api/taskboards'
import { sendWorkspaceUpdate } from 'src/api/webSocket'
import { colorSelection } from 'src/components/pages/Task/TaskMenu/TaskMenuComponents/ColorsForPicker'
import { taskboardIcons } from 'src/components/pages/Task/TaskViews/TaskViewComponents/TaskboardIcons'
import { Workspace } from 'src/models/Taskboard'

type Props = {
  close: () => void
  open: boolean
  refreshBoards: () => void
  workspaceItem: Workspace
}

const EditWorkspaceModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  const [groupName, setGroupName] = useState(props.workspaceItem.title)
  const [boardColor, setBoardColor] = useState(props.workspaceItem.color)
  const [boardIcon, setBoardIcon] = useState(props.workspaceItem.icon)

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setGroupName(event.target.value)
  }

  const handleColorChange = (color: ColorResult) => {
    setBoardColor(color.hex)
  }

  const handleClick = async () => {
    const newGroup = new Workspace({
      title: groupName,
      color: boardColor,
      icon: boardIcon,
      id: props.workspaceItem.id,
    })
    await updateWorkspace(newGroup)
      .then(() => sendWorkspaceUpdate(v4()))
      .then(props.refreshBoards)
    props.close()
    setGroupName('')
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='xs'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
        width: 1,
      }}
    >
      <Stack paddingX={3} paddingY={3} spacing={2} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          width='100%'
        >
          <Typography variant='h3'>Update a Workspace</Typography>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>
        <Stack width='100%'>
          <TextField
            fullWidth
            inputProps={{ maxLength: 50 }}
            label='Name your workspace'
            onChange={handleNameChange}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                void handleClick()
              }
            }}
            placeholder='Workspace name'
            value={groupName}
          />
        </Stack>
        <Stack spacing={1}>
          <Typography sx={{ marginTop: 0 }} variant='inputLabel'>
            Pick a color
          </Typography>
          <CirclePicker
            color={boardColor}
            colors={colorSelection}
            onChangeComplete={handleColorChange}
            width='100%'
          />
        </Stack>
        <Stack width={1}>
          <Stack direction='row' width={1}>
            <Typography sx={{ marginTop: 0 }} variant='inputLabel'>
              Icon
            </Typography>
          </Stack>
          <Stack direction='row' flexWrap='wrap' width={1}>
            {taskboardIcons.map((icon, index) =>
              <Card
                key={v4()}
                sx={{
                  borderColor: boardIcon === index ? null : 'transparent',
                  borderCollapse: 'collapse',
                }}
                variant='outlined'
              >
                <CardActionArea onClick={() => setBoardIcon(index)}>
                  <IconButton>
                    {icon}
                  </IconButton>
                </CardActionArea>
              </Card>)}
          </Stack>
        </Stack>
        <Stack direction='row' justifyContent='flex-end' spacing={1} width='100%'>
          <Button onClick={() => props.close()} variant='text'>Cancel</Button>
          <Button
            onClick={() => handleClick()}
          >
            Update workspace
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default EditWorkspaceModal
