/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useMemo, useState } from 'react'

import { getNotesComments } from 'src/api/taskboards'
import { initializeNotesComments, subscribeToNotesComments, subscribeToNotesDeleted, subscribeToNotesUpdated } from 'src/api/webSocket'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { NotesCommentDto } from 'src/models/Notes'
import { NotesComment } from 'src/models/Notes'

type NotesCommentsProviderContextProps = {
  notesComments: NotesComment[]
  fetchUpdates: (notesId: string) => Promise<void>
}

export type InitProps = Omit<NotesCommentsProviderContextProps,
'notesComments'> & {
  notesComments: NotesCommentsProviderContextProps['notesComments'] | []
}

const initialValue = {
  notesComments: [],
} as InitProps

const NotesCommentsContext = createContext(initialValue as NotesCommentsProviderContextProps)

export const useNotesComments = () => useContext(NotesCommentsContext)

type Props = {
  children?: React.ReactNode
  notesId: string
}

export const NotesCommentsProvider = (props: Props) => {
  const { currentOrganisation } = useAuth()
  const [notesComments, setNotesComments] = useState<InitProps['notesComments']>([])
  const [latestNotification, setLatestNotification] = useState<NotesComment>()
  const [deletedNotification, setDeletedNotification] = useState<string>()
  const [modifiedNotification, setModifiedNotification] = useState<NotesComment>()

  useEffect(() => {
    void subscribe()
  }, [currentOrganisation?.id, props.notesId])

  const getNotesdWithRefresh = async () => {
    await initializeNotesComments(props.notesId)
      .then(socketItem => {
        subscribeToNotesComments(socketItem, (error: unknown, data?: NotesCommentDto) => {
          if (error) return

          if (data) {
            setLatestNotification(new NotesComment(data))
          }
        })
        subscribeToNotesDeleted(socketItem, (error: unknown, data?: string) => {
          if (error) return

          if (data) {
            setDeletedNotification(data)
          }
        })
        subscribeToNotesUpdated(socketItem, (error: unknown, data?: NotesCommentDto) => {
          if (error) return

          if (data) {
            setModifiedNotification(new NotesComment(data))
          }
        })
        socketItem?.io.once('reconnect', async () => {
          socketItem?.removeAllListeners('newNoteComment')
          socketItem?.removeAllListeners('commentDeleted')
          socketItem?.removeAllListeners('commentUpdated')
          await getNotesdWithRefresh()
        })
      })
  }

  const subscribe = async () => {
    await fetchUpdates(props.notesId)
      .then(() => void getNotesdWithRefresh())
  }

  useEffect(() => {
    if (latestNotification && latestNotification.documentNoteId === props.notesId) {
      setNotesComments(previousState => previousState && previousState.length > 0
        ? [...previousState, latestNotification]
        : [latestNotification])
    }
  }, [latestNotification])

  useEffect(() => {
    if (deletedNotification) {
      setNotesComments(previousState => previousState.filter(item => item.id !== deletedNotification))
    }
  }, [deletedNotification])

  useEffect(() => {
    if (modifiedNotification) {
      setNotesComments(previousState => previousState.map(item => item.id === modifiedNotification.id
        ? modifiedNotification
        : item))
    }
  }, [modifiedNotification])

  const fetchUpdates = async (notesId: string) => {
    if (notesId.length > 0) {
      await getNotesComments(notesId)
        .then(setNotesComments)
    }
  }

  const value = useMemo<InitProps>(
    () => ({
      fetchUpdates,
      notesComments,
    }),
    [notesComments]
  ) as NotesCommentsProviderContextProps

  return (
    <NotesCommentsContext.Provider value={value}>
      {props.children}
    </NotesCommentsContext.Provider>
  )
}
