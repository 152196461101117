/* eslint-disable react-hooks/exhaustive-deps */
import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded'
import CreateRoundedIcon from '@mui/icons-material/CreateRounded'
import LinkRoundedIcon from '@mui/icons-material/LinkRounded'
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded'
import { IconButton, Skeleton, Stack, TableCell, TableRow, Tooltip } from '@mui/material'

const LinksItemLoading = () =>
  <TableRow
    hover
    sx={{
      textDecoration: 'none',
      '.MuiTableCell-root': { borderBottomColor: 'divider' },
    }}
  >
    <TableCell id='project_image'>
      <Skeleton height={64} sx={{ borderRadius: 1 }} variant='rectangular' width={64} />
    </TableCell>
    <TableCell id='project_title'>
      <Stack>
        <Skeleton variant='text' width={85} />
        <Skeleton variant='text' width={40} />
      </Stack>
    </TableCell>
    <TableCell id='status'>
      <Skeleton variant='text' width={40} />
    </TableCell>

    <TableCell id='release_date'>
      <Stack alignItems='center' direction='row' spacing={2}>
        <Skeleton variant='text' width={40} />
      </Stack>
    </TableCell>

    <TableCell>
      <Stack alignItems='center' direction='row' spacing={1}>
        <Tooltip title='Edit link'>
          <IconButton >
            <CreateRoundedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title='Analytics'>
          <IconButton >
            <AnalyticsRoundedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title='Copy link'>
          <IconButton >
            <LinkRoundedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title='More'>
          <IconButton >
            <MoreHorizRoundedIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    </TableCell>
  </TableRow>

export default LinksItemLoading
