import { Stack, Typography } from '@mui/material'

import EditOrgLinkSocial from 'src/components/pages/Links/EditLink/EditLinkModals/EditOrgDefaultSocial'

const Social = () =>
  <Stack padding={2} width={1}>
    <Stack padding={2} width={1}>
      <Typography lineHeight={1.2} variant='h3'>Social Icons</Typography>
      <Typography color='text.label' maxWidth={640} variant='body1'>
        {'Your default social icons will appear on your Landing Pages '}
      </Typography>
    </Stack>
    <EditOrgLinkSocial />
  </Stack>

export default Social
