import { Stack } from '@mui/material'

import AnalyticsStatement from 'src/components/pages/Analytics/AnalyticsTabs/AnalyticsStatement'
import type Project from 'src/models/Project'

type Props = {
  project?: Project
}

const ViewProjectAnalytics = (props: Props) =>
  <Stack height={1} width={1}>
    <AnalyticsStatement
      project={props.project}
    />
  </Stack>

export default ViewProjectAnalytics
