/* eslint-disable complexity */
import { Stack } from '@mui/material'

import RenderAnalyticsCardsProfile from 'src/components/pages/Audience/Components/Audience/RenderProfileCards'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { AnalyticsCurrentData, AnalyticsHistoricData, AnalyticsProfileData } from 'src/models/Analytics'

type Props = {
  currentData?: AnalyticsCurrentData
  historicData?: AnalyticsHistoricData
  profileData?: AnalyticsProfileData
}

const AnalyticsProfileUi = (props: Props) => {
  const { currentOrganisation } = useAuth()
  return (
    <Stack paddingBottom={4} spacing={4} width={1}>
      <RenderAnalyticsCardsProfile
        artworkUrl={currentOrganisation?.logoUrl ?? props.profileData?.profileData.avatar ?? ''}
        currentData={props.currentData}
        historicData={props.historicData}
      />
    </Stack>
  )
}

export default AnalyticsProfileUi
