import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import DragIndicatorRoundedIcon from '@mui/icons-material/DragIndicatorRounded'
import {  Card, IconButton, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'

import GetIcon from 'src/components/pages/SmartLink/Content/GetIcon'
import type { MarketingLink, SocialList } from 'src/models/Marketing'

type Props = {
  smartLinkData: MarketingLink
  element: SocialList
  index: number
  onDeleteByIndex: (event: React.MouseEvent<HTMLElement>) => void
  handleValueChange: (value: SocialList, index: number) => void
}

const EditLinkSocialItem = (props: Props) => {
  const [value, setValue] = useState(props.element)

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    setValue(previousState => {
      const newItem = { ...previousState, value: newValue }

      props.handleValueChange(newItem, props.index)

      return newItem
    })
  }

  return (
    <Draggable
      draggableId={props.element.label}
      index={props.index}
      isDragDisabled={props.smartLinkData.useOrgDefaultSocialIcons}
      key={props.element.label}
    >
      {providedDrag =>
        <Stack
          key={props.element.label}
          paddingTop={2}
          ref={providedDrag.innerRef}
          {...providedDrag.draggableProps}
        >
          <Card
            variant='outlined'
          >
            <Stack
              direction='column'
              padding={2}
              spacing={2}
            >
              <Stack
                alignItems='center'
                direction='row'
                justifyContent='space-between'
                spacing={2}
              >
                <Tooltip title='Drag to move'>
                  <IconButton
                    {...providedDrag.dragHandleProps}
                    disabled={props.smartLinkData.useOrgDefaultSocialIcons}
                    size='small'
                  >
                    <DragIndicatorRoundedIcon
                      fontSize='medium'
                    />
                  </IconButton>
                </Tooltip>
                <IconButton
                  color='default'
                  disableRipple
                  disabled={props.smartLinkData.useOrgDefaultSocialIcons}
                >
                  {GetIcon(props.element.label)}
                </IconButton>
                <TextField
                  autoComplete='new-password'
                  disabled={props.smartLinkData.useOrgDefaultSocialIcons}
                  fullWidth
                  id={props.element.label}
                  onChange={onChange}
                  placeholder={props.element.label}
                  sx={{ marginTop: 0 }}
                  value={value.value}
                />
                <Tooltip title='Remove social icon'>
                  <IconButton
                    color='error'
                    disabled={props.smartLinkData.useOrgDefaultSocialIcons}
                    id={props.element.label}
                    onClick={props.onDeleteByIndex}
                    size='small'
                  >
                    <CloseRoundedIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
              {!value.value.startsWith('https://') &&
              <Typography color='error' variant='helperText'>
                Please make sure your link starts with https://
              </Typography>}
            </Stack>
          </Card>
        </Stack>}
    </Draggable>
  )
}

export default EditLinkSocialItem
