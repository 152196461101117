import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import { Avatar, Badge, Box, Button, Grid, Stack, Tooltip, Typography } from '@mui/material'

import type { RightsHolder } from 'src/models/Recording'

type Props = {
  rightsholder: RightsHolder
}

const ViewUserSplit = (props: Props) =>
  <Box
    border={1}
    borderColor={theme => theme.palette.border}
    borderRadius={theme => theme.shape.inputRadius}
    marginTop={3}
    padding={2}
  >
    <Grid columns={16} container>
      <Grid item lg={2} md={2} sm={3} xl={2} xs={3}>
        <Stack height={1} justifyContent='center'>
          <Typography variant='subtitle2'>
            {`${props.rightsholder.ownership} %`}
          </Typography>
        </Stack>
      </Grid>
      <Grid item lg={6} md={6} sm={10} xl={6} xs={10}>
        <Stack alignItems='center' direction='row' spacing={0.5} width={1}>
          {props.rightsholder.status === 'Locked' &&
            <Tooltip title='Confirmed share'>
              <CheckCircleRoundedIcon color='success' fontSize='small' />
            </Tooltip>}
          <Button
            color='inherit'
            sx={{
              textDecoration: 'none',
              color: 'inherit',
            }}
            variant='text'
          >
            <Stack alignItems='center' direction='row' spacing={1.5} width={1}>
              <Badge
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                badgeContent={<CheckCircleRoundedIcon
                  color='secondary'
                  sx={{
                    backgroundColor: 'white',
                    borderRadius: '50%',
                    width: 12,
                    height: 12,
                    fontSize: 8,
                  }}
                />}
                overlap='circular'
                sx={{
                  '.MuiBadge-badge': {
                    display: props.rightsholder.account.verified ? undefined : 'none',
                  },
                }}
              >
                <Avatar
                  src={props.rightsholder.account.logoUrl}
                  sx={{ width: 24, height: 24 }}
                />
              </Badge>
              <Stack direction='column' width={1}>
                <Typography textAlign='left' variant='body2'>
                  {props.rightsholder.account.name}
                </Typography>
                <Typography textAlign='left' variant='helperText'>
                  {props.rightsholder.account.organisationEmail}
                </Typography>
              </Stack>
            </Stack>
          </Button>
        </Stack>
      </Grid>
    </Grid>
  </Box>

export default ViewUserSplit
