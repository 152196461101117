import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, IconButton, Paper, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'

import { supportRequestArtistMapping } from 'src/api/distribution'
import DistributionProfileArtistItem from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/DistributionTab/DistributionProfileArtistItem'
import type { ArtistToUpdate, Distribution } from 'src/models/Distribution'

type Props = {
  close: () => void
  open: boolean
  distributionItem: Distribution
}

const DistributionProfileSupportModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))
  const [artists, setArtists] = useState<ArtistToUpdate[]>([])
  const [done, setDone] = useState(false)

  const artistsList = useMemo(() => [...new Set(
    [...props.distributionItem.recordings.flatMap(item => item.primaryArtists.map(artist => artist.name)),
      ...props.distributionItem.recordings.flatMap(item => item.featuredArtists.map(artist => artist.name))]
  )], [props.distributionItem])

  useEffect(() => {
    setArtists(artistsList.map(artistItem => ({
      artistName: artistItem,
      profile: [],
    })))
  }, [artistsList])

  const disabledItems =
    artists.every(item => item.profile.every(profile => profile.link.length > 0)) &&
    artists.some(item => item.profile.length > 0)

  const [loading, setLoading] = useState(false)

  const submitRequest = async () => {
    setLoading(true)
    await supportRequestArtistMapping(props.distributionItem.projectId ?? '', artists)
      .finally(() => {
        setLoading(false)
        setDone(true)
      })
      .catch(() => setLoading(false))
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='sm'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      {done
        ? <Stack paddingX={3} paddingY={3} spacing={2} width={1}>
          <Stack width={1}>
            <Stack
              alignItems='center'
              direction='row'
              justifyContent='space-between'
              width={1}
            >
              <Typography variant='h3'>
                Get Support
              </Typography>
              <IconButton onClick={() => props.close()}>
                <CloseIcon color='disabled' />
              </IconButton>
            </Stack>
            <Stack width={1}>
              <Typography
                color='text.secondary'
                lineHeight={1.2}
                maxWidth='sm'
                variant='body1'
              >
                The best way to resolve your request is through our one-on-one customer support
              </Typography>
            </Stack>
          </Stack>
          <Stack alignItems='center' height={1} justifyContent='center' width={1} >
            <Paper
              elevation={0}
              sx={{
                backgroundColor: theme => theme.palette.success.main,
                borderRadius: 16,
              }}
            >
              <Stack
                alignItems='center'
                justifyContent='center'
                padding={2}
              >
                <CheckCircleRoundedIcon
                  sx={{
                    color: theme => theme.palette.primary.contrastText,
                    fontSize: '64px',
                  }}
                />
              </Stack>
            </Paper>
            <Typography
              color={themeItem.palette.text.secondary}
              sx={{
                marginTop: 1,
              }}
              variant='h4'
            >
              We have received your request
            </Typography>
            <Typography
              color={themeItem.palette.text.label}
              sx={{
                textAlign: 'center',
              }}
              variant='body1'
            >
              Our support team will get in touch with you as soon as possible
            </Typography>
            <Button
              color='primary'
              fullWidth
              onClick={() => props.close()}
              sx={{
                marginTop: 2,
              }}
            >
              Done
            </Button>
          </Stack>
        </Stack>
        : <Stack paddingX={3} paddingY={3} spacing={4} width={1}>
          <Stack width={1}>
            <Stack
              alignItems='center'
              direction='row'
              justifyContent='space-between'
              width={1}
            >
              <Typography variant='h3'>
                Profile Mapping
              </Typography>
              <IconButton onClick={() => props.close()}>
                <CloseIcon color='disabled' />
              </IconButton>
            </Stack>
            <Stack width={1}>
              <Typography color='text.secondary' lineHeight={1.2} maxWidth='sm' variant='body1'>
                Select the platforms on which your release is linked to the wrong artist profile
              </Typography>
            </Stack>
            <Stack spacing={2} width={1}>
              {artists.map((artistItem, index) =>
                <DistributionProfileArtistItem
                  artistItem={artistItem}
                  distributionItem={props.distributionItem}
                  index={index}
                  key={artistItem.artistName}
                  setArtists={setArtists}
                />)}
            </Stack>
          </Stack>
          <Stack direction='row' justifyContent='flex-end' spacing={1} width='100%'>
            <Button onClick={() => props.close()} variant='text'>
              Cancel
            </Button>
            <LoadingButton
              color='primary'
              disabled={!disabledItems}
              loading={loading}
              onClick={() => void submitRequest()}
              variant='contained'
            >
              Submit Update
            </LoadingButton>
          </Stack>
        </Stack>}
    </Dialog>
  )
}

export default DistributionProfileSupportModal
