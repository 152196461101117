/* eslint-disable react-hooks/exhaustive-deps */
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import { Avatar, AvatarGroup, Card, CardActionArea, CardContent, CardMedia, Chip, IconButton, ListItemIcon, Menu, MenuItem, Paper, Stack, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'

import { getDistributionByProjectId } from 'src/api/distribution'
import DeleteProjectModal from 'src/components/pages/Projects/ProjectTabs/ProjectsTab/DeleteProjectModal'
import { getColor } from 'src/components/pages/Projects/ViewProject/ViewProjectDetails'
import { useAuth } from 'src/components/providers/AuthProvider'
import { NavLink } from 'src/components/SafeRouterLink'
import type { Distribution } from 'src/models/Distribution'
import { Permissions } from 'src/models/Organisation'
import type Project from 'src/models/Project'

type Props = {
  project: Project
  updateProjects: () => Promise<void>
}

const ProjectGridItem = (props: Props) => {
  const themeItem = useTheme()
  const mobile = useMediaQuery(themeItem.breakpoints.down('md'))
  const { currentOrganisation, currentAccountPermissions } = useAuth()
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const [deleteProjectModal, setDeleteProjectModalOpen] = useState(false)
  const [distributionItem, setDistributionItem] = useState<Distribution>()
  const openMenu = Boolean(anchorElement)

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorElement(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorElement(null)
  }

  useEffect(() => {
    if (props.project.distributed) {
      void getDistributionByProjectId(props.project.id)
        .then(setDistributionItem)
        .catch(() => null)
    }
  }, [currentOrganisation?.id, props.project.id])

  const recordingString = `${props.project.recordingCount} ${props.project.recordingCount === 1
    ? 'Recording'
    : 'Recordings'}`

  return (
    <>
      {!props.project.distributed &&
      props.project.organisation?.id === currentOrganisation?.id &&
      <DeleteProjectModal
        close={() => setDeleteProjectModalOpen(false)}
        open={deleteProjectModal}
        project={props.project}
        updateProjects={props.updateProjects}
      />}
      <Card
        sx={{
          width: 'auto',
          minHeight: 200,
          maxHeight: '100%',
          margin: mobile ? 0.5 : 1,
        }}
        variant='outlined'
      >
        <CardActionArea
          component={NavLink}
          sx={{
            height: 1,
            width: 1,
          }}
          to={`/project/${props.project.id}`}
        >
          {props.project.artworkPreviewUrl
            ? <CardMedia
              alt='Artwork'
              component='img'
              src={props.project.artworkPreviewUrl}
              sx={{
                objectFit: 'cover',
                aspectRatio: '1/1',
                height: 'auto',
              }}
              width='200px'
            />
            : <Paper
              elevation={0}
              sx={{
                height: 'auto',
                width: 1,
                borderRadius: 0,
                backgroundColor: theme => theme.palette.background.input,
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                aspectRatio: '1/1',
              }}
            >
              <MusicNoteRoundedIcon
                sx={{
                  fontSize: 150,
                  color: theme => theme.palette.action.disabled,
                }}
              />
            </Paper>}
          <CardContent sx={{ width: 1, padding: mobile ? 1.5 : 2 }}>
            <Stack width={1}>
              <Typography
                fontSize='1.15rem'
                fontWeight={600}
                lineHeight={1.3}
                variant='h4'
              >
                {props.project.title}
              </Typography>
              <Typography
                sx={{ lineHeight: 1.4 }}
                variant='body1'
              >
                {props.project.primaryArtists.map(item => item.name).join(', ')}
              </Typography>
              {!mobile &&
              <Typography color={themeItem.palette.text.label} variant='body2'>
                {recordingString}
              </Typography>}
              {!mobile &&
              <Stack direction='row' marginTop={1}>
                <AvatarGroup
                  max={4}
                  sx={{
                    '.MuiAvatarGroup-avatar': {
                      width: 24,
                      height: 24,
                      fontSize: 12,
                    },
                  }}
                >
                  <Tooltip title={props.project.organisation?.name}>
                    <Avatar
                      alt={props.project.organisation?.name}
                      key={props.project.organisation?.id}
                      src={props.project.organisation?.logoUrl}
                    />
                  </Tooltip>
                  {props.project.collaborators.map(collaborator =>
                    <Tooltip
                      key={collaborator.account.id}
                      title={collaborator.account.name}
                    >
                      <Avatar
                        alt={collaborator.account.name}
                        src={collaborator.account.logoUrl}
                      />
                    </Tooltip>)}
                </AvatarGroup>
              </Stack>}
              <Stack direction='row' justifyContent='space-between' marginTop={1} width={1}>
                <Stack alignItems='center' direction='row' spacing={1}>
                  <Chip
                    color={getColor(props.project.status)}
                    label={props.project.status}
                    sx={{
                      width: 'fit-content',
                    }}
                    variant='subtle'
                  />
                  {props.project.distributed
                    ? <Tooltip
                      title={distributionItem?.status === 'Distributed'
                        ? 'This release is distributed on Releese'
                        : distributionItem?.status === 'Taken Down'
                          ? 'This release has been taken down'
                          : distributionItem?.status === 'Under review'
                            ? 'This release is under review'
                            : distributionItem?.status === 'Changes requested'
                              ? 'Changes are requested, please check your email'
                              : ''}
                    >
                      <CheckCircleRoundedIcon
                        color={distributionItem?.status === 'Distributed'
                          ? 'success'
                          : distributionItem?.status === 'Taken Down'
                            ? 'error'
                            : distributionItem?.status === 'Under review'
                              ? 'primary'
                              : distributionItem?.status === 'Changes requested'
                                ? 'warning'
                                : 'inherit'}
                        fontSize='large'
                      />
                    </Tooltip> : null}
                </Stack>
                <IconButton
                  onClick={event => handleMenuClick(event)}
                  size='small'
                >
                  <MoreHorizRoundedIcon />
                </IconButton>
              </Stack>
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
      <Menu
        anchorEl={anchorElement}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClick={handleClose}
        onClose={handleClose}
        open={openMenu}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <MenuItem
          disabled={props.project.distributed ||
            !currentAccountPermissions?.includes(Permissions.CREATE_EDIT_REMOVE_PROJECTS) ||
            props.project.organisation?.id !== currentOrganisation?.id}
          onClick={() => setDeleteProjectModalOpen(true)}
        >
          <ListItemIcon>
            <DeleteRoundedIcon color='error' fontSize='medium' />
          </ListItemIcon>
          Delete Release
        </MenuItem>
      </Menu>
    </>
  )
}

export default ProjectGridItem
