import ReceiptRoundedIcon from '@mui/icons-material/ReceiptRounded'
import { Button, Grid, Stack, Typography } from '@mui/material'

import { NavLink } from 'src/components/SafeRouterLink'

const SubscriptionCancelled = () =>
  <Grid height={1} item width={1}>
    <Stack alignItems='center' height={1} justifyContent='center' spacing={2} width={1}>
      <Stack alignItems='center' justifyContent='center' maxWidth={750} padding={3} spacing={2} width={1}>
        <ReceiptRoundedIcon color='secondary' sx={{ fontSize: 128 }} />
        <Typography textAlign='center' variant='h3'>Your subscription was successfully cancelled</Typography>
        <Typography textAlign='center' variant='body1'>
          You will continue to have access to your subscription until the end of your billing cycle.
        </Typography>
        <Typography color='text.secondary' textAlign='center' variant='body2'>
          Your data and tools outside the free plan will be saved and become accessible
          if you re-activate your subscription.
        </Typography>
        <Button component={NavLink} to='/'>Go to dashboard</Button>
      </Stack>
    </Stack>
  </Grid>

export default SubscriptionCancelled
