import { TextField } from '@mui/material'
import { useState } from 'react'

type Props = {
  title: string
  handleTitleChange: (value: string) => void
}

const TaskDrawerTitle = (props: Props) => {
  const [title, setTitle] = useState(props.title)
  return (
    <TextField
      autoComplete='disabled'
      fullWidth
      id='task-title'
      inputProps={{ maxLength: 50, style: { fontSize: 22, fontWeight: 500 } }}
      onBlur={() => props.handleTitleChange(title)}
      onChange={event => setTitle(event.target.value)}
      placeholder='Name this task'
      value={title}
      variant='standard'
    />
  )
}
export default TaskDrawerTitle
