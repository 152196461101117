/* eslint-disable max-len */
/* eslint-disable sonarjs/no-nested-template-literals */
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import CloudDownloadRoundedIcon from '@mui/icons-material/CloudDownloadRounded'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticonRounded'
import SendIcon from '@mui/icons-material/SendRounded'
import { Button, Divider,  IconButton, InputAdornment, ListItemIcon, Menu, MenuItem, Stack, TextField, Typography, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import type { BaseEmoji } from 'emoji-mart'
import { useState } from 'react'
import { v4 } from 'uuid'

import { baseUrlLinksApi, updateFile } from 'src/api/files'
import EmojiPicker from 'src/components/form-elements/EmojiPicker'
import EditFileDescriptionModal from 'src/components/pages/Files/Components/EditFileDescriptionModal'
import { renderIcon } from 'src/components/pages/Files/Components/FileItemView'
import { imageFiles } from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/FilesTab/PreviewFile'
import TaskDrawerUpdate from 'src/components/pages/Task/TaskDrawer/TaskDrawerUpdate'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { FileItem } from 'src/models/File'
import { FileUpdate } from 'src/models/File'
import { bytesToSize } from 'src/utils/fileUtils'

type Props = {
  closeTaskDrawer: () => void
  setFileObject: React.Dispatch<React.SetStateAction<FileItem | undefined>>
  fileItem?: FileItem
}

const FileDrawerComponent = (props: Props) => {
  const themeItem = useTheme()
  const regexExtension = /\w{3,4}($|\?)/
  const fileExtension = regexExtension.exec(props.fileItem?.title ?? '')
  const { currentAccount } = useAuth()
  const [valueTaskData, setTaskDataValue] = useState(props.fileItem)
  const [anchorElementEmoji, setAnchorElementEmoji] = useState<HTMLElement | null>(null)
  const openEmojiMenu = Boolean(anchorElementEmoji)
  const [description, setDescription] = useState(false)

  const handleEmojiClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElementEmoji(event.currentTarget)
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
  }

  const [anchorElementMore, setAnchorElementMore] = useState<HTMLElement | null>(null)
  const openMoreMenu = Boolean(anchorElementMore)

  const handleMoreClose = () => {
    setAnchorElementMore(null)
  }

  const [messageText, setMessageText] = useState('')
  const handleEmojiClose = () => {
    setAnchorElementEmoji(null)
  }

  const addEmoji = (event: BaseEmoji) => {
    const symbol = event.native
    setMessageText(`${messageText + symbol} `)
  }

  const handleSendMessage = async () => {
    if (messageText.length > 0 && valueTaskData) {
      const temporaryData = valueTaskData
      temporaryData.updates =
        [...valueTaskData.updates, new FileUpdate({
          id: v4(),
          account: currentAccount.id,
          message: messageText,
          timestamp: new Date(),
        })]
      setTaskDataValue(temporaryData)
      props.setFileObject(temporaryData)
      setMessageText('')
      await updateFile(valueTaskData, temporaryData.id ?? '')
    }
  }

  const updateItem = (fileItem: FileItem) => {
    setTaskDataValue(fileItem)
    props.setFileObject(fileItem)
  }

  return (
    <>
      <Stack alignItems='center' height={1} overflow='auto' paddingTop={0}>
        <Divider sx={{ width: 1 }} />
        <Stack
          alignItems='center'
          justifyContent='flex-start'
          paddingTop={3}
          paddingX={2}
          spacing={2}
          width={1}
        >
          <Stack alignItems='center' direction='row' justifyContent='space-between' width={1}>
            <Stack alignItems='center' direction='row' spacing={1} width={1}>
              {renderIcon(fileExtension?.[0].toString() ?? '')}
              <Typography
                lineHeight={1.2}
                variant='h4'
              >
                {valueTaskData?.title}
              </Typography>
            </Stack>
            <IconButton onClick={() => props.closeTaskDrawer()}>
              <CloseRoundedIcon />
            </IconButton>
          </Stack>

          {imageFiles.has(fileExtension?.[0].toString() ?? 'jpg') &&
          <img
            alt={`https://cdn.releese.io/${valueTaskData?.url}`}
            src={`https://cdn.releese.io/${valueTaskData?.url}`}
            style={{
              maxWidth: '100%',
              maxHeight: '256px',
              borderRadius: 16,
            }}
          />}

          <Stack spacing={1} width={1}>

            <Typography variant='subtitle1'>
              Details
            </Typography>

            <Stack>
              <Typography color={themeItem.palette.text.label} variant='body2'>
                File name
              </Typography>
              <Typography variant='body2'>
                {valueTaskData?.title}
              </Typography>
            </Stack>

            <Stack>
              <Typography color={themeItem.palette.text.label} variant='body2'>
                Size
              </Typography>
              <Typography variant='body2'>
                {bytesToSize(valueTaskData?.size ?? 0)}
              </Typography>
            </Stack>

            <Stack>
              <Typography color={themeItem.palette.text.label} variant='body2'>
                Created at
              </Typography>
              <Typography variant='body2'>
                {dayjs(valueTaskData?.createdAt).format('LL')}
              </Typography>
            </Stack>

            <Stack>
              <Stack alignItems='center' direction='row' spacing={1}>
                <Typography color={themeItem.palette.text.label} variant='body2'>
                  Description
                </Typography>
                <IconButton onClick={() => setDescription(true)} size='small'>
                  <EditRoundedIcon />
                </IconButton>
              </Stack>
              <Typography variant='body2'>
                {valueTaskData?.description}
              </Typography>
            </Stack>

            <Button
              download
              href={`${baseUrlLinksApi}/files/download?fileName=${encodeURIComponent(`https://cdn.releese.io/${valueTaskData?.url}`)}`}
              startIcon={<CloudDownloadRoundedIcon />}
              sx={{
                width: 'fit-content',
              }}
              target='_blank'
            >
              Download
            </Button>

          </Stack>
        </Stack>
        <Stack
          direction='column'
          flexGrow={1}
          height={1}
          justifyContent='flex-end'
          paddingTop={2}
          spacing={2}
          width={1}
        >
          <Stack
            direction='column-reverse'
            height={300}
            overflow='auto'
            paddingX={1.5}
            paddingY={1}
            spacing={1}
            sx={{
              backgroundColor: theme => theme.palette.background.input,
            }}
          >
            {valueTaskData && [...valueTaskData.updates].reverse().map(element =>
              <TaskDrawerUpdate
                key={element.timestamp.toString()}
                taskUpdate={element}
              />)}
          </Stack>
          <Stack justifyContent='center' paddingBottom={2}>
            <TextField
              InputProps={{
                endAdornment: <InputAdornment position='end' style={{ height: '100%' }}  >
                  <IconButton onClick={handleEmojiClick} size='small'>
                    <InsertEmoticonIcon />
                  </IconButton>
                  <Divider flexItem orientation='vertical' />
                  <IconButton
                    color='primary'
                    disabled={!messageText}
                    onClick={handleSendMessage}
                    size='small'
                  >
                    <SendIcon />
                  </IconButton>
                </InputAdornment>,
              }}
              autoComplete='off'
              fullWidth
              onChange={event => setMessageText(event.currentTarget.value)}
              onKeyPress={async event => {
                if (event.key === 'Enter') {
                  await handleSendMessage()
                }
              }}
              placeholder='Post a comment'
              style={{ paddingLeft: 8, paddingRight: 8, marginTop: 0 }}
              value={messageText}
            />
          </Stack>
          <Menu
            anchorEl={anchorElementEmoji}
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
            onClose={handleEmojiClose}
            open={openEmojiMenu}
            transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <EmojiPicker emojiHandler={addEmoji} />
          </Menu>
          <Menu
            anchorEl={anchorElementMore}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            onClick={handleMoreClose}
            onClose={handleMoreClose}
            open={openMoreMenu}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          >
            <MenuItem onClick={() => props.closeTaskDrawer()}>
              <ListItemIcon >
                <DeleteRoundedIcon fontSize='medium' />
              </ListItemIcon>
              Delete file
            </MenuItem>
          </Menu>
        </Stack>
      </Stack>
      {valueTaskData &&
      <EditFileDescriptionModal
        close={() => setDescription(false)}
        item={valueTaskData}
        open={description}
        updateItem={updateItem}
      />}
    </>
  )
}

export default FileDrawerComponent
