/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Stack } from '@mui/material'
import { useEffect, useState } from 'react'

import { getTaskboardsByProjectId } from 'src/api/taskboards'
import TaskBoardLoading from 'src/components/pages/Task/TaskViews/TaskboardLoading'
import TaskBoardView from 'src/components/pages/Task/TaskViews/TaskboardView'
import TaskboardViewEmpty from 'src/components/pages/Task/TaskViews/TaskViewTypes/TaskboardViewEmpty'
import { TasksboardProvider } from 'src/components/providers/TasksboardProvider'
import type Project from 'src/models/Project'
import type { TaskBoard } from 'src/models/Taskboard'

type Props = {
  project?: Project
  updateboard: boolean
}

const ViewProjectTasks = (props: Props) => {
  const [loading, setLoading] = useState(false)
  const [firstDone, setFirstDone] = useState(false)
  const [activeTaskboard, setActiveTaskBoard] = useState<TaskBoard>()
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    setActiveTaskBoard(undefined)
    if (props.project) {
      setLoading(true)
      getTaskboardsByProjectId(props.project.id)
        .then(async data => {
          setActiveTaskBoard(data)
          setFirstDone(true)
        }).finally(() => setLoading(false))
    }
  }, [props.updateboard, refresh])

  const getTaskBoardMethod = () => {
    if (props.project && firstDone) {
      void getTaskboardsByProjectId(props.project.id)
        .then(setActiveTaskBoard)
    }
  }

  return (
    <Stack
      sx={{
        width: 1,
        height: 1,
        overflow: 'hidden',
      }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        width: 1,
        height: 1,
      }}
      >
        {loading
          ? <TaskBoardLoading project={props.project} />
          : activeTaskboard?.id
            ? <TasksboardProvider taskboardId={activeTaskboard.id}>
              <TaskBoardView
                firstDone={firstDone}
                getTaskBoardMethod={getTaskBoardMethod}
                project={props.project}
                setRefresh={setRefresh}
                taskboard={activeTaskboard}
              />
            </TasksboardProvider>
            : <TaskboardViewEmpty />}
      </Box>
    </Stack>
  )
}
export default ViewProjectTasks
