/* eslint-disable max-lines */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable complexity */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable react-hooks/exhaustive-deps */
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone'
import LoopRoundedIcon from '@mui/icons-material/LoopRounded'
import SendRoundedIcon from '@mui/icons-material/SendRounded'
import { Avatar, AvatarGroup, Card, IconButton, Stack, Tooltip, Typography, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { RRule } from 'rrule'
import { v4 } from 'uuid'

import { updateTaskItem } from 'src/api/taskboards'
import { sendTasksboardUpdate } from 'src/api/webSocket'
import { getDayItem } from 'src/components/pages/Task/TaskViews/TaskViewTypes/Calendar'
import { useAuth } from 'src/components/providers/AuthProvider'
import type Account from 'src/models/Account'
import type { TaskBoard, TaskGroup, TaskItem } from 'src/models/Taskboard'
import { TaskBoardEdit } from 'src/models/Taskboard'

type Props = {
  taskDataSet: TaskItem
  id: string
  index: number
  toggleTaskDrawer: (toggleState: boolean, taskGroup: TaskGroup, taskData?: TaskItem) => () => void
  searchQuery: string
  statusQuery: string
  priorityQuery: string
  taskColumn: TaskGroup
  taskboard: TaskBoard
  members: Account[]
}

export const recurringDates = (pace: string, dateItem: Date) => {
  if (pace === 'Monthly') {
    const dateItemTest = new Date(dateItem).getDay()
    const eventsRules = new RRule({
      freq: RRule.WEEKLY,
      byweekday: getDayItem(dateItemTest),
      dtstart: new Date(dateItem),
      interval: 4,
      until: new Date('January 11, 2025'),
    })
    return eventsRules.all()
  } else if (pace === 'Weekly') {
    const dateItemTest = new Date(dateItem).getDay()
    const eventsRules = new RRule({
      freq: RRule.WEEKLY,
      byweekday: getDayItem(dateItemTest),
      dtstart: new Date(dateItem),
      interval: 1,
      until: new Date('January 11, 2025'),
    })
    return eventsRules.all()
  } else {
    return [new Date(dateItem)]
  }
}

const TaskBoardItem = (props: Props) => {
  const themeItem = useTheme()
  const { currentAccount } = useAuth()
  const [taskData, setTaskData] = useState(props.taskDataSet)
  const dates = recurringDates(taskData.recurring, new Date(taskData.dueDate ?? 0))
  const [lastDone, setLastDone] = useState(props.taskDataSet.lastDoneOn)
  const [nextDate, setNextDate] = useState(dates.find(item => item.getTime() > Date.now()))
  const [previousDate, setPreviousDate] = useState([...dates].reverse().find(item => item.getTime() < Date.now()))

  const [status, setTaskStatus] = useState(props.taskDataSet.recurring !== 'None'
    ? lastDone &&
    previousDate &&
    nextDate &&
    new Date(lastDone).getTime() > previousDate.getTime() &&
    new Date(lastDone).getTime() < nextDate.getTime()
      ? 'Done'
      : 'Not started'
    : props.taskDataSet.status)
  const [assignee, setAsignee] = useState<Account[]>([])
  const visibility = props.taskDataSet.title.toLowerCase().includes(props.searchQuery.toLowerCase()) &&
  props.taskDataSet.status.toLowerCase().includes(props.statusQuery.toLowerCase()) &&
  props.taskDataSet.priority.toLowerCase().includes(props.priorityQuery.toLowerCase())

  useEffect(() => {
    const temporaryDates = recurringDates(props.taskDataSet.recurring, new Date(props.taskDataSet.dueDate ?? 0))
    setNextDate(temporaryDates.find(item => item.getTime() > Date.now()))
    setPreviousDate([...temporaryDates].reverse().find(item => item.getTime() < Date.now()))
  }, [props.taskDataSet.dueDate, props.taskDataSet.recurring])

  useEffect(() => {
    if (props.taskDataSet.recurring === 'Weekly' || props.taskDataSet.recurring === 'Monthly') {
      if (previousDate && lastDone && new Date(lastDone).getTime() < new Date(previousDate).getTime()) {
        setTaskStatus('Not started')
      }
      if (!lastDone) {
        setTaskStatus('Not started')
      }
      if (lastDone && !previousDate) {
        setTaskStatus('Done')
      }
    }
  }, [previousDate, nextDate, lastDone, props.taskDataSet.lastDoneOn, props.taskDataSet.recurring])

  useEffect(() => {
    if (props.taskDataSet) {
      const getAssignees = () => {
        setAsignee([])
        if (props.taskDataSet) {
          for (const assignees of props.taskDataSet.assignee) {
            const temporaryItem = props.members.find(item => item.id === assignees)
            if (temporaryItem) {
              setAsignee(previousAssignees => [...previousAssignees, temporaryItem])
            }
          }
        }
      }
      getAssignees()
    }
  }, [props.taskDataSet.assignee])

  const handleChange = async (event: React.MouseEvent<HTMLElement>,
    newStatus: 'Done' | 'In progress' | 'Not approved' | 'Not started') => {
    event.stopPropagation()
    const oldStatusItem = Object.assign({}, { ...props.taskDataSet })
    setTaskStatus(newStatus)
    setLastDone(new Date())
    props.taskDataSet.status = newStatus
    props.taskDataSet.lastUpdated = new Date()
    props.taskDataSet.lastDoneOn = new Date()
    const temporaryData = taskData
    temporaryData.status = newStatus
    temporaryData.lastUpdated = new Date()
    temporaryData.lastDoneOn = new Date()
    setTaskData(taskData)

    if (props.taskboard.id) {
      props.taskboard.taskGroups[props.taskboard.taskGroups.findIndex(group => group.id === props.taskColumn.id)]
        .tasks[props.taskboard.taskGroups[props.taskboard.taskGroups
          .findIndex(group => group.id === props.taskColumn.id)]
          .tasks.findIndex(task => task.id === props.taskDataSet.id)] = taskData
      await updateTaskItem(props.taskboard.id, props.taskColumn.id ?? '', taskData)
        .then(() => {
          const taskUpdateItem = new TaskBoardEdit({
            id: v4(),
            taskboardId: props.taskboard.id,
            taskId: props.taskDataSet.id,
            accountId: currentAccount.id,
            modificationType: 'Task details',
            itemType: 'Field',
            title: `${props.taskDataSet.title}`,
            fields: [{
              fieldName: 'Status',
              oldValue: oldStatusItem.status,
              newValue: newStatus,
            }],
          })
          sendTasksboardUpdate(taskUpdateItem)
        })
    }
  }
  useEffect(() => {
    if (props.taskDataSet.status !== status &&
      props.taskDataSet.recurring !== 'Weekly' &&
      props.taskDataSet.recurring !== 'Monthly') {
      setTaskStatus(props.taskDataSet.status)
    }
  }, [props.taskDataSet.status, props.taskDataSet.recurring, status])

  useEffect(() => {
    setTaskData(props.taskDataSet)
  }, [props.taskDataSet])

  return (
    <Draggable
      draggableId={props.taskDataSet.id?.toString() ?? ''}
      index={props.index}
      key={props.id}
    >
      {provided =>
        <Card
          elevation={6}
          onClick={props.toggleTaskDrawer(true, props.taskColumn,
            { ...props.taskDataSet, updates: props.taskDataSet.updates })}
          ref={provided.innerRef}
          sx={{
            boxShadow: 'none',
            backgroundColor: theme => theme.palette.background.default,
            padding: 0.5,
            marginTop: 1,
            marginBottom: 1,
            border: theme => `1px solid ${theme.palette.background.input}`,
            display:
            visibility
              ? 'block'
              : 'none',
            borderColor: theme => theme.palette.background.input }}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Stack paddingBottom={1} paddingLeft={0.5} paddingTop={1} spacing={1}>
            <Stack alignItems='center' direction='row' justifyContent='space-between'>
              <Stack alignItems='flex-start' direction='row' spacing={1}>
                {(props.taskDataSet.priority === 'None' && status !== 'Done') &&
                  <IconButton
                    aria-label='Complete'
                    onClick={event => handleChange(event, 'Done')}
                    size='small'
                    sx={{
                      padding: 0,
                      fontSize: 24,
                    }}
                  >
                    <CircleTwoToneIcon sx={{ color: theme => theme.palette.text.label }} />
                  </IconButton>}
                {(props.taskDataSet.priority === 'Low' && status !== 'Done') &&
                <IconButton
                  aria-label='Complete'
                  onClick={event => handleChange(event, 'Done')}
                  size='small'
                  sx={{
                    padding: 0,
                    fontSize: 24,
                  }}
                >
                  <CircleTwoToneIcon color='primary' />
                </IconButton>}
                {(props.taskDataSet.priority === 'Medium' && status !== 'Done') &&
                <IconButton
                  aria-label='Complete'
                  onClick={event => handleChange(event, 'Done')}
                  size='small'
                  sx={{
                    color: theme => theme.palette.rating,
                    padding: 0,
                    fontSize: 24,
                  }}
                >
                  <CircleTwoToneIcon sx={{ color: theme => theme.palette.rating }} />
                </IconButton>}
                {(props.taskDataSet.priority === 'High' && status !== 'Done') &&
                <IconButton
                  aria-label='Complete'
                  color='error'
                  onClick={event => handleChange(event, 'Done')}
                  size='small'
                  sx={{
                    padding: 0,
                    fontSize: 24,
                  }}
                >
                  <CircleTwoToneIcon color='error' />
                </IconButton>}
                {status === 'Done' &&
                props.taskDataSet.recurring !== 'Monthly' &&
                props.taskDataSet.recurring !== 'Weekly' &&
                <IconButton
                  aria-label='Complete'
                  color='success'
                  onClick={event => handleChange(event, 'Not started')}
                  size='small'
                  sx={{
                    padding: 0,
                    fontSize: 24,
                  }}
                >
                  <CheckCircleRoundedIcon color='success' />
                </IconButton>}
                {status === 'Done' &&
                (props.taskDataSet.recurring === 'Monthly' ||
                props.taskDataSet.recurring === 'Weekly') &&
                <Tooltip title='This recurring task is completed and locked'>
                  <div>
                    <IconButton
                      aria-label='Complete'
                      color='success'
                      disabled
                      size='small'
                      sx={{
                        padding: 0,
                        fontSize: 24,
                      }}
                    >
                      <CheckCircleRoundedIcon
                        color='success'
                        sx={{
                          opacity: 0.5,
                        }}
                      />
                    </IconButton>
                  </div>
                </Tooltip>}

                <Typography
                  sx={{
                    wordBreak: 'break-word',
                    fontSize: '.95rem',
                    paddingTop: 0,
                  }}
                  variant='body1'
                >
                  {props.taskDataSet.title}
                </Typography>
              </Stack>
            </Stack>
            {!props.taskboard.hiddenFields.includes('Due date') &&
            <>
              {props.taskDataSet.dueDate
                ? props.taskDataSet.recurring === 'Weekly' || props.taskDataSet.recurring === 'Monthly'
                  ? <Stack alignItems='center' direction='row' paddingLeft={0.5} spacing={1}>
                    <CalendarTodayRoundedIcon
                      sx={{
                        color: props.taskDataSet.dueDate && nextDate && new Date(nextDate) > new Date()
                          ? 'text.label'
                          : theme => theme.palette.error.main,
                      }}
                    />
                    <Typography
                      color={props.taskDataSet.dueDate && nextDate && new Date(nextDate) > new Date()
                        ? 'text.label'
                        : themeItem.palette.error.main}
                      variant='tableCell'
                    >
                      {nextDate &&
                      `Due ${dayjs(nextDate).format('MMMM DD')}`}
                    </Typography>
                    <LoopRoundedIcon
                      sx={{
                        color: props.taskDataSet.dueDate && nextDate && new Date(nextDate) > new Date()
                          ? 'text.label'
                          : theme => theme.palette.error.main,
                      }}
                    />
                  </Stack>
                  : <Stack alignItems='center' direction='row' paddingLeft={0.5} spacing={1}>
                    <CalendarTodayRoundedIcon
                      sx={{
                        color: props.taskDataSet.dueDate && new Date(props.taskDataSet.dueDate) > new Date()
                          ? 'text.label'
                          : theme => theme.palette.error.main,
                      }}
                    />
                    <Typography
                      color={props.taskDataSet.dueDate && new Date(props.taskDataSet.dueDate) > new Date()
                        ? 'text.label'
                        : themeItem.palette.error.main}
                      variant='tableCell'
                    >
                      {`Due ${dayjs(props.taskDataSet.dueDate).format('MMMM DD')}`}
                    </Typography>
                  </Stack>
                : null}
            </>}
            {!props.taskboard.hiddenFields.includes('Assignee') && assignee.length > 0 &&
            <Stack
              alignItems='center'
              direction='row'
              spacing={1}
            >
              <AvatarGroup>
                {assignee.map(item =>
                  <Avatar
                    key={item.id}
                    src={item.profilePictureUrl}
                    sx={{ width: 24, height: 24 }}
                  />)}
              </AvatarGroup>
              <Typography color={themeItem.palette.text.secondary} variant='body2'>
                {assignee[0].firstName}
                {assignee.length > 2 &&
                ` + ${assignee.length - 1} more`}
              </Typography>
            </Stack>}
            {props.taskDataSet.updates.length > 0
              ? <Stack alignItems='center' direction='row' paddingLeft={5} spacing={1}>
                <SendRoundedIcon
                  sx={{
                    color: theme => theme.palette.secondary.main,
                  }}
                />
                <Typography
                  color={themeItem.palette.secondary.main}
                  variant='tableCell'
                >
                  {props.taskDataSet.updates.length}
                  {props.taskDataSet.updates.length > 1
                    ? ' updates'
                    : ' update'}
                </Typography>
              </Stack>
              : null}
          </Stack>
        </Card>}
    </Draggable>
  )
}

export default TaskBoardItem
