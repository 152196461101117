/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Stack, Typography, useTheme } from '@mui/material'
import countries from 'i18n-iso-countries'
import data from 'i18n-iso-countries/langs/en.json'
import { useRef } from 'react'
import WorldMap from 'react-svg-worldmap'

import { useContainerDimensions } from 'src/components/ContainerDimensions'
import type { AnalyticsMonthlyChart } from 'src/models/Analytics'

type Props = {
  data: AnalyticsMonthlyChart[]
}

const NewEarningsLocation = (props: Props) => {
  const theme = useTheme()
  countries.registerLocale(data)
  const clicks = props.data.map(({ _id, amount }) =>
    ({ country: _id, value: Number(amount.toFixed(2)) }))

  const componentRef = useRef<HTMLDivElement>(null)
  const { width } = useContainerDimensions(componentRef)

  return (
    <Stack width={1}>
      <Typography variant='h3'>
        Locations
      </Typography>
      <Stack alignItems='center' ref={componentRef} width={1}>
        <WorldMap
          backgroundColor='transparent'
          borderColor={theme.palette.divider}
          color={theme.palette.success.main}
          data={clicks}
          size={width}
          strokeOpacity={1}
          valueSuffix='$'
        />
      </Stack>
    </Stack>
  )
}

export default NewEarningsLocation
