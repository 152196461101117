/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import { Checkbox, Chip, FormControlLabel, Grid, Stack, Typography, useTheme } from '@mui/material'
import dayjs from 'dayjs'

import { languageData } from 'src/data/languagesData'
import type Recording from 'src/models/Recording'

type Props = {
  recording: Recording
  distribution?: boolean
  rights?: boolean
}

const ViewRecordingContentTab = (props: Props) => {
  const themeItem = useTheme()
  return (
    <Grid container rowGap={1} spacing={3}>
      <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
        <Stack direction='column'>
          <Typography color={themeItem.palette.text.label} variant='body2'>
            BPM
          </Typography>
          <Typography variant='body2'>
            {props.recording.bpm.toString()}
          </Typography>
        </Stack>
      </Grid>

      <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
        <Stack direction='column'>
          <Typography color={themeItem.palette.text.label} variant='body2'>
            Duration
          </Typography>
          <Typography variant='body2'>
            {dayjs(new Date(props.recording.duration ? props.recording.duration * 1000 : 0)).format('mm:ss')}
          </Typography>
        </Stack>
      </Grid>

      {!props.distribution && !props.rights &&
      <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
        <Stack direction='column'>
          <Typography color={themeItem.palette.text.label} variant='body2'>
            Recording tags
          </Typography>
          <Stack direction='row' flexWrap='wrap'>
            {props.recording.tags.map(tag =>
              <Chip
                color='default'
                key={tag}
                label={tag}
                sx={{ margin: 0.2 }}
                variant='subtle'
              />)}
          </Stack>
        </Stack>
      </Grid>}

      <Grid item xs={12}>
        <Stack
          direction={{
            xl: 'row',
            lg: 'row',
            md: 'row',
            sm: 'column',
            xs: 'column',
          }}
          justifyContent='space-between'
          width={1}
        >
          <FormControlLabel
            control={<Checkbox checked={props.recording.isExplicit} />}
            label='Is explicit'
          />
          <FormControlLabel
            control={<Checkbox checked={props.recording.isInstrumental} />}
            label='Is instrumental'
          />
          <FormControlLabel
            control={<Checkbox checked={props.recording.containsSamples} />}
            label='Contains samples'
          />
        </Stack>
      </Grid>

      <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
        <Stack direction='column'>
          <Stack alignItems='center' direction='row' spacing={1}>
            <Typography color={themeItem.palette.text.label} variant='body2'>
              Lyrics language
            </Typography>
            {(props.distribution || props.rights) && (!props.recording.language ||
          props.recording.language.length === 0) &&
          <Chip color='error' icon={<WarningRoundedIcon />} label='Error' size='small' variant='subtle' />}
          </Stack>
          <Typography variant='body2'>
            {languageData.find(item => item.code === props.recording.language)?.language}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack direction='column'>
          <Typography color={themeItem.palette.text.label} variant='body2'>
            Lyrics
          </Typography>
          <Typography variant='body2'>
            {props.recording.lyrics}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  )
}

export default ViewRecordingContentTab
