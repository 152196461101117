import { Card, Stack, TextField, Typography, useTheme } from '@mui/material'

import type { MarketingLink } from 'src/models/Marketing'

type Props = {
  smartLinkData: MarketingLink
  setLinkData: React.Dispatch<React.SetStateAction<MarketingLink>>
}

const EditLinkDestination = (props: Props) => {
  const themeItem = useTheme()
  return (
    <Stack direction='column' maxWidth={896}>
      <Stack width={1}>
        <Stack alignItems='flex-start' direction='row' justifyContent='space-between' paddingTop={2} width={1}>
          <Typography variant='h3'>
            Target URL
          </Typography>
        </Stack>
      </Stack>
      <Card
        variant='outlined'
      >
        <Stack padding={4} spacing={2} width={1}>
          <Stack direction='column'>
            <Typography lineHeight={1.4} variant='h4'>
              Redirect your audience
            </Typography>
            <Typography color='text.secondary' variant='body1'>
              Send your audience to a link and collect valuable data
            </Typography>
          </Stack>
          {props.smartLinkData.type === 'Shortlink' &&
          <Stack direction='column' justifyContent='space-between' paddingTop={2} spacing={1}>
            <TextField
              autoComplete='new-password'
              error={props.smartLinkData.target.length > 0 && !props.smartLinkData.target.startsWith('https://')}
              fullWidth
              id='targeturl'
              inputProps={{ maxLength: 500 }}
              label='Target URL'
              onChange={event => props.setLinkData(old =>
                ({ ...old, target: event.target.value.replace(/\s/g, '') }))}
              placeholder='https://releese.io'
              sx={{ marginTop: 0 }}
              value={props.smartLinkData.target}
            />
            {props.smartLinkData.target.length > 0 && !props.smartLinkData.target.startsWith('https://') &&
            <Typography color={themeItem.palette.error.main} variant='body2'>
              Your target URL needs to start with &apos;https://&apos;
            </Typography>}
          </Stack>}
        </Stack>
      </Card>
    </Stack>
  )
}

export default EditLinkDestination
