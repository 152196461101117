/* eslint-disable unicorn/filename-case */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import {
  ELEMENT_BLOCKQUOTE,
  ELEMENT_CODE_BLOCK,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
  ELEMENT_IMAGE,
  ELEMENT_MEDIA_EMBED,
  ELEMENT_OL,
  ELEMENT_PARAGRAPH,
  ELEMENT_TABLE,
  ELEMENT_TODO_LI,
  ELEMENT_UL,
  withDraggables,
} from '@udecode/plate'

import { ELEMENT_ALERT_ERROR } from 'src/components/pages/Notes/Modern/alert/AlertPlugin'
import { ELEMENT_ALERT_INFO } from 'src/components/pages/Notes/Modern/alert/InfoPlugin'
import { ELEMENT_ALERT_SUCCESS } from 'src/components/pages/Notes/Modern/alert/SuccessPlugin'
import { ELEMENT_ALERT_WARNING } from 'src/components/pages/Notes/Modern/alert/WarningPlugin'
import { ELEMENT_CHECKLIST } from 'src/components/pages/Notes/Modern/checklist/ChecklistPlugin'
import { ELEMENT_DIVIDER } from 'src/components/pages/Notes/Modern/divider/DividerPlugin'
import { DragHandleItem } from 'src/components/pages/Notes/Modern/dnd/DragHandleItem'
import { ELEMENT_FRAME_CUSTOM } from 'src/components/pages/Notes/Modern/iFrame/IframePlugin'
import { KEY_CLOUD } from 'src/components/pages/Notes/Modern/image/createImagePlugin'
import { ELEMENT_BULLETED_LIST } from 'src/components/pages/Notes/Modern/listCustom/BulletedListPlugin'
import { ELEMENT_NUMBERED_LIST } from 'src/components/pages/Notes/Modern/listCustom/NumberedListPlugin'
import { ELEMENT_TASKBOARD } from 'src/components/pages/Notes/Modern/taskboard/TaskboardPlugin'

type Props = {
  setAnchorElementField: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>
  components: any
}

export const withStyledDraggables = (props: Props) => withDraggables(props.components, [
  {
    keys: [ELEMENT_UL, ELEMENT_OL],
    level: 0,
  },
  {
    keys: [
      ELEMENT_PARAGRAPH,
      ELEMENT_BLOCKQUOTE,
      ELEMENT_TODO_LI,
      ELEMENT_H1,
      ELEMENT_H2,
      ELEMENT_H3,
      ELEMENT_H4,
      ELEMENT_H5,
      ELEMENT_H6,
      ELEMENT_IMAGE,
      ELEMENT_OL,
      ELEMENT_UL,
      ELEMENT_TABLE,
      ELEMENT_MEDIA_EMBED,
      ELEMENT_CODE_BLOCK,
      ELEMENT_ALERT_ERROR,
      ELEMENT_ALERT_INFO,
      ELEMENT_ALERT_SUCCESS,
      ELEMENT_ALERT_WARNING,
      ELEMENT_CHECKLIST,
      ELEMENT_BULLETED_LIST,
      ELEMENT_NUMBERED_LIST,
      ELEMENT_FRAME_CUSTOM,
      ELEMENT_DIVIDER,
      ELEMENT_TASKBOARD,
      KEY_CLOUD,
    ],
    onRenderDragHandle: () =>
      <DragHandleItem setanchorElementField={props.setAnchorElementField} />
    ,
  },
  {
    key: ELEMENT_H1,
    styles: {
      gutterLeft: {
        padding: '0em 0 4px',
        fontSize: '1.875em',
      },
      blockToolbarWrapper: {
        height: '1.3em',
      },
      block: {
        overflow: 'initial',
      },
    },
  },
  {
    key: ELEMENT_H2,
    styles: {
      gutterLeft: {
        padding: '1.4em 0 1px',
        fontSize: '1.5em',
      },
      blockToolbarWrapper: {
        height: '1.3em',
      },
    },
  },
  {
    key: ELEMENT_H3,
    styles: {
      gutterLeft: {
        padding: '0em 0 1px',
        fontSize: '1.25em',
      },
      blockToolbarWrapper: {
        height: '1.3em',
      },
      block: {
        overflow: 'initial',
      },
    },
  },
  {
    keys: [ELEMENT_H4, ELEMENT_H5, ELEMENT_H6],
    styles: {
      gutterLeft: {
        padding: '0em 0 0',
        fontSize: '1.1em',
      },
      blockToolbarWrapper: {
        height: '1.3em',
      },
      block: {
        overflow: 'initial',
      },
    },
  },
  {
    keys: [ELEMENT_PARAGRAPH, ELEMENT_UL, ELEMENT_OL],
    styles: {
      gutterLeft: {
        padding: '4px 0 0',
      },
      block: {
        overflow: 'initial',
      },
    },
  },
  {
    key: ELEMENT_BLOCKQUOTE,
    styles: {
      gutterLeft: {
        padding: '18px 0 0',
      },
      block: {
        overflow: 'initial',
      },
    },
  },
  {
    key: ELEMENT_CODE_BLOCK,
    styles: {
      gutterLeft: {
        padding: '12px 0 0',
      },
      block: {
        overflow: 'initial',
      },
    },
  },
])
