import { Chip, Stack, TableCell, TableRow, Typography } from '@mui/material'

import type Recording from 'src/models/Recording'

type Props = {
  recording: Recording
}

const NestedTableItem = (props: Props) =>
  <TableRow
    key={props.recording.id}
    sx={{ '.MuiTableCell-root': { borderBottomColor: 'divider' } }}
  >
    <TableCell id='recording_title'>
      <Stack alignItems='center' direction='row'>
        <Typography variant='tableCell'>{props.recording.title}</Typography>
      </Stack>
    </TableCell>

    <TableCell id='artists'>
      <Stack alignItems='center' direction='row' spacing={2}>
        {props.recording.primaryArtists.map(artist =>
          <Chip color='default' key={artist.name} label={artist.name} size='small' sx={{ margin: 0.2 }} />)}
      </Stack>
    </TableCell>

    <TableCell id='status'>
      <Typography variant='tableCell'>{props.recording.ISRC}</Typography>
    </TableCell>
  </TableRow>

export default NestedTableItem
