import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck'
import LibraryMusicRoundedIcon from '@mui/icons-material/LibraryMusicRounded'
import MessageIcon from '@mui/icons-material/Message'
import PaymentIcon from '@mui/icons-material/Payment'

import { Organisation } from 'src/models/Organisation'
import Project from 'src/models/Project'
import { TaskBoard } from 'src/models/Taskboard'
import type { Serialized } from 'src/types/react-app-env'

export const NOTIFICATION_TYPE = [
  'New Task Assigned',
  'Task Update',
  'Publishing Earnings',
  'Neighboring Earnings',
  'Distribution Earnings',
  'Organisation Verified',
  'Added To Project',
  'Added As Rightsholder',
  'Posted A Message',
  'Task Assigned',
  'Task Updated',
] as const

export default class Notification {
  id = ''
  createdAt: Date
  accountId = ''
  read: boolean
  notificationType: typeof NOTIFICATION_TYPE[number]
  organisation?: Organisation
  project?: Project
  taskboard?: TaskBoard
  taskId?: string

  constructor(dto: NotificationDto) {
    Object.assign(this, dto)
    this.createdAt = new Date(dto.createdAt ?? '')
    this.read = !!dto.read
    this.notificationType = dto.notificationType ?? NOTIFICATION_TYPE[0]
    this.organisation = dto.organisation ? new Organisation(dto.organisation) : undefined
    this.project = dto.project ? new Project(dto.project) : undefined
    this.taskboard = dto.taskboard ? new TaskBoard(dto.taskboard) : undefined
    this.taskId = dto.taskId
  }
}

export type NotificationDto = Partial<Serialized<Notification>> & { _id?: string }

export const notificationPreferences = {
  account: {
    label: 'Account',
    Icon: BusinessCenterIcon,
    description: 'Received when your account is modified',
  },
  messages: {
    label: 'Messages',
    Icon: MessageIcon,
    description: 'Received when a message is sent to you',
  },
  publishing: {
    label: 'Projects',
    Icon: LibraryMusicRoundedIcon,
    description: 'Received when you are added as a collaborator to a project',
  },
  tasks: {
    label: 'Tasks',
    Icon: LibraryAddCheckIcon,
    description: 'Received when new task is assigned, status changes, or comment is added',
  },
  earnings: {
    label: 'Earnings',
    Icon: AttachMoneyIcon,
    description: 'Received when you are issued a payout or there is an issue regarding your payouts',
  },
  billing: {
    label: 'Billing',
    Icon: PaymentIcon,
    description: 'Received when your billing information is changed or there is an issue with your payment',
  },
}
