import FolderRoundedIcon from '@mui/icons-material/FolderRounded'
import { Card, CardActionArea, Stack, Typography } from '@mui/material'

import { NavLink } from 'src/components/SafeRouterLink'
import type { Folder } from 'src/models/Folder'

type Props = {
  folder: Folder
}

const SubFolder = (props: Props) =>
  <Stack>
    <Card
      elevation={0}
      sx={{
        background: theme => theme.palette.background.elevatedCard,
      }}
    >
      <CardActionArea
        component={NavLink}
        to={`/share-folder/folder/${props.folder.id}/${props.folder.hash}?goto=back`}
      >
        <Stack
          alignItems='center'
          justifyContent='center'
          padding={1}
        >
          <FolderRoundedIcon
            color='action'
            sx={{
              height: 64,
              width: 64,
            }}
          />
          <Typography
            textAlign='center'
            variant='body2'
          >
            {props.folder.title}
          </Typography>
        </Stack>
      </CardActionArea>
    </Card>
  </Stack>

export default SubFolder
