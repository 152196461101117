/* eslint-disable no-restricted-imports */
import CloseIcon from '@mui/icons-material/Close'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded'
import WorkspacesRoundedIcon from '@mui/icons-material/WorkspacesRounded'
import { LoadingButton } from '@mui/lab'
import { Autocomplete, Avatar, Box, Button, Dialog, FormControl, IconButton, InputLabel, ListItemIcon, ListItemText, MenuItem, Select, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { v4 } from 'uuid'

import { getTaskboardGroups, getWorkspaces, restoreTaskboard, updateTaskboardGroup } from 'src/api/taskboards'
import { sendWorkspaceUpdate } from 'src/api/webSocket'
import { taskboardIcons } from 'src/components/pages/Task/TaskViews/TaskViewComponents/TaskboardIcons'
import type { TaskBoard, TaskboardGroup, Workspace } from 'src/models/Taskboard'

type Props = {
  close: () => void
  open: boolean
  refreshBoards: () => void
  refreshTaskboardArchiveList: () => void
  taskboard: TaskBoard
}

const RestoreTaskboardModal = (props: Props) => {
  const themeItem = useTheme()
  const [loading, setLoading] = useState(false)
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))
  const [groups, setGroups] = useState<TaskboardGroup[]>()
  const [workspaces, setWorkspaces] = useState<Workspace[]>([])
  const [activeWorkspace, setActiveWorkspace] = useState<Workspace>()
  const [group, setGroup] = useState<TaskboardGroup>()

  useEffect(() => {
    void getWorkspaces()
      .then(setWorkspaces)
  }, [])

  useEffect(() => {
    void getTaskboardGroups(activeWorkspace?.id)
      .then(setGroups)
  }, [activeWorkspace?.id])

  const findItem = (valueFound: string) => groups?.find(element => element.id === valueFound)
  const handleChange = (event: string) => {
    const test = event

    const itemFound = findItem(test)

    if (itemFound) {
      setGroup(itemFound)
    }
  }

  const handleClick = async (board: string, groupTest: TaskboardGroup) => {
    if (props.taskboard.id) {
      setLoading(true)
      const testGroup = groupTest
      testGroup.boardIds.push(board)
      await restoreTaskboard(board)
        .then(async () => {
          await updateTaskboardGroup({
            id: groupTest.id ?? '',
            ...testGroup,
          }).then(() => props.refreshTaskboardArchiveList())
            .then(() => sendWorkspaceUpdate(v4()))
            .then(props.refreshBoards)
            .then(() => setLoading(false))
            .catch(() => setLoading(false))
        })
      props.close()
    }
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='xs'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Stack paddingX={3} paddingY={3} spacing={4} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          width='100%'
        >
          <Typography variant='h3'>
            Restore taskboard
          </Typography>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>
        <Stack width='100%'>
          <Stack alignItems='center' direction='column'>
            <WorkspacesRoundedIcon color='primary' sx={{ fontSize: 64 }} />
            <Typography color='text.secondary' variant='h4'>
              Select a location
            </Typography>
            <Typography color='text.label' textAlign='center' variant='subtitle1'>
              Select a workspace and folder to place the taskboard in
            </Typography>
          </Stack>
        </Stack>
        <Stack paddingTop={2} width={1}>
          <FormControl>
            <InputLabel>
              Workspace
            </InputLabel>
            <Select
              displayEmpty
              id='workspace'
              label='Workspace'
              onChange={event => {
                const foundItem = workspaces.find(item => item.id === event.target.value)
                setActiveWorkspace(foundItem)
              }}
              renderValue={_value => <Stack alignItems='center' direction='row' spacing={1}>
                <Avatar
                  color={activeWorkspace?.color ?? themeItem.palette.primary.main}
                  sx={{
                    background: activeWorkspace?.color ?? themeItem.palette.primary.main,
                    color: `${themeItem.palette.primary.contrastText}!important`,
                    '& .MuiSvgIcon-root': {
                      color: 'white!important',
                    },
                    borderRadius: 0.5,
                  }}
                  variant='rounded'
                >
                  {activeWorkspace?.icon
                    ? taskboardIcons[activeWorkspace.icon]
                    : <HomeRoundedIcon />}
                </Avatar>
                <Typography color={themeItem.palette.text.secondary} variant='body1'>
                  {activeWorkspace?.title ?? 'Main Workspace'}
                </Typography>
              </Stack>}
              sx={{
                height: 64,
                '.MuiFilledInput-input': {
                  height: '64px!important',
                  alignItems: 'center',
                  display: 'flex',
                },
              }}
              value={activeWorkspace?.id ?? 'Home'}
            >
              <MenuItem value='Home'>
                <ListItemIcon>
                  <Avatar
                    color={themeItem.palette.primary.main}
                    sx={{
                      background: themeItem.palette.primary.main,
                      color: themeItem.palette.primary.contrastText,
                      width: 32,
                      height: 32,
                      borderRadius: 0.5,
                    }}
                    variant='rounded'
                  >
                    <HomeRoundedIcon />
                  </Avatar>
                </ListItemIcon>
                <ListItemText>
                  Main Workspace
                </ListItemText>
              </MenuItem>
              {workspaces.map(item =>
                <MenuItem key={item.id} value={item.id}>
                  <ListItemIcon>
                    <Avatar
                      sx={{
                        width: 32,
                        height: 32,
                        borderRadius: 0.5,
                        bgcolor: item.color,
                        color: themeItem.palette.primary.contrastText,
                      }}
                      variant='rounded'
                    >
                      {taskboardIcons[item.icon]}
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText>
                    {item.title}
                  </ListItemText>
                </MenuItem>)}
            </Select>
          </FormControl>
        </Stack>

        <Stack>
          {groups &&
          <Autocomplete
            autoHighlight
            autoSelect
            fullWidth
            getOptionLabel={option => option.title}
            onChange={(_test, newValue) => handleChange(newValue?.id ?? '')}
            options={groups}
            renderInput={params =>
              <TextField
                {...params}
                label='Folder'
                placeholder='Select a folder'
                sx={{ marginTop: 0,  height: 42 }}
              />}
            renderOption={(renderProps, option) =>
              <Box component='li' {...renderProps}>
                {option.title}
              </Box>}
            sx={{ marginTop: 2, height: 42 }}
            value={group}
          />}
        </Stack>

        <Stack direction='row' justifyContent='flex-end' spacing={1} width='100%'>
          <Button onClick={props.close} variant='text'>
            Cancel
          </Button>
          <LoadingButton
            color='primary'
            disabled={!group}
            loading={loading}
            onClick={() => {
              if (group && props.taskboard.id) {
                void handleClick(props.taskboard.id, group)
              }
            }}
            variant='contained'
          >
            Restore taskboard
          </LoadingButton>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default RestoreTaskboardModal
