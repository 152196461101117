/* eslint-disable no-restricted-imports */
/* eslint-disable sonarjs/no-identical-functions */
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded'
import CloseIcon from '@mui/icons-material/Close'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded'
import { Autocomplete, Avatar, Box, Button, Dialog, FormControl, IconButton, InputLabel, ListItemIcon, ListItemText, MenuItem, Select, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { v4 } from 'uuid'

import { createReleeseDocument, createTaskboardGroup, getTaskboardGroups, getWorkspaces, updateTaskboardGroup } from 'src/api/taskboards'
import { sendWorkspaceUpdate } from 'src/api/webSocket'
import { taskboardIcons } from 'src/components/pages/Task/TaskViews/TaskViewComponents/TaskboardIcons'
import type { TaskboardGroup, Workspace } from 'src/models/Taskboard'
import { NewTaskboardGroup } from 'src/models/Taskboard'

type Props = {
  close: () => void
  open: boolean
  group?: TaskboardGroup
  refreshBoards?: () => void
}

const NewDocumentModal = (props: Props) => {
  const history = useNavigate()
  const themeItem = useTheme()
  const theme = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  const [groups, setGroups] = useState<TaskboardGroup[]>()
  const [group, setGroup] = useState(props.group)

  const [workspaces, setWorkspaces] = useState<Workspace[]>([])
  const [activeWorkspace, setActiveWorkspace] = useState<Workspace>()

  useEffect(() => {
    void getWorkspaces()
      .then(setWorkspaces)
  }, [])

  useEffect(() => {
    void getTaskboardGroups(activeWorkspace?.id)
      .then(setGroups)
  }, [activeWorkspace?.id])

  const findItem = (valueFound: string) => groups?.find(element => element.id === valueFound)

  const handleChange = (event: string) => {
    const test = event

    const itemFound = findItem(test)

    if (itemFound) {
      setGroup(itemFound)
    }
  }

  const handleClick = async () => {
    if (!group) {
      const newGroup = new NewTaskboardGroup({
        title: 'Untitled Group',
      })
      await createTaskboardGroup(newGroup)
        .then(async groupItem => {
          await createReleeseDocument()
            .then(board => {
              void handleGroup(`doc/${board.projectId}`, groupItem)
              props.close()
            })
            .then(() => {
              if (props.refreshBoards) {
                props.refreshBoards()
              }
            })
        })
    } else {
      await createReleeseDocument()
        .then(board => {
          void handleGroup(`doc/${board.projectId}`, group)
          props.close()
        })
        .then(() => {
          if (props.refreshBoards) {
            props.refreshBoards()
          }
        })
    }
    props.close()
  }

  const handleGroup = async (board: string, groupTest: TaskboardGroup) => {
    const testGroup = groupTest
    testGroup.boardIds.push(board)
    await updateTaskboardGroup({
      id: groupTest.id ?? '',
      ...testGroup,
    })
      .then(() => sendWorkspaceUpdate(v4()))
      .then(() => history(`/calendar/${board}`))
    props.close()
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='xs'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
        width: 1,
      }}
    >
      <Stack paddingX={3} paddingY={3} spacing={2} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          width='100%'
        >
          <Typography variant='h3'>Create a Doc</Typography>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>

        <Stack alignItems='center' height='100%' justifyContent='center' paddingBottom={2}>
          <Avatar
            sx={{
              background: theme.palette.info.main,
              width: 128,
              height: 128 }}
          >
            <ArticleRoundedIcon
              sx={{
                margin: 'auto',
                fontSize: 96,
                color: theme.palette.primary.contrastText,
              }}
            />
          </Avatar>
        </Stack>

        <Stack paddingBottom={1} width={1}>
          <FormControl>
            <InputLabel>
              Workspace
            </InputLabel>
            <Select
              displayEmpty
              id='workspace'
              label='Workspace'
              onChange={event => {
                const foundItem = workspaces.find(item => item.id === event.target.value)
                setActiveWorkspace(foundItem)
              }}
              renderValue={_value => <Stack alignItems='center' direction='row' spacing={1}>
                <Avatar
                  color={activeWorkspace?.color ?? theme.palette.primary.main}
                  sx={{
                    background: activeWorkspace?.color ?? theme.palette.primary.main,
                    color: `${theme.palette.primary.contrastText}!important`,
                    '& .MuiSvgIcon-root': {
                      color: 'white!important',
                    },
                    borderRadius: 0.5,
                  }}
                  variant='rounded'
                >
                  {activeWorkspace?.icon
                    ? taskboardIcons[activeWorkspace.icon]
                    : <HomeRoundedIcon />}
                </Avatar>
                <Typography color={theme.palette.text.secondary} variant='body1'>
                  {activeWorkspace?.title ?? 'Main Workspace'}
                </Typography>
              </Stack>}
              sx={{
                height: 64,
                '.MuiFilledInput-input': {
                  height: '64px!important',
                  alignItems: 'center',
                  display: 'flex',
                },
              }}
              value={activeWorkspace?.id ?? 'Home'}
            >
              <MenuItem value='Home'>
                <ListItemIcon>
                  <Avatar
                    color={theme.palette.primary.main}
                    sx={{
                      background: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                      width: 32,
                      height: 32,
                      borderRadius: 0.5,
                    }}
                    variant='rounded'
                  >
                    <HomeRoundedIcon />
                  </Avatar>
                </ListItemIcon>
                <ListItemText>
                  Main Workspace
                </ListItemText>
              </MenuItem>
              {workspaces.map(item =>
                <MenuItem key={item.id} value={item.id}>
                  <ListItemIcon>
                    <Avatar
                      sx={{
                        width: 32,
                        height: 32,
                        borderRadius: 0.5,
                        bgcolor: item.color,
                        color: theme.palette.primary.contrastText,
                      }}
                      variant='rounded'
                    >
                      {taskboardIcons[item.icon]}
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText>
                    {item.title}
                  </ListItemText>
                </MenuItem>)}
            </Select>
          </FormControl>
        </Stack>
        <Stack>
          {groups &&
          <Autocomplete
            autoHighlight
            autoSelect
            fullWidth
            getOptionLabel={option => option.title}
            onChange={(_test, newValue) => handleChange(newValue?.id ?? '')}
            options={groups}
            renderInput={params =>
              <TextField
                {...params}
                label='Folder'
                placeholder='Select a folder'
                sx={{ marginTop: 0,  height: 42 }}
              />}
            renderOption={(renderProps, option) =>
              <Box component='li' {...renderProps}>
                {option.title}
              </Box>}
            sx={{ marginTop: 2, height: 42 }}
            value={group}
          />}
        </Stack>
        <Stack direction='row' justifyContent='flex-end' spacing={1} width='100%'>
          <Button onClick={() => props.close()} variant='text'>Cancel</Button>
          <Button
            onClick={() => handleClick()}
          >
            Create Doc
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default NewDocumentModal
