import { forwardRef } from 'react'
import type { LinkProps, NavLinkProps } from 'react-router-dom'
// eslint-disable-next-line no-restricted-imports
import { Link as UnsafeRouterLink, NavLink as UnsafeNavLink } from 'react-router-dom'

import type { Optional } from 'src/types/react-app-env'

const warnEmptyTo = () => false

export const NavLink = forwardRef((props: Optional<NavLinkProps, 'to'>, _ref) =>
  props.to
    ? <UnsafeNavLink {...props} to={props.to} />
    : <>{warnEmptyTo()}</>)
NavLink.displayName = 'SafeNavLink'

export const RouterLink = forwardRef((props: Optional<LinkProps, 'to'>, _ref) =>
  props.to
    ? <UnsafeRouterLink {...props} to={props.to} />
    : <>{warnEmptyTo()}</>)
RouterLink.displayName = 'SafeRouterLink'
