import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded'
import AdjustRoundedIcon from '@mui/icons-material/AdjustRounded'
import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded'
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded'
import AppRegistrationRoundedIcon from '@mui/icons-material/AppRegistrationRounded'
import AppShortcutRoundedIcon from '@mui/icons-material/AppShortcutRounded'
import ArchitectureRoundedIcon from '@mui/icons-material/ArchitectureRounded'
import ArchiveRoundedIcon from '@mui/icons-material/ArchiveRounded'
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded'
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded'
import GraphicEqRoundedIcon from '@mui/icons-material/GraphicEqRounded'
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import OndemandVideoRoundedIcon from '@mui/icons-material/OndemandVideoRounded'
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded'
import SellRoundedIcon from '@mui/icons-material/SellRounded'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'
import { v4 } from 'uuid'

export const taskboardIcons = [
  <DashboardRoundedIcon
    key={v4()}
    sx={{
      color: 'transparent!important',
    }}
  />,
  <DashboardRoundedIcon
    key={v4()}
  />,
  <SettingsRoundedIcon
    key={v4()}
  />,
  <MusicNoteRoundedIcon
    key={v4()}
  />,
  <GraphicEqRoundedIcon
    key={v4()}
  />,
  <OndemandVideoRoundedIcon
    key={v4()}
  />,
  <AttachMoneyRoundedIcon
    key={v4()}
  />,
  <SellRoundedIcon
    key={v4()}
  />,
  <PeopleAltRoundedIcon
    key={v4()}
  />,
  <AccessTimeRoundedIcon
    key={v4()}
  />,
  <AdjustRoundedIcon
    key={v4()}
  />,
  <ManageAccountsRoundedIcon
    key={v4()}
  />,
  <AnalyticsRoundedIcon
    key={v4()}
  />,
  <AppShortcutRoundedIcon
    key={v4()}
  />,
  <ArchitectureRoundedIcon
    key={v4()}
  />,
  <ArchiveRoundedIcon
    key={v4()}
  />,
  <ApartmentRoundedIcon
    key={v4()}
  />,
  <AppRegistrationRoundedIcon
    key={v4()}
  />,
]

export const taskboardIconsLarge = [
  <DashboardRoundedIcon
    key={v4()}
    sx={{
      color: 'transparent!important',
    }}
  />,
  <DashboardRoundedIcon
    key={v4()}
    sx={{
      fontSize: 48,
      color: theme => theme.palette.background.default,
    }}
  />,
  <SettingsRoundedIcon
    key={v4()}
    sx={{
      fontSize: 48,
      color: theme => theme.palette.background.default,
    }}
  />,
  <MusicNoteRoundedIcon
    key={v4()}
    sx={{
      fontSize: 48,
      color: theme => theme.palette.background.default,
    }}
  />,
  <GraphicEqRoundedIcon
    key={v4()}
    sx={{
      fontSize: 48,
      color: theme => theme.palette.background.default,
    }}
  />,
  <OndemandVideoRoundedIcon
    key={v4()}
    sx={{
      fontSize: 48,
      color: theme => theme.palette.background.default,
    }}
  />,
  <AttachMoneyRoundedIcon
    key={v4()}
    sx={{
      fontSize: 48,
      color: theme => theme.palette.background.default,
    }}
  />,
  <SellRoundedIcon
    key={v4()}
    sx={{
      fontSize: 48,
      color: theme => theme.palette.background.default,
    }}
  />,
  <PeopleAltRoundedIcon
    key={v4()}
    sx={{
      fontSize: 48,
      color: theme => theme.palette.background.default,
    }}
  />,
  <AccessTimeRoundedIcon
    key={v4()}
    sx={{
      fontSize: 48,
      color: theme => theme.palette.background.default,
    }}
  />,
  <AdjustRoundedIcon
    key={v4()}
    sx={{
      fontSize: 48,
      color: theme => theme.palette.background.default,
    }}
  />,
  <ManageAccountsRoundedIcon
    key={v4()}
    sx={{
      fontSize: 48,
      color: theme => theme.palette.background.default,
    }}
  />,
  <AnalyticsRoundedIcon
    key={v4()}
    sx={{
      fontSize: 48,
      color: theme => theme.palette.background.default,
    }}
  />,
  <AppShortcutRoundedIcon
    key={v4()}
    sx={{
      fontSize: 48,
      color: theme => theme.palette.background.default,
    }}
  />,
  <ArchitectureRoundedIcon
    key={v4()}
    sx={{
      fontSize: 48,
      color: theme => theme.palette.background.default,
    }}
  />,
  <ArchiveRoundedIcon
    key={v4()}
    sx={{
      fontSize: 48,
      color: theme => theme.palette.background.default,
    }}
  />,
  <ApartmentRoundedIcon
    key={v4()}
    sx={{
      fontSize: 48,
      color: theme => theme.palette.background.default,
    }}
  />,
  <AppRegistrationRoundedIcon
    key={v4()}
    sx={{
      fontSize: 48,
      color: theme => theme.palette.background.default,
    }}
  />,
]
