/* eslint-disable complexity */
/* eslint-disable max-lines */
/* eslint-disable sonarjs/cognitive-complexity */
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded'
import LastPageRoundedIcon from '@mui/icons-material/LastPageRounded'
import MarkChatReadRoundedIcon from '@mui/icons-material/MarkChatReadRounded'
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded'
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded'
import { Avatar, IconButton, Link, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Typography, useTheme } from '@mui/material'
import type { MouseEvent } from 'react'
import { useState } from 'react'

import { DrawerState } from 'src/components/pages/Menu/MenuDrawer'
import { timeSince } from 'src/components/pages/Messages/TimeSince'
import { taskboardIcons } from 'src/components/pages/Task/TaskViews/TaskViewComponents/TaskboardIcons'
import { useNotifications } from 'src/components/providers/NotificationProvider'
import { NavLink } from 'src/components/SafeRouterLink'
import type Notification from 'src/models/Notification'
import { NOTIFICATION_TYPE } from 'src/models/Notification'

type Props = {
  toggleDrawer: React.Dispatch<React.SetStateAction<DrawerState>>
}

const MenuNotifications = (props: Props) => {
  const themeItem = useTheme()
  const { notifications, readNotification } = useNotifications()
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)

  const openMenu = Boolean(anchorElement)
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorElement(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorElement(null)
  }

  const handleNotificationRead = (event: MouseEvent<HTMLAnchorElement>, notification: Notification) => {
    if (!notification.read) {
      readNotification(notification.id)
    }
    props.toggleDrawer(DrawerState.Closed)
  }

  const markAllAsRead = () => {
    for (const notificationItem of notifications) {
      if (!notificationItem.read) {
        readNotification(notificationItem.id)
      }
    }
    handleClose()
  }

  return <>
    <Stack height={1} overflow='hidden' paddingTop={2}>
      <Stack alignItems='center' direction='row' justifyContent='space-between'>
        <Typography
          paddingLeft={2}
          variant='h3'
        >
          Notifications
        </Typography>
        <Stack alignItems='center' direction='row'>
          <IconButton onClick={event => handleMenuClick(event)} >
            <MoreHorizRoundedIcon />
          </IconButton>
          <IconButton onClick={() => props.toggleDrawer(DrawerState.Closed)} >
            <LastPageRoundedIcon />
          </IconButton>
        </Stack>
      </Stack>
      <Stack overflow='auto' width={1}>
        {notifications.length === 0 &&
        <Typography color={themeItem.palette.text.label} textAlign='center' variant='body2'>
          No notifications found
        </Typography>}
        {notifications.map(notification =>
          <Link
            color='text.primary'
            component={NavLink}
            key={notification.id}
            onClick={(event: MouseEvent<HTMLAnchorElement>) => handleNotificationRead(event, notification)}
            sx={{
              cursor: 'pointer',
              backgroundColor: notification.read
                ? undefined
                : theme => theme.palette.primary.subtleResting,
              ':hover': {
                background: theme => theme.palette.background.input,
              },
            }}
            to={notification.project && notification.notificationType === NOTIFICATION_TYPE[8]
              ? `/project/${notification.project.id}?goto=messages`
              : notification.taskboard
                ? notification.taskboard.projectId.length > 0
                  ? `/project/${notification.taskboard.projectId}?goto=task&taskId=${notification.taskId}`
                  : `/calendar/${notification.taskboard.id}?taskId=${notification.taskId}`
                : notification.project
                  ? `/project/${notification.project.id}`
                  : notification.notificationType === 'Added As Rightsholder'
                    ? '/splits'
                    : notification.notificationType === 'Task Assigned' ||
                  notification.notificationType === 'Task Updated'
                      ? '/calendar/mytasks'
                      : notification.organisation
                        ? '/settings/general/details'
                        : '/settings/account/personalinformation'}
            underline='none'
          >
            <Stack alignItems='center' direction='row' width={1}>
              <Stack padding={1} width={1}>

                {notification.notificationType === NOTIFICATION_TYPE[2] &&
                <ListItem sx={{ paddingX: 1, paddingY: 0 }}>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        bgcolor: theme => theme.palette.success.componentBackground,
                      }}
                    >
                      <AttachMoneyRoundedIcon
                        sx={{
                          fontSize: 24,
                          color: theme => theme.palette.success.main,
                        }}
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={notification.organisation?.name}
                    secondary='You have new Publishing Cloud earnings'
                  />
                </ListItem>}

                {notification.notificationType === NOTIFICATION_TYPE[3] &&
                <ListItem sx={{ paddingX: 1, paddingY: 0 }}>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        bgcolor: theme => theme.palette.success.componentBackground,
                      }}
                    >
                      <AttachMoneyRoundedIcon
                        sx={{
                          fontSize: 24,
                          color: theme => theme.palette.success.main,
                        }}
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={notification.organisation?.name}
                    secondary='You have new Neighboring Cloud earnings'
                  />
                </ListItem>}

                {notification.notificationType === NOTIFICATION_TYPE[4] &&
                <ListItem sx={{ paddingX: 1, paddingY: 0 }}>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        bgcolor: theme => theme.palette.success.componentBackground,
                      }}
                    >
                      <AttachMoneyRoundedIcon
                        sx={{
                          fontSize: 24,
                          color: theme => theme.palette.success.main,
                        }}
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={notification.organisation?.name}
                    secondary='You have new Distribution Cloud earnings'
                  />
                </ListItem>}

                {notification.notificationType === NOTIFICATION_TYPE[5] &&
                <ListItem sx={{ paddingX: 1, paddingY: 0 }}>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        bgcolor: theme => theme.palette.secondary.subtleResting,
                      }}
                    >
                      <VerifiedRoundedIcon
                        sx={{
                          fontSize: 24,
                          color: theme => theme.palette.secondary.main,
                        }}
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={notification.organisation?.name}
                    secondary='Your organisation was successfully verified'
                  />
                </ListItem>}

                {notification.notificationType === NOTIFICATION_TYPE[6] &&
                <ListItem sx={{ paddingX: 1, paddingY: 0 }}>
                  <ListItemAvatar>
                    <Avatar
                      src={notification.project?.artworkPreviewUrl}
                    >
                      <MusicNoteRoundedIcon
                        sx={{
                          fontSize: 24,
                          color: theme => theme.palette.action.disabled,
                        }}
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={notification.project?.title}
                    secondary='You were added as a collaborator on a project'
                  />
                </ListItem>}

                {notification.notificationType === NOTIFICATION_TYPE[7] &&
                <ListItem sx={{ paddingX: 1, paddingY: 0 }}>
                  <ListItemAvatar>
                    <Avatar>
                      <MusicNoteRoundedIcon
                        sx={{
                          fontSize: 24,
                          color: theme => theme.palette.action.disabled,
                        }}
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary='Splits'
                    secondary='You were added as a rightsholder on a recording'
                  />
                </ListItem>}

                {notification.notificationType === NOTIFICATION_TYPE[8] &&
                <ListItem sx={{ paddingX: 1, paddingY: 0 }}>
                  <ListItemAvatar>
                    <Avatar
                      src={notification.project?.artworkPreviewUrl}
                    >
                      <MusicNoteRoundedIcon
                        sx={{
                          fontSize: 24,
                          color: theme => theme.palette.action.disabled,
                        }}
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={notification.project?.title}
                    secondary={`A new message was posted in ${notification.project?.title}`}
                  />
                </ListItem>}

                {notification.notificationType === NOTIFICATION_TYPE[9] &&
                <ListItem sx={{ paddingX: 1, paddingY: 0 }}>
                  <ListItemAvatar>
                    {notification.taskboard && notification.taskboard.projectId.length === 0
                      ? <Avatar
                        sx={{
                          borderRadius: 1,
                          bgcolor: notification.taskboard.color,
                        }}
                      >
                        {taskboardIcons[notification.taskboard.icon]}
                      </Avatar>
                      : <Avatar
                        src={notification.project?.artworkPreviewUrl}
                        sx={{
                          background: theme => theme.palette.success.componentBackground,
                        }}
                      >
                        <TaskAltRoundedIcon
                          sx={{
                            fontSize: 24,
                            color: theme => theme.palette.success.main,
                          }}
                        />
                      </Avatar>}

                  </ListItemAvatar>
                  <ListItemText
                    primary='New Task Assigned'
                    secondary={notification.taskboard
                      ? `You have been assigned a new task in ${notification.taskboard.title}`
                      : 'You have been assigned a new task'}
                  />
                </ListItem>}

                {notification.notificationType === NOTIFICATION_TYPE[10] &&
                <ListItem sx={{ paddingX: 1, paddingY: 0 }}>
                  <ListItemAvatar>
                    {notification.taskboard && notification.taskboard.projectId.length === 0
                      ? <Avatar
                        sx={{
                          borderRadius: 1,
                          bgcolor: notification.taskboard.color,
                        }}
                      >
                        {taskboardIcons[notification.taskboard.icon]}
                      </Avatar>
                      : <Avatar
                        src={notification.project?.artworkPreviewUrl}
                        sx={{
                          background: theme => theme.palette.success.componentBackground,
                        }}
                      >
                        <TaskAltRoundedIcon
                          sx={{
                            fontSize: 24,
                            color: theme => theme.palette.success.main,
                          }}
                        />
                      </Avatar>}
                  </ListItemAvatar>
                  <ListItemText
                    primary='New Task Update'
                    secondary={notification.taskboard
                      ? `A task assigned to you has received an update in ${notification.taskboard.title}`
                      : 'A task assigned to you has received an update'}
                  />
                </ListItem>}

                {/* Timestamp + org if org notification */}
                <Stack alignItems='center' direction='row' justifyContent='flex-start' paddingLeft={8} spacing={1}>
                  <Typography
                    color='text.label'
                    variant='helperText'
                  >
                    {timeSince(Number(notification.createdAt))}
                  </Typography>
                  {notification.organisation &&
                  <Stack alignItems='center' direction='row' spacing={0.5}>
                    <Avatar
                      alt={notification.organisation.name}
                      key={notification.organisation.id}
                      src={notification.organisation.logoUrl}
                      sx={{
                        height: 16,
                        width: 16,
                        fontSize: 12,
                      }}
                    >
                      {notification.organisation.name.charAt(0)}
                    </Avatar>
                    <Typography
                      color='text.secondary'
                      variant='helperText'
                    >
                      {notification.organisation.name}
                    </Typography>
                  </Stack>}
                </Stack>
              </Stack>
            </Stack>
          </Link>)}
      </Stack>
    </Stack>
    <Menu
      anchorEl={anchorElement}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      onClick={handleClose}
      onClose={handleClose}
      open={openMenu}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
    >
      <MenuItem onClick={() => markAllAsRead()}>
        <ListItemIcon>
          <MarkChatReadRoundedIcon color='action' fontSize='medium' />
        </ListItemIcon>
        Mark all as read
      </MenuItem>
    </Menu>
  </>
}
export default MenuNotifications
