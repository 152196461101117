/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-identical-functions */
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded'
import BorderAllRoundedIcon from '@mui/icons-material/BorderAllRounded'
import CloseIcon from '@mui/icons-material/Close'
import { Avatar, Button, Dialog, IconButton, Stack, TextField, Typography } from '@mui/material'
import type { ChangeEvent } from 'react'
import { useEffect, useState } from 'react'
import { v4 } from 'uuid'

import { savePartialProject } from 'src/api/projects'
import { createReleeseDocument, createReleeseSheet } from 'src/api/taskboards'
import type Project from 'src/models/Project'
import { EmbeddedItem } from 'src/models/Taskboard'

type Props = {
  close: () => void
  open: boolean
  project: Project
  setProject: React.Dispatch<React.SetStateAction<Project | undefined>>
  type: string
}
const AddReleeseDocumentProject = (props: Props) => {
  const [embedName, setEmbedName] = useState('')
  const [embedType, setEmbedType] = useState(props.type)

  useEffect(() => {
    setEmbedType(props.type)
  }, [props.type])

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmbedName(event.target.value)
  }

  const handleClick = async () => {
    if (props.project.id && embedType === 'Releese Doc') {
      await createReleeseDocument()
        .then(async item => {
          await handleAddItem(item.projectId ?? '')
        })
    } else if (props.project.id && embedType === 'Releese Sheet') {
      await createReleeseSheet()
        .then(async item => {
          await handleAddItem(item.id)
        })
    }
  }

  const handleAddItem = async (content: string) => {
    if (props.project.id) {
      const newItem = new EmbeddedItem({
        id: v4(),
        title: embedName,
        embedType,
        content,
      })
      await savePartialProject({
        ...props.project,
        embeddedItems: props.project.embeddedItems.length > 0
          ? [...props.project.embeddedItems, newItem]
          : [newItem] })
        .then(project => props.setProject(project))
        .then(() => props.close())
      setEmbedName('')
      setEmbedType('')
    }
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullWidth
      maxWidth='xs'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Stack marginX='auto' maxWidth='lg' paddingX={3} paddingY={3} spacing={2} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          width='100%'
        >
          <Typography variant='h3'>
            {embedType === 'Releese Doc'
              ? 'Create new Doc'
              : 'Create new Sheet'}
          </Typography>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>

        {embedType === 'Releese Doc' &&
        <Stack alignItems='center' height='100%' justifyContent='center'>
          <Avatar
            sx={{
              background: theme => theme.palette.info.main,
              width: 128,
              height: 128,
            }}
          >
            <ArticleRoundedIcon
              sx={{
                margin: 'auto',
                fontSize: 96,
                color: theme => theme.palette.primary.contrastText,
              }}
            />
          </Avatar>
        </Stack>}

        {embedType === 'Releese Sheet' &&
        <Stack alignItems='center' height='100%' justifyContent='center'>
          <Avatar
            sx={{
              background: theme => theme.palette.success.componentBackground,
              width: 128,
              height: 128 }}
          >
            <BorderAllRoundedIcon color='success' sx={{ margin: 'auto', fontSize: 96 }} />
          </Avatar>
        </Stack>}

        <Stack spacing={4} width={1}>
          <TextField
            fullWidth
            inputProps={{ maxLength: 50 }}
            label='Title'
            onChange={handleNameChange}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                void handleClick()
              }
            }}
            placeholder='Title'
            value={embedName}
          />
          <Stack direction='row' justifyContent='flex-end' marginLeft='auto' marginTop={2} spacing={1} width={1}>
            <Button
              onClick={() => props.close()}
              variant='text'
            >
              Cancel
            </Button>
            <Button
              disabled={embedName.length === 0 ||
                embedType.length === 0}
              onClick={() => handleClick()}
            >
              {embedType === 'Releese Doc'
                ? 'Create new Doc'
                : 'Create new Sheet'}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default AddReleeseDocumentProject
