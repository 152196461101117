/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable complexity */
/* eslint-disable max-lines */
/* eslint-disable no-await-in-loop */
import 'src/styles/Custom/big-calendar.css'

import AppleIcon from '@mui/icons-material/Apple'
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded'
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone'
import FlagRoundedIcon from '@mui/icons-material/FlagRounded'
import { Avatar, AvatarGroup, Button, Card, CardActionArea, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import type { SlideProps } from '@mui/material/Slide'
import Slide from '@mui/material/Slide'
import dayjs from 'dayjs'
import { forwardRef, useEffect, useState } from 'react'
import type { EventProps } from 'react-big-calendar'
import { Calendar as BigCalendar } from 'react-big-calendar'
import { RRule } from 'rrule'
import { createGlobalStyle } from 'styled-components'
import { v4 } from 'uuid'

import { getIcalHashAccount } from 'src/api/accounts'
import { getAllTaskboards, updateTaskItem } from 'src/api/taskboards'
import { sendTasksboardUpdate } from 'src/api/webSocket'
import GoogleCalendarLogo from 'src/components/Icons/GoogleCalendarLogo'
import TaskSyncModal from 'src/components/pages/Task/TaskSync/TaskSyncModal'
import type { CalendarEvent } from 'src/components/pages/Task/TaskViews/TaskViewTypes/Calendar'
import { getDayItem, HeaderTest, ToolbarComponent } from 'src/components/pages/Task/TaskViews/TaskViewTypes/Calendar'
import { useAuth } from 'src/components/providers/AuthProvider'
import { NavLink } from 'src/components/SafeRouterLink'
import type Account from 'src/models/Account'
import type { TaskBoard } from 'src/models/Taskboard'
import { TaskBoardEdit } from 'src/models/Taskboard'
import { dayjsLocalizer } from 'src/utils/dayjsLocalizer'

const localizer = dayjsLocalizer()

export const CustomEvent = (event: EventProps<CalendarEvent>) => {
  const { currentAccount } = useAuth()
  const [assignee, setAsignee] = useState<Account[]>([])
  const [status, setTaskStatus] = useState(event.event.task.status)

  const [lastDone, setLastDone] = useState(event.event.task.lastDoneOn)

  const previousDate = event.event.previousDate

  const isTaskLocked =
    (event.event.task.recurring === 'Weekly' ||
    event.event.task.recurring === 'Monthly') &&
    (event.event.previousDate && new Date(event.event.previousDate).getTime() > Date.now())

  useEffect(() => {
    if (event.event.task.recurring === 'Weekly' || event.event.task.recurring === 'Monthly') {
      if (previousDate && lastDone && new Date(lastDone).getTime() < new Date(previousDate).getTime()) {
        setTaskStatus('Not started')
      }
      if (lastDone && !previousDate) {
        setTaskStatus('Done')
      }
      if (!lastDone) {
        setTaskStatus('Not started')
      }
    }
  }, [lastDone, event.event.task.lastDoneOn, event.event.task.recurring])

  useEffect(() => {
    if (event.event.task.status !== status &&
      event.event.task.recurring !== 'Weekly' &&
      event.event.task.recurring !== 'Monthly') {
      setTaskStatus(event.event.task.status)
    }
  }, [event.event.task.status])

  useEffect(() => {
    const getAssignees = () => {
      setAsignee([])
      for (const assignees of event.event.task.assignee) {
        const temporaryItem = event.event.members.find(item => item.id === assignees)
        if (temporaryItem) {
          setAsignee(previousAssignees => [...previousAssignees, temporaryItem])
        }
      }
    }
    getAssignees()
  }, [event.event.task.assignee])

  const handleChange = async (eventItem: React.MouseEvent<HTMLButtonElement>,
    newStatus: 'Done' | 'In progress' | 'Not approved' | 'Not started') => {
    eventItem.stopPropagation()
    eventItem.preventDefault()
    const oldStatusItem = Object.assign({}, { ...event.event.task })
    setLastDone(new Date())
    setTaskStatus(newStatus)

    if (event.event.taskboard.id) {
      await updateTaskItem(event.event.taskboard.id, event.event.board.id,
        {
          ...event.event.task,
          status: newStatus,
          lastDoneOn: new Date(),
        })
        .then(() => {
          const taskUpdateItem = new TaskBoardEdit({
            id: v4(),
            taskboardId: event.event.taskboard.id,
            taskId: event.event.task.id,
            accountId: currentAccount.id,
            modificationType: 'Task details',
            itemType: 'Field',
            title: `${event.event.task.title}`,
            fields: [{
              fieldName: 'Status',
              oldValue: oldStatusItem.status,
              newValue: newStatus,
            }],
          })
          sendTasksboardUpdate(taskUpdateItem)
        })
    }
  }

  const background = event.event.board.color

  return (
    <Card
      sx={{
        width: 1,
        borderRadius: '4px',
        textDecoration: 'none!important',
        display: 'block',
      }}
      variant='outlined'
    >
      <CardActionArea
        component={NavLink}
        to={event.event.taskboard.projectId
          ? `/project/${event.event.taskboard.projectId}?goto=task&taskId=${event.event.task.id}`
          : `/calendar/${event.event.taskboard.id}?taskId=${event.event.task.id}`}
      >
        <Stack
          direction='column'
          width={1}
        >
          <Stack
            height={8}
            sx={{
              bgcolor: background,
            }}
          />
          <Stack
            direction='column'
            spacing={0.5}
            sx={{
              padding: 1,
            }}
            width={1}
          >
            <Stack
              alignItems='center'
              direction='row'
              justifyContent='space-between'
              width={1}
            >
              {isTaskLocked && event.event.previousDate &&
              <Tooltip
                title={`This task will unlock on ${dayjs(new Date(event.event.previousDate)).format('LL')}`}
              >
                <div>
                  <IconButton
                    aria-label='Complete'
                    disabled
                    size='small'
                    sx={{
                      color: theme => theme.palette.text.label,
                      padding: 0,
                    }}
                  >
                    <CircleTwoToneIcon
                      sx={{
                        color: theme => theme.palette.text.label,
                        pointerEvents: 'none',
                        opacity: 0.5,
                      }}
                    />
                  </IconButton>
                </div>
              </Tooltip>}

              {(event.event.task.priority === 'None' && status !== 'Done') && !isTaskLocked &&
              <IconButton
                aria-label='Complete'
                onClick={eventItem => handleChange(eventItem, 'Done')}
                size='small'
                sx={{
                  color: theme => theme.palette.text.label,
                  padding: 0,
                }}
              >
                <CircleTwoToneIcon
                  sx={{
                    color: theme => theme.palette.text.label,
                    pointerEvents: 'none',
                  }}
                />
              </IconButton>}
              {(event.event.task.priority === 'Low' && status !== 'Done') && !isTaskLocked &&
              <IconButton
                aria-label='Complete'
                color='primary'
                onClick={eventItem => handleChange(eventItem, 'Done')}
                size='small'
                sx={{
                  padding: 0,
                }}
              >
                <CircleTwoToneIcon
                  color='primary'
                  sx={{
                    pointerEvents: 'none',
                  }}
                />
              </IconButton>}
              {(event.event.task.priority === 'Medium' && status !== 'Done') && !isTaskLocked &&
              <IconButton
                aria-label='Complete'
                onClick={eventItem => handleChange(eventItem, 'Done')}
                size='small'
                sx={{
                  color: theme => theme.palette.rating,
                  padding: 0,
                }}
              >
                <CircleTwoToneIcon
                  sx={{
                    color: theme => theme.palette.rating,
                    pointerEvents: 'none',
                  }}
                />
              </IconButton>}
              {(event.event.task.priority === 'High' && status !== 'Done') && !isTaskLocked &&
              <IconButton
                aria-label='Complete'
                color='error'
                onClick={eventItem => handleChange(eventItem, 'Done')}
                size='small'
                sx={{
                  padding: 0,
                }}
              >
                <CircleTwoToneIcon
                  color='error'
                  sx={{
                    pointerEvents: 'none',
                  }}
                />
              </IconButton>}
              {status === 'Done' &&
            (event.event.task.recurring === 'Weekly' || event.event.task.recurring === 'Monthly') &&
            <Tooltip title='This recurring task is completed and locked'>
              <div>
                <IconButton
                  aria-label='Complete'
                  color='success'
                  disabled
                  onClick={eventItem => handleChange(eventItem, 'Not started')}
                  size='small'
                  sx={{
                    padding: 0,
                  }}
                >
                  <CheckCircleRoundedIcon
                    color='success'
                    sx={{
                      pointerEvents: 'none',
                      opacity: 0.5,
                    }}
                  />
                </IconButton>
              </div>
            </Tooltip>}
              {status === 'Done' &&
            event.event.task.recurring !== 'Weekly' &&
            event.event.task.recurring !== 'Monthly' &&
            <IconButton
              aria-label='Complete'
              color='success'
              onClick={eventItem => handleChange(eventItem, 'Not started')}
              size='small'
              sx={{
                padding: 0,
              }}
            >
              <CheckCircleRoundedIcon
                color='success'
                sx={{
                  pointerEvents: 'none',
                }}
              />
            </IconButton>}

              <Stack alignItems='center' direction='row' spacing={0.5}>
                {event.event.task.priority === 'None' &&
                <FlagRoundedIcon
                  fontSize='medium'
                  sx={{
                    color: theme => theme.palette.text.label,
                    pointerEvents: 'none',
                  }}
                />}
                {event.event.task.priority === 'Low' &&
                <FlagRoundedIcon
                  color='primary'
                  fontSize='medium'
                  sx={{
                    pointerEvents: 'none',
                  }}
                />}

                {event.event.task.priority === 'Medium' &&
                <FlagRoundedIcon
                  fontSize='medium'
                  style={{
                    color: '#FBBF24',
                    pointerEvents: 'none',
                  }}
                />}

                {event.event.task.priority === 'High' &&
                <FlagRoundedIcon
                  fontSize='medium'
                  sx={{
                    color: theme => theme.palette.error.main,
                    pointerEvents: 'none',
                  }}
                />}

                {assignee.length > 0 &&
                <AvatarGroup>
                  {assignee.map(assigneeItem =>
                    <Avatar
                      key={assigneeItem.id}
                      src={assigneeItem.profilePictureUrl}
                      sx={{
                        width: 20,
                        height: 20,
                        pointerEvents: 'none',
                        '.MuiSvgIcon-root': {
                          pointerEvents: 'none',
                        },
                      }}
                    />)}
                </AvatarGroup>}
              </Stack>
            </Stack>

            <Typography
              sx={{
                WebkitLineClamp: 2,
                paddingLeft: 0.5,
                WebkitBoxOrient: 'vertical',
                display: '-webkit-box',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '100%',
              }}
              variant='body2'
              whiteSpace='normal'
            >
              {event.event.task.title}
            </Typography>
          </Stack>
        </Stack>
      </CardActionArea>
    </Card>
  )
}

const Transition = forwardRef<typeof Slide, SlideProps>((props, ref) =>
  <Slide direction='up' ref={ref} {...props} />)
Transition.displayName = 'Transition'

const MyCalendar = () => {
  const themeItem = useTheme()
  const matches = useMediaQuery(themeItem.breakpoints.down('md'))
  const [_, setLoading] = useState(true)
  const { currentAccount } = useAuth()
  const [taskboards, setTaskboards] = useState<TaskBoard[]>()

  const [hash, setHash] = useState('')
  const [openSyncModal, setOpenSyncModal] = useState(false)
  const [syncType, setSyncType] = useState<'apple' | 'google'>('google')
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const openMenu = Boolean(anchorElement)

  useEffect(() => {
    void getIcalHashAccount()
      .then(setHash)
  }, [currentAccount.id])

  const flatItems = taskboards?.map(taskboard =>
    taskboard.taskGroups.map(group =>
      group.tasks.flatMap(task => {
        if (task.recurring === 'Monthly') {
          const dateItemTest = new Date(task.startDate ?? 0).getDay()
          const eventsRules = new RRule({
            freq: RRule.WEEKLY,
            byweekday: getDayItem(dateItemTest),
            dtstart: new Date(task.startDate ?? 0),
            interval: 4,
            until: new Date('January 11, 2025'),
          })
          const times = eventsRules.all()

          return times.map((time, index) => ({
            board: group,
            task,
            title: task.title,
            startDate: new Date(time.toString()),
            dueDate: new Date(time.toString()),
            allDay: task.allDay,
            taskboard,
            members: [],
            nextDate: index < times.length - 1 ? times[index + 1] : undefined,
            previousDate: index > 0 ? times[index - 1] : undefined,
          }))
        } else if (task.recurring === 'Weekly') {
          const dateItemTest = new Date(task.startDate ?? 0).getDay()
          const eventsRules = new RRule({
            freq: RRule.WEEKLY,
            byweekday: getDayItem(dateItemTest),
            dtstart: new Date(task.startDate ?? 0),
            interval: 1,
            until: new Date('January 11, 2025'),
          })
          const times = eventsRules.all()

          return times.map((time, index) => ({
            board: group,
            task,
            title: task.title,
            startDate: new Date(time.toString()),
            dueDate: new Date(time.toString()),
            allDay: task.allDay,
            taskboard,
            members: [],
            nextDate: index < times.length - 1 ? times[index + 1] : undefined,
            previousDate: index > 0 ? times[index - 1] : undefined,
          }))
        } else {
          return {
            board: group,
            task,
            title: task.title,
            startDate: new Date(task.startDate ?? 0),
            dueDate: new Date(task.dueDate ?? 0),
            allDay: task.allDay,
            taskboard,
            members: [],
          }
        }
      })))
    .flat(2)
    .filter(task => task.task.assignee.includes(currentAccount.id)) as unknown as CalendarEvent[]

  useEffect(() => {
    setLoading(true)
    getAllTaskboards()
      .then(data => {
        setTaskboards(data)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const GlobalStyles = createGlobalStyle`
  html {
    .rbc-month-row>.rbc-row-bg>.rbc-day-bg {
      border-right: 1px solid ${themeItem.palette.border}!important
    }
    .rbc-month-row+.rbc-month-row {
      border-top: 1px solid ${themeItem.palette.border}!important
    }
    .rbc-off-range-bg {
      background: ${themeItem.palette.border}!important
    }
    .rbc-header {
      border-bottom: 1px solid ${themeItem.palette.border}!important
    }
  }
  `

  return (
    <>
      <Stack
        alignItems='stretch'
        boxSizing='border-box'
        direction='column'
        height={1}
        minWidth={0}
        overflow='auto'
        padding={0}
        width={1}
      >
        <GlobalStyles />
        <Stack alignItems='center' direction='row' justifyContent='space-between' padding={2} spacing={2}>
          <Stack alignItems='center' direction='row' spacing={2}>
            {matches &&
            <IconButton
              color='primary'
              component={NavLink}
              size='small'
              to='/calendar'
            >
              <ChevronLeftRoundedIcon />
            </IconButton>}
            <Avatar
              alt={currentAccount.firstName}
              src={currentAccount.profilePictureUrl}
              style={{
                width: 64,
                height: 64,
                objectFit: 'cover',
              }}
              variant='circular'
            />
            <Stack alignContent='center' height={1} justifyContent='center'>
              <ListItemText
                primary={<Typography variant='h5'>
                  Your Calendar
                </Typography>}
                secondary={currentAccount.firstName}
              />
            </Stack>
          </Stack>
          <Stack>
            <Button
              color='inherit'
              onClick={event => setAnchorElement(event.currentTarget)}
              startIcon={<CalendarMonthRoundedIcon />}
              variant='contained'
            >
              Sync Calendar
            </Button>
          </Stack>
        </Stack>
        <Divider />
        <Stack height={1} overflow='auto' width={1}>
          <BigCalendar
            components={{
              event: CustomEvent,
              month: {
                dateHeader: propsHeader => HeaderTest(propsHeader),
              },
              toolbar: ToolbarComponent,
            }}
            endAccessor='dueDate'
            events={flatItems}
            localizer={localizer}
            showAllEvents
            showMultiDayTimes
            startAccessor='startDate'
            style={{ height: '100%', minWidth: '750px' }}
            titleAccessor='title'
            tooltipAccessor='title'
            views={{ month: true }}
          />
        </Stack>
      </Stack>
      {openSyncModal &&
      <TaskSyncModal
        close={() => setOpenSyncModal(false)}
        hash={hash}
        id={currentAccount.id}
        open={openSyncModal}
        provider={syncType}
        type='account'
      />}
      <Menu
        anchorEl={anchorElement}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClick={() => setAnchorElement(null)}
        onClose={() => setAnchorElement(null)}
        open={openMenu}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <MenuItem
          onClick={() => {
            setSyncType('google')
            setOpenSyncModal(true)
          }}
        >
          <ListItemIcon>
            <GoogleCalendarLogo fontSize='medium' />
          </ListItemIcon>
          Add to Google Calendar
        </MenuItem>
        <MenuItem
          onClick={() => {
            setSyncType('apple')
            setOpenSyncModal(true)
          }}
        >
          <ListItemIcon>
            <AppleIcon color='action' fontSize='medium' />
          </ListItemIcon>
          Add to Apple Calendar
        </MenuItem>
      </Menu>
    </>
  )
}

export default MyCalendar
