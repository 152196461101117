/* eslint-disable no-await-in-loop */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable max-lines */
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import DragIndicatorRoundedIcon from '@mui/icons-material/DragIndicatorRounded'
import { IconButton, Stack, Tooltip } from '@mui/material'

export const newTable = {
  rowCount: 2,
  colCount: 2,
  header: false,
}

export const threeTable = {
  rowCount: 3,
  colCount: 3,
  header: false,
}

export const fourTable = {
  rowCount: 4,
  colCount: 4,
  header: false,
}

type Props = {
  setanchorElementField: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>
}

export const DragHandleItem = (props: Props) =>
  <Stack direction='row'>
    <Tooltip title='Add block'>
      <IconButton
        disableFocusRipple
        disableRipple
        disableTouchRipple
        onClick={event => {
          event.preventDefault()
          event.stopPropagation()
          props.setanchorElementField(event.currentTarget)
        }}
        size='small'
      >
        <AddRoundedIcon />
      </IconButton>
    </Tooltip>
    <Tooltip title='Re-order'>
      <IconButton
        disableFocusRipple
        disableRipple
        disableTouchRipple
        size='small'
      >
        <DragIndicatorRoundedIcon />
      </IconButton>
    </Tooltip>
  </Stack>

