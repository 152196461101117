/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable no-restricted-imports */
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded'
import CreateRoundedIcon from '@mui/icons-material/CreateRounded'
import LinkRoundedIcon from '@mui/icons-material/LinkRounded'
import PhonelinkRoundedIcon from '@mui/icons-material/PhonelinkRounded'
import { Alert, Card, CardActionArea, IconButton, Paper, Snackbar, Stack, Tooltip, Typography, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'

import { createFirstBioLink, createMarketingLink, getBioLink } from 'src/api/links'
import Loading from 'src/components/Loading'
import EditLink from 'src/components/pages/Links/EditLink/EditLink'
import { useAuth } from 'src/components/providers/AuthProvider'
import { MarketingLink } from 'src/models/Marketing'
import { Permissions } from 'src/models/Organisation'

const LinksListBioLink = () => {
  const themeItem = useTheme()
  const { currentOrganisation, currentAccountPermissions, refreshCurrentOrganisation } = useAuth()

  const navigate = useNavigate()
  const [biolinkLoading, setBiolinkLoading] = useState(false)
  const [editLinkModal, setEditLinkModal] = useState(false)
  const [biolink, setBiolink] = useState<MarketingLink>()
  const [linkCopiedToast, setLinkCopiedToastOpen] = useState(false)
  const [openTab, setOpenTab] = useState(0)

  const search = useLocation().search
  const goto = new URLSearchParams(search).get('marketingEditId')

  const handleCloseToast = (event: Event | React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setLinkCopiedToastOpen(false)
  }

  const getBiolinkItem = async () => {
    setBiolinkLoading(true)
    await getBioLink()
      .then(setBiolink)
      .finally(() => setBiolinkLoading(false))
      .catch(() => setBiolinkLoading(false))
  }

  useEffect(() => {
    if (biolink && goto === biolink.id) {
      setEditLinkModal(true)
    }
  }, [goto, biolink])

  useEffect(() => {
    void getBiolinkItem()
  }, [currentOrganisation?.id, goto])

  const createBioLink = async () => {
    if (currentOrganisation) {
      setBiolinkLoading(true)
      await createMarketingLink(new MarketingLink({
        type: 'Biolink',
        title: currentOrganisation.name,
        domain: '',
        descriptionText: 'This is my bio!',
        artworkUrl: currentOrganisation.logoUrl,
        useOrgDefaultSocialIcons: true,
        theme: 'Subtle',
        url: '',
        releaseDate: new Date(),
        useDefaultPixels: currentOrganisation.membershipTier === 'CREATOR_PRO' ||
        currentOrganisation.membershipTier === 'CREATOR_PRO_YEARLY' ||
        currentOrganisation.membershipTier === 'ENTERPRISE' ||
        currentOrganisation.membershipTier === 'ENTERPRISE_YEARLY',
      }))
        .then(async marketingLinkId => {
          await createFirstBioLink()
            .catch(() => null)
          navigate(`/links?marketingEditId=${marketingLinkId}&edit=true`)
        })
        .finally(() => setBiolinkLoading(false))
    }
  }

  const copyLink = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    event.preventDefault()
    setLinkCopiedToastOpen(true)
    void navigator.clipboard.writeText(biolink?.completeLink ?? '')
  }

  const updateLinks = async () => {
    await getBioLink()
      .then(setBiolink)
      .then(async () => refreshCurrentOrganisation())
      .finally(() => setBiolinkLoading(false))
      .catch(() => setBiolinkLoading(false))
  }

  return (
    <>
      {editLinkModal && biolink &&
      <EditLink
        close={() => setEditLinkModal(false)}
        link={biolink}
        open={editLinkModal}
        tab={openTab}
        updateLinks={updateLinks}
      />}
      <Snackbar
        autoHideDuration={5000}
        message='Link Copied'
        onClose={handleCloseToast}
        open={linkCopiedToast}
      >
        <Alert
          onClose={handleCloseToast}
          severity='success'
          sx={{ width: '100%' }}
          variant='filled'
        >
          Link copied
        </Alert>
      </Snackbar>
      <Stack maxWidth={1332} width={1}>
        <Card
          sx={{
            width: 1,
            background: 'transparent',
          }}
          variant='outlined'
        >
          <CardActionArea
            disabled={biolinkLoading}
            onClick={() => {
              if (biolink?.id) {
                if (currentAccountPermissions?.includes(Permissions.CREATE_EDIT_REMOVE_MARKETING_LINKS)) {
                  setOpenTab(0)
                } else {
                  setOpenTab(4)
                }
                setEditLinkModal(true)
              } else {
                void createBioLink()
              }
            }}
          >
            <Stack padding={2} width={1}>
              {biolinkLoading &&
              <Loading />}
              {!biolinkLoading && !biolink &&
              <Stack alignItems='center' direction='row' justifyContent='center' spacing={1} width={1}>
                <AddRoundedIcon color='primary' sx={{ fontSize: '1.5rem' }} />
                <Typography
                  color={themeItem.palette.primary.main}
                  variant='h4'
                >
                  Create your Bio Link
                </Typography>
              </Stack>}
              {biolink &&
              <Stack
                alignItems='center'
                direction='row'
                justifyContent='space-between'
                spacing={2}
                width={1}
              >
                <Stack alignItems='center' direction='row' spacing={2}>
                  <Stack alignItems='center' direction='row' paddingY={1} spacing={2}>
                    {biolink.artworkUrl
                      ? <img
                        alt={biolink.title}
                        src={biolink.artworkUrl}
                        style={{
                          height: 64,
                          width: 64,
                          borderRadius: 64,
                        }}
                      />
                      : <Paper
                        elevation={0}
                        sx={{
                          height: 64,
                          width: 64,
                          borderRadius: '64px!important',
                          backgroundColor: theme => theme.palette.background.input,
                          alignItems: 'center',
                          justifyContent: 'center',
                          display: 'flex',
                        }}
                      >
                        <PhonelinkRoundedIcon
                          sx={{
                            fontSize: 48,
                            color: theme => theme.palette.action.disabled,
                          }}
                        />
                      </Paper>}
                  </Stack>
                  <Stack alignItems='flex-start' direction='column' spacing={0}>
                    <Typography lineHeight={1.4} variant='h4'>
                      Biolink
                    </Typography>
                    <Typography color={themeItem.palette.text.link} variant='body2'>
                      {biolink.completeLink}
                    </Typography>
                  </Stack>
                </Stack>

                <Stack alignItems='center' direction='row' spacing={1}>
                  <Tooltip title='Edit'>
                    <div>
                      <IconButton
                        disabled={!currentAccountPermissions?.includes(Permissions.CREATE_EDIT_REMOVE_MARKETING_LINKS)}
                        onClick={event => {
                          event.preventDefault()
                          event.stopPropagation()
                          setOpenTab(0)
                          setEditLinkModal(true)
                        }}
                      >
                        <CreateRoundedIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                  <Tooltip title='Analytics'>
                    <div>
                      <IconButton
                        onClick={event => {
                          event.preventDefault()
                          event.stopPropagation()
                          setOpenTab(4)
                          setEditLinkModal(true)
                        }}
                        title='Analytics'
                      >
                        <AnalyticsRoundedIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                  <Tooltip title='Copy link'>
                    <div>
                      <IconButton
                        onClick={event => copyLink(event)}
                      >
                        <LinkRoundedIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                </Stack>
              </Stack>}
            </Stack>
          </CardActionArea>
        </Card>
      </Stack>
    </>
  )
}

export default LinksListBioLink
