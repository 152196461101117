/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-identical-functions */
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded'
import BorderAllRoundedIcon from '@mui/icons-material/BorderAllRounded'
import CloseIcon from '@mui/icons-material/Close'
import { Avatar, Button, Card, Dialog, IconButton, Stack, TextField, Typography } from '@mui/material'
import type { ChangeEvent } from 'react'
import { useState } from 'react'

import { savePartialProject } from 'src/api/projects'
import { EMBED_TYPES } from 'src/components/pages/Task/TaskViews/TaskViewComponents/AddEmbedTaskboard'
import EmbedCard from 'src/components/pages/Task/TaskViews/TaskViewComponents/EmbedCard'
import type Project from 'src/models/Project'

type Props = {
  close: () => void
  open: boolean
  project: Project
  embedId: string
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>
  setProject: React.Dispatch<React.SetStateAction<Project | undefined>>
  currentTab: string
}

const EditEmbedProject = (props: Props) => {
  const [embedName, setEmbedName] =
    useState(props.project.embeddedItems.find(item => item.id === props.embedId)?.title ?? '')
  const [embedContent, setEmbedContent] =
    useState(props.project.embeddedItems.find(item => item.id === props.embedId)?.content ?? '')
  const [embedType, setEmbedType] =
  useState(props.project.embeddedItems.find(item => item.id === props.embedId)?.embedType ?? '')

  const handleContentChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmbedContent(event.target.value)
  }

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmbedName(event.target.value)
  }

  const handleClick = async () => {
    const itemToEdit = props.project.embeddedItems.findIndex(item => item.id === props.embedId)
    if (props.project.id && itemToEdit !== -1) {
      await savePartialProject({
        ...props.project,
        embeddedItems: props.project.embeddedItems.map(item =>
          item.id === props.embedId
            ? {
              id: item.id,
              title: embedName,
              embedType,
              content: embedContent,
            }
            : item),
      })
        .then(project => props.setProject(project))
        .then(() => {
          if (props.currentTab === props.embedId) {
            props.setCurrentTab('8')
          }
        })
        .then(props.close)
      setEmbedName('')
      setEmbedType('')
    }
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={embedType !== 'Releese Sheet' && embedType !== 'Releese Doc'}
      fullWidth
      maxWidth='xs'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Stack marginX='auto' maxWidth='lg' paddingX={3} paddingY={3} spacing={2} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          width='100%'
        >
          <Typography variant='h3'>
            Edit Item
          </Typography>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>
        <Stack spacing={4} width={1}>
          {embedType !== 'Releese Sheet' && embedType !== 'Releese Doc' &&
          <Card sx={{ width: 1 }} variant='outlined'>
            <Stack spacing={2}>
              <Stack paddingTop={2} paddingX={2}>
                <Typography variant='subtitle1'>
                  Type
                </Typography>
              </Stack>
              <Stack overflow='auto' paddingBottom={2} width={1}>
                <Stack direction='row' width='fit-content'>
                  {EMBED_TYPES.map(embedOption =>
                    <EmbedCard
                      embedOption={embedOption}
                      key={`project-${embedOption}`}
                      selected={embedType}
                      setEmbedType={setEmbedType}
                    />)}
                </Stack>
              </Stack>
            </Stack>
          </Card>}

          {embedType === 'Releese Doc' &&
          <Stack alignItems='center' height='100%' justifyContent='center'>
            <Avatar
              sx={{
                background: theme => theme.palette.info.main,
                width: 128,
                height: 128 }}
            >
              <ArticleRoundedIcon
                color='info'
                sx={{
                  margin: 'auto',
                  fontSize: 96,
                  color: theme => theme.palette.primary.contrastText,
                }}
              />
            </Avatar>
          </Stack>}

          {embedType === 'Releese Sheet' &&
          <Stack alignItems='center' height='100%' justifyContent='center'>
            <Avatar
              sx={{
                background: theme => theme.palette.success.componentBackground,
                width: 128,
                height: 128 }}
            >
              <BorderAllRoundedIcon
                color='success'
                sx={{ margin: 'auto', fontSize: 96 }}
              />
            </Avatar>
          </Stack>}

          <TextField
            fullWidth
            inputProps={{ maxLength: 50 }}
            label='Title'
            onChange={handleNameChange}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                void handleClick()
              }
            }}
            placeholder='Title'
            value={embedName}
          />
          {embedType !== 'Releese Doc' && embedType !== 'Releese Sheet' &&
          <TextField
            fullWidth
            label={embedType === 'HTML' ? 'Content' : 'URL'}
            multiline
            onChange={handleContentChange}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                void handleClick()
              }
            }}
            placeholder={embedType === 'HTML' ? '<iframe></iframe>' : 'https://'}
            rows={embedType === 'HTML' ? 6 : 1}
            value={embedContent}
          />}

          <Stack direction='row' justifyContent='flex-end' marginLeft='auto' marginTop={2} spacing={1} width={1}>
            <Button
              onClick={() => props.close()}
              variant='text'
            >
              Cancel
            </Button>
            <Button
              disabled={embedName.length === 0 ||
                (embedContent.length === 0 && embedType !== 'Releese Doc' && embedType !== 'Releese Sheet') ||
                embedType.length === 0}
              onClick={() => handleClick()}
            >
              Edit item
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default EditEmbedProject
