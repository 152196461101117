/* eslint-disable react/jsx-props-no-spreading */
import type { PlateRenderElementProps, TElement } from '@udecode/plate'
import { createPluginFactory } from '@udecode/plate'

import type { MyValue } from 'src/components/pages/Notes/Modern/typescript/plateTypes'

export const ELEMENT_LIST_ITEM = 'list-item'

export const ListItemElement = (props: PlateRenderElementProps<MyValue, TElement>) =>
  <li
    {...props.attributes}
    {...props.nodeProps}
    className={props.className}
  >
    {props.children}
  </li>

export const createListItemPlugin = createPluginFactory({
  key: ELEMENT_LIST_ITEM,
  isElement: true,
  component: ListItemElement,
})
