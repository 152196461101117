
import { Stack } from '@mui/material'

import NotesClient from 'src/components/pages/Notes/NotesClient'
import type Project from 'src/models/Project'

type Props = {
  slug: string
  project?: Project
}

const NotesInitialValue = (props: Props) =>
  <Stack width={1}>
    <NotesClient
      project={props.project}
      slug={props.slug}
    />
  </Stack>

export default NotesInitialValue
