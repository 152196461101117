/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Link, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material'
import { useState } from 'react'

import parseObjectProperties from 'src/components/pages/Wallet/OnboardingModal/ParseObjectProperties'
import WalletTermsModal from 'src/components/pages/Wallet/WalletTermsModal'
import type { PayoutUser } from 'src/models/Payout'

const createTableRowData = (fieldName: string, fieldValue: string) => ({ fieldName, fieldValue })

const capitalizeTxt = (txt: string) => txt.charAt(0).toUpperCase() + txt.slice(1)

const formatFieldKey = (fieldKey: string) => {
  // TODO clean up if conditions
  if (fieldKey) {
    if (fieldKey.toString() === 'firstLine') {
      return 'Address'
    } else if (fieldKey.toString() === 'currencyCode') {
      return 'Currency'
    } else if (fieldKey.toString().toLowerCase() === 'iban') {
      return 'IBAN'
    } else if (fieldKey.toString().toLowerCase() === 'bic') {
      return 'BIC'
    } else if (fieldKey.toString() === 'legalType') {
      return 'Account Type'
    }

    return capitalizeTxt(fieldKey)
      .split(/(?=[A-Z])/)
      .join(' ')
  } else {
    return ''
  }
}

const formatFieldValue = (fieldKey: string, fieldValue: string) => {
  let designedValue = ''

  if (fieldKey && fieldValue) {
    if (fieldKey.toString() === 'legalType') {
      if (fieldValue.toString() === 'PRIVATE') {
        return 'Individual'
      } else if (fieldValue.toString() === 'BUSINESS') {
        return 'Company'
      }

      return capitalizeTxt(fieldValue.toString().toLowerCase())
    } else if (fieldKey.toString() === 'country') {
      const countryDetails = { country: fieldValue }

      if (countryDetails.country) {
        return countryDetails.country
      }
    }

    if (fieldValue.toString() === 'iban') {
      return 'IBAN'
    }

    if (fieldValue.toString().split('_').length > 1) {
      designedValue = capitalizeTxt(fieldValue.toString().toLowerCase())
        .split('_')
        .join(' ')

      return designedValue
    }

    return fieldValue
  } else {
    return ''
  }
}

type Props = {
  recipientData: PayoutUser
  name: string
}

type field = {
  fieldName: string
  fieldValue: string
}

const OnboardingSubmit = (props: Props) => {
  const themeItem = useTheme()
  const [openTermsModal, setOpenTermsModal] = useState(false)

  const customObject: any = {}
  const rows: field[] = []
  const recipientData = props.recipientData

  parseObjectProperties(recipientData, (key: string, value: boolean | number | string) => {
    if (value && typeof value === 'string' && value.toString().length > 0 && key !== 'id') {
      customObject[`${key}`] = value
    }
  })

  for (const [key, value] of Object.entries(customObject)) {
    if (
      key &&
      key.toString() !== 'firstLine' &&
      key.toString() !== 'city' &&
      key.toString() !== 'postCode' &&
      key.toString() !== 'country' &&
      key.toString() !== 'id' &&
      key.toString() !== 'name' &&
      key.toString() !== 'key' &&
      key.toString() !== 'profile' &&
      key.toString() !== 'type' &&
      key.toString() !== 'bankDetailsFormat' &&
      typeof value === 'string'
    ) {
      rows.push(
        createTableRowData(formatFieldKey(key), formatFieldValue(key, value))
      )
    }
  }

  if (recipientData.details?.address?.firstLine) {
    rows.push(
      createTableRowData(
        formatFieldKey('firstLine'),
        formatFieldValue(
          'firstLine',
          recipientData.details.address.firstLine
        )
      )
    )
  }
  if (recipientData.details?.address?.city) {
    rows.push(
      createTableRowData(
        formatFieldKey('city'),
        formatFieldValue('city', recipientData.details.address.city)
      )
    )
  }
  if (recipientData.details?.address?.postCode) {
    rows.push(
      createTableRowData(
        formatFieldKey('postCode'),
        formatFieldValue('postCode', recipientData.details.address.postCode)
      )
    )
  }
  if (recipientData.details?.address?.country) {
    rows.push(
      createTableRowData(
        formatFieldKey('country'),
        formatFieldValue('country', recipientData.details.address.country.name)
      )
    )
  }

  return (
    <Stack alignItems='center' spacing={2} width={1}>
      <TableContainer>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow
              sx={{
                '& .MuiTableCell-root': {
                  borderColor: theme => theme.palette.divider,
                },
              }}
            >
              <TableCell>
                <Typography variant='subtitle1'>
                  Field
                </Typography>
              </TableCell>
              <TableCell >
                <Typography variant='subtitle1'>
                  Value
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => row.fieldName && row.fieldValue &&
              <TableRow
                key={row.fieldName}
                sx={{
                  '& .MuiTableCell-root': {
                    borderColor: theme => theme.palette.divider,
                  },
                }}
              >
                <TableCell>
                  <Typography color={themeItem.palette.text.label} variant='body2'>
                    {row.fieldName}
                  </Typography>
                </TableCell>
                <TableCell>
                  {row.fieldValue}
                </TableCell>
              </TableRow>)}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography textAlign='center' variant='helperText'>
        {'By adding a payout method, I agree to the '}
        <Link
          onClick={() => setOpenTermsModal(true)}
          sx={{
            ':hover': {
              cursor: 'pointer',
            },
          }}
        >
          Wise Terms and Conditions
        </Link>
      </Typography>
      <WalletTermsModal
        close={() => setOpenTermsModal(false)}
        open={openTermsModal}
      />
    </Stack>
  )
}

export default OnboardingSubmit
