/* eslint-disable sonarjs/no-duplicate-string */
import FacebookIcon from '@mui/icons-material/Facebook'
import GoogleIcon from '@mui/icons-material/Google'
import { LoadingButton } from '@mui/lab'
import { Button, IconButton, Stack, TextField } from '@mui/material'
import { useState } from 'react'

import SnapchatIcon from 'src/components/Icons/SnapchatIcon'
import TikTokIcon from 'src/components/Icons/TikTokIcon'
import { useAuth } from 'src/components/providers/AuthProvider'

type Props = {
  close?: () => void
}

const EditOrgLinkPixels = (props: Props) => {
  const { currentOrganisation, updateCurrentOrganisation } = useAuth()
  const [saving, setSaving] = useState(false)
  const [tiktok, setTiktok] = useState(currentOrganisation?.defaultTikTokTag ?? '')
  const [google, setGoogle] = useState(currentOrganisation?.defaultGTMTag ?? '')
  const [facebook, setFacebook] = useState(currentOrganisation?.defaultFbTag ?? '')
  const [snapchat, setSnapchat] = useState(currentOrganisation?.defaultSnapchatTag ?? '')

  const notRightTier =
    currentOrganisation?.membershipTier !== 'CREATOR_PRO' &&
    currentOrganisation?.membershipTier !== 'CREATOR_PRO_YEARLY' &&
    currentOrganisation?.membershipTier !== 'ENTERPRISE' &&
    currentOrganisation?.membershipTier !== 'ENTERPRISE_YEARLY'

  const save = async () => {
    setSaving(true)
    await updateCurrentOrganisation({
      ...currentOrganisation,
      defaultTikTokTag: tiktok,
      defaultGTMTag: google,
      defaultFbTag: facebook,
      defaultSnapchatTag: snapchat,
    })
      .then(() => {
        setSaving(false)
        if (props.close) {
          props.close()
        }
      })
  }

  return (
    <Stack direction='column' height={1} maxWidth={896} overflow='auto' padding={2} spacing={4}>
      <Stack direction='column' justifyContent='space-between' spacing={4}>
        <Stack direction='row' justifyContent='space-between' paddingTop={2} spacing={2}>
          <IconButton
            disableRipple
            sx={{
              backgroundColor: theme => theme.palette.facebook,
              color: 'white',
              ':hover': {
                backgroundColor: theme => theme.palette.facebook,
                cursor: 'default',
              },
            }}
          >
            <FacebookIcon />
          </IconButton>
          <TextField
            autoComplete='new-password'
            disabled={notRightTier}
            fullWidth
            id='facebookpixel'
            label='Facebook pixel'
            onChange={event => setFacebook(event.target.value)}
            placeholder='Enter your Facebook Pixel ID'
            sx={{ marginTop: 0 }}
            value={facebook}
          />
        </Stack>
        <Stack direction='row' justifyContent='space-between' paddingTop={2} spacing={2}>
          <IconButton
            disableRipple
            sx={{
              backgroundColor: theme => theme.palette.google,
              color: 'white',
              ':hover': {
                backgroundColor: theme => theme.palette.google,
                cursor: 'default',
              },
            }}
          >
            <GoogleIcon />
          </IconButton>
          <TextField
            autoComplete='new-password'
            disabled={notRightTier}
            fullWidth
            id='gtmpixel'
            label='Google tag manager'
            onChange={event => setGoogle(event.target.value)}
            placeholder='Enter your GTM code'
            sx={{ marginTop: 0 }}
            value={google}
          />
        </Stack>
        <Stack direction='row' justifyContent='space-between' paddingTop={2} spacing={2}>
          <IconButton
            disableRipple
            sx={{
              backgroundColor: theme => theme.palette.tiktok,
              color: 'white',
              ':hover': {
                backgroundColor: theme => theme.palette.tiktok,
                cursor: 'default',
              },
            }}
          >
            <TikTokIcon />
          </IconButton>
          <TextField
            autoComplete='new-password'
            disabled={notRightTier}
            fullWidth
            id='tiktokpixel'
            label='TikTok pixel'
            onChange={event => setTiktok(event.target.value)}
            placeholder='Enter your TikTok Pixel ID'
            sx={{ marginTop: 0 }}
            value={tiktok}
          />
        </Stack>
        <Stack direction='row' justifyContent='space-between' paddingTop={2} spacing={2}>
          <IconButton
            disableRipple
            sx={{
              backgroundColor: '#FFFC00',
              color: 'white',
              ':hover': {
                backgroundColor: '#FFFC00',
                cursor: 'default',
              },
            }}
          >
            <SnapchatIcon />
          </IconButton>
          <TextField
            autoComplete='new-password'
            disabled={notRightTier}
            fullWidth
            id='snapchat'
            label='Snapchat pixel'
            onChange={event => setSnapchat(event.target.value)}
            placeholder='Enter your Snapchat Pixel ID'
            sx={{ marginTop: 0 }}
            value={snapchat}
          />
        </Stack>
      </Stack>
      <Stack direction='row' justifyContent='space-between' spacing={2}>
        {props.close &&
        <Button
          onClick={props.close}
          variant='text'
        >
          Cancel
        </Button>}
        <LoadingButton
          loading={saving}
          onClick={() => save()}
          variant='contained'
        >
          Save
        </LoadingButton>
      </Stack>
    </Stack>
  )
}

export default EditOrgLinkPixels
