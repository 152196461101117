/* eslint-disable react-hooks/exhaustive-deps */
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { LoadingButton } from '@mui/lab'
import { Alert, Dialog, IconButton, ListItemText, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'

import { downloadStatement, downloadStatementProject } from 'src/api/analytics'
import EarningsExportRow from 'src/components/pages/Earnings/EarningsDistribution/Modals/ExportEarningsItem'
import type { AnalyticsMonthlyChart } from 'src/models/Analytics'

type Props = {
  readonly close: () => void
  readonly open: boolean
  readonly analyticsMonthlyChart: AnalyticsMonthlyChart[]
  readonly projectId?: string
}

const EarningsExportModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))
  const data = props.analyticsMonthlyChart.sort((a, b) => new Date(b._id).getTime() - new Date(a._id).getTime())

  const [loading, setLoading] = useState(false)

  const total = data.map(item => item.amount).reduce((partialSum, a) => partialSum + a, 0)

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='sm'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Stack spacing={1} width={1}>
        <Stack alignItems='center' direction='row' justifyContent='space-between' paddingTop={2} paddingX={2} width={1}>
          <Typography variant='h3'>
            Export Earnings
          </Typography>
          <IconButton onClick={() => props.close()}>
            <CloseRoundedIcon color='disabled' />
          </IconButton>
        </Stack>
        <Stack direction='column' paddingBottom={2} paddingX={2} spacing={1} width={1}>
          <Alert severity='info' variant='filled'>
            The exported documents represent total earnings and streaming data, they do not account for
            splits and recoups.
          </Alert>
          <Stack alignItems='center' direction='row' justifyContent='center' paddingY={1} spacing={2} width={1}>
            <ListItemText
              primary='Grand total'
              secondary={`${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total)}`}
            />
            <LoadingButton
              loading={loading}
              onClick={async () => {
                setLoading(true)
                await (props.projectId
                  ? downloadStatementProject('all',
                    props.projectId,
                    new Date().getFullYear().toString(),
                    new Date().toLocaleString('default', { month: 'long' }))
                    .then(() => setLoading(false))
                  : downloadStatement('all',
                    new Date().getFullYear().toString(),
                    new Date().toLocaleString('default', { month: 'long' }))
                    .then(() => setLoading(false)))
              }}
              variant='contained'
            >
              Download all
            </LoadingButton>
          </Stack>
          <Table>
            <TableHead
              sx={{
                '.MuiTableCell-root': {
                  borderBottom: 2,
                  borderBottomColor: 'divider',
                  color: 'text.secondary',
                },
              }}
            >
              <TableRow>
                <TableCell>
                  Year
                </TableCell>
                <TableCell>
                  Month
                </TableCell>
                <TableCell>
                  Amount
                </TableCell>
                <TableCell>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(item =>
                <EarningsExportRow
                  item={item}
                  key={item._id}
                  projectId={props.projectId}
                />)}
            </TableBody>
          </Table>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default EarningsExportModal
