import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { AppBar, Dialog, Divider, IconButton, Slide, Stack, Toolbar, Typography } from '@mui/material'

import SubmitPitch from 'src/components/pages/Projects/PitchModal/PitchSubmit'
import type { Pitch } from 'src/models/Pitch'
import type Project from 'src/models/Project'

type Props = {
  readonly close: () => void
  readonly open: boolean
  readonly project: Project
  readonly pitch: Pitch
}

const ViewPitchModal = (props: Props) =>
  <Dialog
    BackdropProps={{
      timeout: 500,
    }}
    closeAfterTransition
    fullScreen
    onClose={props.close}
    open={props.open}
    sx={{
      '& .MuiPaper-root': {
        transform: 'none!important',
      },
      '.MuiDialog-paper': {
        height: '100%',
      },
    }}
  >
    <Slide direction='up' in={props.open}>
      <Stack
        height={1}
        overflow='auto'
        sx={{
          background: theme => theme.palette.background.input,
        }}
      >
        <AppBar position='sticky' sx={{ top: 0, background: theme => theme.palette.background.default }}>
          <Toolbar sx={{ justifyContent: 'space-between' }}>
            <Typography variant='h3'>
              Your Pitch
            </Typography>
            <IconButton
              aria-label='close'
              onClick={props.close}
            >
              <CloseRoundedIcon />
            </IconButton>
          </Toolbar>
          <Divider sx={{ width: 1 }} />
        </AppBar>

        <Stack alignItems='center' width={1}>
          <Stack
            height={1}
            marginTop={4}
            maxWidth='lg'
            paddingBottom={8}
            paddingX={1}
            width={1}
          >
            <SubmitPitch
              artist={props.pitch.artist}
              country={props.pitch.country}
              fanbaseLocation={props.pitch.fanbaseLocation}
              genre={props.pitch.genre}
              marketing={props.pitch.marketing}
              priorityTitle={props.pitch.priorityTitle}
              project={props.project}
              similarArtists={props.pitch.similarArtists}
            />
          </Stack>
        </Stack>
      </Stack>
    </Slide>
  </Dialog>

export default ViewPitchModal
