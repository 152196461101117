import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import { Avatar, Dialog, IconButton, ListItemText, Stack, useMediaQuery, useTheme } from '@mui/material'

import AnalyticsDistribution from 'src/components/pages/Analytics/AnalyticsTabs/AnalyticsDistribution'

type Props = {
  readonly close: () => void
  readonly open: boolean
  readonly idItem: string
  readonly title: string
  readonly avatar: string
  readonly artists: string
}

const CatalogRowModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='lg'
      onClose={props.close}
      open={props.open}
      sx={{
        '& .MuiPaper-root': {
          overflow: 'hidden',
        },
      }}
    >
      <Stack overflow='auto' width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          paddingLeft={2}
          paddingRight={2}
          paddingTop={2}
          width={1}
        >
          <Stack
            alignItems='center'
            direction='row'
            spacing={2}
            width={1}
          >
            <Avatar
              src={props.avatar}
              sx={{
                width: 64,
                height: 64,
                background: theme => theme.palette.background.input,
              }}
              variant='rounded'
            >
              <MusicNoteRoundedIcon
                sx={{
                  color: theme => theme.palette.action.disabled,
                }}
              />
            </Avatar>
            <ListItemText
              primary={props.title}
              secondary={props.artists}
            />
          </Stack>
          <IconButton onClick={props.close}>
            <CloseRoundedIcon />
          </IconButton>
        </Stack>
        <AnalyticsDistribution
          artwork={props.avatar}
          songstatsId={props.idItem}
        />
      </Stack>
    </Dialog>
  )
}

export default CatalogRowModal
