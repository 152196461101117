/* eslint-disable complexity */
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'
import AlternateEmailRoundedIcon from '@mui/icons-material/AlternateEmailRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CodeRoundedIcon from '@mui/icons-material/CodeRounded'
import InsertLinkRoundedIcon from '@mui/icons-material/InsertLinkRounded'
import SaveRoundedIcon from '@mui/icons-material/SaveRounded'
import ViewWeekRoundedIcon from '@mui/icons-material/ViewWeekRounded'
import { Avatar, Card, CardActionArea, LinearProgress, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'

import MarketingSettingsModal from 'src/components/pages/Links/LinksList/LinksListModal/MarketingCloudSettingsModal'
import NewLinkModal from 'src/components/pages/Links/LinksList/LinksListModal/NewLinkModal'
import { useAuth } from 'src/components/providers/AuthProvider'
import { Permissions } from 'src/models/Organisation'

const LinksListActions = () => {
  const {
    currentOrganisation,
    currentAccountPermissions,
  } = useAuth()
  const [newLinkModal, setNewLinkModalOpen] = useState(false)
  const [settingsTab, setSettingsTab] = useState('profile')
  const [settings, setSettings] = useState(false)
  const themeItem = useTheme()
  const matches = useMediaQuery(themeItem.breakpoints.down('md'))

  const profileDone = !!currentOrganisation &&
    currentOrganisation.name.length > 0 &&
    currentOrganisation.logoUrl.length > 0

  const pixelsDone = !!currentOrganisation &&
      currentOrganisation.membershipTier !== 'FREE' &&
      currentOrganisation.membershipTier !== 'CREATOR' &&
      currentOrganisation.membershipTier !== 'CREATOR_YEARLY' &&
      currentOrganisation.defaultFbTag.length > 0 &&
      currentOrganisation.defaultGTMTag.length > 0 &&
      currentOrganisation.defaultSnapchatTag.length > 0 &&
      currentOrganisation.defaultTikTokTag.length > 0

  const socialsDone = !!currentOrganisation &&
      currentOrganisation.defaultSocialList.length > 0

  const domainDone = !!currentOrganisation &&
      currentOrganisation.customDomain.length > 0

  const arrayDoneItems = [profileDone, pixelsDone, socialsDone, domainDone]

  const percentageDone = arrayDoneItems.filter(item => item).length * 25

  return (
    <>
      {newLinkModal &&
      currentAccountPermissions?.includes(Permissions.CREATE_EDIT_REMOVE_MARKETING_LINKS) &&
      <NewLinkModal
        close={() => setNewLinkModalOpen(false)}
        open={newLinkModal}
      />}
      <MarketingSettingsModal
        close={() => setSettings(false)}
        open={settings}
        tab={settingsTab}
      />
      <Stack direction={matches ? 'column' : 'row'} maxWidth={1332} spacing={4} width={1}>
        <Stack direction='column' width={1}>
          <Typography marginBottom={1} variant='h3'>
            Set up your Smart Links
          </Typography>
          <LinearProgress
            color={percentageDone === 100 ? 'success' : 'primary'}
            sx={{
              height: 12,
              borderRadius: 2,
              marginBottom: 2,
            }}
            value={percentageDone}
            variant='determinate'
          />
          <Stack direction={matches ? 'column' : 'row'} spacing={2} width={1}>
            <Stack direction='column' spacing={2} width={1}>
              <Card
                sx={{
                  height: 1,
                  background: 'transparent',
                }}
                variant='outlined'
              >
                <CardActionArea
                  onClick={() => {
                    setSettings(true)
                    setSettingsTab('profile')
                  }}
                  sx={{ height: 1 }}
                >
                  <Stack alignItems='center' direction='row' height={1} padding={2} spacing={1} width={1}>
                    <Avatar
                      sx={{
                        background: theme => theme.palette.primary.main,
                        color: theme => theme.palette.primary.contrastText,
                      }}
                    >
                      <AccountCircleRoundedIcon />
                    </Avatar>
                    <Typography fontSize='0.9rem' fontWeight='400' variant='h6'>
                      Finalize profile
                    </Typography>
                    {profileDone &&
                    <CheckCircleRoundedIcon color='success' fontSize='small' />}
                  </Stack>
                </CardActionArea>
              </Card>
              <Card
                sx={{
                  height: 1,
                  background: 'transparent',
                }}
                variant='outlined'
              >
                <CardActionArea
                  onClick={() => {
                    setSettings(true)
                    setSettingsTab('social')
                  }}
                  sx={{ height: 1 }}
                >
                  <Stack alignItems='center' direction='row' height={1} padding={2} spacing={1} width={1}>
                    <Avatar
                      sx={{
                        background: theme => theme.palette.secondary.main,
                        color: theme => theme.palette.primary.contrastText,
                      }}
                    >
                      <AlternateEmailRoundedIcon />
                    </Avatar>
                    <Typography fontSize='0.9rem' fontWeight='400' variant='h6'>
                      Set socials
                    </Typography>
                    {socialsDone &&
                    <CheckCircleRoundedIcon color='success' fontSize='small' />}
                  </Stack>
                </CardActionArea>
              </Card>
            </Stack>
            <Stack direction='column' spacing={2} width={1}>
              <Card
                sx={{
                  height: 1,
                  background: 'transparent',
                }}
                variant='outlined'
              >
                <CardActionArea
                  onClick={() => {
                    setSettings(true)
                    setSettingsTab('pixels')
                  }}
                  sx={{ height: 1 }}
                >
                  <Stack alignItems='center' direction='row' height={1} padding={2} spacing={1} width={1}>
                    <Avatar
                      sx={{
                        background: theme => theme.palette.success.main,
                        color: theme => theme.palette.primary.contrastText,
                      }}
                    >
                      <CodeRoundedIcon />
                    </Avatar>
                    <Typography fontSize='0.9rem' fontWeight='400' variant='h6'>
                      Add pixels
                    </Typography>
                    {pixelsDone &&
                    <CheckCircleRoundedIcon color='success' fontSize='small' />}
                  </Stack>
                </CardActionArea>
              </Card>
              <Card
                sx={{
                  height: 1,
                  background: 'transparent',
                }}
                variant='outlined'
              >
                <CardActionArea
                  onClick={() => {
                    setSettings(true)
                    setSettingsTab('domain')
                  }}
                  sx={{ height: 1 }}
                >
                  <Stack alignItems='center' direction='row' height={1} padding={2} spacing={1} width={1}>
                    <Avatar
                      sx={{
                        background: theme => theme.palette.info.main,
                        color: theme => theme.palette.primary.contrastText,
                      }}
                    >
                      <InsertLinkRoundedIcon />
                    </Avatar>
                    <Typography fontSize='0.9rem' fontWeight='400' variant='h6'>
                      Link custom domain
                    </Typography>
                    {domainDone &&
                    <CheckCircleRoundedIcon color='success' fontSize='small' />}
                  </Stack>
                </CardActionArea>
              </Card>
            </Stack>
          </Stack>
        </Stack>

        <Stack direction='column' width={1}>
          <Typography marginBottom={1} variant='h3'>
            Recommended Actions
          </Typography>
          <Card
            sx={{
              height: 1,
              background: 'transparent',
            }}
            variant='outlined'
          >
            <Stack direction='row' height={1} padding={2} spacing={2} width={1}>
              <Card
                elevation={0}
                sx={{
                  width: 1,
                  background: 'transparent',
                }}
              >
                <CardActionArea
                  onClick={() => setNewLinkModalOpen(true)}
                  sx={{ height: 1, width: 1 }}
                >
                  <Stack alignItems='center' height={1} justifyContent='center' padding={1} width={1}>
                    <Avatar
                      sx={{
                        background: theme => theme.palette.primary.main,
                        width: 64,
                        height: 64,
                      }}
                    >
                      <ViewWeekRoundedIcon
                        sx={{
                          margin: 'auto',
                          fontSize: 42,
                          color: theme => theme.palette.primary.contrastText,
                          rotate: '90deg',
                        }}
                      />
                    </Avatar>
                    <Typography textAlign='center' variant='h5'>
                      Create a Smart link
                    </Typography>
                    <Typography fontSize='.8rem!important' textAlign='center' variant='body2'>
                      Redirect your fans to their preferred platform and gain subscribers
                    </Typography>
                  </Stack>
                </CardActionArea>
              </Card>
              <Card
                elevation={0}
                sx={{
                  width: 1,
                  background: 'transparent',
                }}
              >
                <CardActionArea
                  onClick={() => setNewLinkModalOpen(true)}
                  sx={{ height: 1, width: 1 }}
                >
                  <Stack alignItems='center' height={1} justifyContent='center' padding={1} width={1}>
                    <Avatar
                      sx={{
                        background: theme => theme.palette.success.main,
                        width: 64,
                        height: 64,
                      }}
                    >
                      <SaveRoundedIcon
                        sx={{
                          margin: 'auto',
                          fontSize: 42,
                          color: theme => theme.palette.primary.contrastText,
                        }}
                      />
                    </Avatar>
                    <Typography textAlign='center' variant='h5'>
                      Create a Pre-save link
                    </Typography>
                    <Typography fontSize='.8rem!important' textAlign='center' variant='body2'>
                      Grow your release day streams by saving your music in your fan&apos;s libraries
                    </Typography>
                  </Stack>
                </CardActionArea>
              </Card>
            </Stack>
          </Card>
        </Stack>
      </Stack>
    </>
  )
}

export default LinksListActions
