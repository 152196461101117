/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Stack } from '@mui/system'
import type { TComment } from '@udecode/plate-comments'
import { useCommentsActions, useCommentsSelectors } from '@udecode/plate-comments'
import type { ReactNode } from 'react'
import { useEffect, useState } from 'react'

import { useNotesComments } from 'src/components/providers/NotesCommentsProvider'

type Props = {
  children: ReactNode
  documentId: string
  setActiveId: React.Dispatch<React.SetStateAction<string | null | undefined>>
}

const CommentsUpdater = (props: Props) => {
  const { notesComments } = useNotesComments()
  const { comments } = useCommentsActions()
  const commentItem = useCommentsSelectors().activeCommentId()

  const [itemTyped, setItemTyped] = useState<Record<string, TComment>>()

  useEffect(() => {
    setItemTyped({})
    setItemTyped(Object.fromEntries(new Map((notesComments
      .map(item => ({
        id: item.id?.toString() ?? '',
        value: item.value,
        createdAt: item.createdAt,
        userId: item.userId,
        parentId: item.parentId?.toString() ?? '',
        isResolved: item.isResolved ?? false,
      })) as TComment[])
      .map(object => [object.id, object]))))
  }, [notesComments, notesComments.length, props.documentId])

  useEffect(() => {
    if (commentItem && commentItem !== null && commentItem !== undefined) {
      props.setActiveId(commentItem)
    }
  }, [commentItem])

  comments()(itemTyped ?? {})

  return (
    <Stack>
      {props.children}
    </Stack>
  )
}

export default CommentsUpdater
