/* eslint-disable @typescript-eslint/no-dynamic-delete */
/* eslint-disable switch-case/no-case-curly */
/* eslint-disable max-lines */
import { Stack, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import { visuallyHidden } from '@mui/utils'
import _ from 'lodash'
import type { Key } from 'react'
import { useState } from 'react'

import TopPlaylistRowItem from 'src/components/pages/Audience/Components/Playlists/TopPlaylistRowItem'
import type { TopPlaylists } from 'src/models/Analytics'

type Data = {
  playlist_id: string
  playlist_name: string
  playlist_type: string
  curator_id: string
  curator_name: string
  followers_total: number
  image_url: string
  top_position: number
  recorded_at: string
  source: string
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

type Order = 'asc' | 'desc'

const getComparator = (
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
  ) => number => order === 'desc'
  ? (a, b) => descendingComparator(a, b, orderBy)
  : (a, b) => -descendingComparator(a, b, orderBy)

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((element, index) => [element, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map(element => element[0])
}

type HeadCell = {
  disablePadding: boolean
  id: keyof Data
  label: string
  numeric: boolean
}

const headCells: readonly HeadCell[] = [
  {
    id: 'image_url',
    numeric: true,
    disablePadding: false,
    label: 'Artwork',
  },
  {
    id: 'playlist_name',
    numeric: true,
    disablePadding: false,
    label: 'Title',
  },
  {
    id: 'source',
    numeric: true,
    disablePadding: false,
    label: 'Platform',
  },
  {
    id: 'followers_total',
    numeric: true,
    disablePadding: false,
    label: 'Followers',
  },
  {
    id: 'top_position',
    numeric: true,
    disablePadding: false,
    label: 'Top Position',
  },
  {
    id: 'recorded_at',
    numeric: true,
    disablePadding: false,
    label: 'Added At',
  },
]

type EnhancedTableProps = {
  readonly onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void
  readonly order: Order
  readonly orderBy: string
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { order, orderBy,  onRequestSort } =
    props
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  return (
    <TableHead sx={{
      '.MuiTableCell-root': {
        borderBottom: 2,
        borderBottomColor: 'divider',
        color: 'text.secondary',
      },
    }}
    >
      <TableRow>
        {headCells.map(headCell =>
          <TableCell
            align='left'
            key={headCell.id}
            padding='normal'
            sortDirection={orderBy === headCell.id ? order : false}
            width={headCell.id === 'image_url' ? 100 : 'auto'}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id
                ? <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
                : null}
            </TableSortLabel>
          </TableCell>)}
      </TableRow>
    </TableHead>
  )
}

type Props = {
  readonly topPlaylistItems: TopPlaylists
  readonly currentTab: string
}

const TopPlaylistsRows = (props: Props) => {
  const rows = (props.topPlaylistItems.data &&
    props.topPlaylistItems.data.filter(item =>
      item.source === props.currentTab || props.currentTab === 'Overview')
      .flatMap(itemSource =>
        itemSource.top_playlists.map(itemPlaylist =>
          ({
            playlist_id: itemPlaylist.playlist_id,
            playlist_name: itemPlaylist.playlist_name,
            playlist_type: itemPlaylist.playlist_type,
            curator_id: itemPlaylist.curator_id,
            curator_name: itemPlaylist.curator_name,
            followers_total: itemPlaylist.followers_total,
            image_url: itemPlaylist.image_url,
            top_position: itemPlaylist.top_position,
            recorded_at: itemPlaylist.recorded_at,
            source: itemSource.source,
          }))) as unknown as Data[] | undefined) ?? []

  const [order, setOrder] = useState<Order>('desc')
  const [orderBy, setOrderBy] = useState<keyof Data>('followers_total')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number.parseInt(event.target.value, 10))
    setPage(0)
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

  return (
    <Box width={1}>
      <Stack sx={{ width: '100%', mb: 2 }}>
        <Typography variant='h3'>
          Top playlists
        </Typography>
        <TableContainer>
          <Table
            aria-labelledby='tableTitle'
            size='medium'
            sx={{
              minWidth: 750,
              tableLayout: 'fixed',
            }}
          >
            <EnhancedTableHead
              onRequestSort={handleRequestSort}
              order={order}
              orderBy={orderBy}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`
                  return (
                    <TopPlaylistRowItem
                      added_at={row.recorded_at}
                      artwork={row.image_url}
                      external_url=''
                      followers_count={row.followers_total}
                      key={labelId}
                      name={row.playlist_name}
                      owner_name={row.curator_name}
                      source={row.source}
                      top_position={row.top_position}
                      type={row.playlist_type}
                    />
                  )
                })}
              {emptyRows > 0 &&
                <TableRow
                  style={{
                    height: 33 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component='div'
          count={rows.length}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 50]}
        />
      </Stack>
    </Box>
  )
}

export default TopPlaylistsRows
