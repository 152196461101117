/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable id-length */
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded'
import type { Theme } from '@mui/material'
import { Avatar, Skeleton, TableCell, TableRow, Typography, useTheme } from '@mui/material'
import { Stack } from '@mui/system'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useEffect, useState } from 'react'
import { v4 } from 'uuid'

import { getAccount } from 'src/api/accounts'
import type Account from 'src/models/Account'
import type { TaskBoardEdit, TasksboardEditField } from 'src/models/Taskboard'
import { TASK_MODIFICATION_TYPES } from 'src/models/Taskboard'

type Props = {
  item: TaskBoardEdit
  members: Account[]
}

const embedFields = (field: TasksboardEditField, themeItem: Theme) =>
  <Typography color={themeItem.palette.text.secondary} key={v4()} variant='body2'>
    <span>
      {`${field.fieldName} from `}
      <strong>
        {field.oldValue}
      </strong>
      {' to '}
      <strong>
        {field.newValue}
      </strong>
    </span>
  </Typography>

const HistoryItem = (props: Props) => {
  dayjs.extend(relativeTime)
  const [assignee, setAsignee] = useState<Account>()

  const getWho = async () => {
    const temporaryItem = props.members.find(item => item.id === props.item.accountId)
    if (temporaryItem) {
      setAsignee(temporaryItem)
    } else {
      await getAccount(props.item.accountId)
        .then(account => account && account !== null && setAsignee(account))
    }
  }

  const themeItem = useTheme()

  useEffect(() => {
    void getWho()
  }, [])

  return (
    <TableRow
      hover
      onClick={() => null}
      role='checkbox'
      sx={{
        '.MuiTableCell-root': {
          borderBottomColor: 'divider',
          paddingY: 2,
          fontFamily: 'Matter',
        },
      }}
    >
      <TableCell>
        <Typography
          color={themeItem.palette.text.label}
          variant='body1'
        >
          <AccessTimeRoundedIcon />
          {` ${dayjs(props.item.createdAt).fromNow(true)} ago`}
        </Typography>
      </TableCell>

      <TableCell>
        {assignee
          ? <Stack alignItems='center' direction='row' spacing={1}>
            <Avatar
              src={assignee.profilePictureUrl}
              sx={{
                height: 24,
                width: 24,
              }}
            />
            <Typography
              color={themeItem.palette.text.secondary}
              sx={{
                wordBreak: 'keep-all',
              }}
              variant='body1'
            >
              {`${assignee.firstName}`}
            </Typography>
          </Stack>
          : <Stack alignItems='center' direction='row' spacing={1}>
            <Skeleton height={32} variant='circular' width={32} />
            <Skeleton variant='text' width={160} />
          </Stack>}

      </TableCell>

      <TableCell>
        {props.item.modificationType === TASK_MODIFICATION_TYPES[0] &&
        <Typography variant='body2'>
          <span>
            Posted an update on
            {' '}
            <strong>
              {props.item.title}
            </strong>
          </span>
        </Typography>}
        {props.item.modificationType === TASK_MODIFICATION_TYPES[1] &&
        <Typography variant='body2'>
          <span>
            Re-ordered tasks
            {' '}
            <strong>
              {props.item.title}
            </strong>
          </span>
        </Typography>}
        {props.item.modificationType === TASK_MODIFICATION_TYPES[2] &&
        <Typography variant='body2'>
          <span>
            Created the task
            {' '}
            <strong>
              {props.item.title}
            </strong>
          </span>
        </Typography>}
        {props.item.modificationType === TASK_MODIFICATION_TYPES[3] &&
        <Typography variant='body2'>
          <span>
            Assigned a task to
            {' '}
            <strong>
              {props.item.title}
            </strong>
          </span>
        </Typography>}
        {props.item.modificationType === TASK_MODIFICATION_TYPES[4] &&
        <Stack direction='column'>
          {' '}
          <Typography variant='body2'>
            <span>
              Updated the task
              {' '}
              <strong>
                {props.item.title}
              </strong>
            </span>
          </Typography>
          {props.item.fields.map(field =>
            embedFields(field, themeItem))}
        </Stack>}
        {props.item.modificationType === TASK_MODIFICATION_TYPES[5] &&
        <Typography variant='body2'>
          <span>
            Removed the task
            {' '}
            <strong>
              {props.item.title}
            </strong>
          </span>
        </Typography>}
        {props.item.modificationType === TASK_MODIFICATION_TYPES[6] &&
        <Typography variant='body2'>
          <span>
            Created the section
            {' '}
            <strong>
              {props.item.title}
            </strong>
          </span>
        </Typography>}
        {props.item.modificationType === TASK_MODIFICATION_TYPES[7] &&
        <Stack direction='column'>
          <Typography variant='body2'>
            <span>
              Edited the column
              {' '}
              <strong>
                {props.item.title}
              </strong>
            </span>
          </Typography>
          {props.item.fields.map(field =>
            embedFields(field, themeItem))}
        </Stack>}
        {props.item.modificationType === TASK_MODIFICATION_TYPES[8] &&
        <Typography variant='body2'>
          <span>
            Removed the column
            {' '}
            <strong>
              {props.item.title}
            </strong>
          </span>
        </Typography>}
        {props.item.modificationType === TASK_MODIFICATION_TYPES[9] &&
        <Stack direction='column'>
          <Typography variant='body2'>
            <span>
              Edited the taskboard&apos;s details
              {' '}
              <strong>
                {props.item.title}
              </strong>
            </span>
          </Typography>
          {props.item.fields.map(field =>
            embedFields(field, themeItem))}
        </Stack>}
        {props.item.modificationType === TASK_MODIFICATION_TYPES[10] &&
        <Typography variant='body2'>
          <span>
            Embedded
            {' '}
            <strong>
              {props.item.title}
            </strong>
          </span>
        </Typography>}
        {props.item.modificationType === TASK_MODIFICATION_TYPES[11] &&
        <Typography variant='body2'>
          <span>
            Removed the embedded item
            {' '}
            <strong>
              {props.item.title}
            </strong>
          </span>
        </Typography>}
        {props.item.modificationType === TASK_MODIFICATION_TYPES[12] &&
        <Stack direction='column'>
          <Typography variant='body2'>
            <span>
              Updated the embedded item
              {' '}
              <strong>
                {props.item.title}
              </strong>
            </span>
          </Typography>
          {props.item.fields.map(field =>
            embedFields(field, themeItem))}
        </Stack>}
        {props.item.modificationType === TASK_MODIFICATION_TYPES[13] &&
        <Typography variant='body2'>
          <span>
            Added a custom field
            {' '}
            <strong>
              {props.item.title}
            </strong>
          </span>
        </Typography>}
        {props.item.modificationType === TASK_MODIFICATION_TYPES[14] &&
        <Stack direction='column'>
          <Typography variant='body2'>
            <span>
              Updated the custom field
              {' '}
              <strong>
                {props.item.title}
              </strong>
            </span>
          </Typography>
          {props.item.fields.map(field =>
            embedFields(field, themeItem))}
        </Stack>}
        {props.item.modificationType === TASK_MODIFICATION_TYPES[15] &&
        <Typography variant='body2'>
          <span>
            Removed the custom field
            {' '}
            <strong>
              {props.item.title}
            </strong>
          </span>
        </Typography>}
        {props.item.modificationType === TASK_MODIFICATION_TYPES[16] &&
        <Typography variant='body2'>
          <span>
            Imported the template
            {' '}
            <strong>
              {props.item.title}
            </strong>
          </span>
        </Typography>}
        {props.item.modificationType === TASK_MODIFICATION_TYPES[17] &&
        <Typography variant='body2'>
          <span>
            Changed hidden fields
          </span>
        </Typography>}
        {props.item.modificationType === TASK_MODIFICATION_TYPES[18] &&
        <Typography variant='body2'>
          <span>
            Uploaded
            {' '}
            <strong>
              {props.item.title}
            </strong>
          </span>
        </Typography>}
        {props.item.modificationType === TASK_MODIFICATION_TYPES[19] &&
        <Typography variant='body2'>
          <span>
            Duplicated a section
          </span>
        </Typography>}
      </TableCell>
    </TableRow>
  )
}
export default HistoryItem
