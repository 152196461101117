/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable complexity */
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import ClearIcon from '@mui/icons-material/Clear'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import LockOpenRoundedIcon from '@mui/icons-material/LockOpenRounded'
import LockRoundedIcon from '@mui/icons-material/LockRounded'
import { Avatar, Badge, Box, Button, IconButton, Stack, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'

import EditRightsholderModal from 'src/components/pages/Projects/EditProject/EditProjectTabs/RecordingsTab/RecordingTabs/RightsTab/EditRightsholderModal'
import SplitTour from 'src/components/pages/Tour/Recordings/SplitTour'
import { useAuth } from 'src/components/providers/AuthProvider'
import { Permissions } from 'src/models/Organisation'
import type Project from 'src/models/Project'
import type { RightsHolder } from 'src/models/Recording'

type Props = {
  readonly rightsholder: RightsHolder
  readonly remove: () => void
  readonly project?: Project | undefined
  readonly updateRightsHolder: (rightsholder: RightsHolder) => Promise<void>
  readonly type: 'Master' | 'Publishing'
  readonly locked: boolean
  readonly toggleSplitHolder: (type: 'master' | 'publishing') => Promise<void>
  readonly index?: number
}

const RightsListItem = (props: Props) => {
  const { currentOrganisation, currentAccountPermissions } = useAuth()
  const [_, setRightsholderValues] = useState(props.rightsholder)
  const [edit, setOpenEdit] = useState(false)

  const themeItem = useTheme()
  const matches = useMediaQuery(themeItem.breakpoints.down('md'))

  useEffect(() => {
    setRightsholderValues(props.rightsholder)
  }, [props.rightsholder])

  return (
    <>
      {props.rightsholder.account.id === currentOrganisation?.id &&
      <SplitTour />}
      <Box
        border={1}
        borderColor={theme => theme.palette.border}
        borderRadius={theme => theme.shape.inputRadius}
        marginTop={3}
        padding={2}
      >
        <Stack direction={matches ? 'column' : 'row'} spacing={1} width={1}>
          <Stack width={1}>
            <Stack alignItems='center' direction='row' spacing={1} width={1}>
              <Typography sx={{ paddingRight: 1 }} variant='subtitle2'>
                {`${props.rightsholder.ownership} %`}
              </Typography>
              {props.rightsholder.status === 'Locked' &&
              <Tooltip title='Confirmed share'>
                <CheckCircleRoundedIcon color='success' fontSize='small' />
              </Tooltip>}
              <Button
                color='inherit'
                sx={{
                  textDecoration: 'none',
                  color: 'inherit',
                }}
                variant='text'
              >
                <Stack alignItems='center' direction='row' spacing={1.5} width={1}>
                  <Badge
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    badgeContent={<CheckCircleRoundedIcon
                      color='secondary'
                      sx={{
                        backgroundColor: 'white',
                        borderRadius: '50%',
                        width: 12,
                        height: 12,
                        fontSize: 8,
                      }}
                    />}
                    overlap='circular'
                    sx={{
                      '.MuiBadge-badge': {
                        display: props.rightsholder.account.verified ? undefined : 'none',
                      },
                    }}
                  >
                    <Avatar
                      src={props.rightsholder.account.logoUrl}
                      sx={{ width: 24, height: 24 }}
                    />
                  </Badge>
                  <Stack direction='column' width={1}>
                    <Typography textAlign='left' variant='body2'>
                      {props.rightsholder.account.name}
                    </Typography>
                    <Typography textAlign='left' variant='helperText'>
                      {props.rightsholder.account.organisationEmail}
                    </Typography>
                  </Stack>
                </Stack>
              </Button>
            </Stack>
          </Stack>

          <Stack direction='row' sx={{ flexShrink: 0 }}>
            {props.rightsholder.account.id === currentOrganisation?.id &&
            <Tooltip title={props.rightsholder.status === 'Locked'
              ? 'Unlock your split'
              : 'Lock your split'}
            >
              <div>
                <IconButton
                  color={props.rightsholder.status === 'Locked'
                    ? 'primary'
                    : 'success'}
                  disabled={props.project?.distributed ||
                    props.locked ||
                    !currentAccountPermissions?.includes(Permissions.MANAGE_SPLITS)}
                  id={props.index ? 'split-lock' : undefined}
                  onClick={async () => {
                    await props.toggleSplitHolder(props.type === 'Master' ? 'master' : 'publishing')
                  }}
                  size='small'
                >
                  {props.rightsholder.status === 'Locked' &&
                  <LockRoundedIcon />}
                  {props.rightsholder.status === 'Unlocked' &&
                  <LockOpenRoundedIcon />}
                </IconButton>
              </div>
            </Tooltip>}
            {((props.rightsholder.account.id === currentOrganisation?.id) ||
          props.locked ||
          (props.project &&
            props.project.organisation?.id === currentOrganisation?.id)) &&
            <Tooltip title='Edit'>
              <div>
                <IconButton
                  color='default'
                  disabled={props.rightsholder.status === 'Locked' ||
            props.project?.distributed ||
            props.locked ||
            !currentAccountPermissions?.includes(Permissions.MANAGE_SPLITS)}
                  onClick={() => setOpenEdit(true)}
                  size='small'
                >
                  <EditRoundedIcon />
                </IconButton>
              </div>
            </Tooltip>}

            {((props.rightsholder.account.id === currentOrganisation?.id) ||
          props.locked ||
          (props.project &&
            props.project.organisation?.id === currentOrganisation?.id)) &&
            <Tooltip title='Remove'>
              <div>
                <IconButton
                  color='error'
                  disabled={props.rightsholder.status === 'Locked' ||
            props.project?.distributed ||
            props.locked ||
            !currentAccountPermissions?.includes(Permissions.MANAGE_SPLITS)}
                  onClick={props.remove}
                  size='small'
                >
                  <ClearIcon />
                </IconButton>
              </div>
            </Tooltip>}
          </Stack>
        </Stack>
      </Box>
      {edit && !(props.rightsholder.status === 'Locked' ||
            props.project?.distributed ||
            props.locked) &&
            <EditRightsholderModal
              close={() => setOpenEdit(false)}
              open={edit}
              rightsholder={props.rightsholder}
              setRightsholderValues={setRightsholderValues}
              updateRightsHolder={props.updateRightsHolder}
            />}
    </>
  )
}

export default RightsListItem
