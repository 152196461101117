import type { RouteProps } from 'react-router-dom'
import { Navigate } from 'react-router-dom'

import type { EarlyAuth } from './AuthProvider'
import { useAuth } from './AuthProvider'

type Props =
  RouteProps & {
    elementItem: JSX.Element
  }

const LoggedOutRouter = (props: Props) => {
  const { currentFirebaseUser } = useAuth() as EarlyAuth

  const redirectTo = new URLSearchParams(window.location.search).get('redirectTo')?.replace('%2F', '/') ?? '/'

  return currentFirebaseUser
    ? props.elementItem
    : <Navigate to={redirectTo} />
}

export default LoggedOutRouter
