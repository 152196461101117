/* eslint-disable react-hooks/exhaustive-deps */
import type { FC } from 'react'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'

export type UploadingItem = {
  id: string
  file: File
  parent: string
  projectId?: string
  organisationId?: string
  taskId?: string
  taskboardId?: string
  loading: number
  total: number
}

export type LoadingItem = {
  id: string
  loading: number
  total: number
}

type FilesProviderContextProps = {
  files?: UploadingItem[]
  loading?: LoadingItem[]
  setFiles: React.Dispatch<React.SetStateAction<UploadingItem[] | null | undefined>>
  setLoading: React.Dispatch<React.SetStateAction<LoadingItem[] | null | undefined>>
}

export type InitProps = Omit<FilesProviderContextProps,
'files' | 'loading' | 'setFiles' | 'setLoading'> & {
  files: FilesProviderContextProps['files'] | null | undefined
  setFiles: FilesProviderContextProps['setFiles'] | null | undefined
  loading: FilesProviderContextProps['loading'] | null | undefined
  setLoading: FilesProviderContextProps['setLoading'] | null | undefined
}

const initialValue = {
  files: undefined,
  loading: undefined,
} as InitProps

const FilesContext = createContext(initialValue as FilesProviderContextProps)

export const useFileUploader = () => useContext(FilesContext)

export const FilesProvider: FC = props => {
  const [files, setFiles] = useState<InitProps['files']>()
  const [loading, setLoading] = useState<InitProps['loading']>()

  useEffect(() => {
    const message = 'Your files are not finished uploading, are you sure you want to leave?'
    function listener(event: BeforeUnloadEvent) {
      if (files && files.length > 0) {
        event.preventDefault()
        event.returnValue = message
        return message
      }
    }

    window.addEventListener('beforeunload', listener)

    return () => {
      window.removeEventListener('beforeunload', listener)
    }
  }, [files?.length])

  const value = useMemo<InitProps>(
    () => ({
      files,
      setFiles,
      loading,
      setLoading,
    }),
    [files,
      loading]
  ) as FilesProviderContextProps

  return (
    <FilesContext.Provider value={value}>
      {props.children}
    </FilesContext.Provider>
  )
}
