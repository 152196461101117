import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded'
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded'
import { Alert, AlertTitle, Button, Card, Stack, Switch, Typography } from '@mui/material'
import { useState } from 'react'

import BillingModal from 'src/components/pages/Account/Billing/BillingModal'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { MarketingLink } from 'src/models/Marketing'

type Props = {
  smartLinkData: MarketingLink
  setLinkData: React.Dispatch<React.SetStateAction<MarketingLink>>
}

const SubscriptionCard = (props: Props) => {
  const { currentOrganisation } = useAuth()

  const [billingModal, setBillingModalOpen] = useState(false)

  return (
    <>
      {billingModal && currentOrganisation &&
      <BillingModal
        account={currentOrganisation}
        close={() => setBillingModalOpen(false)}
        open={billingModal}
      />}
      <Stack width={1}>
        <Stack
          alignItems='flex-start'
          direction='row'
          justifyContent='space-between'
          paddingTop={2}
          width={1}
        >
          <Typography variant='h3'>
            Subscriptions
          </Typography>
        </Stack>
        <Card
          variant='outlined'
        >
          <Stack direction='column' padding={2} spacing={2} width={1}>
            <Stack alignItems='center' direction='row' justifyContent='space-between' spacing={2}>
              <Stack direction='column'>
                <Typography lineHeight={1.4} variant='h4'>
                  Let your fans subscribe
                </Typography>
                <Typography color='text.secondary' variant='body1'>
                  Add a button so your visitors can subscribe to your Releese profile
                </Typography>
              </Stack>
              <Stack alignItems='center' direction='row' spacing={2}>
                <Switch
                  checked={props.smartLinkData.foreverPresave}
                  disabled={currentOrganisation?.membershipTier === 'FREE'}
                  onChange={(_event, checked) => props.setLinkData(old => ({ ...old, foreverPresave: checked }))}
                />
              </Stack>
            </Stack>
            <Alert
              icon={false}
              severity='success'
              sx={{
                color: 'white!important',
              }}
              variant='filled'
            >
              <AlertTitle
                sx={{
                  fontSize: '1rem',
                  fontWeight: 'bold',
                }}
              >
                <NotificationsRoundedIcon sx={{ fontSize: '1.5rem' }} />
                {' Grow your fanbase'}
              </AlertTitle>
              When your listeners subscribe to your Releese profile, they will automatically save all your future
              releases in their Spotify, Apple Music, or Deezer library.
            </Alert>
            {currentOrganisation?.membershipTier === 'FREE' &&
            <Button
              onClick={() => setBillingModalOpen(true)}
              startIcon={<AutoAwesomeRoundedIcon />}
              sx={{
                width: 'fit-content',
                flexShrink: 0,
                color: 'white!important',
                background: '#FFA500',
                ':hover': {
                  background: '#c47f00',
                },
              }}
            >
              Upgrade
            </Button>}
          </Stack>
        </Card>
      </Stack>
    </>
  )
}

export default SubscriptionCard
