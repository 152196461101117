import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded'
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded'
import CalculateRoundedIcon from '@mui/icons-material/CalculateRounded'
import FolderRoundedIcon from '@mui/icons-material/FolderRounded'
import ForumRoundedIcon from '@mui/icons-material/ForumRounded'
import LibraryMusicRoundedIcon from '@mui/icons-material/LibraryMusicRounded'
import PhonelinkRoundedIcon from '@mui/icons-material/PhonelinkRounded'
import PublishRoundedIcon from '@mui/icons-material/PublishRounded'
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded'
import TextSnippetRoundedIcon from '@mui/icons-material/TextSnippetRounded'

const GetIconFeature = (feature: string, active: boolean) => {
  switch (feature) {
    case 'Tasks':
      return <TaskAltRoundedIcon color={active ? 'primary' : 'action'} fontSize='medium' />
    case 'Files':
      return <FolderRoundedIcon color={active ? 'primary' : 'action'} fontSize='medium' />
    case 'Messages':
      return <ForumRoundedIcon color={active ? 'primary' : 'action'} fontSize='medium' />
    case 'Notes':
      return <TextSnippetRoundedIcon color={active ? 'primary' : 'action'} fontSize='medium' />
    case 'Landing Pages':
      return <PhonelinkRoundedIcon color={active ? 'primary' : 'action'} fontSize='medium' />
    case 'Distribution':
      return <PublishRoundedIcon color={active ? 'primary' : 'action'} fontSize='medium' />
    case 'Analytics':
      return <AnalyticsRoundedIcon color={active ? 'primary' : 'action'} fontSize='medium' />
    case 'Earnings':
      return <AttachMoneyRoundedIcon color={active ? 'primary' : 'action'} fontSize='medium' />
    case 'Accounting':
      return <CalculateRoundedIcon color={active ? 'primary' : 'action'} fontSize='medium' />
    default:
      return <LibraryMusicRoundedIcon color={active ? 'primary' : 'action'} fontSize='medium' />
  }
}

export default GetIconFeature
