import CloseIcon from '@mui/icons-material/Close'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import { Avatar, Dialog, Divider, IconButton, ListItemText, Skeleton, Stack, useMediaQuery, useTheme } from '@mui/material'

import EarningsDistribution from 'src/components/pages/Earnings/EarningsTabs/EarningsDistribution'
import type Recording from 'src/models/Recording'

type Props = {
  close: () => void
  open: boolean
  selectedProject: string
  selectedRecording: string
  recording?: Recording
  loading: boolean
}

const EarningsDistributionModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='lg'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
        width: 1,
      }}
    >
      <Stack height={1} overflow='hidden' width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          paddingX={2}
          position='sticky'
          width={1}
        >
          <Stack alignItems='center' direction='row' paddingY={2} spacing={2}>
            {props.loading
              ? <>
                <Skeleton
                  height={64}
                  variant='rounded'
                  width={64}
                />
                <Skeleton
                  height={20}
                  variant='text'
                  width={100}
                />
              </>
              : <>
                <Avatar
                  src={props.recording?.artworkPreviewUrl}
                  sx={{
                    width: 64,
                    height: 64,
                  }}
                  variant='rounded'
                >
                  <MusicNoteRoundedIcon />
                </Avatar>
                <ListItemText
                  primary={props.recording?.title}
                  secondary={props.recording
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    ? props.recording.primaryArtists?.map(artist => artist.name).join(', ')
                    : ''}
                />
              </>}
          </Stack>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>

        <Divider />

        <Stack direction='row' height={1} justifyContent='center' overflow='auto' width={1}>
          <EarningsDistribution
            selectedProject={props.selectedProject}
            selectedRecording={props.selectedRecording}
          />
        </Stack>
      </Stack>
    </Dialog>
  )
}
export default EarningsDistributionModal
