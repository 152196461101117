import SaveRoundedIcon from '@mui/icons-material/SaveRounded'
import { Avatar, Badge, Card, CardActionArea, Stack, Typography, useTheme } from '@mui/material'

import { useAuth } from 'src/components/providers/AuthProvider'

type Props = {
  setSelected: React.Dispatch<React.SetStateAction<number>>
  selected: number
}

const PresaveLinkCard = (props: Props) => {
  const themeItem = useTheme()
  const transitionMode = 'all 0.2s ease'
  const { currentOrganisation } = useAuth()

  const disabled = !currentOrganisation ||
    currentOrganisation.membershipTier === 'FREE'

  return (
    <Card
      elevation={0}
      sx={{
        background: 'transparent',
        border: theme => props.selected === 1
          ? `1px solid ${theme.palette.success.main}`
          : '1px solid transparent',
        '&:hover': {
          cursor: 'pointer',
          transition: transitionMode,
          background: theme => theme.palette.success.componentBackground,
        },
      }}
    >
      <CardActionArea
        disabled={disabled}
        onClick={() => {
          if (currentOrganisation &&
            currentOrganisation.membershipTier !== 'FREE') {
            props.setSelected(1)
          }
        }}
        sx={{ height: 1, borderRadius: 1 }}
      >
        <Stack alignItems='center' direction='column' padding={2} spacing={1} width={1}>
          <Stack alignItems='center' height='100%' justifyContent='center'>
            <Badge
              badgeContent='Essential+'
              color='success'
              invisible={!disabled}
              sx={{
                '.MuiBadge-badge': {
                  right: '48px',
                },
              }}
            >
              <Avatar
                sx={{
                  background: theme => disabled
                    ? theme.palette.action.disabled
                    : theme.palette.success.main,
                  width: 96,
                  height: 96,
                }}
              >
                <SaveRoundedIcon
                  sx={{
                    margin: 'auto',
                    fontSize: 64,
                    color: theme => theme.palette.primary.contrastText,
                  }}
                />
              </Avatar>
            </Badge>
          </Stack>
          <Stack alignItems='center' direction='column' spacing={0}>
            <Typography
              color={disabled
                ? themeItem.palette.text.disabled
                : themeItem.palette.text.primary}
              variant='h4'
            >
              Pre-save
            </Typography>
            <Typography
              align='center'
              color={disabled
                ? themeItem.palette.text.disabled
                : themeItem.palette.text.secondary}
              variant='body2'
            >
              Engage your audience on streaming platforms before your release goes live.
            </Typography>
          </Stack>
        </Stack>
      </CardActionArea>
    </Card>
  )
}
export default PresaveLinkCard
