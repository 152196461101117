/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-duplicate-imports */
/* eslint-disable react/no-unstable-nested-components */
import { useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import type { ReactourStep, ReactourStepContentArgs } from 'reactour'
import Tour from 'reactour'

import TourStep from 'src/components/pages/Tour/TourComponents/TourStep'
import { useAuth } from 'src/components/providers/AuthProvider'

const ViewReleaseTour = () => {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const { currentOrganisation, updateCurrentOrganisation } = useAuth()
  const [open, setOpen] = useState(false)

  const closeTour = () => {
    setOpen(false)
    if (currentOrganisation) {
      void updateCurrentOrganisation({
        ...currentOrganisation,
        onboarding: {
          ...currentOrganisation.onboarding,
          releaseView: false,
        },
      })
    }
  }

  useEffect(() => {
    if (currentOrganisation?.onboarding.releaseView) {
      setOpen(currentOrganisation.onboarding.releaseView)
    }
  }, [currentOrganisation?.onboarding.releaseView])

  const myRef = useRef(document.getElementById('nav-release'))

  useEffect(() => {
    const element = document.getElementById('nav-release')
    if (element) myRef.current = element

    if (myRef.current) {
      myRef.current.addEventListener('click', closeTour)
    }
    return () => {
      if (myRef.current) {
        myRef.current.removeEventListener('click', closeTour)
      }
    }
  }, [myRef.current, closeTour])

  const steps: ReactourStep[] = [
    {
      content: ({ close, goTo, step }: ReactourStepContentArgs) =>
        <TourStep
          close={close}
          description='Releases contain everything you need to put out music, you can follow these steps to build one.'
          final={false}
          finalAction={() => closeTour()}
          finalText='Got it'
          goTo={goTo}
          step={step}
          title='Building your release'
        />
      ,
      position: mobile ? 'top' : 'bottom',
      selector: '#steps-release',
      style: {
        width: 300,
        maxWidth: 300,
        backgroundColor: theme.palette.background.paper,
        padding: 0,
      },
    },
    {
      content: ({ close, goTo, step }: ReactourStepContentArgs) =>
        <TourStep
          close={close}
          description='Click here to go to your release&apos;s recordings'
          final
          finalAction={() => closeTour()}
          finalText='Got it'
          goTo={goTo}
          step={step}
          title='Upload your tracks'
        />
      ,
      position: mobile ? 'bottom' : 'right',
      selector: '#nav-release',
      style: {
        width: 300,
        maxWidth: 300,
        backgroundColor: theme.palette.background.paper,
        padding: 0,
      },
    },
  ]

  return (
    <Tour
      accentColor={theme.palette.primary.main}
      closeWithMask
      disableFocusLock
      disableKeyboardNavigation
      isOpen={open}
      onRequestClose={() => closeTour()}
      rounded={16}
      showButtons={false}
      showNavigation={false}
      showNavigationNumber={false}
      showNumber={false}
      steps={steps}
    />
  )
}

export default ViewReleaseTour
