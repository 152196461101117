/* eslint-disable no-restricted-imports */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable id-length */
import { MenuItem, Select, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import type { DateRange } from 'mui-daterange-picker'
import { useEffect, useRef, useState } from 'react'
import Chart from 'react-apexcharts'

import { useContainerDimensions } from 'src/components/ContainerDimensions'
import TimeSelectAnalytics from 'src/components/pages/Links/ViewLink/ViewLinkComponents/TimePeriodSelect/TimeSelect'
import { GetColorLink } from 'src/components/pages/SmartLink/Content/GetColorLink'
import type { HistoryEntity } from 'src/models/Analytics'

type Props = {
  data: HistoryEntity[]
  source: string
}

const DistributionFullChart = (props: Props) => {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const componentRef = useRef<HTMLDivElement>(null)
  const { width } = useContainerDimensions(componentRef)

  const types = [
    'monthly_listeners_current',
    'streams_total',
    'streams_current',
    'popularity_current',
    'followers_total',
    'video_views_total',
    'video_likes_total',
    'video_comments_total',
    'views_total',
    'likes_total',
    'comments_total',
    'videos_total',
    'shares_total',
    'shazams_total',
    'favorites_total',
    'playlists_current',
    'playlists_reach_current',
    'playlist_reach_current',
    'charts_current',
    'charted_tracks_current',
    'track_charts_current',
    'reposts_total',
  ] as (keyof HistoryEntity)[]

  const seriesTest =
    types.map(key => ({
      name: key.split('_').map(word => word[0].toLocaleUpperCase() + word.slice(1)).join(' ')
        .replaceAll('Current', '')
        .replaceAll('Total', ''),
      data: props.data.filter(object => object[key] !== null && object[key] !== undefined)
        .map(item => ({
          x: item.date,
          y: item[key] ?? 0,
        })),
    })).filter(item => item.data && item.data?.length > 0)

  const [activeOption, setActiveOption] = useState('')
  const currentDate = new Date()
  const lastTwentyEightDays = new Date()
  lastTwentyEightDays.setDate(currentDate.getDate() - 28)

  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: lastTwentyEightDays,
    endDate: currentDate,
  })

  const activeSeries = seriesTest.find(item => item.name === activeOption)

  useEffect(() => {
    if (seriesTest && seriesTest.length > 0) {
      setActiveOption(seriesTest[0].name)
    }
  }, [props.source])

  const getDateBoolean = (item: { x: string, y: number | string }) => (new Date(item.x).getTime() <= (dateRange.endDate
    ? new Date(dateRange.endDate).getTime()
    : Date.now())) &&
  (new Date(item.x).getTime() >= new Date(dateRange.startDate ?? 0).getTime())

  const optionsSectionsOverdue: ApexCharts.ApexOptions = {
    chart: {
      animations: {
        enabled: false,
      },
      zoom: {
        enabled: false,
      },
      foreColor: theme.palette.text.primary,
      width: '100%',
      toolbar: {
        show: false,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      width: 2,
      curve: 'smooth',
    },
    colors: [
      GetColorLink(props.source),
    ],
    tooltip: {
      theme: 'dark',
      x: {
        format: 'MMMM dd, yyyy',
      },
    },
    grid: {
      borderColor: theme.palette.divider,
    },
    fill: {
      type: 'gradient',
    },
    dataLabels: {
      enabled: false,
    },
    labels: activeSeries?.data.filter(item => getDateBoolean(item)).map(item => item.x),
    xaxis: {
      type: 'datetime',
      tooltip: {
        enabled: false,
      },
      axisBorder: {
        color: theme.palette.divider,
      },
      axisTicks: {
        color: theme.palette.divider,
      },
    },
    yaxis: {
      labels: {
        formatter: value => value?.toLocaleString(),
      },
      axisBorder: {
        color: theme.palette.divider,
      },
      axisTicks: {
        color: theme.palette.divider,
      },
    },
    legend: {
      showForZeroSeries: false,
    },
  }

  return (
    <Stack
      alignItems='center'
      direction='row'
      flexDirection='row'
      flexWrap='wrap'
      ref={componentRef}
      width={1}
    >
      {seriesTest && seriesTest.length > 0 &&
      <Stack
        sx={{
          width: 1,
          paddingBottom: 2,
        }}
      >
        <Stack
          width={1}
        >
          <Stack
            alignItems={mobile ? 'flex-start' : 'center'}
            direction={mobile ? 'column' : 'row'}
            justifyContent='space-between'
            width={1}
          >
            <Typography
              textAlign='left'
              variant='h3'
            >
              {activeSeries?.name}
            </Typography>
            <Stack
              alignItems={mobile ? 'flex-start' : 'center'}
              direction={mobile ? 'column' : 'row'}
              spacing={1}
              width={mobile ? 1 : 'auto'}
            >
              <Stack sx={{ marginTop: '-16px!important' }}>
                <TimeSelectAnalytics
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                />
              </Stack>
              <Select
                onChange={event => setActiveOption(event.target.value)}
                placeholder='Select item'
                sx={{
                  width: 180,
                  marginTop: 0,
                }}
                value={activeOption}
              >
                {seriesTest.map(item =>
                  <MenuItem
                    id={item.name}
                    key={`${item.name}`}
                    value={item.name}
                  >
                    {item.name}
                  </MenuItem>)}
              </Select>
            </Stack>
          </Stack>
          {activeSeries && dateRange.endDate && dateRange.startDate &&
          <Chart
            height={420}
            options={optionsSectionsOverdue}
            series={[{
              name: activeSeries.name,
              data: activeSeries.data.filter(item => getDateBoolean(item)),
            }]}
            type='area'
            width={width}
          />}
        </Stack>
      </Stack>}
    </Stack>
  )
}
export default DistributionFullChart

