import { Stack } from '@mui/material'

import MarketingCloudChart from 'src/components/pages/Links/ViewLink/ViewLinkComponents/LocationsComponents/MarketingCloudChart'
import ViewLocationsMap from 'src/components/pages/Links/ViewLink/ViewLinkComponents/LocationsComponents/ViewLocationsMap'
import type { MarketingAnalyticsString } from 'src/models/Marketing'

type Props = {
  countryClicks: MarketingAnalyticsString[]
  countryViews: MarketingAnalyticsString[]
  cityClicks: MarketingAnalyticsString[]
  cityViews: MarketingAnalyticsString[]
  loading: boolean
}

const ViewLinkLocations = (props: Props) =>
  <Stack width={1}>
    <ViewLocationsMap
      countryClicks={props.countryClicks}
      loading={props.loading}
    />
    <MarketingCloudChart
      clicks={props.countryClicks}
      country
      loading={props.loading}
      views={props.countryViews}
    />
    <MarketingCloudChart
      city
      clicks={props.cityClicks}
      loading={props.loading}
      views={props.cityViews}
    />
  </Stack>

export default ViewLinkLocations
