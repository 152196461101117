/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable react/no-unstable-nested-components */
import { Card, CardActionArea, Paper, Skeleton, Stack, Tooltip, Typography, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import Linkify from 'react-linkify'

import { getAccount } from 'src/api/accounts'
import AccountAvatar from 'src/components/AccountAvatar'
import GetLink from 'src/components/pages/Messages/MessagesPanel/MessagesPanelComponents/GetLink'
import ImageView from 'src/components/pages/Messages/MessagesPanel/MessagesPanelComponents/ImageView'
import type Account from 'src/models/Account'
import type { ProjectUpdate } from 'src/models/Project'

type Props = {
  taskUpdate: ProjectUpdate
  myAccount: string
  members: Account[]
}

const ProjectMessageItem = (props: Props) => {
  const [updateFrom, setUpdateFrom] = useState<Account | null>()
  const [imageOpen, setImageOpen] = useState(false)
  const theme = useTheme()

  useEffect(() => {
    if (!props.taskUpdate.account) return
    const findItem = props.members.find(item => item.id === props.taskUpdate.account)
    if (findItem) {
      setUpdateFrom(findItem)
    } else if (props.members.length > 0) {
      void getAccount(props.taskUpdate.account).then(setUpdateFrom)
    }
  }, [props.taskUpdate.account, props.members])

  return (
    <Stack
      direction='row'
      justifyContent={props.taskUpdate.account === props.myAccount ? 'flex-end' : 'flex-start'}
      key={props.taskUpdate.timestamp.toString()}
      spacing={1}
      width={1}
    >
      {props.taskUpdate.account === props.myAccount
        ? <Stack
          flexDirection='row'
          justifyContent='flex-end'
          spacing={1}
        >
          <Stack>
            <Tooltip title={dayjs(props.taskUpdate.timestamp).format('llll')}>
              {(/(jpg|gif|png|JPG|GIF|PNG|JPEG|jpeg)$/).test(props.taskUpdate.message)
                ? <Card elevation={0}>
                  <CardActionArea onClick={() => setImageOpen(true)}>
                    <img
                      alt='Message'
                      src={props.taskUpdate.message}
                      style={{ borderRadius: 8, boxShadow: 'none' }}
                      width={312}
                    />
                  </CardActionArea>
                  <ImageView close={() => setImageOpen(false)} image={props.taskUpdate.message} open={imageOpen} />
                </Card>
                : (/(https|http)/).test(props.taskUpdate.message)
                  ? <Stack>
                    <Paper
                      elevation={0}
                      sx={{
                        maxWidth: 336,
                        padding: 1.5,
                        backgroundColor: theme.palette.primary.main,
                        color: `${theme.palette.primary.contrastText}!important`,
                      }}
                    >
                      <Linkify componentDecorator={(decoratedHref, decoratedText, key) =>
                        <a href={decoratedHref} key={key} rel='noreferrer' style={{ color: 'inherit' }} target='blank'>
                          {decoratedText}
                        </a>}
                      >
                        <Typography sx={{ wordWrap: 'break-word' }}>
                          {props.taskUpdate.message}
                        </Typography>
                      </Linkify>
                    </Paper>
                    {GetLink(props.taskUpdate.message)}
                  </Stack>
                  : <Paper
                    elevation={0}
                    sx={{
                      maxWidth: 336,
                      padding: 1.5,
                      backgroundColor: theme.palette.primary.main,
                      color: `${theme.palette.primary.contrastText}!important`,
                    }}
                  >
                    <Typography sx={{ wordWrap: 'break-word' }}>
                      {props.taskUpdate.message}
                    </Typography>
                    {' '}
                  </Paper>}
            </Tooltip>
          </Stack>
        </Stack>
        : <Stack
          direction='row'
          key={props.taskUpdate.id ?? props.taskUpdate.timestamp.toString()}
          spacing={1}
        >
          {updateFrom
            ? <Tooltip title={`${updateFrom.firstName} ${updateFrom.lastName}`}>
              <Stack>
                <AccountAvatar account={updateFrom} nolink size={42} />
              </Stack>
            </Tooltip>
            : <Stack>
              <Skeleton height={42} variant='circular' width={42} />
            </Stack>}
          <Tooltip title={dayjs(props.taskUpdate.timestamp).format('llll')}>
            {(/(jpg|gif|png|JPG|GIF|PNG|JPEG|jpeg)$/).test(props.taskUpdate.message)
              ? <Card elevation={0}>
                <CardActionArea onClick={() => setImageOpen(true)}>
                  <img
                    alt='Message'
                    src={props.taskUpdate.message}
                    style={{ borderRadius: 8, boxShadow: 'none' }}
                    width={312}
                  />
                </CardActionArea>
                <ImageView close={() => setImageOpen(false)} image={props.taskUpdate.message} open={imageOpen} />
              </Card>
              : (/(https|http)/).test(props.taskUpdate.message)
                ? <Stack>
                  <Paper
                    elevation={0}
                    sx={{
                      maxWidth: 336,
                      padding: 1.5,
                      backgroundColor: theme.palette.background.input,
                    }}
                  >
                    <Linkify componentDecorator={(decoratedHref, decoratedText, key) =>
                      <a href={decoratedHref} key={key} rel='noreferrer' style={{ color: 'inherit' }} target='blank'>
                        {decoratedText}
                      </a>}
                    >
                      <Typography sx={{ wordWrap: 'break-word' }}>
                        {props.taskUpdate.message}
                      </Typography>
                    </Linkify>
                  </Paper>
                  {GetLink(props.taskUpdate.message)}
                </Stack>
                : <Paper
                  elevation={0}
                  sx={{
                    maxWidth: 336,
                    padding: 1.5,
                    backgroundColor: theme.palette.background.input,
                  }}
                >
                  <Typography sx={{ wordWrap: 'break-word' }}>
                    {props.taskUpdate.message}
                  </Typography>
                  {' '}
                </Paper>}
          </Tooltip>
        </Stack>}
    </Stack>
  )
}

export default ProjectMessageItem
