/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable unicorn/no-unsafe-regex */
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { Button, IconButton, ListItemText, Menu, MenuItem, Stack } from '@mui/material'
import { useState } from 'react'
import type { DropResult } from 'react-beautiful-dnd'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { v4 } from 'uuid'

import { sendImage } from 'src/api/conversations'
import { getEmbedCode } from 'src/api/links'
import type { IconType } from 'src/components/form-elements/IconPicker/iconType'
import BioLinkListEditItem from 'src/components/pages/Links/EditLink/EditLinkComponents/BioLinkBlockItem'
import { getLinkDescription, getLinkTitle } from 'src/components/pages/Links/EditLink/EditLinkComponents/bioLinkFunctions'
import { GetColorLink } from 'src/components/pages/SmartLink/Content/GetColorLink'
import GetIcon from 'src/components/pages/SmartLink/Content/GetIcon'
import { BioLinkInput } from 'src/data/smartLinkData'
import type { BioLinkListItem, MarketingLink } from 'src/models/Marketing'

type Props = {
  smartLinkData: MarketingLink
  setLinkData: React.Dispatch<React.SetStateAction<MarketingLink>>
}

const reorder = (list: BioLinkListItem[], startIndex: number, endIndex: number) => {
  const result = [...list]
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const EditBioLinkMarketingServices = (props: Props) => {
  const filteredInputs = BioLinkInput

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const openMenu = Boolean(anchorElement)

  const handleClose = () => {
    setAnchorElement(null)
  }

  const findInputIndex = (labelFound: string) =>
    props.smartLinkData.bioLinkList.findIndex(element => element.id === labelFound)

  const onDeleteByIndex = (event: React.MouseEvent<HTMLElement>) => {
    const index = findInputIndex(event.currentTarget.id)
    props.setLinkData(old =>
      ({ ...old, bioLinkList: [...old.bioLinkList.filter((item, indexItem) => indexItem !== index)] }))
  }

  const handleChangeLinkDestination = (value: string, id: string) => {
    props.setLinkData(old =>
      ({
        ...old,
        bioLinkList: old.bioLinkList.map(item => item.id === id.replace('destination-', '')
          ? { ...item, linkDestinationUrl: value }
          : item),
      }))
  }

  const handleChangePrimaryText = (value: string, id: string) => {
    props.setLinkData(old =>
      ({
        ...old,
        bioLinkList: old.bioLinkList.map(item => item.id === id.replace('primary-', '')
          ? { ...item, linkPrimaryText: value }
          : item),
      }))
  }

  const handleChangesecondaryText = (value: string, id: string) => {
    props.setLinkData(old =>
      ({
        ...old,
        bioLinkList: old.bioLinkList.map(item => item.id === id.replace('primary-', '')
          ? { ...item, linkSecondaryText: value }
          : item),
      }))
  }

  const handleChangeVideoEmbedUrl = async (value: string, id: string) => {
    props.setLinkData(old =>
      ({
        ...old,
        bioLinkList: old.bioLinkList.map(item => item.id === id.replace('embed-', '')
          ? { ...item, videoEmbedUrl: value }
          : item),
      }))

    try {
      new URL(value)

      if (value.startsWith('https://')) {
        const htmlData = await getEmbedCode(value)

        if (htmlData && htmlData.length > 0) {
          props.setLinkData(old =>
            ({
              ...old,
              bioLinkList: old.bioLinkList.map(item => item.id === id.replace('embed-', '')
                ? { ...item, embedHtml: htmlData }
                : item),
            }))
        }
      }
    } catch {
      return
    }
  }

  const handleChangeBandsintown = async (value: string, id: string) => {
    props.setLinkData(old =>
      ({
        ...old,
        bioLinkList: old.bioLinkList.map(item => item.id === id.replace('embed-', '')
          ? { ...item, videoEmbedUrl: value }
          : item),
      }))
  }

  const handleChangeVideoEmbedSmall = (value: string, id: string) => {
    props.setLinkData(old =>
      ({
        ...old,
        bioLinkList: old.bioLinkList.map(item => item.id === id.replace('embed-', '')
          ? { ...item, videoEmbedUrl: value }
          : item),
      }))
  }

  const handleChangeImageUrl = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const index = findInputIndex(event.target.id.replace('image-', '').replace('imageTest-', ''))
    const file = event.target.files?.[0]
    if (!file) return

    await sendImage(file)
      .then(imageLink => props.setLinkData(old =>
        ({
          ...old,
          bioLinkList: old.bioLinkList.map((item, indexItem) => indexItem === index
            ? { ...item, imageUrl: imageLink }
            : item),
        })))
  }

  const handleChangeTextContent = (value: string, id: string) => {
    props.setLinkData(old =>
      ({
        ...old,
        bioLinkList: old.bioLinkList.map(item => item.id === id.replace('textContent-', '')
          ? { ...item, textContent: value }
          : item),
      }))
  }

  const handleChangeIcon = (value: IconType, id: string) => {
    props.setLinkData(old =>
      ({
        ...old,
        bioLinkList: old.bioLinkList.map(item => item.id === id
          ? { ...item, icon: value }
          : item),
      }))
  }

  const handleChangeActiveHeader = (event: React.ChangeEvent<HTMLInputElement>) => {
    const index = findInputIndex(event.target.id.replace('active-', ''))
    props.setLinkData(old =>
      ({
        ...old,
        bioLinkList: old.bioLinkList.map((item, indexItem) => indexItem === index
          ? { ...item, active: event.target.checked }
          : item),
      }))
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    } else if (result.destination.droppableId) {
      props.setLinkData(old =>
        ({
          ...old,
          bioLinkList: reorder(props.smartLinkData.bioLinkList, result.source.index, result.destination?.index ?? 0) }
        ))
    }
  }

  return (
    <Stack direction='column' spacing={2}>
      <Stack alignItems='center' direction='row' justifyContent='space-between' spacing={2}>
        <Button
          fullWidth
          onClick={event => setAnchorElement(event.currentTarget)}
          rounded
          startIcon={<AddRoundedIcon />}
          sx={{
            height: 48,
            fontSize: '1rem',
          }}
        >
          Add Content Block
        </Button>
      </Stack>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='presaves' key='presaves'>
          {provided =>
            <Stack {...provided.droppableProps} direction='column' ref={provided.innerRef} spacing={1}>
              {props.smartLinkData.bioLinkList.map((element, index) =>
                <BioLinkListEditItem
                  element={element}
                  handleChangeActiveHeader={handleChangeActiveHeader}
                  handleChangeBandsintown={handleChangeBandsintown}
                  handleChangeIcon={handleChangeIcon}
                  handleChangeImageUrl={handleChangeImageUrl}
                  handleChangeLinkDestination={handleChangeLinkDestination}
                  handleChangePrimaryText={handleChangePrimaryText}
                  handleChangeSecondaryText={handleChangesecondaryText}
                  handleChangeTextContent={handleChangeTextContent}
                  handleChangeVideoEmbedSmall={handleChangeVideoEmbedSmall}
                  handleChangeVideoEmbedUrl={handleChangeVideoEmbedUrl}
                  index={index}
                  key={element.id}
                  onDeleteByIndex={onDeleteByIndex}
                  smartLinkData={props.smartLinkData}
                />)}
              {provided.placeholder}
            </Stack>}
        </Droppable>
      </DragDropContext>

      <Menu
        anchorEl={anchorElement}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={handleClose}
        open={openMenu}
        sx={{
          '& .MuiPaper-root': {
            maxHeight: '240px',
          },
        }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        {filteredInputs.map(option =>
          <MenuItem
            disabled={props.smartLinkData.bioLinkList.some(item =>
              (item.blockType === 'next_release' && option.blockType === 'next_release') ||
              (item.blockType === 'latest_release' && option.blockType === 'latest_release') ||
              (item.blockType === 'seated' && option.blockType === 'seated') ||
              (item.blockType === 'bandsintown' && option.blockType === 'bandsintown'))}
            key={option.blockType}
            onClick={() => {
              props.setLinkData(old => ({
                ...old,
                bioLinkList: [
                  ...old.bioLinkList,
                  {
                    ...option,
                    id: v4(),
                  },
                ],
              }))
              handleClose()
            }}
          >
            <Stack alignItems='center' direction='row' spacing={1} width={1}>
              <IconButton
                disableRipple
                size='medium'
                sx={{
                  backgroundColor: GetColorLink(option.blockType),
                  color: theme => theme.palette.primary.contrastText,
                  ':hover': {
                    backgroundColor: GetColorLink(option.blockType),
                    cursor: 'default',
                  },
                }}
              >
                {GetIcon(option.blockType)}
              </IconButton>
              <ListItemText
                primary={getLinkTitle(option.blockType)}
                secondary={getLinkDescription(option.blockType)}
                sx={{
                  '& .MuiListItemText-secondary': {
                    color: theme => theme.palette.text.secondary,
                  },
                  width: 1,
                }}
              />
            </Stack>
          </MenuItem>)}
      </Menu>
    </Stack>
  )
}

export default EditBioLinkMarketingServices
