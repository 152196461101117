import type { Serialized } from 'src/types/react-app-env'

export type Subscription = {
  status: string
  latest_invoice: {
    payment_intent: {
      client_secret: string
      status: string
    } | null
  }
  pending_setup_intent: {
    client_secret: string
    status: 'canceled' |
    'processing' |
    'requires_action' |
    'requires_confirmation' |
    'requires_payment_method' |
    'succeeded'
  } | null
}

export class UpcomingInvoice {
  due_today: number
  subtotal: number
  total: number
  total_tax_amounts?: {
    amount: number | undefined
    tax_rate: string | undefined
  }[] | null
  discounts?: {
    percent: number | undefined
    discount_name: string | undefined
  }[] | null

  constructor(dto: UpcomingInvoiceDto) {
    this.subtotal = dto.subtotal ?? 0
    this.due_today = dto.due_today ?? 0
    this.total = dto.total ?? 0
    this.total_tax_amounts = dto.total_tax_amounts?.map(item => item) ?? []
    this.discounts = dto.discounts?.map(item => item) ?? []
  }
}

export type UpcomingInvoiceDto = Partial<Serialized<UpcomingInvoice>>

export class ActivePlanBenefits {
  subscribers: number
  releases: number
  files: number
  playlists: number

  constructor(dto: ActivePlanBenefitsDto) {
    this.subscribers = dto.subscribers ?? 0
    this.releases = dto.releases ?? 0
    this.files = dto.files ?? 0
    this.playlists = dto.playlists ?? 0
  }
}

export type ActivePlanBenefitsDto = Partial<Serialized<ActivePlanBenefits>>
