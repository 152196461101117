/* eslint-disable no-duplicate-imports */
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { Avatar, Badge, Card, Collapse, Divider, Grid, IconButton, ListItemText, Stack, Tooltip, Typography, useTheme } from '@mui/material'
import { useState } from 'react'

import BalanceChart from 'src/components/pages/Earnings/EarningsTabs/BalanceChart'
import RecordinEarningsChart from 'src/components/pages/Earnings/EarningsTabs/RecordingEarningsChart'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { Organisation } from 'src/models/Organisation'
import type Recording from 'src/models/Recording'
import type { RightsHolder } from 'src/models/Recording'

type Props = {
  holder: Organisation
  recordingSelected: Recording | undefined
  payoutsProject: {
    asset: undefined
    title: string
    description: string
    amount: number
    dateAdded: Date
    transactionType: string
    organisationAdvanceId: string
  }[]
  recordings: Recording[]
  allMasterRightsHolders: RightsHolder[]
  incomeProject: number
  expensesProject: number
  totalOwnershipProject: number
  balanceProject: number
}

const ViewEarningsPerson = (props: Props) => {
  const themeItem = useTheme()
  const { currentOrganisation } = useAuth()
  const [expanded, setExpanded] = useState(false)
  const payoutItemsSpecificProject = props.payoutsProject
    .filter(item => item.organisationAdvanceId === props.holder.id)
    .reduce((a, b) => a + b.amount, 0)

  const advancesSpecificProject = props.payoutsProject
    .filter(item => item.organisationAdvanceId === props.holder.id && item.transactionType === 'Advance')
    .reduce((a, b) => a + b.amount, 0)

  const myOwnershipProject = props.recordingSelected
    ? props.recordingSelected.masterRightsHolders.find(item =>
      item.account.id === props.holder.id)?.ownership ?? 0
    : props.allMasterRightsHolders
      .filter(rightsholder => rightsholder.account.id === props.holder.id)
      .flatMap(({ ownership }) => ownership)
      .reduce((partialSum, a) => partialSum + a, 0) / props.totalOwnershipProject * 100

  const myProjectIncome = (props.incomeProject / 100) * myOwnershipProject
  const myProjectExpenses = (props.expensesProject / 100) * myOwnershipProject

  const recordingToProcess = props.recordingSelected
    ? [props.recordingSelected]
    : props.recordings.filter(item =>
      item.masterRightsHolders.some(holder => holder.account.id === currentOrganisation?.id))

  const totalIncomeProject = myProjectIncome
  const totalExpensesProject = myProjectExpenses
  const totalPayoutProject = payoutItemsSpecificProject
  const myShareGrossProject = (props.balanceProject / 100) * myOwnershipProject
  const netProject = myShareGrossProject - payoutItemsSpecificProject

  return (
    <Grid item lg={6} md={12} sm={12} xl={6} xs={12}>
      <Stack direction='column' spacing={1} width={1}>
        <Card sx={{ padding: 2, width: 1 }} variant='outlined'>
          <Stack direction='column' spacing={1} width={1}>
            <Stack alignItems='center' direction='row' spacing={1}>
              <Badge
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                badgeContent={<CheckCircleRoundedIcon
                  color='secondary'
                  sx={{
                    backgroundColor: 'white',
                    borderRadius: '50%',
                    width: 12,
                    height: 12,
                    fontSize: 8,
                  }}
                />}
                overlap='circular'
                sx={{
                  '.MuiBadge-badge': {
                    display: props.holder.verified ? undefined : 'none',
                  },
                }}
              >
                <Avatar
                  src={props.holder.logoUrl}
                  sx={{ width: 48, height: 48 }}
                />
              </Badge>
              <ListItemText
                primary={props.holder.name}
                secondary={<Typography
                  sx={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                  variant='body2'
                >
                  {props.holder.organisationEmail}
                </Typography>}
                sx={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
              />
            </Stack>

            <Divider />

            <Grid container>
              <Grid item lg={6} md={12} sm={12} xl={6} xs={12}>
                <Stack height={1} width={1}>
                  {netProject < 0 || advancesSpecificProject > 0 || totalExpensesProject > 0
                    ? <BalanceChart
                      amount={Math.abs(netProject)}
                      maximum={totalExpensesProject + totalPayoutProject}
                      title='Project recoups'
                    />
                    : <BalanceChart
                      amount={0}
                      maximum={100}
                      title='Project recoups'
                    />}

                  <Stack direction='column' width={1}>
                    <Typography color={themeItem.palette.text.label} variant='body2'>
                      Amount to recoup on project
                    </Typography>
                    <Typography variant='body2'>
                      {new Intl.NumberFormat('en-US',
                        { style: 'currency', currency: 'USD' }).format(Math.abs(netProject))}
                    </Typography>
                  </Stack>

                  <Collapse in={expanded}>
                    <Stack direction='row' paddingY={1} spacing={1} width={1}>
                      <Stack direction='column' spacing={1} width={1}>
                        <Stack direction='column' width={1}>
                          <Typography color={themeItem.palette.text.label} variant='body2'>
                            Total project income share
                          </Typography>
                          <Typography variant='body2'>
                            {new Intl.NumberFormat('en-US',
                              { style: 'currency', currency: 'USD' }).format(totalIncomeProject)}
                          </Typography>
                        </Stack>
                        <Stack direction='column' width={1}>
                          <Typography color={themeItem.palette.text.label} variant='body2'>
                            Total project expenses share
                          </Typography>
                          <Typography variant='body2'>
                            {new Intl.NumberFormat('en-US',
                              { style: 'currency', currency: 'USD' }).format(totalExpensesProject)}
                          </Typography>
                        </Stack>
                        <Stack direction='column' width={1}>
                          <Typography color={themeItem.palette.text.label} variant='body2'>
                            Total project payouts
                          </Typography>
                          <Typography variant='body2'>
                            {new Intl.NumberFormat('en-US',
                              { style: 'currency', currency: 'USD' }).format(totalPayoutProject)}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Collapse>
                  <Tooltip title={expanded ? 'Hide details' : 'View details'}>
                    <IconButton
                      color='inherit'
                      onClick={() => setExpanded(old => !old)}
                    >
                      {expanded
                        ? <ExpandLessRoundedIcon />
                        : <ExpandMoreRoundedIcon />}
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Grid>
              {recordingToProcess.map(recordingItem =>
                <Grid item key={`${recordingItem.id}${props.holder.id}`} lg={6} md={12} sm={12} xl={6} xs={12}>
                  <RecordinEarningsChart
                    holder={props.holder}
                    recordingSelected={recordingItem}
                  />
                </Grid>)}
            </Grid>

          </Stack>
        </Card>
      </Stack>
    </Grid>
  )
}

export default ViewEarningsPerson
