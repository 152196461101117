import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'

import { deleteMarketingLink } from 'src/api/links'
import DeleteItemComponent from 'src/components/DeleteItemComponent'
import type { MarketingLink } from 'src/models/Marketing'

type Props = {
  close: () => void
  open: boolean
  link: MarketingLink
  updateLinks: () => Promise<void>
}

const DeleteLinkModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  const [loading, setLoading] = useState(false)
  const deleteLink = async ()  => {
    setLoading(true)
    await deleteMarketingLink(props.link)
      .then(props.updateLinks)
      .then(props.close)
      .finally(() => setLoading(false))
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='xs'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Stack paddingX={3} paddingY={3} spacing={4} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          width='100%'
        >
          <Typography variant='h3'>
            Delete Smart Link
          </Typography>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>
        <DeleteItemComponent
          primary='Are you sure?'
          secondary='This action cannot be undone'
        />
        <Stack direction='row' justifyContent='flex-end' spacing={1} width='100%'>
          <Button onClick={() => props.close()} variant='text'>Cancel</Button>
          <LoadingButton
            color='error'
            loading={loading}
            onClick={deleteLink}
            variant='contained'
          >
            Delete Smart Link
          </LoadingButton>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default DeleteLinkModal
