import { Divider, Paper, Stack, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'
import { Route, Routes } from 'react-router'

import FilesMenu from 'src/components/pages/Files/Components/FilesMenu'
import ViewFolder from 'src/components/pages/Files/Folder/ViewFolder'
import FolderHome from 'src/components/pages/Files/Home/FolderHome'
import { DropzoneProvider } from 'src/components/pages/Projects/FullscreenDropzone'
import SPACING from 'src/styles/spacing'

const Files = () => {
  const themeItem = useTheme()
  const matches = useMediaQuery(themeItem.breakpoints.down('lg'))

  const [viewType, setViewType] = useState('list')
  const [searchQuery, setSearchQuery] = useState('')
  const [images, setImages] = useState(false)
  const [files, setFiles] = useState(false)
  const [music, setMusic] = useState(false)
  const [documents, setDocuments] = useState(false)

  return (
    <Stack
      sx={{
        paddingBottom: SPACING.RESPONSIVE_CONTOUR,
        paddingRight: SPACING.RESPONSIVE_CONTOUR,
        height: 1,
        width: 1,
      }}
    >
      <Paper
        elevation={0}
        sx={{
          width: 1,
          height: 1,
          overflow: 'hidden',
          borderRadius: {
            xl: 1,
            lg: 1,
            md: 1,
            sm: 0,
            xs: 0,
          },
        }}
      >
        <Stack direction='row' height={1} width={1}>
          <FilesMenu
            documents={documents}
            files={files}
            images={images}
            music={music}
            searchQuery={searchQuery}
            setDocuments={setDocuments}
            setFiles={setFiles}
            setImages={setImages}
            setMusic={setMusic}
            setSearchQuery={setSearchQuery}
          />
          <Divider
            orientation='vertical'
            sx={{
              height: 1,
              display: matches ? 'none' : 'block',
            }}
          />
          <DropzoneProvider>
            <Routes>
              <Route
                element={<FolderHome
                  display={viewType}
                  documents={documents}
                  files={files}
                  images={images}
                  music={music}
                  searchQuery={searchQuery}
                  setDisplay={setViewType}
                />}
                path=''
              />
              <Route
                element={<ViewFolder
                  display={viewType}
                  documents={documents}
                  files={files}
                  images={images}
                  music={music}
                  searchQuery={searchQuery}
                  setDisplay={setViewType}
                />}
                path='/:id'
              />
            </Routes>
          </DropzoneProvider>
        </Stack>
      </Paper>
    </Stack>
  )
}

export default Files
