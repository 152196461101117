/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable id-length */
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import { Card, Divider, IconButton, Popper, Stack, Tooltip } from '@mui/material'
import { DeleteColumn, DeleteRow, InsertColumnLeft, InsertRowBottom }  from '@styled-icons/remix-editor'
import {
  deleteColumn,
  deleteRow,
  deleteTable,
  getBlockAbove,
  getNodesRange,
  getRangeBoundingClientRect,
  insertTableColumn,
  insertTableRow,
  useEditorState,
} from '@udecode/plate'
import { useEffect, useRef, useState } from 'react'

type VirtualElement = {
  getBoundingClientRect: () => DOMRect
  clientWidth: number
  clientHeight: number
}

export const TableToolbarFloating = () => {
  const ref = useRef<HTMLDivElement | null>(null)
  const editor = useEditorState()
  const [anchorElement, setAnchorElement] = useState<VirtualElement | null>(null)

  const [open, setOpen] = useState(false)
  const selection = editor.selection
  const domSelection = window.getSelection()

  useEffect(() => {
    const tableEntry = getBlockAbove(editor, {
      match: {
        type: 'table',
      },
    })

    if (tableEntry) {
      setOpen(true)
      const rangeItem = getNodesRange(editor, [tableEntry])
      if (rangeItem) {
        const rectangle = getRangeBoundingClientRect(editor, rangeItem)

        setOpen(true)
        const getRect = () => ({
          width: rectangle.width,
          height: rectangle.height,
          x: rectangle.x,
          y: rectangle.y,
          bottom: rectangle.bottom,
          left:rectangle.left,
          top:rectangle.top,
          right: rectangle.right,
        }) as DOMRect

        if (rectangle.x !== 0 && rectangle.y !== 0) {
          setAnchorElement({
            clientWidth: rectangle.width,
            clientHeight: rectangle.height,
            getBoundingClientRect: getRect,
          })
        }
      } else {
        setOpen(false)
      }
    } else {
      setOpen(false)
    }
  }, [editor, selection, domSelection])

  return (
    <Popper
      anchorEl={anchorElement}
      id='hover-toolbar'
      onClick={event => {
        event.preventDefault()
      }}
      onMouseDown={event => {
        event.preventDefault()
      }}
      open={open}
      placement='top'
      ref={ref}
      sx={{
        zIndex: 999,
      }}
    >
      <Card
        elevation={4}
        sx={{
          height: '58px',
          marginBottom: 2,
        }}
      >
        <Stack
          alignItems='center'
          direction='row'
          height={1}
          padding={1}
          spacing={1}
        >
          <Tooltip placement='top' title='Add row'>
            <IconButton
              color='success'
              onClick={event => {
                event.stopPropagation()
                event.preventDefault()
                insertTableRow(editor)
              }}
              size='small'
              sx={{
                boxSizing: 'border-box',
                borderRadius: '8px',
              }}
            >
              <InsertRowBottom height={24} width={24} />
            </IconButton>
          </Tooltip>

          <Tooltip placement='top' title='Add column'>
            <IconButton
              color='success'
              onClick={event => {
                event.stopPropagation()
                event.preventDefault()
                insertTableColumn(editor)
              }}
              size='small'
              sx={{
                boxSizing: 'border-box',
                borderRadius: '8px',
              }}
            >
              <InsertColumnLeft height={24} width={24} />
            </IconButton>
          </Tooltip>

          <Divider orientation='vertical' />

          <Tooltip placement='top' title='Remove row'>
            <IconButton
              color='error'
              onClick={event => {
                event.stopPropagation()
                event.preventDefault()
                deleteRow(editor)
              }}
              size='small'
              sx={{
                boxSizing: 'border-box',
                borderRadius: '8px',
              }}
            >
              <DeleteRow height={24} width={24} />
            </IconButton>
          </Tooltip>

          <Tooltip placement='top' title='Remove column'>
            <IconButton
              color='error'
              onClick={event => {
                event.stopPropagation()
                event.preventDefault()
                deleteColumn(editor)
              }}
              size='small'
              sx={{
                boxSizing: 'border-box',
                borderRadius: '8px',
              }}
            >
              <DeleteColumn height={24} width={24} />
            </IconButton>
          </Tooltip>

          <Divider orientation='vertical' />
          <Tooltip placement='top' title='Remove table'>
            <IconButton
              color='default'
              onClick={event => {
                event.stopPropagation()
                event.preventDefault()
                deleteTable(editor)
              }}
              size='small'
              sx={{
                boxSizing: 'border-box',
                borderRadius: '8px',
              }}
            >
              <DeleteRoundedIcon />
            </IconButton>
          </Tooltip>

        </Stack>
      </Card>
    </Popper>
  )
}
