/* eslint-disable unicorn/filename-case */
/* eslint-disable @typescript-eslint/sort-type-constituents */
/* eslint-disable extra-rules/no-commented-out-code */
/* eslint-disable max-len */
import type {
  AutoformatRule,
  CreatePlateEditorOptions,
  Decorate,
  DecorateEntry,
  DOMHandler,
  EDescendant,
  EElement,
  EElementEntry,
  EElementOrText,
  ELEMENT_BLOCKQUOTE,
  ELEMENT_CODE_BLOCK,
  ELEMENT_CODE_LINE,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_HR,
  ELEMENT_IMAGE,
  ELEMENT_LI,
  ELEMENT_LINK,
  ELEMENT_MEDIA_EMBED,
  ELEMENT_MENTION,
  ELEMENT_MENTION_INPUT,
  ELEMENT_OL,
  ELEMENT_PARAGRAPH,
  ELEMENT_TABLE,
  ELEMENT_TD,
  ELEMENT_TODO_LI,
  ELEMENT_TR,
  ELEMENT_UL,
  EMarks,
  ENode,
  ENodeEntry,
  EText,
  ETextEntry,
  InjectComponent,
  InjectProps,
  KeyboardHandler,
  NoInfer,
  OnChange,
  OverrideByKey,
  PlateEditor,
  PlateId,
  PlatePlugin,
  PlatePluginComponent,
  PlatePluginInsertData,
  PlatePluginProps,
  PlateProps,
  PluginOptions,
  SerializeHtml,
  TCommentText,
  TElement,
  TImageElement,
  TLinkElement,
  TMediaEmbedElement,
  TMentionElement,
  TMentionInputElement,
  TNodeEntry,
  TReactEditor,
  TTableElement,
  TText,
  TTodoListItemElement,
  WithOverride } from '@udecode/plate'
import {
  createPlateEditor,
  createPluginFactory,
  createPlugins,
  createTEditor,
  getTEditor,
  useEditorRef,
  useEditorState,
  usePlateActions,
  usePlateEditorRef,
  usePlateEditorState,
  usePlateSelectors,
  usePlateStates,
} from '@udecode/plate'
// import {
//   ELEMENT_EXCALIDRAW,
//   TExcalidrawElement,
// } from '@udecode/plate-ui-excalidraw';
import type { CSSProperties } from 'styled-components'

/**
 * Text
 */

export type EmptyText = {
  text: ''
}

export type PlainText = {
  text: string
}

export type RichText = {
  bold?: boolean
  italic?: boolean
  underline?: boolean
  strikethrough?: boolean
  code?: boolean
  kbd?: boolean
  subscript?: boolean
  backgroundColor?: CSSProperties['backgroundColor']
  fontFamily?: CSSProperties['fontFamily']
  color?: CSSProperties['color']
  fontSize?: CSSProperties['fontSize']
  fontWeight?: CSSProperties['fontWeight']
} & TText & TCommentText

/**
 * Inline Elements
 */

export type MyLinkElement = TLinkElement & {
  type: typeof ELEMENT_LINK
  children: RichText[]
}

export type MyMentionInputElement = TMentionInputElement & {
  type: typeof ELEMENT_MENTION_INPUT
  children: [PlainText]
}

export type MyMentionElement = TMentionElement & {
  type: typeof ELEMENT_MENTION
  children: [EmptyText]
}

export type MyInlineElement =
  | MyLinkElement
  | MyMentionElement
  | MyMentionInputElement
export type MyInlineDescendant = MyInlineElement | RichText
export type MyInlineChildren = MyInlineDescendant[]

/**
 * Block props
 */

export type MyIndentProps = {
  indent?: number
}

export type MyIndentListProps = MyIndentProps & {
  listStart?: number
  listRestart?: number
  listStyleType?: string
}

export type MyLineHeightProps = {
  lineHeight?: CSSProperties['lineHeight']
}

export type MyAlignProps = {
  align?: CSSProperties['textAlign']
}

export type MyBlockElement = {
  id?: PlateId
} & TElement & MyIndentListProps & MyLineHeightProps

/**
 * Blocks
 */

export type MyParagraphElement = MyBlockElement & {
  type: typeof ELEMENT_PARAGRAPH
  children: MyInlineChildren
}

export type MyH1Element = MyBlockElement & {
  type: typeof ELEMENT_H1
  children: MyInlineChildren
}

export type MyH2Element = MyBlockElement & {
  type: typeof ELEMENT_H2
  children: MyInlineChildren
}

export type MyH3Element = MyBlockElement & {
  type: typeof ELEMENT_H3
  children: MyInlineChildren
}

export type MyBlockquoteElement = MyBlockElement & {
  type: typeof ELEMENT_BLOCKQUOTE
  children: MyInlineChildren
}

export type MyCodeBlockElement = MyBlockElement & {
  type: typeof ELEMENT_CODE_BLOCK
  children: MyCodeLineElement[]
}

export type MyCodeLineElement = TElement & {
  type: typeof ELEMENT_CODE_LINE
  children: PlainText[]
}

export type MyTableElement = MyBlockElement & TTableElement & {
  type: typeof ELEMENT_TABLE
  children: MyTableRowElement[]
}

export type MyTableRowElement = TElement & {
  type: typeof ELEMENT_TR
  children: MyTableCellElement[]
}

export type MyTableCellElement = TElement & {
  type: typeof ELEMENT_TD
  children: MyNestableBlock[]
}

export type MyBulletedListElement = MyBlockElement & TElement & {
  type: typeof ELEMENT_UL
  children: MyListItemElement[]
}

export type MyNumberedListElement = MyBlockElement & TElement & {
  type: typeof ELEMENT_OL
  children: MyListItemElement[]
}

export type MyListItemElement = MyBlockElement & TElement & {
  type: typeof ELEMENT_LI
  children: MyInlineChildren
}

export type MyTodoListElement = MyBlockElement & TTodoListItemElement & {
  type: typeof ELEMENT_TODO_LI
  children: MyInlineChildren
}

export type MyImageElement = MyBlockElement & TImageElement & {
  type: typeof ELEMENT_IMAGE
  children: [EmptyText]
}

export type MyMediaEmbedElement = MyBlockElement & TMediaEmbedElement & {
  type: typeof ELEMENT_MEDIA_EMBED
  children: [EmptyText]
}

export type MyHrElement = {
  type: typeof ELEMENT_HR
  children: [EmptyText]
} & MyBlockElement

// export interface MyExcalidrawElement
//   extends TExcalidrawElement,
//     MyBlockElement {
//   type: typeof ELEMENT_EXCALIDRAW;
//   children: [EmptyText];
// }

export type MyNestableBlock = MyParagraphElement

export type MyBlock = Exclude<MyElement, MyInlineElement>
export type MyBlockEntry = TNodeEntry<MyBlock>

export type MyRootBlock =
  MyBlockquoteElement | MyBulletedListElement | MyCodeBlockElement | MyH1Element | MyH2Element | MyH3Element | MyHrElement | MyImageElement | MyMediaEmbedElement | MyNumberedListElement | MyParagraphElement | MyTableElement | MyTodoListElement
// | MyExcalidrawElement;

export type MyValue = MyRootBlock[]

/**
 * Editor types
 */

export type MyEditor = PlateEditor<MyValue> & { isDragging?: boolean }
export type MyReactEditor = TReactEditor<MyValue>
export type MyNode = ENode<MyValue>
export type MyNodeEntry = ENodeEntry<MyValue>
export type MyElement = EElement<MyValue>
export type MyElementEntry = EElementEntry<MyValue>
export type MyText = EText<MyValue>
export type MyTextEntry = ETextEntry<MyValue>
export type MyElementOrText = EElementOrText<MyValue>
export type MyDescendant = EDescendant<MyValue>
export type MyMarks = EMarks<MyValue>
export type MyMark = keyof MyMarks

/**
 * Plate types
 */

export type MyDecorate<P = PluginOptions> = Decorate<P, MyValue, MyEditor>
export type MyDecorateEntry = DecorateEntry<MyValue>
export type MyDOMHandler<P = PluginOptions> = DOMHandler<P, MyValue, MyEditor>
export type MyInjectComponent = InjectComponent<MyValue>
export type MyInjectProps = InjectProps<MyValue>
export type MyKeyboardHandler<P = PluginOptions> = KeyboardHandler<
  P,
  MyValue,
  MyEditor
>
export type MyOnChange<P = PluginOptions> = OnChange<P, MyValue, MyEditor>
export type MyOverrideByKey = OverrideByKey<MyValue, MyEditor>
export type MyPlatePlugin<P = PluginOptions> = PlatePlugin<
  P,
  MyValue,
  MyEditor
>
export type MyPlatePluginInsertData = PlatePluginInsertData<MyValue>
export type MyPlatePluginProps = PlatePluginProps<MyValue>
export type MyPlateProps = PlateProps<MyValue, MyEditor>
export type MySerializeHtml = SerializeHtml<MyValue>
export type MyWithOverride<P = PluginOptions> = WithOverride<
  P,
  MyValue,
  MyEditor
>

/**
 * Plate store, Slate context
 */

export const getMyEditor = (editor: MyEditor) =>
  getTEditor<MyValue, MyEditor>(editor)
export const useMyEditorRef = () => useEditorRef<MyValue, MyEditor>()
export const useMyEditorState = () => useEditorState<MyValue, MyEditor>()
export const useMyPlateEditorRef = (id?: PlateId) =>
  usePlateEditorRef<MyValue, MyEditor>(id)
export const useMyPlateEditorState = (id?: PlateId) =>
  usePlateEditorState<MyValue, MyEditor>(id)
export const useMyPlateSelectors = (id?: PlateId) =>
  usePlateSelectors<MyValue, MyEditor>(id)
export const useMyPlateActions = (id?: PlateId) =>
  usePlateActions<MyValue, MyEditor>(id)
export const useMyPlateStates = (id?: PlateId) =>
  usePlateStates<MyValue, MyEditor>(id)

/**
 * Utils
 */
export const createMyEditor = () => createTEditor() as MyEditor
export const createMyPlateEditor = (
  options: CreatePlateEditorOptions<MyValue, MyEditor> = {}
) => createPlateEditor<MyValue, MyEditor>(options)
export const createMyPluginFactory = <P = PluginOptions>(
  defaultPlugin: PlatePlugin<NoInfer<P>, MyValue, MyEditor>
) => createPluginFactory(defaultPlugin)
export const createMyPlugins = (
  plugins: MyPlatePlugin[],
  options?: {
    components?: Record<string, PlatePluginComponent>
    overrideByKey?: MyOverrideByKey
  }
) => createPlugins<MyValue, MyEditor>(plugins, options)

export type MyAutoformatRule = AutoformatRule<MyValue, MyEditor>
