import { LoadingButton } from '@mui/lab'
import { Link, Stack, TextField, Typography } from '@mui/material'
import type { FirebaseError } from 'firebase/app'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'

import { resetPassword } from 'src/api/accounts'
import { RouterLink } from 'src/components/SafeRouterLink'
import { parseError } from 'src/utils/firebase'

const LostPassword = () => {
  const { search } = useLocation()
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const handleSubmit = () => {
    setErrorMessage('')
    setLoading(true)

    void resetPassword(email)
      .then(() => setMessage('Reset Password Link has been sent to your email'))
      .catch((error: FirebaseError) => setErrorMessage(parseError(error)))
      .finally(() => setLoading(false))
  }

  return <>
    <Stack alignItems='flext-start' width={1}>
      <Typography textAlign='left' variant='h3'>
        Forgot your password?
      </Typography>
    </Stack>
    <Stack paddingBottom={2} paddingTop={1} spacing={6} width={1}>
      <TextField
        error={!!errorMessage}
        helperText={errorMessage || message}
        label='Email'
        onChange={event => setEmail(event.target.value)}
        placeholder='example@email.com'
        required
        type='email'
        value={email}
      />
    </Stack>
    <Stack spacing={2} width={1}>
      <LoadingButton loading={loading} onClick={handleSubmit} variant='contained'>
        Reset password
      </LoadingButton>
      <Typography textAlign='left' variant='body2'>
        {'Found your password? '}
        <Link component={RouterLink} to={`/account/login${search}`}>
          Sign in
        </Link>
      </Typography>
    </Stack>
  </>
}

export default LostPassword
