import dayjs from 'dayjs'

import type { CountryPhoneCode } from 'src/data/countryPhoneCodes'
import type { Serialized } from 'src/types/react-app-env'

export const MINIMUM_AGE = 18

export type Phone = {
  code: CountryPhoneCode['code']
  phoneNumber: string
}

export class NewAccount {
  id?: string
  dateOfJoining?: Date
  phone?: Phone
  tutorial = true
  email = ''
  firstName = ''
  lastName = ''
  addressLine1 = ''
  addressLine2 = ''
  city = ''
  country = ''
  region = ''
  postalCode = ''
  timezone = ''
  discountCode?: string
  birthday = dayjs().subtract(MINIMUM_AGE, 'year').toDate()

  disabledNotifications: {
    disabled: boolean
    inApp: string[]
    email: string[]
  } = {
      disabled: false,
      inApp: [],
      email: [],
    }
  blocked: Account[] = []

  profilePicture?: File = undefined

  constructor(dto: Partial<NewAccount>) {
    Object.assign(this, dto)
  }
}
export default class Account extends NewAccount {
  id: string
  _id?: string
  bio: string
  dateOfJoining = new Date()
  displayName: string
  phone: Phone
  createdAt: Date

  profilePicture: undefined
  profilePictureUrl: string

  constructor(dto: AccountDto) {
    super({
      ...dto,
      dateOfJoining: dto.dateOfJoining ? new Date(dto.dateOfJoining) : new Date(),
      birthday: new Date(dto.birthday ?? 0),
      blocked: dto.blocked ? dto.blocked.map(blockedAccount => new Account(blockedAccount)) : [],
    })
    this.id = dto.id ?? ''
    this.bio = dto.bio ?? ''
    this.createdAt = dto.createdAt ? new Date(dto.createdAt) : new Date()
    this.displayName = dto.displayName ?? ''
    this.phone = dto.phone ?? { code: '' as Phone['code'], phoneNumber: '' }
    this.profilePictureUrl = dto.profilePictureUrl ?? ''
  }
}
export type AccountDto = Partial<Serialized<Account>>
