/* eslint-disable @typescript-eslint/indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, useMediaQuery, useTheme } from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import type { Key } from 'react'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { getMarketingLinks, getMarketingLinksByProjectId } from 'src/api/links'
import LinksListEmpty from 'src/components/pages/Links/LinksList/LinksListComponents/LinksListEmpty'
import LinksListItem from 'src/components/pages/Links/LinksList/LinksListComponents/LinksListItem'
import LinksLoading from 'src/components/pages/Links/LinksList/LinksListComponents/LinksLoading'
import { useAuth } from 'src/components/providers/AuthProvider'
import { useNotifications } from 'src/components/providers/NotificationProvider'
import type { MarketingLink } from 'src/models/Marketing'
import type Project from 'src/models/Project'

type Props = {
  searchQuery: string
  linkType: string
  project?: Project
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

type Data = {
  artworkUrl: string
  createdAt: Date
  title: string
  type: string
  subdomain: string
  domain: string
  url: string
  actions: Date
}

type Order = 'asc' | 'desc'

const getComparator = (
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
  ) => number => order === 'desc'
  ? (a, b) => descendingComparator(a, b, orderBy)
  : (a, b) => -descendingComparator(a, b, orderBy)

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((element, index) => [element, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map(element => element[0])
}

type HeadCell = {
  disablePadding: boolean
  id: keyof Data
  label: string
  numeric: boolean
}

const headCells: readonly HeadCell[] = [
  {
    id: 'artworkUrl',
    numeric: true,
    disablePadding: false,
    label: 'Artwork',
  },
  {
    id: 'title',
    numeric: true,
    disablePadding: false,
    label: 'Title',
  },
  {
    id: 'type',
    numeric: true,
    disablePadding: false,
    label: 'Type',
  },
  {
    id: 'createdAt',
    numeric: true,
    disablePadding: false,
    label: 'Created on',
  },
  {
    id: 'actions',
    numeric: true,
    disablePadding: false,
    label: 'Actions',
  },
]

type EnhancedTableProps = {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void
  order: Order
  orderBy: string
  mobile: boolean
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { order, orderBy,  onRequestSort, mobile } =
    props
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  return (
    <TableHead
      sx={{
        '.MuiTableCell-root': {
          borderBottom: 2,
          borderBottomColor: 'divider',
          color: 'text.secondary',
        },
      }}
    >
      <TableRow>
        {headCells.filter(item => mobile
          ? item.id === 'artworkUrl' || item.id === 'title' || item.id === 'actions'
          : true).map(headCell =>
          // eslint-disable-next-line react/jsx-indent
          <TableCell
            align='left'
            key={headCell.id}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              width: headCell.id === 'artworkUrl'
                ? 96
                : 'auto',
            }}
          >
              <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              >
              {headCell.label}
              {orderBy === headCell.id
                ? <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
                : null}
            </TableSortLabel>
            </TableCell>)}
      </TableRow>
    </TableHead>
  )
}

const LinksListContent = (props: Props) => {
  const themeItem = useTheme()
  const isMobile = useMediaQuery(themeItem.breakpoints.down('md'))

  const { currentOrganisation } = useAuth()
  const { notifications } = useNotifications()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<MarketingLink[]>([])
  const [page, setPage] = useState(0)

  const [order, setOrder] = useState<Order>('desc')
  const [orderBy, setOrderBy] = useState<keyof Data>('createdAt')

  const filterData = data.filter(item =>
    item.title.toLowerCase().includes(props.searchQuery.toLowerCase()) &&
    item.type.toLowerCase().includes(props.linkType.toLowerCase()))
    .map(item => ({
      id: item.id ?? '',
      title: item.title,
      artworkUrl: item.artworkUrl,
      createdAt: Number(item.createdAt),
      type: item.type as string,
      subdomain: item.subdomain,
      domain: item.domain,
      url: item.url,
      actions: Number(item.createdAt),
    }))

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const [rowsPerPage, setRowsPerPage] = useState(25)

  const search = useLocation().search
  const goto = new URLSearchParams(search).get('marketingEditId')

  useEffect(() => {
    setLoading(true)
    updateLinks()
      .finally(() => setLoading(false))
  }, [currentOrganisation?.id, props.project?.id])

  useEffect(() => {
    void updateLinks()
  }, [notifications.length])

  useEffect(() => {
    void updateLinks()
  }, [goto])

  useEffect(() => {
    setPage(0)
  }, [filterData.length])

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number.parseInt(event.target.value, 10))
    setPage(0)
  }

  const updateLinks = async () => {
    await (props.project
      ? getMarketingLinksByProjectId(props.project.id)
        .then(setData)
        .finally(() => setLoading(false))
      : getMarketingLinks()
        .then(setData)
        .finally(() => setLoading(false)))
  }

  return (
    <>
      {
        loading ? <LinksLoading />
          : <Stack height={1}>
            {filterData.length > 0
              ? <>
                <TableContainer>
                  <Table
                    aria-label='projects table'
                    size='small'
                    sx={{
                      tableLayout: 'fixed',
                      minWidth: isMobile
                        ? 0
                        : 750,
                    }}
                  >
                    <EnhancedTableHead
                      mobile={isMobile}
                      onRequestSort={handleRequestSort}
                      order={order}
                      orderBy={orderBy}
                    />
                    <TableBody>
                      {stableSort(filterData, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map(link => {
                          const testLink = data.find(item => item.id === link.id)
                          return (
                            testLink &&
                            <LinksListItem
                              key={link.id}
                              link={testLink}
                              updateLinks={updateLinks}
                            />)
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  component='div'
                  count={filterData.length}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[10, 25, 50]}
                />
              </>
              : <LinksListEmpty
                project={props.project}
              />}
          </Stack>
      }
    </>
  )
}

export default LinksListContent
