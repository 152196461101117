/* eslint-disable @typescript-eslint/no-non-null-assertion */
import CloudDownloadRoundedIcon from '@mui/icons-material/CloudDownloadRounded'
import { Avatar, Card, Container, Paper, Stack, Typography, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'

import { getFilePublic } from 'src/api/files'
import { getBasicOrganisation } from 'src/api/organisation'
import { getProjectPublic } from 'src/api/projects'
import Loading from 'src/components/Loading'
import AudioPlayerItem from 'src/components/pages/Menu/AudioPlayerItem'
import PageNotFound from 'src/components/pages/PageNotFound'
import ViewFileItemPulicTable from 'src/components/pages/ShareFile/Components/ViewFileItemPublicTable'
import ReleeseLogo from 'src/components/ReleeseLogo'
import type { FileItem } from 'src/models/File'
import type { BasicOrganisation } from 'src/models/Organisation'

const ShareFile = () => {
  const themeItem = useTheme()
  const { id } = useParams<{ id: string }>()
  const { hash } = useParams<{ hash: string }>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [file, setFile] = useState<FileItem>()
  const [orgSimple, setOrg] = useState<BasicOrganisation>()

  useEffect(() => {
    if (id && hash) {
      setLoading(true)
      getFilePublic(id, hash)
        .then(data => {
          setFile(data)
          setError(false)
        })
        .catch(() => setError(true))
        .finally(() => setLoading(false))
    }
  }, [id, hash])

  useEffect(() => {
    if (file?.organisationId && file.organisationId.length > 0) {
      void getBasicOrganisation(file.organisationId)
        .then(org => {
          if (org) {
            setOrg(org)
          }
        })
    }
  }, [file?.organisationId])

  useEffect(() => {
    if (file?.projectId && file.projectId.length > 0) {
      void getProjectPublic(file.projectId)
        .then(proj => {
          if (proj.organisation !== undefined) {
            setOrg({
              name: proj.organisation.name,
              id: proj.organisation.id,
              logoUrl: proj.organisation.logoUrl,
              defaultSocialList: [],
            })
          }
        })
    }
  }, [file?.projectId])

  return !error
    ? loading
      ? <Loading />
      : <>
        <Container
          sx={{
            display: 'flex',
            alignItems: 'center',
            minHeight: 1,
            width: '100%!important',
            padding: {
              xl: 4,
              lg: 4,
              md: 4,
              sm: 2,
              xs: 2,
            },
            height: '100%',
            overflowY: 'auto',
            margin: 0,
            flexDirection: 'column',
            maxWidth: '100%!important',
            backgroundColor: theme => theme.palette.background.input,
          }}
        >
          <Card sx={{ width: 1, maxWidth: 'sm' }}>
            <Stack alignItems='center' justifyContent='center' maxWidth='sm' padding={4} spacing={4} width={1}>
              <CloudDownloadRoundedIcon
                sx={{
                  fontSize: 144,
                  color: theme => theme.palette.action.active,
                }}
              />
              <Stack alignItems='center' justifyContent='center' width={1}>
                <Typography lineHeight={1.2} textAlign='center' variant='h2'>
                  Your file is ready to download
                </Typography>
                <Typography color={themeItem.palette.text.label} textAlign='center' variant='body1'>
                  This link will not expire until the creator deletes this file
                </Typography>
              </Stack>
              <Paper sx={{ width: 1 }}>
                <Stack width={1}>
                  {file && file.url.length > 0 &&
                  <ViewFileItemPulicTable
                    fileItem={file}
                    link={`https://cdn.releese.io/${file.url}`}
                  />}
                </Stack>
              </Paper>
              <Stack alignItems='center' justifyContent='center' spacing={0.5}>
                <Typography color={themeItem.palette.text.label} variant='body2'>
                  Shared by
                </Typography>
                <Stack alignItems='center' direction='row' justifyContent='center' spacing={0.75}>
                  <Avatar src={orgSimple?.logoUrl} sx={{ height: 32, width: 32 }} />
                  <Typography color={themeItem.palette.text.primary} variant='h4'>
                    {orgSimple?.name}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Card>
          <Stack paddingY={4}>
            <ReleeseLogo full />
          </Stack>
        </Container>
        <AudioPlayerItem />
      </>
    : <Stack
      alignItems='flex-start'
      direction='row'
      height={1}
      width={1}
    >
      <PageNotFound />
    </Stack>
}

export default ShareFile
