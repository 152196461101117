/* eslint-disable id-length */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Stack, Typography, useTheme } from '@mui/material'
import _ from 'lodash'
import type { DateRange } from 'mui-daterange-picker'
import { useRef, useState } from 'react'
import Chart from 'react-apexcharts'

import { useContainerDimensions } from 'src/components/ContainerDimensions'
import type { AnalyticsCurrentData, AnalyticsHistoricData, HistoryEntity } from 'src/models/Analytics'

type Props = {
  historicData: AnalyticsHistoricData
  currentData: AnalyticsCurrentData
}

const MonthlyListenersDashboard = (props: Props) => {
  const theme = useTheme()
  const componentRef = useRef<HTMLDivElement>(null)
  const { width } = useContainerDimensions(componentRef)

  const types = [
    'monthly_listeners_current',
  ] as (keyof HistoryEntity)[]

  const mapItem = props.historicData.stats?.map(item => item.data?.history) as unknown as HistoryEntity[][] | undefined
  const flat = mapItem?.flat()

  const seriesTest =
    types.flatMap(key => ({
      name: key,
      data: (flat ?? []).filter(object => object && key in object)
        .map(item => ({
          x: item.date,
          y: item[key] ?? 0,
        })),
    }))

  const activeSeries = seriesTest.flatMap(item => item.data)

  const result = _.chain(activeSeries)
    .groupBy('x')
    .map((group, x) => ({ x, y: _.sumBy(group, 'y') }))
    .value()

  const newSeries = {
    name: 'Monthly listeners',
    data: result,
  }

  const total = props.currentData.stats?.map(item => item.data.monthly_listeners_current ?? 0)
    .reduce((partialSum, a) => partialSum + a, 0)

  const currentDate = new Date()
  const lastTwentyEightDays = new Date()
  lastTwentyEightDays.setDate(currentDate.getDate() - 28)
  const [dateRange, _setDateRange] = useState<DateRange>({
    startDate: lastTwentyEightDays,
    endDate: currentDate,
  })

  const getDateBoolean = (item: { x: string, y: number | string }) => (new Date(item.x).getTime() <= (dateRange.endDate
    ? new Date(dateRange.endDate).getTime()
    : Date.now())) &&
    (new Date(item.x).getTime() >= new Date(dateRange.startDate ?? 0).getTime())

  const optionsSectionsOverdue: ApexCharts.ApexOptions = {
    chart: {
      animations: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
      zoom: {
        enabled: false,
      },
      foreColor: theme.palette.text.primary,
      width: '100%',
      toolbar: {
        show: false,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      width: 2,
      curve: 'smooth',
    },
    colors: [
      theme.palette.spotify,
    ],
    grid: {
      show: false,
      padding: { left: -10, right: -10, top: 0, bottom: 0 },
    },
    tooltip: {
      theme: 'dark',
      x: {
        format: 'MMMM dd, yyyy',
      },
    },
    fill: {
      type: 'gradient',
    },
    dataLabels: {
      enabled: false,
    },
    labels: newSeries?.data.filter(item => getDateBoolean(item)).map(item => item.x),
    xaxis: {
      labels: {
        show: false,
      },
      type: 'datetime',
      tooltip: {
        enabled: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
      labels: {
        formatter: value => value?.toLocaleString(),
      },
    },
    legend: {
      showForZeroSeries: false,
    },
  }

  return (
    <Stack ref={componentRef} width={1}>
      <Typography lineHeight={1.1} textAlign='center' variant='h2'>
        {total?.toLocaleString()}
      </Typography>

      {newSeries && dateRange.endDate && dateRange.startDate &&
      <Chart
        height={240}
        options={optionsSectionsOverdue}
        series={[{
          name: 'Monthly listeners',
          data: newSeries.data.filter(item => getDateBoolean(item)),
        }]}
        type='area'
        width={width}
      />}
    </Stack>
  )
}

export default MonthlyListenersDashboard
