/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete, Avatar, Chip, ListItemText, MenuItem, Stack, TextField } from '@mui/material'
import _, { debounce } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { v4 } from 'uuid'

import { getArtists } from 'src/api/distribution'
import { ArtistObject } from 'src/models/Distribution'

type Props = {
  readonly disabled?: boolean
  readonly placeholder: string
  readonly selectedArtists: ArtistObject[] | undefined
  readonly setSelectedArtists: (values: ArtistObject[] | undefined) => void
}

const SearchArtistComponentSubtle = (props: Props) => {
  const [searchArtists, setSearchArtists] = useState<ArtistObject[]>([])
  const [searchQuery, setSearchQuery] = useState('')
  const emptyArtistItem = new ArtistObject({
    id: v4(),
    uri: '',
    name: searchQuery,
    followers: {
      total: 0,
    },
    images: [{ url: '' }],
  })

  const searchArtistFunction = async (query: string) => {
    if (query && query.length > 0) {
      await getArtists(query)
        .then(response => setSearchArtists(response.filter(item => item.id !== undefined)))
    } else {
      setSearchArtists([])
    }
  }

  const refreshItems = useCallback(
    debounce((query: string) => {
      void searchArtistFunction(query)
    }, 150, { leading: false, trailing: true }),
    []
  )

  useEffect(() => {
    refreshItems.cancel()
    refreshItems(searchQuery)
  }, [searchQuery, refreshItems])

  return (
    <Autocomplete
      autoSelect={false}
      disabled={props.disabled}
      filterOptions={filterValue => filterValue}
      fullWidth
      getOptionLabel={option => `${option.name}`}
      inputValue={searchQuery}
      multiple
      onChange={(event, value) => props.setSelectedArtists(value)}
      onInputChange={(event, value) => setSearchQuery(value)}
      options={searchQuery.length > 0 ? [emptyArtistItem, ...searchArtists] : []}
      renderInput={params =>
        <TextField
          {...params}
          disabled={props.disabled}
          fullWidth
          placeholder={props.placeholder}
          sx={{
            marginTop: 0,
            background: 'transparent!important',
            border: 'none',
            '.MuiInputBase-root': {
              background: 'transparent!important',
              borderColor: 'transparent!important',
              paddingY: 0,
              marginTop: 0,
            },
          }}
        />}
      renderOption={(renderProps, option) =>
        <MenuItem
          {...renderProps}
          key={option.id}
          sx={{
            paddingY: 0.5,
          }}
        >
          <Stack
            sx={{
              minWidth: 48,
            }}
          >
            <Avatar
              src={option.images?.[0]?.url ? option.images[0].url : ''}
              sx={{
                height: 36,
                width: 36,
                borderRadius: 0.5,
                backgroundColor: theme => theme.palette.background.input,
              }}
            />
          </Stack>
          <ListItemText
            primary={option.name}
            secondary={option.followers && option.followers.total > 0
              ? `${option.followers.total.toLocaleString()} followers`
              : 'Create new artist'}
          />
        </MenuItem>}
      renderTags={(value: readonly ArtistObject[], getTagProps) =>
        value.map((option: ArtistObject, index: number) =>
          <Chip
            label={option.name}
            {...getTagProps({ index })}
            avatar={<Avatar src={option.images?.[0]?.url ? option.images[0].url : ''} />}
            disabled={props.disabled}
            key={`${option.id}-${v4()}`}
            sx={{
              background: theme => theme.palette.background.input,
            }}
          />)}
      sx={{
        overflow: 'hidden',
        '.MuiAutocomplete-tag': {
          background: theme => theme.palette.background.input,
        },
      }}
      value={props.selectedArtists}
    />
  )
}

export default SearchArtistComponentSubtle
