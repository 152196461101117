/* eslint-disable complexity */
import DataObjectRoundedIcon from '@mui/icons-material/DataObjectRounded'
import SpeedRoundedIcon from '@mui/icons-material/SpeedRounded'
import { LoadingButton } from '@mui/lab'
import { Alert, Divider, Snackbar, Stack, TextField, Typography, useTheme } from '@mui/material'
import { useState } from 'react'

import { createTestMarketingEvent } from 'src/api/links'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { Organisation } from 'src/models/Organisation'
import { Permissions } from 'src/models/Organisation'

const Targeting = () =>  {
  const themeItem = useTheme()
  const { currentOrganisation, updateCurrentOrganisation, refreshCurrentOrganisation } = useAuth()

  const [loading, setLoading] = useState(false)
  const [loadingEvent, setLoadingEvent] = useState(false)
  const [toastTest, setTestToast] = useState(false)
  const [toastPixel, setToastPixel] = useState(false)

  const [conversionApiAccessToken, setConversionApiAccessToken] =
    useState(currentOrganisation?.conversionApiAccessToken ?? '')
  const [conversionApiPixelId, setCnversionApiPixelId] =
    useState(currentOrganisation?.conversionApiPixelId ?? '')
  const [testEvent, setTestEvent] = useState('')

  const checkSubscription = currentOrganisation?.membershipTier !== 'ENTERPRISE' &&
    currentOrganisation?.membershipTier !== 'ENTERPRISE_YEARLY' &&
    currentOrganisation?.membershipTier !== 'CREATOR_PRO' &&
    currentOrganisation?.membershipTier !== 'CREATOR_PRO_YEARLY'

  const saveOwnerDetails = async () => {
    if (!currentOrganisation?.seats
      .find(seat => seat.role.permissions
        .includes(Permissions.EDIT_ORGANISATION_SETTINGS))) {
      return
    }
    setLoading(true)
    const account: Organisation = {
      ...currentOrganisation,
      conversionApiAccessToken,
      conversionApiPixelId,
    }
    await updateCurrentOrganisation(account)
      .then(() => setLoading(false))
      .then(refreshCurrentOrganisation)
      .finally(() => {
        setLoading(false)
        setToastPixel(true)
      })
  }

  const testEventClick = async () => {
    setLoadingEvent(true)
    await createTestMarketingEvent(testEvent)
      .finally(() => {
        setLoadingEvent(false)
        setTestToast(true)
      })
  }

  const handleCloseToast = (event: Event | React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setToastPixel(false)
    setTestToast(false)
  }

  return (
    <>
      <Snackbar
        autoHideDuration={5000}
        message='Link Copied'
        onClose={handleCloseToast}
        open={toastPixel}
      >
        <Alert
          onClose={handleCloseToast}
          severity='success'
          sx={{ width: '100%' }}
          variant='filled'
        >
          Your Pixel has been set
        </Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={5000}
        message='Link Copied'
        onClose={handleCloseToast}
        open={toastTest}
      >
        <Alert
          onClose={handleCloseToast}
          severity='success'
          sx={{ width: '100%' }}
          variant='filled'
        >
          Test event submitted
        </Alert>
      </Snackbar>
      <Stack direction='column' maxWidth={1096} padding={4} spacing={4} width={1}>
        {currentOrganisation &&
        <>
          <Stack direction='column'>
            <Typography lineHeight={1.2} variant='h3'>
              Meta Conversions API
            </Typography>
            <Typography color={themeItem.palette.text.label} maxWidth={640} variant='body1' >
              Bypass ad blockers and improve targeting using Releese&apos;s implementation of Conversions API.
              To get started with Conversions API, visit
              {' '}
              <a
                href='https://www.facebook.com/business/help/2041148702652965?id=818859032317965'
                style={{
                  textDecoration: 'none',
                  color: themeItem.palette.text.link,
                }}
              >
                this page
              </a>
            </Typography>
          </Stack>

          {checkSubscription &&
          <Stack marginTop={2} spacing={2}>
            <Alert
              severity='success'
              sx={{
                maxWidth: 640,
                color: theme => theme.palette.primary.contrastText,
              }}
              variant='filled'
            >
              The Conversions API integration is only available to Pro and Business subscribers
            </Alert>
          </Stack>}

          <Stack direction='column' maxWidth={620} paddingTop={4} spacing={4} width={1}>
            <Stack direction='row' spacing={3} width={1}>
              <Typography variant='body2' width={192}>
                Access Token
              </Typography>
              <Stack direction='column' spacing={1} width={1}>
                <Stack alignItems='center' direction='row' spacing={1}>
                  <TextField
                    autoComplete='new-password'
                    disabled={checkSubscription}
                    fullWidth
                    id='conversionapi'
                    label='Conversions API Access Token'
                    onChange={event => setConversionApiAccessToken(event.target.value.replace(' ', ''))}
                    placeholder='01234567890123456789'
                    sx={{ marginTop: 0 }}
                    type='password'
                    value={conversionApiAccessToken}
                  />
                </Stack>
                <Typography
                  maxWidth={480}
                  variant='helperText'
                >
                  Your access token in required to enable Conversions API on your landing pages
                </Typography>
              </Stack>
            </Stack>
            <Stack direction='row' paddingTop={4} spacing={3} width={1}>
              <Typography variant='body2' width={192}>
                Pixel ID
              </Typography>
              <Stack direction='column' spacing={1} width={1}>
                <TextField
                  autoComplete='new-password'
                  disabled={checkSubscription}
                  fullWidth
                  id='pixelid'
                  label='Pixel ID'
                  onChange={event => setCnversionApiPixelId(event.target.value.replace(' ', ''))}
                  placeholder='01234567890123456789'
                  sx={{ marginTop: 0 }}
                  value={conversionApiPixelId}
                />
                <Typography
                  maxWidth={480}
                  variant='helperText'
                >
                  Please make sure your Pixel ID matches your Access Token
                </Typography>
              </Stack>
            </Stack>

            <Stack direction='row' spacing={3} width={1}>
              <Typography variant='body2' width={192}>
                Setup
              </Typography>
              <Stack direction='column' spacing={1} width={1}>
                <LoadingButton
                  disabled={!conversionApiAccessToken ||
                  conversionApiAccessToken.length === 0 ||
                  !conversionApiPixelId ||
                  conversionApiPixelId.length === 0 ||
                  checkSubscription}
                  loading={loading}
                  onClick={()  => saveOwnerDetails()}
                  startIcon={<DataObjectRoundedIcon />}
                  sx={{
                    width: 'fit-content',
                  }}
                  variant='contained'
                >
                  Set your Pixel
                </LoadingButton>
                <Typography
                  maxWidth={480}
                  variant='helperText'
                >
                  Once your pixel is set, you can send a test event by inputing your test code
                </Typography>
              </Stack>
            </Stack>

            <Divider />

            <Stack direction='row' paddingTop={4} spacing={3} width={1}>
              <Typography variant='body2' width={192}>
                Test Code
              </Typography>
              <Stack direction='column' spacing={1} width={1}>
                <TextField
                  autoComplete='off'
                  disabled={!currentOrganisation.conversionApiAccessToken ||
                    currentOrganisation.conversionApiAccessToken.length === 0 ||
                    !currentOrganisation.conversionApiPixelId ||
                    currentOrganisation.conversionApiPixelId.length === 0 ||
                    checkSubscription}
                  fullWidth
                  id='testid'
                  label='Test ID'
                  onChange={event => setTestEvent(event.target.value.replace(' ', ''))}
                  placeholder='TEST12345'
                  sx={{ marginTop: 0 }}
                  value={testEvent}
                />
                <Typography
                  maxWidth={480}
                  variant='helperText'
                >
                  Set your test code to send test events to your Events Manager.
                </Typography>
              </Stack>
            </Stack>

            <Stack direction='row' spacing={3} width={1}>
              <Typography variant='body2' width={192}>
                Test
              </Typography>
              <Stack direction='column' spacing={1} width={1}>
                <LoadingButton
                  color='secondary'
                  disabled={!currentOrganisation.conversionApiAccessToken ||
                    currentOrganisation.conversionApiAccessToken.length === 0 ||
                    !currentOrganisation.conversionApiPixelId ||
                    currentOrganisation.conversionApiPixelId.length === 0 ||
                    testEvent.length === 0 ||
                    checkSubscription}
                  loading={loadingEvent}
                  onClick={()  => testEventClick()}
                  startIcon={<SpeedRoundedIcon />}
                  sx={{
                    width: 'fit-content',
                  }}
                  variant='contained'
                >
                  Test Event
                </LoadingButton>
                <Typography
                  maxWidth={480}
                  variant='helperText'
                >
                  Click test, and monitor your dashboard to view your test events. It can take up to 90 seconds
                  for the events to be visible in your Events Manager dashboard
                </Typography>
              </Stack>
            </Stack>

          </Stack>
        </>}

      </Stack>
    </>
  )
}

export default Targeting
