import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'

import { deleteRecording } from 'src/api/recordings'
import DeleteItemComponent from 'src/components/DeleteItemComponent'
import { useAuth } from 'src/components/providers/AuthProvider'
import type Recording from 'src/models/Recording'

type Props = {
  close: () => void
  open: boolean
  recording: Recording
  updateRecordings: () => Promise<void>
}

const DeleteRecordingModal = (props: Props) => {
  const themeColors = useTheme()
  const [loading, setLoading] = useState(false)
  const fullScreen = useMediaQuery(themeColors.breakpoints.down('md'))

  const { refreshCurrentOrganisation } = useAuth()

  const deleteProjectAction = async ()  => {
    setLoading(true)
    await deleteRecording(props.recording)
      .then(() => setLoading(false))
      .then(props.updateRecordings)
      .then(refreshCurrentOrganisation)
      .then(props.close)
      .catch(() => setLoading(false))
      .finally(() => setLoading(false))
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='xs'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Stack paddingX={3} paddingY={3} spacing={4} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          width='100%'
        >
          <Typography variant='h3'>
            Delete Recording
          </Typography>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>
        <DeleteItemComponent
          primary='Are you sure?'
          secondary='This action cannot be undone'
        />
        <Stack direction='row' justifyContent='flex-end' spacing={1} width='100%'>
          <Button onClick={() => props.close()} variant='text'>
            Cancel
          </Button>
          <LoadingButton
            color='error'
            loading={loading}
            onClick={deleteProjectAction}
            variant='contained'
          >
            Delete recording
          </LoadingButton>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default DeleteRecordingModal
