/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-duplicated-branches */
import { Avatar, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Stack, Typography } from '@mui/material'

import GetIcon from 'src/components/pages/SmartLink/Content/GetIcon'
import type { AnalyticsCurrentData, AnalyticsProfileData, Data } from 'src/models/Analytics'

type Props = {
  currentTab: string
  setCurrentTab: (tab: string) => void
  currentData?: AnalyticsCurrentData
  profileData?: AnalyticsProfileData
}

export const setAnalyticsTitle =
  (stringItem: string) =>
    (stringItem.charAt(0).toUpperCase() + stringItem.slice(1).replaceAll('_', ' '))
      .replaceAll('Youtube', 'YouTube')
      .replaceAll('Itunes', 'iTunes')
      .replaceAll('Tiktok', 'TikTok')
      .replaceAll('Apple music', 'Apple Music')

const AnalyticsTotalNav = (props: Props) => {
  const tabs = props.currentData?.stats?.filter(item => {
    let count = 0
    const keys = Object.keys(item.data)
    for (const key of keys) {
      if (item.data[key as keyof Data] !== undefined &&
        item.data[key as keyof Data] !== null) {
        const test = item.data[key as keyof Data]
        if (test && (typeof test === 'number' || (typeof test !== 'number' && test.length > 0))) {
          count++
        }
      }
    }
    return count > 0 && keys.length > 0
  })

  return (
    <Stack direction='column' flexShrink={0} justifyContent='space-between' overflow='auto' width={280}>
      <Stack alignItems='center'>
        <Stack alignItems='center' paddingTop={3}>
          <Avatar
            src={props.profileData?.profileData.avatar}
            sx={{
              width: 128,
              height: 128,
            }}
          />
          <Typography textAlign='center' variant='h3'>
            {props.profileData?.profileData.name}
          </Typography>
        </Stack>
        <List dense id='project-tabs' sx={{ width: 1 }}>
          <Stack width={1}>
            <ListItem
              dense
              disablePadding
            >
              <ListItemButton
                onClick={() => props.setCurrentTab('Overview')}
                selected={props.currentTab === 'Overview'}
                sx={{ borderRadius: 0 }}
              >
                <ListItemAvatar
                  sx={{
                    color: theme => props.currentTab === 'Overview'
                      ? theme.palette.primary.main
                      : theme.palette.action.active,
                  }}
                >
                  {GetIcon('Overview')}
                </ListItemAvatar>
                <ListItemText
                  primary={<Typography variant='body2'>
                    {setAnalyticsTitle('Overview')}
                  </Typography>}
                  sx={{ wordBreak: 'break-word' }}
                />
              </ListItemButton>
            </ListItem>

            <ListItem
              dense
              disablePadding
            >
              <ListItemButton
                onClick={() => props.setCurrentTab('Catalog')}
                selected={props.currentTab === 'Catalog'}
                sx={{ borderRadius: 0 }}
              >
                <ListItemAvatar
                  sx={{
                    color: theme => props.currentTab === 'Catalog'
                      ? theme.palette.primary.main
                      : theme.palette.action.active,
                  }}
                >
                  {GetIcon('Catalog')}
                </ListItemAvatar>
                <ListItemText
                  primary={<Typography variant='body2'>
                    {setAnalyticsTitle('Catalog')}
                  </Typography>}
                  sx={{ wordBreak: 'break-word' }}
                />
              </ListItemButton>
            </ListItem>

            {tabs?.map(currentItem =>
              <ListItem
                dense
                disablePadding
                key={currentItem.source}
              >
                <ListItemButton
                  onClick={() => props.setCurrentTab(currentItem.source)}
                  selected={props.currentTab === currentItem.source}
                  sx={{ borderRadius: 0 }}
                >
                  <ListItemAvatar
                    sx={{
                      color: theme => props.currentTab === currentItem.source
                        ? theme.palette.primary.main
                        : theme.palette.action.active,
                    }}
                  >
                    {GetIcon(currentItem.source)}
                  </ListItemAvatar>
                  <ListItemText
                    primary={<Typography variant='body2'>
                      {setAnalyticsTitle(currentItem.source)}
                    </Typography>}
                    sx={{ wordBreak: 'break-word' }}
                  />
                </ListItemButton>
              </ListItem>)}

          </Stack>
        </List>
      </Stack>
    </Stack>
  )
}

export default AnalyticsTotalNav
