export const storesSocialAssets = [
  { name: 'Spotify' },
  { name: 'Apple Music' },
  { name: 'Amazon' },
  { name: 'Deezer' },
  { name: 'SoundCloud' },
  { name: 'Tidal' },
  { name: 'YouTube' },
  { name: 'Anghami' },
  { name: 'Claro-musica' },
  { name: 'iHeartRadio' },
  { name: 'Joox' },
  { name: 'Napster' },
  { name: 'Netease' },
  { name: 'Pandora' },
  { name: 'Qobuz' },
  { name: 'Saavn' },
  { name: 'Tencent' },
  { name: 'Naver' },
]
