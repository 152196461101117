import CloseIcon from '@mui/icons-material/Close'
import { Button, Dialog, IconButton, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import type { ChangeEvent } from 'react'
import { useState } from 'react'
import { v4 } from 'uuid'

import { createTaskboardGroup } from 'src/api/taskboards'
import { sendWorkspaceUpdate } from 'src/api/webSocket'
import type { Workspace } from 'src/models/Taskboard'
import { NewTaskboardGroup } from 'src/models/Taskboard'

type Props = {
  close: () => void
  open: boolean
  refreshBoards: () => void
  activeWorkspace: Workspace | undefined
}

const NewTaskboardGroupModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  const [groupName, setGroupName] = useState('')

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setGroupName(event.target.value)
  }

  const handleClick = async () => {
    const newGroup = new NewTaskboardGroup({
      title: groupName,
      workspaceId: props.activeWorkspace?.id,
    })
    await createTaskboardGroup(newGroup)
      .then(() => sendWorkspaceUpdate(v4()))
      .then(props.refreshBoards)
    props.close()
    setGroupName('')
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='xs'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
        width: 1,
      }}
    >
      <Stack paddingX={3} paddingY={3} spacing={2} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          width='100%'
        >
          <Typography variant='h3'>Create a Folder</Typography>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>
        <Stack width='100%'>
          <TextField
            fullWidth
            inputProps={{ maxLength: 50 }}
            label='Name your folder'
            onChange={handleNameChange}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                void handleClick()
              }
            }}
            placeholder='Folder name'
            value={groupName}
          />
        </Stack>
        <Stack direction='row' justifyContent='flex-end' spacing={1} width='100%'>
          <Button onClick={() => props.close()} variant='text'>Cancel</Button>
          <Button
            onClick={() => handleClick()}
          >
            Create folder
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default NewTaskboardGroupModal
