import { Stack } from '@mui/material'

import EarningsCloudChart from 'src/components/pages/Earnings/EarningsDistribution/EarningsCloudChart'
import NewEarningsLocation from 'src/components/pages/Earnings/EarningsDistribution/Locations/NewEarningsMap'
import type { AnalyticsMonthlyChart } from 'src/models/Analytics'

type Props = {
  data: AnalyticsMonthlyChart[]
}

const NewEarningsDistributionLocations = (props: Props) =>
  <Stack width={1}>
    <NewEarningsLocation
      data={props.data}
    />
    <EarningsCloudChart
      data={props.data}
      location
    />
  </Stack>

export default NewEarningsDistributionLocations
