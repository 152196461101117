/* eslint-disable unicorn/filename-case */
import type { TEditableProps } from '@udecode/plate'

import type { MyValue } from 'src/components/pages/Notes/Modern/typescript/plateTypes'

export const editableProps: TEditableProps<MyValue> = {
  spellCheck: false,
  autoFocus: false,
  readOnly: false,
  placeholder: '',
}
