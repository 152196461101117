/* eslint-disable react-hooks/exhaustive-deps */
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { Button, Dialog, IconButton, Paper, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'

type Props = {
  readonly close: () => void
  readonly open: boolean
  readonly description: string
  readonly title: string
  readonly icon: JSX.Element
  readonly color: string
}

const ExplainModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='sm'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Stack spacing={1} width={1}>
        <Stack alignItems='center' direction='row' justifyContent='space-between' paddingTop={2} paddingX={2} width={1}>
          <Typography variant='h3'>
            {props.title}
          </Typography>
          <IconButton onClick={() => props.close()}>
            <CloseRoundedIcon color='disabled' />
          </IconButton>
        </Stack>
        <Stack alignItems='center' paddingY={2} width={1}>
          <Paper
            elevation={0}
            sx={{
              backgroundColor: props.color,
              borderRadius: 16,
              width: 'fit-content',
            }}
          >
            <Stack
              alignItems='center'
              justifyContent='center'
              padding={2}
            >
              {props.icon}
            </Stack>
          </Paper>
        </Stack>
        <Stack paddingX={2} width={1}>
          <Typography variant='body1'>
            {props.description}
          </Typography>
        </Stack>
        <Stack direction='column' paddingBottom={2} paddingX={2} spacing={1} width={1}>
          <Stack alignItems='flex-end' direction='row' justifyContent='flex-end' paddingY={1} spacing={2} width={1}>
            <Button onClick={() => props.close()}>
              Close
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default ExplainModal
