/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete, Avatar, Chip, ListItemText, MenuItem, Stack, TextField, Typography } from '@mui/material'
import _, { debounce } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { v4 } from 'uuid'

import { getArtists } from 'src/api/distribution'
import { ArtistObject } from 'src/models/Distribution'

type Props = {
  readonly disabled?: boolean
  readonly label: string
  readonly placeholder: string
  readonly selectedArtists: ArtistObject[] | undefined
  readonly setSelectedArtists: (values: ArtistObject[] | undefined) => void
  readonly error?: boolean
  readonly limit?: number
}

const SearchArtistComponent = (props: Props) => {
  const [searchArtists, setSearchArtists] = useState<ArtistObject[]>([])
  const [searchQuery, setSearchQuery] = useState('')
  const emptyArtistItem = new ArtistObject({
    id: v4(),
    uri: '',
    name: searchQuery,
    followers: {
      total: 0,
    },
    images: [{ url: '' }],
  })

  const searchArtistFunction = async (query: string) => {
    if (query && query.length > 0) {
      await getArtists(query)
        .then(response => setSearchArtists(response.filter(item => item.id !== undefined)))
    } else {
      setSearchArtists([])
    }
  }

  const refreshItems = useCallback(
    debounce((query: string) => {
      void searchArtistFunction(query)
    }, 150, { leading: false, trailing: true }),
    []
  )

  useEffect(() => {
    refreshItems.cancel()
    refreshItems(searchQuery)
  }, [searchQuery, refreshItems])

  return (
    <>
      <Autocomplete
        ListboxProps={{
          sx: {
            maxHeight: 256,
          },
        }}
        autoSelect={false}
        disabled={props.disabled}
        filterOptions={filterValue => filterValue}
        getOptionLabel={option => `${option.name}`}
        inputValue={searchQuery}
        limitTags={props.limit}
        multiple
        onChange={(event, value) => {
          props.setSelectedArtists(value)
        }}
        onInputChange={(event, value) => setSearchQuery(value)}
        options={searchQuery.length > 0 ? [emptyArtistItem, ...searchArtists] : []}
        renderInput={params =>
          <TextField
            {...params}
            autoComplete='new-password'
            disabled={props.disabled}
            error={props.error ?? false}
            fullWidth
            label={props.label}
            placeholder={props.placeholder}
          />}
        renderOption={(renderProps, option) =>
          <MenuItem
            {...renderProps}
            disabled={props.disabled}
            key={option.id}
            sx={{
              paddingY: 0.5,
            }}
          >
            <Stack
              sx={{
                minWidth: 48,
              }}
            >
              <Avatar
                src={option.images?.[0]?.url ? option.images[0].url : ''}
                sx={{
                  height: 36,
                  width: 36,
                  borderRadius: 0.5,
                  backgroundColor: theme => theme.palette.background.input,
                }}
              />
            </Stack>
            <ListItemText
              primary={option.name}
              secondary={option.followers && option.followers.total > 0
                ? `${option.followers.total.toLocaleString()} followers`
                : 'Create new artist'}
            />
          </MenuItem>}
        renderTags={(value: readonly ArtistObject[], getTagProps) =>
          value.map((option: ArtistObject, index: number) =>
            <Chip
              label={option.name}
              {...getTagProps({ index })}
              avatar={<Avatar src={option.images?.[0]?.url ? option.images[0].url : ''} />}
              key={`${option.id}-${v4()}`}
            />)}
        value={props.selectedArtists}
      />
      {props.error &&
      <Typography color='error' variant='body2'>
        Please do not include duplicate artists and select at least 1 artist
      </Typography>}
    </>
  )
}

export default SearchArtistComponent
