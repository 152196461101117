/* eslint-disable complexity */
import BookmarkRoundedIcon from '@mui/icons-material/BookmarkRounded'
import BorderAllRoundedIcon from '@mui/icons-material/BorderAllRounded'
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded'
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded'
import FormatListNumberedRoundedIcon from '@mui/icons-material/FormatListNumberedRounded'
import FormatQuoteRoundedIcon from '@mui/icons-material/FormatQuoteRounded'
import Grid3x3RoundedIcon from '@mui/icons-material/Grid3x3Rounded'
import Grid4x4RoundedIcon from '@mui/icons-material/Grid4x4Rounded'
import ImageRoundedIcon from '@mui/icons-material/ImageRounded'
import Looks3RoundedIcon from '@mui/icons-material/Looks3Rounded'
import LooksOneRoundedIcon from '@mui/icons-material/LooksOneRounded'
import LooksTwoRoundedIcon from '@mui/icons-material/LooksTwoRounded'
import OndemandVideoRoundedIcon from '@mui/icons-material/OndemandVideoRounded'
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded'
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded'
import TextFormatRoundedIcon from '@mui/icons-material/TextFormatRounded'
import TitleRoundedIcon from '@mui/icons-material/TitleRounded'
import YouTubeIcon from '@mui/icons-material/YouTube'

const blockOptionsPrettyMore = [
  'Paragraph',
  'Large title',
  'Medium title',
  'Small title',
  'Quote',
  'Todo list',
  'Numbered list',
  'Bulleted list',
  'Red banner',
  'Yellow banner',
  'Blue banner',
  'Green banner',
  'YouTube',
  '2x2 Table',
  '3x3 Table',
  '4x4 Table',
  'Image',
  'Video',
  'Divider',
  'Taskboard',
  'Project',
  'Recording',
]

export const getCodeIcon = (text: string) => {
  switch (text) {
    case blockOptionsPrettyMore[0]:
      return <TextFormatRoundedIcon color='primary' />
    case blockOptionsPrettyMore[1]:
      return <LooksOneRoundedIcon color='secondary' />
    case blockOptionsPrettyMore[2]:
      return <LooksTwoRoundedIcon color='secondary' />
    case blockOptionsPrettyMore[3]:
      return <Looks3RoundedIcon color='secondary'  />
    case blockOptionsPrettyMore[4]:
      return <FormatQuoteRoundedIcon color='action' />
    case blockOptionsPrettyMore[5]:
      return <CheckBoxRoundedIcon color='primary'  />
    case blockOptionsPrettyMore[6]:
      return <FormatListNumberedRoundedIcon color='primary' />
    case blockOptionsPrettyMore[7]:
      return <FormatListBulletedRoundedIcon color='primary' />
    case blockOptionsPrettyMore[8]:
      return <BookmarkRoundedIcon color='error' />
    case blockOptionsPrettyMore[9]:
      return <BookmarkRoundedIcon color='warning' />
    case blockOptionsPrettyMore[10]:
      return <BookmarkRoundedIcon color='info' />
    case blockOptionsPrettyMore[11]:
      return <BookmarkRoundedIcon color='success' />
    case blockOptionsPrettyMore[12]:
      return <YouTubeIcon sx={{ color: theme => theme.palette.youtube }} />
    case blockOptionsPrettyMore[13]:
      return <BorderAllRoundedIcon color='action' />
    case blockOptionsPrettyMore[14]:
      return <Grid3x3RoundedIcon color='action' />
    case blockOptionsPrettyMore[15]:
      return <Grid4x4RoundedIcon color='action' />
    case blockOptionsPrettyMore[16]:
      return <ImageRoundedIcon color='action' />
    case blockOptionsPrettyMore[17]:
      return <OndemandVideoRoundedIcon color='action' />
    case blockOptionsPrettyMore[18]:
      return <RemoveRoundedIcon color='action' />
    case blockOptionsPrettyMore[19]:
      return <TaskAltRoundedIcon color='success' />
    default:
      return <TitleRoundedIcon color='action' />
  }
}

export const getCodeDescription = (text: string) => {
  switch (text) {
    case blockOptionsPrettyMore[0]:
      return 'A paragraph block'
    case blockOptionsPrettyMore[1]:
      return 'An H1 title block'
    case blockOptionsPrettyMore[2]:
      return 'An H2 title block'
    case blockOptionsPrettyMore[3]:
      return 'An H3 title block'
    case blockOptionsPrettyMore[4]:
      return 'A quote item'
    case blockOptionsPrettyMore[5]:
      return 'A checklist'
    case blockOptionsPrettyMore[6]:
      return 'An ordered list'
    case blockOptionsPrettyMore[7]:
      return 'An unordered list'
    case blockOptionsPrettyMore[8]:
      return 'An alert banner'
    case blockOptionsPrettyMore[9]:
      return 'A warning banner'
    case blockOptionsPrettyMore[10]:
      return 'An info banner'
    case blockOptionsPrettyMore[11]:
      return 'A success banner'
    case blockOptionsPrettyMore[12]:
      return 'A YouTube video'
    case blockOptionsPrettyMore[13]:
      return 'A small table'
    case blockOptionsPrettyMore[14]:
      return 'A medium table'
    case blockOptionsPrettyMore[15]:
      return 'A large table'
    case blockOptionsPrettyMore[16]:
      return 'An image'
    case blockOptionsPrettyMore[17]:
      return 'An embedded video'
    case blockOptionsPrettyMore[18]:
      return 'A dividing line'
    case blockOptionsPrettyMore[19]:
      return 'A Releese Taskboard'
    case blockOptionsPrettyMore[20]:
      return 'A Releese Project'
    case blockOptionsPrettyMore[21]:
      return 'A Releese Recording'
    default:
      return 'Description'
  }
}

export const defaultCommands = [
  'p',
  'h1',
  'h2',
  'h3',
  'blockquote',
  'checklist',
  'numbered-list',
  'bulleted-list',
  'alert',
  'warning',
  'info',
  'success',
]
