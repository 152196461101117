/* eslint-disable max-lines */
/* eslint-disable id-length */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import TableViewRoundedIcon from '@mui/icons-material/TableViewRounded'
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded'
import { Backdrop, Button, Card, Grid, Stack, Typography, useTheme } from '@mui/material'
import { useRef, useState } from 'react'
import Chart from 'react-apexcharts'

import { useContainerDimensions } from 'src/components/ContainerDimensions'
import BillingModal from 'src/components/pages/Account/Billing/BillingModal'
import { colorSelection } from 'src/components/pages/Task/TaskMenu/TaskMenuComponents/ColorsForPicker'
import { useAuth } from 'src/components/providers/AuthProvider'

const DashboardFree = () => {
  const { currentOrganisation } = useAuth()
  const [billingModal, setBillingModalOpen] = useState(false)
  const theme = useTheme()

  const taskSectionNames = ['Marketing', 'Events', 'Promotions', 'Accounting', 'Legal']
  const taskSectionColors =
    [colorSelection[0], colorSelection[1], colorSelection[2], colorSelection[3], colorSelection[4]]
  const taskSectionCount = [12, 8, 7, 11, 5]
  const overdueTaskPerSection = [2, 1, 3, 1, 3]

  const allTasks = taskSectionCount.reduce((partialSum, a) => partialSum + a, 0)
  const completedTasks = 14
  const incompletedTasks = allTasks - completedTasks
  const overdueTasks = 3

  const notStartedTasks = 12
  const inProgressTasks = 9
  const doneTasks = 13
  const notApprovedTasks = 9

  const noPriorityTasks = 9
  const lowPriorityTasks = 12
  const mediumPriorityTasks = 14
  const highPriorityTasks = 8

  const seriesPriority: number[] = [noPriorityTasks, lowPriorityTasks, mediumPriorityTasks, highPriorityTasks]
  const labelsPriority = ['None', 'Low', 'Medium', 'High']
  const optionsPriority = {
    series: seriesPriority,
    labels: labelsPriority,
    dataLabels: {
      enabled: false,
    },
    colors: [theme.palette.text.label,
      theme.palette.primary.main,
      theme.palette.rating,
      theme.palette.error.main,
    ],
    plotOptions: {
      pie: {
        donut: {
          size: '55%',
          labels: {
            show: true,
            fontFamily: 'Inter',
          },
        },
      },
    },
    stroke: {
      colors: ['transparent'],
    },
    legend: {
      fontFamily: 'Inter',
      width: 150,
    },
    chart: {
      foreColor: theme.palette.text.primary,
    },
  }

  const seriesStatus: number[] = [notStartedTasks, inProgressTasks, doneTasks, notApprovedTasks]
  const labelsStatus = ['Not started', 'In progress', 'Done', 'Not approved']
  const optionsStatus = {
    series: seriesStatus,
    labels: labelsStatus,
    dataLabels: {
      enabled: false,
    },
    colors: [theme.palette.primary.main,
      theme.palette.warning.main,
      theme.palette.success.main,
      theme.palette.error.main,
    ],
    plotOptions: {
      pie: {
        donut: {
          size: '55%',
          labels: {
            show: true,
            fontFamily: 'Inter',
          },
        },
      },
    },
    stroke: {
      colors: ['transparent'],
    },
    legend: {
      fontFamily: 'Inter',
      width: 150,
    },
    tooltip: {
      theme: 'dark',
    },
    chart: {
      background: 'transparent',
      foreColor: theme.palette.text.primary,
    },
  }

  const optionsSections = {
    series: [{
      name: 'Tasks',
      data: taskSectionCount,
    }],
    labels: taskSectionNames,
    dataLabels: {
      enabled: false,
    },
    colors: taskSectionColors,
    plotOptions: {
      bar: {
        columnWidth: '25%',
        distributed: true,
        borderRadius: 8,
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      theme: 'dark',
      followCursor: true,
      x: {
        show: false,
      },
    },
    chart: {
      foreColor: theme.palette.text.primary,
      toolbar: {
        show: false,
      },
    },
  }

  const optionsSectionsOverdue = {
    series: [{
      name: 'Tasks',
      data: overdueTaskPerSection,
    }],
    labels: taskSectionNames,
    dataLabels: {
      enabled: false,
    },
    colors: taskSectionColors,
    plotOptions: {
      bar: {
        columnWidth: '25%',
        distributed: true,
        borderRadius: 8,
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      theme: 'dark',
      followCursor: true,
      x: {
        show: false,
      },
    },
    chart: {
      foreColor: theme.palette.text.primary,
      toolbar: {
        show: false,
      },
    },
  }

  const componentRef = useRef<HTMLDivElement>(null)
  const { width } = useContainerDimensions(componentRef)

  return <Stack
    direction='column'
    height={1}
    overflow='hidden'
    paddingX={2}
    position='relative'
    spacing={2}
    width={1}
  >
    {billingModal && currentOrganisation &&
    <BillingModal
      account={currentOrganisation}
      close={() => setBillingModalOpen(false)}
      open={billingModal}
    />}
    <Backdrop
      open
      sx={{
        position: 'absolute',
        zIndex: theme.zIndex.drawer - 1,
      }}
    >
      <Stack alignItems='center' maxWidth='sm' width={1}>
        <Card>
          <Stack alignItems='center' padding={2} spacing={2} width={1}>
            <TableViewRoundedIcon
              sx={{
                color: theme.palette.success.main,
                fontSize: 64,
              }}
            />
            <Stack alignItems='center' width={1}>
              <Typography textAlign='center' variant='h6'>
                Visualize and report on your team&apos;s progress with Dashboards
              </Typography>
              <Typography textAlign='center' variant='body2'>
                Uncover valuable insights and keep your progress on track.
              </Typography>
            </Stack>
            <Button
              color='success'
              onClick={() => setBillingModalOpen(true)}
            >
              Upgrade
            </Button>
          </Stack>
        </Card>
      </Stack>
    </Backdrop>
    <Stack direction='row' spacing={2} width={1}>
      <Grid container spacing={1}>
        <Grid item lg={3} md={6} sm={6} xl={3} xs={6}>
          <Card sx={{ width: 1 }} variant='outlined'>
            <Stack direction='column' padding={2} spacing={1}>
              <Stack alignItems='center' direction='row' justifyContent='space-between'>
                <Typography variant='textLabel'>Completed tasks</Typography>
                <CheckCircleRoundedIcon color='success' fontSize='medium' />
              </Stack>
              <Typography variant='h3'>{completedTasks}</Typography>
              <Typography variant='helperText'>Number of tasks</Typography>
            </Stack>
          </Card>
        </Grid>

        <Grid item lg={3} md={6} sm={6} xl={3} xs={6}>
          <Card sx={{ width: 1 }} variant='outlined'>
            <Stack direction='column' padding={2} spacing={1}>
              <Stack alignItems='center' direction='row' justifyContent='space-between'>
                <Typography variant='textLabel'>Incomplete tasks</Typography>
                <CalendarTodayRoundedIcon color='disabled' fontSize='medium' />
              </Stack>
              <Typography variant='h3'>{incompletedTasks}</Typography>
              <Typography variant='helperText'>Number of tasks</Typography>
            </Stack>
          </Card>
        </Grid>

        <Grid item lg={3} md={6} sm={6} xl={3} xs={6}>
          <Card sx={{ width: 1 }} variant='outlined'>
            <Stack direction='column' padding={2} spacing={1}>
              <Stack alignItems='center' direction='row' justifyContent='space-between'>
                <Typography variant='textLabel' >Overdue tasks</Typography>
                <CalendarTodayRoundedIcon color='error' fontSize='medium' />
              </Stack>
              <Typography variant='h3'>{overdueTasks}</Typography>
              <Typography variant='helperText'>Number of tasks</Typography>
            </Stack>
          </Card>
        </Grid>

        <Grid item lg={3} md={6} sm={6} xl={3} xs={6}>
          <Card sx={{ width: 1 }} variant='outlined'>
            <Stack direction='column' padding={2} spacing={1} >
              <Stack alignItems='center' direction='row' justifyContent='space-between'>
                <Typography variant='textLabel'>All tasks</Typography>
                <TaskAltRoundedIcon color='primary' fontSize='medium' />
              </Stack>
              <Typography variant='h3'>{allTasks}</Typography>
              <Typography variant='helperText'>Number of tasks</Typography>
            </Stack>
          </Card>
        </Grid>

      </Grid>
    </Stack>

    <Stack direction='row' paddingBottom={2} spacing={2} width={1}>
      <Grid container spacing={1}>
        <Grid item lg={6} md={12} sm={12} xl={6} xs={12}>
          <Card ref={componentRef} sx={{ width: 1 }} variant='outlined'>
            <Stack direction='column' padding={2} spacing={2}>
              <Typography variant='subtitle2'>Tasks by status</Typography>
              <Stack alignItems='center' width={1}>
                <Chart
                  labels={labelsStatus}
                  options={optionsStatus}
                  series={seriesStatus}
                  type='donut'
                  width={width > 450 ? 450 : width}
                />
              </Stack>
            </Stack>
          </Card>
        </Grid>

        <Grid item lg={6} md={12} sm={12} xl={6} xs={12}>
          <Card sx={{ width: 1 }} variant='outlined'>
            <Stack direction='column' padding={2} spacing={2}>
              <Typography variant='subtitle2'>Tasks by priority</Typography>
              <Stack alignItems='center' width={1}>
                <Chart
                  labels={labelsPriority}
                  options={optionsPriority}
                  series={seriesPriority}
                  type='donut'
                  width={width > 450 ? 450 : width}
                />
              </Stack>
            </Stack>
          </Card>
        </Grid>

        <Grid item lg={6} md={12} sm={12} xl={6} xs={12}>
          <Card sx={{ width: 1 }} variant='outlined'>
            <Stack direction='column' padding={2} spacing={2}>
              <Typography variant='subtitle2'>Tasks per section</Typography>
              <Stack alignItems='center' width={1}>
                <Chart
                  labels={taskSectionNames}
                  options={optionsSections}
                  series={optionsSections.series}
                  type='bar'
                  width={width > 450 ? 450 : width}
                />
              </Stack>
            </Stack>
          </Card>
        </Grid>

        <Grid item lg={6} md={12} sm={12} xl={6} xs={12}>
          <Card sx={{ width: 1 }} variant='outlined'>
            <Stack direction='column' padding={2} spacing={2}>
              <Typography variant='subtitle2'>Overdue tasks per section</Typography>
              <Stack alignItems='center' width={1}>
                <Chart
                  labels={taskSectionNames}
                  options={optionsSectionsOverdue}
                  series={optionsSectionsOverdue.series}
                  type='bar'
                  width={width > 450 ? 450 : width}
                />
              </Stack>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </Stack>
  </Stack>
}

export default DashboardFree
