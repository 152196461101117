import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { Button, Input } from '@mui/material'
import { Stack } from '@mui/system'
import type { ChangeEvent } from 'react'

type Props = {
  handleAttachmentUpload: (event: ChangeEvent<HTMLInputElement>) => void
}

const AddFileUpload = (props: Props) =>
  <Stack alignItems='center' width={1}>
    <label htmlFor='upload-attachment'>
      <Button
        color='inherit'
        component='span'
        startIcon={<AddRoundedIcon />}
        sx={{
          marginTop: 2,
        }}
        variant='contained'
      >
        Upload a File
      </Button>
      <Input
        id='upload-attachment'
        onChange={props.handleAttachmentUpload}
        sx={{ display: 'none', zIndex: '100000' }}
        type='file'
      />
    </label>
  </Stack>
export default AddFileUpload
