/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import { Avatar, Badge, Stack, Typography, useTheme } from '@mui/material'

import type { Collaborator } from 'src/models/Project'

type Props = {
  collaborator: Collaborator
}

const CollaboratorItemDetails = (props: Props) => {
  const themeColors = useTheme()
  return (
    <Stack
      direction='column'
      key={props.collaborator.account.id}
      spacing={2}
      width={1}
    >
      <Stack alignItems='center' direction='row' justifyContent='space-between' spacing={1} width={1}>
        <Stack alignItems='center' direction='row' spacing={2} width={1}>
          <Badge
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            badgeContent={<CheckCircleRoundedIcon
              color='secondary'
              fontSize='small'
              sx={{
                backgroundColor: 'white',
                borderRadius: '50%',
              }}
            />}
            overlap='circular'
            sx={{
              '.MuiBadge-badge': {
                display: props.collaborator.account.verified ? undefined : 'none',
              },
            }}
          >
            <Avatar
              src={props.collaborator.account.logoUrl}
            />
          </Badge>
          <Stack direction='column' overflow='hidden' width={1}>
            <Typography
              overflow='hidden'
              textAlign='start'
              textOverflow='ellipsis'
              variant='body1'
              whiteSpace='nowrap'
            >
              {props.collaborator.account.name}
            </Typography>
            <Typography
              color={themeColors.palette.text.label}
              overflow='hidden'
              textAlign='start'
              textOverflow='ellipsis'
              variant='body2'
              whiteSpace='nowrap'
            >
              {props.collaborator.account.organisationEmail}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default CollaboratorItemDetails
