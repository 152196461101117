/* eslint-disable no-multi-str */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import AudioFileRoundedIcon from '@mui/icons-material/AudioFileRounded'
import { Card, CardActionArea, Stack, Typography, useTheme } from '@mui/material'
import { useState } from 'react'

import ExplainModal from 'src/components/pages/Analytics/ExplainModal'
import type { AnalyticsMonthlyChart } from 'src/models/Analytics'

type Props = {
  readonly data: AnalyticsMonthlyChart[]
  readonly selectedRecording: string
  readonly periods: string[]
}

const AnalyticsDownloadsLifetime = (props: Props) => {
  const themeItem = useTheme()
  const [explain, setExplain] = useState(false)
  const totalStreams = props.data.filter(item => props.periods.length === 0 || props.periods.includes(item._id))
    .flatMap(({ downloads }) => downloads).reduce((partialSum, a) => partialSum + a, 0) ??
    0
  return (
    <Stack height={1} width={1}>
      <Card
        elevation={0}
        sx={{
          height: 1,
          background: theme => theme.palette.background.elevatedCard,
        }}
      >
        <CardActionArea onClick={() => setExplain(true)}>
          <Stack height={1} justifyContent='space-between' padding={2} spacing={3}>
            <Stack direction='row' spacing={2} width={1}>
              <Stack spacing={2} width={1}>
                <Typography variant='body1'>
                  Downloads
                </Typography>
                <Typography variant='h3'>
                  {totalStreams.toLocaleString('en-US')}
                </Typography>
              </Stack>
              <Stack spacing={2}>
                <AudioFileRoundedIcon color='info' sx={{ fontSize: 48 }} />
              </Stack>
            </Stack>
            <Typography variant='textLabel'>
              {props.selectedRecording.length > 0
                ? 'Total distribution downloads since the release of this recording'
                : 'Total distribution downloads'}
            </Typography>
          </Stack>
        </CardActionArea>
      </Card>
      <ExplainModal
        close={() => setExplain(false)}
        color={themeItem.palette.info.main}
        description='Downloads refer to the number of monetized downloads on your releases on platforms such as iTunes,
        & Beatport.'
        icon={<AudioFileRoundedIcon
          sx={{
            color: theme => theme.palette.primary.contrastText,
            fontSize: '64px',
          }}
        />}
        open={explain}
        title='Downloads'
      />
    </Stack>
  )
}

export default AnalyticsDownloadsLifetime

