import type { MenuItemProps, SelectProps } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import type { OptionHTMLAttributes, ReactNode } from 'react'

type ChildrenToLabel<T> = Omit<T, 'children'> & { label?: ReactNode, key?: string, children?: undefined }

type NonNativeSelectProps<T> = {
  native?: false
  options: ChildrenToLabel<Omit<MenuItemProps, 'value'> & { value?: T }>[]
}
type NativeSelectProps = {
  native: true
  options: ChildrenToLabel<OptionHTMLAttributes<HTMLOptionElement>>[]
}
type OptionsProps<T> = NativeSelectProps | NonNativeSelectProps<T>

type IsMultiple<T> = { multiple: true, value: T[], onChange?: (newValue: T[], child?: ReactNode) => void }
type IsSingle<T> = { multiple?: false, value?: T, onChange?: (newValue: T, child?: ReactNode) => void }

type Props<T> =
  & Omit<SelectProps<T>, 'children' | 'multiple' | 'native' | 'onChange' | 'value'>
  & OptionsProps<T>
  & (IsMultiple<T> | IsSingle<T>)

export type SelectFieldOptions<T> = OptionsProps<T>['options']

const SelectField = <T extends { toString: () => string }>({ ...props }: Props<T>) => {
  let options: JSX.Element[]
  if (props.native) {
    options = props.options.map(optionProps =>
      <option
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...optionProps}
        key={optionProps.key ?? optionProps.value?.toString() ?? optionProps.label?.toString()}
      >
        {optionProps.label ?? optionProps.value?.toString() ?? optionProps.key}
      </option>)
    if (props.value === undefined) options.unshift(<option hidden key='' />)
  } else {
    options = props.options.map(optionProps =>
      <MenuItem
        {...optionProps}
        key={optionProps.key ?? optionProps.value?.toString() ?? optionProps.label?.toString()}
        value={optionProps.value as unknown as string}
      >
        {optionProps.label ?? optionProps.value?.toString() ?? optionProps.key}
      </MenuItem>)
    if (props.value === undefined) options.unshift(<MenuItem hidden key='' value='' />)
  }

  return <FormControl fullWidth={props.fullWidth} sx={{ marginTop: 2 }}>
    <InputLabel id={`${props.id}-label`} required={props.required}>{props.label}</InputLabel>
    <Select
      labelId={`${props.id}-label`}
      {...props}
      onChange={(event, child) => props.onChange?.(event.target.value as unknown as (T & T[]), child)}
      value={(props.value ?? '') as T & T[]}
    >
      {options}
    </Select>
  </FormControl>
}
export default SelectField
