/* eslint-disable react-hooks/exhaustive-deps */
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded'
import { Button, Stack, Typography } from '@mui/material'
import type { FirebaseError } from 'firebase/app'
import { applyActionCode } from 'firebase/auth'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

import Loading from 'src/components/Loading'
import { useAuth } from 'src/components/providers/AuthProvider'
import { NavLink } from 'src/components/SafeRouterLink'
import firebaseErrorMessages from 'src/data/firebaseAuthErrorCodes'
import { auth } from 'src/utils/firebase'

type Props = {
  actionCode: string
}

const VerifyEmail = (props: Props) => {
  const [errorFB, setErrorFB] = useState<string>()
  const [validCode, setValidCode] = useState(false)
  const [verifiedCode, setVerifiedCode] = useState(false)
  const navigate = useNavigate()
  const { setCurrentFirebaseUser } = useAuth()

  const applyActionCodeItem = async (code: string) => {
    if (code && code.length > 0) {
      await applyActionCode(auth, code)
        .then(async () => {
          if (auth.currentUser) {
            setCurrentFirebaseUser(old => old
              ? { ...old, emailVerified: true }
              : null)
            await auth.currentUser.reload()
              .then(() => {
                navigate('/')
              })
          }
          setValidCode(true)
          setVerifiedCode(true)
        })
        .catch((error: FirebaseError) => {
          setErrorFB(firebaseErrorMessages.find(errorCode => errorCode.code === error.code)?.message)
          setVerifiedCode(true)
        })
    }
  }

  useEffect(() => {
    void applyActionCodeItem(props.actionCode)
  }, [props.actionCode])

  if (!verifiedCode) {
    return <Loading />
  } else if (validCode) {
    return (
      <Stack alignItems='center' spacing={1}>
        <CheckCircleRoundedIcon color='success' sx={{ height: 64, width: 64 }} />
        <Typography variant='h3'>Your email has been verified</Typography>
        <Typography variant='body1'>You can now sign in with your account</Typography>
        <Button component={NavLink} to='/account/login'>Finish</Button>
      </Stack>
    )
  } else {
    return (
      <Stack alignItems='center' spacing={1}>
        <ErrorRoundedIcon color='error' sx={{ height: 64, width: 64 }} />
        <Typography variant='h3'>An error has occured</Typography>
        <Typography variant='body1'>Try verifying your email again</Typography>
        <Typography color='error' variant='body1'>{errorFB}</Typography>
        <Button component={NavLink} to='/account/login'>Finish</Button>
      </Stack>
    )
  }
}
export default VerifyEmail
