/* eslint-disable max-lines */
/* eslint-disable id-length */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded'
import { Card, Grid, Stack, Typography, useTheme } from '@mui/material'
import { useRef } from 'react'
import Chart from 'react-apexcharts'

import { useContainerDimensions } from 'src/components/ContainerDimensions'
import type { TaskGroup } from 'src/models/Taskboard'

type Props = {
  taskDataSet: TaskGroup[]
}

const DashboardView = (props: Props) => {
  const theme = useTheme()
  const componentRef = useRef<HTMLDivElement>(null)
  const { width } = useContainerDimensions(componentRef)

  const taskSectionNames = props.taskDataSet.map(taskGroup => taskGroup.title)
  const taskSectionColors = props.taskDataSet.map(taskGroup => taskGroup.color)
  const taskSectionCount = props.taskDataSet.map(taskGroup => taskGroup.tasks.length)
  const overdueTaskPerSection = props.taskDataSet.map(taskGroup =>
    taskGroup.tasks.filter(task => task.status !== 'Done' &&
    new Date(task.dueDate ?? new Date()) < new Date()).length)

  const tasksList = props.taskDataSet.flatMap(({ tasks }) => tasks)
  const allTasks = tasksList.length
  const completedTasks = tasksList.filter(task => task.status === 'Done').length
  const incompletedTasks = allTasks - completedTasks
  const overdueTasks = tasksList.filter(task => task.status !== 'Done' &&
  new Date(task.dueDate ?? new Date()) < new Date()).length

  const notStartedTasks = tasksList.filter(task => task.status === 'Not started').length
  const inProgressTasks = tasksList.filter(task => task.status === 'In progress').length
  const doneTasks = tasksList.filter(task => task.status === 'Done').length
  const notApprovedTasks = tasksList.filter(task => task.status === 'Not approved').length

  const noPriorityTasks = tasksList.filter(task => task.priority === 'None').length
  const lowPriorityTasks = tasksList.filter(task => task.priority === 'Low').length
  const mediumPriorityTasks = tasksList.filter(task => task.priority === 'Medium').length
  const highPriorityTasks = tasksList.filter(task => task.priority === 'High').length

  const seriesPriority: number[] = [noPriorityTasks, lowPriorityTasks, mediumPriorityTasks, highPriorityTasks]
  const labelsPriority = ['None', 'Low', 'Medium', 'High']
  const optionsPriority = {
    series: seriesPriority,
    labels: labelsPriority,
    dataLabels: {
      enabled: false,
    },
    colors: [theme.palette.text.label,
      theme.palette.primary.main,
      theme.palette.rating,
      theme.palette.error.main,
    ],
    plotOptions: {
      pie: {
        donut: {
          size: '55%',
          labels: {
            show: true,
            fontFamily: 'Inter',
          },
        },
      },
    },
    stroke: {
      colors: ['transparent'],
    },
    legend: {
      fontFamily: 'Inter',
      width: 150,
    },
    chart: {
      foreColor: theme.palette.text.primary,
    },
  }

  const seriesStatus: number[] = [notStartedTasks, inProgressTasks, doneTasks, notApprovedTasks]
  const labelsStatus = ['Not started', 'In progress', 'Done', 'Not approved']
  const optionsStatus = {
    series: seriesStatus,
    labels: labelsStatus,
    dataLabels: {
      enabled: false,
    },
    colors: [theme.palette.primary.main,
      theme.palette.warning.main,
      theme.palette.success.main,
      theme.palette.error.main,
    ],
    plotOptions: {
      pie: {
        donut: {
          size: '55%',
          labels: {
            show: true,
            fontFamily: 'Inter',
          },
        },
      },
    },
    stroke: {
      colors: ['transparent'],
    },
    legend: {
      fontFamily: 'Inter',
      width: 150,
    },
    tooltip: {
      theme: 'dark',
    },
    chart: {
      background: 'transparent',
      foreColor: theme.palette.text.primary,
    },
  }

  const optionsSections = {
    series: [{
      name: 'Tasks',
      data: taskSectionCount,
    }],
    labels: taskSectionNames,
    dataLabels: {
      enabled: false,
    },
    colors: taskSectionColors,
    plotOptions: {
      bar: {
        columnWidth: '25%',
        distributed: true,
        borderRadius: 8,
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      theme: 'dark',
      followCursor: true,
      x: {
        show: false,
      },
    },
    chart: {
      foreColor: theme.palette.text.primary,
      toolbar: {
        show: false,
      },
    },
  }

  const optionsSectionsOverdue = {
    series: [{
      name: 'Tasks',
      data: overdueTaskPerSection,
    }],
    labels: taskSectionNames,
    dataLabels: {
      enabled: false,
    },
    colors: taskSectionColors,
    plotOptions: {
      bar: {
        columnWidth: '25%',
        distributed: true,
        borderRadius: 8,
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      theme: 'dark',
      followCursor: true,
      x: {
        show: false,
      },
    },
    chart: {
      foreColor: theme.palette.text.primary,
      toolbar: {
        show: false,
      },
    },
  }

  return (
    <Stack direction='column' height={1} paddingX={2} spacing={2} width={1}>
      <Stack width={1}>
        <Grid container spacing={1}>
          <Grid item lg={3} md={6} sm={6} xl={3} xs={6}>
            <Card sx={{ width: 1 }} variant='outlined'>
              <Stack direction='column' padding={2} spacing={1}>
                <Stack alignItems='center' direction='row' justifyContent='space-between'>
                  <Typography
                    color={theme.palette.text.label}
                    variant='body1'
                  >
                    Completed tasks
                  </Typography>
                  <CheckCircleRoundedIcon color='success' fontSize='medium' />
                </Stack>
                <Typography variant='h3'>
                  {completedTasks}
                </Typography>
                <Typography variant='body2'>
                  Number of tasks
                </Typography>
              </Stack>
            </Card>
          </Grid>

          <Grid item lg={3} md={6} sm={6} xl={3} xs={6}>
            <Card sx={{ width: 1 }} variant='outlined'>
              <Stack direction='column' padding={2} spacing={1}>
                <Stack alignItems='center' direction='row' justifyContent='space-between'>
                  <Typography
                    color={theme.palette.text.label}
                    variant='body1'
                  >
                    Incomplete tasks
                  </Typography>
                  <CalendarTodayRoundedIcon color='disabled' fontSize='medium' />
                </Stack>
                <Typography variant='h3'>{incompletedTasks}</Typography>
                <Typography variant='body2'>
                  Number of tasks
                </Typography>
              </Stack>
            </Card>
          </Grid>

          <Grid item lg={3} md={6} sm={6} xl={3} xs={6}>
            <Card sx={{ width: 1 }} variant='outlined'>
              <Stack direction='column' padding={2} spacing={1}>
                <Stack alignItems='center' direction='row' justifyContent='space-between'>
                  <Typography
                    color={theme.palette.text.label}
                    variant='body1'
                  >
                    Overdue tasks
                  </Typography>
                  <CalendarTodayRoundedIcon color='error' fontSize='medium' />
                </Stack>
                <Typography variant='h3'>{overdueTasks}</Typography>
                <Typography variant='body2'>
                  Number of tasks
                </Typography>
              </Stack>
            </Card>
          </Grid>

          <Grid item lg={3} md={6} sm={6} xl={3} xs={6}>
            <Card sx={{ width: 1 }} variant='outlined'>
              <Stack direction='column' padding={2} spacing={1} >
                <Stack alignItems='center' direction='row' justifyContent='space-between'>
                  <Typography
                    color={theme.palette.text.label}
                    variant='body1'
                  >
                    All tasks
                  </Typography>
                  <TaskAltRoundedIcon color='primary' fontSize='medium' />
                </Stack>
                <Typography variant='h3'>{allTasks}</Typography>
                <Typography variant='body2'>
                  Number of tasks
                </Typography>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Stack>

      <Stack paddingBottom={2} width={1}>
        <Grid container spacing={1}>
          <Grid item lg={6} md={12} sm={12} xl={6} xs={12}>
            <Card ref={componentRef} sx={{ width: 1 }} variant='outlined'>
              <Stack direction='column' padding={2} spacing={2}>
                <Typography variant='subtitle2'>
                  Tasks by status
                </Typography>
                <Stack alignItems='center' width={1}>
                  <Chart
                    labels={labelsStatus}
                    options={optionsStatus}
                    series={seriesStatus}
                    type='donut'
                    width={width > 450 ? 450 : width}
                  />
                </Stack>
              </Stack>
            </Card>
          </Grid>

          <Grid item lg={6} md={12} sm={12} xl={6} xs={12}>
            <Card sx={{ width: 1 }} variant='outlined'>
              <Stack direction='column' padding={2} spacing={2}>
                <Typography variant='subtitle2'>
                  Tasks by priority
                </Typography>
                <Stack alignItems='center' width={1}>
                  <Chart
                    labels={labelsPriority}
                    options={optionsPriority}
                    series={seriesPriority}
                    type='donut'
                    width={width > 450 ? 450 : width}
                  />
                </Stack>
              </Stack>
            </Card>
          </Grid>

          <Grid item lg={6} md={12} sm={12} xl={6} xs={12}>
            <Card sx={{ width: 1 }} variant='outlined'>
              <Stack direction='column' padding={2} spacing={2}>
                <Typography variant='subtitle2'>
                  Tasks per section
                </Typography>
                <Stack alignItems='center' width={1}>
                  <Chart
                    labels={taskSectionNames}
                    options={optionsSections}
                    series={optionsSections.series}
                    type='bar'
                    width={width > 450 ? 450 : width}
                  />
                </Stack>
              </Stack>
            </Card>
          </Grid>

          <Grid item lg={6} md={12} sm={12} xl={6} xs={12}>
            <Card sx={{ width: 1 }} variant='outlined'>
              <Stack direction='column' padding={2} spacing={2}>
                <Typography variant='subtitle2'>
                  Overdue tasks per section
                </Typography>
                <Stack alignItems='center' width={1}>
                  <Chart
                    labels={taskSectionNames}
                    options={optionsSectionsOverdue}
                    series={optionsSectionsOverdue.series}
                    type='bar'
                    width={width > 450 ? 450 : width}
                  />
                </Stack>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  )
}

export default DashboardView
