/* eslint-disable max-len */
import type { SvgIconProps } from '@mui/material'
import { SvgIcon } from '@mui/material'

const CapcutLogo = (props: SvgIconProps) =>
  <SvgIcon {...props} viewBox='0 0 2000 2000' >
    <path d='M1637.02 716.41c-.06-36.54.2-73.05-.13-109.56-.15-5.74-7.52-9.35-12.43-6.64-64.95 32.17-129.93 64.38-194.85 96.6-4.9-21.01-12.85-41.48-25.38-59.13-26.83-39.33-71.87-65.59-119.39-69.08-221.05-.03-442.14-.03-663.19 0-75.67 1.5-143.97 65.31-150.53 140.73-.44 35.33-.22 70.65-.19 105.98-.77 8.52 5.73 15.7 12.81 19.47C594.97 890.06 706.4 945 817.59 1000.37c-110.37 54.22-220.35 109.14-330.58 163.62-7.72 3.46-16.02 10.09-15.99 19.31-.22 35.39-.29 70.78.07 106.17 5.44 70.36 64.56 131.92 134.49 140.86 13.89 1.28 27.86 1.06 41.79 1.09h618.13c14.86-.23 30.01.28 44.49-3.66 43.72-10.43 82.24-40.32 103.48-79.91 8.09-13.84 11.66-29.62 16.97-44.48 63.48 33.05 128.27 63.76 192.23 95.91 5.67 4.32 14.93.09 14.35-7.3 0-36.57.07-73.15-.02-109.73-.16-7.43-5.87-13.32-12.43-15.98-178.94-88.65-357.82-177.4-536.79-266.02 179.3-88.97 358.68-177.75 537.96-266.69 6.7-2.85 11.76-9.74 11.27-17.17zM1312.3 1245.5c-.8 15.54 2.98 32.54-5.19 46.7-6.85 12.46-20.88 20.3-35.03 20.11-213.63.06-427.28 0-640.9.03-15.06.54-30.14-7.85-36.87-21.43-7.07-13.45-3.87-29.21-4.45-43.75 120.65-60.17 241.56-119.81 362.32-179.74 120.27 58.58 240.08 118.8 360.12 178.08zm-.07-490.5c-119.78 59.31-239.57 118.62-359.31 178.1-121.2-59.57-242.07-119.85-363.1-179.8.62-14.38-2.46-29.81 4.17-43.21 6.63-13.87 21.81-22.6 37.09-22.03 213.55.03 427.14-.03 640.74.03 19.53-.55 37.92 15.15 40.1 34.62.83 10.73.42 21.52.31 32.28z' />
  </SvgIcon>

export default CapcutLogo
