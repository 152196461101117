/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import axios from 'axios'

import { SongStatsLabels } from 'src/models/Analytics'
import type { ArtistToUpdate, DistributionDto, SingleWriter } from 'src/models/Distribution'
import { ArtistObject, Distribution } from 'src/models/Distribution'
import type { RecordingDto } from 'src/models/Recording'
import { CoverItem } from 'src/models/Recording'
import Recording from 'src/models/Recording'
import { toFormData } from 'src/utils/objectUtils'

const PATH = '/distribution' as const

// leaving this ownerId undefined returns all releases belonging to current user
export const getDistribution = () =>
  axios.get<DistributionDto[]>(`${PATH}`)
    .then(response => response.data.map(dto => new Distribution(dto)))

export const getDistributionByProjectId = (project_Id: string) =>
  axios.get<DistributionDto>(`${PATH}/project/${project_Id}`)
    .then(response => new Distribution(response.data))

export const getPublishingList = () =>
  axios.get<SingleWriter[]>(`${PATH}/publishing`)
    .then(response => response.data.map(dto => dto))

export const createDistribution = (distribution: Partial<Distribution>) =>
  axios.post<string>(`${PATH}`, distribution)
    .then(response => response.data ?? '')

export const getArtists = (query: string) =>
  axios.put<ArtistObject[]>(`${PATH}/artist`, { _id: query })
    .then(response => response.data.map(dto => new ArtistObject(dto)))

export const getLabels = (query: string) =>
  axios.put<SongStatsLabels[]>(`${PATH}/label/${query}`)
    .then(response => response.data.map(dto => new SongStatsLabels(dto)))

export const getSong = (query: string) =>
  axios.put<CoverItem[]>(`${PATH}/song`, { _id: query })
    .then(response => response.data.map(dto => new CoverItem(dto)))

export const takedownDistribution = (id: string) =>
  axios.delete<DistributionDto>(`${PATH}/${id}`)
    .then(response => new Distribution(response.data))

export const getDistributionISRC = (isrc: string, projectId: string) =>
  axios.get<RecordingDto>(`${PATH}/ISRC/${isrc}/${projectId}`)
    .then(response => new Recording(response.data))

export const getDistributedRecordings = () =>
  axios.get<RecordingDto[]>(`${PATH}/recordings`)
    .then(response => response.data.map(dto => new Recording(dto)))

export const applyForBeatport = (id: string,
  beatportName: string,
  beatportGenre: string,
  schedule: string,
  releases: string,
  social: string,
  artists: string) =>
  axios.put<boolean>(`${PATH}/beatport/${id}`, {
    beatportName,
    beatportGenre,
    schedule,
    releases,
    social,
    artists,
  })
    .then(response => response.data)

export const supportRequestGeneric = (id: string, request: string, text: string) =>
  axios.patch<boolean>(`${PATH}/generic-support/${id}`, { request, text })
    .then(response => response.data)

export const supportRequestArtistMapping = (id: string, artists: ArtistToUpdate[]) =>
  axios.patch<boolean>(`${PATH}/artist-mapping/${id}`, { artists })
    .then(response => response.data)

export const updateMetadata = (distribution: Partial<Distribution>) =>
  axios.patch<boolean>(`${PATH}/update-metadata`, toFormData(distribution))
    .then(response => response.data)
