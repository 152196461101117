import type { Components } from '@mui/material'

import SPACING from 'src/styles/spacing'

const MuiButton: Components['MuiButton'] = {
  defaultProps: {
    variant: 'contained',
    disableElevation: true,
  },
  styleOverrides: {
    root: {
      borderRadius: SPACING.BORDER_RADIUS,
      textTransform: 'unset',
      lineHeight: 'unset',
      letterSpacing: 'inherit',
      '&[rounded="true"]': {
        borderRadius: 100,
      },
      '.MuiButton-text': {
        borderRadius: 0,
      },
    },
    startIcon: {
      // HACK: workaround for the first child of startIcon being set to specific size
      '> :nth-of-type(1):not(.MuiSvgIcon-root)': {
        display: 'none',
      },
    },
    sizeSmall: {
      height: '32px',
      padding: `12px ${SPACING.addPixels(SPACING.INPUT_SIDE_PADDING, -SPACING.INPUT_BORDER_WIDTH)}`,
      borderRadius: '8px',
      fontSize: '0.825rem',
      lineHeight: '18px',
      fontWeight: 400,
    },
    sizeMedium: {
      height: '36px',
      padding: `${SPACING.themeSpacing(2)} ${SPACING.INPUT_SIDE_PADDING}`,
      borderRadius: '6px',
      fontSize: '0.825rem',
      lineHeight: '20px',
      fontWeight: 400,
    },
    sizeLarge: {
      height: '40px',
      padding: `${SPACING.INPUT_VERT_PADDING_LARGE} ${SPACING.INPUT_SIDE_PADDING_LARGE}`,
      borderRadius: '6px',
      fontSize: '0.825rem',
      lineHeight: '24px',
      letterSpacing: '0.15 px',
      fontWeight: 400,
    },
  },
  variants: [
    {
      props: { size: 'huge' },
      style: {
        height: '72px',
        padding: `${SPACING.INPUT_VERT_PADDING_LARGE} ${SPACING.INPUT_SIDE_PADDING_LARGE}`,
        borderRadius: '8px',
      },
    },
    {
      props: { rounded: true },
      style: {
        borderRadius: 100,
      },
    },
  ],
}

export default MuiButton
