/* eslint-disable sonarjs/max-switch-cases */
/* eslint-disable complexity */
import { useTheme } from '@mui/material'

export function GetColorLink(LinkName: string) {
  const theme = useTheme()
  switch (true) {
    case LinkName.toLowerCase().includes('spotify'):
      return theme.palette.spotify
    case LinkName.toLowerCase().includes('allsaints'):
      return '#e62318'
    case LinkName.toLowerCase().includes('apple music'):
      return theme.palette.appleMusic
    case LinkName.toLowerCase().includes('apple_music'):
      return theme.palette.appleMusic
    case LinkName.toLowerCase().includes('itunes & apple music'):
      return theme.palette.appleMusic
    case LinkName.toLowerCase().includes('deezer'):
      return theme.palette.deezer
    case LinkName.toLowerCase().includes('amazon music'):
      return theme.palette.amazon
    case LinkName.toLowerCase().includes('amazon'):
      return theme.palette.amazon
    case LinkName.toLowerCase().includes('soundcloud'):
      return theme.palette.soundcloud
    case LinkName.toLowerCase().includes('tiktok'):
      return theme.palette.tiktok
    case LinkName.toLowerCase().includes('youtube'):
      return theme.palette.youtube
    case LinkName.toLowerCase().includes('itunes'):
      return '#EA4CC0'
    case LinkName.toLowerCase().includes('tidal'):
      return '#000000'
    case LinkName.toLowerCase().includes('pandora'):
      return '#00A0EE'
    case LinkName.toLowerCase().includes('audiomack'):
      return '#ffa200'
    case LinkName.toLowerCase().includes('iheartradio'):
      return '#C6002B'
    case LinkName.toLowerCase().includes('vevo'):
      return '#174BEB'
    case LinkName.toLowerCase().includes('joox'):
      return '#00DE75'
    case LinkName.toLowerCase().includes('napster'):
      return '#2ca6de'
    case LinkName.toLowerCase().includes('youtube music'):
      return theme.palette.youtube
    case LinkName.toLowerCase().includes('beatport'):
      return '#01FF95'
    case LinkName.toLowerCase().includes('bandcamp'):
      return '#629aa9'
    case LinkName.toLowerCase().includes('anghami'):
      return '#39baf8'
    case LinkName.toLowerCase().includes('kanjian'):
      return '#0040ff'
    case LinkName.toLowerCase().includes('melon'):
      return '#00d344'
    case LinkName.toLowerCase().includes('claro-musica'):
      return '#ED1C24'
    case LinkName.toLowerCase().includes('netease'):
      return '#C20C0C'
    case LinkName.toLowerCase().includes('kkbox'):
      return '#09CEF6'
    case LinkName.toLowerCase().includes('saavn'):
      return '#1E1F1F'
    case LinkName.toLowerCase().includes('facebook'):
      return theme.palette.facebook
    case LinkName.toLowerCase().includes('youtube content id'):
      return theme.palette.youtube
    case LinkName.toLowerCase().includes('7digital'):
      return '#048da3'
    case LinkName.toLowerCase().includes('bugs!'):
      return '#FF3D33'
    case LinkName.toLowerCase().includes('tencent'):
      return '#1772F9'
    case LinkName.toLowerCase().includes('qobuz'):
      return '#1C8EBC'
    case LinkName.toLowerCase().includes('naver'):
      return '#19ce60'
    case LinkName.toLowerCase().includes('shazam'):
      return '#0088ff'
    case LinkName.toLowerCase().includes('instagram'):
      return '#8a3ab9'
    case LinkName.toLowerCase().includes('nuuday'):
      return '#5bfe60'
    case LinkName.toLowerCase().includes('emusic'):
      return '#ef3e28'
    case LinkName.toLowerCase().includes('awa'):
      return '#fc7459'
    case LinkName.toLowerCase().includes('flo'):
      return '#4962f7'
    case LinkName.toLowerCase().includes('kuack media'):
      return '#f7aa2f'
    case LinkName.toLowerCase().includes('gracenote'):
      return '#f40a53'
    case LinkName.toLowerCase().includes('songkick'):
      return '#f80046'
    case LinkName.toLowerCase().includes('ticketmaster'):
      return '#008CFF'
    case LinkName.toLowerCase().includes('twitter'):
      return '#000000'
    case LinkName.toLowerCase().includes('snapchat'):
      return '#FFFC00'
    case LinkName.toLowerCase().includes('vimeo'):
      return '#86c9ef'
    case LinkName.toLowerCase().includes('daily_motion'):
      return '#00aaff'
    case LinkName.toLowerCase().includes('threads'):
      return '#000000'
    case LinkName.toLowerCase().includes('opensea'):
      return '#2081E2'
    case LinkName.toLowerCase().includes('latest_release'):
      return theme.palette.primary.main
    case LinkName.toLowerCase().includes('next_release'):
      return theme.palette.success.main
    case LinkName.toLowerCase().includes('image'):
      return theme.palette.warning.main
    case LinkName.toLowerCase().includes('text'):
      return theme.palette.action.active
    case LinkName.toLowerCase().includes('header'):
      return theme.palette.action.active
    case LinkName.toLowerCase().includes('chat'):
      return theme.palette.action.active
    case LinkName.toLowerCase().includes('left_divider'):
      return theme.palette.action.active
    case LinkName.toLowerCase().includes('card_block'):
      return theme.palette.secondary.main
    case LinkName.toLowerCase().includes('link'):
      return theme.palette.secondary.main
    case LinkName.toLowerCase().includes('video_embed'):
      return theme.palette.error.main
    case LinkName.toLowerCase().includes('bandsintown'):
      return '#00CEC8'
    case LinkName.toLowerCase().includes('seated'):
      return '#A4DDE0'
    case LinkName.toLowerCase().includes('capcut'):
      return '#000000'
    case LinkName.toLowerCase().includes('line'):
      return '#0ee071'
    case LinkName.toLowerCase().includes('boomplay'):
      return '#00FFFF'
    case LinkName.toLowerCase().includes('zing'):
      return '#9b4de0'
    case LinkName.toLowerCase().includes('twitch'):
      return '#6441a5'
    case LinkName.toLowerCase().includes('hungama'):
      return '#0f7ef3'
    case LinkName.toLowerCase().includes('genie'):
      return '#0096FF'
    case LinkName.toLowerCase().includes('download'):
      return '#000000'
    case LinkName.toLowerCase().includes('website'):
      return '#000000'
    default:
      return theme.palette.primary.main
  }
}
