import { Button, Chip, Pagination, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography, useMediaQuery, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

import { getInvoices } from 'src/api/payment'
import type { Invoice } from 'src/models/Invoice'
import { statusColor } from 'src/models/Invoice'

const PaymentHistory = () => {
  const themeItem = useTheme()
  const matches = useMediaQuery(themeItem.breakpoints.down('md'))
  const [page, setPage] = useState(1)
  const [invoices, setInvoices] = useState<Invoice[]>([])

  const currentPageData = invoices.slice(((page * 100) / 10) - 10, (page * 100) / 10)

  const updateCurrentDisplayData = (currentPage: number) => {
    setPage(currentPage)
  }

  useEffect(() => {
    void getInvoices('current')
      .then(invoiceResponse => invoiceResponse ? setInvoices(invoiceResponse) : null)
  }, [])

  return (
    <Stack height={1} paddingBottom={2} paddingTop={4} width={1} >
      <Stack direction='row' justifyContent='space-between' paddingLeft={4} >
        <Typography variant='h3'>Invoices</Typography>
      </Stack>
      <Stack height={1} overflow='auto' width={1}>
        <Stack height={1} paddingLeft={4} paddingRight={4} width={1}>
          <Table aria-label='invoices table'>
            <TableHead>
              <TableRow
                sx={{
                  '.MuiTableCell-root': { borderBottomColor: 'divider' } }}
              >
                <TableCell>Date</TableCell>
                <TableCell>Total</TableCell>
                {!matches && <>
                  <TableCell>Subtotal</TableCell>
                  <TableCell>Tax</TableCell>
                  <TableCell>Status</TableCell>
                </>}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {currentPageData.map(invoice =>
                <TableRow
                  key={invoice.created.toString()}
                  sx={{
                    '.MuiTableCell-root': { borderBottomColor: 'divider' } }}
                >
                  <TableCell>
                    {dayjs(invoice.created).format('ll')}
                  </TableCell>
                  <TableCell>
                    {`$ ${invoice.total < 0 ? '0.00' : invoice.total.toFixed(2)}`}
                  </TableCell>
                  {!matches && <>
                    <TableCell>
                      {`$ ${invoice.subtotal < 0 ? '0.00' : invoice.subtotal.toFixed(2)}`}
                    </TableCell>
                    <TableCell>
                      {`$ ${invoice.tax < 0 ? '0.00' : invoice.tax.toFixed(2)}`}
                    </TableCell>
                    <TableCell>
                      <Chip
                        color={statusColor[invoice.status]}
                        label={invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1)}
                        size='small'
                        variant='filled'
                      />
                    </TableCell>
                  </>}
                  <TableCell>
                    <Button
                      href={invoice.hosted_invoice_url}
                      rounded
                      size='small'
                      target='stripe'
                      variant='outlined'
                    >
                      Download
                    </Button>
                  </TableCell>

                </TableRow>)}
            </TableBody>
          </Table>
          <Stack>
            {
              invoices.length > 10 &&
              <Pagination
                count={invoices.length > 0 ? Math.ceil(invoices.length / 10) : 1}
                onChange={(event, value: number) => {
                  updateCurrentDisplayData(value)
                }}
                page={page}
                sx={{ marginTop: 3, marginBottom: 4, marginLeft: 'auto' }}
              />
            }
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default PaymentHistory
