/* eslint-disable max-lines */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable complexity */
import { Grid, Stack } from '@mui/material'

import NumberComponentAnalytics from 'src/components/pages/Audience/Components/Overview/NumberComponent'
import type { AnalyticsCurrentData } from 'src/models/Analytics'

type Props = {
  currentTab: string
  currentData?: AnalyticsCurrentData
  artworkUrl: string
  activeSelection: string
}

const current = 'Current'
const total = 'Total'

const RenderAnalyticsCards = (props: Props) =>
  <Stack width={1}>
    {props.currentData?.stats?.map(item =>
      item.source === props.currentTab &&
      <Stack direction='row' spacing={1} width={1}>
        <Grid
          container
          spacing={1}
          sx={{
            marginLeft: '-8px!important',
            width: 'calc(100% + 8px)!important',
          }}
        >
          {item.data.charted_cities_total !== null &&
          item.data.charted_cities_total !== undefined &&
          props.activeSelection === total &&
          <NumberComponentAnalytics
            artworkUrl={props.artworkUrl}
            currentTab={props.currentTab}
            number={item.data.charted_cities_total}
            secondaryTitle='Total cities'
            title='Charts'
            titleSocial='city charts'
          />}
          {item.data.charted_countries_total !== null &&
          item.data.charted_countries_total !== undefined &&
          props.activeSelection === total &&
          <NumberComponentAnalytics
            artworkUrl={props.artworkUrl}
            currentTab={props.currentTab}
            number={item.data.charted_countries_total}
            secondaryTitle='Total countries'
            title='Charts'
            titleSocial='country charts'
          />}
          {item.data.charts_current !== null &&
          item.data.charts_current !== undefined &&
          props.activeSelection === current &&
          <NumberComponentAnalytics
            artworkUrl={props.artworkUrl}
            currentTab={props.currentTab}
            number={item.data.charts_current}
            secondaryTitle='Current'
            title='Charts'
            titleSocial='charts'
          />}
          {item.data.charts_total !== null &&
          item.data.charts_total !== undefined &&
          props.activeSelection === total &&
          <NumberComponentAnalytics
            artworkUrl={props.artworkUrl}
            currentTab={props.currentTab}
            number={item.data.charts_total}
            secondaryTitle='Total'
            title='Charts'
            titleSocial='charts'
          />}
          {item.data.comments_total !== null &&
          item.data.comments_total !== undefined &&
          props.activeSelection === total &&
          <NumberComponentAnalytics
            artworkUrl={props.artworkUrl}
            currentTab={props.currentTab}
            number={item.data.comments_total}
            secondaryTitle='Total'
            title='Comments'
            titleSocial='comments'
          />}
          {item.data.favorites_total !== null &&
          item.data.favorites_total !== undefined &&
          props.activeSelection === total &&
          <NumberComponentAnalytics
            artworkUrl={props.artworkUrl}
            currentTab={props.currentTab}
            number={item.data.favorites_total}
            secondaryTitle='Total'
            title='Favorites'
            titleSocial='favorites'
          />}
          {item.data.likes_total !== null &&
          item.data.likes_total !== undefined &&
          props.activeSelection === total &&
          <NumberComponentAnalytics
            artworkUrl={props.artworkUrl}
            currentTab={props.currentTab}
            number={item.data.likes_total}
            secondaryTitle='Total'
            title='Likes'
            titleSocial='likes'
          />}
          {item.data.playlist_reach_current !== null &&
          item.data.playlist_reach_current !== undefined &&
          props.activeSelection === current &&
          <NumberComponentAnalytics
            artworkUrl={props.artworkUrl}
            currentTab={props.currentTab}
            number={item.data.playlist_reach_current}
            secondaryTitle='Current reach'
            title='Playlists'
            titleSocial='playlist followers'
          />}
          {item.data.playlist_reach_total !== null &&
          item.data.playlist_reach_total !== undefined &&
          props.activeSelection === total &&
          <NumberComponentAnalytics
            artworkUrl={props.artworkUrl}
            currentTab={props.currentTab}
            number={item.data.playlist_reach_total}
            secondaryTitle='Total reach'
            title='Playlist'
            titleSocial='playlist followers'
          />}
          {item.data.playlists_current !== null &&
          item.data.playlists_current !== undefined &&
          props.activeSelection === current &&
          <NumberComponentAnalytics
            artworkUrl={props.artworkUrl}
            currentTab={props.currentTab}
            number={item.data.playlists_current}
            secondaryTitle='Current number'
            title='Playlists'
            titleSocial='playlist placements'
          />}
          {item.data.playlists_total !== null &&
          item.data.playlists_total !== undefined &&
          props.activeSelection === total &&
          <NumberComponentAnalytics
            artworkUrl={props.artworkUrl}
            currentTab={props.currentTab}
            number={item.data.playlists_total}
            secondaryTitle='Total number'
            title='Playlists'
            titleSocial='playlist placements'
          />}
          {item.data.popularity_current !== null &&
          item.data.popularity_current !== undefined &&
          props.activeSelection === current &&
          <NumberComponentAnalytics
            artworkUrl={props.artworkUrl}
            currentTab={props.currentTab}
            number={item.data.popularity_current}
            secondaryTitle='Current'
            title='Popularity'
            titleSocial='popularity'
          />}
          {item.data.shares_total !== null &&
          item.data.shares_total !== undefined &&
          props.activeSelection === total &&
          <NumberComponentAnalytics
            artworkUrl={props.artworkUrl}
            currentTab={props.currentTab}
            number={item.data.shares_total}
            secondaryTitle='Total'
            title='Shares'
            titleSocial='shares'
          />}
          {item.data.shazams_total !== null &&
          item.data.shazams_total !== undefined &&
          props.activeSelection === total &&
          <NumberComponentAnalytics
            artworkUrl={props.artworkUrl}
            currentTab={props.currentTab}
            number={item.data.shazams_total}
            secondaryTitle='Total'
            title='Shazams'
            titleSocial='shazams'
          />}
          {item.data.short_comments_total !== null &&
          item.data.short_comments_total !== undefined &&
          props.activeSelection === total &&
          <NumberComponentAnalytics
            artworkUrl={props.artworkUrl}
            currentTab={props.currentTab}
            number={item.data.short_comments_total}
            secondaryTitle='Total'
            title='Shorts comments'
            titleSocial='comments'
          />}
          {item.data.short_likes_total !== null &&
          item.data.short_likes_total !== undefined &&
          props.activeSelection === total &&
          <NumberComponentAnalytics
            artworkUrl={props.artworkUrl}
            currentTab={props.currentTab}
            number={item.data.short_likes_total}
            secondaryTitle='Total'
            title='Shorts likes'
            titleSocial='likes'
          />}
          {item.data.short_views_total !== null &&
          item.data.short_views_total !== undefined &&
          props.activeSelection === total &&
          <NumberComponentAnalytics
            artworkUrl={props.artworkUrl}
            currentTab={props.currentTab}
            number={item.data.short_views_total}
            secondaryTitle='Total'
            title='Shorts views'
            titleSocial='views'
          />}
          {item.data.shorts_total !== null &&
          item.data.shorts_total !== undefined &&
          props.activeSelection === total &&
          <NumberComponentAnalytics
            artworkUrl={props.artworkUrl}
            currentTab={props.currentTab}
            number={item.data.shorts_total}
            secondaryTitle='Total'
            title='Shorts'
            titleSocial='video'
          />}
          {item.data.streams_total !== null &&
          item.data.streams_total !== undefined &&
          props.activeSelection === total &&
          <NumberComponentAnalytics
            artworkUrl={props.artworkUrl}
            currentTab={props.currentTab}
            number={item.data.streams_total}
            secondaryTitle='Total'
            title='Streams'
            titleSocial='streams'
          />}
          {item.data.video_comments_total !== null &&
          item.data.video_comments_total !== undefined &&
          props.activeSelection === total &&
          <NumberComponentAnalytics
            artworkUrl={props.artworkUrl}
            currentTab={props.currentTab}
            number={item.data.video_comments_total}
            secondaryTitle='Total'
            title='Video comments'
            titleSocial='comments'
          />}
          {item.data.video_likes_total !== null &&
          item.data.video_likes_total !== undefined &&
          props.activeSelection === total &&
          <NumberComponentAnalytics
            artworkUrl={props.artworkUrl}
            currentTab={props.currentTab}
            number={item.data.video_likes_total}
            secondaryTitle='Total'
            title='Video likes'
            titleSocial='likes'
          />}
          {item.data.video_views_total !== null &&
          item.data.video_views_total !== undefined &&
          props.activeSelection === total &&
          <NumberComponentAnalytics
            artworkUrl={props.artworkUrl}
            currentTab={props.currentTab}
            number={item.data.video_views_total}
            secondaryTitle='Total'
            title='Video views'
            titleSocial='views'
          />}
          {item.data.videos_total !== null &&
          item.data.videos_total !== undefined &&
          props.activeSelection === total &&
          <NumberComponentAnalytics
            artworkUrl={props.artworkUrl}
            currentTab={props.currentTab}
            number={item.data.videos_total}
            secondaryTitle='Total'
            title='Videos'
            titleSocial='videos'
          />}
          {item.data.views_total !== null &&
          item.data.views_total !== undefined &&
          props.activeSelection === total &&
          <NumberComponentAnalytics
            artworkUrl={props.artworkUrl}
            currentTab={props.currentTab}
            number={item.data.views_total}
            secondaryTitle='Total'
            title='Views'
            titleSocial='views'
          />}
          {item.data.charted_tracks_current !== null &&
          item.data.charted_tracks_current !== undefined &&
          props.activeSelection === current &&
          <NumberComponentAnalytics
            artworkUrl={props.artworkUrl}
            currentTab={props.currentTab}
            number={item.data.charted_tracks_current}
            secondaryTitle='Current songs'
            title='Charts'
            titleSocial='charting songs'
          />}
          {item.data.charted_tracks_total !== null &&
          item.data.charted_tracks_total !== undefined &&
          props.activeSelection === total &&
          <NumberComponentAnalytics
            artworkUrl={props.artworkUrl}
            currentTab={props.currentTab}
            number={item.data.charted_tracks_total}
            secondaryTitle='Total songs'
            title='Charts'
            titleSocial='charting songs'
          />}
          {item.data.monthly_listeners_current !== null &&
          item.data.monthly_listeners_current !== undefined &&
          props.activeSelection === current &&
          <NumberComponentAnalytics
            artworkUrl={props.artworkUrl}
            currentTab={props.currentTab}
            number={item.data.monthly_listeners_current}
            secondaryTitle='Current'
            title='Monthly listeners'
            titleSocial='monthly listeners'
          />}
          {item.data.followers_total !== null &&
          item.data.followers_total !== undefined &&
          props.activeSelection === current &&
          <NumberComponentAnalytics
            artworkUrl={props.artworkUrl}
            currentTab={props.currentTab}
            number={item.data.followers_total}
            secondaryTitle='Current'
            title='Followers'
            titleSocial='followers'
          />}

        </Grid>
      </Stack>)}
  </Stack>

export default RenderAnalyticsCards
