/* eslint-disable @typescript-eslint/no-explicit-any */

import type { Serialized } from 'src/types/react-app-env'
export class Notes {
  name = ''
  members = []
  projectId?: string
  content?: any[]
  bannerUrl = ''

  constructor(dto: NotesDto) {
    Object.assign(this, dto)
    this.content = dto.content
  }
}

export class NotesComment {
  id = ''
  value?: any
  createdAt = 0
  userId = ''
  parentId?: string
  isResolved?: boolean
  documentNoteId = ''
  uuid = ''

  constructor(dto: NotesCommentDto) {
    Object.assign(this, dto)
    this.parentId = dto.parentId
  }
}

export type NotesCommentDto = Partial<Serialized<NotesComment>>

export type NotesDto = Partial<Serialized<Notes>> & { _id?: string }

