/* eslint-disable no-duplicate-imports */
/* eslint-disable no-restricted-imports */
/* eslint-disable react-hooks/exhaustive-deps */
import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded'
import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded'
import { TabContext, TabList } from '@mui/lab'
import { Stack, Tab } from '@mui/material'
import { useState } from 'react'

import LinkAnalyticsProject from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/MarketingTab/LinkAnalyticsProject'
import ViewProjectMarketingLinks from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/MarketingTab/LinksProject'
import type Project from 'src/models/Project'

type Props = {
  project?: Project
}

const ViewProjectMarketing = (props: Props) => {
  const [currentTab, setCurrentTab] = useState('links')

  return (
    <Stack height={1} width={1}>
      <Stack height={1} width={1}>
        <TabContext value={currentTab}>
          <Stack direction='row' sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              aria-label='Current projects tab'
              onChange={(event, value: string) => setCurrentTab(value)}
            >
              <Tab
                icon={<DevicesRoundedIcon />}
                label='Smart Links'
                value='links'
              />
              <Tab
                icon={<AnalyticsRoundedIcon />}
                label='Analytics'
                value='analytics'
              />
            </TabList>
          </Stack>
          <Stack height={1} overflow='auto' width={1}>
            {(() => {
              switch (currentTab) {
                case 'links':
                  return <ViewProjectMarketingLinks project={props.project} />
                case 'analytics':
                  return <LinkAnalyticsProject projectId={props.project?.id ?? ''} />
                  // eslint-disable-next-line sonarjs/no-duplicated-branches
                default:
                  return <ViewProjectMarketingLinks project={props.project} />
              }
            })()}
          </Stack>
        </TabContext>
      </Stack>
    </Stack>
  )
}

export default ViewProjectMarketing
