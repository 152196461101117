/* eslint-disable react-hooks/exhaustive-deps */
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded'
import { Avatar, IconButton, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Skeleton, Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { useParams } from 'react-router-dom'

import { getEmbeddedItem, getReleeseDocument, getTaskboard } from 'src/api/taskboards'
import DeleteItemdModal from 'src/components/pages/Task/TaskMenu/TaskMenuComponents/DeleteItemModal'
import { GetIconTaskSmall } from 'src/components/pages/Task/TaskViews/TaskViewComponents/GetIconTask'
import { taskboardIcons } from 'src/components/pages/Task/TaskViews/TaskViewComponents/TaskboardIcons'
import { NavLink } from 'src/components/SafeRouterLink'
import type { Notes } from 'src/models/Notes'
import type { EmbedItemWorkspace, TaskBoard } from 'src/models/Taskboard'

type Props = {
  index: number
  board: string
  searchQuery: string
  refreshBoards: () => void
}

export const TaskBoardListItem = (props: Props) => {
  const { taskboardId } = useParams<{ taskboardId?: string }>()
  const [loading, setLoading] = useState(true)
  const [taskboard, setTaskboard] = useState<TaskBoard>()
  const [document, setDocument] = useState<Notes>()
  const [embedItem, setEmbedItem] = useState<EmbedItemWorkspace>()

  const [deleteWorkspace, setDeleteWorkspace] = useState(false)

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const openMoreMenu = Boolean(anchorElement)

  const handleMoreMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget)
  }
  const handleMoreMenuClose = () => {
    setAnchorElement(null)
  }

  useEffect(() => {
    if (props.board.includes('doc/')) {
      setLoading(false)
      void getReleeseDocument(props.board.replace('doc/', ''))
        .then(setDocument)
    } else if (props.board.includes('embed/')) {
      setLoading(true)
      void getEmbeddedItem(props.board.replace('embed/', ''))
        .then(setEmbedItem)
        .then(() => setLoading(false))
    } else {
      setLoading(true)
      void getTaskboard(props.board)
        .then(board => setTaskboard(board))
        .then(() => setLoading(false))
    }
  }, [])

  const visibility = props.board.toLowerCase().includes(props.searchQuery.toLowerCase())
  return (
    <>
      {loading
        ? <Stack paddingX={3} paddingY={0.5} width={1}>
          <ListItem
            dense
            disablePadding
          >
            <Stack alignItems='center' direction='row' spacing={1.5}>
              <Skeleton height={18} variant='circular' width={18} />
              <Skeleton variant='text' width={120} />
            </Stack>
          </ListItem>
        </Stack>
        : <Draggable draggableId={`${props.board}`} index={props.index} key={props.board}>
          {provided =>
            <Stack
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              sx={{
                display:
        visibility
          ? 'box'
          : 'none',
              }}
            >
              <ListItem
                component={NavLink}
                dense
                disablePadding
                to={taskboard
                  ? `/calendar/${taskboard.id}`
                  : `/calendar/${props.board}`}
              >
                <ListItemButton
                  sx={{
                    borderRadius: 0,
                    backgroundColor: taskboardId === props.board.replace('doc/', '').replace('embed/', '')
                      ? theme => theme.palette.primary.contrast2
                      : theme => theme.palette.background.default,
                    '.MuiIconButton-root': {
                      visibility: 'hidden',
                    },
                    ':hover .MuiIconButton-root': {
                      visibility: 'visible',
                    },
                  }}
                >
                  <ListItemAvatar>
                    {props.board.includes('doc/')
                      ? <ArticleRoundedIcon
                        color='info'
                        sx={{
                          width: 24,
                          height: 24,
                        }}
                      />
                      : !props.board.includes('embed/')
                        ? <Avatar
                          sx={{
                            width: 24,
                            height: 24,
                            borderRadius: 0.25,
                            bgcolor: taskboard?.color,
                            '.MuiSvgIcon-root': {
                              fontSize: 16,
                              color: theme => theme.palette.primary.contrastText,
                            },
                          }}
                        >
                          {taskboardIcons[taskboard?.icon ?? 0]}
                        </Avatar>
                        : <div style={{ fontSize: 24 }}>{GetIconTaskSmall(embedItem?.embedType ?? '')}</div>}
                  </ListItemAvatar>
                  <ListItemText
                    primary={taskboard?.title ??
                    embedItem?.title ??
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    document?.content?.[0]?.children?.[0]?.text as string | undefined ??
                    'Untitled'}
                    sx={{
                      wordBreak: 'break-word',
                      marginLeft: 1,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      lineClamp: '1',
                      display: 'block',
                      '.MuiTypography-root': {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        WebkitLineClamp: '1',
                        display: 'block',
                        whiteSpace: 'nowrap',
                      },
                    }}
                  />
                  <IconButton
                    onClick={event => {
                      event.preventDefault()
                      event.stopPropagation()
                      handleMoreMenuOpen(event)
                    }}
                    size='small'
                  >
                    <MoreHorizRoundedIcon />
                  </IconButton>
                </ListItemButton>
              </ListItem>
            </Stack>}
        </Draggable>}
      <Menu
        anchorEl={anchorElement}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClick={handleMoreMenuClose}
        onClose={handleMoreMenuClose}
        open={openMoreMenu}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
      >
        <MenuItem onClick={() => setDeleteWorkspace(true)}>
          <ListItemIcon>
            <DeleteRoundedIcon color='error' fontSize='medium' />
          </ListItemIcon>
          Delete item
        </MenuItem>
      </Menu>
      <DeleteItemdModal
        close={() => setDeleteWorkspace(false)}
        open={deleteWorkspace}
        refreshBoards={props.refreshBoards}
        taskboardId={props.board}
      />
    </>
  )
}

export default TaskBoardListItem

