/* eslint-disable id-length */
/* eslint-disable @typescript-eslint/no-magic-numbers */
import { Skeleton, Stack, Typography, useTheme } from '@mui/material'
import type { ApexOptions } from 'apexcharts'
import { useRef } from 'react'
import Chart from 'react-apexcharts'

import { useContainerDimensions } from 'src/components/ContainerDimensions'
import type { MarketingAnalyticsString } from 'src/models/Marketing'

type Props = {
  osClicks: MarketingAnalyticsString[]
  loading: boolean
}

const DevicesChart = (props: Props) => {
  const theme = useTheme()
  const labelsDevices = ['Mobile', 'Desktop']

  const totalMobile = props.osClicks.map(item => item._id === 'iOS' || item._id === 'Android' ? item.count : 0)
    .reduce((accumulator, current) => accumulator + current, 0)

  const totalDesktop = props.osClicks.map(item => item._id !== 'iOS' && item._id !== 'Android' ? item.count : 0)
    .reduce((accumulator, current) => accumulator + current, 0)

  const seriesDevices = [totalMobile, totalDesktop]

  const optionsDevices: ApexOptions = {
    chart: {
      foreColor: theme.palette.text.primary,
    },
    colors: [theme.palette.primary.main, theme.palette.secondary.main],
    series: seriesDevices,
    labels: labelsDevices,
    dataLabels: {
      enabled: true,
    },
    plotOptions: {
      pie: {
        donut: {
          size: '55%',
          labels: {
            show: false,
          },
        },
      },
    },
    stroke: {
      colors: ['transparent'],
    },
    legend: {
      fontFamily: 'Inter',
      width: 150,
    },
    tooltip: {
      y: {
        formatter: value => `${value.toLocaleString()} Clicks`,
      },
    },
  }

  const componentRef = useRef<HTMLDivElement>(null)
  const { width } = useContainerDimensions(componentRef)

  return (
    <Stack ref={componentRef} width={1}>
      <Stack direction='row' justifyContent='space-between' width={1}>
        <Typography variant='h3'>Devices</Typography>
      </Stack>
      <Stack alignItems='center' width={1}>
        {props.loading
          ? <Skeleton
            height={400}
            sx={{
              transform: 'none',
            }}
            width='100%'
          />
          : <Chart
            height={400}
            labels={labelsDevices}
            options={optionsDevices}
            series={seriesDevices}
            type='donut'
            width={width}
          />}
      </Stack>
    </Stack>
  )
}
export default DevicesChart

