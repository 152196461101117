
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import VpnKeyRoundedIcon from '@mui/icons-material/VpnKeyRounded'
import { Button, Dialog, IconButton, Paper, Stack, Typography, useTheme } from '@mui/material'

type Props = {
  close: () => void
  open: boolean
}

const EmailPasswordResetModal = (props: Props) => {
  const themeItem = useTheme()
  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullWidth
      keepMounted={false}
      maxWidth='xs'
      onClose={props.close}
      open={props.open}
    >
      <Stack width={1}>
        <Stack direction='row' justifyContent='space-between' padding={2} width={1}>
          <Typography variant='h3'>
            Security
          </Typography>
          <IconButton
            aria-label='close'
            onClick={props.close}
          >
            <CloseRoundedIcon />
          </IconButton>
        </Stack>
        <Stack alignItems='center' width={1}>
          <Stack
            direction='column'
            height={1}
            paddingBottom={3}
            paddingX={3}
            spacing={1}
            width={1}
          >
            <Stack alignItems='center' id='handle-login'>
              <Paper
                elevation={0}
                sx={{
                  backgroundColor: theme => theme.palette.primary.main,
                  borderRadius: 16,
                }}
              >
                <Stack
                  alignItems='center'
                  justifyContent='center'
                  padding={4}
                >
                  <VpnKeyRoundedIcon
                    sx={{
                      color: theme => theme.palette.primary.contrastText,
                      fontSize: '144px',
                    }}
                  />
                </Stack>
              </Paper>
              <Typography
                color={themeItem.palette.text.secondary}
                sx={{
                  marginTop: 1,
                }}
                textAlign='center'
                variant='h4'
              >
                Reset Password
              </Typography>
              <Typography
                color={themeItem.palette.text.label}
                textAlign='center'
                variant='body2'
              >
                Your password reset link has been sent
              </Typography>
              <Button
                color='primary'
                onClick={props.close}
                sx={{
                  marginTop: 2,
                }}
              >
                Close
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default EmailPasswordResetModal
