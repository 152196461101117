/* eslint-disable max-lines */
import 'src/components/pages/Links/EditLink/bellAnimation.css'

import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded'
import { alpha, Avatar, Button, Card, CardContent, CardMedia, darken, IconButton, lighten, Stack, Typography } from '@mui/material'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'

import { GetColorLink } from 'src/components/pages/SmartLink/Content/GetColorLink'
import GetIcon from 'src/components/pages/SmartLink/Content/GetIcon'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { MarketingLink } from 'src/models/Marketing'
import type { BasicOrganisation } from 'src/models/Organisation'
import * as darkTheme from 'src/styles/dark.theme'
import * as lightTheme from 'src/styles/light.theme'

type Props = {
  smartLinkData: MarketingLink
  artworkPreview: string | null | undefined
  organisation: BasicOrganisation | null
}

const getFontFamily = (font: string) => {
  switch (font) {
    case 'classical':
      return 'Times New Roman, Times, serif'
    case 'artistic':
      return 'Georgia, cursive'
    case 'modern':
      return 'Arial, Helvetica, sans-serif'
    default:
      return
  }
}

const getBorderRadius = (style: string) => {
  switch (style) {
    case 'square':
      return 0
    case 'rounded':
      return
    case 'round':
      return 64
    case 'outlined-square':
      return 0
    case 'outlined-rounded':
      return
    case 'outlined-round':
      return 64
    case 'glass-square':
      return 0
    case 'glass-rounded':
      return
    case 'glass-round':
      return 64
    default:
      return
  }
}

const appleMusic = 'Apple Music'
const deezer = 'Deezer'
const spotify = 'Spotify'
const amazon = 'Amazon'

const SmartLinkRightTabSmartLink = (props: Props) => {
  const { currentOrganisation } = useAuth()

  const socialList = props.smartLinkData.useOrgDefaultSocialIcons
    ? currentOrganisation?.defaultSocialList ?? []
    : props.smartLinkData.socialList

  const activeTheme = props.smartLinkData.palette === 'Light'
    ? lightTheme
    : darkTheme

  dayjs.extend(advancedFormat)

  const getButtonBackgroundColor = (platform: string) => props.smartLinkData.buttonStyle.includes('outlined')
    ? 'transparent'
    : props.smartLinkData.buttonStyle.includes('glass')
      ? props.smartLinkData.colorStyle === 'color'
        ? alpha(GetColorLink(platform), 0.4)
        : alpha(activeTheme.default.palette.text.label, 0.25)
      : props.smartLinkData.colorStyle === 'color'
        ? GetColorLink(platform)
        : activeTheme.default.palette.text.primary

  const getButtonBorderColor = (platform: string) => props.smartLinkData.colorStyle === 'color'
    ? GetColorLink(platform)
    : activeTheme.default.palette.text.primary

  const getButtonTextColor = (platform: string) => props.smartLinkData.buttonStyle.includes('glass')
    ? props.smartLinkData.colorStyle === 'color'
      ? activeTheme.default.palette.primary.contrastText
      : activeTheme.default.palette.text.primary
    : props.smartLinkData.buttonStyle.includes('outlined')
      ? props.smartLinkData.colorStyle === 'color'
        ? GetColorLink(platform)
        : activeTheme.default.palette.text.primary
      : props.smartLinkData.colorStyle === 'color'
        ? activeTheme.default.palette.primary.contrastText
        : activeTheme.default.palette.background.default

  const getButtonBackgroundHoverColor = (platform: string) => props.smartLinkData.colorStyle === 'color'
    ? darken(GetColorLink(platform), 0.2)
    : GetColorLink(platform)

  const getButtonVariant = () => props.smartLinkData.buttonStyle.includes('outlined') ||
    props.smartLinkData.buttonStyle.includes('glass')
    ? 'outlined'
    : 'contained'

  const getSubscribeButtonTextBorderColor = () => props.smartLinkData.buttonStyle.includes('outlined')
    ? activeTheme.default.palette.text.primary
    : props.smartLinkData.buttonStyle.includes('glass')
      ? activeTheme.default.palette.text.primary
      : activeTheme.default.palette.background.default

  const getActiveButtonBackgroundColor = () => props.smartLinkData.buttonStyle.includes('outlined')
    ? 'transparent'
    : props.smartLinkData.buttonStyle.includes('glass')
      ? alpha(activeTheme.default.palette.text.label, 0.25)
      : activeTheme.default.palette.text.primary

  const getBoxShadow = () => props.smartLinkData.buttonStyle.includes('glass')
    ? '4px 8px 8px rgba(0,0,0,.08)'
    : 'none'

  return (
    <Card
      elevation={0}
      sx={{
        width: 325,
        marginTop: 4,
        marginBottom: 4,
        borderRadius: '16px',
        background: 'transparent',
        overflow: 'initial',
        zIndex: 3,
      }}
    >
      {props.artworkPreview
        ? <CardMedia
          alt={`${props.artworkPreview ? 'Invalid' : 'Missing'} artwork`}
          component='img'
          height={325}
          image={props.artworkPreview}
          sx={{
            borderRadius: '8px!important',
            flexShrink: 0,
          }}
          width={325}
        />
        : <Card
          elevation={0}
          sx={{
            borderRadius: '8px!important',
            background: theme => theme.palette.background.input,
            height: 325,
            width: 325,
            flexShrink: 0,
          }}
        >
          <MusicNoteRoundedIcon
            sx={{
              fontSize: 325,
              color: theme => theme.palette.action.disabled,
            }}
          />
        </Card>}
      <CardContent sx={{ paddingX: 0 }}>
        <Stack spacing={2}>
          <Stack alignItems='flex-start' direction='column' spacing={1} width={1}>
            <Typography
              color={activeTheme.default.palette.text.primary}
              fontFamily={getFontFamily(props.smartLinkData.fontStyle)}
              fontSize='1.5rem'
              lineHeight={1}
              sx={{ wordBreak: 'break-word' }}
              textAlign='left'
              variant='h3'
            >
              {props.smartLinkData.title}
            </Typography>
            {props.smartLinkData.artists.length > 0 &&
            <Typography
              color={activeTheme.default.palette.text.primary}
              fontFamily={getFontFamily(props.smartLinkData.fontStyle)}
              fontSize='1rem'
              lineHeight={1}
              textAlign='left'
              variant='body1'
            >
              {props.smartLinkData.artists.map((artist, index) => index + 1 === props.smartLinkData.artists.length
                ? `${artist.toString()}`
                : `${artist.toString()}, `)}
            </Typography>}
            {props.smartLinkData.type === 'Presave' &&
            <Typography
              color={activeTheme.default.palette.text.secondary}
              fontFamily={getFontFamily(props.smartLinkData.fontStyle)}
              fontSize='0.75rem'
              lineHeight={1}
              textAlign='left'
              variant='helperText'
            >
              {`Out ${dayjs(props.smartLinkData.releaseDate).format('MMMM Do')}`}
            </Typography>}
          </Stack>

          {props.smartLinkData.descriptionText.length > 0 &&
          <Stack alignItems='flex-end' direction='row' spacing={1} width={1}>
            <Avatar
              src={props.organisation?.logoUrl}
            />
            <Card
              elevation={0}
              sx={{
                width: 1,
                background: props.smartLinkData.palette === 'Light'
                  ? '#e9e9eb'
                  : '#26252a',
                borderRadius: '20px 20px 20px 6px',
              }}
            >
              <Stack padding={1.5}>
                <Typography
                  color={activeTheme.default.palette.text.primary}
                  fontFamily={getFontFamily(props.smartLinkData.fontStyle)}
                  fontSize='0.875rem'
                  textAlign='left'
                  variant='body2'
                >
                  {props.smartLinkData.descriptionText}
                </Typography>
              </Stack>
            </Card>
          </Stack>}

          <Stack spacing={1.5}>
            {props.smartLinkData.foreverPresave &&
            <Stack
              alignItems='center'
              direction='row'
              justifyContent='space-between'
              spacing={1}
              width={1}
            >
              <Button
                fullWidth
                startIcon={<NotificationsRoundedIcon
                  sx={{ animation: 'ring 3s infinite' }}
                />}
                sx={{
                  borderRadius: getBorderRadius(props.smartLinkData.buttonStyle),
                  fontFamily: getFontFamily(props.smartLinkData.fontStyle),
                  height: '48px',
                  color: getSubscribeButtonTextBorderColor(),
                  backgroundColor: getActiveButtonBackgroundColor(),
                  borderColor: getSubscribeButtonTextBorderColor(),
                  boxShadow: getBoxShadow(),
                  ':hover': {
                    color: activeTheme.default.palette.background.default,
                    backgroundColor: props.smartLinkData.palette === 'Light'
                      ? lighten(activeTheme.default.palette.text.primary, 0.2)
                      : darken(activeTheme.default.palette.text.primary, 0.2),
                    borderColor: getSubscribeButtonTextBorderColor(),
                  },
                  '.MuiButton-startIcon': {
                    position: 'absolute',
                    left: 16,
                  },
                  paddingX: 3,
                }}
                variant={getButtonVariant()}
              >
                Subscribe
                {props.organisation?.name && props.organisation.name.length > 0 ? ' to' : ''}
                {' '}
                {props.organisation?.name}
              </Button>
            </Stack>}

            {props.smartLinkData.type === 'Presave' &&
            <>
              <Button
                fullWidth
                key='Spotify-Presave'
                startIcon={GetIcon(spotify)}
                sx={{
                  boxShadow: getBoxShadow(),
                  borderRadius: getBorderRadius(props.smartLinkData.buttonStyle),
                  fontFamily: getFontFamily(props.smartLinkData.fontStyle),
                  color: getButtonTextColor(spotify),
                  backgroundColor: getButtonBackgroundColor(spotify),
                  borderColor: getButtonBorderColor(spotify),
                  ':hover': {
                    color: activeTheme.default.palette.primary.contrastText,
                    backgroundColor: getButtonBackgroundHoverColor(spotify),
                    borderColor: getButtonBorderColor(spotify),
                  },
                  '.MuiButton-startIcon': {
                    position: 'absolute',
                    left: 16,
                  },
                }}
                variant={getButtonVariant()}
              >
                Pre-save on Spotify
              </Button>
              <Button
                fullWidth
                id='apple-music-authorize'
                key='Apple-Music-Presave'
                startIcon={GetIcon(appleMusic)}
                sx={{
                  boxShadow: getBoxShadow(),
                  borderRadius: getBorderRadius(props.smartLinkData.buttonStyle),
                  fontFamily: getFontFamily(props.smartLinkData.fontStyle),
                  color: getButtonTextColor(appleMusic),
                  backgroundColor: getButtonBackgroundColor(appleMusic),
                  borderColor: getButtonBorderColor(appleMusic),
                  ':hover': {
                    color: activeTheme.default.palette.primary.contrastText,
                    backgroundColor: getButtonBackgroundHoverColor(appleMusic),
                    borderColor: getButtonBorderColor(appleMusic),
                  },
                  '.MuiButton-startIcon': {
                    position: 'absolute',
                    left: 16,
                  },
                }}
                variant={getButtonVariant()}
              >
                Pre-save on Apple Music
              </Button>
              <Button
                fullWidth
                key='Deezer-Presave'
                startIcon={GetIcon(deezer)}
                sx={{
                  boxShadow: getBoxShadow(),
                  borderRadius: getBorderRadius(props.smartLinkData.buttonStyle),
                  fontFamily: getFontFamily(props.smartLinkData.fontStyle),
                  color: getButtonTextColor(deezer),
                  backgroundColor: getButtonBackgroundColor(deezer),
                  borderColor: getButtonBorderColor(deezer),
                  ':hover': {
                    color: activeTheme.default.palette.primary.contrastText,
                    backgroundColor: getButtonBackgroundHoverColor(deezer),
                    borderColor: getButtonBorderColor(deezer),
                  },
                  '.MuiButton-startIcon': {
                    position: 'absolute',
                    left: 16,
                  },
                }}
                variant={getButtonVariant()}
              >
                Pre-save on Deezer
              </Button>
              <Button
                fullWidth
                key='Amazon-Presave'
                startIcon={GetIcon(amazon)}
                sx={{
                  boxShadow: getBoxShadow(),
                  borderRadius: getBorderRadius(props.smartLinkData.buttonStyle),
                  fontFamily: getFontFamily(props.smartLinkData.fontStyle),
                  color: getButtonTextColor(amazon),
                  backgroundColor: getButtonBackgroundColor(amazon),
                  borderColor: getButtonBorderColor(amazon),
                  ':hover': {
                    color: activeTheme.default.palette.primary.contrastText,
                    backgroundColor: getButtonBackgroundHoverColor(amazon),
                    borderColor: getButtonBorderColor(amazon),
                  },
                  '.MuiButton-startIcon': {
                    position: 'absolute',
                    left: 16,
                  },
                }}
                variant={getButtonVariant()}
              >
                Pre-save on Amazon Music
              </Button>
            </>}

            {props.smartLinkData.type === 'Smartlink' &&
            props.smartLinkData.inputList.map(linkItem =>
              <Button
                fullWidth
                key={linkItem.id}
                startIcon={GetIcon(linkItem.label)}
                sx={{
                  boxShadow: getBoxShadow(),
                  borderRadius: getBorderRadius(props.smartLinkData.buttonStyle),
                  fontFamily: getFontFamily(props.smartLinkData.fontStyle),
                  color: getButtonTextColor(linkItem.label),
                  backgroundColor: getButtonBackgroundColor(linkItem.label),
                  borderColor: getButtonBorderColor(linkItem.label),
                  ':hover': {
                    color: activeTheme.default.palette.primary.contrastText,
                    backgroundColor: getButtonBackgroundHoverColor(linkItem.label),
                    borderColor: getButtonBorderColor(linkItem.label),
                  },
                  '.MuiButton-startIcon': {
                    position: 'absolute',
                    left: 16,
                  },
                }}
                variant={getButtonVariant()}
              >
                {linkItem.customTitle.length > 0
                  ? linkItem.customTitle
                  : linkItem.label}
              </Button>)}
          </Stack>

          <Stack alignItems='center' direction='row' flexWrap='wrap' justifyContent='center' spacing={1} width={1}>
            {socialList.map(element =>
              <Stack key={element.label}>
                <IconButton
                  color='default'
                  href={element.value}
                  size='medium'
                  sx={{
                    color: activeTheme.default.palette.text.primary,
                    ':hover': {
                      color: activeTheme.default.palette.text.primary,
                    },
                  }}
                >
                  {GetIcon(element.label)}
                </IconButton>
              </Stack>)}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}

export default SmartLinkRightTabSmartLink
