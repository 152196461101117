/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable complexity */
/* eslint-disable react/forbid-component-props */
import 'src/styles/Custom/waveform.css'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import PauseCircleRoundedIcon from '@mui/icons-material/PauseCircleRounded'
import PlayCircleFilledWhiteRoundedIcon from '@mui/icons-material/PlayCircleFilledWhiteRounded'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Button, Collapse, IconButton, Paper, Stack, Tab, Typography, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import WaveSurfer from 'wavesurfer.js'

import { generateTitleRecording } from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/RecordingsTab/Components/EditRecordingComponent'
import ViewRecordingContentTab from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/RecordingsTab/Components/ViewContentTab'
import ViewRecordingDetailsTab from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/RecordingsTab/Components/ViewDetailtsTab'
import ViewRecordingPublishingTab from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/RecordingsTab/Components/ViewMetadataTab'
import { usePlayer } from 'src/components/providers/MusicProvider'
import type Project from 'src/models/Project'
import type Recording from 'src/models/Recording'

type Props = {
  recording: Recording
  project?: Project
}

const ShareProjectRecordingItem = (props: Props) => {
  const { play, currentTime, playing, timeAttempt, setPlaying, pausePlay, requestTime } = usePlayer()
  const themeColors = useTheme()
  const [timeLocal, setTimeLocal] = useState(0)
  const [open, setOpen] = useState(false)
  const [currentTab, setCurrentTab] = useState('1')
  const [loading, setLoading] = useState(true)
  const [wavesurfer, setWavesurfer] = useState<WaveSurfer>()

  const containerName = `waveform-${props.recording.id}`
  const containerGet = `#waveform-${props.recording.id}`

  useEffect(() => {
    if (props.recording.audioWaveform && loading && wavesurfer === undefined) {
      setWavesurfer(WaveSurfer.create({
        container: containerGet,
        barRadius: 3,
        barGap: 0.5,
        mediaControls: false,
        barWidth: 2,
        cursorWidth: 0,
        height: 64,
        normalize: true,
        mediaType: 'audio',
        hideScrollbar: true,
        progressColor: themeColors.palette.primary.main,
        responsive: true,
        waveColor: themeColors.palette.waveform,
        backend: 'MediaElement',
      }))
    }
    if (props.recording.audioWaveform && loading && wavesurfer) {
      wavesurfer.load(props.recording.audioFileStreamingUrl.length > 0
        ? props.recording.audioFileStreamingUrl
        : props.recording.audioFileUrl
      , props.recording.audioWaveform.data)
      wavesurfer.setVolume(0)
      setLoading(false)
      wavesurfer.on('seek', (progress: number) => {
        if (playing !== props.recording.id && !wavesurfer.isPlaying()) {
          setPlaying(props.recording)
        }
        requestTime(props.recording.duration * progress)
      })
      setLoading(false)
    }
    if (wavesurfer && playing === props.recording.id && timeLocal !== timeAttempt) {
      void wavesurfer.play(timeAttempt)?.catch(() => null).finally(() => setTimeLocal(timeAttempt))
    }
    if (wavesurfer && playing === props.recording.id && play && !wavesurfer.isPlaying()) {
      void wavesurfer.playPause()?.catch(() => null)
    }
    if (wavesurfer && (playing !== props.recording.id || !play) && wavesurfer.isPlaying()) {
      void wavesurfer.playPause()?.catch(() => null)
    }
  }, [playing, play, timeAttempt, wavesurfer, currentTime, timeLocal])

  const handlePlaying = () => {
    if (playing === props.recording.id) {
      pausePlay(!play)
    } else {
      pausePlay(true)
      setPlaying(props.recording)
      requestTime(0)
    }
  }

  return (
    <Paper
      sx={{
        maxWidth: 900,
        width: 1,
      }}
      variant='outlined'
    >
      <Stack direction='column' width={1}>
        <Stack
          alignItems='center'
          direction={{
            xl: 'row',
            lg: 'row',
            md: 'row',
            sm: 'column',
            xs: 'column',
          }}
          padding={2}
          spacing={2}
          width={1}
        >
          {props.recording.artworkPreviewUrl
            ? <img
              alt={props.recording.title}
              height={120}
              src={props.recording.artworkPreviewUrl}
              style={{
                borderRadius: 16,
                objectFit: 'cover',
                aspectRatio: '120 / 120',
              }}
              width={120}
            />
            : <Paper
              elevation={0}
              sx={{
                background: theme => theme.palette.background.input,
                width: 120,
                height: 120,
              }}
            >
              <MusicNoteRoundedIcon color='disabled' sx={{ fontSize: 120 }} />
            </Paper>}

          <Stack direction='column' width={1}>
            <Stack direction='row' justifyContent='space-between'>
              <Stack direction='column'>
                <Typography
                  color={themeColors.palette.text.label}
                  lineHeight={1}
                  variant='body1'
                >
                  {props.recording.primaryArtists.map((artist, index) =>
                    `${artist.name}${props.recording.primaryArtists.length === index + 1 ? '' : ', '}`)}
                </Typography>
                <Typography
                  fontSize='1.15rem'
                  lineHeight={1.5}
                  variant='h4'
                >
                  {generateTitleRecording(undefined, props.recording)}
                </Typography>
              </Stack>
            </Stack>
            <Stack alignItems='center' direction='row' spacing={1} width={1}>
              <Stack alignItems='center' direction='row' spacing={1} width={1}>
                <IconButton
                  color='primary'
                  onClick={handlePlaying}
                  size='large'
                  sx={{
                    padding: 0.5,
                  }}
                >
                  {playing === props.recording.id && play
                    ? <PauseCircleRoundedIcon />
                    : <PlayCircleFilledWhiteRoundedIcon />}
                </IconButton>
                <Stack
                  sx={{
                    visibility: {
                      xl: 'inherit',
                      lg: 'inherit',
                      md: 'inherit',
                      sm: 'hidden',
                      xs: 'hidden',
                    },
                  }}
                  width={1}
                >
                  <div
                    className='react-waves'
                    id={containerName}
                  />
                </Stack>
                <Typography variant='textLabel' whiteSpace='nowrap'>
                  {`${dayjs(new Date(currentTime && playing === props.recording.id
                    ? currentTime * 1000
                    : 0)).format('mm:ss')} / 
                  ${dayjs(new Date(props.recording.duration ? props.recording.duration * 1000 : 0)).format('mm:ss')}`}
                </Typography>
              </Stack>
              <Stack alignItems='center' direction='row' marginLeft='auto' spacing={2}>
                <Button
                  aria-controls='basic-menu'
                  aria-expanded={open ? 'true' : undefined}
                  aria-haspopup='true'
                  endIcon={<KeyboardArrowDownIcon />}
                  id='basic-button'
                  onClick={() => setOpen(!open)}
                  variant='text'
                >
                  Details
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        <Stack width={1}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <TabContext value={currentTab}>
              <Stack sx={{ paddingLeft: 2, borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  aria-label='current profile tab'
                  onChange={(event, value: string) => setCurrentTab(value)}
                  sx={{
                    minHeight: 32,
                    '.MuiTab-root':
                  {
                    textTransform: 'none',
                    fontWeight: 'normal',
                    fontSize: '16px',
                    minHeight: 32,
                  },
                  }}
                >
                  <Tab
                    label='General'
                    sx={{ minHeight: 32 }}
                    value='1'
                  />
                  <Tab
                    label='Metadata'
                    sx={{ minHeight: 32 }}
                    value='2'
                  />
                  <Tab
                    label='Content'
                    sx={{ minHeight: 32 }}
                    value='3'
                  />
                </TabList>
              </Stack>
              <Stack sx={{ margin: 0, padding: 0 }}>
                <TabPanel value='1'>
                  <ViewRecordingDetailsTab
                    disableDownload={props.project?.disableDownload}
                    recording={props.recording}
                    share
                  />
                </TabPanel>
                <TabPanel value='2'>
                  <ViewRecordingPublishingTab
                    recording={props.recording}
                  />
                </TabPanel>
                <TabPanel value='3'>
                  <ViewRecordingContentTab
                    recording={props.recording}
                  />
                </TabPanel>
              </Stack>

            </TabContext>
          </Collapse>
        </Stack>
      </Stack>
    </Paper>
  )
}

export default ShareProjectRecordingItem
