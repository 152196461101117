/* eslint-disable sonarjs/no-duplicate-string */
import Autocomplete from '@mui/material/Autocomplete'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import type { SyntheticEvent } from 'react'

type Props = {
  setInputSelected: (item: string[]) => void
  tags: string[]
}

const TagsInputField = (props: Props) => {
  const handleChange = (event: SyntheticEvent, value: string[]) => {
    props.setInputSelected(value)
  }
  return (
    <Stack width={1}>
      <Autocomplete
        freeSolo
        id='tags-filled'
        limitTags={10}
        multiple
        onChange={handleChange}
        options={[]}
        renderInput={params =>
          <TextField
            {...params}
            placeholder='Type and press enter'
            sx={{
              marginTop: 0,
              background: 'transparent!important',
              border: 'none',
              '.MuiInputBase-root': {
                border: 'none',
                background: 'transparent!important',
                borderColor: 'transparent!important',
                paddingY: 0,
                marginTop: 0,
              },
            }}
          />}
        sx={{
          background: 'transparent',
          border: 'none',
          '.MuiInputBase-root': {
            background: 'transparent',
            border: 'none',
          },
        }}
        value={props.tags}
      />
    </Stack>
  )
}

export default TagsInputField
