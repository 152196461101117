/* eslint-disable max-lines */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable no-duplicate-imports */
/* eslint-disable react-hooks/exhaustive-deps */
import PowerSettingsNewRoundedIcon from '@mui/icons-material/PowerSettingsNewRounded'
import { Button, Stack, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import ReactDevicePreview from 'react-device-preview'
import TagManager from 'react-gtm-module'
import { useLocation } from 'react-router'
import { Route, Routes, useNavigate } from 'react-router-dom'

import PersonalInformation from './PersonalInformation'
import { getCurrentOrganisation, getUsersOrganisations, onboardingEvent } from 'src/api/organisation'
import ClientPersona from 'src/components/pages/Account/InitialSetup/ClientPersona'
import CreateProfile from 'src/components/pages/Account/InitialSetup/CreateProfile'
import FakeUi from 'src/components/pages/Account/InitialSetup/FakeUi'
import InviteTeam from 'src/components/pages/Account/InitialSetup/InviteTeam'
import EditLinkRightTab from 'src/components/pages/Links/EditLink/EditLinkRightTab'
import type { EarlyAuth } from 'src/components/providers/AuthProvider'
import { useAuth } from 'src/components/providers/AuthProvider'
import LogoFull from 'src/images/LogoFull.svg'
import LogoR from 'src/images/LogoR.svg'
import { NewAccount } from 'src/models/Account'
import { MarketingLink } from 'src/models/Marketing'
import { Organisation } from 'src/models/Organisation'
import { auth, parseError } from 'src/utils/firebase'

// eslint-disable-next-line max-len
const artworkItem = 'https://cdn.releese.io/fake-images/preview.webp'

const InitialSetup = () => {
  const themeColors = useTheme()
  const matches = useMediaQuery(themeColors.breakpoints.down('md'))

  const { currentAccount, refreshCurrentAccount, currentFirebaseUser } = useAuth() as EarlyAuth
  const [editingAccount, setEditingAccount] = useState<NewAccount>(new NewAccount(currentAccount ?? {}))
  const navigate = useNavigate()
  const location = useLocation()
  const [isArtist, setIsArtist] = useState(true)
  const [persona, setPersona] = useState('')
  const [picture, setPicture] = useState('')

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const handleLogout = () =>
    auth.signOut()
      .then(() => window.location.reload())
      .catch(parseError)

  useEffect(() => {
    void refreshCurrentAccount()
  }, [])

  const handleSaveAccount = async (savedAccount: NewAccount) => {
    void onboardingEvent('Add Personal Information')
    setEditingAccount(savedAccount)
    await getUsersOrganisations()
      .then(async data => {
        if (data.length > 0 && data[0].name && data[0].name.length > 0) {
          await getUsersOrganisations()
            .then(async () => {
              await getCurrentOrganisation()
                .then(org => {
                  TagManager.dataLayer({
                    dataLayer: {
                      event: 'sign_up',
                      userId: org?.id,
                      eventProps: {
                        city: org?.city,
                        country: org?.country,
                        email: org?.organisationEmail,
                        firstName: org?.firstName,
                        lastName: org?.lastName,
                        state: org?.region,
                        zip: org?.postalCode,
                      },
                    },
                  })

                  TagManager.dataLayer({
                    dataLayer: {
                      event: 'lead',
                      userId: org?.id,
                      eventProps: {
                        city: org?.city,
                        country: org?.country,
                        email: org?.organisationEmail,
                        firstName: org?.firstName,
                        lastName: org?.lastName,
                        state: org?.region,
                        zip: org?.postalCode,
                      },
                    },
                  })

                  navigate({ pathname: '/', search: document.location.search })
                })
            })
        } else {
          window.rewardful('convert', { email: currentFirebaseUser?.email ?? '' })
          navigate({ pathname: '/InitialSetup/CreateProfile', search: document.location.search })
        }
      })
      .finally(() => null)
  }

  const handleCreateProfile = () => {
    void onboardingEvent('Create Profile')
    navigate({ pathname: '/InitialSetup/AboutYou', search: document.location.search })
  }

  const handleInviteTeam = () => {
    void onboardingEvent('Invite Team')
    navigate({ pathname: '/', search: document.location.search })
  }

  return <Stack
    sx={{
      display: 'flex',
      flexDirection: 'row',
      margin: 0,
      height: 1,
      padding: '0px!important',
      backgroundColor: theme => theme.palette.background.paper,
    }}
  >
    <Stack direction='row' height={1} overflow='auto' width={1}>
      <Stack
        alignItems='flex-start'
        justifyContent={matches ? 'flex-start' : 'center'}
        overflow='auto'
        padding={matches ? 2 : 8}
        spacing={4}
        sx={{
          background: theme => theme.palette.background.paper,
        }}
        width={1}
        zIndex={4}
      >
        <Stack alignItems='flex-end' direction='row' spacing={1}>
          <img
            alt='Releese Icon'
            height='28px'
            src={LogoR}
          />
          <img
            alt='Releese Logo'
            height='36px'
            src={LogoFull}
            style={{
              filter: 'invert(1)',
            }}
          />
        </Stack>
        <Routes>
          <Route
            element={<PersonalInformation
              account={editingAccount}
              onSave={handleSaveAccount}
            />}
            path='/PersonalInformation'
          />
          <Route
            element={
              <CreateProfile
                handleCreateProfile={handleCreateProfile}
                setIsArtist={setIsArtist}
                setPersona={setPersona}
                setProfilePicture={setPicture}
              />
            }
            path='/CreateProfile'
          />
          <Route
            element={
              <ClientPersona
                isArtist={isArtist}
              />
            }
            path='/AboutYou'
          />
          <Route
            element={
              <InviteTeam
                handleInviteTeam={handleInviteTeam}
              />
            }
            path='/InviteTeam'
          />
        </Routes>
      </Stack>
      {!matches &&
      <Stack
        height={1}
        justifyContent='center'
        marginX='auto'
        maxWidth={800}
        sx={{
          backgroundColor: isArtist
            ? theme => theme.palette.primary.main
            : theme => theme.palette.info.main,
          flexShrink: 0,
          transition: 'background-color 0.2s linear',
          borderRadius: '64px 0px 0px 64px',
        }}
        width='40%'
        zIndex={4}
      >
        <Stack
          alignItems='center'
          direction='column'
          height='90%'
          justifyContent='center'
          spacing={4}
          width={1}
          zIndex={4}
        >
          {(location.pathname.split('/').pop() === 'PersonalInformation' ||
          location.pathname.split('/').pop() === 'AboutYou' ||
          location.pathname.split('/').pop() === 'InviteTeam') &&
          <Stack
            sx={{
              overflow: 'hidden',
              height: '100%',
              justifyContent: 'flex-start',
              position: 'relative',
              alignItems: 'flex-start',
            }}
            width={1}
          >
            <Stack
              sx={{
                width: '1920px',
                height: '100%',
                backgroundColor: theme => theme.palette.background.default,
                marginLeft: '-128px',
                transform: 'scale(0.72)',
                borderRadius: '16px',
              }}
            >
              <FakeUi />
            </Stack>
          </Stack>}

          {location.pathname.split('/').pop() === 'CreateProfile' &&
          <Stack
            height={1}
            sx={{
              marginLeft: '64px',
              borderRadius: '16px',
              overflow: 'hidden',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            width='auto'
          >
            <Stack
              height={665}
              sx={{
                pointerEvents: 'none',
              }}
              width={340}
            >
              <ReactDevicePreview
                device='iphonex'
                scale='0.8'
              >
                <Stack
                  alignItems='center'
                  flexGrow={0}
                  height={1}
                  maxWidth={420}
                  position='relative'
                  sx={{
                    background: '#000000',
                    overflow: 'hidden',
                    borderRadius: '16px',
                  }}
                  top={0}
                  width={1}
                >
                  <Stack
                    sx={{
                      position: 'absolute',
                      height: '100vh',
                      width: '100vw',
                      background: `url(${artworkItem})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      filter: 'blur(60px)',
                      zIndex: 2,
                      transform: 'scale(1.5)',
                      opacity: 0.5,
                      minWidth: 600,
                      left: 'calc(50%)',
                      backgroundPositionX: 'center',
                      backgroundPositionY: 'center',
                      backgroundRepeat: 'no-repeat',
                    }}
                  />
                  <EditLinkRightTab
                    artworkPreview={artworkItem}
                    organisation={new Organisation({
                      name: persona,
                      logoUrl: picture.length > 0
                        ? picture
                        : undefined,
                    })}
                    smartLinkData={new MarketingLink({
                      type: 'Presave',
                      title: 'My Next Single',
                      artists: persona.length > 0 ? [persona] : [],
                      foreverPresave: true,
                      useOrgDefaultSocialIcons: true,
                      releaseDate: new Date(new Date().setDate(new Date().getDate() + 7)),
                      palette: 'Dark',
                      theme: 'Subtle',
                      colorStyle: 'color',
                      descriptionText: 'Pre-save my next release',
                    })}
                  />
                </Stack>
              </ReactDevicePreview>
            </Stack>
          </Stack>}

        </Stack>
        <Stack alignItems='center' paddingY={2} width={1} zIndex={6}>
          <Button
            color='inherit'
            onClick={handleLogout}
            startIcon={<PowerSettingsNewRoundedIcon />}
            sx={{
              width: 'fit-content',
              color: 'white',
            }}
            variant='text'
          >
            Log Out
          </Button>
        </Stack>
      </Stack>}
    </Stack>
  </Stack>
}
export default InitialSetup
