/* eslint-disable @typescript-eslint/no-explicit-any */
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { Button, IconButton, Paper, Stack, Typography } from '@mui/material'
import type { ReactElement } from 'react'

type Props = {
  readonly close: () => void
  readonly finalAction: () => void
  readonly title: string
  readonly description: string
  readonly final: boolean
  readonly first?: boolean
  readonly step: number
  readonly goTo: (step: number) => void
  readonly item?: ReactElement<any, any>
  readonly finalText: string
}

const TourStep = (props: Props) => {
  const setupProject = () => {
    props.finalAction()
  }
  return (
    <Stack
      direction='column'
      spacing={2}
      sx={{
        borderRadius: 1,
        overflow: 'hidden',
      }}
      width={1}
    >
      {props.item &&
      <Stack alignItems='center' position='relative' width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='flex-end'
          position='absolute'
          right={16}
          top={16}
          zIndex={3}
        >
          <IconButton onClick={props.close} size='small'>
            <CloseRoundedIcon />
          </IconButton>
        </Stack>
        {props.item}
      </Stack>}
      <Paper elevation={0} sx={{ borderRadius: 0 }}>
        <Stack
          alignItems='left'
          padding={2}
          position='relative'
          spacing={2}
          width={1}
        >
          <Typography
            lineHeight={1}
            textAlign='left'
            variant='h4'
          >
            {props.title}
          </Typography>
          <Typography
            color='textSecondary'
            sx={{ marginTop: '8px!important' }}
            textAlign='left'
            variant='body1'
          >
            {props.description}
          </Typography>
          {props.final &&
          <Stack direction='row' justifyContent='flex-end' spacing={1} width={1}>
            <Button
              color='primary'
              onClick={() => setupProject()}
              size='small'
              variant='contained'
            >
              {props.finalText}
            </Button>
          </Stack>}
          {!props.final &&
          <Stack direction='row' justifyContent='flex-end' spacing={1} width={1}>
            {!props.first &&
            <Button
              onClick={() => props.goTo(props.step - 2)}
              size='small'
              variant='text'
            >
              Previous
            </Button>}
            <Button
              onClick={() => props.goTo(props.step)}
              size='small'
              variant='contained'
            >
              Continue
            </Button>
          </Stack>}
        </Stack>
      </Paper>
    </Stack>
  )
}

export default TourStep
