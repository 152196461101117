import CloseIcon from '@mui/icons-material/Close'
import { Alert, Card, CardActionArea, Checkbox, Dialog, FormControlLabel, FormGroup, IconButton, Snackbar, Stack, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'
import QRCode from 'react-qr-code'

import { savePartialProject } from 'src/api/projects'
import type Project from 'src/models/Project'

type Props = {
  close: () => void
  open: boolean
  project?: Project
}

const ShareModal = (props: Props) => {
  const [project, setProject] = useState(props.project)
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))
  const [linkCopiedToast, setLinkCopiedToastOpen] = useState(false)

  const handleCloseToast = (event: Event | React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setLinkCopiedToastOpen(false)
  }

  const copyLink = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    event.preventDefault()
    setLinkCopiedToastOpen(true)
    void navigator.clipboard.writeText(`https://cloud.releese.io/share/${props.project?.id}`)
  }

  const updatePublic = async (value: boolean) => {
    if (project) {
      await savePartialProject({
        ...project,
        disableDownload: value,
      }).then(() => setProject({ ...project, disableDownload: value }))
    }
  }

  return (
    <>
      <Snackbar
        autoHideDuration={5000}
        message='Link Copied'
        onClose={handleCloseToast}
        open={linkCopiedToast}
      >
        <Alert
          onClose={handleCloseToast}
          severity='success'
          sx={{ width: '100%' }}
          variant='filled'
        >
          Link copied
        </Alert>
      </Snackbar>

      <Dialog
        BackdropProps={{
          timeout: 500,
        }}
        closeAfterTransition
        fullScreen={fullScreen}
        onClose={props.close}
        open={props.open}
        sx={{
          justifyContent: 'center',
        }}
      >
        {project &&
          <Stack paddingX={3} paddingY={3} spacing={1} width={1}>
            <Stack
              alignItems='center'
              direction='row'
              justifyContent='space-between'
              width={1}
            >
              <Typography variant='h3'>Share</Typography>
              <IconButton onClick={() => props.close()}>
                <CloseIcon color='disabled' />
              </IconButton>
            </Stack>
            <Stack alignItems='center' minWidth={300} spacing={2} width={1}>
              <Card
                elevation={0}
                sx={{
                  backgroundColor: 'white',
                  width: 'fit-content',
                }}
              >
                <Stack padding={2}>
                  <QRCode
                    value={`https://cloud.releese.io/share/${props.project?.id}`}
                  />
                </Stack>
              </Card>
              <Tooltip
                title='Click to copy'
              >
                <Card variant='outlined'>
                  <CardActionArea
                    onClick={event => copyLink(event)}
                    sx={{
                      padding: 1,
                    }}
                  >
                    <Typography variant='body1'>
                      {`https://cloud.releese.io/share/${props.project?.id}`}
                    </Typography>
                  </CardActionArea>
                </Card>
              </Tooltip>
              <FormGroup>
                <FormControlLabel
                  aria-label='Disable download'
                  checked={project.disableDownload}
                  control={<Checkbox checked={project.disableDownload} />}
                  label='Disable download'
                  onChange={() => updatePublic(!project.disableDownload)}
                />
              </FormGroup>
            </Stack>
          </Stack>}
      </Dialog>
    </>
  )
}

export default ShareModal
