/* eslint-disable max-lines */
/* eslint-disable sonarjs/no-duplicate-string */
import { v4 as uuidv4 } from 'uuid'

import { colorSelection } from 'src/components/pages/Task/TaskMenu/TaskMenuComponents/ColorsForPicker'
import type { TaskBoardTemplate, TaskGroup, TaskItem } from 'src/models/Taskboard'

const notStarted = 'Not started'

const tasksPreRelease: TaskItem[] = [{
  id: uuidv4(),
  title: 'Determine release date for album',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
{
  id: uuidv4(),
  title: 'Identify target audience for album',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
{
  id: uuidv4(),
  title: 'Research and compile a list of potential promotional opportunities (press, radio, online)',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
{
  id: uuidv4(),
  title: 'Develop album artwork and design',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
{
  id: uuidv4(),
  title: 'Create press release and album bio',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
{
  id: uuidv4(),
  title: 'Plan and schedule photo and video shoots for promotional materials',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
{
  id: uuidv4(),
  title: 'Plan and book album release concert or show',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
{
  id: uuidv4(),
  title: 'Create and sign agreements with collaborators',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
{
  id: uuidv4(),
  title: 'Create project in Releese, assign royalty splits to collaborators',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
{
  id: uuidv4(),
  title: 'Distribute album through Releese',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
{
  id: uuidv4(),
  title: 'Plan and execute pre-release teaser campaign with a Releese Pre-save link',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
{
  id: uuidv4(),
  title: 'Organize interview schedule for rpess and media outlets',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
{
  id: uuidv4(),
  title: 'Reach out to music bloggers and influencers for early reviews and buzz',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
{
  id: uuidv4(),
  title: 'Set up and maintain social media presence for album',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
{
  id: uuidv4(),
  title: 'Develop physical release plan (CD, vinyl, etc.)',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
{
  id: uuidv4(),
  title: 'Secure promotional materials and merchandise (posters, t-shirts, etc.)',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
{
  id: uuidv4(),
  title: 'Coordinate with tour schedule and plan album promotion at live shows',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
{
  id: uuidv4(),
  title: 'Plan and execute album listening parties or listening events',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
]

const tasksReleaseWeek: TaskItem[] = [{
  id: uuidv4(),
  title: 'Run Instagram and TikTok ads to promote lead song',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
{
  id: uuidv4(),
  title: 'Post daily on social media platforms to create a buzz',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
{
  id: uuidv4(),
  title: 'Contact local radio stations',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
{
  id: uuidv4(),
  title: 'Contact press outlets',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
]

const taskGroupPreRelease: TaskGroup = {
  id: uuidv4(),
  tasks: tasksPreRelease,
  title: 'Pre release',
  color: colorSelection[0],
}

const taskGroupReleaseWeek: TaskGroup = {
  id: uuidv4(),
  tasks: tasksReleaseWeek,
  title: 'Release week',
  color: colorSelection[1],
}

const taskGroups: TaskGroup[] = [taskGroupPreRelease, taskGroupReleaseWeek]

export const albumRelease: TaskBoardTemplate = {
  id: '',
  customFields: [],
  icon: 0,
  title: 'Album Release',
  color: colorSelection[6],
  fieldOrder: [],
  createdAt: new Date(),
  taskGroups,
  hiddenFields: [],
  description: 'Your album rollout strategy, engineered by Releese.',
}
