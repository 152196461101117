import CloseIcon from '@mui/icons-material/Close'
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded'
import { Button, Dialog, IconButton, Paper, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'

type Props = {
  readonly close: () => void
  readonly open: boolean
}

const ServiceModalComplete = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='sm'
      onClose={props.close}
      open={props.open}
      sx={{
        height: 'auto',
        '.MuiPaper-root': {
          height: 'auto!important',
        },
        '.MuiDialog-container': {
          height: 'auto',
          minHeight: 1,
        },
      }}
    >
      <Stack
        height={1}
        overflow='auto'
        width={1}
      >
        <Stack padding={2} width={1}>
          <Stack
            alignItems='center'
            direction='row'
            justifyContent='space-between'
            width={1}
          >
            <Typography variant='h3'>
              Releese Services
            </Typography>
            <IconButton onClick={() => props.close()}>
              <CloseIcon color='disabled' />
            </IconButton>
          </Stack>
          <Stack width={1}>
            <Stack alignItems='center' direction='column' spacing={2}>
              <Paper
                elevation={0}
                sx={{
                  backgroundColor: theme => theme.palette.success.main,
                  borderRadius: 16,
                }}
              >
                <Stack
                  alignItems='center'
                  justifyContent='center'
                  padding={2}
                >
                  <ShoppingCartRoundedIcon
                    sx={{
                      color: theme => theme.palette.primary.contrastText,
                      fontSize: '64px',
                    }}
                  />
                </Stack>
              </Paper>
              <Stack width={1}>
                <Typography
                  color='text.primary'
                  lineHeight={1.4}
                  textAlign='center'
                  variant='h3'
                >
                  We have received your request
                </Typography>
                <Typography
                  color='text.secondary'
                  textAlign='center'
                  variant='body1'
                >
                  We have received your Releese Services purchase request, our team will be in touch by email very soon
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction='row' justifyContent='flex-end' spacing={1} width='100%'>
            <Button
              color='primary'
              onClick={() => props.close()}
              variant='contained'
            >
              Complete
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default ServiceModalComplete
