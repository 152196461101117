/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useMemo, useState } from 'react'

import { getTasksboardUpdates } from 'src/api/taskboards'
import { initializeTaskboard, subscribeToTasksboards } from 'src/api/webSocket'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { TaskBoardEditDto } from 'src/models/Taskboard'
import { TaskBoardEdit } from 'src/models/Taskboard'

type TasksboardProviderContextProps = {
  tasksboardUpdates: TaskBoardEdit[]
  fetchUpdates: (tasksboardId: string) => Promise<void>
}

export type InitProps = Omit<TasksboardProviderContextProps,
'tasksboardUpdates'> & {
  tasksboardUpdates: TasksboardProviderContextProps['tasksboardUpdates'] | []
}

const initialValue = {
  tasksboardUpdates: [],
} as InitProps

const TasksboardContext = createContext(initialValue as TasksboardProviderContextProps)

export const useTasksboard = () => useContext(TasksboardContext)

type Props = {
  children?: React.ReactNode
  taskboardId: string
}

export const TasksboardProvider = (props: Props) => {
  const { currentOrganisation } = useAuth()
  const [tasksboardUpdates, setTasksboardUpdates] = useState<InitProps['tasksboardUpdates']>([])
  const [latestNotification, setLatestNotification] = useState<TaskBoardEdit>()

  useEffect(() => {
    void subscribe()
  }, [currentOrganisation?.id, props.taskboardId])

  const getTaskBoardWithRefresh = async () => {
    await initializeTaskboard(props.taskboardId)
      .then(socketItem => {
        subscribeToTasksboards(socketItem, (error: unknown, data?: TaskBoardEditDto) => {
          if (error) return

          if (data) {
            setLatestNotification(new TaskBoardEdit(data))
          }
        })
        socketItem?.io.once('reconnect', async () => {
          socketItem?.removeAllListeners('tasksboardNotification')
          await getTaskBoardWithRefresh()
        })
      })
  }

  const subscribe = async () => {
    await fetchUpdates(props.taskboardId)
      .then(() => void getTaskBoardWithRefresh())
  }

  useEffect(() => {
    if (latestNotification && latestNotification.taskboardId === props.taskboardId) {
      setTasksboardUpdates(previousState => previousState && previousState.length > 0
        ? [latestNotification, ...previousState]
        : [latestNotification])
    }
  }, [latestNotification])

  const fetchUpdates = async (tasksboardId: string) => {
    if (tasksboardId.length > 0) {
      await getTasksboardUpdates(tasksboardId)
        .then(setTasksboardUpdates)
    }
  }

  const value = useMemo<InitProps>(
    () => ({
      fetchUpdates,
      tasksboardUpdates,
    }),
    [tasksboardUpdates]
  ) as TasksboardProviderContextProps

  return (
    <TasksboardContext.Provider value={value}>
      {props.children}
    </TasksboardContext.Provider>
  )
}
