import { FormControl, FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material'

type Props = {
  font: string
  updateFont: (font: string) => void
}

const EditLinkFont = (props: Props) =>
  <Stack direction='column' spacing={1}>
    <FormControl>
      <RadioGroup
        aria-labelledby='font-select-form'
        name='link-font'
        onChange={event => props.updateFont(event.target.value)}
        value={props.font}
      >
        <FormControlLabel
          control={<Radio />}
          label='Default'
          sx={{
            '& .MuiTypography-root': {
              fontSize: '1rem',
            },
          }}
          value='default'
        />
        <FormControlLabel
          control={<Radio />}
          label='Classical'
          sx={{
            '& .MuiTypography-root': {
              fontFamily: 'Times New Roman, Times, serif',
              fontSize: '1rem',
            },
          }}
          value='classical'
        />
        <FormControlLabel
          control={<Radio />}
          label='Modern'
          sx={{
            '& .MuiTypography-root': {
              fontFamily: 'Arial, Helvetica, sans-serif',
              fontSize: '1rem',
            },
          }}
          value='modern'
        />
        <FormControlLabel
          control={<Radio />}
          label='Artistic'
          sx={{
            '& .MuiTypography-root': {
              fontFamily: 'Georgia, cursive',
              fontSize: '1rem',
            },
          }}
          value='artistic'
        />
      </RadioGroup>
    </FormControl>
  </Stack>

export default EditLinkFont
