/* eslint-disable max-len */
import type { SvgIconProps } from '@mui/material'
import { SvgIcon } from '@mui/material'

const GoogleFormsLogo = (props: SvgIconProps) =>
  <SvgIcon {...props} viewBox='0 0 48 66' >
    <defs>
      <path d='M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z' id='path-1' />
      <path d='M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z' id='path-3' />
      <path d='M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z' id='path-5' />
      <linearGradient id='linearGradient-7' x1='50.0053945%' x2='50.0053945%' y1='8.58633094%' y2='100.013939%'>
        <stop offset='0%' stopColor='#311B92' stopOpacity='0.2' />
        <stop offset='100%' stopColor='#311B92' stopOpacity='0.02' />
      </linearGradient>
      <path d='M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z' id='path-8' />
      <path d='M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z' id='path-10' />
      <path d='M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z' id='path-12' />
      <path d='M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z' id='path-14' />
      <radialGradient cx='3.16804688%' cy='2.71744318%' fx='3.16804688%' fy='2.71744318%' gradientTransform='translate(0.031680,0.027174),scale(1.000000,0.727273),translate(-0.031680,-0.027174)' id='radialGradient-16' r='161.248516%'>
        <stop offset='0%' stopColor='#FFFFFF' stopOpacity='0.1' />
        <stop offset='100%' stopColor='#FFFFFF' stopOpacity='0' />
      </radialGradient>
    </defs>
    <g fill='none' fillRule='evenodd' id='Page-1' stroke='none' strokeWidth='1'>
      <g id='Consumer-Apps-Forms-Large-VD-R8' transform='translate(-449.000000, -452.000000)'>
        <g id='Hero' transform='translate(0.000000, 63.000000)'>
          <g id='Personal' transform='translate(277.000000, 299.000000)'>
            <g id='Forms-icon' transform='translate(172.000000, 90.000000)'>
              <g id='Group'>
                <g id='Clipped'>
                  <mask fill='white' id='mask-2'>
                    <use xlinkHref='#path-1' />
                  </mask>
                  <g id='SVGID_20_' />
                  <path d='M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L36.9791667,10.3541667 L29.5833333,0 Z' fill='#673AB7' fillRule='nonzero' id='Path' mask='url(#mask-2)' />
                </g>
                <g id='Clipped'>
                  <mask fill='white' id='mask-4'>
                    <use xlinkHref='#path-3' />
                  </mask>
                  <g id='SVGID_20_' />
                  <path d='M19.2291667,50.2916667 L35.5,50.2916667 L35.5,47.3333333 L19.2291667,47.3333333 L19.2291667,50.2916667 Z M19.2291667,32.5416667 L19.2291667,35.5 L35.5,35.5 L35.5,32.5416667 L19.2291667,32.5416667 Z M15.53125,34.0208333 C15.53125,35.2448438 14.5365104,36.2395833 13.3125,36.2395833 C12.0884896,36.2395833 11.09375,35.2448438 11.09375,34.0208333 C11.09375,32.7968229 12.0884896,31.8020833 13.3125,31.8020833 C14.5365104,31.8020833 15.53125,32.7968229 15.53125,34.0208333 Z M15.53125,41.4166667 C15.53125,42.6406771 14.5365104,43.6354167 13.3125,43.6354167 C12.0884896,43.6354167 11.09375,42.6406771 11.09375,41.4166667 C11.09375,40.1926563 12.0884896,39.1979167 13.3125,39.1979167 C14.5365104,39.1979167 15.53125,40.1926563 15.53125,41.4166667 Z M15.53125,48.8125 C15.53125,50.0365104 14.5365104,51.03125 13.3125,51.03125 C12.0884896,51.03125 11.09375,50.0365104 11.09375,48.8125 C11.09375,47.5884896 12.0884896,46.59375 13.3125,46.59375 C14.5365104,46.59375 15.53125,47.5884896 15.53125,48.8125 Z M19.2291667,42.8958333 L35.5,42.8958333 L35.5,39.9375 L19.2291667,39.9375 L19.2291667,42.8958333 Z' fill='#F1F1F1' fillRule='nonzero' id='Shape' mask='url(#mask-4)' />
                </g>
                <g id='Clipped'>
                  <mask fill='white' id='mask-6'>
                    <use xlinkHref='#path-5' />
                  </mask>
                  <g id='SVGID_20_' />
                  <polygon fill='url(#linearGradient-7)' fillRule='nonzero' id='Path' mask='url(#mask-6)' points='30.8813021 16.4520313 47.3333333 32.9003646 47.3333333 17.75' />
                </g>
                <g id='Clipped'>
                  <mask fill='white' id='mask-9'>
                    <use xlinkHref='#path-8' />
                  </mask>
                  <g id='SVGID_20_' />
                  <g id='Group' mask='url(#mask-9)'>
                    <g transform='translate(26.625000, -2.958333)'>
                      <path d='M2.95833333,2.95833333 L2.95833333,16.2708333 C2.95833333,18.7225521 4.94411458,20.7083333 7.39583333,20.7083333 L20.7083333,20.7083333 L2.95833333,2.95833333 Z' fill='#B39DDB' fillRule='nonzero' id='Path' />
                    </g>
                  </g>
                </g>
                <g id='Clipped'>
                  <mask fill='white' id='mask-11'>
                    <use xlinkHref='#path-10' />
                  </mask>
                  <g id='SVGID_20_' />
                  <path d='M4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,4.80729167 C0,2.36666667 1.996875,0.369791667 4.4375,0.369791667 L29.5833333,0.369791667 L29.5833333,0 L4.4375,0 Z' fill='#FFFFFF' fillOpacity='0.2' fillRule='nonzero' id='Path' mask='url(#mask-11)' />
                </g>
                <g id='Clipped'>
                  <mask fill='white' id='mask-13'>
                    <use xlinkHref='#path-12' />
                  </mask>
                  <g id='SVGID_20_' />
                  <path d='M42.8958333,64.7135417 L4.4375,64.7135417 C1.996875,64.7135417 0,62.7166667 0,60.2760417 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,60.2760417 C47.3333333,62.7166667 45.3364583,64.7135417 42.8958333,64.7135417 Z' fill='#311B92' fillOpacity='0.2' fillRule='nonzero' id='Path' mask='url(#mask-13)' />
                </g>
                <g id='Clipped'>
                  <mask fill='white' id='mask-15'>
                    <use xlinkHref='#path-14' />
                  </mask>
                  <g id='SVGID_20_' />
                  <path d='M34.0208333,17.75 C31.5691146,17.75 29.5833333,15.7642187 29.5833333,13.3125 L29.5833333,13.6822917 C29.5833333,16.1340104 31.5691146,18.1197917 34.0208333,18.1197917 L47.3333333,18.1197917 L47.3333333,17.75 L34.0208333,17.75 Z' fill='#311B92' fillOpacity='0.1' fillRule='nonzero' id='Path' mask='url(#mask-15)' />
                </g>
              </g>
              <path d='M29.5833333,0 L4.4375,0 C1.996875,0 0,1.996875 0,4.4375 L0,60.6458333 C0,63.0864583 1.996875,65.0833333 4.4375,65.0833333 L42.8958333,65.0833333 C45.3364583,65.0833333 47.3333333,63.0864583 47.3333333,60.6458333 L47.3333333,17.75 L29.5833333,0 Z' fill='url(#radialGradient-16)' fillRule='nonzero' id='Path' />
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>

export default GoogleFormsLogo
