/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-mixed-operators */
/* eslint-disable complexity */
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import { Button, Card, Chip, Divider, FormControl, FormControlLabel, lighten, Radio, RadioGroup, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import TagManager from 'react-gtm-module'

import SeatSelectField from 'src/components/form-elements/SeatSelectField'
import CompleteFeatureList from 'src/components/pages/Account/Billing/BillingComponents/CompleteFeatureList'
import CreatorCard from 'src/components/pages/Account/Billing/BillingComponents/CreatorCard'
import CreatorProCard from 'src/components/pages/Account/Billing/BillingComponents/CreatorProCard'
import EnterpriseCard from 'src/components/pages/Account/Billing/BillingComponents/EnterpriseCard'
import pricingFeaturesList from 'src/components/pages/Account/Billing/BillingComponents/pricingFeaturesList'
import { useAuth } from 'src/components/providers/AuthProvider'
import background from 'src/images/Pricing/bg.png'
import type { Organisation, PriceLookupKey } from 'src/models/Organisation'

export type SeatCount = {
  label: string
  value: number
}

const seats: SeatCount[] = [
  { label: '1 User', value: 1 },
  { label: '2 Users', value: 2 },
  { label: '3 Users', value: 3 },
  { label: '4 Users', value: 4 },
  { label: '5 Users', value: 5 },
  { label: '6 Users', value: 6 },
  { label: '7 Users', value: 7 },
  { label: '8 Users', value: 8 },
  { label: '9 Users', value: 9 },
  { label: '10 Users', value: 10 },
  { label: '15 Users', value: 15 },
  { label: '20 Users', value: 20 },
  { label: '25 Users', value: 25 },
  { label: '30 Users', value: 30 },
  { label: '35 Users', value: 35 },
  { label: '40 Users', value: 40 },
  { label: '45 Users', value: 45 },
  { label: '50 Users', value: 50 },
]

type Props = {
  readonly setPriceLookupKey: (price: PriceLookupKey) => void
  readonly setTerm: (term: number) => void
  readonly setSeats: (seats: number) => void
  readonly setCheckout: (checkout: string) => void
  readonly account: Organisation
}

const PlanComponent = (props: Props) => {
  const themeItem = useTheme()
  const matches = useMediaQuery(themeItem.breakpoints.down('md'))

  const { currentOrganisation } = useAuth()

  const creator = 19
  const creatorPro = 49
  const enterprise = 79
  const month = 1
  const year = 10

  const [billingCycle, setBillingCycle] = useState(10)
  const [activeBilling, setActiveBilling] = useState(19)
  const [total, setTotal] = useState(190)
  const [seatsSelected, setSeatsSelected] = useState<string>(seats[0].label)
  const [inputSelected, setInputSelected] = useState<SeatCount>(seats[0])
  const disabledNoPlanSelected = activeBilling > 0

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'view_plans',
        userId: currentOrganisation?.id,
        eventProps: {
          city: currentOrganisation?.city,
          country: currentOrganisation?.country,
          email: currentOrganisation?.organisationEmail,
          firstName: currentOrganisation?.firstName,
          lastName: currentOrganisation?.lastName,
          state: currentOrganisation?.region,
          zip: currentOrganisation?.postalCode,
        },
      },
    })
  }, [currentOrganisation])

  const updateTotal = (price: number, term: number, seatCount: number) => {
    switch (price) {
      case creator:
        if (term === year) {
          props.setPriceLookupKey('NEW_CREATOR_YEARLY')
          setTotal(price * term)
          props.setSeats(1)
          setSeatsSelected(seats[0].label)
          setInputSelected(seats[0])
        } else {
          props.setPriceLookupKey('NEW_CREATOR')
          setTotal(price * term)
          props.setSeats(1)
          setSeatsSelected(seats[0].label)
          setInputSelected(seats[0])
        }
        break
      case creatorPro:
        if (term === year) {
          props.setPriceLookupKey('NEW_PRO_YEARLY')
          setTotal(price * term + Math.max(0, (seatCount - 2) * 19 * term))
        } else {
          props.setPriceLookupKey('NEW_PRO')
          setTotal(price * term + Math.max(0, (seatCount - 2) * 19 * term))
        }
        break
      case enterprise:
        if (term === year) {
          props.setPriceLookupKey('NEW_BUSINESS_YEARLY')
          setTotal(price * term + Math.max(0, (seatCount - 3) * 19 * term))
        } else {
          props.setPriceLookupKey('NEW_BUSINESS')
          setTotal(price * term + Math.max(0, (seatCount - 3) * 19 * term))
        }
        break
      default:
        props.setPriceLookupKey('NEW_CREATOR')
        setTotal(price * term)
        break
    }
  }

  const handleSeatChange = (seat: SeatCount) => {
    setInputSelected(seat)
    props.setSeats(seat.value)
    updateTotal(activeBilling, billingCycle, seat.value)
  }

  const handleActiveBilling = (price: number) => () => {
    const currentInput = inputSelected
    setInputSelected(currentInput)
    setSeatsSelected(currentInput.label)
    setActiveBilling(price)
    updateTotal(price, billingCycle, currentInput.value)
  }

  const handleBillingCycle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBillingCycle(Number((event.target as HTMLInputElement).value))
    props.setTerm(Number((event.target as HTMLInputElement).value))
    updateTotal(activeBilling, Number((event.target as HTMLInputElement).value), inputSelected.value)
  }

  const handleClick = () => {
    props.setSeats(inputSelected.value)
    props.setCheckout('checkout')
    updateTotal(activeBilling, billingCycle, inputSelected.value)
  }

  const handleDowngrade = () => {
    props.setSeats(inputSelected.value)
    props.setCheckout('cancelconfirmation')
  }

  return (
    <Stack
      alignContent='center'
      alignItems='center'
      direction='column'
      justifyContent='center'
      padding={matches ? 2 : 0}
      spacing={4}
      width={1}
    >
      <Stack
        direction={{ xl: 'row', lg: 'row', md: 'column', sm: 'column', xs: 'column' }}
        maxWidth={1024}
        spacing={2}
      >
        <Stack direction='column' flexGrow={1} spacing={2}>
          <Typography variant='h3'>
            Plan
          </Typography>
          <Stack
            spacing={matches ? 2 : 0}
            sx={{ flexDirection: { xl: 'row', lg: 'row', md: 'column', sm: 'column', xs: 'column' } }}
          >
            <Stack width={1}>
              <CreatorCard
                activeBilling={activeBilling}
                billingCycle={billingCycle}
                creator={creator}
                disabled
                handleBilling={handleActiveBilling}
                month={month}
                seats={inputSelected.value}
              />
            </Stack>
            <Stack
              sx={{
                marginX: { xl: 1, lg: 1, md: 1, sm: 0, xs: 0 },
                marginY: { xl: 0, lg: 0, md: 0, sm: 1, xs: 1 },
              }}
              width={1}
            >
              <CreatorProCard
                activeBilling={activeBilling}
                billingCycle={billingCycle}
                creatorPro={creatorPro}
                disabled
                handleBilling={handleActiveBilling}
                month={month}
                seats={inputSelected.value}
              />
            </Stack>
            <Stack width={1}>
              <EnterpriseCard
                activeBilling={activeBilling}
                billingCycle={billingCycle}
                enterprise={enterprise}
                handleBilling={handleActiveBilling}
                month={month}
                seats={inputSelected.value}
              />
            </Stack>
          </Stack>
        </Stack>

        <Stack direction='column' spacing={2} sx={{ flexShrink: 0 }} width={matches ? 1 : 260}>
          <Typography variant='h3'>
            Summary
          </Typography>
          <Card sx={{ width: 1 }} variant='outlined'>
            <Stack padding={3} spacing={2} width={1}>
              <Typography variant='h4'>
                Billing cycle
              </Typography>
              <Stack alignItems='flex-end' direction='row' justifyContent='space-between' width={1}>
                <FormControl component='fieldset' sx={{ width: 1 }}>
                  <RadioGroup
                    aria-label='orgtype'
                    name='plantype'
                    onChange={handleBillingCycle}
                    row
                    value={billingCycle}
                  >
                    <FormControlLabel control={<Radio />} label='Monthly' value={month} />
                    <FormControlLabel control={<Radio />} label='Annual' value={year} />
                  </RadioGroup>
                </FormControl>
                <Stack paddingX={0} paddingY={1}>
                  <Chip
                    color='primary'
                    label='2 months free'
                    size='small'
                  />
                </Stack>
              </Stack>
              <Divider />
              <Typography variant='h4'>
                Plan
              </Typography>
              <Stack direction='row' justifyContent='space-between'>
                {activeBilling === creator &&
                <Typography variant='body1'>
                  Essential
                </Typography>}
                {activeBilling === creatorPro &&
                <Typography variant='body1'>
                  Pro
                </Typography>}
                {activeBilling === enterprise &&
                <Typography variant='body1'>
                  Business
                </Typography>}
                <Typography variant='body1'>
                  {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total)}
                </Typography>
              </Stack>
              <Stack direction='row' justifyContent='space-between'>
                <SeatSelectField
                  disabled={activeBilling === creator}
                  seatName={seatsSelected}
                  seats={seats}
                  setInputSelected={handleSeatChange}
                  setSeatName={setSeatsSelected}
                />
              </Stack>
              <Divider />
              <Stack direction='row' justifyContent='space-between'>
                {billingCycle === month
                  ? <Typography variant='body1'>
                    Monthly Total
                  </Typography>
                  : <Typography variant='body1'>
                    Yearly Total
                  </Typography>}
                <Typography variant='body1'>
                  {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total)}
                </Typography>
              </Stack>
              <Button
                color='secondary'
                disabled={total === 0}
                fullWidth
                onClick={() => handleClick()}
              >
                {currentOrganisation?.neverTrial
                  ? 'Add payment information'
                  : 'Proceed to payment'}
              </Button>
              {!currentOrganisation?.neverTrial &&
              <Stack direction='row'>
                {disabledNoPlanSelected || props.account.trial
                  ? <Chip label='Secure transaction' size='small' />
                  : <Chip color='error' label='This is your current plan' size='small' variant='subtle' />}
              </Stack>}
              {currentOrganisation?.neverTrial &&
              <Stack>
                <Typography lineHeight={1.3} variant='h4'>
                  Trial
                </Typography>
                <Typography color={themeItem.palette.text.secondary} variant='body2'>
                  {props.account.trial
                    ? 'Update your 14-trial experience, you will not be charged until your trial period ends.'
                    : 'Pick your 14-day trial experience, you will be charged $1 to start your trial.'}
                </Typography>
              </Stack>}
            </Stack>
          </Card>
          {props.account.membershipTier !== 'FREE' &&
          <Stack alignItems='center' direction='row' justifyContent='center' spacing={1}>
            <Typography variant='body1'>
              or
            </Typography>
            <Button onClick={() => handleDowngrade()} variant='text'>
              {props.account.trial
                ? 'Cancel trial'
                : 'Cancel plan'}
            </Button>
          </Stack>}
        </Stack>
      </Stack>
      <Stack
        alignItems='center'
        justifyContent='center'
        marginTop={8}
        sx={{
          background: theme => theme.palette.background.input,
        }}
        width={1}
      >
        <Stack maxWidth='lg' paddingX={2} width={1}>
          <Stack
            alignContent='center'
            alignItems='center'
            direction={{
              xl: 'row',
              lg: 'row',
              md: 'column',
              sm: 'column',
              xs: 'column',
            }}
            spacing={2}
          >
            <Stack paddingY={4} spacing={2} width={1}>
              <Stack width={1}>
                <Typography variant='overline'>
                  By invitation only
                </Typography>
                <Typography variant='h2'>
                  Releese Enterprise
                </Typography>
                <Typography color={themeItem.palette.text.secondary} variant='h6'>
                  For creators and teams that need centralized visibility, control, and support. Get Releese&apos;s
                  powerful, intuitive music business management platform with the most advanced and tailored features
                  and support that scale with you.
                </Typography>
              </Stack>
              <Button
                endIcon={<ArrowForwardRoundedIcon />}
                href='https://tally.so/r/wMXpEA'
                sx={{
                  color: 'white',
                  background: 'black',
                  ':hover': {
                    background: lighten('#000000', 0.3),
                  },
                  width: 'fit-content',
                }}
                target='_blank'
              >
                Request invitation
              </Button>
            </Stack>
            <Stack width={1}>
              <img
                alt='hand drawing'
                height={2000}
                loading='lazy'
                src={background}
                style={{
                  height: '100%',
                  width: '100%',
                }}
                width={2000}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <CompleteFeatureList
        featureGroup={pricingFeaturesList}
      />
    </Stack>
  )
}

export default PlanComponent
