/* eslint-disable max-len */
import { Icon } from '@mui/material'

import boomplayImage from 'src/components/Icons/boomplay.png'

const BoomplayLogo = () =>
  <Icon sx={{ height: 26, width: 26, fontSize: '1rem' }}>
    <img alt='Boomplay Logo' height='100%' src={boomplayImage} width='100%' />
  </Icon>

export default BoomplayLogo
