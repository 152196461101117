
import type { SelectChangeEvent } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import {  Box, MenuItem, Select, Stack, Typography  } from '@mui/material'
import type { Dispatch, SetStateAction } from 'react'

export type SeatCount = {
  label: string
  value: number
}

type Props = {
  setInputSelected: (item: SeatCount) => void
  seats: SeatCount[]
  seatName: string
  setSeatName: Dispatch<SetStateAction<string>>
  disabled: boolean
}

const SeatSelectField = (props: Props) => {
  const handleChange = (event: SelectChangeEvent) => {
    const { target: { value } } = event
    props.setSeatName(value)

    const itemFound = findItem(value)

    if (itemFound) {
      props.setInputSelected(itemFound)
    }
  }

  const findItem = (labelFound: string) => props.seats.find(element => element.label === labelFound)

  return (
    <Select
      disabled={props.disabled}
      id='selectseat'
      onChange={handleChange}
      renderValue={selected => <Box sx={{ display: 'flex', flexWrap: 'wrap', height: 48, width: 1 }}>
        <Stack alignItems='center' direction='row' height={1} key={selected} spacing={1} width={1}>
          <Typography noWrap variant='body1' width={1}>{selected}</Typography>
        </Stack>
      </Box>}
      sx={{ height: 48, flexGrow: 1, '&.MuiList-root': { paddingBottom: 0, paddingTop: 0 } }}
      value={props.seatName}
      variant='outlined'
    >
      {props.seats.map(seat =>
        <MenuItem key={seat.label} value={seat.label}>
          <Stack alignItems='center' direction='row' height={32} spacing={1} >
            <Typography noWrap variant='body1'>
              {seat.label}
            </Typography>
          </Stack>
        </MenuItem>)}
    </Select>
  )
}

export default SeatSelectField
