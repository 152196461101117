/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded'
import { LoadingButton } from '@mui/lab'
import { Paper, Stack, Typography, useTheme } from '@mui/material'
import type { FirebaseError } from 'firebase/app'
import { multiFactor, PhoneAuthProvider, PhoneMultiFactorGenerator, RecaptchaVerifier } from 'firebase/auth'
import { useEffect, useState } from 'react'
import OtpInput from 'react-otp-input'
import { useTimer } from 'react-timer-hook'

import firebaseErrorMessages from 'src/data/firebaseAuthErrorCodes'
import { auth } from 'src/utils/firebase'

type Props = {
  phoneNumber: string
  setStep: (step: string) => void
}

const MfaAskCode = (props: Props) => {
  const themeHook = useTheme()
  const widgetId = 'recaptcha'
  const [phoneCode, setPhoneCode] = useState('')
  const [loading, setLoading] = useState(false)
  const [loadingMfa, setLoadingMfa] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [verificationId, setVerificationId] = useState('')
  const currentUser = auth.currentUser
  const [codeSent, setCodeSent] = useState(false)

  const {
    seconds,
    isRunning,
    start,
    restart,
  } = useTimer({
    onExpire: () => console.warn('onExpire called'),
    expiryTimestamp: new Date(),
  })

  const multiFactorUser = multiFactor(currentUser!)

  const handleClick = async () => {
    const recaptchaVerifier = new RecaptchaVerifier(
      widgetId,
      {
        'size': 'invisible',
      },
      auth
    )
    const time = new Date()
    time.setSeconds(time.getSeconds() + 30)
    restart(time)
    await multiFactorUser.getSession()
      .then(async multiFactorSession => {
        setLoading(true)
        setCodeSent(false)
        // Specify the phone number and pass the MFA session.
        const phoneInfoOptions = {
          phoneNumber: props.phoneNumber,
          session: multiFactorSession,
        }
        const phoneAuthProvider = new PhoneAuthProvider(auth)
        // Send SMS verification code.
        await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions, recaptchaVerifier
        ).then(setVerificationId)
      })
      .catch((error: FirebaseError) => {
        setErrorMessage(firebaseErrorMessages.find(errorCode => errorCode.code === error.code)!.message)
        setLoading(false)
      })
      .finally(() => {
        setLoading(false)
        setCodeSent(true)
      })
  }

  const handleConfirm = async () => {
    setLoadingMfa(true)
    const authCredential = PhoneAuthProvider.credential(verificationId, phoneCode)
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(authCredential)
    await multiFactorUser.enroll(multiFactorAssertion)
      .then(() => {
        props.setStep('done')
        setLoadingMfa(false)
      })
      .catch((error: FirebaseError) => {
        setLoadingMfa(false)
        setErrorMessage(firebaseErrorMessages.find(errorCode => errorCode.code === error.code)!.message)
      })
  }

  useEffect(() => {
    start()
    void handleClick()
  }, [])

  return (
    <Stack alignItems='center' id='handle-login'>
      <Paper
        elevation={0}
        sx={{
          backgroundColor: theme => theme.palette.primary.main,
          borderRadius: 16,
        }}
      >
        <Stack
          alignItems='center'
          justifyContent='center'
          padding={4}
        >
          <LocalPhoneRoundedIcon
            sx={{
              color: theme => theme.palette.primary.contrastText,
              fontSize: '144px',
            }}
          />
        </Stack>
      </Paper>
      <Typography
        color={themeHook.palette.text.secondary}
        sx={{
          marginTop: 1,
        }}
        textAlign='center'
        variant='h4'
      >
        2FA Setup
      </Typography>
      {!loading &&
        <Typography
          color={themeHook.palette.text.label}
          textAlign='center'
          variant='body1'
        >
          Please click the button below to send the code
        </Typography>}
      {loading &&
        <Typography
          color={themeHook.palette.text.label}
          textAlign='center'
          variant='body2'
        >
          Please enter the code sent to your phone number
        </Typography>}
      <Stack alignItems='center' marginTop={2} spacing={2} width={1}>
        <OtpInput
          focusStyle={{
            borderColor: themeHook.palette.primary.main,
            borderSize: '1px',
            borderStyle: 'solid',
            outline: 'none',
          }}
          inputStyle={{
            borderColor: themeHook.palette.border,
            borderSize: '1px',
            borderStyle: 'solid',
            borderRadius: '8px',
            width: '54px',
            height: '54px',
            fontSize: '1rem',
            color: themeHook.palette.text.primary,
            fontWeight: '400',
            backgroundColor: themeHook.palette.background.input,
          }}
          isDisabled={loadingMfa || !codeSent}
          isInputNum
          numInputs={6}
          onChange={(event: string) => setPhoneCode(event)}
          separator={<span style={{ width: '8px' }} />}
          shouldAutoFocus
          value={phoneCode}
        />
        <Stack direction='row' justifyContent='center' spacing={1} width={1}>
          <LoadingButton
            disabled={isRunning}
            loading={loading}
            onClick={() => void handleClick()}
            variant='text'
          >
            Re-send code
          </LoadingButton>
          <LoadingButton
            disabled={!codeSent}
            loading={loadingMfa}
            onClick={() => void handleConfirm()}
            onSubmit={() => void handleConfirm()}
            type='submit'
            variant='contained'
          >
            Confirm 2FA enrollment
          </LoadingButton>
        </Stack>
        {isRunning &&
        <Typography color='text.label' variant='body2'>
          You can re-send a code in
          {` ${seconds} seconds.`}
        </Typography>}
        {errorMessage &&
        <Typography color='error'>{errorMessage}</Typography>}
        <Stack id='recaptcha' />
      </Stack>
    </Stack>
  )
}

export default MfaAskCode
