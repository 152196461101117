import type { Components } from '@mui/material'

import SPACING from 'src/styles/spacing'

const MuiAutocomplete: Components['MuiAutocomplete'] = {
  styleOverrides: {
    root: {
      '.MuiInputBase-root': {
        padding: `0 ${SPACING.addPixels(SPACING.INPUT_SIDE_PADDING, -SPACING.INPUT_BORDER_WIDTH)}`,
      },
    },
    input: {
      '&.MuiFilledInput-input': {
        padding: 0,
      },
    },
    tag: {
      margin: 0,
      marginRight: SPACING.themeSpacing(0.5),
      height: '30px',
      borderRadius: '32px',
    },
  },
}

export default MuiAutocomplete
