import { ListItemIcon, ListItemText, Stack } from '@mui/material'
import type {
  ComboboxItemProps } from '@udecode/plate'

import { getCodeDescription, getCodeIcon } from 'src/components/pages/Notes/Modern/commands/CommandItems'

const SelectItems = (props: ComboboxItemProps<undefined>) => {
  const text = props.item.text as string
  return (
    <Stack alignItems='center' direction='row' width={1}>
      <ListItemIcon
        sx={{
          fontSize: 30,
        }}
      >
        {getCodeIcon(text)}
      </ListItemIcon>
      <ListItemText
        primary={text}
        primaryTypographyProps={{
          sx: {
            color: theme => theme.palette.text.primary,
            fontSize: '16px!important',
          },
        }}
        secondary={getCodeDescription(text)}
        secondaryTypographyProps={{
          sx: {
            color: theme => theme.palette.text.secondary,
            fontSize: '14px!important',
          },
        }}
      />
    </Stack>
  )
}

export default SelectItems
