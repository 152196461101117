import { Typography } from '@mui/material'

type Props = {
  color?: string
  isForward?: boolean
  name?: string
}

const Caret = (props: Props) =>
  <span
    contentEditable={false}
    style={{
      position: 'absolute',
      pointerEvents: 'none',
      userSelect: 'none',
      height: '1.2em',
      width: '2px',
      background: props.name && props.name.length > 0 ? props.color : 'transparent',
      left: props.isForward ? '100%' : '0%',
    }}
  >
    <span
      style={{
        position: 'absolute',
      }}
    >
      <span
        contentEditable={false}
        style={{
          position: 'absolute',
          borderRadius: '4px',
          paddingLeft: 4,
          paddingRight: 4,
          top: '2px',
          pointerEvents: 'none',
          userSelect: 'none',
          transform: 'translateY(-100%)',
          fontSize: 12,
          whiteSpace: 'nowrap',
          background: props.color,
          left: props.isForward ? '100%' : '0%',
        }}
      >
        <Typography color='white' variant='body2'>
          {props.name ?? ''}
        </Typography>
      </span>
    </span>
  </span>

export default Caret

