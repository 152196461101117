/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable sonarjs/no-duplicated-branches */
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import GppMaybeRoundedIcon from '@mui/icons-material/GppMaybeRounded'
import { LoadingButton } from '@mui/lab'
import { Dialog, IconButton, Paper, Stack, Typography, useTheme } from '@mui/material'
import type { FirebaseError } from 'firebase/app'
import { multiFactor } from 'firebase/auth'
import { useState } from 'react'

import { auth } from 'src/utils/firebase'

type Props = {
  close: () => void
  open: boolean
}

const MfaRemoveMfaModal = (props: Props) => {
  const themeItem = useTheme()
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const multiFactorUser = multiFactor(auth.currentUser!)
  const handleClick = () => {
    setLoading(true)
    const options = multiFactorUser.enrolledFactors

    return multiFactorUser.unenroll(options[0])
      .then(() => setLoading(false))
      .then(props.close)
      .catch((error: FirebaseError) => {
        if (error.code === 'auth/requires-recent-login') {
          setErrorMessage('Your login has expired, please logout, login and try again.')
          setLoading(false)
        } else {
          setErrorMessage(error.code)
          setLoading(false)
        }
      })
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullWidth
      keepMounted={false}
      maxWidth='xs'
      onClose={props.close}
      open={props.open}
    >
      <Stack width={1}>
        <Stack direction='row' justifyContent='space-between' padding={2} width={1}>
          <Typography variant='h3'>
            Remove 2FA
          </Typography>
          <IconButton
            aria-label='close'
            onClick={props.close}
          >
            <CloseRoundedIcon />
          </IconButton>
        </Stack>
        <Stack alignItems='center' width={1}>
          <Stack
            alignItems='center'
            direction='column'
            height={1}
            paddingBottom={3}
            paddingX={3}
            spacing={3}
            width={1}
          >
            <Stack alignItems='center' id='handle-login'>
              <Paper
                elevation={0}
                sx={{
                  backgroundColor: theme => theme.palette.error.main,
                  borderRadius: 16,
                }}
              >
                <Stack
                  alignItems='center'
                  justifyContent='center'
                  padding={4}
                >
                  <GppMaybeRoundedIcon
                    sx={{
                      color: theme => theme.palette.primary.contrastText,
                      fontSize: '144px',
                    }}
                  />
                </Stack>
              </Paper>
              <Typography
                color={themeItem.palette.text.primary}
                lineHeight={1.4}
                sx={{
                  marginTop: 1,
                }}
                textAlign='center'
                variant='h3'
              >
                Remove 2FA
              </Typography>
              <Typography
                color={themeItem.palette.text.secondary}
                textAlign='center'
                variant='body1'
              >
                By removing your second factor, your account will not be as secure
                and you expose yourself to cyber security risks
              </Typography>
              <LoadingButton
                color='error'
                loading={loading}
                onClick={handleClick}
                sx={{
                  marginTop: 2,
                }}
                variant='contained'
              >
                I understand the risks, remove 2FA
              </LoadingButton>
              <Typography color='error' variant='helperText'>
                {errorMessage}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default MfaRemoveMfaModal
