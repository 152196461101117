/* eslint-disable react/no-array-index-key */
import { Grid, Stack } from '@mui/material'

import ProjectGridSkeleton from 'src/components/pages/Projects/ProjectTabs/ProjectsTab/ProjectGridSkeleton'

const ProjectsGridLoading = () =>
  <Stack alignItems='center' height={1} overflow='auto' padding={1} width={1}>
    <Stack maxWidth={1332} width={1}>
      <Grid
        container
        spacing={0}
        width={1}
      >
        {[...Array.from({ length: 20 })].map((_item, index) =>
          <Grid
            item
            key={`${index}-test`}
            lg={2.4}
            md={3}
            sm={3}
            xl={2.4}
            xs={6}
          >
            <ProjectGridSkeleton
              key={index.toString()}
            />
          </Grid>)}
      </Grid>
    </Stack>
  </Stack>

export default ProjectsGridLoading
