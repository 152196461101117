import { Stack } from '@mui/material'
import { useEffect, useState } from 'react'

import { getRecordingsByProjectIdPublic } from 'src/api/recordings'
import Loading from 'src/components/Loading'
import RecordingsTableEmpty from 'src/components/pages/Projects/ProjectTabs/RecordingsTab/RecordingsTableEmpty'
import ShareProjectRecordingItem from 'src/components/pages/Share/ShareComponents/ShareProjectRecordingItem'
import type Project from 'src/models/Project'
import type Recording from 'src/models/Recording'

type Props = {
  project: Project
}

const ShareProjectRecordings = (props: Props) => {
  const [recordings, setRecordings] = useState<Recording[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getRecordingsByProjectIdPublic(props.project.id)
      .then(recordingItems => {
        // eslint-disable-next-line max-len
        const newArray = recordingItems.sort((a, b) => props.project.recordings.indexOf(a.id) - props.project.recordings.indexOf(b.id))
        setRecordings(newArray)
      })
      .finally(() => setLoading(false))
  }, [props.project])
  return (
    <Stack
      sx={{
        width: 1,
        height: 1,
      }}
    >
      <Stack
        alignItems='center'
        padding={2}
        spacing={2}
        width={1}
      >
        {loading
          ? <Loading />
          : recordings.length > 0
            ? recordings.map(recording =>
              <ShareProjectRecordingItem
                key={recording.title}
                project={props.project}
                recording={recording}
              />)
            : <Stack height={1}>
              <RecordingsTableEmpty />
            </Stack>}
      </Stack>
    </Stack>
  )
}

export default ShareProjectRecordings
