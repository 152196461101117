/* eslint-disable max-lines */
import { alpha } from '@mui/material'
import type { ThemeOptions } from '@mui/material/styles'
import { createTheme, darken } from '@mui/material/styles'

import baseThemeOptions from './base.theme'
import COLOR from 'src/styles/colors'
import { mergeDeep } from 'src/utils/objectUtils'

// Actual theme declaration
// https://www.figma.com/file/joURga8mwrUDLQAJXzqVhl/releese-branding
// https://www.figma.com/file/H9Tnz7fJqdZHyknZ2oQeqc/Design-System
const themeOptions: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: COLOR.PRIMARY.LIGHT,
      subtleResting: COLOR.PRIMARY.DARK,
      containedResting: COLOR.PRIMARY.DARK,
      contrast2: COLOR.PRIMARY.DARK,
    },
    secondary: {
      main: COLOR.SECONDARY.DARKMODE,
      subtleResting: COLOR.SECONDARY.DARK,
      containedResting: COLOR.SECONDARY.DARK,
    },
    success: {
      main: COLOR.SUCCESS.DARKMODE,
      componentBackground: COLOR.SUCCESS.DARK,
    },
    warning: {
      main: COLOR.WARNING.DARKMODE,
      componentBackground: COLOR.WARNING.DARK,
    },
    info: {
      main: COLOR.INFO.DARKMODE,
      componentBackground: COLOR.INFO.DARK,
    },
    error: {
      main: COLOR.ERROR.DARKMODE,
      componentBackground: COLOR.ERROR.DARK,
    },
    background: {
      default: COLOR.SURFACE.DARK_BACKGROUND,
      input: COLOR.SURFACE.DARK_PAPER,
      elevatedCard: COLOR.SURFACE.DARK_PAPER_ELEVATED,
    },
    text: {
      primary: COLOR.TEXT.DARK_PRIMARY,
      secondary: COLOR.TEXT.DARK_SECONDARY,
      title: COLOR.TEXT.DARK_PRIMARY,
      disabled: COLOR.TEXT.DARK_DISABLED,
      link: COLOR.TEXT.DARK_LINK,
      label: COLOR.TEXT.DARK_LABEL,
    },
    action: {
      active: COLOR.PRIMARY.CONTRAST_2,
    },
    divider: COLOR.OTHER.DARK_DIVIDER,
    border: COLOR.OTHER.DARK_DIVIDER,
    rating: COLOR.OTHER.RATING_ACTIVE,
    facebook: COLOR.SOCIAL.FACEBOOK,
    apple: COLOR.SOCIAL.APPLE,
    amazon: COLOR.STREAMING.AMAZON_MUSIC,
    soundcloud: COLOR.STREAMING.SOUNDCLOUD,
    google: COLOR.SOCIAL.GOOGLE,
    appleMusic: COLOR.STREAMING.APPLE_MUSIC,
    youtube: COLOR.SOCIAL.YOUTUBE,
    spotify: COLOR.STREAMING.SPOTIFY,
    deezer: COLOR.STREAMING.DEEZER,
    instagram: COLOR.SOCIAL.INSTAGRAM,
    twitter: COLOR.SOCIAL.TWITTER,
    tiktok: COLOR.SOCIAL.TIKTOK,
    shadow: COLOR.OTHER.SHADOW_DARKMODE,
    waveform: COLOR.OTHER.WAVEFORM_DARK,
    stripeBg: COLOR.OTHER.DARK_FILLED_INPUT_BACKGROUND,
    tableHoverBg: COLOR.OTHER.TABLE_HOVER_DARK,
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'subtle', color: 'primary' },
          style: {
            backgroundColor: alpha(COLOR.PRIMARY.DARKMODE, 0.16),
            borderColor: alpha(COLOR.PRIMARY.DARKMODE, 0.16),
            color: COLOR.PRIMARY.DARKMODE,
            ':focus-within.Mui-focused': {
              backgroundColor: alpha(COLOR.PRIMARY.DARKMODE, 0.24),
              borderColor: alpha(COLOR.PRIMARY.DARKMODE, 0.24),
            },
            ':hover': {
              backgroundColor: alpha(COLOR.PRIMARY.DARKMODE, 0.24),
              borderColor: alpha(COLOR.PRIMARY.DARKMODE, 0.24),
            },
            '&.Mui-disabled': {
              backgroundColor: COLOR.ACTION.DARK_DISABLED_BG,
              borderColor: COLOR.ACTION.DARK_DISABLED_BG,
              color: COLOR.TEXT.DARK_DISABLED,
            },
          },
        },
        {
          props: { variant: 'subtle', color: 'secondary' },
          style: {
            backgroundColor: alpha(COLOR.SECONDARY.DARKMODE, 0.16),
            borderColor: alpha(COLOR.SECONDARY.DARKMODE, 0.16),
            color: COLOR.SECONDARY.DARKMODE,
            ':focus-within.Mui-focused': {
              backgroundColor: alpha(COLOR.SECONDARY.DARKMODE, 0.24),
              borderColor: alpha(COLOR.SECONDARY.DARKMODE, 0.24),
            },
            ':hover': {
              backgroundColor: alpha(COLOR.SECONDARY.DARKMODE, 0.24),
              borderColor: alpha(COLOR.SECONDARY.DARKMODE, 0.24),
            },
            '&.Mui-disabled': {
              backgroundColor: COLOR.ACTION.DARK_DISABLED_BG,
              borderColor: COLOR.ACTION.DARK_DISABLED_BG,
              color: COLOR.TEXT.DARK_DISABLED,
            },
          },
        },
        {
          props: { variant: 'subtle', color: 'error' },
          style: {
            backgroundColor: alpha(COLOR.ERROR.DARKMODE, 0.75),
            borderColor: alpha(COLOR.ERROR.DARKMODE, 0.75),
            color: COLOR.ERROR.DARKMODE,
            ':focus-within.Mui-focused': {
              backgroundColor: alpha(COLOR.ERROR.DARKMODE, 1),
              borderColor: alpha(COLOR.ERROR.DARKMODE, 1),
            },
            ':hover': {
              backgroundColor: alpha(COLOR.ERROR.DARKMODE, 1),
              borderColor: alpha(COLOR.ERROR.DARKMODE, 1),
            },
            '&.Mui-disabled': {
              backgroundColor: COLOR.ACTION.DARK_DISABLED_BG,
              borderColor: COLOR.ACTION.DARK_DISABLED_BG,
              color: COLOR.TEXT.DARK_DISABLED,
            },
          },
        },
        {
          props: { variant: 'subtle', color: 'success' },
          style: {
            backgroundColor: alpha(COLOR.SUCCESS.DARKMODE, 0.16),
            borderColor: alpha(COLOR.SUCCESS.DARKMODE, 0.16),
            color: COLOR.SUCCESS.DARKMODE,
            ':focus-within.Mui-focused': {
              backgroundColor: alpha(COLOR.SUCCESS.DARKMODE, 0.24),
              borderColor: alpha(COLOR.SUCCESS.DARKMODE, 0.24),
            },
            ':hover': {
              backgroundColor: alpha(COLOR.SUCCESS.DARKMODE, 0.24),
              borderColor: alpha(COLOR.SUCCESS.DARKMODE, 0.24),
            },
            '&.Mui-disabled': {
              backgroundColor: COLOR.ACTION.DARK_DISABLED_BG,
              borderColor: COLOR.ACTION.DARK_DISABLED_BG,
              color: COLOR.TEXT.DARK_DISABLED,
            },
          },
        },
        {
          props: { variant: 'subtle', color: 'info' },
          style: {
            backgroundColor: alpha(COLOR.INFO.DARKMODE, 0.16),
            borderColor: alpha(COLOR.INFO.DARKMODE, 0.16),
            color: COLOR.INFO.DARKMODE,
            ':focus-within.Mui-focused': {
              backgroundColor: alpha(COLOR.INFO.DARKMODE, 0.24),
              borderColor: alpha(COLOR.INFO.DARKMODE, 0.24),
            },
            ':hover': {
              backgroundColor: alpha(COLOR.INFO.DARKMODE, 0.24),
              borderColor: alpha(COLOR.INFO.DARKMODE, 0.24),
            },
            '&.Mui-disabled': {
              backgroundColor: COLOR.ACTION.DARK_DISABLED_BG,
              borderColor: COLOR.ACTION.DARK_DISABLED_BG,
              color: COLOR.TEXT.DARK_DISABLED,
            },
          },
        },
        {
          props: { variant: 'contained', color: 'inherit' },
          style: {
            backgroundColor: COLOR.OTHER.SHADOW_DARKMODE,
            background: COLOR.OTHER.SHADOW_DARKMODE,
            borderColor: COLOR.OTHER.SHADOW_DARKMODE,
            color: COLOR.TEXT.DARK_PRIMARY,
            ':focus-within.Mui-focused': {
              backgroundColor: alpha(COLOR.OTHER.SHADOW_DARKMODE, 0.24),
              borderColor: alpha(COLOR.OTHER.SHADOW_DARKMODE, 0.24),
            },
            ':hover': {
              backgroundColor: alpha(COLOR.OTHER.SHADOW_DARKMODE, 0.24),
              borderColor: alpha(COLOR.OTHER.SHADOW_DARKMODE, 0.24),
            },
            '&.Mui-disabled': {
              backgroundColor: COLOR.ACTION.DARK_DISABLED_BG,
              borderColor: COLOR.ACTION.DARK_DISABLED_BG,
              color: COLOR.TEXT.DARK_DISABLED,
            },
          },
        },
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            backgroundColor: COLOR.PRIMARY.DARKMODE,
            borderColor: COLOR.PRIMARY.DARKMODE,
            ':hover': {
              backgroundColor: darken(COLOR.PRIMARY.DARKMODE, 0.24),
              borderColor: darken(COLOR.PRIMARY.DARKMODE, 0.24),
            },
          },
        },
        {
          props: {
            rounded: true,
          },
          style: {
            borderRadius: 100,
            color: COLOR.PRIMARY.CONTRAST_2,
          },
        },
      ],
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*::-webkit-scrollbar': {
          background: 'transparent',
          width: 8,
          height: 8,
        },
        '*::-webkit-scrollbar-corner': {
          background: 'rgba(0,0,0,0)',
          backgroundColor: 'rgba(0,0,0,0)',
        },
        '*::-webkit-scrollbar-thumb': {
          background: COLOR.OTHER.DARK_DIVIDER,
          borderRadius: 16,
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          overflow: 'auto',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          overflow: 'auto',
          '& .MuiBackdrop-root': {
            backdropFilter: 'blur(4px)',
            background: 'rgba(256, 256, 256, 0.1)!important',
            boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
          },
        },
        paper: {
          backgroundColor: COLOR.SURFACE.DARK_BACKGROUND,
          backgroundImage: 'none',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& label[data-shrink=false]+.MuiInputBase-formControl > .MuiFilledInput-input::placeholder': {
            opacity: '0.42!important',
          },
        },
      },
    },
    MuiFab: {
      variants: [
        {
          props: { color: 'primary' },
          style: {
            backgroundColor: COLOR.PRIMARY.DARKMODE,
            borderColor: COLOR.PRIMARY.DARKMODE,
            color: COLOR.PRIMARY.CONTRAST_1,
            ':hover': {
              backgroundColor: darken(COLOR.PRIMARY.DARKMODE, 0.24),
              borderColor: darken(COLOR.PRIMARY.DARKMODE, 0.24),
            },
          },
        },
      ],
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: COLOR.OTHER.DARK_FILLED_INPUT_BACKGROUND,
          borderColor: COLOR.OTHER.DARK_DIVIDER,
          ':focus-within.Mui-focused': {
            backgroundColor: COLOR.OTHER.DARK_FILLED_INPUT_BACKGROUND,
            borderColor: `${COLOR.PRIMARY.DARKMODE}!important`,
            '& .MuiSvgIcon-root': {
              color: COLOR.PRIMARY.DARKMODE,
            },
          },
          '& .MuiInputAdornment-root': {
            padding: 0,
            color: COLOR.TEXT.LABEL,
          },
          ':hover': {
            backgroundColor: COLOR.OTHER.DARK_FILLED_INPUT_BACKGROUND,
          },
          '&.Mui-disabled': {
            borderColor: COLOR.ACTION.DARK_DISABLED_BG,
            backgroundColor: COLOR.ACTION.DARK_DISABLED_BG,
            color: COLOR.TEXT.DARK_DISABLED,
          },
          '&.Mui-error': {
            backgroundColor: COLOR.OTHER.DARK_FILLED_INPUT_BACKGROUND,
            borderColor: COLOR.ERROR.DARKMODE,
            ':focus-within.Mui-focused': {
              backgroundColor: COLOR.OTHER.DARK_FILLED_INPUT_BACKGROUND,
              borderColor: COLOR.ERROR.DARKMODE,
            },
          },
        },
        input: {
          color: COLOR.TEXT.DARK_PRIMARY,
        },
      },
      variants: [
        {
          props: { color: 'warning' },
          style: {
            backgroundColor: COLOR.OTHER.DARK_FILLED_INPUT_BACKGROUND,
            borderColor: COLOR.WARNING.DARKMODE,
            ':focus-within.Mui-focused': {
              backgroundColor: COLOR.OTHER.DARK_FILLED_INPUT_BACKGROUND,
              borderColor: COLOR.WARNING.DARKMODE,
            },
          },
        },
        {
          props: { color: 'success' },
          style: {
            backgroundColor: COLOR.OTHER.DARK_FILLED_INPUT_BACKGROUND,
            borderColor: COLOR.SUCCESS.DARKMODE,
            ':focus-within.Mui-focused': {
              backgroundColor: COLOR.OTHER.DARK_FILLED_INPUT_BACKGROUND,
              borderColor: COLOR.SUCCESS.DARKMODE,
            },
          },
        },
      ],
    },

    MuiChip: {
      variants: [
        {
          props: { variant: 'subtle', color: 'primary' },
          style: {
            backgroundColor: alpha(COLOR.PRIMARY.DARKMODE, 0.16),
            color: COLOR.PRIMARY.DARKMODE,
          },
        },
        {
          props: { variant: 'subtle', color: 'secondary' },
          style: {
            backgroundColor: alpha(COLOR.SECONDARY.DARKMODE, 0.16),
            color: COLOR.SECONDARY.DARKMODE,
          },
        },
        {
          props: { variant: 'subtle', color: 'success' },
          style: {
            backgroundColor: alpha(COLOR.SUCCESS.DARKMODE, 0.16),
            color: COLOR.SUCCESS.DARKMODE,
          },
        },
        {
          props: { variant: 'subtle', color: 'warning' },
          style: {
            backgroundColor: alpha(COLOR.WARNING.DARKMODE, 0.16),
            color: COLOR.WARNING.DARKMODE,
          },
        },
        {
          props: { variant: 'subtle', color: 'error' },
          style: {
            backgroundColor: alpha(COLOR.ERROR.DARKMODE, 0.16),
            color: COLOR.ERROR.DARKMODE,
          },
        },
        {
          props: { color: 'default' },
          style: {
            color: COLOR.TEXT.DARK_PRIMARY,
            backgroundColor: alpha(COLOR.TEXT.DARK_PRIMARY, 0.16),
          },
        },
      ],
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: COLOR.SURFACE.DARK_BACKGROUND,
          '&.MuiPaper-outlined': {
            borderColor: COLOR.OTHER.DARK_DIVIDER,
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          background: COLOR.SURFACE.DARK_BACKGROUND,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: COLOR.PRIMARY.DARKMODE,
          '&.Mui-selected': {
            background: 'rgba(189, 181, 253, 0.16)',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        tag: {
          border: `1px solid ${COLOR.OTHER.DARK_DIVIDER}`,
          background: COLOR.SURFACE.DARK_BACKGROUND,
        },
      },
    },
    MuiStep: {
      styleOverrides: {
        root: {
          '&.Mui-completed': {
            color: COLOR.SUCCESS.DARKMODE,
          },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-completed': {
            color: COLOR.SUCCESS.DARKMODE,
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          '&.MuiStepIcon-root': {
            '&.Mui-completed': {
              color: COLOR.SUCCESS.DARKMODE,
            },
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          '& .MuiSwitch-switchBase': {
            '&.Mui-checked': {
              color: COLOR.SURFACE.BACKGROUND,
              '&.Mui-disabled + .MuiSwitch-track': {
                color: COLOR.SURFACE.BACKGROUND,
              },
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              color: COLOR.SURFACE.BACKGROUND,
            },
          },
          '& .MuiSwitch-track': {
            backgroundColor: COLOR.OTHER.DARK_FILLED_INPUT_BACKGROUND,
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          '& .MuiListSubheader-root': {
            color: COLOR.PRIMARY.CONTRAST_2,
          },
          '& .MuiListItemButton-root': {
            color: COLOR.PRIMARY.CONTRAST_2,
          },
          '& .MuiListItemIcon-root': {
            color: COLOR.PRIMARY.CONTRAST_2,
          },
          '& .Mui-selected .MuiListItemIcon-root': {
            color: COLOR.PRIMARY.DARKMODE,
          },
          '& .Mui-selected .MuiListItemText-root': {
            color: COLOR.PRIMARY.DARKMODE,
          },
          '& .Mui-selected': {
            backgroundColor: 'rgba(189, 181, 253, 0.16)',
          },
        },
        dense: {
          '& .MuiListItemButton-dense': {
            color: COLOR.PRIMARY.CONTRAST_2,
          },
        },
      },
    },
  },
}

const theme = createTheme(mergeDeep(baseThemeOptions, themeOptions))

export default theme
