import CloseIcon from '@mui/icons-material/Close'
import GavelRoundedIcon from '@mui/icons-material/GavelRounded'
import { LoadingButton } from '@mui/lab'
import { Button, Checkbox, Dialog, FormControl, FormControlLabel, IconButton, Paper, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'

import { agreeReleeseRules } from 'src/api/organisation'
import { useAuth } from 'src/components/providers/AuthProvider'

type Props = {
  close: () => void
  open: boolean
}

const DistributionRulesModal = (props: Props) => {
  const { refreshCurrentOrganisation } = useAuth()
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))
  const [loading, setLoading] = useState(false)

  const [agreeBots, setAgreeBots] = useState(false)
  const [agreeCopyright, setAgreeCopyright] = useState(false)
  const [agreeArtificial, setAgreeArtificial] = useState(false)
  const [agreeShort, setAgreeShort] = useState(false)
  const [agreeCloseAccount, setAgreeCloseAccount] = useState(false)

  const submit = async () => {
    setLoading(true)
    await agreeReleeseRules()
      .then(async () => {
        await refreshCurrentOrganisation()
      })
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='sm'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Stack paddingX={3} paddingY={3} spacing={4} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          width='100%'
        >
          <Typography variant='h3'>
            Rules
          </Typography>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>
        <Stack
          alignItems='center'
          height={1}
          justifyContent='center'
          width='100%'
        >
          <Paper
            elevation={0}
            sx={{
              backgroundColor: theme => theme.palette.primary.main,
              borderRadius: 16,
            }}
          >
            <Stack
              alignItems='center'
              justifyContent='center'
              padding={2}
            >
              <GavelRoundedIcon
                sx={{
                  color: theme => theme.palette.primary.contrastText,
                  fontSize: '64px',
                }}
              />
            </Stack>
          </Paper>
          <Typography
            color={themeItem.palette.text.secondary}
            sx={{
              marginTop: 1,
            }}
            variant='h4'
          >
            Releese Rules
          </Typography>
          <Typography
            color={themeItem.palette.text.label}
            sx={{
              textAlign: 'center',
            }}
            variant='body1'
          >
            Please read and agree to our rules to distribute your release
          </Typography>
        </Stack>
        <Stack direction='column' spacing={2} width={1}>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={agreeBots}
                  disabled={loading}
                  onChange={() => setAgreeBots(oldterms => !oldterms)}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }}
                />
              }
              label={<Typography
                color={themeItem.palette.text.secondary}
                variant='body1'
              >
                I will not use bots or artificial streaming techniques to promote my music
              </Typography>}
            />
          </FormControl>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={agreeCopyright}
                  disabled={loading}
                  onChange={() => setAgreeCopyright(oldterms => !oldterms)}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }}
                />
              }
              label={<Typography
                color={themeItem.palette.text.secondary}
                variant='body1'
              >
                I will not steal music, distribute music that I don&apos;t own, distribute sped-up,
                or slowed down remixes of popular songs
              </Typography>}
            />
          </FormControl>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={agreeArtificial}
                  disabled={loading}
                  onChange={() => setAgreeArtificial(oldterms => !oldterms)}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }}
                />
              }
              label={<Typography
                color={themeItem.palette.text.secondary}
                variant='body1'
              >
                I will not distribute music that was completely made by AI
              </Typography>}
            />
          </FormControl>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={agreeShort}
                  disabled={loading}
                  onChange={() => setAgreeShort(oldterms => !oldterms)}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }}
                />
              }
              label={<Typography
                color={themeItem.palette.text.secondary}
                variant='body1'
              >
                I will not distribute music shorter than 1 minute
              </Typography>}
            />
          </FormControl>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={agreeCloseAccount}
                  disabled={loading}
                  onChange={() => setAgreeCloseAccount(oldterms => !oldterms)}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }}
                />
              }
              label={<Typography
                color={themeItem.palette.text.secondary}
                variant='body1'
              >
                I understand that Releese can close my account if I don&apos;t respect these rules
              </Typography>}
            />
          </FormControl>
        </Stack>
        <Stack direction='row' justifyContent='flex-end' spacing={1} width='100%'>
          <Button
            onClick={() => props.close()}
            variant='text'
          >
            Cancel
          </Button>
          <LoadingButton
            color='primary'
            disabled={!agreeBots || !agreeCopyright || !agreeArtificial || !agreeShort || !agreeCloseAccount}
            loading={loading}
            onClick={() => void submit()}
            variant='contained'
          >
            I underand and agree
          </LoadingButton>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default DistributionRulesModal
