/* eslint-disable complexity */
/* eslint-disable react/jsx-key */
/* eslint-disable sonarjs/no-small-switch */
/* eslint-disable react-hooks/exhaustive-deps */
import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded'
import { Divider, Grid, Paper, Skeleton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'

import { getActivities, getAnalytics, getAnalyticsCloudTrack, getAnalyticsCloudTrackActivities, getAnalyticsCloudTrackCurrent, getAnalyticsCurrent } from 'src/api/analyticsCloud'
import { getDistributedRecordings, getDistributionByProjectId } from 'src/api/distribution'
import AnalyticsDistributionAppBar from 'src/components/pages/Analytics/AnalyticsDistribution/AnalyticsDistributionAppbar'
import AnalyticsMenu from 'src/components/pages/Analytics/AnalyticsMenu/AnalyticsMenu'
import AnalyticsPlatformDistribution from 'src/components/pages/Audience/Components/Overview/OverviewPlatform'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { AnalyticsActivity, AnalyticsCurrentData, AnalyticsHistoricData } from 'src/models/Analytics'
import type Project from 'src/models/Project'
import type Recording from 'src/models/Recording'
import SPACING from 'src/styles/spacing'

type Props = {
  project?: Project
  songstatsId?: string
  artwork?: string
}

const AnalyticsDistribution = (props: Props) => {
  const { currentOrganisation } = useAuth()
  const [loading, setLoading] = useState(false)
  const [loadingHistoric, setLoadingHistoric] = useState(false)
  const [loadingItems, setLoadingItems] = useState(false)
  const [loadingActivities, setLoadingActivities] = useState(false)
  const [currentTab, setCurrentTab] = useState('spotify')
  const [distributionItems, setDistributionItems] = useState<Recording[]>()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))
  const [selectedRecording, setSelectedRecording] = useState('')
  const [historicData, setHistoricData] = useState<AnalyticsHistoricData>()
  const [currentData, setCurrentData] = useState<AnalyticsCurrentData>()
  const [activityData, setActivityData] = useState<AnalyticsActivity>()

  useEffect(() => {
    if (!props.songstatsId) {
      setLoadingItems(true)
      if (props.project?.distributed) {
        void getDistributionByProjectId(props.project.id)
          .then(item => setDistributionItems(item.recordings))
          .finally(() => setLoadingItems(false))
      } else {
        void getDistributedRecordings()
          .then(item => setDistributionItems(item))
          .finally(() => setLoadingItems(false))
      }
    }
  }, [props.project?.id, currentOrganisation?.id, props.songstatsId])

  useEffect(() => {
    if ((selectedRecording && selectedRecording.length > 0) || props.songstatsId) {
      getData()
    }
  }, [selectedRecording, props.songstatsId])

  const getData = () => {
    setCurrentTab('spotify')
    setLoading(true)
    setLoadingHistoric(true)
    setLoadingActivities(true)
    if (selectedRecording && selectedRecording.length > 0 && !props.songstatsId) {
      void getAnalytics(selectedRecording)
        .then(result =>
          setHistoricData(result))
        .finally(() => setLoading(false))
        .catch(() => setLoading(false))
      void getAnalyticsCurrent(selectedRecording)
        .then(resultCurrent =>
          setCurrentData(resultCurrent))
        .finally(() => setLoadingHistoric(false))
        .catch(() => setLoadingHistoric(false))
      void getActivities(selectedRecording)
        .then(resultCurrent =>
          setActivityData(resultCurrent))
        .finally(() => setLoadingActivities(false))
        .catch(() => setLoadingActivities(false))
    } else if (props.songstatsId) {
      void getAnalyticsCloudTrack(props.songstatsId)
        .then(result =>
          setHistoricData(result))
        .finally(() => setLoading(false))
        .catch(() => setLoading(false))
      void getAnalyticsCloudTrackCurrent(props.songstatsId)
        .then(resultCurrent =>
          setCurrentData(resultCurrent))
        .finally(() => setLoadingHistoric(false))
        .catch(() => setLoadingHistoric(false))
      void getAnalyticsCloudTrackActivities(props.songstatsId)
        .then(resultCurrent =>
          setActivityData(resultCurrent))
        .finally(() => setLoadingActivities(false))
        .catch(() => setLoadingActivities(false))
    }
  }

  return (
    <Stack direction='row' height={1} overflow='auto'>
      {!matches && !props.songstatsId &&
      <AnalyticsMenu
        distributionItems={distributionItems}
        loading={loadingItems}
        selectedRecording={selectedRecording}
        setSelectedRecording={setSelectedRecording}
      />}
      {!matches && !props.songstatsId &&
      <Divider orientation='vertical' />}
      <Stack
        alignItems='stretch'
        boxSizing='border-box'
        direction='column'
        height={1}
        minWidth={0}
        padding={0}
        width={1}
      >
        {(selectedRecording.length > 0 || props.songstatsId) &&
        <AnalyticsDistributionAppBar
          currentData={currentData}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />}
        <Stack alignItems='center' height={1} overflow='auto' width={1}>
          {!loading && selectedRecording.length === 0 && !loadingHistoric && !props.songstatsId
            ? <Stack
              alignItems='center'
              height={1}
              justifyContent='center'
              marginLeft='auto'
              marginRight='auto'
              maxWidth={1332}
              width={1}
            >
              <Paper
                elevation={0}
                sx={{
                  backgroundColor: theme.palette.info.componentBackground,
                  borderRadius: 16,
                }}
              >
                <Stack
                  alignItems='center'
                  justifyContent='center'
                  padding={4}
                >
                  <AnalyticsRoundedIcon
                    sx={{
                      color: theme.palette.info.main,
                      fontSize: '144px',
                    }}
                  />
                </Stack>
              </Paper>
              <Typography
                color={theme.palette.text.secondary}
                sx={{
                  marginTop: 1,
                }}
                variant='h4'
              >
                No Recording Selected
              </Typography>
              <Typography
                color={theme.palette.text.label}
                sx={{
                  textAlign: 'center',
                }}
                variant='body1'
              >
                Select a Recording to track Playlists, Charts, UGC and Streams
              </Typography>
            </Stack>
            : <Stack
              marginLeft='auto'
              marginRight='auto'
              maxWidth={1332}
              padding={SPACING.CONTENT_PADDING}
              paddingBottom={props.songstatsId ? 0 : 10}
              width={1}
            >
              {(selectedRecording.length > 0 || props.songstatsId) && !loading && !loadingHistoric &&
              <AnalyticsPlatformDistribution
                activityData={activityData}
                artworkUrl={props.project?.artworkPreviewUrl ??
                  distributionItems?.find(item => item.ISRC === selectedRecording)?.artworkPreviewUrl ??
                  props.artwork ?? ''}
                currentData={currentData}
                currentTab={currentTab}
                historicData={historicData}
                loadingActivities={loadingActivities}
              />}

              {(loading || loadingHistoric) &&
              <Stack spacing={3} width={1}>
                <Typography variant='h2'>
                  <Skeleton />
                </Typography>
                <Grid container spacing={2} sx={{ marginLeft: '-16px!important' }}>
                  {Array.from({ length: 8 }, () =>
                    <Grid item lg={6} md={6} sm={6} xl={3} xs={12}>
                      <Skeleton variant='rounded' width='100%'>
                        <div style={{ paddingTop: '57%' }} />
                      </Skeleton>
                    </Grid>)}
                </Grid>
                <Skeleton height={400} variant='rounded' width='100%'>
                  <div />
                </Skeleton>
              </Stack>}
            </Stack>}
        </Stack>
      </Stack>
    </Stack>
  )
}

export default AnalyticsDistribution
