import axios from 'axios'

import type { PitchDto } from 'src/models/Pitch'
import { Pitch } from 'src/models/Pitch'

const PATH = '/pitch' as const

export const getPitchByProjectId = (project_Id: string) =>
  axios.get<PitchDto>(`${PATH}/project/${project_Id}`)
    .then(response => new Pitch(response.data))

export const createPitch = (pitchItem: Partial<Pitch>) =>
  axios.post<PitchDto>(`${PATH}`, pitchItem)
    .then(response => new Pitch(response.data))

