/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded'
import { Autocomplete, Box, Button, Card, Fab, InputAdornment, Menu, Paper, TextField, Typography, useTheme } from '@mui/material'
import { Stack } from '@mui/system'
import type { PlateRenderElementProps, TElement } from '@udecode/plate'
import { createPluginFactory, useEditorState } from '@udecode/plate'
import { useEffect, useState } from 'react'
import { Transforms } from 'slate'
import { ReactEditor } from 'slate-react'

import { getAllTaskboards, getTaskboard } from 'src/api/taskboards'
import type { MyValue } from 'src/components/pages/Notes/Modern/typescript/plateTypes'
import TaskboardModalItem from 'src/components/pages/Task/TaskDrawer/TaskboardListItemModal'
import TaskBoardView from 'src/components/pages/Task/TaskViews/TaskboardView'
import { TasksboardProvider } from 'src/components/providers/TasksboardProvider'
import Project from 'src/models/Project'
import type { TaskBoard } from 'src/models/Taskboard'

export const ELEMENT_TASKBOARD = 'taskboard'

export const TaskboardElement = (props: PlateRenderElementProps<MyValue, TElement>) => {
  const [taskboard, setTaskboard] = useState<TaskBoard | undefined>()
  const editor = useEditorState()
  const theme = useTheme()
  const { taskboardId } = props.element
  const length = taskboardId as string | undefined
  const [anchorElementEmbed, setAnchorElementEmbed] = useState<HTMLElement | null>(null)
  const openMenuEmbed = Boolean(anchorElementEmbed)

  const [firstDone, setFirstDone] = useState(false)
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    setTaskboard(undefined)
    if (length && length.length > 0) {
      void getTaskboard(length)
        .then(async data => {
          setTaskboard(data)
          setFirstDone(true)
        })
    }
  }, [length, refresh])

  const getTaskBoardMethod = () => {
    if (length && firstDone && length.length > 0) {
      void getTaskboard(length)
        .then(setTaskboard)
    }
  }
  return (
    <div
      {...props.attributes}
      {...props.nodeProps}
      className={props.className}
    >

      <div contentEditable={false} style={{ position: 'relative', userSelect: 'none' }}>
        {taskboard &&
        <Fab
          onClick={event => setAnchorElementEmbed(event.currentTarget)}
          sx={{
            top: -16,
            right: -16,
            position: 'absolute',
          }}
        >
          <EditRoundedIcon />
        </Fab>}

        {taskboard?.id
          ? <div
            style={{
              position: 'relative',
            }}
          >
            <Card
              sx={{
                width: 1,
                height: 700,
              }}
              variant='outlined'
            >
              <TasksboardProvider taskboardId={taskboard.id}>
                <TaskBoardView
                  firstDone
                  getTaskBoardMethod={getTaskBoardMethod}
                  project={new Project({})}
                  setRefresh={setRefresh}
                  taskboard={taskboard}
                />
              </TasksboardProvider>
            </Card>
          </div>
          : <Card sx={{ widht: 1 }} variant='outlined'>
            <Stack alignItems='center' justifyContent='center' padding={2} width={1}>
              <Paper
                elevation={0}
                sx={{
                  backgroundColor: theme.palette.success.componentBackground,
                  borderRadius: 16,
                }}
              >
                <Stack
                  alignItems='center'
                  justifyContent='center'
                  padding={4}
                >
                  <TaskAltRoundedIcon
                    sx={{
                      color: theme.palette.success.main,
                      fontSize: '144px',
                    }}
                  />
                </Stack>
              </Paper>
              <Typography
                color={theme.palette.text.secondary}
                sx={{
                  marginTop: 1,
                }}
                variant='h4'
              >
                This could be a taskboard
              </Typography>
              <Typography
                color={theme.palette.text.label}
                variant='body1'
              >
                Pick a taskboard
              </Typography>
              <Button
                color='success'
                onClick={event => setAnchorElementEmbed(event.currentTarget)}
                startIcon={<AddRoundedIcon />}
                sx={{
                  marginTop: 2,
                }}
                variant='contained'
              >
                Add taskboard
              </Button>
            </Stack>
          </Card>}
        {props.children}

        <UrlInput
          anchorEl={anchorElementEmbed}
          onChange={(value: string) => {
            const path = ReactEditor.findPath(editor, props.element)
            const newProperties = {
              taskboardId: value,
            }
            Transforms.setNodes(editor, newProperties, {
              at: path,
            })
          }}
          open={openMenuEmbed}
          setAnchorElementEmbed={setAnchorElementEmbed}
          taskboard={taskboard}
        />
      </div>
    </div>
  )
}

type UrlInputProps = {
  onChange: (value: string) => void
  anchorEl: Element | null
  setAnchorElementEmbed: React.Dispatch<React.SetStateAction<HTMLElement | null>>
  open: boolean
  taskboard?: TaskBoard
}

const UrlInput = (props: UrlInputProps) => {
  const [taskboard, setTaskboard] = useState<TaskBoard | undefined>(props.taskboard)
  const [taskboards, setTaskboards] = useState<TaskBoard[]>()
  const findTaskboard = (valueFound: string) => taskboards?.find(element => element.id === valueFound)

  const handleTaskboardChange = (event: string) => {
    const test = event

    const itemFound = findTaskboard(test)

    if (itemFound) {
      setTaskboard(itemFound)
    }
  }

  useEffect(() => {
    void getAllTaskboards().then(setTaskboards)
  }, [])

  return (
    <Menu
      anchorEl={props.anchorEl}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      onClose={() => props.setAnchorElementEmbed(null)}
      open={props.open}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
    >
      <Stack
        alignItems='flex-start'
        direction='column'
        padding={2}
        spacing={1}
        width={500}
      >
        {taskboards &&
        <Autocomplete
          autoHighlight
          autoSelect
          fullWidth
          getOptionLabel={option => option.title}
          onChange={(_, newValue) => handleTaskboardChange(newValue?.id ?? '')}
          options={taskboards}
          renderInput={params =>
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                startAdornment: <InputAdornment position='start'>
                  <TaskboardModalItem
                    taskboard={taskboard}
                  />
                </InputAdornment>,
              }}
              placeholder='Select a board'
              sx={{
                marginTop: 0,
              }}
            />}
          renderOption={(renderProps, option) =>
            <Box component='li' {...renderProps} >
              <Stack alignItems='center' direction='row' spacing={1}>
                <TaskboardModalItem
                  taskboard={option}
                />
                <Typography
                  sx={{ color: theme => theme.palette.text.secondary }}
                  variant='body2'
                >
                  {option.title}
                </Typography>
              </Stack>
            </Box>}
          sx={{ marginTop: 0, height: 42 }}
          value={taskboard}
        />}
        <Button
          disabled={!taskboard}
          fullWidth
          onClick={() => {
            if (taskboard?.id) {
              props.onChange(taskboard.id)
              props.setAnchorElementEmbed(null)
            }
          }}
        >
          Save
        </Button>
      </Stack>
    </Menu>
  )
}

export const createTaskboardPlugin = createPluginFactory({
  key: ELEMENT_TASKBOARD,
  isElement: true,
  isVoid: true,
  component: TaskboardElement,
})
