import axios from 'axios'

import type { RecordingDto } from 'src/models/Recording'
import Recording from 'src/models/Recording'

const PATH = '/splits' as const

export const inviteMasterCollaborator = (
  recordingId: string,
  email: string,
  ownership: number,
  credit: string[]
) =>
  axios.post<RecordingDto>(`${PATH}/${recordingId}/master/${email.toLowerCase()}/${ownership}`, { credit })
    .then(response => new Recording(response.data))

export const invitePubCollaborator = (
  recordingId: string,
  email: string,
  ownership: number,
  credit: string[]
) =>
  axios.post<RecordingDto>(`${PATH}/${recordingId}/publishing/${email.toLowerCase()}/${ownership}`, { credit })
    .then(response => new Recording(response.data))

export const remindRightsholders = (
  recordingId: string,
  type: 'master' | 'publishing'
) =>
  axios.post(`${PATH}/reminder/${recordingId}/${type}`)

export const removeCollaborator = (
  recordingId: string,
  orgId: string,
  type: 'master' | 'publishing'
) =>
  axios.delete<RecordingDto>(`${PATH}/${recordingId}/${orgId}/${type}`)
    .then(response => new Recording(response.data))

export const toggleSplit = (
  recordingId: string,
  type: 'master' | 'publishing'
) =>
  axios.put<RecordingDto>(`${PATH}/lock/${recordingId}/${type}`)
    .then(response => new Recording(response.data))

export const editSplit = (
  recordingId: string,
  orgId: string,
  type: 'master' | 'publishing',
  credit: string[],
  ownership: number
) =>
  axios.patch<RecordingDto>(`${PATH}/split/${recordingId}/${orgId}/${type}`, { ownership, credit })
    .then(response => new Recording(response.data))
