/* eslint-disable complexity */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines */
import { TabContext, TabPanel } from '@mui/lab'
import { Alert, Snackbar, Stack, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'

import { savePartialRecording } from 'src/api/recordings'
import RecordingContentTab from 'src/components/pages/Projects/EditProject/EditProjectTabs/RecordingsTab/RecordingTabs/ContentTab/RecordingContentTab'
import RecordingDetailsTab from 'src/components/pages/Projects/EditProject/EditProjectTabs/RecordingsTab/RecordingTabs/DetailsTab/RecordingDetailsTab'
import RecordingPublishingTab from 'src/components/pages/Projects/EditProject/EditProjectTabs/RecordingsTab/RecordingTabs/PublishingTab/RecordingPublishingTab'
import RecordingRightsTab from 'src/components/pages/Projects/EditProject/EditProjectTabs/RecordingsTab/RecordingTabs/RightsTab/RecordingRightsTab'
import { usePlayer } from 'src/components/providers/MusicProvider'
import type Project from 'src/models/Project'
import type Recording from 'src/models/Recording'

export type EditRecordingFormProps = {
  recording: Recording
  onSave: (recording: Recording) => void
  saveProgress: (recording: Recording) => void
  onCancel: () => void
  saving: boolean
  project?: Project
}

type Props = {
  readonly setRecording: React.Dispatch<React.SetStateAction<Recording>>
  readonly recording?: Recording
  readonly project?: Project
  readonly recordingFile?: File
  readonly done?: boolean
  readonly setNewRecordings?: React.Dispatch<React.SetStateAction<File[]>>
  readonly setRecordings?: React.Dispatch<React.SetStateAction<Recording[]>>
  readonly setLoading: React.Dispatch<React.SetStateAction<boolean>>
  readonly setWavesurfer: React.Dispatch<React.SetStateAction<WaveSurfer | undefined>>
  readonly setAudioPeaks: React.Dispatch<React.SetStateAction<number[] | undefined>>
  readonly refresh?: React.Dispatch<React.SetStateAction<boolean>>
  readonly updateRecordingsSubtle?: () => Promise<void>
  readonly currentTab: string
  readonly setLoadingUpload: React.Dispatch<React.SetStateAction<boolean>>
  readonly distributing?: boolean
}

const regexGetSubtitle = /(?<=\[)(.*?)(?=])/
const generateSubtitleRecording = (subtitle: string) => {
  if (subtitle.includes('[')) {
    const subSubtitle = regexGetSubtitle.exec(subtitle)
    if (subSubtitle) {
      const newSmallSubtitle = subtitle.replace(subSubtitle[0], '').replaceAll('[', '').replaceAll(']', '').trim()
      return ` (${newSmallSubtitle}) [${subSubtitle[0].trim()}]`
    } else {
      return ` (${subtitle.trim()})`
    }
  } else if (subtitle.trim().length > 0) {
    return ` (${subtitle.trim()})`
  } else {
    return ''
  }
}

export const generateTitleRecording = (recordingFile?: File, recording?: Recording) => {
  if (recordingFile) {
    return recordingFile.name
  } else if (recording) {
    const subtitle = generateSubtitleRecording(recording.subTitle)
    const features = recording.featuredArtists.length > 0
      ? ` (Feat. ${recording.featuredArtists.map(item => item.name).join(', ')})`
      : ''
    const remixers = recording.remixArtists.length > 0
      ? ` (${recording.remixArtists.map(item => item.name).join(', ')} Remix)`
      : ''
    return `${recording.title}${features}${remixers}${subtitle}`
  } else {
    return ''
  }
}

const EditRecordingComponent = (props: Props) => {
  const { playing, setPlaying } = usePlayer()

  const themeColors = useTheme()
  const matches = useMediaQuery(themeColors.breakpoints.down('md'))

  const [savedToast, setSavedToastOpen] = useState(false)
  const [done, setDone] = useState(props.done ?? false)
  const [saveLoading, setSaveLoading] = useState(false)
  const [saveError, setSaveError] = useState(false)

  const [currentRecording, setCurrentRecording] = useState<Recording | undefined>(props.recording)

  const [newAudio, setNewAudio] = useState<File | undefined>()

  const handleCloseToast = (event: Event | React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setSavedToastOpen(false)
    setSaveError(false)
  }

  const handleSave = async (recording: Recording) => {
    setSaveLoading(true)
    if (newAudio) {
      props.setWavesurfer(undefined)
      props.setLoadingUpload(true)
      if (playing === props.recording?.id) {
        setPlaying(undefined)
      }
      recording.audioFile = newAudio
      setDone(false)
    }
    await savePartialRecording(recording)
      .then(newRecording => {
        props.setRecording(newRecording)
        props.setAudioPeaks(newRecording.audioWaveform?.data)
        setCurrentRecording(newRecording)
        setSavedToastOpen(true)

        if (props.updateRecordingsSubtle) {
          void props.updateRecordingsSubtle()
        }
        props.setLoadingUpload(false)
        props.setLoading(true)
      })
      .catch(() => {
        setSaveError(true)
        setSaveLoading(false)
      })
      .finally(() => {
        setSaveLoading(false)
        setNewAudio(undefined)
        props.setLoadingUpload(false)
        setDone(true)
        if (props.refresh) {
          props.refresh(old => !old)
        }
      })
  }

  useEffect(() => {
    if (props.recording && newAudio) {
      props.setLoadingUpload(true)
      void handleSave({ ...props.recording, audioFile: newAudio })
        .then(() => {
          props.setLoadingUpload(false)
        })
    }
  }, [props.recording, newAudio])

  useEffect(() => {
    if (done && props.recordingFile && props.setNewRecordings && props.setRecordings) {
      props.setNewRecordings(oldRecordings => oldRecordings.filter(item => item.name !== props.recordingFile?.name))
      if (currentRecording) {
        props.setRecordings(records => [...records, currentRecording])
      }
    }
  }, [done])

  useEffect(() => {
    setCurrentRecording(props.recording)
  }, [props.recording])

  return (
    <>
      <Snackbar
        autoHideDuration={5000}
        message='Recording saved'
        onClose={handleCloseToast}
        open={savedToast}
      >
        <Alert
          onClose={handleCloseToast}
          severity='success'
          sx={{ width: '100%' }}
          variant='filled'
        >
          Recording saved
        </Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={5000}
        message='Your image upload failed, it might contain explicit content'
        onClose={handleCloseToast}
        open={saveError}
      >
        <Alert
          onClose={handleCloseToast}
          severity='error'
          sx={{ width: '100%' }}
          variant='filled'
        >
          Your image upload failed, it might contain explicit content
        </Alert>
      </Snackbar>
      <Stack>
        <TabContext value={props.currentTab}>
          <Stack sx={{ margin: 0, padding: 0 }}>
            <TabPanel sx={{ minHeight: 500, height: matches ? 1 : 500, overflow: 'auto' }} value='1'>
              {currentRecording &&
              <RecordingDetailsTab
                onSave={handleSave}
                project={props.project}
                recording={currentRecording}
                saveProgress={(recording: Recording) => setCurrentRecording(recording)}
                saving={saveLoading}
                setNewAudio={setNewAudio}
              />}
            </TabPanel >
            <TabPanel sx={{ minHeight: 500, height: matches ? 1 : 500, overflow: 'auto' }} value='2'>
              {currentRecording &&
              <RecordingPublishingTab
                distributing={props.distributing}
                onSave={handleSave}
                project={props.project}
                recording={currentRecording}
                saveProgress={(recording: Recording) => setCurrentRecording(recording)}
                saving={saveLoading}

              />}
            </TabPanel>
            <TabPanel sx={{ minHeight: 500, height: matches ? 1 : 500, overflow: 'auto' }} value='3'>
              {currentRecording &&
              <RecordingContentTab
                distributing={props.distributing}
                onSave={handleSave}
                project={props.project}
                recording={currentRecording}
                saveProgress={(recording: Recording) => setCurrentRecording(recording)}
                saving={saveLoading}
              />}
            </TabPanel>
            <TabPanel sx={{ minHeight: 500, height: matches ? 1 : 500, overflow: 'auto' }} value='4'>
              {currentRecording &&
              <RecordingRightsTab
                onSave={handleSave}
                project={props.project}
                recording={currentRecording}
                saveProgress={(recording: Recording) => setCurrentRecording(recording)}
                saving={saveLoading}
              />}
            </TabPanel>
          </Stack>
        </TabContext>
      </Stack>
    </>
  )
}

export default EditRecordingComponent
