/* eslint-disable sonarjs/no-duplicated-branches */
import { TabContext, TabList } from '@mui/lab'
import { Divider, Stack, Tab } from '@mui/material'

import GetIcon from 'src/components/pages/SmartLink/Content/GetIcon'
import type { AnalyticsCurrentData, Data } from 'src/models/Analytics'

type Props = {
  currentTab: string
  setCurrentTab: (tab: string) => void
  currentData?: AnalyticsCurrentData
  profile?: boolean
}

export const setAnalyticsTitle =
  (stringItem: string) =>
    (stringItem.charAt(0).toUpperCase() + stringItem.slice(1).replaceAll('_', ' '))
      .replaceAll('Youtube', 'YouTube')
      .replaceAll('Itunes', 'iTunes')
      .replaceAll('Tiktok', 'TikTok')
      .replaceAll('Apple music', 'Apple Music')

const AnalyticsDistributionAppBar = (props: Props) => {
  const tabs = props.currentData?.stats?.filter(item => {
    let count = 0
    const keys = Object.keys(item.data)
    for (const key of keys) {
      if (item.data[key as keyof Data] !== undefined &&
        item.data[key as keyof Data] !== null) {
        const test = item.data[key as keyof Data]
        if (test && (typeof test === 'number' || (typeof test !== 'number' && test.length > 0))) {
          count++
        }
      }
    }
    return count > 0 && keys.length > 0
  })

  return (
    <Stack direction='column' justifyContent='space-between' width={1}>
      <Stack alignItems='center' direction='row' paddingTop={1} paddingX={3} spacing={2}>
        <TabContext value={props.currentTab}>
          <TabList
            aria-label='Current analytics view'
            onChange={(event, value: string) => props.setCurrentTab(value)}
            sx={{ minHeight: 32 }}
            variant='scrollable'
          >
            {props.currentData?.stats && props.profile &&
            <Tab
              icon={GetIcon('Overview')}
              iconPosition='start'
              key='Overview'
              label={setAnalyticsTitle('Overview')}
              sx={{ minHeight: 32 }}
              value='Overview'
            />}
            {tabs?.map(currentItem =>
              <Tab
                icon={GetIcon(currentItem.source)}
                iconPosition='start'
                key={currentItem.source}
                label={setAnalyticsTitle(currentItem.source)}
                sx={{ minHeight: 32 }}
                value={currentItem.source}
              />)}
          </TabList>
        </TabContext>
      </Stack>
      <Divider sx={{ width: 1 }} />
    </Stack>
  )
}

export default AnalyticsDistributionAppBar
