import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CloseIcon from '@mui/icons-material/Close'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import { LoadingButton } from '@mui/lab'
import { Avatar, Button, Card, Dialog, IconButton, Paper, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'

import { supportRequestGeneric } from 'src/api/distribution'
import type Project from 'src/models/Project'

type Props = {
  close: () => void
  open: boolean
  icon: React.ReactNode
  title: string
  project: Project
}

const DistributionGeneralSupportModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  const [loading, setLoading] = useState(false)
  const [done, setDone] = useState(false)
  const [supportText, setSupportText] = useState('')

  const submitRequest = async () => {
    setLoading(true)
    await supportRequestGeneric(props.project.id, props.title, supportText)
      .finally(() => {
        setLoading(false)
        setDone(true)
      })
      .catch(() => setLoading(false))
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='sm'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      {done
        ? <Stack paddingX={3} paddingY={3} spacing={2} width={1}>
          <Stack width={1}>
            <Stack
              alignItems='center'
              direction='row'
              justifyContent='space-between'
              width={1}
            >
              <Typography variant='h3'>
                Get Support
              </Typography>
              <IconButton onClick={() => props.close()}>
                <CloseIcon color='disabled' />
              </IconButton>
            </Stack>
            <Stack width={1}>
              <Typography
                color='text.secondary'
                lineHeight={1.2}
                maxWidth='sm'
                variant='body1'
              >
                The best way to resolve your request is through our one-on-one customer support
              </Typography>
            </Stack>
          </Stack>
          <Stack alignItems='center' height={1} justifyContent='center' width={1} >
            <Paper
              elevation={0}
              sx={{
                backgroundColor: theme => theme.palette.success.main,
                borderRadius: 16,
              }}
            >
              <Stack
                alignItems='center'
                justifyContent='center'
                padding={2}
              >
                <CheckCircleRoundedIcon
                  sx={{
                    color: theme => theme.palette.primary.contrastText,
                    fontSize: '64px',
                  }}
                />
              </Stack>
            </Paper>
            <Typography
              color={themeItem.palette.text.secondary}
              sx={{
                marginTop: 1,
              }}
              variant='h4'
            >
              We have received your request
            </Typography>
            <Typography
              color={themeItem.palette.text.label}
              sx={{
                textAlign: 'center',
              }}
              variant='body1'
            >
              Our support team will get in touch with you as soon as possible
            </Typography>
            <Button
              color='primary'
              fullWidth
              onClick={() => props.close()}
              sx={{
                marginTop: 2,
              }}
            >
              Done
            </Button>
          </Stack>
        </Stack>
        : <Stack paddingX={3} paddingY={3} spacing={2} width={1}>
          <Stack width={1}>
            <Stack
              alignItems='center'
              direction='row'
              justifyContent='space-between'
              width={1}
            >
              <Typography variant='h3'>
                Get Support
              </Typography>
              <IconButton onClick={() => props.close()}>
                <CloseIcon color='disabled' />
              </IconButton>
            </Stack>
            <Stack width={1}>
              <Typography
                color='text.secondary'
                lineHeight={1.2}
                maxWidth='sm'
                variant='body1'
              >
                The best way to resolve your request is through our one-on-one customer support
              </Typography>
            </Stack>
          </Stack>
          <Stack spacing={2} width={1}>
            <Card
              elevation={0}
              sx={{
                background: theme => theme.palette.background.elevatedCard,
              }}
            >
              <Stack alignItems='center' padding={2} width={1}>
                {props.icon}
                <Typography lineHeight={1.2} textAlign='center' variant='h3'>
                  {props.title}
                </Typography>
                <Stack alignItems='center' paddingTop={2} spacing={1} width={1}>
                  <Avatar
                    src={props.project.artworkPreviewUrl}
                    sx={{
                      width: 100,
                      height: 100,
                      transition: '0.3s',
                      background: theme => theme.palette.background.input,
                    }}
                    variant='rounded'
                  >
                    <MusicNoteRoundedIcon
                      sx={{
                        fontSize: 100,
                        color: theme => theme.palette.action.disabled,
                      }}
                    />
                  </Avatar>
                  <Typography
                    color='text.secondary'
                    variant='body2'
                  >
                    {props.project.title}
                  </Typography>
                </Stack>
              </Stack>
            </Card>

            <TextField
              fullWidth
              minRows={4}
              multiline
              onChange={event => setSupportText(event.target.value)}
              placeholder='Include details if necessary or leave blank'
              value={supportText}
            />
          </Stack>
          <Stack direction='row' justifyContent='flex-end' spacing={1} width='100%'>
            <Button
              onClick={() => props.close()}
              variant='text'
            >
              Cancel
            </Button>
            <LoadingButton
              color='primary'
              loading={loading}
              onClick={() => void submitRequest()}
              variant='contained'
            >
              Submit Request
            </LoadingButton>
          </Stack>
        </Stack>}
    </Dialog>
  )
}

export default DistributionGeneralSupportModal
