/* eslint-disable complexity */
/* eslint-disable max-lines */
/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingButton } from '@mui/lab'
import { Alert, Autocomplete, Button, Snackbar, Stack, Switch, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material'
import { useEffect, useState } from 'react'

import { updateRole } from 'src/api/organisation'
import NewPermissionGroupModal from 'src/components/pages/Settings/Team/TeamTabs/TeamTabsComponents/CreatePermissionGroupModal'
import { useAuth } from 'src/components/providers/AuthProvider'
import { Permissions } from 'src/models/Organisation'

const PermissionsSettings = () => {
  const { currentOrganisation, currentAccountPermissions } = useAuth()

  const [permissionGroups, setPermissionGroup] = useState('')
  const [newGroupdModal, setNewGroupModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [linkCopiedToast, setLinkCopiedToastOpen] = useState(false)

  const handleCloseToast = (event: Event | React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setLinkCopiedToastOpen(false)
  }

  const disablePermissionButtons = permissionGroups === 'Owner' ||
  permissionGroups === 'Admin' ||
  permissionGroups === 'Manager' ||
  permissionGroups === 'Guest' ||
  permissionGroups === '' ||
  !currentAccountPermissions?.includes(Permissions.ADD_REMOVE_USERS)

  const hasPermission = (permission: Permissions, currentPermission: string) =>
    currentOrganisation?.organisationRoles
      .find(role => role.name === currentPermission)?.permissions
      .includes(permission)

  const [editProjects, setEditProjects] =
    useState(hasPermission(Permissions.CREATE_EDIT_REMOVE_PROJECTS, permissionGroups))

  const [viewProjects, setViewProjects] =
  useState(hasPermission(Permissions.VIEW_PROJECTS, permissionGroups))

  const [viewTaskboards, setViewTaskboard] =
  useState(hasPermission(Permissions.VIEW_TASKBOARDS, permissionGroups))

  const [editMarketingLinks, setEditMarketingLinks] =
    useState(hasPermission(Permissions.CREATE_EDIT_REMOVE_MARKETING_LINKS, permissionGroups))

  const [viewMarketingLinks, setViewMarketingLinks] =
    useState(hasPermission(Permissions.VIEW_MARKETING_LINKS, permissionGroups))

  const [distributeReleases, setDistributeReleases] =
    useState(hasPermission(Permissions.DISTRIBUTE_RELEASES, permissionGroups))

  const [viewEarnings, setViewEarnings] =
    useState(hasPermission(Permissions.VIEW_EARNINGS, permissionGroups))

  const [viewAnalytics, setViewAnalytics] =
    useState(hasPermission(Permissions.VIEW_ANALYTICS, permissionGroups))

  const [managePayouts, setManagePayouts] =
    useState(hasPermission(Permissions.MANAGE_PAYOUTS, permissionGroups))

  const [editRights, setEditRights] =
    useState(hasPermission(Permissions.CREATE_EDIT_REMOVE_RIGHTS, permissionGroups))

  const [viewRights, setViewRights] =
    useState(hasPermission(Permissions.VIEW_RIGHTS, permissionGroups))

  const [editOrganisationSettings, setEditOrganisationSettings] =
    useState(hasPermission(Permissions.EDIT_ORGANISATION_SETTINGS, permissionGroups))

  const [addRemoveUsers, setAddRemoveUsers] =
    useState(hasPermission(Permissions.ADD_REMOVE_USERS, permissionGroups))

  const [manageBilling, setManageBilling] =
    useState(hasPermission(Permissions.MANAGE_BILLING, permissionGroups))

  const [manageSplits, setManageSplits] =
    useState(hasPermission(Permissions.MANAGE_SPLITS, permissionGroups))

  useEffect(() => {
    setEditProjects(hasPermission(Permissions.CREATE_EDIT_REMOVE_PROJECTS, permissionGroups))
    setViewProjects(hasPermission(Permissions.VIEW_PROJECTS, permissionGroups))

    setViewTaskboard(hasPermission(Permissions.VIEW_TASKBOARDS, permissionGroups))

    setEditMarketingLinks(hasPermission(Permissions.CREATE_EDIT_REMOVE_MARKETING_LINKS, permissionGroups))
    setViewMarketingLinks(hasPermission(Permissions.VIEW_MARKETING_LINKS, permissionGroups))

    setEditRights(hasPermission(Permissions.CREATE_EDIT_REMOVE_RIGHTS, permissionGroups))
    setViewRights(hasPermission(Permissions.VIEW_RIGHTS, permissionGroups))

    setDistributeReleases(hasPermission(Permissions.DISTRIBUTE_RELEASES, permissionGroups))
    setViewEarnings(hasPermission(Permissions.VIEW_EARNINGS, permissionGroups))
    setViewAnalytics(hasPermission(Permissions.VIEW_ANALYTICS, permissionGroups))
    setManagePayouts(hasPermission(Permissions.MANAGE_PAYOUTS, permissionGroups))
    setEditOrganisationSettings(hasPermission(Permissions.EDIT_ORGANISATION_SETTINGS, permissionGroups))
    setAddRemoveUsers(hasPermission(Permissions.ADD_REMOVE_USERS, permissionGroups))
    setManageBilling(hasPermission(Permissions.MANAGE_BILLING, permissionGroups))
    setManageSplits(hasPermission(Permissions.MANAGE_SPLITS, permissionGroups))
  }, [permissionGroups])

  const savePermissions = async () => {
    const permissions: Permissions[] = []

    if (editProjects) {
      permissions.push(Permissions.CREATE_EDIT_REMOVE_PROJECTS)
    }
    if (viewProjects) {
      permissions.push(Permissions.VIEW_PROJECTS)
    }
    if (viewTaskboards) {
      permissions.push(Permissions.VIEW_TASKBOARDS)
    }
    if (editMarketingLinks) {
      permissions.push(Permissions.CREATE_EDIT_REMOVE_MARKETING_LINKS)
    }
    if (viewMarketingLinks) {
      permissions.push(Permissions.VIEW_MARKETING_LINKS)
    }
    if (distributeReleases) {
      permissions.push(Permissions.DISTRIBUTE_RELEASES)
    }
    if (viewEarnings) {
      permissions.push(Permissions.VIEW_EARNINGS)
    }
    if (viewAnalytics) {
      permissions.push(Permissions.VIEW_ANALYTICS)
    }
    if (managePayouts) {
      permissions.push(Permissions.MANAGE_PAYOUTS)
    }
    if (editRights) {
      permissions.push(Permissions.CREATE_EDIT_REMOVE_RIGHTS)
    }
    if (viewRights) {
      permissions.push(Permissions.VIEW_RIGHTS)
    }
    if (editOrganisationSettings) {
      permissions.push(Permissions.EDIT_ORGANISATION_SETTINGS)
    }
    if (addRemoveUsers) {
      permissions.push(Permissions.ADD_REMOVE_USERS)
    }
    if (manageBilling) {
      permissions.push(Permissions.MANAGE_BILLING)
    }
    if (manageSplits) {
      permissions.push(Permissions.MANAGE_SPLITS)
    }

    const role = currentOrganisation?.organisationRoles.find(roleItem => roleItem.name === permissionGroups)
    if (role) {
      setLoading(true)
      role.permissions = permissions
      await updateRole(role.id, role)
        .then(() => setLinkCopiedToastOpen(true))
        .finally(() => setLoading(false))
    }
  }

  return (
    <Stack direction='column' maxWidth={1096} padding={4}>
      <Stack alignContent='center' alignItems='flex-start' direction='column' spacing={3}>
        <Stack alignItems='center' direction='row' marginTop={2} spacing={2} width={1}>
          <Autocomplete
            disabled={!currentAccountPermissions?.includes(Permissions.ADD_REMOVE_USERS)}
            onChange={(event, value) => setPermissionGroup(value ?? '')}
            options={currentOrganisation?.organisationRoles.map(role =>
              role.name) ?? []}
            renderInput={params =>
              <TextField
                {...params}
                fullWidth
                label='Permission group'
                placeholder='Permission group'
                sx={{
                  marginTop: 0,
                }}
              />}
            sx={{
              width: 300,
              marginTop: 0,
            }}
            value={permissionGroups}
          />

          <Button
            disabled={!currentAccountPermissions?.includes(Permissions.ADD_REMOVE_USERS) ||
            (currentOrganisation?.membershipTier !== 'ENTERPRISE' &&
            currentOrganisation?.membershipTier !== 'ENTERPRISE_YEARLY')}
            onClick={() => setNewGroupModalOpen(true)}
            variant='text'
          >
            + Create a permission group
          </Button>
        </Stack>

        <Table aria-label='permissions' size='small' stickyHeader>
          <TableHead sx={{
            '.MuiTableCell-root':
          {
            borderBottom: 2,
            borderBottomColor: 'divider',
            color: 'text.secondary',
            background: 'transparent',
          },
          }}
          >
            <TableRow>
              <TableCell align='left'>
                Permissions
              </TableCell>
              <TableCell align='left'>
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{ '.MuiTableCell-root': { borderBottomColor: 'divider' } }}>
              <TableCell align='left'>
                Create, edit and remove projects
              </TableCell>
              <TableCell align='left'>
                <Switch
                  checked={editProjects}
                  disabled={disablePermissionButtons}
                  onChange={(event, checked) => {
                    setEditProjects(checked)
                    if (checked) {
                      setViewProjects(checked)
                    }
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow sx={{ '.MuiTableCell-root': { borderBottomColor: 'divider' } }}>
              <TableCell align='left'>
                View projects
              </TableCell>
              <TableCell align='left'>
                <Switch
                  checked={viewProjects}
                  disabled={disablePermissionButtons || editProjects}
                  onChange={(event, checked) => setViewProjects(checked)}
                />
              </TableCell>
            </TableRow>
            <TableRow sx={{ '.MuiTableCell-root': { borderBottomColor: 'divider' } }}>
              <TableCell align='left'>
                Manage taskboards
              </TableCell>
              <TableCell align='left'>
                <Switch
                  checked={viewTaskboards}
                  disabled={disablePermissionButtons}
                  onChange={(event, checked) => setViewTaskboard(checked)}
                />
              </TableCell>
            </TableRow>
            <TableRow sx={{ '.MuiTableCell-root': { borderBottomColor: 'divider' } }}>
              <TableCell align='left' >
                Create, edit and remove marketing links
              </TableCell>
              <TableCell align='left'>
                <Switch
                  checked={editMarketingLinks}
                  disabled={disablePermissionButtons}
                  onChange={(event, checked) => {
                    setEditMarketingLinks(checked)
                    if (checked) {
                      setViewMarketingLinks(checked)
                    }
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow sx={{ '.MuiTableCell-root': { borderBottomColor: 'divider' } }}>
              <TableCell align='left'>
                View marketing links
              </TableCell>
              <TableCell align='left'>
                <Switch
                  checked={viewMarketingLinks}
                  disabled={disablePermissionButtons || editMarketingLinks}
                  onChange={(event, checked) => {
                    setViewMarketingLinks(checked)
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow sx={{ '.MuiTableCell-root': { borderBottomColor: 'divider' } }}>
              <TableCell align='left'>
                Distribute releases
              </TableCell>
              <TableCell align='left'>
                <Switch
                  checked={distributeReleases}
                  disabled={disablePermissionButtons}
                  onChange={(event, checked) => setDistributeReleases(checked)}
                />
              </TableCell>
            </TableRow>
            <TableRow sx={{ '.MuiTableCell-root': { borderBottomColor: 'divider' } }}>
              <TableCell align='left'>
                View earnings
              </TableCell>
              <TableCell align='left'>
                <Switch
                  checked={viewEarnings}
                  disabled={disablePermissionButtons}
                  onChange={(event, checked) => setViewEarnings(checked)}
                />
              </TableCell>
            </TableRow>
            <TableRow sx={{ '.MuiTableCell-root': { borderBottomColor: 'divider' } }}>
              <TableCell align='left'>
                Manage splits
              </TableCell>
              <TableCell align='left'>
                <Switch
                  checked={manageSplits}
                  disabled={disablePermissionButtons}
                  onChange={(event, checked) => setManageSplits(checked)}
                />
              </TableCell>
            </TableRow>
            <TableRow sx={{ '.MuiTableCell-root': { borderBottomColor: 'divider' } }}>
              <TableCell align='left'>
                View analytics
              </TableCell>
              <TableCell align='left'>
                <Switch
                  checked={viewAnalytics}
                  disabled={disablePermissionButtons}
                  onChange={(event, checked) => setViewAnalytics(checked)}
                />
              </TableCell>
            </TableRow>
            <TableRow sx={{ '.MuiTableCell-root': { borderBottomColor: 'divider' } }}>
              <TableCell align='left' >
                Manage payouts
              </TableCell>
              <TableCell align='left'>
                <Switch
                  checked={managePayouts}
                  disabled={disablePermissionButtons}
                  onChange={(event, checked) => setManagePayouts(checked)}
                />
              </TableCell>
            </TableRow>
            <TableRow sx={{ '.MuiTableCell-root': { borderBottomColor: 'divider' } }}>
              <TableCell align='left' >
                Create, edit and remove rights
              </TableCell>
              <TableCell align='left'>
                <Switch
                  checked={editRights}
                  disabled={disablePermissionButtons}
                  onChange={(event, checked) => {
                    setEditRights(checked)
                    if (checked) {
                      setViewRights(checked)
                    }
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow sx={{ '.MuiTableCell-root': { borderBottomColor: 'divider' } }}>
              <TableCell align='left' >
                View rights
              </TableCell>
              <TableCell align='left'>
                <Switch
                  checked={viewRights}
                  disabled={disablePermissionButtons || editRights}
                  onChange={(event, checked) => setViewRights(checked)}
                />
              </TableCell>
            </TableRow>
            <TableRow sx={{ '.MuiTableCell-root': { borderBottomColor: 'divider' } }}>
              <TableCell align='left'>
                Edit organization settings
              </TableCell>
              <TableCell align='left'>
                <Switch
                  checked={editOrganisationSettings}
                  disabled={disablePermissionButtons}
                  onChange={(event, checked) => setEditOrganisationSettings(checked)}
                />
              </TableCell>
            </TableRow>
            <TableRow sx={{ '.MuiTableCell-root': { borderBottomColor: 'divider' } }}>
              <TableCell align='left' >
                Add or remove users
              </TableCell>
              <TableCell align='left'>
                <Switch
                  checked={addRemoveUsers}
                  disabled={disablePermissionButtons}
                  onChange={(event, checked) => setAddRemoveUsers(checked)}
                />
              </TableCell>
            </TableRow>
            <TableRow sx={{ '.MuiTableCell-root': { borderBottomColor: 'divider' } }}>
              <TableCell align='left'>
                Manage billing
              </TableCell>
              <TableCell align='left'>
                <Switch
                  checked={manageBilling}
                  disabled={disablePermissionButtons}
                  onChange={(event, checked) => setManageBilling(checked)}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Stack direction='row' justifyContent='flex-end' spacing={1} width={1}>
          <Button variant='text'>
            Cancel
          </Button>
          <LoadingButton
            disabled={disablePermissionButtons}
            loading={loading}
            onClick={() => savePermissions()}
            variant='contained'
          >
            Save changes
          </LoadingButton>
        </Stack>
        {currentAccountPermissions?.includes(Permissions.ADD_REMOVE_USERS) &&
        <NewPermissionGroupModal
          close={() => setNewGroupModalOpen(false)}
          open={newGroupdModal}
          openToast={setLinkCopiedToastOpen}
        />}
        <Snackbar
          autoHideDuration={5000}
          message='Group updated'
          onClose={handleCloseToast}
          open={linkCopiedToast}
        >
          <Alert
            onClose={handleCloseToast}
            severity='success'
            sx={{ width: '100%' }}
            variant='filled'
          >
            Group updated
          </Alert>
        </Snackbar>
      </Stack>
    </Stack>
  )
}

export default PermissionsSettings
