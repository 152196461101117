/* eslint-disable max-lines */
/* eslint-disable sonarjs/no-identical-functions */
import type { Socket } from 'socket.io-client'
import { io } from 'socket.io-client'

import type { Message, MessageDto } from 'src/models/Message'
import type { NotesComment, NotesCommentDto } from 'src/models/Notes'
import type { NotificationDto } from 'src/models/Notification'
import type { TaskBoardEdit, TaskBoardEditDto } from 'src/models/Taskboard'
import { auth } from 'src/utils/firebase'

export type ServerToClientEvents = {
  message: (message: MessageDto) => void
  read: (message: MessageDto) => void
  notification: (notification: NotificationDto) => void
  tasksboardNotification: (notification: TaskBoardEditDto) => void
  refreshConversations: (value: string) => void
  newNoteComment: (noteItem: NotesComment) => void
  commentDeleted: (id: string) => void
  commentUpdated: (notification: NotesComment) => void
  workspaceNotification: (value: string) => void
}

export type ClientToServerEvents = {
  message: (message: Message, callback: (result: MessageDto) => void) => void
  joinConversations: () => void
  joinNotifications: () => void
  joinWorkspace: () => void
  getData: (sheetId: string) => void
  joinTasksboard: (taskboardId: string) => void
  read: (messageId: string) => void
  readNotification: (notificationId: string) => void
  receiveNotification: (notification: TaskBoardEdit) => void
  newConversation: (conversationId: string) => void
  notesComment: (notification: NotesComment) => void
  notesDeleteComment: (id: string) => void
  notesUpdateComment: (notification: NotesComment) => void
  joinNotesComments: (notesId: string) => void
  sendWorkspaceUpdate: (value: string) => void
}

const baseUrlWebSocket = process.env.NODE_ENV === 'development'
  ? 'ws://localhost:5000'
  : 'wss://api.releese.io'

let socket: Socket<ServerToClientEvents, ClientToServerEvents> | null = null

export const initializeSocket = async () => {
  await auth.currentUser?.getIdToken()
    .then(token => {
      socket = io(baseUrlWebSocket, {
        path: '/events',
        reconnectionDelayMax: 1000,
        withCredentials: true,
        auth: {
          token,
        },
      })
        .emit('joinNotifications')
    })
    .catch(() =>
      null)
  return socket
}

export const initializeTaskboard = async (taskboardId: string) => {
  if (!socket) {
    await auth.currentUser?.getIdToken()
      .then(token => {
        socket = io(baseUrlWebSocket, {
          path: '/events',
          reconnectionDelayMax: 1000,
          withCredentials: true,
          auth: {
            token,
          },
        })
          .emit('joinTasksboard', taskboardId)
      })
      .catch(() =>
        null)
  } else {
    socket.emit('joinTasksboard', taskboardId)
  }
  return socket
}

export const initializeNotesComments = async (notesId: string) => {
  if (!socket) {
    await auth.currentUser?.getIdToken()
      .then(token => {
        socket = io(baseUrlWebSocket, {
          path: '/events',
          reconnectionDelayMax: 1000,
          withCredentials: true,
          auth: {
            token,
          },
        })
          .emit('joinNotesComments', notesId)
      })
      .catch(() =>
        null)
  } else {
    socket.emit('joinNotesComments', notesId)
  }
  return socket
}

export const initializeConversations = async () => {
  if (!socket) {
    await auth.currentUser?.getIdToken()
      .then(token => {
        socket = io(baseUrlWebSocket, {
          path: '/events',
          reconnectionDelayMax: 1000,
          withCredentials: true,
          auth: {
            token,
          },
        })
          .emit('joinConversations')
      })
      .catch(() =>
        null)
  } else {
    socket.emit('joinConversations')
  }
  return socket
}

export const initializeWorkspace = async () => {
  if (!socket) {
    await auth.currentUser?.getIdToken()
      .then(token => {
        socket = io(baseUrlWebSocket, {
          path: '/events',
          reconnectionDelayMax: 1000,
          withCredentials: true,
          auth: {
            token,
          },
        })
          .emit('joinWorkspace')
      })
      .catch(() =>
        null)
  } else {
    socket.emit('joinWorkspace')
  }
  return socket
}

export const subscribeToWorkspace = (socketItem: Socket<ServerToClientEvents, ClientToServerEvents> | null,
  callBack: (error: unknown, data?: string) => void) => {
  if (!socketItem) {
    callBack(false)
    return
  }
  socketItem.on('workspaceNotification', message => {
    callBack(null, message)
  })
}

export const subscribeToMessages = (socketItem: Socket<ServerToClientEvents, ClientToServerEvents> | null,
  callBack: (error: unknown, data?: MessageDto) => void) => {
  if (!socketItem) {
    callBack(false)
    return
  }
  socketItem.on('message', message => {
    callBack(null, message)
  })
}

export const subscribeToReadReceipts = (socketItem: Socket<ServerToClientEvents, ClientToServerEvents> | null,
  callBack: (error: unknown, data?: MessageDto) => void) => {
  if (!socketItem) {
    callBack(false)
    return
  }
  socketItem.on('read', message => {
    callBack(null, message)
  })
}

export const subscribeToNotifications = (socketItem: Socket<ServerToClientEvents, ClientToServerEvents> | null,
  callBack: (error: unknown, data?: NotificationDto) => void) => {
  if (!socketItem) {
    callBack(false)
    return
  }
  socketItem.on('notification', notification => {
    callBack(null, notification)
  })
}

export const subscribeToTasksboards = (socketItem: Socket<ServerToClientEvents, ClientToServerEvents> | null,
  callBack: (error: unknown, data?: TaskBoardEditDto) => void) => {
  if (!socketItem) {
    callBack(false)
    return
  }
  socketItem.on('tasksboardNotification', notification => {
    callBack(null, notification)
  })
}

export const subscribeToNotesComments = (socketItem: Socket<ServerToClientEvents, ClientToServerEvents> | null,
  callBack: (error: unknown, data?: NotesCommentDto) => void) => {
  if (!socketItem) {
    callBack(false)
    return
  }
  socketItem.on('newNoteComment', notification => {
    callBack(null, notification)
  })
}

export const subscribeToNotesDeleted = (socketItem: Socket<ServerToClientEvents, ClientToServerEvents> | null,
  callBack: (error: unknown, data?: string) => void) => {
  if (!socketItem) {
    callBack(false)
    return
  }
  socketItem.on('commentDeleted', notification => {
    callBack(null, notification)
  })
}

export const subscribeToNotesUpdated = (socketItem: Socket<ServerToClientEvents, ClientToServerEvents> | null,
  callBack: (error: unknown, data?: NotesCommentDto) => void) => {
  if (!socketItem) {
    callBack(false)
    return
  }
  socketItem.on('commentUpdated', notification => {
    callBack(null, notification)
  })
}

export const sendMessageToConversation =
(message: Message, callBack: (error: unknown, data?: MessageDto) => void) => {
  if (socket) {
    socket.emit('message', message, (result: MessageDto) => callBack(null, result))
  }
}

export const sendWorkspaceUpdate = (message: string) => {
  if (socket) {
    socket.emit('sendWorkspaceUpdate', message)
  }
}

export const sendTasksboardUpdate = (message: TaskBoardEdit) => {
  if (socket) {
    socket.emit('receiveNotification', message)
  }
}

export const sendNotesComment = (message: NotesComment) => {
  if (socket) {
    socket.emit('notesComment', message)
  }
}

export const sendNotesDeleteComment = (id: string) => {
  if (socket) {
    socket.emit('notesDeleteComment', id)
  }
}

export const sendNotesUpdateComment = (message: NotesComment) => {
  if (socket) {
    socket.emit('notesUpdateComment', message)
  }
}

export const setMessageToRead = (messageId: string) => {
  if (socket) {
    socket.emit('read', messageId)
  }
}

export const setNotificationToRead = (notificationId: string) => {
  if (socket) {
    socket.emit('readNotification', notificationId)
  }
}

export const subscribeToNewConversations = (socketItem: Socket<ServerToClientEvents, ClientToServerEvents> | null,
  callBack: (error: unknown, data?: string) => void) => {
  if (!socketItem) {
    callBack(false)
    return
  }
  socketItem.on('refreshConversations', conversation => {
    callBack(null, conversation)
  })
}

export const newConversation = (conversationId: string) => {
  if (socket) {
    socket.emit('newConversation', conversationId)
  }
}

export const refreshConversations = (socketItem: Socket<ServerToClientEvents, ClientToServerEvents>) => {
  socketItem.emit('joinConversations')
}
