
import { Box, Chip, Menu, MenuItem, Stack } from '@mui/material'
import { useState } from 'react'

type StatusType = {
  label: string
  color: 'default' | 'error' | 'info' | 'primary' | 'secondary' | 'success' | 'warning' | undefined
}
const statuses: readonly StatusType[] = [
  { label: 'Not started', color: 'primary' },
  { label: 'In progress', color: 'warning' },
  { label: 'Done', color: 'success' },
  { label: 'Not approved', color: 'error' },
]

type Props = {
  setInputSelected: (item: 'Done' | 'In progress' | 'Not approved' | 'Not started') => void
  statusName: 'Done' | 'In progress' | 'Not approved' | 'Not started'
}

const StatusSelect = (props: Props) => {
  const [anchorElementMore, setAnchorElementMore] = useState<HTMLElement | null>(null)
  const openMoreMenu = Boolean(anchorElementMore)

  const handleMoreClose = () => {
    setAnchorElementMore(null)
  }

  const findColor = (labelFound: string) => statuses.find(element => element.label === labelFound)

  return (
    <Stack
      direction='column'
      flexShrink={0}
      spacing={1}
      width={113}
    >
      <Box sx={{ display: 'flex', flexWrap: 'wrap', height: 1 }}>
        <Stack alignItems='center' direction='row' spacing={1}>
          {findColor(props.statusName)?.color &&
          <Chip
            color={findColor(props.statusName)?.color}
            label={props.statusName}
            onClick={event => setAnchorElementMore(event.currentTarget)}
            sx={{ '&:hover': { cursor: 'pointer' }  }}
            variant='filled'
          />}
        </Stack>
      </Box>
      <Menu
        anchorEl={anchorElementMore}
        onClick={() => handleMoreClose()}
        open={openMoreMenu}
      >
        {statuses.map(status =>
          <MenuItem
            key={status.label}
            onClick={() =>
              props.setInputSelected(status.label as 'Done' | 'In progress' | 'Not approved' | 'Not started')}
            value={status.label}
          >
            <Stack alignItems='center' direction='row' spacing={1}>
              <Chip
                color={status.color}
                label={status.label}
                sx={{ '&:hover': { cursor: 'pointer' }  }}
                variant='filled'
              />
            </Stack>
          </MenuItem>)}
      </Menu>
    </Stack>
  )
}

export default StatusSelect
