import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import EditIcon from '@mui/icons-material/Edit'
import { Avatar, Badge, Paper, Stack } from '@mui/material'
import type { ChangeEvent } from 'react'

import { FileUploadButtonBase } from 'src/components/form-elements/FileUpload'
import type { Organisation } from 'src/models/Organisation'
import SPACING from 'src/styles/spacing'

type Props = {
  changeProfilePicture: (event: ChangeEvent<HTMLInputElement>) => void
  account: Organisation
  isCurrentAccount?: boolean
}

const size = SPACING.themeSpacing(24.5)

export const OrganisationAvatar = (props: Props) => <FileUploadButtonBase
  disabled={!props.isCurrentAccount}
  id='upload-profile-picture'
  onChange={props.changeProfilePicture}
  sx={{
    width: size,
    height: size,
  }}
>
  <Paper
    elevation={0}
    sx={{
      objectFit: 'contain',
      cursor: 'pointer',
      position: 'relative',
      width: size,
      height: size,
      padding: 0,
      borderRadius: '50%',
      transition: '0.3s',
      '&:hover .MuiAvatar-root': props.isCurrentAccount
        ? {
          opacity: 0.2,
        }
        : {
          opacity: 1,
        }
      ,
      '&:hover .MuiSvgIcon-root': {
        opacity: 1,
      },
      ':hover': props.isCurrentAccount
        ? {
          zIndex: 1,
          backgroundColor: 'primary.main',
          opacity: 0.8,
        }
        : {
          zIndex: 1,
          backgroundColor: 'transparent',
          opacity: 1,
        },
    }}
  >
    <Badge
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      badgeContent={<CheckCircleRoundedIcon
        color='secondary'
        sx={{
          backgroundColor: 'white',
          borderRadius: '50%',
          width: 196 / 4,
          height: 196 / 4,
          fontSize: 196 / 4,
        }}
      />}
      overlap='circular'
      sx={{
        '.MuiBadge-badge': {
          display: props.account.verified ? undefined : 'none',
        },
      }}
    >
      <Avatar
        alt={props.account.name}
        sx={{
          width: size,
          height: size,
          transition: '0.3s',
        }}
        {...props}
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        src={props.account.logoUrl}
        variant='circular'
      />
    </Badge>
    {props.isCurrentAccount &&
    <Stack
      alignItems='center'
      borderRadius='50%'
      height='100%'
      justifyContent='center'
      left={0}
      marginLeft='auto'
      marginRight='auto'
      position='absolute'
      right={0}
      top={0}
      width={size}
      zIndex='1000000000'
    >
      <EditIcon
        sx={{
          position: 'absolute',
          zIndex: 100,
          width: '100%',
          height: '100%',
          padding: `${Number.parseInt(SPACING.addPixels(size, -32)) / 2}px`,
          color: 'common.white',
          opacity: 0,
          transition: '0.3s',
        }}
      />
    </Stack>}
  </Paper>
</FileUploadButtonBase>
