/* eslint-disable unicorn/no-unsafe-regex */

import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import DragIndicatorRoundedIcon from '@mui/icons-material/DragIndicatorRounded'
import SaveRoundedIcon from '@mui/icons-material/SaveRounded'
import { Badge, Card, IconButton, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'

import { GetColorLink } from 'src/components/pages/SmartLink/Content/GetColorLink'
import GetIcon from 'src/components/pages/SmartLink/Content/GetIcon'
import type { MarketingLink, ServicesList } from 'src/models/Marketing'

type Props = {
  smartLinkData: MarketingLink
  setLinkData: React.Dispatch<React.SetStateAction<MarketingLink>>
  element: ServicesList
  index: number
}

const EditLinkMarketingServiceItem = (props: Props) => {
  const spotifyTrackId = /^https?:\/\/open\.spotify\.com\/track\/([\dA-Za-z]{22})/
  const spotifyAlbumId = /^https?:\/\/open\.spotify\.com\/album\/([\dA-Za-z]{22})/
  const deezerTrackId = /^https?:\/\/www\.deezer\.com\/(?:[a-z]{2}\/)?track\/([1-9]\d+)/
  const appleId = /^https?:\/\/music\.apple\.com\/(?:[a-z]{2}\/)?album\/([1-9]\d+)/

  const [value, setValue] = useState(props.element.value)
  const [title, setTitle] = useState(props.element.customTitle)

  const onDeleteByIndex = () => {
    props.setLinkData(old =>
      ({ ...old, inputList: [...old.inputList.filter((item, indexItem) => indexItem !== props.index)] }))
  }

  const handleValueChange = () => {
    props.setLinkData(old =>
      ({
        ...old,
        inputList: old.inputList.map((item, indexItem) => indexItem === props.index
          ? { ...item, value }
          : item),
      }))
  }

  const handleValueChangeTitle = () => {
    props.setLinkData(old =>
      ({
        ...old,
        inputList: old.inputList.map((item, indexItem) => indexItem === props.index
          ? { ...item, customTitle: title }
          : item),
      }))
  }

  return (
    <Draggable draggableId={props.element.id} index={props.index} key={props.element.id}>
      {providedDrag =>
        <Stack
          key={props.element.label}
          paddingTop={2}
          ref={providedDrag.innerRef}
          {...providedDrag.draggableProps}
        >
          <Card
            variant='outlined'
          >
            <Stack
              alignItems='flex-start'
              direction='column'
              padding={2}
              spacing={1}
              width={1}
            >
              <Stack
                alignItems='flex-start'
                direction='row'
                justifyContent='space-between'
                spacing={2}
                width={1}
              >
                <Tooltip title='Drag to move'>
                  <IconButton {...providedDrag.dragHandleProps} size='small'>
                    <DragIndicatorRoundedIcon
                      fontSize='medium'
                      sx={{
                        color: theme => theme.palette.action.active,
                      }}
                    />
                  </IconButton>
                </Tooltip>
                <IconButton
                  disableRipple
                  sx={{
                    backgroundColor: GetColorLink(props.element.label),
                    color: 'white!important',
                    ':hover': {
                      backgroundColor: GetColorLink(props.element.label),
                      cursor: 'default',
                    },
                    '& .MuiSvgIcon-root': {
                      color: 'white!important',
                    },
                  }}
                >
                  {props.smartLinkData.type === 'Presave' &&
              (props.element.label === 'Spotify' ||
              props.element.label === 'Apple Music' ||
              props.element.label === 'Deezer')
                    ? <Tooltip title='Presave'>
                      <Badge badgeContent={<SaveRoundedIcon />} color='secondary'>
                        {GetIcon(props.element.label)}
                      </Badge>
                    </Tooltip>
                    : GetIcon(props.element.label)}
                </IconButton>
                <Stack direction='column' spacing={2} width={1}>
                  <TextField
                    autoComplete='new-password'
                    fullWidth
                    id={`title-${props.element.id}`}
                    onBlur={() => handleValueChangeTitle()}
                    onChange={event => setTitle(event.target.value)}
                    placeholder={props.element.label}
                    sx={{ marginTop: 0 }}
                    value={title}
                  />
                  <TextField
                    autoComplete='new-password'
                    error={!value.startsWith('https://')}
                    fullWidth
                    id={props.element.id}
                    onBlur={() => handleValueChange()}
                    onChange={event => setValue(event.target.value)}
                    placeholder={props.element.placeholder}
                    sx={{ marginTop: 0 }}
                    value={value}
                  />
                </Stack>

                <Tooltip title='Remove link'>
                  <IconButton
                    color='error'
                    id={props.element.id}
                    onClick={onDeleteByIndex}
                    size='small'
                  >
                    <CloseRoundedIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
              {props.smartLinkData.type === 'Presave' &&
                          props.element.label === 'Spotify' &&
                          !spotifyTrackId.test(props.smartLinkData.inputList[props.index].value) &&
                          !spotifyAlbumId.test(props.smartLinkData.inputList[props.index].value) &&
                          props.smartLinkData.inputList[props.index].value.length > 0 &&
                            <Typography color='error' variant='helperText'>
                              Please use the following format: https://open.spotify.com/track/4cOdK2wGLETKBW3PvgPWqT
                            </Typography>}
              {props.smartLinkData.type === 'Presave' &&
                          props.element.label === 'Apple Music' &&
                          !appleId.test(props.smartLinkData.inputList[props.index].value) &&
                          props.smartLinkData.inputList[props.index].value.length > 0 &&
                            <Typography color='error' variant='helperText'>
                              Please use the following format: https://music.apple.com/us/album/1478168215
                            </Typography>}
              {props.smartLinkData.type === 'Presave' &&
                          props.element.label === 'Deezer' &&
                          !deezerTrackId.test(props.smartLinkData.inputList[props.index].value) &&
                          props.smartLinkData.inputList[props.index].value.length > 0 &&
                            <Typography color='error' variant='helperText'>
                              Please use the following format: https://www.deezer.com/us/track/14408104
                            </Typography>}
              {props.smartLinkData.type === 'Smartlink' &&
                            !props.smartLinkData.inputList[props.index].value.startsWith('https://') &&
                            <Typography color='error' variant='helperText'>
                              Please make sure your link starts with https://
                            </Typography>}
            </Stack>
          </Card>
        </Stack>}
    </Draggable>
  )
}

export default EditLinkMarketingServiceItem
