/* eslint-disable complexity */
/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import PercentRoundedIcon from '@mui/icons-material/PercentRounded'
import { LoadingButton } from '@mui/lab'
import { Alert, Autocomplete, Avatar, FormControlLabel, FormGroup, Grid, InputAdornment, ListItem, Snackbar, Stack, Switch, TextField, Typography } from '@mui/material'
import type { SyntheticEvent } from 'react'
import { useEffect, useState } from 'react'
import { NumericFormat } from 'react-number-format'
import { v4 } from 'uuid'

import { getAllCollaborators } from 'src/api/projects'
import { getRecordingsByProjectId } from 'src/api/recordings'
import { inviteMasterCollaborator, invitePubCollaborator } from 'src/api/splits'
import SearchField from 'src/components/form-elements/SearchField'
import { useAuth } from 'src/components/providers/AuthProvider'
import { Organisation } from 'src/models/Organisation'
import type Project from 'src/models/Project'
import type { RightsHolder } from 'src/models/Recording'
import type Recording from 'src/models/Recording'
import { emailCheck } from 'src/utils/regexUtil'

type Props = {
  project?: Project
  updateRecordings: () => Promise<void>
}

const BulkUpdateRights = (props: Props) => {
  const { currentOrganisation } = useAuth()
  const [loading, setLoading] = useState(false)
  const [myCollaborators, setMyCollaborators] = useState<Organisation[]>([])
  const [recordings, setRecordings] = useState<Recording[]>([])
  const [masterKey, setMasterKey] = useState(false)
  const [masterOwnership, setMasterOwnership] = useState<number | undefined>(100)
  const [selectedMaster, setSelectedMaster] = useState<Organisation>()
  const [searchMaster, setSearchMaster] = useState('')

  const [open, setOpen] = useState(false)

  const handleClose = (event?: Event | SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const [editMaster, setEditMaster] = useState(true)
  const [editPub, setEditPub] = useState(true)

  const [masterRightsHolders, setMasterRightsHolders] = useState<RightsHolder[]>([])
  const [pubRightsHolders, setPubRightsHolders] = useState<RightsHolder[]>([])

  const emptyMasterItem = new Organisation({
    id: v4(),
    name: 'New Collaborator',
    organisationEmail: searchMaster,
  })

  const checkMasterEmail = (selectedMaster &&
    selectedMaster.organisationEmail.length > 0 &&
    !emailCheck.test(selectedMaster.organisationEmail)) || (selectedMaster &&
    !masterRightsHolders.map(item => item.account.organisationEmail !== selectedMaster.organisationEmail)
      .every(item => item))

  const checkPubEmail = (selectedMaster &&
    selectedMaster.organisationEmail.length > 0 &&
    !emailCheck.test(selectedMaster.organisationEmail)) || (selectedMaster &&
    !pubRightsHolders.map(item => item.account.organisationEmail !== selectedMaster.organisationEmail)
      .every(item => item))

  const checkMasterLocked = masterRightsHolders.map(holder =>
    holder.status === 'Unlocked')
    .every(element => element)

  const checkPubLocked = pubRightsHolders.map(holder =>
    holder.status === 'Unlocked')
    .every(element => element)

  const addRightsholder = async () => {
    setLoading(true)
    if (masterOwnership && selectedMaster && recordings.length > 0) {
      for (const recordingItem of recordings) {
        if (editMaster) {
          await inviteMasterCollaborator(recordingItem.id,
            selectedMaster.organisationEmail,
            masterOwnership,
            ['Artist'])
        }
        if (editPub) {
          await invitePubCollaborator(recordingItem.id,
            selectedMaster.organisationEmail,
            masterOwnership,
            ['Songwriter'])
        }
      }
    }
    setLoading(false)
    setOpen(true)
    await props.updateRecordings()
    setSelectedMaster(undefined)
    setMasterKey(old => !old)
  }

  useEffect(() => {
    void getAllCollaborators()
      .then(setMyCollaborators)
  }, [masterRightsHolders, masterKey, pubRightsHolders])

  useEffect(() => {
    if (props.project?.id) {
      void getRecordingsByProjectId(props.project.id)
        .then(setRecordings)
    }
  }, [props.project?.id, masterKey])

  useEffect(() => {
    const holdersTotalMaster = [...recordings.flatMap(item => item.masterRightsHolders)]
    setMasterRightsHolders(holdersTotalMaster)

    const holdersTotalPub = [...recordings.flatMap(item => item.publisherRightsHolders)]
    setPubRightsHolders(holdersTotalPub)
  }, [recordings])

  return (
    <Stack spacing={2} width={1}>

      <Snackbar autoHideDuration={6000} onClose={handleClose} open={open}>
        <Alert onClose={handleClose} severity='success' sx={{ width: '100%' }} variant='filled'>
          Successfully added rightsholder
        </Alert>
      </Snackbar>

      <Stack width={1}>
        <Typography variant='h3'>
          Bulk invite splits
        </Typography>
        <Typography variant='body2'>
          When you invite an Organization to this project using this form, they will be invited as a rightsholder on
          all recordings in this project
        </Typography>
      </Stack>

      {!checkMasterLocked && editMaster &&
      <Typography color='error' variant='body2'>
        This project includes a locked master split, which disables this feature
      </Typography>}

      {checkMasterEmail && editMaster &&
      <Typography color='error' variant='body2'>
        This rightsholder already has a master split in this project
      </Typography>}

      {!checkPubLocked && editPub &&
      <Typography color='error' variant='body2'>
        This project includes a locked publishing split, which disables this feature
      </Typography>}

      {checkPubEmail && editPub &&
      <Typography color='error' variant='body2'>
        This rightsholder already has a publishing split in this project
      </Typography>}

      {props.project?.organisation?.id === currentOrganisation?.id &&
      <Stack marginLeft='12px!important' spacing={2} width={1}>
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={editMaster} onChange={value => setEditMaster(value.currentTarget.checked)} />}
            label={<Typography marginLeft={1} variant='body2'>
              Master
            </Typography>}
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={editPub} onChange={value => setEditPub(value.currentTarget.checked)} />}
            label={<Typography marginLeft={1} variant='body2'>
              Publishing
            </Typography>}
          />
        </FormGroup>
      </Stack>}

      {props.project?.organisation?.id === currentOrganisation?.id &&
      <Grid
        columns={16}
        container
        marginLeft='-16px!important'
        spacing={2}
        width='calc(100% + 16px)'
      >
        <Grid item lg={3} md={3} sm={16} xl={3} xs={16}>
          <NumericFormat
            InputProps={{
              endAdornment:
  <InputAdornment position='end'>
    <PercentRoundedIcon color='disabled' fontSize='small' />
  </InputAdornment>,
            }}
            customInput={TextField}
            decimalScale={2}
            decimalSeparator='.'
            displayType='input'
            fullWidth
            label='Ownership'
            onValueChange={values => {
              setMasterOwnership(values.floatValue)
            }}
            thousandSeparator=','
            thousandsGroupStyle='thousand'
            value={masterOwnership}
          />
        </Grid>
        <Grid item lg={10} md={10} sm={16} xl={10} xs={16}>
          <Autocomplete
            disabled={(!checkMasterLocked && editMaster) || (!checkPubLocked && editPub) || props.project?.distributed}
            getOptionLabel={(option: Organisation) => option.organisationEmail}
            inputValue={searchMaster}
            key={masterKey ? 'MasterKey' : 'NewMasterKey'}
            noOptionsText='Type an email'
            onChange={(event, newValue) => setSelectedMaster(newValue ?? undefined)}
            onInputChange={(event, value) => setSearchMaster(value)}
            options={searchMaster.length > 0 ? [emptyMasterItem, ...myCollaborators] : [...myCollaborators]}
            renderInput={params =>
              <SearchField
                {...params}
                disabled={(!checkMasterLocked && editMaster) ||
                  (!checkPubLocked && editPub) || props.project?.distributed}
                error={(checkMasterEmail && editMaster) || (checkPubEmail && editPub)}
                label='Collaborator Email'
                placeholder='Search collaborators'
                sx={{ marginTop: 2 }}
              />}
            renderOption={(renderProps, option) =>
              <ListItem {...renderProps}>
                <Stack
                  alignItems='center'
                  direction='row'
                  paddingX={2}
                  paddingY={1}
                  width={1}
                >
                  <Avatar src={option.logoUrl} />
                  <Stack flex='1' padding={1} width='90%'>
                    <Typography variant='subtitle2'>{option.name}</Typography>
                    <Typography variant='body2'>{option.organisationEmail}</Typography>
                  </Stack>
                </Stack>
              </ListItem>}
            value={selectedMaster}
          />
        </Grid>
        <Grid item lg={3} md={3} sm={16} xl={3} xs={16}>
          <LoadingButton
            disabled={(editMaster &&
              (!checkMasterLocked ||
                checkMasterEmail ||
                props.project?.distributed)) ||
              (editPub &&
                (!checkPubLocked ||
                checkPubEmail)) ||
              !masterOwnership ||
              !selectedMaster ||
              (!editMaster && !editPub)}
            fullWidth
            loading={loading}
            onClick={() => addRightsholder()}
            startIcon={<AddRoundedIcon />}
            sx={{
              marginTop: 2,
            }}
            variant='contained'
          >
            Add
          </LoadingButton>
        </Grid>
      </Grid>}
    </Stack>
  )
}

export default BulkUpdateRights
