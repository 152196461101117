/* eslint-disable no-await-in-loop */
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import { LoadingButton } from '@mui/lab'
import { Stack, TextField, Typography, useTheme } from '@mui/material'
import _, { clamp } from 'lodash'
import { useState } from 'react'

import { inviteUserToOrganisation } from 'src/api/organisation'
import { useAuth } from 'src/components/providers/AuthProvider'
import { emailCheck } from 'src/utils/regexUtil'

type Props = {
  handleInviteTeam: () => void
}

const InviteTeam = (props: Props) => {
  const themeItem = useTheme()
  const { currentAccount, currentOrganisation, refreshCurrentOrganisation } = useAuth()
  const [email, setEmail] = useState<string[]>([])

  const handleEmailChange = (value: string, index: number) => {
    const newEmail = _.cloneDeep(email)
    newEmail[index] = value
    setEmail(newEmail)
  }

  const [loading, setLoading] = useState(false)

  const inviteMember = async () => {
    setLoading(true)
    for (const newUser of email) {
      if (!!newUser && newUser.length > 0 && newUser !== currentAccount.email) {
        await inviteUserToOrganisation(newUser, '000000000000000000000002')
      }
    }
    await refreshCurrentOrganisation()
    setLoading(false)
    props.handleInviteTeam()
  }
  return (
    <Stack
      justifyContent='center'
      maxWidth='md'
      sx={{
        border: 'none',
        width: 1,
      }}
    >
      <form
        onSubmit={event => {
          event.preventDefault()
          void inviteMember()
        }}
      >
        <Stack alignItems='flex-start' height={1} width={1}>
          <Stack
            height={1}
            width={1}
          >
            <Stack alignItems='flex-start' direction='column' spacing={6} width={1}>
              <Stack direction='column' width={1}>
                <Typography textAlign='left' variant='h2'>
                  Invite your teammates
                </Typography>
                <Typography color={themeItem.palette.text.secondary} textAlign='left' variant='body1' >
                  Your teammates will be able to work on projects, execute tasks, and take full advantage of Releese
                  with you.
                </Typography>
              </Stack>
              <Stack direction='column' spacing={2} width={1}>
                {currentOrganisation &&
                [...Array.from({ length: clamp(currentOrganisation.seatAllocation - 1, 0, 10) })].map((_value, index) =>
                  <TextField
                    autoComplete='new-password'
                    error={!!email[index] && email[index].length > 0 && !emailCheck.test(email[index])}
                    fullWidth
                    key={`${index.toString()}`}
                    onChange={event => handleEmailChange(event.target.value, index)}
                    placeholder='Email address'
                    sx={{
                      marginTop: 0,
                    }}
                    value={email[index]}
                  />)}
              </Stack>
              <Stack alignItems='flex-end' direction='column' width={1}>
                <LoadingButton
                  disabled={email.some(item => !!item && item.length > 0 && !emailCheck.test(item))}
                  endIcon={<ArrowForwardIosRoundedIcon />}
                  loading={loading}
                  type='submit'
                  variant='contained'
                >
                  Take me to my dashboard
                </LoadingButton>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </form>
    </Stack>
  )
}

export default InviteTeam
