import { TextField } from '@mui/material'
import type { ChangeEvent } from 'react'

type Props = {
  description: string
  disabled: boolean
  error: boolean
  helperText: string
  onBlur: () => void
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  placeholder: string
  value: string
}

export const FormInput = (props: Props) =>
  <TextField
    disabled={props.disabled}
    error={props.error}
    helperText={props.error ? props.helperText : ''}
    label={props.description}
    margin='normal'
    onBlur={props.onBlur}
    onChange={props.onChange}
    placeholder={props.placeholder}
    sx={{
      marginTop: 2,
      marginBottom: 0,
    }}
    value={props.value}
  />

