/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-identical-functions */
import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Button, Card, Dialog, IconButton, Stack, TextField, Typography } from '@mui/material'
import type { ChangeEvent } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { v4 } from 'uuid'

import { createEmbeddedItem, createTaskboardGroup, updateTaskboardGroup } from 'src/api/taskboards'
import { sendWorkspaceUpdate } from 'src/api/webSocket'
import CustomFieldFreeModal from 'src/components/pages/Task/TaskViews/TaskViewComponents/CustomFieldFreeComponent'
import EmbedCard from 'src/components/pages/Task/TaskViews/TaskViewComponents/EmbedCard'
import { useAuth } from 'src/components/providers/AuthProvider'
import { type TaskboardGroup, NewTaskboardGroup } from 'src/models/Taskboard'

type Props = {
  close: () => void
  open: boolean
  taskboardGroup?: TaskboardGroup
  workspace?: string
  type?: string
  refreshBoards: () => void
}

export const EMBED_TYPES = [
  'HTML',
  'Google Sheet',
  'Google Doc',
  'Google Form',
  'Google Calendar',
  'Airtable Base',
  'DISCO',
  'YouTube',
  'Soundcloud',
  'Spotify',
]

const AddEmbedModalWorkspace = (props: Props) => {
  const { currentOrganisation } = useAuth()
  const history = useNavigate()
  const [embedName, setEmbedName] = useState('')
  const [embedContent, setEmbedContent] = useState('')
  const [embedType, setEmbedType] = useState(props.type ?? 'HTML')
  const [loading, setLoading] = useState(false)

  const handleContentChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmbedContent(event.target.value)
  }

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmbedName(event.target.value)
  }

  const handleClick = async () => {
    await handleAddItem()
  }

  const handleAddItem = async () => {
    if (!props.taskboardGroup?.id) {
      const newGroup = new NewTaskboardGroup({
        title: 'Untitled Group',
        workspaceId: props.workspace,
      })
      await createTaskboardGroup(newGroup)
        .then(async groupItem => {
          await createEmbeddedItem({
            title: embedName,
            embedType,
            content: embedContent,
            taskboardGroupId: groupItem.id ?? '',
            workspaceId: props.workspace,
          })
            .then(async board => {
              await handleGroup(board.id, groupItem)
            })
            .finally(() => setLoading(false))
        })
    } else {
      await createEmbeddedItem({
        title: embedName,
        embedType,
        content: embedContent,
        taskboardGroupId: props.taskboardGroup.id,
        workspaceId: props.workspace,
      })
        .then(async board => {
          if (props.taskboardGroup) {
            await handleGroup(board.id, props.taskboardGroup)
          }
        })
        .finally(() => setLoading(false))
    }

    setEmbedName('')
    setEmbedType('')
  }

  const handleGroup = async (board: string, groupTest: TaskboardGroup) => {
    const testGroup = groupTest
    testGroup.boardIds.push(`embed/${board}`)
    await updateTaskboardGroup({
      id: groupTest.id ?? '',
      ...testGroup,
    })
      .then(() => sendWorkspaceUpdate(v4()))
      .then(() => props.refreshBoards())
      .then(() => history(`/calendar/embed/${board}`))
    props.close()
  }

  return (
    <>
      {currentOrganisation?.membershipTier === 'FREE'
        ? <CustomFieldFreeModal
          close={props.close}
          open={props.open}
        />
        : <Dialog
          BackdropProps={{
            timeout: 500,
          }}
          closeAfterTransition
          fullScreen
          fullWidth
          maxWidth='xs'
          onClose={props.close}
          open={props.open}
          sx={{
            justifyContent: 'center',
          }}
        >
          <Stack marginX='auto' maxWidth='lg' paddingX={3} paddingY={3} spacing={2} width={1}>
            <Stack
              alignItems='center'
              direction='row'
              justifyContent='space-between'
              width='100%'
            >
              <Typography variant='h3'>
                Add Item
              </Typography>
              <IconButton onClick={() => props.close()}>
                <CloseIcon color='disabled' />
              </IconButton>
            </Stack>
            <Stack spacing={4} width={1}>
              <Card sx={{ width: 1 }} variant='outlined'>
                <Stack spacing={2}>
                  <Stack paddingTop={2} paddingX={2}>
                    <Typography variant='subtitle1'>
                      Type
                    </Typography>
                  </Stack>
                  <Stack overflow='auto' paddingBottom={2} width={1}>
                    <Stack direction='row' width='fit-content'>
                      {EMBED_TYPES.map(embedOption =>
                        <EmbedCard
                          embedOption={embedOption}
                          key={`project-${embedOption}`}
                          selected={embedType}
                          setEmbedType={setEmbedType}
                        />)}
                    </Stack>
                  </Stack>
                </Stack>
              </Card>
              <TextField
                fullWidth
                inputProps={{ maxLength: 50 }}
                label='Title'
                onChange={handleNameChange}
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    void handleClick()
                  }
                }}
                placeholder='Title'
                value={embedName}
              />
              {embedType !== 'Releese Doc' && embedType !== 'Releese Sheet' &&
              <TextField
                fullWidth
                label={embedType === 'HTML' ? 'Content' : 'URL'}
                multiline
                onChange={handleContentChange}
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    void handleClick()
                  }
                }}
                placeholder={embedType === 'HTML' ? '<iframe></iframe>' : 'https://'}
                rows={embedType === 'HTML' ? 6 : 1}
                value={embedContent}
              />}

              <Stack direction='row' justifyContent='flex-end' marginLeft='auto' marginTop={2} spacing={1} width={1}>
                <Button
                  onClick={() => props.close()}
                  variant='text'
                >
                  Cancel
                </Button>
                <LoadingButton
                  disabled={embedName.length === 0 ||
                (embedContent.length === 0 && embedType !== 'Releese Doc' && embedType !== 'Releese Sheet') ||
                embedType.length === 0}
                  loading={loading}
                  onClick={() => handleClick()}
                  variant='contained'
                >
                  Add Item
                </LoadingButton>
              </Stack>
            </Stack>
          </Stack>
        </Dialog>}
    </>
  )
}

export default AddEmbedModalWorkspace

