/* eslint-disable react-hooks/exhaustive-deps */
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { Button, Checkbox, Dialog, Divider, FormControlLabel, FormGroup, IconButton, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'

import type { AtmosSubmission } from 'src/models/Distribution'

type Props = {
  close: () => void
  open: boolean
  atmosFiles: AtmosSubmission[]
  setAtmosFiles: React.Dispatch<React.SetStateAction<AtmosSubmission[]>>
  atmosAddOn: boolean
  setAtmosAddOn: React.Dispatch<React.SetStateAction<boolean>>
}

const AtmosModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))
  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='sm'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Stack width={1}>
        <Stack alignItems='center' direction='row' justifyContent='space-between' paddingTop={2} paddingX={2} width={1}>
          <Typography variant='h3'>
            Dolby Atmos
          </Typography>
          <IconButton onClick={() => props.close()}>
            <CloseRoundedIcon color='disabled' />
          </IconButton>
        </Stack>

        <Stack paddingBottom={4} paddingX={2} spacing={2} width={1}>
          <Typography color={themeItem.palette.text.label} variant='body1'>
            You can distribute your Dolby Atmos masters through Releese to Apple Music and Tidal.
          </Typography>
          <Divider />

          {props.atmosFiles.map((recording, index) =>
            <>
              <Stack key={recording.recordingId} width={1}>
                <Typography lineHeight={1.1} variant='h5'>
                  {recording.recordingTitle}
                </Typography>
                <TextField
                  autoComplete='new-password'
                  fullWidth
                  onChange={event => props.setAtmosFiles(old => old.map((oldItem, oldIndex) => oldIndex === index
                    ? {
                      recordingId: oldItem.recordingId,
                      recordingTitle: oldItem.recordingTitle,
                      atmosFileLink: event.target.value,
                    }
                    : oldItem))}
                  placeholder='Dropbox or Google Drive link'
                  sx={{
                    marginTop: 1,
                  }}
                  value={recording.atmosFileLink}
                />
              </Stack>
              <Divider />
            </>)}

          <FormGroup>
            <FormControlLabel
              control={<Checkbox
                checked={props.atmosAddOn}
                onChange={event => props.setAtmosAddOn(event.target.checked)}
              />}
              label='I confirm to have linked Atmos compliant masters'
            />
          </FormGroup>

        </Stack>

        <Stack direction='row' justifyContent='flex-end' paddingBottom={2} paddingX={2} spacing={1} width={1}>
          <Button
            onClick={() => props.close()}
            variant='text'
          >
            Cancel
          </Button>
          <Button
            onClick={() => props.close()}
          >
            Confirm
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default AtmosModal
