import PublicRoundedIcon from '@mui/icons-material/PublicRounded'
import { Avatar, ListItemText, Stack } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import countries, { getName } from 'i18n-iso-countries'
import countryCodes from 'i18n-iso-countries/langs/en.json'
import { useState } from 'react'

import SocialAssetModal from 'src/components/pages/SocialAssets/SocialAssetModal'

type Props = {
  row: {
    city_name: string
    city_region: string
    country_code: string
    city_lat: string
    city_lng: string
    current_listeners: number
    peak_listeners: number
  }
  currentTab: string
  artworkUrl: string
}

const MonthlyListenerRow = (props: Props) => {
  const [socialDrawer, setSocialDrawer] = useState(false)
  countries.registerLocale(countryCodes)

  return (
    <>
      <TableRow
        hover
        key={props.row.city_name}
        onClick={() => setSocialDrawer(true)}
        role='checkbox'
        sx={{
          '.MuiTableCell-root': { borderBottomColor: 'divider', paddingY: 2 },
        }}
        tabIndex={-1}
      >
        <TableCell
          component='th'
          padding='normal'
          scope='row'
        >
          <Stack alignItems='center' direction='row' spacing={2}>
            <Avatar
              src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/
${props.row.country_code.toUpperCase()}.svg`}
              sx={{
                background: theme => theme.palette.info.main,
                height: 23,
                width: 35,
                borderRadius: '4px',
              }}
              variant='rounded'
            >
              <PublicRoundedIcon />
            </Avatar>
            <ListItemText
              primary={props.row.city_name}
              secondary={getName(props.row.country_code, 'en')}
            />
          </Stack>
        </TableCell>
        <TableCell align='left'>
          {props.row.current_listeners.toLocaleString()}
        </TableCell>
        <TableCell align='left'>
          {props.row.peak_listeners.toLocaleString()}
        </TableCell>
      </TableRow>
      {socialDrawer &&
      <SocialAssetModal
        close={() => setSocialDrawer(false)}
        imageMainUrl={props.artworkUrl}
        imageSecondaryUrl=''
        open={socialDrawer}
        platform={props.currentTab}
        text={`Reached ${props.row.current_listeners.toLocaleString()} monthly listeners in ${props.row.city_name}`}
      />}
    </>
  )
}

export default MonthlyListenerRow
