import axios from 'axios'

import { allowNotFoundAndUnauthorized } from 'src/api/axios'
import { baseUrlLinksApi } from 'src/api/links'
import type { BasicOrganisationDto, OrganisationDto, Role } from 'src/models/Organisation'
import { BasicOrganisation, Organisation } from 'src/models/Organisation'
import { auth } from 'src/utils/firebase'
import { toFormData } from 'src/utils/objectUtils'

const PATH = '/organisation' as const

export const getOrganisation = (organisationId: string) =>
  axios.get<OrganisationDto>(`${PATH}/${organisationId}`)
    .then(response => new Organisation(response.data))
    .catch(allowNotFoundAndUnauthorized)

export const getBasicOrganisation = (organisationId: string) =>
  axios.get<BasicOrganisationDto>(`${baseUrlLinksApi}${PATH}/basic/${organisationId}`)
    .then(response => new BasicOrganisation(response.data))
    .catch(allowNotFoundAndUnauthorized)

export const getAllOrganisations = (name: string) =>
  axios.get<OrganisationDto[]>(`${PATH}?name=${name}`)
    .then(response => response.data.map(dto => new Organisation(dto)))

export const getIcalHashReleaseSchedule = () =>
  axios.get<string>(`${PATH}/ical`)
    .then(response => response.data)

export const getSanctions = () =>
  axios.get<string>(`${PATH}/sanc`)
    .then(response => response.data)

export const getCurrentOrganisation = () =>
  axios.get<OrganisationDto>(`${PATH}/current`).then(async response => {
    await auth.currentUser?.getIdToken(true)
    return response
  })
    .then(response => new Organisation(response.data))
    .catch(allowNotFoundAndUnauthorized)

export const getUsersOrganisations = () =>
  axios.get<OrganisationDto[]>(`${PATH}/current/in`)
    .then(result => result.data.map(dto => new Organisation(dto)))

export const savePartialOrganisation = ({ query, ...organisation }: Partial<Organisation> & { query: string }) =>
  axios.patch<OrganisationDto>(`${PATH}/${query}`, toFormData(organisation))
    .then(response => new Organisation(response.data))

export const inviteUserToOrganisation = (email: string, permission?: string) =>
  axios.post<string>(`${PATH}/seats/${email.toLowerCase()}/role/${permission}`)
    .then(response => response.data)

export const changeUserPermissionOrganisation = (seatId: string, permission: string) =>
  axios.patch<string>(`${PATH}/seats/${seatId}/role/${permission}`)
    .then(response => response.data)

export const removeUserFromOrganisation = (seatId: string) =>
  axios.delete<string>(`${PATH}/seats/${seatId}`)
    .then(response => response.data)

export const addNewRole = (role: Role) =>
  axios.post<string>(`${PATH}/role`, role)
    .then(response => response.data)

export const updateRole = (id: string, role: Role) =>
  axios.patch<string>(`${PATH}/roles/${id}`, role)
    .then(response => response.data)

export const setCustomDomain = (domain: string) =>
  axios.patch<string>(`${PATH}/domain/${domain}`)
    .then(response => response.data)

export const setOrganisationAnalyticsSource = (type: string, uniqueId: string) =>
  axios.patch<string>(`${PATH}/analytics/${type}/${uniqueId}`)
    .then(response => response.data)

export const updateOrgImageFromUrl = (imageUrl: string) =>
  axios.put<string>(`${PATH}/image-from-url`, { url: imageUrl })
    .then(response => response.data)

export const agreeReleeseRules = () =>
  axios.put<boolean>(`${PATH}/accept-rules`)
    .then(response => response.data)

export const onboardingEvent = (event: string) =>
  axios.put<boolean>(`${PATH}/onboarding`, { event })
    .then(response => response.data)
