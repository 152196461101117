import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded'
import { Card, CardActionArea, Paper, Stack, Typography } from '@mui/material'

type Props = {
  transactionType: string
  setTransactionType: React.Dispatch<React.SetStateAction<string>>
  selected: string
}

const TransactionCard = (props: Props) =>
  <Card
    elevation={0}
    sx={{
      background: 'transparent',
      width: '25%',
      borderColor: props.selected === props.transactionType ? null : 'transparent',
    }}
    variant='outlined'
  >
    <CardActionArea
      onClick={() => props.setTransactionType(props.transactionType)}
      sx={{
        padding: 2,
        height: 1,
      }}
    >
      <Stack alignItems='center' width={1}>
        <Paper
          elevation={0}
          sx={{
            borderRadius: 8,
            width: 100,
            height: 100,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            border: '1px solid',
            background: props.transactionType === 'Income'
              ? theme => theme.palette.success.main
              : props.transactionType === 'Expense'
                ? theme => theme.palette.error.main
                : props.transactionType === 'Payout'
                  ? theme => theme.palette.secondary.main
                  : theme => theme.palette.primary.main,
            borderColor: props.transactionType === 'Income'
              ? theme => theme.palette.success.main
              : props.transactionType === 'Expense'
                ? theme => theme.palette.error.main
                : props.transactionType === 'Payout'
                  ? theme => theme.palette.secondary.main
                  : theme => theme.palette.primary.main,
          }}
        >
          <AttachMoneyRoundedIcon
            sx={{
              fontSize: 64,
              color: theme => theme.palette.primary.contrastText,
            }}
          />
        </Paper>
        <Stack alignItems='center' paddingTop={2}>
          <Typography sx={{ wordBreak: 'break-word' }} textAlign='center' variant='subtitle2'>
            {props.transactionType}
          </Typography>
        </Stack>
      </Stack>
    </CardActionArea>
  </Card>

export default TransactionCard
