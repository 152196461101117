import { Button, Card, Stack, Tooltip, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

import { getPaymentMethods } from 'src/api/payout'
import OnboardingModal from 'src/components/pages/Wallet/OnboardingModal/OnbordingModal'
import PaymentTable from 'src/components/pages/Wallet/PaymentItems/PaymentTable'
import PayoutMethodTable from 'src/components/pages/Wallet/PayoutMethods/PayoutMethodTable'
import WithdrawModal from 'src/components/pages/Wallet/WithdrawModal/WithdrawModal'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { PayoutMethod } from 'src/models/Payout'
import SPACING from 'src/styles/spacing'

const Wallet = () => {
  const { currentAccount, currentOrganisation } = useAuth()
  const [withdrawModal, setWithdrawModal] = useState(false)
  const [openNewPayoutMethodModal, setOpenNewPayoutMethodModal] = useState(false)
  const [payoutMethods, setPayoutMethods] = useState<PayoutMethod[]>()

  useEffect(() => {
    void getPaymentMethods()
      .then(setPayoutMethods)
  }, [currentOrganisation?.id, openNewPayoutMethodModal])

  return (
    <Stack alignItems='center' height={1} overflow='auto' padding={SPACING.CONTENT_PADDING} width={1}>
      <Stack alignItems='center' width={1}>
        {openNewPayoutMethodModal &&
        <OnboardingModal
          close={() => setOpenNewPayoutMethodModal(false)}
          open={openNewPayoutMethodModal}
        />}
        {withdrawModal &&
        <WithdrawModal
          close={() => setWithdrawModal(false)}
          open={withdrawModal}
        />}
        <Stack direction='column' maxWidth='lg' paddingY={2} spacing={2} width={1}>
          <Stack direction='column' spacing={2} width={1}>
            <Stack width={1}>
              <Card
                sx={{
                  boxShadow: 'none',
                  background: 'transparent',
                }}
              >
                <Stack alignItems='center' spacing={1}>
                  <Typography
                    lineHeight={1.2}
                    variant='h1'
                  >
                    Wallet
                  </Typography>

                  <Card
                    elevation={0}
                    sx={{
                      background: 'linear-gradient(150deg, rgba(251,66,131,1) 0%, rgba(255,144,46,1) 91%)',
                      width: 280,
                    }}
                  >
                    <Stack padding={2} spacing={2}>
                      <Typography sx={{ color: theme => theme.palette.primary.contrastText }} variant='body3'>
                        RELEESE INNOVATIONS
                      </Typography>
                      <Typography sx={{ color: theme => theme.palette.primary.contrastText }} variant='h2'>
                        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                          .format(currentOrganisation?.balance ?? 0)}
                      </Typography>
                      <Typography sx={{ color: theme => theme.palette.primary.contrastText }} variant='body3'>
                        {'Member since '}
                        {dayjs(currentAccount.createdAt).format('MMMM YYYY')}
                      </Typography>
                    </Stack>
                  </Card>
                  <Tooltip
                    title={currentOrganisation &&
                      currentOrganisation.balance < 5
                      ? 'The minimum amount you can withdraw is $5.00'
                      : ''}
                  >
                    <div>
                      <Button
                        color='success'
                        disabled={payoutMethods?.length === 0 ||
                          !currentOrganisation?.balance ||
                          currentOrganisation.balance < 5}
                        onClick={() => setWithdrawModal(true)}
                        sx={{
                          width: 280,
                        }}
                      >
                        Withdraw
                      </Button>
                    </div>
                  </Tooltip>
                </Stack>
              </Card>
            </Stack>
            <PayoutMethodTable />

            <PaymentTable />

          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default Wallet

