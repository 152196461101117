export const upcValidation = (code: number) => {
  const validlengths = [8, 12, 13]
  if (!validlengths.includes(code.toString().length)) return false

  let checksum = 0
  const codelist = [...code.toString()]
  const lastItem = codelist.pop()
  if (typeof lastItem === 'undefined') return false

  const checkdigit = Number.parseInt(lastItem, 10)
  for (const [index, value] of codelist.entries()) {
    const digit = Number.parseInt(value, 10)
    if (code.toString().length % 2 === 1) checksum += index % 2 ? digit * 3 : digit
    else checksum += index % 2 ? digit : digit * 3
  }

  let check = checksum % 10
  if (check !== 0) check = 10 - check
  if (check === checkdigit) return true
  return false
}
