import axios from 'axios'

import type { FileDto, SiteData } from 'src/models/File'
import { FileItem } from 'src/models/File'

export const baseUrlLinksApi = process.env.NODE_ENV === 'development'
  ? 'http://localhost:5000/api'
  : 'https://api.releese.io/api'

const PATH = '/files' as const

export const getFiles = (folder?: string) =>
  axios.get<FileDto[]>(folder ? `${PATH}/${folder}` : `${PATH}/`)
    .then(response => response.data.map(dto => new FileItem(dto)))

export const getRecentFiles = () =>
  axios.get<FileDto[]>(`${PATH}/recent`)
    .then(response => response.data.map(dto => new FileItem(dto)))

export const getFilesByProjectId = (project_Id: string) =>
  axios.get<FileDto[]>(`${PATH}/project/${project_Id}`)
    .then(response => response.data.map(dto => new FileItem(dto)))

export const getFilePublic = (id: string, hash: string) =>
  axios.get<FileDto>(`${PATH}/public/${id}/${hash}`)
    .then(response => new FileItem(response.data))

export const getFilesByTaskId = (taskId: string, taskboardId: string) =>
  axios.get<FileDto[]>(`${PATH}/taskItem/${taskId}/${taskboardId}`)
    .then(response => response.data.map(dto => new FileItem(dto)))

export const getFilesByTaskboardId = (taskboardId: string) =>
  axios.get<FileDto[]>(`${PATH}/taskboard/${taskboardId}`)
    .then(response => response.data.map(dto => new FileItem(dto)))

export const deleteFile = (id: string) =>
  axios.delete<string>(`${PATH}/delete/${id}`)
    .then(result => result.data)

export const uploadFile = (file: FileItem) =>
  axios.post<FileItem>(`${PATH}`, file)
    .then(response => new FileItem(response.data))

export const updateFile = (file: FileItem, id: string) =>
  axios.patch<FileItem>(`${PATH}/update/${id}`, file)
    .then(response => new FileItem(response.data))

export const getUploadLink = (organisationId: string, fileSize: number, title: string, contentType: string) =>
  axios.post<{ uploadLink: string, fileLink: string }>(`${PATH}/link`,
    { organisationId, fileSize, title, contentType })
    .then(response => response.data)

export const renameFile = (title: string, id: string) =>
  axios.patch<FileItem>(`${PATH}/rename/${id}`, { name: title })
    .then(response => new FileItem(response.data))

export const getFilePreview = (link: string) =>
  axios.post<SiteData>(`${PATH}/messagePreview`, { link })
    .then(response => response.data)
