/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable complexity */
/* eslint-disable no-await-in-loop */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/no-duplicate-string */
import { Autocomplete, Avatar, Checkbox, Chip, Link, Rating, Stack, TextField, Typography, useTheme } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { useEffect, useRef, useState } from 'react'

import { getAccount } from 'src/api/accounts'
import EditDropdownField from 'src/components/pages/Task/TaskDrawer/EditDropdownField'
import { formulaTypes } from 'src/components/pages/Task/TaskViews/TaskViewComponents/AddCustomFieldsModal'
import type Account from 'src/models/Account'
import type { CustomFields, CustomFieldValue, TaskItem } from 'src/models/Taskboard'

type Props = {
  fieldType: CustomFields
  fieldValue: CustomFieldValue
  handleFieldChange: (newvalue: CustomFieldValue) => Promise<void>
  fields: CustomFields[]
  taskData: TaskItem
}

const RenderListItemCustom = (props: Props) => {
  const themeItem = useTheme()
  const [valueItem, setValue] = useState({ ...props.fieldValue })
  const [people, setPeople] = useState<Account[]>([])
  const [editing, setEditing] = useState(false)
  const emptyString: string[] = []
  const inputRef = useRef<HTMLInputElement>()

  const formulaValueOne = props.fields.find(item =>
    item.id === props.fieldType.formulaValues[0] && (item.fieldType === 'Money' || item.fieldType === 'Number'))
  const formulaValueTwo = props.fields.find(item =>
    item.id === props.fieldType.formulaValues[1] && (item.fieldType === 'Money' || item.fieldType === 'Number'))

  const fieldValueOne =
    props.taskData.customFields.find(item => item.rowId === props.fieldType.formulaValues[0])?.numberValue ?? 0
  const fieldValueTwo =
    props.taskData.customFields.find(item => item.rowId === props.fieldType.formulaValues[1])?.numberValue ?? 0

  const sumValue = fieldValueOne + fieldValueTwo
  const minusValue = fieldValueOne - fieldValueTwo
  const multiplicationValue = fieldValueOne * fieldValueTwo
  const divisionValue = fieldValueOne / fieldValueTwo

  const websiteString = valueItem.websiteValue
    .replace('www.', '')
    .replace('https://', '')
    .replace('http://', '')
    .split('/')[0]
    .split('?')[0]

  useEffect(() => {
    setValue(props.fieldValue)
    void getAssignees()
  }, [props.fieldValue])

  const getAssignees = async () => {
    if (!editing) {
      setEditing(true)
      const currentAssignees = people.map(account => account.id)
      if (props.fieldValue.peopleValue.length !== currentAssignees.length ||
      !props.fieldValue.peopleValue.every((value, index) => value === currentAssignees[index])) {
        setPeople([])
        for (const assignees of props.fieldValue.peopleValue) {
          await getAccount(assignees)
            .then(account => account && setPeople(previousPeople => [...previousPeople, account]))
        }
      }
      setEditing(false)
    }
  }

  return (
    <Stack width={1}>
      {props.fieldType.fieldType === 'Text' &&
      <Typography color={themeItem.palette.text.secondary}>
        {valueItem.stringValue}
      </Typography>}

      {props.fieldType.fieldType === 'Number' &&
      <Typography color={themeItem.palette.text.secondary}>
        {valueItem.numberValue !== 0 ? valueItem.numberValue.toFixed(2) : ''}
      </Typography>}

      {props.fieldType.fieldType === 'Money' &&
      <Typography color={themeItem.palette.text.secondary}>
        {valueItem.numberValue !== 0
          ? new Intl.NumberFormat('en-CA', {
            style: 'currency',
            currency: props.fieldType.currency,
          }).format(valueItem.numberValue)
          : ''}
      </Typography>}

      {props.fieldType.fieldType === 'Checkbox' &&
      <Checkbox
        checked={valueItem.checkboxValue}
        onChange={(event, value) => {
          void props.handleFieldChange({ ...valueItem, checkboxValue: value })
          setValue({ ...valueItem, checkboxValue: value })
        }}
        onClick={event => event.stopPropagation()}
        sx={{
          width: 'fit-content',
          marginLeft: 1,
        }}
      />}

      {props.fieldType.fieldType === 'Chips' &&
      <Stack direction='row' flexWrap='wrap' onClick={event => event.stopPropagation()} width={1}>
        <Autocomplete
          freeSolo
          fullWidth
          multiple
          onChange={(event, value) => {
            void props.handleFieldChange({ ...valueItem, stringArrayValue: value })
            setValue({ ...valueItem, stringArrayValue: value })
          }}
          options={emptyString}
          renderInput={params =>
            <TextField
              {...params}
              fullWidth
              inputRef={inputRef}
              onClick={event => event.stopPropagation()}
              placeholder={document.activeElement === inputRef.current ? 'Type and press enter' : ''}
              sx={{
                marginTop: 0,
                border: 'none',
                '.MuiInputBase-root': {
                  background: 'transparent!important',
                  borderColor: 'transparent!important',
                  paddingY: 0,
                  marginTop: 0,
                },
              }}
            />}
          sx={{
            width: 1,
            background: 'transparent!important',
            border: 'none',
            '.MuiInputBase-root': {
              background: 'transparent!important',
              border: 'none',
            },
          }}
          value={valueItem.stringArrayValue}
        />
      </Stack>}

      {props.fieldType.fieldType === 'Date' &&
      <Stack onClick={event => event.stopPropagation()}>
        <DesktopDatePicker
          format='MMMM DD, YYYY'
          onChange={event => {
            void props.handleFieldChange({ ...valueItem, dateValue: event ? event.toDate() : null })
            setValue({ ...valueItem, dateValue: event ? event.toDate() : null })
          }}
          sx={{
            marginTop: 0,
            background: 'transparent',
            '.MuiInputBase-root': {
              border: 'none',
              background: 'transparent!important',
              borderColor: 'transparent!important',
              paddingY: 0,
              marginTop: 0,
            },
          }}
          value={valueItem.dateValue ? dayjs(valueItem.dateValue) : null}
        />
      </Stack>}

      {props.fieldType.fieldType === 'Formula' && formulaValueOne && formulaValueTwo &&
      <Typography color={themeItem.palette.text.secondary}>
        {props.fieldType.formulaType === formulaTypes[0] &&
          (sumValue || 0).toLocaleString()}
        {props.fieldType.formulaType === formulaTypes[1] &&
          (minusValue || 0).toLocaleString()}
        {props.fieldType.formulaType === formulaTypes[2] &&
          (multiplicationValue || 0).toLocaleString()}
        {props.fieldType.formulaType === formulaTypes[3] &&
          (divisionValue || 0).toLocaleString()}
      </Typography>}

      {props.fieldType.fieldType === 'Website' && websiteString.length > 0 &&
      <Link
        href={valueItem.websiteValue}
        onClick={event => {
          event.stopPropagation()
        }}
        sx={{
          width: 'fit-content',
        }}
        target='_blank'
      >
        <Stack alignItems='center' direction='row' spacing={1}>
          <img
            alt='Website Icon'
            height='16px'
            src={`https://www.google.com/s2/favicons?domain=${websiteString}`}
            style={{
              marginRight: '8px',
            }}
            width='16px'
          />
          {websiteString.length > 15
            ? `${websiteString.slice(0, 12)}...`
            : websiteString}
        </Stack>
      </Link>}

      {props.fieldType.fieldType === 'Email' &&
      <Link
        href={`mailto:${valueItem.stringValue}`}
        onClick={event => {
          event.stopPropagation()
        }}
        sx={{
          width: 'fit-content',
        }}
      >
        {valueItem.stringValue.length > 15
          ? `${valueItem.stringValue.slice(0, 12)}...`
          : valueItem.stringValue}
      </Link>}

      {props.fieldType.fieldType === 'Phone' &&
      <Link
        href={`tel:${valueItem.stringValue}`}
        onClick={event => {
          event.stopPropagation()
        }}
        sx={{
          width: 'fit-content',
        }}
      >
        {valueItem.stringValue}
      </Link>}

      {props.fieldType.fieldType === 'Dropdown' &&
      <Typography>
        {valueItem.dropdownValue}
      </Typography>}

      {props.fieldType.fieldType === 'Status' && valueItem.dropdownValue.length > 0 &&
      <Stack onClick={event => event.stopPropagation()}>
        <EditDropdownField
          colors={props.fieldType.dropdownColors}
          handleValueChange={value => {
            void props.handleFieldChange({ ...valueItem, dropdownValue: value })
            setValue({ ...valueItem, dropdownValue: value })
          }}
          options={props.fieldType.dropdownOptions}
          statusName={valueItem.dropdownValue}
        />
      </Stack>}

      {props.fieldType.fieldType === 'Rating' &&
      <Stack
        direction='row'
      >
        <Rating
          onChange={(event, value) => {
            void props.handleFieldChange({ ...valueItem, ratingValue: value ?? 0 })
            setValue({ ...valueItem, ratingValue: value ?? 0 })
          }}
          onClick={event => event.stopPropagation()}
          size='small'
          value={valueItem.ratingValue}
        />
      </Stack>}

      {props.fieldType.fieldType === 'People' &&
      <Stack direction='row' flexWrap='wrap' >
        {valueItem.peopleValue.length > 0 &&
          people.map(assigneeItem =>
            <Chip
              avatar={<Avatar
                src={assigneeItem.profilePictureUrl}
                sx={{
                  zIndex: 1,
                }}
              />}
              key={assigneeItem.id}
              label={`${assigneeItem.firstName}`}
              rounded
              size='small'
              sx={{
                width: 'fit-content',
                margin: 0.5,
              }}
              variant='subtle'
            />)}
      </Stack>}
    </Stack>
  )
}

export default RenderListItemCustom
