/* eslint-disable max-len */

export type FirebaseErrorMessages = { code: string, message: string }

const firebaseErrorMessages: FirebaseErrorMessages[] = [
  { code: 'auth/missing-code', message: 'Please enter a code' },
  { code: 'auth/app-deleted', message: 'The database was not found' },
  { code: 'auth/expired-action-code', message: 'The action code or link has expired' },
  { code: 'auth/invalid-action-code', message: 'The action code is invalid. This can happen if the code is malformed or has already been used' },
  { code: 'auth/user-disabled', message: 'The user corresponding to the provided credential has been disabled' },
  { code: 'auth/user-not-found', message: 'User does not match any credentials' },
  { code: 'auth/weak-password', message: 'The password is too weak' },
  { code: 'auth/email-already-in-use', message: 'I already had an account with the provided email address' },
  { code: 'auth/invalid-email', message: 'The email address is invalid' },
  { code: 'auth/operation-not-allowed', message: 'The account type corresponding to this credential is not yet activated' },
  { code: 'auth/account-exists-with-different-credential', message: 'Email already associated with another account' },
  { code: 'auth/auth-domain-config-required', message: 'Configuration for authentication has not been provided' },
  { code: 'auth/credential-already-in-use', message: 'An account already exists for this credential' },
  { code: 'auth/operation-not-supported-in-this-environment', message: 'This operation is not supported in the environment being performed. Make sure it must be http or https' },
  { code: 'auth/timeout', message: 'Response time exceeded. The domain may not be authorized to perform operations' },
  { code: 'auth/missing-android-pkg-name', message: 'A package name must be provided to install the Android application' },
  { code: 'auth/missing-continue-uri', message: 'The following URL must be provided in the request' },
  { code: 'auth/missing-ios-bundle-id', message: 'A bundle name must be provided to install the iOS application' },
  { code: 'auth/invalid-continue-uri', message: 'The following URL provided in the request is not valid' },
  { code: 'auth/unauthorized-continue-uri', message: 'The domain of the following URL is not whitelisted' },
  { code: 'auth/invalid-dynamic-link-domain', message: 'The provided dynamic link domain is not authorized or configured in the current project' },
  { code: 'auth/argument-error', message: 'Check the binding settings for the application' },
  { code: 'auth/invalid-persistence-type', message: 'The type specified for data persistence is invalid' },
  { code: 'auth/unsupported-persistence-type', message: 'The current environment does not support the specified type for data persistence' },
  { code: 'auth/invalid-credential', message: 'The credential has expired or is malformed' },
  { code: 'auth/wrong-password', message: 'Please enter a valid email address and password' },
  { code: 'auth/invalid-verification-code', message: 'The credential verification code is invalid' },
  { code: 'auth/invalid-verification-id', message: 'The credential verification ID is invalid' },
  { code: 'auth/custom-token-mismatch', message: 'The token is different from the requested standard' },
  { code: 'auth/invalid-custom-token', message: 'The supplied token is invalid' },
  { code: 'auth/captcha-check-failed', message: 'The reCAPTCHA response token is invalid, expired, or the domain is not allowed' },
  { code: 'auth/invalid-phone-number', message: 'The phone number is in an invalid format (E.164 standard)' },
  { code: 'auth/missing-phone-number', message: 'The phone number is required' },
  { code: 'auth/quota-exceeded', message: 'The SMS quota has been exceeded' },
  { code: 'auth/canceled-popup-request', message: 'Only one popup request is allowed at a time' },
  { code: 'auth/popup-blocked', message: 'The browser has blocked the popup' },
  { code: 'auth/popup-closed-by-user', message: '' },
  { code: 'auth/unauthorized-domain', message: 'The application domain is not authorized to perform operations' },
  { code: 'auth/invalid-user-token', message: 'The current user was not identified' },
  { code: 'auth/user-token-expired', message: 'The current user’s token has expired' },
  { code: 'auth/null-user', message: 'The current user is null' },
  { code: 'auth/app-not-authorized', message: 'Application not authorized to authenticate with the given key' },
  { code: 'auth/invalid-api-key', message: 'The provided API key is invalid' },
  { code: 'auth/network-request-failed', message: 'Error connecting to network' },
  { code: 'auth/requires-recent-login', message: 'Please logout, log in and try again' },
  { code: 'auth/too-many-requests', message: 'Requests were blocked due to unusual activity. Please try again after a while' },
  { code: 'auth/web-storage-unsupported', message: 'The browser does not support storage or if the user has disabled this function' },
  { code: 'auth/invalid-claims', message: 'The custom registry attributes are not valid' },
  { code: 'auth/claims-too-large', message: 'The size of the request exceeds the maximum allowed size of 1 Megabyte' },
  { code: 'auth/id-token-expired', message: 'The reported token has expired' },
  { code: 'auth/id-token-revoked', message: 'The reported token has expired' },
  { code: 'auth/invalid-argument', message: 'An invalid argument was supplied to a method' },
  { code: 'auth/invalid-creation-time', message: 'The creation time must be a valid UTC date' },
  { code: 'auth/invalid-disabled-field', message: 'Property for disabled user is invalid' },
  { code: 'auth/invalid-display-name', message: 'The username is not valid' },
  { code: 'auth/invalid-email-verified', message: 'The email is not valid' },
  { code: 'auth/invalid-hash-algorithm', message: 'The HASH algorithm does not support cryptography' },
  { code: 'auth/invalid-hash-block-size', message: 'The HASH block size is invalid' },
  { code: 'auth/invalid-hash-derived-key-length', message: 'The size of the HASH derived key is invalid' },
  { code: 'auth/invalid-hash-key', message: 'The HASH key must have a valid byte buffer' },
  { code: 'auth/invalid-hash-memory-cost', message: 'HASH memory cost is not valid' },
  { code: 'auth/invalid-hash-parallelization', message: 'HASH parallel loading is invalid' },
  { code: 'auth/invalid-hash-rounds', message: 'HASH rounding is not valid' },
  { code: 'auth/invalid-hash-salt-separator', message: 'The SALT separator field of the HASH generation algorithm must be a valid byte buffer' },
  { code: 'auth/invalid-id-token', message: 'The entered token code is invalid' },
  { code: 'auth/invalid-last-sign-in-time', message: 'The last login time must be a valid UTC date' },
  { code: 'auth/invalid-page-token', message: 'The following URL provided in the request is not valid' },
  { code: 'auth/invalid-password', message: 'The password is not valid, it must be at least 6 characters long' },
  { code: 'auth/invalid-password-hash', message: 'The HASH password is invalid' },
  { code: 'auth/invalid-password-salt', message: 'SALT password is invalid' },
  { code: 'auth/invalid-photo-url', message: 'The URL of the user’s photo is not valid' },
  { code: 'auth/invalid-provider-id', message: 'The provider identifier is not supported' },
  { code: 'auth/invalid-session-cookie-duration', message: 'The duration of the session COOKIE must be a valid number in milliseconds, between 5 minutes and 2 weeks' },
  { code: 'auth/invalid-uid', message: 'The identifier provided must have a maximum of 128 characters' },
  { code: 'auth/invalid-user-import', message: 'The user record to import is invalid' },
  { code: 'auth/invalid-provider-data', message: 'The data provider is invalid' },
  { code: 'auth/maximum-user-count-exceeded', message: 'The maximum number of users allowed to import has been exceeded' },
  { code: 'auth/missing-hash-algorithm', message: 'It is necessary to provide the HASH generation algorithm and its parameters to import users' },
  { code: 'auth/missing-uid', message: 'An identifier is required for the current operation' },
  { code: 'auth/reserved-claims', message: 'One or more custom properties provided reserved words used' },
  { code: 'auth/session-cookie-revoked', message: 'The COOKIE session has expired' },
  { code: 'auth/uid-adic-exists', message: 'The provided identifier is already in use' },
  { code: 'auth/email-already-exists', message: 'The email provided is already in use' },
  { code: 'auth/phone-number-already-exists', message: 'The provided phone is already in use' },
  { code: 'auth/project-not-found', message: 'No projects found' },
  { code: 'auth/insufficient-permission', message: 'The credential used does not have access to the requested resource' },
  { code: 'auth/internal-error', message: 'The authentication server encountered an unexpected error while trying to process the request' },
]
export default firebaseErrorMessages

