/* eslint-disable react-hooks/exhaustive-deps */
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import LinkRoundedIcon from '@mui/icons-material/LinkRounded'
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import { Alert, Avatar, AvatarGroup, Box, Chip, Collapse, IconButton, ListItemIcon, Menu, MenuItem, Paper, Snackbar, Stack, TableCell, TableRow, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import dayjs from 'dayjs'
import moment from 'moment'
import { useEffect, useState } from 'react'

import { getRecordingsByProjectId } from 'src/api/recordings'
import DeleteProjectModal from 'src/components/pages/Projects/ProjectTabs/ProjectsTab/DeleteProjectModal'
import NestedTable from 'src/components/pages/Projects/ProjectTabs/ProjectsTab/NestedTable'
import { getColor } from 'src/components/pages/Projects/ViewProject/ViewProjectDetails'
import { useAuth } from 'src/components/providers/AuthProvider'
import { RouterLink } from 'src/components/SafeRouterLink'
import { Permissions } from 'src/models/Organisation'
import type Project from 'src/models/Project'
import type Recording from 'src/models/Recording'

type Props = {
  project: Project
  updateProjects: () => Promise<void>
}

const ProjectsTableItem = (props: Props) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))
  const { currentOrganisation, currentAccountPermissions } = useAuth()
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const [deleteProjectModal, setDeleteProjectModalOpen] = useState(false)
  const [linkCopiedToast, setLinkCopiedToastOpen] = useState(false)
  const dateItem = props.project.releaseDate.toISOString()
  const testItem = moment(dateItem).tz('America/New_York')

  const handleCloseToast = (event: Event | React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setLinkCopiedToastOpen(false)
  }

  const openMenu = Boolean(anchorElement)
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorElement(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorElement(null)
  }

  const [expanded, setExpanded] = useState(false)
  const [recordings, setRecordings] = useState<Recording[]>([])

  const expandRecordings = (event: React.MouseEvent<HTMLElement>, expand: boolean) => {
    event.stopPropagation()
    event.preventDefault()
    setExpanded(expand)
  }

  const copyLink = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    event.preventDefault()
    setLinkCopiedToastOpen(true)
    void navigator.clipboard.writeText(`https://cloud.releese.io/share/${props.project.id}`)
  }

  useEffect(() => {
    getRecordingsByProjectId(props.project.id)
      .then(setRecordings)
      .catch(() => setRecordings([]))
  }, [])

  const recordingString = `${props.project.recordingCount} ${props.project.recordingCount === 1
    ? 'Recording'
    : 'Recordings'}`

  return (
    <>
      <Snackbar
        autoHideDuration={5000}
        message='Link Copied'
        onClose={handleCloseToast}
        open={linkCopiedToast}
      >
        <Alert
          onClose={handleCloseToast}
          severity='success'
          sx={{ width: '100%' }}
          variant='filled'
        >
          Link copied
        </Alert>
      </Snackbar>
      {!props.project.distributed &&
      props.project.organisation?.id === currentOrganisation?.id &&
      <DeleteProjectModal
        close={() => setDeleteProjectModalOpen(false)}
        open={deleteProjectModal}
        project={props.project}
        updateProjects={props.updateProjects}
      />}
      <TableRow
        component={RouterLink}
        key={props.project.id}
        sx={{
          textDecoration: 'none',
          backgroundColor: theme.palette.background.default,
          '&:hover .MuiTableCell-root': {
            backgroundColor: theme.palette.background.input,
          },
          '.MuiTableCell-root': { borderBottomColor: 'divider' },
        }}
        to={`/project/${props.project.id}`}
      >
        {!matches &&
        <TableCell id='checkbox' onClick={event => expandRecordings(event, !expanded)}>
          <Stack alignItems='start' direction='row'>
            <IconButton onClick={event => expandRecordings(event, !expanded)}>
              {
                expanded ? <ArrowDropDownIcon fontSize='tiny' /> : <ArrowRightIcon fontSize='tiny' />
              }
            </IconButton>
          </Stack>
        </TableCell>}
        <TableCell id='project_image'>
          {props.project.artworkPreviewUrl
            ? <img
              alt='Artwork'
              height='36px'
              src={props.project.artworkPreviewUrl}
              style={{
                borderRadius: 4,
                objectFit: 'cover',
              }}
              width='36px'
            />
            : <Paper
              elevation={0}
              sx={{
                height: 36,
                width: 36,
                borderRadius: 0.5,
                backgroundColor: theme.palette.background.input,
              }}
            >
              <MusicNoteRoundedIcon
                sx={{
                  fontSize: 36,
                  color: theme.palette.action.disabled,
                }}
              />
            </Paper>}

        </TableCell>
        <TableCell id='project_title'>
          <Stack>

            <Typography color='text.link' sx={{ wordBreak: 'break-word' }} variant='tableCell'>
              {props.project.title}
            </Typography>

            <Typography color='text.secondary' variant='body3'>
              {recordingString}
            </Typography>
          </Stack>
        </TableCell>
        {!matches &&
        <>
          <TableCell id='status'>
            <Chip
              color={getColor(props.project.status)}
              label={props.project.status}
              variant='subtle'
            />
          </TableCell>
          <TableCell id='release_date'>
            <Stack alignItems='center' direction='row' spacing={2}>
              <CalendarTodayIcon sx={{ fontSize: 'inherit' }} />
              <Typography variant='tableCell'>
                {testItem.format('MMM D YYYY')}
              </Typography>
            </Stack>
          </TableCell>
          <TableCell id='collaborators'>
            <Stack direction='row'>
              <AvatarGroup
                max={4}
                sx={{
                  '.MuiAvatarGroup-avatar': {
                    width: 24,
                    height: 24,
                    fontSize: 12,
                  },
                }}
              >
                <Avatar
                  alt={props.project.organisation?.name}
                  key={props.project.organisation?.id}
                  src={props.project.organisation?.logoUrl}
                />
                {props.project.collaborators.map(collaborator => <Avatar
                  alt={collaborator.account.name}
                  key={collaborator.account.id}
                  src={collaborator.account.logoUrl}
                />)}
              </AvatarGroup>
            </Stack>
          </TableCell>
          <TableCell sx={{ width: 160 }}>
            {props.project.UPC}
          </TableCell>
          <TableCell id='last modified' sx={{ width: 160 }}>
            {dayjs(props.project.updatedAt).format('MMM D YYYY, HH:mm')}
          </TableCell>
          <TableCell id='project_tags'>
            <Stack alignItems='center' direction='row' flexWrap='wrap'>
              {props.project.tags.map(tag => <Chip key={tag} label={tag} size='small' sx={{ margin: 0.2 }} />)}
            </Stack>
          </TableCell>
        </>}

        <TableCell>
          <Stack alignItems='center' direction='row' spacing={1}>
            <Tooltip title='Copy link'>
              <IconButton onClick={event => copyLink(event)}>
                <LinkRoundedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='More'>
              <IconButton onClick={handleMenuClick}>
                <MoreHorizRoundedIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={12} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse in={expanded} timeout='auto' unmountOnExit>
            <Box sx={{
              margin: 2,
            }}
            >
              {recordings.length > 0
                ? <NestedTable recordings={expanded ? recordings : []} />
                : <Typography color='text.label' sx={{ margin: 4 }} variant='body2'>No recordings found</Typography>}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Menu
        anchorEl={anchorElement}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClick={handleClose}
        onClose={handleClose}
        open={openMenu}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <MenuItem
          disabled={props.project.distributed ||
            !currentAccountPermissions?.includes(Permissions.CREATE_EDIT_REMOVE_PROJECTS) ||
            props.project.organisation?.id !== currentOrganisation?.id}
          onClick={() => setDeleteProjectModalOpen(true)}
        >
          <ListItemIcon>
            <DeleteRoundedIcon color='error' fontSize='medium' />
          </ListItemIcon>
          Delete Release
        </MenuItem>
      </Menu>
    </>
  )
}

export default ProjectsTableItem
