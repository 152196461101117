/* eslint-disable @typescript-eslint/no-magic-numbers */
/* eslint-disable id-length */
import { Skeleton, Stack, Typography, useTheme } from '@mui/material'
import { useRef } from 'react'
import Chart from 'react-apexcharts'

import { useContainerDimensions } from 'src/components/ContainerDimensions'
import type { MarketingAnalyticsString } from 'src/models/Marketing'

type Props = {
  destinations: MarketingAnalyticsString[]
  destinationsPresaves: MarketingAnalyticsString[]
  loading: boolean
}

const SourcesChart = (props: Props) => {
  const theme = useTheme()
  const labels = props.destinations.map(({ _id }) =>  _id)
  const series = props.destinations.map(({ count }) => count)

  const labelsPresaves = props.destinationsPresaves.map(({ _id }) =>  _id)
  const seriesPresaves = props.destinationsPresaves.map(({ count }) => count)

  const seriesSources = {
    name: 'Clicks',
    labels,
    data: series,
    color: theme.palette.primary.main,
  }

  const seriesSourcesPresaves = {
    name: 'Pre-saves',
    labels: labelsPresaves,
    data: seriesPresaves,
    color: theme.palette.success.main,
  }

  const optionsSources = {
    series: [seriesSources, seriesSourcesPresaves],
    options: {
      labels: seriesSources.labels,
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          columnWidth: '25%',
          distributed: false,
          borderRadius: 8,
        },
      },
      chart: {
        foreColor: theme.palette.text.primary,
        toolbar: {
          show: false,
        },
        stacked: false,
      },
      legend: {
        show: false,
      },
      grid: {
        borderColor: theme.palette.divider,
      },
      yaxis: {
        min: 0,
        forceNiceScale: true,
        labels: {
          formatter: (value: number) => value.toLocaleString(),
        },
        axisBorder: {
          color: theme.palette.divider,
        },
        axisTicks: {
          color: theme.palette.divider,
        },
      },
      tooltip: {
        theme: 'dark',
        followCursor: true,
        x: {
          show: false,
        },
        axisBorder: {
          color: theme.palette.divider,
        },
        axisTicks: {
          color: theme.palette.divider,
        },
      },
      xaxis: {
        labels: {
          format: 'dd/MM',
        },
        tooltip: {
          enabled: false,
        },
        axisBorder: {
          color: theme.palette.divider,
        },
        axisTicks: {
          color: theme.palette.divider,
        },
      },
    },
  }

  const componentRef = useRef<HTMLDivElement>(null)
  const { width } = useContainerDimensions(componentRef)

  return (
    <>
      <Typography variant='h3'>
        Conversions
      </Typography>
      <Stack alignItems='center' ref={componentRef} width={1}>
        {props.loading
          ? <Skeleton
            height={300}
            sx={{
              transform: 'none',
            }}
            width='100%'
          />
          : <Chart
            height={300}
            options={optionsSources.options}
            series={optionsSources.series}
            type='bar'
            width={width}
          />}
      </Stack>
    </>
  )
}
export default SourcesChart

