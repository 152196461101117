import { LoadingButton } from '@mui/lab'
import { Stack, TextField, Typography } from '@mui/material'
import type { ChangeEvent } from 'react'
import { useState } from 'react'

import { OrganisationAvatar } from 'src/components/pages/Settings/Marketing/Profile/OrganisationAvatar'
import { useAuth } from 'src/components/providers/AuthProvider'

const Profile = () => {
  const { updateCurrentOrganisation, currentOrganisation, refreshCurrentOrganisation } = useAuth()

  const [loading, setLoading] = useState(false)
  const [name, setName] = useState(currentOrganisation?.name)

  const handleSubmit = async () => {
    setLoading(true)
    await updateCurrentOrganisation({
      ...currentOrganisation,
      name,
    })
      .then(() => setLoading(false))
      .then(refreshCurrentOrganisation)
      .catch(() => null)
      .finally(() => setLoading(false))
  }

  const uploadProfilePicture = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) return
    await updateCurrentOrganisation({
      ...currentOrganisation,
      logo: file,
    })
      .then(() => setLoading(false))
      .then(refreshCurrentOrganisation)
      .catch(() => null)
      .finally(() => setLoading(false))
  }

  return (
    <Stack padding={2} width={1}>
      <Stack padding={2} width={1}>
        <Typography lineHeight={1.2} variant='h3'>Profile</Typography>
        <Typography color='text.label' maxWidth={640} variant='body1'>
          {'Your profile will be visible on your Landing Pages '}
        </Typography>
      </Stack>
      {currentOrganisation &&
      <Stack direction='column' height={1} maxWidth={896} overflow='auto' padding={2} spacing={4}>
        <Stack alignItems='center' justifyContent='center' spacing={4}>
          <OrganisationAvatar
            account={currentOrganisation}
            changeProfilePicture={uploadProfilePicture}
            isCurrentAccount
          />
          <TextField
            fullWidth
            inputProps={{ maxLength: 24 }}
            label='Display name'
            onChange={event_ => setName(event_.target.value)}
            placeholder='mr_cool_99'
            required
            value={name}
          />
          <LoadingButton
            loading={loading}
            onClick={() => void handleSubmit()}
            variant='contained'
          >
            Save
          </LoadingButton>
        </Stack>
      </Stack>}
    </Stack>
  )
}

export default Profile
