import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import { Stack, Typography } from '@mui/material'

const OnboardingComplete = () =>
  <Stack alignItems='center' spacing={1} width={1}>
    <CheckCircleRoundedIcon color='success' sx={{ height: 64, width: 64 }} />
    <Typography textAlign='center' variant='body2'>
      Your payout method was succesfully added
    </Typography>
  </Stack>

export default OnboardingComplete
