/* eslint-disable react-hooks/exhaustive-deps */
import AppleIcon from '@mui/icons-material/Apple'
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded'
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded'
import { Avatar, Button, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, TableRow, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { RRule } from 'rrule'

import { getIcalHashAccount } from 'src/api/accounts'
import { getAllTaskboards } from 'src/api/taskboards'
import GoogleCalendarLogo from 'src/components/Icons/GoogleCalendarLogo'
import TaskItemDashboard from 'src/components/pages/Dashboard/Tasks/TasksComponents/TaskItem'
import TaskItemSkeleton from 'src/components/pages/Dashboard/Tasks/TasksComponents/TaskItemSkeleton'
import NewTaskModal from 'src/components/pages/Task/TaskDrawer/NewTaskModal'
import TaskSyncModal from 'src/components/pages/Task/TaskSync/TaskSyncModal'
import { getDayItem } from 'src/components/pages/Task/TaskViews/TaskViewTypes/Calendar'
import { useAuth } from 'src/components/providers/AuthProvider'
import { NavLink } from 'src/components/SafeRouterLink'
import type { TaskBoard } from 'src/models/Taskboard'

const MyTasks = () => {
  const themeItem = useTheme()
  const matches = useMediaQuery(themeItem.breakpoints.down('md'))
  const { currentAccount } = useAuth()
  const [loading, setLoading] = useState(true)
  const [taskboards, setTaskboards] = useState<TaskBoard[]>()
  const [newTaskModal, setNewTaskModal] = useState(false)
  const [hash, setHash] = useState('')

  const [openSyncModal, setOpenSyncModal] = useState(false)
  const [syncType, setSyncType] = useState<'apple' | 'google'>('google')
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const openMenu = Boolean(anchorElement)

  useEffect(() => {
    void getIcalHashAccount()
      .then(setHash)
  }, [currentAccount.id])

  const flatItems = taskboards?.map(taskboard =>
    taskboard.taskGroups.map(group =>
      group.tasks.map(task => {
        if (task.recurring === 'Monthly') {
          const dateItemTest = new Date(task.startDate ?? 0).getDay()
          const eventsRules = new RRule({
            freq: RRule.WEEKLY,
            byweekday: getDayItem(dateItemTest),
            dtstart: new Date(task.startDate ?? 0),
            interval: 4,
            until: new Date('January 11, 2025'),
          })
          const times = eventsRules.all()
          const nextDate = times.find(item => item.getTime() > Date.now())
          return {
            task,
            group,
            taskboard,
            dueDate: nextDate,
          }
        } else if (task.recurring === 'Weekly') {
          const dateItemTest = new Date(task.startDate ?? 0).getDay()
          const eventsRules = new RRule({
            freq: RRule.WEEKLY,
            byweekday: getDayItem(dateItemTest),
            dtstart: new Date(task.startDate ?? 0),
            interval: 1,
            until: new Date('January 11, 2025'),
          })
          const times = eventsRules.all()
          const nextDate = times.find(item => item.getTime() > Date.now())
          return {
            task,
            group,
            taskboard,
            dueDate: nextDate,
          }
        } else {
          return {
            task,
            group,
            taskboard,
            dueDate: task.dueDate,
          }
        }
      })))
    .flat(2)
    .filter(task => task.task.assignee.includes(currentAccount.id))
    .filter(task =>
      task.task.recurring === 'Monthly' || task.task.recurring === 'Weekly' || task.task.status !== 'Done')
    .sort((a, b) => Number(new Date(a.dueDate ?? new Date())) - Number(new Date(b.dueDate ?? new Date())))

  useEffect(() => {
    setLoading(true)
    getAllTaskboards()
      .then(data => {
        setTaskboards(data)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return (
    <>
      <Stack height={1} width={1}>
        <Stack alignItems='center' direction='row' justifyContent='space-between' padding={2} spacing={2}>
          <Stack alignItems='center' direction='row' spacing={2}>
            {matches &&
            <IconButton
              color='primary'
              component={NavLink}
              size='small'
              to='/calendar'
            >
              <ChevronLeftRoundedIcon />
            </IconButton>}
            <Avatar
              alt={currentAccount.firstName}
              src={currentAccount.profilePictureUrl}
              style={{
                width: 64,
                height: 64,
                objectFit: 'cover',
              }}
              variant='circular'
            />
            <Stack alignContent='center' height={1} justifyContent='center'>
              <ListItemText
                primary={<Typography variant='h5'>
                  Your Tasks
                </Typography>}
                secondary={currentAccount.firstName}
              />
            </Stack>
          </Stack>
          <Stack>
            <Button
              color='inherit'
              onClick={event => setAnchorElement(event.currentTarget)}
              startIcon={<CalendarMonthRoundedIcon />}
            >
              Sync Calendar
            </Button>
          </Stack>
        </Stack>
        <Divider />
        <Stack height={1} overflow='auto' spacing={2}>
          <Stack
            width={1}
          >
            {!loading && taskboards
              ? <Stack>
                <TableRow
                  hover
                  onClick={() => setNewTaskModal(true)}
                  sx={{
                    borderBottom: theme => `1px solid ${theme.palette.divider}`,
                    backgroundColor: theme => theme.palette.background.default,
                    textDecoration: 'none',
                    '&:hover .MuiTableCell-root': {
                      backgroundColor: theme => theme.palette.background.input,
                      textDecoration: 'none',
                    },
                    '&:hover': {
                      cursor: 'pointer',
                    },
                    width: 1,
                    overflow: 'hidden',
                    tableLayout: 'fixed',
                  }}
                >
                  <Stack padding={2} width={1}>
                    <Typography color={themeItem.palette.text.link} variant='body2'>
                      + Create a task
                    </Typography>
                  </Stack>
                </TableRow>
                {flatItems?.map(task =>
                  <TaskItemDashboard
                    dueDate={task.dueDate}
                    key={task.task.id}
                    taskColumn={task.group}
                    taskDataSet={task.task}
                    taskboard={task.taskboard}
                  />)}
              </Stack>
              : <Stack direction='column'>
                <TaskItemSkeleton />
                <TaskItemSkeleton />
                <TaskItemSkeleton />
                <TaskItemSkeleton />
              </Stack>}
          </Stack>
        </Stack>
      </Stack>

      {newTaskModal &&
      <NewTaskModal
        close={() => setNewTaskModal(false)}
        open={newTaskModal}
      />}
      {openSyncModal &&
      <TaskSyncModal
        close={() => setOpenSyncModal(false)}
        hash={hash}
        id={currentAccount.id}
        open={openSyncModal}
        provider={syncType}
        type='account'
      />}
      <Menu
        anchorEl={anchorElement}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClick={() => setAnchorElement(null)}
        onClose={() => setAnchorElement(null)}
        open={openMenu}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <MenuItem
          onClick={() => {
            setSyncType('google')
            setOpenSyncModal(true)
          }}
        >
          <ListItemIcon>
            <GoogleCalendarLogo fontSize='medium' />
          </ListItemIcon>
          Add to Google Calendar
        </MenuItem>
        <MenuItem
          onClick={() => {
            setSyncType('apple')
            setOpenSyncModal(true)
          }}
        >
          <ListItemIcon>
            <AppleIcon color='action' fontSize='medium' />
          </ListItemIcon>
          Add to Apple Calendar
        </MenuItem>
      </Menu>
    </>
  )
}

export default MyTasks
