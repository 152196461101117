/* eslint-disable unicorn/filename-case */
/* eslint-disable complexity */
export const getLinkTitle = (type: string) => {
  switch (type) {
    case 'link':
      return 'Link'
    case 'social':
      return 'Social Icons'
    case 'header':
      return 'Header'
    case 'text':
      return 'Text'
    case 'image':
      return 'Image'
    case 'video_embed':
      return 'Video'
    case 'next_release':
      return 'Next Release'
    case 'latest_release':
      return 'Latest Release'
    case 'facebook':
      return 'Facebook Video'
    case 'twitter':
      return 'Twitter'
    case 'youtube':
      return 'YouTube Video'
    case 'daily_motion':
      return 'Daily Motion Video'
    case 'vevo':
      return 'Vevo'
    case 'vimeo':
      return 'Vimeo Video'
    case 'twitch':
      return 'Twitch Stream'
    case 'audiomack':
      return 'Audiomack'
    case 'soundcloud':
      return 'SoundCloud'
    case 'spotify':
      return 'Spotify'
    case 'instagram':
      return 'Instagram'
    case 'threads':
      return 'Threads Profile'
    case 'opensea':
      return 'OpenSea Collection'
    case 'tiktok':
      return 'TikTok'
    case 'snapchat':
      return 'Snapchat Profile'
    case 'chat':
      return 'Chat'
    case 'left_divider':
      return 'Divider'
    case 'card_block':
      return 'Large Link'
    case 'bandsintown':
      return 'Bandsintown'
    case 'seated':
      return 'Seated'
    default:
      return
  }
}

export const getLinkDescription = (type: string) => {
  switch (type) {
    case 'link':
      return 'A button with text and URL to send your fans to your content'
    case 'social':
      return 'A list of your social media icons'
    case 'header':
      return 'A centered header block to divide content'
    case 'text':
      return 'Add descriptions or call outs to organize your content'
    case 'image':
      return 'Images to display your personality to your fans'
    case 'video_embed':
      return 'Embed your videos or previews on your bio link'
    case 'next_release':
      return 'Your next upcoming Releese Pre-save Link, updated automatically'
    case 'latest_release':
      return 'Your latest Releese Smart Link, updated automatically'
    case 'facebook':
      return 'Add Facebook videos to your Bio Link'
    case 'twitter':
      return 'Showcase your tweets and feed'
    case 'youtube':
      return 'Share YouTube videos in your Bio Link'
    case 'daily_motion':
      return 'Share Daily Motion videos in your Bio Link'
    case 'vevo':
      return 'Share Vevo videos in your Bio Link'
    case 'vimeo':
      return 'Share Vimeo videos in your Bio Link'
    case 'twitch':
      return 'Grow your Twitch stream'
    case 'audiomack':
      return 'Share a release from Audiomack'
    case 'soundcloud':
      return 'Get your music heard on SoundCloud'
    case 'spotify':
      return 'Gain more streams on Spotify'
    case 'instagram':
      return 'Showcase an Instagram post'
    case 'threads':
      return 'Showcase your Threads profile'
    case 'opensea':
      return 'Display your NFT collection'
    case 'tiktok':
      return 'Share your most viral moments'
    case 'snapchat':
      return 'Showcase your public Snapchat profile'
    case 'chat':
      return 'A personal message block'
    case 'left_divider':
      return 'A divider block with left-aligned large text'
    case 'card_block':
      return 'Large button with an image, primary and secondary text'
    case 'bandsintown':
      return 'Display your upcoming events from your Bandsintown profile'
    case 'seated':
      return 'Display your upcoming events from your Seated profile'
    default:
      return
  }
}
