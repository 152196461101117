/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Card, CardActionArea, CircularProgress, Stack, Typography } from '@mui/material'
import type { CancelTokenSource } from 'axios'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { v4 } from 'uuid'

import { getUploadLink, uploadFile } from 'src/api/files'
import { sendTasksboardUpdate } from 'src/api/webSocket'
import { renderIcon } from 'src/components/pages/Files/Components/FileItemView'
import { useAuth } from 'src/components/providers/AuthProvider'
import { useFileUploader } from 'src/components/providers/FilesUploadProvider'
import { FileItem } from 'src/models/File'
import { TaskBoardEdit } from 'src/models/Taskboard'
import { bytesToSize } from 'src/utils/fileUtils'

type Props = {
  file?: File
  location: string
  projectId?: string
  taskboardId?: string
  taskId?: string
  id: string
}

const FileItemUpload = (props: Props) => {
  const { setFiles, setLoading } = useFileUploader()
  const { currentAccount, refreshCurrentOrganisation, currentOrganisation } = useAuth()
  const [failed, setFailed] = useState(false)
  const [done, setDone] = useState(false)
  const [_, setTokenCancellationSource] = useState<CancelTokenSource | null>(null)

  const [total, setTotal] = useState('')
  const [loaded, setLoaded] = useState('')
  const [current, setCurrent] = useState<number>(0)

  const regexExtension = /\w{3,4}($|\?)/
  const fileExtension = regexExtension.exec(props.file?.name ?? '')

  useEffect(() => {
    void uploadFileItem()
  }, [])

  const uploadFileItem = async () => {
    if (!done && props.file && currentOrganisation) {
      setFailed(false)
      const token = axios.CancelToken.source()
      setTokenCancellationSource(token)
      setLoaded(bytesToSize(0))
      setTotal(bytesToSize(props.file.size))

      await getUploadLink(currentOrganisation.id, props.file.size, props.file.name, props.file.type)
        .then(async response => {
          if (props.file) {
            await axios.put<string>(response.uploadLink, props.file, {
              cancelToken: token.token,
              onUploadProgress: (progressEvent: ProgressEvent) => {
                const percent = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
                setLoadProperties(percent, progressEvent.loaded, progressEvent.total)
              },
              headers: {
                'Content-Type': props.file.type,
              },
            })
              .then(async () => {
                await (props.projectId
                  ? uploadFile(new FileItem({
                    url: response.fileLink,
                    projectId: props.projectId,
                    parent: props.location,
                    organisationId: '',
                    size: props.file?.size,
                    title: props.file?.name,
                  }))
                    .then(() => {
                      setFiles(old => old?.filter(url => url.id !== props.id))
                      setLoading(old => old?.filter(url => url.id !== props.id))
                    })
                    .then(() => void refreshCurrentOrganisation())
                    .then(() => setDone(true))
                    .catch(() => setFailed(true))
                  : props.taskboardId && props.taskId
                    ? uploadFile(new FileItem({
                      url: response.fileLink,
                      organisationId: '',
                      parent: props.location,
                      taskboardId: props.taskboardId,
                      taskId: props.taskId,
                      size: props.file?.size,
                      title: props.file?.name,
                    }))
                      .then(newFile => {
                        setFiles(old => old?.filter(url => url.id !== props.id))
                        setLoading(old => old?.filter(url => url.id !== props.id))
                        const taskUpdateItem = new TaskBoardEdit({
                          id: v4(),
                          taskboardId: props.taskboardId,
                          taskId: '',
                          accountId: currentAccount.id,
                          modificationType: 'Uploaded a file',
                          itemType: 'Field',
                          title: `${newFile.title}`,
                        })
                        sendTasksboardUpdate(taskUpdateItem)
                      })
                      .then(() => void refreshCurrentOrganisation())
                      .then(() => setDone(true))
                      .catch(() => setFailed(true))
                    : props.taskboardId && !props.taskId
                      ? uploadFile(new FileItem({
                        url: response.fileLink,
                        organisationId: '',
                        parent: props.location,
                        taskboardId: props.taskboardId,
                        size: props.file?.size,
                        title: props.file?.name,
                        taskId: '',
                      }))
                        .then(newFile => {
                          setFiles(old => old?.filter(url => url.id !== props.id))
                          setLoading(old => old?.filter(url => url.id !== props.id))
                          const taskUpdateItem = new TaskBoardEdit({
                            id: v4(),
                            taskboardId: props.taskboardId,
                            taskId: '',
                            accountId: currentAccount.id,
                            modificationType: 'Uploaded a file',
                            itemType: 'Field',
                            title: `${newFile.title}`,
                          })
                          sendTasksboardUpdate(taskUpdateItem)
                        })
                        .then(() => void refreshCurrentOrganisation())
                        .then(() => setDone(true))
                        .catch(() => setFailed(true))
                      : uploadFile(new FileItem({
                        url: response.fileLink,
                        organisationId: currentOrganisation.id,
                        parent: props.location,
                        size: props.file?.size,
                        title: props.file?.name,
                      }))
                        .then(() => {
                          setFiles(old => old?.filter(url => url.id !== props.id))
                          setLoading(old => old?.filter(url => url.id !== props.id))
                        })
                        .then(() => void refreshCurrentOrganisation())
                        .then(() => setDone(true))
                        .catch(() => setFailed(true)))
              })
              .catch(() => setFailed(true))
          }
        })
        .catch(() => setFailed(true))
    }
  }

  const setLoadProperties = (perc: number, load: number, tot: number) => {
    setLoaded(bytesToSize(load))
    setTotal(bytesToSize(tot))
    setCurrent(perc)
  }

  return (
    <Card
      elevation={6}
      sx={{
        boxShadow: 'none',
        backgroundColor: theme => theme.palette.background.default,
        border: theme => `1px solid ${theme.palette.divider}`,
        width: 1,
        height: 1,
        borderColor: theme => theme.palette.divider }}
    >
      <CardActionArea
        disabled
        sx={{
          height: 1,
        }}
      >
        <Stack direction='column'>
          <Stack alignItems='center' direction='row' padding={1} width={1}>
            <Stack alignItems='center' direction='row' marginRight='auto' width={1}>
              {renderIcon(fileExtension?.[0].toString() ?? '')}
              <Stack direction='column' paddingX={2} width={1}>
                <Typography
                  sx={{
                    paddingY: 0,
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    width: '200px',
                    overflow: 'hidden',
                  }}
                  variant='body1'
                >
                  {props.file?.name}
                </Typography>
                <Typography color={failed ? 'error.main' : 'text.label'} sx={{ paddingY: 0 }} variant='body2'>
                  {
                    failed
                      ? <>
                        There was an error uploading.
                      </>
                      : !done &&
                              total &&
                              loaded &&
                              loaded !== undefined &&
                              total !== undefined &&
                              total !== 'undefined' &&
                              loaded !== 'undefined' &&
                                <>{`${loaded} of ${total}`}</>
                  }
                  {' '}
                </Typography>
              </Stack>
              {!done &&
              <CircularProgress
                color='primary'
                value={current}
                variant='determinate'
              />}
            </Stack>
          </Stack>
        </Stack>
      </CardActionArea>
    </Card>
  )
}

export default FileItemUpload
