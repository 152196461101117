import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import { Avatar, ListItemText, Stack } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { v4 } from 'uuid'

type Props = {
  row: {
    _id: string
    image: string
    name: string
    amount: number
    streams: number
    payperstream: number
  }
}

const TopArtistItem = (props: Props) =>
  <TableRow
    hover
    key={`${props.row._id}${props.row._id}${v4()}`}
    role='checkbox'
    sx={{
      '.MuiTableCell-root': {
        borderBottomColor: 'divider',
        paddingY: 2,
        textDecoration: 'none!important',
      },
      ':hover': {
        '.MuiTableCell-root': {
          background: theme => `${theme.palette.tableHoverBg}!important`,
        },
      },
    }}
    tabIndex={-1}
  >
    <TableCell
      component='th'
      scope='row'
      sx={{
        position: 'sticky',
        background: theme => theme.palette.background.default,
        borderRight: theme => `1px solid ${theme.palette.divider}`,
        left: 0,
        zIndex: 999,
      }}
    >
      <Stack alignItems='center' direction='row' spacing={2}>

        <Avatar
          src={props.row.image}
          variant='circular'
        >
          <MusicNoteRoundedIcon />
        </Avatar>
        <ListItemText
          primary={props.row.name}
        />
      </Stack>
    </TableCell>
    <TableCell align='left'>
      {new Intl.NumberFormat('en-US',
        { style: 'currency', currency: 'USD' }).format(props.row.amount)}
    </TableCell>

    <TableCell align='left'>
      {`${props.row.streams.toLocaleString('en-US')}`}
    </TableCell>

    <TableCell align='left'>
      {new Intl.NumberFormat('en-US',
        { style: 'currency', currency: 'USD' })
        .format(props.row.amount > 0.01 && props.row.streams > 0
          ? props.row.amount / props.row.streams * 1000
          : 0)}
    </TableCell>
  </TableRow>

export default TopArtistItem
