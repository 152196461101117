/* eslint-disable sonarjs/no-nested-template-literals */
import axios from 'axios'

import type { MarketingAnalyticsDatesDto, MarketingAnalyticsStringDto } from 'src/models/Marketing'
import { MarketingAnalyticsDates, MarketingAnalyticsString } from 'src/models/Marketing'
import type { SubscriberDto } from 'src/models/Subscriber'
import { Subscriber } from 'src/models/Subscriber'

const PATH = '/alllinkdata' as const

// leaving this ownerId undefined returns all releases belonging to current user
export const getClicksOrg = (startDate: Date, endDate: Date, services: string[]) =>
  axios.get<MarketingAnalyticsDatesDto[]>(`${PATH}/analytics/clicks/${Number(startDate).toString()}/
  ${Number(endDate).toString()}?${services.map((item, index) => `services[${index}]=${item}`).join('&')}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsDates(dto)))

export const getPresavesOrg = (startDate: Date, endDate: Date, services: string[]) =>
  axios.get<MarketingAnalyticsDatesDto[]>(`${PATH}/analytics/presaves/${Number(startDate).toString()}/
      ${Number(endDate).toString()}?${services.map((item, index) => `services[${index}]=${item}`).join('&')}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsDates(dto)))

export const getViewsOrg = (startDate: Date, endDate: Date) =>
  axios.get<MarketingAnalyticsDatesDto[]>(`${PATH}/analytics/views/${Number(startDate).toString()}/
  ${Number(endDate).toString()}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsDates(dto)))

export const getDestinationsOrg = (startDate: Date, endDate: Date) =>
  axios.get<MarketingAnalyticsStringDto[]>(`${PATH}/analytics/destinations/${Number(startDate).toString()}/
  ${Number(endDate).toString()}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsString(dto)))

export const getDestinationsPresavesOrg = (startDate: Date, endDate: Date) =>
  axios.get<MarketingAnalyticsStringDto[]>(`${PATH}/analytics/destinations-presaves/${Number(startDate).toString()}/
      ${Number(endDate).toString()}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsString(dto)))

export const getViewsPerCityOrg = (startDate: Date, endDate: Date) =>
  axios.get<MarketingAnalyticsStringDto[]>(`${PATH}/analytics/cities/views/${Number(startDate).toString()}/
  ${Number(endDate).toString()}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsString(dto)))

export const getClicksPerCityOrg = (startDate: Date, endDate: Date, services: string[]) =>
  axios.get<MarketingAnalyticsStringDto[]>(`${PATH}/analytics/cities/clicks/${Number(startDate).toString()}/
  ${Number(endDate).toString()}?${services.map((item, index) => `services[${index}]=${item}`).join('&')}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsString(dto)))

export const getClicksPerCountryOrg = (startDate: Date, endDate: Date, services: string[]) =>
  axios.get<MarketingAnalyticsStringDto[]>(`${PATH}/analytics/countries/clicks/${Number(startDate).toString()}/
  ${Number(endDate).toString()}?${services.map((item, index) => `services[${index}]=${item}`).join('&')}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsString(dto)))

export const getViewsPerCountryOrg = (startDate: Date, endDate: Date) =>
  axios.get<MarketingAnalyticsStringDto[]>(`${PATH}/analytics/countries/views/${Number(startDate).toString()}/
  ${Number(endDate).toString()}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsString(dto)))

export const getViewsPerSourceOrg = (startDate: Date, endDate: Date) =>
  axios.get<MarketingAnalyticsStringDto[]>(`${PATH}/analytics/sources/views/${Number(startDate).toString()}/
  ${Number(endDate).toString()}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsString(dto)))

export const getClicksPerSourceOrg = (startDate: Date, endDate: Date, services: string[]) =>
  axios.get<MarketingAnalyticsStringDto[]>(`${PATH}/analytics/sources/clicks/${Number(startDate).toString()}/
  ${Number(endDate).toString()}?${services.map((item, index) => `services[${index}]=${item}`).join('&')}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsString(dto)))

export const getAllViewsPerBrowser = (startDate: Date, endDate: Date) =>
  axios.get<MarketingAnalyticsStringDto[]>(`${PATH}/analytics/browser/views/${Number(startDate).toString()}/
    ${Number(endDate).toString()}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsString(dto)))

export const getAllClicksPerBrowser = (startDate: Date, endDate: Date, services: string[]) =>
  axios.get<MarketingAnalyticsStringDto[]>(`${PATH}/analytics/browser/clicks/${Number(startDate).toString()}/
    ${Number(endDate).toString()}?${services.map((item, index) => `services[${index}]=${item}`).join('&')}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsString(dto)))

export const getAllViewsPerOs = (startDate: Date, endDate: Date) =>
  axios.get<MarketingAnalyticsStringDto[]>(`${PATH}/analytics/os/views/${Number(startDate).toString()}/
    ${Number(endDate).toString()}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsString(dto)))

export const getAllClicksPerOs = (startDate: Date, endDate: Date, services: string[]) =>
  axios.get<MarketingAnalyticsStringDto[]>(`${PATH}/analytics/os/clicks/${Number(startDate).toString()}/
    ${Number(endDate).toString()}?${services.map((item, index) => `services[${index}]=${item}`).join('&')}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsString(dto)))

export const getSubscriberCount = () =>
  axios.get<MarketingAnalyticsStringDto[]>(`${PATH}/analytics/overall/subscribers`)
    .then(response => response.data.map(dto => new MarketingAnalyticsString(dto)))

export const getAllSubscribers = () =>
  axios.get<SubscriberDto[]>(`${PATH}/analytics/overall/subscriberlist`)
    .then(response => response.data.map(dto => new Subscriber(dto)))
