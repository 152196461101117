/* eslint-disable sonarjs/no-duplicate-string */
import { v4 as uuidv4 } from 'uuid'

import { colorSelection } from 'src/components/pages/Task/TaskMenu/TaskMenuComponents/ColorsForPicker'
import type { TaskBoardTemplate, TaskGroup, TaskItem } from 'src/models/Taskboard'

const notStarted = 'Not started'

const tasksPreRelease: TaskItem[] = [{
  id: uuidv4(),
  title: 'Send to Editorial Playlist curators',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
{
  id: uuidv4(),
  title: 'Send to YouTube channel owners',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
]

const tasksReleaseWeek: TaskItem[] = [{
  id: uuidv4(),
  title: 'Send to blog writers',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
{
  id: uuidv4(),
  title: 'Send to press outlets',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
]

const tasksPostRelease: TaskItem[] = [{
  id: uuidv4(),
  title: 'Send follow up to curators',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
{
  id: uuidv4(),
  title: 'Send follow up to blog writers',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
]

const taskGroupPreRelease: TaskGroup = {
  id: uuidv4(),
  tasks: tasksPreRelease,
  title: '4 weeks Pre release',
  color: colorSelection[0],
}

const taskGroupReleaseWeek: TaskGroup = {
  id: uuidv4(),
  tasks: tasksReleaseWeek,
  title: '2 week Pre release',
  color: colorSelection[1],
}

const taskGroupPostRelease: TaskGroup = {
  id: uuidv4(),
  tasks: tasksPostRelease,
  title: '1 week Pre release',
  color: colorSelection[2],
}

const taskGroups: TaskGroup[] = [taskGroupPreRelease, taskGroupReleaseWeek, taskGroupPostRelease]

export const taskboardPitchingSchedule: TaskBoardTemplate = {
  id: '',
  customFields: [],
  icon: 0,
  title: 'Pitching schedule',
  color: colorSelection[4],
  fieldOrder: [],
  taskGroups,
  hiddenFields: [],
  createdAt: new Date(),
  description: `Never send out music too early or too late using our pitching schedule for playlist curators,
  video curators, influencer, blog writers, and media outlets.`,
}
