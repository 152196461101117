import type { DrawerProps } from '@mui/material'
import { alpha, Divider, Drawer } from '@mui/material'

export enum DrawerState {
  Closed,
  Messages,
  Notifications,
  Profile,
}

const MenuDrawer = ({ drawerWidth: width, ...props }: DrawerProps & { drawerWidth: number | string }) => <Drawer
  BackdropProps={{ invisible: true }}
  anchor='right'
  elevation={5}
  sx={{
    flexShrink: 0,
    width,
    '& .MuiDrawer-paper': {
      top: 0,
      width,
      overflow: 'hidden',
      borderRadius: 0,
      boxShadow: theme => `-12px 0px 15px -10px ${alpha(theme.palette.shadow, 0.75)}`,
    },
  }}
  variant='temporary'
  {...props}
>
  <Divider />
  {props.children}
</Drawer>

export default MenuDrawer
