import { Chip, ListItemText, Skeleton, TableCell, TableRow } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { v4 } from 'uuid'

import { getPaymentStatus } from 'src/api/payout'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { PaymentObject, WisePaymentObject } from 'src/models/Payout'

type Props = {
  transaction: PaymentObject
}

const statusToString = (statusCode: string) => {
  switch (statusCode) {
    case 'incoming_payment_waiting': return 'On its way'
    case 'incoming_payment_initiated': return 'On its way'
    case 'processing': return 'Processing'
    case 'funds_converted': return 'Processing'
    case 'outgoing_payment_sent': return 'Sent'
    case 'charged_back': return 'Charged back'
    case 'cancelled': return 'Cancelled'
    case 'funds_refunded': return 'Refunded'
    case 'bounced_back': return 'Bounced back'
    default: return 'Unknown'
  }
}

const statusToColor = (statusCode: string) => {
  switch (statusCode) {
    case 'incoming_payment_waiting': return 'primary'
    case 'incoming_payment_initiated': return 'primary'
    case 'processing': return 'primary'
    case 'funds_converted': return 'primary'
    case 'outgoing_payment_sent': return 'success'
    case 'charged_back': return 'error'
    case 'cancelled': return 'error'
    case 'funds_refunded': return 'error'
    case 'bounced_back': return 'error'
    default: return 'error'
  }
}

const PaymentTableItem = (props: Props) => {
  const { currentOrganisation } = useAuth()

  const [paymentItem, setPaymentItem] = useState<WisePaymentObject>()

  useEffect(() => {
    void getPaymentStatus(props.transaction.id)
      .then(setPaymentItem)
  }, [currentOrganisation?.id, props.transaction.id])

  return (
    paymentItem
      ? <TableRow
        key={props.transaction.customerTransactionId}
        sx={{
          '.MuiTableCell-root': {
            borderBottomColor: 'divider',
            paddingY: 2,
          },
        }}
      >
        <TableCell>
          {dayjs(paymentItem.created).format('LL')}
        </TableCell>
        <TableCell>
          <ListItemText
            primary={`${new Intl.NumberFormat('en-US',
              {
                style: 'currency',
                currency: paymentItem.targetCurrency,
              })
              .format(paymentItem.targetValue)}`}
            secondary={`${new Intl.NumberFormat('en-US',
              {
                style: 'currency',
                currency: 'USD',
              })
              .format(paymentItem.sourceValue)}`}
          />
        </TableCell>
        <TableCell>
          <Chip
            color={statusToColor(paymentItem.status)}
            label={statusToString(paymentItem.status)}
            sx={{
              textTransform: 'capitalize',
            }}
            variant='filled'
          />
        </TableCell>
      </TableRow>
      : <TableRow
        key={v4()}
        sx={{
          '.MuiTableCell-root': {
            borderBottomColor: 'divider',
            paddingY: 2,
          },
        }}
      >
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
      </TableRow>
  )
}

export default PaymentTableItem

