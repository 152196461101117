/* eslint-disable unicorn/filename-case */
import type {
  SelectOnBackspacePlugin } from '@udecode/plate'
import {
  ELEMENT_HR,
  ELEMENT_IMAGE,
} from '@udecode/plate'

import type { MyPlatePlugin } from 'src/components/pages/Notes/Modern/typescript/plateTypes'

export const selectOnBackspacePlugin: Partial<
  MyPlatePlugin<SelectOnBackspacePlugin>
> = {
  options: {
    query: {
      allow: [ELEMENT_IMAGE, ELEMENT_HR],
    },
  },
}
