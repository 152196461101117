/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable id-length */
import { Stack, useTheme } from '@mui/material'
import { useRef } from 'react'
import Chart from 'react-apexcharts'

import { useContainerDimensions } from 'src/components/ContainerDimensions'
import type { AnalyticsMonthlyChart } from 'src/models/Analytics'

type Props = {
  data: AnalyticsMonthlyChart[]
  netIncome: AnalyticsMonthlyChart[]
  isOwner: boolean
}

export const convertMonthToString = (month: string) => {
  switch (month) {
    case '12':
      return 'DEC'
    case '11':
      return 'NOV'
    case '10':
      return 'OCT'
    case '9':
      return 'SEP'
    case '8':
      return 'AUG'
    case '7':
      return 'JUL'
    case '6':
      return 'JUN'
    case '5':
      return 'MAY'
    case '4':
      return 'APR'
    case '3':
      return 'MAR'
    case '2':
      return 'FEB'
    case '1':
      return 'JAN'
  // No default
  }
}

const EarningsStatementArea = (props: Props) => {
  const theme = useTheme()
  const componentRef = useRef<HTMLDivElement>(null)
  const { width } = useContainerDimensions(componentRef)

  const array1 = props.data.map(item => ({
    x: `01${item._id.slice(2).toUpperCase()}`,
    y: item.amount,
  })).sort((a, b) => Number(new Date(a.x)) - Number(new Date(b.x)))

  const array2 = props.netIncome.map(item => {
    const dateItem = new Date(item._id)
    const newDate = new Date(dateItem.setMonth(dateItem.getMonth() - 2))
    const stringItem = `01-${convertMonthToString((newDate.getMonth() + 1).toString())}-${newDate.getFullYear()}`
    const amountItem = array1.find(arrayItem => arrayItem.x === stringItem)?.y ?? 0

    return {
      x: stringItem,
      y: props.isOwner
        ? amountItem - Number(item.amount)
        : item.amount,
    }
  }).sort((a, b) => Number(new Date(a.x)) - Number(new Date(b.x)))

  const array3 = array1.map(item => {
    const amountItem = props.isOwner
      ? array2.find(arrayItem => arrayItem.x === item.x)?.y ?? item.y
      : array2.find(arrayItem => arrayItem.x === item.x)?.y ?? 0
    return {
      x: item.x,
      y: amountItem,
    }
  })

  const series2 = [
    {
      name: 'Gross Income',
      data: array1,
    },
    {
      name: 'Net Income',
      data: array3,
    },
  ]

  const optionsSectionsOverdue: ApexCharts.ApexOptions = {
    chart: {
      animations: {
        enabled: false,
      },
      zoom: {
        enabled: false,
      },
      stacked: false,
      foreColor: theme.palette.text.primary,
      width,
      toolbar: {
        show: false,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      width: 2,
      curve: 'smooth',
    },
    colors: [
      theme.palette.secondary.main,
      theme.palette.success.main,
    ],
    tooltip: {
      theme: 'dark',
      x: {
        show: false,
      },
    },
    fill: {
      type: 'gradient',
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      borderColor: theme.palette.divider,
    },
    xaxis: {
      type: 'datetime',
      tooltip: {
        enabled: false,
      },
      axisBorder: {
        color: theme.palette.divider,
      },
      axisTicks: {
        color: theme.palette.divider,
      },
    },
    yaxis: {
      labels: {
        formatter: value => `${value?.toFixed(2)}$`,
      },
      axisBorder: {
        color: theme.palette.divider,
      },
      axisTicks: {
        color: theme.palette.divider,
      },
    },
    legend: {
      showForZeroSeries: false,
    },
  }

  return (
    <Stack alignItems='center' ref={componentRef} width={1}>
      {series2 &&
        <Chart
          height={400}
          options={optionsSectionsOverdue}
          series={series2}
          type='area'
          width={width}
        />}
    </Stack>
  )
}
export default EarningsStatementArea

