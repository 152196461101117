import { Stack, Typography } from '@mui/material'

import ReleeseLogo from 'src/components/ReleeseLogo'

export const NotAvailable = () =>
  <Stack
    alignItems='center'
    height='100%'
    justifyContent='center'
    padding={2}
    spacing={2}
    sx={{
      background: 'black',
    }}
  >
    <Stack
      alignItems='baseline'
      direction='row'
      spacing={1.5}
    >
      <ReleeseLogo height='40px' />
      <ReleeseLogo full height='60px' />
    </Stack>

    <Typography
      color='white'
      textAlign='center'
      variant='h2'
    >
      Releese is unavailable
    </Typography>
    <Typography
      textAlign='center'
      variant='body1'
    >
      Releese is not available in your location due to Canadian economic sanctions.
    </Typography>
    <Typography
      textAlign='center'
      variant='body1'
    >
      Using a VPN service to access Releese will not enable you to
      activate a Releese subscription or withdraw royalties.
    </Typography>
  </Stack>
