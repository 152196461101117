import InfoRoundedIcon from '@mui/icons-material/InfoRounded'
import { Card, Grid, IconButton, Stack, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'

import { getClicksOrg, getSubscriberCount, getViewsOrg } from 'src/api/allLinkAnalytics'
import { getAllFans } from 'src/api/links'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { MarketingAnalyticsDates, MarketingAnalyticsString, MarketingLinkFan } from 'src/models/Marketing'

const LinksListAnalytics = () => {
  const themeItem = useTheme()
  const mobile = useMediaQuery(themeItem.breakpoints.down('md'))
  const { currentOrganisation } = useAuth()
  const [_, setLoading] = useState(false)
  const [clicks, setClicks] = useState<MarketingAnalyticsDates[]>([])
  const [views, setViews] = useState<MarketingAnalyticsDates[]>([])
  const [subscribers, setSubscribers] = useState<MarketingAnalyticsString[]>([])
  const [fans, setFans] = useState<MarketingLinkFan[]>([])

  useEffect(() => {
    const currentDate = new Date()
    const lastTwentyEightDays = new Date()
    lastTwentyEightDays.setDate(currentDate.getDate() - 28)
    setLoading(true)
    void getClicksOrg(lastTwentyEightDays, currentDate, [])
      .then(setClicks)
      .finally(() => setLoading(false))
    void getViewsOrg(lastTwentyEightDays, currentDate)
      .then(setViews)
      .finally(() => setLoading(false))
    void getSubscriberCount()
      .then(setSubscribers)
      .finally(() => setLoading(false))
    void getAllFans()
      .then(setFans)
      .finally(() => setLoading(false))
  }, [currentOrganisation?.id])

  return (
    <Stack direction='row' maxWidth={1332} spacing={1} width={1}>
      <Stack direction='column' width={1}>
        <Stack direction='row' width={1}>
          <Grid container spacing={1}>
            <Grid item lg={3} md={3} sm={6} xl={3} xs={6}>
              <Card
                elevation={0}
                sx={{
                  height: 1,
                  background: theme => theme.palette.background.elevatedCard,
                }}
              >
                <Stack padding={mobile ? 1.5 : 2} spacing={mobile ? 1 : 2} width={1}>
                  <Stack direction='row' justifyContent='space-between'>
                    <Stack>
                      <Typography
                        fontSize='1.45em'
                        fontWeight={600}
                        lineHeight={1.2}
                        variant='h3'
                      >
                        Subscribers
                      </Typography>
                      <Typography
                        color={themeItem.palette.text.secondary}
                        fontSize='1em'
                        fontWeight={400}
                        lineHeight={1.2}
                        variant='body2'
                      >
                        Current
                      </Typography>
                    </Stack>
                    {!mobile &&
                    <Tooltip title='Subscribers are users who pre-save all your future releases'>
                      <div>
                        <IconButton>
                          <InfoRoundedIcon />
                        </IconButton>
                      </div>
                    </Tooltip>}
                  </Stack>
                  <Typography
                    lineHeight={1.1}
                    textAlign='center'
                    variant='h2'
                  >
                    {subscribers.map(item => item.count).reduce((partialSum, a) => partialSum + a, 0).toLocaleString()}
                  </Typography>
                </Stack>
              </Card>
            </Grid>

            <Grid item lg={3} md={3} sm={6} xl={3} xs={6}>
              <Card
                elevation={0}
                sx={{
                  height: 1,
                  background: theme => theme.palette.background.elevatedCard,
                }}
              >
                <Stack padding={mobile ? 1.5 : 2} spacing={mobile ? 1 : 2} width={1}>
                  <Stack direction='row' justifyContent='space-between'>
                    <Stack>
                      <Typography
                        fontSize='1.45em'
                        fontWeight={600}
                        lineHeight={1.2}
                        variant='h3'
                      >
                        Views
                      </Typography>
                      <Typography
                        color={themeItem.palette.text.secondary}
                        fontSize='1em'
                        fontWeight={400}
                        lineHeight={1.2}
                        variant='body2'
                      >
                        Last 28 days
                      </Typography>
                    </Stack>
                    {!mobile &&
                    <Tooltip title='Views represent everytime a user opens one of your smart links'>
                      <div>
                        <IconButton>
                          <InfoRoundedIcon />
                        </IconButton>
                      </div>
                    </Tooltip>}
                  </Stack>
                  <Typography
                    lineHeight={1.1}
                    textAlign='center'
                    variant='h2'
                  >
                    {views.map(item => item.count).reduce((partialSum, a) => partialSum + a, 0).toLocaleString()}
                  </Typography>
                </Stack>
              </Card>
            </Grid>

            <Grid item lg={3} md={3} sm={6} xl={3} xs={6}>
              <Card
                elevation={0}
                sx={{
                  height: 1,
                  background: theme => theme.palette.background.elevatedCard,
                }}
              >
                <Stack padding={mobile ? 1.5 : 2} spacing={mobile ? 1 : 2} width={1}>
                  <Stack direction='row' justifyContent='space-between'>
                    <Stack>
                      <Typography
                        fontSize='1.45em'
                        fontWeight={600}
                        lineHeight={1.2}
                        variant='h3'
                      >
                        Clicks
                      </Typography>
                      <Typography
                        color={themeItem.palette.text.secondary}
                        fontSize='1em'
                        fontWeight={400}
                        lineHeight={1.2}
                        variant='body2'
                      >
                        Last 28 days
                      </Typography>
                    </Stack>
                    {!mobile &&
                    <Tooltip title='Clicks represent everytime a user clicks a button on your smart links'>
                      <div>
                        <IconButton>
                          <InfoRoundedIcon />
                        </IconButton>
                      </div>
                    </Tooltip>}
                  </Stack>
                  <Typography
                    lineHeight={1.1}
                    textAlign='center'
                    variant='h2'
                  >
                    {clicks.map(item => item.count).reduce((partialSum, a) => partialSum + a, 0).toLocaleString()}
                  </Typography>
                </Stack>
              </Card>
            </Grid>

            <Grid item lg={3} md={3} sm={6} xl={3} xs={6}>
              <Card
                elevation={0}
                sx={{
                  height: 1,
                  background: theme => theme.palette.background.elevatedCard,
                }}
              >
                <Stack padding={mobile ? 1.5 : 2} spacing={mobile ? 1 : 2} width={1}>
                  <Stack direction='row' justifyContent='space-between'>
                    <Stack>
                      <Typography
                        fontSize='1.45em'
                        fontWeight={600}
                        lineHeight={1.2}
                        variant='h3'
                      >
                        Pre-saves
                      </Typography>
                      <Typography
                        color={themeItem.palette.text.secondary}
                        fontSize='1em'
                        fontWeight={400}
                        lineHeight={1.2}
                        variant='body2'
                      >
                        Lifetime
                      </Typography>
                    </Stack>
                    {!mobile &&
                    <Tooltip title='Pre-saves represent everytime a user pre-saves one of your releases'>
                      <div>
                        <IconButton>
                          <InfoRoundedIcon />
                        </IconButton>
                      </div>
                    </Tooltip>}
                  </Stack>
                  <Typography
                    lineHeight={1.1}
                    textAlign='center'
                    variant='h2'
                  >
                    {fans.length.toLocaleString()}
                  </Typography>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default LinksListAnalytics
