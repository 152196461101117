/* eslint-disable complexity */
/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable no-useless-escape */
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded'
import ImageRoundedIcon from '@mui/icons-material/ImageRounded'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import { IconButton, Stack, TableCell, TableRow, Typography } from '@mui/material'
import { useState } from 'react'

import PreviewFile from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/FilesTab/PreviewFile'

type Props = {
  file: string
}

export const renderIcon = (extension: string) => {
  switch (extension.toLowerCase()) {
    case 'jpg':
    case 'png':
    case 'jpeg':
    case 'gif':
    case 'webp':
    case 'bmp':
      return <ImageRoundedIcon color='secondary' fontSize='large' />
    case 'docx':
    case 'doc':
    case 'pdf':
    case 'xml':
    case 'rtf':
    case 'pages':
    case 'odt':
    case 'f2b':
      return <ArticleRoundedIcon color='success' fontSize='large' />
    case 'mp3':
    case 'wav':
    case 'mp2':
    case 'flac':
    case 'ogg':
    case 'aiff':
    case 'aac':
      return <MusicNoteRoundedIcon color='primary' fontSize='large' />
    default:
      return <AttachFileIcon color='primary' fontSize='large' />
  }
}

const FileObject = (props: Props) => {
  const [openImage, setImageOpen] = useState(false)
  const regexExtension = /\w{3,4}($|\?)/
  const fileExtension = regexExtension.exec(props.file)
  const fileName = props.file.slice(Math.max(0, props.file.lastIndexOf('/') + 1))

  const preventClick = (event: React.MouseEvent) => {
    event.stopPropagation()
  }

  return (
    <>
      <TableRow
        key={props.file}
        onClick={() => setImageOpen(true)}
        sx={{
          textDecoration: 'none',
          backgroundColor: theme => theme.palette.background.default,
          '&:hover .MuiTableCell-root': {
            backgroundColor: theme => theme.palette.background.input,
            cursor: 'pointer',
          },
          '.MuiTableCell-root': { borderBottomColor: 'divider' },
        }}
      >
        <TableCell>
          <Stack alignItems='center' direction='row' spacing={2} width={1}>
            {renderIcon(fileExtension?.[0].toString() ?? '')}
            <Typography variant='body1'>
              {fileName}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell>
          <IconButton
            download
            href={props.file}
            onClick={event => preventClick(event)}
            size='medium'
            target='_blank'
          >
            <FileDownloadRoundedIcon color='action' fontSize='medium' />
          </IconButton>
        </TableCell>
      </TableRow>
      <PreviewFile
        close={() => setImageOpen(false)}
        fileType={fileExtension?.[0].toString() ?? 'jpg'}
        image={props.file}
        open={openImage}
        text={fileName}
      />
    </>
  )
}

export default FileObject
