import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded'
import { Stack, Typography } from '@mui/material'

const WithdrawError = () =>
  <Stack alignItems='center' spacing={1} width={1}>
    <ErrorRoundedIcon color='error' sx={{ height: 64, width: 64 }} />
    <Typography textAlign='center' variant='body2'>
      There was an error while attempting to withdraw your funds, please contact support if this error persists
    </Typography>
  </Stack>

export default WithdrawError
