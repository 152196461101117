/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import './styles.css'

import type {
  PlateEditor,
} from '@udecode/plate'
import {
  Plate,
  PlateProvider,
} from '@udecode/plate'
import type { CSSProperties } from 'react'
import { useRef } from 'react'

import { CommentBalloonToolbar } from './comments/CommentBalloonToolbar'
import { MyCommentsProvider } from './comments/MyCommentsProvider'
import { editableProps } from './common/editableProps'
import type {
  MyValue } from './typescript/plateTypes'
import { FloatingComments } from 'src/components/pages/Notes/Modern/comments/CommentsFloating'
import CustomEditable from 'src/components/pages/Notes/Modern/CustomEditable'
import { DragHandleItemMainComponent } from 'src/components/pages/Notes/Modern/dnd/ItemMenuDragItem'
import MentionComponents from 'src/components/pages/Notes/Modern/mention/MentionComponent'
import { TableToolbarFloating } from 'src/components/pages/Notes/Modern/table-toolbar/TableToolbar'
import { NotesCommentsProvider } from 'src/components/providers/NotesCommentsProvider'
import type Project from 'src/models/Project'

const styles: Record<string, CSSProperties> = {
  container: { position: 'relative' },
}

type Props = {
  editor: PlateEditor<MyValue>
  notesId: string
  project?: Project

  anchorElementField: HTMLButtonElement | null
  setanchorElementField: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>
  openMenuField: boolean
  handleCloseField: () => void
}

const NotesEditorModern = (props: Props) => {
  const containerRef = useRef(null)

  return (

    <PlateProvider<MyValue>
      editor={props.editor}
    >
      <NotesCommentsProvider notesId={props.notesId}>
        <MyCommentsProvider documentId={props.notesId}>
          <div ref={containerRef} style={styles.container}>
            <Plate
              editableProps={editableProps}
              editor={props.editor}
              renderEditable={() => <CustomEditable {...editableProps} />}
            >
              <CommentBalloonToolbar />
              <TableToolbarFloating />
              <FloatingComments />

              <MentionComponents
                project={props.project}
              />

              <DragHandleItemMainComponent
                anchorElementField={props.anchorElementField}
                handleCloseField={props.handleCloseField}
                openMenuField={props.openMenuField}
                setanchorElementField={props.setanchorElementField}
              />
            </Plate>
          </div>
        </MyCommentsProvider>
      </NotesCommentsProvider>
    </PlateProvider>
  )
}

export default NotesEditorModern
