/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded'
import FlagRoundedIcon from '@mui/icons-material/FlagRounded'
import LoopRoundedIcon from '@mui/icons-material/LoopRounded'
import { Avatar, Chip, Stack, TableCell, Typography, useTheme } from '@mui/material'
import dayjs from 'dayjs'

import RenderListItemCustom from 'src/components/pages/Task/TaskViews/TaskViewComponents/RenderListItemCustom'
import type Account from 'src/models/Account'
import type { CustomFields, TaskItem } from 'src/models/Taskboard'
import { CustomFieldValue } from 'src/models/Taskboard'

type Props = {
  keyItem: string
  taskDataSet: TaskItem
  hiddenFields: string[]
  customFields: CustomFields[]
  assignee: Account[]
  status: 'Done' | 'In progress' | 'Not approved' | 'Not started'
  handleFieldChange: (newvalue: CustomFieldValue) => Promise<void>
  nextDate: Date | undefined
}

const RenderListValues = (props: Props) => {
  const themeItem = useTheme()
  const key = props.keyItem
  const field = props.customFields.find(item => item.id === key)
  return (
    <>
      {key === 'Due date' && !props.hiddenFields.includes('Due date') &&
      <TableCell id='Due date' width='150px'>
        {props.taskDataSet.dueDate &&
        <Stack alignItems='center' direction='row' spacing={1} width={1}>
          {props.taskDataSet.recurring === 'Weekly' || props.taskDataSet.recurring === 'Monthly'
            ? <Stack alignItems='center' direction='row' spacing={1}>
              <CalendarTodayRoundedIcon
                sx={{
                  color: props.taskDataSet.dueDate && props.nextDate && new Date(props.nextDate) > new Date()
                    ? 'text.label'
                    : theme => theme.palette.error.main,
                }}
              />
              <Typography
                color={props.taskDataSet.dueDate && props.nextDate && new Date(props.nextDate) > new Date()
                  ? 'text.label'
                  : themeItem.palette.error.main}
                variant='tableCell'
              >
                {props.nextDate &&
                      dayjs(props.nextDate).format('MMM DD')}
              </Typography>
              <LoopRoundedIcon
                sx={{
                  color: props.taskDataSet.dueDate && props.nextDate && new Date(props.nextDate) > new Date()
                    ? 'text.label'
                    : theme => theme.palette.error.main,
                }}
              />
            </Stack>
            : <Stack alignItems='center' direction='row' spacing={1}>
              <CalendarTodayRoundedIcon
                sx={{
                  color: props.taskDataSet.dueDate && new Date(props.taskDataSet.dueDate) > new Date()
                    ? 'text.label'
                    : theme => theme.palette.error.main,
                }}
              />
              <Typography
                color={props.taskDataSet.dueDate && new Date(props.taskDataSet.dueDate) > new Date()
                  ? 'text.label'
                  : themeItem.palette.error.main}
                variant='tableCell'
              >
                {dayjs(props.taskDataSet.dueDate).format('MMM DD')}
              </Typography>
            </Stack>}
        </Stack>}
      </TableCell>}

      {key === 'Assignee' && !props.hiddenFields.includes('Assignee') &&
      <TableCell id='Assignee' width='300px'>
        <Stack direction='row' flexWrap='wrap' >
          {props.taskDataSet.assignee &&
                props.taskDataSet.assignee.length > 0 &&
                props.assignee.map(assigneeItem =>
                  <Chip
                    avatar={<Avatar
                      src={assigneeItem.profilePictureUrl}
                      sx={{
                        zIndex: 1,
                      }}
                    />}
                    key={assigneeItem.id}
                    label={`${assigneeItem.firstName}`}
                    rounded
                    size='small'
                    sx={{
                      width: 'fit-content',
                      margin: 0.5,
                    }}
                    variant='subtle'
                  />)}
        </Stack>
      </TableCell>}

      {key === 'Last Updated' && !props.hiddenFields.includes('Last Updated') &&
      <TableCell id='Last Updated' width='150px'>
        <Stack alignItems='center' direction='row' spacing={1}>
          <Typography color='text.label' variant='tableCell'>
            {dayjs(props.taskDataSet.lastUpdated).format('MMM DD')}
          </Typography>
        </Stack>
      </TableCell>}

      {key === 'Priority' && !props.hiddenFields.includes('Priority') &&
      <TableCell id='Priority' width='150px'>
        {props.taskDataSet.priority === 'None' &&
        <Stack alignItems='center' direction='row' spacing={1}>
          <FlagRoundedIcon fontSize='medium' sx={{ color: theme => theme.palette.text.label }} />
          <Typography color={themeItem.palette.text.label} variant='body1'>
            {props.taskDataSet.priority}
          </Typography>
        </Stack>}
        {props.taskDataSet.priority === 'Low' &&
        <Stack alignItems='center' direction='row' spacing={1}>
          <FlagRoundedIcon color='primary' fontSize='medium' />
          <Typography color='primary' variant='body1'>{props.taskDataSet.priority}</Typography>
        </Stack>}
        {props.taskDataSet.priority === 'Medium' &&
        <Stack alignItems='center' direction='row' spacing={1}>
          <FlagRoundedIcon fontSize='medium' style={{ color: '#FBBF24' }} />
          <Typography color='#FBBF24' variant='body1'>{props.taskDataSet.priority}</Typography>
        </Stack>}
        {props.taskDataSet.priority === 'High' &&
        <Stack alignItems='center' direction='row' spacing={1}>
          <FlagRoundedIcon fontSize='medium' sx={{ color: theme => theme.palette.error.main }} />
          <Typography color={themeItem.palette.error.main} variant='body1'>
            {props.taskDataSet.priority}
          </Typography>
        </Stack>}
      </TableCell>}

      {key === 'Status' && !props.hiddenFields.includes('Status') &&
      <TableCell id='Status' width='150px'>
        {props.status === 'Done' &&
        <Chip color='success' label={props.status} />}
        {props.status === 'In progress' &&
        <Chip color='warning' label={props.status} />}
        {props.status === 'Not started' &&
        <Chip color='primary' label={props.status} />}
        {props.status === 'Not approved' &&
        <Chip color='error' label={props.status} />}
      </TableCell>}

      {field &&
      <TableCell
        align='left'
        id={field.id}
        key={field.id}
        sx={{ zIndex: 0 }}
        width='250px'
      >
        <RenderListItemCustom
          fieldType={field}
          fieldValue={props.taskDataSet.customFields.find(item => item.rowId === field.id) ??
          new CustomFieldValue({ rowId: field.id })}
          fields={props.customFields}
          handleFieldChange={props.handleFieldChange}
          taskData={props.taskDataSet}
        />
      </TableCell>}
    </>
  )
}

export default RenderListValues
