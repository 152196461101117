import { Card, CardContent, CardMedia, Skeleton, Typography } from '@mui/material'

const FakeReleaseCard = () =>
  <Card
    sx={{
      maxWidth: 240,
      minHeight: 240,
      maxHeight: '100%',
      margin: 2,
      width: 1,
    }}
    variant='outlined'
  >
    <CardMedia
      component='div'
      sx={{
        objectFit: 'cover',
        height: '240px',
        background: theme => theme.palette.background.input,
      }}
    />
    <CardContent>
      <Typography variant='h5'>
        <Skeleton animation={false} width={160} />
      </Typography>
      <Typography variant='body1'>
        <Skeleton animation={false} width={128} />
      </Typography>
      <Skeleton animation={false} height={32} sx={{ marginTop: 1 }} variant='circular' width={32} />
    </CardContent>
  </Card>

export default FakeReleaseCard
