
import { IconButton, Menu,  MenuItem, Stack, Tooltip, Typography, useTheme } from '@mui/material'
import { useState } from 'react'

import { getCustomFieldFormula } from 'src/components/pages/Task/TaskViews/TaskViewComponents/CustomFieldIcon'

type FormulaTypes = {
  label: string
  color: string
}

type Props = {
  setInputSelected: React.Dispatch<React.SetStateAction<string>>
  formulaName: string
}

const FormulaTypeSelect = (props: Props) => {
  const theme = useTheme()
  const [anchorElementMore, setAnchorElementMore] = useState<HTMLElement | null>(null)
  const openMoreMenu = Boolean(anchorElementMore)

  const handleMoreClose = () => {
    setAnchorElementMore(null)
  }

  const formulas: readonly FormulaTypes[] = [
    { label: 'Add', color: theme.palette.primary.main },
    { label: 'Subtract', color: theme.palette.error.main },
    { label: 'Multiplicate', color: theme.palette.success.main },
    { label: 'Divide', color: theme.palette.info.main },
  ]

  const findColor = (labelFound: string) => formulas.find(element => element.label === labelFound)

  return (
    <Stack
      alignItems='center'
      direction='column'
    >
      <Tooltip title={`Priority: ${props.formulaName}`}>
        <IconButton
          onClick={event => setAnchorElementMore(event.currentTarget)}
          sx={{
            border: `2px solid ${findColor(props.formulaName)?.color}`,
          }}
        >
          {getCustomFieldFormula(props.formulaName)}
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorElementMore}
        onClick={() => handleMoreClose()}
        open={openMoreMenu}
      >
        {formulas.map(priority =>
          <MenuItem
            key={priority.label}
            onClick={() => props.setInputSelected(priority.label as 'Add' | 'Divide' | 'Multiplicate' | 'Subtract')}
            value={priority.label}
          >
            <Stack alignItems='center' direction='row' spacing={1}>
              {getCustomFieldFormula(priority.label)}
              <Typography
                color={priority.color}
                variant='body2'
              >
                {priority.label}
              </Typography>
            </Stack>
          </MenuItem>)}
      </Menu>
    </Stack>
  )
}

export default FormulaTypeSelect
