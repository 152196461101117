/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-component-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Checkbox, Stack } from '@mui/material'
import type { PlateRenderElementProps, TElement } from '@udecode/plate'
import { createPluginFactory, useEditorState } from '@udecode/plate'
import { Transforms } from 'slate'
import { ReactEditor } from 'slate-react'

import type { MyValue } from 'src/components/pages/Notes/Modern/typescript/plateTypes'

export const ELEMENT_CHECKLIST = 'checklist'

export const ChecklistElement = (props: PlateRenderElementProps<MyValue, TElement>) => {
  const editor = useEditorState()
  const { checked } = props.element
  const checkedTyped = checked as boolean
  return (
    <Stack
      {...props.attributes}
      alignItems='center'
      direction='row'
      spacing={1}
    >
      <div
        contentEditable={false}
      >
        <Checkbox
          checked={checkedTyped}
          color='primary'
          onChange={event => {
            const path = ReactEditor.findPath(editor, props.element)
            const newProperties = {
              checked: event.target.checked,
            }
            Transforms.setNodes(editor, newProperties, { at: path })
          }}
          size='medium'
        />
      </div>
      {props.children}
    </Stack>
  )
}

export const createChecklistPlugin = createPluginFactory({
  key: ELEMENT_CHECKLIST,
  isElement: true,
  component: ChecklistElement,
})
