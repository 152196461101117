import { TabContext } from '@mui/lab'
import { Stack } from '@mui/material'
import { useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'

import Details from 'src/components/pages/Settings/General/GeneralTabs.tsx/Details'

const General = () => {
  const location = useLocation().pathname
  const [currentTab, _setCurrentTab] = useState(location)
  return (
    <TabContext value={currentTab}>
      <Stack height={1} overflow='auto' width={1}>
        <Routes>
          <Route element={<Details />} path='/details' />
        </Routes>
      </Stack>
    </TabContext>
  )
}
export default General
