/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { LoadingButton } from '@mui/lab'
import { Avatar, Container, Paper, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { unsubscribeQuery } from 'src/api/links'
import { getBasicOrganisation } from 'src/api/organisation'
import ReleeseLogo from 'src/components/ReleeseLogo'
import type { BasicOrganisation } from 'src/models/Organisation'

const Unsubscribe = () => {
  const query = new URLSearchParams(document.location.search)
  const { orgId } = useParams<{ orgId: string }>()
  const emailQuery = query.get('email')

  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [org, setOrg] = useState<BasicOrganisation | null>(null)
  const [done, setDone] = useState(false)

  useEffect(() => {
    if (emailQuery && emailQuery.length > 0) {
      setEmail(emailQuery)
    }
  }, [emailQuery])

  useEffect(() => {
    if (orgId) {
      void getBasicOrganisation(orgId)
        .then(setOrg)
        .catch(() => null)
    }
  }, [orgId])

  const confirm = async () => {
    if (orgId) {
      setLoading(true)
      await unsubscribeQuery(orgId, email)
        .finally(() => {
          setLoading(false)
          setDone(true)
        })
    }
  }

  return <Container
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 1,
      width: '100%!important',
      padding: 0,
      margin: 0,
      maxWidth: '100%!important',
      backgroundColor: theme => theme.palette.background.input,
    }}
  >
    <Paper
      sx={{
        padding: 3,
        textAlign: 'center',
        width: '100%',
        maxWidth: 600,
        marginY: 2,
        borderRadius: {
          xl: 1,
          lg: 1,
          md: 1,
          sm: 0,
          xs: 0,
        },
      }}
    >
      <Stack paddingY={4} >
        <ReleeseLogo full />
      </Stack>
      <Stack alignItems='center' justifyContent='center' spacing={1} width={1}>
        <Avatar
          src={org?.logoUrl}
          sx={{
            width: 128,
            height: 128,
          }}
        />
        <Typography variant='h4'>
          {org?.name}
        </Typography>
        {!done &&
        <Typography variant='body2'>
          Are you sure you want to unsubscribe? You will stop receiving their new releases in your library.
        </Typography>}
        {done &&
        <Typography variant='body2'>
          You have successfully unsubscribed
        </Typography>}
        {!done &&
        <LoadingButton
          loading={loading}
          onClick={() => void confirm()}
          variant='contained'
        >
          Unsubscribe
        </LoadingButton>}
      </Stack>
    </Paper>
  </Container>
}

export default Unsubscribe
