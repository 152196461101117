import axios from 'axios'

import type { SearchDto } from 'src/models/Search'
import { Search } from 'src/models/Search'

const PATH = '/search' as const

export const searchItems = () =>
  axios.get<SearchDto[]>(`${PATH}/`)
    .then(response => response.data.map(dto => new Search(dto)))

