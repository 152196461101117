/* eslint-disable max-lines */
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { Card, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Marquee } from 'dynamic-marquee-react'

import LogoR from 'src/images/LogoR.svg'

const pricingData = [
  {
    title: 'DistroKid',
    description: 'At least $7.50 / month',
    lessValues: 'No unlimited artists distribution',
    logoUrl: 'https://cdn.releese.io/website/production/alternatives/distrokid.webp',
  },
  {
    title: 'Linkfire',
    description: 'At least $24.99 / month',
    lessValues: 'No lifetime pre-saves or personalization',
    logoUrl: 'https://cdn.releese.io/website/production/alternatives/linkfire.webp',
  },
  {
    title: 'Linktree',
    description: 'At least $8.00 / month',
    lessValues: 'Does not display all your releases',
    logoUrl: 'https://cdn.releese.io/website/production/alternatives/linktree.webp',
  },
  {
    title: 'Soundcharts',
    description: '$10.00 / month for artists, $49.00 for labels',
    lessValues: 'No user-generated content tracking',
    logoUrl: 'https://cdn.releese.io/website/production/alternatives/soundcharts.webp',
  },
  {
    title: 'Songtrust',
    description: '$150.00 Signup fee + 15%',
    lessValues: 'No neighboring rights collection',
    logoUrl: 'https://cdn.releese.io/website/production/alternatives/songtrust.webp',
  },
  {
    title: 'ClickUp',
    description: 'At least $10.00 / month',
    lessValues: 'Not made for music projects',
    logoUrl: 'https://cdn.releese.io/website/production/alternatives/clickup.webp',
  },
  {
    title: 'Mailchimp',
    description: 'At least $20.00 / month',
    lessValues: 'Maximum 500 contacts',
    logoUrl: 'https://cdn.releese.io/website/production/alternatives/mailchimp.webp',
  },
]

export const PricingComparisonUi = () => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  return (
    <Stack
      alignItems='center'
      justifyContent='center'
      position='relative'
      width={1}
    >
      <Stack
        direction={fullScreen
          ? 'column'
          : 'row'}
        position='relative'
        width={1}
        zIndex={999}
      >
        <Stack
          alignContent='center'
          alignItems='center'
          height={fullScreen ? 'auto' : 480}
          justifyContent='center'
          position='relative'
          width={fullScreen ? 1 : 'calc(50% - 8px)'}
        >
          <Card
            elevation={0}
            sx={{
              bgcolor: themeItem.palette.background.elevatedCard,
              width: 1,
            }}
          >
            <Stack
              alignContent='center'
              alignItems='center'
              direction='row'
              justifyContent='flex-start'
              padding={2}
              spacing={2}
              width={1}
            >
              <Stack
                bgcolor='white'
                borderRadius={64}
                flexShrink={0}
                padding={1.5}
                position='relative'
              >
                <img
                  alt='Releese logo'
                  height='48px'
                  src={LogoR}
                  width='48px'
                />
              </Stack>
              <Stack>
                <Typography lineHeight={1.2} variant='h3'>
                  Releese
                </Typography>
                <Typography color='textSecondary' variant='body1'>
                  Only $19.00 / month
                </Typography>
                <Typography color='textLabel' variant='body1'>
                  <CheckCircleRoundedIcon color='success' sx={{ marginTop: '-2px' }} />
                  {' All combined features, and more'}
                </Typography>
              </Stack>
            </Stack>
          </Card>

          <Stack
            alignItems='center'
            display={fullScreen ? 'flex' : 'none'}
            height={16}
            justifyContent='center'
            position='relative'
            width={1}
            zIndex={20}
          >
            <Stack
              alignItems='center'
              bgcolor='white'
              borderRadius={64}
              color='black'
              height={32}
              justifyContent='center'
              position='absolute'
              sx={{
                fontSize: 24,
              }}
              top={-8}
              width={32}
            >
              =
            </Stack>
          </Stack>
          <Stack
            alignItems='center'
            display={fullScreen ? 'none' : 'flex'}
            height={16}
            justifyContent='center'
            position='absolute'
            right={-16}
            width={16}
            zIndex={20}
          >
            <Stack
              alignItems='center'
              bgcolor='white'
              borderRadius={64}
              color='black'
              height={32}
              justifyContent='center'
              position='absolute'
              sx={{
                fontSize: 24,
              }}
              top={-8}
              width={32}
            >
              =
            </Stack>
          </Stack>
        </Stack>

        <Stack
          height={480}
          position='absolute'
          right={0}
          sx={{
            // eslint-disable-next-line max-len
            maskImage: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 188, 212, 1) 20%, rgba(0, 188, 212, 1) 50%, rgba(0, 188, 212, 1) 80%, rgba(0, 0, 0, 0) 100%)',
          }}
          visibility={fullScreen ? 'hidden' : 'visible'}
          width='calc(50% - 8px)'
        >
          <Marquee rate={50} startOnScreen upDown>
            {pricingData.map((item, index) =>
              <Stack key={`2-${index.toString()}`} width={1}>
                <Card
                  elevation={0}
                  sx={{
                    bgcolor: themeItem.palette.background.elevatedCard,
                    width: 1,
                  }}
                >
                  <Stack
                    alignContent='center'
                    alignItems='center'
                    direction='row'
                    justifyContent='flex-start'
                    padding={2}
                    spacing={2}
                    width={1}
                  >
                    <Stack
                      bgcolor='white'
                      borderRadius={64}
                      flexShrink={0}
                      padding={1.5}
                      position='relative'
                    >
                      <img
                        alt='Releese logo'
                        height='48px'
                        src={item.logoUrl}
                        width='48px'
                      />
                    </Stack>
                    <Stack>
                      <Typography lineHeight={1.2} variant='h3'>
                        {item.title}
                      </Typography>
                      <Typography color='textSecondary' variant='body1'>
                        {item.description}
                      </Typography>
                      <Typography color='textLabel' variant='body1'>
                        <CloseRoundedIcon color='error' sx={{ marginTop: '-2px' }} />
                        {` ${item.lessValues}`}
                      </Typography>
                    </Stack>
                  </Stack>
                </Card>

                <Stack
                  alignItems='center'
                  height={16}
                  justifyContent='center'
                  position='relative'
                  width={1}
                  zIndex={20}
                >
                  <Stack
                    alignItems='center'
                    bgcolor='white'
                    borderRadius={64}
                    height={32}
                    justifyContent='center'
                    position='absolute'
                    top={-8}
                    width={32}
                  >
                    <AddRoundedIcon
                      sx={{
                        color: 'black',
                        fontSize: 24,
                      }}
                    />
                  </Stack>
                </Stack>
              </Stack>)}
          </Marquee>
        </Stack>

        <Stack
          display={fullScreen ? 'flex' : 'none'}
          width={1}
        >
          {pricingData.map((item, index) =>
            <Stack key={`1-${index.toString()}`} width={1}>
              <Card
                elevation={0}
                sx={{
                  bgcolor: themeItem.palette.background.elevatedCard,
                  width: 1,
                }}
              >
                <Stack
                  alignContent='center'
                  alignItems='center'
                  direction='row'
                  justifyContent='flex-start'
                  padding={2}
                  spacing={2}
                  width={1}
                >
                  <Stack
                    bgcolor='white'
                    borderRadius={64}
                    flexShrink={0}
                    padding={1.5}
                    position='relative'
                  >
                    <img
                      alt='Releese logo'
                      height='48px'
                      src={item.logoUrl}
                      width='48px'
                    />
                  </Stack>
                  <Stack>
                    <Typography lineHeight={1.2} variant='h3'>
                      {item.title}
                    </Typography>
                    <Typography color='textSecondary' variant='body1'>
                      {item.description}
                    </Typography>
                    <Typography color='textLabel' variant='body1'>
                      <CloseRoundedIcon color='error' sx={{ marginTop: '-2px' }} />
                      {` ${item.lessValues}`}
                    </Typography>
                  </Stack>
                </Stack>
              </Card>
              {index !== (pricingData.length - 1) &&
              <Stack
                alignItems='center'
                height={16}
                justifyContent='center'
                position='relative'
                width={1}
                zIndex={20}
              >
                <Stack
                  alignItems='center'
                  bgcolor='white'
                  borderRadius={64}
                  height={32}
                  justifyContent='center'
                  position='absolute'
                  top={-8}
                  width={32}
                >
                  <AddRoundedIcon
                    sx={{
                      color: 'black',
                      fontSize: 24,
                    }}
                  />
                </Stack>
              </Stack>}
            </Stack>)}
        </Stack>
      </Stack>
      <Stack
        bottom={0}
        display='flex'
        left='0'
        position='absolute'
        sx={{
          transform: 'translateX(100%)',
        }}
        width='30%'
      >
        <div
          style={{
            opacity: '30%',
            filter: 'blur(100px)',
            background: '#F087FF',
            width: '200px',
            height: '200px',
            borderRadius: '256px',
          }}
        />
        <div
          style={{
            opacity: '30%',
            filter: 'blur(100px)',
            background: '#40DDFF',
            width: '200px',
            height: '200px',
            borderRadius: '256px',
            bottom: '48px',
          }}
        />
      </Stack>
    </Stack>
  )
}
