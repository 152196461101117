import { Paper, styled } from '@mui/material'

const Content = styled(
  Paper,
  { shouldForwardProp: property => property !== 'open' && property !== 'drawerWidth' }
)<{
  open?: boolean
  drawerWidth?: number | string
}>(({ theme, open, drawerWidth: marginRight }) => ({
  flexGrow: 1,
  // Maybe? not sure about this overflow. Ok for now probably
  boxShadow: 'none',
  borderRadius: 0,
  backgroundColor: theme.palette.background.input,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...open && {
    borderTopRightRadius: '16px',
    marginRight,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}))

export default Content

