/* eslint-disable no-restricted-imports */
/* eslint-disable complexity */
import { IconButton, MenuItem, Select, Skeleton, Stack, Typography } from '@mui/material'
import { useState } from 'react'

import ActivityRows from 'src/components/pages/Audience/Components/Activity/ActivityRows'
import ChartsTable from 'src/components/pages/Audience/Components/Charts/ChartsTable'
import PreviousChartsTable from 'src/components/pages/Audience/Components/Charts/PreviousChartsTable'
import PreviousTrackChartTable from 'src/components/pages/Audience/Components/Charts/PreviousTrackCharts'
import TrackChartTable from 'src/components/pages/Audience/Components/Charts/TrackChart'
import DistributionFullChart from 'src/components/pages/Audience/Components/Overview/DistributionFullChart'
import RenderAnalyticsCards from 'src/components/pages/Audience/Components/Overview/RenderCards'
import PlaylistsTable from 'src/components/pages/Audience/Components/Playlists/PlaylistsTable'
import PlaylistsTableNoFollowers from 'src/components/pages/Audience/Components/Playlists/PlaylistTableNoFollowers'
import RemovedPlaylistsTable from 'src/components/pages/Audience/Components/Playlists/RemovedPlaylists'
import RemovedPlaylistsTableNoFollowers from 'src/components/pages/Audience/Components/Playlists/RemovedPlaylistTableNoFollowers'
import ShortsItems from 'src/components/pages/Audience/Components/Shorts/ShortsItem'
import TikTokInstagramVideoItems from 'src/components/pages/Audience/Components/Videos/TikTokInstagramVideos'
import VideoItems from 'src/components/pages/Audience/Components/Videos/VideoItems'
import { GetColorLink } from 'src/components/pages/SmartLink/Content/GetColorLink'
import GetIcon from 'src/components/pages/SmartLink/Content/GetIcon'
import type { AnalyticsActivity, AnalyticsCurrentData, AnalyticsHistoricData } from 'src/models/Analytics'

type Props = {
  currentTab: string
  currentData?: AnalyticsCurrentData
  historicData?: AnalyticsHistoricData
  artworkUrl: string
  activityData?: AnalyticsActivity
  loadingActivities: boolean
}

const AnalyticsPlatformDistribution = (props: Props) => {
  const [activeOption, setActiveOption] = useState('Current')
  const charts = props.currentData?.stats?.find(chartFind => chartFind.source === props.currentTab)?.data
    .charts?.filter(chart => !chart.removed_at)
  const previousCharts = props.currentData?.stats?.find(chartFind => chartFind.source === props.currentTab)?.data
    .charts?.filter(chart => chart.removed_at)

  const playlistsWithFollowers = props.currentData?.stats?.find(chartFind =>
    chartFind.source === props.currentTab)?.data.playlists?.filter(playlist =>
    !playlist.removed_at && playlist.followers_count)
  const removedPlaylistsWithFollowers = props.currentData?.stats?.find(chartFind =>
    chartFind.source === props.currentTab)?.data.playlists?.filter(playlist =>
    playlist.removed_at && playlist.followers_count)

  const playlistsWithNoFollowers = props.currentData?.stats?.find(chartFind =>
    chartFind.source === props.currentTab)?.data.playlists?.filter(playlist =>
    !playlist.removed_at && !playlist.followers_count && !playlist.spotifyid)
  const removedPlaylistsWithNoFollowers = props.currentData?.stats?.find(chartFind =>
    chartFind.source === props.currentTab)?.data.playlists?.filter(playlist =>
    playlist.removed_at && !playlist.followers_count && !playlist.spotifyid)

  const trackCharts = props.currentData?.stats?.find(chartFind =>
    chartFind.source === props.currentTab)?.data.track_charts?.filter(chart => !chart.removed_at)
  const albumCharts = props.currentData?.stats?.find(chartFind =>
    chartFind.source === props.currentTab)?.data.album_charts?.filter(chart => !chart.removed_at)

  const previousTrackCharts = props.currentData?.stats?.find(chartFind =>
    chartFind.source === props.currentTab)?.data.track_charts?.filter(chart => chart.removed_at)
  const previousAlbumCharts = props.currentData?.stats?.find(chartFind =>
    chartFind.source === props.currentTab)?.data.album_charts?.filter(chart => chart.removed_at)

  const videoItems = props.currentData?.stats?.find(chartFind =>
    chartFind.source === props.currentTab)?.data.videos?.filter(chart => chart.title)
  const socialVideoItems = props.currentData?.stats?.find(chartFind =>
    chartFind.source === props.currentTab)?.data.videos?.filter(chart => !chart.title)

  const shortsItems = props.currentData?.stats?.find(chartFind =>
    chartFind.source === props.currentTab)?.data.shorts

  const historicDataItem = props.historicData?.stats?.find(historic => historic.source === props.currentTab)?.data

  return (
    <Stack spacing={3} width={1}>
      {props.currentData?.stats?.map(item =>
        item.source === props.currentTab &&
        <Stack spacing={4} width={1}>

          {item.source !== 'Overview' &&
          <Stack alignItems='center' direction='row' justifyContent='space-between' spacing={1}>
            <Stack alignItems='center' direction='row' spacing={1}>
              {/* Platform */}
              <IconButton
                disableRipple
                sx={{
                  backgroundColor: GetColorLink(item.source),
                  color: 'white!important',
                  ':hover': {
                    backgroundColor: GetColorLink(item.source),
                    cursor: 'default',
                  },
                  '& .MuiSvgIcon-root': {
                    color: 'white!important',
                  },
                  borderRadius: '8px',
                }}
              >
                {GetIcon(item.source)}
              </IconButton>
              <Typography variant='h3'>
                {item.source.split('_').map(word => word[0].toLocaleUpperCase() + word.slice(1)).join(' ')}
              </Typography>
            </Stack>
            <Select
              onChange={event => setActiveOption(event.target.value)}
              placeholder='Select item'
              sx={{
                width: 180,
                marginTop: 0,
              }}
              value={activeOption}
            >
              {['Current', 'Total'].map(itemMap =>
                <MenuItem
                  id={itemMap}
                  key={`${itemMap}`}
                  value={itemMap}
                >
                  {itemMap}
                </MenuItem>)}
            </Select>
          </Stack>}

          {/* Data Cards */}
          <RenderAnalyticsCards
            activeSelection={activeOption}
            artworkUrl={props.artworkUrl}
            currentData={props.currentData}
            currentTab={props.currentTab}
          />

          {historicDataItem?.history &&
          <DistributionFullChart
            data={historicDataItem.history}
            source={item.source}
          />}

          {/* Track activities */}
          {props.loadingActivities
            ? <Stack sx={{ width: '100%', mb: 2 }} width={1}>
              <Typography variant='h3'>
                Recent activities
              </Typography>
              <Stack
                spacing={1}
                width='100%'
              >
                <Skeleton height={110} sx={{ transform: 'none ' }} width='100%' />
                <Skeleton height={110} sx={{ transform: 'none ' }} width='100%' />
                <Skeleton height={110} sx={{ transform: 'none ' }} width='100%' />
                <Skeleton height={110} sx={{ transform: 'none ' }} width='100%' />
                <Skeleton height={110} sx={{ transform: 'none ' }} width='100%' />
                <Skeleton height={110} sx={{ transform: 'none ' }} width='100%' />
              </Stack>
            </Stack>
            : props.activityData?.data &&
            <ActivityRows
              chartItems={props.activityData.data}
              currentTab={props.currentTab}
              overview={props.currentTab === 'Overview'}
              track
            />}

          {/* Current and Previous Charts */}
          {charts && charts.length > 0 &&
          <ChartsTable
            artworkUrl={props.artworkUrl}
            chartItems={charts}
            currentTab={props.currentTab}
          />}
          {previousCharts && previousCharts.length > 0 &&
          <PreviousChartsTable
            artworkUrl={props.artworkUrl}
            chartItems={previousCharts}
            currentTab={props.currentTab}
          />}

          {/* Current Charts Regions */}
          {trackCharts && trackCharts.length > 0 &&
          <TrackChartTable
            artworkUrl={props.artworkUrl}
            chartItems={trackCharts}
            currentTab={props.currentTab}
            track
          />}
          {albumCharts && albumCharts.length > 0 &&
          <TrackChartTable
            artworkUrl={props.artworkUrl}
            chartItems={albumCharts}
            currentTab={props.currentTab}
          />}

          {/* Previous Charts Regions */}
          {previousTrackCharts && previousTrackCharts.length > 0 &&
          <PreviousTrackChartTable
            artworkUrl={props.artworkUrl}
            chartItems={previousTrackCharts}
            currentTab={props.currentTab}
            track
          />}
          {previousAlbumCharts && previousAlbumCharts.length > 0 &&
          <PreviousTrackChartTable
            artworkUrl={props.artworkUrl}
            chartItems={previousAlbumCharts}
            currentTab={props.currentTab}
          />}

          {/* Follower Playlists */}
          {playlistsWithFollowers && playlistsWithFollowers.length > 0 &&
          <PlaylistsTable
            artworkUrl={props.artworkUrl}
            chartItems={playlistsWithFollowers}
            currentTab={props.currentTab}
          />}
          {removedPlaylistsWithFollowers && removedPlaylistsWithFollowers.length > 0 &&
          <RemovedPlaylistsTable
            artworkUrl={props.artworkUrl}
            chartItems={removedPlaylistsWithFollowers}
            currentTab={props.currentTab}
          />}

          {/* No Follower Playlists */}
          {playlistsWithNoFollowers && playlistsWithNoFollowers.length > 0 &&
          <PlaylistsTableNoFollowers
            artworkUrl={props.artworkUrl}
            chartItems={playlistsWithNoFollowers}
            currentTab={props.currentTab}
          />}
          {removedPlaylistsWithNoFollowers && removedPlaylistsWithNoFollowers.length > 0 &&
          <RemovedPlaylistsTableNoFollowers
            artworkUrl={props.artworkUrl}
            chartItems={removedPlaylistsWithNoFollowers}
            currentTab={props.currentTab}
          />}

          {/* YouTube Videos */}
          {videoItems && videoItems.length > 0 &&
          <VideoItems
            chartItems={videoItems}
          />}

          {/* YouTube Videos */}
          {socialVideoItems && socialVideoItems.length > 0 &&
          <TikTokInstagramVideoItems
            chartItems={socialVideoItems}
          />}

          {/* YouTube Shorts */}
          {shortsItems && shortsItems.length > 0 &&
          <ShortsItems
            chartItems={shortsItems}
          />}

        </Stack>)}
    </Stack>
  )
}
export default AnalyticsPlatformDistribution
