import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { Button, IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import type { DropResult } from 'react-beautiful-dnd'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { v4 } from 'uuid'

import EditLinkMarketingServiceItem from 'src/components/pages/Links/EditLink/EditLinkComponents/EditLinkMarketingServiceItem'
import { GetColorLink } from 'src/components/pages/SmartLink/Content/GetColorLink'
import GetIcon from 'src/components/pages/SmartLink/Content/GetIcon'
import { SmartLinkInput } from 'src/data/smartLinkData'
import type { MarketingLink, ServicesList } from 'src/models/Marketing'

type Props = {
  smartLinkData: MarketingLink
  setLinkData: React.Dispatch<React.SetStateAction<MarketingLink>>
}

const reorder = (list: ServicesList[], startIndex: number, endIndex: number) => {
  const result = [...list]
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const EditLinkMarketingServices = (props: Props) => {
  const filteredInputs = SmartLinkInput

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const openMenu = Boolean(anchorElement)

  const handleClose = () => {
    setAnchorElement(null)
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    } else if (result.destination.droppableId) {
      props.setLinkData(old =>
        ({
          ...old,
          inputList: reorder(props.smartLinkData.inputList, result.source.index, result.destination?.index ?? 0) }
        ))
    }
  }

  return (
    <Stack direction='column' spacing={2}>
      <Stack alignItems='center' direction='row' justifyContent='space-between' spacing={2}>
        <Button
          fullWidth
          onClick={event => setAnchorElement(event.currentTarget)}
          rounded
          startIcon={<AddRoundedIcon />}
          sx={{
            height: 48,
            fontSize: '1rem',
          }}
        >
          Add Music Link
        </Button>
      </Stack>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='presaves' key='presaves'>
          {provided => <Stack {...provided.droppableProps} direction='column' ref={provided.innerRef} spacing={1}>
            {props.smartLinkData.inputList.map((element, index) =>
              <EditLinkMarketingServiceItem
                element={element}
                index={index}
                key={element.id}
                setLinkData={props.setLinkData}
                smartLinkData={props.smartLinkData}
              />)}
            {provided.placeholder}
          </Stack>}
        </Droppable>
      </DragDropContext>

      <Menu
        anchorEl={anchorElement}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClick={handleClose}
        onClose={handleClose}
        open={openMenu}
        sx={{
          '& .MuiPaper-root': {
            maxHeight: '240px',
            minWidth: '400px',
          },
        }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        {filteredInputs.map(option =>
          <MenuItem
            key={option.label}
            onClick={() => {
              props.setLinkData(old => ({
                ...old,
                inputList: [
                  ...old.inputList,
                  {
                    label: option.label,
                    placeholder: option.placeholder,
                    value: option.value,
                    id: v4(),
                    customTitle: '',
                  },
                ],
              }))
            }}
          >
            <Stack alignItems='center' direction='row' spacing={1}>
              <IconButton
                disableRipple
                sx={{
                  backgroundColor: GetColorLink(option.label),
                  color: 'white',
                  ':hover': {
                    backgroundColor: GetColorLink(option.label),
                    cursor: 'default',
                  },
                }}
              >
                {GetIcon(option.label)}
              </IconButton>
              <Typography
                sx={{ color: theme => theme.palette.text.secondary }}
                variant='body2'
              >
                {option.label}
              </Typography>
            </Stack>
          </MenuItem>)}
      </Menu>
    </Stack>
  )
}

export default EditLinkMarketingServices
