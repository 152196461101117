/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import type { AxiosError } from 'axios'
import axios from 'axios'
import { StatusCodes } from 'http-status-codes'

import { auth } from 'src/utils/firebase'

const { NOT_FOUND, UNAUTHORIZED } = StatusCodes

export const allowNotFoundAndUnauthorized = (error: AxiosError) => {
  if (error.response && (error.response.status === NOT_FOUND || error.response.status === UNAUTHORIZED)) {
    return null
  }
  throw error
}

const initializeAxios = () => {
  axios.defaults.baseURL = process.env.NODE_ENV === 'production'
    ? 'https://api.releese.io/api'
    : `${window.location.origin}/api`

  axios.interceptors.request.use(async config => {
    let token = await auth.currentUser?.getIdToken()
    if (!token && process.env.REACT_APP_BYPASS_LOGIN) token = 'anonymous'
    if (token) {
      (config.headers as Record<string, string>).Authorization = `Bearer ${token}`
    }

    return config
  })
}
export default initializeAxios
