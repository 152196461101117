/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */
import { Paper, Stack, Tooltip, Typography, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import Linkify from 'react-linkify'

import { getAccount } from 'src/api/accounts'
import AccountAvatar from 'src/components/AccountAvatar'
import GetLink from 'src/components/pages/Messages/MessagesPanel/MessagesPanelComponents/GetLink'
import { useAuth } from 'src/components/providers/AuthProvider'
import Account from 'src/models/Account'

type MessageElementProps = {
  message: string
  time: number
  account: string
}

const CommentItemNotes = (props: MessageElementProps) => {
  const themeItem = useTheme()
  const { currentAccount } = useAuth()
  const [account, setAccount] = useState<Account | null>()

  useEffect(() => {
    void getAccount(props.account)
      .then(setAccount)
  }, [props.account])

  return (
    props.account === currentAccount.id
      ? <Stack
        direction='row'
        justifyContent='flex-end'
        spacing={1}
      >
        <Tooltip title={dayjs(props.time).format('llll')}>
          {(/(https|http)/).test(props.message)
            ? <Stack>
              <Paper
                elevation={0}
                sx={{
                  maxWidth: 336,
                  padding: 1.5,
                  backgroundColor: theme => theme.palette.primary.main,
                }}
              >
                <Linkify componentDecorator={(decoratedHref, decoratedText, key) =>
                  <a href={decoratedHref} key={key} rel='noreferrer' style={{ color: 'inherit' }} target='blank'>
                    {decoratedText}
                  </a>}
                >
                  <Typography color={themeItem.palette.primary.contrastText} sx={{ wordWrap: 'break-word' }}>
                    {props.message}
                  </Typography>
                </Linkify>
              </Paper>
              {GetLink(props.message)}
            </Stack>
            : <Paper
              elevation={0}
              sx={{
                maxWidth: 336,
                padding: 1.5,
                backgroundColor: theme => theme.palette.primary.main,
              }}
            >
              <Typography color={themeItem.palette.primary.contrastText} sx={{ wordWrap: 'break-word' }}>
                {props.message}
              </Typography>
              {' '}
            </Paper>}
        </Tooltip>
      </Stack>
      :     <Stack
        direction='row'
        spacing={1}
      >
        <Tooltip title={`${account?.firstName} ${account?.lastName}`}>
          <Stack>
            <AccountAvatar account={account ?? new Account({})} nolink size={42} />
          </Stack>
        </Tooltip>
        <Tooltip title={dayjs(props.time).format('llll')}>
          {(/(https|http)/).test(props.message)
            ? <Stack>
              <Paper
                elevation={0}
                sx={{
                  maxWidth: 336,
                  padding: 1.5,
                  backgroundColor: theme => theme.palette.background.input,
                }}
              >
                <Linkify componentDecorator={(decoratedHref, decoratedText, key) =>
                  <a href={decoratedHref} key={key} rel='noreferrer' style={{ color: 'inherit' }} target='blank'>
                    {decoratedText}
                  </a>}
                >
                  <Typography sx={{ wordWrap: 'break-word' }}>
                    {props.message}
                  </Typography>
                </Linkify>
              </Paper>
              {GetLink(props.message)}
            </Stack>
            : <Paper
              elevation={0}
              sx={{
                maxWidth: 336,
                padding: 1.5,
                backgroundColor: theme => theme.palette.background.input,
              }}
            >
              <Typography sx={{ wordWrap: 'break-word' }}>
                {props.message}
              </Typography>
              {' '}
            </Paper>}
        </Tooltip>
      </Stack>
  )
}

export default CommentItemNotes
