/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable complexity */
import type { ArtistObject } from 'src/models/Distribution'
import { Organisation } from 'src/models/Organisation'
import { Recoupment } from 'src/models/Recording'
import type { EmbeddedItem } from 'src/models/Taskboard'
import type { Serialized } from 'src/types/react-app-env'

export const PROJECT_STATUS = ['Released', 'On Hold', 'Confirmed', 'Cut', 'WIP'] as const

export const COLLABORATOR_RESTRICTIONS = [
  'Tasks',
  'Messages',
  'Files',
  'Notes',
  'Landing Pages',
  'Accounting',
  'Distribution',
  'Analytics',
  'Earnings',
]

export class Collaborator {
  account: Organisation
  id?: string
  role = ''
  status = 'Invited'

  restrictions: string[] = []

  constructor(dto: CollaboratorDto) {
    Object.assign(this, dto)
    this.account =  new Organisation(dto.account ?? {})
  }
}

export type CollaboratorDto = Partial<Serialized<Collaborator>>

export class LinkItem {
  id = ''
  link = ''

  constructor(dto: LinkItemDto) {
    Object.assign(this, dto)
  }
}

export type LinkItemDto = Partial<Serialized<LinkItem>>

export class NewProject {
  id?: string
  organisation?: Organisation
  title: string
  description = ''
  projectArtwork?: File
  status: typeof PROJECT_STATUS[number] = PROJECT_STATUS[4]
  collaborators: Collaborator[] = []
  invitedCollaboratorEmails: string[] = []

  tags: string[] = []
  recordLabel = ''
  ISRC = ''
  UPC = ''
  cLine = ''
  pLine = ''
  releaseDate = new Date()
  createdAt = new Date()
  updatedAt = new Date()

  format = ''
  catalogNumber = ''
  genre = ''
  subGenres: string[] = []
  primaryArtists: ArtistObject[] = []
  featuredArtists: ArtistObject[] = []

  recoupItems: Recoupment[] = []

  public = false

  distributed = false
  pitched = false

  constructor(dto: Partial<NewProject> & { title: string }) {
    Object.assign(this, dto)
    this.title = dto.title
  }
}

export default class Project extends NewProject {
  id: string
  projectArtworkUrl: string
  artworkPreviewUrl: string
  projectArtwork?: File
  attachmentUrls: string[]
  attachment?: File
  recordings: string[]
  disableDownload: boolean
  privateInviteHash: string

  updates: ProjectUpdate[] = []

  linkItems: LinkItem[] = []
  embeddedItems: EmbeddedItem[] = []
  recordingCount = 0

  constructor(dto: ProjectDto) {
    super({
      ...dto,
      releaseDate: new Date(dto.releaseDate ?? 0),
      createdAt: new Date(dto.createdAt ?? 0),
      updatedAt: new Date(dto.updatedAt ?? 0),
      organisation: new Organisation(dto.organisation ?? {}),
      title: dto.title ?? '',
      projectArtwork: dto.projectArtwork as File | undefined,
      format: dto.format ?? '',
      catalogNumber: dto.catalogNumber ?? '',
      genre: dto.genre ?? '',
      subGenres: dto.subGenres ?? [],
      primaryArtists: dto.primaryArtists ?? [],
      featuredArtists: dto.featuredArtists ?? [],
      collaborators: dto.collaborators?.map(collaborator => new Collaborator(collaborator)),
      tags: dto.tags ?? [],
      recoupItems: dto.recoupItems
        ? dto.recoupItems.map(recoup => new Recoupment(recoup))
        : [],
      public: dto.public ?? false,
    })
    this.id = dto.id ?? ''
    this.recordingCount = dto.recordingCount ?? 0
    this.disableDownload = dto.disableDownload ?? false
    this.projectArtworkUrl = dto.projectArtworkUrl ?? ''
    this.attachmentUrls = dto.attachmentUrls ?? []
    this.artworkPreviewUrl = dto.artworkPreviewUrl ?? ''
    this.recordings = dto.recordings ?? []
    this.privateInviteHash = dto.privateInviteHash ?? ''
    this.linkItems = dto.linkItems ?? []
    this.embeddedItems = dto.embeddedItems ?? []
    this.updates = dto.updates?.map(item =>
      new ProjectUpdate({
        id: item.id,
        account: item.account,
        message: item.message,
        timestamp: new Date(item.timestamp),
      })) ?? []
  }
}

export type ProjectDto = Partial<Serialized<Project>>

export class ProjectUpdate {
  id?: string
  account: string
  message: string
  timestamp = new Date()

  constructor(dto: ProjectUpdateDto & { account: string, message: string }) {
    Object.assign(this, dto)
    this.account = dto.account
    this.message = dto.message
  }
}

export type ProjectUpdateDto = Partial<ProjectUpdate>
