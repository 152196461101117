import PublicRoundedIcon from '@mui/icons-material/PublicRounded'
import { Avatar, ListItemText, Stack } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { getName } from 'i18n-iso-countries'
import { useState } from 'react'

import SocialAssetModal from 'src/components/pages/SocialAssets/SocialAssetModal'

type Props = {
  row: {
    country_code: string
    reposts_total: number
    repost_reach_total: number
  }
  currentTab: string
  artworkUrl: string
}

const RepostRow = (props: Props) => {
  const [socialDrawer, setSocialDrawer] = useState(false)
  return (
    <>
      <TableRow
        hover
        key={props.row.country_code}
        onClick={() => setSocialDrawer(true)}
        role='checkbox'
        sx={{
          '.MuiTableCell-root': { borderBottomColor: 'divider', paddingY: 2 },
        }}
        tabIndex={-1}
      >
        <TableCell
          component='th'
          padding='normal'
          scope='row'
        >
          <Stack alignItems='center' direction='row' spacing={2}>
            <Avatar
              src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/
${props.row.country_code.toUpperCase()}.svg`}
              sx={{
                background: theme => theme.palette.info.main,
                height: 23,
                width: 35,
                borderRadius: '4px',
              }}
              variant='rounded'
            >
              <PublicRoundedIcon />
            </Avatar>
            <ListItemText
              primary={getName(props.row.country_code, 'en')}
            />
          </Stack>
        </TableCell>
        <TableCell align='left'>
          {props.row.reposts_total.toLocaleString()}
        </TableCell>
        <TableCell align='left'>
          {props.row.repost_reach_total.toLocaleString()}
        </TableCell>
      </TableRow>
      {socialDrawer &&
      <SocialAssetModal
        close={() => setSocialDrawer(false)}
        imageMainUrl={props.artworkUrl}
        imageSecondaryUrl=''
        open={socialDrawer}
        platform={props.currentTab}
        text={`Reached ${props.row.reposts_total.toLocaleString()} reposts in ${props.row.country_code}`}
      />}
    </>
  )
}

export default RepostRow
