import type { ChangeEvent } from 'react'

import EditBioLinkServices from 'src/components/pages/Links/EditLink/EditBioLinkServices'
import EditLinkComplete from 'src/components/pages/Links/EditLink/EditLinkComplete'
import AllReleasesCard from 'src/components/pages/Links/EditLink/EditLinkComponents/AllReleasesCard'
import EditLinkAdvancedSettings from 'src/components/pages/Links/EditLink/EditLinkComponents/EditLinkAdvancedSettings'
import EditLinkDestination from 'src/components/pages/Links/EditLink/EditLinkComponents/EditLinkDestination'
import SubscriptionCard from 'src/components/pages/Links/EditLink/EditLinkComponents/SubscriptionCard'
import EditLinkGeneral from 'src/components/pages/Links/EditLink/EditLinkGeneral'
import EditLinkServices from 'src/components/pages/Links/EditLink/EditLinkServices'
import EditLinkSocial from 'src/components/pages/Links/EditLink/EditLinkSocial'
import EditLinkTracking from 'src/components/pages/Links/EditLink/EditLinkTracking'
import LinkView from 'src/components/pages/Links/ViewLink/LinkView'
import type { MarketingLink } from 'src/models/Marketing'
import type Project from 'src/models/Project'

type Props = {
  step: number
  smartLinkData: MarketingLink
  handleArtworkUpload: (event: ChangeEvent<HTMLInputElement>) => void
  artworkPreview: string | null | undefined
  setLinkData: React.Dispatch<React.SetStateAction<MarketingLink>>
  uniqueLinkBoolean: boolean
  setUniqueLinkBoolean: React.Dispatch<React.SetStateAction<boolean>>
  saveError: string
  projects: Project[] | undefined
  project: Project | undefined
  setProject: React.Dispatch<React.SetStateAction<Project | undefined>>
  colors: string[]
}

const EditLinkNewLinkSteps = (props: Props) => {
  switch (props.step) {
    case 0:
      return <>
        <EditLinkComplete
          setLinkData={props.setLinkData}
          setUniqueLinkBoolean={props.setUniqueLinkBoolean}
          smartLinkData={props.smartLinkData}
          uniqueLinkBoolean={props.uniqueLinkBoolean}
        />
        {props.smartLinkData.type !== 'Shortlink' && props.smartLinkData.type !== 'Biolink' &&
          <EditLinkServices
            setLinkData={props.setLinkData}
            smartLinkData={props.smartLinkData}
          />}
        {props.smartLinkData.type === 'Biolink' &&
          <EditBioLinkServices
            setLinkData={props.setLinkData}
            smartLinkData={props.smartLinkData}
          />}
        {props.smartLinkData.type === 'Shortlink' &&
          <EditLinkDestination
            setLinkData={props.setLinkData}
            smartLinkData={props.smartLinkData}
          />}
      </>
    case 1:
      return <EditLinkGeneral
        artworkPreview={props.artworkPreview}
        colors={props.colors}
        handleArtworkUpload={props.handleArtworkUpload}
        setLinkData={props.setLinkData}
        smartLinkData={props.smartLinkData}
      />
    case 2:
      return <EditLinkTracking
        setLinkData={props.setLinkData}
        smartLinkData={props.smartLinkData}
      />
    case 3:
      return <>
        {props.smartLinkData.type !== 'Shortlink' &&
        <>
          <AllReleasesCard
            setLinkData={props.setLinkData}
            smartLinkData={props.smartLinkData}
          />
          <SubscriptionCard
            setLinkData={props.setLinkData}
            smartLinkData={props.smartLinkData}
          />
          <EditLinkSocial
            setLinkData={props.setLinkData}
            smartLinkData={props.smartLinkData}
          />
        </>}
        {props.smartLinkData.type !== 'Biolink' &&
        <EditLinkAdvancedSettings
          project={props.project}
          projects={props.projects}
          saveError={props.saveError}
          setLinkData={props.setLinkData}
          setProject={props.setProject}
          smartLinkData={props.smartLinkData}
        />}
      </>
    case 4:
      return <LinkView
        link={props.smartLinkData}
      />
    default:
      return null
  }
}

export default EditLinkNewLinkSteps
