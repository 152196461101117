import type { Components } from '@mui/material'

import COLOR from 'src/styles/colors'

const MuiIconButton: Components['MuiIconButton'] = {
  styleOverrides: {
    root: {
      '.MuiTouchRipple-ripple, .MuiTouchRipple-child': {
        borderRadius: 'inherit',
      },
      '&.MuiLink-root': {
        color: COLOR.ACTION.ACTIVE,
      },
    },
    sizeSmall: {
      borderRadius: '12px',
      fontSize: '1.3rem', // 42px
    },
    sizeMedium: {
      borderRadius: '18px',
      fontSize: '1.5rem', // 42px
    },
    sizeLarge: {
      borderRadius: '24px',
      fontSize: '1.6rem', // 42px
    },
  },
}

export default MuiIconButton
