import axios from 'axios'
import download from 'js-file-download'

import type { AppleMusicPresave, BandsintownArtist, BandsintownEvents, MarketingEvent, MarketingLinkDto, SeatedEvents } from 'src/models/Marketing'
import { MarketingLink, MarketingLinkFan } from 'src/models/Marketing'
import { toFormData } from 'src/utils/objectUtils'

export const baseUrlLinksApi = process.env.NODE_ENV === 'development'
  ? 'http://localhost:5000/api'
  : 'https://api.releese.io/api'

const PATH = '/links' as const

// leaving this ownerId undefined returns all releases belonging to current user
export const getMarketingLinks = () =>
  axios.get<MarketingLinkDto[]>(`${PATH}`)
    .then(response => response.data.map(dto => new MarketingLink(dto)))

export const getMarketingLink = (marketingLinkId: string) =>
  axios.get<MarketingLinkDto>(`${PATH}/${marketingLinkId}`)
    .then(response => new MarketingLink(response.data))

export const getBioLink = () =>
  axios.get<MarketingLinkDto>(`${PATH}/biolink`)
    .then(response => new MarketingLink(response.data))

export const createFirstBioLink = () =>
  axios.post<boolean>(`${PATH}/firstBioLink`)
    .then(response => response)

export const getMarketingLinksByProjectId = (project_Id: string) =>
  axios.get<MarketingLinkDto[]>(`${PATH}/project/${project_Id}`)
    .then(response => response.data.map(dto => new MarketingLink(dto)))

export const savePartialMarketingLink = ({ id, ...marketingLink }: MarketingLink & { id: string }) =>
  axios.patch<MarketingLinkDto>(`${PATH}/${id}`, toFormData(marketingLink))
    .then(response => new MarketingLink(response.data))

export const createMarketingLink = (marketingLink: Partial<MarketingLink>) =>
  axios.post<string>(`${PATH}`, marketingLink)
    .then(result => result.data)

export const createMarketingEvent = (marketingEvent: Partial<MarketingEvent>) =>
  axios.post<string>(`${baseUrlLinksApi}${PATH}/event`, marketingEvent)
    .then(result => result.data)

export const createTestMarketingEvent = (testId: string) =>
  axios.post<string>(`${baseUrlLinksApi}${PATH}/testEvent/${testId}`)
    .then(result => result.data)

export const createTikTokTestMarketingEvent = (testId: string) =>
  axios.post<string>(`${baseUrlLinksApi}${PATH}/testEventTikTok/${testId}`)
    .then(result => result.data)

export const deleteMarketingLink = (marketingLink: Partial<MarketingLink>) =>
  axios.delete<string>(`${PATH}/delete/${marketingLink.id}`)
    .then(result => result.data)

export const getMarketingLinkByUrl = (url: string) =>
  axios.get<MarketingLinkDto | undefined>(`${baseUrlLinksApi}${PATH}/view/item?link=${url}`)
    .then(response => response.data ? new MarketingLink(response.data) : undefined)

export const createAppleMusicPresave = (presave: Partial<AppleMusicPresave>) =>
  axios.post<string>(`${baseUrlLinksApi}${PATH}/appleMusic`, presave)
    .then(response => response.data)

export const getUniqueLink = (subdomain: string, domain: string, url: string, id: string) =>
  axios.post<boolean>(`${PATH}/linkUrl/${id.length > 0 ? id : 'new'}`, { subdomain, domain, url })
    .then(response => response.data)

export const getUniqueBioLink = (username: string, id: string) =>
  axios.post<boolean>(`${PATH}/biolinkUrl/${id}`, { username })
    .then(response => response.data)

export const getAllFans = () =>
  axios.get<MarketingLinkFan[]>(`${PATH}/audience/fans`)
    .then(response => response.data.map(dto => new MarketingLinkFan(dto)))

export const downloadFans = () =>
  axios.put<File>(`${PATH}/audience/download`)
    .then(response => download(response.data, 'audience.csv'))

export const getLinksByISRC = (isrc: string) =>
  axios.get<string[]>(`${PATH}/scanForLinksSingle/${isrc}`)
    .then(response => response.data.map(dto => String(dto)))

export const getLinksByUPC = (upc: string) =>
  axios.get<string[]>(`${PATH}/scanForLinksAlbum/${upc}`)
    .then(response => response.data.map(dto => String(dto)))

export const getLinksByUrl = (link: string) =>
  axios.put<string[]>(`${PATH}/scanForLinksByLink`, { link })
    .then(response => response.data.map(dto => String(dto)))

export const firstLink = () =>
  axios.post<string>(`${PATH}/firstLink`)
    .then(response => response)

export const unsubscribeQuery = (orgId: string, email: string) =>
  axios.post<string>(`subscribe/unsubscribe/${orgId}/${email}`)
    .then(response => response)

export const getEmbedCode = (url: string) =>
  axios.put<string>(`${PATH}/oembed`, { url })
    .then(response => response.data)

export const getNextRelease = (orgId: string) =>
  axios.get<MarketingLinkDto | undefined>(`${PATH}/nextrelease/${orgId}`)
    .then(response => response.data ? new MarketingLink(response.data) : undefined)

export const getLatestRelease = (orgId: string) =>
  axios.get<MarketingLinkDto | undefined>(`${PATH}/latestrelease/${orgId}`)
    .then(response => response.data ? new MarketingLink(response.data) : undefined)

export const getAllReleases = (orgId: string, linkId: string) =>
  axios.get<MarketingLinkDto[]>(`${PATH}/allreleasespublic/${orgId}/${linkId}`)
    .then(response => response.data.map(dto => new MarketingLink(dto)))

export const getBandsintownArtist = (link: string) =>
  axios.put<{ artist: BandsintownArtist, events: BandsintownEvents[] }>(`${PATH}/bandsintown`, { link })
    .then(response => response.data)

export const getSeatedArtist = (link: string) =>
  axios.put<{ artist: SeatedEvents }>(`${PATH}/seated`, { link })
    .then(response => response.data)
