import CloseIcon from '@mui/icons-material/Close'
import PublishRoundedIcon from '@mui/icons-material/PublishRounded'
import { LoadingButton } from '@mui/lab'
import { Button, Checkbox, Dialog, FormControl, FormControlLabel, IconButton, Link, Paper, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'

type Props = {
  readonly close: () => void
  readonly open: boolean
  readonly distribute: () => Promise<void>
  readonly loadingSubmit: boolean
  readonly agreeTerms: boolean
  readonly publishing: boolean
  readonly setAgreeTerms: (value: React.SetStateAction<boolean>) => void
}

const DistributionTermsModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))
  const [loading, setLoading] = useState(false)

  const submit = async () => {
    setLoading(true)
    await props.distribute()
      .then(() => {
        setLoading(false)
        props.close()
      })
      .catch(() => {
        setLoading(false)
        props.close()
      })
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='xs'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Stack paddingX={3} paddingY={3} spacing={4} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          width='100%'
        >
          <Typography variant='h3'>
            Distribute
          </Typography>
          <IconButton
            disabled={props.loadingSubmit || loading}
            onClick={() => props.close()}
          >
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>
        <Stack
          alignItems='center'
          height={1}
          justifyContent='center'
          width='100%'
        >
          <Paper
            elevation={0}
            sx={{
              backgroundColor: theme => theme.palette.primary.main,
              borderRadius: 16,
            }}
          >
            <Stack
              alignItems='center'
              justifyContent='center'
              padding={2}
            >
              <PublishRoundedIcon
                sx={{
                  color: theme => theme.palette.primary.contrastText,
                  fontSize: '64px',
                }}
              />
            </Stack>
          </Paper>
          <Typography
            color={themeItem.palette.text.secondary}
            sx={{
              marginTop: 1,
            }}
            variant='h4'
          >
            Distribute your project
          </Typography>
          <Typography
            color={themeItem.palette.text.label}
            sx={{
              textAlign: 'center',
            }}
            variant='body1'
          >
            Please read and agree to the terms to distribute your project
          </Typography>
        </Stack>
        <Stack direction='row' spacing={1} width={1}>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.agreeTerms}
                  disabled={props.loadingSubmit || loading}
                  onChange={() => props.setAgreeTerms(oldterms => !oldterms)}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                />
              }
              label={props.publishing
                ? <Typography
                  color={themeItem.palette.text.label}
                  variant='body2'
                >
                  {'I agree to Releese\'s '}
                  <Link href='https://releese.io/legal/distribution-agreement/' target='_blank'>
                    Distribution Agreement
                  </Link>
                  {', '}
                  <Link href='https://releese.io/legal/publishing-agreement/' target='_blank'>
                    Publishing Agreement
                  </Link>
                  {' and '}
                  <Link href='https://releese.io/legal/terms-and-conditions/' target='_blank'>
                    Terms & Conditions.
                  </Link>
                </Typography>
                : <Typography
                  color={themeItem.palette.text.label}
                  variant='body2'
                >
                  {'I agree to Releese\'s '}
                  <Link href='https://releese.io/legal/distribution-agreement/' target='_blank'>
                    Distribution Agreement
                  </Link>
                  {' and '}
                  <Link href='https://releese.io/legal/terms-and-conditions/' target='_blank'>
                    Terms & Conditions.
                  </Link>
                </Typography>}
            />
          </FormControl>
        </Stack>
        <Stack direction='row' justifyContent='flex-end' spacing={1} width='100%'>
          <Button
            disabled={props.loadingSubmit || loading}
            onClick={() => props.close()}
            variant='text'
          >
            Cancel
          </Button>
          <LoadingButton
            color='primary'
            disabled={!props.agreeTerms}
            endIcon={<PublishRoundedIcon />}
            loading={props.loadingSubmit || loading}
            onClick={() => void submit()}
            variant='contained'
          >
            Distribute
          </LoadingButton>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default DistributionTermsModal
