import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded'
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded'
import { Card, CardActionArea, Radio, Stack, Typography } from '@mui/material'

type Props = {
  activePalette: string
  updateActivePalette: (palette: string) => void
}

const EditLinkPalette = (props: Props) =>
  <Stack direction='row' spacing={1}>
    <Card
      elevation={0}
      sx={{
        background: '#FCFCFD',
      }}
    >
      <CardActionArea
        onClick={() => props.updateActivePalette('Light')}
      >
        <Stack
          alignItems='center'
          justifyContent='center'
          minWidth={104}
          paddingBottom={1}
          paddingTop={2}
          paddingX={2}
        >
          <LightModeRoundedIcon
            sx={{
              color: 'black',
              fontSize: '3rem',
            }}
          />
          <Typography
            color='black'
            variant='h6'
          >
            Light
          </Typography>
          <Radio
            checked={props.activePalette === 'Light'}
            sx={{
              color: 'black',
            }}
          />
        </Stack>
      </CardActionArea>
    </Card>
    <Card
      elevation={0}
      sx={{
        background: 'black!important',
      }}
    >
      <CardActionArea
        onClick={() => props.updateActivePalette('Dark')}
      >
        <Stack
          alignItems='center'
          justifyContent='center'
          minWidth={104}
          paddingBottom={1}
          paddingTop={2}
          paddingX={2}
        >
          <DarkModeRoundedIcon
            sx={{
              color: 'white',
              fontSize: '3rem',
            }}
          />
          <Typography
            color='white'
            variant='h6'
          >
            Dark
          </Typography>
          <Radio
            checked={props.activePalette === 'Dark'}
            sx={{
              color: 'white',
            }}
          />
        </Stack>
      </CardActionArea>
    </Card>
  </Stack>

export default EditLinkPalette
