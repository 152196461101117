/* eslint-disable max-lines */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-await-in-loop */
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone'
import { alpha, IconButton, TableCell, TableRow, Tooltip } from '@mui/material'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { v4 } from 'uuid'

import { updateTaskItem } from 'src/api/taskboards'
import { sendTasksboardUpdate } from 'src/api/webSocket'
import { recurringDates } from 'src/components/pages/Task/TaskViews/TaskViewComponents/TaskBoardItem'
import RenderListValues from 'src/components/pages/Task/TaskViews/TaskViewTypes/RenderListValues'
import { useAuth } from 'src/components/providers/AuthProvider'
import type Account from 'src/models/Account'
import type { CustomFieldValue, TaskBoard, TaskGroup, TaskItem } from 'src/models/Taskboard'
import { TaskBoardEdit } from 'src/models/Taskboard'

type Props = {
  toggleTaskDrawer: (toggleState: boolean, taskGroup: TaskGroup, taskData?: TaskItem) => () => void
  taskDataSet: TaskItem
  id: string
  index: number
  searchQuery: string
  statusQuery: string
  priorityQuery: string
  taskColumn: TaskGroup
  taskboard: TaskBoard
  fieldOrder: string[]
  members: Account[]
}

const TaskListItem = (props: Props) => {
  const { currentAccount } = useAuth()
  const [taskData, setTaskData] = useState(props.taskDataSet)
  const dates = recurringDates(taskData.recurring, new Date(taskData.dueDate ?? 0))
  const [lastDone, setLastDone] = useState(props.taskDataSet.lastDoneOn)
  const [nextDate, setNextDate] = useState(dates.find(item => item.getTime() > Date.now()))
  const [previousDate, setPreviousDate] = useState([...dates].reverse().find(item => item.getTime() < Date.now()))

  const [status, setTaskStatus] = useState(props.taskDataSet.recurring !== 'None'
    ? lastDone &&
    previousDate &&
    nextDate &&
    new Date(lastDone).getTime() > previousDate.getTime() &&
    new Date(lastDone).getTime() < nextDate.getTime()
      ? 'Done'
      : 'Not started'
    : props.taskDataSet.status)

  const visibility = props.taskDataSet.title.toLowerCase().includes(props.searchQuery.toLowerCase()) &&
  props.taskDataSet.status.toLowerCase().includes(props.statusQuery.toLowerCase()) &&
  props.taskDataSet.priority.toLowerCase().includes(props.priorityQuery.toLowerCase())
  const [assignee, setAsignee] = useState<Account[]>([])

  useEffect(() => {
    const temporaryDates = recurringDates(props.taskDataSet.recurring, new Date(props.taskDataSet.dueDate ?? 0))
    setNextDate(temporaryDates.find(item => item.getTime() > Date.now()))
    setPreviousDate([...temporaryDates].reverse().find(item => item.getTime() < Date.now()))
  }, [props.taskDataSet.dueDate, props.taskDataSet.recurring])

  useEffect(() => {
    if (props.taskDataSet.recurring === 'Weekly' || props.taskDataSet.recurring === 'Monthly') {
      if (previousDate && lastDone && new Date(lastDone).getTime() < new Date(previousDate).getTime()) {
        setTaskStatus('Not started')
      }
      if (!lastDone) {
        setTaskStatus('Not started')
      }
      if (lastDone && !previousDate) {
        setTaskStatus('Done')
      }
    }
  }, [previousDate, nextDate, lastDone, props.taskDataSet.lastDoneOn, props.taskDataSet.recurring])

  useEffect(() => {
    if (props.taskDataSet) {
      const getAssignees = () => {
        setAsignee([])
        if (props.taskDataSet) {
          for (const assignees of props.taskDataSet.assignee) {
            const temporaryItem = props.members.find(item => item.id === assignees)
            if (temporaryItem) {
              setAsignee(previousAssignees => [...previousAssignees, temporaryItem])
            }
          }
        }
      }
      getAssignees()
    }
  }, [props.taskDataSet.assignee])

  const handleChange = async (event: React.MouseEvent<HTMLElement>,
    newStatus: 'Done' | 'In progress' | 'Not approved' | 'Not started') => {
    event.stopPropagation()
    const oldStatusItem = Object.assign({}, { ...props.taskDataSet })
    setTaskStatus(newStatus)
    setLastDone(new Date())
    props.taskDataSet.status = newStatus
    props.taskDataSet.lastUpdated = new Date()
    props.taskDataSet.lastDoneOn = new Date()
    const temporaryData = taskData
    temporaryData.status = newStatus
    temporaryData.lastUpdated = new Date()
    temporaryData.lastDoneOn = new Date()
    setTaskData(taskData)

    if (props.taskboard.id) {
      props.taskboard.taskGroups[props.taskboard.taskGroups.findIndex(group => group.id === props.taskColumn.id)]
        .tasks[props.taskboard.taskGroups[props.taskboard.taskGroups
          .findIndex(group => group.id === props.taskColumn.id)]
          .tasks.findIndex(task => task.id === props.taskDataSet.id)] = taskData
      await updateTaskItem(props.taskboard.id, props.taskColumn.id ?? '', taskData)
        .then(() => {
          const taskUpdateItem = new TaskBoardEdit({
            id: v4(),
            taskboardId: props.taskboard.id,
            taskId: props.taskDataSet.id,
            accountId: currentAccount.id,
            modificationType: 'Task details',
            itemType: 'Field',
            title: `${props.taskDataSet.title}`,
            fields: [{
              fieldName: 'Status',
              oldValue: oldStatusItem.status,
              newValue: newStatus,
            }],
          })
          sendTasksboardUpdate(taskUpdateItem)
        })
    }
  }

  const handleFieldChange = async (newvalue: CustomFieldValue) => {
    const oldValue = _.cloneDeep(taskData)
    const index = props.taskDataSet.customFields.findIndex(item => item.rowId === newvalue.rowId)
    props.taskDataSet.lastUpdated = new Date()
    taskData.lastUpdated = new Date()

    if (props.taskDataSet.customFields.some(item => item.rowId === newvalue.rowId)) {
      if (index !== -1) {
        taskData.customFields[index] = newvalue
        props.taskDataSet.customFields[index] = newvalue
        setTaskData(props.taskDataSet)
      }
    } else {
      props.taskDataSet.customFields.push(newvalue)
      taskData.customFields.push(newvalue)
      setTaskData(props.taskDataSet)
    }
    if (props.taskboard.id) {
      props.taskboard.taskGroups[props.taskboard.taskGroups.findIndex(group => group.id === props.taskColumn.id)]
        .tasks[props.taskboard.taskGroups[props.taskboard.taskGroups
          .findIndex(group => group.id === props.taskColumn.id)]
          .tasks.findIndex(task => task.id === props.taskDataSet.id)] = taskData
      await updateTaskItem(props.taskboard.id, props.taskColumn.id ?? '', taskData)
        .then(() => {
          const fieldRowItem = props.taskboard.customFields.findIndex(item => item.id === newvalue.rowId)
          if (fieldRowItem !== -1 && props.taskboard.customFields[fieldRowItem].fieldType === 'Checkbox') {
            const taskUpdateItem = new TaskBoardEdit({
              id: v4(),
              taskboardId: props.taskboard.id,
              taskId: props.taskDataSet.id,
              accountId: currentAccount.id,
              modificationType: 'Task details',
              itemType: 'Field',
              title: `${props.taskDataSet.title}`,
              fields: [{
                fieldName: props.taskboard.customFields[fieldRowItem].title,
                oldValue: newvalue.checkboxValue ? 'Unchecked' : 'Checked',
                newValue: newvalue.checkboxValue ? 'Checked' : 'Unchecked',
              }],
            })
            sendTasksboardUpdate(taskUpdateItem)
          } else if (fieldRowItem !== -1 && props.taskboard.customFields[fieldRowItem].fieldType === 'Rating') {
            const taskUpdateItem = new TaskBoardEdit({
              id: v4(),
              taskboardId: props.taskboard.id,
              taskId: props.taskDataSet.id,
              accountId: currentAccount.id,
              modificationType: 'Task details',
              itemType: 'Field',
              title: `${props.taskDataSet.title}`,
              fields: [{
                fieldName: props.taskboard.customFields[fieldRowItem].title,
                oldValue: oldValue.customFields[index]
                  ? oldValue.customFields[index].ratingValue.toString()
                  : '0',
                newValue: newvalue.ratingValue.toString(),
              }],
            })
            sendTasksboardUpdate(taskUpdateItem)
          } else {
            const taskUpdateItem = new TaskBoardEdit({
              id: v4(),
              taskboardId: props.taskboard.id,
              taskId: props.taskDataSet.id,
              accountId: currentAccount.id,
              modificationType: 'Task details',
              itemType: 'Field',
              title: `${props.taskDataSet.title}`,
            })
            sendTasksboardUpdate(taskUpdateItem)
          }
        })
    }
  }

  useEffect(() => {
    if (props.taskDataSet.status !== status &&
      props.taskDataSet.recurring !== 'Weekly' &&
      props.taskDataSet.recurring !== 'Monthly') {
      setTaskStatus(props.taskDataSet.status)
    }
  }, [props.taskDataSet.status, props.taskDataSet.recurring, status])

  useEffect(() => {
    setTaskData(props.taskDataSet)
  }, [props.taskDataSet])

  const alphaValue = 0.95

  return (
    <Draggable draggableId={props.taskDataSet.id?.toString() ?? ''} index={props.index} key={props.id}>
      {provided =>
        <TableRow
          hover
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          onClick={props.toggleTaskDrawer(true, props.taskColumn,
            { ...props.taskDataSet, updates: props.taskDataSet.updates })}
          sx={{
            position: 'relative',
            width: '100%',
            display:
            visibility
              ? 'flex'
              : 'none',
            backgroundColor: theme => theme.palette.background.default,
            '&:hover .MuiTableCell-root': {
              backgroundColor: theme => theme.palette.background.input,
            },
            borderTopColor: 'divider',
            borderTopStyle: 'solid',
            borderTopWidth: '1px',
            marginTop: '-1px',
            '.MuiTableCell-root': {
              borderColor: 'divider',
              display: 'flex',
              alignItems: 'center',
              borderRightStyle: 'solid',
              borderSpacing: '0px',
              borderWidth: '1px',
              borderCollapse: 'collapse',
            },
          }}
        >
          <TableCell
            id='IconStatus'
            sx={{
              position: 'sticky',
              left: 0,
              padding: 0,
              zIndex: 2,
              backgroundColor: theme => alpha(theme.palette.background.default, alphaValue),
            }}
            width='48px!important'
          >
            {(props.taskDataSet.priority === 'None' && status !== 'Done') &&
            <IconButton
              aria-label='Complete'
              onClick={event => handleChange(event, 'Done')}
              sx={{ color: theme => theme.palette.text.label }}
            >
              <CircleTwoToneIcon fontSize='large' sx={{ color: theme => theme.palette.text.label }} />
            </IconButton>}
            {(props.taskDataSet.priority === 'Low' && status !== 'Done') &&
            <IconButton
              aria-label='Complete'
              color='primary'
              onClick={event => handleChange(event, 'Done')}
            >
              <CircleTwoToneIcon color='primary' fontSize='large' />
            </IconButton>}
            {(props.taskDataSet.priority === 'Medium' && status !== 'Done') &&
            <IconButton
              aria-label='Complete'
              onClick={event => handleChange(event, 'Done')}
              sx={{ color: theme => theme.palette.rating }}
            >
              <CircleTwoToneIcon fontSize='large' sx={{ color: theme => theme.palette.rating }} />
            </IconButton>}
            {(props.taskDataSet.priority === 'High' && status !== 'Done') &&
            <IconButton
              aria-label='Complete'
              color='error'
              onClick={event => handleChange(event, 'Done')}
            >
              <CircleTwoToneIcon color='error' fontSize='large' />
            </IconButton>}
            {status === 'Done' &&
            props.taskDataSet.recurring !== 'Monthly' &&
            props.taskDataSet.recurring !== 'Weekly' &&
            <IconButton
              aria-label='Complete'
              color='success'
              onClick={event => handleChange(event, 'Not started')}
            >
              <CheckCircleRoundedIcon color='success' fontSize='large' />
            </IconButton>}
            {status === 'Done' &&
            (props.taskDataSet.recurring === 'Monthly' ||
            props.taskDataSet.recurring === 'Weekly') &&
            <Tooltip title='This recurring task is completed and locked'>
              <div>
                <IconButton
                  aria-label='Complete'
                  color='success'
                  disabled
                >
                  <CheckCircleRoundedIcon
                    color='success'
                    fontSize='large'
                    sx={{
                      opacity: 0.5,
                    }}
                  />
                </IconButton>
              </div>
            </Tooltip>}
          </TableCell>
          <TableCell
            id='Title'
            sx={{
              position: 'sticky',
              left: '46px',
              zIndex: 2,
              width: '350px',
              backgroundColor: theme => alpha(theme.palette.background.default, alphaValue),
            }}
          >
            {props.taskDataSet.title}
          </TableCell>

          {props.fieldOrder.map(field =>
            <RenderListValues
              assignee={assignee}
              customFields={props.taskboard.customFields}
              handleFieldChange={handleFieldChange}
              hiddenFields={props.taskboard.hiddenFields}
              key={field}
              keyItem={field}
              nextDate={nextDate}
              status={status}
              taskDataSet={props.taskDataSet}
            />)}

          <TableCell
            sx={{
              flexGrow: '1!important',
            }}
            width='150px'
          />
        </TableRow>}
    </Draggable>
  )
}

export default TaskListItem
