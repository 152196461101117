import LockPersonRoundedIcon from '@mui/icons-material/LockPersonRounded'
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded'
import { TabContext, TabList } from '@mui/lab'
import { Divider, Stack, Tab } from '@mui/material'
import { useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'

import Members from 'src/components/pages/Settings/Team/TeamTabs/Members'
import PermissionsSettings from 'src/components/pages/Settings/Team/TeamTabs/PermissionsSettings'
import { NavLink } from 'src/components/SafeRouterLink'

const Team = () => {
  const location = useLocation().pathname
  const [currentTab, setCurrentTab] = useState(location)
  return (
    <TabContext value={currentTab}>
      <Stack
        direction='column'
        width={1}
      >
        <Stack
          direction='row'
          width={1}
        >
          <TabList
            aria-label='Current team tab'
            onChange={(event, value: string) => setCurrentTab(value)}
          >
            <Tab
              component={NavLink}
              icon={<PeopleAltRoundedIcon />}
              label='Members'
              to='/settings/team/members'
              value='/settings/team/members'
            />
            <Tab
              component={NavLink}
              icon={<LockPersonRoundedIcon />}
              label='Permissions'
              to='/settings/team/permissions'
              value='/settings/team/permissions'
            />
          </TabList>
        </Stack>
        <Divider />
      </Stack>
      <Stack height={1} overflow='auto' width={1}>
        <Routes>
          <Route element={<Members />} path='/members' />
          <Route element={<PermissionsSettings />} path='/permissions' />
        </Routes>
      </Stack>
    </TabContext>
  )
}
export default Team
