/* eslint-disable react-hooks/exhaustive-deps */
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { Dialog, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'

import EditOrgLinkSocial from 'src/components/pages/Links/EditLink/EditLinkModals/EditOrgDefaultSocial'

type Props = {
  close: () => void
  open: boolean
}

const EditSocialOrgModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))
  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='sm'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Stack width={1}>
        <Stack alignItems='center' direction='row' justifyContent='space-between' padding={2} width={1}>
          <Typography variant='subtitle1'>
            Manage default icons
          </Typography>
          <IconButton onClick={() => props.close()}>
            <CloseRoundedIcon color='disabled' />
          </IconButton>
        </Stack>
        <Stack paddingBottom={2} paddingX={2}>
          <Typography color={themeItem.palette.text.label} variant='body2'>
            These icons will appear on all smart links and pre-save pages you create
          </Typography>
        </Stack>
        <Stack width={1}>
          <EditOrgLinkSocial
            close={props.close}
          />
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default EditSocialOrgModal
