/* eslint-disable sonarjs/no-duplicate-string */
import { v4 as uuidv4 } from 'uuid'

import { colorSelection } from 'src/components/pages/Task/TaskMenu/TaskMenuComponents/ColorsForPicker'
import type { TaskBoardTemplate, TaskGroup, TaskItem } from 'src/models/Taskboard'

const notStarted = 'Not started'

const tasksPreRelease: TaskItem[] = [{
  id: uuidv4(),
  title: 'Post stories on social media',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
{
  id: uuidv4(),
  title: 'Post Releese Pre-save link on socials',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
{
  id: uuidv4(),
  title: 'Create listening party on Twitch',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
]

const tasksReleaseWeek: TaskItem[] = [{
  id: uuidv4(),
  title: 'Send Releese Pre-save link to email list',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
{
  id: uuidv4(),
  title: 'Post teaser video on socials',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
]

const tasksPostRelease: TaskItem[] = [{
  id: uuidv4(),
  title: 'Engage fans through private messages',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
{
  id: uuidv4(),
  title: 'Advertise on socials',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
]

const taskGroupPreRelease: TaskGroup = {
  id: uuidv4(),
  tasks: tasksPreRelease,
  title: 'Pre release',
  color: colorSelection[0],
}

const taskGroupReleaseWeek: TaskGroup = {
  id: uuidv4(),
  tasks: tasksReleaseWeek,
  title: 'Release week',
  color: colorSelection[1],
}

const taskGroupPostRelease: TaskGroup = {
  id: uuidv4(),
  tasks: tasksPostRelease,
  title: 'Post release',
  color: colorSelection[2],
}

const taskGroups: TaskGroup[] = [taskGroupPreRelease, taskGroupReleaseWeek, taskGroupPostRelease]

export const taskboardFanEngagement: TaskBoardTemplate = {
  id: '',
  customFields: [],
  title: 'Fan engagement',
  color: colorSelection[3],
  fieldOrder: [],
  createdAt: new Date(),
  taskGroups,
  icon: 0,
  hiddenFields: [],
  description: `Plan your marketing strategy using this templates which focuses on scheduling
  social media posts, advertising campaigns and story posting to maximize fan engagement.`,
}
