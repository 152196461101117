/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import PersonalVideoRoundedIcon from '@mui/icons-material/PersonalVideoRounded'
import { Button, Card, Fab, Menu, Paper, TextField, Typography, useTheme } from '@mui/material'
import { Stack } from '@mui/system'
import type { PlateRenderElementProps, TElement } from '@udecode/plate'
import { createPluginFactory, useEditorState } from '@udecode/plate'
import { useState } from 'react'
import { Transforms } from 'slate'
import { ReactEditor } from 'slate-react'

import type { MyValue } from 'src/components/pages/Notes/Modern/typescript/plateTypes'

export const ELEMENT_FRAME_CUSTOM = 'iframeplugin'

export const IframeElement = (props: PlateRenderElementProps<MyValue, TElement>) => {
  const editor = useEditorState()
  const theme = useTheme()
  const { url } = props.element
  const length = url as string | undefined
  const [anchorElementEmbed, setAnchorElementEmbed] = useState<HTMLElement | null>(null)
  const openMenuEmbed = Boolean(anchorElementEmbed)
  return (
    <div
      {...props.attributes}
      {...props.nodeProps}
      className={props.className}
    >

      <div contentEditable={false} style={{ position: 'relative', userSelect: 'none' }}>
        {length && length.length > 0 &&
        <Fab
          onClick={event => setAnchorElementEmbed(event.currentTarget)}
          sx={{
            top: -16,
            right: -16,
            position: 'absolute',
          }}
        >
          <EditRoundedIcon />
        </Fab>}

        {length && length.length > 0
          ? <div
            style={{
              padding: '75% 0 0 0',
              position: 'relative',
            }}
          >
            <iframe
              src={`${url as string}?title=0&byline=0&portrait=0`}
              style={{
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: '16px',
              }}
            />
          </div>
          : <Card sx={{ widht: 1 }} variant='outlined'>
            <Stack alignItems='center' justifyContent='center' padding={2} width={1}>
              <Paper
                elevation={0}
                sx={{
                  backgroundColor: theme.palette.primary.subtleResting,
                  borderRadius: 16,
                }}
              >
                <Stack
                  alignItems='center'
                  justifyContent='center'
                  padding={4}
                >
                  <PersonalVideoRoundedIcon
                    sx={{
                      color: theme.palette.primary.main,
                      fontSize: '144px',
                    }}
                  />
                </Stack>
              </Paper>
              <Typography
                color={theme.palette.text.secondary}
                sx={{
                  marginTop: 1,
                }}
                variant='h4'
              >
                This could be a video
              </Typography>
              <Typography
                color={theme.palette.text.label}
                variant='body1'
              >
                Add a link to load your video
              </Typography>
              <Button
                color='primary'
                onClick={event => setAnchorElementEmbed(event.currentTarget)}
                startIcon={<AddRoundedIcon />}
                sx={{
                  marginTop: 2,
                }}
                variant='contained'
              >
                Add link
              </Button>
            </Stack>
          </Card>}
        {props.children}
        <UrlInput
          anchorEl={anchorElementEmbed}
          onChange={(value: string) => {
            const path = ReactEditor.findPath(editor, props.element)
            const newProperties = {
              url: value,
            }
            Transforms.setNodes(editor, newProperties, {
              at: path,
            })
          }}
          open={openMenuEmbed}
          setAnchorElementEmbed={setAnchorElementEmbed}
          url={url as string}
        />
      </div>
    </div>
  )
}

type UrlInputProps = {
  url: string
  onChange: (value: string) => void
  anchorEl: Element | null
  setAnchorElementEmbed: React.Dispatch<React.SetStateAction<HTMLElement | null>>
  open: boolean
}

const UrlInput = (props: UrlInputProps) => {
  const [value, setValue] = useState(props.url)
  return (
    <Menu
      anchorEl={props.anchorEl}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      onClose={() => props.setAnchorElementEmbed(null)}
      open={props.open}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
    >
      <Stack
        alignItems='flex-start'
        direction='column'
        padding={2}
        spacing={1}
        width={500}
      >
        <Typography variant='h6'>
          Set video link
        </Typography>
        <Stack
          alignItems='center'
          direction='row'
          spacing={1}
          width={1}
        >
          <TextField
            fullWidth
            onChange={event => {
              const newUrl = event.target.value
              setValue(newUrl)
            }}
            onClick={event => event.stopPropagation()}
            placeholder='https://youtube.com/embed/videoexample'
            sx={{
              marginTop: 0,
            }}
            value={value}
          />
          <Button
            disabled={!value || value.length === 0}
            onClick={() => {
              props.onChange(value)
              props.setAnchorElementEmbed(null)
            }}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </Menu>
  )
}

export const createCustomFramePlugin = createPluginFactory({
  key: ELEMENT_FRAME_CUSTOM,
  isElement: true,
  isVoid: true,
  component: IframeElement,
})
