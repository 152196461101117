import CloseIcon from '@mui/icons-material/Close'
import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded'
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded'
import { Autocomplete, Avatar, Button, Card, CardActionArea, Dialog, IconButton, ListItem, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'

import { getAllOrganisations } from 'src/api/organisation'
import SearchField from 'src/components/form-elements/SearchField'
import { useAuth } from 'src/components/providers/AuthProvider'
import { useMessenger } from 'src/components/providers/MessengerProvider'
import type Account from 'src/models/Account'
import type { Conversation } from 'src/models/Conversation'
import { NewConversation } from 'src/models/Conversation'
import type { Organisation } from 'src/models/Organisation'

type Props = {
  close: () => void
  open: boolean
  conversations?: Conversation[]
  targetOrganisation?: Organisation
}

const NewMessageModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  const { createConversation } = useMessenger()
  const { currentOrganisation, currentAccount } = useAuth()
  const [organisations, setOrganisations] = useState<Organisation[]>([])
  const [searchTerm, setSearchTerm] = useState('')
  const [participants, setParticipants] = useState<Organisation[]>([])
  const [accounts, setAccounts] = useState<Account[]>([])
  const [resultsLoading, setResultsLoading] = useState(false)
  const [type, setType] = useState<string>('')

  useEffect(() => {
    const delaySearch = setTimeout(() => {
      setResultsLoading(true)
      searchTerm.length > 2 && getAllOrganisations(searchTerm)
        .then(setOrganisations)
        .finally(() => setResultsLoading(false))
    }, 1000)

    return () => clearTimeout(delaySearch)
  }, [searchTerm])

  const manageReturn = () => {
    setAccounts([])
    setParticipants([])
    setType('')
  }

  const create = () => {
    if (type === 'Organisation') {
      const convo = new NewConversation(
        {
          type,
          selectedParticipants:
        [
          ...participants.map(org => org.id), currentOrganisation?.id ?? '',
        ],
        }
      )
      createConversation(convo)
        .finally(() => props.close())
    }
    if (type === 'Account') {
      const convo = new NewConversation(
        {
          type,
          selectedAccounts:
        [
          ...accounts.map(account => account.id), currentAccount.id,
        ],
        }
      )
      createConversation(convo)
        .finally(() => props.close())
    }
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='sm'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Stack paddingX={3} paddingY={3} spacing={1} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          width={1}
        >
          <Typography variant='h3'>New Message</Typography>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>
        {type === '' &&
        <Stack direction='row' spacing={2} width={1}>
          <Card
            elevation={0}
            sx={{
              width: 1,
              backgroundColor: 'transparent',
            }}
          >
            <CardActionArea
              onClick={() => setType('Account')}
            >
              <Stack alignItems='center' padding={2} spacing={2} width={1}>
                <Avatar
                  sx={{ background: theme => theme.palette.primary.main,
                    width: 128,
                    height: 128 }}
                >
                  <PersonAddAlt1RoundedIcon
                    sx={{
                      margin: 'auto',
                      fontSize: 96,
                      color: theme => theme.palette.primary.contrastText,
                    }}
                  />
                </Avatar>
                <Stack alignItems='center' width={1}>
                  <Typography
                    textAlign='center'
                    variant='h4'
                  >
                    Account
                  </Typography>
                  <Typography
                    color={themeItem.palette.text.secondary}
                    textAlign='center'
                    variant='body2'
                  >
                    Create a conversation with your team members
                  </Typography>
                </Stack>
              </Stack>
            </CardActionArea>
          </Card>
          <Card
            elevation={0}
            sx={{
              width: 1,
              backgroundColor: 'transparent',
            }}
          >
            <CardActionArea
              onClick={() => setType('Organisation')}
            >
              <Stack alignItems='center' padding={2} spacing={2} width={1}>
                <Avatar
                  sx={{ background: theme => theme.palette.secondary.main,
                    width: 128,
                    height: 128 }}
                >
                  <CorporateFareRoundedIcon
                    sx={{
                      margin: 'auto',
                      fontSize: 96,
                      color: theme => theme.palette.primary.contrastText,
                    }}
                  />
                </Avatar>
                <Stack alignItems='center' width={1}>
                  <Typography
                    textAlign='center'
                    variant='h4'
                  >
                    Organization
                  </Typography>
                  <Typography
                    color={themeItem.palette.text.secondary}
                    textAlign='center'
                    variant='body2'
                  >
                    Create a conversation between your teams
                  </Typography>
                </Stack>
              </Stack>
            </CardActionArea>
          </Card>
        </Stack>}

        {type === 'Account' && currentOrganisation &&
        <Stack minWidth={300} spacing={2} width={1}>
          {/* Search for users in organization */}
          <Autocomplete
            getOptionLabel={(option: Account) => `${option.firstName} ${option.lastName}`}
            multiple
            noOptionsText='No contacts found...'
            onChange={(event, newValue) => setAccounts(newValue)}
            options={currentOrganisation.seats.filter(seat =>
              seat.account.id !== currentAccount.id).map(seat => seat.account)}
            renderInput={params =>
              <SearchField
                {...params}
                placeholder='Search team members'
              />}
            renderOption={(renderProps, option) =>
              <ListItem {...renderProps}>
                <Stack
                  alignItems='center'
                  direction='row'
                  paddingX={2}
                  paddingY={1}
                  width={1}
                >
                  <Avatar src={option.profilePictureUrl} />
                  <Stack flex='1' padding={1} width='90%'>
                    <Typography variant='subtitle2'>
                      {`${option.firstName} ${option.lastName}`}
                    </Typography>
                    <Typography variant='body2'>
                      {option.email}
                    </Typography>
                  </Stack>
                </Stack>
              </ListItem>}
            value={accounts}
          />
        </Stack>}

        {type === 'Organisation' &&
        <Stack minWidth={300} spacing={2} width={1}>
          {/* Search for other organizations */}
          <Autocomplete
            getOptionLabel={(option: Organisation) => option.name}
            loading={resultsLoading}
            loadingText={searchTerm.length < 2 ? 'Enter a name' : 'Searching...'}
            multiple
            noOptionsText='No contacts found...'
            onChange={(event, newValue) => setParticipants(newValue)}
            options={organisations}
            renderInput={params =>
              <SearchField
                onChange={event => setSearchTerm(event.target.value)}
                {...params}
                placeholder='Search organizations'
              />}
            renderOption={(renderProps, option) =>
              <ListItem {...renderProps}>
                <Stack
                  alignItems='center'
                  direction='row'
                  paddingX={2}
                  paddingY={1}
                  width={1}
                >
                  <Avatar src={option.logoUrl} />
                  <Stack flex='1' padding={1} width='90%'>
                    <Typography variant='subtitle2'>{option.name}</Typography>
                    <Typography variant='body2'>{option.organisationEmail}</Typography>
                  </Stack>
                </Stack>
              </ListItem>}
            value={participants}
          />
        </Stack>}
        <Stack direction='row' justifyContent='flex-end' spacing={1} width={1}>
          {type !== '' &&
          <Button onClick={() => manageReturn()} variant='text'>
            Return
          </Button>}
          {type === '' &&
          <Button onClick={() => props.close()} variant='text'>
            Cancel
          </Button>}
          <Button
            disabled={
              (participants.length === 0 && type === 'Organisation') ||
              (accounts.length === 0 && type === 'Account') ||
              type === ''
            }
            onClick={create}
          >
            Next
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default NewMessageModal
