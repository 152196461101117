/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-lines */
/* eslint-disable complexity */
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import { Avatar, Badge, Button, Card, Chip, Collapse, Divider, Grid, Paper, Stack, Typography, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { useState } from 'react'

import type Project from 'src/models/Project'

type Props = {
  project?: Project
}

const ShareProjectDetails = (props: Props) => {
  const [openDetails, setOpenDetails] = useState(false)
  const themeColors = useTheme()

  return (
    <Stack
      alignItems='flex-start'
      paddingX={4}
      sx={{
        overflowY: 'auto',
        overflowX: 'inherit',
      }}
      width={1}
    >
      <Stack
        direction={{
          xl: 'row',
          lg: 'row',
          md: 'row',
          sm: 'column',
          xs: 'column',
        }}
        paddingY={4}
        spacing={2}
        width={1}
      >
        <Stack
          alignItems='center'
          width={{
            xl: 'fit-content',
            lg: 'fit-content',
            md: 'fit-content',
            sm: 1,
            xs: 1,
          }}
        >
          {props.project?.artworkPreviewUrl
            ? <Card elevation={0} sx={{ padding: 0 }}>
              <img
                alt='Artwork'
                height='256px'
                src={props.project.artworkPreviewUrl}
                style={{
                  borderRadius: 16,
                  objectFit: 'cover',
                }}
                width='256px'
              />
            </Card>
            : <Paper
              elevation={0}
              sx={{
                height: 256,
                width: 256,
                backgroundColor: theme => theme.palette.background.input,
              }}
            >
              <MusicNoteRoundedIcon
                sx={{
                  fontSize: 256,
                  color: theme => theme.palette.action.disabled,
                }}
              />
            </Paper>}
        </Stack>
        <Stack direction='column' spacing={2} width={1}>
          <Stack
            alignItems='flex-start'
            direction='column'
            justifyContent='space-between'
            spacing={1}
            width={1}
          >
            <Typography variant='h2'>
              {props.project?.title}
            </Typography>
            <Stack direction='row' justifyContent='space-between' spacing={1} width={1}>
              <Stack spacing={1}>
                <Typography color={themeColors.palette.text.label} variant='body2'>
                  Created by
                </Typography>
                <Stack spacing={1} >
                  <Stack alignItems='flex-start' direction='row' spacing={1}>
                    <Badge
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      badgeContent={<CheckCircleRoundedIcon
                        color='secondary'
                        sx={{
                          backgroundColor: 'white',
                          borderRadius: '50%',
                          width: 12,
                          height: 12,
                          fontSize: 8,
                        }}
                      />}
                      overlap='circular'
                      sx={{
                        '.MuiBadge-badge': {
                          display: props.project?.organisation?.verified ? undefined : 'none',
                        },
                      }}
                    >
                      <Avatar
                        src={props.project?.organisation?.logoUrl}
                        sx={{ width: 24, height: 24 }}
                      />
                    </Badge>
                    <Typography variant='body2'>{props.project?.organisation?.name}</Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Button
                onClick={() => setOpenDetails(!openDetails)}
                sx={{
                  width: 'fit-content',
                }}
                variant='text'
              >
                {!openDetails
                  ? 'View all details'
                  : 'Hide details'}
              </Button>
            </Stack>

            <Divider sx={{ width: 1 }} />

            <Collapse
              in={openDetails}
              sx={{
                width: 1,
              }}
            >
              <Stack direction='column' spacing={2}>
                <Grid container rowSpacing={1}>
                  <Grid item lg={2} md={3} sm={4} xl={2} xs={6}>
                    <Stack>
                      <Typography color={themeColors.palette.text.label} variant='body2'>
                        Created on
                      </Typography>
                      <Typography variant='body2'>
                        {dayjs(props.project?.createdAt).format('LL')}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item lg={2} md={3} sm={4} xl={2} xs={6}>
                    {props.project?.primaryArtists ? <Stack>
                      <Typography color={themeColors.palette.text.label} variant='body2'>
                        Artists
                      </Typography>
                      <Stack direction='row' flexWrap='wrap'>
                        {props.project.primaryArtists.length > 0
                          ? props.project.primaryArtists.map(artist =>
                            <Chip
                              avatar={
                                <Avatar src={artist.images?.[0]?.url ? artist.images[0].url : ''} />
                              }
                              key={artist.name}
                              label={artist.name}
                              sx={{ margin: 0.2 }}
                              variant='subtle'
                            />)
                          : <Typography variant='body2'>
                            No primary artists
                          </Typography>}
                      </Stack>
                    </Stack> : null}
                  </Grid>

                  {props.project?.featuredArtists ? <Grid item lg={2} md={3} sm={4} xl={2} xs={6}>
                    <Stack>
                      <Typography color={themeColors.palette.text.label} variant='body2'>
                        Featured artists
                      </Typography>
                      <Stack direction='row' flexWrap='wrap'>
                        {props.project.featuredArtists.length > 0
                          ? props.project.featuredArtists.map(featuredArtist =>
                            <Chip
                              avatar={
                                <Avatar src={featuredArtist.images?.[0]?.url ? featuredArtist.images[0].url : ''} />
                              }
                              key={featuredArtist.name}
                              label={featuredArtist.name}
                              sx={{ margin: 0.2 }}
                              variant='subtle'
                            />)
                          : <Typography variant='body2'>
                            No featured artists
                          </Typography>}
                      </Stack>
                    </Stack>
                  </Grid> : null}

                  <Grid item lg={2} md={3} sm={4} xl={2} xs={6}>
                    <Stack>
                      <Typography color={themeColors.palette.text.label} variant='body2'>
                        Tags
                      </Typography>
                      <Stack direction='row' flexWrap='wrap'>
                        {props.project?.tags && props.project.tags.length > 0
                          ? props.project.tags.map(tag =>
                            <Chip
                              key={tag}
                              label={tag}
                              sx={{ margin: 0.2 }}
                              variant='subtle'
                            />)
                          : <Typography variant='body2'>
                            No tag found
                          </Typography>}
                      </Stack>
                    </Stack>
                  </Grid>

                  {props.project?.genre ? <Grid item lg={2} md={3} sm={4} xl={2} xs={6}>
                    <Stack>
                      <Typography color={themeColors.palette.text.label} variant='body2'>
                        Genre
                      </Typography>
                      <Typography variant='body2'>
                        {props.project.genre}
                      </Typography>
                    </Stack>
                  </Grid> : null}

                  {props.project?.subGenres ? <Grid item lg={2} md={3} sm={4} xl={2} xs={6}>
                    <Stack>
                      <Typography color={themeColors.palette.text.label} variant='body2'>
                        Subgenre
                      </Typography>
                      <Stack direction='row' flexWrap='wrap'>
                        {props.project.subGenres.length > 0
                          ? props.project.subGenres.map(genre =>
                            <Chip
                              key={genre}
                              label={genre}
                              sx={{ margin: 0.2 }}
                              variant='subtle'
                            />)
                          : <Typography variant='body2'>
                            No subgenre found
                          </Typography>}
                      </Stack>
                    </Stack>
                  </Grid> : null}

                  {props.project?.releaseDate ? <Grid item lg={2} md={3} sm={4} xl={2} xs={6}>
                    <Stack>
                      <Typography color={themeColors.palette.text.label} variant='body2'>
                        Release date
                      </Typography>
                      <Typography variant='body2'>
                        {dayjs(props.project.releaseDate).format('LL')}
                      </Typography>
                    </Stack>
                  </Grid> : null}

                  {props.project?.UPC ? <Grid item lg={2} md={3} sm={4} xl={2} xs={6}>
                    <Stack>
                      <Typography color={themeColors.palette.text.label} variant='body2'>
                        UPC
                      </Typography>
                      <Typography variant='body2'>
                        {props.project.UPC}
                      </Typography>
                    </Stack>
                  </Grid> : null}

                  {props.project?.cLine ? <Grid item lg={2} md={3} sm={4} xl={2} xs={6}>
                    <Stack>
                      <Typography color={themeColors.palette.text.label} variant='body2'>
                        C Line
                      </Typography>
                      <Typography variant='body2'>
                        {props.project.cLine}
                      </Typography>
                    </Stack>
                  </Grid>  : null}

                  {props.project?.pLine ?  <Grid item lg={2} md={3} sm={4} xl={2} xs={6}>
                    <Stack>
                      <Typography color={themeColors.palette.text.label} variant='body2'>
                        P Line
                      </Typography>
                      <Typography variant='body2'>
                        {props.project.pLine}
                      </Typography>
                    </Stack>
                  </Grid> : null}

                  {props.project?.recordLabel ? <Grid item lg={2} md={3} sm={4} xl={2} xs={6}>
                    <Stack>
                      <Typography color={themeColors.palette.text.label} variant='body2'>
                        Record Label
                      </Typography>
                      <Typography variant='body2'>
                        {props.project.recordLabel}
                      </Typography>
                    </Stack>
                  </Grid> : null}

                  {props.project?.catalogNumber ? <Grid item lg={2} md={3} sm={4} xl={2} xs={6}>
                    <Stack>
                      <Typography color={themeColors.palette.text.label} variant='body2'>
                        Catalog Number
                      </Typography>
                      <Typography variant='body2'>
                        {props.project.catalogNumber}
                      </Typography>
                    </Stack>
                    {' '}
                  </Grid> : null}

                  {props.project?.format ? <Grid item lg={2} md={3} sm={4} xl={2} xs={6}>
                    <Stack>
                      <Typography color={themeColors.palette.text.label} variant='body2'>
                        Format
                      </Typography>
                      <Typography variant='body2'>
                        {props.project.format}
                      </Typography>
                    </Stack>
                  </Grid> : null}
                </Grid>
              </Stack>
            </Collapse>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
export default ShareProjectDetails
