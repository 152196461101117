import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { Button, Dialog, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'

type Props = {
  close: () => void
  open: boolean
}

const DomainSetupModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))
  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='xs'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Stack paddingX={3} paddingY={3} spacing={4} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          width='100%'
        >
          <Typography variant='subtitle1'>
            Domain Setup
          </Typography>
          <IconButton onClick={() => props.close()}>
            <CloseRoundedIcon color='disabled' />
          </IconButton>
        </Stack>
        <Stack width='100%'>
          <Stack alignItems='center' direction='column'>
            <CheckCircleRoundedIcon color='success' sx={{ fontSize: 64 }} />
            <Typography color='text.secondary' textAlign='center' variant='h4'>
              Your custom domain was added
            </Typography>
            <Typography color='text.label' textAlign='center' variant='subtitle1'>
              You can now use your custom domain on Marketing Cloud Landing Pages
            </Typography>
          </Stack>
        </Stack>
        <Stack direction='row' justifyContent='flex-end' spacing={1} width='100%'>
          <Button
            color='success'
            onClick={props.close}
          >
            Finish
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default DomainSetupModal
