/* eslint-disable max-lines */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable no-await-in-loop */
/* eslint-disable sonarjs/no-identical-functions */
import type { AutocompleteRenderGetTagProps } from '@mui/material'
import { Autocomplete, Avatar, Box, Checkbox, Chip, FormGroup, ListItemIcon, Rating, Stack, TableCell, TextField, Typography } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

import { getAccount } from 'src/api/accounts'
import EditDropdownField from 'src/components/pages/Task/TaskDrawer/EditDropdownField'
import { getCustomFieldIcon } from 'src/components/pages/Task/TaskViews/TaskViewComponents/CustomFieldIcon'
import type Account from 'src/models/Account'
import type { CustomFieldValue } from 'src/models/Taskboard'

type Props = {
  fieldType: string
  fieldValue: CustomFieldValue
  handleFieldChange: (newvalue: CustomFieldValue) => void
  fieldName: string
  dropdownOptions: string[]
  members: Account[]
  fieldColors: string[]
}

const EditCustomFieldDrawer = (props: Props) => {
  const [valueItem, setValue] = useState(props.fieldValue)
  const [people, setPeople] = useState<Account[]>([])
  const [loading, setLoading] = useState(false)

  const emptyString: string[] = []

  useEffect(() => {
    setValue(props.fieldValue)
  }, [props.fieldValue])

  useEffect(() => {
    if (props.fieldType === 'People' && props.fieldValue.peopleValue && props.fieldValue.peopleValue.length > 0) {
      const getAssignees = async () => {
        setLoading(true)
        for (const assignees of props.fieldValue.peopleValue) {
          await getAccount(assignees)
            .then(account => account && setPeople(previousAssignees => [...previousAssignees, account]))
        }
      }
      void getAssignees()
        .finally(() => setLoading(false))
    }
  }, [props.fieldValue.peopleValue])

  const handlePeopleChange = (event: Account[]) => {
    const stringArray = event.map(item => item.id)

    setPeople(event)
    props.handleFieldChange({ ...valueItem, peopleValue: stringArray })
    setValue({ ...valueItem, peopleValue: stringArray })
  }

  return (
    <>
      <TableCell
        sx={{
          paddingX: 1,
          paddingY: 0.5,
          width: 175,
        }}
      >
        <Stack alignItems='center' direction='row' spacing={1}>
          <ListItemIcon
            sx={{
              fontSize: '16px',
              minWidth: '24px',
            }}
          >
            {getCustomFieldIcon(props.fieldType)}
          </ListItemIcon>
          <Typography sx={{ marginTop: 0 }} variant='textLabel'>
            {props.fieldName}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell
        sx={{
          paddingX: 1,
          paddingY: 0.5,
          fontSize: 'inherit',
        }}
      >
        {(props.fieldType === 'Text' || props.fieldType === 'Email' || props.fieldType === 'Phone') &&
        <TextField
          fullWidth
          onChange={event => {
            props.handleFieldChange({ ...valueItem, stringValue: event.target.value })
            setValue({ ...valueItem, stringValue: event.target.value })
          }}
          placeholder='Aa'
          sx={{
            marginTop: 0,
            background: 'transparent!important',
            border: 'none',
            '.MuiInputBase-root': {
              border: 'none',
              background: 'transparent!important',
              borderColor: 'transparent!important',
              paddingY: 0,
              marginTop: 0,
            },
          }}
          value={valueItem.stringValue}
        />}

        {(props.fieldType === 'Number' || props.fieldType === 'Money') &&
        <TextField
          fullWidth
          onChange={event => {
            props.handleFieldChange({ ...valueItem, numberValue: Number(event.target.value) })
            setValue({ ...valueItem, numberValue: Number(event.target.value) })
          }}
          placeholder='0.00'
          sx={{
            marginTop: 0,
            border: 'none',
            'input::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
            'input::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
            '.MuiInputBase-root': {
              border: 'none',
              background: 'transparent!important',
              borderColor: 'transparent!important',
              paddingY: 0,
              marginTop: 0,
            },
          }}
          type='number'
          value={valueItem.numberValue.toString()}
        />}

        {props.fieldType === 'Checkbox' &&
        <FormGroup>
          <Checkbox
            checked={valueItem.checkboxValue}
            onChange={(event, value) => {
              props.handleFieldChange({ ...valueItem, checkboxValue: value })
              setValue({ ...valueItem, checkboxValue: value })
            }}
            onClick={event => event.stopPropagation()}
            sx={{
              width: 'fit-content',
            }}
          />
        </FormGroup>}

        {props.fieldType === 'Chips' &&
        <Autocomplete
          freeSolo
          fullWidth
          multiple
          onChange={(event, value) => {
            props.handleFieldChange({ ...valueItem, stringArrayValue: value })
            setValue({ ...valueItem, stringArrayValue: value })
          }}
          options={emptyString}
          renderInput={params =>
            <TextField
              {...params}
              fullWidth
              placeholder='Type and press enter to add a chip'
              sx={{
                marginTop: 0,
                border: 'none',
                '.MuiInputBase-root': {
                  background: 'transparent!important',
                  borderColor: 'transparent!important',
                  paddingY: 0,
                  marginTop: 0,
                },
              }}
            />}
          sx={{
            width: 1,
            background: 'transparent!important',
            border: 'none',
            '.MuiInputBase-root': {
              background: 'transparent!important',
              border: 'none',
            },
          }}
          value={valueItem.stringArrayValue}
        />}

        {props.fieldType === 'Date' &&
        <DesktopDatePicker
          format='MMMM DD, YYYY'
          onChange={event => {
            props.handleFieldChange({ ...valueItem, dateValue: event ? event.toDate() : null })
            setValue({ ...valueItem, dateValue: event ? event.toDate() : null })
          }}
          sx={{
            marginTop: 0,
            background: 'transparent',
            '.MuiInputBase-root': {
              border: 'none',
              background: 'transparent!important',
              borderColor: 'transparent!important',
              paddingY: 0,
              marginTop: 0,
            },
          }}
          value={valueItem.dateValue ? dayjs(valueItem.dateValue) : null}
        />}

        {props.fieldType === 'Website' &&
        <TextField
          fullWidth
          onChange={event => {
            props.handleFieldChange({ ...valueItem, websiteValue: event.target.value })
            setValue({ ...valueItem, websiteValue: event.target.value })
          }}
          placeholder='https://releese.io'
          sx={{
            marginTop: 0,
            border: 'none',
            '.MuiInputBase-root': {
              border: 'none',
              background: 'transparent!important',
              borderColor: 'transparent!important',
              paddingY: 0,
              marginTop: 0,
            },
          }}
          value={valueItem.websiteValue}
        />}

        {props.fieldType === 'Dropdown' &&
        <Autocomplete
          fullWidth
          onChange={(event, value) => {
            props.handleFieldChange({ ...valueItem, dropdownValue: value ?? '' })
            setValue({ ...valueItem, dropdownValue: value ?? '' })
          }}
          options={props.dropdownOptions}
          renderInput={params =>
            <TextField
              {...params}
              placeholder='Select an item'
              sx={{
                marginTop: 0,
                border: 'none',
                background: 'transparent!important',
                '.MuiInputBase-root': {
                  background: 'transparent!important',
                  borderColor: 'transparent!important',
                  paddingY: 0,
                  marginTop: 0,
                },
              }}
            />}
          sx={{
            width: 1,
            background: 'transparent!important',
            border: 'none',
            '.MuiInputBase-root': {
              background: 'transparent!important',
              border: 'none',
            },
          }}
          value={valueItem.dropdownValue}
        />}

        {props.fieldType === 'Status' &&
        <EditDropdownField
          colors={props.fieldColors}
          handleValueChange={value => {
            props.handleFieldChange({ ...valueItem, dropdownValue: value ?? '' })
            setValue({ ...valueItem, dropdownValue: value ?? '' })
          }}
          options={props.dropdownOptions}
          statusName={valueItem.dropdownValue}
        />}

        {props.fieldType === 'Rating' &&
        <Stack
          direction='column'
          paddingY={1}
        >
          <Rating
            onChange={(event, value) => {
              props.handleFieldChange({ ...valueItem, ratingValue: value ?? 0 })
              setValue({ ...valueItem, ratingValue: value ?? 0 })
            }}
            onClick={event => event.stopPropagation()}
            size='small'
            value={valueItem.ratingValue}
          />
        </Stack>}

        {props.fieldType === 'People' &&
        <Stack width={1}>
          {!loading
            ? <Autocomplete
              fullWidth
              getOptionLabel={option => option.firstName
                ? `${option.firstName} ${option.lastName}`
                : `${option.email}`}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              multiple
              onChange={(_event, newValue) => handlePeopleChange(newValue)}
              options={props.members}
              renderInput={params =>
                <TextField
                  {...params}
                  placeholder='Select people'
                  sx={{
                    marginTop: 0,
                    background: 'transparent!important',
                    border: 'none',
                  }}
                />}
              renderOption={(renderProps, option) =>
                <Box component='li' {...renderProps} >
                  <Stack alignItems='center' direction='row' spacing={1}>
                    <Avatar
                      src={option.profilePictureUrl}
                      sx={{ height: 24, width: 24 }}
                    />
                    <Typography
                      sx={{ color: theme => theme.palette.text.secondary }}
                      variant='body2'
                    >
                      {option.firstName
                        ? `${option.firstName} ${option.lastName}`
                        : `${option.email}`}
                    </Typography>
                  </Stack>
                </Box>}
              renderTags={(value: Account[], getTagProps: AutocompleteRenderGetTagProps) =>
                value.map((option: Account, index: number) =>
                  <Chip
                    avatar={<Avatar src={option.profilePictureUrl} />}
                    key={option.id}
                    label={`${option.firstName} ${option.lastName}`}
                    onDelete={getTagProps({ index }).onDelete}
                    sx={{ margin: 0.5 }}
                    variant='subtle'
                  />)}
              sx={{
                marginTop: 0,
                minHeight: 42,
                background: 'transparent!important',
                border: 'none',
                '.MuiInputBase-root': {
                  background: 'transparent!important',
                  border: 'none',
                },
              }}
              value={people}
            />
            : <TextField
              fullWidth
              placeholder='Select people'
              sx={{
                marginTop: 0,
                background: 'transparent!important',
                border: 'none',
                '.MuiInputBase-root': {
                  background: 'transparent!important',
                  border: 'none',
                },
              }}
            />}
        </Stack>}
      </TableCell>
    </>
  )
}

export default EditCustomFieldDrawer
