/* eslint-disable react/forbid-component-props */
import type { BoxProps, IconButtonProps, TooltipProps } from '@mui/material'
import { Box, IconButton } from '@mui/material'

import type { IconSize, IconType } from './iconType'
type IconPickerItemProps = {
  icon: IconType
  onClick: (icon: IconType) => void
  iconListContainerProps?: BoxProps
  iconTooltipProps?: TooltipProps
  iconButtonProps?: IconButtonProps
  iconListIconSize?: IconSize
}

export const IconPickerItem = ({
  icon,
  onClick,
  iconListContainerProps,
  iconButtonProps,
  iconListIconSize,
}: IconPickerItemProps) =>
  <Box sx={{ display: 'inline-block', margin: 1.5 }} {...iconListContainerProps}>
    <IconButton
      color='default'
      onClick={() => onClick(icon)}
      size='large'
      sx={{
        width: 56,
        height: 56,
      }}
      {...iconButtonProps}
    >
      <i
        className={`fa ${icon} fa-${iconListIconSize}x`}
        style={{
          fontSize: '2rem',
        }}
      />
    </IconButton>
  </Box>

export default IconPickerItem
