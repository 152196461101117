import axios from 'axios'

import type { CurrencyDto, PaymentObjectDto, PayoutMethodDto, PayoutUser, RequirementsBankDto, WisePaymentMethodObjectDto, WisePaymentObjectDto, WiseQuoteResponseDto } from 'src/models/Payout'
import { Currency, PaymentObject, PayoutMethod, RequirementsBank, WisePaymentMethodObject, WisePaymentObject, WiseQuoteResponse } from 'src/models/Payout'

const PATH = '/payout' as const

export const getCurrencies = () =>
  axios.get<CurrencyDto[]>(`${PATH}/currencies`)
    .then(result => result.data.map(dto => new Currency(dto)))

export const getBankInfo = (currency: string) =>
  axios.get<RequirementsBankDto[]>(`${PATH}/details/${currency}`)
    .then(result => result.data.map(dto => new RequirementsBank(dto)))

export const getRefreshedBankInfo = (currency: string, payoutUser: PayoutUser) =>
  axios.post<RequirementsBankDto[]>(`${PATH}/details/${currency}`, payoutUser)
    .then(result => result.data.map(dto => new RequirementsBank(dto)))

export const addPaymentMethod = (payoutUser: PayoutUser, name: string) =>
  axios.post<PayoutMethodDto>(`${PATH}/new/${name}`, payoutUser)
    .then(result => new PayoutMethod(result.data))

export const getPaymentMethods = () =>
  axios.get<PayoutMethodDto[]>(`${PATH}/`)
    .then(result => result.data.map(dto => new PayoutMethod(dto)))

export const removePaymentMethod = (wiseId: string) =>
  axios.delete<string>(`${PATH}/${wiseId}`)
    .then(result => result.data)

export const requestWithdrawFundsOrganisation = (wiseId: string, amount: number) =>
  axios.post<WiseQuoteResponseDto>(`${PATH}/withdraw/${wiseId}/${amount}`)
    .then(result => new WiseQuoteResponse(result.data))

export const completeWithdrawFundsOrganisation = (wiseId: string, quoteId: string) =>
  axios.post<boolean>(`${PATH}/confirmWithdraw/${wiseId}/${quoteId}`)
    .then(result => result.data)

export const getPayments = () =>
  axios.get<PaymentObjectDto[]>(`${PATH}/transactions`)
    .then(result => result.data.map(dto => new PaymentObject(dto)))

export const getWiseTerms = () =>
  axios.get<string>(`${PATH}/wiseTos`)
    .then(result => String(result.data))

export const getPaymentStatus = (id: string) =>
  axios.get<WisePaymentObjectDto>(`${PATH}/transfer/${id}`)
    .then(result => new WisePaymentObject(result.data))

export const getPaymentMethodStatus = (id: string) =>
  axios.get<WisePaymentMethodObjectDto>(`${PATH}/method-description/${id}`)
    .then(result => new WisePaymentMethodObject(result.data))
