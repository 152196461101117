import CreateRoundedIcon from '@mui/icons-material/CreateRounded'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import { Alert, Avatar, Button, Chip, IconButton, Snackbar, Stack, TableCell, TableRow, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'

import DeleteUserModal from 'src/components/pages/Settings/Team/TeamTabs/TeamTabsComponents/DeactivateUserModal'
import EditUserModal from 'src/components/pages/Settings/Team/TeamTabs/TeamTabsComponents/EditUserModal'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { Seat } from 'src/models/Organisation'
import { Permissions } from 'src/models/Organisation'

const UserListItem = (props: { seat?: Seat }) => {
  const themeColors = useTheme()
  const matches = useMediaQuery(themeColors.breakpoints.down('md'))

  const { currentAccount, currentAccountPermissions } = useAuth()
  const [editMemberModal, setEditMemberModalOpen] = useState(false)
  const [deactivateMemberModal, setDeactivateMemberModalOpen] = useState(false)
  const [linkCopiedToast, setLinkCopiedToastOpen] = useState(false)

  const handleCloseToast = (event: Event | React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setLinkCopiedToastOpen(false)
  }

  const copyLink = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    event.preventDefault()
    setLinkCopiedToastOpen(true)
    void navigator.clipboard.writeText(props.seat?.account.email ?? '')
  }

  return (
    <>
      <Snackbar
        autoHideDuration={5000}
        message='Email Copied'
        onClose={handleCloseToast}
        open={linkCopiedToast}
      >
        <Alert
          onClose={handleCloseToast}
          severity='success'
          sx={{ width: '100%' }}
          variant='filled'
        >
          Email copied
        </Alert>
      </Snackbar>
      <TableRow
        key='test'
        sx={{
          '.MuiTableCell-root': { borderBottomColor: 'divider' },
        }}
      >
        <TableCell sx={{ paddingY: 2 }} >
          <Stack alignItems='center' direction='row' spacing={2}>
            {!matches &&
            <Avatar
              src={props.seat?.account.profilePictureUrl}
            />}
            <Typography
              textAlign='left'
              variant='body2'
            >
              {props.seat?.account.firstName
                ? `${props.seat.account.firstName} ${props.seat.account.lastName}`
                : 'Pending'}
            </Typography>
          </Stack>
        </TableCell>
        {!matches && <>
          <TableCell >
            <Tooltip title='Copy email'>
              <Button
                color='secondary'
                onClick={copyLink}
                sx={{
                  textAlign: 'left',
                }}
                variant='text'
              >
                <Typography textAlign='left' variant='body2'>
                  {props.seat?.account.email}
                </Typography>
              </Button>
            </Tooltip>
          </TableCell>
          <TableCell >
            <Typography
              sx={{
                textTransform: 'capitalize',
              }}
              textAlign='left'
              variant='body2'
            >
              {props.seat?.role.name.toLowerCase()}
            </Typography>
          </TableCell>
        </>}
        <TableCell>
          {props.seat?.account.firstName
            ? <Chip
              color='success'
              label='Active'
              sx={{ textTransform: 'capitalize' }}
              variant='filled'
            />
            : <Chip
              color='warning'
              label='Pending'
              sx={{ textTransform: 'capitalize' }}
              variant='filled'
            />}
        </TableCell>
        <TableCell >
          <Stack alignItems='center' direction='row' spacing={1}>
            <Tooltip title='Edit'>
              <IconButton
                disabled={props.seat?.role.name === 'Owner' ||
                !currentAccountPermissions?.includes(Permissions.ADD_REMOVE_USERS)}
                onClick={() => setEditMemberModalOpen(true)}
              >
                <CreateRoundedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Remove user'>
              <IconButton
                color='error'
                disabled={props.seat?.role.name === 'Owner' ||
                !currentAccountPermissions?.includes(Permissions.ADD_REMOVE_USERS) ||
                props.seat?.account.id === currentAccount.id}
                onClick={() => setDeactivateMemberModalOpen(true)}
              >
                <DeleteRoundedIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        </TableCell>
        {currentAccountPermissions?.includes(Permissions.ADD_REMOVE_USERS) && props.seat?.role.name !== 'Owner' &&
        <EditUserModal
          close={() => setEditMemberModalOpen(false)}
          open={editMemberModal}
          user={props.seat}
        />}
        {currentAccountPermissions?.includes(Permissions.ADD_REMOVE_USERS) && props.seat?.role.name !== 'Owner' &&
        <DeleteUserModal
          close={() => setDeactivateMemberModalOpen(false)}
          open={deactivateMemberModal}
          user={props.seat}
        />}
      </TableRow>
    </>
  )
}

export default UserListItem
