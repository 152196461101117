/* eslint-disable max-lines */
import { v4 } from 'uuid'

import type { BioLinkListItem, ServicesList, SocialList } from 'src/models/Marketing'

export const SmartLinkInput: ServicesList[] = [
  { label: 'Spotify', placeholder: 'Spotify link', value: '', id: v4(), customTitle: '' },
  { label: 'Apple Music', placeholder: 'Apple Music link', value: '', id: v4(), customTitle: '' },
  { label: 'Deezer', placeholder: 'Deezer link', value: '', id: v4(), customTitle: '' },
  { label: 'iTunes', placeholder: 'iTunes link', value: '', id: v4(), customTitle: '' },
  { label: 'Tidal', placeholder: 'Tidal link', value: '', id: v4(), customTitle: '' },
  { label: 'Amazon Music', placeholder: 'Amazon Music link', value: '', id: v4(), customTitle: '' },
  { label: 'YouTube', placeholder: 'YouTube link', value: '', id: v4(), customTitle: '' },
  { label: 'Pandora', placeholder: 'Pandora link', value: '', id: v4(), customTitle: '' },
  { label: 'Audiomack', placeholder: 'Audiomack link', value: '', id: v4(), customTitle: '' },
  { label: 'Anghami', placeholder: 'Anghami link', value: '', id: v4(), customTitle: '' },
  { label: 'iHeartRadio', placeholder: 'iHeartRadio link', value: '', id: v4(), customTitle: '' },
  { label: 'Soundcloud', placeholder: 'Soundcloud link', value: '', id: v4(), customTitle: '' },
  { label: 'Vevo', placeholder: 'Vevo link', value: '', id: v4(), customTitle: '' },
  { label: 'Joox', placeholder: 'Joox link', value: '', id: v4(), customTitle: '' },
  { label: 'KKBox', placeholder: 'KKBox link', value: '', id: v4(), customTitle: '' },
  { label: 'Napster', placeholder: 'Napster link', value: '', id: v4(), customTitle: '' },
  { label: 'YouTube Music', placeholder: 'YouTube Music link', value: '', id: v4(), customTitle: '' },
  { label: 'Beatport', placeholder: 'Beatport link', value: '', id: v4(), customTitle: '' },
  { label: 'Bandcamp', placeholder: 'Bandcamp link', value: '', id: v4(), customTitle: '' },
  { label: 'Bugs!', placeholder: 'Bugs link', value: '', id: v4(), customTitle: '' },
  { label: 'Claro-musica', placeholder: 'Claro-musica link', value: '', id: v4(), customTitle: '' },
  { label: 'Saavn', placeholder: 'Saavn link', value: '', id: v4(), customTitle: '' },
  { label: 'Netease', placeholder: 'Netease link', value: '', id: v4(), customTitle: '' },
  { label: 'eMUSIC', placeholder: 'eMUSIC link', value: '', id: v4(), customTitle: '' },
  { label: 'AWA', placeholder: 'AWA link', value: '', id: v4(), customTitle: '' },
  { label: 'Tencent', placeholder: 'Tencent link', value: '', id: v4(), customTitle: '' },
  { label: 'Qobuz', placeholder: 'Qobuz link', value: '', id: v4(), customTitle: '' },
  { label: 'Kanjian', placeholder: 'Kanjian link', value: '', id: v4(), customTitle: '' },
  { label: 'Naver', placeholder: 'Naver link', value: '', id: v4(), customTitle: '' },
  { label: 'Melon', placeholder: 'Melon link', value: '', id: v4(), customTitle: '' },
  { label: 'FLO', placeholder: 'FLO link', value: '', id: v4(), customTitle: '' },
  { label: 'Bandsintown', placeholder: 'Bandsintown link', value: '', id: v4(), customTitle: '' },
  { label: 'Songkick', placeholder: 'Songkick link', value: '', id: v4(), customTitle: '' },
  { label: 'Ticketmaster', placeholder: 'Ticketmaster link', value: '', id: v4(), customTitle: '' },
  { label: 'Download', placeholder: 'Download link', value: '', id: v4(), customTitle: '' },
  { label: 'Website', placeholder: 'Website link', value: '', id: v4(), customTitle: '' },
]

export const SmartLinkSocial: SocialList[] = [
  { label: 'Facebook', value: '' },
  { label: 'Twitter', value: '' },
  { label: 'Instagram', value: '' },
  { label: 'TikTok', value: '' },
  { label: 'Discord', value: '' },
  { label: 'Twitch',  value: '' },
  { label: 'YouTube', value: '' },
  { label: 'Tumblr', value: '' },
  { label: 'VK', value: '' },
  { label: 'Snapchat', value: '' },
  { label: 'WeChat', value: '' },
  { label: 'Weibo', value: '' },
  { label: 'QQ', value: '' },
  { label: 'Qzone', value: '' },
]

export const possibleUrls = [
  { label: 'Spotify', placeholder: 'Spotify link', value: 'open.spotify.com', weight: 1 },
  { label: 'Apple Music', placeholder: 'Apple Music link', value: 'app=music', weight: 2 },
  { label: 'Deezer', placeholder: 'Deezer link', value: 'www.deezer.com', weight: 3 },
  { label: 'iTunes', placeholder: 'iTunes link', value: 'app=itunes', weight: 4 },
  { label: 'Tidal', placeholder: 'Tidal link', value: 'listen.tidal.com', weight: 5 },
  { label: 'Amazon Music', placeholder: 'Amazon Music link', value: 'music.amazon.com', weight: 6 },
  { label: 'YouTube', placeholder: 'YouTube link', value: 'www.youtube.com', weight: 7 },
  { label: 'Pandora', placeholder: 'Pandora link', value: 'www.pandora.com', weight: 8 },
  { label: 'Anghami', placeholder: 'Anghami link', value: 'play.anghami.com', weight: 9 },
  { label: 'Napster', placeholder: 'Napster link', value: 'play.napster.com', weight: 10 },
  { label: 'YouTube Music', placeholder: 'YouTube Music link', value: 'music.youtube.com', weight: 11 },
]

export const BioLinkInput: BioLinkListItem[] = [
  {
    id: v4(),
    blockType: 'link',
    linkDestinationUrl: '',
    linkPrimaryText: 'My link',
    linkSecondaryText: '',
    videoEmbedUrl: '',
    imageUrl: '',
    textContent: '',
    socialIcons: [] as SocialList[],
    header: false,
    embedHtml: '',
    active: true,
    icon: '',
  },
  {
    id: v4(),
    blockType: 'card_block',
    linkDestinationUrl: '',
    linkPrimaryText: 'This is a large button',
    linkSecondaryText: 'A very large button',
    videoEmbedUrl: '',
    imageUrl: '',
    textContent: '',
    socialIcons: [] as SocialList[],
    header: false,
    embedHtml: '',
    active: true,
    icon: '',
  },
  {
    id: v4(),
    blockType: 'left_divider',
    linkDestinationUrl: '',
    linkPrimaryText: '',
    linkSecondaryText: '',
    videoEmbedUrl: '',
    imageUrl: '',
    textContent: 'Divider',
    socialIcons: [] as SocialList[],
    header: false,
    embedHtml: '',
    active: true,
    icon: '',
  },
  {
    id: v4(),
    blockType: 'header',
    linkDestinationUrl: '',
    linkPrimaryText: '',
    linkSecondaryText: '',
    videoEmbedUrl: '',
    imageUrl: '',
    textContent: 'H1',
    socialIcons: [] as SocialList[],
    header: false,
    embedHtml: '',
    active: true,
    icon: '',
  },
  {
    id: v4(),
    blockType: 'text',
    linkDestinationUrl: '',
    linkPrimaryText: '',
    linkSecondaryText: '',
    videoEmbedUrl: '',
    imageUrl: '',
    textContent: 'This is some text',
    socialIcons: [] as SocialList[],
    header: false,
    embedHtml: '',
    active: true,
    icon: '',
  },
  {
    id: v4(),
    blockType: 'chat',
    linkDestinationUrl: '',
    linkPrimaryText: '',
    linkSecondaryText: '',
    videoEmbedUrl: '',
    imageUrl: '',
    textContent: 'This is a personal message',
    socialIcons: [] as SocialList[],
    header: false,
    embedHtml: '',
    active: true,
    icon: '',
  },
  {
    id: v4(),
    blockType: 'image',
    linkDestinationUrl: '',
    linkPrimaryText: '',
    linkSecondaryText: '',
    videoEmbedUrl: '',
    imageUrl: '',
    textContent: '',
    socialIcons: [] as SocialList[],
    header: false,
    embedHtml: '',
    active: true,
    icon: '',
  },
  {
    id: v4(),
    blockType: 'next_release',
    linkDestinationUrl: '',
    linkPrimaryText: '',
    linkSecondaryText: '',
    videoEmbedUrl: '',
    imageUrl: '',
    textContent: '',
    socialIcons: [] as SocialList[],
    header: false,
    embedHtml: '',
    active: true,
    icon: '',
  },
  {
    id: v4(),
    blockType: 'latest_release',
    linkDestinationUrl: '',
    linkPrimaryText: '',
    linkSecondaryText: '',
    videoEmbedUrl: '',
    imageUrl: '',
    textContent: '',
    socialIcons: [] as SocialList[],
    header: false,
    embedHtml: '',
    active: true,
    icon: '',
  },
  {
    id: v4(),
    blockType: 'bandsintown',
    linkDestinationUrl: '',
    linkPrimaryText: '',
    linkSecondaryText: '',
    videoEmbedUrl: '',
    imageUrl: '',
    textContent: '',
    socialIcons: [] as SocialList[],
    header: false,
    embedHtml: '',
    active: true,
    icon: '',
  },
  {
    id: v4(),
    blockType: 'seated',
    linkDestinationUrl: '',
    linkPrimaryText: '',
    linkSecondaryText: '',
    videoEmbedUrl: '',
    imageUrl: '',
    textContent: '',
    socialIcons: [] as SocialList[],
    header: false,
    embedHtml: '',
    active: true,
    icon: '',
  },
  {
    id: v4(),
    blockType: 'facebook',
    linkDestinationUrl: '',
    linkPrimaryText: '',
    linkSecondaryText: '',
    videoEmbedUrl: '',
    imageUrl: '',
    textContent: '',
    socialIcons: [] as SocialList[],
    header: false,
    embedHtml: '',
    active: true,
    icon: '',
  },
  {
    id: v4(),
    blockType: 'twitter',
    linkDestinationUrl: '',
    linkPrimaryText: '',
    linkSecondaryText: '',
    videoEmbedUrl: '',
    imageUrl: '',
    textContent: '',
    socialIcons: [] as SocialList[],
    header: false,
    embedHtml: '',
    active: true,
    icon: '',
  },
  {
    id: v4(),
    blockType: 'youtube',
    linkDestinationUrl: '',
    linkPrimaryText: '',
    linkSecondaryText: '',
    videoEmbedUrl: '',
    imageUrl: '',
    textContent: '',
    socialIcons: [] as SocialList[],
    header: false,
    embedHtml: '',
    active: true,
    icon: '',
  },
  {
    id: v4(),
    blockType: 'daily_motion',
    linkDestinationUrl: '',
    linkPrimaryText: '',
    linkSecondaryText: '',
    videoEmbedUrl: '',
    imageUrl: '',
    textContent: '',
    socialIcons: [] as SocialList[],
    header: false,
    embedHtml: '',
    active: true,
    icon: '',
  },
  {
    id: v4(),
    blockType: 'vevo',
    linkDestinationUrl: '',
    linkPrimaryText: '',
    linkSecondaryText: '',
    videoEmbedUrl: '',
    imageUrl: '',
    textContent: '',
    socialIcons: [] as SocialList[],
    header: false,
    embedHtml: '',
    active: true,
    icon: '',
  },
  {
    id: v4(),
    blockType: 'vimeo',
    linkDestinationUrl: '',
    linkPrimaryText: '',
    linkSecondaryText: '',
    videoEmbedUrl: '',
    imageUrl: '',
    textContent: '',
    socialIcons: [] as SocialList[],
    header: false,
    embedHtml: '',
    active: true,
    icon: '',
  },
  {
    id: v4(),
    blockType: 'twitch',
    linkDestinationUrl: '',
    linkPrimaryText: '',
    linkSecondaryText: '',
    videoEmbedUrl: '',
    imageUrl: '',
    textContent: '',
    socialIcons: [] as SocialList[],
    header: false,
    embedHtml: '',
    active: true,
    icon: '',
  },
  {
    id: v4(),
    blockType: 'spotify',
    linkDestinationUrl: '',
    linkPrimaryText: '',
    linkSecondaryText: '',
    videoEmbedUrl: '',
    imageUrl: '',
    textContent: '',
    socialIcons: [] as SocialList[],
    header: false,
    embedHtml: '',
    active: true,
    icon: '',
  },
  {
    id: v4(),
    blockType: 'audiomack',
    linkDestinationUrl: '',
    linkPrimaryText: '',
    linkSecondaryText: '',
    videoEmbedUrl: '',
    imageUrl: '',
    textContent: '',
    socialIcons: [] as SocialList[],
    header: false,
    embedHtml: '',
    active: true,
    icon: '',
  },
  {
    id: v4(),
    blockType: 'soundcloud',
    linkDestinationUrl: '',
    linkPrimaryText: '',
    linkSecondaryText: '',
    videoEmbedUrl: '',
    imageUrl: '',
    textContent: '',
    socialIcons: [] as SocialList[],
    header: false,
    embedHtml: '',
    active: true,
    icon: '',
  },
  {
    id: v4(),
    blockType: 'instagram',
    linkDestinationUrl: '',
    linkPrimaryText: '',
    linkSecondaryText: '',
    videoEmbedUrl: '',
    imageUrl: '',
    textContent: '',
    socialIcons: [] as SocialList[],
    header: false,
    embedHtml: '',
    active: true,
    icon: '',
  },
  {
    id: v4(),
    blockType: 'threads',
    linkDestinationUrl: '',
    linkPrimaryText: '',
    linkSecondaryText: '',
    videoEmbedUrl: '',
    imageUrl: '',
    textContent: '',
    socialIcons: [] as SocialList[],
    header: false,
    embedHtml: '',
    active: true,
    icon: '',
  },
  {
    id: v4(),
    blockType: 'snapchat',
    linkDestinationUrl: '',
    linkPrimaryText: '',
    linkSecondaryText: '',
    videoEmbedUrl: '',
    imageUrl: '',
    textContent: '',
    socialIcons: [] as SocialList[],
    header: false,
    embedHtml: '',
    active: true,
    icon: '',
  },
  {
    id: v4(),
    blockType: 'tiktok',
    linkDestinationUrl: '',
    linkPrimaryText: '',
    linkSecondaryText: '',
    videoEmbedUrl: '',
    imageUrl: '',
    textContent: '',
    socialIcons: [] as SocialList[],
    header: false,
    embedHtml: '',
    active: true,
    icon: '',
  },
  {
    id: v4(),
    blockType: 'video_embed',
    linkDestinationUrl: '',
    linkPrimaryText: '',
    linkSecondaryText: '',
    videoEmbedUrl: '',
    imageUrl: '',
    textContent: '',
    socialIcons: [] as SocialList[],
    header: false,
    embedHtml: '',
    active: true,
    icon: '',
  },
]
