/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, Checkbox, FormControlLabel, FormGroup, Grid, Stack, TextField, Typography, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'

type Props = {
  readonly handleDowngrade: () => void
  readonly reason: string
  readonly setReason: React.Dispatch<React.SetStateAction<string>>
  readonly setAdditionalReason: React.Dispatch<React.SetStateAction<string[]>>
}

const distributionText = 'Distribution was too complicated'
const splitsText = 'Splits were too complicated to set up'
const marketingText = 'Pre-saves and Smart Links were too complicated'
const rightsText = 'Publishing and Neighboring rights were too complicated'
const analyticsText = 'Analytics were too complicated to understand'
const recoupsText = 'Could not figure out royalty recoups'
const planningText = 'Could not figure out release planning'

const DifficultyConfirm = (props: Props) => {
  const themeItem = useTheme()
  const [distribution, setDistribution] = useState(false)
  const [splits, setSplits] = useState(false)
  const [marketing, setMarketing] = useState(false)
  const [rights, setRights] = useState(false)
  const [analytics, setAnalytics] = useState(false)
  const [recoups, setRecoups] = useState(false)
  const [planning, setPlanning] = useState(false)

  useEffect(() => {
    if (!distribution) {
      props.setAdditionalReason(old => old.filter(name => name.includes(distributionText)))
    } else {
      props.setAdditionalReason(old => [...old, distributionText])
    }
  }, [distribution])

  useEffect(() => {
    if (!splits) {
      props.setAdditionalReason(old => old.filter(name => name.includes(splitsText)))
    } else {
      props.setAdditionalReason(old => [...old, splitsText])
    }
  }, [splits])

  useEffect(() => {
    if (!marketing) {
      props.setAdditionalReason(old => old.filter(name => name.includes(marketingText)))
    } else {
      props.setAdditionalReason(old => [...old, marketingText])
    }
  }, [marketing])

  useEffect(() => {
    if (!rights) {
      props.setAdditionalReason(old => old.filter(name => name.includes(rightsText)))
    } else {
      props.setAdditionalReason(old => [...old, rightsText])
    }
  }, [rights])

  useEffect(() => {
    if (!analytics) {
      props.setAdditionalReason(old => old.filter(name => name.includes(analyticsText)))
    } else {
      props.setAdditionalReason(old => [...old, analyticsText])
    }
  }, [analytics])

  useEffect(() => {
    if (!recoups) {
      props.setAdditionalReason(old => old.filter(name => name.includes(recoupsText)))
    } else {
      props.setAdditionalReason(old => [...old, recoupsText])
    }
  }, [recoups])

  useEffect(() => {
    if (!planning) {
      props.setAdditionalReason(old => old.filter(name => name.includes(planningText)))
    } else {
      props.setAdditionalReason(old => [...old, planningText])
    }
  }, [planning])

  return (
    <Grid height={1} item width={1}>
      <Card elevation={0} sx={{ backgroundColor: theme => theme.palette.background.input }}>
        <Stack alignItems='flex-start' height={1} justifyContent='flex-start' padding={4} spacing={2} width={1}>
          <Stack>
            <Typography lineHeight={1.2} variant='h3'>
              Tell us more
            </Typography>
            <Typography color={themeItem.palette.text.secondary} variant='body1'>
              Were there specific aspects of our platform that you found challenging?
            </Typography>
          </Stack>

          <FormGroup sx={{ paddingBottom: 2 }}>
            <FormControlLabel
              control={<Checkbox checked={distribution} onClick={() => setDistribution(old => !old)} />}
              label={distributionText}
            />
            <FormControlLabel
              control={<Checkbox checked={splits} onClick={() => setSplits(old => !old)} />}
              label={splitsText}
            />
            <FormControlLabel
              control={<Checkbox checked={marketing} onClick={() => setMarketing(old => !old)} />}
              label={marketingText}
            />
            <FormControlLabel
              control={<Checkbox checked={rights} onClick={() => setRights(old => !old)} />}
              label={rightsText}
            />
            <FormControlLabel
              control={<Checkbox checked={analytics} onClick={() => setAnalytics(old => !old)} />}
              label={analyticsText}
            />
            <FormControlLabel
              control={<Checkbox checked={recoups} onClick={() => setRecoups(old => !old)} />}
              label={recoupsText}
            />
            <FormControlLabel
              control={<Checkbox checked={planning} onClick={() => setPlanning(old => !old)} />}
              label={planningText}
            />
          </FormGroup>

          <TextField
            fullWidth
            label='Tell us why'
            minRows={4}
            multiline
            onChange={event => props.setReason(event.target.value)}
            placeholder='Tell us more, we might reach out and give
            you an Amazon gift card for more information'
            sx={{
              marginTop: '32px!important',
              '.MuiInputBase-root': {
                backgroundColor: theme => `${theme.palette.background.default}!important`,
              },
            }}
            value={props.reason}
          />
          <Button
            color='error'
            disabled={!distribution && !splits && !marketing && !rights && !analytics && !recoups && !planning}
            onClick={() => props.handleDowngrade()}
          >
            Cancel subscription
          </Button>
        </Stack>
      </Card>
    </Grid>
  )
}

export default DifficultyConfirm
