/* eslint-disable max-lines */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/no-duplicate-string */
import { alpha, Button, Card, CardActionArea, Stack, useMediaQuery, useTheme } from '@mui/material'

type Props = {
  buttonShape: string
  updateButtonShape: (shape: string) => void
}

const EditLinkButtonShape = (props: Props) => {
  const themeColors = useTheme()
  const matches = useMediaQuery(themeColors.breakpoints.down('sm'))
  return (
    <Stack direction={matches ? 'column' : 'row'} spacing={2} width={1}>
      <Stack direction='column' spacing={2} width='fit-content'>
        <Card
          sx={{
            width: 'fit-content',
            background: 'transparent',
            borderColor: theme => props.buttonShape === 'rounded'
              ? theme.palette.primary.main
              : undefined,
          }}
          variant='outlined'
        >
          <CardActionArea
            onClick={() => props.updateButtonShape('rounded')}
          >
            <Stack
              alignItems='center'
              justifyContent='center'
              paddingBottom={1}
              paddingX={1}
              paddingY={1}
            >
              <Button
                color='inherit'
                sx={{
                  minWidth: 200,
                }}
              >
                Default
              </Button>
            </Stack>
          </CardActionArea>
        </Card>
        <Card
          sx={{
            width: 'fit-content',
            background: 'transparent',
            borderColor: theme => props.buttonShape === 'round'
              ? theme.palette.primary.main
              : undefined,
          }}
          variant='outlined'
        >
          <CardActionArea
            onClick={() => props.updateButtonShape('round')}
          >
            <Stack
              alignItems='center'
              justifyContent='center'
              paddingBottom={1}
              paddingX={1}
              paddingY={1}
            >
              <Button
                color='inherit'
                sx={{
                  minWidth: 200,
                  borderRadius: 200,
                }}
              >
                Rounded
              </Button>
            </Stack>
          </CardActionArea>
        </Card>
        <Card
          sx={{
            width: 'fit-content',
            background: 'transparent',
            borderColor: theme => props.buttonShape === 'square'
              ? theme.palette.primary.main
              : undefined,
          }}
          variant='outlined'
        >
          <CardActionArea
            onClick={() => props.updateButtonShape('square')}
          >
            <Stack
              alignItems='center'
              justifyContent='center'
              paddingBottom={1}
              paddingX={1}
              paddingY={1}
            >
              <Button
                color='inherit'
                sx={{
                  minWidth: 200,
                  borderRadius: 0,
                }}
              >
                Square
              </Button>
            </Stack>
          </CardActionArea>
        </Card>
      </Stack>
      <Stack direction='column' spacing={2} width='fit-content'>
        <Card
          sx={{
            width: 'fit-content',
            background: 'transparent',
            borderColor: theme => props.buttonShape === 'outlined-rounded'
              ? theme.palette.primary.main
              : undefined,
          }}
          variant='outlined'
        >
          <CardActionArea
            onClick={() => props.updateButtonShape('outlined-rounded')}
          >
            <Stack
              alignItems='center'
              justifyContent='center'
              paddingBottom={1}
              paddingX={1}
              paddingY={1}
            >
              <Button
                color='inherit'
                sx={{
                  minWidth: 200,
                }}
                variant='outlined'
              >
                Outlined
              </Button>
            </Stack>
          </CardActionArea>
        </Card>
        <Card
          sx={{
            width: 'fit-content',
            background: 'transparent',
            borderColor: theme => props.buttonShape === 'outlined-round'
              ? theme.palette.primary.main
              : undefined,
          }}
          variant='outlined'
        >
          <CardActionArea
            onClick={() => props.updateButtonShape('outlined-round')}
          >
            <Stack
              alignItems='center'
              justifyContent='center'
              paddingBottom={1}
              paddingX={1}
              paddingY={1}
            >
              <Button
                color='inherit'
                sx={{
                  minWidth: 200,
                  borderRadius: 200,
                }}
                variant='outlined'
              >
                Outlined Rounded
              </Button>
            </Stack>
          </CardActionArea>
        </Card>
        <Card
          sx={{
            width: 'fit-content',
            background: 'transparent',
            borderColor: theme => props.buttonShape === 'outlined-square'
              ? theme.palette.primary.main
              : undefined,
          }}
          variant='outlined'
        >
          <CardActionArea
            onClick={() => props.updateButtonShape('outlined-square')}
          >
            <Stack
              alignItems='center'
              justifyContent='center'
              paddingBottom={1}
              paddingX={1}
              paddingY={1}
            >
              <Button
                color='inherit'
                sx={{
                  minWidth: 200,
                  borderRadius: 0,
                }}
                variant='outlined'
              >
                Outlined Square
              </Button>
            </Stack>
          </CardActionArea>
        </Card>
      </Stack>
      <Stack direction='column' spacing={2} width='fit-content'>
        <Card
          sx={{
            width: 'fit-content',
            background: 'transparent',
            borderColor: theme => props.buttonShape === 'glass-rounded'
              ? theme.palette.primary.main
              : undefined,
          }}
          variant='outlined'
        >
          <CardActionArea
            onClick={() => props.updateButtonShape('glass-rounded')}
          >
            <Stack
              alignItems='center'
              justifyContent='center'
              paddingBottom={1}
              paddingX={1}
              paddingY={1}
            >
              <Button
                color='inherit'
                sx={{
                  minWidth: 200,
                  background: theme => alpha(theme.palette.text.label, 0.3),
                }}
                variant='outlined'
              >
                Glass
              </Button>
            </Stack>
          </CardActionArea>
        </Card>
        <Card
          sx={{
            width: 'fit-content',
            background: 'transparent',
            borderColor: theme => props.buttonShape === 'glass-round'
              ? theme.palette.primary.main
              : undefined,
          }}
          variant='outlined'
        >
          <CardActionArea
            onClick={() => props.updateButtonShape('glass-round')}
          >
            <Stack
              alignItems='center'
              justifyContent='center'
              paddingBottom={1}
              paddingX={1}
              paddingY={1}
            >
              <Button
                color='inherit'
                sx={{
                  minWidth: 200,
                  borderRadius: 200,
                  background: theme => alpha(theme.palette.text.label, 0.3),
                }}
                variant='outlined'
              >
                Glass Rounded
              </Button>
            </Stack>
          </CardActionArea>
        </Card>
        <Card
          sx={{
            width: 'fit-content',
            background: 'transparent',
            borderColor: theme => props.buttonShape === 'glass-square'
              ? theme.palette.primary.main
              : undefined,
          }}
          variant='outlined'
        >
          <CardActionArea
            onClick={() => props.updateButtonShape('glass-square')}
          >
            <Stack
              alignItems='center'
              justifyContent='center'
              paddingBottom={1}
              paddingX={1}
              paddingY={1}
            >
              <Button
                color='inherit'
                sx={{
                  minWidth: 200,
                  borderRadius: 0,
                  background: theme => alpha(theme.palette.text.label, 0.3),
                }}
                variant='outlined'
              >
                Glass Square
              </Button>
            </Stack>
          </CardActionArea>
        </Card>
      </Stack>
    </Stack>
  )
}

export default EditLinkButtonShape
