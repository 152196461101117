export const languageData = [
  { language: 'Afrikaans', code: 'af' },
  { language: 'Arabic', code: 'ar' },
  { language: 'Armenian', code: 'hy' },
  { language: 'Assamese', code: 'as' },
  { language: 'Azerbaijani', code: 'az' },
  { language: 'Belarusian', code: 'be' },
  { language: 'Bengali', code: 'bn' },
  { language: 'Bosnian', code: 'bs' },
  { language: 'Bulgarian', code: 'bg' },
  { language: 'Cantonese', code: 'yue' },
  { language: 'Catalan', code: 'ca' },
  { language: 'Chechen', code: 'ce' },
  { language: 'Croatian', code: 'hr' },
  { language: 'Czech', code: 'cs' },
  { language: 'Danish', code: 'da' },
  { language: 'Dutch', code: 'nl' },
  { language: 'English', code: 'en' },
  { language: 'Estonian', code: 'et' },
  { language: 'Finnish', code: 'fi' },
  { language: 'French', code: 'fr' },
  { language: 'Georgian', code: 'ka' },
  { language: 'German', code: 'de' },
  { language: 'Greek', code: 'el' },
  { language: 'Gujarati', code: 'gu' },
  { language: 'Haitian', code: 'ht' },
  { language: 'Hakka', code: 'hak' },
  { language: 'Hawaiian', code: 'haw' },
  { language: 'Hebrew', code: 'he' },
  { language: 'Hindi', code: 'hi' },
  { language: 'Hungarian', code: 'hu' },
  { language: 'Icelandic', code: 'is' },
  { language: 'Indonesian', code: 'id' },
  { language: 'Irish', code: 'ga' },
  { language: 'Italian', code: 'it' },
  { language: 'Japanese', code: 'ja' },
  { language: 'Kannada', code: 'kn' },
  { language: 'Kazakh', code: 'kk' },
  { language: 'Korean', code: 'ko' },
  { language: 'Kurdish', code: 'ku' },
  { language: 'Lao', code: 'lo' },
  { language: 'Latin', code: 'la' },
  { language: 'Latvian', code: 'lv' },
  { language: 'Lithuanian', code: 'lt' },
  { language: 'Malay', code: 'ms' },
  { language: 'Malayalam', code: 'ml' },
  { language: 'Mandarin', code: 'cmn' },
  { language: 'Maori', code: 'mi' },
  { language: 'Marathi', code: 'mr' },
  { language: 'Mongolian', code: 'mn' },
  { language: 'Nonlinguistic (none)', code: 'zxx' },
  { language: 'Norwegian', code: 'no' },
  { language: 'Odia', code: 'or' },
  { language: 'Persian', code: 'fa' },
  { language: 'Polish', code: 'pl' },
  { language: 'Portuguese', code: 'pt' },
  { language: 'Punjabi', code: 'pa' },
  { language: 'Romanian', code: 'ro' },
  { language: 'Russian', code: 'ru' },
  { language: 'Samoan', code: 'sm' },
  { language: 'Sanskrit', code: 'sa' },
  { language: 'Serbian', code: 'sr' },
  { language: 'Slovak', code: 'sk' },
  { language: 'Slovenian', code: 'sl' },
  { language: 'Spanish', code: 'es' },
  { language: 'Swedish', code: 'sv' },
  { language: 'Tagalog', code: 'tl' },
  { language: 'Tahitian', code: 'ty' },
  { language: 'Taiwanese', code: 'nan' },
  { language: 'Tamil', code: 'ta' },
  { language: 'Telugu', code: 'te' },
  { language: 'Thai', code: 'th' },
  { language: 'Tonga', code: 'to' },
  { language: 'Turkish', code: 'tr' },
  { language: 'Ukrainian', code: 'uk' },
  { language: 'Urdu', code: 'ur' },
  { language: 'Vietnamese', code: 'vi' },
  { language: 'Yiddish', code: 'yi' },
  { language: 'Zulu', code: 'zu' },
]
