import { Skeleton, Stack, TableCell, TableRow } from '@mui/material'

const TaskItemSkeleton = () =>
  <TableRow
    hover
    sx={{
      borderBottom: theme => `1px solid ${theme.palette.divider}`,
      backgroundColor: theme => theme.palette.background.default,
      textDecoration: 'none',
      '&:hover .MuiTableCell-root': {
        backgroundColor: theme => theme.palette.background.input,
        textDecoration: 'none',
      },
      width: 1,
      overflow: 'hidden',
    }}
  >
    <TableCell
      id='button'
      sx={{
        left: 0,
        padding: 0,
      }}
      width={48}
    >
      <Skeleton height={32} variant='circular' width={32} />
    </TableCell>
    <TableCell
      id='task name'
      sx={{
        left: '48px',
        width: 1,
      }}
    >
      <Skeleton variant='text' width={150} />
    </TableCell>

    <TableCell id='taskboard'>
      <Stack alignItems='center' direction='row' spacing={1} width={150}>
        <Skeleton height={24} variant='circular' width={24} />
        <Skeleton variant='text' width={75} />
      </Stack>
    </TableCell>

    <TableCell id='due date'>
      <Stack alignItems='center' direction='row' spacing={1} width={100}>
        <Skeleton variant='text' width={75} />
      </Stack>
    </TableCell>

    <TableCell id='priority' >
      <Stack alignItems='center' direction='row' spacing={1} width={100}>
        <Skeleton variant='text' width={75} />
      </Stack>
    </TableCell>
    <TableCell id='status'>
      <Stack width={100}>
        <Skeleton variant='text' width={75} />
      </Stack>
    </TableCell>
  </TableRow>

export default TaskItemSkeleton
