/* eslint-disable sonarjs/no-duplicate-string */
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded'
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded'
import { ListItem, ListItemAvatar, ListItemButton, ListItemText, Stack, Typography } from '@mui/material'
import { useLocation } from 'react-router'

import { NavLink } from 'src/components/SafeRouterLink'

type Props = {
  readonly handleMoreMenuOpen: (event: React.MouseEvent<HTMLElement>) => void
}

export const MyTasks = (props: Props) => {
  const location = useLocation()
  return (
    <Stack id='my-tasks-selector'>
      <ListItem
        component={NavLink}
        dense
        disablePadding
        to='/calendar/mytasks'
      >
        <ListItemButton
          sx={{
            borderRadius: 0,
            backgroundColor: location.pathname === '/calendar/mytasks'
              ? theme => theme.palette.primary.contrast2
              : theme => theme.palette.background.default,
          }}
        >
          <ListItemAvatar>
            <TaskAltRoundedIcon
              color='action'
              sx={{
                fontSize: 18,
              }}
            />
          </ListItemAvatar>
          <ListItemText
            primary={<Typography variant='body2'>Your Tasks</Typography>}
            sx={{ wordBreak: 'break-word' }}
          />
        </ListItemButton>
      </ListItem>
      <ListItem
        component={NavLink}
        dense
        disablePadding
        to='/calendar/mycalendar'
      >
        <ListItemButton
          sx={{
            borderRadius: 0,
            backgroundColor: location.pathname === '/calendar/mycalendar'
              ? theme => theme.palette.primary.contrast2
              : theme => theme.palette.background.default,
          }}
        >
          <ListItemAvatar>
            <CalendarMonthRoundedIcon
              color='action'
              sx={{
                fontSize: 18,
              }}
            />
          </ListItemAvatar>
          <ListItemText
            primary={<Typography variant='body2'>Your Calendar</Typography>}
            sx={{ wordBreak: 'break-word' }}
          />
        </ListItemButton>
      </ListItem>
      <ListItem
        dense
        disablePadding
        onClick={event => props.handleMoreMenuOpen(event)}
      >
        <ListItemButton
          sx={{
            borderRadius: 0,
            backgroundColor: theme => theme.palette.background.default,
          }}
        >
          <ListItemAvatar>
            <AddRoundedIcon
              color='action'
              sx={{
                fontSize: 18,
              }}
            />
          </ListItemAvatar>
          <ListItemText
            primary={<Typography variant='body2'>Add item</Typography>}
            sx={{ wordBreak: 'break-word' }}
          />
        </ListItemButton>
      </ListItem>
    </Stack>
  )
}

export default MyTasks

