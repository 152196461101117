/* eslint-disable react-hooks/exhaustive-deps */
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import DataObjectRoundedIcon from '@mui/icons-material/DataObjectRounded'
import PieChartRoundedIcon from '@mui/icons-material/PieChartRounded'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Dialog, Divider, IconButton, Stack, Tab, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'

import BulkUpdateRights from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/RecordingsTab/Components/BulkInviteRightsItems'
import BulkUpdateComponent from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/RecordingsTab/Components/BulkUpdateComponent'
import type Project from 'src/models/Project'

type Props = {
  close: () => void
  open: boolean
  project?: Project
  updateRecordings: () => Promise<void>
}

const BulkUpdateModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))
  const [currentTab, setCurrentTab] = useState('1')
  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='md'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Stack width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          paddingTop={2}
          paddingX={2}
          width={1}
        >
          <Typography variant='h3'>
            Bulk Update
          </Typography>
          <IconButton onClick={() => props.close()}>
            <CloseRoundedIcon color='disabled' />
          </IconButton>
        </Stack>

        <TabContext value={currentTab}>
          <TabList
            aria-label='current profile tab'
            onChange={(event, value: string) => setCurrentTab(value)}
            sx={{
              minHeight: 32,
              '.MuiTab-root':
                  {
                    textTransform: 'none',
                    fontWeight: 'normal',
                    fontSize: '16px',
                  },
            }}
            variant='scrollable'
          >
            <Tab
              icon={<DataObjectRoundedIcon />}
              label='Details'
              value='1'
            />
            <Tab
              icon={<PieChartRoundedIcon />}
              label='Splits'
              value='2'
            />
          </TabList>

          <Divider />

          <TabPanel sx={{ width: 1, minHeight: 600 }} value='1'>
            <BulkUpdateComponent
              close={props.close}
              project={props.project}
              updateRecordings={props.updateRecordings}
            />
          </TabPanel>

          <TabPanel sx={{ width: 1, minHeight: 600 }} value='2'>
            <BulkUpdateRights
              project={props.project}
              updateRecordings={props.updateRecordings}
            />
          </TabPanel>

        </TabContext>
      </Stack>
    </Dialog>
  )
}

export default BulkUpdateModal
