/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable react-hooks/exhaustive-deps */
import type { FC } from 'react'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import useSound from 'use-sound'

import { getNotifications } from 'src/api/accounts'
import { initializeSocket, setNotificationToRead, subscribeToNotifications } from 'src/api/webSocket'
import type { NotificationDto } from 'src/models/Notification'
import Notification from 'src/models/Notification'
import messageSound from 'src/sounds/messagesSound.mp3'

type NotificationProviderContextProps = {
  notifications: Notification[]
  fetchNotifications: () => Promise<void>
  readNotification: (notificationId: string) => void
}

export type InitProps = Omit<NotificationProviderContextProps,
'notifications'> & {
  notifications: NotificationProviderContextProps['notifications'] | []
}

const initialValue = {
  notifications: [],
} as InitProps

const NotificationContext = createContext(initialValue as NotificationProviderContextProps)

export const useNotifications = () => useContext(NotificationContext)

export const NotificationProvider: FC = props => {
  const [playMessageSound] = useSound(messageSound)

  const [notifications, setNotifications] = useState<InitProps['notifications']>([])
  const [latestNotification, setLatestNotification] = useState<Notification>()

  const getListenersWithRefresh = async () => {
    await initializeSocket()
      .then(socketItem => {
        subscribeToNotifications(socketItem, (error: unknown, data?: NotificationDto) => {
          if (error) return

          if (data) {
            setLatestNotification(new Notification(data))
          }
        })
        socketItem?.io.once('reconnect', async () => {
          socketItem?.removeAllListeners('notification')
          await getListenersWithRefresh()
        })
      })
  }

  const subscribe = async () => {
    await fetchNotifications()
      .then(() => void getListenersWithRefresh())
  }

  useEffect(() => {
    void subscribe()
  }, [])

  useEffect(() => {
    if (latestNotification) {
      playMessageSound()
      setNotifications(previousState => [latestNotification, ...previousState])
    }
  }, [latestNotification])

  const fetchNotifications = async () =>
    getNotifications().then(setNotifications)

  const readNotification = (notificationId: string) => {
    setNotificationToRead(notificationId)

    const temporaryNotificationsList = notifications
    const index = notifications.findIndex(notif => notif.id === notificationId)
    temporaryNotificationsList[index].read = true
    setNotifications(temporaryNotificationsList)
  }

  const value = useMemo<InitProps>(
    () => ({
      fetchNotifications,
      readNotification,
      notifications,
    }),
    [notifications]
  ) as NotificationProviderContextProps

  return (
    <NotificationContext.Provider value={value}>
      {props.children}
    </NotificationContext.Provider>
  )
}
