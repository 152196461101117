/* eslint-disable sonarjs/no-duplicate-string */
import { Alert, Autocomplete, Box, InputAdornment, ListItemText, Stack, TextField } from '@mui/material'

import type { Currency } from 'src/models/Payout'

type Props = {
  currencies: Currency[]
  setCurrency: (currencyItem: Currency | undefined) => void
  currency: Currency | undefined
  setName: React.Dispatch<React.SetStateAction<string>>
  name: string
}

const OnboardingCurrency = (props: Props) => {
  const changeCurrency = (currency: Currency | null) => {
    if (currency === null) {
      props.setCurrency(undefined)
    } else {
      props.setCurrency(currency)
    }
  }

  return (
    <Stack height={1} spacing={4} width={1}>
      <Alert severity='info' variant='filled'>
        Please give your payment method a name you will remember and pick the currency you wish to receive
      </Alert>
      <TextField
        label='Payment method name'
        onChange={event => props.setName(event.target.value)}
        placeholder='Payment method name'
        sx={{
          marginTop: 0,
        }}
        value={props.name}
      />
      <Autocomplete
        autoHighlight
        autoSelect
        fullWidth
        getOptionLabel={option => `${option.code} - ${option.name}`}
        onChange={(_, newValue) => changeCurrency(newValue)}
        options={props.currencies}
        renderInput={params =>
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: <InputAdornment position='start'>
                {props.currency &&
                <img
                  alt='country-flag'
                  height={22}
                  src={`https://wise.com/public-resources/assets/flags/rectangle/${props.currency.code
                    .toString()
                    .toLowerCase()}.png`}
                  width={33}
                />}
              </InputAdornment>,
            }}
            label='Currency'
            placeholder='Select a currency'
            sx={{
              marginTop: 0,
            }}
          />}
        renderOption={(renderProps, option) =>
          <Box component='li' {...renderProps} >
            <Stack alignItems='center' direction='row' spacing={1}>
              <img
                alt='country-flag'
                height={22}
                src={`https://wise.com/public-resources/assets/flags/rectangle/${option.code
                  .toString()
                  .toLowerCase()}.png`}
                width={33}
              />
              <ListItemText
                primary={option.name}
                secondary={option.code}
              />
            </Stack>
          </Box>}
        sx={{ marginTop: 0, height: 42 }}
        value={props.currency}
      />
    </Stack>
  )
}
export default OnboardingCurrency
