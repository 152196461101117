/* eslint-disable max-lines */
/* eslint-disable sonarjs/no-duplicate-string */
import { v4 as uuidv4 } from 'uuid'

import { colorSelection } from 'src/components/pages/Task/TaskMenu/TaskMenuComponents/ColorsForPicker'
import type { TaskBoardTemplate, TaskGroup, TaskItem } from 'src/models/Taskboard'

const notStarted = 'Not started'

const tasksPreRelease: TaskItem[] = [{
  id: uuidv4(),
  title: 'Finalize production',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
{
  id: uuidv4(),
  title: 'Master track',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
{
  id: uuidv4(),
  title: 'Export stems',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
{
  id: uuidv4(),
  title: 'Create artwork',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
{
  id: uuidv4(),
  title: 'Create social media posts',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
{
  id: uuidv4(),
  title: 'Create social media videos',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
{
  id: uuidv4(),
  title: 'Plan posting schedule',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
{
  id: uuidv4(),
  title: 'Assign splits to collaborators',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
{
  id: uuidv4(),
  title: 'Create release contract',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
{
  id: uuidv4(),
  title: 'Distribute album on Releese',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
]

const tasksReleaseWeek: TaskItem[] = [{
  id: uuidv4(),
  title: 'Send music to curators',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
{
  id: uuidv4(),
  title: 'Post daily on social media',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
{
  id: uuidv4(),
  title: 'Contact local radio stations',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
{
  id: uuidv4(),
  title: 'Contact press outlets',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
]

const tasksPostRelease: TaskItem[] = [{
  id: uuidv4(),
  title: 'Release event',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
{
  id: uuidv4(),
  title: 'Advertising',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
{
  id: uuidv4(),
  title: 'Email sendouts',
  assignee: [],
  startTime: '',
  dueTime: '',
  allDay: false,
  status: notStarted,
  priority: 'None',
  lastUpdated: new Date(),
  tags: [],
  description: '',
  updates: [],
  dependencies: [],
  customFields: [],
  checklist: [],
  recurring: 'None',
},
]

const taskGroupPreRelease: TaskGroup = {
  id: uuidv4(),
  tasks: tasksPreRelease,
  title: 'Pre release',
  color: colorSelection[0],
}

const taskGroupReleaseWeek: TaskGroup = {
  id: uuidv4(),
  tasks: tasksReleaseWeek,
  title: 'Release week',
  color: colorSelection[1],
}

const taskGroupPostRelease: TaskGroup = {
  id: uuidv4(),
  tasks: tasksPostRelease,
  title: 'Post release',
  color: colorSelection[2],
}

const taskGroups: TaskGroup[] = [taskGroupPreRelease, taskGroupReleaseWeek, taskGroupPostRelease]

export const taskboardMusicRelease: TaskBoardTemplate = {
  id: '',
  customFields: [],
  icon: 0,
  title: 'Music Release',
  color: colorSelection[2],
  fieldOrder: [],
  createdAt: new Date(),
  taskGroups,
  hiddenFields: [],
  description: `This template features your music creation schedule, pre and release day marketing, 
  post-release strategies.`,
}
