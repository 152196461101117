/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-lines */
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import { Avatar, AvatarGroup, Button, Chip, IconButton, Menu, Stack, TextField, Tooltip, Typography } from '@mui/material'
import type { AutocompleteRenderGetTagProps } from '@mui/material/Autocomplete'
import Autocomplete, {
  autocompleteClasses,
} from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { styled, useTheme } from '@mui/material/styles'
import { useEffect, useState } from 'react'

import type Account from 'src/models/Account'

type PopperComponentProps = {
  anchorEl?: any
  disablePortal?: boolean
  open: boolean
}

const StyledAutocompletePopper = styled('div')(() => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    margin: 0,
    borderRadius: 0,
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
  '&.MuiAutocomplete-popper': {
    width: '100%!important',
  },
}))

const PopperComponent = (props: PopperComponentProps) => {
  const { disablePortal, anchorEl, open, ...other } = props
  return <StyledAutocompletePopper {...other} />
}

type Props = {
  assignee: Account[]
  members: Account[]
  handleAssigneeChange: (event: Account[]) => void
}

const SelectAssignee = (props: Props) => {
  const [valueItems, setValue] = useState(props.assignee)
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const theme = useTheme()

  useEffect(() => {
    setValue(props.assignee)
  }, [props.assignee])

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget)
  }

  const handleClose = () => {
    if (anchorElement) {
      anchorElement.focus()
    }
    setAnchorElement(null)
  }

  const open = Boolean(anchorElement)
  const id = open ? 'github-label' : undefined

  return (
    <>
      <Typography
        sx={{
          marginTop: 0,
          width: 72,
        }}
        variant='inputLabel'
      >
        Assignees
      </Typography>
      <Stack alignItems='center' direction='row' onClick={event => handleClick(event)} spacing={1}>
        {props.assignee.length > 0 &&
        <Button
          color='inherit'
          sx={{
            padding: 1,
          }}
          variant='subtle'
        >
          <AvatarGroup max={16}>
            {props.assignee.map(item =>
              <Tooltip key={item.id} title={`${item.firstName} ${item.lastName}`}>
                <Avatar
                  src={item.profilePictureUrl}
                  sx={{
                    width: 32,
                    height: 32,
                  }}
                />
              </Tooltip>)}
          </AvatarGroup>
        </Button>}
        {props.assignee.length === 0 &&
        <IconButton
          size='medium'
          sx={{
            border: `2px dotted ${theme.palette.divider}`,
            height: 'fit-content',
            flexShrink: 0,
            marginLeft: 1,
          }}
        >
          <PersonAddIcon fontSize='small' />
        </IconButton>}
      </Stack>
      <Menu anchorEl={anchorElement} id={id} open={open}>
        <ClickAwayListener onClickAway={handleClose}>
          <Stack width={360}>
            <Typography
              sx={{
                paddingX: 1,
                paddingTop: 1,
              }}
              variant='h6'
            >
              Assignees
            </Typography>
            <Autocomplete
              PopperComponent={PopperComponent}
              disableCloseOnSelect
              getOptionLabel={option => option.firstName
                ? `${option.firstName} ${option.lastName}`
                : `${option.email}`}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              multiple
              noOptionsText='No one found'
              onChange={(event, newValue) => props.handleAssigneeChange(newValue)}
              open
              options={props.members}
              renderInput={params =>
                <TextField
                  {...params}
                  placeholder='Select people'
                  sx={{
                    marginTop: 0,
                  }}
                />}
              renderOption={(renderProps, option) =>
                <Box component='li' {...renderProps} >
                  <Stack alignItems='center' direction='row' spacing={1}>
                    <Avatar
                      src={option.profilePictureUrl}
                      sx={{ height: 24, width: 24 }}
                    />
                    <Typography
                      sx={{ color: theme.palette.text.secondary }}
                      variant='body2'
                    >
                      {option.firstName
                        ? `${option.firstName} ${option.lastName}`
                        : `${option.email}`}
                    </Typography>
                  </Stack>
                </Box>}
              renderTags={(value: Account[], getTagProps: AutocompleteRenderGetTagProps) =>
                value.map((option: Account, index: number) =>
                  <Chip
                    avatar={<Avatar src={option.profilePictureUrl} />}
                    key={option.id}
                    label={`${option.firstName}`}
                    onDelete={getTagProps({ index }).onDelete}
                    sx={{ margin: 0.2 }}
                    variant='subtle'
                  />)}
              sx={{
                padding: 1,
              }}
              value={valueItems}
            />
          </Stack>
        </ClickAwayListener>
      </Menu>
    </>
  )
}

export default SelectAssignee
