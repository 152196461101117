import { Card, CardActionArea, Radio, Stack, Typography } from '@mui/material'

import AppleMusicIcon from 'src/components/Icons/AppleMusicIcon'
type Props = {
  buttonColors: string
  updateButtonColors: (palette: string) => void
}

const EditLinkButtonColors = (props: Props) =>
  <Stack direction='row' spacing={1}>
    <Card
      elevation={0}
      sx={{
        background: 'transparent',
      }}
    >
      <CardActionArea
        onClick={() => props.updateButtonColors('color')}
      >
        <Stack
          alignItems='center'
          justifyContent='center'
          minWidth={104}
          paddingBottom={1}
          paddingTop={2}
          paddingX={2}
        >
          <Stack
            sx={{
              height: 48,
              width: 48,
              justifyContent: 'center',
              alignItems: 'center',
              background: theme => theme.palette.appleMusic,
              borderRadius: 1,
            }}
          >
            <AppleMusicIcon
              sx={{
                fontSize: '2rem',
                color: theme => theme.palette.background.default,
                background: theme => theme.palette.appleMusic,
              }}
            />
          </Stack>
          <Typography
            variant='h6'
          >
            Colorful
          </Typography>
          <Radio
            checked={props.buttonColors === 'color'}
          />
        </Stack>
      </CardActionArea>
    </Card>
    <Card
      elevation={0}
      sx={{
        background: 'transparent',
      }}
    >
      <CardActionArea
        onClick={() => props.updateButtonColors('greyscale')}
      >
        <Stack
          alignItems='center'
          justifyContent='center'
          minWidth={104}
          paddingBottom={1}
          paddingTop={2}
          paddingX={2}
        >
          <Stack
            sx={{
              height: 48,
              width: 48,
              justifyContent: 'center',
              alignItems: 'center',
              background: theme => theme.palette.text.primary,
              borderRadius: 1,
            }}
          >
            <AppleMusicIcon
              sx={{
                fontSize: '2rem',
                color: theme => theme.palette.background.default,
                background: theme => theme.palette.text.primary,
              }}
            />
          </Stack>
          <Typography
            variant='h6'
          >
            Colorless
          </Typography>
          <Radio
            checked={props.buttonColors === 'greyscale'}
          />
        </Stack>
      </CardActionArea>
    </Card>
  </Stack>

export default EditLinkButtonColors
