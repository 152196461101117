/* eslint-disable react-hooks/exhaustive-deps */
import { Stack, TableRow, Typography, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { RRule } from 'rrule'

import { getAllTaskboards } from 'src/api/taskboards'
import TaskItemDashboard from 'src/components/pages/Dashboard/Tasks/TasksComponents/TaskItem'
import TaskItemSkeleton from 'src/components/pages/Dashboard/Tasks/TasksComponents/TaskItemSkeleton'
import NewTaskModal from 'src/components/pages/Task/TaskDrawer/NewTaskModal'
import { getDayItem } from 'src/components/pages/Task/TaskViews/TaskViewTypes/Calendar'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { TaskBoard } from 'src/models/Taskboard'

const TasksCard = () => {
  const themeItem = useTheme()
  const { currentAccount, currentOrganisation } = useAuth()
  const [loading, setLoading] = useState(true)
  const [taskboards, setTaskboards] = useState<TaskBoard[]>()
  const [newTaskModal, setNewTaskModal] = useState(false)

  const flatItems = taskboards?.map(taskboard =>
    taskboard.taskGroups.map(group =>
      group.tasks.map(task => {
        if (task.recurring === 'Monthly') {
          const dateItemTest = new Date(task.startDate ?? 0).getDay()
          const eventsRules = new RRule({
            freq: RRule.WEEKLY,
            byweekday: getDayItem(dateItemTest),
            dtstart: new Date(task.startDate ?? 0),
            interval: 4,
            until: new Date('January 11, 2025'),
          })
          const times = eventsRules.all()
          const nextDate = times.find(item => item.getTime() > Date.now())
          return {
            task,
            group,
            taskboard,
            dueDate: nextDate,
          }
        } else if (task.recurring === 'Weekly') {
          const dateItemTest = new Date(task.startDate ?? 0).getDay()
          const eventsRules = new RRule({
            freq: RRule.WEEKLY,
            byweekday: getDayItem(dateItemTest),
            dtstart: new Date(task.startDate ?? 0),
            interval: 1,
            until: new Date('January 11, 2025'),
          })
          const times = eventsRules.all()
          const nextDate = times.find(item => item.getTime() > Date.now())
          return {
            task,
            group,
            taskboard,
            dueDate: nextDate,
          }
        } else {
          return {
            task,
            group,
            taskboard,
            dueDate: task.dueDate,
          }
        }
      })))
    .flat(2)
    .filter(task => task.task.assignee.includes(currentAccount.id))
    .filter(task =>
      task.task.recurring === 'Monthly' || task.task.recurring === 'Weekly' || task.task.status !== 'Done')
    .sort((a, b) => Number(new Date(a.dueDate ?? new Date())) - Number(new Date(b.dueDate ?? new Date())))

  useEffect(() => {
    setLoading(true)
    getAllTaskboards()
      .then(data => {
        setTaskboards(data)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [currentOrganisation?.id])

  return (
    <>
      <Stack height={400}>
        <Stack overflow='auto' spacing={2}>
          <Stack
            sx={{
              borderTop: theme => `1px solid ${theme.palette.divider}`,
            }}
            width={1}
          >
            {!loading && taskboards
              ? <Stack>
                <TableRow
                  hover
                  onClick={() => setNewTaskModal(true)}
                  sx={{
                    borderBottom: theme => `1px solid ${theme.palette.divider}`,
                    backgroundColor: 'transparent',
                    textDecoration: 'none',
                    '&:hover .MuiTableCell-root': {
                      backgroundColor: theme => theme.palette.background.input,
                      textDecoration: 'none',
                    },
                    '&:hover': {
                      cursor: 'pointer',
                    },
                    width: 1,
                    overflow: 'hidden',
                  }}
                >
                  <Stack padding={2} width={1}>
                    <Typography color={themeItem.palette.text.link} variant='body2'>
                      + Create a task
                    </Typography>
                  </Stack>
                </TableRow>
                {flatItems?.map(task =>
                  <TaskItemDashboard
                    key={task.task.id}
                    taskColumn={task.group}
                    taskDataSet={task.task}
                    taskboard={task.taskboard}
                  />)}
              </Stack>
              : <Stack direction='column'>
                <TaskItemSkeleton />
                <TaskItemSkeleton />
                <TaskItemSkeleton />
                <TaskItemSkeleton />
              </Stack>}
          </Stack>
        </Stack>
      </Stack>
      {newTaskModal &&
      <NewTaskModal
        close={() => setNewTaskModal(false)}
        open={newTaskModal}
      />}
    </>
  )
}

export default TasksCard
