import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'
import TagManager from 'react-gtm-module'
import { useNavigate } from 'react-router'

import { onboardingEvent } from 'src/api/organisation'
import BillingModal from 'src/components/pages/Account/Billing/BillingModal'
import SetupCard from 'src/components/pages/Account/InitialSetup/InitialSetupComponents/SetupCard'
import ConfirmFreeModal from 'src/components/pages/Account/InitialSetup/InitialSetupModals/ConfirmFreeModal'
import { useAuth } from 'src/components/providers/AuthProvider'

type Props = {
  readonly isArtist: boolean
}

const ClientPersona = (props: Props) => {
  const themeColors = useTheme()
  const matches = useMediaQuery(themeColors.breakpoints.down('md'))
  const navigate = useNavigate()
  const { currentOrganisation } = useAuth()

  const [openPaymentModal, setOpenPaymentModal] = useState(false)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)

  const handleSelection = () => {
    void onboardingEvent('Complete Sign up')
    TagManager.dataLayer({
      dataLayer: {
        event: 'open_billing_signup',
        userId: currentOrganisation?.id,
      },
    })
    setOpenPaymentModal(true)
  }

  const handleFormSubmitFree = () => {
    void onboardingEvent('Complete Sign up')
    TagManager.dataLayer({
      dataLayer: {
        event: 'complete_signup_collaborator',
        userId: currentOrganisation?.id,
      },
    })
    navigate({ pathname: '/', search: document.location.search })
  }

  return (
    <>
      {currentOrganisation && openPaymentModal &&
      <BillingModal
        account={currentOrganisation}
        close={() => setOpenPaymentModal(false)}
        open={openPaymentModal}
      />}
      <ConfirmFreeModal
        close={() => setOpenConfirmModal(false)}
        handleFormSubmitFree={handleFormSubmitFree}
        handleSelection={handleSelection}
        open={openConfirmModal}
      />
      <Stack
        justifyContent='center'
        maxWidth='md'
        sx={{
          border: 'none',
          width: 1,
        }}
      >
        <Stack
          alignItems='flex-start'
          height={1}
          spacing={4}
          width={1}
        >
          <Stack
            marginX='auto'
            width={1}
          >
            <Stack alignItems='flex-start' direction='column' width={1}>
              <Stack alignItems='flex-start' direction='column' spacing={6} width={1}>
                <Stack direction='column'>
                  <Typography textAlign='left' variant='h2'>
                    Pick your experience
                  </Typography>
                  <Typography color={themeColors.palette.text.secondary} textAlign='left' variant='body1' >
                    You can start your free trial today, if you choose not to you can always start one in the future.
                  </Typography>
                </Stack>
                <Stack
                  direction={matches
                    ? 'column-reverse'
                    : 'row'}
                  spacing={2}
                  width={1}
                >
                  <SetupCard
                    features={[
                      'Claim royalties',
                      'Fast payouts',
                      'See how Releese works',
                      'No credit card required',
                    ]}
                    featuresCallout='Get paid for your collaborations'
                    free
                    handleSelection={() => setOpenConfirmModal(true)}
                    text='Work with collaborators when they invite you'
                    title='Collaborator'
                  />
                  <SetupCard
                    features={[
                      'Unlimited distribution',
                      'Editorial playlist pitching',
                      'Pre-saves and pre-save Subscriptions',
                      'Royalty splits',
                      'Playlists and Charts tracking',
                      'Social media analytics',
                      'Royalty recoups',
                    ]}
                    featuresCallout={props.isArtist
                      ? 'Tools to manage your career from one place.'
                      : 'Automation tools for labels who want to scale.'}
                    free={false}
                    handleSelection={handleSelection}
                    text='Try every Releese feature for free for 14 days.'
                    title=' Releese Trial'
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}

export default ClientPersona
