/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-component-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Divider } from '@mui/material'
import type { PlateRenderElementProps, TElement } from '@udecode/plate'
import { createPluginFactory } from '@udecode/plate'

import type { MyValue } from 'src/components/pages/Notes/Modern/typescript/plateTypes'

export const ELEMENT_DIVIDER = 'divider'

export const DividerElement = (props: PlateRenderElementProps<MyValue, TElement>) =>
  <div {...props.attributes}>
    <div contentEditable={false} style={{ position: 'relative', userSelect: 'none' }}>
      <Divider
        {...props.attributes}
        {...props.nodeProps}
        className={props.className}
      />
      {props.children}
    </div>
  </div>

export const createDividerlugin = createPluginFactory({
  key: ELEMENT_DIVIDER,
  isElement: true,
  component: DividerElement,
})
