import { Stack, Typography } from '@mui/material'

import UpdateRecordingItem from 'src/components/pages/Projects/UpdateModal/DistributeModalComponents/UpdateRecordingItem'
import type Project from 'src/models/Project'
import type Recording from 'src/models/Recording'

type Props = {
  recordings: Recording[]
  project: Project
  setRecordings: React.Dispatch<React.SetStateAction<Recording[]>>
}

const RecordingsUpdate = (props: Props) =>
  <Stack spacing={4} width={1}>
    <Stack spacing={1} width={1}>
      <Typography variant='h3'>
        Recordings
      </Typography>
    </Stack>
    <Stack alignItems='center' direction='column' spacing={2} width={1}>
      {props.recordings.map(recording =>
        <UpdateRecordingItem
          distribution
          key={recording.id}
          project={props.project}
          recording={recording}
          setRecordings={props.setRecordings}
        />)}
    </Stack>
  </Stack>

export default RecordingsUpdate
