import SimpleTour from 'src/components/pages/Tour/TourComponents/SimpleTour'

const TaskAiTour = () =>
  <SimpleTour
    description='Releese AI can create release plans tailored to your needs'
    desktopDirection='bottom'
    mobileDirection='bottom'
    onboardingKey='taskAi'
    selector='ai-tasks'
    title='AI Release Plans'
  />

export default TaskAiTour
