/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable sonarjs/no-identical-functions */
import CloudDownloadRoundedIcon from '@mui/icons-material/CloudDownloadRounded'
import EastRoundedIcon from '@mui/icons-material/EastRounded'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import { Avatar, Button, Chip, Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Typography, useTheme } from '@mui/material'
import { useState } from 'react'

import { baseUrlLinksApi } from 'src/api/files'
import GetIconLink from 'src/components/pages/Projects/ViewProject/EditDetails/GetIconLink'
import { useAuth } from 'src/components/providers/AuthProvider'
import { NavLink } from 'src/components/SafeRouterLink'
import type Project from 'src/models/Project'
import type Recording from 'src/models/Recording'

type Props = {
  recording: Recording
  distribution?: boolean
  rights?: boolean
  share?: boolean
  inProject?: boolean
  disableDownload?: boolean
  project?: Project
}

const ViewRecordingDetailsTab = (props: Props) => {
  const themeItem = useTheme()
  const { currentOrganisation } = useAuth()
  const [anchorElementEmbed, setAnchorElementEmbed] = useState<HTMLElement | null>(null)
  const openMenuEmbed = Boolean(anchorElementEmbed)

  const handleCloseEmbed = () => {
    setAnchorElementEmbed(null)
  }

  const endsWithNumber = /\d$/
  const errorTitle = endsWithNumber.test(props.recording.title.trim())

  return <>
    <Menu
      anchorEl={anchorElementEmbed}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      onClose={handleCloseEmbed}
      open={openMenuEmbed}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
    >
      <MenuItem
        component='a'
        download
        href={`${baseUrlLinksApi}/recordings/download/${props.recording.id}/wav`}
        target='_blank'
      >
        <ListItemIcon sx={{ width: 64 }}>
          <Chip
            color='primary'
            label='WAV'
            rounded
            sx={{
              borderRadius: 8,
            }}
            variant='filled'
          />
        </ListItemIcon>
        <ListItemText
          primary='WAV'
          secondary='Uncompressed quality'
        />
      </MenuItem>
      <MenuItem
        component='a'
        download
        href={`${baseUrlLinksApi}/recordings/download/${props.recording.id}/mp3`}
        target='_blank'
      >
        <ListItemIcon sx={{ width: 64 }}>
          <Chip
            color='secondary'
            label='MP3'
            rounded
            sx={{
              borderRadius: 8,
            }}
            variant='filled'
          />
        </ListItemIcon>
        <ListItemText
          primary='MP3'
          secondary='Smallest file size'
        />
      </MenuItem>
      <MenuItem
        component='a'
        download
        href={`${baseUrlLinksApi}/recordings/download/${props.recording.id}/flac`}
        target='_blank'
      >
        <ListItemIcon sx={{ width: 64 }}>
          <Chip
            color='default'
            label='FLAC'
            rounded
            sx={{
              borderRadius: 8,
            }}
            variant='filled'
          />
        </ListItemIcon>
        <ListItemText
          primary='FLAC'
          secondary='Legacy lossless uncompressed quality'
        />
      </MenuItem>
      <MenuItem
        component='a'
        download
        href={`${baseUrlLinksApi}/recordings/download/${props.recording.id}/aiff`}
        target='_blank'
      >
        <ListItemIcon sx={{ width: 64 }}>
          <Chip
            color='primary'
            label='AIFF'
            rounded
            sx={{
              background: 'black',
              borderRadius: 8,
            }}
            variant='filled'
          />
        </ListItemIcon>
        <ListItemText
          primary='AIFF'
          secondary='Apple specific uncompressed quality'
        />
      </MenuItem>
      <MenuItem
        component='a'
        download
        href={`${baseUrlLinksApi}/recordings/download/${props.recording.id}/ogg`}
        target='_blank'
      >
        <ListItemIcon sx={{ width: 64 }}>
          <Chip
            color='info'
            label='OGG'
            rounded
            sx={{
              borderRadius: 8,
            }}
            variant='filled'
          />
        </ListItemIcon>
        <ListItemText
          primary='OGG'
          secondary='Balanced file size and audio quality'
        />
      </MenuItem>
    </Menu>
    <Grid container rowGap={1} spacing={3}>
      <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
        <Stack direction='column'>
          <Stack alignItems='center' direction='row' spacing={1}>
            <Typography color={themeItem.palette.text.label} variant='body2'>
              Title
            </Typography>
            {(props.distribution || props.rights) &&
            (!props.recording.title || props.recording.title.length === 0 || errorTitle) &&
              <Chip color='error' icon={<WarningRoundedIcon />} label='Error' size='small' variant='subtle' />}
          </Stack>
          <Typography variant='body2'>
            {props.recording.title}
          </Typography>
        </Stack>
      </Grid>

      <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
        <Stack direction='column'>
          <Typography color={themeItem.palette.text.label} variant='body2'>
            Version/Subtitle
          </Typography>
          <Typography variant='body2'>
            {props.recording.subTitle}
          </Typography>
        </Stack>
      </Grid>

      <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
        <Stack direction='column'>
          <Stack alignItems='center' direction='row' spacing={1}>
            <Typography color={themeItem.palette.text.label} variant='body2'>
              Primary Artists
            </Typography>
            {(props.distribution || props.rights) && (props.recording.primaryArtists.length === 0 ||
            props.recording.primaryArtists.length > 5) &&
            <Chip color='error' icon={<WarningRoundedIcon />} label='Error' size='small' variant='subtle' />}
          </Stack>
          <Stack direction='row' flexWrap='wrap'>
            {props.recording.primaryArtists.map(artist =>
              <Chip
                avatar={<Avatar src={artist.images?.[0]?.url ? artist.images[0].url : ''} />}
                color='default'
                key={artist.name}
                label={artist.name}
                sx={{ margin: 0.2 }}
                variant='subtle'
              />)}
          </Stack>
        </Stack>
      </Grid>

      <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
        <Stack direction='column'>
          <Typography color={themeItem.palette.text.label} variant='body2'>
            Featured Artists
          </Typography>
          <Stack direction='row' flexWrap='wrap'>
            {props.recording.featuredArtists.map(artist =>
              <Chip
                avatar={<Avatar src={artist.images?.[0]?.url ? artist.images[0].url : ''} />}
                color='default'
                key={artist.name}
                label={artist.name}
                sx={{ margin: 0.2 }}
                variant='subtle'
              />)}
          </Stack>
        </Stack>
      </Grid>

      {!props.distribution && !props.rights &&
      <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
        <Stack direction='column'>
          <Typography color={themeItem.palette.text.label} variant='body2'>
            Recording version
          </Typography>
          <Typography variant='body2'>
            {props.recording.recordingVersion}
          </Typography>
        </Stack>
      </Grid>}

      <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
        <Stack direction='column'>
          <Stack alignItems='center' direction='row' spacing={1}>
            <Typography color={themeItem.palette.text.label} variant='body2'>
              Track Type
            </Typography>
            {(props.distribution || props.rights) && (!props.recording.trackType ||
            props.recording.trackType.length === 0) &&
            <Chip color='error' icon={<WarningRoundedIcon />} label='Error' size='small' variant='subtle' />}
          </Stack>
          <Typography variant='body2'>
            {props.recording.trackType}
          </Typography>
        </Stack>
      </Grid>

      <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
        <Stack direction='column'>
          <Stack alignItems='center' direction='row' spacing={1}>
            <Typography color={themeItem.palette.text.label} variant='body2'>
              Links
            </Typography>
          </Stack>
          <Stack direction='row' flexWrap='wrap'>
            {props.recording.linkItems.map(link =>
              <IconButton
                href={link.link}
                key={link.id}
                size='small'
                target='_blank'
              >
                {GetIconLink(link.link)}
              </IconButton>)}
          </Stack>
        </Stack>
      </Grid>

      {((!props.share && !props.distribution && !props.rights) ||
      (props.share && !props.disableDownload)) &&
      <Grid item lg={3} md={3} sm={6} xl={3} xs={12}>
        <Button
          color='primary'
          disabled={!props.recording.audioFileUrl}
          endIcon={<CloudDownloadRoundedIcon />}
          fullWidth
          onClick={event => setAnchorElementEmbed(event.currentTarget)}
        >
          Download audio
        </Button>
      </Grid>}
      {((!props.share && !props.distribution && !props.rights) ||
      (props.share && !props.disableDownload)) &&
      <Grid item lg={3} md={3} sm={6} xl={3} xs={12}>
        <Button
          color='secondary'
          disabled={!props.recording.artworkUrl}
          download
          endIcon={<CloudDownloadRoundedIcon />}
          fullWidth
          href={`${baseUrlLinksApi}/files/download?fileName=${encodeURIComponent(props.recording.artworkUrl)}`}
          target='_blank'
        >
          Download artwork
        </Button>
      </Grid>}
      {!props.share &&
      !props.distribution &&
      !props.rights &&
      !props.inProject &&
      props.project &&
      (props.project?.organisation?.id === currentOrganisation?.id ||
        props.project?.collaborators.some(item => item.account.id === currentOrganisation?.id)) &&
        <Grid item lg={3} md={3} sm={6} xl={3} xs={12}>
          <Button
            color='inherit'
            component={NavLink}
            endIcon={<EastRoundedIcon />}
            fullWidth
            to={`/project/${props.recording.projectId}`}
          >
            View Project
          </Button>
        </Grid>}
    </Grid>
  </>
}

export default ViewRecordingDetailsTab
