import CloseIcon from '@mui/icons-material/Close'
import { Button, Dialog, IconButton, Skeleton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'

import { getActivePlanBenefits } from 'src/api/payment'
import DeleteItemComponent from 'src/components/DeleteItemComponent'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { ActivePlanBenefits } from 'src/models/Payment'

type Props = {
  readonly close: () => void
  readonly open: boolean
  readonly handleDowngrade: () => void
}

const CancelModalYourAccount = (props: Props) => {
  const { currentOrganisation } = useAuth()
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))
  const [benefits, setBenefits] = useState<ActivePlanBenefits>()

  useEffect(() => {
    void getActivePlanBenefits()
      .then(setBenefits)
  }, [currentOrganisation?.id])

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='sm'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Stack paddingX={3} paddingY={3} spacing={4} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          width='100%'
        >
          <Typography variant='h3'>
            Cancel plan
          </Typography>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>
        <DeleteItemComponent
          primary='Are you sure?'
          secondary='At the end of your billing cycle...'
        />
        {benefits
          ? <Stack spacing={1} width={1}>
            <Typography variant='h4'>
              {`${new Intl.NumberFormat().format(benefits.releases)} `}
              Releases will be set on a 15% commission
            </Typography>
            <Typography variant='h4'>
              {`${new Intl.NumberFormat().format(benefits.subscribers)} `}
              Subscribers will never pre-save your future releases
            </Typography>
            <Typography variant='h4'>
              {`${new Intl.NumberFormat().format(benefits.files)} `}
              Files will be deleted
            </Typography>
            <Typography variant='h4'>
              {`${new Intl.NumberFormat().format(benefits.playlists)} `}
              Playlists will no longer be tracked
            </Typography>
            <Typography variant='h4'>
              Your future releases will not be pitched to editors
            </Typography>
          </Stack>
          : <Stack spacing={1} width={1}>
            <Skeleton sx={{ width: 1, height: 32 }} variant='rounded' />
            <Skeleton sx={{ width: 1, height: 32 }} variant='rounded' />
            <Skeleton sx={{ width: 1, height: 32 }} variant='rounded' />
            <Skeleton sx={{ width: 1, height: 32 }} variant='rounded' />
            <Skeleton sx={{ width: 1, height: 32 }} variant='rounded' />
          </Stack>}
        <Stack direction='row' justifyContent='flex-end' spacing={1} width='100%'>
          <Button onClick={() => props.close()} variant='text'>
            Go back
          </Button>
          <Button
            color='error'
            onClick={() => props.handleDowngrade()}
            variant='contained'
          >
            Cancel subscription
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default CancelModalYourAccount
