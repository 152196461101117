import InsertLinkRoundedIcon from '@mui/icons-material/InsertLinkRounded'
import { Paper, Stack, Typography, useTheme } from '@mui/material'

const FailedIframeView = () => {
  const themeItem = useTheme()
  return (
    <Stack alignItems='center' height={1} justifyContent='center' width={1} >
      <Paper
        elevation={0}
        sx={{
          backgroundColor: theme => theme.palette.background.input,
          borderRadius: 16,
        }}
      >
        <Stack
          alignItems='center'
          justifyContent='center'
          padding={4}
        >
          <InsertLinkRoundedIcon
            sx={{
              color: theme => theme.palette.action.disabled,
              fontSize: '144px',
            }}
          />
        </Stack>
      </Paper>
      <Typography
        color={themeItem.palette.text.secondary}
        sx={{
          marginTop: 1,
        }}
        variant='h4'
      >
        Failed to load iFrame
      </Typography>
      <Typography
        color={themeItem.palette.text.label}
        variant='body1'
      >
        Please make sure you have properly set your code or link
      </Typography>
    </Stack>
  )
}

export default FailedIframeView
