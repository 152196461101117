import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Autocomplete, Avatar, Box, Button, Dialog, IconButton, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'

import { changeUserPermissionOrganisation } from 'src/api/organisation'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { Seat } from 'src/models/Organisation'

type Props = {
  close: () => void
  open: boolean
  user?: Seat
}

const EditUserModal = (props: Props) => {
  const themeColors = useTheme()
  const fullScreen = useMediaQuery(themeColors.breakpoints.down('md'))
  const { currentOrganisation, refreshCurrentOrganisation } = useAuth()

  const [loading, setLoading] = useState(false)

  const selectableRoles =
    currentOrganisation?.organisationRoles.filter(role => role.name.toLocaleLowerCase() !== 'owner')

  const [permissionGroups, setPermissionGroup] = useState(currentOrganisation?.seats
    .find(seat => seat.account.id === props.user?.account.id)?.role.id)

  const saveUser = async () => {
    await changeUserPermissionOrganisation(
      props.user?.account.id ?? '', permissionGroups ?? ''
    )
      .then(() => setLoading(false))
      .then(refreshCurrentOrganisation)
    props.close()
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='xs'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <form
        onSubmit={event => {
          event.preventDefault()
          void saveUser()
        }}
      >
        <Stack paddingX={3} paddingY={3} spacing={4} width={1}>
          <Stack
            alignItems='center'
            direction='row'
            justifyContent='space-between'
            width='100%'
          >
            <Typography variant='h3'>Edit a user</Typography>
            <IconButton onClick={() => props.close()}>
              <CloseIcon color='disabled' />
            </IconButton>
          </Stack>
          <Stack
            alignItems='center'
            direction='column'
            justifyContent='space-between'
            spacing={1}
            width='100%'
          >
            <Avatar src={props.user?.account.profilePicture} sx={{ width: 96, height: 96 }} />
            <Typography variant='h5'>{props.user?.account.displayName}</Typography>
          </Stack>

          <Stack spacing={5} width='100%'>
            <Autocomplete
              getOptionLabel={option => option.name}
              onChange={(event, value) => setPermissionGroup(value?.id ?? '')}
              options={selectableRoles ?? []}
              renderInput={params =>
                <TextField
                  {...params}
                  fullWidth
                  label='Permissions'
                  placeholder='Permissions'
                  sx={{
                    marginTop: 0,
                    width: 1,
                    minWidth: 260,
                    textTransform: 'capitalize',
                  }}
                />}
              renderOption={(renderProps, option) =>
                <Box component='li' {...renderProps}>
                  {option.name}
                </Box>}
              sx={{
                width: 1,
                marginTop: 0,
              }}
              value={selectableRoles?.find(item => item.id === permissionGroups)}
            />
          </Stack>
          <Stack direction='row' justifyContent='flex-end' spacing={1} width='100%'>
            <Button onClick={() => props.close()} variant='text'>Cancel</Button>
            <LoadingButton
              disabled={permissionGroups?.length === 0}
              loading={loading}
              type='submit'
              variant='contained'
            >
              Save
            </LoadingButton>
          </Stack>
        </Stack>
      </form>
    </Dialog>
  )
}

export default EditUserModal
