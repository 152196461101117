/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingButton } from '@mui/lab'
import { TableCell, TableRow  } from '@mui/material'
import { useState } from 'react'

import { downloadStatement, downloadStatementProject } from 'src/api/analytics'
import type { AnalyticsMonthlyChart } from 'src/models/Analytics'

type Props = {
  item: AnalyticsMonthlyChart
  projectId?: string
}

const EarningsExportRow = (props: Props) => {
  const [loading, setLoading] = useState(false)
  return (

    <TableRow
      key={props.item._id}
      sx={{
        '.MuiTableCell-root': { borderBottomColor: 'divider', paddingY: 2 },
      }}
    >
      <TableCell>
        {`${new Date(props.item._id).getFullYear()}`}
      </TableCell>
      <TableCell>
        {`${new Date(props.item._id).toLocaleString('default', { month: 'long' })}`}
      </TableCell>
      <TableCell>
        {`${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(props.item.amount)}`}
      </TableCell>
      <TableCell>
        <LoadingButton
          loading={loading}
          onClick={async () => {
            setLoading(true)
            await (props.projectId
              ? downloadStatementProject(props.item._id,
                props.projectId,
                new Date(props.item._id).getFullYear().toString(),
                new Date(props.item._id).toLocaleString('default', { month: 'long' }))
                .then(() => setLoading(false))
              : downloadStatement(props.item._id,
                new Date(props.item._id).getFullYear().toString(),
                new Date(props.item._id).toLocaleString('default', { month: 'long' }))
                .then(() => setLoading(false)))
          }}
          rounded
          size='small'
          variant='outlined'
        >
          Download
        </LoadingButton>
      </TableCell>
    </TableRow>
  )
}

export default EarningsExportRow
