import { Avatar, Paper, Skeleton, Stack, Tooltip, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

import { getAccount } from 'src/api/accounts'
import type Account from 'src/models/Account'
import type { TaskUpdate } from 'src/models/Taskboard'

type MessageElementProps = {
  taskUpdate: TaskUpdate
}

const TaskDrawerUpdate = (props: MessageElementProps) => {
  const [updateFrom, setUpdateFrom] = useState<Account | null>()

  useEffect(() => {
    if (!props.taskUpdate.account) return
    void getAccount(props.taskUpdate.account).then(setUpdateFrom)
  }, [props.taskUpdate.account])

  return (
    <Stack
      direction='row'
      key={props.taskUpdate.timestamp.toString()}
      spacing={1}
      width={1}
    >
      {updateFrom
        ? <Avatar
          src={updateFrom.profilePictureUrl}
          sx={{ width: 42, height: 42 }}
        />
        : <Skeleton height={42} variant='circular' width={42} />}
      <Paper sx={{
        paddingX: 1.5,
        paddingBottom: 1.5,
        width: 1,
        boxShadow: 0,
        background: 'transparent',
      }}
      >
        <Stack direction='column' spacing={0.5} width={1}>
          {updateFrom
            ? <Typography>{`${updateFrom.firstName}`}</Typography>
            : <Skeleton variant='text' width={125} />}

          <Stack direction='row' justifyContent='space-between' width={1}>
            {(/(jpg|gif|png|JPG|GIF|PNG|JPEG|jpeg)$/).test(props.taskUpdate.message)
              ? <img
                alt='Message'
                src={props.taskUpdate.message}
                style={{ borderRadius: 8, boxShadow: 'none' }}
                width={312}
              />
              : <Typography
                color='text.secondary'
                sx={{ wordWrap: 'break-word', maxWidth: 445 }}
              >
                {props.taskUpdate.message}
              </Typography>}
            <Tooltip title={dayjs(props.taskUpdate.timestamp).format('llll')}>
              <Typography color='text.label' sx={{ minWidth: 80, height: 'fit-content' }} variant='body2'>
                {dayjs(props.taskUpdate.timestamp).format('LT')}
              </Typography>
            </Tooltip>
          </Stack>
        </Stack>
      </Paper>
    </Stack>
  )
}

export default TaskDrawerUpdate
