/* eslint-disable no-multi-str */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import PaidRoundedIcon from '@mui/icons-material/PaidRounded'
import { Card, CardActionArea, Stack, Typography, useTheme } from '@mui/material'
import { useState } from 'react'

import ExplainModal from 'src/components/pages/Analytics/ExplainModal'
import type { AnalyticsMonthlyChart } from 'src/models/Analytics'

type Props = {
  readonly data: AnalyticsMonthlyChart[]
  readonly selectedRecording: string
  readonly periods: string[]
}

const EarningsDistributionLifetime = (props: Props) => {
  const themeItem = useTheme()
  const [explain, setExplain] = useState(false)
  const totalEarnings = props.data.filter(item => props.periods.length === 0 || props.periods.includes(item._id))
    .flatMap(({ amount }) => amount).reduce((partialSum, a) => partialSum + a, 0) ?? 0
  return (
    <Stack height={1} width={1}>
      <Card
        elevation={0}
        sx={{
          height: 1,
          background: theme => theme.palette.background.elevatedCard,
        }}
      >
        <CardActionArea onClick={() => setExplain(true)}>
          <Stack height={1} justifyContent='space-between' padding={2} spacing={3}>
            <Stack direction='row' spacing={2} width={1}>
              <Stack spacing={2} width={1}>
                <Typography variant='body1'>
                  Gross income
                </Typography>
                <Typography variant='h3'>
                  {new Intl.NumberFormat('en-US',
                    { style: 'currency', currency: 'USD' }).format(totalEarnings)}
                </Typography>
              </Stack>
              <Stack spacing={2}>
                <PaidRoundedIcon color='secondary' sx={{ fontSize: 48 }} />
              </Stack>
            </Stack>
            <Stack spacing={2} width={1}>
              <Typography variant='textLabel'>
                {props.selectedRecording.length > 0
                  ? 'Total distribution earnings since the release of this recording'
                  : 'Total distribution earnings'}
              </Typography>
            </Stack>
          </Stack>
        </CardActionArea>
      </Card>
      <ExplainModal
        close={() => setExplain(false)}
        color={themeItem.palette.secondary.main}
        description='Total distribution earnings refers how to much income has been generated by the releases you
         are a shareholder on. To view your share of income, refer to the Net Income.'
        icon={<PaidRoundedIcon
          sx={{
            color: theme => theme.palette.primary.contrastText,
            fontSize: '64px',
          }}
        />}
        open={explain}
        title='Gross income'
      />
    </Stack>
  )
}

export default EarningsDistributionLifetime

