/* eslint-disable no-duplicate-imports */
/* eslint-disable react/jsx-sort-default-props */
/* eslint-disable unicorn/prevent-abbreviations */
/* eslint-disable id-length */
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import type {
  BoxProps,
  ButtonProps,
  DialogActionsProps,
  DialogContentProps,
  DialogProps,
  FormControlProps,
  IconButtonProps,
  TextFieldProps,
  TooltipProps } from '@mui/material'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Pagination,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import type { ChangeEvent } from 'react'
import { useEffect, useState } from 'react'

import { iconList } from './iconList'
import IconPickerItem from './IconPickerItem'
import type { IconSize, IconType } from './iconType'

type IconPickerProps = {
  value: IconType
  name?: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  showSearch?: boolean
  searchPlaceholder?: string
  iconPerPage?: number
  formControlProps?: FormControlProps
  pickerInputLabel?: string
  dialogTitleText?: string
  dialogCancelText?: string
  dialogProps?: DialogProps
  dialogContentProps?: DialogContentProps
  dialogActionsProps?: DialogActionsProps
  searchFieldContainer?: BoxProps
  searchFieldProps?: TextFieldProps
  cancelButtonProps?: ButtonProps
  iconListContainerProps?: BoxProps
  iconTooltipProps?: TooltipProps
  iconButtonProps?: IconButtonProps
  iconListIconSize?: IconSize
}

const IconPicker = ({
  value,
  name,
  onChange,
  searchPlaceholder,
  iconPerPage,
  dialogProps,
  dialogContentProps,
  searchFieldProps,
  iconListContainerProps,
  iconButtonProps,
  iconListIconSize,
}: IconPickerProps) => {
  const [showIconListModal, setShowIconListModal] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const [icon, setIcon] = useState<IconType>('')
  const [page, setPage] = useState<number>(1)
  const iconListLength = iconList.length

  useEffect(() => {
    if (value && value.length > 0) {
      setIcon(value)
    }
  }, [])

  const handleClickIconPicker = () => {
    setShowIconListModal(!showIconListModal)
  }

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  const handleFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    !!onChange && onChange(e)
    setIcon(e.target.value as IconType)
  }

  const handlePageChange = (e: ChangeEvent<unknown>, pageItem: number) => {
    e.preventDefault()
    setPage(pageItem)
  }

  const paginationCount = () => {
    if (search !== '') {
      const filteredListLength = iconList.filter((iconItem: IconType) =>
        iconItem.toLowerCase().includes(search.toLowerCase())).length

      return Math.ceil(filteredListLength / (iconPerPage as number))
    }

    return Math.ceil(iconListLength / (iconPerPage as number))
  }

  return (
    <>
      <link href='https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css' rel='stylesheet' />

      <IconButton
        edge='end'
        onClick={handleClickIconPicker}
        sx={{
          borderRadius: '6px',
          width: 37.6,
          height: 37.6,
          fontSize: '1.3rem',
          marginLeft: '0px!important',
          marginRight: '4px',
          background: theme => theme.palette.background.input,
        }}
      >
        {icon === ''
          ? null
          : iconList.includes(icon)
            ? <i className={`fa ${icon}`} />
            : <CircularProgress size={20} />}
      </IconButton>

      <Dialog
        fullWidth
        maxWidth='sm'
        onClose={handleClickIconPicker}
        open={showIconListModal}
        {...dialogProps}
      >
        <Stack padding={2} width={1}>
          <Stack spacing={2} width={1}>
            <Stack alignItems='center' direction='row' justifyContent='space-between' width={1}>
              <Typography variant='h4'>
                Pick an icon
              </Typography>
              <IconButton
                onClick={() => {
                  handleClickIconPicker()
                  setSearch('')
                }}
                sx={{
                  width: 'fit-content',
                }}
              >
                <CloseRoundedIcon />
              </IconButton>
            </Stack>
            <TextField
              fullWidth
              onChange={handleSearchChange}
              placeholder={searchPlaceholder}
              size='small'
              sx={{
                marginTop: 0,
              }}
              {...searchFieldProps}
            />

          </Stack>
        </Stack>
        <Divider />
        <DialogContent
          sx={{
            textAlign: 'center',
            minHeight: 702,
          }}
          {...dialogContentProps}
        >
          {iconList
            .filter(iconItem => iconItem.toLowerCase().includes(search.toLowerCase()))
            .slice((page - 1) * (iconPerPage as number), page * (iconPerPage as number))
            .map((iconItem, index: number) =>
              <IconPickerItem
                icon={iconItem}
                iconButtonProps={iconButtonProps}
                iconListContainerProps={iconListContainerProps}
                iconListIconSize={iconListIconSize}
                key={index.toString()}
                onClick={(valueItem: IconType) => {
                  handleFieldChange({
                    target: { value: valueItem, name } } as ChangeEvent<HTMLInputElement>)
                  setShowIconListModal(false)
                  setSearch('')
                }}
              />)}
        </DialogContent>
        <Divider />
        {paginationCount() > 0 &&
          <DialogActions
            sx={{
              display: 'block',
              padding: 2,
            }}
          >
            <Stack
              direction='row'
              spacing={2}
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Pagination count={paginationCount()} onChange={handlePageChange} page={page} />
              <Button
                color='error'
                disabled={icon === ''}
                onClick={() => {
                  handleFieldChange({
                    target: { value: '', name } } as ChangeEvent<HTMLInputElement>)
                  setShowIconListModal(false)
                  setSearch('')
                }}
                rounded
                size='small'
              >
                Remove icon
              </Button>
            </Stack>
          </DialogActions>}
      </Dialog>
    </>
  )
}

export default IconPicker
