/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable max-len */
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded'
import { Card, CardActionArea, Grid, Stack, Typography, useTheme } from '@mui/material'
import { useState } from 'react'

import SocialAssetModal from 'src/components/pages/SocialAssets/SocialAssetModal'

type Props = {
  number: number
  title: string
  secondaryTitle: string
  currentTab: string
  artworkUrl: string
  titleSocial: string
}

const NumberComponentAnalytics = (props: Props) => {
  const themeItem = useTheme()
  const [socialDrawer, setSocialDrawer] = useState(false)
  const roundup = 10 ** (`${props.number}`.length - 1) * Number(props.number.toString().charAt(0))
  return (
    <>
      <Grid item lg={3} md={4} sm={6} xl={3} xs={6}>
        <Card
          elevation={0}
          sx={{
            height: 1,
            background: theme => theme.palette.background.elevatedCard,
          }}
        >
          <CardActionArea onClick={() => setSocialDrawer(true)} sx={{ height: 1 }}>
            <Stack padding={2} spacing={2} width={1}>
              <Stack direction='row' justifyContent='space-between'>
                <Stack>
                  <Typography
                    fontSize='1.45em'
                    fontWeight={600}
                    lineHeight={1.2}
                    variant='h3'
                  >
                    {props.title}
                  </Typography>
                  <Typography
                    color={themeItem.palette.text.secondary}
                    fontSize='1em'
                    fontWeight={400}
                    lineHeight={1.2}
                    variant='body2'
                  >
                    {props.secondaryTitle}
                  </Typography>
                </Stack>
                <IosShareRoundedIcon
                  fontSize='medium'
                />
              </Stack>
              <Typography
                lineHeight={1.1}
                textAlign='center'
                variant='h2'
              >
                {props.number.toLocaleString()}
              </Typography>
            </Stack>
          </CardActionArea>
        </Card>
      </Grid>
      {socialDrawer &&
      <SocialAssetModal
        close={() => setSocialDrawer(false)}
        imageMainUrl={props.artworkUrl}
        imageSecondaryUrl=''
        open={socialDrawer}
        platform={props.currentTab}
        text={`Reached ${roundup.toLocaleString()} ${props.titleSocial}`}
      />}
    </>
  )
}

export default NumberComponentAnalytics
