import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { Card, CardActionArea, CardMedia, Grid, IconButton, Stack, Tooltip, Typography, useTheme } from '@mui/material'
import { useState } from 'react'
import { v4 } from 'uuid'

import ServiceModal from 'src/components/pages/Projects/DistributeModal/DistributeModalComponents/ServiceModal'
import { GetColorLink } from 'src/components/pages/SmartLink/Content/GetColorLink'
import GetIcon from 'src/components/pages/SmartLink/Content/GetIcon'
import type { ReleeseServices } from 'src/models/Distribution'
import type Project from 'src/models/Project'
import type Recording from 'src/models/Recording'

type Props = {
  readonly selected: boolean
  readonly title: string
  readonly subtitle: string
  readonly price: number[]
  readonly platforms: string[]
  readonly description: string
  readonly timelineStart: number
  readonly timelineCompletion: number
  readonly process: string[]
  readonly requirements: string[]
  readonly recordings: Recording[]
  readonly project: Project
  readonly product: string
  readonly setCart: React.Dispatch<React.SetStateAction<ReleeseServices[]>>
  readonly disabled?: boolean
}

const ServicesItem = (props: Props) => {
  const gradient = props.platforms.length === 1
    ? GetColorLink(props.platforms[0])
    : props.platforms[0] === props.platforms[1]
      ? GetColorLink(props.platforms[0])
      : `linear-gradient(90deg, ${GetColorLink(props.platforms[0])} 30%, ${GetColorLink(props.platforms[1])} 70%)`

  const [openModal, setOpenModal] = useState(false)

  const themeItem = useTheme()

  return (
    <>
      <Grid item lg={4} md={4} sm={6} xl={4} xs={12}>
        <Tooltip
          title={props.disabled
            ? 'This service will be available soon, contact us for more information.'
            : ''}
        >
          <Card
            sx={{
              position: 'relative',
              borderColor: theme => props.selected
                ? theme.palette.primary.main
                : undefined,
              height: 1,
              filter: props.disabled
                ? 'grayscale(100%)'
                : 'none',
              '&:before': {
                content: '""',
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundImage: props.disabled
                  ? 'linear-gradient(to bottom right,#3c3c3c,#3c3c3c)'
                  : 'none',
                opacity: '.6',
                zIndex: 999,
                pointerEvents: 'none',
              },
            }}
            variant='outlined'
          >
            <CardActionArea
              disabled={props.disabled}
              onClick={() => setOpenModal(true)}
              sx={{ height: 1 }}
            >
              <Stack height={1} width={1}>
                <CardMedia
                  sx={{
                    aspectRatio: '16/7',
                    width: '100%',
                    background: gradient,
                    top: 0,
                  }}
                >
                  <Stack alignItems='center' direction='row' height={1} justifyContent='center' width={1}>
                    {props.platforms.map((item, index) =>
                      <>
                        <IconButton disableRipple key={`item ${v4()}`} sx={{ fontSize: 64 }}>
                          {GetIcon(item)}
                        </IconButton>
                        {(index < props.platforms.length - 1) &&
                        <IconButton disableRipple sx={{ fontSize: 48 }}>
                          <AddRoundedIcon />
                        </IconButton>}
                      </>)}
                  </Stack>
                </CardMedia>
                <Stack padding={2} spacing={1}>
                  <Typography lineHeight={1.1} variant='h4'>
                    {props.title}
                  </Typography>
                  <Typography color={themeItem.palette.text.label} variant='body2'>
                    {props.subtitle}
                  </Typography>
                  <Typography color={themeItem.palette.text.secondary} variant='body2'>
                    Starting at
                    {' '}
                    {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(props.price[0])}
                  </Typography>
                </Stack>
              </Stack>
            </CardActionArea>
          </Card>
        </Tooltip>
      </Grid>
      <ServiceModal
        close={() => setOpenModal(false)}
        description={props.description}
        gradient={gradient}
        open={openModal}
        platforms={props.platforms}
        pricing={props.price}
        process={props.process}
        product={props.product}
        project={props.project}
        recordings={props.recordings}
        requirements={props.requirements}
        setCart={props.setCart}
        subtitle={props.subtitle}
        timelineCompletion={props.timelineCompletion}
        timelineStart={props.timelineStart}
        title={props.title}
      />
    </>
  )
}

export default ServicesItem
