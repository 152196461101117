import CloseIcon from '@mui/icons-material/Close'
import { Button, Dialog, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { v4 } from 'uuid'

import { deleteTaskboard } from 'src/api/taskboards'
import { sendWorkspaceUpdate } from 'src/api/webSocket'
import DeleteItemComponent from 'src/components/DeleteItemComponent'

type Props = {
  close: () => void
  open: boolean
  refreshBoards: () => void
  taskboardId: string
}

const DeleteItemdModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  const handleClick = async () => {
    await deleteTaskboard(props.taskboardId.replace('doc/', 'DOCUMENT-').replace('embed/', 'EMBED-'))
      .then(() => sendWorkspaceUpdate(v4()))
      .then(props.refreshBoards)
    props.close()
  }

  return (

    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='xs'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Stack paddingX={3} paddingY={3} spacing={4} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          width='100%'
        >
          <Typography variant='h3'>
            Delete Item
          </Typography>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>
        <DeleteItemComponent
          primary='Are you sure?'
          secondary='This action cannot be undone'
        />
        <Stack direction='row' justifyContent='flex-end' spacing={1} width='100%'>
          <Button onClick={() => props.close()} variant='text'>Cancel</Button>
          <Button
            color='error'
            onClick={() => handleClick()}
          >
            Delete item
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default DeleteItemdModal
