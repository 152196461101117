import { Stack, Typography } from '@mui/material'

import EditBioLinkMarketingServices from 'src/components/pages/Links/EditLink/EditLinkComponents/EditBioLinkMarketingServicesEdit'
import type { MarketingLink } from 'src/models/Marketing'

type Props = {
  smartLinkData: MarketingLink
  setLinkData: React.Dispatch<React.SetStateAction<MarketingLink>>
}

const EditBioLinkServices = (props: Props) =>
  <Stack direction='column' maxWidth={896} spacing={2}>
    <Stack width={1}>
      <Stack alignItems='flex-start' direction='row' justifyContent='space-between' paddingTop={2} width={1}>
        <Typography variant='h3'>
          Content Blocks
        </Typography>
      </Stack>
    </Stack>
    <EditBioLinkMarketingServices
      setLinkData={props.setLinkData}
      smartLinkData={props.smartLinkData}
    />
  </Stack>

export default EditBioLinkServices
