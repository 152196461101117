/* eslint-disable max-len */
import CloseIcon from '@mui/icons-material/Close'
import { Alert, Card, CardActionArea, Dialog, IconButton, Snackbar, Stack, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'
import QRCode from 'react-qr-code'

import type Project from 'src/models/Project'

type Props = {
  close: () => void
  open: boolean
  projectItem: Project
}

const ShareProjectFolderModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))
  const [linkCopiedToast, setLinkCopiedToastOpen] = useState(false)

  const handleCloseToast = (event: Event | React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setLinkCopiedToastOpen(false)
  }

  const copyLink = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    event.preventDefault()
    setLinkCopiedToastOpen(true)
    void navigator.clipboard.writeText(`https://cloud.releese.io/share-folder/project/${props.projectItem.id}/${props.projectItem.privateInviteHash}`)
  }

  return (
    <>
      <Snackbar
        autoHideDuration={5000}
        message='Link Copied'
        onClose={handleCloseToast}
        open={linkCopiedToast}
      >
        <Alert
          onClose={handleCloseToast}
          severity='success'
          sx={{ width: '100%' }}
          variant='filled'
        >
          Link copied
        </Alert>
      </Snackbar>

      <Dialog
        BackdropProps={{
          timeout: 500,
        }}
        closeAfterTransition
        fullScreen={fullScreen}
        onClose={props.close}
        open={props.open}
        sx={{
          justifyContent: 'center',
        }}
      >
        <Stack paddingX={3} paddingY={3} spacing={1} width={1}>
          <Stack
            alignItems='center'
            direction='row'
            justifyContent='space-between'
            width={1}
          >
            <Typography variant='h3'>Share project files</Typography>
            <IconButton onClick={() => props.close()}>
              <CloseIcon color='disabled' />
            </IconButton>
          </Stack>
          <Stack alignItems='center' minWidth={300} spacing={2} width={1}>
            <Card
              elevation={0}
              sx={{
                backgroundColor: 'white',
                width: 'fit-content',
              }}
            >
              <Stack padding={2}>
                <QRCode
                  value={`https://cloud.releese.io/share-folder/project/${props.projectItem.id}/${props.projectItem.privateInviteHash}`}
                />
              </Stack>
            </Card>
            <Typography color={themeItem.palette.text.secondary} variant='body2'>
              Users who have access to this link will be able to see this folder, all its sub-folders, and their respective content.
            </Typography>
            <Tooltip
              title='Click to copy'
            >
              <Card variant='outlined'>
                <CardActionArea
                  onClick={event => copyLink(event)}
                  sx={{
                    padding: 1,
                  }}
                >
                  <Typography variant='body1'>
                    {`https://cloud.releese.io/share-folder/project/${props.projectItem.id}/${props.projectItem.privateInviteHash}`}
                  </Typography>
                </CardActionArea>
              </Card>
            </Tooltip>
          </Stack>
        </Stack>
      </Dialog>
    </>
  )
}

export default ShareProjectFolderModal
