import AddRoundedIcon from '@mui/icons-material/AddRounded'
import PhonelinkRoundedIcon from '@mui/icons-material/PhonelinkRounded'
import { Button, Paper, Stack, Typography, useTheme } from '@mui/material'
import { useState } from 'react'

import NewLinkModal from 'src/components/pages/Links/LinksList/LinksListModal/NewLinkModal'
import { useAuth } from 'src/components/providers/AuthProvider'
import { Permissions } from 'src/models/Organisation'
import type Project from 'src/models/Project'

type Props = {
  project?: Project
}

const LinksListEmpty = (props: Props) => {
  const themeItem = useTheme()
  const { currentAccountPermissions } = useAuth()
  const [newLinkModal, setNewLinkModalOpen] = useState(false)
  return (
    <>
      {newLinkModal &&
      currentAccountPermissions?.includes(Permissions.CREATE_EDIT_REMOVE_MARKETING_LINKS) &&
      <NewLinkModal
        close={() => setNewLinkModalOpen(false)}
        open={newLinkModal}
        project={props.project}
      />}

      <Stack alignItems='center' height={1} justifyContent='center' width={1} >
        <Paper
          elevation={0}
          sx={{
            backgroundColor: theme => theme.palette.warning.main,
            borderRadius: 16,
          }}
        >
          <Stack
            alignItems='center'
            justifyContent='center'
            padding={2}
          >
            <PhonelinkRoundedIcon
              sx={{
                color: theme => theme.palette.primary.contrastText,
                fontSize: '64px',
              }}
            />
          </Stack>
        </Paper>
        <Typography
          color={themeItem.palette.text.secondary}
          sx={{
            marginTop: 1,
          }}
          variant='h4'
        >
          No Smart Links found
        </Typography>
        <Typography
          color={themeItem.palette.text.label}
          sx={{
            textAlign: 'center',
          }}
          variant='body1'
        >
          Create a new Smart Link to get new fans
        </Typography>
        <Button
          color='warning'
          disabled={!currentAccountPermissions?.includes(Permissions.CREATE_EDIT_REMOVE_MARKETING_LINKS)}
          onClick={() => setNewLinkModalOpen(true)}
          startIcon={<AddRoundedIcon />}
          sx={{
            marginTop: 2,
          }}
        >
          Create a Smart Link
        </Button>
      </Stack>
    </>
  )
}

export default LinksListEmpty
