/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Container, Paper, Stack, Typography } from '@mui/material'

import ResetPassword from 'src/components/pages/Login/FirebaseActionHandler/ResetPassword'
import RevertSecondFactor from 'src/components/pages/Login/FirebaseActionHandler/RevertSecondFactor'
import VerifyEmail from 'src/components/pages/Login/FirebaseActionHandler/VerifyEmail'
import ReleeseLogo from 'src/components/ReleeseLogo'

const ManageUsers = () => {
  const query = new URLSearchParams(document.location.search)

  const mode = query.get('mode')
  const actionCode = query.get('oobCode')

  return <Container
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 1,
      width: '100%!important',
      padding: 0,
      margin: 0,
      maxWidth: '100%!important',
      backgroundColor: theme => theme.palette.background.input,
    }}
  >
    <Paper
      sx={{
        padding: 3,
        textAlign: 'center',
        width: 600,
        marginY: 2,
        borderRadius: {
          xl: 1,
          lg: 1,
          md: 1,
          sm: 0,
          xs: 0,
        },
      }}
    >
      <Stack paddingY={4} >
        <ReleeseLogo full />
      </Stack>
      <Stack spacing={2}>
        <Stack alignItems='flex-start' width={1}>
          <Stack height={0} id='recaptcha' marginTop='0px!important' />
        </Stack>
        {(() => {
          switch (mode) {
            case 'resetPassword':
              return <ResetPassword actionCode={actionCode!} />
            case 'verifyEmail':
              return <VerifyEmail actionCode={actionCode!} />
            case 'revertSecondFactorAddition':
              return <RevertSecondFactor actionCode={actionCode!} />
            default:
              return (
                <Stack>
                  <Typography variant='h3'>Error encountered</Typography>
                  <Typography variant='body1'>The selected page mode is invalid.</Typography>
                </Stack>
              )
          }
        })()}
      </Stack>
    </Paper>
  </Container>
}

export default ManageUsers
