import { initializeAnalytics, isSupported } from 'firebase/analytics'
import type { FirebaseError } from 'firebase/app'
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

import type { ExtractPromiseParam, TypedCatchPromise } from 'src/types/react-app-env'

const firebaseApp = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
})

void isSupported().then(result => result && initializeAnalytics(firebaseApp))

export const auth = getAuth(firebaseApp)
export default firebaseApp

export const parseError = (error: FirebaseError) => {
  console.warn(error.code, error)
  return error.message
}

export type FirebasePromise<T> = TypedCatchPromise<ExtractPromiseParam<T>, FirebaseError>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FirebasePromiseAll<T extends any[]> = FirebasePromise<ExtractPromiseParam<T>>
