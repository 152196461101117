import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, IconButton, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'

import { addNewRole } from 'src/api/organisation'
import { useAuth } from 'src/components/providers/AuthProvider'
import { Role } from 'src/models/Organisation'

type Props = {
  close: () => void
  open: boolean
  openToast: React.Dispatch<React.SetStateAction<boolean>>
}

const NewPermissionGroupModal = (props: Props) => {
  const themeColors = useTheme()
  const fullScreen = useMediaQuery(themeColors.breakpoints.down('md'))
  const { refreshCurrentOrganisation } = useAuth()
  const [roleName, setRoleName] = useState('')
  const [loading, setLoading] = useState(false)

  const newGroup = async () => {
    const role = new Role({ name: roleName })
    setLoading(true)
    await addNewRole(role)
      .then(props.close)
      .then(refreshCurrentOrganisation)
      .then(() => props.openToast(true))
      .finally(() => setLoading(false))
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='xs'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <form
        onSubmit={event => {
          event.preventDefault()
          void newGroup()
        }}
      >
        <Stack paddingX={3} paddingY={3} spacing={2} width={1}>
          <Stack
            alignItems='center'
            direction='row'
            justifyContent='space-between'
            width='100%'
          >
            <Typography variant='h3'>Create a permission group</Typography>
            <IconButton onClick={() => props.close()}>
              <CloseIcon color='disabled' />
            </IconButton>
          </Stack>
          <Stack width='100%'>
            <TextField
              fullWidth
              label='Name your group'
              onChange={event => setRoleName(event.target.value)}
              placeholder='Group name'
              value={roleName}
            />
          </Stack>
          <Stack direction='row' justifyContent='flex-end' spacing={1} width='100%'>
            <Button onClick={() => props.close()} variant='text'>Cancel</Button>
            <LoadingButton
              loading={loading}
              type='submit'
              variant='contained'
            >
              Create group
            </LoadingButton>
          </Stack>
        </Stack>
      </form>
    </Dialog>
  )
}

export default NewPermissionGroupModal
