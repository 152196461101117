/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import 'src/styles/Custom/cookielaw.css'

import { Button, Card, Skeleton, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

import { getSeatedArtist } from 'src/api/links'
import SeatedDarkLogo from 'src/components/Icons/SeatedDark'
import SeatedLightLogo from 'src/components/Icons/SeatedLight'
import RenderSeatedItem from 'src/components/pages/Links/EditLink/EditLinkPreview/Components/SeatedItem'
import type { MarketingLink, SeatedEvents } from 'src/models/Marketing'
import * as darkTheme from 'src/styles/dark.theme'
import * as lightTheme from 'src/styles/light.theme'

type Props = {
  link: MarketingLink
  embedUrl: string
}

const RenderSeated = (props: Props) => {
  const [loading, setLoading] = useState(false)
  const [artist, setArtist] = useState<SeatedEvents | null | undefined>(null)

  const activeTheme = props.link.palette === 'Light'
    ? lightTheme
    : darkTheme

  const skeletonColor = props.link.palette === 'Light'
    ? 'rgba(17, 24, 39, 0.11)'
    : 'rgba(256, 256, 256, 0.25)'

  useEffect(() => {
    setLoading(true)
    void getSeatedArtist(props.embedUrl)
      .then(event => {
        setArtist(event.artist)
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }, [props.embedUrl])

  return (
    <Card
      elevation={0}
      sx={{
        background: activeTheme.default.palette.background.default,
        width: 1,
      }}
    >
      <Stack alignItems='center' direction='column' justifyContent='center' padding={2} spacing={2}>
        {loading && [...Array.from({ length: 5 })].map((_item, index) =>
          <Skeleton
            height={54}
            key={index.toString()}
            sx={{
              backgroundColor: skeletonColor,
            }}
            variant='rounded'
            width='100%'
          />)}

        {!loading && artist?.data.slice(0, 5).map(event =>
          <RenderSeatedItem
            event={event}
            key={event.id}
            link={props.link}
          />)}

        {!loading && (!artist || (artist.data.length === 0)) &&
        <Typography color={activeTheme.default.palette.text.secondary} textAlign='center' variant='body1'>
          No upcoming events scheduled
        </Typography>}
      </Stack>

      <Stack
        alignItems='center'
        justifyContent='center'
        paddingBottom={2}
        paddingX={2}
        spacing={1}
      >
        {artist && !loading && artist.data.length > 0 &&
          <Button
            color='secondary'
            size='small'
            sx={{
              color: '#A4DDE0',
            }}
            variant='text'
          >
            View all events
          </Button>}

        <Stack
          alignItems='center'
          direction='row'
          justifyContent='center'
          spacing={1}
        >
          <Typography
            color={activeTheme.default.palette.text.primary}
            sx={{
              flexShrink: 0,
            }}
            textAlign='center'
            variant='body1'
          >
            Powered by
            {' '}
          </Typography>
          {props.link.palette === 'Light'
            ? <SeatedLightLogo />
            : <SeatedDarkLogo />}
        </Stack>
      </Stack>
    </Card>
  )
}

export default RenderSeated
