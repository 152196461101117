import CloseIcon from '@mui/icons-material/Close'
import {  Button, Dialog, IconButton, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import _ from 'lodash'
import type { ChangeEvent } from 'react'
import { useState } from 'react'
import type { ColorResult } from 'react-color'
import { CirclePicker } from 'react-color'
import { v4 as uuidv4, v4 } from 'uuid'

import { savePartialTaskboard } from 'src/api/taskboards'
import { sendTasksboardUpdate } from 'src/api/webSocket'
import { colorSelection } from 'src/components/pages/Task/TaskMenu/TaskMenuComponents/ColorsForPicker'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { TaskBoard, TaskGroup, TaskItem } from 'src/models/Taskboard'
import { TaskBoardEdit } from 'src/models/Taskboard'

type Props = {
  close: () => void
  open: boolean
  taskboard: TaskBoard
}

const emptyTasks: TaskItem[] = []

const TaskSectionModal = (props: Props) => {
  const { currentAccount } = useAuth()
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  const taskData: TaskGroup = {
    id: uuidv4(),
    tasks: emptyTasks,
    title: 'Untitled section',
    color: '#828DF8',
  }

  const [sectionName, setSectionName] = useState('')
  const [sectionColor, setSectionColor] = useState('#828DF8')
  const [useableData, setUseableDate] = useState(_.cloneDeep(taskData))

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSectionName(event.target.value)
    useableData.title = event.target.value
    setUseableDate(useableData)
  }

  const handleColorChange = (color: ColorResult) => {
    setSectionColor(color.hex)
    useableData.color = color.hex
    setUseableDate(useableData)
  }

  const handleClick = async (transferData: TaskGroup) => {
    if (props.taskboard.id) {
      props.taskboard.taskGroups.push(transferData)
      await savePartialTaskboard({ id: props.taskboard.id, ...props.taskboard })
        .then(() => {
          const taskUpdateItem = new TaskBoardEdit({
            id: v4(),
            taskboardId: props.taskboard.id,
            taskId: '',
            accountId: currentAccount.id,
            modificationType: 'Column created',
            itemType: 'Field',
            title: `${sectionName}`,
          })
          sendTasksboardUpdate(taskUpdateItem)
        })
        .then(() => props.close())
    }
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      maxWidth='xs'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Stack paddingX={3} paddingY={3} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          width='100%'
        >
          <Typography variant='h3'>Create a Section</Typography>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>
        <Stack alignItems='center' width='100%'>
          <Stack marginBottom={4} marginTop={3} spacing={1} width={338}>
            <CirclePicker
              color={sectionColor}
              colors={colorSelection}
              onChangeComplete={
                handleColorChange
              }
              width='100%'
            />
          </Stack>
          <TextField
            fullWidth
            label='Section name'
            onChange={handleNameChange}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                void handleClick(useableData)
              }
            }}
            placeholder='Name this section'
            value={sectionName}
          />
          <Stack direction='row' justifyContent='flex-end' paddingTop={2} spacing={1} width={1}>
            <Button onClick={props.close} variant='text' >
              Cancel
            </Button>
            <Button onClick={() => handleClick(useableData)} >
              Save
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default TaskSectionModal

