/* eslint-disable react-hooks/exhaustive-deps */
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { Button, Checkbox, Dialog, Divider, FormControlLabel, FormGroup, IconButton, Link, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'

type Props = {
  close: () => void
  open: boolean
  appleDigitalAddOn: boolean
  setAppleDigitalAddOn: React.Dispatch<React.SetStateAction<boolean>>
  appleEmail: string
  setAppleEmail: React.Dispatch<React.SetStateAction<string>>
}

const AppleDigitalMasterModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))
  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='sm'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Stack width={1}>
        <Stack alignItems='center' direction='row' justifyContent='space-between' paddingTop={2} paddingX={2} width={1}>
          <Typography variant='h3'>
            Apple Digital Masters
          </Typography>
          <IconButton onClick={() => props.close()}>
            <CloseRoundedIcon color='disabled' />
          </IconButton>
        </Stack>

        <Stack paddingBottom={4} paddingX={2} spacing={2} width={1}>
          <Typography color={themeItem.palette.text.label} variant='body1'>
            Apple Digital Masters allows artists to have their music mastered and distributed in high-resolution audio.
            To release music through
            {' '}
            <Link
              href='https://www.apple.com/apple-music/apple-digital-masters/'
              target='_blank'
            >
              this service
            </Link>
            {', '}
            you will need to provide your Apple Digital Masters Engineer&apos;s
            email.
          </Typography>
          <Divider />

          <TextField
            autoComplete='new-password'
            label='Apple Digital Master Engineer Email'
            onChange={event => props.setAppleEmail(event.target.value)}
            placeholder='example@apple.com'
            sx={{
              marginTop: '32px!important',
            }}
            value={props.appleEmail}
          />

          <FormGroup>
            <FormControlLabel
              control={<Checkbox
                checked={props.appleDigitalAddOn}
                onChange={event => props.setAppleDigitalAddOn(event.target.checked)}
              />}
              label='I confirm to have uploaded 24bit - 44.1 kHz WAV recordings'
            />
          </FormGroup>
        </Stack>

        <Stack direction='row' justifyContent='flex-end' paddingBottom={2} paddingX={2} spacing={1} width={1}>
          <Button
            onClick={() => props.close()}
            variant='text'
          >
            Cancel
          </Button>
          <Button
            onClick={() => props.close()}
          >
            Confirm
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default AppleDigitalMasterModal
