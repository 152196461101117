/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import type { TComment } from '@udecode/plate-comments'
import { CommentsProvider } from '@udecode/plate-comments'
import type { ReactNode } from 'react'
import { useEffect, useState } from 'react'
import { v4 } from 'uuid'

import { usersData } from './constants'
import { sendNotesComment, sendNotesDeleteComment, sendNotesUpdateComment } from 'src/api/webSocket'
import CommentsUpdater from 'src/components/pages/Notes/Modern/comments/UpdateComments'
import { useAuth } from 'src/components/providers/AuthProvider'
import { NotesComment } from 'src/models/Notes'

type Props = {
  children: ReactNode
  documentId: string
}

export const MyCommentsProvider = (props: Props) => {
  const { currentAccount } = useAuth()
  const [activeId, setActiveId] = useState<string | null>()
  const [updateData, setUpdateData] = useState<Partial<TComment>>()
  const [requestData, setRequestData] = useState(false)

  const updateItem = (valueTest: Partial<TComment>, idItem: string) => {
    const noteItem = new NotesComment(
      {
        id: idItem ?? '',
        uuid: idItem ?? '',
        value: valueTest.value as any,
        createdAt: Date.now(),
        parentId: valueTest.parentId,
        isResolved: valueTest.isResolved,
        documentNoteId: props.documentId,
        userId: currentAccount.id,
      }
    )
    sendNotesUpdateComment(noteItem)
  }

  useEffect(() => {
    if (requestData && updateData && activeId) {
      updateItem(updateData, activeId)
      setRequestData(false)
    }
  }, [updateData, activeId])

  return (
    <CommentsProvider
      myUserId={currentAccount.id}
      onCommentAdd={valueItem => {
        const newId = v4()
        const noteItem = new NotesComment(
          {
            id: valueItem.id?.toString() ?? newId,
            uuid: valueItem.id?.toString() ?? newId,
            value: valueItem.value as any,
            createdAt: Date.now(),
            parentId: valueItem.parentId,
            isResolved: valueItem.isResolved,
            documentNoteId: props.documentId,
            userId: currentAccount.id,
          }
        )
        sendNotesComment(noteItem)
      }}
      onCommentDelete={id => {
        sendNotesDeleteComment(id)
      }}
      onCommentUpdate={valueItem => {
        setUpdateData(valueItem)
        setRequestData(true)
      }}
      users={usersData}
    >
      <CommentsUpdater
        documentId={props.documentId}
        setActiveId={setActiveId}
      >
        {props.children}
      </CommentsUpdater>
    </CommentsProvider>
  )
}

