/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import 'src/styles/Custom/cookielaw.css'

import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded'
import { Card, CardActionArea, Stack, Typography } from '@mui/material'
import dayjs from 'dayjs'

import type { BandsintownEvents, MarketingLink } from 'src/models/Marketing'
import * as darkTheme from 'src/styles/dark.theme'
import * as lightTheme from 'src/styles/light.theme'

type Props = {
  link: MarketingLink
  event: BandsintownEvents
}

const RenderBandsintownItem = (props: Props) => {
  const activeTheme = props.link.palette === 'Light'
    ? lightTheme
    : darkTheme

  return (
    <Card
      key={props.event.id}
      sx={{
        background: 'transparent',
        borderColor: activeTheme.default.palette.border,
        width: 1,
      }}
      variant='outlined'
    >
      <CardActionArea>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          padding={1}
          spacing={1}
        >
          <Card
            elevation={0}
            sx={{
              background: activeTheme.default.palette.background.input,
              borderRadius: '8px',
              flexShrink: 0,
            }}
          >
            <Stack alignItems='center' flexShrink={0} justifyContent='center' padding={1}>
              <Typography
                color={activeTheme.default.palette.text.primary}
                lineHeight={1}
                textAlign='center'
                variant='subtitle1'
              >
                {dayjs(new Date(props.event.datetime)).format('MMM')}
              </Typography>
              <Typography
                color={activeTheme.default.palette.text.primary}
                lineHeight={1}
                textAlign='center'
                variant='h3'
              >
                {dayjs(new Date(props.event.datetime)).date()}
              </Typography>
            </Stack>
          </Card>
          <Stack flexGrow={1}>
            <Typography
              color={activeTheme.default.palette.text.primary}
              lineHeight={1.3}
              variant='h4'
            >
              {props.event.venue.name}
            </Typography>
            <Typography
              color={activeTheme.default.palette.text.secondary}
              lineHeight={1.2}
              variant='body1'
            >
              {props.event.venue.city}
              {', '}
              {props.event.venue.country}
              {' · '}
              {dayjs(new Date(props.event.datetime)).format('ha')}
            </Typography>
          </Stack>
          <OpenInNewRoundedIcon
            sx={{
              color: activeTheme.default.palette.text.secondary,
              fontSize: 24,
            }}
          />
        </Stack>
      </CardActionArea>
    </Card>
  )
}

export default RenderBandsintownItem
