import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded'
import { Avatar, Chip, ListItemText, Stack, TableCell, TableRow, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

import { getBasicOrganisation } from 'src/api/organisation'
import ViewTransactionModal from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/AccountingTab/ViewTransaction'
import type { BasicOrganisation } from 'src/models/Organisation'
import type { Recoupment } from 'src/models/Recording'
import type Recording from 'src/models/Recording'

type Props = {
  recoup: Recoupment
  asset?: Recording
}

const RecoupItemTable = (props: Props) => {
  const [expanded, setExpanded] = useState(false)
  const [org, setOrg] = useState<BasicOrganisation>()

  useEffect(() => {
    if (props.recoup.organisationAdvanceId.length > 0) {
      void getBasicOrganisation(props.recoup.organisationAdvanceId)
        .then(orgItem => setOrg(orgItem ?? undefined))
        .catch(() => null)
    }
  }, [props.recoup.organisationAdvanceId])

  return (
    <>
      <TableRow
        hover
        onClick={() => setExpanded(true)}
        role='checkbox'
        sx={{
          '.MuiTableCell-root': {
            borderColor: 'divider',
            paddingY: 2,
          },
        }}
        tabIndex={-1}
      >
        <TableCell>
          <Stack>
            {props.recoup.title}
            <Typography
              color='text.label'
              variant='body2'
            >
              {dayjs(props.recoup.dateAdded).format('LL')}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell>
          {props.asset &&
          <ListItemText
            primary={props.asset.title}
            secondary={props.asset.primaryArtists.map(item => item.name).join(', ')}
          />}
        </TableCell>
        <TableCell>
          {org &&
          <Stack width={1}>
            <Stack alignItems='center' direction='row' spacing={1}>
              <Avatar
                src={org.logoUrl}
                sx={{
                  height: 24,
                  width: 24,
                }}
              />
              <Typography
                sx={{ marginTop: 0.5 }}
                variant='body2'
              >
                {org.name}
              </Typography>
            </Stack>
          </Stack>}
        </TableCell>
        <TableCell>
          {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(props.recoup.amount)}
        </TableCell>
        <TableCell>
          <Chip
            color={props.recoup.transactionType === 'Income'
              ? 'success'
              : props.recoup.transactionType === 'Expense'
                ? 'error'
                : props.recoup.transactionType === 'Payout'
                  ? 'secondary'
                  : 'primary'}
            icon={<AttachMoneyRoundedIcon />}
            label={props.recoup.transactionType}
            variant='filled'
          />
        </TableCell>
      </TableRow>
      <ViewTransactionModal
        close={() => setExpanded(false)}
        open={expanded}
        org={org}
        recoup={props.recoup}
      />
    </>
  )
}

export default RecoupItemTable
