/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable complexity */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-lines */
import HelpRoundedIcon from '@mui/icons-material/HelpRounded'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded'
import { Autocomplete, Chip, Grid, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'

import { getProjectTags } from 'src/api/projects'
import SearchArtistComponentSubtle from 'src/components/form-elements/SearchArtistSubtle'
import SelectField from 'src/components/form-elements/SelectField'
import type { ArtistObject } from 'src/models/Distribution'
import type Project from 'src/models/Project'
import { PROJECT_STATUS } from 'src/models/Project'
import { GENRES, RECORDING_VERSIONS } from 'src/models/Recording'

function setColor(selected: string) {
  switch (selected) {
    case PROJECT_STATUS[0].toString():
      return 'success'
    case PROJECT_STATUS[1].toString():
      return 'warning'
    case PROJECT_STATUS[2].toString():
      return 'secondary'
    case PROJECT_STATUS[3].toString():
      return 'error'
    case PROJECT_STATUS[4].toString():
      return 'primary'
    default:
      return 'primary'
  }
}

type Props = {
  project: Project
  updatedFields: (project: Project) => void
  distributing?: boolean
  setProject: React.Dispatch<React.SetStateAction<Project | undefined>>
}

const EditProjectMetadataFrontend = (props: Props) => {
  const [projectFields, setProjectFields] = useState(Object.assign({}, props.project))

  const [tags, setTags] = useState<string[]>([])
  const [openDate, setOpenDate] = useState(false)

  useEffect(() => {
    void getProjectTags()
      .then(setTags)
  }, [])

  useEffect(() => {
    setProjectFields(props.project)
  }, [props.project])

  useEffect(() => {
    moment.tz.setDefault('America/New_York')
  }, [])

  const [selectedPrimaryArtists, setSelectedPrimaryArtists] = useState<ArtistObject[]>(projectFields.primaryArtists)
  const [selectedFeaturedArtists, setSelectedFeaturedArtists] = useState<ArtistObject[]>(projectFields.featuredArtists)

  const handlePrimaryChange = (values: ArtistObject[] | undefined) => {
    setSelectedPrimaryArtists(values ?? [])
    const proj = Object.assign({}, projectFields)
    proj.primaryArtists = values ?? []
    setProjectFields(proj)
    save({ ...proj, primaryArtists: values ?? [] })
  }

  const handleFeaturedChange = (values: ArtistObject[] | undefined) => {
    setSelectedFeaturedArtists(values ?? [])
    const proj = Object.assign({}, projectFields)
    proj.featuredArtists = values ?? []
    setProjectFields(proj)
    save({ ...proj, featuredArtists: values ?? [] })
  }

  const checkTitleErrors = projectFields.title.includes(',') || projectFields.title.includes('-') ||
    projectFields.title.toLowerCase().includes('feat.') || projectFields.title.toLowerCase().includes('featuring') ||
    projectFields.title.toLowerCase().includes('ft.')

  const updateField = (field:
  'catalogNumber' | 'cLine' | 'description' | 'format' | 'genre' | 'pLine' | 'recordLabel' | 'title' | 'UPC',
  value: string) => {
    const proj = Object.assign({}, projectFields)
    proj[field] = value
    setProjectFields(proj)
    if (field === 'genre') {
      save({ ...proj, genre: value })
    }
    if (field === 'format') {
      save({ ...proj, format: value })
    }
  }

  const handleMultiFieldsChange = (values: string[],
    field: 'subGenres' | 'tags') => {
    const proj = Object.assign({}, projectFields)
    proj[field] = values
    setProjectFields(proj)
    if (field === 'subGenres') {
      save({ ...proj, subGenres: values })
    } else {
      save({ ...proj, tags: values })
    }
  }

  const updateStatus = (value: 'Confirmed' | 'Cut' | 'On Hold' | 'Released' | 'WIP') => {
    const proj = Object.assign({}, projectFields)
    proj.status = value
    setProjectFields(proj)
    save({ ...proj, status: value })
  }

  const updateReleaseDate = (value: Date | null) => {
    if (value) {
      const dateItem = value.toISOString().slice(0, 10)
      const testItem = moment(dateItem, 'YYYY-MM-DD').tz('America/New_York')
      const proj = Object.assign({}, projectFields)
      proj.releaseDate = testItem.toDate()
      setProjectFields(proj)
      save({ ...proj, releaseDate: testItem.toDate() })
    }
  }

  const save = (newProject?: Project) => {
    const projectTemporary = newProject
      ? newProject
      : projectFields
    props.setProject(projectTemporary)
    setProjectFields(projectTemporary)
  }

  return (
    <Stack paddingBottom={4} spacing={4} width={1}>
      <Stack spacing={1} width={1}>
        <Grid
          container
          spacing={2}
          sx={{
            '& .MuiGrid-item': {
              borderBottom: theme => `1px solid ${theme.palette.divider}`,
            },
          }}
          width={1}
        >

          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <Stack alignItems='center' direction='row' paddingBottom={2} spacing={1} width={1}>
              <Typography
                sx={{
                  marginTop: 0,
                  width: 100,
                  flexShrink: 0,
                }}
                variant='inputLabel'
              >
                Title
                <Tooltip
                  title='Please do not include artist names here, they are automatically pulled from recordings.'
                >
                  <HelpRoundedIcon color='action' sx={{ fontSize: 16, marginLeft: 0.5 }} />
                </Tooltip>
              </Typography>
              <TextField
                autoComplete='new-password'
                error={props.distributing && !!props.project.title && props.project.title.length === 0}
                fullWidth
                inputProps={{ maxLength: 50 }}
                onBlur={() => save()}
                onChange={event_ => updateField('title', event_.target.value)}
                placeholder='Title'
                sx={{
                  marginTop: 0,
                  background: 'transparent!important',
                  border: 'none',
                  '.MuiInputBase-root': {
                    background: 'transparent!important',
                    borderColor: 'transparent!important',
                    paddingY: 0,
                    marginTop: 0,
                  },
                }}
                value={projectFields.title}
              />
              {props.distributing && (!props.project.title || props.project.title.length === 0) &&
              <Chip color='error' label='Missing' size='small' variant='filled' />}
            </Stack>
            {checkTitleErrors &&
            <Typography color='error' variant='body2'>
              Do not include any versions or artist names in the title
            </Typography>}
          </Grid>

          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <Stack alignItems='center' direction='row' paddingBottom={2} spacing={1} width={1}>
              <Typography
                sx={{
                  marginTop: 0,
                  width: 100,
                  flexShrink: 0,
                }}
                variant='inputLabel'
              >
                Release Date
                <Tooltip
                  title='Distribute your release at least 7 business days in
                  advance to guarantee your release date.'
                >
                  <InfoRoundedIcon color='action' sx={{ fontSize: 16, marginLeft: 0.5 }} />
                </Tooltip>
              </Typography>
              <Stack
                onClick={() => setOpenDate(true)}
                sx={{
                  cursor: 'pointer!important',
                }}
                width={1}
              >
                <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
                  <DatePicker
                    format='MMMM DD, YYYY'
                    onChange={date => updateReleaseDate(moment(date).toDate())}
                    onClose={() => setOpenDate(false)}
                    open={openDate}
                    sx={{
                      width: 1,
                      marginTop: 0,
                      cursor: 'pointer!important',
                      background: 'transparent!important',
                      border: 'none',
                      '.MuiInputBase-root': {
                        cursor: 'pointer!important',
                        background: 'transparent!important',
                        borderColor: 'transparent!important',
                        paddingY: 0,
                        marginTop: 0,
                      },
                      '.MuiInputBase-input': {
                        cursor: 'pointer!important',
                      },
                    }}
                    value={moment(projectFields.releaseDate.toISOString().slice(0, 10),
                      'YYYY-MM-DD').tz('America/New_York')}
                  />
                </LocalizationProvider>
              </Stack>
            </Stack>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <Stack alignItems='center' direction='row' paddingBottom={2} spacing={1} width={1}>
              <Typography
                sx={{
                  marginTop: 0,
                  width: 100,
                  flexShrink: 0,
                }}
                variant='inputLabel'
              >
                Primary artists
              </Typography>
              <SearchArtistComponentSubtle
                placeholder='Search Artists'
                selectedArtists={selectedPrimaryArtists}
                setSelectedArtists={handlePrimaryChange}
              />
            </Stack>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <Stack alignItems='center' direction='row' paddingBottom={2} spacing={1} width={1}>
              <Typography
                sx={{
                  marginTop: 0,
                  width: 100,
                  flexShrink: 0,
                }}
                variant='inputLabel'
              >
                Featured Artists
              </Typography>
              <SearchArtistComponentSubtle
                placeholder='Search Artists'
                selectedArtists={selectedFeaturedArtists}
                setSelectedArtists={handleFeaturedChange}
              />
            </Stack>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <Stack alignItems='center' direction='row' paddingBottom={2} spacing={1} width={1}>
              <Typography
                sx={{
                  marginTop: 0,
                  width: 100,
                  flexShrink: 0,
                }}
                variant='inputLabel'
              >
                Primary Genre
              </Typography>
              <Autocomplete
                fullWidth
                onChange={(event, value) => updateField('genre', value ?? '')}
                options={GENRES}
                renderInput={params =>
                  <TextField
                    {...params}
                    fullWidth
                    placeholder='Primary Genres'
                    sx={{
                      marginTop: 0,
                      background: 'transparent!important',
                      border: 'none',
                      '.MuiInputBase-root': {
                        background: 'transparent!important',
                        borderColor: 'transparent!important',
                        paddingY: 0,
                        marginTop: 0,
                      },
                    }}
                  />}
                value={projectFields.genre}
              />
              {props.distributing && !props.project.genre &&
              <Chip color='error' label='Missing' size='small' variant='filled' />}
            </Stack>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <Stack alignItems='center' direction='row' paddingBottom={2} spacing={1} width={1}>
              <Typography
                sx={{
                  marginTop: 0,
                  width: 100,
                  flexShrink: 0,
                }}
                variant='inputLabel'
              >
                Sub-Genres
              </Typography>
              <Autocomplete
                fullWidth
                multiple
                onChange={(event, value) => handleMultiFieldsChange(value, 'subGenres')}
                options={GENRES}
                renderInput={params =>
                  <TextField
                    {...params}
                    fullWidth
                    placeholder='Sub-Genres'
                    sx={{
                      marginTop: 0,
                      background: 'transparent!important',
                      border: 'none',
                      '.MuiInputBase-root': {
                        background: 'transparent!important',
                        borderColor: 'transparent!important',
                        paddingY: 0,
                        marginTop: 0,
                      },
                    }}
                  />}
                sx={{
                  '.MuiAutocomplete-tag': {
                    background: theme => theme.palette.background.input,
                  },
                }}
                value={projectFields.subGenres}
              />
              {props.distributing && props.project.subGenres.length === 0 &&
              <Chip color='error' label='Missing' size='small' variant='filled' />}
            </Stack>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <Stack alignItems='center' direction='row' paddingBottom={2} spacing={1} width={1}>
              <Typography
                sx={{
                  marginTop: 0,
                  width: 100,
                  flexShrink: 0,
                }}
                variant='inputLabel'
              >
                UPC
                <Tooltip
                  title='The UPC code is used to identify an album. Leave this field empty
                  if you want Releese to generate one for you.'
                >
                  <HelpRoundedIcon color='action' sx={{ fontSize: 16, marginLeft: 0.5 }} />
                </Tooltip>
              </Typography>
              <TextField
                autoComplete='new-password'
                disabled
                fullWidth
                placeholder='123456789123 or leave empty to autogenerate'
                sx={{
                  marginTop: 0,
                  background: 'transparent!important',
                  border: 'none',
                  '.MuiInputBase-root': {
                    background: 'transparent!important',
                    borderColor: 'transparent!important',
                    paddingY: 0,
                    marginTop: 0,
                  },
                }}
                value={projectFields.UPC}
              />
            </Stack>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <Stack alignItems='center' direction='row' paddingBottom={2} spacing={1} width={1}>
              <Typography
                sx={{
                  marginTop: 0,
                  width: 100,
                  flexShrink: 0,
                }}
                variant='inputLabel'
              >
                Record Label
              </Typography>
              <TextField
                autoComplete='new-password'
                fullWidth
                onBlur={() => save()}
                onChange={event_ => updateField('recordLabel', event_.target.value)}
                placeholder='Record Records'
                sx={{
                  marginTop: 0,
                  background: 'transparent!important',
                  border: 'none',
                  '.MuiInputBase-root': {
                    background: 'transparent!important',
                    borderColor: 'transparent!important',
                    paddingY: 0,
                    marginTop: 0,
                  },
                }}
                value={projectFields.recordLabel}
              />
              {props.distributing && !props.project.recordLabel &&
              <Chip color='error' label='Missing' size='small' variant='filled' />}
            </Stack>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <Stack alignItems='center' direction='row' paddingBottom={2} spacing={1} width={1}>
              <Typography
                sx={{
                  marginTop: 0,
                  width: 100,
                  flexShrink: 0,
                }}
                variant='inputLabel'
              >
                C Line
                <Tooltip
                  title='The C Line is used to identify the copyright owner of an album, typically a publisher.'
                >
                  <HelpRoundedIcon color='action' sx={{ fontSize: 16, marginLeft: 0.5 }} />
                </Tooltip>
              </Typography>
              <TextField
                autoComplete='new-password'
                fullWidth
                onBlur={() => save()}
                onChange={event_ => updateField('cLine', event_.target.value)}
                placeholder='Copyright Owner (Publisher)'
                sx={{
                  marginTop: 0,
                  background: 'transparent!important',
                  border: 'none',
                  '.MuiInputBase-root': {
                    background: 'transparent!important',
                    borderColor: 'transparent!important',
                    paddingY: 0,
                    marginTop: 0,
                  },
                }}
                value={projectFields.cLine}
              />
              {props.distributing && !props.project.cLine &&
              <Chip color='error' label='Missing' size='small' variant='filled' />}
            </Stack>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <Stack alignItems='center' direction='row' paddingBottom={2} spacing={1} width={1}>
              <Typography
                sx={{
                  marginTop: 0,
                  width: 100,
                  flexShrink: 0,
                }}
                variant='inputLabel'
              >
                P Line
                <Tooltip
                  title='The P Line is used to identify the product owner of an album, typically a record label.'
                >
                  <HelpRoundedIcon color='action' sx={{ fontSize: 16, marginLeft: 0.5 }} />
                </Tooltip>
              </Typography>
              <TextField
                autoComplete='new-password'
                fullWidth
                onBlur={() => save()}
                onChange={event_ => updateField('pLine', event_.target.value)}
                placeholder='Recording Owner (Label)'
                sx={{
                  marginTop: 0,
                  background: 'transparent!important',
                  border: 'none',
                  '.MuiInputBase-root': {
                    background: 'transparent!important',
                    borderColor: 'transparent!important',
                    paddingY: 0,
                    marginTop: 0,
                  },
                }}
                value={projectFields.pLine}
              />
              {props.distributing && !props.project.pLine &&
              <Chip color='error' label='Missing' size='small' variant='filled' />}
            </Stack>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <Stack alignItems='center' direction='row' paddingBottom={2} spacing={1} width={1}>
              <Typography
                sx={{
                  marginTop: 0,
                  width: 100,
                  flexShrink: 0,
                }}
                variant='inputLabel'
              >
                Catalog number
              </Typography>
              <TextField
                autoComplete='new-password'
                fullWidth
                onBlur={() => save()}
                onChange={event_ => updateField('catalogNumber', event_.target.value)}
                placeholder='ABC001'
                sx={{
                  marginTop: 0,
                  background: 'transparent!important',
                  border: 'none',
                  '.MuiInputBase-root': {
                    background: 'transparent!important',
                    borderColor: 'transparent!important',
                    paddingY: 0,
                    marginTop: 0,
                  },
                }}
                value={projectFields.catalogNumber}
              />
            </Stack>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <Stack alignItems='center' direction='row' paddingBottom={2} spacing={1} width={1}>
              <Typography
                sx={{
                  marginTop: 0,
                  width: 100,
                  flexShrink: 0,
                }}
                variant='inputLabel'
              >
                Format
              </Typography>
              <Autocomplete
                fullWidth
                onChange={(event, value) => updateField('format', value ?? '')}
                options={RECORDING_VERSIONS}
                renderInput={params =>
                  <TextField
                    {...params}
                    autoComplete='new-password'
                    fullWidth
                    placeholder='Format'
                    sx={{
                      marginTop: 0,
                      background: 'transparent!important',
                      border: 'none',
                      '.MuiInputBase-root': {
                        background: 'transparent!important',
                        borderColor: 'transparent!important',
                        paddingY: 0,
                        marginTop: 0,
                      },
                    }}
                  />}
                value={projectFields.format}
              />
            </Stack>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <Stack alignItems='center' direction='row' paddingBottom={2} spacing={1} width={1}>
              <Typography
                sx={{
                  marginTop: 0,
                  width: 100,
                  flexShrink: 0,
                }}
                variant='inputLabel'
              >
                Tags
              </Typography>
              <Autocomplete
                freeSolo
                fullWidth
                multiple
                onChange={(event, value) => handleMultiFieldsChange(value, 'tags')}
                options={tags}
                renderInput={params =>
                  <TextField
                    {...params}
                    autoComplete='new-password'
                    fullWidth
                    placeholder='Type and press enter to add a tag'
                    sx={{
                      marginTop: 0,
                      background: 'transparent!important',
                      border: 'none',
                      '.MuiInputBase-root': {
                        background: 'transparent!important',
                        borderColor: 'transparent!important',
                        paddingY: 0,
                        marginTop: 0,
                      },
                    }}
                  />}
                value={projectFields.tags}
              />
            </Stack>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <Stack alignItems='center' direction='row' paddingBottom={2} spacing={1} width={1}>
              <Typography
                sx={{
                  marginTop: 0,
                  width: 100,
                  flexShrink: 0,
                }}
                variant='inputLabel'
              >
                Status
              </Typography>
              <SelectField
                fullWidth
                onChange={updateStatus}
                options={[
                  { value: PROJECT_STATUS[0], label: PROJECT_STATUS[0].toString() },
                  { value: PROJECT_STATUS[1], label: PROJECT_STATUS[1].toString() },
                  { value: PROJECT_STATUS[2], label: PROJECT_STATUS[2].toString() },
                  { value: PROJECT_STATUS[3], label: PROJECT_STATUS[3].toString() },
                  { value: PROJECT_STATUS[4], label: PROJECT_STATUS[4].toString() },
                ]}
                renderValue={item => <Chip color={setColor(item)} label={item} variant='subtle' />}
                sx={{
                  marginTop: 0,
                  background: 'transparent!important',
                  border: 'none',
                  '.MuiInputBase-root': {
                    background: 'transparent!important',
                    borderColor: 'transparent!important',
                    paddingY: 0,
                    marginTop: 0,
                  },
                }}
                value={projectFields.status}
              />
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  )
}

export default EditProjectMetadataFrontend
