/* eslint-disable no-restricted-imports */
import { Box, Divider, FilledInput, MenuItem, Paper, Select, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'

import SearchField from 'src/components/form-elements/SearchField'
import SplitsTable from 'src/components/pages/Projects/ProjectTabs/SplitsTab/SplitsTable'
import SPACING from 'src/styles/spacing'

type Props = {
  refresh: React.Dispatch<React.SetStateAction<boolean>>
}

const SplitsTab = (props: Props) => {
  const [splitStatus, setSplitStatus] = useState('')
  const [masterStatus, setMasterStatus] = useState('')
  const [pubStatus, setPubStatus] = useState('')
  const [searchQuery, setSearchQuery] = useState('')

  const themeColors = useTheme()
  const matches = useMediaQuery(themeColors.breakpoints.down('md'))

  return (
    <Stack
      sx={{
        paddingBottom: SPACING.RESPONSIVE_CONTOUR,
        paddingRight: SPACING.RESPONSIVE_CONTOUR,
        height: 1,
        width: 1,
      }}
    >
      <Paper
        elevation={0}
        sx={{
          height: 1,
          width: 1,
          overflow: 'hidden',
          borderRadius: {
            xl: 1,
            lg: 1,
            md: 1,
            sm: 0,
            xs: 0,
          },
        }}
      >
        <Stack height={1} width={1}>
          <Stack height={1}>
            <Stack
              direction='row'
              padding={2}
              sx={{
                overflowX: 'auto',
                overflowY: 'clip',
              }}
            >
              <Stack
                direction='row'
                spacing={1}
              >
                <SearchField
                  onChange={event => setSearchQuery(event.target.value)}
                  value={searchQuery}
                />

                {!matches &&
                <>
                  <Stack direction='column' sx={{ width: 200 }} >
                    <Select
                      displayEmpty
                      fullWidth
                      id='version-filter'
                      input={<FilledInput />}
                      onChange={event => setSplitStatus(event.target.value)}
                      renderValue={selected =>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', height: 1 }}>
                          <Stack alignItems='center' direction='row' key={selected} spacing={1}>
                            <Typography variant='textLabel'>Status</Typography>
                            <Typography>{selected.length === 0 ? 'All' : selected}</Typography>
                          </Stack>
                        </Box>}
                      value={splitStatus}
                    >
                      <MenuItem value=''>All</MenuItem>
                      {['Locked', 'Unlocked'].map(type =>
                        <MenuItem key={type} value={type}>{type}</MenuItem>)}
                    </Select>
                  </Stack>

                  <Stack direction='column' sx={{ width: 200 }} >
                    <Select
                      displayEmpty
                      fullWidth
                      id='version-filter'
                      input={<FilledInput />}
                      onChange={event => setMasterStatus(event.target.value)}
                      renderValue={selected =>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', height: 1 }}>
                          <Stack alignItems='center' direction='row' key={selected} spacing={1}>
                            <Typography variant='textLabel'>Master</Typography>
                            <Typography>{selected.length === 0 ? 'All' : selected}</Typography>
                          </Stack>
                        </Box>}
                      value={masterStatus}
                    >
                      <MenuItem value=''>All</MenuItem>
                      {['Locked', 'Unlocked'].map(type =>
                        <MenuItem key={type} value={type}>{type}</MenuItem>)}
                    </Select>
                  </Stack>

                  <Stack direction='column' sx={{ width: 200 }} >
                    <Select
                      displayEmpty
                      fullWidth
                      id='version-filter'
                      input={<FilledInput />}
                      onChange={event => setPubStatus(event.target.value)}
                      renderValue={selected =>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', height: 1 }}>
                          <Stack alignItems='center' direction='row' key={selected} spacing={1}>
                            <Typography variant='textLabel'>Publishing</Typography>
                            <Typography>{selected.length === 0 ? 'All' : selected}</Typography>
                          </Stack>
                        </Box>}
                      value={pubStatus}
                    >
                      <MenuItem value=''>All</MenuItem>
                      {['Locked', 'Unlocked'].map(type =>
                        <MenuItem key={type} value={type}>{type}</MenuItem>)}
                    </Select>
                  </Stack>
                </>}

              </Stack>
            </Stack>
            <Divider sx={{ width: 1 }} />
            <Stack height={1} paddingBottom={2} sx={{ overflow: 'auto' }} width={1}>
              <Stack alignItems='center' height={1} marginX='auto' paddingBottom={2} width={1}>
                <SplitsTable
                  masterContract={masterStatus}
                  pubContract={pubStatus}
                  refresh={props.refresh}
                  searchQuery={searchQuery}
                  statusQuery={splitStatus}
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Paper>
    </Stack>
  )
}

export default SplitsTab
