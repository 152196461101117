import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { AppBar, Dialog, IconButton, Slide, Stack, Toolbar } from '@mui/material'
import { useEffect, useState } from 'react'

import { onboardingEvent } from 'src/api/organisation'
import SubscriptionCancelled from 'src/components/pages/Account/Billing/BillingComponents/SubscriptionCancelled'
import SubscriptionCreated from 'src/components/pages/Account/Billing/BillingComponents/SubscriptionCreated'
import CancelConfirmation from 'src/components/pages/Account/Billing/CancelConfirmation'
import CheckoutFormComponent from 'src/components/pages/Account/Billing/CheckoutFormComponent'
import PlanComponent from 'src/components/pages/Account/Billing/PlanComponent'
import ReleeseLogo from 'src/components/ReleeseLogo'
import type { Organisation, PriceLookupKey } from 'src/models/Organisation'

type Props = {
  readonly close: () => void
  readonly open: boolean
  readonly account: Organisation
  readonly price?: PriceLookupKey
}

const BillingModal = (props: Props) => {
  const [priceLookupKey, setPriceLookupKey] = useState<PriceLookupKey>(props.price ?? 'NEW_CREATOR')
  const [term, setTerm] = useState(10)
  const [seats, setSeats] = useState(1)
  const [checkout, setCheckout] = useState('plan')

  useEffect(() => {
    if (props.open) {
      void onboardingEvent('View plans')
    }
  }, [props.open])

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen
      keepMounted={false}
      onClose={props.close}
      open={props.open}
    >
      <Slide direction='up' in={props.open}>
        <Stack height={1} overflow='auto'>
          <AppBar
            sx={{
              position: 'relative',
              background: 'transparent',
            }}
          >
            <Toolbar
              sx={{
                justifyContent: 'space-between',
                paddingX: 2,
                background: 'transparent',
              }}
            >
              <ReleeseLogo full />
              <IconButton
                aria-label='close'
                onClick={props.close}
              >
                <CloseRoundedIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Stack alignItems='center' width={1}>
            <Stack
              alignItems='center'
              direction='column'
              height={1}
              justifyContent='center'
              paddingY={3}
              spacing={1}
              width={1}
            >
              {(() => {
                switch (checkout) {
                  case 'plan':
                    return <PlanComponent
                      account={props.account}
                      setCheckout={setCheckout}
                      setPriceLookupKey={setPriceLookupKey}
                      setSeats={setSeats}
                      setTerm={setTerm}
                    />
                  case 'checkout':
                    return <Stack
                      alignItems='center'
                      height={1}
                      justifyContent='center'
                      maxWidth={1024}
                      paddingX={3}
                      width={1}
                    >
                      <CheckoutFormComponent
                        account={props.account}
                        priceLookupKey={priceLookupKey}
                        seats={seats}
                        setCheckout={setCheckout}
                        term={term}
                      />
                    </Stack>
                  case 'cancelconfirmation':
                    return <Stack height={1} maxWidth={1024} paddingX={3} width={1}>
                      <CancelConfirmation
                        setCheckout={setCheckout}
                        setPriceLookupKey={setPriceLookupKey}
                      />
                    </Stack>
                  case 'cancelled':
                    return <Stack height={1} maxWidth={1024} paddingX={3} width={1}>
                      <SubscriptionCancelled />
                    </Stack>
                  case 'subscribed':
                    return priceLookupKey !== 'FREE'
                      ? <Stack height={1} maxWidth={1024} paddingX={3} width={1}>
                        <SubscriptionCreated
                          close={props.close}
                        />
                      </Stack>
                      : <Stack height={1} maxWidth={1024} paddingX={3} width={1}>
                        <SubscriptionCancelled />
                      </Stack>
                    // eslint-disable-next-line sonarjs/no-duplicated-branches
                  default:
                    return <PlanComponent
                      account={props.account}
                      setCheckout={setCheckout}
                      setPriceLookupKey={setPriceLookupKey}
                      setSeats={setSeats}
                      setTerm={setTerm}
                    />
                }
              })()}
            </Stack>
          </Stack>
        </Stack>
      </Slide>
    </Dialog>
  )
}

export default BillingModal
