/* eslint-disable complexity */
/* eslint-disable sonarjs/no-duplicate-string */
import DragIndicatorRoundedIcon from '@mui/icons-material/DragIndicatorRounded'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import { IconButton, TableCell, Tooltip } from '@mui/material'
import type { DraggableProvided } from 'react-beautiful-dnd'

import type { CustomFields } from 'src/models/Taskboard'

type Props = {
  keyItem: string
  hiddenFields: string[]
  customFields: CustomFields[]
  setManageEmbed: (value: React.SetStateAction<string>) => void
  setAnchorElementEmbed: (value: React.SetStateAction<HTMLElement | null>) => void
  providedItem: DraggableProvided
}

const RenderListKeys = (props: Props) => {
  const key = props.keyItem
  const itemCustom = props.customFields.find(item => item.id === key)
  return (
    <>
      {key === 'Due date' && !props.hiddenFields.includes('Due date') &&
      <TableCell
        align='left'
        ref={props.providedItem.innerRef}
        sx={{
          zIndex: 0,
        }}
        width='150px'
        {...props.providedItem.draggableProps}
      >
        <IconButton
          aria-label='Drag'
          {...props.providedItem.dragHandleProps}
          sx={{ width: 24, height: 24, marginRight: 1 }}
        >
          <DragIndicatorRoundedIcon
            fontSize='small'
            sx={{ color: theme => theme.palette.action.active }}
          />
        </IconButton>
        Due date
      </TableCell>}

      {key === 'Assignee' && !props.hiddenFields.includes('Assignee') &&
      <TableCell
        align='left'
        ref={props.providedItem.innerRef}
        sx={{ zIndex: 0 }}
        width='300px'
        {...props.providedItem.draggableProps}
      >
        <IconButton
          aria-label='Drag'
          size='small'
          {...props.providedItem.dragHandleProps}
          sx={{ width: 24, height: 24, marginRight: 1 }}
        >
          <DragIndicatorRoundedIcon
            fontSize='small'
            sx={{ color: theme => theme.palette.action.active }}
          />
        </IconButton>
        Assignee
      </TableCell>}

      {key === 'Priority' && !props.hiddenFields.includes('Priority') &&
      <TableCell
        align='left'
        ref={props.providedItem.innerRef}
        sx={{ zIndex: 0 }}
        width='150px'
        {...props.providedItem.draggableProps}
      >
        <IconButton
          aria-label='Drag'
          size='small'
          {...props.providedItem.dragHandleProps}
          sx={{ width: 24, height: 24, marginRight: 1 }}
        >
          <DragIndicatorRoundedIcon
            fontSize='small'
            sx={{ color: theme => theme.palette.action.active }}
          />
        </IconButton>
        Priority
      </TableCell>}

      {key === 'Status' && !props.hiddenFields.includes('Status') &&
      <TableCell
        align='left'
        ref={props.providedItem.innerRef}
        sx={{ zIndex: 0 }}
        width='150px'
        {...props.providedItem.draggableProps}
      >
        <IconButton
          aria-label='Drag'
          size='small'
          {...props.providedItem.dragHandleProps}
          sx={{ width: 24, height: 24, marginRight: 1 }}
        >
          <DragIndicatorRoundedIcon
            fontSize='small'
            sx={{ color: theme => theme.palette.action.active }}
          />
        </IconButton>
        Status
      </TableCell>}

      {key === 'Last Updated' && !props.hiddenFields.includes('Last Updated') &&
      <TableCell
        align='left'
        ref={props.providedItem.innerRef}
        sx={{ zIndex: 0 }}
        width='150px'
        {...props.providedItem.draggableProps}
      >
        <IconButton
          aria-label='Drag'
          size='small'
          {...props.providedItem.dragHandleProps}
          sx={{ width: 24, height: 24, marginRight: 1 }}
        >
          <DragIndicatorRoundedIcon
            fontSize='small'
            sx={{ color: theme => theme.palette.action.active }}
          />
        </IconButton>
        Last updated
      </TableCell>}

      {itemCustom &&
      <TableCell
        align='left'
        key={itemCustom.id}
        ref={props.providedItem.innerRef}
        sx={{ zIndex: 0 }}
        width='250px'
        {...props.providedItem.draggableProps}
      >
        <IconButton
          aria-label='Drag'
          size='small'
          {...props.providedItem.dragHandleProps}
          sx={{ width: 24, height: 24, marginRight: 1 }}
        >
          <DragIndicatorRoundedIcon
            fontSize='small'
            sx={{ color: theme => theme.palette.action.active }}
          />
        </IconButton>
        {itemCustom.title}
        <Tooltip title='Manage field'>
          <IconButton
            onClick={event => {
              event.stopPropagation()
              event.preventDefault()
              props.setManageEmbed(itemCustom.id)
              props.setAnchorElementEmbed(event.currentTarget)
            }}
            size='small'
            sx={{
              marginLeft: 1,
              width: 24,
              height: 24,
            }}
          >
            <MoreVertRoundedIcon
              sx={{
                fontSize: 24,
              }}
            />
          </IconButton>
        </Tooltip>
      </TableCell>}

      {((key !== 'Due date' &&
      key !== 'Last Updated' &&
      key !== 'Status' &&
      key !== 'Priority' &&
      key !== 'Assignee' &&
      !itemCustom) ||
      (key === 'Due date' && props.hiddenFields.includes('Due date')) ||
      (key === 'Last Updated' && props.hiddenFields.includes('Last Updated')) ||
      (key === 'Status' && props.hiddenFields.includes('Status')) ||
      (key === 'Priority' && props.hiddenFields.includes('Priority')) ||
      (key === 'Assignee' && props.hiddenFields.includes('Assignee'))) &&
      <TableCell
        align='left'
        ref={props.providedItem.innerRef}
        sx={{
          zIndex: 0,
          padding: '0px!important',
        }}
        width='0px'
        {...props.providedItem.draggableProps}
        {...props.providedItem.dragHandleProps}
      />}
    </>
  )
}

export default RenderListKeys
