/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-identical-functions */
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded'
import BorderAllRoundedIcon from '@mui/icons-material/BorderAllRounded'
import CloseIcon from '@mui/icons-material/Close'
import { Avatar, Button, Card, Dialog, IconButton, Stack, TextField, Typography } from '@mui/material'
import type { ChangeEvent } from 'react'
import { useState } from 'react'
import { v4 } from 'uuid'

import { savePartialTaskboard } from 'src/api/taskboards'
import { sendTasksboardUpdate } from 'src/api/webSocket'
import { EMBED_TYPES } from 'src/components/pages/Task/TaskViews/TaskViewComponents/AddEmbedTaskboard'
import EmbedCard from 'src/components/pages/Task/TaskViews/TaskViewComponents/EmbedCard'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { TaskBoard, TasksboardEditField } from 'src/models/Taskboard'
import { TaskBoardEdit } from 'src/models/Taskboard'

type Props = {
  close: () => void
  open: boolean
  taskboard: TaskBoard
  embedId: string
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>
  currentTab: string
}

const EditEmbedTaskboard = (props: Props) => {
  const { currentAccount } = useAuth()
  const [embedName, setEmbedName] =
    useState(props.taskboard.embeddedItems.find(item => item.id === props.embedId)?.title ?? '')
  const [embedContent, setEmbedContent] =
    useState(props.taskboard.embeddedItems.find(item => item.id === props.embedId)?.content ?? '')
  const [embedType, setEmbedType] =
  useState(props.taskboard.embeddedItems.find(item => item.id === props.embedId)?.embedType ?? '')

  const handleContentChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmbedContent(event.target.value)
  }

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmbedName(event.target.value)
  }

  const handleClick = async () => {
    const itemToEdit = props.taskboard.embeddedItems.findIndex(item => item.id === props.embedId)
    if (props.taskboard.id && itemToEdit !== -1) {
      const itemClone = Object.assign({}, props.taskboard.embeddedItems[itemToEdit])

      const oldTitle = itemClone.title
      const oldType = itemClone.embedType

      const changeTitle = oldTitle !== embedName
      const changeType = oldType !== embedType

      const updatedFields: TasksboardEditField[] = []

      if (changeTitle) {
        updatedFields.push({
          fieldName: 'Title',
          oldValue: oldTitle,
          newValue: embedName,
        })
      }

      if (changeType) {
        updatedFields.push({
          fieldName: 'Type',
          oldValue: oldType,
          newValue: embedType,
        })
      }

      props.taskboard.embeddedItems[itemToEdit].title = embedName
      props.taskboard.embeddedItems[itemToEdit].content = embedContent
      props.taskboard.embeddedItems[itemToEdit].embedType = embedType

      await savePartialTaskboard({ id: props.taskboard.id, ...props.taskboard })
        .then(() => {
          const taskUpdateItem = new TaskBoardEdit({
            id: v4(),
            taskboardId: props.taskboard.id,
            taskId: '',
            accountId: currentAccount.id,
            modificationType: 'Taskboard embed details',
            itemType: 'Field',
            title: `${itemClone.title}`,
            fields: updatedFields,
          })
          sendTasksboardUpdate(taskUpdateItem)
        })
        .then(() => {
          if (props.currentTab === props.embedId) {
            props.setCurrentTab('list')
          }
        })
        .then(props.close)
      setEmbedName('')
      setEmbedType('')
    }
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={embedType !== 'Releese Sheet' && embedType !== 'Releese Doc'}
      fullWidth
      maxWidth='xs'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Stack marginX='auto' maxWidth='lg' paddingX={3} paddingY={3} spacing={2} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          width='100%'
        >
          <Typography variant='h3'>
            Edit Item
          </Typography>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>
        <Stack spacing={4} width={1}>
          {embedType !== 'Releese Sheet' && embedType !== 'Releese Doc' &&
          <Card sx={{ width: 1 }} variant='outlined'>
            <Stack spacing={2}>
              <Stack paddingTop={2} paddingX={2}>
                <Typography variant='subtitle1'>
                  Type
                </Typography>
              </Stack>
              <Stack overflow='auto' paddingBottom={2} width={1}>
                <Stack direction='row' width='fit-content'>
                  {EMBED_TYPES.map(embedOption =>
                    <EmbedCard
                      embedOption={embedOption}
                      key={`project-${embedOption}`}
                      selected={embedType}
                      setEmbedType={setEmbedType}
                    />)}
                </Stack>
              </Stack>
            </Stack>
          </Card>}

          {embedType === 'Releese Doc' &&
          <Stack alignItems='center' height='100%' justifyContent='center'>
            <Avatar
              sx={{
                background: theme => theme.palette.info.componentBackground,
                width: 128,
                height: 128 }}
            >
              <ArticleRoundedIcon color='info' sx={{ margin: 'auto', fontSize: 96 }} />
            </Avatar>
          </Stack>}

          {embedType === 'Releese Sheet' &&
          <Stack alignItems='center' height='100%' justifyContent='center'>
            <Avatar
              sx={{
                background: theme => theme.palette.success.componentBackground,
                width: 128,
                height: 128 }}
            >
              <BorderAllRoundedIcon color='success' sx={{ margin: 'auto', fontSize: 96 }} />
            </Avatar>
          </Stack>}

          <TextField
            fullWidth
            inputProps={{ maxLength: 50 }}
            label='Title'
            onChange={handleNameChange}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                void handleClick()
              }
            }}
            placeholder='Title'
            value={embedName}
          />
          {embedType !== 'Releese Doc' && embedType !== 'Releese Sheet' &&
          <TextField
            fullWidth
            label={embedType === 'HTML' ? 'Content' : 'URL'}
            multiline
            onChange={handleContentChange}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                void handleClick()
              }
            }}
            placeholder={embedType === 'HTML' ? '<iframe></iframe>' : 'https://'}
            rows={embedType === 'HTML' ? 6 : 1}
            value={embedContent}
          />}

          <Stack direction='row' justifyContent='flex-end' marginLeft='auto' marginTop={2} spacing={1} width={1}>
            <Button
              onClick={() => props.close()}
              variant='text'
            >
              Cancel
            </Button>
            <Button
              disabled={embedName.length === 0 ||
                (embedContent.length === 0 && embedType !== 'Releese Doc' && embedType !== 'Releese Sheet') ||
                embedType.length === 0}
              onClick={() => handleClick()}
            >
              Edit item
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default EditEmbedTaskboard
