import ContactSupportIcon from '@mui/icons-material/ContactSupport'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import StoreIcon from '@mui/icons-material/Store'
import YouTubeIcon from '@mui/icons-material/YouTube'
import { Grid, Stack, Typography, useTheme } from '@mui/material'
import { useState } from 'react'

import UpdateReleaseModal from 'src/components/pages/Projects/UpdateModal/UpdateReleaseModal'
import DistributionGeneralSupportModal from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/DistributionTab/DistributionGeneralSupportModal'
import DistributionProfileSupportModal from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/DistributionTab/DistributionProfileModal'
import ViewDistributionSupportItem from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/DistributionTab/DistributionSupportItem'
import type { Distribution } from 'src/models/Distribution'
import type Project from 'src/models/Project'

type Props = {
  distributionItem: Distribution | undefined
  project: Project
}

const ViewDistributionSupport = (props: Props) => {
  const themeItem  = useTheme()
  const [profileModalOpen, setProfileModalOpen] = useState(false)
  const [metadataModalOpen, setMetadataModalOpen] = useState(false)

  const [releaseNotLive, setReleaseNoteLive] = useState(false)
  const [contentId, setContentId] = useState(false)
  const [notInStores, setNotInStores] = useState(false)
  const [other, setOther] = useState(false)

  return (
    <>
      {releaseNotLive &&
      <DistributionGeneralSupportModal
        close={() => setReleaseNoteLive(false)}
        icon={<ErrorRoundedIcon
          sx={{
            fontSize: '64px',
          }}
        />}
        open={releaseNotLive}
        project={props.project}
        title='Release not live'
      />}
      {contentId &&
      <DistributionGeneralSupportModal
        close={() => setContentId(false)}
        icon={<YouTubeIcon
          sx={{
            fontSize: '64px',
          }}
        />}
        open={contentId}
        project={props.project}
        title='YouTube Content ID'
      />}
      {notInStores &&
      <DistributionGeneralSupportModal
        close={() => setNotInStores(false)}
        icon={<StoreIcon
          sx={{
            fontSize: '64px',
          }}
        />}
        open={notInStores}
        project={props.project}
        title='Music not on certain stores'
      />}
      {other &&
      <DistributionGeneralSupportModal
        close={() => setOther(false)}
        icon={<ContactSupportIcon
          sx={{
            fontSize: '64px',
          }}
        />}
        open={other}
        project={props.project}
        title='Other issues'
      />}
      {profileModalOpen && props.distributionItem &&
      <DistributionProfileSupportModal
        close={() => setProfileModalOpen(false)}
        distributionItem={props.distributionItem}
        open={profileModalOpen}
      />}
      {metadataModalOpen &&
      <UpdateReleaseModal
        close={() => setMetadataModalOpen(false)}
        open={metadataModalOpen}
        project={props.project}
      />}
      <Stack marginX='auto' maxWidth={900} paddingBottom={4} paddingTop={2} paddingX={2} spacing={4} width={1}>
        <Stack alignItems='center' justifyContent='center' width={1}>
          <Typography lineHeight={1.2} textAlign='center' variant='h2'>
            Welcome to Releese Support
          </Typography>
          <Typography
            color={themeItem.palette.text.secondary}
            lineHeight={1.2}
            maxWidth={400}
            textAlign='center'
            variant='body2'
          >
            We&apos;re here to help. If you need assistance with your music release, explore our support resources
            or get instant help for your issue.
          </Typography>
        </Stack>
        <Stack alignItems='center' justifyContent='center' width={1}>
          <Grid container spacing={2} width={1}>
            <ViewDistributionSupportItem
              action={() => {
                setReleaseNoteLive(true)
              }}
              icon={<ErrorRoundedIcon
                sx={{
                  fontSize: '64px',
                }}
              />}
              title='Release not live'
            />
            <ViewDistributionSupportItem
              action={() => setProfileModalOpen(true)}
              icon={<PersonRoundedIcon
                sx={{
                  fontSize: '64px',
                }}
              />}
              title='Release on wrong profile'
            />
            <ViewDistributionSupportItem
              action={() => setMetadataModalOpen(true)}
              disabled={!props.distributionItem || props.distributionItem.status !== 'Distributed'}
              icon={<EditRoundedIcon
                sx={{
                  fontSize: '64px',
                }}
              />}
              title='Update my release'
            />
            <ViewDistributionSupportItem
              action={() => {
                setContentId(true)
              }}
              disabled={props.distributionItem?.platforms.findIndex(item => item.name === 'YouTube Content ID') === -1}
              icon={<YouTubeIcon
                sx={{
                  fontSize: '64px',
                }}
              />}
              title='YouTube Content ID'
            />
            <ViewDistributionSupportItem
              action={() => {
                setNotInStores(true)
              }}
              icon={<StoreIcon
                sx={{
                  fontSize: '64px',
                }}
              />}
              title='Music not on certain stores'
            />
            <ViewDistributionSupportItem
              action={() => {
                setOther(true)
              }}
              icon={<ContactSupportIcon
                sx={{
                  fontSize: '64px',
                }}
              />}
              title='Other issues'
            />
          </Grid>
        </Stack>
      </Stack>
    </>
  )
}

export default ViewDistributionSupport
