/* eslint-disable @typescript-eslint/no-non-null-assertion */
import GppGoodRoundedIcon from '@mui/icons-material/GppGoodRounded'
import { Button, Grid, Paper, Stack, Typography, useTheme } from '@mui/material'

type Props = {
  close: () => void
}

const MfaDone = (props: Props) => {
  const themeItem = useTheme()
  return (
    <Grid item>
      <Stack alignItems='center' id='handle-login' spacing={2}>
        <Stack alignItems='center' id='handle-login'>
          <Paper
            elevation={0}
            sx={{
              backgroundColor: theme => theme.palette.primary.main,
              borderRadius: 16,
            }}
          >
            <Stack
              alignItems='center'
              justifyContent='center'
              padding={4}
            >
              <GppGoodRoundedIcon
                sx={{
                  color: theme => theme.palette.primary.contrastText,
                  fontSize: '144px',
                }}
              />
            </Stack>
          </Paper>
          <Typography
            color={themeItem.palette.text.secondary}
            sx={{
              marginTop: 1,
            }}
            textAlign='center'
            variant='h4'
          >
            Two-Factor Authentication Setup
          </Typography>
          <Typography
            color={themeItem.palette.text.label}
            textAlign='center'
            variant='body2'
          >
            Your second factor was successfully added to your account
          </Typography>
          <Button
            color='primary'
            onClick={props.close}
            sx={{
              marginTop: 2,
            }}
          >
            Close
          </Button>
        </Stack>
      </Stack>
    </Grid>
  )
}

export default MfaDone
