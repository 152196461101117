export class Search {
  id?: string
  title = ''
  type = ''
  url = ''
  image = ''

  constructor(dto: Partial<Search>) {
    Object.assign(this, dto)
  }
}

export type SearchDto = Partial<Search>
