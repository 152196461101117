/* eslint-disable react/jsx-props-no-spreading */
import ImageRoundedIcon from '@mui/icons-material/ImageRounded'
import { Card, CircularProgress, Paper, Stack, Typography, useTheme } from '@mui/material'
import type { PlateRenderElementProps, TElement } from '@udecode/plate'
import { findNodePath, useEditorState } from '@udecode/plate'
import type { ChangeEvent } from 'react'
import { useState } from 'react'
import { Transforms } from 'slate'

import { sendImage } from 'src/api/conversations'
import { FileUploadNotesButtonImage } from 'src/components/form-elements/FileUpload'
import type { MyValue } from 'src/components/pages/Notes/Modern/typescript/plateTypes'

export const ImageElement = (props: PlateRenderElementProps<MyValue, TElement>) => {
  const editor = useEditorState()
  const theme = useTheme()
  const { url, loading } = props.element
  const loadingTyped = loading as boolean | undefined
  const urlTyped = url as string
  const [errorMessage, setErrorMessage] = useState<string>()

  const  uploadFileItem = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) return

    if ((/\.(jpg|png|jpeg|webp)$/i).test(file.name)) {
      const pathItem = findNodePath(editor, props.element)
      const newPropertiesItem = {
        loading: true,
      }
      Transforms.setNodes(editor, newPropertiesItem, {
        at: pathItem,
      })

      await sendImage(file)
        .then(newUrl => {
          const path = findNodePath(editor, props.element)
          const newProperties = {
            url: newUrl,
            loading: false,
          }
          Transforms.setNodes(editor, newProperties, {
            at: path,
          })
        })
        .catch(() => {
          const pathItem2 = findNodePath(editor, props.element)
          const newPropertiesItem2 = {
            loading: false,
          }
          Transforms.setNodes(editor, newPropertiesItem2, {
            at: pathItem2,
          })
          setErrorMessage('Please upload an image')
        })
    } else {
      setErrorMessage('Please upload an image')
    }
  }

  return (
    <div
      {...props.attributes}
      {...props.nodeProps}
      className={props.className}
    >
      <div contentEditable={false} style={{ position: 'relative', userSelect: 'none' }}>
        {loadingTyped !== undefined
          ? !loadingTyped
            ? <img
              alt='import'
              src={urlTyped}
              style={{
                width: '100%',
              }}
            />
            : <Card sx={{ widht: 1 }} variant='outlined'>
              <Stack alignItems='center' justifyContent='center' padding={2} width={1}>
                <Paper
                  elevation={0}
                  sx={{
                    backgroundColor: theme.palette.primary.subtleResting,
                    borderRadius: 16,
                  }}
                >
                  <Stack
                    alignItems='center'
                    justifyContent='center'
                    padding={4}
                  >
                    <CircularProgress variant='indeterminate' />
                  </Stack>
                </Paper>
              </Stack>
            </Card>
          : <Card sx={{ widht: 1 }} variant='outlined'>
            <Stack alignItems='center' justifyContent='center' padding={2} width={1}>
              <Paper
                elevation={0}
                sx={{
                  backgroundColor: theme.palette.primary.subtleResting,
                  borderRadius: 16,
                }}
              >
                <Stack
                  alignItems='center'
                  justifyContent='center'
                  padding={4}
                >
                  <ImageRoundedIcon
                    sx={{
                      color: theme.palette.primary.main,
                      fontSize: '144px',
                    }}
                  />
                </Stack>
              </Paper>
              <Typography
                color={theme.palette.text.secondary}
                sx={{
                  marginTop: 1,
                }}
                variant='h4'
              >
                This could be an image
              </Typography>
              <Typography
                color={theme.palette.text.label}
                variant='body1'
              >
                Upload your image to display it here
              </Typography>
              <Stack alignItems='center' justifyContent='center' padding={2} width={1}>
                <FileUploadNotesButtonImage
                  id='upload-image'
                  onChange={uploadFileItem}
                >
                  Pick an image
                </FileUploadNotesButtonImage>
              </Stack>
              {errorMessage &&
              <Typography
                color={theme.palette.error.main}
                variant='body1'
              >
                {errorMessage}
              </Typography>}
            </Stack>
          </Card>}
      </div>
      {props.children}
    </div>
  )
}
