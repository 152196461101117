import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded'
import { Stack, Typography } from '@mui/material'

const OnboardingError = () =>
  <Stack alignItems='center' spacing={1} width={1}>
    <ErrorRoundedIcon color='error' sx={{ height: 64, width: 64 }} />
    <Typography textAlign='center' variant='body2'>
      There was an error adding your payout method, please verify your details and try again
    </Typography>
  </Stack>

export default OnboardingError
