/* eslint-disable max-len */
import { Icon } from '@mui/material'

import hungamaImage from 'src/components/Icons/hungama.png'

const HungamaLogo = () =>
  <Icon sx={{ height: 26, width: 24, fontSize: '1rem', overflow: 'visible' }}>
    <img alt='Hungama Logo' height='100%' src={hungamaImage} style={{ marginLeft: 2 }} width='100%' />
  </Icon>

export default HungamaLogo
