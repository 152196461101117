import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded'
import { Button, Dialog, Stack, Typography, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import OrganizationSelect from 'src/components/form-elements/OrganizationSelectField'
import { auth, parseError } from 'src/utils/firebase'

type Props = {
  close: () => void
  open: boolean
}

const MaxSeatsModal = (props: Props) => {
  const themeItem = useTheme()
  const navigate = useNavigate()
  const handleLogout = () =>
    auth.signOut()
      .then(() => navigate('/Login', { replace: true }))
      .catch(parseError)

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      onClose={props.close}
      open={props.open}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        overflow: 'scroll',
      }}
    >
      <Stack alignItems='center' minWidth={400} paddingX={3} paddingY={3} spacing={2}>
        <ErrorRoundedIcon color='error' sx={{ fontSize: 96 }} />
        <Typography textAlign='center' variant='subtitle1'>
          Organisation error
        </Typography>
        <Typography color={themeItem.palette.text.secondary} textAlign='center' variant='body2'>
          The organisation you are trying to access has more used seats than allowed in the current billing
          cycle, please contact your administrator to regain access to this organisation or select another organisation.
        </Typography>
        <OrganizationSelect />
        <Stack direction='row' justifyContent='center' spacing={1} width='100%'>
          <Button onClick={handleLogout}>
            Log Out
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default MaxSeatsModal
