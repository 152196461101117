/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-component-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Alert } from '@mui/material'
import type { PlateRenderElementProps, TElement } from '@udecode/plate'
import { createPluginFactory } from '@udecode/plate'

import type { MyValue } from 'src/components/pages/Notes/Modern/typescript/plateTypes'

export const ELEMENT_ALERT_WARNING = 'warning'

export const WarningElement = (props: PlateRenderElementProps<MyValue, TElement>) =>
  <Alert
    {...props.attributes}
    {...props.nodeProps}
    className={props.className}
    severity='warning'
    sx={{
      marginY: 1,
    }}
    variant='filled'
  >
    {props.children}
  </Alert>

export const createWarningPlugin = createPluginFactory({
  key: ELEMENT_ALERT_WARNING,
  isElement: true,
  component: WarningElement,
})
