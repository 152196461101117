import SimpleTour from 'src/components/pages/Tour/TourComponents/SimpleTour'

const CreateReleaseTour = () =>
  <SimpleTour
    description='Let&apos;s keep things easy, click here to create your first release.'
    desktopDirection='left'
    mobileDirection='bottom'
    onboardingKey='releaseCreate'
    selector='create-release'
    title='Create your first release'
  />

export default CreateReleaseTour
