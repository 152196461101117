/* eslint-disable sonarjs/no-duplicate-string */
import AddIcon from '@mui/icons-material/AddRounded'
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded'
import InsightsRoundedIcon from '@mui/icons-material/InsightsRounded'
import LibraryMusicRoundedIcon from '@mui/icons-material/LibraryMusicRounded'
import MenuIcon from '@mui/icons-material/Menu'
import PhonelinkRoundedIcon from '@mui/icons-material/PhonelinkRounded'
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded'
import { BottomNavigation, BottomNavigationAction, Fab, ListItemIcon, Menu, MenuItem } from '@mui/material'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'

import NewLinkModal from 'src/components/pages/Links/LinksList/LinksListModal/NewLinkModal'
import NewProjectModal from 'src/components/pages/Projects/EditProject/NewProjectModal'
import NewTaskModal from 'src/components/pages/Task/TaskDrawer/NewTaskModal'
import NewTaskboardModal from 'src/components/pages/Task/TaskMenu/TaskMenuModals/NewTaskboardModal'
import { useAuth } from 'src/components/providers/AuthProvider'
import { useMessenger } from 'src/components/providers/MessengerProvider'
import { useNotifications } from 'src/components/providers/NotificationProvider'
import { Permissions } from 'src/models/Organisation'

type Props = {
  setMobileMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const MobileBottomNav = (props: Props) => {
  const { conversations } = useMessenger()
  const { notifications } = useNotifications()
  const { currentAccountPermissions, currentAccount } = useAuth()

  const location = useLocation()
  const navigate = useNavigate()

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const openMenu = Boolean(anchorElement)
  const [newProjectModalOpen, setNewProjectModalOpen] = useState(false)
  const [newLinkModal, setNewLinkModalOpen] = useState(false)
  const [newTaskboardModal, setNewTaskboardModal] = useState(false)
  const [newTaskModal, setNewTaskModal] = useState(false)

  const handleClose = () => {
    setAnchorElement(null)
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget)
  }

  const unreadNotifications = notifications.filter(notif => !notif.read).length

  const unreadConversations = conversations.filter(convo =>
    convo.lastMessage.content !== '' &&
    convo.lastMessage.senderId !== currentAccount.id &&
    !convo.lastMessage.readByAccountIds.includes(currentAccount.id)).length

  useEffect(() => {
    document.title = unreadNotifications + unreadConversations === 0
      ? 'Releese'
      : `(${unreadNotifications + unreadConversations}) Releese`
  }, [unreadNotifications, unreadConversations])

  return (
    <>
      <BottomNavigation
        onChange={(event, newValue) => {
          if (newValue as string !== '/mobilemenu') {
            navigate(newValue as string)
          }
        }}
        showLabels
        sx={{
          height: '64px',
          '.MuiSvgIcon-root': {
            fontSize: '2em',
            marginBottom: 0.5,
          },
          '.MuiBottomNavigationAction-label': {
            fontSize: '1em!important',
          },
          alignItems: 'center',
          display: {
            xl: 'none',
            lg: 'none',
            md: 'none',
            sm: 'flex',
            xs: 'flex',
          },
          '.MuiBottomNavigationAction-root': {
            height: '100%',
          },
        }}
        value={location.pathname}
      >
        <BottomNavigationAction
          icon={<HomeRoundedIcon />}
          label='Home'
          value='/'
        />
        <BottomNavigationAction
          icon={<LibraryMusicRoundedIcon />}
          id='projects'
          label='Releases'
          value='/projects/projects'
        />
        <Fab
          id='create'
          onClick={handleClick}
          size='medium'
          sx={{
            marginX: '4px',
            boxShadow: 0,
            top: '-24px',
            '.MuiSvgIcon-root': {
              fontSize: '2em!important',
              marginBottom: '0px!important',
            },
          }}
        >
          <AddIcon />
        </Fab>
        <BottomNavigationAction
          icon={<InsightsRoundedIcon />}
          label='Audience'
          value='/audience/overview'
        />
        <BottomNavigationAction
          icon={<MenuIcon />}
          label='More'
          onClick={event => {
            event.preventDefault()
            event.stopPropagation()
            props.setMobileMenuOpen(true)
          }}
          value='/mobilemenu'
        />
      </BottomNavigation>

      {newLinkModal &&
      <NewLinkModal
        close={() => setNewLinkModalOpen(false)}
        open={newLinkModal}
      />}
      <NewProjectModal
        close={() => setNewProjectModalOpen(false)}
        open={newProjectModalOpen}
      />
      {newTaskModal &&
      <NewTaskModal
        close={() => setNewTaskModal(false)}
        open={newTaskModal}
      />}
      {newTaskboardModal &&
      <NewTaskboardModal
        close={() => setNewTaskboardModal(false)}
        open={newTaskboardModal}
      />}
      <Menu
        anchorEl={anchorElement}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClick={handleClose}
        onClose={handleClose}
        open={openMenu}
        transformOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        <MenuItem
          disabled={!currentAccountPermissions?.includes(Permissions.CREATE_EDIT_REMOVE_PROJECTS)}
          onClick={() => setNewProjectModalOpen(true)}
        >
          <ListItemIcon>
            <LibraryMusicRoundedIcon color='primary' fontSize='medium' />
          </ListItemIcon>
          Release
        </MenuItem>
        <MenuItem
          disabled={!currentAccountPermissions?.includes(Permissions.VIEW_TASKBOARDS)}
          onClick={() => setNewTaskModal(true)}
        >
          <ListItemIcon>
            <TaskAltRoundedIcon color='success' fontSize='medium' />
          </ListItemIcon>
          Task
        </MenuItem>
        <MenuItem
          disabled={!currentAccountPermissions?.includes(Permissions.VIEW_TASKBOARDS)}
          onClick={() => setNewTaskboardModal(true)}
        >
          <ListItemIcon>
            <AssignmentRoundedIcon color='secondary' fontSize='medium' />
          </ListItemIcon>
          Taskboard
        </MenuItem>
        <MenuItem
          disabled={!currentAccountPermissions?.includes(Permissions.CREATE_EDIT_REMOVE_MARKETING_LINKS)}
          onClick={() => setNewLinkModalOpen(true)}
        >
          <ListItemIcon>
            <PhonelinkRoundedIcon color='warning' fontSize='medium' />
          </ListItemIcon>
          Smart Link
        </MenuItem>
      </Menu>
    </>
  )
}
export default MobileBottomNav
