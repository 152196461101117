/* eslint-disable max-len */
/* eslint-disable id-length */
/* eslint-disable no-multi-str */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import PaidRoundedIcon from '@mui/icons-material/PaidRounded'
import { Card, CardActionArea, Stack, Typography, useTheme } from '@mui/material'
import { useState } from 'react'

import ExplainModal from 'src/components/pages/Analytics/ExplainModal'
import { convertMonthToString } from 'src/components/pages/Earnings/EarningsDistribution/Overview/EarningsOverviewNewChart'
import type { AnalyticsMonthlyChart } from 'src/models/Analytics'

type Props = {
  readonly netIncome: AnalyticsMonthlyChart[]
  readonly grossData: AnalyticsMonthlyChart[]
  readonly selectedRecording: string
  readonly isOwner: boolean
  readonly periods: string[]
}

const MyEarningsDistributionLifetime = (props: Props) => {
  const themeItem = useTheme()
  const [explain, setExplain] = useState(false)
  const arrayGross = props.grossData.filter(item => props.periods.length === 0 || props.periods.includes(item._id))
    .map(item => ({
      x: `${item._id.toUpperCase()}`,
      y: item.amount,
    })).sort((a, b) => Number(new Date(a.x)) - Number(new Date(b.x)))

  const arrayNet = props.netIncome
    .map(item => {
      const dateItem = new Date(item._id)
      const newDate = new Date(dateItem.setMonth(dateItem.getMonth() - 2))
      const stringItem =
        `${(newDate.getMonth() + 1).toString().padStart(2, '0')}-${convertMonthToString((newDate.getMonth() + 1).toString())}-${newDate.getFullYear()}`
      const amountItem = arrayGross.find(arrayItem => arrayItem.x === stringItem)?.y ?? 0

      return {
        x: stringItem,
        y: props.isOwner
          ? amountItem - Number(item.amount)
          : item.amount,
      }
    })
    .sort((a, b) => Number(new Date(a.x)) - Number(new Date(b.x)))

  const arrayTotal = arrayGross.map(item => {
    const amountItem = props.isOwner
      ? arrayNet.find(arrayItem => arrayItem.x === item.x)?.y ?? item.y
      : arrayNet.find(arrayItem => arrayItem.x === item.x)?.y ?? 0
    return {
      x: item.x,
      y: amountItem,
    }
  })

  const totalEarnings = arrayTotal
    .filter(item => props.periods.length === 0 || props.periods.includes(item.x))
    .map(item => item.y)
    .reduce((partialSum, a) => partialSum + a, 0) ?? 0

  return (
    <Stack height={1} width={1}>
      <Card
        elevation={0}
        sx={{
          height: 1,
          background: theme => theme.palette.background.elevatedCard,
        }}
      >
        <CardActionArea onClick={() => setExplain(true)}>
          <Stack height={1} justifyContent='space-between' padding={2} spacing={3}>
            <Stack direction='row' spacing={2} width={1}>
              <Stack spacing={2} width={1}>
                <Typography variant='body1'>
                  Net income
                </Typography>
                <Typography variant='h3'>
                  {new Intl.NumberFormat('en-US',
                    { style: 'currency', currency: 'USD' }).format(totalEarnings)}
                </Typography>
              </Stack>
              <Stack spacing={2}>
                <PaidRoundedIcon color='success' sx={{ fontSize: 48 }} />
              </Stack>
            </Stack>
            <Typography variant='textLabel'>
              {props.selectedRecording.length > 0
                ? 'Your share of distribution earnings since the release of this recording'
                : 'Your share of distribution earnings'}
            </Typography>
          </Stack>
        </CardActionArea>
      </Card>
      <ExplainModal
        close={() => setExplain(false)}
        color={themeItem.palette.success.main}
        description='Net income refer to the income you have received on releases you are a shareholder of.
        This amount includes recoups if you are a project owner. To view total earnings on releases, refer to the
        Gross Income.'
        icon={<PaidRoundedIcon
          sx={{
            color: theme => theme.palette.primary.contrastText,
            fontSize: '64px',
          }}
        />}
        open={explain}
        title='Net income'
      />
    </Stack>
  )
}

export default MyEarningsDistributionLifetime

