/* eslint-disable sonarjs/no-ignored-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable max-lines */
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import CalendarViewMonthRoundedIcon from '@mui/icons-material/CalendarViewMonthRounded'
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded'
import FolderRoundedIcon from '@mui/icons-material/FolderRounded'
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded'
import TableViewRoundedIcon from '@mui/icons-material/TableViewRounded'
import ViewKanbanRoundedIcon from '@mui/icons-material/ViewKanbanRounded'
import ViewListRoundedIcon from '@mui/icons-material/ViewListRounded'
import ViewTimelineRoundedIcon from '@mui/icons-material/ViewTimelineRounded'
import { TabContext, TabList } from '@mui/lab'
import { Button, Divider, IconButton, Skeleton, Stack, Tab, Table, TableCell, TableHead, TableRow, TextField, useMediaQuery, useTheme } from '@mui/material'

import SearchField from 'src/components/form-elements/SearchField'
import { NavLink } from 'src/components/SafeRouterLink'
import type Project from 'src/models/Project'

type Props = {
  project?: Project
}

const TaskBoardLoading = (props: Props) => {
  const themeItem = useTheme()
  const matches = useMediaQuery(themeItem.breakpoints.down('md'))
  return (
    <Stack
      alignItems='stretch'
      boxSizing='border-box'
      direction='column'
      height={1}
      minWidth={0}
      padding={0}
      width={1}
    >
      <Stack
        alignItems='center'
        direction='row'
        height={!props.project ? 88 : 48}
        justifyContent='space-between'
        minHeight={!props.project ? 88 : 48}
        paddingLeft={2}
        paddingRight={2}
        sx={{
          overflowX: 'auto',
          overflowY: 'clip',
          alignItems: 'end',
        }}
        width={1}
      >
        <Stack alignItems='flex-end' direction='row' spacing={2}>
          {matches &&
          <IconButton
            color='primary'
            component={NavLink}
            size='small'
            to='/calendar'
          >
            <ChevronLeftRoundedIcon />
          </IconButton>}

          {!props.project &&
          <Skeleton height={64} sx={{ borderRadius: 1 }} variant='rectangular' width={64} />}

          <Stack
            alignItems='flex-end'
            direction='column'
            height={!props.project ? 80 : 32}
            justifyContent='space-between'
          >
            {!props.project &&
            <Stack height={1} justifyContent='center'>
              <Skeleton variant='text' width={150} />
            </Stack>}
            <TabContext value='0'>
              <TabList
                aria-label='Current taskboard view'
                sx={{ minHeight: '32px' }}
              >
                <Tab
                  icon={<ViewListRoundedIcon />}
                  iconPosition='start'
                  label='List'
                  sx={{ minHeight: 32 }}
                />
                <Tab
                  icon={<ViewKanbanRoundedIcon />}
                  iconPosition='start'
                  label='Board'
                  sx={{ minHeight: 32 }}
                />
                <Tab
                  icon={<CalendarViewMonthRoundedIcon />}
                  iconPosition='start'
                  label='Calendar'
                  sx={{ minHeight: 32 }}
                />
                <Tab
                  icon={<ViewTimelineRoundedIcon />}
                  iconPosition='start'
                  label='Timeline'
                  sx={{ minHeight: 32 }}
                />
                {!props.project &&
                <Tab
                  icon={<FolderRoundedIcon />}
                  iconPosition='start'
                  label='Files'
                  sx={{ minHeight: 32 }}
                />}
                <Tab
                  icon={<TableViewRoundedIcon />}
                  iconPosition='start'
                  label='Dashboard'
                  sx={{ minHeight: 32 }}
                />
                <Tab
                  icon={<HistoryRoundedIcon />}
                  iconPosition='start'
                  label='History'
                  sx={{ minHeight: 32 }}
                />
              </TabList>
            </TabContext>
          </Stack>
        </Stack>
        <Stack alignItems='center' direction='row' spacing={1}>
          <Stack alignItems='center' direction='row' spacing={2} />
        </Stack>
      </Stack>
      <Divider style={{ width: '100%' }} />
      <Stack
        alignItems='center'
        direction='row'
        justifyContent='space-between'
        padding={2}
        spacing={2}
      >
        <Stack direction='row' spacing={2}>
          <Button
            disabled
            startIcon={<AddRoundedIcon />}
            sx={{ width: 110 }}
          >
            Add task
          </Button>
          <SearchField
            sx={{ width: 160, marginTop: 0, marginRight: 2 }}
          />
          <Stack width={200}>
            <TextField
              fullWidth
              sx={{ marginTop: 0 }}
            />
          </Stack>
          <Stack width={200}>
            <TextField
              fullWidth
              sx={{ marginTop: 0 }}
            />
          </Stack>
        </Stack>
        <Stack alignItems='center' direction='row' spacing={2}>
          <Skeleton height={24} variant='circular' width={24} />
        </Stack>
      </Stack>
      <Stack sx={{ width: 1, height: 1, overflow: 'auto' }}>
        <Stack direction='column' justifyContent='flex-start' minWidth={1150}>
          <Table aria-label='projects table' size='small' stickyHeader>
            <TableHead sx={{
              '.MuiTableCell-root': {
                borderBottom: 2,
                borderBottomColor: 'divider',
                color: 'text.secondary',
              },
            }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    position: 'sticky',
                    left: 0,
                  }}
                  width={48}
                />
                <TableCell
                  align='left'
                  sx={{
                    position: 'sticky',
                    left: '48px',
                  }}
                >
                  Task name
                </TableCell>
                <TableCell
                  align='left'
                  sx={{
                    zIndex: 0,
                  }}
                  width='150px'
                >
                  Due date
                </TableCell>
                <TableCell
                  align='left'
                  sx={{ zIndex: 0 }}
                  width='225px'
                >
                  Assignee
                </TableCell>
                <TableCell
                  align='left'
                  sx={{ zIndex: 0 }}
                  width='150px'
                >
                  Priority
                </TableCell>
                <TableCell
                  align='left'
                  sx={{ zIndex: 0 }}
                  width='150px'
                >
                  Status
                </TableCell>
                <TableCell
                  align='left'
                  sx={{ zIndex: 0 }}
                  width='150px'
                >
                  Last updated
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Stack paddingX={2} paddingY={1} sx={{ position: 'sticky', left: 0 }} width='fit-content'>
            <Button
              color='secondary'
              sx={{ width: 'fit-content' }}
              variant='text'
            >
              + Add a section
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default TaskBoardLoading
