/* eslint-disable no-await-in-loop */
/* eslint-disable sonarjs/no-identical-functions */
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded'
import { LoadingButton } from '@mui/lab'
import { Autocomplete, Box, Button, Card, Divider, Grid, Stack, TextField, Typography } from '@mui/material'
import { useState } from 'react'

import { gptText } from 'src/api/taskboards'
import SearchArtistComponent from 'src/components/form-elements/SearchArtists'
import BillingModal from 'src/components/pages/Account/Billing/BillingModal'
import { useAuth } from 'src/components/providers/AuthProvider'
import { countries } from 'src/data/countriesData'
import type { ArtistObject } from 'src/models/Distribution'
import type Project from 'src/models/Project'
import type Recording from 'src/models/Recording'

type Props = {
  readonly marketing: string
  readonly artist: string
  readonly priorityTitle: string
  readonly country: string
  readonly fanbaseLocation: string
  readonly genre: string
  readonly similarArtists: ArtistObject[]
  readonly recordings: Recording[]
  readonly project: Project
  readonly setMarketing: React.Dispatch<React.SetStateAction<string>>
  readonly setArtist: React.Dispatch<React.SetStateAction<string>>
  readonly setPriorityTitle: React.Dispatch<React.SetStateAction<string>>
  readonly setCountry: React.Dispatch<React.SetStateAction<string>>
  readonly setFanbaseLocation: React.Dispatch<React.SetStateAction<string>>
  readonly setGenre: React.Dispatch<React.SetStateAction<string>>
  readonly setSimilarArtists: React.Dispatch<React.SetStateAction<ArtistObject[]>>
}

const timeout = (delay: number) => new Promise(response => setTimeout(response, delay))

const PitchDetails = (props: Props) => {
  const { currentOrganisation } = useAuth()

  const setSelectedArtists = (values: ArtistObject[] | undefined) => {
    props.setSimilarArtists(values ?? [])
  }
  const [billingModal, setBillingModalOpen] = useState(false)
  const [loadingGpt, setLoadingGpt] = useState(false)
  const [doneGpt, setDoneGpt] = useState(false)
  const [blurb, setBlurb] = useState<string[]>([])
  // eslint-disable-next-line max-len
  const [prompt, setPrompt] = useState(`Write a marketing pitch for a ${props.genre} song titled “${props.project.title}” and explain why it should be added to playlists and get supported by blogs.`)

  const generatePitch = async () => {
    setLoadingGpt(true)
    await gptText(prompt)
      .then(async textItems => {
        for (const textItem of textItems) {
          if (textItem.length > 0) {
            setBlurb(oldValue => oldValue.length > 0 ? [...oldValue, textItem] : [textItem])
          }
          await timeout(125)
        }
      })
      .then(() => {
        setDoneGpt(true)
        setLoadingGpt(false)
      })
      .catch(() => {
        setDoneGpt(true)
        setLoadingGpt(false)
      })
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={8} md={6} sm={12} xl={8} xs={12}>
          <Card elevation={0}>
            <Stack
              direction='column'
              padding={2}
              spacing={4}
              width={1}
            >
              <Stack marginBottom={2} maxWidth='sm' width={1}>
                <Typography variant='h3'>
                  General information
                </Typography>
                <Typography color='text.label' variant='body2'>
                  We use this information to pitch your project for editorial playlist placements, sync
                  placements and general marketing opportunities. This form does not replace your Spotify for Artists
                  pitch.
                </Typography>
              </Stack>
              <Autocomplete
                autoHighlight
                autoSelect
                fullWidth
                getOptionLabel={option => option}
                onChange={(_, newValue) => props.setPriorityTitle(newValue ?? '')}
                options={props.recordings.map(item => item.title)}
                renderInput={params =>
                  <TextField
                    {...params}
                    label='Priority track'
                    placeholder='What is your highest priority track?'
                    sx={{
                      marginTop: 0,
                    }}
                  />}
                renderOption={(renderProps, option) =>
                  <Box component='li' {...renderProps}>
                    {option}
                  </Box>}
                value={props.priorityTitle}
              />
              <Stack marginTop='16px!important' width={1}>
                <SearchArtistComponent
                  disabled={false}
                  error={false}
                  label='Similar artists'
                  placeholder='Search at least 3 similar artists'
                  selectedArtists={props.similarArtists}
                  setSelectedArtists={setSelectedArtists}
                />
              </Stack>
              <TextField
                fullWidth
                label='Track genre'
                onChange={event => props.setGenre(event.target.value)}
                placeholder='What genre qualifies your track best?'
                value={props.genre}
              />
              <Autocomplete
                autoHighlight
                autoSelect
                fullWidth
                getOptionLabel={option => option.countryName}
                onChange={(_, newValue) => props.setCountry(newValue?.countryShortCode ?? '')}
                options={countries}
                renderInput={params =>
                  <TextField
                    {...params}
                    label='Artist country'
                    placeholder='What country does the primary artist call home?'
                    sx={{
                      marginTop: 0,
                    }}
                  />}
                renderOption={(renderProps, option) =>
                  <Box component='li' {...renderProps}>
                    {option.countryName}
                  </Box>}
                value={countries.find(
                  countryObject => countryObject.countryShortCode === props.country
                )}
              />
              <Autocomplete
                autoHighlight
                autoSelect
                fullWidth
                getOptionLabel={option => option.countryName}
                onChange={(_, newValue) => props.setFanbaseLocation(newValue?.countryShortCode ?? '')}
                options={countries}
                renderInput={params =>
                  <TextField
                    {...params}
                    label='Primary fanbase location'
                    placeholder='Select a country'
                    sx={{
                      marginTop: 0,
                    }}
                  />}
                renderOption={(renderProps, option) =>
                  <Box component='li' {...renderProps}>
                    {option.countryName}
                  </Box>}
                value={countries.find(
                  countryObject => countryObject.countryShortCode === props.fanbaseLocation
                )}
              />
              <TextField
                fullWidth
                label='Artist details'
                minRows={6}
                multiline
                onChange={event => props.setArtist(event.target.value)}
                placeholder='Give us some information about your/their journey as an artist/band and what led them to
              create this track. You can also include success stories, playlist additions and relevant collaborations.'
                value={props.artist}
              />
              <TextField
                fullWidth
                label='Marketing details'
                minRows={6}
                multiline
                onChange={event => props.setMarketing(event.target.value)}
                placeholder='Describe how you are going to bring your music in front of an audience. This can be
            advertising, pre-save campaigns, email blasts, or a premiere. What makes this track stand out?
            Why should stores add it to a playlist? Where else is the track being promoting?'
                value={props.marketing}
              />
            </Stack>
          </Card>
        </Grid>

        <Grid item lg={4} md={6} sm={12} xl={4} xs={12}>
          <Card elevation={0}>
            <Stack padding={2} spacing={2} width={1}>
              <Typography
                sx={{
                  color: theme => theme.palette.text.label,
                }}
                variant='body2'
              >
                <AutoAwesomeRoundedIcon />
                {' '}
                AI Assisted Marketing Pitch
              </Typography>

              <form
                onSubmit={event => {
                  event.preventDefault()
                  void generatePitch()
                }}
              >
                <Stack spacing={2} width={1}>
                  <TextField
                    disabled={doneGpt ||
                    loadingGpt ||
                    !currentOrganisation ||
                    currentOrganisation.membershipTier === 'FREE'}
                    fullWidth
                    minRows={3}
                    multiline
                    onChange={event => setPrompt(event.target.value)}
                    sx={{
                      marginTop: 0,
                    }}
                    value={prompt}
                  />

                  <LoadingButton
                    color='info'
                    disabled={doneGpt ||
                    !currentOrganisation ||
                    currentOrganisation.membershipTier === 'FREE'}
                    loading={loadingGpt}
                    startIcon={<AutoAwesomeRoundedIcon />}
                    sx={{
                      width: 'fit-content',
                    }}
                    type='submit'
                    variant='contained'
                  >
                    Generate blurb
                  </LoadingButton>
                </Stack>
              </form>

              {blurb.length > 0 &&
              <>
                <Divider />

                {blurb.map((item, index) =>
                  <Typography
                    key={`${index.toString()}`}
                    variant='body1'
                  >
                    {item}
                  </Typography>)}
              </>}

              {currentOrganisation?.membershipTier === 'FREE' &&
              <>
                <Divider />
                <Typography
                  variant='body1'
                >
                  Write compelling marketing pitches using the power of AI
                  directly on Releese.
                </Typography>

                <Button
                  onClick={() => setBillingModalOpen(true)}
                  sx={{
                    color: 'white!important',
                    background: '#FFA500',
                    ':hover': {
                      background: '#c47f00',
                    },
                  }}
                >
                  Explore plans
                </Button>
              </>}

            </Stack>
          </Card>
        </Grid>

      </Grid>

      {billingModal && currentOrganisation &&
      <BillingModal
        account={currentOrganisation}
        close={() => setBillingModalOpen(false)}
        open={billingModal}
      />}
    </>
  )
}

export default PitchDetails
