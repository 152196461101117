/* eslint-disable sonarjs/no-duplicate-string */
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import DragIndicatorRoundedIcon from '@mui/icons-material/DragIndicatorRounded'
import { LoadingButton } from '@mui/lab'
import { Button, Card, IconButton, Menu, MenuItem, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import type { DropResult } from 'react-beautiful-dnd'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

import GetIcon from 'src/components/pages/SmartLink/Content/GetIcon'
import { useAuth } from 'src/components/providers/AuthProvider'
import { SmartLinkSocial } from 'src/data/smartLinkData'
import type { SocialList } from 'src/models/Marketing'

type Props = {
  close?: () => void
}

const EditOrgLinkSocial = (props: Props) => {
  const { currentOrganisation, updateCurrentOrganisation } = useAuth()
  const [saving, setSaving] = useState(false)
  const [inputList, setInputList] = useState<SocialList[]>([])
  const filteredInputs = SmartLinkSocial.filter(item => !inputList.some(selected => selected.label === item.label))

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const openMenu = Boolean(anchorElement)

  const handleClose = () => {
    setAnchorElement(null)
  }

  useEffect(() => {
    if (currentOrganisation) {
      setInputList(currentOrganisation.defaultSocialList)
    }
  }, [currentOrganisation, currentOrganisation?.id])

  const findInputIndex = (labelFound: string) => inputList.findIndex(element => element.label === labelFound)

  const onDeleteByIndex = (event: React.MouseEvent<HTMLElement>) => {
    const index = findInputIndex(event.currentTarget.id)
    const temporaryInputList = [...inputList]
    temporaryInputList[index].value = ''
    temporaryInputList.splice(index, 1)
    setInputList(temporaryInputList)
  }

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const index = findInputIndex(event.target.id)
    const temporaryData = [...inputList]
    temporaryData[index].value = event.target.value
    setInputList(temporaryData)
  }

  const reorder = (list: SocialList[], startIndex: number, endIndex: number) => {
    const result = [...list]
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    } else if (result.destination.droppableId) {
      setInputList(reorder(inputList, result.source.index, result.destination.index))
    }
  }

  const save = async () => {
    setSaving(true)
    await updateCurrentOrganisation({ ...currentOrganisation, defaultSocialList: inputList })
      .then(() => {
        setSaving(false)
        if (props.close) {
          props.close()
        }
      })
  }

  return (
    <>
      <Stack direction='column' height={1} maxWidth={896} overflow='auto' padding={2} spacing={4}>
        <Stack alignItems='center' direction='row' justifyContent='space-between' spacing={2}>
          <Button
            fullWidth
            onClick={event => setAnchorElement(event.currentTarget)}
            startIcon={<AddRoundedIcon />}
          >
            Add Social Icon
          </Button>
        </Stack>
        <Stack direction='column' spacing={2}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='presaves' key='presaves'>
              {provided => <Stack {...provided.droppableProps} direction='column' ref={provided.innerRef} spacing={1}>
                {inputList.map((element, index) =>
                  <Draggable
                    draggableId={element.label}
                    index={index}
                    key={element.label}
                  >
                    {providedDrag =>
                      <Stack
                        key={element.label}
                        paddingTop={2}
                        ref={providedDrag.innerRef}
                        {...providedDrag.draggableProps}

                      >
                        <Card
                          variant='outlined'
                        >
                          <Stack
                            direction='column'
                            padding={2}
                            spacing={2}
                          >
                            <Stack
                              alignItems='center'
                              direction='row'
                              justifyContent='space-between'
                              spacing={2}
                            >
                              <Tooltip title='Drag to move'>
                                <IconButton {...providedDrag.dragHandleProps} size='small'>
                                  <DragIndicatorRoundedIcon
                                    fontSize='medium'
                                    sx={{
                                      color: theme => theme.palette.action.active,
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>

                              <IconButton
                                color='default'
                                disableRipple
                              >
                                {GetIcon(element.label)}
                              </IconButton>
                              <TextField
                                autoComplete='new-password'
                                fullWidth
                                id={element.label}
                                onChange={handleValueChange}
                                placeholder={element.label}
                                sx={{ marginTop: 0 }}
                                value={(inputList[index].value)}
                              />
                              <Tooltip title='Remove icon'>
                                <IconButton
                                  color='error'
                                  id={element.label}
                                  onClick={onDeleteByIndex}
                                  size='small'
                                >
                                  <CloseRoundedIcon />
                                </IconButton>
                              </Tooltip>
                            </Stack>

                            {!inputList[index].value.startsWith('https://') &&
                            <Typography color='error' variant='helperText'>
                              Please make sure your link starts with https://
                            </Typography>}
                          </Stack>
                        </Card>
                      </Stack>}
                  </Draggable>)}
                {provided.placeholder}
              </Stack>}
            </Droppable>
          </DragDropContext>
        </Stack>
        <Stack direction='row' justifyContent='space-between' spacing={2}>
          {props.close &&
          <Button
            onClick={props.close}
            variant='text'
          >
            Cancel
          </Button>}
          <LoadingButton
            loading={saving}
            onClick={() => save()}
            variant='contained'
          >
            Save
          </LoadingButton>
        </Stack>
      </Stack>
      <Menu
        anchorEl={anchorElement}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClick={handleClose}
        onClose={handleClose}
        open={openMenu}
        sx={{
          '& .MuiPaper-root': {
            maxHeight: '240px',
            minWidth: '400px',
          },
        }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        {filteredInputs.map(option =>
          <MenuItem
            key={option.label}
            onClick={() => {
              setInputList(old => [...old, option])
            }}
          >
            <Stack alignItems='center' direction='row' spacing={1}>
              <IconButton
                disableRipple
                sx={{
                  backgroundColor: theme => theme.palette.background.input,
                  color: theme => theme.palette.action.active,
                  ':hover': {
                    backgroundColor: theme => theme.palette.background.input,
                    cursor: 'default',
                  },
                }}
              >
                {GetIcon(option.label)}
              </IconButton>
              <Typography
                sx={{ color: theme => theme.palette.text.secondary }}
                variant='body2'
              >
                {option.label}
              </Typography>
            </Stack>
          </MenuItem>)}
      </Menu>
    </>
  )
}

export default EditOrgLinkSocial
