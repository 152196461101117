/* eslint-disable no-restricted-imports */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable max-lines */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
import AddTaskRoundedIcon from '@mui/icons-material/AddTaskRounded'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import CalendarViewMonthRoundedIcon from '@mui/icons-material/CalendarViewMonthRounded'
import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import EmojiPeopleRoundedIcon from '@mui/icons-material/EmojiPeopleRounded'
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded'
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded'
import ScheduleSendRoundedIcon from '@mui/icons-material/ScheduleSendRounded'
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded'
import TableViewRoundedIcon from '@mui/icons-material/TableViewRounded'
import ViewKanbanRoundedIcon from '@mui/icons-material/ViewKanbanRounded'
import ViewListRoundedIcon from '@mui/icons-material/ViewListRounded'
import { LoadingButton, TabContext, TabList } from '@mui/lab'
import { AppBar, Autocomplete, Avatar, AvatarGroup, Box, Button, Card, CardActionArea, Dialog, FormControl, Grid, IconButton, InputLabel, ListItemIcon, ListItemText, MenuItem, Select, Slide, Stack, Tab, TextField, Toolbar, Typography, useTheme } from '@mui/material'
import _ from 'lodash'
import type { ChangeEvent } from 'react'
import { useEffect, useState } from 'react'
import type { ColorResult } from 'react-color'
import { CirclePicker } from 'react-color'
import { useNavigate } from 'react-router'
import { v4 } from 'uuid'

import { createTaskboard, createTaskboardGroup, firstTaskboard, getTaskboardGroups, getTaskboardTemplates, getWorkspaces, updateTaskboardGroup } from 'src/api/taskboards'
import { sendWorkspaceUpdate } from 'src/api/webSocket'
import SearchField from 'src/components/form-elements/SearchField'
import BillingModal from 'src/components/pages/Account/Billing/BillingModal'
import { taskboardEmpty } from 'src/components/pages/Task/TaskboardTemplates/Empty'
import { taskboardFanEngagement } from 'src/components/pages/Task/TaskboardTemplates/FanEngagement'
import { taskboardMusicRelease } from 'src/components/pages/Task/TaskboardTemplates/MusicRelease'
import { taskboardPitchingSchedule } from 'src/components/pages/Task/TaskboardTemplates/PitchingSchedule'
import { taskboardReleaseRollout } from 'src/components/pages/Task/TaskboardTemplates/ReleaseRollout'
import CardTemplateItem from 'src/components/pages/Task/TaskMenu/TaskMenuComponents/CardTemplateItem'
import { colorSelection } from 'src/components/pages/Task/TaskMenu/TaskMenuComponents/ColorsForPicker'
import TaskSkeletonModal from 'src/components/pages/Task/TaskMenu/TaskMenuModals/TaskSkeletonModal'
import { taskboardIcons } from 'src/components/pages/Task/TaskViews/TaskViewComponents/TaskboardIcons'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { TaskboardGroup, TaskBoardTemplate, Workspace } from 'src/models/Taskboard'
import { NewTaskboardGroup } from 'src/models/Taskboard'

type Props = {
  close: () => void
  open: boolean
  group?: TaskboardGroup
  refreshBoards?: () => void
}

const NewTaskboardModal = (props: Props) => {
  const { currentOrganisation, updateCurrentOrganisation, refreshCurrentOrganisation } = useAuth()
  const theme = useTheme()

  const templateList = _.cloneDeep([
    taskboardEmpty,
    taskboardMusicRelease,
    taskboardFanEngagement,
    taskboardPitchingSchedule,
    taskboardReleaseRollout,
  ])

  const [groups, setGroups] = useState<TaskboardGroup[]>()
  const [indexTemplate, setIndexTemplate] = useState(0)
  const [customIndexTemplate, setCustomIndexTemplate] = useState(0)
  const [billingModal, setBillingModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [boardIcon, setBoardIcon] = useState(0)

  const [workspaces, setWorkspaces] = useState<Workspace[]>([])
  const [activeWorkspace, setActiveWorkspace] = useState<Workspace>()

  useEffect(() => {
    void getWorkspaces()
      .then(setWorkspaces)
  }, [])

  useEffect(() => {
    void getTaskboardGroups(activeWorkspace?.id)
      .then(setGroups)
  }, [activeWorkspace?.id])

  const history = useNavigate()

  const [filter, setFilter] = useState('')
  const filterTemplateList = templateList.filter(item =>
    item.title.toLowerCase().includes(filter.toLowerCase()))

  const [taskboardTemplates, setTaskboardTemplates] = useState<TaskBoardTemplate[]>([])

  const filterCustomTemplateList = taskboardTemplates.filter(item =>
    item.title.toLowerCase().includes(filter.toLowerCase()))
  const [boardColor, setBoardColor] = useState('#828DF8')
  const [boardName, setBoardName] = useState('')
  const [group, setGroup] = useState(props.group)
  const deepCloneTemplate = _.cloneDeep(taskboardEmpty)
  const [templateItem, setTemplateItem] = useState(deepCloneTemplate)

  const [tab, setTab] = useState(0)
  const [tabForTemplates, setTabForTemplates] = useState('0')

  const findItem = (valueFound: string) => groups?.find(element => element.id === valueFound)

  const handleColorChange = (color: ColorResult) => {
    setBoardColor(color.hex)
  }

  const handleNameChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setBoardName(event.target.value)
  }

  useEffect(() => {
    void getTaskboardTemplates()
      .then(setTaskboardTemplates)
  }, [])

  const taskboardTemplateRefresh = () => {
    void getTaskboardTemplates()
      .then(setTaskboardTemplates)
  }

  const handleSubmit = async () => {
    setLoading(true)
    if (!currentOrganisation?.firstTaskboard) {
      await updateCurrentOrganisation({ ...currentOrganisation, firstTaskboard: true })
        .then(() => setLoading(true))
        .then(refreshCurrentOrganisation)
        .finally(() => setLoading(true))

      await firstTaskboard()
    }
    if (!group) {
      const newGroup = new NewTaskboardGroup({
        title: 'Untitled Group',
      })
      await createTaskboardGroup(newGroup)
        .then(async groupItem => {
          templateItem.title = boardName
          templateItem.color = boardColor
          templateItem.icon = boardIcon

          const oldDate = new Date(templateItem.createdAt)
          const distanceDates = (Date.now() - oldDate.getTime()) /
          (1000 * 3600 * 24)

          for (const taskgroup of templateItem.taskGroups) {
            for (const taskItem of taskgroup.tasks) {
              if (taskItem.dueDate && taskItem.dueDate !== null) {
                taskItem.dueDate =
                  new Date(new Date(taskItem.dueDate).setDate(new Date(taskItem.dueDate).getDate() + distanceDates))
              }
            }
          }

          await createTaskboard(templateItem)
            .then(board => {
              void handleGroup(board, groupItem)
              props.close()
            })
            .finally(() => setLoading(false))
        })
    } else {
      templateItem.title = boardName
      templateItem.color = boardColor
      templateItem.icon = boardIcon
      await createTaskboard(templateItem)
        .then(board => {
          void handleGroup(board, group)
          props.close()
        })
        .finally(() => setLoading(false))
    }
    if (props.refreshBoards) {
      props.refreshBoards()
    }
  }

  const handleGroup = async (board: string, groupTest: TaskboardGroup) => {
    const testGroup = groupTest
    testGroup.boardIds.push(board)
    await updateTaskboardGroup({
      id: groupTest.id ?? '',
      ...testGroup,
    })
      .then(() => sendWorkspaceUpdate(v4()))
      .then(() => history(`/calendar/${board}`))
    props.close()
  }

  const handleChange = (event: string) => {
    const test = event

    const itemFound = findItem(test)

    if (itemFound) {
      setGroup(itemFound)
    }
  }

  const handleSwitch = (index: number) => {
    setIndexTemplate(index)
    setTemplateItem(filterTemplateList[index])
  }

  const handleSwitchCustom = (index: number) => {
    setCustomIndexTemplate(index)
    setTemplateItem(filterCustomTemplateList[index])
  }

  function iconRender(index: number, colorIcon: string) {
    switch (index) {
      case 0:
        return <CheckBoxOutlineBlankRoundedIcon fontSize='large' sx={{ color: colorIcon }} />
      case 1:
        return <MusicNoteRoundedIcon fontSize='large' sx={{ color: colorIcon }} />
      case 2:
        return <ScheduleSendRoundedIcon fontSize='large' sx={{ color: colorIcon }} />
      case 3:
        return <ScheduleRoundedIcon fontSize='large' sx={{ color: colorIcon }} />
      case 4:
        return <EmojiPeopleRoundedIcon fontSize='large' sx={{ color: colorIcon }} />
      case 5:
        return <MusicNoteRoundedIcon fontSize='large' sx={{ color: colorIcon }} />
      default:
        return <MusicNoteRoundedIcon fontSize='large' sx={{ color: colorIcon }} />
    }
  }

  return (
    <>
      {billingModal && currentOrganisation &&
      <BillingModal
        account={currentOrganisation}
        close={() => setBillingModalOpen(false)}
        open={billingModal}
      />}
      <Dialog
        BackdropProps={{
          timeout: 500,
        }}
        closeAfterTransition
        fullScreen
        onClose={props.close}
        open={props.open}
        sx={{
          '& .MuiPaper-root': {
            transform: 'none!important',
          },
          '.MuiDialog-paper': {
            height: '100%',
          },
        }}
      >
        <Slide direction='up' in={props.open}>
          <Stack
            height={1}
            overflow='auto'
            sx={{
              background: theme.palette.background.input,
            }}
          >
            <AppBar
              position='fixed'
              sx={{
                background: theme.palette.background.default,
                borderRadius: 2,
                zIndex: '99999!important',
                top: 16,
                marginLeft: 2,
                marginRight: 2,
                paddingX: 2,
                width: 'calc(100% - 32px)',
              }}
              variant='outlined'
            >
              <Toolbar
                sx={{
                  justifyContent: 'space-between',
                  paddingX: '8px!important',
                }}
              >
                <Stack
                  alignItems='center'
                  direction='row'
                  justifyContent='space-between'
                  spacing={2}
                  width={1}
                >
                  <Typography variant='h3'>
                    Create Taskboard
                  </Typography>
                  <IconButton
                    aria-label='close'
                    onClick={props.close}
                  >
                    <CloseRoundedIcon />
                  </IconButton>
                </Stack>
              </Toolbar>
            </AppBar>
            <Stack alignItems='center' height={1} marginTop={8} width={1}>
              <Stack direction='column' height={1} paddingX={2} paddingY={2} spacing={1} width={1}>
                <Stack direction='row' height={1} spacing={2}>
                  <Grid container height={1} spacing={2} width={1}>
                    {tab === 0 &&
                    <>
                      <Grid
                        item
                        lg={4}
                        md={6}
                        sm={6}
                        sx={{
                          height: 1,
                        }}
                        xl={4}
                        xs={12}
                      >
                        <Stack
                          alignItems='center'
                          borderRadius={2}
                          height={1}
                          overflow='hidden'
                          sx={{
                            bgcolor: theme.palette.background.input,
                          }}
                          width={1}
                        >
                          <Stack
                            alignItems='center'
                            borderRadius={2}
                            height={1}
                            overflow='auto'
                            padding={2}
                            spacing={2}
                            width={1}
                          >
                            <Stack alignItems='center' spacing={2} width={1}>
                              <AvatarGroup>
                                <Avatar
                                  sx={{
                                    bgcolor: theme.palette.primary.main,
                                    width: 48,
                                    height: 48,
                                  }}
                                >
                                  <ArrowForwardRoundedIcon />
                                </Avatar>
                                <Avatar
                                  sx={{
                                    bgcolor: theme.palette.primary.main,
                                    width: 48,
                                    height: 48,

                                  }}
                                >
                                  <ArrowForwardRoundedIcon />
                                </Avatar>
                                <Avatar
                                  sx={{
                                    bgcolor: theme.palette.primary.main,
                                    width: 48,
                                    height: 48,
                                  }}
                                >
                                  <ArrowForwardRoundedIcon />
                                </Avatar>
                              </AvatarGroup>
                              <Stack alignItems='center' maxWidth={240} width={1}>
                                <Typography lineHeight={1.4} textAlign='center' variant='h3'>
                                  Select a template to build your workflow
                                </Typography>
                              </Stack>
                              <SearchField
                                onChange={event => setFilter(event.target.value)}
                                value={filter}
                              />
                            </Stack>
                            <TabContext value={tabForTemplates}>
                              <TabList
                                aria-label='Current template tab'
                                onChange={(event, value: string) => setTabForTemplates(value)}
                                sx={{
                                  minHeight: 32,
                                  '.MuiTab-root':
                  {
                    textTransform: 'none',
                    fontWeight: 'normal',
                    fontSize: '16px',
                    minHeight: 32,
                    background: 'transparent!important',
                  },
                                }}
                                variant='scrollable'
                              >
                                <Tab label='Releese templates' value='0' />
                                <Tab label='Your templates' value='1' />
                              </TabList>
                            </TabContext>
                            {tabForTemplates === '0' && filterTemplateList.map((template, index) =>
                              <Card
                                key={`${template.title}${index.toString()}`}
                                onClick={() => handleSwitch(index)}
                                sx={{
                                  borderColor: indexTemplate === index
                                    ? `${theme.palette.primary.main}!important`
                                    : 'default',
                                  width: 1,
                                  flexShrink: 0,
                                }}
                                variant='outlined'
                              >
                                <CardActionArea>
                                  <Stack alignItems='center' direction='row' padding={2} spacing={2}>
                                    {iconRender(index, template.color)}
                                    <Stack direction='column' spacing={0.5}>
                                      <Typography lineHeight={1.4} variant='h4'>
                                        {template.title}
                                      </Typography>
                                      {template.description &&
                                      <Typography variant='body2'>
                                        {`${template.description.slice(0, 80)}...`}
                                      </Typography>}
                                    </Stack>
                                  </Stack>
                                </CardActionArea>
                              </Card>)}
                            {tabForTemplates === '1' &&
                          currentOrganisation?.membershipTier !== 'FREE' &&
                          filterCustomTemplateList.reverse().map((template, index) =>
                            <CardTemplateItem
                              customIndexTemplate={customIndexTemplate}
                              handleSwitchCustom={handleSwitchCustom}
                              index={index}
                              key={template.title}
                              taskboardTemplateRefresh={taskboardTemplateRefresh}
                              template={template}
                            />)}
                            {tabForTemplates === '1' &&
                            currentOrganisation?.membershipTier === 'FREE' &&
                            <Stack alignItems='center' spacing={2}>
                              <Stack alignItems='center'>
                                <AddTaskRoundedIcon
                                  color='success'
                                  sx={{
                                    frontSize: 64,
                                    height: 64,
                                    width: 64,
                                  }}
                                />
                                <Typography textAlign='center' variant='h6'>
                                  Automate your workflows
                                </Typography>
                                <Typography textAlign='center' variant='body2'>
                                  Create custom templates from scratch
                                </Typography>
                              </Stack>
                              <Button
                                color='success'
                                onClick={() => setBillingModalOpen(true)}
                              >
                                Explore plans
                              </Button>
                            </Stack>}
                          </Stack>
                        </Stack>
                      </Grid>

                      <Grid item lg={8} md={6} sm={6} xl={8} xs={12}>
                        <Stack height={1} spacing={1} width={1}>
                          <Stack maxWidth={600}>
                            <Typography lineHeight={1.4} variant='h4'>
                              {templateItem.title}
                            </Typography>
                            {templateItem.description &&
                            <Typography variant='body2'>
                              {templateItem.description}
                            </Typography>}
                          </Stack>
                          <TaskSkeletonModal
                            click={() => setTab(1)}
                          />
                        </Stack>
                      </Grid>

                    </>}

                    {tab === 1 &&
                    <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                      <Stack alignItems='center' paddingBottom={4} spacing={2} width={1}>
                        <Stack
                          direction='column'
                          maxWidth={360}
                          spacing={2}
                          width={1}
                        >
                          <Typography variant='h4'>
                            Create a board
                          </Typography>
                          <Stack alignItems='center' direction='row' spacing={1} width={1}>
                            <Typography
                              sx={{
                                fontWeight: 600,
                                wordBreak: 'normal',
                              }}
                              variant='caption'
                            >
                              Template
                            </Typography>
                            <Stack alignItems='center' direction='row' spacing={1}>
                              <FiberManualRecordRoundedIcon sx={{ color: templateItem.color }} />
                              <Typography
                                sx={{
                                  wordBreak: 'normal',
                                }}
                                variant='caption'
                              >
                                {templateItem.title}
                              </Typography>
                            </Stack>
                            <Button color='secondary' onClick={() => setTab(0)} size='small' variant='text'>
                              Switch template
                            </Button>
                          </Stack>
                        </Stack>
                        <Stack maxWidth={360} width={1}>
                          <Stack marginBottom={3} marginTop={2} spacing={3} width='100%'>
                            <TextField
                              fullWidth
                              inputProps={{ maxLength: 50 }}
                              label='Taskboard name'
                              onChange={event => handleNameChange(event)}
                              onKeyPress={event => {
                                if (event.key === 'Enter') {
                                  void handleSubmit()
                                }
                              }}
                              placeholder='Board name'
                              value={boardName}
                            />

                            <Stack paddingTop={2} width={1}>
                              <FormControl>
                                <InputLabel>
                                  Workspace
                                </InputLabel>
                                <Select
                                  displayEmpty
                                  id='workspace'
                                  label='Workspace'
                                  onChange={event => {
                                    const foundItem = workspaces.find(item => item.id === event.target.value)
                                    setActiveWorkspace(foundItem)
                                  }}
                                  renderValue={_value => <Stack alignItems='center' direction='row' spacing={1}>
                                    <Avatar
                                      color={activeWorkspace?.color ?? theme.palette.primary.main}
                                      sx={{
                                        background: activeWorkspace?.color ?? theme.palette.primary.main,
                                        color: `${theme.palette.primary.contrastText}!important`,
                                        '& .MuiSvgIcon-root': {
                                          color: 'white!important',
                                        },
                                        borderRadius: 0.5,
                                      }}
                                      variant='rounded'
                                    >
                                      {activeWorkspace?.icon
                                        ? taskboardIcons[activeWorkspace.icon]
                                        : <HomeRoundedIcon />}
                                    </Avatar>
                                    <Typography color={theme.palette.text.secondary} variant='body1'>
                                      {activeWorkspace?.title ?? 'Main Workspace'}
                                    </Typography>
                                  </Stack>}
                                  sx={{
                                    height: 64,
                                    '.MuiFilledInput-input': {
                                      height: '64px!important',
                                      alignItems: 'center',
                                      display: 'flex',
                                    },
                                  }}
                                  value={activeWorkspace?.id ?? 'Home'}
                                >
                                  <MenuItem value='Home'>
                                    <ListItemIcon>
                                      <Avatar
                                        color={theme.palette.primary.main}
                                        sx={{
                                          background: theme.palette.primary.main,
                                          color: theme.palette.primary.contrastText,
                                          width: 32,
                                          height: 32,
                                          borderRadius: 0.5,
                                        }}
                                        variant='rounded'
                                      >
                                        <HomeRoundedIcon />
                                      </Avatar>
                                    </ListItemIcon>
                                    <ListItemText>
                                      Main Workspace
                                    </ListItemText>
                                  </MenuItem>
                                  {workspaces.map(item =>
                                    <MenuItem key={item.id} value={item.id}>
                                      <ListItemIcon>
                                        <Avatar
                                          sx={{
                                            width: 32,
                                            height: 32,
                                            borderRadius: 0.5,
                                            bgcolor: item.color,
                                            color: theme.palette.primary.contrastText,
                                          }}
                                          variant='rounded'
                                        >
                                          {taskboardIcons[item.icon]}
                                        </Avatar>
                                      </ListItemIcon>
                                      <ListItemText>
                                        {item.title}
                                      </ListItemText>
                                    </MenuItem>)}
                                </Select>
                              </FormControl>
                            </Stack>

                            <Stack>
                              {groups &&
                              <Autocomplete
                                autoHighlight
                                autoSelect
                                fullWidth
                                getOptionLabel={option => option.title}
                                onChange={(_test, newValue) => handleChange(newValue?.id ?? '')}
                                options={groups}
                                renderInput={params =>
                                  <TextField
                                    {...params}
                                    label='Folder'
                                    placeholder='Select a folder'
                                    sx={{ marginTop: 0,  height: 42 }}
                                  />}
                                renderOption={(renderProps, option) =>
                                  <Box component='li' {...renderProps}>
                                    {option.title}
                                  </Box>}
                                sx={{ marginTop: 2, height: 42 }}
                                value={group}
                              />}
                            </Stack>
                            <Stack spacing={1}>
                              <Typography sx={{ marginTop: 0 }} variant='inputLabel'>
                                Pick a color
                              </Typography>
                              <CirclePicker
                                color={boardColor}
                                colors={colorSelection}
                                onChangeComplete={handleColorChange}
                                width='100%'
                              />
                            </Stack>

                            <Stack width={1}>
                              <Stack direction='row' width={1}>
                                <Typography sx={{ marginTop: 0 }} variant='inputLabel'>
                                  Icon
                                </Typography>
                              </Stack>
                              <Stack direction='row' flexWrap='wrap' width={1}>
                                {taskboardIcons.map((icon, index) =>
                                  <Card
                                    key={v4()}
                                    sx={{
                                      borderColor: boardIcon === index ? null : 'transparent',
                                      borderCollapse: 'collapse',
                                    }}
                                    variant='outlined'
                                  >
                                    <CardActionArea onClick={() => setBoardIcon(index)}>
                                      <IconButton>
                                        {icon}
                                      </IconButton>
                                    </CardActionArea>
                                  </Card>)}
                              </Stack>
                            </Stack>
                          </Stack>

                          <Stack spacing={2}>
                            <Stack spacing={1}>
                              <Typography variant='subtitle2'>Available features</Typography>
                              <Stack direction='row' justifyContent='space-between'>
                                <Stack direction='column' spacing={1} width={1}>
                                  <Stack direction='row' spacing={1}>
                                    <ViewListRoundedIcon sx={{ color: theme.palette.action.active }} />
                                    <Typography
                                      color={theme.palette.action.active}
                                      variant='body2'
                                    >
                                      List
                                    </Typography>
                                  </Stack>
                                  <Stack direction='row' spacing={1}>
                                    <ViewKanbanRoundedIcon sx={{ color: theme.palette.action.active }} />
                                    <Typography
                                      color={theme.palette.action.active}
                                      variant='body2'
                                    >
                                      Board
                                    </Typography>
                                  </Stack>
                                  <Stack direction='row' spacing={1}>
                                    <CalendarViewMonthRoundedIcon
                                      sx={{ color: theme.palette.action.active }}
                                    />
                                    <Typography
                                      color={theme.palette.action.active}
                                      variant='body2'
                                    >
                                      Calendar
                                    </Typography>
                                  </Stack>
                                </Stack>
                                <Stack direction='column' spacing={1} width={1}>
                                  <Stack direction='row' spacing={1}>
                                    <TableViewRoundedIcon sx={{ color: theme.palette.action.active }} />
                                    <Typography
                                      color={theme.palette.action.active}
                                      variant='body2'
                                    >
                                      Dashboard
                                    </Typography>
                                  </Stack>
                                  <Stack direction='row' spacing={1}>
                                    <SettingsSuggestRoundedIcon sx={{ color: theme.palette.action.active }} />
                                    <Typography
                                      color={theme.palette.action.active}
                                      variant='body2'
                                    >
                                      Rules
                                    </Typography>
                                  </Stack>
                                  <Stack direction='row' spacing={1}>
                                    <QuestionAnswerRoundedIcon sx={{ color: theme.palette.action.active }} />
                                    <Typography
                                      color={theme.palette.action.active}
                                      variant='body2'
                                    >
                                      Messages
                                    </Typography>
                                  </Stack>
                                </Stack>
                              </Stack>
                            </Stack>
                          </Stack>
                        </Stack>
                        <LoadingButton
                          disabled={boardName.length === 0}
                          fullWidth
                          loading={loading}
                          onClick={() => handleSubmit()}
                          sx={{ maxWidth: 360 }}
                          variant='contained'
                        >
                          Create board
                        </LoadingButton>
                      </Stack>
                    </Grid>}

                  </Grid>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Slide>
      </Dialog>
    </>
  )
}

export default NewTaskboardModal
