import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { removeCollaboratorProject } from 'src/api/projects'
import DeleteItemComponent from 'src/components/DeleteItemComponent'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { Organisation } from 'src/models/Organisation'
import type { Collaborator } from 'src/models/Project'
import type Project from 'src/models/Project'

type Props = {
  close: () => void
  open: boolean
  project?: Project
  updatedFields: (project: Project) => void
  collaborator: Collaborator
}

const DeleteCollaboratorModal = (props: Props) => {
  const themeItem = useTheme()
  const { currentOrganisation } = useAuth()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const deleteCollaborator = async (organisation: Organisation) => {
    if (props.project) {
      setLoading(true)
      await removeCollaboratorProject(props.project, organisation)
        .then(project => {
          if (props.collaborator.account.id === currentOrganisation?.id) {
            navigate('/projects/projects')
          } else {
            props.updatedFields(project)
          }
        })
        .finally(() => {
          props.close()
          setLoading(false)
        })
    }
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='xs'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Stack paddingX={3} paddingY={3} spacing={4} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          width='100%'
        >
          <Typography variant='h3'>
            {currentOrganisation?.id === props.collaborator.account.id
              ? 'Leave project'
              : 'Remove collaborator'}
          </Typography>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>
        <DeleteItemComponent
          primary='Are you sure?'
          secondary={currentOrganisation?.id === props.collaborator.account.id
            ? 'By leaving you will lose access to all assets contained in this project'
            : `By removing ${props.collaborator.account.name && props.collaborator.account.name.length > 0
              ? props.collaborator.account.name
              : props.collaborator.account.organisationEmail} 
          they will lose access to all assets contained in this project`}
        />
        <Stack direction='row' justifyContent='flex-end' spacing={1} width='100%'>
          <Button onClick={props.close} variant='text'>
            Cancel
          </Button>
          <LoadingButton
            color='error'
            loading={loading}
            onClick={() => deleteCollaborator(props.collaborator.account)}
            variant='contained'
          >
            {currentOrganisation?.id === props.collaborator.account.id
              ? 'Leave project'
              : 'Remove collaborator'}

          </LoadingButton>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default DeleteCollaboratorModal
