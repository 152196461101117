/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines */
/* eslint-disable react/forbid-component-props */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable complexity */
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import { CircularProgress, Paper, Stack, Typography } from '@mui/material'
import type { CancelTokenSource } from 'axios'
import axios from 'axios'
import { useEffect, useState } from 'react'

import { createRecording } from 'src/api/recordings'
import { generateTitleRecording } from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/RecordingsTab/Components/EditRecordingComponent'
import { useAuth } from 'src/components/providers/AuthProvider'
import type Project from 'src/models/Project'
import type Recording from 'src/models/Recording'
import { NewRecording } from 'src/models/Recording'
import { bytesToSize } from 'src/utils/fileUtils'

export type EditRecordingFormProps = {
  recording: Recording
  onSave: (recording: Recording) => Promise<void>
  saveProgress: (recording: Recording) => void
  saving: boolean
  project?: Project
  distributing?: boolean
}

type Props = {
  recording?: Recording
  project?: Project
  recordingFile?: File
  done?: boolean
  setNewRecordings?: React.Dispatch<React.SetStateAction<File[]>>
  setRecordings?: React.Dispatch<React.SetStateAction<Recording[]>>
}

const RecordingsListItem = (props: Props) => {
  const { currentOrganisation, refreshCurrentOrganisation } = useAuth()
  const [_, setTokenCancellationSource] = useState<CancelTokenSource | null>(null)
  const [total, setTotal] = useState('')
  const [loaded, setLoaded] = useState('')
  const [failed, setFailed] = useState(false)
  const [done, setDone] = useState(props.done ?? false)

  const [currentRecording, setCurrentRecording] = useState<Recording | undefined>(props.recording)

  useEffect(() => {
    uploadFile()
  }, [])

  const uploadFile = () => {
    if (props.recordingFile && !done) {
      setFailed(false)
      const token = axios.CancelToken.source()
      setTokenCancellationSource(token)
      setLoaded(bytesToSize(0))
      setTotal(bytesToSize(props.recordingFile.size))
      createRecording(new NewRecording({
        audioFile: props.recordingFile,
        title: props.recordingFile.name.slice(0, -4),
        projectId: props.project?.id ?? '',
        primaryArtists: props.project?.primaryArtists,
        productionYear: new Date().getFullYear().toString(),
        recordLabel: currentOrganisation?.name ?? '',
      }), setLoadProperties, token)
        .then(recording => {
          setCurrentRecording(recording)
          void refreshCurrentOrganisation()
        })
        .catch(() => setFailed(true))
        .finally(() => setDone(true))
    }
  }

  const setLoadProperties = (perc: number, load: number, tot: number) => {
    setLoaded(bytesToSize(load))
    setTotal(bytesToSize(tot))
  }

  useEffect(() => {
    if (done && props.recordingFile && props.setNewRecordings && props.setRecordings) {
      props.setNewRecordings(oldRecordings => oldRecordings.filter(item => item.name !== props.recordingFile?.name))
      if (currentRecording) {
        props.setRecordings(records => [...records, currentRecording])
      }
    }
  }, [done])

  return (
    <Paper
      sx={{
        width: 1,
        marginX: 'auto!important',
        maxWidth: 900,
        marginY: 1,
      }}
      variant='outlined'
    >
      <Stack alignItems='center' direction='row' padding={2} width={1}>
        <Paper
          elevation={0}
          sx={{
            background: theme => theme.palette.background.input,
          }}
        >
          <MusicNoteRoundedIcon color='disabled' sx={{ fontSize: 120 }} />
        </Paper>
        <Stack alignItems='center' direction='column' paddingLeft={2} width={1}>
          <Stack alignItems='center' direction='row' marginRight='auto'>
            <Stack direction='column'>
              <Stack direction='column'>
                <Typography variant='textLabel'>
                  {currentRecording?.primaryArtists.map(item => item.name).join(', ')}
                </Typography>
                <Typography variant='h4'>
                  {generateTitleRecording(props.recordingFile, currentRecording)}
                </Typography>
              </Stack>
              <Typography color={failed ? 'error.main' : 'text.label'} sx={{ paddingY: 0 }} variant='body2'>
                {
                  failed
                    ? <>
                      There was an error uploading this file, try again later
                    </>
                    : !done && total && <>{`${loaded} of ${total}`}</>
                }
              </Typography>
            </Stack>
          </Stack>
          <Stack direction='row' spacing={1} width={1}>
            <Stack alignItems='center' direction='row' marginLeft='auto' spacing={2}>
              {!done && <CircularProgress color='primary' />}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  )
}

export default RecordingsListItem
