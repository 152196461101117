import SimpleTour from 'src/components/pages/Tour/TourComponents/SimpleTour'

const SplitTour = () =>
  <SimpleTour
    description='All split holders need to lock their share before releases can be distributed.'
    desktopDirection='left'
    mobileDirection='bottom'
    onboardingKey='recordingSplit'
    selector='split-lock'
    title='Split your royalties'
  />

export default SplitTour
