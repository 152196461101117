/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable switch-case/no-case-curly */
/* eslint-disable max-lines */
import { Stack, TablePagination, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import { visuallyHidden } from '@mui/utils'
import type { Key } from 'react'
import { useState } from 'react'

import TopArtistItem from 'src/components/pages/Earnings/EarningsDistribution/Overview/TopArtistItem'
import type { AnalyticsMonthlyChart, AnalyticsTopArtists } from 'src/models/Analytics'

type Data = {
  _id: string
  amount: number
  streams: number
  payperstream: number
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

type Order = 'asc' | 'desc'

const getComparator = (
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
  ) => number => order === 'desc'
  ? (a, b) => descendingComparator(a, b, orderBy)
  : (a, b) => -descendingComparator(a, b, orderBy)

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((element, index) => [element, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map(element => element[0])
}

type HeadCell = {
  disablePadding: boolean
  id: keyof Data
  label: string
  numeric: boolean
}

const headCells: readonly HeadCell[] = [
  {
    id: '_id',
    numeric: false,
    disablePadding: false,
    label: 'Artist',
  },
  {
    id: 'amount',
    numeric: true,
    disablePadding: false,
    label: 'Earnings',
  },
]

type EnhancedTableProps = {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void
  order: Order
  orderBy: string
}

const EnhancedTableHead = (props: EnhancedTableProps, addStreams: boolean) => {
  const { order, orderBy,  onRequestSort } =
    props
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  return (
    <TableHead sx={{
      '.MuiTableCell-root': {
        borderBottom: 2,
        borderBottomColor: 'divider',
        color: 'text.secondary',
      },
    }}
    >
      <TableRow>
        {headCells.map((headCell, index) =>
          <TableCell
            align='left'
            key={headCell.id}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              position: index === 0 ? 'sticky' : undefined,
              left: index === 0 ? 0 : undefined,
              zIndex: index === 0 ? 999 : undefined,
              background: theme => theme.palette.background.default,
              borderRight: theme => index === 0 ? `1px solid ${theme.palette.divider}` : 'none',
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id
                ? <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
                : null}
            </TableSortLabel>
          </TableCell>)}
        {addStreams &&
        <>
          <TableCell
            align='left'
            key='streams'
            padding='normal'
            sortDirection={orderBy === 'streams' ? order : false}
          >
            <TableSortLabel
              active={orderBy === 'streams'}
              direction={orderBy === 'streams' ? order : 'asc'}
              onClick={createSortHandler('streams')}
            >
              Streams
              {orderBy === 'streams'
                ? <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
                : null}
            </TableSortLabel>
          </TableCell>
          <TableCell
            align='left'
            key='payperstream'
            padding='normal'
            sortDirection={orderBy === 'payperstream' ? order : false}
          >
            <TableSortLabel
              active={orderBy === 'payperstream'}
              direction={orderBy === 'payperstream' ? order : 'asc'}
              onClick={createSortHandler('payperstream')}
            >
              $ / 1000 Streams
              {orderBy === 'payperstream'
                ? <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
                : null}
            </TableSortLabel>
          </TableCell>
        </>}
      </TableRow>
    </TableHead>
  )
}

type Props = {
  data: AnalyticsMonthlyChart[]
  artists: AnalyticsTopArtists[]
}

const TopArtistsChart = (props: Props) => {
  const rows = props.artists.map(item => ({
    _id: item._id,
    image: item.images?.[0]?.[0] ?? '',
    name: item.name?.[0] ?? '',
    amount: props.data.filter(earnings => item.ISRC?.includes(earnings._id))
      .map(earning => earning.amount)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0),
    streams: props.data.filter(earnings => item.ISRC?.includes(earnings._id))
      .map(earning => earning.streams)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0),
    payperstream: 0,
  }))

  const [order, setOrder] = useState<Order>('desc')
  const [orderBy, setOrderBy] = useState<keyof Data>('amount')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number.parseInt(event.target.value, 10))
    setPage(0)
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

  return (
    <Box width={1}>
      <Stack sx={{ width: '100%', mb: 2 }}>
        <Typography variant='h3'>
          Top artists
        </Typography>
        <TableContainer>
          <Table
            aria-labelledby='tableTitle'
            size='medium'
            sx={{
              minWidth: 750,
              tableLayout: 'fixed',
              borderCollapse: 'separate',
            }}
          >
            <EnhancedTableHead
              onRequestSort={handleRequestSort}
              order={order}
              orderBy={orderBy}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => {
                  const labelId = `enhanced-table-checkbox-${row._id}-${row.name}`
                  return (
                    <TopArtistItem
                      key={labelId}
                      row={row}
                    />
                  )
                })}
              {emptyRows > 0 &&
                <TableRow
                  style={{
                    height: 33 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component='div'
          count={rows.length}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 50]}
        />
      </Stack>
    </Box>
  )
}

export default TopArtistsChart
