import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CloseIcon from '@mui/icons-material/Close'
import PercentRoundedIcon from '@mui/icons-material/PercentRounded'
import { LoadingButton } from '@mui/lab'
import { Avatar, Badge, Button, Card, Dialog, IconButton, InputAdornment, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'
import { NumericFormat } from 'react-number-format'

import type { RightsHolder } from 'src/models/Recording'

type Props = {
  close: () => void
  open: boolean
  updateRightsHolder: (rightsholder: RightsHolder) => Promise<void>
  rightsholder: RightsHolder
  setRightsholderValues: React.Dispatch<React.SetStateAction<RightsHolder>>
}

const EditRightsholderModal = (props: Props) => {
  const [numberValue, setNumberValue] = useState<number | undefined>(props.rightsholder.ownership)
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))
  const [rightsholderValues, setRightsholderValues] = useState(props.rightsholder)
  const [loading, setLoading] = useState(false)

  const saveItems = async () => {
    setLoading(true)
    await props.updateRightsHolder(rightsholderValues)
      .then(() => {
        props.setRightsholderValues(rightsholderValues)
        props.close()
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='xs'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Stack paddingX={3} paddingY={3} spacing={2} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
        >
          <Typography variant='h3'>
            Update Split
          </Typography>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>
        <Card
          elevation={0}
          sx={{
            background: theme => theme.palette.background.input,
            width: 1,
          }}
        >
          <Stack alignItems='center' direction='row' padding={2} spacing={1}>
            <Badge
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              badgeContent={<CheckCircleRoundedIcon
                color='secondary'
                sx={{
                  backgroundColor: 'white',
                  borderRadius: '50%',
                  width: 12,
                  height: 12,
                  fontSize: 8,
                }}
              />}
              overlap='circular'
              sx={{
                '.MuiBadge-badge': {
                  display: props.rightsholder.account.verified ? undefined : 'none',
                },
              }}
            >
              <Avatar
                src={props.rightsholder.account.logoUrl}
                sx={{ width: 32, height: 32 }}
              />
            </Badge>
            <Stack direction='column' width={1}>
              <Typography variant='body2'>
                {props.rightsholder.account.name}
              </Typography>
              <Typography variant='helperText'>
                {props.rightsholder.account.organisationEmail}
              </Typography>
            </Stack>
          </Stack>
        </Card>
        <Stack
          alignItems='center'
          direction='row'
          marginTop='32px!important'
          spacing={1}
        >
          <NumericFormat
            InputProps={{
              endAdornment:
  <InputAdornment position='end'>
    <PercentRoundedIcon color='disabled' fontSize='small' />
  </InputAdornment>,
            }}
            customInput={TextField}
            decimalScale={2}
            decimalSeparator='.'
            displayType='input'
            fullWidth
            label='Split'
            onValueChange={values => {
              setNumberValue(values.floatValue)
              if (values.floatValue) {
                setRightsholderValues({
                  ...rightsholderValues,
                  ownership: values.floatValue,
                })
              }
            }}
            thousandSeparator=','
            thousandsGroupStyle='thousand'
            value={numberValue}
          />
        </Stack>
        <Stack alignItems='flex-end' direction='row' justifyContent='flex-end' spacing={1} width={1}>
          <Button
            onClick={() => props.close()}
            variant='text'
          >
            Cancel
          </Button>
          <LoadingButton
            disabled={!numberValue}
            loading={loading}
            onClick={() => saveItems()}
            variant='contained'
          >
            Update
          </LoadingButton>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default EditRightsholderModal
