/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable max-lines */
/* eslint-disable id-length */
/* eslint-disable sonarjs/no-duplicate-string */
import ChatBubbleRoundedIcon from '@mui/icons-material/ChatBubbleRounded'
import CodeRoundedIcon from '@mui/icons-material/CodeRounded'
import FormatAlignCenterRoundedIcon from '@mui/icons-material/FormatAlignCenterRounded'
import FormatAlignJustifyRoundedIcon from '@mui/icons-material/FormatAlignJustifyRounded'
import FormatAlignLeftRoundedIcon from '@mui/icons-material/FormatAlignLeftRounded'
import FormatAlignRightRoundedIcon from '@mui/icons-material/FormatAlignRightRounded'
import FormatBoldRoundedIcon from '@mui/icons-material/FormatBoldRounded'
import FormatColorTextRoundedIcon from '@mui/icons-material/FormatColorTextRounded'
import FormatIndentDecreaseRoundedIcon from '@mui/icons-material/FormatIndentDecreaseRounded'
import FormatIndentIncreaseRoundedIcon from '@mui/icons-material/FormatIndentIncreaseRounded'
import FormatItalicRoundedIcon from '@mui/icons-material/FormatItalicRounded'
import FormatStrikethroughRoundedIcon from '@mui/icons-material/FormatStrikethroughRounded'
import FormatUnderlinedRoundedIcon from '@mui/icons-material/FormatUnderlinedRounded'
import HighlightRoundedIcon from '@mui/icons-material/HighlightRounded'
import InsertLinkRoundedIcon from '@mui/icons-material/InsertLinkRounded'
import { Card, Divider, IconButton, Popper, Stack, Tooltip } from '@mui/material'
import {
  addMark,
  getMarks,
  getNodeEntries,
  getSelectionBoundingClientRect,
  indent,
  isCollapsed,
  LinkToolbarButton,
  MARK_BG_COLOR,
  MARK_BOLD,
  MARK_CODE,
  MARK_COLOR,
  MARK_ITALIC,
  MARK_STRIKETHROUGH,
  MARK_UNDERLINE,
  setAlign,
  setElements,
  someNode,
  useAddCommentMark,
  useEditorState,
  withoutNormalizing,
} from '@udecode/plate'
import type { ReactNode } from 'react'
import { useEffect, useRef, useState } from 'react'

import SelectTypeToolbar from 'src/components/pages/Notes/Modern/balloon-toolbar/SelectBlockType'
import { ButtonColorText } from 'src/components/pages/Notes/Modern/balloon-toolbar/SelectColorButton'

type VirtualElement = {
  getBoundingClientRect: () => DOMRect
  clientWidth: number
  clientHeight: number
}

export const MarkBalloonToolbar = ({ children }: { children?: ReactNode }) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const editor = useEditorState()

  const boldTooltip = 'Bold (⌘+B)'
  const italicTooltip = 'Italic (⌘+I)'
  const underlineTooltip = 'Underline (⌘+U)'

  const [anchorElement, setAnchorElement] = useState<VirtualElement | null>(null)

  const [open, setOpen] = useState(false)

  const domSelection = window.getSelection()
  const addCommentMark = useAddCommentMark()

  const isAlignedLeft =
    !someNode(editor, { match: { align: 'center' } }) &&
    !someNode(editor, { match: { align: 'right' } }) &&
    !someNode(editor, { match: { align: 'justify' } })

  const selection = editor.selection

  useEffect(() => {
    const returnItem = getSelectionBoundingClientRect()

    if (isCollapsed(selection) ||
      !editor.selection) {
      setOpen(false)
      return
    }

    setOpen(true)
    const getRect = () => ({
      width: returnItem.width,
      height: returnItem.height,
      x: returnItem.x,
      y: returnItem.y,
      bottom: returnItem.bottom,
      left: returnItem.left,
      top: returnItem.top,
      right: returnItem.right,
    }) as DOMRect

    if (returnItem.x !== 0 && returnItem.y !== 0) {
      setAnchorElement({
        clientWidth: returnItem.width,
        clientHeight: returnItem.height,
        getBoundingClientRect: getRect,
      })
    }
  }, [editor, selection, domSelection])

  return (
    <Popper
      anchorEl={anchorElement}
      id='hover-toolbar'
      onClick={event => {
        event.preventDefault()
      }}
      onMouseDown={event => {
        event.preventDefault()
      }}
      open={open}
      placement='top-start'
      ref={ref}
      sx={{
        zIndex: 999,
      }}
    >
      <Card
        elevation={4}
        sx={{
          height: '58px',
        }}
      >
        <Stack
          alignItems='center'
          direction='row'
          height={1}
          padding={1}
          spacing={1}
        >
          <SelectTypeToolbar
            setOpen={setOpen}
          />

          <Tooltip placement='top' title={boldTooltip}>
            <IconButton
              color={getMarks(editor)?.[MARK_BOLD] === true ? 'primary' : 'default'}
              onClick={event => {
                event.stopPropagation()
                event.preventDefault()
                if (getMarks(editor)?.[MARK_BOLD] === true) {
                  addMark(editor, MARK_BOLD, false)
                } else {
                  addMark(editor, MARK_BOLD, true)
                }
              }}
              size='small'
              sx={{
                outline: theme => getMarks(editor)?.[MARK_BOLD] === true
                  ? `1px solid ${theme.palette.primary.main}`
                  : '1px solid transparent',
                color: theme => getMarks(editor)?.[MARK_BOLD] === true
                  ? `${theme.palette.primary.main}!important`
                  : `${theme.palette.action.active}!important`,
                boxSizing: 'border-box',
                borderRadius: '8px',
              }}
            >
              <FormatBoldRoundedIcon />
            </IconButton>
          </Tooltip>

          <Tooltip placement='top' title={italicTooltip}>
            <IconButton
              color={getMarks(editor)?.[MARK_ITALIC] === true ? 'primary' : 'default'}
              onClick={event => {
                event.stopPropagation()
                event.preventDefault()
                if (getMarks(editor)?.[MARK_ITALIC] === true) {
                  addMark(editor, MARK_ITALIC, false)
                } else {
                  addMark(editor, MARK_ITALIC, true)
                }
              }}
              size='small'
              sx={{
                outline: theme => getMarks(editor)?.[MARK_ITALIC] === true
                  ? `1px solid ${theme.palette.primary.main}`
                  : '1px solid transparent',
                color: theme => getMarks(editor)?.[MARK_ITALIC] === true
                  ? `${theme.palette.primary.main}!important`
                  : `${theme.palette.action.active}!important`,
                userSelect: 'none',
                boxSizing: 'border-box',
                borderRadius: '8px',
              }}
            >
              <FormatItalicRoundedIcon />
            </IconButton>
          </Tooltip>

          <Tooltip placement='top' title={underlineTooltip}>
            <IconButton
              color={getMarks(editor)?.[MARK_UNDERLINE] === true ? 'primary' : 'default'}
              onClick={event => {
                event.stopPropagation()
                event.preventDefault()
                if (getMarks(editor)?.[MARK_UNDERLINE] === true) {
                  addMark(editor, MARK_UNDERLINE, false)
                } else {
                  addMark(editor, MARK_UNDERLINE, true)
                }
              }}
              size='small'
              sx={{
                outline: theme => getMarks(editor)?.[MARK_UNDERLINE] === true
                  ? `1px solid ${theme.palette.primary.main}`
                  : '1px solid transparent',
                color: theme => getMarks(editor)?.[MARK_UNDERLINE] === true
                  ? `${theme.palette.primary.main}!important`
                  : `${theme.palette.action.active}!important`,
                boxSizing: 'border-box',
                borderRadius: '8px',
              }}
            >
              <FormatUnderlinedRoundedIcon />
            </IconButton>
          </Tooltip>

          <Tooltip placement='top' title='Strikethrough'>
            <IconButton
              color={getMarks(editor)?.[MARK_STRIKETHROUGH] === true ? 'primary' : 'default'}
              onClick={event => {
                event.stopPropagation()
                event.preventDefault()
                if (getMarks(editor)?.[MARK_STRIKETHROUGH] === true) {
                  addMark(editor, MARK_STRIKETHROUGH, false)
                } else {
                  addMark(editor, MARK_STRIKETHROUGH, true)
                }
              }}
              size='small'
              sx={{
                outline: theme => getMarks(editor)?.[MARK_STRIKETHROUGH] === true
                  ? `1px solid ${theme.palette.primary.main}`
                  : '1px solid transparent',
                color: theme => getMarks(editor)?.[MARK_STRIKETHROUGH] === true
                  ? `${theme.palette.primary.main}!important`
                  : `${theme.palette.action.active}!important`,
                boxSizing: 'border-box',
                borderRadius: '8px',
              }}
            >
              <FormatStrikethroughRoundedIcon />
            </IconButton>
          </Tooltip>

          <Tooltip placement='top' title='Code'>
            <IconButton
              color={getMarks(editor)?.[MARK_CODE] === true ? 'primary' : 'default'}
              onClick={event => {
                event.stopPropagation()
                event.preventDefault()
                if (getMarks(editor)?.[MARK_CODE] === true) {
                  addMark(editor, MARK_CODE, false)
                } else {
                  addMark(editor, MARK_CODE, true)
                }
              }}
              size='small'
              sx={{
                outline: theme => getMarks(editor)?.[MARK_CODE] === true
                  ? `1px solid ${theme.palette.primary.main}`
                  : '1px solid transparent',
                color: theme => getMarks(editor)?.[MARK_CODE] === true
                  ? `${theme.palette.primary.main}!important`
                  : `${theme.palette.action.active}!important`,
                boxSizing: 'border-box',
                borderRadius: '8px',
              }}
            >
              <CodeRoundedIcon />
            </IconButton>
          </Tooltip>

          <Divider orientation='vertical' />

          <Tooltip placement='top' title='Outdent'>
            <IconButton
              color='default'
              onClick={event => {
                event.stopPropagation()
                event.preventDefault()

                const _nodes = getNodeEntries(editor, {
                  block: true,
                  mode: 'lowest',
                })

                const nodes = [..._nodes]

                withoutNormalizing(editor, () => {
                  for (const [node, path] of nodes) {
                    let _ref, _setNodesProps

                    const blockIndent = (_ref = node.indent) !== null && _ref !== void 0 ? _ref : 0
                    const test = blockIndent as number
                    const newIndent = test - 1
                    if (newIndent <= 0) {
                      setElements(editor, {
                        indent: 0,
                      }, {
                        at: path,
                      })
                    } else {
                      setElements(editor, {
                        indent: newIndent,
                      }, {
                        at: path,
                      })
                    }
                  }
                })
              }}
              size='small'
              sx={{
                userSelect: 'none',
                boxSizing: 'border-box',
                borderRadius: '8px',
              }}
            >
              <FormatIndentDecreaseRoundedIcon />
            </IconButton>
          </Tooltip>

          <Tooltip placement='top' title='Indent'>
            <IconButton
              color='default'
              onClick={event => {
                event.stopPropagation()
                event.preventDefault()
                indent(editor)
              }}
              size='small'
              sx={{
                userSelect: 'none',
                boxSizing: 'border-box',
                borderRadius: '8px',
              }}
            >
              <FormatIndentIncreaseRoundedIcon />
            </IconButton>
          </Tooltip>

          <Divider orientation='vertical' />

          <Tooltip placement='top' title='Align left'>
            <IconButton
              color={isAlignedLeft ? 'primary' : 'default'}
              onClick={event => {
                event.stopPropagation()
                event.preventDefault()
                setElements(editor, {
                  'align': 'left',
                })
              }}
              size='small'
              sx={{
                outline: theme => isAlignedLeft
                  ? `1px solid ${theme.palette.primary.main}`
                  : '1px solid transparent',
                color: theme => isAlignedLeft
                  ? `${theme.palette.primary.main}!important`
                  : `${theme.palette.action.active}!important`,
                boxSizing: 'border-box',
                borderRadius: '8px',
              }}
            >
              <FormatAlignLeftRoundedIcon />
            </IconButton>
          </Tooltip>

          <Tooltip placement='top' title='Align center'>
            <IconButton
              color={someNode(editor, { match: { align: 'center' } }) ? 'primary' : 'default'}
              onClick={event => {
                event.stopPropagation()
                event.preventDefault()
                setAlign(editor, { value: 'center' })
              }}
              size='small'
              sx={{
                outline: theme => someNode(editor, { match: { align: 'center' } })
                  ? `1px solid ${theme.palette.primary.main}`
                  : '1px solid transparent',
                color: theme => someNode(editor, { match: { align: 'center' } })
                  ? `${theme.palette.primary.main}!important`
                  : `${theme.palette.action.active}!important`,
                boxSizing: 'border-box',
                borderRadius: '8px',
              }}
            >
              <FormatAlignCenterRoundedIcon />
            </IconButton>
          </Tooltip>

          <Tooltip placement='top' title='Align right'>
            <IconButton
              color={someNode(editor, { match: { align: 'right' } }) ? 'primary' : 'default'}
              onClick={event => {
                event.stopPropagation()
                event.preventDefault()
                setAlign(editor, { value: 'right' })
              }}
              size='small'
              sx={{
                outline: theme => someNode(editor, { match: { align: 'right' } })
                  ? `1px solid ${theme.palette.primary.main}`
                  : '1px solid transparent',
                color: theme => someNode(editor, { match: { align: 'right' } })
                  ? `${theme.palette.primary.main}!important`
                  : `${theme.palette.action.active}!important`,
                boxSizing: 'border-box',
                borderRadius: '8px',
              }}
            >
              <FormatAlignRightRoundedIcon />
            </IconButton>
          </Tooltip>

          <Tooltip placement='top' title='Justify'>
            <IconButton
              color={someNode(editor, { match: { align: 'justify' } }) ? 'primary' : 'default'}
              onClick={event => {
                event.stopPropagation()
                event.preventDefault()
                setAlign(editor, { value: 'justify' })
              }}
              size='small'
              sx={{
                outline: theme => someNode(editor, { match: { align: 'justify' } })
                  ? `1px solid ${theme.palette.primary.main}`
                  : '1px solid transparent',
                color: theme => someNode(editor, { match: { align: 'justify' } })
                  ? `${theme.palette.primary.main}!important`
                  : `${theme.palette.action.active}!important`,
                boxSizing: 'border-box',
                borderRadius: '8px',
              }}
            >
              <FormatAlignJustifyRoundedIcon />
            </IconButton>
          </Tooltip>

          <Divider orientation='vertical' />

          <ButtonColorText
            icon={<FormatColorTextRoundedIcon />}
            itemType={MARK_COLOR}
            tooltip='Text color'
          />

          <ButtonColorText
            icon={<HighlightRoundedIcon />}
            itemType={MARK_BG_COLOR}
            tooltip='Highlight color'
          />

          <Divider orientation='vertical' />

          <Tooltip placement='top' title='Insert link'>
            <IconButton
              color='default'
              size='small'
              sx={{
                outline: '1px solid transparent',
                color: theme => `${theme.palette.action.active}!important`,
                boxSizing: 'border-box',
                borderRadius: '8px',
                '& svg': {
                  height: '23px!important',
                  width: '23px!important',
                },
              }}
            >
              <LinkToolbarButton
                icon={<InsertLinkRoundedIcon />}
              />
            </IconButton>
          </Tooltip>

          <Tooltip placement='top' title='Add comment'>
            <IconButton
              color='default'
              onClick={event => {
                event.stopPropagation()
                event.preventDefault()
                addCommentMark()
              }}
              size='small'
              sx={{
                outline: '1px solid transparent',
                userSelect: 'none',
                color: theme => `${theme.palette.action.active}!important`,
                boxSizing: 'border-box',
                borderRadius: '8px',
                '& svg': {
                  height: '23px!important',
                  width: '23px!important',
                },
              }}
            >
              <ChatBubbleRoundedIcon />
            </IconButton>
          </Tooltip>
          {children}
        </Stack>
      </Card>
    </Popper>
  )
}
