/* eslint-disable unicorn/consistent-function-scoping */
import PowerSettingsNewRoundedIcon from '@mui/icons-material/PowerSettingsNewRounded'
import { Button, Dialog, Stack, Typography, useTheme } from '@mui/material'

import { auth, parseError } from 'src/utils/firebase'

type Props = {
  close: () => void
  open: boolean
}

const LogOutModal = (props: Props) => {
  const themeItem = useTheme()
  const handleLogout = () =>
    auth.signOut()
      .then(() => window.location.reload())
      .catch(parseError)

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      onClose={props.close}
      open={props.open}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        overflow: 'scroll',
      }}
    >
      <Stack alignItems='center' minWidth={400} paddingX={3} paddingY={3} spacing={2}>
        <PowerSettingsNewRoundedIcon color='primary' fontSize='large' />
        <Typography textAlign='center' variant='subtitle1'>
          Log out
        </Typography>
        <Typography color={themeItem.palette.text.secondary} textAlign='center' variant='body2'>
          Are you sure you want to log out?
        </Typography>
        <Stack direction='row' justifyContent='center' spacing={1} width='100%'>
          <Button onClick={() => props.close()} variant='text'>Cancel</Button>
          <Button onClick={handleLogout}>
            Log Out
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default LogOutModal
