/* eslint-disable react/jsx-props-no-spreading */
import type { PlateRenderElementProps, TElement } from '@udecode/plate'
import { createPluginFactory } from '@udecode/plate'

import type { MyValue } from 'src/components/pages/Notes/Modern/typescript/plateTypes'

export const ELEMENT_H2_QUOTE = 'heading-two'

export const LegacyHeaderTwoElement = (props: PlateRenderElementProps<MyValue, TElement>) =>
  <h2
    {...props.attributes}
    {...props.nodeProps}
    className={props.className}
  >
    {props.children}
  </h2>

export const createLegacyHeaderTwoPlugin = createPluginFactory({
  key: ELEMENT_H2_QUOTE,
  isElement: true,
  component: LegacyHeaderTwoElement,
})
