import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import { Button, Dialog, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'

import BillingModal from 'src/components/pages/Account/Billing/BillingModal'
import MoneyAnimation from 'src/components/pages/Projects/EditProject/EditProjectTabs/RecordingsTab/RecordingTabs/RecoupTab/MoneyAnimation'
import { useAuth } from 'src/components/providers/AuthProvider'

type Props = {
  close: () => void
  open: boolean
}

const premiumFeatures = [
  'Expense Tracking',
  'Collaborator Accounting',
  'Distribution Royalty Recoup',
  '2.9% Distribution Commission',
  'Unlimited Distribution',
  'Multi-User Team',
  'Territory Carving',
  'Royalty collection',
  'Next-gen collaborations',
]

const RecoupFreeModal = (props: Props) => {
  const themeColors = useTheme()
  const fullScreen = useMediaQuery(themeColors.breakpoints.down('md'))

  const [billingModal, setBillingModalOpen] = useState(false)
  const { currentOrganisation } = useAuth()
  return (
    <>
      {billingModal && currentOrganisation &&
      <BillingModal
        account={currentOrganisation}
        close={() => setBillingModalOpen(false)}
        open={billingModal}
      />}
      <Dialog
        BackdropProps={{
          timeout: 500,
        }}
        closeAfterTransition
        fullScreen={fullScreen}
        fullWidth
        maxWidth='md'
        onClose={props.close}
        open={props.open}
        sx={{
          justifyContent: 'center',
        }}
      >
        <Stack height={1} paddingX={3} paddingY={3} spacing={3} width={1}>
          <Stack justifyContent='center' width={1}>
            <Typography variant='h3'>
              Keep track of your expenses
            </Typography>
            <Typography color='text.label' variant='body1'>
              Subscribe to the Enterprise Plan to track and recoup expenses
            </Typography>
          </Stack>

          <Grid
            columnSpacing={fullScreen ? 0 : 2}
            container
            rowSpacing={2}
            width={1}
          >
            <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
              <Stack direction='column' spacing={1} width={1}>
                {premiumFeatures.map(item =>
                  <Stack alignItems='center' direction='row' justifyContent='space-between' key={item} width={1}>
                    <Typography variant='body2'>
                      {item}
                    </Typography>
                    <CheckCircleRoundedIcon
                      color='success'
                      fontSize='medium'
                    />
                  </Stack>)}
              </Stack>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
              <MoneyAnimation />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
              <Stack direction='column' justifyContent='flex-end' spacing={1} width={1}>
                <Button
                  onClick={() => setBillingModalOpen(true)}
                >
                  Explore plans
                </Button>
                <Button
                  onClick={props.close}
                  variant='text'
                >
                  Close
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Dialog>
    </>
  )
}

export default RecoupFreeModal
