import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded'
import CloseIcon from '@mui/icons-material/Close'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { AppBar, Avatar, Dialog, Divider, IconButton, ListItemText, Skeleton, Stack, Tab, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'

import AnalyticsStatement from 'src/components/pages/Analytics/AnalyticsTabs/AnalyticsStatement'
import ProjectAnalyticsTab from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/AnalyticsTab/ProjectAnalyticsTab'
import { useAuth } from 'src/components/providers/AuthProvider'
import { Permissions } from 'src/models/Organisation'
import type Recording from 'src/models/Recording'

type Props = {
  close: () => void
  open: boolean
  selectedRecording: string
  recording?: Recording
  loading: boolean
}

const AnalyticsDistributionModal = (props: Props) => {
  const { currentAccountPermissions } = useAuth()
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))
  const [currentTab, setCurrentTab] = useState('statement')

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='lg'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
        width: 1,
      }}
    >
      <Stack height={1} overflow='hidden' width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          paddingX={2}
          position='sticky'
          width={1}
        >
          <Stack alignItems='center' direction='row' paddingY={2} spacing={2}>
            {props.loading
              ? <>
                <Skeleton
                  height={64}
                  variant='rounded'
                  width={64}
                />
                <Skeleton
                  height={20}
                  variant='text'
                  width={100}
                />
              </>
              : <>
                <Avatar
                  src={props.recording?.artworkPreviewUrl}
                  sx={{
                    width: 64,
                    height: 64,
                  }}
                  variant='rounded'
                >
                  <MusicNoteRoundedIcon />
                </Avatar>
                <ListItemText
                  primary={props.recording?.title}
                  secondary={props.recording
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    ? props.recording.primaryArtists?.map(artist => artist.name).join(', ')
                    : ''}
                />
              </>}
          </Stack>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>

        <Divider />
        <Stack height={1} justifyContent='center' overflow='auto' width={1}>
          <TabContext value={currentTab}>
            <AppBar
              position='sticky'
              sx={{
                marginTop: 2,
                paddingX: 2,
                height: 'fit-content',
                background: 'transparent',
              }}
            >
              <TabList
                aria-label='project view'
                id='project-tabs'
                onChange={(_, value: string) => setCurrentTab(value)}
                sx={{
                  width: 1,
                  minHeight: 32,
                }}
                variant='scrollable'
              >
                <Tab
                  disabled={!currentAccountPermissions?.includes(Permissions.VIEW_EARNINGS)}
                  icon={<AnalyticsRoundedIcon />}
                  label='Statements'
                  sx={{
                    minHeight: 32,
                  }}
                  value='statement'
                />
                <Tab
                  disabled={!currentAccountPermissions?.includes(Permissions.VIEW_EARNINGS)}
                  icon={<TrendingUpRoundedIcon />}
                  label='Daily Trends'
                  sx={{
                    minHeight: 32,
                  }}
                  value='dailytrends'
                />
              </TabList>
            </AppBar>
            <Divider />
            <Stack
              sx={{
                overflow: 'auto',
              }}
              width={1}
            >
              {currentAccountPermissions?.includes(Permissions.VIEW_ANALYTICS) &&
              <TabPanel sx={{ width: 1, padding: 0 }} value='statement'>
                <Stack direction='row' height={1} justifyContent='center' width={1}>
                  <AnalyticsStatement
                    selectedRecording={props.selectedRecording}
                  />
                </Stack>
              </TabPanel>}
              {currentAccountPermissions?.includes(Permissions.VIEW_ANALYTICS) &&
              <TabPanel sx={{ width: 1, padding: 0 }} value='dailytrends'>
                <ProjectAnalyticsTab
                  artwork={props.recording?.artworkPreviewUrl ?? ''}
                  selectedRecording={props.selectedRecording}
                />
              </TabPanel>}
            </Stack>
          </TabContext>
        </Stack>
      </Stack>
    </Dialog>
  )
}
export default AnalyticsDistributionModal
