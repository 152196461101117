/* eslint-disable sonarjs/no-duplicate-string */
import { colorSelection } from 'src/components/pages/Task/TaskMenu/TaskMenuComponents/ColorsForPicker'
import { TaskBoardTemplate } from 'src/models/Taskboard'

export const taskboardEmpty = new TaskBoardTemplate({
  title: 'Empty Taskboard',
  color: colorSelection[1],
  taskGroups: [],
  description: `An empty taskboard that can become anything you can dream of, use this clean slate
  as you desire.`,
})
