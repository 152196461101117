/* eslint-disable react/jsx-props-no-spreading */
import type { PlateRenderElementProps, TElement } from '@udecode/plate'
import { createPluginFactory } from '@udecode/plate'

import type { MyValue } from 'src/components/pages/Notes/Modern/typescript/plateTypes'

export const ELEMENT_H1_QUOTE = 'heading-one'

export const LegacyHeaderOneElement = (props: PlateRenderElementProps<MyValue, TElement>) =>
  <h1
    {...props.attributes}
    {...props.nodeProps}
    className={props.className}
  >
    {props.children}
  </h1>

export const createLegacyHeaderOnePlugin = createPluginFactory({
  key: ELEMENT_H1_QUOTE,
  isElement: true,
  component: LegacyHeaderOneElement,
})
