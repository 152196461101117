import { Link, useTheme } from '@mui/material'
import type { ImgHTMLAttributes } from 'react'

import { RouterLink } from 'src/components/SafeRouterLink'
import LogoFull from 'src/images/LogoFull.svg'
import LogoR from 'src/images/LogoR.svg'

type Props = ImgHTMLAttributes<HTMLImageElement> & { full?: boolean }

const ReleeseLogo = (props: Props) => {
  const theme = useTheme()
  return (
    <Link
      component={RouterLink}
      sx={{
        width: props.width ?? 'auto',
        height: props.height ?? '32px',
      }}
      to='/'
      underline='none'
    >
      <img
        alt='Releese Logo'
        height={props.height ?? '32px'}
        src={props.full ? LogoFull : LogoR}
        style={{
          filter: props.full && theme.palette.mode === 'dark' ? 'invert(1)' : 'none',
        }}
        width={props.width}
      />
    </Link>
  )
}

export default ReleeseLogo
