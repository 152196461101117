/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable max-lines */
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded'
import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded'
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded'
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded'
import AttachMoneyIcon from '@mui/icons-material/AttachMoneyRounded'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import ChatBubbleIcon from '@mui/icons-material/ChatBubbleRounded'
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded'
import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded'
import FolderRoundedIcon from '@mui/icons-material/FolderRounded'
import HelpRoundedIcon from '@mui/icons-material/HelpRounded'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded'
import InsightsRoundedIcon from '@mui/icons-material/InsightsRounded'
import LibraryMusicIcon from '@mui/icons-material/LibraryMusicRounded'
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded'
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded'
import PaymentRoundedIcon from '@mui/icons-material/PaymentRounded'
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded'
import PersonIcon from '@mui/icons-material/Person'
import PhonelinkRoundedIcon from '@mui/icons-material/PhonelinkRounded'
import PieChartRoundedIcon from '@mui/icons-material/PieChartRounded'
import PowerSettingsNewRoundedIcon from '@mui/icons-material/PowerSettingsNewRounded'
import SettingsIcon from '@mui/icons-material/Settings'
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded'
import { Box, Button, Collapse, Divider, List, ListItemButton, ListItemIcon, ListItemText, Stack, SwipeableDrawer, Switch, Typography, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { useState } from 'react'

import type { themeSelector } from 'src/components/App'
import OrganizationSelect from 'src/components/form-elements/OrganizationSelectField'
import LogOutModal from 'src/components/pages/Menu/MenuModals/LogoutModal'
import { useAuth } from 'src/components/providers/AuthProvider'
import { NavLink, RouterLink } from 'src/components/SafeRouterLink'
import { Permissions } from 'src/models/Organisation'

type Props = {
  changeTheme: (theme: themeSelector) => void
  currentTheme: string
  isOpen: boolean
  close: () => void
  open: () => void
}

const MobileMenu = (props: Props) => {
  const themeItem = useTheme()
  const [themeChecked, setThemeChecked] = useState(props.currentTheme === 'dark')
  const [openLogoutModal, setOpenLogoutModal] = useState(false)
  const [selectedMenuItem, _setSelectedMenuItem] = useState(0)
  const { currentAccount, currentAccountPermissions } = useAuth()

  const [openCollapse, setOpenCollapse] = useState(false)

  const updateTheme = (event: React.ChangeEvent<HTMLInputElement>) => {
    setThemeChecked(event.target.checked)
    if (event.target.checked) {
      props.changeTheme('dark')
    } else {
      props.changeTheme('light')
    }
  }

  return (
    <SwipeableDrawer
      anchor='bottom'
      disableSwipeToOpen
      draggable
      onClose={props.close}
      onOpen={props.open}
      open={props.isOpen}
      sx={{
        '.MuiDrawer-paper': {
          borderRadius: '16px 16px 0px 0px',
          background: themeItem.palette.background.default,
          maxHeight: '90%',
        },
      }}
    >
      <Box
        sx={{
          width: 64,
          height: 6,
          background: themeItem.palette.divider,
          borderRadius: 3,
          display: 'flex',
          marginY: 1,
          marginX: 'auto',
        }}
      />
      <Stack
        sx={{
          borderRadius: '16px 16px 0px 0px',
          overflow: 'auto',
          paddingBottom: 'env(safe-area-inset-bottom)',
        }}
        width={1}
      >
        <Stack
          alignItems='center'
          height={1}
          justifyContent='space-between'
          overflow='auto'
          paddingY={2}
          width={1}
        >
          <Stack alignItems='center' justifyContent='flex-start' marginBottom={2} width={1}>
            <Stack alignItems='center' marginBottom={2} sx={{ paddingX: 2 }} width={1}>
              <OrganizationSelect />
            </Stack>
            <Divider sx={{ width: 1 }} />
            <List
              sx={{
                width: 1,
                paddingY: 0,
                paddingX: 2,
                '& .MuiListItemIcon-root': {
                  marginRight: '16px!important',
                  fontSize: '1.25rem',
                },
                '.MuiListItemButton-root': {
                  borderRadius: 0,
                  borderBottom: 'none',
                },
              }}
            >
              <RouterLink style={{ textDecoration: 'none' }} to='/'>
                <ListItemButton
                  onClick={() => props.close()}
                  selected={selectedMenuItem === 1}
                >
                  <ListItemIcon>
                    <HomeRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary='Home' />
                </ListItemButton>
              </RouterLink>
              <RouterLink style={{ textDecoration: 'none' }} to='/message'>
                <ListItemButton
                  onClick={() => props.close()}
                  selected={selectedMenuItem === 1}
                >
                  <ListItemIcon>
                    <ChatBubbleIcon />
                  </ListItemIcon>
                  <ListItemText primary='Messages' />
                </ListItemButton>
              </RouterLink>
              <RouterLink style={{ textDecoration: 'none' }} to='/projects/projects'>
                <ListItemButton
                  onClick={() => props.close()}
                  selected={selectedMenuItem === 1}
                >
                  <ListItemIcon>
                    <LibraryMusicIcon />
                  </ListItemIcon>
                  <ListItemText primary='Releases' />
                </ListItemButton>
              </RouterLink>
              <RouterLink style={{ textDecoration: 'none' }} to='/splits'>
                <ListItemButton
                  onClick={() => props.close()}
                  selected={selectedMenuItem === 1}
                >
                  <ListItemIcon>
                    <PieChartRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary='Splits' />
                </ListItemButton>
              </RouterLink>
              <RouterLink style={{ textDecoration: 'none' }} to='/schedule'>
                <ListItemButton
                  onClick={() => props.close()}
                  selected={selectedMenuItem === 1}
                >
                  <ListItemIcon>
                    <CalendarMonthIcon />
                  </ListItemIcon>
                  <ListItemText primary='Schedule' />
                </ListItemButton>
              </RouterLink>
              <RouterLink style={{ textDecoration: 'none' }} to='/calendar'>
                <ListItemButton
                  onClick={() => props.close()}
                  selected={selectedMenuItem === 1}
                >
                  <ListItemIcon>
                    <TaskAltRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary='Tasks' />
                </ListItemButton>
              </RouterLink>
              <RouterLink style={{ textDecoration: 'none' }} to='/files'>
                <ListItemButton
                  onClick={() => props.close()}
                  selected={selectedMenuItem === 1}
                >
                  <ListItemIcon>
                    <FolderRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary='Files' />
                </ListItemButton>
              </RouterLink>
              <RouterLink style={{ textDecoration: 'none' }} to='/links'>
                <ListItemButton
                  onClick={() => props.close()}
                  selected={selectedMenuItem === 1}
                >
                  <ListItemIcon>
                    <DevicesRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary='Smart Links' />
                </ListItemButton>
              </RouterLink>
              <RouterLink style={{ textDecoration: 'none' }} to='/audience/overview'>
                <ListItemButton
                  onClick={() => props.close()}
                  selected={selectedMenuItem === 1}
                >
                  <ListItemIcon>
                    <InsightsRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary='Audience' />
                </ListItemButton>
              </RouterLink>
              <RouterLink style={{ textDecoration: 'none' }} to='/analytics/statements'>
                <ListItemButton
                  onClick={() => props.close()}
                  selected={selectedMenuItem === 1}
                >
                  <ListItemIcon>
                    <AnalyticsRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary='Analytics' />
                </ListItemButton>
              </RouterLink>
              <RouterLink style={{ textDecoration: 'none' }} to='/earnings/distribution'>
                <ListItemButton
                  onClick={() => props.close()}
                  selected={selectedMenuItem === 1}
                >
                  <ListItemIcon>
                    <AttachMoneyIcon />
                  </ListItemIcon>
                  <ListItemText primary='Earnings' />
                </ListItemButton>
              </RouterLink>
              <RouterLink style={{ textDecoration: 'none' }} to='/wallet'>
                <ListItemButton
                  onClick={() => props.close()}
                  selected={selectedMenuItem === 1}
                >
                  <ListItemIcon>
                    <AccountBalanceWalletRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary='Wallet' />
                </ListItemButton>
              </RouterLink>
            </List>

            <List
              sx={{
                width: 1,
                paddingY: 0,
                paddingX: 2,
                '& .MuiListItemIcon-root': {
                  marginRight: '16px!important',
                  fontSize: '1.25rem',
                },
                '.MuiListItemButton-root': {
                  borderRadius: 0,
                  borderBottom: 'none',
                },
              }}
            >
              {openCollapse
                ? <ListItemButton
                  onClick={() => setOpenCollapse(open => !open)}
                >
                  <ListItemIcon>
                    <ArrowDropUpRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary='Settings' />
                </ListItemButton>
                : <ListItemButton
                  onClick={() => setOpenCollapse(open => !open)}
                >
                  <ListItemIcon>
                    <ArrowDropDownRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary='Settings' />
                </ListItemButton>}
            </List>
            <Collapse
              in={openCollapse}
              sx={{
                width: 1,
              }}
            >
              <List
                sx={{
                  width: 1,
                  paddingY: 0,
                  paddingX: 2,
                  '& .MuiListItemIcon-root': {
                    marginRight: '16px!important',
                    fontSize: '1.25rem',
                  },
                  '.MuiListItemButton-root': {
                    borderRadius: 0,
                    borderBottom: 'none',
                  },
                }}
              >
                <RouterLink style={{ textDecoration: 'none' }} to='/settings/account/personalinformation'>
                  <ListItemButton
                    onClick={() => props.close()}
                    selected={selectedMenuItem === 1}
                  >
                    <ListItemIcon>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary='Account Settings' />
                  </ListItemButton>
                </RouterLink>
                <RouterLink style={{ textDecoration: 'none' }} to='/settings/notifications'>
                  <ListItemButton
                    onClick={() => props.close()}
                    selected={selectedMenuItem === 1}
                  >
                    <ListItemIcon>
                      <NotificationsRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary='Notifications' />
                  </ListItemButton>
                </RouterLink>
                {currentAccountPermissions?.includes(Permissions.EDIT_ORGANISATION_SETTINGS)
                  ? <NavLink style={{ textDecoration: 'none' }} to='/settings/general/details'>
                    <ListItemButton
                      onClick={() => props.close()}
                      selected={selectedMenuItem === 1}
                    >
                      <ListItemIcon>
                        <SettingsIcon />
                      </ListItemIcon>
                      <ListItemText primary='Organization settings' />
                    </ListItemButton>
                  </NavLink>
                  : <ListItemButton
                    disabled
                  >
                    <ListItemIcon>
                      <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary='Organization settings' />
                  </ListItemButton>}
                {currentAccountPermissions?.includes(Permissions.ADD_REMOVE_USERS)
                  ? <NavLink style={{ textDecoration: 'none' }} to='/settings/team/members'>
                    <ListItemButton
                      onClick={() => props.close()}
                      selected={selectedMenuItem === 1}
                    >
                      <ListItemIcon>
                        <PeopleAltRoundedIcon />
                      </ListItemIcon>
                      <ListItemText primary='Team' />
                    </ListItemButton>
                  </NavLink>
                  : <ListItemButton
                    disabled
                  >
                    <ListItemIcon>
                      <PeopleAltRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary='Team' />
                  </ListItemButton>}

                {currentAccountPermissions?.includes(Permissions.EDIT_ORGANISATION_SETTINGS)
                  ? <NavLink style={{ textDecoration: 'none' }} to='/settings/marketing/profile'>
                    <ListItemButton
                      onClick={() => props.close()}
                      selected={selectedMenuItem === 1}
                    >
                      <ListItemIcon>
                        <PhonelinkRoundedIcon />
                      </ListItemIcon>
                      <ListItemText primary='Marketing Cloud' />
                    </ListItemButton>
                  </NavLink>
                  : <ListItemButton
                    disabled
                  >
                    <ListItemIcon>
                      <PhonelinkRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary='Marketing Cloud' />
                  </ListItemButton>}

                {currentAccountPermissions?.includes(Permissions.MANAGE_BILLING)
                  ? <NavLink
                    style={{ textDecoration: 'none' }}
                    to='/settings/billing/overview'
                  >
                    <ListItemButton
                      onClick={() => props.close()}
                      selected={selectedMenuItem === 1}
                    >
                      <ListItemIcon>
                        <PaymentRoundedIcon />
                      </ListItemIcon>
                      <ListItemText primary='Billing' />
                    </ListItemButton>
                  </NavLink>
                  : <ListItemButton
                    disabled
                  >
                    <ListItemIcon>
                      <PaymentRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary='Billing' />
                  </ListItemButton>}

                <a
                  href='https://support.releese.io'
                  rel='noreferrer'
                  style={{ textDecoration: 'none' }}
                  target='_blank'
                >
                  <ListItemButton
                    onClick={() => props.close()}
                    selected={selectedMenuItem === 1}
                  >
                    <ListItemIcon>
                      <HelpRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary='Support' />
                  </ListItemButton>
                </a>
              </List>
            </Collapse>
          </Stack>
          <Stack alignItems='center' direction='row' marginBottom={2} spacing={2}>
            <LightModeRoundedIcon color='action' fontSize='large' />
            <Switch
              checked={themeChecked}
              inputProps={{ 'aria-label': 'controlled' }}
              onChange={updateTheme}
            />
            <DarkModeRoundedIcon color='action' fontSize='large' />
          </Stack>
          <Stack direction='column' justifyContent='flex-start' spacing={1} width={1}>
            <Divider sx={{ width: 1 }} />
            <Stack alignItems='center' direction='column' spacing={1}>
              <Stack width='fit-content'>
                <Button
                  color='inherit'
                  onClick={() => setOpenLogoutModal(true)}
                  startIcon={<PowerSettingsNewRoundedIcon />}
                  sx={{
                    color: theme => theme.palette.text.primary,
                  }}
                  variant='text'
                >
                  Log out
                </Button>
              </Stack>
              <Stack direction='row' justifyContent='center' spacing={1}>
                <Typography color='text.secondary' variant='helperText' >Member since: </Typography>
                <Typography color='text.secondary' variant='helperText'>
                  {dayjs(currentAccount.dateOfJoining).format('MMMM YYYY')}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <LogOutModal
            close={() => setOpenLogoutModal(false)}
            open={openLogoutModal}
          />
        </Stack>
      </Stack>
    </SwipeableDrawer>
  )
}

export default MobileMenu
