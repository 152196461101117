/* eslint-disable unicorn/filename-case */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import type { LinkPlugin } from '@udecode/plate'
import { PlateFloatingLink } from '@udecode/plate'

import type { MyPlatePlugin } from 'src/components/pages/Notes/Modern/typescript/plateTypes'

export const linkPlugin: Partial<MyPlatePlugin<LinkPlugin>> = {
  renderAfterEditable: PlateFloatingLink as any,
}
