import AddRoundedIcon from '@mui/icons-material/AddRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { Button, Divider, IconButton, Menu, MenuItem, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { useState } from 'react'

import { GetColorLink } from 'src/components/pages/SmartLink/Content/GetColorLink'
import GetIcon from 'src/components/pages/SmartLink/Content/GetIcon'
import type { ArtistToUpdate, Distribution } from 'src/models/Distribution'

type Props = {
  artistItem: ArtistToUpdate
  setArtists: (value: React.SetStateAction<ArtistToUpdate[]>) => void
  index: number
  distributionItem: Distribution
}

const DistributionProfileArtistItem = (props: Props) => {
  const filteredInputs = props.distributionItem.platforms.filter(item =>
    !props.artistItem.profile.some(selected => selected.name === item.name))
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const openMenu = Boolean(anchorElement)

  const handleClose = () => {
    setAnchorElement(null)
  }

  const findInputIndex = (labelFound: string) =>
    props.artistItem.profile.findIndex(element => element.name === labelFound)

  const onDeleteByIndex = (event: React.MouseEvent<HTMLElement>) => {
    const index = findInputIndex(event.currentTarget.id)
    const temporaryInputList = [...props.artistItem.profile]
    temporaryInputList[index].link = ''
    temporaryInputList.splice(index, 1)
    props.setArtists(old => old.map((oldItem, oldIndex) => oldIndex === props.index
      ? {
        artistName: oldItem.artistName,
        profile: temporaryInputList,
      }
      : oldItem))
  }

  return (
    <>
      <Stack key={props.artistItem.artistName} paddingY={2} spacing={2} width={1}>
        <Divider />
        <Typography
          lineHeight={1}
          variant='h4'
        >
          {props.artistItem.artistName}
        </Typography>
        <Button
          endIcon={<AddRoundedIcon />}
          onClick={event => setAnchorElement(event.currentTarget)}
          sx={{
            width: 'fit-content',
          }}
        >
          Select platforms
        </Button>
        {props.artistItem.profile.map((profileItem, profileIndex) =>
          <Stack
            alignItems='center'
            direction='row'
            key={`${profileItem.name}-${props.artistItem.artistName}`}
            spacing={1.5}
            width={1}
          >
            <IconButton
              disableRipple
              sx={{
                backgroundColor: GetColorLink(profileItem.name),
                color: theme => theme.palette.action.active,
                ':hover': {
                  backgroundColor: GetColorLink(profileItem.name),
                  cursor: 'default',
                },
                borderRadius: '8px',
                height: 42,
                width: 42,
              }}
            >
              {GetIcon(profileItem.name)}
            </IconButton>
            <TextField
              fullWidth
              onChange={event => props.setArtists(old => old.map((oldItem, oldIndex) => oldIndex === props.index
                ? {
                  artistName: oldItem.artistName,
                  profile: oldItem.profile.map((oldProfile, oldProfileIndex) => oldProfileIndex === profileIndex
                    ? {
                      name: oldProfile.name,
                      link: event.target.value,
                    }
                    : oldProfile),
                }
                : oldItem))}
              placeholder={`${profileItem.name} link or leave blank for a new profile`}
              value={profileItem.link}
            />
            <Tooltip title='Remove platform'>
              <IconButton
                color='error'
                id={profileItem.name}
                onClick={onDeleteByIndex}
                size='small'
              >
                <CloseRoundedIcon />
              </IconButton>
            </Tooltip>
          </Stack>)}
        <Divider />
      </Stack>
      <Menu
        anchorEl={anchorElement}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClick={handleClose}
        onClose={handleClose}
        open={openMenu}
        sx={{
          '& .MuiPaper-root': {
            maxHeight: '240px',
            minWidth: '400px',
          },
        }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        {filteredInputs.map(option =>
          <MenuItem
            key={option.name}
            onClick={() => {
              props.setArtists(old => old.map((oldItem, oldIndex) => oldIndex === props.index
                ? {
                  artistName: oldItem.artistName,
                  profile: [...oldItem.profile, { name: option.name, link: '' }],
                }
                : oldItem))
            }}
          >
            <Stack alignItems='center' direction='row' spacing={1}>
              <IconButton
                disableRipple
                sx={{
                  backgroundColor: GetColorLink(option.name),
                  color: theme => theme.palette.action.active,
                  ':hover': {
                    backgroundColor: GetColorLink(option.name),
                    cursor: 'default',
                  },
                  borderRadius: '8px',
                }}
              >
                {GetIcon(option.name)}
              </IconButton>
              <Typography
                sx={{ color: theme => theme.palette.text.secondary }}
                variant='body2'
              >
                {option.name}
              </Typography>
            </Stack>
          </MenuItem>)}
      </Menu>
    </>
  )
}

export default DistributionProfileArtistItem
