/* eslint-disable complexity */
import HelpRoundedIcon from '@mui/icons-material/HelpRounded'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import { Chip, Grid, IconButton, Stack, Tooltip, Typography, useTheme } from '@mui/material'

import { isrcValidation } from 'src/components/pages/Projects/EditProject/EditProjectTabs/RecordingsTab/RecordingTabs/PublishingTab/RecordingPublishingTab'
import type Recording from 'src/models/Recording'

type Props = {
  recording: Recording
  distribution?: boolean
  rights?: boolean
  type?: string
}

const ViewRecordingPublishingTab = (props: Props) => {
  const themeItem = useTheme()
  return (

    <Grid container rowGap={1} spacing={3}>
      <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
        <Stack direction='column'>
          <Stack alignItems='center' direction='row' spacing={1}>
            <Typography color={themeItem.palette.text.label} variant='body2'>
              ISRC
            </Typography>
            {props.distribution && !isrcValidation.test(props.recording.ISRC) &&
          props.recording.ISRC.length > 0 &&
          <Chip color='error' icon={<WarningRoundedIcon />} label='Error' size='small' variant='subtle' />}
            {props.type === 'Publishing' && (!isrcValidation.test(props.recording.ISRC) ||
          props.recording.ISRC.length <= 0) &&
          <Chip color='error' icon={<WarningRoundedIcon />} label='Error' size='small' variant='subtle' />}
          </Stack>
          <Typography variant='body2'>
            {props.recording.ISRC}
          </Typography>
        </Stack>
      </Grid>

      {!props.distribution &&
      <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
        <Stack direction='column'>
          <Typography color={themeItem.palette.text.label} variant='body2'>
            ISWC
          </Typography>
          <Typography variant='body2'>
            {props.recording.ISWC}
          </Typography>
        </Stack>
      </Grid>}

      <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
        <Stack direction='column'>
          <Stack alignItems='center' direction='row' spacing={1}>
            <Typography color={themeItem.palette.text.label} variant='body2'>
              Catalog Number
            </Typography>
          </Stack>
          <Typography variant='body2'>
            {props.recording.catalogNumber}
          </Typography>
        </Stack>
      </Grid>

      <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
        <Stack direction='column'>
          <Stack alignItems='center' direction='row' spacing={1}>
            <Typography color={themeItem.palette.text.label} variant='body2'>
              Composed by
            </Typography>
            {(props.distribution || props.rights) &&
          (props.recording.writtenBy.length === 0 ||
            !props.recording.writtenBy.map(composer => composer.split(' ').length > 1).every(element => element) ||
            props.recording.writtenBy.length > 15) &&
            <>
              <Chip
                color='error'
                icon={<WarningRoundedIcon />}
                label='Error'
                size='small'
                variant='subtle'
              />
              <Tooltip title='Requires at least one writer, all writers should have a first and last name'>
                <IconButton
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                  size='small'
                  sx={{
                    padding: 0,
                  }}
                >
                  <HelpRoundedIcon />
                </IconButton>
              </Tooltip>
            </>}
          </Stack>
          <Stack direction='row' flexWrap='wrap' >
            {props.recording.writtenBy.map(writer =>
              <Chip
                color='default'
                key={writer}
                label={writer}
                sx={{ margin: 0.2 }}
                variant='subtle'
              />)}
          </Stack>
        </Stack>
      </Grid>

      <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
        <Stack direction='column'>
          <Stack alignItems='center' direction='row' spacing={1}>
            <Typography color={themeItem.palette.text.label} variant='body2'>
              Written by
            </Typography>
            {(props.distribution || props.rights) &&
          (props.recording.composedBy.length === 0 ||
            !props.recording.composedBy.map(composer => composer.split(' ').length > 1).every(element => element) ||
            props.recording.composedBy.length > 15) &&
            <>
              <Chip
                color='error'
                icon={<WarningRoundedIcon />}
                label='Error'
                size='small'
                variant='subtle'
              />
              <Tooltip title='Requires at least one composer, all composers should have a first and last name'>
                <IconButton
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                  size='small'
                  sx={{
                    padding: 0,
                  }}
                >
                  <HelpRoundedIcon />
                </IconButton>
              </Tooltip>
            </>}
          </Stack>
          <Stack direction='row' flexWrap='wrap' >
            {props.recording.composedBy.map(composer =>
              <Chip
                color='default'
                key={composer}
                label={composer}
                sx={{ margin: 0.2 }}
                variant='subtle'
              />)}
          </Stack>
        </Stack>
      </Grid>

      <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
        <Stack direction='column'>
          <Stack alignItems='center' direction='row' spacing={1}>
            <Typography color={themeItem.palette.text.label} variant='body2'>
              Produced by
            </Typography>
            {(props.distribution || props.rights) && (props.recording.producedBy.length === 0 ||
            props.recording.producedBy.length > 15) &&
            <Chip color='error' icon={<WarningRoundedIcon />} label='Error' size='small' variant='subtle' />}
          </Stack>
          <Stack direction='row' flexWrap='wrap' >
            {props.recording.producedBy.map(producer =>
              <Chip
                color='default'
                key={producer}
                label={producer}
                sx={{ margin: 0.2 }}
                variant='subtle'
              />)}
          </Stack>
        </Stack>
      </Grid>

      <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
        <Stack direction='column'>
          <Typography color={themeItem.palette.text.label} variant='body2'>
            Preview start
          </Typography>
          <Typography variant='body2'>
            {props.recording.previewStartTime}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  )
}

export default ViewRecordingPublishingTab
