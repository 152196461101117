/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable unicorn/prefer-switch */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable react/no-danger */
/* eslint-disable complexity */
/* eslint-disable array-callback-return */
/* eslint-disable sonarjs/no-ignored-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable max-lines */
import 'src/styles/Custom/iframeItem.css'

import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import AppleIcon from '@mui/icons-material/Apple'
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded'
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded'
import CalendarViewMonthRoundedIcon from '@mui/icons-material/CalendarViewMonthRounded'
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded'
import ColorLensRoundedIcon from '@mui/icons-material/ColorLensRounded'
import DatasetLinkedRoundedIcon from '@mui/icons-material/DatasetLinkedRounded'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import FolderRoundedIcon from '@mui/icons-material/FolderRounded'
import HideSourceRoundedIcon from '@mui/icons-material/HideSourceRounded'
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded'
import ImportExportRoundedIcon from '@mui/icons-material/ImportExportRounded'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import TableViewRoundedIcon from '@mui/icons-material/TableViewRounded'
import TaskRoundedIcon from '@mui/icons-material/TaskRounded'
import ViewKanbanRoundedIcon from '@mui/icons-material/ViewKanbanRounded'
import ViewListRoundedIcon from '@mui/icons-material/ViewListRounded'
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab'
import { Alert, Avatar, Button, Chip, Divider, IconButton, ListItemIcon, Menu, MenuItem, Snackbar, Stack, Tab, TextField, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import store from 'storejs'
import { v4 } from 'uuid'

import { getAccount } from 'src/api/accounts'
import { getOrganisation, onboardingEvent } from 'src/api/organisation'
import { gptTasks, newTaskboardTemplate, newTaskNotification, savePartialTaskboard, updateTaskItem } from 'src/api/taskboards'
import { sendTasksboardUpdate } from 'src/api/webSocket'
import SearchField from 'src/components/form-elements/SearchField'
import GoogleCalendarLogo from 'src/components/Icons/GoogleCalendarLogo'
import BillingModal from 'src/components/pages/Account/Billing/BillingModal'
import TaskboardFiles from 'src/components/pages/Files/Taskboard/TaskBoardFiles'
import NotesInitialValue from 'src/components/pages/Notes/Modern/NotesValueInitial'
import { DropzoneProvider } from 'src/components/pages/Projects/FullscreenDropzone'
import NewTaskModal from 'src/components/pages/Task/TaskDrawer/NewTaskModal'
import TaskDrawerComponent from 'src/components/pages/Task/TaskDrawer/TaskDrawerComponent'
import ImportTemplateModal from 'src/components/pages/Task/TaskMenu/TaskMenuModals/ImportTemplateModal'
import TaskSyncModal from 'src/components/pages/Task/TaskSync/TaskSyncModal'
import AddTaskboardCustomField from 'src/components/pages/Task/TaskViews/TaskViewComponents/AddCustomFieldsModal'
import AddTaskboardEmbedModal, { EMBED_TYPES } from 'src/components/pages/Task/TaskViews/TaskViewComponents/AddEmbedTaskboard'
import AddReleeseDocument from 'src/components/pages/Task/TaskViews/TaskViewComponents/AddReleeseDocument'
import DeleteEmbedModalItem from 'src/components/pages/Task/TaskViews/TaskViewComponents/DeleteEmbedModalItem'
import DeleteTaskboardModal from 'src/components/pages/Task/TaskViews/TaskViewComponents/DeleteTaskboardModal'
import DeleteTaskSectionModal from 'src/components/pages/Task/TaskViews/TaskViewComponents/DeleteTaskSectionModal'
import EditEmbedTaskboard from 'src/components/pages/Task/TaskViews/TaskViewComponents/EditEmbedTaskboard'
import EditTaskboardModal from 'src/components/pages/Task/TaskViews/TaskViewComponents/EditTaskboardDetailsModal'
import EditTaskSectionModal from 'src/components/pages/Task/TaskViews/TaskViewComponents/EditTaskSectionModal'
import FailedIframeView from 'src/components/pages/Task/TaskViews/TaskViewComponents/FailIframe'
import PriorityFilter from 'src/components/pages/Task/TaskViews/TaskViewComponents/FilterPriority'
import FilterStatus from 'src/components/pages/Task/TaskViews/TaskViewComponents/FilterStatus'
import GetIconTaskSmall from 'src/components/pages/Task/TaskViews/TaskViewComponents/GetIconTaskSmall'
import HideFieldsModal from 'src/components/pages/Task/TaskViews/TaskViewComponents/HideFieldsModal'
import { taskboardIconsLarge } from 'src/components/pages/Task/TaskViews/TaskViewComponents/TaskboardIcons'
import TaskboardTemplateFree from 'src/components/pages/Task/TaskViews/TaskViewComponents/TaskboardTemplateFree'
import BoardView from 'src/components/pages/Task/TaskViews/TaskViewTypes/Board'
import Calendar from 'src/components/pages/Task/TaskViews/TaskViewTypes/Calendar'
import DashboardView from 'src/components/pages/Task/TaskViews/TaskViewTypes/Dashboard'
import DashboardFree from 'src/components/pages/Task/TaskViews/TaskViewTypes/DashboardFree'
import HistoryView from 'src/components/pages/Task/TaskViews/TaskViewTypes/HistoryView'
import ListView from 'src/components/pages/Task/TaskViews/TaskViewTypes/List'
import TaskAiTour from 'src/components/pages/Tour/Tasks/TaskAiTour'
import { useAuth } from 'src/components/providers/AuthProvider'
import { useTasksboard } from 'src/components/providers/TasksboardProvider'
import { NavLink } from 'src/components/SafeRouterLink'
import type Account from 'src/models/Account'
import type { Organisation } from 'src/models/Organisation'
import type Project from 'src/models/Project'
import type { CustomFieldValue, TaskBoard, TaskGroup, TasksboardEditField } from 'src/models/Taskboard'
import { TaskBoardEdit, TaskItem } from 'src/models/Taskboard'

type Props = {
  taskboard: TaskBoard
  project?: Project
  refreshBoards?: () => void
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>
  firstDone: boolean
  getTaskBoardMethod: () => void
}

const TaskBoardView = (props: Props) => {
  const { tasksboardUpdates } = useTasksboard()
  const themeItem = useTheme()
  const [billingModal, setBillingModalOpen] = useState(false)
  const matches = useMediaQuery(themeItem.breakpoints.down('md'))
  const { currentOrganisation, currentAccount } = useAuth()
  const [members, setMembers] = useState<Account[]>([])
  const [organisations, setOrganisations] = useState<Organisation[]>([])

  const search = useLocation().search
  const taskId = new URLSearchParams(search).get('taskId')

  useEffect(() => {
    setOrganisations([])
    props.taskboard.members.map(member =>
      void getOrganisation(member)
        .then(account => account && setOrganisations([...organisations, account])))
  }, [])

  useEffect(() => {
    setMembers([])
    void Promise.all(props.taskboard.members.map(member =>
      void getOrganisation(member)
        .then(account => account?.seats.map(seat =>
          setMembers(previousState => [...previousState, seat.account])))))
  }, [])

  useEffect(() => {
    if (props.firstDone) {
      props.getTaskBoardMethod()
    }
  }, [tasksboardUpdates.length])

  const [searchQuery, setSearchQuery] = useState('')
  const [statusQuery, setStatusQuery] = useState('')
  const [priorityQuery, setPriorityQuery] = useState('')

  const [prompt, setPrompt] = useState('')

  const [openSyncModal, setOpenSyncModal] = useState(false)
  const [syncType, setSyncType] = useState<'apple' | 'google'>('google')

  const [editTaskGroupData, setEditTaskGroupData] = useState<TaskGroup>()

  const [taskDrawerData, setTaskDrawerData] = useState<TaskItem>()

  const viewStorage = store.get(`taskboard/${props.taskboard.id}`) as string | undefined
  const [currentTab, setCurrentTab] = useState(viewStorage !== undefined ? viewStorage : 'list')

  useEffect(() => {
    store.set(`taskboard/${props.taskboard.id}`, currentTab)
  }, [currentTab])

  const [deleteSectionModal, setDeleteSectionModalOpen] = useState(false)
  const [addEmbedModal, setAddEmbedModal] = useState(false)

  const [addReleeseEmbedModal, setAddReleeseEmbedModal] = useState(false)

  const [manageFields, setManageFields] = useState(false)
  const [hideFields, setHideFields] = useState(false)

  const [taskDrawerState, setTaskDrawerState] = useState(false)
  const [animation, setAnimation] = useState(false)

  const [loadingFirstAnimation, setLoadingFirstAnimation] = useState(false)

  useEffect(() => {
    if (taskDrawerState) {
      setAnimation(true)
      setLoadingFirstAnimation(true)
    } else if (loadingFirstAnimation) {
      void new Promise(response => setTimeout(response, 200))
        .then(() => {
          setAnimation(false)
        })
    }
  }, [taskDrawerState, loadingFirstAnimation])

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const openMenu = Boolean(anchorElement)

  const [newTaskModal, setNewTaskModalOpen] = useState(false)

  const [deleteTaskModal, setDeleteTaskModalOpen] = useState(false)
  const [editTaskModal, setEditTaskModalOpen] = useState(false)

  const toggleDeleteTaskModal = (toggleState: boolean, taskGroup: TaskGroup) => () => {
    setDeleteSectionModalOpen(toggleState)
    setEditTaskGroupData(taskGroup)
  }

  const [editSectionModal, setEditSectionModalOpen] = useState(false)

  const [anchorElementEmbed, setAnchorElementEmbed] = useState<HTMLElement | null>(null)
  const openMenuEmbed = Boolean(anchorElementEmbed)
  const [manageEmbedItem, setManageEmbed] = useState('')

  const [anchorElementAddEmbed, setAnchorElementAddEmbed] = useState<HTMLElement | null>(null)
  const openMenuAddEmbed = Boolean(anchorElementAddEmbed)
  const [addEmbedItem, setAddEmbed] = useState('HTML')

  const handleMenuAddEmbedClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElementAddEmbed(event.currentTarget)
  }

  const [editEmbedModal, setEditEmbedModal] = useState(false)
  const [deleteEmbedModal, setDeleteEmbedModal] = useState(false)

  const toggleEditTaskModal = (toggleState: boolean, taskGroup: TaskGroup) => () => {
    setEditSectionModalOpen(toggleState)
    setEditTaskGroupData(taskGroup)
  }

  const handleStatusChange = (value: string) => {
    setStatusQuery(value)
  }

  const handlePriorityChange = (value: string) => {
    setPriorityQuery(value)
  }

  const [anchorElementAi, setAnchorElementAi] = useState<HTMLElement | null>(null)
  const openMenuAi = Boolean(anchorElementAi)
  const handleMenuAiClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElementAi(event.currentTarget)
  }

  const handleCloseAi = () => {
    setAnchorElementAi(null)
  }

  const [aiError, setAiError] = useState(false)
  const [loadingGpt, setLoadingGpt] = useState(false)

  const generateAiTasks = async () => {
    void onboardingEvent('Generate AI tasks')
    setLoadingGpt(true)
    setAiError(false)
    await gptTasks(prompt)
      .then(async items => {
        if (props.taskboard.id) {
          const emptyTaskGroup: TaskGroup = {
            id: v4(),
            title: 'Ai Prompt',
            color: '#828DF8',
            tasks: [],
          }
          for (const item of items) {
            if (item.length > 0) {
              const stringItem = item.replace(/^\d+\. /m, '').replace('- ', '')
              const singleTasks = new TaskItem({ title: stringItem, id: v4(), assignee: [] })
              emptyTaskGroup.tasks.push(singleTasks)
            }
          }
          props.taskboard.taskGroups.push(emptyTaskGroup)
          await savePartialTaskboard({ id: props.taskboard.id, ...props.taskboard })
          const taskUpdateItem = new TaskBoardEdit({
            id: v4(),
            taskboardId: props.taskboard.id,
            taskId: '',
            accountId: currentAccount.id,
            modificationType: 'Task created',
            itemType: 'Field',
          })
          sendTasksboardUpdate(taskUpdateItem)
        }
      })
      .finally(() => {
        setLoadingGpt(false)
        handleCloseAi()
        setPrompt('')
      })
      .catch(() => {
        setAiError(true)
      })
  }

  useEffect(() => {
    if (taskId) {
      for (const groupItem of props.taskboard.taskGroups) {
        const taskIndex = groupItem.tasks.findIndex(item => item.id === taskId)
        if (taskIndex !== -1) {
          toggleTaskDrawerCalendar(
            true,
            groupItem,
            groupItem.tasks[taskIndex],
          )
        }
      }
    } else {
      setLoadingFirstAnimation(true)
    }
  }, [taskId])

  const closeTaskDrawer = (requestDeletion: boolean,
    taskData?: TaskItem,
    assignees?: string[],
    originalData?: TaskItem) => async () => {
    if (requestDeletion && props.taskboard.id && taskData && editTaskGroupData) {
      const taskCopy = { ...props.taskboard.taskGroups[props.taskboard.taskGroups.findIndex(group =>
        group.id === editTaskGroupData.id)]
        .tasks[props.taskboard.taskGroups[props.taskboard.taskGroups
          .findIndex(group => group.id === editTaskGroupData.id)]
          .tasks.findIndex(task => task.id === taskData.id)] }
      props.taskboard.taskGroups[props.taskboard.taskGroups.findIndex(group => group.id === editTaskGroupData.id)]
        .tasks.splice(props.taskboard.taskGroups[props.taskboard.taskGroups
          .findIndex(group => group.id === editTaskGroupData.id)]
          .tasks.findIndex(task => task.id === taskData.id), 1)
      await savePartialTaskboard({ id: props.taskboard.id, ...props.taskboard })
        .then(() => {
          const taskUpdateItem = new TaskBoardEdit({
            id: v4(),
            taskboardId: props.taskboard.id,
            taskId: '',
            accountId: currentAccount.id,
            modificationType: 'Task deleted',
            itemType: 'Field',
            title: `${taskCopy.title}`,
          })
          sendTasksboardUpdate(taskUpdateItem)
        })
        .then(() => setTaskDrawerState(false))
    } else if (props.taskboard.id && taskData && editTaskGroupData && originalData) {
      taskData.lastUpdated = new Date()
      props.taskboard.taskGroups[props.taskboard.taskGroups.findIndex(group => group.id === editTaskGroupData.id)]
        .tasks[props.taskboard.taskGroups[props.taskboard.taskGroups
          .findIndex(group => group.id === editTaskGroupData.id)]
          .tasks.findIndex(task => task.id === taskData.id)] = taskData
      if (assignees !== taskData.assignee) {
        const newAssigness = taskData.assignee.filter(assigneeItem => !assignees?.includes(assigneeItem))

        newAssigness.map(async assigneeItem => {
          if (assigneeItem !== currentAccount.id) {
            await newTaskNotification(assigneeItem, {
              description: taskData.description,
              taskboard: props.taskboard.title,
              due_date: taskData.dueDate ? dayjs(taskData.dueDate).format('MMMM D') : 'No due date',
              task_name: taskData.title,
              taskboard_id: props.taskboard.id ?? '',
              task_id: taskData.id,
            })
          }
        })
      }
      const differences = _.reduce(taskData, (result: string[], value, key) =>
        _.isEqual(value, originalData[key as unknown as keyof TaskItem])
          ? result : [...result, key], [])

      if (differences.length > 0) {
        const fields: TasksboardEditField[] = []

        for (const difference of differences) {
          const typedString = difference as unknown as keyof TaskItem

          if (typedString !== 'updates' &&
        typedString !== 'customFields' &&
        typedString !== 'assignee' &&
        typedString !== 'description' &&
        typedString !== 'startDate' &&
        typedString !== 'dueDate' &&
        typedString !== 'recurring' &&
        typedString !== 'startTime' &&
        typedString !== 'dueTime' &&
        typedString !== 'tags' &&
        typedString !== 'lastDoneOn' &&
        typedString !== 'allDay' &&
        typedString !== 'lastUpdated' &&
        typedString !== 'checklist') {
            fields.push({
              fieldName: difference.charAt(0).toUpperCase() + difference.slice(1),
              oldValue: originalData[typedString].toString().length > 0
                ? originalData[typedString].toString()
                : 'Unset',
              newValue: taskData[typedString].toString().length > 0
                ? taskData[typedString].toString()
                : 'Unset',
            })
          } else if (typedString === 'startDate' || typedString === 'dueDate') {
            const title = typedString === 'startDate' ? 'Start Date' : 'Due Date'
            fields.push({
              fieldName: title,
              oldValue: originalData[typedString] !== null && originalData[typedString] !== undefined
                ? dayjs(new Date(originalData[typedString] ?? 0)).format('ll')
                : 'Unset',
              newValue: taskData[typedString] !== null && taskData[typedString] !== undefined
                ? dayjs(new Date(taskData[typedString] ?? 0)).format('ll')
                : 'Unset',
            })
          } else if (typedString === 'startTime' || typedString === 'dueTime') {
            const title = typedString === 'startTime'
              ? 'Start Time'
              : 'Due Time'
            fields.push({
              fieldName: title,
              oldValue: originalData[typedString].length > 0
                ? originalData[typedString]
                : 'None',
              newValue: taskData[typedString].length > 0
                ? taskData[typedString]
                : 'None',
            })
          } else if (typedString === 'tags') {
            fields.push({
              fieldName: 'Tags',
              oldValue: originalData[typedString].length > 0
                ? originalData[typedString].join(', ')
                : 'None',
              newValue: taskData[typedString].length > 0
                ? taskData[typedString].join(', ')
                : 'None',
            })
          } else if (typedString === 'allDay') {
            fields.push({
              fieldName: 'All day',
              oldValue: originalData[typedString]
                ? 'True'
                : 'False',
              newValue: taskData[typedString]
                ? 'True'
                : 'False',
            })
          } else if (typedString === 'customFields') {
            for (const [index, fieldItemTest] of taskData.customFields.entries()) {
              const differencesItem = _.reduce(fieldItemTest, (result: string[], value, key) =>
                _.isEqual(value, originalData.customFields[index]?.[key as unknown as keyof CustomFieldValue] ?? '')
                  ? result : [...result, key], [])

              const fieldChanges = props.taskboard.customFields.find(item => item.id === fieldItemTest.rowId)

              if (differencesItem.length > 0 && fieldChanges) {
                let olValue = ''
                let newValue = ''
                if (fieldChanges.fieldType === 'Checkbox') {
                  newValue = fieldItemTest.checkboxValue ? 'Checked' : 'Unchecked'
                  olValue = fieldItemTest.checkboxValue ? 'Unchecked' : 'Checked'
                } else if (fieldChanges.fieldType === 'Number') {
                  newValue = fieldItemTest.numberValue.toString() ?? '0'
                  olValue = originalData.customFields[index]?.numberValue.toString() ?? '0'
                } else if (fieldChanges.fieldType === 'Text' ||
                fieldChanges.fieldType === 'Phone' ||
                fieldChanges.fieldType === 'Email') {
                  newValue = fieldItemTest.stringValue ?? 'None'
                  olValue = originalData.customFields[index]?.stringValue ?? 'None'
                } else if (fieldChanges.fieldType === 'Chips') {
                  newValue = fieldItemTest.stringArrayValue.join(', ') ?? 'None'
                  olValue = originalData.customFields[index]?.stringArrayValue.join(', ') ?? 'None'
                } else if (fieldChanges.fieldType === 'Date') {
                  newValue = fieldItemTest.dateValue !== null && fieldItemTest.dateValue !== undefined
                    ? dayjs(new Date(fieldItemTest.dateValue ?? 0)).format('ll')
                    : 'Unset'
                  olValue = originalData.customFields[index]?.dateValue !== null &&
                originalData.customFields[index]?.dateValue !== undefined
                    ? dayjs(new Date(fieldItemTest.dateValue ?? 0)).format('ll')
                    : 'Unset'
                } else if (fieldChanges.fieldType === 'Website') {
                  newValue = fieldItemTest.websiteValue ?? 'None'
                  olValue = originalData.customFields[index]?.websiteValue ?? 'None'
                } else if (fieldChanges.fieldType === 'Rating') {
                  newValue = fieldItemTest.ratingValue.toString() ?? '0'
                  olValue = originalData.customFields[index]?.ratingValue.toString() ?? '0'
                } else if (fieldChanges.fieldType === 'Dropdown' || fieldChanges.fieldType === 'Status') {
                  newValue = fieldItemTest.dropdownValue.toString() ?? 'None'
                  olValue = originalData.customFields[index]?.dropdownValue.toString() ?? 'None'
                } else if (fieldChanges.fieldType === 'People') {
                  const newAccounts: Account[] = []
                  const oldAccounts: Account[] = []

                  for (const assignee of originalData.customFields[index]?.peopleValue ?? []) {
                    await getAccount(assignee)
                      .then(account => {
                        if (account) {
                          oldAccounts.push(account)
                        }
                      })
                  }

                  for (const assignee of fieldItemTest.peopleValue ?? []) {
                    await getAccount(assignee)
                      .then(account => {
                        if (account) {
                          newAccounts.push(account)
                        }
                      })
                  }
                  olValue = oldAccounts.length === 0
                    ? 'None'
                    : oldAccounts.map(accountItem => `${accountItem.firstName} ${accountItem.lastName}`).join(', ')
                  newValue = newAccounts.length === 0
                    ? 'None'
                    : newAccounts.map(accountItem => `${accountItem.firstName} ${accountItem.lastName}`).join(', ')
                }

                fields.push({
                  fieldName: fieldChanges.title,
                  oldValue: olValue,
                  newValue,
                })
              }
            }
          }
        }

        if (assignees !== taskData.assignee) {
          const newAccounts: Account[] = []
          const oldAccounts: Account[] = []

          for (const assignee of assignees ?? []) {
            await getAccount(assignee)
              .then(account => {
                if (account) {
                  oldAccounts.push(account)
                }
              })
          }

          for (const assignee of taskData.assignee ?? []) {
            await getAccount(assignee)
              .then(account => {
                if (account) {
                  newAccounts.push(account)
                }
              })
          }

          fields.push({
            fieldName: 'Assignees',
            oldValue: oldAccounts.length === 0
              ? 'None'
              : oldAccounts.map(accountItem => `${accountItem.firstName} ${accountItem.lastName}`).join(', '),
            newValue: newAccounts.length === 0
              ? 'None'
              : newAccounts.map(accountItem => `${accountItem.firstName} ${accountItem.lastName}`).join(', '),
          })
        }

        await updateTaskItem(props.taskboard.id, editTaskGroupData.id, taskData)
          .then(() => {
            const taskUpdateItem = new TaskBoardEdit({
              id: v4(),
              taskboardId: props.taskboard.id,
              taskId: taskData.id,
              accountId: currentAccount.id,
              modificationType: 'Task details',
              itemType: 'Field',
              title: `${originalData.title}`,
              fields,
            })
            sendTasksboardUpdate(taskUpdateItem)
          })
          .then(() => setTaskDrawerState(false))
      } else {
        setTaskDrawerState(false)
      }
    }
  }

  const toggleTaskDrawer = (toggleState: boolean, taskGroup: TaskGroup, taskData?: TaskItem) => () => {
    if (taskData) {
      setTaskDrawerData(taskData)
    }
    setEditTaskGroupData(taskGroup)
    setTaskDrawerState(toggleState)
  }

  const toggleTaskDrawerCalendar = (toggleState: boolean, taskGroup: TaskGroup, taskData?: TaskItem) => {
    if (taskData) {
      setTaskDrawerData(taskData)
    }
    setEditTaskGroupData(taskGroup)
    setTaskDrawerState(toggleState)
  }

  const toggleNewTaskModal = (toggleState: boolean, taskGroup?: TaskGroup) => () => {
    if (taskGroup) {
      setEditTaskGroupData(taskGroup)
      setNewTaskModalOpen(toggleState)
    } else {
      setEditTaskGroupData(undefined)
      setNewTaskModalOpen(toggleState)
    }
  }

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget)
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
  }
  const handleClose = () => {
    setAnchorElement(null)
  }

  const handleCloseEmbed = () => {
    setAnchorElementEmbed(null)
    setAddEmbed('HTML')
  }

  const handleCloseAddEmbed = () => {
    setAnchorElementAddEmbed(null)
  }

  const [templateCreatedToast, setTemplateCreatedToastOpen] = useState(false)
  const [freeTaskboardTemplate, setFreeTaskboardTemplate] = useState(false)
  const [switchTemplate, setSwitchTemplate] = useState(false)

  const handleCloseToast = (event: Event | React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setTemplateCreatedToastOpen(false)
  }

  const createTaskboardTemplate = async () => {
    if (currentOrganisation?.membershipTier !== 'FREE') {
      await newTaskboardTemplate({
        id: v4(),
        title: props.taskboard.title,
        color: props.taskboard.color,
        taskGroups: props.taskboard.taskGroups,
        createdAt: new Date(),
        icon: props.taskboard.icon,
        customFields: props.taskboard.customFields,
        hiddenFields: props.taskboard.hiddenFields,
        fieldOrder: props.taskboard.fieldOrder,
      }).then(() => {
        setTemplateCreatedToastOpen(true)
      })
    } else {
      setFreeTaskboardTemplate(true)
    }
  }

  return (
    <>
      <TaskAiTour />
      <TaskboardTemplateFree
        close={() => setFreeTaskboardTemplate(false)}
        open={freeTaskboardTemplate}
      />
      {switchTemplate &&
      <ImportTemplateModal
        close={() => setSwitchTemplate(false)}
        open={switchTemplate}
        setRefresh={props.setRefresh}
        taskboard={props.taskboard}
      />}
      <Snackbar
        autoHideDuration={5000}
        message='Link Copied'
        onClose={handleCloseToast}
        open={templateCreatedToast}
      >
        <Alert
          onClose={handleCloseToast}
          severity='success'
          sx={{ width: '100%' }}
          variant='filled'
        >
          Template created
        </Alert>
      </Snackbar>
      <TabContext value={currentTab}>
        <Stack
          alignItems='stretch'
          boxSizing='border-box'
          direction='column'
          height={1}
          minWidth={0}
          padding={0}
          width={1}
        >
          <Stack
            alignItems='flex-end'
            direction='row'
            minHeight={!props.project ? 88 : 48}
            width={1}
          >
            <Stack
              height={1}
              sx={{
                alignItems: 'flex-end',
              }}
              width={1}
            >
              <Stack alignItems='flex-end' direction='row' height={!props.project ? 1 : 48} width={1}>
                {matches && !props.project &&
                <IconButton
                  color='primary'
                  component={NavLink}
                  size='small'
                  sx={{
                    paddingLeft: 2,
                    paddingBottom: 2,
                  }}
                  to='/calendar'
                >
                  <ChevronLeftRoundedIcon />
                </IconButton>}
                {!props.project &&
                <Stack height={1} justifyContent='center' paddingLeft={2}>
                  <Avatar
                    sx={{
                      width: 64,
                      height: 64,
                      borderRadius: 1,
                      bgcolor: props.taskboard.color,
                      '.MuiSvgIcon-root': {
                        color: theme => theme.palette.primary.contrastText,
                      },
                    }}
                  >
                    {taskboardIconsLarge[props.taskboard.icon]}
                  </Avatar>
                </Stack>}

                <Stack
                  direction='column'
                  height={!props.project ? 80 : 'auto'}
                  marginTop={!props.project ? 0 : 2}
                  paddingLeft={2}
                  width={props.project
                    ? 'calc(100% - 52px)'
                    : matches
                      ? 'calc(100% - 176px)'
                      : 'calc(100% - 128px)'}
                >
                  {!props.project &&
                  <Stack height={1} justifyContent='center' width='fit-content'>
                    <Typography variant='h5'>
                      {props.taskboard.title}
                    </Typography>
                  </Stack>}

                  <TabList
                    aria-label='Current taskboard view'
                    onChange={(event, value: string) => setCurrentTab(value)}
                    sx={{ minHeight: '32px' }}
                    variant='scrollable'
                  >
                    <Tab
                      icon={<ViewListRoundedIcon />}
                      iconPosition='start'
                      label='List'
                      sx={{ minHeight: 32 }}
                      value='list'
                    />
                    <Tab
                      icon={<ViewKanbanRoundedIcon />}
                      iconPosition='start'
                      label='Board'
                      sx={{ minHeight: 32 }}
                      value='board'
                    />
                    <Tab
                      icon={<CalendarViewMonthRoundedIcon />}
                      iconPosition='start'
                      label='Calendar'
                      sx={{ minHeight: 32 }}
                      value='calendar'
                    />
                    <Tab
                      icon={<FolderRoundedIcon />}
                      iconPosition='start'
                      label='Files'
                      sx={{ minHeight: 32 }}
                      value='files'
                    />
                    <Tab
                      icon={<TableViewRoundedIcon />}
                      iconPosition='start'
                      label='Dashboard'
                      sx={{ minHeight: 32 }}
                      value='dashboard'
                    />
                    <Tab
                      icon={<HistoryRoundedIcon />}
                      iconPosition='start'
                      label='History'
                      sx={{ minHeight: 32 }}
                      value='history'
                    />
                    {props.taskboard.embeddedItems.map(embed =>
                      <Tab
                        icon={GetIconTaskSmall(embed.embedType)}
                        iconPosition='start'
                        key={embed.id}
                        label={
                          <>
                            {embed.title}
                            <IconButton
                              onClick={event => {
                                event.stopPropagation()
                                event.preventDefault()
                                setManageEmbed(embed.id)
                                setAnchorElementEmbed(event.currentTarget)
                              }}
                              size='small'
                              sx={{
                                marginLeft: 1,
                                width: 24,
                                height: 24,
                                opacity: 0.5,
                                transition: 'opacity 0.5s ease-out 100ms',
                                ':hover': {
                                  opacity: 1,
                                },
                              }}
                            >
                              <MoreVertRoundedIcon
                                sx={{
                                  fontSize: 24,
                                }}
                              />
                            </IconButton>
                          </>
                        }
                        sx={{ minHeight: 32 }}
                        value={embed.id}
                      />)}
                    <IconButton
                      aria-label='more'
                      onClick={handleMenuAddEmbedClick}
                      size='small'
                    >
                      <AddCircleOutlineRoundedIcon />
                    </IconButton>
                  </TabList>

                </Stack>

                <Stack
                  alignItems='center'
                  direction='row'
                  height={1}
                  paddingBottom={!props.project ? 1 : 0}
                  paddingLeft={1}
                  spacing={1}
                >
                  <Tooltip title='Settings'>
                    <IconButton
                      aria-label='more'
                      onClick={handleMenuClick}
                      size='small'
                    >
                      <MoreVertRoundedIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>

              </Stack>
            </Stack>
          </Stack>
          <Divider style={{ width: '100%' }} />
          {(currentTab === 'list' ||
          currentTab === 'board' ||
          currentTab === 'calendar' ||
          currentTab === 'dashboard') &&
          <Stack
            alignItems='center'
            direction='row'
            justifyContent='space-between'
            padding={2}
            spacing={2}
            sx={{
              overflowX: 'auto',
              overflowY: 'clip',
            }}
          >
            <Stack direction='row' spacing={1}>
              <Button
                onClick={toggleNewTaskModal(true)}
                startIcon={<AddRoundedIcon />}
                sx={{ width: 110 }}
              >
                Add task
              </Button>
              <Button
                color='info'
                id='ai-tasks'
                onClick={event => handleMenuAiClick(event)}
                startIcon={<AutoAwesomeRoundedIcon />}
                sx={{ width: 120 }}
              >
                AI Assistant
              </Button>
              <SearchField
                onChange={event => setSearchQuery(event.target.value)}
                sx={{ width: 160, marginTop: 0, marginRight: 2 }}
                value={searchQuery}
              />
              <FilterStatus
                setInputSelected={handleStatusChange}
                statusFilter={statusQuery}
              />
              <PriorityFilter
                priorityFilter={priorityQuery}
                setInputSelected={handlePriorityChange}
              />
            </Stack>
          </Stack>}
          <Stack
            sx={{
              width: 1,
              height: 1,
              overflow: 'auto',
            }}
          >
            <TabPanel sx={{ height: 1, width: 1, padding: 0 }} value='board'>
              <BoardView
                members={members}
                priorityQuery={priorityQuery}
                searchQuery={searchQuery}
                setRefresh={props.setRefresh}
                setSwitchTemplate={setSwitchTemplate}
                statusQuery={statusQuery}
                taskDataSet={props.taskboard}
                toggleDeleteTaskModal={toggleDeleteTaskModal}
                toggleEditTaskModal={toggleEditTaskModal}
                toggleTaskDrawer={toggleTaskDrawer}
                toggleTaskModal={toggleNewTaskModal}
              />
            </TabPanel>
            <TabPanel sx={{ height: 1, width: 1, padding: 0 }} value='calendar'>
              <Calendar
                members={members}
                priorityQuery={priorityQuery}
                searchQuery={searchQuery}
                statusQuery={statusQuery}
                taskDataSet={props.taskboard.taskGroups}
                taskboard={props.taskboard}
                toggleTaskDrawer={toggleTaskDrawerCalendar}
              />
            </TabPanel>
            <TabPanel sx={{ height: 1, width: 1, padding: 0 }} value='history'>
              <HistoryView
                members={members}
              />
            </TabPanel>
            <TabPanel sx={{ height: 1, width: 1, padding: 0 }} value='dashboard'>
              {currentOrganisation?.membershipTier === 'FREE'
                ? <DashboardFree />
                : <DashboardView
                  taskDataSet={props.taskboard.taskGroups}
                />}
            </TabPanel>
            <TabPanel sx={{ height: 1, width: 1, padding: 0 }} value='list'>
              <ListView
                members={members}
                priorityQuery={priorityQuery}
                searchQuery={searchQuery}
                setRefresh={props.setRefresh}
                setSwitchTemplate={setSwitchTemplate}
                statusQuery={statusQuery}
                taskDataSet={props.taskboard}
                toggleDeleteTaskModal={toggleDeleteTaskModal}
                toggleEditTaskModal={toggleEditTaskModal}
                toggleTaskDrawer={toggleTaskDrawer}
                toggleTaskModal={toggleNewTaskModal}
              />
            </TabPanel>
            <Stack
              sx={{
                width: 1,
                height: 1,
                padding: 0,
                display: currentTab === 'files' ? 'flex' : 'none',
              }}
            >
              <Stack height={1} width={1}>
                <Stack height={1} paddingBottom={4} paddingTop={2} paddingX={2} width={1}>
                  <DropzoneProvider>
                    <TaskboardFiles
                      home
                      taskboardId={props.taskboard.id}
                    />
                  </DropzoneProvider>
                </Stack>
              </Stack>
            </Stack>
            {props.taskboard.embeddedItems.map(embed =>
              <TabPanel key={embed.id} sx={{ height: 1, width: 1, padding: 0 }} value={embed.id}>
                {embed.embedType === 'Releese Doc'
                  ? currentTab === embed.id &&
                  <NotesInitialValue
                    slug={embed.content}
                  />
                  : embed.embedType === 'HTML'
                    ? embed.content.startsWith('<iframe')
                      ? <div
                        className='projectIframe'
                        dangerouslySetInnerHTML={{ __html: embed.content }}
                        style={{
                          width: '100%',
                          height: '100%',
                        }}
                      />
                      : <FailedIframeView />
                    : embed.content.startsWith('https://')
                      ? <div
                        className='projectIframe'
                        dangerouslySetInnerHTML={{
                          __html:
                    `<iframe src=${embed.content}></iframe>`,
                        }}
                        style={{
                          width: '100%',
                          height: '100%',
                        }}
                      />
                      : <FailedIframeView />}

              </TabPanel>)}
          </Stack>
          {animation &&
            <TaskDrawerComponent
              closeTaskDrawer={closeTaskDrawer}
              editTaskGroupData={editTaskGroupData}
              members={members}
              open={taskDrawerState}
              taskDataSet={taskDrawerData}
              taskboard={props.taskboard}
            />}
        </Stack>
      </TabContext>

      <Menu
        anchorEl={anchorElement}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClick={handleClose}
        onClose={handleClose}
        open={openMenu}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <MenuItem
          onClick={() => {
            setSyncType('google')
            setOpenSyncModal(true)
          }}
        >
          <ListItemIcon>
            <GoogleCalendarLogo fontSize='medium' />
          </ListItemIcon>
          Add to Google Calendar
        </MenuItem>
        <MenuItem
          onClick={() => {
            setSyncType('apple')
            setOpenSyncModal(true)
          }}
        >
          <ListItemIcon>
            <AppleIcon color='action' fontSize='medium' />
          </ListItemIcon>
          Add to Apple Calendar
        </MenuItem>

        <Divider sx={{ borderColor: theme => theme.palette.text.label }} />
        <MenuItem onClick={() => setManageFields(true)}>
          <ListItemIcon>
            <AddBoxRoundedIcon fontSize='medium' />
          </ListItemIcon>
          Add custom field
        </MenuItem>
        <MenuItem onClick={() => {
          setAddEmbed('HTML')
          setAddEmbedModal(true)
        }}
        >
          <ListItemIcon>
            <DatasetLinkedRoundedIcon fontSize='medium' />
          </ListItemIcon>
          Embed content
        </MenuItem>
        <MenuItem onClick={() => setHideFields(true)}>
          <ListItemIcon>
            <HideSourceRoundedIcon fontSize='medium' />
          </ListItemIcon>
          Manage hidden fields
        </MenuItem>
        {!props.project &&
        <MenuItem onClick={() => setEditTaskModalOpen(true)} >
          <ListItemIcon>
            <ColorLensRoundedIcon fontSize='medium' />
          </ListItemIcon>
          Edit taskboard details
        </MenuItem>}
        <Divider sx={{ borderColor: theme => theme.palette.text.label }} />
        <MenuItem onClick={() => setSwitchTemplate(true)}>
          <ListItemIcon>
            <ImportExportRoundedIcon fontSize='medium' />
          </ListItemIcon>
          Import template
        </MenuItem>
        <MenuItem onClick={() => createTaskboardTemplate()} >
          <ListItemIcon>
            <TaskRoundedIcon fontSize='medium' />
          </ListItemIcon>
          Create template
        </MenuItem>
        {!props.project &&
        <Divider sx={{ borderColor: theme => theme.palette.text.label }} />}
        {!props.project &&
        <MenuItem onClick={() => setDeleteTaskModalOpen(true)} >
          <ListItemIcon>
            <DeleteRoundedIcon color='error' fontSize='medium' />
          </ListItemIcon>
          Delete taskboard
        </MenuItem>}
      </Menu>

      <Menu
        anchorEl={anchorElementEmbed}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClick={handleCloseEmbed}
        onClose={handleCloseEmbed}
        open={openMenuEmbed}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <MenuItem onClick={() => setEditEmbedModal(true)}>
          <ListItemIcon>
            <EditRoundedIcon fontSize='medium' />
          </ListItemIcon>
          Edit item
        </MenuItem>
        <MenuItem onClick={() => setDeleteEmbedModal(true)}>
          <ListItemIcon>
            <DeleteRoundedIcon color='error' fontSize='medium' />
          </ListItemIcon>
          Delete item
        </MenuItem>
      </Menu>

      {currentOrganisation?.membershipTier !== 'FREE' &&
      <Menu
        anchorEl={anchorElementAi}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={handleCloseAi}
        open={openMenuAi}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <Stack padding={2} spacing={2} width={360}>
          <Stack marginBottom={2} width={1}>
            <Stack alignItems='center' direction='row' justifyContent='space-between' width={1}>
              <Typography lineHeight={1.3} variant='h3'>
                AI Assistant
              </Typography>
            </Stack>
            <Typography variant='body2'>
              Generate tasks from a simple text query and populate your Releese Taskboard.
            </Typography>
            {aiError &&
            <Typography color='error' variant='body2'>
              There was a problem generating your tasks, please try again
            </Typography>}
          </Stack>
          <TextField
            minRows={4}
            multiline
            onChange={event => setPrompt(event.target.value)}
            placeholder='Generate a marketing plan for a pop music release on streaming platforms'
            value={prompt}
          />
          <LoadingButton
            color='primary'
            loading={loadingGpt}
            onClick={() => generateAiTasks()}
            startIcon={<AutoAwesomeRoundedIcon />}
            variant='contained'
          >
            Generate tasks
          </LoadingButton>
        </Stack>
      </Menu>}

      {currentOrganisation?.membershipTier === 'FREE' &&
      <Menu
        anchorEl={anchorElementAi}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={handleCloseAi}
        open={openMenuAi}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <Stack padding={2} spacing={2} width={360}>
          <Stack width={1}>
            <Stack alignItems='center' direction='row' justifyContent='space-between' width={1}>
              <Typography variant='h2'>
                AI Assist
              </Typography>
              <Chip
                color='success'
                label='Beta'
                variant='subtle'
              />
            </Stack>
            <Typography variant='body2'>
              Generate tasks from a simple text query and populate your Releese Taskboard using the GPT-3 model.
              Available on all paid plans
            </Typography>

          </Stack>
          <Button
            color='info'
            onClick={() => setBillingModalOpen(true)}
            startIcon={<AutoAwesomeRoundedIcon />}
          >
            Upgrade plan
          </Button>
        </Stack>
      </Menu>}

      <Menu
        anchorEl={anchorElementAddEmbed}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClick={handleCloseAddEmbed}
        onClose={handleCloseAddEmbed}
        open={openMenuAddEmbed}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <MenuItem
          onClick={() => {
            setAddEmbed('Releese Doc')
            setAddReleeseEmbedModal(true)
          }}
        >
          <ListItemIcon
            sx={{
              fontSize: '24px',
              minWidth: '24px',
            }}
          >
            <ArticleRoundedIcon color='info' />
          </ListItemIcon>
          Releese Doc
        </MenuItem>
        <Divider sx={{ borderColor: theme => theme.palette.text.label }} />
        {EMBED_TYPES.map(item =>
          <MenuItem
            key={item}
            onClick={() => {
              setAddEmbed(item)
              setAddEmbedModal(true)
            }}
          >
            <ListItemIcon
              sx={{
                fontSize: '24px',
                minWidth: '24px',
              }}
            >
              {GetIconTaskSmall(item)}
            </ListItemIcon>
            {item}
          </MenuItem>)}
      </Menu>

      {manageFields &&
      <AddTaskboardCustomField
        close={() => setManageFields(false)}
        open={manageFields}
        taskboard={props.taskboard}
      />}
      {editEmbedModal &&
      <EditEmbedTaskboard
        close={() => setEditEmbedModal(false)}
        currentTab={currentTab}
        embedId={manageEmbedItem}
        open={editEmbedModal}
        setCurrentTab={setCurrentTab}
        taskboard={props.taskboard}
      />}
      {deleteEmbedModal &&
      <DeleteEmbedModalItem
        close={() => setDeleteEmbedModal(false)}
        currentTab={currentTab}
        embeddedId={manageEmbedItem}
        open={deleteEmbedModal}
        setCurrentTab={setCurrentTab}
        taskboard={props.taskboard}
      />}
      {deleteTaskModal && props.refreshBoards &&
      <DeleteTaskboardModal
        close={() => setDeleteTaskModalOpen(false)}
        open={deleteTaskModal}
        refreshBoards={props.refreshBoards}
        taskboard={props.taskboard}
      />}
      {editTaskModal && props.refreshBoards &&
      <EditTaskboardModal
        close={() => setEditTaskModalOpen(false)}
        open={editTaskModal}
        refreshBoards={props.refreshBoards}
        taskboard={props.taskboard}
      />}
      {addEmbedModal &&
      <AddTaskboardEmbedModal
        close={() => setAddEmbedModal(false)}
        open={addEmbedModal}
        taskboard={props.taskboard}
        type={addEmbedItem}
      />}
      {newTaskModal &&
      <NewTaskModal
        close={() => setNewTaskModalOpen(false)}
        open={newTaskModal}
        taskboard={props.taskboard}
        taskgroup={editTaskGroupData}
      />}
      {deleteSectionModal && editTaskGroupData &&
      <DeleteTaskSectionModal
        close={toggleDeleteTaskModal(false, editTaskGroupData)}
        open={deleteSectionModal}
        taskSection={editTaskGroupData}
        taskboard={props.taskboard}
      />}
      {editSectionModal && editTaskGroupData &&
      <EditTaskSectionModal
        close={toggleEditTaskModal(false, editTaskGroupData)}
        open={editSectionModal}
        taskSection={editTaskGroupData}
        taskboard={props.taskboard}
      />}
      {hideFields &&
      <HideFieldsModal
        close={() => setHideFields(false)}
        open={hideFields}
        taskboard={props.taskboard}
      />}
      {addReleeseEmbedModal &&
      <AddReleeseDocument
        close={() => setAddReleeseEmbedModal(false)}
        open={addReleeseEmbedModal}
        taskboard={props.taskboard}
        type={addEmbedItem}
      />}
      {billingModal && currentOrganisation &&
      <BillingModal
        account={currentOrganisation}
        close={() => setBillingModalOpen(false)}
        open={billingModal}
      />}
      {openSyncModal &&
      <TaskSyncModal
        close={() => setOpenSyncModal(false)}
        hash={props.taskboard.iCalHash}
        id={props.taskboard.id ?? ''}
        open={openSyncModal}
        project={props.project}
        provider={syncType}
        type='taskboard'
      />}
    </>
  )
}

export default TaskBoardView
