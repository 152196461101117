/* eslint-disable unicorn/filename-case */
import type {
  SoftBreakPlugin } from '@udecode/plate'
import {
  ELEMENT_BLOCKQUOTE,
  ELEMENT_CODE_BLOCK,
  ELEMENT_TD,
} from '@udecode/plate'

import { ELEMENT_ALERT_ERROR } from 'src/components/pages/Notes/Modern/alert/AlertPlugin'
import { ELEMENT_ALERT_INFO } from 'src/components/pages/Notes/Modern/alert/InfoPlugin'
import { ELEMENT_ALERT_SUCCESS } from 'src/components/pages/Notes/Modern/alert/SuccessPlugin'
import { ELEMENT_ALERT_WARNING } from 'src/components/pages/Notes/Modern/alert/WarningPlugin'
import type { MyPlatePlugin } from 'src/components/pages/Notes/Modern/typescript/plateTypes'

export const softBreakPlugin: Partial<MyPlatePlugin<SoftBreakPlugin>> = {
  options: {
    rules: [
      { hotkey: 'shift+enter' },
      {
        hotkey: 'enter',
        query: {
          allow: [
            ELEMENT_CODE_BLOCK,
            ELEMENT_BLOCKQUOTE,
            ELEMENT_TD,
            ELEMENT_ALERT_ERROR,
            ELEMENT_ALERT_INFO,
            ELEMENT_ALERT_SUCCESS,
            ELEMENT_ALERT_WARNING,
          ],
        },
      },
    ],
  },
}
