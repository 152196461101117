import SimpleTour from 'src/components/pages/Tour/TourComponents/SimpleTour'

const ShareFilesTour = () =>
  <SimpleTour
    description='Share your files with users outside Releese. Everything can be downloaded as a bundled ZIP file.'
    desktopDirection='left'
    mobileDirection='bottom'
    onboardingKey='filesShare'
    selector='share-folder'
    title='Private sharing'
  />

export default ShareFilesTour
