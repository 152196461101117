import CloseIcon from '@mui/icons-material/Close'
import ReceiptRoundedIcon from '@mui/icons-material/ReceiptRounded'
import { Button, Dialog, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'

type Props = {
  close: () => void
  open: boolean
}

const ReActivatedModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))
  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='xs'
      onClose={props.close}
      open={props.open}
    >
      <Stack paddingX={3} paddingY={3} spacing={2} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          width='100%'
        >
          <Typography variant='h3'>
            Subscription
          </Typography>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>

        <Stack alignItems='center' spacing={2} width='100%'>
          <ReceiptRoundedIcon color='success' sx={{ height: 120, width: 120 }} />
          <Typography textAlign='center' variant='body2'>
            Congratulations, your subscription was successfully reactivated
          </Typography>
        </Stack>
        <Stack direction='row' justifyContent='center' spacing={1} width='100%'>
          <Button onClick={() => props.close()}>
            Return to dashboard
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default ReActivatedModal
