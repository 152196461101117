
import FlagRoundedIcon from '@mui/icons-material/FlagRounded'
import type { SelectChangeEvent } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { Box, FilledInput, MenuItem, Select, Stack, Typography, useTheme } from '@mui/material'

import COLOR from 'src/styles/colors'

type PriorityFilterType = {
  label: string
  color: string
  value: string
}

type Props = {
  setInputSelected: (item: string) => void
  priorityFilter: string
}

const PriorityFilter = (props: Props) => {
  const themeItem = useTheme()

  const handleChange = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event
    props.setInputSelected(value)
  }

  const priorities: readonly PriorityFilterType[] = [
    { label: 'All', color: 'default', value: '' },
    { label: 'High', color: themeItem.palette.error.main, value: 'High'  },
    { label: 'Medium', color: COLOR.OTHER.RATING_ACTIVE, value: 'Medium'  },
    { label: 'Low', color: themeItem.palette.primary.main, value: 'Low'  },
    { label: 'None', color: COLOR.TEXT.LABEL, value: 'None'  },
  ]
  const findColor = (labelFound: string) => priorities.find(element => element.value === labelFound)

  return (
    <Stack direction='column' spacing={1} sx={{ width: 200 }} >
      <Select
        displayEmpty
        fullWidth
        id='demo-multiple-checkbox'
        input={<FilledInput />}
        onChange={handleChange}
        renderValue={selected => <Box sx={{ display: 'flex', flexWrap: 'wrap', height: 1 }}>
          <Stack alignItems='center' direction='row' key={selected} spacing={1}>
            <Typography color={themeItem.palette.text.label} variant='inputText'>
              Priority
            </Typography>
            {findColor(selected)?.color &&
            <Typography color={findColor(selected)?.color} variant='body2'>{findColor(selected)?.label}</Typography>}
          </Stack>
        </Box>}
        value={props.priorityFilter}
      >
        {priorities.map(priority => <MenuItem key={priority.value} value={priority.value}>
          <Stack alignItems='center' direction='row' spacing={1}>
            <FlagRoundedIcon
              fontSize='medium'
              sx={{
                color: `${findColor(priority.label)?.color}!important`,
              }}

            />
            <Typography color={priority.color} variant='body2'>{priority.label}</Typography>
          </Stack>
        </MenuItem>)}
      </Select>
    </Stack>
  )
}

export default PriorityFilter
