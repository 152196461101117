import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'
import { v4 } from 'uuid'

import { permanentlyWipeBoard } from 'src/api/taskboards'
import { sendWorkspaceUpdate } from 'src/api/webSocket'
import DeleteItemComponent from 'src/components/DeleteItemComponent'
import type { TaskBoard } from 'src/models/Taskboard'

type Props = {
  close: () => void
  open: boolean
  refreshBoards: () => void
  refreshTaskboardArchiveList: () => void
  taskboard: TaskBoard
}

const PermanentlyDeleteBoard = (props: Props) => {
  const themeItem = useTheme()
  const [loading, setLoading] = useState(false)
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  const handleClick = async () => {
    if (props.taskboard.id) {
      setLoading(true)
      await permanentlyWipeBoard(props.taskboard.id)
        .then(() => props.refreshTaskboardArchiveList())
        .then(() => sendWorkspaceUpdate(v4()))
        .then(props.refreshBoards)
        .then(() => setLoading(false))
        .catch(() => setLoading(false))
      props.close()
    }
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='xs'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Stack paddingX={3} paddingY={3} spacing={4} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          width='100%'
        >
          <Typography variant='h3'>
            Delete Taskboard
          </Typography>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>
        <DeleteItemComponent
          primary='Are you sure?'
          secondary={`You will permanently delete "${props.taskboard.title}" and all its files. 
          This action cannot be undone`}
        />
        <Stack direction='row' justifyContent='flex-end' spacing={1} width='100%'>
          <Button onClick={props.close} variant='text'>
            Cancel
          </Button>
          <LoadingButton
            color='error'
            loading={loading}
            onClick={() => handleClick()}
            variant='contained'
          >
            Delete taskboard forever
          </LoadingButton>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default PermanentlyDeleteBoard
