/* eslint-disable react-hooks/exhaustive-deps */
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import { LoadingButton } from '@mui/lab'
import { Card, Checkbox, Dialog, Divider, IconButton, Paper, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

import { createSingles } from 'src/api/projects'
import { getRecordingsByProjectId } from 'src/api/recordings'
import SearchField from 'src/components/form-elements/SearchField'
import { generateTitleRecording } from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/RecordingsTab/Components/EditRecordingComponent'
import type Project from 'src/models/Project'
import type Recording from 'src/models/Recording'

type Props = {
  close: () => void
  open: boolean
  project?: Project
}

const CreateSinglesModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('sm'))
  const navigate = useNavigate()

  const [recordings, setRecordings] = useState<Recording[]>([])
  const [selectedRecordings, setSelectedRecordings] = useState<string[]>([])
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState('')

  useEffect(() => {
    if (props.project?.id) {
      void getRecordingsByProjectId(props.project.id)
        .then(result => {
          setRecordings(result)
          setSelectedRecordings(result.map(item => item.id))
        })
    }
  }, [props.project?.id])

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
    let updatedList = [...selectedRecordings]
    if (event.target.checked) {
      updatedList = [...selectedRecordings, id]
    } else {
      updatedList.splice(selectedRecordings.indexOf(id), 1)
    }
    setSelectedRecordings(updatedList)
  }

  const submit = async () => {
    setLoading(true)

    await createSingles(selectedRecordings)
      .then(() => {
        setLoading(false)
        navigate('/projects/projects')
      })
  }

  const filterData = recordings.filter(recording =>
    recording.title.toLowerCase().includes(filter.toLowerCase()))

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='md'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
        '.MuiDialog-paper': {
          height: '100%',
        },
      }}
    >
      <Stack height={1} width={1}>
        <Stack alignItems='center' direction='row' justifyContent='space-between' padding={2} width={1}>
          <Stack>
            <Typography lineHeight={1.1} variant='h3'>
              Create singles
            </Typography>
            <Typography color={themeItem.palette.text.label} variant='body2'>
              Every selected recording will be duplicated in it&apos;s own project, which can be distributed as a single
            </Typography>
          </Stack>
          <IconButton onClick={() => props.close()}>
            <CloseRoundedIcon color='disabled' />
          </IconButton>
        </Stack>
        <Divider />
        <Stack height={1} overflow='auto' padding={2} spacing={2} width={1}>
          <SearchField
            onChange={event => setFilter(event.target.value)}
            value={filter}
          />
          {filterData.map(recording =>
            <Card
              key={recording.id}
              sx={{
                width: 1,
                height: 'auto',
                overflow: 'initial',
              }}
              variant='outlined'
            >
              <Stack alignItems='center' direction='row' padding={2} spacing={2} width={1}>
                <Checkbox
                  checked={selectedRecordings.includes(recording.id)}
                  name={recording.title}
                  onChange={event => handleCheck(event, recording.id)}
                  sx={{
                    height: 'fit-content',
                  }}
                />
                {recording.artworkPreviewUrl
                  ? <img
                    alt={recording.title}
                    height={64}
                    src={recording.artworkPreviewUrl}
                    style={{
                      borderRadius: 16,
                      objectFit: 'cover',
                      aspectRatio: '64 / 64',
                    }}
                    width={64}
                  />
                  : <Paper
                    elevation={0}
                    sx={{
                      background: theme => theme.palette.background.input,
                    }}
                  >
                    <MusicNoteRoundedIcon color='disabled' sx={{ fontSize: 64 }} />
                  </Paper>}
                <Stack width={1}>
                  <Typography variant='textLabel'>
                    {recording.primaryArtists.map((artist, index) =>
                      `${artist.name}${recording.primaryArtists.length === index + 1 ? '' : ', '}`)}
                  </Typography>
                  <Typography variant='h4'>
                    {generateTitleRecording(undefined, recording)}
                  </Typography>
                </Stack>
              </Stack>
            </Card>)}

        </Stack>
        <Divider />
        <Stack padding={2} width={1}>
          <LoadingButton
            disabled={selectedRecordings.length === 0}
            loading={loading}
            onClick={() => void submit()}
            variant='contained'
          >
            Create singles
          </LoadingButton>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default CreateSinglesModal
