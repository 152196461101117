import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded'
import { Collapse, IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme } from '@mui/material'
import { useState } from 'react'

type Props = {
  featureGroup: {
    title: string
    featureItem: {
      title: string
      free: string
      creator: string
      creatorPro: string
      enterprise: string
      enterprisePlus?: string | undefined
      freeIcon: boolean
      creatorIcon: boolean
      creatorProIcon: boolean
      enterpriseIcon: boolean
      enterprisePlusIcon: boolean
    }[]
  }[]
}

const iconSize = 20

const CompleteFeatureList = (props: Props) => {
  const themeItem = useTheme()
  const [expanded, setExpanded] = useState(true)
  const handleExpand = () => {
    setExpanded(!expanded)
  }

  const currentTheme = useTheme().palette.mode

  return (
    <Stack
      alignItems='center'
      direction='column'
      maxWidth='lg'
      paddingX={2}
      paddingY={8}
      sx={{ display: { xl: 'flex', lg: 'flex', md: 'flex', sm: 'none', xs: 'none' } }}
      width={1}
    >
      <Typography
        color={themeItem.palette.primary.main}
        textAlign='center'
        variant='overline'
      >
        PRICING
      </Typography>
      <Typography
        sx={{ marginBottom: 4 }}
        textAlign='center'
        variant='h3'
      >
        Complete feature list
      </Typography>

      <IconButton onClick={handleExpand} >
        {expanded ? <KeyboardArrowDownRoundedIcon />
          : <KeyboardArrowUpRoundedIcon />}
      </IconButton>
      <Collapse in={expanded} sx={{ width: 1 }}>
        {props.featureGroup.map(featureGroupItem =>
          <Stack key={featureGroupItem.title} marginTop={4} spacing={1} width={1}>
            <Typography
              color='text.primary'
              variant='h4'
            >
              {featureGroupItem.title}
            </Typography>
            <Table
              aria-label='pricing table'
              size='small'
            >
              <TableHead sx={{
                '.MuiTableCell-root': {
                  borderBottom: 2,
                  borderBottomColor: 'divider',
                  color: 'text.secondary',
                },
              }}
              >

                <TableRow>
                  <TableCell align='center' width='25%' />
                  <TableCell align='center' width='15%'>
                    <Typography
                      color='text.primary'
                      variant='h5'
                    >
                      Free
                    </Typography>
                  </TableCell>
                  <TableCell align='center' width='15%'>
                    <Typography
                      color='text.primary'
                      variant='h5'
                    >
                      Essential
                    </Typography>
                  </TableCell>
                  <TableCell align='center' width='15%'>
                    <Typography
                      color='text.primary'
                      variant='h5'
                    >
                      Pro
                    </Typography>
                  </TableCell>
                  <TableCell align='center' width='15%'>
                    <Typography
                      color='text.primary'
                      variant='h5'
                    >
                      Business
                    </Typography>
                  </TableCell>
                  <TableCell align='center' width='15%'>
                    <Typography
                      color='text.primary'
                      variant='h5'
                    >
                      Enterprise
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {featureGroupItem.featureItem.map(featureItemRow =>
                  <TableRow
                    key={featureItemRow.title}
                    sx={{
                      '.MuiTableCell-root': {
                        paddingY: 2,
                        borderBottom: 0.5,
                        borderBottomColor: 'divider',
                        color: 'text.secondary',
                      },
                    }}
                  >
                    <TableCell align='left'>
                      <Typography variant='body2'>
                        {featureItemRow.title}
                      </Typography>
                    </TableCell>
                    <TableCell align='center'>
                      {featureItemRow.free.length > 0 &&
                      <Typography variant='body2'>
                        {featureItemRow.free}
                      </Typography>}
                      {featureItemRow.freeIcon
                        ? <CheckCircleRoundedIcon color='action' sx={{ width: iconSize, height: iconSize }} />
                        : null}
                    </TableCell>
                    <TableCell align='center'>
                      {featureItemRow.creator.length > 0 &&
                      <Typography variant='body2'>
                        {featureItemRow.creator}
                      </Typography>}
                      {featureItemRow.creatorIcon
                        ? <CheckCircleRoundedIcon color='primary' sx={{ width: iconSize, height: iconSize }} />
                        : null}
                    </TableCell>
                    <TableCell align='center'>
                      {featureItemRow.creatorPro.length > 0 &&
                      <Typography variant='body2'>
                        {featureItemRow.creatorPro}
                      </Typography>}
                      {featureItemRow.creatorProIcon
                        ? <CheckCircleRoundedIcon color='info' sx={{ width: iconSize, height: iconSize }} />
                        : null}
                    </TableCell>
                    <TableCell align='center'>
                      {featureItemRow.enterprise.length > 0 &&
                      <Typography variant='body2'>
                        {featureItemRow.enterprise}
                      </Typography>}
                      {featureItemRow.enterpriseIcon
                        ? <CheckCircleRoundedIcon sx={{ width: iconSize, height: iconSize, color: '#327659' }} />
                        : null}
                    </TableCell>
                    <TableCell align='center'>
                      {featureItemRow.enterprisePlus && featureItemRow.enterprisePlus.length > 0
                        ? <Typography variant='body2'>
                          {featureItemRow.enterprisePlus}
                        </Typography>
                        : null}
                      {featureItemRow.enterprisePlusIcon
                        ? <CheckCircleRoundedIcon
                          sx={{
                            width: iconSize,
                            height: iconSize,
                            color: currentTheme === 'light'
                              ? 'black'
                              : 'white' }}
                        />
                        : null}
                    </TableCell>
                  </TableRow>)}
              </TableBody>
            </Table>
          </Stack>)}
      </Collapse>
    </Stack>
  )
}

export default CompleteFeatureList
