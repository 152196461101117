/* eslint-disable max-len */
import type { SvgIconProps } from '@mui/material/SvgIcon'
import SvgIcon from '@mui/material/SvgIcon'

const TrackListLogo = (props: SvgIconProps) =>
  <SvgIcon {...props} viewBox='0 -25 215 250'>
    <g fill='#fff'>
      <path d='M261 150.5V138h13.5H288v-35.5V67h-13.5H261V54.5 42h13.5H288v-6.5V29h12.5H313v54.5V138h13.5H340v12.5V163h-39.5H261z' />
      <path d='M363 156v-7h-7-7V95.5 42h7 7v-6.5V29h32.5H428v6.5V42h7 7v53.5V149h-7-7v7 7h-32.5H363v-7zm54-60.5V53h-21.2-21.3v42.5V138h21.3H417V95.5zm48 60.5v-7h-6.5-6.5V95.5 42h6.5 6.5v-6.5V29h33 33v6.5V42h6.5 6.5v53.5V149h-6.5-6.5v7 7h-33-33v-7zm54-60.5V53h-21-21v42.5V138h21 21V95.5z' fillRule='nonzero' />
      <path d='M554 150.5V138h13.5H581v-35.5V67h-13.5H554V54.5 42h13.5H581v-6.5V29h12.5H606v54.5V138h13.5H633v12.5V163h-39.5H554zM660 108V53h-20.5H619V41 29h53 53v12 12h-20.5H684v55 55h-12-12z' />
      <path d='M734 96V29h40 40v6.5V42h6.5 6.5v26.5V95h-6.5-6.5v8 8h6.5 6.5v26 26h-12-12v-27.5V108h-22-22v27.5V163h-12.5H734V96zm69-28V53h-22-22v15 15h22 22V68zm37 34.5V42h6.5 6.5v-6.5V29h33 33v6.5 6.4l6.8.3 6.7.3v60 60l-12.2.3-12.3.3v-27.6V108h-22-22v27.5V163h-12-12v-60.5zM908 68V53h-22-22v15 15h22 22V68z' fillRule='nonzero' />
      <path d='M959 156v-7h-7-7l.2-53.2.3-53.3 6.8-.3 6.7-.3v-6.4V29h33 33v6.5V42h6.5 6.5v12.5V67h-12-12v-7-7h-22-22v42.5V138h22 22v-7-7h12.5 12.5v12.5V149h-7-7v7 7h-33-33zm92-60V29h12.5 12.5v27 27h15 15V69.5 56h6.5 6.5V42.5 29h12.5 12.5v12 12h-7-7v14 14h-6.5-6.5v15 15h6.5 6.5v13.5V138h7 7v12.5V163h-12.5-12.5v-14-14h-6.5-6.5v-13.5V108h-15-15v27.5V163h-12.5-12.5zm102 0V29h12.5 12.5v54.5V138h27 27v12.5V163h-39.5-39.5zm89 0V29h12.5 12.5v67 67h-12.5-12.5zm51 60v-7h-7-7v-12.5V124h12.5 12.5v7 7h21.5 21.5v-15-15h-27-27v-6.5V95h-7-7V68.5 42h7 7v-6.5V29h33 33v6.5V42h6.5 6.5v12.5V67h-12.5-12.5v-7-7h-21.5-21.5v15 15l27.3.2 27.2.3.3 6.8.3 6.7h6.4 6.5v26 26h-6.5-6.4l-.3 6.8-.3 6.7-32.7.3-32.8.2zm129-48V53h-20.5-20.5V41 29h53.5 53.5v12 12h-20.5-20.5v55 55h-12.5-12.5zm86 48v-7h-7-7v-12.5V124h12.5 12.5v7 7h21.5 21.5v-15-15h-27-27v-6.5V95h-7-7V68.5 42h7 7v-6.5V29h32.5 32.5v6.5V42h6.5 6.5v12.5V67h-12-12v-7-7h-21.5-21.5v15 15h27 27v7 7h6.5 6.5v26 26h-6.5-6.5v7 7h-32.5-32.5z' />
    </g>
    <path d='M97.393 180.913v-11.087H108.5h11.107v11.087V192H108.5 97.393zm0-36.522v-11.087h6.098 6.098v-6.087-6.087h6.098 6.098v-19.565V82H108.5 95.215v18.261 18.261H84.107 73v-17.174-17.174h6.098 6.098v-6.087V72H108.5h23.304v6.087 6.087h6.098H144v23.478 23.478h-12.196-12.197v12.174 12.174H108.5 97.393z' fill='#ff3a46' />
    <path d='M24.289 160.943v-6.057h-6.072-6.072v-6.057-6.057H6.072 0V131.74v-11.033h6.072 6.073v-6.057-6.057h6.072 6.072V78.525 48.456h6.072 6.073v-6.057-6.057h6.072 6.072v-6.057-6.057h6.073 6.072v-6.057-6.057h6.072 6.072V6.057 0H108h35.133v6.057 6.057h6.072 6.072v6.057 6.057h6.072 6.073v6.057 6.057h6.072 6.072v6.057 6.057h6.073 6.072v30.069 30.068h6.072 6.072v6.057 6.057h6.073H216v11.033 11.032h-6.072-6.073v6.057 6.057h-6.072-6.072v6.057V167h-11.06-11.061v-35.26-35.261h6.073 6.072V77.443 58.407h-6.072-6.073V52.35v-6.057h-6.072-6.072v-6.057-6.057h-6.073-6.072v-6.057-6.057H108 70.699v6.057 6.057h-6.072-6.073v6.057 6.057h-6.072-6.072v6.057 6.057h-6.073-6.072v19.036 19.036h6.072 6.073v35.261V167H35.349h-11.06z' fill='#40aef0' />
  </SvgIcon>

export default TrackListLogo
