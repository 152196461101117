/* eslint-disable react/jsx-child-element-spacing */
/* eslint-disable max-lines */
/* eslint-disable react-hooks/exhaustive-deps */
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import { Avatar, Card, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'

import { setAnalyticsTitle } from 'src/components/pages/Analytics/AnalyticsDistribution/AnalyticsDistributionAppbar'
import GetIconAsset from 'src/components/pages/SocialAssets/GetIconAsset'

type Props = {
  text: string
  mainImage: string
  smallImage: string
  platform: string
  color: string
  colors: string[]
  format: number
  template: string
  disableWatermark: boolean
}

const templates = [
  'Vibrant',
  'Subtle',
]

const PreviewAsset = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  return (
    <Stack
      alignItems='center'
      position='relative'
      width={1}
    >
      <Card
        elevation={0}
        sx={{
          position: 'relative',
          borderRadius: 0,
          background: props.template === templates[1]
            ? 'transparent'
            : props.color,
          scale: fullScreen
            ? 0.8
            : 1,
          width: 400,
          aspectRatio: props.format === 0
            ? '9 / 16'
            : props.format === 1
              ? '16 / 9'
              : '1 / 1',
        }}
      >
        {props.template === templates[1] &&
        <div
          style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            background: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), 
url("${props.mainImage}") center center / 200%`,
            filter: 'blur(20px)',
            zIndex: 0,
            transform: 'scale(1.2)',
          }}
        />}
        <Stack
          alignItems='center'
          height={1}
          paddingBottom={4}
          paddingTop={6}
          paddingX={6}
          spacing={4}
          width={1}
        >
          <div
            style={{
              position: 'relative',
            }}
          >
            <Avatar
              src={props.mainImage}
              sx={{
                height: 304,
                width: 304,
                position: 'relative',
              }}
              variant='rounded'
            >
              <MusicNoteRoundedIcon />
            </Avatar>
            {props.smallImage.length > 0 &&
            <Avatar
              src={props.smallImage}
              sx={{
                height: 128,
                width: 128,
                position: 'absolute',
                bottom: -20,
                left: -10,
              }}
              variant='rounded'
            >
              <MusicNoteRoundedIcon />
            </Avatar>}
          </div>
          <Stack spacing={2} width={1}>
            <Typography
              color={props.color === props.colors[0] ||
                props.color === props.colors[1] ||
                props.color === props.colors[2]
                ? 'white'
                : 'black'}
              sx={{
                width: 1,
                lineHeight: 1,
                zIndex: 2,
              }}
              textAlign='left'
              variant='h2'
            >
              {props.text}
            </Typography>
            {props.platform !== '' &&
            <Typography
              color={props.color === props.colors[0] ||
                props.color === props.colors[1] ||
                props.color === props.colors[2]
                ? 'white'
                : 'black'}
              sx={{
                width: 1,
                lineHeight: 1,
                zIndex: 2,
              }}
              textAlign='left'
              variant='h4'
            >
              {GetIconAsset(props.platform)}
              {' '}
              On
              {` ${setAnalyticsTitle(props.platform)}`}
            </Typography>}
          </Stack>
          {!props.disableWatermark &&
          <Stack alignItems='center' marginTop='auto!important' width={1}>
            <Typography
              color={props.color === props.colors[0] ||
                props.color === props.colors[1] ||
                props.color === props.colors[2]
                ? 'white'
                : 'black'}
              sx={{
                width: 1,
                lineHeight: 1,
                zIndex: 2,
                fontSize: '20px',
                fontFamily: 'moderna',
              }}
              textAlign='center'
              variant='h6'
            >
              <span
                style={{
                  lineHeight: 1,
                  fontSize: '16px',
                  fontFamily: [
                    'Poppins',
                    'sans-serif',
                    'Moderna',
                    'Helvetica Neue',
                    'Droid Sans',
                    'Fira Sans',
                    'Cantarell',
                    'Ubuntu',
                    'Inter',
                    'Oxygen',
                    'Roboto',
                    'Segoe UI',
                    'BlinkMacSystemFont',
                    '-apple-system',
                  ].join(','),
                  fontWeight: '400',
                }}
              >
                Powered by
                {' '}
              </span>
              releese
            </Typography>
          </Stack>}
        </Stack>
      </Card>
    </Stack>
  )
}

export default PreviewAsset
