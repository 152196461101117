import AddIcon from '@mui/icons-material/Add'
import ControlPointDuplicateRoundedIcon from '@mui/icons-material/ControlPointDuplicateRounded'
import CreateRoundedIcon from '@mui/icons-material/CreateRounded'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import DragIndicatorRoundedIcon from '@mui/icons-material/DragIndicatorRounded'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import { Card, Chip, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Tooltip, Typography } from '@mui/material'
import { useState } from 'react'
import type { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import { Droppable } from 'react-beautiful-dnd'
import { v4 } from 'uuid'

import { savePartialTaskboard } from 'src/api/taskboards'
import { sendTasksboardUpdate } from 'src/api/webSocket'
import TaskBoardItem from 'src/components/pages/Task/TaskViews/TaskViewComponents/TaskBoardItem'
import { useAuth } from 'src/components/providers/AuthProvider'
import type Account from 'src/models/Account'
import type { TaskBoard, TaskGroup, TaskItem, TaskUpdate } from 'src/models/Taskboard'
import { TASK_MODIFICATION_TYPES, TaskBoardEdit } from 'src/models/Taskboard'

type Props = {
  toggleTaskDrawer: (toggleState: boolean, taskGroup: TaskGroup, taskData?: TaskItem) => () => void
  toggleTaskModal: (toggleState: boolean, taskGroup: TaskGroup) => () => void
  toggleDeleteTaskModal: (toggleState: boolean, taskGroup: TaskGroup) => () => void
  toggleEditTaskModal: (toggleState: boolean, taskGroup: TaskGroup) => () => void
  id: string
  column: string
  taskColumn: TaskGroup
  taskboard: TaskBoard
  searchQuery: string
  statusQuery: string
  priorityQuery: string
  dragHandle: DraggableProvidedDragHandleProps | null | undefined
  members: Account[]
}

const TaskBoardContainer = (props: Props) => {
  const { currentAccount } = useAuth()
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const openMoreMenu = Boolean(anchorElement)
  const handleMoreMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget)
  }
  const handleMoreMenuClose = () => {
    setAnchorElement(null)
  }

  const handleDuplicateSection = async () => {
    if (props.taskboard.id) {
      const newTaskboardGroup = {
        title: props.taskColumn.title,
        color: props.taskColumn.color,
        tasks: props.taskColumn.tasks.map(item => ({ ...item, id: v4(), updates: [] as TaskUpdate[] })) as TaskItem[],
        id: v4(),
      }

      props.taskboard.taskGroups.push(newTaskboardGroup)

      await savePartialTaskboard({ id: props.taskboard.id, ...props.taskboard })
        .then(() => {
          const taskUpdateItem = new TaskBoardEdit({
            id: v4(),
            taskboardId: props.taskboard.id,
            taskId: '',
            accountId: currentAccount.id,
            modificationType: TASK_MODIFICATION_TYPES[19],
            itemType: 'Field',
            title: '',
          })
          sendTasksboardUpdate(taskUpdateItem)
        })
    }
  }

  return (
    <>
      <Stack alignItems='center' direction='row' justifyContent='space-between' paddingBottom={1}>
        <Stack alignItems='center' direction='row' spacing={0.5}>
          <Tooltip title='Re-order'>
            <IconButton
              aria-label='Drag'
              {...props.dragHandle}
              sx={{ width: 28, height: 28 }}
            >
              <DragIndicatorRoundedIcon fontSize='medium' sx={{ color: theme => theme.palette.action.active }} />
            </IconButton>
          </Tooltip>
          <Chip
            label={props.taskColumn.title}
            size='medium'
            sx={{
              color: theme => theme.palette.primary.contrastText,
              backgroundColor: props.taskColumn.color,
            }}
            variant='subtle'
          />
        </Stack>
        <Stack direction='row' spacing={1}>
          <Tooltip title='More'>
            <IconButton
              aria-label='Add'
              onClick={handleMoreMenuOpen}
              sx={{ width: 28, height: 28 }}
            >
              <MoreVertRoundedIcon fontSize='medium' sx={{ color: theme => theme.palette.action.active }} />
            </IconButton>
          </Tooltip>
          <Tooltip title='Add a task'>
            <IconButton
              aria-label='Add'
              onClick={props.toggleTaskModal(true, props.taskColumn)}
              sx={{ backgroundColor: theme => theme.palette.background.input, width: 28, height: 28 }}
            >
              <AddIcon fontSize='medium' sx={{ color: theme => theme.palette.action.active }} />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
      <Card
        sx={{
          maxHeight: 1,
          overflow: 'hidden',
          padding: 2,
          boxShadow: 'none',
          backgroundColor: theme => theme.palette.background.input,
        }}
      >
        <Stack height='100%' overflow='auto' sx={{ padding: 0, paddingRight: 0, borderRadius: 1 }}>
          <Droppable droppableId={props.id} key={props.id} type='task'>
            {provided => <Stack {...provided.droppableProps} ref={provided.innerRef}>
              {props.taskColumn.tasks.map((element, index) =>
                <TaskBoardItem
                  id={`${element.id}`}
                  index={index}
                  key={element.id}
                  members={props.members}
                  priorityQuery={props.priorityQuery}
                  searchQuery={props.searchQuery}
                  statusQuery={props.statusQuery}
                  taskColumn={props.taskColumn}
                  taskDataSet={element}
                  taskboard={props.taskboard}
                  toggleTaskDrawer={props.toggleTaskDrawer}
                />)}
              {provided.placeholder}
              <ListItem disablePadding>
                <ListItemButton
                  onClick={props.toggleTaskModal(true, props.taskColumn)}
                  sx={{ borderRadius: 2 }}
                >
                  <ListItemText
                    disableTypography
                    primary={<Typography
                      sx={{ color: theme => theme.palette.text.secondary }}
                      variant='body2'
                    >
                      + Add a task
                    </Typography>}
                  />
                </ListItemButton>
              </ListItem>
            </Stack>}
          </Droppable>
        </Stack>
      </Card>
      <Menu
        anchorEl={anchorElement}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClick={handleMoreMenuClose}
        onClose={handleMoreMenuClose}
        open={openMoreMenu}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
      >
        <MenuItem onClick={() => void handleDuplicateSection()}>
          <ListItemIcon>
            <ControlPointDuplicateRoundedIcon fontSize='medium' />
          </ListItemIcon>
          Duplicate section
        </MenuItem>
        <MenuItem onClick={props.toggleEditTaskModal(true, props.taskColumn)}>
          <ListItemIcon>
            <CreateRoundedIcon fontSize='medium' />
          </ListItemIcon>
          Edit section
        </MenuItem>
        <MenuItem onClick={props.toggleDeleteTaskModal(true, props.taskColumn)}>
          <ListItemIcon>
            <DeleteRoundedIcon fontSize='medium' />
          </ListItemIcon>
          Delete section
        </MenuItem>
      </Menu>
    </>
  )
}
export default TaskBoardContainer

