import CloseIcon from '@mui/icons-material/Close'
import WorkspacesRoundedIcon from '@mui/icons-material/WorkspacesRounded'
import { Dialog, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'

import WorkspaceItem from 'src/components/pages/Task/TaskMenu/TaskMenuModals/WorkspaceItem'
import type { Workspace } from 'src/models/Taskboard'

type Props = {
  close: () => void
  open: boolean
  refreshBoards: () => void
  workspaces: Workspace[]
}

const ManageWorkspacesModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='sm'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
        width: 1,
      }}
    >
      <Stack paddingX={3} paddingY={3} spacing={2} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          width='100%'
        >
          <Typography variant='h3'>Manage Workspaces</Typography>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>
        {props.workspaces.length > 0 &&
        <Stack width={1}>
          {props.workspaces.map(item =>
            <WorkspaceItem
              key={item.id}
              refreshBoards={props.refreshBoards}
              workspace={item}
            />)}
        </Stack>}
        {props.workspaces.length === 0 &&
          <Stack
            alignItems='center'
            height={1}
            justifyContent='center'
            width={1}
          >
            <WorkspacesRoundedIcon sx={{ color: 'action.disabled', fontSize: '144px' }} />
            <Typography color='text.label' variant='h4'>
              No workspaces found
            </Typography>
            <Typography color='text.disabled' variant='subtitle1' >
              Create a workspace to get started
            </Typography>
          </Stack>}
      </Stack>
    </Dialog>
  )
}

export default ManageWorkspacesModal
