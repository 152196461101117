/* eslint-disable id-length */
import { Pagination, Stack, Table, TableCell, TableHead, TableRow } from '@mui/material'
import { useState } from 'react'

import HistoryItem from 'src/components/pages/Task/TaskViews/TaskViewComponents/HistoryItem'
import { useTasksboard } from 'src/components/providers/TasksboardProvider'
import type Account from 'src/models/Account'

type Props = {
  members: Account[]
}

const HistoryView = (props: Props) => {
  const { tasksboardUpdates } = useTasksboard()
  const [page, setPage] = useState(1)
  const currentPageData = tasksboardUpdates.slice(((page * 100) / 5) - 20, (page * 100) / 5)

  const updateCurrentDisplayData = (currentPage: number) => {
    setPage(currentPage)
  }

  return (
    <Stack direction='column' height={1} spacing={1} width={1}>
      <Table
        aria-label='history=log'
        sx={{
          minWidth: 650,
          tableLayout: 'fixed',
        }}
      >
        <TableHead
          sx={{
            '.MuiTableCell-root': {
              borderBottom: 2,
              borderBottomColor: 'divider',
              color: 'text.secondary',
            },
          }}
        >
          <TableRow>
            <TableCell>
              Timestamp
            </TableCell>
            <TableCell>
              Editor
            </TableCell>
            <TableCell>
              Edited item
            </TableCell>
          </TableRow>
        </TableHead>
        {currentPageData.map(item =>
          <HistoryItem
            item={item}
            key={item.id}
            members={props.members}
          />)}
      </Table>
      <Stack>
        {
          tasksboardUpdates.length > 20 &&
          <Pagination
            count={tasksboardUpdates.length > 0 ? Math.ceil(tasksboardUpdates.length / 20) : 1}
            onChange={(event, value: number) => {
              updateCurrentDisplayData(value)
            }}
            page={page}
            sx={{ marginTop: 3, marginBottom: 4, marginLeft: 'auto' }}
          />
        }
      </Stack>
    </Stack>
  )
}
export default HistoryView
