/* eslint-disable react-hooks/exhaustive-deps */
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded'
import MinimizeRoundedIcon from '@mui/icons-material/MinimizeRounded'
import { AppBar, IconButton, Paper, Slide, Stack, Typography, useTheme } from '@mui/material'
import { useState } from 'react'

import FileItemUpload from 'src/components/pages/Menu/FileItemUpload'
import { useFileUploader } from 'src/components/providers/FilesUploadProvider'

const FilesMenu = () => {
  const themeItem = useTheme()
  const { files } = useFileUploader()
  const [minimized, setMinimized] = useState(false)

  return (
    <Slide direction='up' in={files && files.length > 0}>
      <Paper
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 80,
          borderRadius: '8px 8px 0px 0px',
          zIndex: 999,
          overflow: 'hidden',
        }}
      >
        <Stack
          direction='column'
          display={files && files.length > 0
            ? 'flex'
            : 'none'}
          height={minimized
            ? 74
            : 360}
          overflow={minimized
            ? 'hidden'
            : 'auto'}
          position='relative'
          width={360}
        >
          <AppBar
            position='static'
            sx={{
              backgroundColor: theme => theme.palette.primary.main,
            }}
          >
            <Stack alignItems='center' direction='row' justifyContent='space-between' padding={2} width={1}>
              <Typography color={themeItem.palette.primary.contrastText} variant='h4'>
                Upload queue
              </Typography>
              <IconButton
                onClick={() => setMinimized(old => !old)}
                sx={{
                  color: theme => theme.palette.primary.contrastText,
                }}
              >
                {minimized
                  ? <ExpandLessRoundedIcon />
                  : <MinimizeRoundedIcon />}
              </IconButton>
            </Stack>

          </AppBar>
          <Stack
            height={minimized
              ? 0
              : 'auto'}
            padding={2}
            spacing={2}
            visibility={minimized
              ? 'hidden'
              : 'visible'}
          >
            {files && files.length > 0
              ? files.map(item  =>
                <FileItemUpload
                  file={item.file}
                  id={item.id}
                  key={item.id}
                  location={item.parent}
                  projectId={item.projectId}
                  taskId={item.taskId}
                  taskboardId={item.taskboardId}
                />)
              : <Typography color={themeItem.palette.text.label} variant='body2'>
                No files are being uploaded
              </Typography>}
          </Stack>
        </Stack>
      </Paper>
    </Slide>
  )
}

export default FilesMenu
