import axios from 'axios'
import download from 'js-file-download'

import type { FolderDto } from 'src/models/Folder'
import { Folder } from 'src/models/Folder'
import type { ProjectDto } from 'src/models/Project'
import Project from 'src/models/Project'

export const baseUrlLinksApi = process.env.NODE_ENV === 'development'
  ? 'http://localhost:5000/api'
  : 'https://api.releese.io/api'

const PATH = '/folders' as const

export const getFolder = (folder?: string) =>
  axios.get<FolderDto[]>(folder ? `${PATH}/${folder}` : `${PATH}/`)
    .then(response => response.data.map(dto => new Folder(dto)))

export const getFoldersByProjectId = (project_Id: string) =>
  axios.get<FolderDto[]>(`${PATH}/project/${project_Id}`)
    .then(response => response.data.map(dto => new Folder(dto)))

export const getCurrentFolder = (folder?: string) =>
  axios.get<FolderDto>(folder ? `${PATH}/current/${folder}` : `${PATH}/`)
    .then(response => new Folder(response.data))

export const deleteFolder = (id: string) =>
  axios.delete<string>(`${PATH}/delete/${id}`)
    .then(result => result.data)

export const createFolder = (folder: Folder) =>
  axios.post<Folder>(`${PATH}`, folder)
    .then(response => new Folder(response.data))

export const updateFolder = (folder: Folder, id: string) =>
  axios.patch<Folder>(`${PATH}/${id}`, folder)
    .then(response => new Folder(response.data))

export const getFolderPublic = (id: string, hash: string) =>
  axios.get<Folder>(`${PATH}/public/${id}/${hash}`)
    .then(response => new Folder(response.data))

export const getFolderPublicContent = (id: string, hash: string) =>
  axios.get<FolderDto[]>(`${PATH}/public-content/${id}/${hash}`)
    .then(response => response.data.map(dto => new Folder(dto)))

export const getFolderPublicProject = (id: string, hash: string) =>
  axios.get<ProjectDto>(`${PATH}/public-project/${id}/${hash}`)
    .then(response => new Project(response.data))

export const getFolderPublicProjectContent = (id: string, hash: string) =>
  axios.get<FolderDto[]>(`${PATH}/public-content-project/${id}/${hash}`)
    .then(response => response.data.map(dto => new Folder(dto)))

export const downloadFolder = (folder: Folder) =>
  axios.post<Blob>('https://us-central1-ninth-park-304715.cloudfunctions.net/downloadFolder',
    { id: folder.id, hash: folder.hash }, { responseType: 'blob' })
    .then(response => download(response.data, `${folder.title}.zip`))

export const downloadProjectFolder = (project: Project) =>
  axios.post<Blob>('https://us-central1-ninth-park-304715.cloudfunctions.net/downloadFolderProject',
    { id: project.id, hash: project.privateInviteHash }, { responseType: 'blob' })
    .then(response => download(response.data, `${project.title}.zip`))
