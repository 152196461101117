/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */
// @ts-nocheck
import { eq } from 'date-arithmetic'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import type { Culture, DateRangeFormatFunction } from 'react-big-calendar'
import { DateLocalizer } from 'react-big-calendar'

const defaultCulture = ''

const locale = (month: Dayjs, culture: Culture) => culture ? month.locale(culture) : month

const dateLocalizer = new DateLocalizer({
  firstOfWeek: _culture => dayjs.localeData().firstDayOfWeek(),
  format: (value, format, culture) => locale(dayjs(value), culture ?? 'en').format(format),
  formats: {},
})

const dateRangeFormat: DateRangeFormatFunction = (
  { start, end },
  culture = defaultCulture,
  localizer = dateLocalizer
) =>
  `${localizer.format(start, 'L', culture)} – ${localizer.format(end, 'L', culture)}`

const timeRangeFormat: DateRangeFormatFunction = (
  { start, end },
  culture = defaultCulture,
  localizer = dateLocalizer
) =>
  `${localizer.format(start, 'LT', culture)} – ${localizer.format(end, 'LT', culture)}`

const timeRangeStartFormat: DateRangeFormatFunction = (
  { start },
  culture = defaultCulture,
  localizer = dateLocalizer
) =>
  `${localizer.format(start, 'LT', culture)} – `

const timeRangeEndFormat: DateRangeFormatFunction = (
  { end },
  culture = defaultCulture,
  localizer = dateLocalizer
) =>
  ` – ${localizer.format(end, 'LT', culture)}`

const weekRangeFormat: DateRangeFormatFunction = (
  { start, end },
  culture = defaultCulture,
  localizer = dateLocalizer
) =>
  `${localizer.format(start, 'MMMM DD', culture)
  } – ${localizer.format(end, eq(start, end, 'month') ? 'DD' : 'MMMM DD', culture)}`

export const formats = {
  dateFormat: 'DD',
  dayFormat: 'DD ddd',
  weekdayFormat: 'ddd',

  selectRangeFormat: timeRangeFormat,
  eventTimeRangeFormat: timeRangeFormat,
  eventTimeRangeStartFormat: timeRangeStartFormat,
  eventTimeRangeEndFormat: timeRangeEndFormat,

  timeGutterFormat: 'LT',

  monthHeaderFormat: 'MMMM YYYY',
  dayHeaderFormat: 'dddd MMM DD',
  dayRangeHeaderFormat: weekRangeFormat,
  agendaHeaderFormat: dateRangeFormat,

  agendaDateFormat: 'ddd MMM DD',
  agendaTimeFormat: 'LT',
  agendaTimeRangeFormat: timeRangeFormat,
}
dateLocalizer.formats = formats

export const dayjsLocalizer = () => dateLocalizer
