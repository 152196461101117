/* eslint-disable unicorn/consistent-function-scoping */
import { Dialog, Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import TagManager from 'react-gtm-module'
import { useLocation } from 'react-router-dom'

import SubscriptionCreated from 'src/components/pages/Account/Billing/BillingComponents/SubscriptionCreated'
import { useAuth } from 'src/components/providers/AuthProvider'

const PaymentModal = () => {
  const { currentOrganisation } = useAuth()
  const [open, setOpen] = useState(false)

  const search = useLocation().search
  const valueSuccess = new URLSearchParams(search).get('redirect_status')

  useEffect(() => {
    if (valueSuccess === 'succeeded') {
      setOpen(true)
    }
  }, [valueSuccess])

  useEffect(() => {
    if (open) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'checkout_success',
          userId: currentOrganisation?.id,
          eventProps: {
            city: currentOrganisation?.city,
            country: currentOrganisation?.country,
            email: currentOrganisation?.organisationEmail,
            firstName: currentOrganisation?.firstName,
            lastName: currentOrganisation?.lastName,
            state: currentOrganisation?.region,
            zip: currentOrganisation?.postalCode,
          },
        },
      })
    }
  }, [currentOrganisation, open])

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      onClose={() => setOpen(false)}
      open={open}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        overflow: 'scroll',
      }}
    >
      <Stack alignItems='center' width={1}>
        <SubscriptionCreated
          close={() => setOpen(false)}
        />
      </Stack>
    </Dialog>
  )
}

export default PaymentModal
