/* eslint-disable max-lines */
import { LoadingButton } from '@mui/lab'
import { Autocomplete, Button, Checkbox, FormControlLabel, FormGroup, Grid, ListItemText, MenuItem, Stack, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

import { bulkUpdateRecordings, getAllComposers, getAllProducers, getAllWriters } from 'src/api/recordings'
import SearchArtistComponent from 'src/components/form-elements/SearchArtists'
import { useAuth } from 'src/components/providers/AuthProvider'
import { languageData } from 'src/data/languagesData'
import type { ArtistObject } from 'src/models/Distribution'
import type Project from 'src/models/Project'
import { BulkRecording } from 'src/models/Recording'

type Props = {
  project?: Project
  updateRecordings: () => Promise<void>
  close: () => void
}

const BulkUpdateComponent = (props: Props) => {
  const { currentOrganisation } = useAuth()

  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(false)

  const [language, setLanguage] = useState('en')
  const [tags, setTags] = useState<string[]>()

  const [writers, setWriters] = useState<string[]>([])
  const [composers, setComposers] = useState<string[]>([])
  const [producers, setProducers] = useState<string[]>([])

  const [allWriters, setAllWriters] = useState<string[]>([])
  const [allComposers, setAllComposers] = useState<string[]>([])
  const [allProducers, setAllProducers] = useState<string[]>([])

  const [searchWriter, setSearchWriter] = useState('')
  const [searchComposer, setSearchComposer] = useState('')
  const [searchProducer, setSearchProducer] = useState('')

  const [isInstrumental, setisInstrumental] = useState(false)
  const [isExplicit, setisExplicit] = useState(false)
  const [containsSamples, setcontainsSamples] = useState(false)

  const [selectedPrimaryArtists, setSelectedPrimaryArtists] =
  useState<ArtistObject[]>()
  const [selectedFeaturedArtists, setSelectedFeaturedArtists] =
  useState<ArtistObject[]>()

  const handlePrimaryChange = (values: ArtistObject[] | undefined) => {
    setSelectedPrimaryArtists(values ?? [])
  }
  const handleFeaturedChange = (values: ArtistObject[] | undefined) => {
    setSelectedFeaturedArtists(values ?? [])
  }

  useEffect(() => {
    setLoading(true)
    void getAllWriters()
      .then(result => {
        setAllWriters(result)
      })
    void getAllComposers()
      .then(result => {
        setAllComposers(result)
      })
    void getAllProducers()
      .then(result => {
        setAllProducers(result)
      })
    setLoading(false)
  }, [currentOrganisation?.id])

  const save = async () => {
    if (props.project) {
      setSaving(true)
      const testItem = new BulkRecording({
        language,
        writtenBy: writers,
        composedBy: composers,
        producedBy: producers,
        primaryArtists: selectedPrimaryArtists ?? [],
        featuredArtists: selectedFeaturedArtists ?? [],
        isInstrumental,
        isExplicit,
        containsSamples,
        tags,
      })
      await bulkUpdateRecordings(props.project.id, testItem)
        .then(async () => {
          await props.updateRecordings()
        })
        .then(() => setSaving(false))
        .finally(() => props.close())
    }
  }

  return (
    <Stack spacing={4} width={1}>
      <Stack spacing={1} width={1}>

        <Stack width={1}>
          <Typography variant='h3'>
            Bulk edit metadata
          </Typography>
          <Typography variant='body2'>
            When updating this form, you will be replacing the metadata on all Recordings in this project
          </Typography>
        </Stack>

        <Grid
          container
          marginLeft='-16px!important'
          spacing={2}
          width='calc(100% + 16px)'
        >
          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <SearchArtistComponent
              disabled={props.project?.distributed}
              label='Primary Artists'
              placeholder='Search Artists'
              selectedArtists={selectedPrimaryArtists}
              setSelectedArtists={handlePrimaryChange}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <SearchArtistComponent
              disabled={props.project?.distributed}
              label='Featured Artists'
              placeholder='Search Artists'
              selectedArtists={selectedFeaturedArtists}
              setSelectedArtists={handleFeaturedChange}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <Autocomplete
              clearOnBlur
              disabled={props.project?.distributed}
              freeSolo
              id='written-by'
              inputValue={searchWriter}
              limitTags={3}
              loading={loading}
              multiple
              onChange={(event, value) => setWriters(value)}
              onInputChange={(event, value) => setSearchWriter(value)}
              options={searchWriter.length > 0 ? [...new Set([searchWriter, ...allWriters])] : [...allWriters]}
              renderInput={params =>
                <TextField
                  {...params}
                  label='Written by'
                  placeholder='Type and press enter to add a writer'
                  required
                />}
              renderOption={(renderProps, option) =>
                <MenuItem
                  {...renderProps}
                  key={option}
                  sx={{
                    paddingY: 0.5,
                  }}
                >
                  <ListItemText
                    primary={option}
                    secondary={option && option === searchWriter && !allWriters.includes(option)
                      ? 'Create new writer'
                      : undefined}
                  />
                </MenuItem>}
              value={writers}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <Autocomplete
              clearOnBlur
              disabled={props.project?.distributed}
              freeSolo
              id='composedBy'
              inputValue={searchComposer}
              limitTags={3}
              loading={loading}
              multiple
              onChange={(event, value) => setComposers(value)}
              onInputChange={(event, value) => setSearchComposer(value)}
              options={searchComposer.length > 0 ? [...new Set([searchComposer, ...allComposers])] : [...allComposers]}
              renderInput={params =>
                <TextField
                  {...params}
                  label='Composed by'
                  placeholder='Type and press enter to add a composer'
                  required
                />}
              renderOption={(renderProps, option) =>
                <MenuItem
                  {...renderProps}
                  key={option}
                  sx={{
                    paddingY: 0.5,
                  }}
                >
                  <ListItemText
                    primary={option}
                    secondary={option && option === searchComposer && !allComposers.includes(option)
                      ? 'Create new composer'
                      : undefined}
                  />
                </MenuItem>}
              value={composers}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <Autocomplete
              clearOnBlur
              disabled={props.project?.distributed}
              freeSolo
              id='produced-by'
              inputValue={searchProducer}
              limitTags={3}
              loading={loading}
              multiple
              onChange={(event, value) => setProducers(value)}
              onInputChange={(event, value) => setSearchProducer(value)}
              options={searchProducer.length > 0 ? [...new Set([searchProducer, ...allProducers])] : [...allProducers]}
              renderInput={params =>
                <TextField
                  {...params}
                  label='Produced by'
                  placeholder='Type and press enter to add a producer'
                  required
                />}
              renderOption={(renderProps, option) =>
                <MenuItem
                  {...renderProps}
                  key={option}
                  sx={{
                    paddingY: 0.5,
                  }}
                >
                  <ListItemText
                    primary={option}
                    secondary={option && option === searchProducer && !allProducers.includes(option)
                      ? 'Create new producer'
                      : undefined}
                  />
                </MenuItem>}
              value={producers}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <Autocomplete
              disabled={props.project?.distributed}
              getOptionLabel={option => option.language}
              onChange={(event, value) => setLanguage(value?.code ?? '')}
              options={languageData}
              renderInput={params =>
                <TextField {...params} fullWidth label='Language' placeholder='Language' />}
              value={languageData.find(item => item.code === language)}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <Autocomplete
              freeSolo
              multiple
              onChange={(event, value) => setTags(value)}
              options={['']}
              renderInput={params =>
                <TextField {...params} fullWidth label='Recording Tags' placeholder='Tags' />}
              value={tags}
            />
          </Grid>

          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                aria-label='Is Explicit?'
                checked={isExplicit}
                control={<Checkbox checked={isExplicit} disabled={props.project?.distributed} />}
                disabled={props.project?.distributed}
                label='Is Explicit?'
                onChange={() => setisExplicit(!isExplicit)}
              />
              <FormControlLabel
                aria-label='Is Instrument?'
                checked={isInstrumental}
                control={<Checkbox checked={isInstrumental} disabled={props.project?.distributed} />}
                disabled={props.project?.distributed}
                label='Is Instrumental?'
                onChange={() => setisInstrumental(!isInstrumental)}
              />
              <FormControlLabel
                aria-label='Contains Samples?'
                checked={containsSamples}
                control={<Checkbox checked={containsSamples} disabled={props.project?.distributed} />}
                disabled={props.project?.distributed}
                label='Contains Samples?'
                onChange={() => setcontainsSamples(!containsSamples)}
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Stack>
      <Stack direction='row' justifyContent='flex-end' spacing={1} width={1}>
        <Button onClick={props.close} variant='text'>
          Cancel
        </Button>
        <LoadingButton
          disabled={props.project?.distributed}
          loading={saving}
          onClick={() => void save()}
          variant='contained'
        >
          Update all recordings
        </LoadingButton>
      </Stack>
    </Stack>
  )
}

export default BulkUpdateComponent
