import AddRoundedIcon from '@mui/icons-material/AddRounded'
import ChatBubbleRoundedIcon from '@mui/icons-material/ChatBubbleRounded'
import { Button, Paper, Stack, Typography, useTheme } from '@mui/material'
import { useState } from 'react'

import NewMessageModal from 'src/components/pages/Messages/NewMessageModal/NewMessageModal'
import { useMessenger } from 'src/components/providers/MessengerProvider'

const MessagesPanelEmpty = () => {
  const [newMessage, setNewMessage] = useState(false)
  const { conversations } = useMessenger()
  const themeItem = useTheme()
  return (
    <>
      <NewMessageModal
        close={() => setNewMessage(false)}
        conversations={conversations.sort((a, b) =>
          +b.lastMessage.createdAt - +a.lastMessage.createdAt)}
        open={newMessage}
      />
      <Stack
        alignItems='center'
        height={1}
        justifyContent='center'
        width='100%'
      >
        <Paper
          elevation={0}
          sx={{
            backgroundColor: theme => theme.palette.info.main,
            borderRadius: 16,
          }}
        >
          <Stack
            alignItems='center'
            justifyContent='center'
            padding={2}
          >
            <ChatBubbleRoundedIcon
              sx={{
                color: theme => theme.palette.primary.contrastText,
                fontSize: '64px',
              }}
            />
          </Stack>
        </Paper>
        <Typography
          color={themeItem.palette.text.secondary}
          sx={{
            marginTop: 1,
          }}
          variant='h4'
        >
          No Conversation selected
        </Typography>
        <Typography
          color={themeItem.palette.text.label}
          variant='body1'
        >
          Select a Conversation or start a new one
        </Typography>
        <Button
          color='info'
          onClick={() => setNewMessage(true)}
          startIcon={<AddRoundedIcon />}
          sx={{
            marginTop: 1,
          }}
        >
          Start a Conversation
        </Button>
      </Stack>
    </>
  )
}

export default MessagesPanelEmpty
