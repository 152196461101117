import CloseIcon from '@mui/icons-material/Close'
import { Button, Dialog, IconButton, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import type { ChangeEvent } from 'react'
import { useState } from 'react'

import { updateFile } from 'src/api/files'
import type { FileItem } from 'src/models/File'

type Props = {
  close: () => void
  open: boolean
  item: FileItem
  updateItem: (fileItem: FileItem) => void
}

const EditFileDescriptionModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  const [newFile, setnewFile] = useState(props.item)
  const [groupName, setGroupName] = useState(props.item.description)

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setGroupName(event.target.value)
  }

  const handleClick = () => {
    if (newFile.id) {
      newFile.description = groupName
      setnewFile(newFile)
      void updateFile(newFile, newFile.id)
      props.updateItem(newFile)
      props.close()
      setGroupName('')
    }
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='xs'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <form
        onSubmit={event => {
          event.preventDefault()
          handleClick()
        }}
      >
        <Stack paddingX={3} paddingY={3} spacing={2} width={1}>
          <Stack
            alignItems='center'
            direction='row'
            justifyContent='space-between'
            width='100%'
          >
            <Typography variant='h3'>Edit Description</Typography>
            <IconButton onClick={() => props.close()}>
              <CloseIcon color='disabled' />
            </IconButton>
          </Stack>
          <Stack width='100%'>
            <TextField
              autoComplete='off'
              fullWidth
              id='description'
              label='Description'
              multiline
              onChange={handleNameChange}
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  handleClick()
                }
              }}
              placeholder='File Description'
              rows={4}
              value={groupName}
            />

          </Stack>
          <Stack direction='row' justifyContent='flex-end' spacing={1} width={1}>
            <Button onClick={props.close} variant='text' >
              Cancel
            </Button>
            <Button
              type='submit'
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </form>
    </Dialog>
  )
}

export default EditFileDescriptionModal

