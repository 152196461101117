/* eslint-disable no-duplicate-imports */
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { Collapse, IconButton, Stack, Tooltip, Typography, useTheme } from '@mui/material'
import { useState } from 'react'

import BalanceChart from 'src/components/pages/Earnings/EarningsTabs/BalanceChart'
import type { Organisation } from 'src/models/Organisation'
import type Recording from 'src/models/Recording'

type Props = {
  holder: Organisation
  recordingSelected: Recording
}

const RecordinEarningsChart = (props: Props) => {
  const themeItem = useTheme()
  const [expanded, setExpanded] = useState(false)

  const recordingToProcess = [props.recordingSelected]

  const allRecordingsIncome = recordingToProcess.map(recording => {
    const myShare = recording.masterRightsHolders
      .find(itemHolder => itemHolder.account.id === props.holder.id)?.ownership ?? 0
    const income = recording.recoupItems
      .filter(item => item.transactionType === 'Income')
      .reduce((a, b) => a + b.amount, 0)
    return (income / 100) * myShare
  }).reduce((a, b) => a + b, 0)

  const allRecordingsExpenses = recordingToProcess.map(recording => {
    const myShare = recording.masterRightsHolders
      .find(itemHolder => itemHolder.account.id === props.holder.id)?.ownership ?? 0

    const expenses = recording.recoupItems
      .filter(item => item.transactionType === 'Expense')
      .reduce((a, b) => a + b.amount, 0)
    return (expenses / 100) * myShare
  }).reduce((a, b) => a + b, 0)

  const allRecordingsAdvances = recordingToProcess.map(recording => {
    const payouts = recording.recoupItems
      .filter(item => item.transactionType === 'Advance')
    return payouts
      .filter(item => item.organisationAdvanceId === props.holder.id)
      .reduce((a, b) => a + b.amount, 0)
  }).reduce((a, b) => a + b, 0)

  const allRecordingsPayouts = recordingToProcess.map(recording => {
    const payouts = recording.recoupItems
      .filter(item => item.transactionType === 'Payout' || item.transactionType === 'Advance')
    return payouts
      .filter(item => item.organisationAdvanceId === props.holder.id)
      .reduce((a, b) => a + b.amount, 0)
  }).reduce((a, b) => a + b, 0)

  const totalIncomeRecording = allRecordingsIncome
  const totalExpensesRecording = allRecordingsExpenses
  const totalPayoutRecording = allRecordingsPayouts
  const netRecording = allRecordingsIncome - allRecordingsExpenses - allRecordingsPayouts

  return (
    <Stack direction='column' spacing={1} width={1}>
      <Stack direction='column' width={1}>
        <Stack spacing={1} width={1}>
          <Stack width={1}>
            {netRecording < 0 || allRecordingsAdvances > 0 || totalExpensesRecording > 0
              ? <BalanceChart
                amount={Math.abs(netRecording)}
                maximum={totalExpensesRecording + totalPayoutRecording}
                title='Recording recoups'
              />
              : <BalanceChart
                amount={0}
                maximum={100}
                title='Recording recoups'
              />}
            <Stack direction='column' width={1}>
              <Typography color={themeItem.palette.text.label} variant='body2'>
                Amount to recoup on
                {' '}
                {`"${props.recordingSelected.title}"`}
              </Typography>
              <Typography variant='body2'>
                {new Intl.NumberFormat('en-US',
                  { style: 'currency', currency: 'USD' }).format(Math.abs(netRecording))}
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Collapse in={expanded}>
          <Stack direction='column' paddingY={1} spacing={1} width={1}>
            <Stack direction='column' width={1}>
              <Typography color={themeItem.palette.text.label} variant='body2'>
                Total recording income share
              </Typography>
              <Typography variant='body2'>
                {new Intl.NumberFormat('en-US',
                  { style: 'currency', currency: 'USD' }).format(totalIncomeRecording)}
              </Typography>
            </Stack>
            <Stack direction='column' width={1}>
              <Typography color={themeItem.palette.text.label} variant='body2'>
                Total recording expenses share
              </Typography>
              <Typography variant='body2'>
                {new Intl.NumberFormat('en-US',
                  { style: 'currency', currency: 'USD' }).format(totalExpensesRecording)}
              </Typography>
            </Stack>
            <Stack direction='column' width={1}>
              <Typography color={themeItem.palette.text.label} variant='body2'>
                Total recording payouts
              </Typography>
              <Typography variant='body2'>
                {new Intl.NumberFormat('en-US',
                  { style: 'currency', currency: 'USD' }).format(totalPayoutRecording)}
              </Typography>
            </Stack>
          </Stack>
        </Collapse>

        <Tooltip title={expanded ? 'Hide details' : 'View details'}>
          <IconButton
            color='inherit'
            onClick={() => setExpanded(old => !old)}
          >
            {expanded
              ? <ExpandLessRoundedIcon />
              : <ExpandMoreRoundedIcon />}
          </IconButton>
        </Tooltip>
      </Stack>
    </Stack>
  )
}

export default RecordinEarningsChart
