import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, IconButton, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'

import { createFolder } from 'src/api/folder'
import { useAuth } from 'src/components/providers/AuthProvider'
import { Folder } from 'src/models/Folder'
import type Project from 'src/models/Project'

type Props = {
  close: () => void
  open: boolean
  setFolders: React.Dispatch<React.SetStateAction<Folder[]>>
  parent: string
  project?: Project
}

const NewFolderModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  const { currentOrganisation } = useAuth()
  const [title, setTitle] = useState('')
  const [loading, setLoading] = useState(false)

  const newFolderFunction = async () => {
    setLoading(true)

    await (props.project?.id
      ? createFolder(new Folder({
        title,
        parent: props.parent,
        projectId: props.project.id,
      })).then(newFolder => {
        props.setFolders(folderItems => [newFolder, ...folderItems])
      })
        .then(() => props.close())
        .finally(() => setLoading(false))
      : createFolder(new Folder({
        title,
        parent: props.parent,
        organisationId: currentOrganisation?.id,
      })).then(newFolder => {
        props.setFolders(folderItems => [newFolder, ...folderItems])
      })
        .then(() => props.close())
        .finally(() => setLoading(false)))
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='xs'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <form
        onSubmit={event => {
          event.preventDefault()
          void newFolderFunction()
        }}
      >
        <Stack height={1} paddingX={3} paddingY={3} spacing={2} width={1}>
          <Stack
            alignItems='center'
            direction='row'
            justifyContent='space-between'
            width={1}
          >
            <Typography variant='h3'>
              Create a Folder
            </Typography>
            <IconButton onClick={() => props.close()}>
              <CloseIcon color='disabled' />
            </IconButton>
          </Stack>

          <Stack>
            <TextField
              label='Title'
              onChange={event => setTitle(event.target.value)}
              placeholder='Folder Name'
              value={title}
            />
          </Stack>

          <Stack direction='row' justifyContent='flex-end' spacing={1} width={1}>
            <Button
              onClick={props.close}
              variant='text'
            >
              Cancel
            </Button>
            <LoadingButton
              disabled={title.length === 0}
              loading={loading}
              type='submit'
              variant='contained'
            >
              Create
            </LoadingButton>
          </Stack>
        </Stack>
      </form>
    </Dialog>
  )
}
export default NewFolderModal
