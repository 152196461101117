import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import ArrowDropDownCircleRoundedIcon from '@mui/icons-material/ArrowDropDownCircleRounded'
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded'
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded'
import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded'
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import ColorLensRoundedIcon from '@mui/icons-material/ColorLensRounded'
import EmailRoundedIcon from '@mui/icons-material/EmailRounded'
import FunctionsIcon from '@mui/icons-material/Functions'
import GradeRoundedIcon from '@mui/icons-material/GradeRounded'
import NumbersRoundedIcon from '@mui/icons-material/NumbersRounded'
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded'
import PercentRoundedIcon from '@mui/icons-material/PercentRounded'
import PhoneIphoneRoundedIcon from '@mui/icons-material/PhoneIphoneRounded'
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded'
import TextFormatRoundedIcon from '@mui/icons-material/TextFormatRounded'
import WebAssetRoundedIcon from '@mui/icons-material/WebAssetRounded'

export const getCustomFieldIcon = (fieldName: string) => {
  switch (fieldName.toLowerCase()) {
    case 'text':
      return <TextFormatRoundedIcon />
    case 'number':
      return <NumbersRoundedIcon />
    case 'checkbox':
      return <CheckBoxRoundedIcon />
    case 'formula':
      return <FunctionsIcon />
    case 'chips':
      return <AddCircleRoundedIcon />
    case 'date':
      return <CalendarMonthRoundedIcon />
    case 'website':
      return <WebAssetRoundedIcon />
    case 'dropdown':
      return <ArrowDropDownCircleRoundedIcon />
    case 'rating':
      return <GradeRoundedIcon />
    case 'money':
      return <AttachMoneyRoundedIcon />
    case 'email':
      return <EmailRoundedIcon />
    case 'phone':
      return <PhoneIphoneRoundedIcon />
    case 'people':
      return <PeopleAltRoundedIcon />
    case 'status':
      return <ColorLensRoundedIcon />
    default:
      return <CheckBoxOutlineBlankRoundedIcon />
  }
}

export const getCustomFieldFormula = (fieldName: string) => {
  switch (fieldName.toLowerCase()) {
    case 'add':
      return <AddRoundedIcon
        sx={{
          color: theme => theme.palette.primary.main,
        }}
      />
    case 'subtract':
      return <RemoveRoundedIcon
        sx={{
          color: theme => theme.palette.error.main,
        }}
      />
    case 'multiplicate':
      return <ClearRoundedIcon
        sx={{
          color: theme => theme.palette.success.main,
        }}
      />
    case 'divide':
      return <PercentRoundedIcon
        sx={{
          color: theme => theme.palette.info.main,
        }}
      />
    default:
      return <PercentRoundedIcon
        sx={{
          color: theme => theme.palette.secondary.main,
        }}
      />
  }
}
