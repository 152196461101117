
import { List, ListItem, ListItemButton, ListItemText, Stack, Typography } from '@mui/material'

import { generateTitleRecording } from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/RecordingsTab/Components/EditRecordingComponent'
import type Recording from 'src/models/Recording'

type Props = {
  searchQuery: string
  recordings: Recording[]
  selectedRecording: string
  setSelectedRecording: React.Dispatch<React.SetStateAction<string>>
  id?: boolean
  selectedProject?: string
  setSelectedProject?: React.Dispatch<React.SetStateAction<string>>
}

const RecordingsMenu = (props: Props) =>
  <Stack direction='column' justifyContent='flex-start' width={1}>
    <List dense sx={{ paddingTop: 0 }}>
      {props.recordings.map(recording =>
        <Stack
          key={recording.id}
          sx={{
            display: recording.title.toLowerCase().includes(props.searchQuery)
              ? undefined
              : 'none',
          }}
        >
          <ListItem
            dense
            disablePadding
          >
            <ListItemButton
              onClick={() => {
                if (props.setSelectedProject) {
                  props.setSelectedProject(recording.projectId)
                }
                props.setSelectedRecording(props.id
                  ? recording.id
                  : recording.ISRC)
              }}
              sx={{
                borderRadius: 0,
                backgroundColor: props.id
                  ? recording.id === props.selectedRecording && props.selectedRecording.length > 0
                    ? theme => theme.palette.primary.contrast2
                    : theme => theme.palette.background.default
                  : recording.ISRC === props.selectedRecording &&
                    props.selectedRecording.length > 0 &&
                    props.selectedProject === undefined
                    ? theme => theme.palette.primary.contrast2
                    : props.selectedProject === recording.projectId && recording.ISRC === props.selectedRecording
                      ? theme => theme.palette.primary.contrast2
                      : theme => theme.palette.background.default,
              }}
            >
              <ListItemText
                primary={<Typography sx={{ lineHeight: 1.3 }} variant='subtitle2'>
                  {generateTitleRecording(undefined, recording)}
                </Typography>}
                secondary={<Typography sx={{ lineHeight: 1 }} variant='body2'>
                  {recording.primaryArtists.map(item => item.name).join(', ')}
                </Typography>}
              />
            </ListItemButton>
          </ListItem>
        </Stack>)}
    </List>
  </Stack>

export default RecordingsMenu
