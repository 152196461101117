/* eslint-disable react-hooks/exhaustive-deps */
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { Button, Checkbox, Dialog, Divider, FormControlLabel, FormGroup, IconButton, Link, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'

type Props = {
  close: () => void
  open: boolean
  appleMotionAddOn: boolean
  setAppleMotionAddOn: React.Dispatch<React.SetStateAction<boolean>>
  folderLink: string
  setFolderLink: React.Dispatch<React.SetStateAction<string>>
}

const AppleMotionModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))
  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='sm'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Stack width={1}>
        <Stack alignItems='center' direction='row' justifyContent='space-between' paddingTop={2} paddingX={2} width={1}>
          <Typography variant='h3'>
            Album Motion
          </Typography>
          <IconButton onClick={() => props.close()}>
            <CloseRoundedIcon color='disabled' />
          </IconButton>
        </Stack>

        <Stack paddingBottom={4} paddingX={2} spacing={2} width={1}>
          <Typography color={themeItem.palette.text.label} variant='body1'>
            Album Motion on Apple Music makes your album artwork come to life. Motion artwork provides a richer
            experience for your audience. Please follow
            {' '}
            <Link
              href='https://help.apple.com/itc/albummotionguide/#/bc927a183bfb'
              target='_blank'
            >
              these guidelines
            </Link>
            {' '}
            when submitting your files to Releese.
          </Typography>
          <Divider />

          <TextField
            autoComplete='new-password'
            label='Link to folder containing the required assets'
            onChange={event => props.setFolderLink(event.target.value)}
            placeholder='https://dropbox.com/folder/link/123456'
            sx={{
              marginTop: '32px!important',
            }}
            value={props.folderLink}
          />

          <FormGroup>
            <FormControlLabel
              control={<Checkbox
                checked={props.appleMotionAddOn}
                onChange={event => props.setAppleMotionAddOn(event.target.checked)}
              />}
              label='I confirm to have followed the guidelines.'
            />
          </FormGroup>
        </Stack>

        <Stack direction='row' justifyContent='flex-end' paddingBottom={2} paddingX={2} spacing={1} width={1}>
          <Button
            onClick={() => props.close()}
            variant='text'
          >
            Cancel
          </Button>
          <Button
            onClick={() => props.close()}
          >
            Confirm
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default AppleMotionModal
