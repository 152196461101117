/* eslint-disable @typescript-eslint/no-dynamic-delete */
/* eslint-disable switch-case/no-case-curly */
/* eslint-disable max-lines */
import PublicRoundedIcon from '@mui/icons-material/PublicRounded'
import SubscriptionsRoundedIcon from '@mui/icons-material/SubscriptionsRounded'
import { Avatar, Chip, ListItemText, Stack } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import dayjs from 'dayjs'
import countries, { getName } from 'i18n-iso-countries'
import countryCodes from 'i18n-iso-countries/langs/en.json'
import _ from 'lodash'
import { useState } from 'react'

import { GetColorLink } from 'src/components/pages/SmartLink/Content/GetColorLink'
import GetIcon from 'src/components/pages/SmartLink/Content/GetIcon'
import SocialAssetModal from 'src/components/pages/SocialAssets/SocialAssetModal'

type Props = {
  row: {
    followers_count: number
    name: string
    type: string
    external_url: string
    top_position: number
    top_position_date: string
    current_position: number
    added_at: string
    removed_at: string
    artwork: string
    region: string
    owner_name: string
  }
  currentTab: string
  artworkUrl: string
}

const RemovePlaylistNoFollowerItem = (props: Props) => {
  const [socialDrawer, setSocialDrawer] = useState(false)
  countries.registerLocale(countryCodes)
  return (
    <>
      <TableRow
        hover
        key={props.row.artwork}
        onClick={() => setSocialDrawer(true)}
        role='checkbox'
        sx={{
          '.MuiTableCell-root': { borderBottomColor: 'divider', paddingY: 2 },
        }}
        tabIndex={-1}
      >
        <TableCell
          component='th'
          padding='normal'
          scope='row'
          width={64}
        >
          <Avatar
            src={props.row.artwork}
            sx={{
              width: 64,
              height: 64,
              background: theme => theme.palette.error.main,
            }}
            variant='rounded'
          >
            <SubscriptionsRoundedIcon />
          </Avatar>
        </TableCell>
        <TableCell align='left'>
          <ListItemText
            primary={props.row.name}
            secondary={props.row.owner_name}
          />
          {(props.row.type === 'Editorial' || props.row.type === 'Personalized') &&
          <Chip
            icon={GetIcon(props.currentTab)}
            label='Editorial'
            size='small'
            sx={{
              color: 'white',
              background: GetColorLink(props.currentTab),
              '.MuiSvgIcon-root': {
                color: 'white',
                marginLeft: '12px',
              },
            }}
          />}
        </TableCell>
        <TableCell align='left'>
          <Stack alignItems='center' direction='row' spacing={2}>
            <Avatar
              src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/
${props.row.region.toUpperCase()}.svg`}
              sx={{
                background: theme => theme.palette.info.main,
                height: 30,
                width: 45,
                borderRadius: '4px',
              }}
              variant='rounded'
            >
              <PublicRoundedIcon />
            </Avatar>
            <ListItemText
              primary={getName(props.row.region, 'en')}
            />
          </Stack>
        </TableCell>
        <TableCell align='left'>{dayjs(props.row.removed_at).format('LL')}</TableCell>
        <TableCell align='left'>{props.row.top_position}</TableCell>
      </TableRow>
      {socialDrawer &&
      <SocialAssetModal
        close={() => setSocialDrawer(false)}
        imageMainUrl={props.artworkUrl}
        imageSecondaryUrl={props.row.artwork}
        open={socialDrawer}
        platform={props.currentTab}
        text={`Added to ${props.row.name}`}
      />}
    </>
  )
}

export default RemovePlaylistNoFollowerItem
