/* eslint-disable unicorn/no-unsafe-regex */
/* eslint-disable no-useless-escape */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable unicorn/no-array-reduce */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded'
import DateRangeRoundedIcon from '@mui/icons-material/DateRangeRounded'
import { InputAdornment, Menu, Stack, TextField, useMediaQuery, useTheme } from '@mui/material'
import _ from 'lodash'
import type { DateRange } from 'mui-daterange-picker'
import { DateRangePicker } from 'mui-daterange-picker'
import { useState } from 'react'

type Props = {
  dateRange: DateRange
  setDateRange: React.Dispatch<React.SetStateAction<DateRange>>
}

const TimeSelectAnalytics = (props: Props) => {
  const themeItem = useTheme()
  const mobile = useMediaQuery(themeItem.breakpoints.down('md'))

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorElement)

  // presets
  const currentDate = new Date()
  const lastSevenDays = new Date()
  lastSevenDays.setDate(currentDate.getDate() - 7)
  const lastTwentyEightDays = new Date()
  lastTwentyEightDays.setDate(currentDate.getDate() - 28)
  const lastSixMonthsDays = new Date()
  lastSixMonthsDays.setDate(currentDate.getDate() - 180)
  const lastYear = new Date()
  lastYear.setDate(currentDate.getDate() - 365)
  const allTime = new Date()
  allTime.setDate(currentDate.getDate() - 1825)

  // booleans
  const isLastSeven = props.dateRange.startDate?.getDate() === lastSevenDays.getDate() &&
  props.dateRange.endDate?.getDate() === currentDate.getDate()
  const isLastTwentyEight = props.dateRange.startDate?.getDate() === lastTwentyEightDays.getDate() &&
  props.dateRange.endDate?.getDate() === currentDate.getDate()
  const isLastSixMonths = props.dateRange.startDate?.getDate() === lastSixMonthsDays.getDate() &&
  props.dateRange.endDate?.getDate() === currentDate.getDate()
  const isLastYear = props.dateRange.startDate?.getDate() === lastYear.getDate() &&
  props.dateRange.endDate?.getDate() === currentDate.getDate()
  const isAllTime = props.dateRange.startDate?.getDate() === allTime.getDate() &&
  props.dateRange.endDate?.getDate() === currentDate.getDate()

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorElement(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorElement(null)
  }

  return (
    <>
      <Stack alignItems='flex-start'>
        <TextField
          InputProps={{
            startAdornment:
  <InputAdornment position='start'>
    <DateRangeRoundedIcon />
  </InputAdornment>
            ,
            endAdornment:
  <InputAdornment position='end'>
    <ArrowDropDownRoundedIcon />
  </InputAdornment>,
          }}
          onClick={event => handleClick(event)}
          size='medium'
          sx={{
            width: mobile ? 1 : 220,
          }}
          value={isLastSeven
            ? 'Last 7 days'
            : isLastTwentyEight
              ? 'Last 28 days'
              : isLastSixMonths
                ? 'Last 6 months'
                : isLastYear
                  ? 'Last year'
                  : isAllTime
                    ? 'All time'
                    : 'Custom'}
        />
      </Stack>
      <Menu
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorEl={anchorElement}
        id='date'
        onClose={handleClose}
        open={open}
        sx={{
          '& .MuiList-root': {
            padding: 0,
            paddingBottom: 0,
            paddingTop: 0,
          },
          '& .MuiPaper-root': {
            overflow: 'hidden',
          },
        }}
      >
        <DateRangePicker
          definedRanges={[
            {
              startDate: lastSevenDays,
              endDate: currentDate,
              label: 'Last 7 days',
            },
            {
              startDate: lastTwentyEightDays,
              endDate: currentDate,
              label: 'Last 28 days',
            },
            {
              startDate: lastSixMonthsDays,
              endDate: currentDate,
              label: 'Last 6 months',
            },
            {
              startDate: lastYear,
              endDate: currentDate,
              label: 'Last year',
            },
            {
              startDate: allTime,
              endDate: currentDate,
              label: 'All time',
            },
          ]}
          initialDateRange={props.dateRange}
          maxDate={new Date()}
          minDate={new Date(1_454_002_000_000)}
          onChange={range => props.setDateRange(range)}
          open
          toggle={handleClose}
          wrapperClassName='test'
        />
      </Menu>
    </>
  )
}

export default TimeSelectAnalytics
