/* eslint-disable @typescript-eslint/no-magic-numbers */
import ReceiptRoundedIcon from '@mui/icons-material/ReceiptRounded'
import { Button, Stack, Typography } from '@mui/material'
import { useEffect, useRef } from 'react'
import Confetti from 'react-confetti'
import TagManager from 'react-gtm-module'

import { useContainerDimensions } from 'src/components/ContainerDimensions'
import { useAuth } from 'src/components/providers/AuthProvider'
import { NavLink } from 'src/components/SafeRouterLink'

type Props = {
  close: () => void
}

const SubscriptionCreated = (props: Props) => {
  const { currentOrganisation } = useAuth()
  const componentRef = useRef<HTMLDivElement>(null)
  const { width, height } = useContainerDimensions(componentRef)

  const isTeam = (currentOrganisation?.seats.length ?? 0) < (currentOrganisation?.seatAllocation ?? 0)

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'checkout_success',
        userId: currentOrganisation?.id,
        eventProps: {
          city: currentOrganisation?.city,
          country: currentOrganisation?.country,
          email: currentOrganisation?.organisationEmail,
          firstName: currentOrganisation?.firstName,
          lastName: currentOrganisation?.lastName,
          state: currentOrganisation?.region,
          zip: currentOrganisation?.postalCode,
        },
      },
    })
  }, [currentOrganisation])

  return (
    <Stack height={1} padding={2} ref={componentRef} width={1}>
      <Confetti
        height={height}
        numberOfPieces={280}
        recycle={false}
        width={width}
      />
      <Stack alignItems='center' height={1} justifyContent='center' spacing={2} width={1}>
        <Stack alignItems='center' justifyContent='center' padding={3} spacing={2} width={1}>
          <ReceiptRoundedIcon color='success' sx={{ fontSize: 128 }} />
          <Typography variant='h3'>
            Congratulations!
          </Typography>
          <Typography textAlign='center' variant='body1'>
            {currentOrganisation?.trial
              ? 'You have successfully started your Releese Trial'
              : 'You have successfully subscribed to Releese'}
          </Typography>
          {isTeam
            ? <Button
              component={NavLink}
              onClick={props.close}
              to='/InitialSetup/InviteTeam'
            >
              Invite your team
            </Button>
            : <Button
              onClick={props.close}
            >
              Go to dashboard
            </Button>}

        </Stack>
      </Stack>
    </Stack>
  )
}

export default SubscriptionCreated
