/* eslint-disable sonarjs/max-switch-cases */
/* eslint-disable complexity */
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkRoundedIcon from '@mui/icons-material/LinkRounded'
import TwitterIcon from '@mui/icons-material/Twitter'
import YouTubeIcon from '@mui/icons-material/YouTube'
import { useTheme } from '@mui/material'

import SevenDigitalLogo from 'src/components/Icons/7digitalLogo'
import TrackListLogo from 'src/components/Icons/1001TracklistLogo'
import AmazonMusicIcon from 'src/components/Icons/AmazonMusicIcon'
import AnghamiLogo from 'src/components/Icons/AnghamiLogo'
import AppleMusicIcon from 'src/components/Icons/AppleMusicIcon'
import AudiomackLogo from 'src/components/Icons/AudiomackLogo'
import AwaLogo from 'src/components/Icons/AwaLogo'
import BamdcampLogo from 'src/components/Icons/BandcampLogo'
import BeatportLogo from 'src/components/Icons/BeatportLogo'
import BugsLogo from 'src/components/Icons/BugsLogo'
import ClaroLogo from 'src/components/Icons/ClaroLogo'
import DeezerIcon from 'src/components/Icons/DeezerIcon'
import DiscordIcon from 'src/components/Icons/DiscordIcon'
import EmusicLogo from 'src/components/Icons/eMusicLogo'
import FloLogo from 'src/components/Icons/FloLogo'
import GracenoteLogo from 'src/components/Icons/GracenoteLogo'
import IheartRadioLogo from 'src/components/Icons/IheartRadioLogo'
import ItunesLogo from 'src/components/Icons/ItunesLogo'
import JooxLogo from 'src/components/Icons/JooxLogo'
import KanjianLogo from 'src/components/Icons/Kanjian'
import KkboxLogo from 'src/components/Icons/KkboxLogo'
import KuackLogo from 'src/components/Icons/KuackLogo'
import MelonLogo from 'src/components/Icons/MelonIcon'
import NapsterLogo from 'src/components/Icons/NapsterLogo'
import NaverLogo from 'src/components/Icons/NaverLogo'
import NeteaseLogo from 'src/components/Icons/NeteaseLogo'
import NuudayLogo from 'src/components/Icons/NuudayLogo'
import PandoraIcon from 'src/components/Icons/PandoraIcon'
import QobuzLogo from 'src/components/Icons/QobuzLogo'
import QqIcon from 'src/components/Icons/QqIcon'
import QzoneIcon from 'src/components/Icons/QzoneIcon'
import SaavnLogo from 'src/components/Icons/SaavnLogo'
import ShazamIcon from 'src/components/Icons/ShazamIcon'
import SnapchatIcon from 'src/components/Icons/SnapchatIcon'
import SoundCloudIcon from 'src/components/Icons/SoundCloudIcon'
import SpotifyIcon from 'src/components/Icons/SpotifyIcon'
import TencentLogo from 'src/components/Icons/TencentLogo'
import TidalLogo from 'src/components/Icons/TidalLogo'
import TikTokIcon from 'src/components/Icons/TikTokIcon'
import TraxsourceLogo from 'src/components/Icons/TraxsourceLogo'
import TumblrIcon from 'src/components/Icons/TumblrIcon'
import TwitchIcon from 'src/components/Icons/TwitchIcon'
import VevoLogo from 'src/components/Icons/VevoLogo'
import VkIcon from 'src/components/Icons/VkIcon'
import WeChatIcon from 'src/components/Icons/WeChatIcon'
import WeiboIcon from 'src/components/Icons/WeiboIcon'

const GetIconLink = (LinkName: string) => {
  const theme = useTheme()
  switch (true) {
    case LinkName.toLowerCase().includes('spotify'):
      return <SpotifyIcon sx={{ color: theme.palette.spotify }} />
    case LinkName.toLowerCase().includes('apple'):
      return <AppleMusicIcon sx={{ color: theme.palette.appleMusic }} />
    case LinkName.toLowerCase().includes('apple_music'):
      return <AppleMusicIcon sx={{ color: theme.palette.appleMusic }} />
    case LinkName.toLowerCase().includes('itunes & apple music'):
      return <AppleMusicIcon sx={{ color: theme.palette.appleMusic }} />
    case LinkName.toLowerCase().includes('deezer'):
      return <DeezerIcon sx={{ color: theme.palette.deezer }} />
    case LinkName.toLowerCase().includes('amazon'):
      return <AmazonMusicIcon sx={{ color: theme.palette.amazon }} />
    case LinkName.toLowerCase().includes('soundcloud'):
      return <SoundCloudIcon sx={{ color: theme.palette.soundcloud }} />
    case LinkName.toLowerCase().includes('tiktok'):
      return <TikTokIcon sx={{ color: theme.palette.tiktok }} />
    case LinkName.toLowerCase().includes('youtube'):
      return <YouTubeIcon sx={{ color: theme.palette.youtube }} />
    case LinkName.toLowerCase().includes('itunes'):
      return <ItunesLogo sx={{ color: '#EA4CC0' }} />
    case LinkName.toLowerCase().includes('tidal'):
      return <TidalLogo sx={{ color: '#000000' }} />
    case LinkName.toLowerCase().includes('pandora'):
      return <PandoraIcon sx={{ color: '#00A0EE' }} />
    case LinkName.toLowerCase().includes('audiomack'):
      return <AudiomackLogo sx={{ color: '#ffa200' }} />
    case LinkName.toLowerCase().includes('iheartradio'):
      return <IheartRadioLogo sx={{ color: '#C6002B' }} />
    case LinkName.toLowerCase().includes('vevo'):
      return <VevoLogo sx={{ color: '#174BEB' }} />
    case LinkName.toLowerCase().includes('joox'):
      return <JooxLogo sx={{ color: '#00DE75' }} />
    case LinkName.toLowerCase().includes('kkbox'):
      return <KkboxLogo sx={{ color: theme.palette.appleMusic }} />
    case LinkName.toLowerCase().includes('napster'):
      return <NapsterLogo sx={{ color: '#2ca6de' }} />
    case LinkName.toLowerCase().includes('beatport'):
      return <BeatportLogo sx={{ color: '#01FF95' }} />
    case LinkName.toLowerCase().includes('bandcamp'):
      return <BamdcampLogo sx={{ color: '#629aa9' }} />
    case LinkName.toLowerCase().includes('anghami'):
      return <AnghamiLogo sx={{ color: '#39baf8' }} />
    case LinkName.toLowerCase().includes('facebook'):
      return <FacebookIcon sx={{ color: theme.palette.facebook }} />
    case LinkName.toLowerCase().includes('instagram'):
      return <InstagramIcon sx={{ color: '#8a3ab9' }} />
    case LinkName.toLowerCase().includes('twitter'):
      return <TwitterIcon sx={{ color: '#1DA1F2' }} />
    case LinkName.toLowerCase().includes('7digital'):
      return <SevenDigitalLogo />
    case LinkName.toLowerCase().includes('bugs!'):
      return <BugsLogo />
    case LinkName.toLowerCase().includes('kuack'):
      return <KuackLogo />
    case LinkName.toLowerCase().includes('nuuday'):
      return <NuudayLogo sx={{ color: '#5bfe60' }} />
    case LinkName.toLowerCase().includes('claro'):
      return <ClaroLogo sx={{ color: '#ED1C24' }} />
    case LinkName.toLowerCase().includes('flo'):
      return <FloLogo />
    case LinkName.toLowerCase().includes('melon'):
      return <MelonLogo />
    case LinkName.toLowerCase().includes('naver'):
      return <NaverLogo sx={{ color: '#19ce60' }} />
    case LinkName.toLowerCase().includes('gracenote'):
      return <GracenoteLogo sx={{ color: '#f40a53' }} />
    case LinkName.toLowerCase().includes('kanjian'):
      return <KanjianLogo />
    case LinkName.toLowerCase().includes('qobuz'):
      return <QobuzLogo sx={{ color: '#1C8EBC' }} />
    case LinkName.toLowerCase().includes('tencent'):
      return <TencentLogo sx={{ color: '#1772F9' }} />
    case LinkName.toLowerCase().includes('awa'):
      return <AwaLogo />
    case LinkName.toLowerCase().includes('emusic'):
      return <EmusicLogo sx={{ color: '#ef3e28' }} />
    case LinkName.toLowerCase().includes('netease'):
      return <NeteaseLogo sx={{ color: '#C20C0C' }} />
    case LinkName.toLowerCase().includes('saavn'):
      return <SaavnLogo sx={{ color: '#1E1F1F' }} />
    case LinkName.toLowerCase().includes('discord'):
      return <DiscordIcon sx={{ color: '#7289DA' }} />
    case LinkName.toLowerCase().includes('qq'):
      return <QqIcon sx={{ color: '#3458b0' }} />
    case LinkName.toLowerCase().includes('qzone'):
      return <QzoneIcon sx={{ color: '#3458b0' }} />
    case LinkName.toLowerCase().includes('snapchat'):
      return <SnapchatIcon sx={{ color: '#FFFC00' }} />
    case LinkName.toLowerCase().includes('tumblr'):
      return <TumblrIcon sx={{ color: '#34526f' }} />
    case LinkName.toLowerCase().includes('twitch'):
      return <TwitchIcon sx={{ color: '#6441a5' }} />
    case LinkName.toLowerCase().includes('vk'):
      return <VkIcon sx={{ color: '#4C75A3' }} />
    case LinkName.toLowerCase().includes('weibo'):
      return <WeiboIcon sx={{ color: '#DF2029' }} />
    case LinkName.toLowerCase().includes('wechat'):
      return <WeChatIcon sx={{ color: '#09B83E' }} />
    case LinkName.toLowerCase().includes('shazam'):
      return <ShazamIcon sx={{ color: '#0088ff' }} />
    case LinkName.toLowerCase().includes('tracklist'):
      return <TrackListLogo />
    case LinkName.toLowerCase().includes('traxsource'):
      return <TraxsourceLogo />
    default:
      return <LinkRoundedIcon />
  }
}

export default GetIconLink
