import axios from 'axios'

import type { ConversationDto, NewConversation } from 'src/models/Conversation'
import { Conversation } from 'src/models/Conversation'
import type { MessageDto } from 'src/models/Message'
import { Message } from 'src/models/Message'
import { toFormData } from 'src/utils/objectUtils'

const PATH = '/conversations' as const

export const getConversations = () =>
  axios.get<ConversationDto[]>(PATH).then(response => response.data.map(dto => new Conversation(dto)))

export const getConversation = (id: string) =>
  axios.get<ConversationDto>(`${PATH}/conversation/${id}`)
    .then(response => new Conversation(response.data))

export const getMessagesWith = (accountId: string) =>
  axios.get<MessageDto[]>(`${PATH}/messages?correspondant=${accountId}`)
    .then(response => response.data.map(dto => new Message(dto)))

export const createNewConversation = (newConversation: NewConversation) =>
  axios.post<ConversationDto>(PATH, toFormData(newConversation))
    .then(response => new Conversation(response.data))

export const getMessages = (conversationId: string, date: Date) =>
  axios.get<MessageDto[]>(`${PATH}/${conversationId}/messages?date=${date.toISOString()}`)
    .then(response => response.data.map(dto => new Message(dto)))

export const sendMessage = (message: Message) =>
  axios.post<MessageDto>(`${PATH}/${message.conversationId}/messages`, message)
    .then(response => new Message(response.data))

export const sendImage = (image: File) =>
  axios.post<string>(`${PATH}/image`, toFormData({ image }))
    .then(response => response.data)
