import CloseIcon from '@mui/icons-material/Close'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Dialog, IconButton, Stack, Tab, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'

import { getArchivedBoards, getArchivedNotes } from 'src/api/taskboards'
import ManageNotesItem from 'src/components/pages/Task/TaskMenu/TaskMenuModals/ManageNotesItem'
import ManageTaskboardItem from 'src/components/pages/Task/TaskMenu/TaskMenuModals/ManageTaskboardItem'
import type { Notes } from 'src/models/Notes'
import type { TaskBoard } from 'src/models/Taskboard'

type Props = {
  close: () => void
  open: boolean
  refreshBoards: () => void
}

const ViewArchiveModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))
  const [taskboards, setTaskboards] = useState<TaskBoard[]>([])
  const [notes, setNotes] = useState<Notes[]>([])
  const [tabPage, setTabPage] = useState('boards')

  const refreshTaskboardArchiveList = () => {
    void getArchivedBoards()
      .then(setTaskboards)
    void getArchivedNotes()
      .then(setNotes)
  }

  useEffect(() => {
    refreshTaskboardArchiveList()
  }, [])

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='sm'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
        width: 1,
      }}
    >
      <Stack spacing={1} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          paddingTop={3}
          paddingX={3}
          width='100%'
        >
          <Typography variant='h3'>
            Archive
          </Typography>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>

        <TabContext
          value={tabPage}
        >
          <TabList onChange={(event, newValue) => setTabPage(newValue as string)}>
            <Tab label='Taskboards' value='boards' />
            <Tab label='Docs' value='docs' />
          </TabList>
          <TabPanel sx={{ padding: 0 }} value='boards'>
            <Stack width={1}>
              {taskboards.length > 0 &&
              <Stack width={1}>
                {taskboards.map(item =>
                  <ManageTaskboardItem
                    key={item.id}
                    refreshBoards={props.refreshBoards}
                    refreshTaskboardArchiveList={refreshTaskboardArchiveList}
                    taskboard={item}
                  />)}
              </Stack>}
              {taskboards.length === 0 &&
              <Stack
                alignItems='center'
                height={1}
                justifyContent='center'
                width={1}
              >
                <DeleteRoundedIcon sx={{ color: 'action.disabled', fontSize: '144px' }} />
                <Typography color='text.label' variant='h4'>
                  No archived taskboards found
                </Typography>
              </Stack>}
            </Stack>
          </TabPanel>
          <TabPanel sx={{ padding: 0 }} value='docs'>
            <Stack width={1}>
              {notes.length > 0 &&
              <Stack width={1}>
                {notes.map(item =>
                  <ManageNotesItem
                    key={item.projectId}
                    refreshBoards={props.refreshBoards}
                    refreshTaskboardArchiveList={refreshTaskboardArchiveList}
                    taskboard={item}
                  />)}
              </Stack>}
              {notes.length === 0 &&
              <Stack
                alignItems='center'
                height={1}
                justifyContent='center'
                width={1}
              >
                <DeleteRoundedIcon sx={{ color: 'action.disabled', fontSize: '144px' }} />
                <Typography color='text.label' variant='h4'>
                  No archived Docs found
                </Typography>
              </Stack>}
            </Stack>
          </TabPanel>

        </TabContext>
      </Stack>
    </Dialog>
  )
}

export default ViewArchiveModal
