/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Button, Stack } from '@mui/material'
import { useState } from 'react'
import type { DraggableLocation, DropResult } from 'react-beautiful-dnd'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { v4 } from 'uuid'

import { savePartialTaskboard } from 'src/api/taskboards'
import { sendTasksboardUpdate } from 'src/api/webSocket'
import TaskBoardContainer from 'src/components/pages/Task/TaskViews/TaskViewComponents/TaskBoardContainer'
import TaskCardEmpty from 'src/components/pages/Task/TaskViews/TaskViewComponents/TaskCardEmpty'
import TaskSectionModal from 'src/components/pages/Task/TaskViews/TaskViewComponents/TaskSectionModal'
import { useAuth } from 'src/components/providers/AuthProvider'
import type Account from 'src/models/Account'
import type { TaskBoard, TaskGroup, TaskItem } from 'src/models/Taskboard'
import { TaskBoardEdit } from 'src/models/Taskboard'

type Props = {
  toggleTaskDrawer: (toggleState: boolean, taskGroup: TaskGroup, taskData?: TaskItem) => () => void
  toggleTaskModal: (toggleState: boolean, taskGroup?: TaskGroup) => () => void
  toggleEditTaskModal: (toggleState: boolean,  taskGroup: TaskGroup) => () => void
  toggleDeleteTaskModal: (toggleState: boolean, taskGroup: TaskGroup) => () => void
  setSwitchTemplate: (value: React.SetStateAction<boolean>) => void
  taskDataSet: TaskBoard
  searchQuery: string
  statusQuery: string
  priorityQuery: string
  members: Account[]
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>
}

const reorder = (list: TaskItem[], startIndex: number, endIndex: number) => {
  const result = [...list]
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const reorderGroups = (list: TaskGroup[], startIndex: number, endIndex: number) => {
  const result = [...list]
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const BoardView = (props: Props) => {
  const { currentAccount } = useAuth()
  const [newSectionModal, setNewSectionModal] = useState(false)
  const toggleModal = (toggleState: boolean) => () => {
    setNewSectionModal(toggleState)
  }

  const move = (source: TaskItem[],
    destination: TaskItem[],
    droppableSource: DraggableLocation,
    droppableDestination: DraggableLocation) => {
    const sourceClone = [...source]
    const destinationClone = [...destination]
    const [removed] = sourceClone.splice(droppableSource.index, 1)

    destinationClone.splice(droppableDestination.index, 0, removed)

    const result: TaskGroup[] = [...props.taskDataSet.taskGroups]

    const sourceId = props.taskDataSet.taskGroups.findIndex(board => board.id === droppableSource.droppableId)
    const destinationId = props.taskDataSet.taskGroups.findIndex(board => board.id === droppableDestination.droppableId)

    result[sourceId].tasks = sourceClone
    result[destinationId].tasks = destinationClone

    return result
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    } else if (result.destination.droppableId && result.type === 'task') {
      const destinationId =
        props.taskDataSet.taskGroups.findIndex(board => board.id === result.destination!.droppableId)
      const sourceId = props.taskDataSet.taskGroups.findIndex(board => board.id === result.source.droppableId)

      if (sourceId === destinationId) {
        const items =
          reorder(props.taskDataSet.taskGroups[sourceId].tasks, result.source.index, result.destination.index)
        const newState = [...props.taskDataSet.taskGroups]
        newState[sourceId].tasks = items

        const newData = props.taskDataSet
        newData.taskGroups = newState

        void savePartialTaskboard({ id: props.taskDataSet.id ?? '', ...newData })
      } else {
        const newDataResult = move(props.taskDataSet.taskGroups[sourceId].tasks,
          props.taskDataSet.taskGroups[destinationId].tasks,
          result.source,
          result.destination)
        const newState = [...props.taskDataSet.taskGroups]
        newState[sourceId] = newDataResult[sourceId]
        newState[destinationId] = newDataResult[destinationId]

        const newData = props.taskDataSet
        newData.taskGroups = newState

        void savePartialTaskboard({ id: props.taskDataSet.id ?? '', ...newData })
      }

      const taskUpdateItem = new TaskBoardEdit({
        id: v4(),
        taskboardId: props.taskDataSet.id,
        taskId: '',
        accountId: currentAccount.id,
        modificationType: 'Reorder',
        itemType: 'Field',
        title: '',
      })
      sendTasksboardUpdate(taskUpdateItem)
    } else if (result.destination.droppableId && result.type === 'taskboard') {
      const items = reorderGroups(props.taskDataSet.taskGroups, result.source.index, result.destination.index)
      props.taskDataSet.taskGroups = items
      const newBoard = { ...props.taskDataSet, taskGroups: items } as TaskBoard
      void savePartialTaskboard({ id: props.taskDataSet.id ?? '', ...newBoard })

      const taskUpdateItem = new TaskBoardEdit({
        id: v4(),
        taskboardId: props.taskDataSet.id,
        taskId: '',
        accountId: currentAccount.id,
        modificationType: 'Reorder',
        itemType: 'Field',
        title: '',
      })
      sendTasksboardUpdate(taskUpdateItem)
    }
  }
  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          direction='horizontal'
          droppableId={props.taskDataSet.id ?? ''}
          key={props.taskDataSet.id}
          type='taskboard'
        >
          {provided =>
            <Stack
              direction='row'
              height={props.taskDataSet.taskGroups.length === 0 ? 'fit-content' : '100%'}
              sx={{
                overflowX: 'auto',
              }}
              width='100%'
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {props.taskDataSet.taskGroups.map((column, index) =>
                <Draggable
                  draggableId={column.id}
                  index={index}
                  key={column.id}
                >
                  {providedItem =>
                    <Stack
                      direction='column'
                      key={column.id}
                      minWidth={280}
                      padding={1}
                      paddingTop={0}
                      ref={providedItem.innerRef}
                      width='20%'
                      {...providedItem.draggableProps}
                    >
                      <TaskBoardContainer
                        column={column.title}
                        dragHandle={providedItem.dragHandleProps}
                        id={`${column.id}`}
                        members={props.members}
                        priorityQuery={props.priorityQuery}
                        searchQuery={props.searchQuery}
                        statusQuery={props.statusQuery}
                        taskColumn={column}
                        taskboard={props.taskDataSet}
                        toggleDeleteTaskModal={props.toggleDeleteTaskModal}
                        toggleEditTaskModal={props.toggleEditTaskModal}
                        toggleTaskDrawer={props.toggleTaskDrawer}
                        toggleTaskModal={props.toggleTaskModal}
                      />
                    </Stack>}
                </Draggable>)}
              {provided.placeholder}
              <Stack alignItems='center' minWidth={300} width='25%'>
                <Button
                  color='secondary'
                  onClick={toggleModal(true)}
                  variant='text'
                >
                  + Add a section
                </Button>
              </Stack>
            </Stack>}
        </Droppable>
      </DragDropContext>
      {props.taskDataSet.taskGroups.length === 0 &&
      <TaskCardEmpty
        marginTop={1}
        setRefresh={props.setRefresh}
        taskboard={props.taskDataSet}
      />}

      {newSectionModal &&
      <TaskSectionModal
        close={toggleModal(false)}
        open={newSectionModal}
        taskboard={props.taskDataSet}
      />}
    </>
  )
}

export default BoardView
