import CloseIcon from '@mui/icons-material/Close'
import { Dialog, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'

import MarketingSettingsComponent from 'src/components/pages/Links/LinksList/LinksListModal/MarketingSettingsComponent'

type Props = {
  close: () => void
  open: boolean
  tab?: string
}

const MarketingSettingsModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))
  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='md'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
        width: 1,
        '& .MuiPaper-root': {
          height: 1,
        },
      }}
    >
      <Stack height={1} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          paddingTop={3}
          paddingX={3}
          width='100%'
        >
          <Typography variant='h3'>
            Marketing Settings
          </Typography>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>
        <MarketingSettingsComponent tab={props.tab} />
      </Stack>
    </Dialog>
  )
}

export default MarketingSettingsModal
