/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded'
import PercentRoundedIcon from '@mui/icons-material/PercentRounded'
import { Autocomplete, Card, Divider, Grid, InputAdornment, Link, Stack, TextField, Tooltip, Typography, useTheme } from '@mui/material'
import { NumericFormat } from 'react-number-format'

import { proList } from 'src/data/performanceRights'
import type { WriterInformation } from 'src/models/Distribution'

type Props = {
  writerInfo: WriterInformation[]
  setWriterInfo: React.Dispatch<React.SetStateAction<WriterInformation[]>>
}

const PublishingAddOnTab = (props: Props) => {
  const themeItem = useTheme()
  return (
    <Stack spacing={4} width={1}>
      <Stack spacing={2} width={1}>
        <Stack width={1}>
          <Typography lineHeight={1.5} variant='h3'>
            Publishing
          </Typography>
          <Typography color={themeItem.palette.text.secondary} maxWidth={600} variant='body2'>
            <span>
              {`Releese collects the publisher's share of publishing royalties. The information requested below is
              to ensure creators can collect the writer's share of earnings through their PRO. `}
            </span>
            <Link href='https://releese.io' target='_blank'>
              Learn more
            </Link>
          </Typography>
        </Stack>
        {props.writerInfo.map((recordingItem, recordingIndex) =>
          <Stack key={recordingItem.recordingId} spacing={2} width={1}>
            <Card sx={{ width: 1 }} variant='outlined'>
              <Stack padding={2} spacing={2} width={1}>
                <Stack alignItems='center' direction='row' spacing={1}>
                  <Typography variant='h2'>
                    {recordingItem.recordingTitle}
                  </Typography>
                  {Number(recordingItem.composerInfo.map(item => Number((item.percent ?? 0).toFixed(2)))
                    .reduce((a, b) => a + b, 0).toFixed(2)) === 100
                    ? <Tooltip title='Splits add up to 100%'>
                      <CheckCircleRoundedIcon color='success' sx={{ fontSize: 24 }} />
                    </Tooltip>
                    : <Tooltip title='Splits need to add up to 100%'>
                      <ErrorRoundedIcon color='error' sx={{ fontSize: 24 }} />
                    </Tooltip>}
                </Stack>
                {recordingItem.composerInfo.map((item, index) =>
                  <>
                    <Divider />
                    <Stack key={item.writer} width={1}>
                      <Grid container spacing={2}>
                        <Grid item lg={4.5} md={3} sm={12} xl={4.5} xs={12}>
                          <Stack height={1} justifyContent='center'>
                            <Typography variant='body2'>
                              {item.writer}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item lg={3} md={3} sm={12} xl={3} xs={12}>
                          <Autocomplete
                            onChange={(event, value) => {
                              props.setWriterInfo(old => old.map((previous, indexPrevious) =>
                                indexPrevious === recordingIndex
                                  ? { ...previous, composerInfo: previous.composerInfo.map((composer, indexComposer) =>
                                    indexComposer === index
                                      ? { ...composer, pro: value ?? '' }
                                      : composer),
                                  }
                                  : previous))
                            }}
                            options={proList}
                            renderInput={params =>
                              <TextField
                                {...params}
                                InputProps={{
                                  ...params.InputProps,
                                  autoComplete: 'new-password',
                                }}
                                autoComplete='new-password'
                                fullWidth
                                placeholder='PRO Affiliation'
                                sx={{
                                  marginTop: 0,
                                }}
                              />}
                            value={item.pro}
                          />
                        </Grid>
                        <Grid item lg={3} md={3} sm={12} xl={3} xs={12}>
                          <TextField
                            autoComplete='new-password'
                            error={item.ipi.length > 0 && (item.ipi.length < 9 || item.ipi.length > 11)}
                            fullWidth
                            inputProps={{ maxLength: 11 }}
                            onChange={event => {
                              props.setWriterInfo(old => old.map((previous, indexPrevious) =>
                                indexPrevious === recordingIndex
                                  ? { ...previous, composerInfo: previous.composerInfo.map((composer, indexComposer) =>
                                    indexComposer === index
                                      ? { ...composer, ipi: event.target.value.replace(/\D/g, '') }
                                      : composer),
                                  }
                                  : previous))
                            }}
                            placeholder='IPI/CAE Number'
                            sx={{
                              marginTop: 0,
                            }}
                            value={item.ipi}
                          />
                        </Grid>
                        <Grid item lg={1.5} md={3} sm={12} xl={1.5} xs={12}>
                          <NumericFormat
                            InputProps={{
                              autoComplete: 'new-password',
                              endAdornment:
  <InputAdornment position='end'>
    <PercentRoundedIcon color='disabled' fontSize='small' />
  </InputAdornment>,
                            }}
                            customInput={TextField}
                            decimalScale={2}
                            decimalSeparator='.'
                            displayType='input'
                            fullWidth
                            onValueChange={values => {
                              if (values.floatValue) {
                                props.setWriterInfo(old => old.map((previous, indexPrevious) =>
                                  indexPrevious === recordingIndex
                                    ? { ...previous,
                                      composerInfo: previous.composerInfo.map((composer, indexComposer) =>
                                        indexComposer === index
                                          ? { ...composer, percent: values.floatValue }
                                          : composer),
                                    }
                                    : previous))
                              }
                            }}
                            placeholder='100'
                            style={{
                              marginTop: 0,
                            }}
                            thousandSeparator=','
                            thousandsGroupStyle='thousand'
                            value={item.percent}
                          />
                        </Grid>
                      </Grid>
                    </Stack>
                  </>)}
              </Stack>
            </Card>
          </Stack>)}
      </Stack>
    </Stack>
  )
}

export default PublishingAddOnTab
