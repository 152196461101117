/* eslint-disable react/jsx-props-no-spreading */
import type { PlateRenderElementProps, TElement } from '@udecode/plate'
import { createPluginFactory } from '@udecode/plate'

import type { MyValue } from 'src/components/pages/Notes/Modern/typescript/plateTypes'

export const ELEMENT_LEGACY_QUOTE = 'block-quote'

export const LegacyQuoteElement = (props: PlateRenderElementProps<MyValue, TElement>) =>
  <blockquote
    {...props.attributes}
    {...props.nodeProps}
    className={props.className}
  >
    {props.children}
  </blockquote>

export const createLegacyQuotePlugin = createPluginFactory({
  key: ELEMENT_LEGACY_QUOTE,
  isElement: true,
  component: LegacyQuoteElement,
})
