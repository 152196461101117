/* eslint-disable complexity */
/* eslint-disable react-hooks/exhaustive-deps */
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import PauseCircleRoundedIcon from '@mui/icons-material/PauseCircleRounded'
import PlayCircleFilledWhiteRoundedIcon from '@mui/icons-material/PlayCircleFilledWhiteRounded'
import VolumeDownRoundedIcon from '@mui/icons-material/VolumeDownRounded'
import VolumeUpRoundedIcon from '@mui/icons-material/VolumeUpRounded'
import { Fab, IconButton, Paper, Slide, Slider, Stack, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import dayjs from 'dayjs'
import { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'

import { getProject } from 'src/api/projects'
import { usePlayer } from 'src/components/providers/MusicProvider'
import { NavLink } from 'src/components/SafeRouterLink'
import type Project from 'src/models/Project'

const AudioPlayerItem = () => {
  const {
    play,
    buffering,
    currentTime,
    recording,
    expanded,
    setExpanded,
    onPlay,
    pausePlay,
    requestTime,
    timeAttempt,
    setBuffering,
  } = usePlayer()
  const player = useRef<ReactPlayer>(null)
  const [volume, setVolume] = useState(10)
  const themeColors = useTheme()
  const matches = useMediaQuery(themeColors.breakpoints.down('md'))
  const [project, setProject] = useState<Project>()
  const [ready, setReady] = useState(false)

  useEffect(() => {
    onPlay(timeAttempt)
    if (player.current) {
      player.current.seekTo(timeAttempt)
    }
  }, [timeAttempt])

  useEffect(() => {
    if (play) {
      setExpanded(true)
    }
  }, [play])

  useEffect(() => {
    if (recording) {
      void getProject(recording.projectId)
        .then(setProject)
    }
  }, [recording?.projectId])

  return <>
    {recording &&
    <Tooltip title='Show player'>
      <Fab
        color='primary'
        disabled={buffering}
        onClick={() => setExpanded(true)}
        size='medium'
        sx={{
          bottom: 8,
          visibility: matches ? 'hidden' : 'visible',
          position: 'fixed',
          left: 8,
          zIndex: 998,
          animation: play ? 'LoaderSpin infinite 12000ms linear' : null,
          '@keyframes LoaderSpin': {
            from: {
              transform: 'rotate(0deg)',
            },
            to: {
              transform: 'rotate(360deg)',
            },
          },
        }}
      >
        {buffering
          ? <CircularProgress color='primary' />
          : <MusicNoteRoundedIcon />}
      </Fab>
    </Tooltip>}
    <Slide
      direction='up'
      in={expanded}
      style={{
        display: expanded ? 'block' : 'none',
        position: 'fixed',
        visibility: matches ? 'hidden' : 'visible',
      }}
    >
      <Paper
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          borderRadius: '8px 8px 0px 0px',
          zIndex: 999,
        }}
      >
        <Stack
          alignItems='center'
          direction='row'
          padding={1}
          spacing={2}
          width={1}
        >
          {project?.artworkPreviewUrl
            ? <img
              alt={project.title}
              height={48}
              src={project.artworkPreviewUrl}
              style={{
                borderRadius: 8,
                objectFit: 'cover',
                aspectRatio: '120 / 120',
              }}
              width={48}
            />
            : <Paper
              elevation={0}
              sx={{
                background: theme => theme.palette.background.input,
                borderRadius: 0.5,
              }}
            >
              <MusicNoteRoundedIcon color='disabled' sx={{ fontSize: 48 }} />
            </Paper>}
          {!matches && recording &&
            <Stack
              component={NavLink}
              flexShrink={0}
              sx={{
                textDecoration: 'none',
                color: 'inherit',
              }}
              to={`/project/${recording.projectId}`}
              width={132}
            >
              <Typography variant='textLabel'>
                {recording.primaryArtists.map(item => item.name).join(', ').length < 19
                  ? recording.primaryArtists.map(item => item.name).join(', ')
                  : `${recording.primaryArtists.map(item => item.name).join(', ').slice(0, 18)}...`}
              </Typography>
              <Typography fontWeight={500} variant='body2'>
                {recording.title.length < 16
                  ? recording.title
                  : `${recording.title.slice(0, 15)}...`}
              </Typography>
            </Stack>}
          <IconButton
            color='primary'
            disabled={!recording}
            onClick={() => pausePlay(!play)}
            size='large'
            sx={{
              padding: 0.5,
            }}
          >
            {play
              ? <PauseCircleRoundedIcon />
              : <PlayCircleFilledWhiteRoundedIcon />}
          </IconButton>
          <Slider
            disabled={!recording}
            max={recording?.duration ? recording.duration : 0}
            onChangeCommitted={(_, value) => typeof value === 'number' ? requestTime(value) : null}
            sx={{ width: 1 }}
            value={currentTime ? currentTime : 0}
          />
          <Typography variant='textLabel' whiteSpace='nowrap'>
            {`${dayjs(new Date(currentTime ? currentTime * 1000 : 0)).format('mm:ss')} / 
          ${dayjs(new Date(recording?.duration ? recording.duration * 1000 : 0)).format('mm:ss')}`}
          </Typography>
          <VolumeDownRoundedIcon color='action' />
          <Slider
            max={10}
            onChange={(_, value) => typeof value === 'number' ? setVolume(value) : null}
            sx={{ width: 100 }}
            value={volume}
          />
          <VolumeUpRoundedIcon color='action' />
          <IconButton
            color='default'
            onClick={() => setExpanded(false)}
            size='medium'
          >
            <ArrowDropDownRoundedIcon />
          </IconButton>
          <ReactPlayer
            onBuffer={() => setBuffering(true)}
            onBufferEnd={() => setBuffering(false)}
            onEnded={() => pausePlay(false)}
            onProgress={state => {
              onPlay(state.playedSeconds)
            }}
            onReady={() => {
              setReady(true)
            }}
            playing={play && ready}
            ref={player}
            style={{
              display: 'none',
            }}
            url={recording?.audioFileStreamingUrl && recording.audioFileStreamingUrl.length > 0
              ? recording.audioFileStreamingUrl
              : recording?.audioFileUrl}
            volume={volume / 10}
          />
        </Stack>
      </Paper>
    </Slide>
  </>
}
export default AudioPlayerItem
