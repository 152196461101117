import SimpleTour from 'src/components/pages/Tour/TourComponents/SimpleTour'

const TourDistribute = () =>
  <SimpleTour
    description='Your release is ready to be distributed, let&apos;s get it in stores!'
    desktopDirection='right'
    mobileDirection='bottom'
    onboardingKey='releaseDistribute'
    selector='distribute-button'
    title='Distribute it'
  />

export default TourDistribute
