/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable id-length */
import { Stack, Typography, useTheme } from '@mui/material'
import { useRef } from 'react'
import Chart from 'react-apexcharts'

import { useContainerDimensions } from 'src/components/ContainerDimensions'

type Props = {
  title: string
  amount: number
  maximum: number
}

const BalanceChart = (props: Props) => {
  const theme = useTheme()
  const numberComplex = Number((100 - Number((props.amount / props.maximum * 100).toFixed(2))).toFixed(2))
  const seriesTest = [numberComplex]

  const optionsSectionsOverdue: ApexCharts.ApexOptions = {
    chart: {
      width: '100%',
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '70%',
        },
        dataLabels: {
          name: {
            offsetY: -16,
            color: theme.palette.text.label,
            fontSize: '16px',
            fontWeight: 300,
            fontFamily: 'Poppins',
          },
          value: {
            offsetY: 8,
            color: theme.palette.text.primary,
            show: true,
            fontSize: '32px',
            fontWeight: 600,
            fontFamily: 'Poppins',
            formatter: value => new Intl.NumberFormat('en-US',
              { style: 'percent', maximumFractionDigits: 2 }).format(value / 100),
          },
        },
      },
    },
    fill: {
      colors: [theme.palette.success.main],
    },
    stroke: {
      lineCap: 'round',
    },
    labels: ['Progress'],
  }

  const componentRef = useRef<HTMLDivElement>(null)
  const { width } = useContainerDimensions(componentRef)

  return (
    <Stack alignItems='center' ref={componentRef} width={1}>
      <Typography sx={{ width: '100%' }} textAlign='left' variant='h4'>
        {props.title}
      </Typography>
      {seriesTest &&
        <Chart
          height='125%'
          options={optionsSectionsOverdue}
          series={seriesTest}
          type='radialBar'
          width={width}
        />}
    </Stack>
  )
}
export default BalanceChart

