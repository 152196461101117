
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded'
import CloseIcon from '@mui/icons-material/Close'
import { Avatar, Chip, Dialog, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import dayjs from 'dayjs'

import type { BasicOrganisation } from 'src/models/Organisation'
import type { Recoupment } from 'src/models/Recording'

type Props = {
  close: () => void
  open: boolean
  recoup: Recoupment
  org?: BasicOrganisation
}

const ViewTransactionModal = (props: Props) => {
  const themeColors = useTheme()
  const fullScreen = useMediaQuery(themeColors.breakpoints.down('md'))

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='xs'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Stack paddingX={3} paddingY={3} spacing={1} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          width='100%'
        >
          <Typography variant='subtitle1'>
            Transaction
          </Typography>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>

        <Stack width={1}>
          <Typography
            color={themeColors.palette.text.label}
            variant='body2'
          >
            Title
          </Typography>
          <Typography
            variant='body2'
          >
            {props.recoup.title}
          </Typography>
        </Stack>

        <Stack width={1}>
          <Typography
            color={themeColors.palette.text.label}
            variant='body2'
          >
            Description
          </Typography>
          <Typography
            variant='body2'
          >
            {props.recoup.description}
          </Typography>
        </Stack>

        <Stack width={1}>
          <Typography
            color={themeColors.palette.text.label}
            variant='body2'
          >
            Amount
          </Typography>
          <Typography
            variant='body2'
          >
            {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(props.recoup.amount)}
          </Typography>
        </Stack>

        <Stack width={1}>
          <Typography
            color={themeColors.palette.text.label}
            variant='body2'
          >
            Date added
          </Typography>
          <Typography
            variant='body2'
          >
            {dayjs(props.recoup.dateAdded).format('LL')}
          </Typography>
        </Stack>

        <Stack spacing={0.5} width={1}>
          <Typography
            color={themeColors.palette.text.label}
            variant='body2'
          >
            Type
          </Typography>
          <Chip
            color={props.recoup.transactionType === 'Income'
              ? 'success'
              : props.recoup.transactionType === 'Expense'
                ? 'error'
                : props.recoup.transactionType === 'Payout'
                  ? 'secondary'
                  : 'primary'}
            icon={<AttachMoneyRoundedIcon />}
            label={props.recoup.transactionType}
            sx={{
              width: 'fit-content',
            }}
            variant='filled'
          />
        </Stack>

        {props.org &&
        <Stack spacing={0.5} width={1}>
          <Typography
            color={themeColors.palette.text.label}
            variant='body2'
          >
            Payee
          </Typography>
          <Stack alignItems='center' direction='row' spacing={1}>
            <Avatar
              src={props.org.logoUrl}
              sx={{
                height: 24,
                width: 24,
              }}
            />
            <Typography
              sx={{ marginTop: 0.5 }}
              variant='body2'
            >
              {props.org.name}
            </Typography>
          </Stack>
        </Stack>}

      </Stack>
    </Dialog>
  )
}

export default ViewTransactionModal
