/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable unicorn/no-unsafe-regex */
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import DragIndicatorRoundedIcon from '@mui/icons-material/DragIndicatorRounded'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { LoadingButton } from '@mui/lab'
import { Accordion, AccordionDetails, AccordionSummary, Card, CircularProgress, IconButton, Input, Stack, Switch, TextField, Tooltip, Typography } from '@mui/material'
import { useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'

import IconPicker from 'src/components/form-elements/IconPicker/IconPicker'
import type { IconType } from 'src/components/form-elements/IconPicker/iconType'
import { getLinkTitle } from 'src/components/pages/Links/EditLink/EditLinkComponents/bioLinkFunctions'
import { GetColorLink } from 'src/components/pages/SmartLink/Content/GetColorLink'
import GetIcon from 'src/components/pages/SmartLink/Content/GetIcon'
import type { BioLinkListItem, MarketingLink } from 'src/models/Marketing'

type Props = {
  element: BioLinkListItem
  index: number
  smartLinkData: MarketingLink
  onDeleteByIndex: (event: React.MouseEvent<HTMLElement>) => void
  handleChangeLinkDestination: (value: string, id: string) => void
  handleChangePrimaryText: (value: string, id: string) => void
  handleChangeSecondaryText: (value: string, id: string) => void
  handleChangeVideoEmbedUrl: (value: string, id: string) => Promise<void>
  handleChangeImageUrl: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>
  handleChangeTextContent: (value: string, id: string) => void
  handleChangeVideoEmbedSmall: (value: string, id: string) => void
  handleChangeActiveHeader: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleChangeIcon: (value: IconType, id: string) => void
  handleChangeBandsintown: (value: string, id: string) => Promise<void>
}

const BioLinkListEditItem = (props: Props) => {
  const [open, setOpen] = useState(
    props.element.blockType !== 'latest_release' &&
    props.element.blockType !== 'next_release'
  )
  const [loadingImage, setLoadingImage] = useState(false)
  const [loadingEmbed, setLoadingEmbed] = useState(false)

  const uploadImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoadingImage(true)
    await props.handleChangeImageUrl(event)
      .then(() => setLoadingImage(false))
  }

  const regexUrlBandsintown = /https:\/\/www.bandsintown.com\/a\/(.*?)-/
  const regexUrlBandsintownShort = /https:\/\/www.bandsintown.com\/a\/(.*)/
  const artistId = regexUrlBandsintown.exec(props.element.videoEmbedUrl)
  const artistIdShort = regexUrlBandsintownShort.exec(props.element.videoEmbedUrl)

  return (
    <Draggable draggableId={props.element.id} index={props.index} key={props.element.id}>
      {providedDrag =>
        <Stack
          key={`item-${props.element.id}`}
          paddingTop={2}
          ref={providedDrag.innerRef}
          {...providedDrag.draggableProps}
        >
          <Card
            variant='outlined'
          >
            <Stack
              alignItems='flex-start'
              direction='column'
              spacing={1}
              width={1}
            >
              <Accordion
                elevation={0}
                expanded={open}
                onChange={(event, isExpanded) => {
                  if (props.element.blockType !== 'latest_release' &&
                  props.element.blockType !== 'next_release') {
                    setOpen(isExpanded)
                  }
                }}
                sx={{
                  width: 1,
                  padding: 0,
                }}
              >
                <AccordionSummary
                  expandIcon={props.element.blockType !== 'latest_release' &&
                  props.element.blockType !== 'next_release'
                    ? <Tooltip title={open ? 'Minimize' : 'Expand'}>
                      <ExpandMoreIcon
                        sx={{
                          fontSize: '1.5rem' }}
                      />
                    </Tooltip>
                    : <ExpandMoreIcon
                      sx={{
                        fontSize: '1.5rem',
                        color: theme => theme.palette.action.disabled,
                      }}
                    />}
                  sx={{
                    flexDirection: 'row-reverse',
                  }}
                >
                  <Stack
                    alignItems='center'
                    direction='row'
                    justifyContent='space-between'
                    paddingLeft={1}
                    spacing={1}
                    width={1}
                  >
                    <Stack
                      alignItems='center'
                      direction='row'
                      spacing={1}
                    >
                      <Tooltip title='Drag to move'>
                        <IconButton {...providedDrag.dragHandleProps} size='small'>
                          <DragIndicatorRoundedIcon
                            fontSize='medium'
                            sx={{
                              color: theme => theme.palette.action.active,
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <IconButton
                        disableRipple
                        size='medium'
                        sx={{
                          backgroundColor: GetColorLink(props.element.blockType),
                          color: theme => theme.palette.primary.contrastText,
                          ':hover': {
                            backgroundColor: GetColorLink(props.element.blockType),
                            cursor: 'default',
                          },
                        }}
                      >
                        {GetIcon(props.element.blockType)}
                      </IconButton>
                      <Typography lineHeight={1.4} variant='h3'>
                        {getLinkTitle(props.element.blockType)}
                      </Typography>
                    </Stack>
                    <Stack
                      alignItems='center'
                      direction='row'
                      spacing={1}
                    >
                      <Stack onClick={event => event.stopPropagation()}>
                        <Switch
                          checked={props.element.active}
                          id={`active-${props.element.id}`}
                          onChange={props.handleChangeActiveHeader}
                        />
                      </Stack>
                      <Tooltip title='Remove block'>
                        <IconButton
                          color='error'
                          id={props.element.id}
                          onClick={props.onDeleteByIndex}
                          size='small'
                        >
                          <CloseRoundedIcon />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </Stack>
                </AccordionSummary>

                <AccordionDetails>
                  <Stack direction='column' spacing={2} width={1}>
                    {props.element.blockType === 'link' &&
                    <>
                      <Stack direction='row' spacing={1} width={1}>
                        <Tooltip title='Pick an icon'>
                          <div>
                            <IconPicker
                              iconListIconSize={2}
                              iconPerPage={54}
                              name='icon'
                              onChange={event =>
                                props.handleChangeIcon(event.target.value as IconType, props.element.id)}
                              searchPlaceholder='Search'
                              showSearch
                              value={props.element.icon}
                            />
                          </div>
                        </Tooltip>
                        <TextField
                          autoComplete='new-password'
                          fullWidth
                          id={`primary-${props.element.id}`}
                          inputProps={{ maxLength: 28 }}
                          key={`primary-${props.element.id}`}
                          onChange={event => props.handleChangePrimaryText(event.target.value, props.element.id)}
                          placeholder='My new link'
                          sx={{ marginTop: 0 }}
                          value={props.element.linkPrimaryText}
                        />
                      </Stack>

                      <TextField
                        autoComplete='new-password'
                        error={!props.element.linkDestinationUrl.startsWith('https://')}
                        fullWidth
                        id={`destination-${props.element.id}`}
                        key={`destination-${props.element.id}`}
                        onChange={event => props.handleChangeLinkDestination(event.target.value, props.element.id)}
                        placeholder='https://instagram.com/'
                        sx={{ marginTop: 0 }}
                        value={props.element.linkDestinationUrl}
                      />
                    </>}

                    {(props.element.blockType === 'video_embed' ||
                    props.element.blockType === 'twitter' ||
                    props.element.blockType === 'youtube' ||
                    props.element.blockType === 'daily_motion' ||
                    props.element.blockType === 'vevo' ||
                    props.element.blockType === 'vimeo' ||
                    props.element.blockType === 'twitch' ||
                    props.element.blockType === 'audiomack' ||
                    props.element.blockType === 'soundcloud' ||
                    props.element.blockType === 'spotify' ||
                    props.element.blockType === 'threads' ||
                    props.element.blockType === 'snapchat') &&
                      <Stack alignItems='center' direction='row' spacing={1} width={1}>
                        <TextField
                          autoComplete='new-password'
                          error={!props.element.videoEmbedUrl.startsWith('https://')}
                          fullWidth
                          key={`embed-${props.element.id}`}
                          onChange={async event => {
                            setLoadingEmbed(true)
                            await props.handleChangeVideoEmbedUrl(event.target.value, props.element.id)
                              .then(() => setLoadingEmbed(false))
                          }}
                          placeholder='https://youtube.com/'
                          sx={{ marginTop: 0 }}
                          value={props.element.videoEmbedUrl}
                        />
                        {loadingEmbed &&
                        <CircularProgress color='primary' variant='indeterminate' />}
                      </Stack>}

                    {(props.element.blockType === 'facebook' ||
                    props.element.blockType === 'instagram' ||
                    props.element.blockType === 'tiktok') &&
                      <Stack alignItems='center' direction='row' spacing={1} width={1}>
                        <TextField
                          autoComplete='new-password'
                          error={!props.element.videoEmbedUrl.startsWith('https://')}
                          fullWidth
                          id={`embed-${props.element.id}`}
                          key={`embed-${props.element.id}`}
                          onChange={event => props.handleChangeVideoEmbedSmall(event.target.value, props.element.id)}
                          placeholder='https://instagram.com/'
                          sx={{ marginTop: 0 }}
                          value={props.element.videoEmbedUrl}
                        />
                      </Stack>}

                    {props.element.blockType === 'bandsintown' &&
                      <Stack alignItems='center' direction='row' spacing={1} width={1}>
                        <TextField
                          autoComplete='new-password'
                          error={!props.element.videoEmbedUrl.startsWith('https://')}
                          fullWidth
                          id={`bandsintown-${props.element.id}`}
                          key={`bandsintown-${props.element.id}`}
                          onChange={event => props.handleChangeBandsintown(event.target.value, props.element.id)}
                          placeholder='https://bandsintown.com/a/12345678-name'
                          sx={{ marginTop: 0 }}
                          value={props.element.videoEmbedUrl}
                        />
                      </Stack>}

                    {props.element.blockType === 'bandsintown' &&
                        ((!artistId || !artistId[1]) && (!artistIdShort || !artistIdShort[1])) &&
                        <Stack alignItems='center' direction='row' spacing={1} width={1}>
                          <Typography color='error' variant='body1'>
                            Please check your Bandsintown link
                          </Typography>
                        </Stack>}

                    {props.element.blockType === 'seated' &&
                      <Stack alignItems='center' direction='row' spacing={1} width={1}>
                        <TextField
                          autoComplete='new-password'
                          error={!props.element.videoEmbedUrl.startsWith('https://')}
                          fullWidth
                          id={`seated-${props.element.id}`}
                          key={`seated-${props.element.id}`}
                          onChange={event => props.handleChangeBandsintown(event.target.value, props.element.id)}
                          placeholder='7b70074c-6f97-4006-ba53-08ca4fcf2df5'
                          sx={{ marginTop: 0 }}
                          value={props.element.videoEmbedUrl}
                        />
                      </Stack>}

                    {props.element.blockType === 'text' &&
                      <TextField
                        autoComplete='new-password'
                        fullWidth
                        id={`textContent-${props.element.id}`}
                        key={`textContent-${props.element.id}`}
                        minRows={4}
                        multiline
                        onChange={event => props.handleChangeTextContent(event.target.value, props.element.id)}
                        placeholder='My cool block of text'
                        sx={{ marginTop: 0 }}
                        value={props.element.textContent}
                      />}

                    {props.element.blockType === 'chat' &&
                      <TextField
                        autoComplete='new-password'
                        fullWidth
                        id={`textContent-${props.element.id}`}
                        key={`textContent-${props.element.id}`}
                        minRows={4}
                        multiline
                        onChange={event => props.handleChangeTextContent(event.target.value, props.element.id)}
                        placeholder='This is a personal message!'
                        sx={{ marginTop: 0 }}
                        value={props.element.textContent}
                      />}

                    {props.element.blockType === 'left_divider' &&
                    <TextField
                      autoComplete='new-password'
                      fullWidth
                      id={`divider-${props.element.id}`}
                      key={`divider-${props.element.id}`}
                      minRows={4}
                      multiline
                      onChange={event => props.handleChangeTextContent(event.target.value, props.element.id)}
                      placeholder='Divider text'
                      sx={{ marginTop: 0 }}
                      value={props.element.textContent}
                    />}

                    {props.element.blockType === 'header' &&
                    <TextField
                      autoComplete='new-password'
                      fullWidth
                      id={`textContent-${props.element.id}`}
                      key={`textContentHeader-${props.element.id}`}
                      minRows={2}
                      multiline
                      onChange={event => props.handleChangeTextContent(event.target.value, props.element.id)}
                      placeholder='My header'
                      sx={{ marginTop: 0 }}
                      value={props.element.textContent}
                    />}

                    {props.element.blockType === 'card_block' &&
                    <>

                      <TextField
                        autoComplete='new-password'
                        fullWidth
                        id={`primary-${props.element.id}`}
                        inputProps={{ maxLength: 28 }}
                        key={`primary-${props.element.id}`}
                        onChange={event => props.handleChangePrimaryText(event.target.value, props.element.id)}
                        placeholder='My new link'
                        sx={{ marginTop: 0 }}
                        value={props.element.linkPrimaryText}
                      />

                      <TextField
                        autoComplete='new-password'
                        fullWidth
                        id={`primary-${props.element.id}`}
                        inputProps={{ maxLength: 28 }}
                        key={`primary-${props.element.id}`}
                        onChange={event => props.handleChangeSecondaryText(event.target.value, props.element.id)}
                        placeholder='My new link'
                        sx={{ marginTop: 0 }}
                        value={props.element.linkSecondaryText}
                      />

                      <TextField
                        autoComplete='new-password'
                        error={!props.element.linkDestinationUrl.startsWith('https://')}
                        fullWidth
                        id={`destination-${props.element.id}`}
                        key={`destination-${props.element.id}`}
                        onChange={event => props.handleChangeLinkDestination(event.target.value, props.element.id)}
                        placeholder='https://instagram.com/'
                        sx={{ marginTop: 0 }}
                        value={props.element.linkDestinationUrl}
                      />

                      <Stack
                        alignItems='flex-start'
                        height={1}
                        justifyContent='flex-start'
                        spacing={2}
                        width={1}
                      >
                        {props.element.imageUrl.length > 0 &&
                        <img
                          alt='Embeded Releese'
                          height='auto'
                          src={props.element.imageUrl}
                          style={{
                            borderRadius: '16px',
                            maxWidth: 200,
                          }}
                          width='100%'
                        />}
                        <label htmlFor={`imageTest-${props.element.id}`}>
                          <LoadingButton
                            color='inherit'
                            component='span'
                            loading={loadingImage}
                            size='small'
                            startIcon={<AddRoundedIcon />}
                            sx={{
                              width: 'fit-content',
                            }}
                            variant='contained'
                          >
                            {props.element.imageUrl.length > 0
                              ? 'Replace Image'
                              : 'Upload an image'}
                          </LoadingButton>
                          <Input
                            id={`imageTest-${props.element.id}`}
                            onChange={uploadImage}
                            sx={{ display: 'none', zIndex: '100000' }}
                            type='file'
                          />
                        </label>
                      </Stack>
                    </>}

                    {props.element.blockType === 'image' &&
                    <Stack
                      alignItems='flex-start'
                      height={1}
                      justifyContent='flex-start'
                      spacing={2}
                      width={1}
                    >
                      {props.element.imageUrl.length > 0 &&
                      <img
                        alt='Embeded Releese'
                        height='auto'
                        src={props.element.imageUrl}
                        style={{
                          borderRadius: '16px',
                          maxWidth: 200,
                        }}
                        width='100%'
                      />}
                      <label htmlFor={`image-${props.element.id}`}>
                        <LoadingButton
                          color='inherit'
                          component='span'
                          loading={loadingImage}
                          size='small'
                          startIcon={<AddRoundedIcon />}
                          sx={{
                            width: 'fit-content',
                          }}
                          variant='contained'
                        >
                          {props.element.imageUrl.length > 0
                            ? 'Replace Image'
                            : 'Upload an image'}
                        </LoadingButton>
                        <Input
                          id={`image-${props.element.id}`}
                          onChange={uploadImage}
                          sx={{ display: 'none', zIndex: '100000' }}
                          type='file'
                        />
                      </label>
                    </Stack>}
                  </Stack>
                </AccordionDetails>
              </Accordion>
            </Stack>
          </Card>
        </Stack>}
    </Draggable>
  )
}

export default BioLinkListEditItem
