import AlbumRoundedIcon from '@mui/icons-material/AlbumRounded'
import MicExternalOnRoundedIcon from '@mui/icons-material/MicExternalOnRounded'
import { LoadingButton } from '@mui/lab'
import { Avatar, Button, Chip, Paper, Stack, Typography, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'

import { setOrganisationAnalyticsSource } from 'src/api/organisation'
import SearchOneArtistComponent from 'src/components/form-elements/SearchOneArtist'
import SearchOneLabel from 'src/components/form-elements/SearchOneLabel'
import AnalyticsOnboardModal from 'src/components/pages/Audience/Modals/AnalyticsOnboardModal'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { SongStatsLabels } from 'src/models/Analytics'
import type { ArtistObject } from 'src/models/Distribution'

const AnalyticsTotalOnboarding = () => {
  const { refreshCurrentOrganisation } = useAuth()
  const theme = useTheme()
  const [artistView, setArtistView] = useState(true)

  const [openModalOnboard, setOpenModalOnboard] = useState(false)

  const [loading, setLoading] = useState(false)
  const [selectedArtist, setSelectedArtist] = useState<ArtistObject | null | undefined>(null)
  const [selectedLabel, setSelectedLabel] = useState<SongStatsLabels | null | undefined>(null)

  const setSelectedArtists = (value: ArtistObject | null | undefined) => {
    setSelectedArtist(value)
  }

  const setSelectedLabels = (value: SongStatsLabels | null | undefined) => {
    setSelectedLabel(value)
  }

  useEffect(() => {
    setSelectedArtist(null)
    setSelectedLabel(null)
  }, [artistView])

  const selectProfile = async () => {
    if (artistView) {
      setLoading(true)
      if (selectedArtist) {
        await setOrganisationAnalyticsSource('artist', selectedArtist.id ?? '')
          .then(refreshCurrentOrganisation)
          .then(() => setLoading(false))
      }
    } else  if (selectedLabel) {
      await setOrganisationAnalyticsSource('label', selectedLabel.songstats_label_id)
        .then(refreshCurrentOrganisation)
        .then(() => setLoading(false))
    }
  }

  return (
    <>
      <Stack
        alignItems='center'
        height={1}
        justifyContent='center'
        marginLeft='auto'
        marginRight='auto'
        maxWidth={1332}
        padding={2}
        spacing={2}
        width={1}
      >
        {artistView
          ? <Paper
            elevation={0}
            sx={{
              backgroundColor: theme.palette.primary.main,
              borderRadius: 16,
            }}
          >
            <Stack
              alignItems='center'
              justifyContent='center'
              padding={4}
            >
              <MicExternalOnRoundedIcon
                sx={{
                  color: theme.palette.primary.contrastText,
                  fontSize: '144px',
                }}
              />
            </Stack>
          </Paper>
          : <Paper
            elevation={0}
            sx={{
              backgroundColor: theme.palette.info.main,
              borderRadius: 16,
            }}
          >
            <Stack
              alignItems='center'
              justifyContent='center'
              padding={4}
            >
              <AlbumRoundedIcon
                sx={{
                  color: theme.palette.primary.contrastText,
                  fontSize: '144px',
                }}
              />
            </Stack>
          </Paper>}

        <Stack
          alignItems='center'
          justifyContent='center'
          width={1}
        >
          <Typography
            sx={{
              textAlign: 'center',
            }}
            variant='h2'
          >
            Find yourself
          </Typography>
          <Typography
            color={theme.palette.text.label}
            sx={{
              textAlign: 'center',
            }}
            variant='body1'
          >
            Please select your
            {' '}
            {artistView ? 'artist' : 'label'}
            {' '}
            profile so we can track your analytics
          </Typography>
        </Stack>

        {artistView &&
        <>
          <Stack
            maxWidth={400}
            width={1}
          >
            <SearchOneArtistComponent
              disabled={false}
              error={false}
              placeholder='Search Artists'
              selectedArtists={selectedArtist}
              setSelectedArtists={setSelectedArtists}
            />
          </Stack>

          {selectedArtist &&
          <Chip
            avatar={<Avatar
              src={selectedArtist.images?.[0]?.url ? selectedArtist.images[0].url : ''}
            />}
            label={selectedArtist.name}
            size='medium'
          />}

          <LoadingButton
            color='primary'
            disabled={!selectedArtist}
            loading={loading}
            onClick={() => setOpenModalOnboard(true)}
            variant='contained'
          >
            Select this artist
          </LoadingButton>
        </>}

        {!artistView &&
        <>
          <Stack
            maxWidth={400}
            width={1}
          >
            <SearchOneLabel
              disabled={false}
              error={false}
              placeholder='Search Labels'
              selectedArtists={selectedLabel}
              setSelectedArtists={setSelectedLabels}
            />
          </Stack>

          {selectedLabel &&
          <Chip
            avatar={<Avatar src={selectedLabel.avatar} />}
            label={selectedLabel.name}
            size='medium'
          />}

          <LoadingButton
            color='primary'
            disabled={!selectedLabel}
            loading={loading}
            onClick={() => setOpenModalOnboard(true)}
            variant='contained'
          >
            Select this label
          </LoadingButton>
        </>}

        <Button
          color='secondary'
          disabled={loading}
          onClick={() => setArtistView(old => !old)}
          size='small'
          variant='text'
        >
          {artistView
            ? 'I represent a label'
            : 'I am an artist'}
        </Button>
      </Stack>
      <AnalyticsOnboardModal
        artistView={artistView}
        close={() => setOpenModalOnboard(false)}
        loading={loading}
        open={openModalOnboard}
        selectProfile={selectProfile}
        selectedArtist={selectedArtist}
        selectedLabel={selectedLabel}
      />
    </>
  )
}

export default AnalyticsTotalOnboarding
