/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable id-length */
import { Stack, useTheme } from '@mui/material'
import { useRef } from 'react'
import Chart from 'react-apexcharts'

import { useContainerDimensions } from 'src/components/ContainerDimensions'
import { GetColorLink } from 'src/components/pages/SmartLink/Content/GetColorLink'
import type { AnalyticsMonthlyChart } from 'src/models/Analytics'

type Props = {
  data: AnalyticsMonthlyChart[]
  source: string
}

const AnalyticsStatementArea = (props: Props) => {
  const theme = useTheme()
  const types = [
    'streams',
    'views',
    'downloads',
    'creations',
  ] as (keyof AnalyticsMonthlyChart)[]

  const seriesTest =
    types.map(key => ({
      name: key.split('_').map(word => word[0].toLocaleUpperCase() + word.slice(1)).join(' '),
      data: props.data.filter(object => object[key] !== null)
        .sort((a, b) => Number(new Date(a._id)) - Number(new Date(b._id)))
        .map(item => ({
          x: `01${item._id.slice(2)}`,
          y: item[key],
        })),
    })).filter(item => item.data && item.data?.length > 0)

  const optionsSectionsOverdue: ApexCharts.ApexOptions = {
    chart: {
      width: '100%',
      stacked: false,
      animations: {
        enabled: false,
      },
      zoom: {
        enabled: false,
      },
      foreColor: theme.palette.text.primary,
      toolbar: {
        show: false,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      width: 2,
      curve: 'smooth',
    },
    grid: {
      borderColor: theme.palette.divider,
    },
    colors: props.source.length > 0
      ? [
        GetColorLink(props.source),
      ]
      : [
        theme.palette.primary.main,
        theme.palette.secondary.main,
        theme.palette.info.main,
        theme.palette.success.main,
      ],
    tooltip: {
      theme: 'dark',
      x: {
        show: false,
      },
    },
    fill: {
      type: 'gradient',
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: 'datetime',
      tooltip: {
        enabled: false,
      },
      axisBorder: {
        color: theme.palette.divider,
      },
      axisTicks: {
        color: theme.palette.divider,
      },
    },
    yaxis: {
      labels: {
        formatter: value => value?.toLocaleString(),
      },
      axisBorder: {
        color: theme.palette.divider,
      },
      axisTicks: {
        color: theme.palette.divider,
      },
    },
    legend: {
      showForZeroSeries: false,
    },
  }

  const componentRef = useRef<HTMLDivElement>(null)
  const { width } = useContainerDimensions(componentRef)

  return (
    <Stack alignItems='center' ref={componentRef} width={1} >
      {seriesTest &&
        <Chart
          height={400}
          options={optionsSectionsOverdue}
          series={seriesTest}
          type='area'
          width={width}
        />}
    </Stack>
  )
}
export default AnalyticsStatementArea

