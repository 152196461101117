import AddRoundedIcon from '@mui/icons-material/AddRounded'
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded'
import { Button, Paper, Stack, Typography, useTheme } from '@mui/material'
import { useState } from 'react'

import NewTaskboardModal from 'src/components/pages/Task/TaskMenu/TaskMenuModals/NewTaskboardModal'

const TaskboardViewEmpty = () => {
  const themeItem = useTheme()
  const [newTaskboardModal, setNewTaskboardModal] = useState(false)
  return (
    <>
      {newTaskboardModal &&
      <NewTaskboardModal
        close={() => setNewTaskboardModal(false)}
        open={newTaskboardModal}
      />}
      <Stack
        alignItems='center'
        height={1}
        justifyContent='center'
        width='100%'
      >
        <Paper
          elevation={0}
          sx={{
            backgroundColor: theme => theme.palette.secondary.main,
            borderRadius: 16,
          }}
        >
          <Stack
            alignItems='center'
            justifyContent='center'
            padding={2}
          >
            <TaskAltRoundedIcon
              sx={{
                color: theme => theme.palette.primary.contrastText,
                fontSize: '64px',
              }}
            />
          </Stack>
        </Paper>
        <Typography
          color={themeItem.palette.text.secondary}
          sx={{
            marginTop: 1,
          }}
          variant='h4'
        >
          No Taskboard Selected
        </Typography>
        <Typography
          color={themeItem.palette.text.label}
          variant='body1'
        >
          Create or select a Taskboard to get started
        </Typography>
        <Button
          color='secondary'
          onClick={() => setNewTaskboardModal(true)}
          startIcon={<AddRoundedIcon />}
          sx={{
            marginTop: 1,
          }}
        >
          Create a Taskboard
        </Button>
      </Stack>
    </>
  )
}

export default TaskboardViewEmpty
