/* eslint-disable max-lines */
import EditIcon from '@mui/icons-material/Edit'
import { Autocomplete, Card, Paper, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import type { ChangeEvent } from 'react'
import type { ColorResult } from 'react-color'

import { FileUploadButtonBase } from 'src/components/form-elements/FileUpload'
import EditLinkBackgroundColor from 'src/components/pages/Links/EditLink/EditLinkComponents/EditLinkBackground'
import EditLinkButtonColor from 'src/components/pages/Links/EditLink/EditLinkComponents/EditLinkButtonColor'
import EditLinkButtonColors from 'src/components/pages/Links/EditLink/EditLinkComponents/EditLinkButtonColors'
import EditLinkButtonShape from 'src/components/pages/Links/EditLink/EditLinkComponents/EditLinkButtonShape'
import EditLinkFont from 'src/components/pages/Links/EditLink/EditLinkComponents/EditLinkFont'
import EditLinkPalette from 'src/components/pages/Links/EditLink/EditLinkComponents/EditLinkPalette'
import EditLinkTemplate from 'src/components/pages/Links/EditLink/EditLinkComponents/EditLinkTheme'
import type { MarketingLink } from 'src/models/Marketing'

type Props = {
  smartLinkData: MarketingLink
  handleArtworkUpload: (event: ChangeEvent<HTMLInputElement>) => void
  artworkPreview: string | null | undefined
  setLinkData: React.Dispatch<React.SetStateAction<MarketingLink>>
  colors: string[]
}

const EditLinkGeneral = (props: Props) => {
  const themeColors = useTheme()
  const matches = useMediaQuery(themeColors.breakpoints.down('md'))

  const handleColorChange = (colorItem: ColorResult) => {
    props.setLinkData(old => ({ ...old, backgroundColor: colorItem.hex }))
  }

  const handleColorChangeBioLink = (colorItem: ColorResult) => {
    props.setLinkData(old => ({ ...old, bioLinkButtonColor: colorItem.hex }))
  }

  return (
    <Stack direction='column' maxWidth={896} spacing={2} width={1}>
      <Stack direction='column' spacing={3} width={1}>
        <Stack direction='column' width={1}>
          <Stack
            alignItems='flex-start'
            direction='row'
            justifyContent='space-between'
            paddingTop={2}
            width={1}
          >
            <Typography variant='h3'>
              {props.smartLinkData.type === 'Shortlink'
                ? 'Internal appearance'
                : 'Header'}
            </Typography>
          </Stack>
          <Card
            variant='outlined'
          >
            <Stack padding={4} spacing={2} width={1}>
              <Stack direction='row' justifyContent='flex-start' spacing={2}>
                <FileUploadButtonBase id='upload-new-project-artwork' onChange={props.handleArtworkUpload}>
                  <Paper
                    elevation={0}
                    sx={{
                      width: 144,
                      height: 144,
                      borderRadius: props.smartLinkData.type === 'Biolink'
                        ? 64
                        : '4px',
                      border: theme => `1px dashed ${theme.palette.primary.main}`,
                      background: theme => theme.palette.background.input,
                      '&:hover': {
                        opacity: 0.5,
                        cursor: 'pointer',
                        transition: 'all 0.2s ease',
                      },
                    }}
                  >
                    {props.artworkPreview
                      ? <img
                        alt='preview'
                        height='100%'
                        src={props.artworkPreview}
                        style={{
                          objectFit: 'cover',
                          borderRadius: props.smartLinkData.type === 'Biolink'
                            ? 128
                            : 8,
                        }}
                        width='100%'
                      />
                      : <Stack alignItems='center' height='100%' justifyContent='center'>
                        <EditIcon color='primary' fontSize='large' sx={{ margin: 'auto' }} />
                      </Stack>}
                  </Paper>
                </FileUploadButtonBase>
                {!matches &&
                <Paper
                  elevation={0}
                  sx={{
                    width: 360,
                    height: 144,
                    background: theme => theme.palette.background.input,
                  }}
                >
                  <Stack height='100%' justifyContent='center' padding={2} spacing={1}>
                    <Typography variant='subtitle2'>Image requirements</Typography>
                    <Typography variant='body2'>At least 500 x 500px</Typography>
                    <Typography variant='body2'>Less than 1,024 KB</Typography>
                    <Typography variant='body2'>JPEG or PNG file format</Typography>
                  </Stack>
                </Paper>}
              </Stack>

              <Stack direction='row' justifyContent='space-between' paddingTop={4} spacing={2}>
                <TextField
                  autoComplete='new-password'
                  fullWidth
                  id='title'
                  label='Title'
                  onChange={event => props.setLinkData(old => ({ ...old, title: event.target.value }))}
                  placeholder='Enter your title'
                  sx={{ marginTop: 0 }}
                  value={props.smartLinkData.title}
                />
              </Stack>

              {props.smartLinkData.type !== 'Shortlink' &&
          props.smartLinkData.type !== 'Biolink' &&
          <Stack direction='row' justifyContent='space-between' spacing={2} width={1}>
            <Autocomplete
              freeSolo
              id='artists'
              limitTags={3}
              multiple
              onChange={(event, value) => props.setLinkData(old => ({ ...old, artists: value }))}
              options={[]}
              renderInput={params =>
                <TextField
                  {...params}
                  autoComplete='new-password'
                  fullWidth
                  label='Artists'
                  placeholder='Type and press enter to add an artist'
                  sx={{ width: 1 }}
                />}
              sx={{ width: 1 }}
              value={props.smartLinkData.artists}
            />
          </Stack>}

              {props.smartLinkData.type !== 'Shortlink' &&
              <Stack direction='column' justifyContent='space-between' paddingTop={2} spacing={1}>
                <TextField
                  autoComplete='new-password'
                  fullWidth
                  id='description'
                  inputProps={{ maxLength: 500 }}
                  label='Description'
                  minRows={4}
                  multiline
                  onChange={event => props.setLinkData(old => ({ ...old, descriptionText: event.target.value }))}
                  placeholder='My new song is out soon...'
                  sx={{ marginTop: 0 }}
                  value={props.smartLinkData.descriptionText}
                />
              </Stack>}
            </Stack>
          </Card>
        </Stack>

        {props.smartLinkData.type !== 'Shortlink' &&
        <Stack spacing={2} width={1}>
          <Stack direction='column' width={1}>
            <Stack
              alignItems='flex-start'
              direction='row'
              justifyContent='space-between'
              paddingTop={2}
              width={1}
            >
              <Typography variant='h3'>
                Page style
              </Typography>
            </Stack>
            <Card
              variant='outlined'
            >
              <Stack padding={4} spacing={2} width={1}>
                <Stack direction='row' justifyContent='space-between' spacing={2} width={1}>
                  <Stack spacing={1} width={1}>
                    <Typography sx={{ marginTop: 0 }} variant='h4'>
                      Template
                    </Typography>
                    <EditLinkTemplate
                      artworkPreview={props.artworkPreview}
                      colors={props.colors}
                      template={props.smartLinkData.theme}
                      updateTemplate={(template: string) => props.setLinkData(old => ({ ...old, theme: template }))}
                    />
                  </Stack>
                </Stack>

                <Stack direction='row' justifyContent='space-between' spacing={2} width={1}>
                  <Stack spacing={1} width={1}>
                    <Typography sx={{ marginTop: 0 }} variant='h4'>
                      Theme
                    </Typography>
                    <EditLinkPalette
                      activePalette={props.smartLinkData.palette}
                      updateActivePalette={(palette: string) => props.setLinkData(old => ({ ...old, palette }))}
                    />
                  </Stack>
                </Stack>

                <Stack direction='row' justifyContent='space-between' spacing={2} width={1}>
                  <Stack spacing={1} width={1}>
                    <Typography sx={{ marginTop: 0 }} variant='h4'>
                      Font
                    </Typography>
                    <EditLinkFont
                      font={props.smartLinkData.fontStyle}
                      updateFont={(font: string) => props.setLinkData(old => ({ ...old, fontStyle: font }))}
                    />
                  </Stack>
                </Stack>

                {props.smartLinkData.theme === 'Vibrant' &&
                <Stack direction='row' justifyContent='space-between' spacing={2} width={1}>
                  <Stack spacing={1} width={1}>
                    <Typography sx={{ marginTop: 0 }} variant='h4'>
                      Background color
                    </Typography>
                    <EditLinkBackgroundColor
                      backgroundColor={props.smartLinkData.backgroundColor}
                      colors={props.colors}
                      handleColorChange={handleColorChange}
                    />
                  </Stack>
                </Stack>}
              </Stack>
            </Card>
          </Stack>

          <Stack direction='column' width={1}>
            <Stack
              alignItems='flex-start'
              direction='row'
              justifyContent='space-between'
              paddingTop={2}
              width={1}
            >
              <Typography variant='h3'>
                Buttons
              </Typography>
            </Stack>
            <Card
              variant='outlined'
            >
              <Stack padding={4} spacing={2} width={1}>
                <Stack direction='row' justifyContent='space-between' spacing={2} width={1}>
                  <Stack spacing={1} width={1}>
                    <Typography sx={{ marginTop: 0 }} variant='h4'>
                      Button palette
                    </Typography>
                    <EditLinkButtonColors
                      buttonColors={props.smartLinkData.colorStyle}
                      updateButtonColors={(colorStyle: string) => props.setLinkData(old => ({ ...old, colorStyle }))}
                    />
                  </Stack>
                </Stack>

                <Stack direction='row' justifyContent='space-between' spacing={2} width={1}>
                  <Stack spacing={1} width={1}>
                    <Typography sx={{ marginTop: 0 }} variant='h4'>
                      Button shape
                    </Typography>
                    <EditLinkButtonShape
                      buttonShape={props.smartLinkData.buttonStyle}
                      updateButtonShape={(buttonStyle: string) => props.setLinkData(old => ({ ...old, buttonStyle }))}
                    />
                  </Stack>
                </Stack>

                {props.smartLinkData.colorStyle === 'color' &&
                props.smartLinkData.type === 'Biolink' &&
                <Stack direction='row' justifyContent='space-between' spacing={2} width={1}>
                  <Stack spacing={1} width={1}>
                    <Typography sx={{ marginTop: 0 }} variant='h4'>
                      Button color
                    </Typography>
                    <EditLinkButtonColor
                      buttonColor={props.smartLinkData.bioLinkButtonColor}
                      colors={props.colors}
                      handleColorChange={handleColorChangeBioLink}
                    />
                  </Stack>
                </Stack>}
              </Stack>
            </Card>
          </Stack>

        </Stack>}
      </Stack>
    </Stack>

  )
}
export default EditLinkGeneral
