import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded'
import DevicesOtherRoundedIcon from '@mui/icons-material/DevicesOtherRounded'
import MapRoundedIcon from '@mui/icons-material/MapRounded'
import PersonPinCircleRoundedIcon from '@mui/icons-material/PersonPinCircleRounded'
import { TabContext, TabList } from '@mui/lab'
import { Divider, Stack, Tab } from '@mui/material'

type Props = {
  currentTab: string
  setCurrentTab: (tab: string) => void
  overall?: boolean
  hideFans?: boolean
}

const ViewLinkAppbar = (props: Props) =>
  <Stack direction='column' justifyContent='space-between' width={1}>
    <Stack alignItems='center' direction='row' paddingTop={props.overall ? 1 : 3} paddingX={3} spacing={2}>
      <TabContext value={props.currentTab}>
        <TabList
          aria-label='Current taskboard view'
          onChange={(event, value: string) => props.setCurrentTab(value)}
          sx={{ minHeight: '32px' }}
        >
          <Tab
            icon={<AnalyticsRoundedIcon />}
            iconPosition='start'
            label='Overview'
            sx={{ minHeight: 32 }}
            value='overview'
          />
          <Tab
            icon={<MapRoundedIcon />}
            iconPosition='start'
            label='Locations'
            sx={{ minHeight: 32 }}
            value='locations'
          />
          <Tab
            icon={<DevicesOtherRoundedIcon />}
            iconPosition='start'
            label='Devices'
            sx={{ minHeight: 32 }}
            value='devices'
          />
          {!props.overall && !props.hideFans &&
            <Tab
              icon={<PersonPinCircleRoundedIcon />}
              iconPosition='start'
              label='Fans'
              sx={{ minHeight: 32 }}
              value='fans'
            />}
        </TabList>
      </TabContext>
    </Stack>
    <Divider sx={{ width: 1 }} />
  </Stack>

export default ViewLinkAppbar
