
import FlagRoundedIcon from '@mui/icons-material/FlagRounded'
import { IconButton, Menu,  MenuItem, Stack, Tooltip, Typography, useTheme } from '@mui/material'
import { useState } from 'react'

import COLOR from 'src/styles/colors'

type PriorityType = {
  label: string
  color: string
}

type Props = {
  setInputSelected: (item: 'High' | 'Low' | 'Medium' | 'None') => void
  priorityName: string
}

const PrioritySelect = (props: Props) => {
  const theme = useTheme()
  const [anchorElementMore, setAnchorElementMore] = useState<HTMLElement | null>(null)
  const openMoreMenu = Boolean(anchorElementMore)

  const handleMoreClose = () => {
    setAnchorElementMore(null)
  }

  const priorities: readonly PriorityType[] = [
    { label: 'High', color: theme.palette.error.main },
    { label: 'Medium', color: COLOR.OTHER.RATING_ACTIVE },
    { label: 'Low', color: theme.palette.primary.main },
    { label: 'None', color: COLOR.TEXT.LABEL },
  ]

  const findColor = (labelFound: string) => priorities.find(element => element.label === labelFound)

  return (
    <Stack
      alignItems='center'
      direction='column'
    >
      <Tooltip title={`Priority: ${props.priorityName}`}>
        <IconButton
          onClick={event => setAnchorElementMore(event.currentTarget)}
          sx={{
            border: `2px dotted ${findColor(props.priorityName)?.color}`,
          }}
        >
          <FlagRoundedIcon
            fontSize='medium'
            sx={{
              color: `${findColor(props.priorityName)?.color}!important`,
            }}
          />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorElementMore}
        onClick={() => handleMoreClose()}
        open={openMoreMenu}
      >
        {priorities.map(priority =>
          <MenuItem
            key={priority.label}
            onClick={() => props.setInputSelected(priority.label as 'High' | 'Low' | 'Medium' | 'None')}
            value={priority.label}
          >
            <Stack alignItems='center' direction='row' spacing={1}>
              <FlagRoundedIcon style={{ color: priority.color }} />
              <Typography color={priority.color} variant='body2'>{priority.label}</Typography>
            </Stack>
          </MenuItem>)}
      </Menu>
    </Stack>
  )
}

export default PrioritySelect
