/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function parseObjectProperties(object: any, parse: any) {
  for (const k in object) {
    if (typeof object[k] === 'object' && object[k] !== null) {
      parseObjectProperties(object[k], parse)
    } else if (object.hasOwnProperty(k)) {
      parse(k, object[k])
    }
  }
}
