import { Divider, Paper, Slide, Stack, useTheme } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useState } from 'react'

import ConversationsPanel from 'src/components/pages/Messages/ConversationsPanel/ConversationsPanel'
import InformationPanel from 'src/components/pages/Messages/InformationPanel/InformationPanel'
import MessagesPanel from 'src/components/pages/Messages/MessagesPanel/MessagesPanel'
import MessagesPanelEmpty from 'src/components/pages/Messages/MessagesPanel/MessagesPanelEmpty'
import { useMessenger } from 'src/components/providers/MessengerProvider'
import SPACING from 'src/styles/spacing'

const Messages = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))

  const { currentConversation } = useMessenger()

  const [openInformationpanel, setOpenInformationpanel] = useState(false)

  const handleInformationPanel = () => {
    setOpenInformationpanel(previous => !previous)
  }

  return <Stack
    sx={{
      paddingBottom: SPACING.RESPONSIVE_CONTOUR,
      paddingRight: SPACING.RESPONSIVE_CONTOUR,
      height: 1,
      width: 1,
    }}
  >
    <Stack
      component={Paper}
      direction='row'
      elevation={0}
      height='100%'
      overflow='hidden'
      sx={{
        borderRadius: {
          xl: 1,
          lg: 1,
          md: 1,
          sm: 0,
          xs: 0,
        },
      }}
      width='100%'
    >
      {(!matches || !currentConversation) &&
      <ConversationsPanel />}

      {!matches &&
      <Divider orientation='vertical' />}

      {currentConversation
        ? <MessagesPanel
          handleInformationPanel={handleInformationPanel}
        />
        : !matches
          ? <MessagesPanelEmpty />
          : null}

      {currentConversation &&
      <Slide direction='left' in={openInformationpanel} mountOnEnter unmountOnExit>
        <Stack direction='row'>
          <Divider orientation='vertical' />
          <InformationPanel
            handleInformationPanel={handleInformationPanel}
          />
        </Stack>
      </Slide>}

    </Stack>
  </Stack>
}

export default Messages
