import { Stack } from '@mui/material'
import _ from 'lodash'

import DevicesChart from 'src/components/pages/Links/ViewLink/ViewLinkComponents/DevicesComponents/DevicesChart'
import MarketingCloudChart from 'src/components/pages/Links/ViewLink/ViewLinkComponents/LocationsComponents/MarketingCloudChart'
import type { MarketingAnalyticsString } from 'src/models/Marketing'

type Props = {
  browserViews: MarketingAnalyticsString[]
  browserClicks: MarketingAnalyticsString[]
  osViews: MarketingAnalyticsString[]
  osClicks: MarketingAnalyticsString[]
  loading: boolean
}

const ViewLinkDevices = (props: Props) =>
  <Stack spacing={3} width={1}>
    <DevicesChart
      loading={props.loading}
      osClicks={props.osClicks}
    />
    <MarketingCloudChart
      browser
      clicks={props.browserClicks}
      loading={props.loading}
      views={props.browserViews}
    />
    <MarketingCloudChart
      clicks={props.osClicks}
      loading={props.loading}
      operatingSystem
      views={props.osViews}
    />
  </Stack>

export default ViewLinkDevices
