/* eslint-disable react-hooks/exhaustive-deps */
import { Pagination, Stack } from '@mui/material'
import { useEffect, useState } from 'react'

import { getRecordings } from 'src/api/recordings'
import Loading from 'src/components/Loading'
import RecordingsTableEmpty from 'src/components/pages/Projects/ProjectTabs/RecordingsTab/RecordingsTableEmpty'
import SplitsItem from 'src/components/pages/Projects/ProjectTabs/SplitsTab/SplitsItem'
import { useAuth } from 'src/components/providers/AuthProvider'
import type Recording from 'src/models/Recording'

type Props = {
  searchQuery: string
  statusQuery: string
  pubContract: string
  masterContract: string
  refresh: React.Dispatch<React.SetStateAction<boolean>>
}

const SplitsTable = (props: Props) => {
  const { currentOrganisation } = useAuth()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<Recording[]>([])
  const [page, setPage] = useState(1)

  const filterData = data.filter(recording =>
    recording.title.toLowerCase().includes(props.searchQuery.toLowerCase()) &&
    (recording.masterRightsHolders.every(holder => holder.status.includes(props.masterContract)) &&
    (recording.masterRightsHolders.map(item => item.ownership)
      .reduce((partialSum, a) => partialSum + a, 0) === 100 ||
      props.masterContract === '' ||
      props.masterContract === 'Unlocked')) &&
    (recording.publisherRightsHolders.every(holder => holder.status.includes(props.pubContract)) &&
    (recording.publisherRightsHolders.map(item => item.ownership)
      .reduce((partialSum, a) => partialSum + a, 0) === 100 ||
      props.pubContract === '' ||
      props.pubContract === 'Unlocked')) &&
    (recording.masterRightsHolders.some(holder =>
      holder.account.id === currentOrganisation?.id && holder.status.includes(props.statusQuery)) ||
    recording.publisherRightsHolders.some(holder =>
      holder.account.id === currentOrganisation?.id && holder.status.includes(props.statusQuery))))
    .sort((a, b) =>
      (a.masterRightsHolders.some(rightsholder =>
        rightsholder.account.id === currentOrganisation?.id)
        ? 1 : -1) >
      (b.masterRightsHolders.some(rightsholder =>
        rightsholder.account.id === currentOrganisation?.id)
        ? 1 : -1)
        ? -1 : 1)
    .sort((a, b) =>
      (a.masterRightsHolders.find(rightsholder =>
        rightsholder.account.id === currentOrganisation?.id)?.status === 'Unlocked'
        ? 1 : -1) >
      (b.masterRightsHolders.find(rightsholder =>
        rightsholder.account.id === currentOrganisation?.id)?.status === 'Unlocked'
        ? 1 : -1)
        ? -1 : 1)

  const currentPageData = filterData.slice(((page * 100) / 5) - 20, (page * 100) / 5)

  useEffect(() => {
    setPage(1)
  }, [filterData.length])

  useEffect(() => {
    setLoading(true)
    getRecordings().then(result => {
      setData(result.reverse())
    }).finally(() => setLoading(false))
  }, [currentOrganisation?.id])

  const updateCurrentDisplayData = (currentPage: number) => {
    setPage(currentPage)
  }

  return (
    <>
      {
        loading ? <Loading />
          : <Stack height={1} width={1}>
            {filterData.length > 0
              ? <Stack marginY={2} paddingBottom={2} paddingX={2} spacing={2}>
                {currentPageData.map(recording =>
                  <SplitsItem
                    key={`project-${recording.id}`}
                    recording={recording}
                    refresh={props.refresh}
                  />)}
                {
                  filterData.length > 20 &&
                    <Stack>
                      <Pagination
                        count={filterData.length > 0 ? Math.ceil(filterData.length / 20) : 1}
                        onChange={(event, value: number) => {
                          updateCurrentDisplayData(value)
                        }}
                        page={page}
                        sx={{ marginTop: 3, marginBottom: 4, marginLeft: 'auto' }}
                      />
                    </Stack>
                }

              </Stack>
              : <RecordingsTableEmpty />}
          </Stack>
      }
    </>
  )
}

export default SplitsTable
