import { Card, CardActionArea, Grid, Stack, Typography } from '@mui/material'

type Props = {
  icon: React.ReactNode
  title: string
  action: () => void
  disabled?: boolean
}

const ViewDistributionSupportItem = (props: Props) =>
  <Grid item lg={4} md={4} sm={6} xl={4} xs={6}>
    <Card
      elevation={0}
      sx={{
        width: 1,
        height: 1,
        background: theme => theme.palette.background.elevatedCard,
        opacity: props.disabled
          ? 0.7
          : 1,
      }}
    >
      <CardActionArea
        disabled={props.disabled}
        onClick={() => props.action()}
        sx={{
          height: 1,
        }}
      >
        <Stack alignItems='center' height={1} justifyContent='center' padding={2} spacing={2} width={1}>
          {props.icon}
          <Typography lineHeight={1.2} textAlign='center' variant='h3'>
            {props.title}
          </Typography>
        </Stack>
      </CardActionArea>
    </Card>
  </Grid>

export default ViewDistributionSupportItem
