import { Button, IconButton, Menu, Tooltip, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { addMark, getMarks, useEditorState } from '@udecode/plate'
import { useState } from 'react'
import type { ColorResult } from 'react-color'
import { CirclePicker } from 'react-color'

import { colorNotes } from 'src/components/pages/Notes/Modern/balloon-toolbar/ColorsNotes'

type Props = {
  itemType: string
  tooltip: string
  icon: JSX.Element
}

export const ButtonColorText = (props: Props) => {
  const editor = useEditorState()
  const [anchorElementEmbed, setAnchorElementEmbed] = useState<HTMLElement | null>(null)
  const openMenuEmbed = Boolean(anchorElementEmbed)
  return (
    <>
      <Tooltip placement='top' title={props.tooltip}>
        <IconButton
          onClick={event => {
            event.preventDefault()
            event.stopPropagation()
            setAnchorElementEmbed(event.currentTarget)
          }}
          size='small'
          sx={{
            color: theme => getMarks(editor) && getMarks(editor)?.[props.itemType]
              ? getMarks(editor)?.[props.itemType] as string
              : theme.palette.action.active,
            boxSizing: 'border-box',
            borderRadius: '8px',
          }}
        >
          {props.icon}
        </IconButton>
      </Tooltip>
      <UrlInput
        anchorEl={anchorElementEmbed}
        onChange={(value: string) => {
          addMark(editor, props.itemType, value)
        }}
        open={openMenuEmbed}
        setAnchorElementEmbed={setAnchorElementEmbed}
      />
    </>
  )
}

type UrlInputProps = {
  onChange: (value: string) => void
  anchorEl: Element | null
  setAnchorElementEmbed: React.Dispatch<React.SetStateAction<HTMLElement | null>>
  open: boolean
}

const UrlInput = (props: UrlInputProps) => {
  const handleColorChange = (color: ColorResult) => {
    props.onChange(color.hex)
    props.setAnchorElementEmbed(null)
  }
  const clearColor = () => {
    props.onChange('')
    props.setAnchorElementEmbed(null)
  }

  return (
    <Menu
      anchorEl={props.anchorEl}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      onClose={() => props.setAnchorElementEmbed(null)}
      open={props.open}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
    >
      <Stack
        alignItems='flex-start'
        direction='column'
        padding={2}
        spacing={1}
        width={500}
      >
        <Typography variant='h4'>
          Color
        </Typography>
        <Stack
          alignItems='center'
          direction='column'
          spacing={2}
          width={1}
        >
          <CirclePicker
            colors={colorNotes}
            onChangeComplete={
              handleColorChange
            }
            width='100%'
          />
          <Button
            color='inherit'
            fullWidth
            onClick={() => {
              clearColor()
            }}
            variant='text'
          >
            Clear
          </Button>
        </Stack>
      </Stack>
    </Menu>
  )
}
