/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable complexity */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable max-lines */
/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-await-in-loop */
import 'src/styles/Custom/big-calendar.css'
import 'src/styles/Custom/big-calendar-drag.css'

import AddRoundedIcon from '@mui/icons-material/AddRounded'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone'
import FlagRoundedIcon from '@mui/icons-material/FlagRounded'
import { Avatar, AvatarGroup, Button, Card, CardActionArea, Divider, IconButton, Stack, Tooltip, Typography, useTheme } from '@mui/material'
import type { SlideProps } from '@mui/material/Slide'
import Slide from '@mui/material/Slide'
import dayjs from 'dayjs'
import { forwardRef, useEffect, useState } from 'react'
import type { EventProps, Messages, NavigateAction, View, ViewsProps } from 'react-big-calendar'
import { Calendar as BigCalendar } from 'react-big-calendar'
import type { withDragAndDropProps } from 'react-big-calendar/lib/addons/dragAndDrop'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import { RRule } from 'rrule'
import { createGlobalStyle } from 'styled-components'
import { v4 } from 'uuid'

import { updateTaskItem } from 'src/api/taskboards'
import { sendTasksboardUpdate } from 'src/api/webSocket'
import NewTaskModal from 'src/components/pages/Task/TaskDrawer/NewTaskModal'
import { useAuth } from 'src/components/providers/AuthProvider'
import type Account from 'src/models/Account'
import type { TaskBoard, TaskGroup, TaskItem } from 'src/models/Taskboard'
import { TaskBoardEdit } from 'src/models/Taskboard'
import { dayjsLocalizer } from 'src/utils/dayjsLocalizer'

const localizer = dayjsLocalizer()

// @ts-expect-error Documentation asks to do this
const DragCalendar = withDragAndDrop<CalendarEvent>(BigCalendar)

const Transition = forwardRef<typeof Slide, SlideProps>((props, ref) =>
  <Slide direction='up' ref={ref} {...props} />)
Transition.displayName = 'Transition'

type Props = {
  readonly taskDataSet: TaskGroup[]
  readonly toggleTaskDrawer: (toggleState: boolean, taskGroup: TaskGroup, taskData?: TaskItem) => void
  readonly searchQuery: string
  readonly statusQuery: string
  readonly priorityQuery: string
  readonly taskboard: TaskBoard
  readonly members: Account[]
}

export type CalendarEvent = {
  board: TaskGroup
  task: TaskItem
  taskboard: TaskBoard
  title: string
  startDate: Date | undefined
  dueDate: Date | undefined
  allDay: boolean
  members: Account[]
  nextDate: Date | undefined
  previousDate: Date | undefined
}

type CustomHeaderProps = {
  date: Date
  drilldownView: string
  isOffRange: boolean
  label: string
  onDrillDown: () => void
}

type ToolbarProps = {
  date: Date
  view: View
  views: ViewsProps<CalendarEvent>
  label: string
  localizer: { messages: Messages }
  onNavigate: (navigate: NavigateAction, date?: Date) => void
  onView: (view: View) => void
  children?: React.ReactNode | undefined
}

export const ToolbarComponent = (event: ToolbarProps) => {
  const themeItem = useTheme()
  return (
    <Stack
      alignItems='center'
      direction='row'
      padding={2}
      position='sticky'
      spacing={2}
      sx={{
        background: themeItem.palette.background.default,
        borderBottom: `1px ${themeItem.palette.divider} solid`,
        zIndex: 999,
      }}
      top={0}
      width={1}
    >
      <Button
        color='inherit'
        onClick={() => event.onNavigate('TODAY')}
        size='small'
        sx={{
          color: theme => theme.palette.text.primary,
          border: theme => `1px solid ${theme.palette.action.active}!important`,
        }}
        variant='outlined'
      >
        Today
      </Button>
      <Stack
        alignItems='center'
        direction='row'
        spacing={1}
      >
        <Tooltip title='Previous month'>
          <IconButton
            onClick={() => event.onNavigate('PREV')}
            size='small'
            sx={{
              borderRadius: '8px',
            }}
          >
            <ArrowBackIosNewRoundedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title='Next month'>
          <IconButton
            onClick={() => event.onNavigate('NEXT')}
            size='small'
            sx={{
              borderRadius: '8px',
            }}
          >
            <ArrowForwardIosRoundedIcon />
          </IconButton>
        </Tooltip>
      </Stack>
      <Typography
        color={themeItem.palette.text.primary}
        sx={{
          fontWeight: 400,
        }}
        variant='h4'
      >
        {event.label}
      </Typography>
    </Stack>
  )
}

export const HeaderTest = (event: CustomHeaderProps, taskboard?: TaskBoard) => {
  const themeItem = useTheme()
  const [newTaskModal, setNewTaskModalOpen] = useState(false)
  const isCurrentDate = event.date.getDate() === new Date().getDate() &&
  event.date.getMonth() === new Date().getMonth() &&
  event.date.getFullYear() === new Date().getFullYear()
  return (
    <>
      <Stack
        alignItems='center'
        direction='row'
        spacing={1}
        sx={{
          '.MuiButtonBase-root': {
            opacity: 0,
          },
          ':hover .MuiButtonBase-root': {
            opacity: event.isOffRange
              ? 0
              : 1,
          },
        }}
        width={1}
      >
        <Typography
          color={event.isOffRange
            ? themeItem.palette.text.disabled
            : isCurrentDate
              ? themeItem.palette.primary.contrastText
              : themeItem.palette.text.primary}
          sx={{
            flexShrink: 0,
            padding: '8px',
            borderRadius: '8px',
            background: theme => !isCurrentDate
              ? 'transparent'
              : theme.palette.primary.main,
          }}
          variant='body1'
        >
          {event.label}
        </Typography>
        <Button
          color='inherit'
          disabled={event.isOffRange}
          onClick={() => setNewTaskModalOpen(true)}
          size='small'
          startIcon={<AddRoundedIcon />}
          sx={{
            color: theme => theme.palette.text.label,
            transition: 'opacity 0.1s linear',
          }}
          variant='text'
        >
          Task
        </Button>
      </Stack>
      {newTaskModal &&
      <NewTaskModal
        close={() => setNewTaskModalOpen(false)}
        date={new Date(event.date)}
        open={newTaskModal}
        taskboard={taskboard}
      />}
    </>
  )
}

export const CustomEvent = (event: EventProps<CalendarEvent>) => {
  const { currentAccount } = useAuth()
  const [assignee, setAsignee] = useState<Account[]>([])
  const [status, setTaskStatus] = useState(event.event.task.status)

  const [lastDone, setLastDone] = useState(event.event.task.lastDoneOn)

  const previousDate = event.event.previousDate

  const isTaskLocked =
    (event.event.task.recurring === 'Weekly' ||
    event.event.task.recurring === 'Monthly') &&
    (event.event.previousDate && new Date(event.event.previousDate).getTime() > Date.now())

  useEffect(() => {
    if (event.event.task.recurring === 'Weekly' || event.event.task.recurring === 'Monthly') {
      if (previousDate && lastDone && new Date(lastDone).getTime() < new Date(previousDate).getTime()) {
        setTaskStatus('Not started')
      }
      if (lastDone && !previousDate) {
        setTaskStatus('Done')
      }
      if (!lastDone) {
        setTaskStatus('Not started')
      }
    }
  }, [lastDone, event.event.task.lastDoneOn, event.event.task.recurring])

  useEffect(() => {
    if (event.event.task.status !== status &&
      event.event.task.recurring !== 'Weekly' &&
      event.event.task.recurring !== 'Monthly') {
      setTaskStatus(event.event.task.status)
    }
  }, [event.event.task.status])

  useEffect(() => {
    const getAssignees = () => {
      setAsignee([])
      for (const assignees of event.event.task.assignee) {
        const temporaryItem = event.event.members.find(item => item.id === assignees)
        if (temporaryItem) {
          setAsignee(previousAssignees => [...previousAssignees, temporaryItem])
        }
      }
    }
    getAssignees()
  }, [event.event.task.assignee])

  const handleChange = async (eventItem: React.MouseEvent<HTMLButtonElement>,
    newStatus: 'Done' | 'In progress' | 'Not approved' | 'Not started') => {
    eventItem.stopPropagation()
    eventItem.preventDefault()
    const oldStatusItem = Object.assign({}, { ...event.event.task })
    setLastDone(new Date())
    setTaskStatus(newStatus)

    if (event.event.taskboard.id) {
      await updateTaskItem(event.event.taskboard.id, event.event.board.id,
        {
          ...event.event.task,
          status: newStatus,
          lastDoneOn: new Date(),
        })
        .then(() => {
          const taskUpdateItem = new TaskBoardEdit({
            id: v4(),
            taskboardId: event.event.taskboard.id,
            taskId: event.event.task.id,
            accountId: currentAccount.id,
            modificationType: 'Task details',
            itemType: 'Field',
            title: `${event.event.task.title}`,
            fields: [{
              fieldName: 'Status',
              oldValue: oldStatusItem.status,
              newValue: newStatus,
            }],
          })
          sendTasksboardUpdate(taskUpdateItem)
        })
    }
  }

  const background = event.event.board.color

  return (
    <Card
      sx={{
        width: 1,
        borderRadius: '4px',
      }}
      variant='outlined'
    >
      <CardActionArea>
        <Stack
          direction='column'
          width={1}
        >
          <Stack
            height={8}
            sx={{
              bgcolor: background,
            }}
          />
          <Stack
            direction='column'
            spacing={0.5}
            sx={{
              padding: 1,
            }}
            width={1}
          >
            <Stack
              alignItems='center'
              direction='row'
              justifyContent='space-between'
              width={1}
            >
              {isTaskLocked && event.event.previousDate &&
              <Tooltip
                title={`This task will unlock on ${dayjs(new Date(event.event.previousDate)).format('LL')}`}
              >
                <div>
                  <IconButton
                    aria-label='Complete'
                    disabled
                    size='small'
                    sx={{
                      color: theme => theme.palette.text.label,
                      padding: 0,
                    }}
                  >
                    <CircleTwoToneIcon
                      sx={{
                        color: theme => theme.palette.text.label,
                        pointerEvents: 'none',
                        opacity: 0.5,
                      }}
                    />
                  </IconButton>
                </div>
              </Tooltip>}

              {(event.event.task.priority === 'None' && status !== 'Done') && !isTaskLocked &&
              <IconButton
                aria-label='Complete'
                onClick={eventItem => handleChange(eventItem, 'Done')}
                size='small'
                sx={{
                  color: theme => theme.palette.text.label,
                  padding: 0,
                }}
              >
                <CircleTwoToneIcon
                  sx={{
                    color: theme => theme.palette.text.label,
                    pointerEvents: 'none',
                  }}
                />
              </IconButton>}
              {(event.event.task.priority === 'Low' && status !== 'Done') && !isTaskLocked &&
              <IconButton
                aria-label='Complete'
                color='primary'
                onClick={eventItem => handleChange(eventItem, 'Done')}
                size='small'
                sx={{
                  padding: 0,
                }}
              >
                <CircleTwoToneIcon
                  color='primary'
                  sx={{
                    pointerEvents: 'none',
                  }}
                />
              </IconButton>}
              {(event.event.task.priority === 'Medium' && status !== 'Done') && !isTaskLocked &&
              <IconButton
                aria-label='Complete'
                onClick={eventItem => handleChange(eventItem, 'Done')}
                size='small'
                sx={{
                  color: theme => theme.palette.rating,
                  padding: 0,
                }}
              >
                <CircleTwoToneIcon
                  sx={{
                    color: theme => theme.palette.rating,
                    pointerEvents: 'none',
                  }}
                />
              </IconButton>}
              {(event.event.task.priority === 'High' && status !== 'Done') && !isTaskLocked &&
              <IconButton
                aria-label='Complete'
                color='error'
                onClick={eventItem => handleChange(eventItem, 'Done')}
                size='small'
                sx={{
                  padding: 0,
                }}
              >
                <CircleTwoToneIcon
                  color='error'
                  sx={{
                    pointerEvents: 'none',
                  }}
                />
              </IconButton>}
              {status === 'Done' &&
            (event.event.task.recurring === 'Weekly' || event.event.task.recurring === 'Monthly') &&
            <Tooltip title='This recurring task is completed and locked'>
              <div>
                <IconButton
                  aria-label='Complete'
                  color='success'
                  disabled
                  onClick={eventItem => handleChange(eventItem, 'Not started')}
                  size='small'
                  sx={{
                    padding: 0,
                  }}
                >
                  <CheckCircleRoundedIcon
                    color='success'
                    sx={{
                      pointerEvents: 'none',
                      opacity: 0.5,
                    }}
                  />
                </IconButton>
              </div>
            </Tooltip>}
              {status === 'Done' &&
            event.event.task.recurring !== 'Weekly' &&
            event.event.task.recurring !== 'Monthly' &&
            <IconButton
              aria-label='Complete'
              color='success'
              onClick={eventItem => handleChange(eventItem, 'Not started')}
              size='small'
              sx={{
                padding: 0,
              }}
            >
              <CheckCircleRoundedIcon
                color='success'
                sx={{
                  pointerEvents: 'none',
                }}
              />
            </IconButton>}

              <Stack alignItems='center' direction='row' spacing={0.5}>
                {event.event.task.priority === 'None' &&
                <FlagRoundedIcon
                  fontSize='medium'
                  sx={{
                    color: theme => theme.palette.text.label,
                    pointerEvents: 'none',
                  }}
                />}
                {event.event.task.priority === 'Low' &&
                <FlagRoundedIcon
                  color='primary'
                  fontSize='medium'
                  sx={{
                    pointerEvents: 'none',
                  }}
                />}

                {event.event.task.priority === 'Medium' &&
                <FlagRoundedIcon
                  fontSize='medium'
                  style={{
                    color: '#FBBF24',
                    pointerEvents: 'none',
                  }}
                />}

                {event.event.task.priority === 'High' &&
                <FlagRoundedIcon
                  fontSize='medium'
                  sx={{
                    color: theme => theme.palette.error.main,
                    pointerEvents: 'none',
                  }}
                />}

                {assignee.length > 0 &&
                <AvatarGroup>
                  {assignee.map(assigneeItem =>
                    <Avatar
                      key={assigneeItem.id}
                      src={assigneeItem.profilePictureUrl}
                      sx={{
                        width: 20,
                        height: 20,
                        pointerEvents: 'none',
                        '.MuiSvgIcon-root': {
                          pointerEvents: 'none',
                        },
                      }}
                    />)}
                </AvatarGroup>}
              </Stack>
            </Stack>

            <Typography
              sx={{
                WebkitLineClamp: 2,
                paddingLeft: 0.5,
                WebkitBoxOrient: 'vertical',
                display: '-webkit-box',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '100%',
                fontSize: '1em',
                fontWeight: '400',
              }}
              variant='body2'
              whiteSpace='normal'
            >
              {event.event.task.title}
            </Typography>
          </Stack>
        </Stack>
      </CardActionArea>
    </Card>
  )
}

export const getDayItem = (item: number) => {
  switch (item) {
    case 0:
      return RRule.SU
    case 1:
      return RRule.MO
    case 2:
      return RRule.TU
    case 3:
      return RRule.WE
    case 4:
      return RRule.TH
    case 5:
      return RRule.FR
    case 6:
      return RRule.SA
    default:
      return RRule.SA
  }
}

const Calendar = (props: Props) => {
  const { currentAccount } = useAuth()
  const themeItem = useTheme()
  const [events, setEvents] = useState(props.taskboard.taskGroups.flatMap(item =>
    item.tasks.filter(task =>
      task.title.toLowerCase().includes(props.searchQuery.toLowerCase()) &&
      task.status.toLowerCase().includes(props.statusQuery.toLowerCase()) &&
      task.priority.toLowerCase().includes(props.priorityQuery.toLowerCase()))
      .flatMap(task => {
        if (task.recurring === 'Monthly') {
          const dateItemTest = new Date(task.startDate ?? 0).getDay()
          const eventsRules = new RRule({
            freq: RRule.WEEKLY,
            byweekday: getDayItem(dateItemTest),
            dtstart: new Date(task.startDate ?? 0),
            interval: 4,
            until: new Date('January 11, 2025'),
          })
          const times = eventsRules.all()
          return times.map((time, index) => ({
            board: item,
            task,
            title: task.title,
            startDate: new Date(time.toString()),
            dueDate: new Date(time.toString()),
            allDay: task.allDay,
            taskboard: props.taskboard,
            members: props.members,
            nextDate: index < times.length - 1 ? times[index + 1] : undefined,
            previousDate: index > 0 ? times[index - 1] : undefined,
          }))
        } else if (task.recurring === 'Weekly') {
          const dateItemTest = new Date(task.startDate ?? 0).getDay()
          const eventsRules = new RRule({
            freq: RRule.WEEKLY,
            byweekday: getDayItem(dateItemTest),
            dtstart: new Date(task.startDate ?? 0),
            interval: 1,
            until: new Date('January 11, 2025'),
          })
          const times = eventsRules.all()
          return times.map((time, index) => ({
            board: item,
            task,
            title: task.title,
            startDate: new Date(time.toString()),
            dueDate: new Date(time.toString()),
            allDay: task.allDay,
            taskboard: props.taskboard,
            members: props.members,
            nextDate: index < times.length - 1 ? times[index + 1] : undefined,
            previousDate: index > 0 ? times[index - 1] : undefined,
          }))
        } else {
          return {
            board: item,
            task,
            title: task.title,
            startDate: new Date(task.startDate ?? 0),
            dueDate: new Date(task.dueDate ?? 0),
            allDay: task.allDay,
            taskboard: props.taskboard,
            members: props.members,
          }
        }
      })) as CalendarEvent[])

  useEffect(() => {
    setEvents(props.taskboard.taskGroups.flatMap(item =>
      item.tasks.filter(task =>
        task.title.toLowerCase().includes(props.searchQuery.toLowerCase()) &&
        task.status.toLowerCase().includes(props.statusQuery.toLowerCase()) &&
        task.priority.toLowerCase().includes(props.priorityQuery.toLowerCase()))
        .flatMap(task => {
          if (task.recurring === 'Monthly') {
            const dateItemTest = new Date(task.startDate ?? 0).getDay()
            const eventsRules = new RRule({
              freq: RRule.WEEKLY,
              dtstart: new Date(task.startDate ?? 0),
              interval: 4,
              byweekday: getDayItem(dateItemTest),
              until: new Date('January 11, 2025'),
            })
            const times = eventsRules.all()
            return times.map((time, index) => ({
              board: item,
              task,
              title: task.title,
              startDate: new Date(time.toString()),
              dueDate: new Date(time.toString()),
              allDay: task.allDay,
              taskboard: props.taskboard,
              members: props.members,
              nextDate: index < times.length - 1 ? times[index + 1] : undefined,
              previousDate: index > 0 ? times[index - 1] : undefined,
            }))
          } else if (task.recurring === 'Weekly') {
            const dateItemTest = new Date(task.startDate ?? 0).getDay()
            const eventsRules = new RRule({
              freq: RRule.WEEKLY,
              dtstart: new Date(task.startDate ?? 0),
              byweekday: getDayItem(dateItemTest),
              interval: 1,
              until: new Date('January 11, 2025'),
            })
            const times = eventsRules.all()
            return times.map((time, index) => ({
              board: item,
              task,
              title: task.title,
              startDate: new Date(time.toString()),
              dueDate: new Date(time.toString()),
              allDay: task.allDay,
              taskboard: props.taskboard,
              members: props.members,
              nextDate: index < times.length - 1 ? times[index + 1] : undefined,
              previousDate: index > 0 ? times[index - 1] : undefined,
            }))
          } else {
            return {
              board: item,
              task,
              title: task.title,
              startDate: new Date(task.startDate ?? 0),
              dueDate: new Date(task.dueDate ?? 0),
              allDay: task.allDay,
              taskboard: props.taskboard,
              members: props.members,
            }
          }
        })) as CalendarEvent[])
  }, [props.taskboard.taskGroups])

  const onEventDrop: withDragAndDropProps<CalendarEvent>['onEventDrop'] = async data => {
    setEvents(previousState =>
      previousState.map(item => item.task.id === data.event.task.id
        ? {
          ...item,
          task: {
            ...item.task,
            startDate: new Date(data.start),
            dueDate: new Date(data.event.task.allDay ? data.end : data.start),
          },
          startDate: new Date(data.start),
          dueDate: new Date(data.event.task.allDay ? data.end : data.start),
        }
        : item))
    const oldStatusItem = Object.assign({}, { ...data.event.task })
    if (props.taskboard.id) {
      await updateTaskItem(props.taskboard.id,
        data.event.board.id,
        {
          ...data.event.task,
          startDate: new Date(data.start),
          dueDate: new Date(data.event.task.allDay ? data.end : data.start),
        })
        .then(() => {
          const taskUpdateItem = new TaskBoardEdit({
            id: v4(),
            taskboardId: props.taskboard.id,
            taskId: data.event.task.id,
            accountId: currentAccount.id,
            modificationType: 'Task details',
            itemType: 'Field',
            title: `${data.event.task.title}`,
            fields: [{
              fieldName: 'Start Date',
              oldValue: dayjs(new Date(oldStatusItem.startDate ?? 0)).format('ll'),
              newValue: dayjs(new Date(data.start)).format('ll'),
            }],
          })
          sendTasksboardUpdate(taskUpdateItem)
        })
    }
  }

  const onEventResize: withDragAndDropProps<CalendarEvent>['onEventResize'] = async data => {
    const oldStatusItem = Object.assign({}, { ...data.event.task })
    setEvents(previousState =>
      previousState.map(item => item.task.id === data.event.task.id
        ? {
          ...item,
          task: {
            ...item.task,
            startDate: new Date(data.start),
            dueDate: new Date(data.end),
            allDay: new Date(data.start).getDate() !== new Date(data.end).getDate(),
          },
          startDate: new Date(data.start),
          dueDate: new Date(data.end),
          allDay: new Date(data.start).getDate() !== new Date(data.end).getDate(),
        }
        : item))

    if (props.taskboard.id) {
      await updateTaskItem(props.taskboard.id,
        data.event.board.id,
        { ...data.event.task,
          startDate: new Date(data.start),
          dueDate: new Date(data.end),
          allDay: new Date(data.start).getDate() !== new Date(data.end).getDate(),
        })
        .then(() => {
          const taskUpdateItem = new TaskBoardEdit({
            id: v4(),
            taskboardId: props.taskboard.id,
            taskId: data.event.task.id,
            accountId: currentAccount.id,
            modificationType: 'Task details',
            itemType: 'Field',
            title: `${data.event.task.title}`,
            fields: [{
              fieldName: 'Start Date',
              oldValue: dayjs(new Date(oldStatusItem.startDate ?? 0)).format('ll'),
              newValue: dayjs(new Date(data.start)).format('ll'),
            }],
          })
          sendTasksboardUpdate(taskUpdateItem)
        })
    }
  }

  const GlobalStyles = createGlobalStyle`
  html {
    .rbc-month-row>.rbc-row-bg>.rbc-day-bg {
      border-right: 1px solid ${themeItem.palette.border}!important
    }
    .rbc-month-row+.rbc-month-row {
      border-top: 1px solid ${themeItem.palette.border}!important
    }
    .rbc-off-range-bg {
      background: ${themeItem.palette.border}!important
    }
    .rbc-header {
      border-bottom: 1px solid ${themeItem.palette.border}!important
    }
  }
  `

  return (
    <Stack height={1} width={1}>
      <GlobalStyles />
      <Divider />
      <Stack height={1} overflow='auto' width={1}>
        <Stack minHeight={1000}>
          <DragCalendar
            allDayAccessor='allDay'
            components={{
              event: CustomEvent,
              month: {
                dateHeader: propsHeader => HeaderTest(propsHeader, props.taskboard),
              },
              toolbar: ToolbarComponent,
            }}
            endAccessor='dueDate'
            events={events}
            localizer={localizer}
            onEventDrop={onEventDrop}
            onEventResize={onEventResize}
            onNavigate={() => null}
            onSelectEvent={(event: CalendarEvent) =>
              props.toggleTaskDrawer(
                true,
                props.taskDataSet[props.taskDataSet.findIndex(group =>
                  group.tasks.find(task => task.id === event.task.id))],
                props.taskDataSet[props.taskDataSet.findIndex(group =>
                  group.tasks.find(task => task.id === event.task.id))].tasks.find(item => item.id === event.task.id)
              )}
            resizable
            showAllEvents
            showMultiDayTimes
            startAccessor='startDate'
            style={{ height: '100%', minWidth: '750px' }}
            titleAccessor='title'
            tooltipAccessor='title'
            views={{ month: true }}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}
export default Calendar
