/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable switch-case/no-case-curly */
/* eslint-disable max-lines */
import ComputerRoundedIcon from '@mui/icons-material/ComputerRounded'
import PublicRoundedIcon from '@mui/icons-material/PublicRounded'
import { Avatar, ListItemText, Skeleton, Stack, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import { visuallyHidden } from '@mui/utils'
import countries from 'i18n-iso-countries'
import type { Key } from 'react'
import { useState } from 'react'

import { operatingSystems } from 'src/data/osData'
import type { MarketingAnalyticsString } from 'src/models/Marketing'

type Data = {
  _id: string
  viewCount: number
  clickCount: number
  ratio: number
  country: string | undefined
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

type Order = 'asc' | 'desc'

const getComparator = (
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
  ) => number => order === 'desc'
  ? (a, b) => descendingComparator(a, b, orderBy)
  : (a, b) => -descendingComparator(a, b, orderBy)

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((element, index) => [element, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map(element => element[0])
}

type HeadCell = {
  disablePadding: boolean
  id: keyof Data
  label: string
  numeric: boolean
}

const headCells: readonly HeadCell[] = [
  {
    id: '_id',
    numeric: true,
    disablePadding: false,
    label: 'Location',
  },
  {
    id: 'clickCount',
    numeric: true,
    disablePadding: false,
    label: 'Clicks',
  },
  {
    id: 'viewCount',
    numeric: true,
    disablePadding: false,
    label: 'Views',
  },
  {
    id: 'ratio',
    numeric: true,
    disablePadding: false,
    label: 'Conversion Ratio',
  },
]

type EnhancedTableProps = {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void
  order: Order
  orderBy: string
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { order, orderBy,  onRequestSort } =
    props
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  return (
    <TableHead
      sx={{
        '.MuiTableCell-root': {
          borderBottom: 2,
          borderBottomColor: 'divider',
          color: 'text.secondary',
        },
      }}
    >
      <TableRow>
        {headCells.map((headCell, index) =>
          <TableCell
            align='left'
            key={headCell.id}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              position: index === 0 ? 'sticky' : undefined,
              left: index === 0 ? 0 : undefined,
              zIndex: index === 0 ? 999 : undefined,
              background: theme => theme.palette.background.default,
              borderRight: theme => index === 0 ? `1px solid ${theme.palette.divider}` : 'none',
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id
                ? <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
                : null}
            </TableSortLabel>
          </TableCell>)}
      </TableRow>
    </TableHead>
  )
}

type Props = {
  clicks: MarketingAnalyticsString[]
  views: MarketingAnalyticsString[]
  country?: boolean
  city?: boolean
  sources?: boolean
  browser?: boolean
  operatingSystem?: boolean
  loading: boolean
}

const MarketingCloudChart = (props: Props) => {
  const clicks = props.clicks
    .map(({ _id, count, country }) =>  ({ _id, clickCount: count, country: country ?? '' }))
  const views = props.views
    .map(({ _id, count, country }) =>  ({ _id, viewCount: count, country: country ?? '' }))

  const rows =
  views.map(itm => ({
    clickCount: clicks.find(item => item._id === itm._id)?.clickCount ?? 0,
    ratio: clicks.some(item => item._id === itm._id)
      ? (clicks.find(item => item._id === itm._id)?.clickCount ?? 0) / itm.viewCount * 100
      : 0,
    ...itm,
  }))

  const [order, setOrder] = useState<Order>('desc')
  const [orderBy, setOrderBy] = useState<keyof Data>('viewCount')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number.parseInt(event.target.value, 10))
    setPage(0)
  }

  const replaceString = (value: string) =>
    value.replace('www.', '')
      .replace('https://', '')
      .replace('http://', '')
      .split('/')[0]
      .split('?')[0]

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

  return (
    <Box width={1}>
      <Stack sx={{ width: '100%', mb: 2 }}>
        <Typography variant='h3'>
          {props.country && 'Top Countries'}
          {props.city && 'Top Cities'}
          {props.sources && 'Top Sources'}
          {props.browser && 'Top Browsers'}
          {props.operatingSystem && 'Top Operating Systems'}
        </Typography>
        {props.loading
          ? <Skeleton
            height={500}
            sx={{
              transform: 'none',
            }}
            width='100%'
          />
          : <>
            <TableContainer>
              <Table
                aria-labelledby='tableTitle'
                size='medium'
                sx={{
                  minWidth: 750,
                  tableLayout: 'fixed',
                  borderCollapse: 'separate',
                }}
              >
                <EnhancedTableHead
                  onRequestSort={handleRequestSort}
                  order={order}
                  orderBy={orderBy}
                />
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`
                      return (
                        <TableRow
                          hover
                          key={row._id}
                          onClick={() => null}
                          role='checkbox'
                          sx={{
                            '.MuiTableCell-root': {
                              borderBottomColor: 'divider',
                              paddingY: 2,
                            },
                            ':hover': {
                              '.MuiTableCell-root': {
                                background: theme => `${theme.palette.tableHoverBg}!important`,
                              },
                            },
                          }}
                          tabIndex={-1}
                        >
                          <TableCell
                            component='th'
                            id={labelId}
                            scope='row'
                            sx={{
                              position: 'sticky',
                              background: theme => theme.palette.background.default,
                              borderRight: theme => `1px solid ${theme.palette.divider}`,
                              left: 0,
                              zIndex: 999,
                            }}
                            width={400}
                          >
                            <Stack alignItems='center' direction='row' spacing={2}>
                              {props.country &&
                              <Avatar
                                src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/
${countries.getAlpha2Code(row._id, 'en')}.svg`}
                                sx={{
                                  background: theme => theme.palette.info.main,
                                  height: 23,
                                  width: 35,
                                  borderRadius: '4px',
                                }}
                                variant='rounded'
                              >
                                <PublicRoundedIcon />
                              </Avatar>}

                              {props.city &&
                              <Avatar
                                src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/
${countries.getAlpha2Code(row.country, 'en')}.svg`}
                                sx={{
                                  background: theme => theme.palette.info.main,
                                  height: 23,
                                  width: 35,
                                  borderRadius: '4px',
                                }}
                                variant='rounded'
                              >
                                <PublicRoundedIcon />
                              </Avatar>}

                              {props.sources &&
                              <img
                                alt='Website Icon'
                                height='23px'
                                src={`https://www.google.com/s2/favicons?domain=${replaceString(row._id)}&sz=256`}
                                style={{
                                  marginRight: '8px',
                                }}
                                width='23px'
                              />}

                              {props.operatingSystem &&
                              <Avatar
                                alt='Website Icon'
                                // eslint-disable-next-line max-len
                                src={`https://cdn.releese.io/ospreview/${operatingSystems.find(item => item.name === row._id)?.code}.png`}
                                sx={{
                                  marginRight: '8px',
                                  width: 23,
                                  height: 23,
                                  borderRadius: '4px',
                                }}
                              >
                                <ComputerRoundedIcon />
                              </Avatar>}

                              {props.browser &&
                              <Avatar
                                alt='Website Icon'
                                // eslint-disable-next-line max-len
                                src={`https://cdn.releese.io/browserpreview/${row._id.toLowerCase().replaceAll(' ', '-')}/${row._id.toLowerCase().replaceAll(' ', '-')}.svg`}
                                sx={{
                                  marginRight: '8px',
                                  width: 23,
                                  height: 23,
                                  borderRadius: '4px',
                                }}
                              >
                                <ComputerRoundedIcon />
                              </Avatar>}

                              <ListItemText
                                primary={row._id && row._id.length > 0
                                  ? row._id
                                  : 'Not found'}
                                secondary={row.country}
                              />
                            </Stack>
                          </TableCell>
                          <TableCell align='left'>{new Intl.NumberFormat('en-US').format(row.clickCount)}</TableCell>
                          <TableCell align='left'>{new Intl.NumberFormat('en-US').format(row.viewCount)}</TableCell>
                          <TableCell align='left'>{`${row.ratio.toFixed(0)}%`}</TableCell>
                        </TableRow>
                      )
                    })}
                  {emptyRows > 0 &&
                  <TableRow
                    style={{
                      height: 33 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component='div'
              count={rows.length}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[10, 25, 50]}
            />
          </>}
      </Stack>
    </Box>
  )
}

export default MarketingCloudChart
