import type { Serialized } from 'src/types/react-app-env'

export const MESSAGE_TYPE = ['Text', 'Image'] as const
export const MESSAGE_STATUS = ['SENT', 'SENDING', 'ERROR', 'READ'] as const

export class Message {
  id?: string
  readByAccountIds: string[]
  createdAt = new Date()
  updatedAt = new Date()
  senderId = ''
  conversationId: string
  contentType: typeof MESSAGE_TYPE[number] = MESSAGE_TYPE[0]
  status: typeof MESSAGE_STATUS[number] = MESSAGE_STATUS[1]
  content = ''

  constructor(dto: MessageDto) {
    Object.assign(this, dto)
    this.id = dto.id ?? ''
    this.readByAccountIds = dto.readByAccountIds ?? []
    this.createdAt = new Date(dto.createdAt ?? '')
    this.updatedAt = new Date(dto.updatedAt ?? '')
    this.conversationId = dto.conversationId ?? ''
    this.contentType = dto.contentType ?? MESSAGE_TYPE[0]
    this.status = dto.status ?? MESSAGE_STATUS[1]
  }
}
export type MessageDto = Partial<Serialized<Message>>
