/* eslint-disable react/jsx-key */
/* eslint-disable sonarjs/no-small-switch */
/* eslint-disable react-hooks/exhaustive-deps */
import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded'
import { Grid, Paper, Skeleton, Stack, Typography, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'

import { getAnalytics, getAnalyticsCurrent } from 'src/api/analyticsCloud'
import AnalyticsDistributionAppBar from 'src/components/pages/Analytics/AnalyticsDistribution/AnalyticsDistributionAppbar'
import AnalyticsPlatformDistribution from 'src/components/pages/Audience/Components/Overview/OverviewPlatform'
import type { AnalyticsCurrentData, AnalyticsHistoricData } from 'src/models/Analytics'

type Props = {
  selectedRecording: string
  artwork: string
}

const ProjectAnalyticsTab = (props: Props) => {
  const selectedRecording = props.selectedRecording
  const [loading, setLoading] = useState(false)
  const [loadingHistoric, setLoadingHistoric] = useState(false)
  const [currentTab, setCurrentTab] = useState('spotify')
  const theme = useTheme()
  const [historicData, setHistoricData] = useState<AnalyticsHistoricData>()
  const [currentData, setCurrentData] = useState<AnalyticsCurrentData>()

  useEffect(() => {
    if (selectedRecording && selectedRecording.length > 0) {
      getData()
    }
  }, [selectedRecording])

  const getData = () => {
    setCurrentTab('spotify')
    setLoading(true)
    setLoadingHistoric(true)
    if (selectedRecording && selectedRecording.length > 0) {
      void getAnalytics(selectedRecording)
        .then(result =>
          setHistoricData(result))
        .finally(() => setLoading(false))
        .catch(() => setLoading(false))

      void getAnalyticsCurrent(selectedRecording)
        .then(resultCurrent =>
          setCurrentData(resultCurrent))
        .finally(() => setLoadingHistoric(false))
        .catch(() => setLoadingHistoric(false))
    }
  }

  return (
    <Stack direction='row' height={1}>
      <Stack
        alignItems='stretch'
        boxSizing='border-box'
        direction='column'
        height={1}
        minWidth={0}
        padding={0}
        width={1}
      >
        {selectedRecording.length > 0 &&
        <AnalyticsDistributionAppBar
          currentData={currentData}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />}
        <Stack alignItems='center' height={1} overflow='auto' width={1}>
          {!loading && selectedRecording.length === 0 && !loadingHistoric
            ? <Stack
              alignItems='center'
              height={1}
              justifyContent='center'
              marginLeft='auto'
              marginRight='auto'
              maxWidth={1332}
              width={1}
            >
              <Paper
                elevation={0}
                sx={{
                  backgroundColor: theme.palette.info.componentBackground,
                  borderRadius: 16,
                }}
              >
                <Stack
                  alignItems='center'
                  justifyContent='center'
                  padding={4}
                >
                  <AnalyticsRoundedIcon
                    sx={{
                      color: theme.palette.info.main,
                      fontSize: '144px',
                    }}
                  />
                </Stack>
              </Paper>
              <Typography
                color={theme.palette.text.secondary}
                sx={{
                  marginTop: 1,
                }}
                variant='h4'
              >
                No Recording Selected
              </Typography>
              <Typography
                color={theme.palette.text.label}
                sx={{
                  textAlign: 'center',
                }}
                variant='body1'
              >
                Select a Recording to track Playlists, Charts, UGC and Streams
              </Typography>
            </Stack>
            : <Stack
              marginLeft='auto'
              marginRight='auto'
              maxWidth={1332}
              padding={3}
              paddingBottom={10}
              width={1}
            >
              {selectedRecording.length > 0 && !loading && !loadingHistoric &&
              <AnalyticsPlatformDistribution
                artworkUrl={props.artwork}
                currentData={currentData}
                currentTab={currentTab}
                historicData={historicData}
                loadingActivities={false}
              />}

              {(loading || loadingHistoric) &&
              <Stack spacing={3} width={1}>
                <Typography variant='h2'>
                  <Skeleton />
                </Typography>
                <Grid container spacing={2} sx={{ marginLeft: '-16px!important' }}>
                  {Array.from({ length: 8 }, () =>
                    <Grid item lg={6} md={6} sm={6} xl={3} xs={12}>
                      <Skeleton variant='rounded' width='100%'>
                        <div style={{ paddingTop: '57%' }} />
                      </Skeleton>
                    </Grid>)}
                </Grid>
                <Skeleton height={400} variant='rounded' width='100%'>
                  <div />
                </Skeleton>
              </Stack>}
            </Stack>}
        </Stack>
      </Stack>
    </Stack>
  )
}

export default ProjectAnalyticsTab
