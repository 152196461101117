/* eslint-disable react-hooks/exhaustive-deps */
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { LoadingButton } from '@mui/lab'
import { AppBar, Autocomplete, Avatar, Dialog, IconButton, Stack, TextField, Toolbar, Typography, useTheme } from '@mui/material'
import { useState } from 'react'

import { applyForBeatport } from 'src/api/distribution'
import BillingModal from 'src/components/pages/Account/Billing/BillingModal'
import { useAuth } from 'src/components/providers/AuthProvider'
import { BEATPORT_GENRES } from 'src/models/Recording'

type Props = {
  close: () => void
  open: boolean
}

const BeatportApplicationModal = (props: Props) => {
  const themeItem = useTheme()
  const { currentOrganisation, updateCurrentOrganisation } = useAuth()

  const [loading, setLoading] = useState(false)
  const [beatportName, setBeatportName] = useState(currentOrganisation?.name ?? '')
  const [beatportGenre, setBeatportGenre] = useState('')
  const [schedule, setSchedule] = useState('')
  const [releases, setReleases] = useState('')
  const [social, setSocial] = useState('')
  const [artists, setArtists] = useState('')

  const submit = async () => {
    setLoading(true)
    await applyForBeatport(currentOrganisation?.id ?? '',
      beatportName,
      beatportGenre,
      schedule,
      releases,
      social,
      artists).then(async () => {
      await updateCurrentOrganisation({ ...currentOrganisation, beatportSubmitted: true })
        .finally(() => {
          setLoading(false)
          props.close()
        })
    })
  }

  return (
    currentOrganisation?.membershipTier === 'FREE'
      ? <BillingModal
        account={currentOrganisation}
        close={props.close}
        open={props.open}
      />
      : <Dialog
        BackdropProps={{
          timeout: 500,
        }}
        closeAfterTransition
        maxWidth='sm'
        onClose={props.close}
        open={props.open}
        sx={{
          width: '100%',
          '.MuiPaper-root': {
            width: '100%',
          },
        }}
      >
        <Stack height={1} width={1}>
          <AppBar position='sticky' sx={{ top: 0, background: theme => theme.palette.background.default }}>
            <Toolbar sx={{ justifyContent: 'space-between' }}>
              <Typography variant='h4'>
                Beatport Label Page Request
              </Typography>
              <IconButton
                aria-label='close'
                onClick={props.close}
              >
                <CloseRoundedIcon />
              </IconButton>
            </Toolbar>
          </AppBar>

          <Stack alignItems='center' height={1} spacing={4} width={1}>
            <Stack
              alignItems='center'
              direction='column'
              height={1}
              justifyContent='center'
              overflow='hidden'
              paddingX={3}
              paddingY={2}
              position='relative'
              spacing={4}
              width={1}
            >
              <Avatar
                src={currentOrganisation?.logoUrl}
                sx={{
                  width: 64,
                  height: 64,
                }}
              />
              <TextField
                label='Label name'
                onChange={event => setBeatportName(event.target.value)}
                placeholder='Record Records'
                sx={{
                  width: 1,
                }}
                value={beatportName}
              />
              <Autocomplete
                onChange={(event, value) => setBeatportGenre(value ?? '')}
                options={BEATPORT_GENRES}
                renderInput={params =>
                  <TextField
                    {...params}
                    label='Beatport Genre'
                    placeholder='Beatport Genre'
                    sx={{
                      width: 1,
                      marginTop: 0,
                    }}
                  />}
                sx={{
                  width: 1,
                }}
                value={beatportGenre}
              />
              <TextField
                label='Social media links'
                onChange={event => setSocial(event.target.value)}
                placeholder='Instagram, Facebook, TikTok, Twitter'
                sx={{
                  width: 1,
                }}
                value={social}
              />
              <TextField
                label='Links to 2 releases'
                onChange={event => setReleases(event.target.value)}
                placeholder='https://open.spotify.com/track/1234567890'
                sx={{
                  width: 1,
                }}
                value={releases}
              />
              <TextField
                label='Planned release schedule'
                onChange={event => setSchedule(event.target.value)}
                placeholder='10 per month'
                sx={{
                  width: 1,
                }}
                value={schedule}
              />
              <TextField
                label='Regular artists'
                onChange={event => setArtists(event.target.value)}
                placeholder='Jane Doe, John Doe'
                sx={{
                  width: 1,
                }}
                value={artists}
              />
              <LoadingButton
                loading={loading}
                onClick={async () => submit()}
                sx={{
                  marginTop: '16px!important',
                }}
                variant='contained'
              >
                Submit application
              </LoadingButton>
              <Typography
                color={themeItem.palette.text.label}
                sx={{
                  marginTop: '16px!important',
                }}
                textAlign='center'
                variant='body2'
              >
                If your submission is accepted by Beatport, our support team will be in touch via email.
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Dialog>
  )
}

export default BeatportApplicationModal
