export const digitalPlatforms = [
  { name: '7Digital' },
  { name: 'AllSaints' },
  { name: 'Amazon' },
  { name: 'Anghami' },
  { name: 'AWA' },
  { name: 'Boomplay' },
  { name: 'Bugs!' },
  { name: 'CapCut' },
  { name: 'Claro-musica' },
  { name: 'Deezer' },
  { name: 'eMUSIC' },
  { name: 'FLO' },
  { name: 'Gracenote' },
  { name: 'Genie Music' },
  { name: 'iHeartRadio' },
  { name: 'iTunes & Apple Music' },
  { name: 'Joox' },
  { name: 'Kanjian' },
  { name: 'KKBOX' },
  { name: 'Kuack Media' },
  { name: 'Line Music' },
  { name: 'Melon' },
  { name: 'Napster' },
  { name: 'Netease' },
  { name: 'Nuuday' },
  { name: 'Pandora' },
  { name: 'Qobuz' },
  { name: 'Saavn' },
  { name: 'SoundCloud' },
  { name: 'Spotify' },
  { name: 'Tencent' },
  { name: 'Tidal' },
  { name: 'Twitch Radio' },
  { name: 'Naver' },
  { name: 'YouTube Music' },
  { name: 'Zing MP3' },
]

export const socialPlatforms = [
  { name: 'Facebook' },
  { name: 'TikTok' },
  { name: 'YouTube Content ID' },
]

export const specialStores = [
  { name: 'Beatport' },
]

export const allPlatforms = [...digitalPlatforms, ...socialPlatforms, ...specialStores]
export const defaultSelectAllPlatforms = [...digitalPlatforms, ...socialPlatforms]
