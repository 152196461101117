import type { Components } from '@mui/material'

import COLOR from 'src/styles/colors'
import SPACING from 'src/styles/spacing'

const MuiTooltip: Components['MuiTooltip'] = {
  defaultProps: {
    arrow: true,
  },
  styleOverrides: {
    tooltip: {
      zIndex: 9999,
      backgroundColor: COLOR.OTHER.SNACKBAR_BG,
      borderRadius: SPACING.BORDER_RADIUS / 2,
      fontSize: '.825rem',
    },
    arrow: {
      color: COLOR.OTHER.SNACKBAR_BG,
    },
  },
}

export default MuiTooltip
