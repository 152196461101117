import CloseIcon from '@mui/icons-material/Close'
import { Button, Dialog, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'

import { PricingComparisonUi } from 'src/components/pages/Account/InitialSetup/InitialSetupModals/PricingComparison'
import { useAuth } from 'src/components/providers/AuthProvider'

type Props = {
  readonly close: () => void
  readonly open: boolean
  readonly handleFormSubmitFree: () => void
  readonly handleSelection: () => void
}

const ConfirmFreeModal = (props: Props) => {
  const { currentOrganisation } = useAuth()
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='lg'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
        '.MuiDialog-paper': {
          height: '100%',
          overflow: 'hidden',
        },
      }}
    >
      <Stack height={1} overflow='auto' paddingX={3} paddingY={3} spacing={4} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='flex-end'
          width='100%'
        >
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>
        <Stack alignItems='center' justifyContent='center' spacing={1} width={1}>
          <Stack maxWidth={600}>
            <Typography
              lineHeight={1.2}
              textAlign='center'
              variant='h1'
            >
              Releese for
              {' '}
              {currentOrganisation?.orgType ?? 'Artist'}
              s
            </Typography>
            <Typography
              color='textSecondary'
              textAlign='center'
              variant='body1'
            >
              We built Releese for artists and labels alike. Before continuing with a Collaborator account,
              we want to make sure you understand what we can do for you so you can take an educated decision.
            </Typography>
          </Stack>
        </Stack>
        <PricingComparisonUi />
        <Stack direction='row' justifyContent='flex-end' spacing={1} width='100%'>
          <Button
            onClick={() => props.handleFormSubmitFree()}
            variant='text'
          >
            No thanks
          </Button>
          <Button
            color='primary'
            onClick={() => props.handleSelection()}
            variant='contained'
          >
            Try for free for 14 days
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default ConfirmFreeModal
