import { Stack } from '@mui/material'

import EarningsCloudChart from 'src/components/pages/Earnings/EarningsDistribution/EarningsCloudChart'
import EarningsStoreChart from 'src/components/pages/Earnings/EarningsDistribution/Stores/EarningsStoreChart'
import type { AnalyticsMonthlyChart } from 'src/models/Analytics'

type Props = {
  data: AnalyticsMonthlyChart[]
}

const EarningsDistributionStores = (props: Props) =>
  <Stack paddingTop={2} width={1}>
    <EarningsStoreChart
      data={props.data}
    />
    <EarningsCloudChart
      data={props.data}
      stores
    />
  </Stack>

export default EarningsDistributionStores
