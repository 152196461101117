/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import CloudDownloadRoundedIcon from '@mui/icons-material/CloudDownloadRounded'
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded'
import { LoadingButton } from '@mui/lab'
import { Avatar, Card, CardActionArea, Container, Stack, Typography, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'

import { getFiles, getFilesByProjectId } from 'src/api/files'
import { downloadFolder, downloadProjectFolder, getFolderPublic, getFolderPublicContent, getFolderPublicProject, getFolderPublicProjectContent } from 'src/api/folder'
import { getBasicOrganisation } from 'src/api/organisation'
import { getProjectPublic } from 'src/api/projects'
import Loading from 'src/components/Loading'
import AudioPlayerItem from 'src/components/pages/Menu/AudioPlayerItem'
import PageNotFound from 'src/components/pages/PageNotFound'
import ViewFileItemPulicTable from 'src/components/pages/ShareFile/Components/ViewFileItemPublicTable'
import SubFolder from 'src/components/pages/ShareFolder/Components/SubFolder'
import ReleeseLogo from 'src/components/ReleeseLogo'
import type { FileItem } from 'src/models/File'
import type { Folder } from 'src/models/Folder'
import type { BasicOrganisation } from 'src/models/Organisation'
import type Project from 'src/models/Project'

const ShareFolder = () => {
  const themeItem = useTheme()
  const { type } = useParams<{ type: string }>()
  const { id } = useParams<{ id: string }>()
  const { hash } = useParams<{ hash: string }>()
  const [loading, setLoading] = useState(false)
  const [loadingbutton, setLoadingbutton] = useState(false)
  const [error, setError] = useState(false)
  const [folder, setFolder] = useState<Folder>()
  const [orgSimple, setOrg] = useState<BasicOrganisation>()
  const [files, setFiles] = useState<FileItem[]>([])
  const [project, setProject] = useState<Project>()
  const [contentFolders, setContentFolders] = useState<Folder[]>([])

  const search = useLocation().search
  const goto = new URLSearchParams(search).get('goto')
  const navigate = useNavigate()

  useEffect(() => {
    if (id && hash && type) {
      setLoading(true)
      if (type === 'folder') {
        getFolderPublic(id, hash)
          .then(data => {
            setFolder(data)
            setError(false)
          })
          .catch(() => setError(true))
          .finally(() => setLoading(false))
        getFolderPublicContent(id, hash)
          .then(data => {
            setContentFolders(data)
            setError(false)
          })
          .catch(() => setError(true))
          .finally(() => setLoading(false))
      } else if (type === 'project') {
        getFolderPublicProject(id, hash)
          .then(data => {
            setProject(data)
            setError(false)
          })
          .catch(() => setError(true))
          .finally(() => setLoading(false))
        getFolderPublicProjectContent(id, hash)
          .then(data => {
            setContentFolders(data)
            setError(false)
          })
          .catch(() => setError(true))
          .finally(() => setLoading(false))
      }
    }
  }, [id, hash, type])

  useEffect(() => {
    if (folder?.organisationId && folder.organisationId.length > 0) {
      void getBasicOrganisation(folder.organisationId)
        .then(org => {
          if (org) {
            setOrg(org)
          }
        })
    }
  }, [folder?.organisationId])

  useEffect(() => {
    if (folder?.projectId && folder.projectId.length > 0) {
      void getProjectPublic(folder.projectId)
        .then(proj => {
          if (proj.organisation !== undefined) {
            setOrg({
              name: proj.organisation.name,
              id: proj.organisation.id,
              logoUrl: proj.organisation.logoUrl,
              defaultSocialList: [],
            })
          }
        })
    }
  }, [folder?.projectId])

  useEffect(() => {
    if (folder?.id && folder.id.length > 0) {
      void getFiles(folder.id)
        .then(fileResponse => {
          setFiles(fileResponse)
        })
    }
  }, [folder?.id])

  useEffect(() => {
    if (project?.id && project.id.length > 0) {
      void getFilesByProjectId(project.id)
        .then(fileResponse => {
          setFiles(fileResponse)
        })
      if (project.organisation) {
        setOrg({
          name: project.organisation.name,
          id: project.organisation.id,
          logoUrl: project.organisation.logoUrl,
          defaultSocialList: [],
        })
      }
    }
  }, [project?.id, project?.organisation])

  const downloadFolderFunction = async (event: React.MouseEvent) => {
    event.stopPropagation()
    if (project) {
      setLoadingbutton(true)
      await downloadProjectFolder(project)
        .finally(() => setLoadingbutton(false))
    } else if (folder) {
      setLoadingbutton(true)
      await downloadFolder(folder)
        .finally(() => setLoadingbutton(false))
    }
  }

  return !error
    ? loading
      ? <Loading />
      : <>
        <Container
          sx={{
            display: 'flex',
            alignItems: 'center',
            minHeight: 1,
            width: '100%!important',
            padding: {
              xl: 4,
              lg: 4,
              md: 4,
              sm: 2,
              xs: 2,
            },
            height: '100%',
            overflowY: 'auto',
            margin: 0,
            flexDirection: 'column',
            maxWidth: '100%!important',
            backgroundColor: theme => theme.palette.background.input,
          }}
        >
          <Card sx={{ width: 1, maxWidth: 'sm' }}>
            <Stack alignItems='center' justifyContent='center' maxWidth='sm' padding={4} spacing={4} width={1}>
              <CloudDownloadRoundedIcon
                sx={{
                  fontSize: 144,
                  color: theme => theme.palette.action.active,
                }}
              />
              <Stack alignItems='center' justifyContent='center' spacing={2} width={1}>
                <Stack alignItems='center' justifyContent='center' width={1}>
                  <Typography lineHeight={1.2} textAlign='center' variant='h2'>
                    Your files are ready to download
                  </Typography>
                  <Typography color={themeItem.palette.text.label} textAlign='center' variant='body1'>
                    This link will not expire until the creator deletes the folder
                  </Typography>
                </Stack>
                <Stack alignItems='center' justifyContent='center' spacing={0.5}>
                  <Typography color={themeItem.palette.text.label} variant='body2'>
                    Shared by
                  </Typography>
                  <Stack alignItems='center' direction='row' justifyContent='center' spacing={0.75}>
                    <Avatar src={orgSimple?.logoUrl} sx={{ height: 32, width: 32 }} />
                    <Typography color={themeItem.palette.text.primary} variant='h4'>
                      {orgSimple?.name}
                    </Typography>
                  </Stack>
                </Stack>
                <LoadingButton
                  disabled={!folder && !project}
                  loading={loadingbutton}
                  onClick={event => downloadFolderFunction(event)}
                  startIcon={<FileDownloadRoundedIcon />}
                  variant='contained'
                >
                  Download all
                </LoadingButton>
              </Stack>
              <Stack spacing={1} width={1}>
                <Stack
                  direction='row'
                  flexWrap='wrap'
                  sx={{
                    alignContent: 'flex-start',
                    alignItems: 'center',
                    width: 1,
                  }}
                >
                  {goto &&
                  <Stack
                    height={1}
                    key='back'
                    padding={1}
                    width={{
                      xl: '33%',
                      lg: '33%',
                      md: '33%',
                      sm: '50%',
                      xs: '50%',
                    }}
                  >
                    <Stack height={1}>
                      <Card
                        elevation={0}
                        sx={{
                          height: 1,
                          background: theme => theme.palette.background.elevatedCard,
                        }}
                      >
                        <CardActionArea
                          onClick={() => navigate(-1)}
                          sx={{
                            height: 1,
                          }}
                        >
                          <Stack
                            alignItems='center'
                            height={1}
                            justifyContent='center'
                            padding={1}
                          >
                            <ArrowBackIosNewRoundedIcon
                              color='action'
                              sx={{
                                height: 64,
                                width: 64,
                              }}
                            />
                          </Stack>
                        </CardActionArea>
                      </Card>
                    </Stack>
                  </Stack>}
                  {contentFolders.length > 0 &&
                  contentFolders.map(item =>
                    <Stack
                      key={item.id}
                      padding={1}
                      width={{
                        xl: '33%',
                        lg: '33%',
                        md: '33%',
                        sm: '50%',
                        xs: '50%',
                      }}
                    >
                      <SubFolder
                        folder={item}
                      />
                    </Stack>)}
                </Stack>
                <Stack spacing={1} width={1}>
                  {files.length > 0 &&
                  files.map(file => file.url.length > 0 &&
                  <ViewFileItemPulicTable
                    fileItem={file}
                    link={`https://cdn.releese.io/${file.url}`}
                  />)}
                </Stack>
              </Stack>
            </Stack>
          </Card>
          <Stack paddingY={4}>
            <ReleeseLogo full />
          </Stack>
        </Container>
        <AudioPlayerItem />
      </>
    : <Stack
      alignItems='flex-start'
      direction='row'
      height={1}
      width={1}
    >
      <PageNotFound />
    </Stack>
}

export default ShareFolder
