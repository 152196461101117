import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import { Avatar, ListItemText, Skeleton, Stack } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { useEffect, useState } from 'react'
import { v4 } from 'uuid'

import { getDistributionISRC } from 'src/api/distribution'
import AnalyticsDistributionModal from 'src/components/pages/Analytics/AnalyticsDistribution/Overview/AnalyticsTrackModal'
import EarningsDistributionModal from 'src/components/pages/Earnings/EarningsTabs/EarningsDistributionModal'
import type Recording from 'src/models/Recording'

type Props = {
  row: {
    _id: string
    amount: number
    streams: number
    payperstream: number
    projectId: string
  }
  analytics?: boolean
}

const TopReleaseItem = (props: Props) => {
  const [loading, setLoading] = useState(false)
  const [recording, setRecording] = useState<Recording>()
  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    void getItem()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.row._id])

  const getItem = async () => {
    setLoading(true)
    await getDistributionISRC(props.row._id, props.row.projectId)
      .then(items => {
        setRecording(items)
      })
      .finally(() => setLoading(false))
  }

  return (
    <>
      {openModal && !props.analytics &&
      <EarningsDistributionModal
        close={() => setOpenModal(false)}
        loading={loading}
        open={openModal}
        recording={recording}
        selectedProject={props.row.projectId}
        selectedRecording={props.row._id}
      />}
      {openModal && props.analytics &&
      <AnalyticsDistributionModal
        close={() => setOpenModal(false)}
        loading={loading}
        open={openModal}
        recording={recording}
        selectedRecording={props.row._id}
      />}
      <TableRow
        hover
        key={`${props.row._id}${props.row.projectId}${v4()}`}
        onClick={() => setOpenModal(true)}
        role='checkbox'
        sx={{
          '.MuiTableCell-root': {
            borderBottomColor: 'divider',
            paddingY: 2,
            textDecoration: 'none!important',
          },
          ':hover': {
            '.MuiTableCell-root': {
              background: theme => `${theme.palette.tableHoverBg}!important`,
            },
          },
        }}
        tabIndex={-1}
      >
        <TableCell
          component='th'
          scope='row'
          sx={{
            position: 'sticky',
            background: theme => theme.palette.background.default,
            borderRight: theme => `1px solid ${theme.palette.divider}`,
            left: 0,
            zIndex: 999,
          }}
        >
          <Stack alignItems='center' direction='row' spacing={2}>
            {loading
              ? <>
                <Skeleton
                  height={40}
                  variant='rounded'
                  width={40}
                />
                <Skeleton
                  height={20}
                  variant='text'
                  width={100}
                />
              </>
              : <>
                <Avatar
                  src={recording?.artworkPreviewUrl}
                  variant='rounded'
                >
                  <MusicNoteRoundedIcon />
                </Avatar>
                <ListItemText
                  primary={recording?.title}
                  secondary={recording
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    ? recording.primaryArtists?.map(artist => artist.name).join(', ')
                    : ''}
                />
              </>}
          </Stack>
        </TableCell>
        <TableCell align='left'>
          {new Intl.NumberFormat('en-US',
            { style: 'currency', currency: 'USD' }).format(props.row.amount)}
        </TableCell>

        <TableCell align='left'>
          {`${props.row.streams.toLocaleString('en-US')}`}
        </TableCell>

        <TableCell align='left'>
          {new Intl.NumberFormat('en-US',
            { style: 'currency', currency: 'USD' })
            .format(props.row.amount > 0.01 && props.row.streams > 0
              ? props.row.payperstream
              : 0)}
        </TableCell>
      </TableRow>
    </>
  )
}

export default TopReleaseItem
