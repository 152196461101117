import { Stack } from '@mui/material'

import EarningsDistribution from 'src/components/pages/Earnings/EarningsTabs/EarningsDistribution'
import type Project from 'src/models/Project'

type Props = {
  project?: Project
}

const ViewProjectEarnings = (props: Props) =>
  <Stack height={1} width={1}>
    <EarningsDistribution
      project={props.project}
      selectedProject={props.project?.id}
    />
  </Stack>

export default ViewProjectEarnings
