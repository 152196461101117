/* eslint-disable max-len */
/* eslint-disable sonarjs/no-nested-template-literals */
import axios from 'axios'

import type { MarketingAnalyticsDatesDto, MarketingAnalyticsStringDto } from 'src/models/Marketing'
import { MarketingAnalyticsDates, MarketingAnalyticsString, MarketingLinkFan } from 'src/models/Marketing'

const PATH = '/marketingdata' as const

// leaving this ownerId undefined returns all releases belonging to current user
export const getClicksPerId = (id: string, startDate: Date, endDate: Date, services: string[]) =>
  axios.get<MarketingAnalyticsDatesDto[]>(`${PATH}/analytics/clicks/${id}/${Number(startDate).toString()}/
${Number(endDate).toString()}?${services.map((item, index) => `services[${index}]=${item}`).join('&')}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsDates(dto)))

export const getViewsPerId = (id: string, startDate: Date, endDate: Date) =>
  axios.get<MarketingAnalyticsDatesDto[]>(`${PATH}/analytics/views/${id}/${Number(startDate).toString()}/
  ${Number(endDate).toString()}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsDates(dto)))

export const getPresavesPerId = (id: string, startDate: Date, endDate: Date) =>
  axios.get<MarketingAnalyticsDatesDto[]>(`${PATH}/analytics/presaves/${id}/${Number(startDate).toString()}/
      ${Number(endDate).toString()}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsDates(dto)))

export const getDestinations = (id: string, startDate: Date, endDate: Date) =>
  axios.get<MarketingAnalyticsStringDto[]>(`${PATH}/analytics/destinations/${id}/${Number(startDate).toString()}/
  ${Number(endDate).toString()}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsString(dto)))

export const getDestinationsPresaves = (id: string, startDate: Date, endDate: Date) =>
  axios.get<MarketingAnalyticsStringDto[]>(`${PATH}/analytics/destinations-presaves/${id}/${Number(startDate).toString()}/
      ${Number(endDate).toString()}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsString(dto)))

export const getViewsPerCity = (id: string, startDate: Date, endDate: Date) =>
  axios.get<MarketingAnalyticsStringDto[]>(`${PATH}/analytics/cities/views/${id}/${Number(startDate).toString()}/
  ${Number(endDate).toString()}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsString(dto)))

export const getClicksPerCity = (id: string, startDate: Date, endDate: Date, services: string[]) =>
  axios.get<MarketingAnalyticsStringDto[]>(`${PATH}/analytics/cities/clicks/${id}/${Number(startDate).toString()}/
  ${Number(endDate).toString()}?${services.map((item, index) => `services[${index}]=${item}`).join('&')}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsString(dto)))

export const getClicksPerCountry = (id: string, startDate: Date, endDate: Date, services: string[]) =>
  axios.get<MarketingAnalyticsStringDto[]>(`${PATH}/analytics/countries/clicks/${id}/${Number(startDate).toString()}/
  ${Number(endDate).toString()}?${services.map((item, index) => `services[${index}]=${item}`).join('&')}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsString(dto)))

export const getViewsPerCountry = (id: string, startDate: Date, endDate: Date) =>
  axios.get<MarketingAnalyticsStringDto[]>(`${PATH}/analytics/countries/views/${id}/${Number(startDate).toString()}/
  ${Number(endDate).toString()}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsString(dto)))

export const getViewsPerSource = (id: string, startDate: Date, endDate: Date) =>
  axios.get<MarketingAnalyticsStringDto[]>(`${PATH}/analytics/sources/views/${id}/${Number(startDate).toString()}/
  ${Number(endDate).toString()}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsString(dto)))

export const getClicksPerSource = (id: string, startDate: Date, endDate: Date, services: string[]) =>
  axios.get<MarketingAnalyticsStringDto[]>(`${PATH}/analytics/sources/clicks/${id}/${Number(startDate).toString()}/
  ${Number(endDate).toString()}?${services.map((item, index) => `services[${index}]=${item}`).join('&')}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsString(dto)))

export const getTotalClicks = (id: string) =>
  axios.get<MarketingAnalyticsStringDto[]>(`${PATH}/analytics/totalclicks/${id}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsString(dto)))

export const getTotalViews = (id: string) =>
  axios.get<MarketingAnalyticsStringDto[]>(`${PATH}/analytics/totalviews/${id}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsString(dto)))

export const getFans = (id: string) =>
  axios.get<MarketingLinkFan[]>(`${PATH}/analytics/fans/${id}`)
    .then(response => response.data.map(dto => new MarketingLinkFan(dto)))

export const getViewsPerBrowser = (id: string, startDate: Date, endDate: Date) =>
  axios.get<MarketingAnalyticsStringDto[]>(`${PATH}/analytics/browser/views/${id}/${Number(startDate).toString()}/
  ${Number(endDate).toString()}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsString(dto)))

export const getClicksPerBrowser = (id: string, startDate: Date, endDate: Date, services: string[]) =>
  axios.get<MarketingAnalyticsStringDto[]>(`${PATH}/analytics/browser/clicks/${id}/${Number(startDate).toString()}/
  ${Number(endDate).toString()}?${services.map((item, index) => `services[${index}]=${item}`).join('&')}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsString(dto)))

export const getViewsPerOs = (id: string, startDate: Date, endDate: Date) =>
  axios.get<MarketingAnalyticsStringDto[]>(`${PATH}/analytics/os/views/${id}/${Number(startDate).toString()}/
  ${Number(endDate).toString()}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsString(dto)))

export const getClicksPerOs = (id: string, startDate: Date, endDate: Date, services: string[]) =>
  axios.get<MarketingAnalyticsStringDto[]>(`${PATH}/analytics/os/clicks/${id}/${Number(startDate).toString()}/
  ${Number(endDate).toString()}?${services.map((item, index) => `services[${index}]=${item}`).join('&')}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsString(dto)))
