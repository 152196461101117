/* eslint-disable react/jsx-props-no-spreading */
import type { PlateRenderElementProps, TElement } from '@udecode/plate'
import { createPluginFactory } from '@udecode/plate'

import type { MyValue } from 'src/components/pages/Notes/Modern/typescript/plateTypes'

export const ELEMENT_LEGACY_PARAGRAPH = 'paragraph'

export const LegacyParagraphElement = (props: PlateRenderElementProps<MyValue, TElement>) =>
  <p
    {...props.attributes}
    {...props.nodeProps}
    className={props.className}
  >
    {props.children}
  </p>

export const createLegacyParagraphPlugin = createPluginFactory({
  key: ELEMENT_LEGACY_PARAGRAPH,
  isElement: true,
  component: LegacyParagraphElement,
})
