/* eslint-disable react/forbid-component-props */
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenterRounded'
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeftRounded'
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRightRounded'
import FormatBoldIcon from '@mui/icons-material/FormatBoldRounded'
import FormatItalicIcon from '@mui/icons-material/FormatItalicRounded'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulletedRounded'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumberedRounded'
import FormatQuoteIcon from '@mui/icons-material/FormatQuoteRounded'
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlinedRounded'
import InsertLinkRoundedIcon from '@mui/icons-material/InsertLinkRounded'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

/**
 * Rich Input Toolbar
 *
 * @returns {JSX.Element}
 */
const RichInputToolbar = () =>
  <div id='toolbarItem'>
    <Grid alignItems='center' container spacing={1}>
      <Grid item>
        <Tooltip title='Bold'>
          <IconButton className='ql-bold' size='medium'>
            <FormatBoldIcon fontSize='medium' />
          </IconButton>
        </Tooltip>
        <Tooltip title='Italic'>
          <IconButton className='ql-italic' size='medium'>
            <FormatItalicIcon fontSize='medium' />
          </IconButton>
        </Tooltip>
        <Tooltip title='Underline'>
          <IconButton className='ql-underline' size='medium'>
            <FormatUnderlinedIcon fontSize='medium' />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip title='Numbered List'>
          <IconButton className='ql-list' size='medium' value='ordered'>
            <FormatListNumberedIcon fontSize='medium' />
          </IconButton>
        </Tooltip>
        <Tooltip title='Bulleted List'>
          <IconButton className='ql-list' size='medium' value='bullet'>
            <FormatListBulletedIcon fontSize='medium' />
          </IconButton>
        </Tooltip>
        <Tooltip title='Quote'>
          <IconButton className='ql-blockquote' color='inherit' size='medium'>
            <FormatQuoteIcon fontSize='medium' sx={{ color: '#6b7280' }} />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip title='Align Left'>
          <IconButton className='ql-align' size='medium' value=''>
            <FormatAlignLeftIcon fontSize='medium' />
          </IconButton>
        </Tooltip>
        <Tooltip title='Align Center'>
          <IconButton className='ql-align' size='medium' value='center'>
            <FormatAlignCenterIcon fontSize='medium' />
          </IconButton>
        </Tooltip>
        <Tooltip title='Align Right'>
          <IconButton className='ql-align' size='medium' value='right'>
            <FormatAlignRightIcon fontSize='medium' />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip title='Insert Link'>
          <IconButton className='ql-link' size='medium'>
            <InsertLinkRoundedIcon fontSize='medium' />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  </div>

export default RichInputToolbar
