/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-const */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, Button, Card, Checkbox, FormControlLabel, FormGroup, Grid, Stack, Typography, useTheme } from '@mui/material'
import countries from 'i18n-iso-countries'
import data from 'i18n-iso-countries/langs/en.json'
import { useEffect, useRef, useState } from 'react'
import WorldMap from 'react-svg-worldmap'
import { v4 } from 'uuid'

import { useContainerDimensions } from 'src/components/ContainerDimensions'
import { useAuth } from 'src/components/providers/AuthProvider'
import { continentsData } from 'src/data/distributionCountries'
import type { Platform, Territory } from 'src/models/Distribution'

type Props = {
  territories: Territory[]
  setTerritories: React.Dispatch<React.SetStateAction<Territory[]>>
  stores: Platform[]
  setStores: React.Dispatch<React.SetStateAction<Platform[]>>
}

const TerritoriesDistribute = (props: Props) => {
  const [key, setKey] = useState(v4())
  const { currentOrganisation } = useAuth()
  const theme = useTheme()
  const componentRef = useRef<HTMLDivElement>(null)
  const { width } = useContainerDimensions(componentRef)
  countries.registerLocale(data)

  const territoryList = props.territories.map(({ iso }) =>
    ({ country: iso.toUpperCase(), value: 1 }))

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>, country: Territory) => {
    let updatedList = [...props.territories]
    if (event.target.checked) {
      updatedList = [...props.territories, country]
    } else {
      updatedList.splice(props.territories.indexOf(country), 1)

      if (country.iso === 'CN') {
        let updatedStores = [...props.stores]
        if (props.stores.findIndex(item => item.name === 'Tencent') !== -1) {
          updatedStores.splice(props.stores.findIndex(item => item.name === 'Tencent'), 1)
        }
        props.setStores(updatedStores)
      }

      if (country.iso === 'KR' || country.iso === 'KP') {
        let updatedStores = [...props.stores]
        if (props.stores.findIndex(item => item.name === 'FLO') !== -1) {
          updatedStores.splice(props.stores.findIndex(item => item.name === 'FLO'), 1)
        }
        props.setStores(updatedStores)
      }
    }
    props.setTerritories(updatedList)
    setKey(v4())
  }

  const handleCheckAll = (event: React.MouseEvent<HTMLButtonElement>, continentName: string) => {
    event.preventDefault()
    event.stopPropagation()
    const newArray = [...props.territories,
      ...continentsData.filter(continent => continent.continentName === continentName)
        .flatMap(item => item.countries)
        .filter(country => !props.territories.includes(country))]
    props.setTerritories(newArray)
    setKey(v4())
  }

  const handleUnselectAll = (event: React.MouseEvent<HTMLButtonElement>, continentName: string) => {
    event.preventDefault()
    event.stopPropagation()
    const continentDataTest = new Set(continentsData.filter(continent => continent.continentName === continentName)
      .flatMap(item => item.countries)
      .filter(country => props.territories.includes(country)))

    const newArray = props.territories.filter(country => !continentDataTest.has(country))

    if (continentName === 'Asia') {
      let updatedStores = [...props.stores]
      if (props.stores.findIndex(item => item.name === 'Tencent') !== -1) {
        updatedStores.splice(props.stores.findIndex(item => item.name === 'Tencent'), 1)
      }
      if (props.stores.findIndex(item => item.name === 'FLO') !== -1) {
        updatedStores.splice(props.stores.findIndex(item => item.name === 'FLO'), 1)
      }
      props.setStores(updatedStores)
    }

    setKey(v4())
    props.setTerritories(newArray)
  }

  useEffect(() => {
    console.log(territoryList)
  }, [territoryList])

  return (
    <Stack width={1}>
      <Stack width={1}>
        <Typography variant='h3'>
          Territories
        </Typography>
        {currentOrganisation?.membershipTier !== 'ENTERPRISE' &&
        currentOrganisation?.membershipTier !== 'ENTERPRISE_YEARLY' &&
        <Typography color='text.label' variant='body2'>
          Territory carving is only available on the Business plan
        </Typography>}
      </Stack>

      <Card variant='outlined'>
        <Stack
          alignItems='center'
          padding={2}
          ref={componentRef}
          sx={{ pointerEvents: 'none' }}
          width={1}
        >
          <WorldMap
            backgroundColor='transparent'
            borderColor={theme.palette.divider}
            color={theme.palette.primary.main}
            data={territoryList}
            key={key}
            size={width > 800 ? width / 2 : width}
            strokeOpacity={1}
            valueSuffix=''
          />
        </Stack>
      </Card>

      <Stack marginTop={4} width={1}>
        {continentsData.map(continent =>
          <Accordion
            TransitionProps={{ unmountOnExit: true }}
            disableGutters
            elevation={0}
            key={continent.continentName}
            sx={{
              paddingY: 2,
            }}
            variant='outlined'
          >
            <AccordionSummary
              aria-controls={continent.continentName}
              expandIcon={<ExpandMoreIcon />}
              id={continent.continentName}
            >
              <Grid
                container
                sx={{
                  alignItems: 'center',
                }}
              >
                <Grid item lg={6} md={6} sm={4} xl={6} xs={12}>
                  <Typography variant='subtitle1'>
                    {continent.continentName}
                  </Typography>
                </Grid>
                <Grid item lg={2} md={2} sm={2} xl={2} xs={12}>
                  <Typography color={theme.palette.text.secondary} variant='body2'>
                    {continent.countries.filter(item => !!props.territories.includes(item)).length.toString()}
                    /
                    {continent.countries.length.toString()}
                    {' '}
                    countries
                  </Typography>
                </Grid>
                <Grid item lg={2} md={2} sm={3} xl={2} xs={6}>
                  <Button
                    disabled={currentOrganisation?.membershipTier !== 'ENTERPRISE' &&
                    currentOrganisation?.membershipTier !== 'ENTERPRISE_YEARLY'}
                    onClick={event => handleCheckAll(event, continent.continentName)}
                    variant='text'
                  >
                    Select all
                  </Button>
                </Grid>
                <Grid item lg={2} md={2} sm={3} xl={2} xs={6}>
                  <Button
                    color='error'
                    disabled={currentOrganisation?.membershipTier !== 'ENTERPRISE' &&
                    currentOrganisation?.membershipTier !== 'ENTERPRISE_YEARLY'}
                    onClick={event => handleUnselectAll(event, continent.continentName)}
                    variant='text'
                  >
                    Unselect all
                  </Button>
                </Grid>
              </Grid>

            </AccordionSummary>
            <AccordionDetails>
              <FormGroup>
                <Grid container>
                  {continent.countries.map(country =>
                    <Grid item key={country.name} lg={3} md={4} sm={4} xl={2} xs={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={props.territories.includes(country)}
                            disabled={currentOrganisation?.membershipTier !== 'ENTERPRISE' &&
                            currentOrganisation?.membershipTier !== 'ENTERPRISE_YEARLY'}
                            name={country.name}
                            onChange={event => handleCheck(event, country)}
                          />
                        }
                        label={country.name}
                      />
                    </Grid>)}
                </Grid>
              </FormGroup>
            </AccordionDetails>
          </Accordion>)}
      </Stack>
    </Stack>
  )
}

export default TerritoriesDistribute
