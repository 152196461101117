
import { List, ListItem, ListItemButton, ListItemText, Skeleton, Stack, Typography } from '@mui/material'
import { useState } from 'react'

import SearchField from 'src/components/form-elements/SearchField'
import RecordingsMenu from 'src/components/pages/Analytics/AnalyticsMenu/Menu/RecordingsMenu'
import type Recording from 'src/models/Recording'

type Props = {
  distributionItems?: Recording[]
  selectedRecording: string
  setSelectedRecording: React.Dispatch<React.SetStateAction<string>>
  loading: boolean
}

const AnalyticsMenu = (props: Props) => {
  const [searchQuery, setSearchQuery] = useState('')
  const filteredRecordings = props.distributionItems

  return (
    <Stack
      alignItems='center'
      direction='column'
      paddingTop={2}
      spacing={2}
      sx={{ flexShrink: 0, maxHeight: '100%', overflow: 'auto' }}
      width={280}
    >
      <Stack paddingX={2} width={1}>
        <SearchField
          fullWidth
          onChange={event => setSearchQuery(event.target.value)}
          sx={{ marginTop: 0 }}
          value={searchQuery}
        />
      </Stack>
      {!props.loading && filteredRecordings &&
      <RecordingsMenu
        recordings={filteredRecordings}
        searchQuery={searchQuery}
        selectedRecording={props.selectedRecording}
        setSelectedRecording={props.setSelectedRecording}
      />}
      {props.loading &&
      <Stack direction='column' justifyContent='flex-start' width={1}>
        <List dense sx={{ paddingTop: 0 }}>
          {[...Array.from({ length: 20 })].map((_item, index) =>
            <Stack key={index.toString()}>
              <ListItem
                dense
                disablePadding
              >
                <ListItemButton
                  sx={{
                    borderRadius: 0,
                  }}
                >
                  <ListItemText
                    primary={<Typography sx={{ lineHeight: 1 }} variant='h6'>
                      <Skeleton />
                    </Typography>}
                    secondary={<Typography sx={{ lineHeight: 1 }} variant='body2'>
                      <Skeleton />
                    </Typography>}
                  />
                </ListItemButton>
              </ListItem>
            </Stack>)}
        </List>
      </Stack>}
    </Stack>
  )
}

export default AnalyticsMenu
