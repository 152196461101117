import { Avatar, Chip, ListItemText } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import dayjs from 'dayjs'
import { useState } from 'react'

import { GetColorLink } from 'src/components/pages/SmartLink/Content/GetColorLink'
import GetIcon from 'src/components/pages/SmartLink/Content/GetIcon'
import SocialAssetModal from 'src/components/pages/SocialAssets/SocialAssetModal'

type Props = {
  row: {
    name: string
    followers_count?: number
    external_url?: string
    artwork: string
    top_position?: number
    current_position?: number
    added_at?: string
    removed_at?: string
    type: string
    owner_name: string
  }
  currentTab: string
  artworkUrl: string
}

const RowPlaylist = (props: Props) => {
  const [socialDrawer, setSocialDrawer] = useState(false)
  return (
    <>
      <TableRow
        hover
        key={props.row.artwork}
        onClick={() => setSocialDrawer(true)}
        role='checkbox'
        sx={{
          '.MuiTableCell-root': { borderBottomColor: 'divider', paddingY: 2 },
        }}
        tabIndex={-1}
      >
        <TableCell
          component='th'
          padding='normal'
          scope='row'
          width={64}
        >
          <Avatar src={props.row.artwork} sx={{ width: 64, height: 64 }} variant='rounded' />
        </TableCell>
        <TableCell align='left'>
          <ListItemText
            primary={props.row.name}
            secondary={props.row.owner_name}
          />
          {(props.row.type === 'Editorial' || props.row.type === 'Personalized') &&
          <Chip
            icon={GetIcon(props.currentTab)}
            label='Editorial'
            size='small'
            sx={{
              color: 'white',
              background: GetColorLink(props.currentTab),
              '.MuiSvgIcon-root': {
                color: 'white',
                marginLeft: '12px',
              },
            }}
          />}
        </TableCell>

        {props.row.followers_count &&
        <TableCell align='left'>{props.row.followers_count.toLocaleString()}</TableCell>}

        {props.row.current_position !== 0 &&
        <TableCell align='left'>{props.row.current_position}</TableCell>}

        {props.row.top_position &&
        <TableCell align='left'>{props.row.top_position}</TableCell>}

        {props.row.removed_at && props.row.removed_at.length > 0 &&
        <TableCell align='left'>{dayjs(props.row.removed_at).format('LL')}</TableCell>}

        {props.row.added_at && props.row.added_at.length > 0 &&
        <TableCell align='left'>{dayjs(props.row.added_at).format('LL')}</TableCell>}
      </TableRow>
      {socialDrawer &&
      <SocialAssetModal
        close={() => setSocialDrawer(false)}
        imageMainUrl={props.row.artwork}
        imageSecondaryUrl={props.artworkUrl}
        open={socialDrawer}
        platform={props.currentTab}
        text={`Added to ${props.row.name}`}
      />}
    </>
  )
}

export default RowPlaylist
