import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded'
import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded'
import PersonPinCircleRoundedIcon from '@mui/icons-material/PersonPinCircleRounded'
import { TabContext, TabList } from '@mui/lab'
import { Paper, Stack, Tab } from '@mui/material'
import { useState } from 'react'
import { Route, Routes, useLocation } from 'react-router'

import LinkAnalytics from 'src/components/pages/Links/Analytics/LinkAnalytics'
import LinkFans from 'src/components/pages/Links/Fans/LinkFans'
import LinksList from 'src/components/pages/Links/LinksList/LinksList'
import { NavLink } from 'src/components/SafeRouterLink'
import SPACING from 'src/styles/spacing'

const Links = () => {
  const location = useLocation().pathname
  const [currentTab, setCurrentTab] = useState(location)
  return (
    <Stack
      sx={{
        paddingBottom: SPACING.RESPONSIVE_CONTOUR,
        paddingRight: SPACING.RESPONSIVE_CONTOUR,
        height: 1,
        width: 1,
      }}
    >
      <Paper
        elevation={0}
        sx={{
          width: 1,
          height: 1,
          overflow: 'hidden',
          borderRadius: {
            xl: 1,
            lg: 1,
            md: 1,
            sm: 0,
            xs: 0,
          },
        }}
      >
        <Stack height={1} width={1}>
          <TabContext value={currentTab}>
            <Stack direction='row' sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                aria-label='Current projects tab'
                onChange={(event, value: string) => setCurrentTab(value)}
              >
                <Tab
                  component={NavLink}
                  icon={<DevicesRoundedIcon />}
                  label='Smart Links'
                  to='/links'
                  value='/links'
                />
                <Tab
                  component={NavLink}
                  icon={<AnalyticsRoundedIcon />}
                  label='Analytics'
                  to='/links/analytics'
                  value='/links/analytics'
                />
                <Tab
                  component={NavLink}
                  icon={<PersonPinCircleRoundedIcon />}
                  label='Audience'
                  to='/links/fans'
                  value='/links/fans'
                />
              </TabList>
            </Stack>
            <Stack height={1} overflow='auto' width={1}>
              <Routes>
                <Route element={<LinksList />} path='' />
                <Route element={<LinkFans />} path='/fans' />
                <Route element={<LinkAnalytics />} path='/analytics' />
              </Routes>
            </Stack>
          </TabContext>
        </Stack>
      </Paper>
    </Stack>
  )
}

export default Links
