export class Pitch {
  description = ''
  marketing = ''
  artist = ''
  press = ''

  projectId?: string

  priorityTitle = ''
  country = ''
  fanbaseLocation = ''
  genre = ''
  similarArtists = ''

  constructor(dto: Partial<Pitch>) {
    Object.assign(this, dto)
  }
}

export type PitchDto = Partial<Pitch>
