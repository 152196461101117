/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Serialized } from 'src/types/react-app-env'

export class Currency {
  code = ''
  symbol = ''
  name = ''
  supportsDecimals = true
  countryKeywords?: string[]

  constructor(dto: CurrencyDto) {
    Object.assign(this, dto)
    this.countryKeywords = dto.countryKeywords ? dto.countryKeywords : []
  }
}

export type CurrencyDto = Partial<Serialized<Currency>>

export class WiseQuoteResponse {
  id = ''
  targetAccount = ''
  paymentOptions = [
    {
      formattedEstimatedDelivery: '',
      targetAmount: 0,
      targetCurrency: '',
      price: {
        total: {
          value: {
            amount: 0,
          },
        },
      },
    },
  ]
  constructor(dto: WiseQuoteResponseDto) {
    Object.assign(this, dto)
  }
}

export type WiseQuoteResponseDto = Partial<Serialized<WiseQuoteResponse>>

export class PaymentObject {
  id = ''
  organisationId = ''
  wiseId = 0
  type = ''
  date = new Date()
  amount = 0
  customerTransactionId = ''
  hasActiveIssues = false
  status = ''
  constructor(dto: PaymentObjectDto) {
    Object.assign(this, dto)
  }
}

export type PaymentObjectDto = Partial<Serialized<PaymentObject>>

export class PayoutUser {
  accountHolderName = ''
  bankDetailsFormat = ''
  currency = ''
  details: any = { legalType: '' }
  type?: string

  constructor(dto?: PayoutUserDto) {
    Object.assign(this, dto)
  }
}

export type PayoutUserDto = Partial<Serialized<PayoutUser>>

export class PayoutMethod {
  id = ''
  name = ''
  wiseId  = ''
  currency  = ''
  type  = ''
  numbers  = ''

  constructor(dto?: PayoutMethodDto) {
    Object.assign(this, dto)
  }
}

export type PayoutMethodDto = Partial<Serialized<PayoutMethod>>

export class RequirementsBank {
  type = ''
  title = ''
  usageInfo?: string
  fields = [
    {
      name: '',
      group: [
        {
          key: '',
          name: '',
          type: '',
          refreshRequirementsOnChange: false,
          required: false,
          displayFormat: '',
          example: '',
          minLength: 0,
          maxLength: 0,
          validationRegexp: '',
          validationAsync: false,
          valuesAllowed: [
            {
              key: '',
              name: '',
            },
          ],
        },
      ],
    },
  ]

  constructor(dto: RequirementsBankDto) {
    Object.assign(this, dto)
  }
}

export type RequirementsBankDto = Partial<Serialized<RequirementsBank>>

export class WisePaymentObject {
  id = ''
  created = new Date()
  sourceCurrency = ''
  targetCurrency = ''
  sourceValue = 0
  targetValue = 0
  status = ''

  constructor(dto: WisePaymentObjectDto) {
    Object.assign(this, dto)
    this.created = new Date(dto.created ?? 0)
  }
}

export type WisePaymentObjectDto = Partial<Serialized<WisePaymentObject>>

export class WisePaymentMethodObject {
  id = ''
  currency = ''
  type = ''
  longAccountSummary = ''

  constructor(dto: WisePaymentMethodObjectDto) {
    Object.assign(this, dto)
  }
}

export type WisePaymentMethodObjectDto = Partial<Serialized<WisePaymentMethodObject>>
