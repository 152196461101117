import type { DrawerProps } from '@mui/material'
import { alpha, Drawer } from '@mui/material'

const FileDrawer = ({ drawerWidth: width, ...props }: DrawerProps & { drawerWidth: number | string }) =>
  <Drawer
    BackdropProps={{ invisible: true }}
    anchor='right'
    elevation={5}
    sx={{
      top: 0,
      height: 1,
      maxWidth: 1,
      '& .MuiDrawer-paper': {
        top: 0,
        width,
        maxWidth: '100%',
        height: 1,
        paddingTop: 0,
        borderRadius: 0,
        overflow: 'hidden',
        boxShadow: theme => `-12px 0px 15px -10px ${alpha(theme.palette.shadow, 0.75)}`,
      },
    }}
    variant='temporary'
    {...props}
  >
    {props.children}
  </Drawer>

export default FileDrawer
