export class Folder {
  id?: string
  title = ''
  parent = ''
  hash = ''
  url = ''
  organisationId?: string
  projectId?: string
  createdAt = new Date()

  constructor(dto: Partial<Folder>) {
    Object.assign(this, dto)
  }
}

export type FolderDto = Partial<Folder>
