import AddRoundedIcon from '@mui/icons-material/AddRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import type { InputProps } from '@mui/material'
import { Button, IconButton, Input } from '@mui/material'
import type { PropsWithChildren } from 'react'

type Props = PropsWithChildren<
  InputProps &
  {
    id: string
  }>

// On the Material-UI roadmap (⏳ Planning to build). So we still have to implement it ourselves for now.
// https://next.material-ui.com/discover-more/roadmap/#heading-new-components
export const FileUploadIconButton = (props: Props) =>
  <label htmlFor={`outlined-button-file-${props.id}`}>
    <IconButton component='span' size='small'>
      {props.children}
    </IconButton>
    <Input
      inputComponent='input'
      inputProps={{
        accept: 'image/*',
      }}
      type='file'
      {...props}
      id={`outlined-button-file-${props.id}`}
      sx={{ display: 'none' }}
    />
  </label>

export const FileUploadButton = (props: Props) =>
  <label htmlFor={`outlined-button-file-${props.id}`}>
    <Button component='span' rounded size='large' variant='outlined'>
      {props.children}
    </Button>
    <Input
      type='file'
      {...props}
      id={`outlined-button-file-${props.id}`}
      sx={{ display: 'none' }}
    />
  </label>

export const FileUploadButtonBase = (props: Props) =>
  <label htmlFor={`outlined-button-file-${props.id}`} style={{ zIndex: '9' }}>
    {props.children}
    <Input
      type='file'
      {...props}
      id={`outlined-button-file-${props.id}`}
      sx={{ display: 'none', zIndex: 9 }}
    />
  </label>

export const FileUploadContainedButton = (props: Props) =>
  <label htmlFor={`contained-button-file-${props.id}`}>
    <Button component='span' rounded size='large' variant='contained'>
      {props.children}
    </Button>
    <Input
      type='file'
      {...props}
      id={`contained-button-file-${props.id}`}
      sx={{ display: 'none' }}
    />
  </label>

export const FileUploadSubtleButton = (props: Props) =>
  <label htmlFor={`subtle-button-file-${props.id}`}>
    <Button component='span' rounded variant='subtle'>
      {props.children}
    </Button>
    <Input
      type='file'
      {...props}
      id={`subtle-button-file-${props.id}`}
      sx={{ display: 'none' }}
    />
  </label>

export const FileUploadNotesButton = (props: Props) =>
  <label htmlFor={`subtle-button-file-${props.id}`}>
    <Button
      color='inherit'
      component='span'
      startIcon={<EditRoundedIcon />}
      sx={{
        transition: 'opacity 0.1s linear',
      }}
    >
      {props.children}
    </Button>

    <Input
      type='file'
      {...props}
      id={`subtle-button-file-${props.id}`}
      sx={{ display: 'none' }}
    />
  </label>

export const FileUploadNotesButtonImage = (props: Props) =>
  <label htmlFor={`subtle-button-file-${props.id}`}>
    <Button
      color='primary'
      component='span'
      startIcon={<AddRoundedIcon />}
      sx={{
        transition: 'opacity 0.1s linear',
      }}
    >
      {props.children}
    </Button>

    <Input
      type='file'
      {...props}
      id={`subtle-button-file-${props.id}`}
      sx={{ display: 'none' }}
    />
  </label>
