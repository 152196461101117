/* eslint-disable unicorn/filename-case */
import { createPluginFactory } from '@udecode/plate'

import { onDropCloud, onPasteCloud } from 'src/components/pages/Notes/Modern/image/handlers'
import { ImageElement } from 'src/components/pages/Notes/Modern/image/ImageComponentProps'

export const KEY_CLOUD = 'cloud'

export const createCloudImagePlugin = createPluginFactory({
  key: KEY_CLOUD,
  isElement: true,
  component: ImageElement,
  handlers: {
    onDrop: editor => event => onDropCloud(editor, event),
    onPaste: editor => event => onPasteCloud(editor, event),
  },
})
