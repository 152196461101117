/* eslint-disable react-hooks/exhaustive-deps */
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { Dialog, Divider, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'

import EditCollaboratorsComponent from 'src/components/pages/Projects/ViewProject/InviteCollaborators/InviteCollaboratorsComponent'
import type Project from 'src/models/Project'

type Props = {
  close: () => void
  open: boolean
  project?: Project
  setProject: React.Dispatch<React.SetStateAction<Project | undefined>>
}

const InviteCollaborators = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))
  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='sm'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Stack width={1}>
        <Stack alignItems='center' direction='row' justifyContent='space-between' paddingTop={2} paddingX={2} width={1}>
          <Typography variant='h3'>
            Manage Collaborators
          </Typography>
          <IconButton onClick={() => props.close()}>
            <CloseRoundedIcon color='disabled' />
          </IconButton>
        </Stack>

        <Stack paddingBottom={4} paddingX={2} spacing={2} width={1}>
          <Typography color={themeItem.palette.text.label} variant='body2'>
            A release is a shared work area for a team. You can collaborate with people outside your team by
            adding them as Collaborators to your release.
          </Typography>
          <Divider />
        </Stack>

        <Stack width={1}>
          <EditCollaboratorsComponent
            project={props.project}
            updatedFields={project => props.setProject(project)}
          />
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default InviteCollaborators
