/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { v4 } from 'uuid'

import type { IconType } from 'src/components/form-elements/IconPicker/iconType'
import type { ArtistObject } from 'src/models/Distribution'

export const BLOCK_TYPE = [
  'link',
  'social',
  'header',
  'text',
  'chat',
  'image',
  'video_embed',
  'next_release',
  'latest_release',
  'facebook',
  'twitter',
  'youtube',
  'daily_motion',
  'vevo',
  'vimeo',
  'twitch',
  'audiomack',
  'soundcloud',
  'spotify',
  'instagram',
  'threads',
  'opensea',
  'snapchat',
  'tiktok',
  'left_divider',
  'card_block',
  'bandsintown',
  'seated',
] as const

export type BioLinkBlock = typeof BLOCK_TYPE[number]

export const LINK_TYPE = [
  'Smartlink',
  'Presave',
  'Shortlink',
  'Biolink',
] as const

export const EVENT_TYPE = [
  'View',
  'Presave',
  'Click',
] as const

export type ServicesList = {
  label: string
  placeholder: string
  value: string
  id: string
  customTitle: string
  save?: boolean
}

export type SocialList = {
  label: string
  value: string
}

export type BioLinkListItem = {
  id: string
  blockType: BioLinkBlock

  linkDestinationUrl: string
  linkPrimaryText: string
  linkSecondaryText: string
  videoEmbedUrl: string
  imageUrl: string
  textContent: string
  socialIcons: SocialList[]
  header: boolean
  embedHtml: string
  active: boolean
  icon: IconType
}

export type BandsintownArtist = {
  id: string
  name: string
  image_url: string
  tracker_count: number
  upcoming_event_count: number
  url: string
}

export type BandsintownEvents = {
  id: string
  datetime: string
  venue: {
    country: string
    city: string
    name: string
    location: string
    region: string
    type: string
  }
  url: string
}

export type SeatedEvents = {
  data:
  {
    id: string
    type: string
    attributes: {
      'venue-display-name': string
      'venue-formatted-address': string
      'primary-button-text': string
      'primary-link-url': string
      'starts-at-date-local': string
    }
  }[]
}

export class MarketingLinkFan {
  id = ''
  createdAt = new Date()
  marketingLinkId = 'Unknown'
  country = 'Unknown'
  email = 'Unknown'
  name = 'Unknown'
  platform = 'Unknown'
  premium = false

  constructor(dto: Partial<MarketingLinkFan>) {
    Object.assign(this, dto)
  }
}

export class AppleMusicPresave {
  id?: string
  createdAt = new Date()
  marketingLinkId?: string
  accessToken?: string

  constructor(dto: Partial<AppleMusicPresave>) {
    Object.assign(this, dto)
  }
}

export type AppleMusicPresaveDto = Partial<AppleMusicPresave>
export class MarketingEvent {
  id?: string
  type: typeof EVENT_TYPE[number] = EVENT_TYPE[0]
  createdAt = new Date()

  marketingLinkId?: string
  referrer?: string
  destination?: string
  presavePlatform?: string

  browser?: string
  operatingSystem?: string
  country?: string
  city?: string

  name?: string
  email?: string
  age?: number
  uuid?: string

  constructor(dto: Partial<MarketingEvent>) {
    Object.assign(this, dto)
  }
}

export type MarketingEventDto = Partial<MarketingEvent>

export class MarketingAnalyticsDates {
  _id: Date
  count: number

  constructor(dto: Partial<MarketingAnalyticsDates>) {
    Object.assign(this, dto)
    this._id = new Date(dto._id ?? '')
    this.count = dto.count ?? 0
  }
}

export type MarketingAnalyticsDatesDto = Partial<MarketingAnalyticsDates>

export class MarketingAnalyticsString {
  _id: string
  count: number
  country?: string

  constructor(dto: Partial<MarketingAnalyticsString>) {
    Object.assign(this, dto)
    this._id = dto._id ?? ''
    this.count = dto.count ?? 0
    this.country = dto.country
  }
}

export type MarketingAnalyticsStringDto = Partial<MarketingAnalyticsString>

export class MarketingLink {
  id?: string
  type: typeof LINK_TYPE[number] = LINK_TYPE[0]

  target = ''
  releaseDate: Date | null

  title: string
  artists: string[] = []

  artworkUrl: string
  artwork?: File

  subdomain = ''
  domain = ''
  url = ''
  descriptionText = ''

  seoTitle = ''
  seoDescription = ''

  facebookPixel = ''
  googleAnalytics = ''
  tiktokPixel = ''
  googleTagManager = ''
  snapchatPixel = ''

  privacyPolicy = ''
  termsAndConditions = ''

  marketingLinkName = ''

  spotifyPresaveUrl = ''
  appleMusicPresaveUrl = ''
  deezerPresaveUrl = ''

  createdAt: Date

  autoscan = false
  upc = ''
  isrc = ''

  tags: string[] = []
  projectId = ''

  socialList: SocialList[] = []
  inputList: ServicesList[] = []

  bioLinkList: BioLinkListItem[] = []
  bioLinkButtonColor = '#ffffff'

  organisationId = ''
  useOrgDefaultSocialIcons = false

  theme: string
  palette: string
  backgroundColor: string
  foreverPresave = false
  useDefaultPixels = false
  colorStyle: string
  buttonStyle: string
  fontStyle: string

  spotifyFollowArtists: ArtistObject[] = []

  scanLinkPrivate = ''
  completeLink?: string
  socialPositionBottom = false
  showAllReleases = false

  active = true

  constructor(dto: Partial<MarketingLink>) {
    Object.assign(this, dto)
    this.title = dto.title ?? ''
    this.releaseDate = new Date(dto.releaseDate ?? '')
    this.artworkUrl = dto.artworkUrl ?? ''
    this.createdAt = new Date(dto.createdAt ?? '')
    this.theme = dto.theme ?? 'Vibrant'
    this.palette = dto.palette ?? 'Light'
    this.completeLink = dto.completeLink
    this.backgroundColor = dto.backgroundColor ?? '#ffffff'
    this.colorStyle = dto.colorStyle ?? 'color'
    this.buttonStyle = dto.buttonStyle ?? 'rounded'
    this.fontStyle = dto.fontStyle ?? 'default'
    this.inputList = dto.inputList
      ? dto.inputList.map(item => ({
        label: item.label,
        value: item.value,
        id: v4(),
        placeholder: item.placeholder,
        customTitle: item.customTitle ?? '',
      }))
      : []
  }
}

export type MarketingLinkDto = Partial<MarketingLink>
