/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-identical-functions */
import CloseIcon from '@mui/icons-material/Close'
import { Button, Card, Dialog, IconButton, Stack, TextField, Typography } from '@mui/material'
import type { ChangeEvent } from 'react'
import { useState } from 'react'
import { v4 } from 'uuid'

import { savePartialProject } from 'src/api/projects'
import { createReleeseDocument } from 'src/api/taskboards'
import CustomFieldFreeModal from 'src/components/pages/Task/TaskViews/TaskViewComponents/CustomFieldFreeComponent'
import EmbedCard from 'src/components/pages/Task/TaskViews/TaskViewComponents/EmbedCard'
import { useAuth } from 'src/components/providers/AuthProvider'
import type Project from 'src/models/Project'
import { EmbeddedItem } from 'src/models/Taskboard'

type Props = {
  close: () => void
  open: boolean
  setProject: React.Dispatch<React.SetStateAction<Project | undefined>>
  project: Project
  type?: string
}

export const EMBED_TYPES = [
  'HTML',
  'Google Sheet',
  'Google Doc',
  'Google Form',
  'Google Calendar',
  'Airtable Base',
  'DISCO',
  'YouTube',
  'Soundcloud',
  'Spotify',
]

const AddProjectEmbedModal = (props: Props) => {
  const { currentOrganisation } = useAuth()
  const [embedName, setEmbedName] = useState('')
  const [embedContent, setEmbedContent] = useState('')
  const [embedType, setEmbedType] = useState(props.type ?? 'HTML')

  const handleContentChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmbedContent(event.target.value)
  }

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmbedName(event.target.value)
  }

  const handleClick = async () => {
    if (props.project.id) {
      if (embedType === 'Releese Doc') {
        await createReleeseDocument()
          .then(async item => {
            await handleAddItem(item.projectId ?? '')
          })
      } else if (embedType === 'Releese Sheet') {
        await handleAddItem('Sheet')
      } else {
        await handleAddItem(embedContent)
      }
    }
  }

  const handleAddItem = async (content: string) => {
    if (props.project.id) {
      const newItem = new EmbeddedItem({
        id: v4(),
        title: embedName,
        embedType,
        content,
      })
      await savePartialProject({
        ...props.project,
        embeddedItems: props.project.embeddedItems.length > 0
          ? [...props.project.embeddedItems, newItem]
          : [newItem] })
        .then(project => props.setProject(project))
        .then(() => props.close())
      setEmbedName('')
      setEmbedType('')
    }
  }

  return (
    <>
      {currentOrganisation?.membershipTier === 'FREE'
        ? <CustomFieldFreeModal
          close={props.close}
          open={props.open}
        />
        : <Dialog
          BackdropProps={{
            timeout: 500,
          }}
          closeAfterTransition
          fullScreen
          fullWidth
          maxWidth='xs'
          onClose={props.close}
          open={props.open}
          sx={{
            justifyContent: 'center',
          }}
        >
          <Stack marginX='auto' maxWidth='lg' paddingX={3} paddingY={3} spacing={2} width={1}>
            <Stack
              alignItems='center'
              direction='row'
              justifyContent='space-between'
              width='100%'
            >
              <Typography variant='h3'>
                Add Item
              </Typography>
              <IconButton onClick={() => props.close()}>
                <CloseIcon color='disabled' />
              </IconButton>
            </Stack>
            <Stack spacing={4} width={1}>
              <Card sx={{ width: 1 }} variant='outlined'>
                <Stack spacing={2}>
                  <Stack paddingTop={2} paddingX={2}>
                    <Typography variant='subtitle1'>
                      Type
                    </Typography>
                  </Stack>
                  <Stack overflow='auto' paddingBottom={2} width={1}>
                    <Stack direction='row' width='fit-content'>
                      {EMBED_TYPES.map(embedOption =>
                        <EmbedCard
                          embedOption={embedOption}
                          key={`project-${embedOption}`}
                          selected={embedType}
                          setEmbedType={setEmbedType}
                        />)}
                    </Stack>
                  </Stack>
                </Stack>
              </Card>
              <TextField
                fullWidth
                inputProps={{ maxLength: 50 }}
                label='Title'
                onChange={handleNameChange}
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    void handleClick()
                  }
                }}
                placeholder='Title'
                value={embedName}
              />
              {embedType !== 'Releese Doc' && embedType !== 'Releese Sheet' &&
              <TextField
                fullWidth
                label={embedType === 'HTML' ? 'Content' : 'URL'}
                multiline
                onChange={handleContentChange}
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    void handleClick()
                  }
                }}
                placeholder={embedType === 'HTML' ? '<iframe></iframe>' : 'https://'}
                rows={embedType === 'HTML' ? 6 : 1}
                value={embedContent}
              />}

              <Stack direction='row' justifyContent='flex-end' marginLeft='auto' marginTop={2} spacing={1} width={1}>
                <Button
                  onClick={() => props.close()}
                  variant='text'
                >
                  Cancel
                </Button>
                <Button
                  disabled={embedName.length === 0 ||
                (embedContent.length === 0 && embedType !== 'Releese Doc' && embedType !== 'Releese Sheet') ||
                embedType.length === 0}
                  onClick={() => handleClick()}
                >
                  Add Item
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Dialog>}
    </>
  )
}

export default AddProjectEmbedModal
