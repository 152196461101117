import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded'
import ImageRoundedIcon from '@mui/icons-material/ImageRounded'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import { Button, Card, LinearProgress, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'

import SearchField from 'src/components/form-elements/SearchField'
import { useAuth } from 'src/components/providers/AuthProvider'
import { NavLink } from 'src/components/SafeRouterLink'
import { bytesToSize } from 'src/utils/fileUtils'

type Props = {
  searchQuery: string
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>
  images: boolean
  setImages: React.Dispatch<React.SetStateAction<boolean>>
  files: boolean
  setFiles: React.Dispatch<React.SetStateAction<boolean>>
  music: boolean
  setMusic: React.Dispatch<React.SetStateAction<boolean>>
  documents: boolean
  setDocuments: React.Dispatch<React.SetStateAction<boolean>>
}

const FilesMenu = (props: Props) => {
  const { currentOrganisation } = useAuth()
  const themeItem = useTheme()
  const matches = useMediaQuery(themeItem.breakpoints.down('lg'))

  return (
    <Stack
      alignItems='center'
      direction='column'
      display={matches ? 'none' : 'flex'}
      paddingTop={2}
      sx={{ flexShrink: 0, maxHeight: '100%', overflow: 'auto' }}
      width={matches ? 1 : 280}
    >
      <Stack paddingX={2} width={1}>
        <SearchField
          fullWidth
          onChange={event => props.setSearchQuery(event.target.value)}
          sx={{ marginTop: 0 }}
          value={props.searchQuery}
        />
      </Stack>

      <Stack paddingTop={3} paddingX={2} width={1}>
        <Button
          color='primary'
          component={NavLink}
          rounded
          size='large'
          startIcon={<HomeRoundedIcon />}
          to='/files'
        >
          Recent Files
        </Button>
      </Stack>

      <Stack paddingTop={2} paddingX={2} spacing={1} width={1}>
        <Button
          color='secondary'
          onClick={() => props.setImages(!props.images)}
          rounded
          variant={props.images ? 'subtle' : 'text'}
        >
          <Stack alignItems='center' direction='row' spacing={1} width={1}>
            <ImageRoundedIcon color='secondary' fontSize='medium' />
            <Typography
              color={props.images
                ? themeItem.palette.secondary.main
                : themeItem.palette.text.primary}
              variant='body2'
            >
              Images
            </Typography>
          </Stack>
        </Button>

        <Button
          color='success'
          onClick={() => props.setFiles(!props.files)}
          rounded
          variant={props.files ? 'subtle' : 'text'}
        >
          <Stack alignItems='center' direction='row' spacing={1} width={1}>
            <ArticleRoundedIcon color='success' fontSize='medium' />
            <Typography
              color={props.files
                ? themeItem.palette.success.main
                : themeItem.palette.text.primary}
              variant='body2'
            >
              Files
            </Typography>
          </Stack>
        </Button>

        <Button
          color='info'
          onClick={() => props.setMusic(!props.music)}
          rounded
          variant={props.music ? 'subtle' : 'text'}
        >
          <Stack alignItems='center' direction='row' spacing={1} width={1}>
            <MusicNoteRoundedIcon color='info' fontSize='medium' />
            <Typography
              color={props.music
                ? themeItem.palette.info.main
                : themeItem.palette.text.primary}
              variant='body2'
            >
              Audio
            </Typography>
          </Stack>
        </Button>

        <Button
          color='primary'
          onClick={() => props.setDocuments(!props.documents)}
          rounded
          variant={props.documents ? 'subtle' : 'text'}
        >
          <Stack alignItems='center' direction='row' spacing={1} width={1}>
            <AttachFileIcon color='primary' fontSize='medium' />
            <Typography
              color={props.documents
                ? themeItem.palette.primary.main
                : themeItem.palette.text.primary}
              variant='body2'
            >
              Documents
            </Typography>
          </Stack>
        </Button>
      </Stack>

      <Stack marginBottom={2} marginTop='auto' paddingX={2} width={1}>
        <Card sx={{ width: 1 }} variant='outlined'>
          <Stack padding={2} spacing={1} width={1}>
            <Typography variant='subtitle1'>
              Storage
            </Typography>
            {currentOrganisation &&
            <Typography
              color={currentOrganisation.storageUsed < currentOrganisation.storageAllocation
                ? 'inherit'
                : 'error'}
              variant='body2'
            >
              <span style={{ fontWeight: 'bold' }}>
                {bytesToSize(currentOrganisation.storageUsed)}
              </span>
              {' '}
              of
              {' '}
              {bytesToSize(currentOrganisation.storageAllocation)}
            </Typography>}
            <Stack sx={{ marginTop: 2 }} width={1}>
              {currentOrganisation &&
                  currentOrganisation.storageUsed < currentOrganisation.storageAllocation
                ? <LinearProgress
                  color='primary'
                  sx={{ height: 8 }}
                  value={(currentOrganisation.storageUsed / currentOrganisation.storageAllocation) * 100}
                  variant='determinate'
                />
                : <LinearProgress
                  color='error'
                  sx={{ height: 8 }}
                  value={100}
                  variant='determinate'
                />}
            </Stack>
          </Stack>
        </Card>
      </Stack>
    </Stack>
  )
}

export default FilesMenu
