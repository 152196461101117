/* eslint-disable max-lines */
import EditIcon from '@mui/icons-material/Edit'
import { LoadingButton } from '@mui/lab'
import { Autocomplete, Box, Button, Divider, Grid, Paper, Stack, TextField, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import MuiPhoneNumber from 'material-ui-phone-number-2'
import type { ChangeEvent } from 'react'
import { useEffect, useState } from 'react'

import { FileUploadButton, FileUploadButtonBase } from 'src/components/form-elements/FileUpload'
import TimezoneSelectField from 'src/components/form-elements/TimezonesSelectField'
import { useAuth } from 'src/components/providers/AuthProvider'
import { countries } from 'src/data/countriesData'
import type { NewAccount } from 'src/models/Account'

const PersonalInformation = () => {
  const { currentAccount, updateCurrentAccount, refreshCurrentAccount } = useAuth()

  const [loading, setLoading] = useState(false)

  const [firstName, setFirstName] = useState(currentAccount.firstName)
  const [lastName, setLastName] = useState(currentAccount.lastName)
  const [phone, setPhone] = useState(currentAccount.phone.phoneNumber)
  const [addressLine1, setAddressLine1] = useState(currentAccount.addressLine1)
  const [addressLine2, setAddressLine2] = useState(currentAccount.addressLine2)
  const [country, setCountry] = useState(currentAccount.country)
  const [postalCode, setPostalCode] = useState(currentAccount.postalCode)
  const [city, setCity] = useState(currentAccount.city)
  const [region, setRegion] = useState(currentAccount.region)
  const [timezone, setTimezone] = useState(currentAccount.timezone)
  const [profilePicture, setProfilePicture] = useState<File | undefined>()
  const [picturePreview, setPicturePreview] = useState<string | null>(currentAccount.profilePictureUrl)
  const [saveError, setSaveError] = useState('')

  const [valueBirthDate, setBirthDate] = useState<Date>(currentAccount.birthday)

  const handleChangeBirthDate = (newValue: Date | null) => {
    if (newValue) {
      setBirthDate(new Date(newValue))
    }
  }

  const selectedCountry = countries.find(
    item => item.countryShortCode === country
  )

  const handlePhoneChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string) => {
    if (typeof event === 'string') {
      setPhone(event)
    }
  }

  const handleTimezoneChange = (timezoneNew: string) => {
    setTimezone(timezoneNew)
  }

  useEffect(() => {
    if (profilePicture) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setPicturePreview(reader.result as string)
      }
      reader.readAsDataURL(profilePicture)
    }
  }, [profilePicture])

  const handleArtworkUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]

    if (file === undefined) return

    if (file.type.startsWith('image')) {
      setProfilePicture(file)
    } else {
      setProfilePicture(undefined)
    }
  }

  const save = async () => {
    setLoading(true)
    const account: NewAccount = {
      ...currentAccount,
      firstName,
      lastName,
      addressLine1,
      addressLine2,
      city,
      country,
      region,
      postalCode,
      birthday: valueBirthDate,
      profilePicture,
      timezone,
      phone: {
        phoneNumber: phone,
        code: 'US',
      },
    }
    await updateCurrentAccount(account)
      .then(() => setLoading(false))
      .then(refreshCurrentAccount)
      .catch(() => {
        setSaveError('Your image upload failed, it might contain explicit content')
        setLoading(false)
      })
      .finally(() => setLoading(false))
  }

  return (
    <Stack direction='column' maxWidth={1096} padding={4} spacing={2}>
      <Typography variant='h3'>Personal Information</Typography>
      <Stack direction='column' spacing={4} >
        <Stack alignItems='center' direction='row' justifyContent='flex-start' spacing={1} width={1}>
          <FileUploadButtonBase id='upload-organisation-image' onChange={handleArtworkUpload}>
            <Stack alignItems='center' direction='row' spacing={1}>
              <Paper
                elevation={0}
                sx={{
                  position: 'relative',
                  width: 96,
                  height: 96,
                  padding: 0,
                  borderRadius: '50%',
                  transition: '0.3s',
                  border: theme => {
                    if (!picturePreview) return `2px dashed ${theme.palette.primary.main}`
                  },
                  background: theme => theme.palette.background.input,
                  '&:hover': {
                    opacity: 0.5,
                    cursor: 'pointer',
                    transition: 'all 0.2s ease',
                  },
                }}
              >
                <Stack alignItems='center' direction='row' height={1} spacing={1} width={1} >
                  {picturePreview ? <img
                    alt='preview'
                    height={96}
                    src={picturePreview}
                    style={{
                      objectFit: 'cover',
                      borderRadius: '50%',
                      aspectRatio: '1',
                    }}
                    width={96}
                  />
                    : <Stack
                      alignContent='center'
                      alignItems='center'
                      height={1}
                      justifyContent='center'
                      justifyItems='center'
                      width={1}
                    >
                      <EditIcon
                        color='primary'
                        fontSize='large'
                        sx={{ margin: 'auto' }}
                      />
                    </Stack>}

                </Stack>
              </Paper>
            </Stack>
          </FileUploadButtonBase>
          <FileUploadButton id='upload-organisation-image' onChange={handleArtworkUpload}>
            Upload
          </FileUploadButton>
        </Stack>
        {saveError.length > 0 &&
        <Typography color='error' variant='helperText'>
          {saveError}
        </Typography>}
        <Grid
          columnSpacing={2}
          container
          rowSpacing={4}
          width={1}
        >
          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <TextField
              fullWidth
              id='firstname'
              inputProps={{ maxLength: 30 }}
              label='First name'
              onChange={event => setFirstName(event.target.value)}
              placeholder='First name'
              sx={{ marginTop: 0 }}
              value={firstName}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <TextField
              fullWidth
              id='lastname'
              inputProps={{ maxLength: 30 }}
              label='Last name'
              onChange={event => setLastName(event.target.value)}
              placeholder='Last name'
              sx={{ marginTop: 0 }}
              value={lastName}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <TextField
              disabled
              fullWidth
              id='email'
              inputProps={{ maxLength: 40 }}
              label='Email address'
              placeholder='Email address'
              sx={{ marginTop: 0 }}
              value={currentAccount.email}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <MuiPhoneNumber
              defaultCountry='us'
              fullWidth
              label='Phone number'
              onChange={handlePhoneChange}
              sx={{
                marginTop: 0,
              }}
              value={phone}
              variant='filled'
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
            <Divider />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <TextField
              fullWidth
              id='address1'
              inputProps={{ maxLength: 30 }}
              label='Address line 1'
              onChange={event => setAddressLine1(event.target.value)}
              placeholder='Address'
              sx={{ marginTop: 0 }}
              value={addressLine1}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <TextField
              fullWidth
              id='address2'
              inputProps={{ maxLength: 30 }}
              label='Address line 2'
              onChange={event => setAddressLine2(event.target.value)}
              placeholder='Address'
              sx={{ marginTop: 0 }}
              value={addressLine2}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <Autocomplete
              autoHighlight
              autoSelect
              fullWidth
              getOptionLabel={option => option.countryName}
              onChange={(_, newValue) => setCountry(newValue?.countryShortCode ?? '')}
              options={countries}
              renderInput={params =>
                <TextField
                  {...params}
                  label='Country'
                  placeholder='Select a country'
                  sx={{ marginTop: 0,  height: 42 }}
                />}
              renderOption={(renderProps, option) =>
                <Box component='li' {...renderProps}>
                  {option.countryName}
                </Box>}
              sx={{ marginTop: 0, height: 42 }}
              value={countries.find(
                countryObject => countryObject.countryShortCode === country
              )}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <Autocomplete
              autoHighlight
              autoSelect
              fullWidth
              getOptionLabel={option => option.name}
              onChange={(_, newValue) => setRegion(newValue?.shortCode ?? '')}
              options={selectedCountry?.regions ?? []}
              renderInput={params =>
                <TextField
                  {...params}
                  label='Region'
                  placeholder='Select a region'
                  sx={{ marginTop: 0,  height: 42 }}
                />}
              renderOption={(renderProps, option) =>
                <Box component='li' {...renderProps}>
                  {option.name}
                </Box>}
              sx={{ marginTop: 0,  height: 42 }}
              value={selectedCountry?.regions.find(
                regionObject => regionObject.shortCode === region
              )}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <TextField
              fullWidth
              id='city'
              inputProps={{ maxLength: 20 }}
              label='City'
              onChange={event => setCity(event.target.value)}
              placeholder='City'
              sx={{ marginTop: 0 }}
              value={city}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <TextField
              fullWidth
              id='postal'
              inputProps={{ maxLength: 20 }}
              label='Postal code'
              onChange={event => setPostalCode(event.target.value)}
              placeholder='ZIP'
              sx={{ marginTop: 0 }}
              value={postalCode}
            />
          </Grid>
          <Divider />
          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>

            <DatePicker
              format='MMMM DD, YYYY'
              label='Date of birth'
              onChange={value => handleChangeBirthDate(dayjs(value).toDate())}
              sx={{
                marginTop: 0,
                width: 1,
              }}
              value={dayjs(valueBirthDate)}
              views={['year', 'month', 'day']}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <TimezoneSelectField
              setInputSelected={handleTimezoneChange}
              timezoneName={timezone}
            />
          </Grid>
        </Grid>
      </Stack>

      <Stack direction='row' justifyContent='flex-end' spacing={2}>
        <Button variant='text'>Cancel</Button>
        <LoadingButton
          loading={loading}
          onClick={save}
          type='submit'
          variant='contained'
        >
          Save changes
        </LoadingButton>
      </Stack>
    </Stack>
  )
}

export default PersonalInformation

