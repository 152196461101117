import 'src/styles/Custom/better-ui.css'

import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import { StrictMode } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import App from './components/App'
import initializeAxios from 'src/api/axios'
import reportWebVitals from 'src/utils/reportWebVitals'

initializeAxios()
dayjs.extend(localeData)
ReactDOM.render(
  // Add static providers here
  <StrictMode>
    <div
      id='releesebody'
      style={{
        inset: 0,
      }}
    >
      <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DndProvider backend={HTML5Backend}>
            <App />
          </DndProvider>
        </LocalizationProvider>
      </BrowserRouter>
    </div>
  </StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.info))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
