/* eslint-disable no-multi-str */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import { Card, CardActionArea, Stack, Typography, useTheme } from '@mui/material'
import { useState } from 'react'

import ExplainModal from 'src/components/pages/Analytics/ExplainModal'
import type { AnalyticsMonthlyChart } from 'src/models/Analytics'

type Props = {
  readonly data: AnalyticsMonthlyChart[]
  readonly selectedRecording: string
  readonly periods: string[]
}

const AnalyticsDistributionLifetime = (props: Props) => {
  const themeItem = useTheme()
  const [explain, setExplain] = useState(false)
  const totalStreams = props.data.filter(item => props.periods.length === 0 || props.periods.includes(item._id))
    .flatMap(({ streams }) => streams).reduce((partialSum, a) => partialSum + a, 0) ?? 0
  return (
    <Stack height={1} width={1}>
      <Card
        elevation={0}
        sx={{
          height: 1,
          background: theme => theme.palette.background.elevatedCard,
        }}
      >
        <CardActionArea onClick={() => setExplain(true)}>
          <Stack height={1} justifyContent='space-between' padding={2} spacing={3}>
            <Stack direction='row' spacing={2} width={1}>
              <Stack spacing={2} width={1}>
                <Typography variant='body1'>
                  Streams
                </Typography>
                <Typography variant='h3'>
                  {totalStreams.toLocaleString('en-US')}
                </Typography>
              </Stack>
              <Stack spacing={2}>
                <MusicNoteRoundedIcon color='primary' sx={{ fontSize: 48 }} />
              </Stack>
            </Stack>
            <Typography variant='textLabel'>
              {props.selectedRecording.length > 0
                ? 'Total distribution streams since the release of this recording'
                : 'Total distribution streams'}
            </Typography>
          </Stack>
        </CardActionArea>
      </Card>
      <ExplainModal
        close={() => setExplain(false)}
        color={themeItem.palette.primary.main}
        description='Streams refer to unique streams on platforms such as Spotify, Apple Music, Tidal, Amazon Music &
        many more.'
        icon={<MusicNoteRoundedIcon
          sx={{
            color: theme => theme.palette.primary.contrastText,
            fontSize: '64px',
          }}
        />}
        open={explain}
        title='Streams'
      />
    </Stack>
  )
}

export default AnalyticsDistributionLifetime

