/* eslint-disable sonarjs/no-nested-template-literals */
import axios from 'axios'

import type { MarketingAnalyticsDatesDto, MarketingAnalyticsStringDto } from 'src/models/Marketing'
import { MarketingAnalyticsDates, MarketingAnalyticsString } from 'src/models/Marketing'

const PATH = '/projectlinkdata' as const

// leaving this ownerId undefined returns all releases belonging to current user
export const getClicksLinkProject = (startDate: Date, endDate: Date, services: string[], projectId: string) =>
  axios.get<MarketingAnalyticsDatesDto[]>(`${PATH}/analytics/clicks/${Number(startDate).toString()}/
  ${Number(endDate).toString()}/${projectId}?${services.map((item, index) =>
  `services[${index}]=${item}`).join('&')}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsDates(dto)))

export const getPresavesLinkProject = (startDate: Date, endDate: Date, services: string[], projectId: string) =>
  axios.get<MarketingAnalyticsDatesDto[]>(`${PATH}/analytics/presaves/${Number(startDate).toString()}/
      ${Number(endDate).toString()}/${projectId}?${services.map((item, index) =>
  `services[${index}]=${item}`).join('&')}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsDates(dto)))

export const getViewsLinkProject = (startDate: Date, endDate: Date, projectId: string) =>
  axios.get<MarketingAnalyticsDatesDto[]>(`${PATH}/analytics/views/${Number(startDate).toString()}/
  ${Number(endDate).toString()}/${projectId}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsDates(dto)))

export const getDestinationsLinkProject = (startDate: Date, endDate: Date, projectId: string) =>
  axios.get<MarketingAnalyticsStringDto[]>(`${PATH}/analytics/destinations/${Number(startDate).toString()}/
  ${Number(endDate).toString()}/${projectId}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsString(dto)))

export const getDestinationsPresavesLinkProject = (startDate: Date, endDate: Date, projectId: string) =>
  axios.get<MarketingAnalyticsStringDto[]>(`${PATH}/analytics/destinations-presaves/${Number(startDate).toString()}/
      ${Number(endDate).toString()}/${projectId}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsString(dto)))

export const getViewsPerCityLinkProject = (startDate: Date, endDate: Date, projectId: string) =>
  axios.get<MarketingAnalyticsStringDto[]>(`${PATH}/analytics/cities/views/${Number(startDate).toString()}/
  ${Number(endDate).toString()}/${projectId}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsString(dto)))

export const getClicksPerCityLinkProject = (startDate: Date, endDate: Date, services: string[], projectId: string) =>
  axios.get<MarketingAnalyticsStringDto[]>(`${PATH}/analytics/cities/clicks/${Number(startDate).toString()}/
  ${Number(endDate).toString()}/${projectId}?${services.map((item, index) => `services[${index}]=${item}`).join('&')}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsString(dto)))

export const getClicksPerCountryLinkProject = (startDate: Date, endDate: Date, services: string[], projectId: string) =>
  axios.get<MarketingAnalyticsStringDto[]>(`${PATH}/analytics/countries/clicks/${Number(startDate).toString()}/
  ${Number(endDate).toString()}/${projectId}?${services.map((item, index) => `services[${index}]=${item}`).join('&')}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsString(dto)))

export const getViewsPerCountryLinkProject = (startDate: Date, endDate: Date, projectId: string) =>
  axios.get<MarketingAnalyticsStringDto[]>(`${PATH}/analytics/countries/views/${Number(startDate).toString()}/
  ${Number(endDate).toString()}/${projectId}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsString(dto)))

export const getViewsPerSourceLinkProject = (startDate: Date, endDate: Date, projectId: string) =>
  axios.get<MarketingAnalyticsStringDto[]>(`${PATH}/analytics/sources/views/${Number(startDate).toString()}/
  ${Number(endDate).toString()}/${projectId}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsString(dto)))

export const getClicksPerSourceLinkProject = (startDate: Date, endDate: Date, services: string[], projectId: string) =>
  axios.get<MarketingAnalyticsStringDto[]>(`${PATH}/analytics/sources/clicks/${Number(startDate).toString()}/
  ${Number(endDate).toString()}/${projectId}?${services.map((item, index) => `services[${index}]=${item}`).join('&')}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsString(dto)))

export const getAllViewsPerBrowserLinkProject = (startDate: Date, endDate: Date, projectId: string) =>
  axios.get<MarketingAnalyticsStringDto[]>(`${PATH}/analytics/browser/views/${Number(startDate).toString()}/
    ${Number(endDate).toString()}/${projectId}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsString(dto)))

export const getAllClicksPerBrowserLinkProject =
(startDate: Date, endDate: Date, services: string[], projectId: string) =>
  axios.get<MarketingAnalyticsStringDto[]>(`${PATH}/analytics/browser/clicks/${Number(startDate).toString()}/
    ${Number(endDate).toString()}/${projectId}?${services.map((item, index) =>
  `services[${index}]=${item}`).join('&')}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsString(dto)))

export const getAllViewsPerOsLinkProject = (startDate: Date, endDate: Date, projectId: string) =>
  axios.get<MarketingAnalyticsStringDto[]>(`${PATH}/analytics/os/views/${Number(startDate).toString()}/
    ${Number(endDate).toString()}/${projectId}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsString(dto)))

export const getAllClicksPerOsLinkProject = (startDate: Date, endDate: Date, services: string[], projectId: string) =>
  axios.get<MarketingAnalyticsStringDto[]>(`${PATH}/analytics/os/clicks/${Number(startDate).toString()}/
    ${Number(endDate).toString()}/${projectId}?${services.map((item, index) =>
  `services[${index}]=${item}`).join('&')}`)
    .then(response => response.data.map(dto => new MarketingAnalyticsString(dto)))
