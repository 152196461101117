/* eslint-disable max-lines */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import ChatBubbleRoundedIcon from '@mui/icons-material/ChatBubbleRounded'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import ImageRoundedIcon from '@mui/icons-material/ImageRounded'
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticonRounded'
import SendIcon from '@mui/icons-material/SendRounded'
import { Card, CircularProgress, Divider, IconButton, InputAdornment, Menu, Paper, Stack, TextField, Typography, useTheme } from '@mui/material'
import type { BaseEmoji } from 'emoji-mart'
import type { ChangeEvent } from 'react'
import { useEffect, useState } from 'react'
import { v4 } from 'uuid'

import { sendImage } from 'src/api/conversations'
import { getOrganisation } from 'src/api/organisation'
import { getProject, sendMessageProject } from 'src/api/projects'
import EmojiPicker from 'src/components/form-elements/EmojiPicker'
import { FileUploadIconButton } from 'src/components/form-elements/FileUpload'
import ProjectMessageItem from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/MessagesTab/ProjectMessageItem'
import { useAuth } from 'src/components/providers/AuthProvider'
import { useNotifications } from 'src/components/providers/NotificationProvider'
import type Account from 'src/models/Account'
import type Project from 'src/models/Project'
import { ProjectUpdate } from 'src/models/Project'

type Props = {
  project?: Project
  setProject: React.Dispatch<React.SetStateAction<Project | undefined>>
}

const ViewProjectMessages = (props: Props) => {
  const { notifications } = useNotifications()
  const themeColors = useTheme()

  const { currentAccount, currentOrganisation } = useAuth()
  const [projectFields, setProjectFields] = useState(props.project)
  const [loading, setLoading] = useState(true)
  const [loadingSendMessage, setLoadingSendMessage] = useState(false)
  const [members, setMembers] = useState<Account[]>([])
  const [errorMessage, setErrorMessage] = useState('')
  const [messageText, setMessageText] = useState('')
  const handleEmojiClose = () => {
    setAnchorElementEmoji(null)
  }

  const [key, setKey] = useState('key')
  const [picturePreview, setPicturePreview] = useState<string | null>()
  const [fileAttachment, setFileAttachment] = useState<File>()

  useEffect(() => {
    if (props.project) {
      setLoading(true)
      void Promise.all(props.project.collaborators?.map(member => {
        if (member.account.id.length > 0) {
          void getOrganisation(member.account.id)
            .then(account => account?.seats?.map(seat =>
              setMembers(previousState => [...previousState, seat.account])))
        }
      }))
        .finally(() => {
          setLoading(false)
        })
    }
  }, [props.project?.id])

  useEffect(() => {
    if (currentOrganisation) {
      setMembers(previousState => [...previousState, ...currentOrganisation.seats.map(seatItem => seatItem.account)])
    }
  }, [props.project?.id, currentOrganisation?.id])

  const addEmoji = (event: BaseEmoji) => {
    const symbol = event.native
    setMessageText(`${messageText + symbol} `)
  }

  const [anchorElementEmoji, setAnchorElementEmoji] = useState<HTMLElement | null>(null)
  const openEmojiMenu = Boolean(anchorElementEmoji)

  const handleEmojiClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElementEmoji(event.currentTarget)
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
  }

  const handleSendMessage = async () => {
    if (messageText.length > 0 && projectFields && !loadingSendMessage) {
      setLoadingSendMessage(true)
      await sendMessageProject(projectFields.id,
        new ProjectUpdate({ id: v4(), account: currentAccount.id, message: messageText }))
        .then(item => {
          props.setProject(item)
          setProjectFields(item)
          setMessageText('')
          setLoadingSendMessage(false)
        })
    }
    if (fileAttachment && projectFields) {
      setLoading(true)
      await sendImage(fileAttachment)
        .then(async url => {
          await sendMessageProject(projectFields.id,
            new ProjectUpdate({ id: v4(), account: currentAccount.id, message: url }))
            .then(item => {
              props.setProject(item)
              setProjectFields(item)
              setMessageText('')
            })
        })
        .finally(() => {
          setFileAttachment(undefined)
          setPicturePreview(null)
          setErrorMessage('')
          setKey(Math.random().toString(36))
          setLoading(false)
        })
    }
  }

  const addMessageFile = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) return
    if ((/\.(jpg|png|jpeg|webp)$/i).test(file.name)) {
      setFileAttachment(file)
      setErrorMessage('')
    } else {
      setErrorMessage('Please upload an image')
    }
  }

  useEffect(() => {
    if (fileAttachment) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setPicturePreview(reader.result as string)
      }
      reader.readAsDataURL(fileAttachment)
    }
  }, [fileAttachment])

  useEffect(() => {
    setFileAttachment(undefined)
    setPicturePreview(null)
    setKey(Math.random().toString(36))
  }, [props.project?.id])

  useEffect(() => {
    void getProject(props.project?.id ?? '')
      .then(item => {
        props.setProject(item)
        setProjectFields(item)
      })
  }, [notifications.length])

  return (
    <Stack height={1} width={1}>
      <Stack direction='column' flexGrow={1} height={1} justifyContent='flex-end' width={1}>
        {projectFields && projectFields.updates.length > 0 && !loading
          ? <Stack
            direction='column-reverse'
            height={1}
            overflow='auto'
            paddingX={1.5}
            paddingY={1}
            spacing={1}
          >
            {[...projectFields.updates].reverse()?.map(element =>
              <ProjectMessageItem
                key={element.timestamp.toString()}
                members={members}
                myAccount={currentAccount.id}
                taskUpdate={element}
              />)}
          </Stack>
          : <Stack
            alignItems='center'
            height={1}
            justifyContent='center'
            width='100%'
          >
            <Paper
              elevation={0}
              sx={{
                backgroundColor: theme => theme.palette.info.main,
                borderRadius: 16,
              }}
            >
              <Stack
                alignItems='center'
                justifyContent='center'
                padding={2}
              >
                <ChatBubbleRoundedIcon
                  sx={{
                    color: theme => theme.palette.primary.contrastText,
                    fontSize: '64px',
                  }}
                />
              </Stack>
            </Paper>
            <Typography
              color={themeColors.palette.text.secondary}
              sx={{
                marginTop: 1,
              }}
              variant='h4'
            >
              No Messages Exchanged
            </Typography>
            <Typography
              color={themeColors.palette.text.label}
              sx={{
                textAlign: 'center',
              }}
              variant='body1'
            >
              Send a message to keep your collaborators up to date
            </Typography>
          </Stack>}

        <Divider />
        <Stack justifyContent='center' paddingY={2}>
          {errorMessage.length > 0 &&
          <Stack
            direction='row'
            paddingTop={2}
            paddingX={2}
            spacing={1}
            width={1}
          >
            <Typography color='error' variant='body2'>
              {errorMessage}
            </Typography>
          </Stack>}
          {picturePreview &&
          <Stack
            direction='row'
            paddingBottom={1}
            paddingTop={0}
            paddingX={2}
            spacing={1}
            width={1}
          >
            <Card
              elevation={0}
              sx={{
                width: 'fit-content',
                position: 'relative',
              }}
            >
              <IconButton
                onClick={() => {
                  setFileAttachment(undefined)
                  setPicturePreview(null)
                }}
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                }}
              >
                <ClearRoundedIcon />
              </IconButton>
              <img
                alt='preview'
                height={96}
                src={picturePreview}
                style={{
                  objectFit: 'cover',
                  borderRadius: '16px',
                }}
                width={96}
              />
            </Card>

            {loading &&
            <CircularProgress color='primary' />}
          </Stack>}

          <TextField
            InputProps={{
              endAdornment: <InputAdornment position='end' style={{ height: '100%' }}>
                <FileUploadIconButton
                  id='upload-image'
                  key={key}
                  onChange={addMessageFile}
                >
                  <ImageRoundedIcon />
                </FileUploadIconButton>
                <IconButton onClick={handleEmojiClick} size='small'>
                  <InsertEmoticonIcon />
                </IconButton>
                <Divider flexItem orientation='vertical' />
                <IconButton
                  color='primary'
                  disabled={!messageText}
                  onClick={handleSendMessage}
                  size='small'
                >
                  <SendIcon />
                </IconButton>
              </InputAdornment>,
            }}
            autoComplete='off'
            fullWidth
            onChange={event => setMessageText(event.currentTarget.value)}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                void handleSendMessage()
              }
            }}
            placeholder='Aa'
            style={{ paddingLeft: 8, paddingRight: 8, marginTop: 0 }}
            value={messageText}
          />
        </Stack>
        <Menu
          anchorEl={anchorElementEmoji}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          onClose={handleEmojiClose}
          open={openEmojiMenu}
          transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <EmojiPicker emojiHandler={addEmoji} />
        </Menu>
      </Stack>
    </Stack>
  )
}

export default ViewProjectMessages
