/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-confusing-void-expression */
/* eslint-disable react-hooks/exhaustive-deps */
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import ContactsRoundedIcon from '@mui/icons-material/ContactsRounded'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded'
import { Button, Grid, IconButton, Pagination, Paper, Stack, Table, TableCell, TableHead, TableRow, Tooltip, Typography, useTheme } from '@mui/material'
import { useState } from 'react'
import { v4 } from 'uuid'

import ViewEarningsPerson from 'src/components/pages/Earnings/EarningsTabs/EarningsPerson'
import RecoupFreeModal from 'src/components/pages/Projects/EditProject/EditProjectTabs/RecordingsTab/RecordingTabs/RecoupTab/AddRecoupModalFree'
import AddProjectRecoupModal from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/AccountingTab/AddProjectTransactionModal'
import RecoupItemTable from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/AccountingTab/RecoupItemTable'
import { useAuth } from 'src/components/providers/AuthProvider'
import { Permissions } from 'src/models/Organisation'
import type Project from 'src/models/Project'
import type Recording from 'src/models/Recording'

type Props = {
  project?: Project
  recordings: Recording[]
  selectedRecording: Recording | undefined
  setProject: React.Dispatch<React.SetStateAction<Project | undefined>>
  setRecordings: React.Dispatch<React.SetStateAction<Recording[]>>
}

const ViewEarningsAccounting = (props: Props) => {
  const themeItem = useTheme()
  const { currentAccountPermissions, currentOrganisation } = useAuth()

  const [recoupModalOpen, setRecoupModalOpen] = useState(false)
  const [recordingSelected, _] = useState<Recording>()
  const [page, setPage] = useState(1)
  const uniqueIds: string[] = []

  const projectRecoups = props.project?.recoupItems.map(item => ({ ...item, asset: undefined })) ?? []

  const filteredRecordingsList = props.recordings.filter(item =>
    props.project?.organisation?.id === currentOrganisation?.id ||
    item.masterRightsHolders.some(hold => hold.account.id === currentOrganisation?.id))
    .filter(item => props.selectedRecording ? item.id === props.selectedRecording.id : true)

  const recordingRecoups = filteredRecordingsList.flatMap(recording =>
    recording.recoupItems.map(item =>
      ({ ...item, asset: recording })))
    .filter(item => props.selectedRecording ? item.asset.id === props.selectedRecording.id : true)

  const allRecoupItems = [...projectRecoups, ...recordingRecoups]
    .sort((a, b) => new Date(b.dateAdded).getTime() - new Date(a.dateAdded).getTime())
    .filter(item => item.transactionType === 'Income' || item.transactionType === 'Expense' ||
      ((item.transactionType === 'Payout' || item.transactionType === 'Advance') &&
      (props.project?.organisation?.id === currentOrganisation?.id ||
        item.organisationAdvanceId === currentOrganisation?.id)))

  const currentPageData = allRecoupItems.slice(((page * 100) / 5) - 20, (page * 100) / 5)

  const updateCurrentDisplayData = (currentPage: number) => {
    setPage(currentPage)
  }

  const allMasterRightsHolders = props.recordings
    .flatMap(({ masterRightsHolders }) => masterRightsHolders)

  const allMasterRightsHoldersUnique = recordingSelected
    ? [...new Set(recordingSelected.masterRightsHolders
      .flatMap(({ account }) => account))].filter(element => {
      const isDuplicated = uniqueIds.includes(element.id)
      if (!isDuplicated) {
        uniqueIds.push(element.id)
        return true
      }
      return false
    })
    : [...new Set(props.recordings
      .flatMap(({ masterRightsHolders }) => masterRightsHolders)
      .flatMap(({ account }) => account))].filter(element => {
      const isDuplicated = uniqueIds.includes(element.id)
      if (!isDuplicated) {
        uniqueIds.push(element.id)
        return true
      }
      return false
    })

  const totalOwnershipProject = props.recordings
    .flatMap(({ masterRightsHolders }) => masterRightsHolders)
    .flatMap(({ ownership }) => ownership)
    .reduce((partialSum, a) => partialSum + a, 0)

  const incomeProject = projectRecoups
    .filter(item => item.transactionType === 'Income')
    .reduce((a, b) => a + b.amount, 0)

  const expensesProject = projectRecoups
    .filter(item => item.transactionType === 'Expense')
    .reduce((a, b) => a + b.amount, 0)

  const payoutsProject = projectRecoups
    .filter(item => item.transactionType === 'Payout' ||
    item.transactionType === 'Advance')

    .filter(item => item.organisationAdvanceId !== currentOrganisation?.id)
  const balanceProject = incomeProject - expensesProject

  return (
    <>
      {props.project &&
      recoupModalOpen &&
      props.project.organisation?.id === currentOrganisation?.id &&
      (currentOrganisation?.membershipTier === 'ENTERPRISE' ||
      currentOrganisation?.membershipTier === 'ENTERPRISE_YEARLY') &&
      <AddProjectRecoupModal
        close={() => setRecoupModalOpen(false)}
        open={recoupModalOpen}
        orgs={allMasterRightsHoldersUnique.filter(orgItem => orgItem.id !== currentOrganisation.id)}
        project={props.project}
        recordings={props.recordings}
        setProject={props.setProject}
        setRecordings={props.setRecordings}
      />}
      {recoupModalOpen &&
      props.project?.organisation?.id === currentOrganisation?.id &&
      (currentOrganisation?.membershipTier !== 'ENTERPRISE' &&
      currentOrganisation?.membershipTier !== 'ENTERPRISE_YEARLY') &&
      <RecoupFreeModal
        close={() => setRecoupModalOpen(false)}
        open={recoupModalOpen}
      />}
      <Stack
        alignItems='center'
        direction='column'
        sx={{
          width: 1,
          height: 1,
        }}
      >
        <Stack spacing={2} width={1}>
          <Stack spacing={2} width={1}>
            <Stack alignItems='center' direction='row' justifyContent='space-between' width={1}>
              <Stack alignItems='center' direction='row' spacing={1} width={1}>
                <Typography variant='h3'>
                  Accounting
                </Typography>
                <Tooltip
                  title={
                    <Stack spacing={1}>
                      <Typography variant='subtitle2'>
                        Total income, expenses, payouts and advances per rightsholder.
                      </Typography>
                      <Typography variant='subtitle2'>
                        Results are filtered by project and by recording.
                      </Typography>
                      <Typography variant='subtitle2'>
                        Project balances are seperate from recording balances, both should be balanced to $0
                      </Typography>
                    </Stack>
                  }
                >
                  <IconButton size='small'>
                    <InfoRoundedIcon />
                  </IconButton>
                </Tooltip>
              </Stack>

            </Stack>
            {allMasterRightsHoldersUnique.some(holder => holder.id !== currentOrganisation?.id)
              ? <Stack alignItems='center' width={1}>
                <Grid container spacing={2}>
                  {allMasterRightsHoldersUnique.filter(holder => holder.id !== props.project?.organisation?.id &&
                    (props.project?.organisation?.id === currentOrganisation?.id ||
                    holder.id === currentOrganisation?.id))
                    .map(holder =>
                      <ViewEarningsPerson
                        allMasterRightsHolders={allMasterRightsHolders}
                        balanceProject={balanceProject}
                        expensesProject={expensesProject}
                        holder={holder}
                        incomeProject={incomeProject}
                        key={holder.id}
                        payoutsProject={payoutsProject}
                        recordingSelected={props.selectedRecording}
                        recordings={props.recordings}
                        totalOwnershipProject={totalOwnershipProject}
                      />)}
                </Grid>
              </Stack>
              : <Stack alignItems='center' direction='row' justifyContent='space-between' width={1}>
                <Stack alignItems='center' height={1} justifyContent='center' width={1} >
                  <Paper
                    elevation={0}
                    sx={{
                      backgroundColor: theme => theme.palette.success.main,
                      borderRadius: 16,
                    }}
                  >
                    <Stack
                      alignItems='center'
                      justifyContent='center'
                      padding={2}
                    >
                      <ContactsRoundedIcon
                        sx={{
                          color: theme => theme.palette.primary.contrastText,
                          fontSize: '64px',
                        }}
                      />
                    </Stack>
                  </Paper>
                  <Typography
                    color={themeItem.palette.text.secondary}
                    sx={{
                      marginTop: 1,
                    }}
                    variant='h4'
                  >
                    No rightsholders found found
                  </Typography>
                  <Typography
                    color={themeItem.palette.text.label}
                    sx={{
                      textAlign: 'center',
                    }}
                    variant='body1'
                  >
                    You are the only shareholder
                  </Typography>
                </Stack>
              </Stack>}
          </Stack>

          <Stack alignItems='center' justifyContent='space-between' overflow='auto' width={1}>
            <Stack spacing={2} width={1}>
              <Stack alignItems='center' direction='row' justifyContent='space-between' width={1}>
                <Stack alignItems='center' direction='row' spacing={1} width={1}>
                  <Typography variant='h3'>
                    Transactions
                  </Typography>
                  <Tooltip
                    title={
                      <Stack spacing={1}>
                        <Typography variant='subtitle2'>
                          Transactions represent individual financial items per release, recording, and rightsholder
                        </Typography>
                      </Stack>
                    }
                  >
                    <IconButton size='small'>
                      <InfoRoundedIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
                {props.project?.organisation?.id === currentOrganisation?.id &&
                <Button
                  disabled={!currentAccountPermissions?.includes(Permissions.MANAGE_PAYOUTS)}
                  onClick={() => setRecoupModalOpen(true)}
                  startIcon={<AddRoundedIcon />}
                  sx={{
                    flexShrink: 0,
                  }}
                >
                  Add transaction
                </Button>}
              </Stack>
              <Table
                sx={{
                  minWidth: 750,
                  tableLayout: 'fixed',
                }}
              >
                <TableHead
                  sx={{
                    '.MuiTableCell-root': {
                      borderBottom: 2,
                      borderBottomColor: 'divider',
                      color: 'text.secondary',
                    },
                  }}
                >
                  <TableRow>
                    <TableCell>
                      Title
                    </TableCell>
                    <TableCell>
                      Recording
                    </TableCell>
                    <TableCell>
                      Payee
                    </TableCell>
                    <TableCell>
                      Amount
                    </TableCell>
                    <TableCell>
                      Type
                    </TableCell>
                  </TableRow>
                </TableHead>
                {
                  currentPageData.map(recoup =>
                    <RecoupItemTable
                      asset={recoup.asset}
                      key={`master-rightsholder-${recoup.title}-${v4()}`}
                      recoup={recoup}
                    />)
                }
              </Table>
            </Stack>
            {
              allRecoupItems.length > 20 &&
              <Stack>
                <Pagination
                  count={allRecoupItems.length > 0 ? Math.ceil(allRecoupItems.length / 20) : 1}
                  onChange={(event, value: number) => {
                    updateCurrentDisplayData(value)
                  }}
                  page={page}
                  sx={{ marginTop: 3, marginBottom: 4, marginLeft: 'auto' }}
                />
              </Stack>
            }
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}

export default ViewEarningsAccounting
