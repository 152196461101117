/* eslint-disable max-len */
/* eslint-disable max-lines */
/* eslint-disable sonarjs/no-duplicate-string */
import { Grid, Stack, Typography, useTheme } from '@mui/material'

import ServicesItem from 'src/components/pages/Projects/DistributeModal/DistributeModalComponents/ServicesItem'
import type { ReleeseServices } from 'src/models/Distribution'
import type Project from 'src/models/Project'
import type Recording from 'src/models/Recording'

type Props = {
  readonly recordings: Recording[]
  readonly project: Project
  readonly setCart: React.Dispatch<React.SetStateAction<ReleeseServices[]>>
}

const textSx = {
  marginBottom: {
    xl: 0,
    lg: 0,
    md: 2,
    sm: 2,
    xs: 2,
  },
  marginRight: {
    xl: 2,
    lg: 2,
    md: 0,
    sm: 0,
    xs: 0,
  },
  maxWidth: {
    xl: 320,
    lg: 320,
    md: '100%',
    sm: '100%',
    xs: '100%',
  },
  minWidth: {
    xl: 320,
    lg: 320,
    md: '100%',
    sm: '100%',
    xs: '100%',
  },
}

const ServicesDistribute = (props: Props) => {
  const themeItem = useTheme()
  return (
    <Stack spacing={6} width={1}>
      <Stack
        direction={{
          xl: 'row',
          lg: 'row',
          md: 'column',
          sm: 'column',
          xs: 'column',
        }}
        width={1}
      >
        <Stack direction='column'>
          <Typography variant='h3'>
            Gain listeners
          </Typography>
          <Typography
            color={themeItem.palette.text.secondary}
            sx={textSx}
            variant='body1'
          >
            Get more ears on your music through our curated marketing services. From playlisting to reposting,
            our experts will reach out to you and execute a campaign tailored to your needs.
          </Typography>
        </Stack>
        <Grid container spacing={2}>
          <ServicesItem
            description='Gain new Spotify listeners by letting our team pitch your release to independent playlist curators.'
            platforms={['Spotify']}
            price={[250, 500, 1000, 1500]}
            process={[
              'Our marketing agent will evaluate your music.',
              'Our team will pitch your song to independent curators.',
              'Your song will be placed in playlists that fit your genre.',
            ]}
            product='Service'
            project={props.project}
            recordings={props.recordings}
            requirements={[
              'Select Spotify as a platform to distribute to.',
            ]}
            selected={false}
            setCart={props.setCart}
            subtitle='Gain new listeners on your release on Spotify by pitching your music to our network.'
            timelineCompletion={30}
            timelineStart={7}
            title='Gain Spotify listeners'
          />
          <ServicesItem
            description='Gain new YouTube viewers by letting our team pitch your release to YouTube playlists.'
            platforms={['YouTube']}
            price={[125, 300, 600, 1200, 2400]}
            process={[
              'Our marketing agent will evaluate your music.',
              'Our team will pitch your song to YouTube video playlist curators.',
              'Your song will be placed in playlists that fit your genre.',
            ]}
            product='Service'
            project={props.project}
            recordings={props.recordings}
            requirements={[
              'A video uploaded to YouTube.',
            ]}
            selected={false}
            setCart={props.setCart}
            subtitle='Gain new viewers on your release on YouTube with our advertising services.'
            timelineCompletion={30}
            timelineStart={7}
            title='Gain YouTube viewers'
          />
          <ServicesItem
            description='Gain new SoundCloud listeners by letting our team pitch your release to SoundCloud channels for reposts.'
            platforms={['SoundCloud']}
            price={[29, 59, 229, 450]}
            process={[
              'Our marketing agent will evaluate your music.',
              'Our team will pitch your song to SoundCloud channels.',
              'Your song will be reposted by fitting curators.',
            ]}
            product='Service'
            project={props.project}
            recordings={props.recordings}
            requirements={[
              'A recording uploaded to your SoundCloud channel.',
            ]}
            selected={false}
            setCart={props.setCart}
            subtitle='Gain new listeners on SoundCloud through our premiere reposting services.'
            timelineCompletion={30}
            timelineStart={7}
            title='Gain SoundCloud listeners'
          />
        </Grid>
      </Stack>
      <Stack
        direction={{
          xl: 'row',
          lg: 'row',
          md: 'column',
          sm: 'column',
          xs: 'column',
        }}
        width={1}
      >
        <Stack direction='column'>
          <Typography variant='h3'>
            Reach new audiences
          </Typography>
          <Typography
            color={themeItem.palette.text.secondary}
            sx={textSx}
            variant='body1'
          >
            Expose your music to new audiences through digital ads. Our advertising strategies are optimized to grow
            your fan and listener base.
          </Typography>
        </Stack>
        <Grid container spacing={2}>
          <ServicesItem
            description='Gain potential Spotify followers through Instagram ads managed by our advertising team.'
            platforms={['Spotify', 'Instagram']}
            price={[400, 700, 1400, 2000, 2750]}
            process={[
              'Our marketing agent will evaluate your music.',
              'Our team will create an ad with the most efficient targeting.',
              'Our team will optimize the ad targeting for optimal results.',
            ]}
            product='Ads'
            project={props.project}
            recordings={props.recordings}
            requirements={[
              'A Meta Ads Manager account.',
              'Select Spotify as a platform to distribute to.',
              'At least one vertical ad creative ready.',
            ]}
            selected={false}
            setCart={props.setCart}
            subtitle='Gain new listeners on your release on Spotify with Instagram ads.'
            timelineCompletion={30}
            timelineStart={7}
            title='Reach Spotify users via Instagram ads'
          />
          <ServicesItem
            description='Gain potential Spotify followers through TikTok ads managed by our advertising team.'
            platforms={['Spotify', 'TikTok']}
            price={[400, 700, 1400, 2000, 2750]}
            process={[
              'Our marketing agent will evaluate your music.',
              'Our team will create an ad with the most efficient targeting.',
              'Our team will optimize the ad targeting for optimal results.',
            ]}
            product='Ads'
            project={props.project}
            recordings={props.recordings}
            requirements={[
              'A TikTok Ads Manager account.',
              'Select Spotify as a platform to distribute to.',
              'At least one vertical ad creative ready.',
            ]}
            selected={false}
            setCart={props.setCart}
            subtitle='Gain new listeners on your release on Spotify with TikTok ads.'
            timelineCompletion={30}
            timelineStart={7}
            title='Reach Spotify users via TikTok ads'
          />
          <ServicesItem
            description='Gain potential Spotify followers through Facebook ads managed by our advertising team.'
            platforms={['Spotify', 'Facebook']}
            price={[400, 700, 1400, 2000, 2750]}
            process={[
              'Our marketing agent will evaluate your music.',
              'Our team will create an ad with the most efficient targeting.',
              'Our team will optimize the ad targeting for optimal results.',
            ]}
            product='Ads'
            project={props.project}
            recordings={props.recordings}
            requirements={[
              'A Meta Ads Manager account.',
              'Select Spotify as a platform to distribute to.',
              'At least one vertical ad creative ready.',
            ]}
            selected={false}
            setCart={props.setCart}
            subtitle='Gain new listeners on your release on Spotify with Facebook ads.'
            timelineCompletion={30}
            timelineStart={7}
            title='Reach Spotify users via Facebook ads'
          />
          <ServicesItem
            description='Gain potential Apple Music listeners through Instagram ads managed by our advertising team.'
            platforms={['Apple Music', 'Instagram']}
            price={[400, 700, 1400, 2000, 2750]}
            process={[
              'Our marketing agent will evaluate your music.',
              'Our team will create an ad with the most efficient targeting.',
              'Our team will optimize the ad targeting for optimal results.',
            ]}
            product='Ads'
            project={props.project}
            recordings={props.recordings}
            requirements={[
              'A Meta Ads Manager account.',
              'Select Apple Music as a platform to distribute to.',
              'At least one vertical ad creative ready.',
            ]}
            selected={false}
            setCart={props.setCart}
            subtitle='Gain new listeners on your release on Apple Music with Instagram ads.'
            timelineCompletion={30}
            timelineStart={7}
            title='Reach Apple Music users via Instagram ads'
          />
          <ServicesItem
            description='Gain potential Apple Music listeners through TikTok ads managed by our advertising team.'
            platforms={['Apple Music', 'TikTok']}
            price={[400, 700, 1400, 2000, 2750]}
            process={[
              'Our marketing agent will evaluate your music.',
              'Our team will create an ad with the most efficient targeting.',
              'Our team will optimize the ad targeting for optimal results.',
            ]}
            product='Ads'
            project={props.project}
            recordings={props.recordings}
            requirements={[
              'A TikTok Ads Manager account.',
              'Select Apple Music as a platform to distribute to.',
              'At least one vertical ad creative ready.',
            ]}
            selected={false}
            setCart={props.setCart}
            subtitle='Gain new listeners on your release on Apple Music with TikTok ads.'
            timelineCompletion={30}
            timelineStart={7}
            title='Reach Apple Music users via TikTok ads'
          />
          <ServicesItem
            description='Gain potential Apple Music listeners through Facebook ads managed by our advertising team.'
            platforms={['Apple Music', 'Facebook']}
            price={[400, 700, 1400, 2000, 2750]}
            process={[
              'Our marketing agent will evaluate your music.',
              'Our team will create an ad with the most efficient targeting.',
              'Our team will optimize the ad targeting for optimal results.',
            ]}
            product='Ads'
            project={props.project}
            recordings={props.recordings}
            requirements={[
              'A Meta Ads Manager account.',
              'Select Apple Music as a platform to distribute to.',
              'At least one vertical ad creative ready.',
            ]}
            selected={false}
            setCart={props.setCart}
            subtitle='Gain new listeners on your release on Apple Music with Facebook ads.'
            timelineCompletion={30}
            timelineStart={7}
            title='Reach Apple Music users via Facebook ads'
          />
          <ServicesItem
            description='Gain potential YouTube viewers through YouTube ads managed by our advertising team.'
            platforms={['YouTube', 'YouTube']}
            price={[400, 700, 1400, 2000, 2750]}
            process={[
              'Our marketing agent will evaluate your music.',
              'Our team will create an ad with the most efficient targeting.',
              'Our team will optimize the ad targeting for optimal results.',
            ]}
            product='Ads'
            project={props.project}
            recordings={props.recordings}
            requirements={[
              'A Google Ads Manager account.',
              'A video uploaded to YouTube.',
            ]}
            selected={false}
            setCart={props.setCart}
            subtitle='Gain new listeners on your release on YouTube with YouTube ads.'
            timelineCompletion={30}
            timelineStart={7}
            title='Reach YouTube users via YouTube ads'
          />
        </Grid>
      </Stack>
      <Stack
        direction={{
          xl: 'row',
          lg: 'row',
          md: 'column',
          sm: 'column',
          xs: 'column',
        }}
        width={1}
      >
        <Stack direction='column'>
          <Typography variant='h3'>
            Grow your fanbase
          </Typography>
          <Typography
            color={themeItem.palette.text.secondary}
            sx={textSx}
            variant='body1'
          >
            Gain more followers on your social channels through our advanced advertising feature. Campaigns last a
            minimum of one month.
          </Typography>
        </Stack>
        <Grid container spacing={2}>
          <ServicesItem
            description='Gain potential Instagram followers through Instagram ads managed by our advertising team.'
            platforms={['Instagram']}
            price={[400, 700, 1400, 2000, 2750]}
            process={[
              'Our marketing agent will evaluate your music.',
              'Our team will create two ads with the most efficient targeting.',
              'Our team will optimize the ads targeting for optimal results.',
            ]}
            product='Account management'
            project={props.project}
            recordings={props.recordings}
            requirements={[
              'A Meta Business Manager account.',
              'A Meta Ads Manager account.',
              'At least two vertical ad creative ready.',
            ]}
            selected={false}
            setCart={props.setCart}
            subtitle='Gain new fans on Instagram with our expert social media management and advertising team.'
            timelineCompletion={30}
            timelineStart={7}
            title='Gain Instagram followers'
          />
          <ServicesItem
            description='Gain potential TikTok followers through TikTok ads managed by our advertising team.'
            platforms={['TikTok']}
            price={[154, 671, 1121]}
            process={[
              'Our marketing agent will evaluate your music.',
              'Our team will create two ads with the most efficient targeting.',
              'Our team will optimize the ads targeting for optimal results.',
            ]}
            product='Account management'
            project={props.project}
            recordings={props.recordings}
            requirements={[
              'A TikTok Business Manager account.',
              'At least two vertical ad creative ready.',
            ]}
            selected={false}
            setCart={props.setCart}
            subtitle='Gain new fans on TikTok with our expert social media management and advertising team.'
            timelineCompletion={30}
            timelineStart={7}
            title='Gain TikTok followers'
          />
          <ServicesItem
            description='Gain potential Facebook followers through Facebook ads managed by our advertising team.'
            platforms={['Facebook']}
            price={[400, 700, 1400, 2000, 2750]}
            process={[
              'Our marketing agent will evaluate your music.',
              'Our team will create two ads with the most efficient targeting.',
              'Our team will optimize the ads targeting for optimal results.',
            ]}
            product='Account management'
            project={props.project}
            recordings={props.recordings}
            requirements={[
              'A Meta Business Manager account.',
              'A Meta Ads Manager account.',
              'At least two vertical ad creative ready.',
            ]}
            selected={false}
            setCart={props.setCart}
            subtitle='Gain new fans on Facebook with our expert social media management and advertising team.'
            timelineCompletion={30}
            timelineStart={7}
            title='Gain Facebook followers'
          />
        </Grid>
      </Stack>
    </Stack>
  )
}

export default ServicesDistribute
