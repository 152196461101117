/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded'
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded'
import { Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import type { DraggableLocation, DropResult  } from 'react-beautiful-dnd'
import { DragDropContext } from 'react-beautiful-dnd'
import { v4 } from 'uuid'

import { updateTaskboardGroup } from 'src/api/taskboards'
import { sendWorkspaceUpdate } from 'src/api/webSocket'
import TaskMenuBoard from 'src/components/pages/Task/TaskMenu/TaskMenuComponents/TaskMenuBoard'
import AddEmbedModalWorkspace from 'src/components/pages/Task/TaskMenu/TaskMenuModals/AddEmbedWorkspace'
import DeleteTaskGroupdModal from 'src/components/pages/Task/TaskMenu/TaskMenuModals/DeleteTaskGroupModal'
import EditTaskGroupModal from 'src/components/pages/Task/TaskMenu/TaskMenuModals/EditTaskGroupModal'
import NewDocumentModal from 'src/components/pages/Task/TaskMenu/TaskMenuModals/NewDocumentModal'
import NewTaskboardGroupModal from 'src/components/pages/Task/TaskMenu/TaskMenuModals/NewTaskboardGroupModal'
import NewTaskboardModal from 'src/components/pages/Task/TaskMenu/TaskMenuModals/NewTaskboardModal'
import { EMBED_TYPES } from 'src/components/pages/Task/TaskViews/TaskViewComponents/AddEmbedTaskboard'
import GetIconTaskSmall from 'src/components/pages/Task/TaskViews/TaskViewComponents/GetIconTaskSmall'
import type { TaskboardGroup, Workspace } from 'src/models/Taskboard'

type Props = {
  searchQuery: string
  boardGroups: TaskboardGroup[] | undefined
  refreshBoards: () => void
  activeWorkspace: Workspace | undefined
}

const reorder = (list: string[], startIndex: number, endIndex: number) => {
  const result = [...list]
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const Boards = (props: Props) => {
  const [editTaskboardGroupModalData, setEditTaskboardGroupModalData] = useState<TaskboardGroup>()
  const [newGroupBoardModal, setNewGroupBoardModalOpen] = useState(false)
  const [newBoardModalModal, setNewBoardModalOpen] = useState(false)
  const [deleteGroupBoardModal, setDeleteGroupBoardModalOpen] = useState(false)
  const [editGroupBoardModal, setEditGroupBoardModalOpen] = useState(false)
  const [newDocumentModalOpen, setNewDocumentModalOpen] = useState(false)
  const [newEmbedModalOpen, setNewEmbedModalOpen] = useState(false)
  const [embedType, setEmbedType] = useState('HTML')

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const openMoreMenu = Boolean(anchorElement)

  const handleMoreMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget)
  }
  const handleMoreMenuClose = () => {
    setAnchorElement(null)
  }

  const toggleDeleteTaskGroupModal = (toggleState: boolean, taskGroup: TaskboardGroup) => () => {
    setDeleteGroupBoardModalOpen(toggleState)
    setEditTaskboardGroupModalData(taskGroup)
  }

  const toggleEditTaskGroupModal = (toggleState: boolean, taskGroup: TaskboardGroup) => () => {
    setEditGroupBoardModalOpen(toggleState)
    setEditTaskboardGroupModalData(taskGroup)
  }

  const toggleNewTaskboardModal = (event: React.MouseEvent<HTMLElement>,
    toggleState: boolean,
    taskGroup: TaskboardGroup) => {
    handleMoreMenuOpen(event)
    setEditTaskboardGroupModalData(taskGroup)
  }

  const move = (source: string[],
    destination: string[],
    droppableSource: DraggableLocation,
    droppableDestination: DraggableLocation) => {
    if (props.boardGroups) {
      const sourceClone = [...source]
      const destinationClone = [...destination]
      const [removed] = sourceClone.splice(droppableSource.index, 1)

      destinationClone.splice(droppableDestination.index, 0, removed)

      const result: TaskboardGroup[] = props.boardGroups

      result[+droppableSource.droppableId].boardIds = sourceClone
      result[+droppableDestination.droppableId].boardIds = destinationClone

      return result
    } else {
      return
    }
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination || !props.boardGroups) {
      return
    } else {
      const sourceId = +result.source.droppableId
      const destinationId = +result.destination.droppableId

      if (sourceId === destinationId) {
        const items = reorder(props.boardGroups[sourceId].boardIds, result.source.index, result.destination.index)
        const newState = props.boardGroups
        newState[sourceId].boardIds = items

        void updateTaskboardGroup({ id: newState[sourceId].id ?? '', ...newState[sourceId] })
          .then(() => sendWorkspaceUpdate(v4()))
      } else {
        const newDataResult = move(props.boardGroups[sourceId].boardIds,
          props.boardGroups[destinationId].boardIds,
          result.source,
          result.destination)
        const newState = props.boardGroups
        if (newDataResult) {
          newState[sourceId] = newDataResult[sourceId]
          newState[destinationId] = newDataResult[destinationId]
        }

        void updateTaskboardGroup({ id: newState[sourceId].id ?? '', ...newState[sourceId] })
          .then(() => sendWorkspaceUpdate(v4()))
        void updateTaskboardGroup({ id: newState[destinationId].id ?? '', ...newState[destinationId] })
          .then(() => sendWorkspaceUpdate(v4()))
      }
    }
  }

  return (
    <>
      {newBoardModalModal &&
      <NewTaskboardModal
        close={() => setNewBoardModalOpen(false)}
        group={editTaskboardGroupModalData}
        open={newBoardModalModal}
      />}
      {newGroupBoardModal &&
      <NewTaskboardGroupModal
        activeWorkspace={props.activeWorkspace}
        close={() => setNewGroupBoardModalOpen(false)}
        open={newGroupBoardModal}
        refreshBoards={props.refreshBoards}
      />}
      {deleteGroupBoardModal && editTaskboardGroupModalData &&
      <DeleteTaskGroupdModal
        close={toggleDeleteTaskGroupModal(false, editTaskboardGroupModalData)}
        open={deleteGroupBoardModal}
        refreshBoards={props.refreshBoards}
        taskboardGroup={editTaskboardGroupModalData}
      />}
      {editGroupBoardModal && editTaskboardGroupModalData &&
      <EditTaskGroupModal
        close={toggleEditTaskGroupModal(false, editTaskboardGroupModalData)}
        group={editTaskboardGroupModalData}
        open={editGroupBoardModal}
      />}
      {newDocumentModalOpen &&
      <NewDocumentModal
        close={() => setNewDocumentModalOpen(false)}
        group={editTaskboardGroupModalData}
        open={newDocumentModalOpen}
        refreshBoards={props.refreshBoards}
      />}

      {newEmbedModalOpen &&
      <AddEmbedModalWorkspace
        close={() => setNewEmbedModalOpen(false)}
        open={newEmbedModalOpen}
        refreshBoards={props.refreshBoards}
        taskboardGroup={editTaskboardGroupModalData}
        type={embedType}
        workspace={props.activeWorkspace?.id}
      />}

      <Menu
        anchorEl={anchorElement}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        onClick={handleMoreMenuClose}
        onClose={handleMoreMenuClose}
        open={openMoreMenu}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
      >
        <MenuItem onClick={() => setNewBoardModalOpen(true)}>
          <ListItemIcon color='success'>
            <TaskAltRoundedIcon color='success' fontSize='medium' />
          </ListItemIcon>
          Taskboard
        </MenuItem>
        <MenuItem onClick={() => setNewDocumentModalOpen(true)}>
          <ListItemIcon color='info'>
            <ArticleRoundedIcon color='info' fontSize='medium' />
          </ListItemIcon>
          Doc
        </MenuItem>
        <Divider sx={{ borderColor: theme => theme.palette.text.label }} />
        {EMBED_TYPES.map(item =>
          <MenuItem
            key={item}
            onClick={() => {
              setEmbedType(item)
              setNewEmbedModalOpen(true)
            }}
          >
            <ListItemIcon
              sx={{
                fontSize: '24px',
                minWidth: '24px',
              }}
            >
              {GetIconTaskSmall(item)}
            </ListItemIcon>
            {item}
          </MenuItem>)}
      </Menu>

      <Stack direction='column' id='all-tasks' justifyContent='flex-start' width={1}>
        <DragDropContext onDragEnd={onDragEnd}>
          <List dense>
            {props.boardGroups && props.boardGroups.map((taskGroup, index) =>
              <TaskMenuBoard
                id={`${index}`}
                key={`${taskGroup.id}`}
                openNewBoardModal={toggleNewTaskboardModal}
                refreshBoards={props.refreshBoards}
                searchQuery={props.searchQuery}
                taskboardGroupSet={taskGroup}
                toggleDeleteTaskGroupModal={toggleDeleteTaskGroupModal}
                toggleEditTaskGroupModal={toggleEditTaskGroupModal}
              />)}
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => setNewGroupBoardModalOpen(true)}
                sx={{ borderRadius: 0 }}
              >
                <ListItemText
                  disableTypography
                  primary={<Typography
                    sx={{ color: theme => theme.palette.text.secondary }}
                    variant='body2'
                  >
                    + Add folder
                  </Typography>}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </DragDropContext>
      </Stack>
    </>
  )
}
export default Boards
