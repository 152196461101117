/* eslint-disable react-hooks/exhaustive-deps */
import type { FC } from 'react'
import { createContext, useContext, useMemo, useState } from 'react'

import type Recording from 'src/models/Recording'

type MusicProviderContextProps = {
  recording?: Recording
  playing?: string
  setPlaying: (recordingItem: Recording | undefined) => void
  currentTime?: number
  onPlay: (time: number) => void
  play: boolean
  pausePlay: (newPlay: boolean) => void
  requestTime: (time: number) => void
  timeAttempt: number
  buffering: boolean
  setBuffering: (bufferingStatus: boolean) => void
  expanded: boolean
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>
}

export type InitProps = Omit<MusicProviderContextProps,
'currentTime' | 'playing' | 'recording'> & {
  recording: MusicProviderContextProps['recording'] | null | undefined
  playing: MusicProviderContextProps['playing'] | null | undefined
  currentTime: MusicProviderContextProps['currentTime'] | null | undefined
  buffering: MusicProviderContextProps['buffering'] | null | undefined
}

const initialValue = {
  recording: undefined,
  playing: undefined,
  currentTime: 0,
} as InitProps

const MusicContext = createContext(initialValue as MusicProviderContextProps)

export const usePlayer = () => useContext(MusicContext)

export const MusicProvider: FC = props => {
  const [recording, setRecording] = useState<InitProps['recording']>()
  const [playing, setPlayer] = useState<InitProps['playing']>()
  const [currentTime, setCurrentTime] = useState<InitProps['currentTime']>()
  const [play, setPlay] = useState<InitProps['play']>(false)
  const [timeAttempt, setRequestedTime] = useState(0)
  const [buffering, setBufferingItem] = useState(false)
  const [expanded, setExpanded] = useState(false)

  const setPlaying = (recordingItem: Recording | undefined) => {
    setRecording(recordingItem)
    setPlayer(recordingItem?.id)
  }

  const setBuffering = (bufferStatus: boolean) => {
    setBufferingItem(bufferStatus)
  }

  const onPlay = (time: number) => {
    setCurrentTime(time)
  }

  const pausePlay = (newPlay: boolean) => {
    setPlay(newPlay)
  }

  const requestTime = (time: number) => {
    setRequestedTime(time)
  }

  const value = useMemo<InitProps>(
    () => ({
      recording,
      playing,
      setPlaying,
      currentTime,
      buffering,
      setBuffering,
      onPlay,
      pausePlay,
      play,
      requestTime,
      timeAttempt,
      expanded,
      setExpanded,
    }),
    [recording, playing, currentTime, play, timeAttempt, expanded, setPlaying]
  ) as MusicProviderContextProps

  return (
    <MusicContext.Provider value={value}>
      {props.children}
    </MusicContext.Provider>
  )
}
