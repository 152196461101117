import ControlPointDuplicateRoundedIcon from '@mui/icons-material/ControlPointDuplicateRounded'
import CreateRoundedIcon from '@mui/icons-material/CreateRounded'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import DragIndicatorRoundedIcon from '@mui/icons-material/DragIndicatorRounded'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import UnfoldLessRoundedIcon from '@mui/icons-material/UnfoldLessRounded'
import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded'
import { Chip, Collapse, Divider, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Table, TableBody, Tooltip, Typography } from '@mui/material'
import { useState } from 'react'
import type { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import { Droppable } from 'react-beautiful-dnd'
import { v4 } from 'uuid'

import { savePartialTaskboard } from 'src/api/taskboards'
import { sendTasksboardUpdate } from 'src/api/webSocket'
import TaskListItem from 'src/components/pages/Task/TaskViews/TaskViewComponents/TaskListItem'
import { useAuth } from 'src/components/providers/AuthProvider'
import type Account from 'src/models/Account'
import type { TaskBoard, TaskGroup, TaskItem, TaskUpdate } from 'src/models/Taskboard'
import { TASK_MODIFICATION_TYPES, TaskBoardEdit } from 'src/models/Taskboard'

type Props = {
  toggleTaskDrawer: (toggleState: boolean, taskGroup: TaskGroup, taskData?: TaskItem) => () => void
  toggleTaskModal: (toggleState: boolean, taskGroup: TaskGroup) => () => void
  toggleDeleteTaskModal: (toggleState: boolean, taskGroup: TaskGroup) => () => void
  toggleEditTaskModal: (toggleState: boolean, taskGroup: TaskGroup) => () => void
  id: string
  column: string
  taskColumn: TaskGroup
  taskboard: TaskBoard
  searchQuery: string
  statusQuery: string
  priorityQuery: string
  dragHandle: DraggableProvidedDragHandleProps | null | undefined
  fieldOrder: string[]
  members: Account[]
}

const TaskListContainer = (props: Props) => {
  const { currentAccount } = useAuth()
  const [expanded, setExpanded] = useState(true)

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const openMoreMenu = Boolean(anchorElement)

  const handleMoreMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget)
  }
  const handleMoreMenuClose = () => {
    setAnchorElement(null)
  }

  const handleDuplicateSection = async () => {
    if (props.taskboard.id) {
      const newTaskboardGroup = {
        title: props.taskColumn.title,
        color: props.taskColumn.color,
        tasks: props.taskColumn.tasks.map(item => ({ ...item, id: v4(), updates: [] as TaskUpdate[] })) as TaskItem[],
        id: v4(),
      }

      props.taskboard.taskGroups.push(newTaskboardGroup)

      await savePartialTaskboard({ id: props.taskboard.id, ...props.taskboard })
        .then(() => {
          const taskUpdateItem = new TaskBoardEdit({
            id: v4(),
            taskboardId: props.taskboard.id,
            taskId: '',
            accountId: currentAccount.id,
            modificationType: TASK_MODIFICATION_TYPES[19],
            itemType: 'Field',
            title: '',
          })
          sendTasksboardUpdate(taskUpdateItem)
        })
    }
  }

  return (

    <>
      <Stack direction='column' key={props.taskColumn.id} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          padding={1}
          spacing={1}
          sx={{ position: 'sticky', left: 0 }}
          width='fit-content'
        >
          <Tooltip title='Re-order'>
            <IconButton
              aria-label='Drag'
              {...props.dragHandle}
              sx={{ width: 28, height: 28 }}
            >
              <DragIndicatorRoundedIcon fontSize='medium' sx={{ color: theme => theme.palette.action.active }} />
            </IconButton>
          </Tooltip>
          <Tooltip title={expanded ? 'Collapse' : 'Expand'}>
            <IconButton
              onClick={() => setExpanded(!expanded)}
              size='small'
            >
              {expanded ? <KeyboardArrowDownRoundedIcon />
                : <KeyboardArrowUpRoundedIcon />}
            </IconButton>
          </Tooltip>

          <Chip
            label={props.taskColumn.title}
            size='medium'
            sx={{
              color: theme => theme.palette.primary.contrastText,
              backgroundColor: props.taskColumn.color,
            }}
            variant='subtle'
          />
          <Tooltip title='More'>
            <IconButton
              aria-label='Menu'
              onClick={handleMoreMenuOpen}
              sx={{ width: 28, height: 28 }}
            >
              <MoreVertRoundedIcon fontSize='medium' sx={{ color: theme => theme.palette.action.active }} />
            </IconButton>
          </Tooltip>
        </Stack>
        <Collapse in={expanded} timeout='auto'>
          <Droppable droppableId={props.id} key={props.taskColumn.id} type='task'>
            {provided => <Stack {...provided.droppableProps} ref={provided.innerRef} width={1}>
              <Table
                aria-label='projects table'
                size='small'
                sx={{
                  display: 'flex',
                  width: 1,
                }}
              >

                <TableBody sx={{ width: 1, display: 'flex', flexDirection: 'column' }}>
                  {props.taskColumn.tasks.map((taskItem, index) =>
                    <TaskListItem
                      fieldOrder={props.fieldOrder}
                      id={`${taskItem.id}`}
                      index={index}
                      key={taskItem.id}
                      members={props.members}
                      priorityQuery={props.priorityQuery}
                      searchQuery={props.searchQuery}
                      statusQuery={props.statusQuery}
                      taskColumn={props.taskColumn}
                      taskDataSet={taskItem}
                      taskboard={props.taskboard}
                      toggleTaskDrawer={props.toggleTaskDrawer}
                    />)}
                </TableBody>
              </Table>
              {provided.placeholder}

              <ListItem disablePadding>
                <ListItemButton
                  onClick={props.toggleTaskModal(true, props.taskColumn)}
                  sx={{ borderRadius: 0 }}
                >
                  <ListItemText
                    disableTypography
                    primary={<Typography
                      sx={{ color: theme => theme.palette.text.secondary, position: 'sticky', left: 64 }}
                      variant='helperText'
                    >
                      + Add a task
                    </Typography>}
                  />
                </ListItemButton>
              </ListItem>
            </Stack>}
          </Droppable>
        </Collapse>
      </Stack>
      <Menu
        anchorEl={anchorElement}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClick={handleMoreMenuClose}
        onClose={handleMoreMenuClose}
        open={openMoreMenu}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
      >

        {expanded
          ? <MenuItem onClick={() => setExpanded(!expanded)}>
            <ListItemIcon>
              <UnfoldLessRoundedIcon fontSize='medium' />
            </ListItemIcon>
            Collapse section
          </MenuItem>
          : <MenuItem onClick={() => setExpanded(!expanded)}>
            <ListItemIcon>
              <UnfoldMoreRoundedIcon fontSize='medium' />
            </ListItemIcon>
            Expand section
          </MenuItem>}

        <Divider sx={{ borderColor: theme => theme.palette.text.label }} />
        <MenuItem onClick={() => void handleDuplicateSection()}>
          <ListItemIcon>
            <ControlPointDuplicateRoundedIcon fontSize='medium' />
          </ListItemIcon>
          Duplicate section
        </MenuItem>
        <MenuItem onClick={props.toggleEditTaskModal(true, props.taskColumn)}>
          <ListItemIcon>
            <CreateRoundedIcon fontSize='medium' />
          </ListItemIcon>
          Edit section
        </MenuItem>
        <MenuItem onClick={props.toggleDeleteTaskModal(true, props.taskColumn)}>
          <ListItemIcon>
            <DeleteRoundedIcon fontSize='medium' />
          </ListItemIcon>
          Delete section
        </MenuItem>
      </Menu>
    </>

  )
}
export default TaskListContainer

