/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useMemo, useState } from 'react'

import { initializeWorkspace, subscribeToWorkspace } from 'src/api/webSocket'
import { useAuth } from 'src/components/providers/AuthProvider'

type WorkspaceProviderContextProps = {
  workspaceUpdates: string
}

export type InitProps = Omit<WorkspaceProviderContextProps,
'workspaceUpdates'> & {
  workspaceUpdates: WorkspaceProviderContextProps['workspaceUpdates'] | []
}

const initialValue = {
  workspaceUpdates: [],
} as InitProps

const WorkspaceContext = createContext(initialValue as WorkspaceProviderContextProps)

export const useWorkspace = () => useContext(WorkspaceContext)

type Props = {
  children?: React.ReactNode
}

export const WorkspaceProvider = (props: Props) => {
  const { currentOrganisation } = useAuth()
  const [workspaceUpdates, setWorkspaceUpdates] = useState<InitProps['workspaceUpdates']>('')

  useEffect(() => {
    subscribe()
  }, [currentOrganisation?.id])

  const getTaskBoardWithRefresh = async () => {
    await initializeWorkspace()
      .then(socketItem => {
        subscribeToWorkspace(socketItem, (error: unknown, data?: string) => {
          if (error) return

          if (data) {
            setWorkspaceUpdates(data)
          }
        })
        socketItem?.io.once('reconnect', async () => {
          socketItem?.removeAllListeners('workspaceNotification')
          await getTaskBoardWithRefresh()
        })
      })
  }

  const subscribe = () => {
    void getTaskBoardWithRefresh()
  }

  const value = useMemo<InitProps>(
    () => ({
      workspaceUpdates,
    }),
    [workspaceUpdates]
  ) as WorkspaceProviderContextProps

  return (
    <WorkspaceContext.Provider value={value}>
      {props.children}
    </WorkspaceContext.Provider>
  )
}
