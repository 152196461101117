import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'

import { removeUserFromOrganisation } from 'src/api/organisation'
import DeleteItemComponent from 'src/components/DeleteItemComponent'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { Seat } from 'src/models/Organisation'

type Props = {
  close: () => void
  open: boolean
  user?: Seat
}

const DeleteUserModal = (props: Props) => {
  const themeColors = useTheme()
  const fullScreen = useMediaQuery(themeColors.breakpoints.down('md'))

  const { refreshCurrentOrganisation } = useAuth()
  const [loading, setLoading] = useState(false)

  const deleteAccount = async () => {
    setLoading(true)
    await removeUserFromOrganisation(props.user?.account.id ?? '')
      .then(() => setLoading(false))
      .then(refreshCurrentOrganisation)
    props.close()
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='xs'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Stack paddingX={3} paddingY={3} spacing={4} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          width='100%'
        >
          <Typography variant='h3'>
            Remove user
          </Typography>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>
        <DeleteItemComponent
          primary='Are you sure?'
          secondary='Removing a user is permanent, this action cannot be undone'
        />
        <Stack direction='row' justifyContent='flex-end' spacing={1} width='100%'>
          <Button onClick={() => props.close()} variant='text'>
            Cancel
          </Button>
          <LoadingButton
            color='error'
            loading={loading}
            onClick={deleteAccount}
            variant='contained'
          >
            Remove user
          </LoadingButton>
        </Stack>
      </Stack>
    </Dialog>)
}

export default DeleteUserModal
