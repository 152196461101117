/* eslint-disable max-lines */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-identical-functions */
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded'
import { Autocomplete, Button, Card, Checkbox, Chip, Grid, IconButton, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { useState } from 'react'

import BeatportApplicationModal from 'src/components/pages/Projects/DistributeModal/DistributeModalComponents/BeatportApplicationModal'
import { GetColorLink } from 'src/components/pages/SmartLink/Content/GetColorLink'
import GetIcon from 'src/components/pages/SmartLink/Content/GetIcon'
import { useAuth } from 'src/components/providers/AuthProvider'
import { allPlatforms, digitalPlatforms, socialPlatforms, specialStores } from 'src/data/storesData'
import type { Platform, Territory } from 'src/models/Distribution'
import { BEATPORT_GENRES } from 'src/models/Recording'

type Props = {
  stores: Platform[]
  territories: Territory[]
  setStores: React.Dispatch<React.SetStateAction<Platform[]>>
  setBeatportGenre: React.Dispatch<React.SetStateAction<string>>
  beatportGenre: string
}

const StoresDistribute = (props: Props) => {
  const { currentOrganisation } = useAuth()
  const [beatport, setBeatport] = useState(false)

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>, platform: Platform) => {
    let updatedList = [...props.stores]
    if (event.target.checked) {
      updatedList = [...props.stores, platform]
    } else {
      updatedList.splice(props.stores.indexOf(platform), 1)
      if (platform === specialStores[0]) {
        props.setBeatportGenre('')
      }
    }
    props.setStores(updatedList)
  }

  const handleCheckAll = () => {
    const newArray = [...props.stores,
      ...allPlatforms.filter(platform => !props.stores.includes(platform) &&
      ((!!currentOrganisation?.beatportLabelString && platform.name === 'Beatport') ||
      platform.name !== 'Beatport'))]
    props.setStores(newArray)
  }

  const handleUnselectAll = () => {
    const newArray = props.stores.filter(platform => !props.stores.includes(platform))
    props.setStores(newArray)
  }

  const checkSK = props.territories.findIndex(item => item.iso === 'KR')
  const checkNK = props.territories.findIndex(item => item.iso === 'KP')
  const checkChina = props.territories.findIndex(item => item.iso === 'CN')

  const checkFlo = checkSK > -1 && checkNK > -1
  const checkTencent = checkChina > -1

  return (
    <Stack spacing={4} width={1}>

      <Stack spacing={0.5} width={1}>
        <Stack alignItems='center' direction='row' justifyContent='space-between' spacing={1}>
          <Typography variant='h3'>
            Stores
          </Typography>

          <Stack direction='row' justifyContent='flex-end' spacing={1}>
            <Button
              onClick={handleCheckAll}
              variant='text'
            >
              Select all
            </Button>
            <Button
              color='error'
              onClick={handleUnselectAll}
              variant='text'
            >
              Unselect all
            </Button>
          </Stack>
        </Stack>

        <Card variant='outlined'>
          <Stack padding={2} width={1}>
            <Grid container rowSpacing={2}>
              {digitalPlatforms.map(platform =>
                <>
                  <Grid item key={platform.name} lg={4} md={4} sm={6} xl={3} xs={6}>
                    <Stack alignItems='center' direction='row' spacing={1}>
                      {platform.name === 'FLO'
                        ? <Checkbox
                          checked={props.stores.includes(platform) && checkFlo}
                          disabled={!checkFlo}
                          name={platform.name}
                          onChange={event => handleCheck(event, platform)}
                          sx={{
                            height: 'fit-content',
                          }}
                        />
                        : platform.name === 'Tencent'
                          ? <Checkbox
                            checked={props.stores.includes(platform) && checkTencent}
                            disabled={!checkTencent}
                            name={platform.name}
                            onChange={event => handleCheck(event, platform)}
                            sx={{
                              height: 'fit-content',
                            }}
                          />
                          : <Checkbox
                            checked={props.stores.includes(platform)}
                            name={platform.name}
                            onChange={event => handleCheck(event, platform)}
                            sx={{
                              height: 'fit-content',
                            }}
                          />}
                      <IconButton
                        disableRipple
                        sx={{
                          backgroundColor: theme => props.stores.includes(platform)
                            ? GetColorLink(platform.name)
                            : theme.palette.action.disabled,
                          color: 'white!important',
                          ':hover': {
                            backgroundColor: theme => props.stores.includes(platform)
                              ? GetColorLink(platform.name)
                              : theme.palette.action.disabled,
                            cursor: 'default',
                          },
                          '& .MuiSvgIcon-root': {
                            color: 'white!important',
                          },
                        }}
                      >
                        {GetIcon(platform.name)}
                      </IconButton>
                      <Typography variant='body2'>
                        {platform.name}
                      </Typography>
                    </Stack>
                  </Grid>
                  {platform.name === 'iTunes & Apple Music' &&
                  <Grid item key={platform.name} lg={4} md={4} sm={6} xl={3} xs={6}>
                    <Stack alignItems='center' direction='row' spacing={1}>
                      <Checkbox
                        checked={props.stores.includes(platform)}
                        name={platform.name}
                        onChange={event => handleCheck(event, platform)}
                      />
                      <IconButton
                        disableRipple
                        sx={{
                          backgroundColor: theme => props.stores.includes(platform)
                            ? GetColorLink('Shazam')
                            : theme.palette.action.disabled,
                          color: 'white!important',
                          ':hover': {
                            backgroundColor: theme => props.stores.includes(platform)
                              ? GetColorLink('Shazam')
                              : theme.palette.action.disabled,
                            cursor: 'default',
                          },
                          '& .MuiSvgIcon-root': {
                            color: 'white!important',
                          },
                        }}
                      >
                        {GetIcon('Shazam')}
                      </IconButton>
                      <Typography variant='body2'>
                        Shazam
                      </Typography>
                    </Stack>
                  </Grid>}
                </>)}
            </Grid>
          </Stack>
        </Card>
      </Stack>

      <Stack spacing={0.5} width={1}>
        <Stack alignItems='center' direction='row' spacing={1}>
          <Typography variant='h3'>
            Social Stores
          </Typography>
          <Tooltip
            title='Social stores are only available for original releases that do not contain samples. Covers,
            music with royalty free samples, remixes and other forms of non-original content might not make it to
            these stores.'
          >
            <IconButton>
              <InfoRoundedIcon />
            </IconButton>
          </Tooltip>
        </Stack>

        <Card variant='outlined'>
          <Stack padding={2} width={1}>
            <Grid container rowSpacing={2}>
              {socialPlatforms.map(platform =>
                <>
                  <Grid item key={platform.name} lg={4} md={4} sm={6} xl={3} xs={6}>
                    <Stack alignItems='center' direction='row' spacing={1}>
                      {platform.name === 'FLO'
                        ? <Checkbox
                          checked={props.stores.includes(platform) && checkFlo}
                          disabled={!checkFlo}
                          name={platform.name}
                          onChange={event => handleCheck(event, platform)}
                        />
                        : platform.name === 'Tencent'
                          ? <Checkbox
                            checked={props.stores.includes(platform) && checkTencent}
                            disabled={!checkTencent}
                            name={platform.name}
                            onChange={event => handleCheck(event, platform)}
                          />
                          : <Checkbox
                            checked={props.stores.includes(platform)}
                            name={platform.name}
                            onChange={event => handleCheck(event, platform)}
                          />}
                      <IconButton
                        disableRipple
                        sx={{
                          backgroundColor: theme => props.stores.includes(platform)
                            ? GetColorLink(platform.name)
                            : theme.palette.action.disabled,
                          color: 'white!important',
                          ':hover': {
                            backgroundColor: theme => props.stores.includes(platform)
                              ? GetColorLink(platform.name)
                              : theme.palette.action.disabled,
                            cursor: 'default',
                          },
                          '& .MuiSvgIcon-root': {
                            color: 'white!important',
                          },
                        }}
                      >
                        {GetIcon(platform.name)}
                      </IconButton>
                      <Typography variant='body2'>
                        {platform.name}
                      </Typography>
                    </Stack>
                  </Grid>
                  {platform.name === 'Facebook' &&
                  <Grid item key={platform.name} lg={4} md={4} sm={6} xl={3} xs={6}>
                    <Stack alignItems='center' direction='row' spacing={1}>
                      <Checkbox
                        checked={props.stores.includes(platform)}
                        name={platform.name}
                        onChange={event => handleCheck(event, platform)}
                      />
                      <IconButton
                        disableRipple
                        sx={{
                          backgroundColor: theme => props.stores.includes(platform)
                            ? GetColorLink('Instagram')
                            : theme.palette.action.disabled,
                          color: 'white!important',
                          ':hover': {
                            backgroundColor: theme => props.stores.includes(platform)
                              ? GetColorLink('Instagram')
                              : theme.palette.action.disabled,
                            cursor: 'default',
                          },
                          '& .MuiSvgIcon-root': {
                            color: 'white!important',
                          },
                        }}
                      >
                        {GetIcon('Instagram')}
                      </IconButton>
                      <Typography variant='body2'>
                        Instagram
                      </Typography>
                    </Stack>
                  </Grid>}
                </>)}
            </Grid>
          </Stack>
        </Card>
      </Stack>

      <Stack spacing={0.5} width={1}>
        <Stack alignItems='center' direction='row' spacing={1}>
          <Typography variant='h3'>
            Speciality Stores
          </Typography>
          <Tooltip
            title='Speciality stores are curated, we cannot guarantee your release will be accepted. Beatport is
            only available for Electronic Music.'
          >
            <IconButton>
              <InfoRoundedIcon />
            </IconButton>
          </Tooltip>
        </Stack>
        <Card variant='outlined'>
          <Stack padding={2} width={1}>
            <Grid container rowSpacing={2}>
              {specialStores.map(platform =>
                <Grid item key={platform.name} lg={4} md={4} sm={6} xl={3} xs={6}>
                  <Stack alignItems='center' direction='row' spacing={1}>
                    <Checkbox
                      checked={props.stores.includes(platform)}
                      disabled={!currentOrganisation?.beatportLabelString}
                      name={platform.name}
                      onChange={event => handleCheck(event, platform)}
                    />
                    <IconButton
                      disableRipple
                      sx={{
                        backgroundColor: theme => props.stores.includes(platform)
                          ? GetColorLink(platform.name)
                          : theme.palette.action.disabled,
                        color: 'white!important',
                        ':hover': {
                          backgroundColor: theme => props.stores.includes(platform)
                            ? GetColorLink(platform.name)
                            : theme.palette.action.disabled,
                          cursor: 'default',
                        },
                        '& .MuiSvgIcon-root': {
                          color: 'white!important',
                        },
                      }}
                    >
                      {GetIcon(platform.name)}
                    </IconButton>
                    <Typography variant='body2'>
                      {platform.name}
                    </Typography>
                  </Stack>
                </Grid>)}
            </Grid>
            <Autocomplete
              disabled={!props.stores.includes(specialStores[0])}
              onChange={(event, value) => props.setBeatportGenre(value ?? '')}
              options={BEATPORT_GENRES}
              renderInput={params =>
                <TextField
                  {...params}
                  label='Beatport Genre'
                  placeholder='Beatport Genre'
                  sx={{
                    width: 256,
                  }}
                />}
              sx={{
                marginTop: '16px!important',
              }}
              value={props.beatportGenre}
            />
            {currentOrganisation?.beatportLabelString
              ? <Chip
                color='success'
                label='Enrolled'
                sx={{
                  width: 'fit-content',
                  marginTop: '8px!important',
                }}
              />
              : currentOrganisation?.beatportSubmitted
                ? <Chip
                  color='default'
                  label='Under review'
                  sx={{
                    width: 'fit-content',
                    marginTop: '8px!important',
                  }}
                />
                : <Button
                  color='success'
                  onClick={() => setBeatport(true)}
                  startIcon={<CheckCircleRoundedIcon />}
                  sx={{
                    width: 'fit-content',
                    marginTop: '8px!important',
                  }}
                >
                  Apply for access
                </Button>}
          </Stack>
        </Card>
      </Stack>

      <BeatportApplicationModal
        close={() => setBeatport(false)}
        open={beatport}
      />
    </Stack>
  )
}

export default StoresDistribute
