
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import CreateRoundedIcon from '@mui/icons-material/CreateRounded'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import { Collapse, IconButton, List, ListItemIcon, ListSubheader, Menu, MenuItem, Stack, Tooltip, Typography } from '@mui/material'
import { useState } from 'react'
import { Droppable } from 'react-beautiful-dnd'

import { TaskBoardListItem } from 'src/components/pages/Task/TaskMenu/TaskMenuComponents/TaskBoardListItem'
import type { TaskboardGroup } from 'src/models/Taskboard'

type Props = {
  taskboardGroupSet: TaskboardGroup
  openNewBoardModal: (event: React.MouseEvent<HTMLElement>, toggleState: boolean, group: TaskboardGroup) => void
  toggleDeleteTaskGroupModal: (toggleState: boolean, group: TaskboardGroup) => () => void
  toggleEditTaskGroupModal: (toggleState: boolean, group: TaskboardGroup) => () => void
  id: string
  searchQuery: string
  refreshBoards: () => void
}

const TaskMenuBoard = (props: Props) => {
  const [expanded, setExpanded] = useState(true)

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const openMenu = Boolean(anchorElement)
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorElement(null)
  }

  return <>
    <ListSubheader sx={{ position: 'relative' }}>
      <Stack flexDirection='row' justifyContent='space-between'>
        <Stack alignItems='center' direction='row' spacing={1}>
          <IconButton onClick={() => setExpanded(!expanded)} size='small'>
            {expanded ? <KeyboardArrowDownRoundedIcon />
              : <KeyboardArrowUpRoundedIcon />}
          </IconButton>
          <Typography sx={{ wordBreak: 'break-word' }} variant='textLabel'>
            {props.taskboardGroupSet.title}
          </Typography>
        </Stack>
        <Stack direction='row'>
          <Tooltip title='More'>
            <IconButton
              aria-label='More options'
              onClick={handleMenuClick}
              size='small'
            >
              <MoreVertRoundedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title='Add item'>
            <IconButton
              aria-label='Add item'
              onClick={event => props.openNewBoardModal(event, true, props.taskboardGroupSet)}
              size='small'
            >
              <AddRoundedIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
    </ListSubheader>
    <Collapse in={expanded} timeout='auto' unmountOnExit>
      <Droppable droppableId={props.id} key={props.id}>
        {provided =>
          <List dense {...provided.droppableProps} ref={provided.innerRef} >
            {props.taskboardGroupSet.boardIds.map((TaskMenuItem, index) =>
              <TaskBoardListItem
                board={TaskMenuItem}
                index={index}
                key={TaskMenuItem}
                refreshBoards={props.refreshBoards}
                searchQuery={props.searchQuery}
              />)}
            {provided.placeholder}
          </List>}
      </Droppable>
    </Collapse>
    <Menu
      anchorEl={anchorElement}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      onClick={handleClose}
      onClose={handleClose}
      open={openMenu}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
    >
      <MenuItem onClick={props.toggleEditTaskGroupModal(true, props.taskboardGroupSet)}>
        <ListItemIcon>
          <CreateRoundedIcon fontSize='medium' />
        </ListItemIcon>
        Edit folder
      </MenuItem>
      <MenuItem onClick={props.toggleDeleteTaskGroupModal(true, props.taskboardGroupSet)}>
        <ListItemIcon>
          <DeleteRoundedIcon color='error' fontSize='medium' />
        </ListItemIcon>
        Delete folder
      </MenuItem>
    </Menu>
  </>
}
export default TaskMenuBoard
