
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded'
import { Button, Stack, Table, TableBody, TableCell, TableHead, TableRow, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'

import SearchField from 'src/components/form-elements/SearchField'
import InviteMemberModal from 'src/components/pages/Settings/Team/TeamTabs/TeamTabsComponents/InviteMember'
import UserListItem from 'src/components/pages/Settings/Team/TeamTabs/TeamTabsComponents/UserListItem'
import { useAuth } from 'src/components/providers/AuthProvider'
import { NavLink } from 'src/components/SafeRouterLink'
import { Permissions } from 'src/models/Organisation'

const Members = () => {
  const themeColors = useTheme()
  const matches = useMediaQuery(themeColors.breakpoints.down('md'))
  const { currentOrganisation, currentAccountPermissions } = useAuth()
  const [searchQuery, setSearchQuery] = useState('')

  const filterData = currentOrganisation?.seats.filter(seat =>
    seat.account.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    seat.account.lastName.toLowerCase().includes(searchQuery.toLowerCase()))

  const [newMemberModal, setNewMemberModalOpen] = useState(false)
  return (
    <Stack direction='column' maxWidth={1096} padding={4} spacing={2}>
      <Stack alignItems='center' direction='row' justifyContent='space-between'>
        <SearchField
          onChange={event => setSearchQuery(event.target.value)}
          sx={{ marginTop: 0 }}
          value={searchQuery}
        />
        <Stack direction='row' spacing={1}>
          {currentOrganisation && (currentOrganisation.seats.length === currentOrganisation.seatAllocation) &&
          <Button color='success' component={NavLink} to='/settings/billing/overview'>
            Upgrade to add more users
          </Button>}
          <Button
            disabled={currentOrganisation?.seats.length === currentOrganisation?.seatAllocation ||
            (currentOrganisation?.seats.length ?? 1) > (currentOrganisation?.seatAllocation ?? 1) ||
            !currentAccountPermissions?.includes(Permissions.ADD_REMOVE_USERS)}
            onClick={() => setNewMemberModalOpen(true)}
            startIcon={<PersonAddAlt1RoundedIcon />}
          >
            Add member
          </Button>
        </Stack>
      </Stack>
      <Table aria-label='projects table' size='small' stickyHeader>
        <TableHead sx={{
          '.MuiTableCell-root':
          {
            borderBottom: 2,
            borderBottomColor: 'divider',
            color: 'text.secondary',
            background: 'transparent',
          },
        }}
        >
          <TableRow>
            <TableCell align='left'>Member</TableCell>
            {!matches && <>
              <TableCell align='left'>Email</TableCell>
              <TableCell align='left'>Permissions</TableCell>
            </>}
            <TableCell align='left'>Status</TableCell>
            <TableCell align='left'>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            filterData?.map(seat =>
              <UserListItem key={`user-${seat.account.id}`} seat={seat} />)
          }
        </TableBody>
      </Table>
      {currentOrganisation &&
      (currentOrganisation.seats.length < currentOrganisation.seatAllocation) &&
      currentAccountPermissions?.includes(Permissions.ADD_REMOVE_USERS) &&
      <InviteMemberModal
        close={() => setNewMemberModalOpen(false)}
        open={newMemberModal}
      />}

    </Stack>
  )
}
export default Members
