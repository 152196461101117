import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import { Paper, Stack } from '@mui/material'
import { useEffect, useState } from 'react'

import { getProject } from 'src/api/projects'
import type Project from 'src/models/Project'

type Props = {
  project?: Project
}

const ProjectItem = (props: Props) => {
  const [project, setProject] = useState<Project | undefined>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (props.project?.id) {
      setLoading(true)
      getProject(props.project.id).then(data => {
        setProject(data)
      }).finally(() => setLoading(false))
    } else {
      setProject(undefined)
    }
  }, [props.project])

  return (
    <Stack>
      {!project || project.projectArtworkUrl.length === 0 || loading
        ? <Paper
          elevation={0}
          sx={{
            height: 28,
            width: 28,
            borderRadius: 0.5,
            backgroundColor: theme => theme.palette.background.input,
          }}
        >
          <MusicNoteRoundedIcon
            sx={{
              fontSize: 28,
              color: theme => theme.palette.action.disabled,
            }}
          />
        </Paper>
        : <img
          alt={project.title}
          src={project.artworkPreviewUrl}
          style={{
            width: 28,
            height: 28,
            borderRadius: 8,
            objectFit: 'cover',
          }}
        />}
    </Stack>
  )
}

export default ProjectItem
