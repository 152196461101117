/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import type { PlateEditor } from '@udecode/plate'
import { Editor, Transforms } from 'slate'

const LIST_TYPES = new Set(['numbered-list', 'bulleted-list'])

export const toggleBlock = (editor: PlateEditor,
  format: 'alphaColor' | 'bold' | 'center' | 'code' | 'href' | 'isCaret' | 'italic' | 'justify' | 'left' | 'linkItem' | 'right' | 'underline') => {
  const isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.has(format)

  Transforms.unwrapNodes(editor, {
    match: item => LIST_TYPES.has(item.type as string),
    split: true,
  })

  Transforms.setNodes(editor, {
    type: isActive
      ? 'p'
      : isList
        ? 'list-item'
        : format,
  })

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

export const isBlockActive = (editor: PlateEditor,
  format: 'alphaColor' | 'bold' | 'center' | 'code' | 'href' | 'isCaret' | 'italic' | 'justify' | 'left' | 'linkItem' | 'right' | 'underline') => {
  const [match] = Editor.nodes(editor, {
    match: item => item.type === format,
  })

  return !!match
}
