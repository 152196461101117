import type { RouteProps } from 'react-router-dom'
import { Navigate } from 'react-router-dom'

import type { EarlyAuth } from './AuthProvider'
import { useAuth } from './AuthProvider'

type Props =
  RouteProps & {
    elementItem: JSX.Element
  }

const LoggedInRouter = (props: Props) => {
  const { currentFirebaseUser } = useAuth() as EarlyAuth

  const redirectTo = new URLSearchParams(window.location.search).get('redirectTo')?.replace('%2F', '/') ?? '/'

  return currentFirebaseUser
    ? <Navigate to={redirectTo} />
    : props.elementItem
}

export default LoggedInRouter
