/* eslint-disable max-lines */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable no-restricted-imports */
/* eslint-disable react-hooks/exhaustive-deps */
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import AddShoppingCartRoundedIcon from '@mui/icons-material/AddShoppingCartRounded'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { LoadingButton } from '@mui/lab'
import { Alert, Autocomplete, Box, Button, Card, CardMedia, Dialog, Divider, IconButton, InputAdornment, InputLabel, ListItem, ListItemText, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { v4 } from 'uuid'

import { temporaryPurchaseSerices } from 'src/api/payment'
import SearchArtistComponent from 'src/components/form-elements/SearchArtists'
import ServiceModalComplete from 'src/components/pages/Projects/DistributeModal/DistributeModalComponents/ServiceModalComplete'
import { generateTitleRecording } from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/RecordingsTab/Components/EditRecordingComponent'
import GetIcon from 'src/components/pages/SmartLink/Content/GetIcon'
import { continentsData } from 'src/data/distributionCountries'
import type { ArtistObject, ReleeseServices } from 'src/models/Distribution'
import type Project from 'src/models/Project'
import type Recording from 'src/models/Recording'

type Props = {
  readonly close: () => void
  readonly open: boolean
  readonly title: string
  readonly subtitle: string
  readonly platforms: string[]
  readonly description: string
  readonly pricing: number[]
  readonly timelineStart: number
  readonly timelineCompletion: number
  readonly process: string[]
  readonly requirements: string[]
  readonly recordings: Recording[]
  readonly project: Project
  readonly product: string
  readonly setCart: React.Dispatch<React.SetStateAction<ReleeseServices[]>>
  readonly gradient: string
}

const ServiceModal = (props: Props) => {
  const [complete, setComplete] = useState(false)
  const [loading, setLoading] = useState(false)
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  const optimalStartDate = new Date(new Date().setDate(new Date().getDate() + props.timelineStart))
  const releaseDate = props.project.releaseDate

  const timeDifference = releaseDate.getTime() - optimalStartDate.getTime()
  const daysDifference = Math.round(timeDifference / (1000 * 3600 * 24))

  const [selectedPrice, setSelectedPrice] = useState<number | null>(null)
  const [selectedRecording, setSelectedRecording] = useState<Recording>()
  const [similarArtists, setSimilarArtists] = useState<ArtistObject[]>([])
  const [countries, setCountries] = useState<string[]>([])

  const confirmTransaction = async () => {
    if (selectedPrice && selectedRecording) {
      setLoading(true)
      await temporaryPurchaseSerices(
        props.title,
        new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(selectedPrice),
        countries.join(', '),
        similarArtists.map(item => item.name).join(', '),
        selectedRecording.title,
        props.project.id,
      ).then(() => setLoading(false))
        .finally(() => setComplete(true))
    }

    /*
    props.setCart(old => [...old, {
      product: props.product,
      quantity: 1,
      recording: selectedRecording,
      platform: props.platforms.length === 1 ? props.platforms[0] : undefined,
      platformFrom: props.platforms.length === 2 ? props.platforms[0] : undefined,
      platformTo: props.platforms.length === 2 ? props.platforms[1] : undefined,
    }])
    props.close()
    */
  }

  const updateSimilarArtists = (artists: ArtistObject[]) => {
    setSimilarArtists(artists.slice(0, 5))
  }

  const updateSimilarCountries = (countryItems: string[]) => {
    setCountries(countryItems.slice(0, 5))
  }

  useEffect(() => {
    if (props.recordings.length === 1) {
      setSelectedRecording(props.recordings[0])
    }
  }, [props.recordings])

  return (
    <>
      <Dialog
        BackdropProps={{
          timeout: 500,
        }}
        closeAfterTransition
        fullScreen={fullScreen}
        fullWidth
        maxWidth='lg'
        onClose={props.close}
        open={props.open}
        sx={{
          height: 'auto',
          '.MuiPaper-root': {
            height: 'auto!important',
          },
          '.MuiDialog-container': {
            height: 'auto',
            minHeight: 1,
          },
        }}
      >
        <Stack
          direction={fullScreen ? 'column-reverse' : 'row'}
          width={1}
        >
          <Stack width={1}>
            {fullScreen &&
            <Divider />}
            {!fullScreen &&
            <CardMedia
              sx={{
                aspectRatio: '16/6',
                width: '100%',
                background: props.gradient,
                top: 0,
                borderRadius: '16px 0px 16px 0px',
              }}
            >
              <Stack alignItems='center' direction='row' height={1} justifyContent='center' width={1}>
                {props.platforms.map((item, index) =>
                  <>
                    <IconButton
                      disableRipple
                      key={`item ${v4()}`}
                      sx={{
                        fontSize: 96,
                      }}
                    >
                      {GetIcon(item)}
                    </IconButton>
                    {(index < props.platforms.length - 1) &&
                    <IconButton
                      disableRipple
                      sx={{
                        fontSize: 64,
                      }}
                    >
                      <AddRoundedIcon />
                    </IconButton>}
                  </>)}
              </Stack>
            </CardMedia>}
            <Stack padding={2} spacing={2} width={1}>
              <Stack>
                <Typography lineHeight={1.3} variant='h2'>
                  {props.title}
                </Typography>
                <Typography
                  color={themeItem.palette.text.secondary}
                  variant='body1'
                >
                  {props.description}
                </Typography>
              </Stack>

              <Stack>
                <Typography variant='h4'>
                  Pricing
                </Typography>
                <Typography
                  color={themeItem.palette.text.secondary}
                  variant='body1'
                >
                  Starting at
                  {' '}
                  {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(props.pricing[0])}
                </Typography>
              </Stack>

              <Stack>
                <Typography variant='h4'>
                  Timeline
                </Typography>
                <Typography
                  color={themeItem.palette.text.secondary}
                  variant='body1'
                >
                  {props.timelineStart}
                  {' '}
                  days for service to start,
                  {' '}
                  {props.timelineCompletion}
                  {' '}
                  days to completion
                </Typography>
              </Stack>

              <Stack>
                <Typography variant='h4'>
                  Process
                </Typography>
                {props.process.map(item =>
                  <Typography
                    color={themeItem.palette.text.secondary}
                    key={v4()}
                    variant='body1'
                  >
                    {`- ${item}`}
                  </Typography>)}
              </Stack>

              <Stack>
                <Typography variant='h4'>
                  Requirements
                </Typography>
                {props.requirements.map(item =>
                  <Typography
                    color={themeItem.palette.text.secondary}
                    key={v4()}
                    variant='body1'
                  >
                    {`- ${item}`}
                  </Typography>)}
              </Stack>

              <Typography color={themeItem.palette.text.label} variant='body2'>
                By purchasing a Releese service, you agree to our
                {' '}
                <Link
                  style={{
                    color: themeItem.palette.text.link,
                  }}
                  target='_blank'
                  to='https://releese.io/legal/terms-and-conditions/'
                >
                  Terms & Conditions
                </Link>
                .
              </Typography>
            </Stack>
          </Stack>
          <Stack width={1}>
            {fullScreen &&
            <CardMedia
              sx={{
                aspectRatio: '16/4',
                width: '100%',
                background: props.gradient,
                top: 0,
                borderRadius: '0px',
              }}
            >
              <Stack alignItems='center' direction='row' height={1} justifyContent='center' width={1}>
                {props.platforms.map((item, index) =>
                  <>
                    <IconButton
                      disableRipple
                      key={`item ${v4()}`}
                      sx={{
                        fontSize: 64,
                      }}
                    >
                      {GetIcon(item)}
                    </IconButton>
                    {(index < props.platforms.length - 1) &&
                    <IconButton
                      disableRipple
                      sx={{
                        fontSize: 48,
                      }}
                    >
                      <AddRoundedIcon />
                    </IconButton>}
                  </>)}
              </Stack>
            </CardMedia>}
            <Stack
              alignItems='flex-start'
              direction='row'
              justifyContent='space-between'
              paddingTop={2}
              paddingX={2}
              width={1}
            >
              <Stack direction='column'>
                <Typography lineHeight={1.2} variant='h3'>
                  Customize your campaign
                </Typography>
                <Typography color={themeItem.palette.text.label} variant='body1'>
                  {props.subtitle}
                </Typography>
              </Stack>
              <IconButton onClick={() => props.close()}>
                <CloseRoundedIcon color='disabled' />
              </IconButton>
            </Stack>
            <Stack paddingBottom={4} paddingTop={2} paddingX={2} spacing={2} width={1}>
              {daysDifference < 0 &&
              <Alert
                severity='info'
                variant='filled'
              >
                <Typography variant='body1'>
                  Your release date is in less than
                  {' '}
                  {props.timelineStart}
                  {' '}
                  days, we recommend changing your release date to
                  {' '}
                  {dayjs(optimalStartDate).format('LL')}
                  {' '}
                  or later for optimal results.
                </Typography>
              </Alert>}
              <Stack spacing={2}>
                <Card
                  elevation={0}
                  sx={{
                    background: theme => theme.palette.background.elevatedCard,
                  }}
                >
                  <Stack padding={2} spacing={2} width={1}>
                    <Stack spacing={0.5} width={1}>
                      <Stack alignItems='center' direction='row' spacing={0.5}>
                        <CheckCircleOutlineRoundedIcon color='success' />
                        <Typography lineHeight={1.2} variant='h5'>
                          Product
                        </Typography>
                      </Stack>
                      <Typography color={themeItem.palette.text.secondary} variant='body1'>
                        Select which song to promote in this release. Our experts might recommend another song if they
                        believe they can overdeliver.
                      </Typography>
                    </Stack>
                    <Autocomplete
                      autoHighlight
                      autoSelect
                      fullWidth
                      getOptionLabel={option => generateTitleRecording(undefined, option)}
                      onChange={(_, newValue) => setSelectedRecording(newValue ?? undefined)}
                      options={props.recordings}
                      renderInput={params =>
                        <TextField
                          {...params}
                          autoComplete='new-password'
                          label='Recording'
                          placeholder='Select the song you want to promote'
                        />}
                      renderOption={(renderProps, option) =>
                        <Box component='li' {...renderProps}>
                          <ListItemText
                            primary={generateTitleRecording(undefined, option)}
                            secondary={option.primaryArtists.map(item => item.name).join(', ')}
                          />
                        </Box>}
                      value={selectedRecording}
                    />

                  </Stack>
                </Card>

                <Card
                  elevation={0}
                  sx={{
                    background: theme => theme.palette.background.elevatedCard,
                  }}
                >
                  <Stack padding={2} spacing={1} width={1}>
                    <Stack spacing={0.5} width={1}>
                      <Stack alignItems='center' direction='row' spacing={0.5}>
                        <CheckCircleOutlineRoundedIcon color='success' />
                        <Typography lineHeight={1.2} variant='h5'>
                          Budget and schedule
                        </Typography>
                      </Stack>
                      <Typography color={themeItem.palette.text.secondary} variant='body1'>
                        You campaign length and how much you are investing in this campaign
                      </Typography>
                    </Stack>
                    <Stack>
                      <InputLabel sx={{ marginTop: '0px' }}>
                        Budget
                      </InputLabel>
                      <Autocomplete
                        getOptionLabel={option => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                          .format(option)}
                        onChange={(event, newValue) => setSelectedPrice(newValue ?? null)}
                        options={props.pricing}
                        renderInput={params =>
                          <TextField
                            {...params}
                            placeholder='Select your budget'
                            slotProps={{
                              input: {
                                ...params.InputProps,
                                endAdornment: params.InputProps.endAdornment,
                                startAdornment: <InputAdornment position='start'>$US</InputAdornment>,
                              },
                            }}
                            sx={{
                              marginTop: '4px!important',
                              '& .MuiInputBase-root': {
                                paddingLeft: 0,
                              },
                              '& .MuiInputAdornment-root': {
                                backgroundColor: theme => theme.palette.action.disabledBackground,
                                paddingX: '8px',
                                maxHeight: '36px!important',
                                height: '36px!important',
                                borderRadius: '8px 0px 0px 8px',
                              },
                            }}
                            variant='filled'
                          />}
                        renderOption={(propsItem, option) =>
                          <ListItem {...propsItem}>
                            <ListItemText
                              primary={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                                .format(option)}
                            />
                          </ListItem>}
                        value={selectedPrice}
                      />
                    </Stack>
                  </Stack>
                </Card>
                <Card
                  elevation={0}
                  sx={{
                    background: theme => theme.palette.background.elevatedCard,
                  }}
                >
                  <Stack padding={2} spacing={2} width={1}>
                    <Stack spacing={0.5} width={1}>
                      <Stack alignItems='center' direction='row' spacing={0.5}>
                        <CheckCircleOutlineRoundedIcon color='success' />
                        <Typography lineHeight={1.2} variant='h5'>
                          Audience targeting
                        </Typography>
                      </Stack>

                      <Typography color={themeItem.palette.text.secondary} variant='body1'>
                        Set who we should prioritize to deliver this campaign to. Releese can reach outside these
                        targets to optimize traffic.
                      </Typography>
                    </Stack>
                    <SearchArtistComponent
                      label='Similar artists'
                      placeholder='Search for up to 5 similar artists'
                      selectedArtists={similarArtists}
                      setSelectedArtists={values => updateSimilarArtists(values ?? [])}
                    />

                    <Autocomplete
                      disabled={props.project.distributed}
                      fullWidth
                      limitTags={5}
                      multiple
                      onChange={(event, value) => updateSimilarCountries(value)}
                      options={continentsData.flatMap(continent => continent.countries.map(country => country.name))}
                      renderInput={params =>
                        <TextField
                          {...params}
                          fullWidth
                          label='Priority Countries'
                          placeholder='Search for up to 5 countries you wish to target'
                          sx={{
                            border: 'none',
                            '.MuiInputBase-root': {
                              paddingY: 0,
                            },
                          }}
                        />}
                      value={countries}
                    />
                  </Stack>
                </Card>
              </Stack>
            </Stack>

            <Stack direction='row' justifyContent='flex-end' paddingBottom={2} paddingX={2} spacing={1} width={1}>
              <Button
                onClick={() => props.close()}
                variant='text'
              >
                Cancel
              </Button>
              <LoadingButton
                disabled={!selectedRecording || !selectedPrice}
                endIcon={<AddShoppingCartRoundedIcon />}
                loading={loading}
                onClick={() => void confirmTransaction()}
                variant='contained'
              >
                Add to cart
              </LoadingButton>
            </Stack>
          </Stack>
        </Stack>
      </Dialog>
      <ServiceModalComplete
        close={() => {
          setComplete(false)
          props.close()
        }}
        open={complete}
      />
    </>
  )
}

export default ServiceModal
