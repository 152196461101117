/* eslint-disable no-await-in-loop */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable max-lines */
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded'
import BookmarkRoundedIcon from '@mui/icons-material/BookmarkRounded'
import BorderAllRoundedIcon from '@mui/icons-material/BorderAllRounded'
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded'
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded'
import FormatListNumberedRoundedIcon from '@mui/icons-material/FormatListNumberedRounded'
import FormatQuoteRoundedIcon from '@mui/icons-material/FormatQuoteRounded'
import Grid3x3RoundedIcon from '@mui/icons-material/Grid3x3Rounded'
import Grid4x4RoundedIcon from '@mui/icons-material/Grid4x4Rounded'
import ImageRoundedIcon from '@mui/icons-material/ImageRounded'
import Looks3RoundedIcon from '@mui/icons-material/Looks3Rounded'
import LooksOneRoundedIcon from '@mui/icons-material/LooksOneRounded'
import LooksTwoRoundedIcon from '@mui/icons-material/LooksTwoRounded'
import OndemandVideoRoundedIcon from '@mui/icons-material/OndemandVideoRounded'
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded'
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded'
import TextFormatRoundedIcon from '@mui/icons-material/TextFormatRounded'
import YouTubeIcon from '@mui/icons-material/YouTube'
import { LoadingButton } from '@mui/lab'
import { Button, Chip, ListItemIcon, ListItemText, Menu, MenuItem, Stack, TextField, Typography, useTheme } from '@mui/material'
import { insertNode, insertTable, useEditorState } from '@udecode/plate'
import { useState } from 'react'

import { gptText } from 'src/api/taskboards'
import BillingModal from 'src/components/pages/Account/Billing/BillingModal'
import { useAuth } from 'src/components/providers/AuthProvider'

export const newTable = {
  rowCount: 2,
  colCount: 2,
  header: false,
}

export const threeTable = {
  rowCount: 3,
  colCount: 3,
  header: false,
}

export const fourTable = {
  rowCount: 4,
  colCount: 4,
  header: false,
}

const iconSize = 30

const timeout = (delay: number) => new Promise(response => setTimeout(response, delay))

type Props = {
  anchorElementField: HTMLButtonElement | null
  setanchorElementField: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>
  openMenuField: boolean
  handleCloseField: () => void
}

export const DragHandleItemMainComponent = (props: Props) => {
  const themeItem = useTheme()
  const editor = useEditorState()
  const { currentOrganisation } = useAuth()
  const [anchorElementAi, setAnchorElementAi] = useState<HTMLElement | null>(null)
  const openMenuAi = Boolean(anchorElementAi)
  const handleMenuAiClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElementAi(event.currentTarget)
  }
  const handleCloseAi = () => {
    setAnchorElementAi(null)
  }
  const [loadingGpt, setLoadingGpt] = useState(false)
  const [prompt, setPrompt] = useState('')
  const [billingModal, setBillingModalOpen] = useState(false)
  const [aiError, setAiError] = useState(false)

  const generateAiDocument = async () => {
    setLoadingGpt(true)
    await gptText(prompt)
      .then(async textItems => {
        for (const textItem of textItems) {
          if (textItem.length > 0) {
            insertNode(editor,
              {
                type: 'p',
                children: [{ text: textItem }],
              })
          }
          await timeout(125)
        }
      })
      .then(() => {
        setPrompt('')
        handleCloseAi()
        props.handleCloseField()
      })
      .catch(() => {
        setAiError(true)
      })
  }

  return (
    <>
      {props.openMenuField &&
      <Menu
        PaperProps={{
          style: {
            maxHeight: 600,
            height: 600,
            overflow: 'hidden',
          },
        }}
        anchorEl={props.anchorElementField}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={props.handleCloseField}
        open={props.openMenuField}
        sx={{
          '.MuiList-root': {
            paddingTop: 0,
            paddingBottom: 0,
            height: 1,
          },
          overflow: 'hidden',
          minWidth: 300,
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        <Stack height={1} overflow='auto'>
          <Typography
            color={themeItem.palette.text.label}
            fontWeight={300}
            paddingX={2}
            paddingY={1}
            variant='h6'
          >
            Text
          </Typography>
          <MenuItem>
            <ListItemIcon>
              <TextFormatRoundedIcon
                color='primary'
                sx={{
                  fontSize: iconSize,
                }}
              />
            </ListItemIcon>
            <ListItemText
              onClick={() => {
                insertNode(editor,
                  { type: 'p', children: [{ text: '' }] })
                props.setanchorElementField(null)
              }}
              primary='Paragraph'
              secondary='Add a new paragraph'
            />
          </MenuItem>

          <MenuItem>
            <ListItemIcon>
              <LooksOneRoundedIcon
                color='secondary'
                sx={{
                  fontSize: iconSize,
                }}
              />
            </ListItemIcon>
            <ListItemText
              onClick={() => {
                insertNode(editor,
                  { type: 'h1', children: [{ text: '' }] })
                props.setanchorElementField(null)
              }}
              primary='Header 1'
              secondary='Add a large header'
            />
          </MenuItem>

          <MenuItem>
            <ListItemIcon>
              <LooksTwoRoundedIcon
                color='secondary'
                sx={{
                  fontSize: iconSize,
                }}
              />
            </ListItemIcon>
            <ListItemText
              onClick={() => {
                insertNode(editor,
                  { type: 'h2', children: [{ text: '' }] })
                props.setanchorElementField(null)
              }}
              primary='Header 2'
              secondary='Add a medium header'
            />
          </MenuItem>

          <MenuItem>
            <ListItemIcon>
              <Looks3RoundedIcon
                color='secondary'
                sx={{
                  fontSize: iconSize,
                }}
              />
            </ListItemIcon>
            <ListItemText
              onClick={() => {
                insertNode(editor,
                  { type: 'h3', children: [{ text: '' }] })
                props.setanchorElementField(null)
              }}
              primary='Header 3'
              secondary='Add a small header'
            />
          </MenuItem>

          <MenuItem>
            <ListItemIcon>
              <FormatQuoteRoundedIcon
                color='action'
                sx={{
                  fontSize: iconSize,
                }}
              />
            </ListItemIcon>
            <ListItemText
              onClick={() => {
                insertNode(editor,
                  { type: 'blockquote', children: [{ text: '' }] })
                props.setanchorElementField(null)
              }}
              primary='Quote'
              secondary='Add a new quote'
            />
          </MenuItem>

          <Typography
            color={themeItem.palette.text.label}
            fontWeight={300}
            paddingX={2}
            paddingY={1}
            variant='h6'
          >
            Lists
          </Typography>

          <MenuItem>
            <ListItemIcon>
              <CheckBoxRoundedIcon
                color='primary'
                sx={{
                  fontSize: iconSize,
                }}
              />
            </ListItemIcon>
            <ListItemText
              onClick={() => {
                insertNode(editor,
                  { type: 'checklist', children: [{ text: '' }] })
                props.setanchorElementField(null)
              }}
              primary='Todo list'
              secondary='Add a new check list'
            />
          </MenuItem>

          <MenuItem>
            <ListItemIcon>
              <FormatListNumberedRoundedIcon
                color='primary'
                sx={{
                  fontSize: iconSize,
                }}
              />
            </ListItemIcon>
            <ListItemText
              onClick={() => {
                insertNode(editor,
                  {
                    type: 'numbered-list',
                    children:
                  [
                    {
                      type: 'list-item',
                      children:
                        [{
                          text: 'Item 1',
                        }],
                    },
                    {
                      type: 'list-item',
                      children:
                        [{
                          text: 'Item 2',
                        }],
                    },
                    {
                      type: 'list-item',
                      children:
                        [{
                          text: 'Item 3',
                        }],
                    },
                  ],
                  })
                props.setanchorElementField(null)
              }}
              primary='Numbered list'
              secondary='Add a numbered list'
            />
          </MenuItem>
          <MenuItem>
            <ListItemIcon>
              <FormatListBulletedRoundedIcon
                color='primary'
                sx={{
                  fontSize: iconSize,
                }}
              />
            </ListItemIcon>
            <ListItemText
              onClick={() => {
                insertNode(editor,
                  {
                    type: 'bulleted-list',
                    children:
                  [
                    {
                      type: 'list-item',
                      children:
                        [{
                          text: 'Item 1',
                        }],
                    },
                    {
                      type: 'list-item',
                      children:
                        [{
                          text: 'Item 2',
                        }],
                    },
                    {
                      type: 'list-item',
                      children:
                        [{
                          text: 'Item 3',
                        }],
                    },
                  ],
                  })
                props.setanchorElementField(null)
              }}
              primary='Bulleted list'
              secondary='Add a bulleted list'
            />
          </MenuItem>

          <Typography
            color={themeItem.palette.text.label}
            fontWeight={300}
            paddingX={2}
            paddingY={1}
            variant='h6'
          >
            Releese items
          </Typography>

          <MenuItem>
            <ListItemIcon>
              <TaskAltRoundedIcon
                color='success'
                sx={{
                  fontSize: iconSize,
                }}
              />
            </ListItemIcon>
            <ListItemText
              onClick={() => {
                insertNode(editor,
                  { type: 'taskboard', children: [{ text: '' }] })
                props.setanchorElementField(null)
              }}
              primary='Taskboard'
              secondary='Add a taskboard'
            />
          </MenuItem>

          <MenuItem>
            <ListItemIcon>
              <AutoAwesomeRoundedIcon
                color='info'
                sx={{
                  fontSize: iconSize,
                }}
              />
            </ListItemIcon>
            <ListItemText
              onClick={event => {
                handleMenuAiClick(event)
              }}
              primary='AI brainstorm'
              secondary='Releese AI text generation'
            />
          </MenuItem>

          <Typography
            color={themeItem.palette.text.label}
            fontWeight={300}
            paddingX={2}
            paddingY={1}
            variant='h6'
          >
            Banners
          </Typography>

          <MenuItem>
            <ListItemIcon>
              <BookmarkRoundedIcon
                color='error'
                sx={{
                  fontSize: iconSize,
                }}
              />
            </ListItemIcon>
            <ListItemText
              onClick={() => {
                insertNode(editor,
                  { type: 'alert', children: [{ text: '' }] })
                props.setanchorElementField(null)
              }}
              primary='Red banner'
              secondary='Add an error banner'
            />
          </MenuItem>
          <MenuItem>
            <ListItemIcon>
              <BookmarkRoundedIcon
                color='warning'
                sx={{
                  fontSize: iconSize,
                }}
              />
            </ListItemIcon>
            <ListItemText
              onClick={() => {
                insertNode(editor,
                  { type: 'warning', children: [{ text: '' }] })
                props.setanchorElementField(null)
              }}
              primary='Yellow banner'
              secondary='Add an warning banner'
            />
          </MenuItem>
          <MenuItem>
            <ListItemIcon>
              <BookmarkRoundedIcon
                color='info'
                sx={{
                  fontSize: iconSize,
                }}
              />
            </ListItemIcon>
            <ListItemText
              onClick={() => {
                insertNode(editor,
                  { type: 'info', children: [{ text: '' }] })
                props.setanchorElementField(null)
              }}
              primary='Blue banner'
              secondary='Add an info banner'
            />
          </MenuItem>
          <MenuItem>
            <ListItemIcon>
              <BookmarkRoundedIcon
                color='success'
                sx={{
                  fontSize: iconSize,
                }}
              />
            </ListItemIcon>
            <ListItemText
              onClick={() => {
                insertNode(editor,
                  { type: 'success', children: [{ text: '' }] })
                props.setanchorElementField(null)
              }}
              primary='Green banner'
              secondary='Add a success banner'
            />
          </MenuItem>

          <Typography
            color={themeItem.palette.text.label}
            fontWeight={300}
            paddingX={2}
            paddingY={1}
            variant='h6'
          >
            Tables
          </Typography>
          <MenuItem>
            <ListItemIcon>
              <BorderAllRoundedIcon
                color='action'
                sx={{
                  fontSize: iconSize,
                }}
              />
            </ListItemIcon>
            <ListItemText
              onClick={() => {
                insertTable(editor, newTable)
                props.setanchorElementField(null)
              }}
              primary='2x2 Table'
              secondary='Add a small table'
            />
          </MenuItem>

          <MenuItem>
            <ListItemIcon>
              <Grid3x3RoundedIcon
                color='action'
                sx={{
                  fontSize: iconSize,
                }}
              />
            </ListItemIcon>
            <ListItemText
              onClick={() => {
                insertTable(editor, threeTable)
                props.setanchorElementField(null)
              }}
              primary='3x3 Table'
              secondary='Add a medium table'
            />
          </MenuItem>

          <MenuItem>
            <ListItemIcon>
              <Grid4x4RoundedIcon
                color='action'
                sx={{
                  fontSize: iconSize,
                }}
              />
            </ListItemIcon>
            <ListItemText
              onClick={() => {
                insertTable(editor, fourTable)
                props.setanchorElementField(null)
              }}
              primary='4x4 Table'
              secondary='Add a large table'
            />
          </MenuItem>

          <Typography
            color={themeItem.palette.text.label}
            fontWeight={300}
            paddingX={2}
            paddingY={1}
            variant='h6'
          >
            Media
          </Typography>

          <MenuItem>
            <ListItemIcon>
              <ImageRoundedIcon
                color='action'
                sx={{
                  fontSize: iconSize,
                }}
              />
            </ListItemIcon>
            <ListItemText
              onClick={() => {
                insertNode(editor,
                  { type: 'cloud', children: [{ text: '' }] })
                props.setanchorElementField(null)
              }}
              primary='Image'
              secondary='Add an image'
            />
          </MenuItem>

          <MenuItem>
            <ListItemIcon>
              <OndemandVideoRoundedIcon
                color='action'
                sx={{
                  fontSize: iconSize,
                }}
              />
            </ListItemIcon>
            <ListItemText
              onClick={() => {
                insertNode(editor,
                  { type: 'iframeplugin', children: [{ text: '' }] })
                props.setanchorElementField(null)
              }}
              primary='Video'
              secondary='Add an embedded video'
            />
          </MenuItem>

          <MenuItem>
            <ListItemIcon>
              <YouTubeIcon
                sx={{
                  fontSize: iconSize,
                  color: theme => theme.palette.youtube,
                }}
              />
            </ListItemIcon>
            <ListItemText
              onClick={() => {
                insertNode(editor,
                  { type: 'iframeplugin', children: [{ text: '' }] })
                props.setanchorElementField(null)
              }}
              primary='YouTube'
              secondary='Add a YouTube video'
            />
          </MenuItem>

          <Typography
            color={themeItem.palette.text.label}
            fontWeight={300}
            paddingX={2}
            paddingY={1}
            variant='h6'
          >
            Layout
          </Typography>

          <MenuItem>
            <ListItemIcon>
              <RemoveRoundedIcon
                color='action'
                sx={{
                  fontSize: iconSize,
                }}
              />
            </ListItemIcon>
            <ListItemText
              onClick={() => {
                insertNode(editor,
                  { type: 'divider', children: [{ text: '' }] })
                props.setanchorElementField(null)
              }}
              primary='Divider'
              secondary='Divide text'
            />
          </MenuItem>
        </Stack>
      </Menu>}

      {currentOrganisation?.membershipTier !== 'FREE' && openMenuAi &&
      <Menu
        anchorEl={anchorElementAi}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={handleCloseAi}
        open={openMenuAi}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
      >
        <Stack padding={2} spacing={2} width={360}>
          <Stack marginBottom={2} width={1}>
            <Stack alignItems='center' direction='row' justifyContent='space-between' width={1}>
              <Typography variant='h2'>
                AI brainstorm
              </Typography>
              <Chip
                color='success'
                label='Beta'
                variant='subtle'
              />
            </Stack>
            <Typography variant='body2'>
              Turn ideas into text using the GPT-3 model.
            </Typography>
            {aiError &&
            <Typography color='error' variant='body2'>
              There was a problem generating your text, please try again
            </Typography>}
          </Stack>
          <TextField
            label='Prompt'
            minRows={4}
            multiline
            onChange={event => setPrompt(event.target.value)}
            placeholder='Generate lyrics for a commercial pop song with love as the main theme'
            value={prompt}
          />
          <LoadingButton
            color='primary'
            loading={loadingGpt}
            onClick={() => generateAiDocument()}
            startIcon={<AutoAwesomeRoundedIcon />}
            variant='contained'
          >
            Generate text
          </LoadingButton>
        </Stack>
      </Menu>}

      {currentOrganisation?.membershipTier === 'FREE'  && openMenuAi &&
      <Menu
        anchorEl={anchorElementAi}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={handleCloseAi}
        open={openMenuAi}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
      >
        <Stack padding={2} spacing={2} width={360}>
          <Stack width={1}>
            <Stack alignItems='center' direction='row' justifyContent='space-between' width={1}>
              <Typography variant='h2'>
                AI brainstorm
              </Typography>
              <Chip
                color='success'
                label='Beta'
                variant='subtle'
              />
            </Stack>
            <Typography variant='body2'>
              Turn ideas into text using the GPT-3 model.
              Available on all paid plans
            </Typography>

          </Stack>
          <Button
            color='info'
            onClick={() => setBillingModalOpen(true)}
            startIcon={<AutoAwesomeRoundedIcon />}
          >
            Upgrade plan
          </Button>
        </Stack>
      </Menu>}
      {billingModal && currentOrganisation &&
      <BillingModal
        account={currentOrganisation}
        close={() => setBillingModalOpen(false)}
        open={billingModal}
      />}
    </>
  )
}
