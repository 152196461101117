import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import { Button, Paper, Stack, Typography } from '@mui/material'

import LogoR from 'src/images/LogoR.svg'

type Props = {
  readonly title: string
  readonly description: string
  readonly step: number
  readonly goTo: (step: number) => void
}

const TourHeader = (props: Props) =>
  <Paper elevation={0} sx={{ borderRadius: 0 }}>
    <Stack direction='column' spacing={2} width={1}>
      <Stack justifyContent='center' width={1}>
        <img
          alt='Releese Logo'
          height='96px'
          loading='lazy'
          src={LogoR}
        />
      </Stack>
      <Stack justifyContent='center' width={1}>
        <Typography textAlign='center' variant='h4'>
          {props.title}
        </Typography>
        <Typography textAlign='center' variant='body1'>
          {props.description}
        </Typography>
      </Stack>
      <Button endIcon={<ArrowForwardRoundedIcon />} onClick={() => props.goTo(props.step)} rounded>
        Get started
      </Button>
    </Stack>
  </Paper>

export default TourHeader
