/* eslint-disable no-restricted-imports */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable react-hooks/exhaustive-deps */
import BookmarkRoundedIcon from '@mui/icons-material/BookmarkRounded'
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded'
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded'
import FormatListNumberedRoundedIcon from '@mui/icons-material/FormatListNumberedRounded'
import FormatQuoteRoundedIcon from '@mui/icons-material/FormatQuoteRounded'
import Looks3RoundedIcon from '@mui/icons-material/Looks3Rounded'
import LooksOneRoundedIcon from '@mui/icons-material/LooksOneRounded'
import LooksTwoRoundedIcon from '@mui/icons-material/LooksTwoRounded'
import TextFormatRoundedIcon from '@mui/icons-material/TextFormatRounded'
import TitleRoundedIcon from '@mui/icons-material/TitleRounded'
import { InputAdornment, MenuItem, Select, Stack, Typography } from '@mui/material'
import {
  useEditorState,
} from '@udecode/plate'

import { isBlockActive, toggleBlock } from 'src/components/pages/Notes/Plugins/Block'

const blockOptions = [
  'p',
  'h1',
  'h2',
  'h3',
  'blockquote',
  'checklist',
  'numbered-list',
  'bulleted-list',
  'alert',
  'warning',
  'info',
  'success',
]

const blockOptionsPretty = [
  'Paragraph',
  'Large title',
  'Medium title',
  'Small title',
  'Quote',
  'Todo list',
  'Numbered list',
  'Bulleted list',
  'Red banner',
  'Yellow banner',
  'Blue banner',
  'Green banner',
]

export type BlockItem =
  'alphaColor' |
  'bold' |
  'center' |
  'code' |
  'href' |
  'isCaret' |
  'italic' |
  'justify' |
  'left' |
  'right' |
  'underline'

const getPrettyIcon = (text: string) => {
  switch (text) {
    case blockOptionsPretty[0]:
      return <TextFormatRoundedIcon color='primary' />
    case blockOptionsPretty[1]:
      return <LooksOneRoundedIcon color='secondary' />
    case blockOptionsPretty[2]:
      return <LooksTwoRoundedIcon color='secondary' />
    case blockOptionsPretty[3]:
      return <Looks3RoundedIcon color='secondary'  />
    case blockOptionsPretty[4]:
      return <FormatQuoteRoundedIcon color='action' />
    case blockOptionsPretty[5]:
      return <CheckBoxRoundedIcon color='primary'  />
    case blockOptionsPretty[6]:
      return <FormatListNumberedRoundedIcon color='primary' />
    case blockOptionsPretty[7]:
      return <FormatListBulletedRoundedIcon color='primary' />
    case blockOptionsPretty[8]:
      return <BookmarkRoundedIcon color='error' />
    case blockOptionsPretty[9]:
      return <BookmarkRoundedIcon color='warning' />
    case blockOptionsPretty[10]:
      return <BookmarkRoundedIcon color='info' />
    case blockOptionsPretty[11]:
      return <BookmarkRoundedIcon color='success' />
    default:
      return <TitleRoundedIcon color='action' />
  }
}

type Props = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const SelectTypeToolbar = (props: Props) => {
  const editor = useEditorState()
  const active = blockOptions.map(item => isBlockActive(editor, item as BlockItem)).indexOf(true)

  return (
    <Select
      inputProps={{
        startAdornment:
  <InputAdornment position='start'>
    {getPrettyIcon(blockOptionsPretty[active])}
  </InputAdornment>,
      }}
      onChange={event => {
        event.preventDefault()
        const foundValue = blockOptionsPretty.indexOf(event.target.value)
        if (foundValue !== -1) {
          toggleBlock(editor, blockOptions[foundValue] as BlockItem)
          props.setOpen(false)
        }
      }}
      onClick={event => {
        event.preventDefault()
      }}
      sx={{
        marginTop: 0,
        width: 200,
        background: 'transparent!important',
        border: 'none',
        '.MuiInputBase-root': {
          background: 'transparent!important',
          borderColor: 'transparent!important',
          paddingY: 0,
          marginTop: 0,
        },
      }}
      value={active !== -1
        ? blockOptionsPretty[active]
        : 'p'}
    >
      {blockOptionsPretty.map(option =>
        <MenuItem key={option} onClick={event => event.preventDefault()} value={option}>
          <Stack alignItems='center' direction='row' height={1} spacing={1}>
            {getPrettyIcon(option)}
            <Typography
              sx={{ color: theme => theme.palette.text.secondary }}
              variant='body1'
            >
              {option}
            </Typography>
          </Stack>
        </MenuItem>)}
    </Select>
  )
}

export default SelectTypeToolbar

