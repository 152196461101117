import type { SvgIconProps } from '@mui/material/SvgIcon'
import SvgIcon from '@mui/material/SvgIcon'

const ShazamIcon = (props: SvgIconProps) =>
  <SvgIcon {...props} viewBox='10 10 28 28' >
    <path d='M20,32c-2.4,0-4.7-0.9-6.4-2.6c-3.5-3.5-3.5-9.2,0-12.7l6-6c0.8-0.8,2-0.8,2.8,
    0c0.8,0.8,0.8,2,0,2.8 l-6,6c-1.9,1.9-1.9,5.1,0,7.1c0.9,0.9,2.2,1.5,3.5,1.5s2.6-0.5,
    3.5-1.5l3.1-3c0.8-0.8,2.1-0.7,2.8,0c0.8,0.8,0.7,2.1,0,2.8l-3,3 C24.7,31.1,22.4,32,20,32z'
    />
    <path d='M27,37.9c-0.5,0-1-0.2-1.4-0.6c-0.8-0.8-0.8-2,0-2.8l6-6c1.9-2,1.9-5.1,
    0-7.1 C30.6,20.5,29.4,20,28,20c-1.3,0-2.6,0.5-3.5,1.5l-3.1,3c-0.8,0.8-2.1,0.7-2.8,
    0c-0.8-0.8-0.7-2.1,0-2.8l3.1-3 c1.7-1.7,3.9-2.6,6.3-2.6s4.7,
    0.9,6.4,2.6c3.5,3.5,3.5,9.2,0,12.7l-6,6C28,37.7,27.5,37.9,27,37.9z'
    />
  </SvgIcon>

export default ShazamIcon
