import CalculateRoundedIcon from '@mui/icons-material/CalculateRounded'
import CurrencyExchangeRoundedIcon from '@mui/icons-material/CurrencyExchangeRounded'
import HandshakeRoundedIcon from '@mui/icons-material/HandshakeRounded'
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded'
import StorageRoundedIcon from '@mui/icons-material/StorageRounded'
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded'
import { Button, Card, CardActionArea, darken, Divider, Stack, Typography, useTheme } from '@mui/material'

type Props = {
  readonly activeBilling: number
  readonly billingCycle: number
  readonly handleBilling: (step: number) => () => void
  readonly enterprise: number
  readonly month: number
  readonly seats: number
}

const EnterpriseCard = (props: Props) => {
  const themeItem = useTheme()
  return (
    <Card
      sx={{
        borderColor: props.activeBilling === props.enterprise
          ? '#327659!important'
          : null,
      }}
      variant='outlined'
    >
      <CardActionArea onClick={props.handleBilling(props.enterprise)} sx={{ height: 1 }} >
        <Stack alignItems='flex-start' direction='column' height={1} padding={2} spacing={2}>
          <Stack>
            <Typography fontWeight='normal' variant='h2'>
              Business
            </Typography>
            <Stack alignItems='flex-start' direction='column' spacing={1}>
              <Typography fontFamily='Domaine-Display' lineHeight={1} variant='h1'>
                <span style={{ fontFamily: 'Matter' }}>
                  $
                </span>
                {props.billingCycle === props.month
                  ? <>
                    {((props.billingCycle *
                  props.enterprise) +
                  Math.max(0, props.billingCycle *
                  (props.seats - 3) *
                  19))}
                  </>
                  : <>
                    {(((props.billingCycle *
                    props.enterprise) +
                    Math.max(0, props.billingCycle *
                    (props.seats - 3) *
                    19)) / 12).toFixed(2)}
                  </>}
              </Typography>
              {props.billingCycle === props.month
                ? <Typography variant='body1'>
                  / month
                </Typography>
                : <Typography variant='body1'>
                  / month, billed yearly
                </Typography>}
            </Stack>
          </Stack>
          <Typography
            color={themeItem.palette.text.secondary}
            variant='body2'
          >
            Next-gen music management and financial tools.
          </Typography>
          <Button
            color='info'
            fullWidth
            sx={{
              background: '#327659',
              '&:hover': {
                background: darken('#327659', 0.25),
              },
            }}
          >
            Select Business
          </Button>
          <Stack direction='column'>
            <Typography lineHeight={1.4} variant='h3'>
              Features
            </Typography>
            <Typography color={themeItem.palette.text.secondary} variant='body2'>
              Everything in Pro and...
            </Typography>
          </Stack>
          <Divider sx={{ width: 1 }} />
          <Stack direction='column' spacing={3}>
            <Stack alignItems='center' direction='row' spacing={1}>
              <StorageRoundedIcon color='action' fontSize='small' />
              <Typography color='text.secondary' variant='body2'>
                10 Tb storage
              </Typography>
            </Stack>
            <Stack alignItems='center' direction='row' spacing={1}>
              <PeopleAltRoundedIcon color='action' fontSize='small' />
              <Typography
                color='text.secondary'
                variant='body2'
              >
                3 People Included
              </Typography>
            </Stack>
            <Stack alignItems='center' direction='row' spacing={1}>
              <PersonRoundedIcon color='action' fontSize='small' />
              <Typography color='text.secondary' variant='body2'>
                19$ / Additional Person
              </Typography>
            </Stack>
            <Stack alignItems='center' direction='row' spacing={1}>
              <SupportAgentRoundedIcon color='action' fontSize='small' />
              <Typography color='text.secondary' variant='body2'>
                Premium Support
              </Typography>
            </Stack>
            <Stack alignItems='center' direction='row' spacing={1}>
              <CurrencyExchangeRoundedIcon color='action' fontSize='small' />
              <Typography color='text.secondary' variant='body2'>
                Royalty Recoups
              </Typography>
            </Stack>
            <Stack alignItems='center' direction='row' spacing={1}>
              <CalculateRoundedIcon color='action' fontSize='small' />
              <Typography color='text.secondary' variant='body2'>
                Album Accounting
              </Typography>
            </Stack>
            <Stack alignItems='center' direction='row' spacing={1}>
              <HandshakeRoundedIcon color='action' fontSize='small' />
              <Typography color='text.secondary' variant='body2'>
                Personalized Onboarding
              </Typography>
            </Stack>
            <Stack alignItems='center' direction='row' spacing={1}>
              <SecurityRoundedIcon color='action' fontSize='small' />
              <Typography color='text.secondary' variant='body2'>
                Custom User Roles
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </CardActionArea>
    </Card>
  )
}

export default EnterpriseCard
