/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable unicorn/prefer-switch */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/default-param-last */
/* eslint-disable no-restricted-imports */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable max-lines */
/* eslint-disable sonarjs/no-duplicate-string */
// TODO Clean up and seperate out component, probably to redo
import { Alert, Autocomplete, Box, createFilterOptions, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material'
import _ from 'lodash'
import type { ChangeEvent } from 'react'
import { useEffect } from 'react'

import { getRefreshedBankInfo } from 'src/api/payout'
import { FormInput } from 'src/components/pages/Wallet/OnboardingModal/FormInput'
import type { PayoutUser, RequirementsBank } from 'src/models/Payout'

type Props = {
  requirements: RequirementsBank[]
  recipientData: PayoutUser
  setRecipientData: React.Dispatch<React.SetStateAction<PayoutUser>>
  setRequirements: React.Dispatch<React.SetStateAction<RequirementsBank[] | undefined>>
  setRecipientDataErrorMap: React.Dispatch<any>
  recipientDataErrorMap: any
}

const OnboardingDetails = (props: Props) => {
  const accountRequirements = props.requirements

  const initializeErrorMap = () => {
    const errorMap = { ...props.recipientDataErrorMap }

    for (const element of accountRequirements) {
      if (!errorMap[`${element.type}`]) {
        errorMap[`${element.type}`] = {}
      }

      for (const requiredField of element.fields) {
        for (const subField of requiredField.group) {
          const embeddedKeys = subField.key.split('.')

          if (embeddedKeys && embeddedKeys.length === 1) {
            errorMap[`${element.type}`][`${embeddedKeys[0]}`] = false
          } else if (embeddedKeys && embeddedKeys.length === 2) {
            if (!errorMap[`${element.type}`][`${embeddedKeys[0]}`]) {
              errorMap[`${element.type}`][`${embeddedKeys[0]}`] = {}
            }

            errorMap[`${element.type}`][`${embeddedKeys[0]}`][
              `${embeddedKeys[1]}`
            ] = false
          }
        }
      }
    }

    errorMap[`${accountRequirements[0].type}`].accountHolderName = false

    props.setRecipientDataErrorMap(errorMap)
  }

  const getErrorFromErrorMap = (propertyKey: string) => {
    const embeddedKeys = propertyKey.split('.')

    if (embeddedKeys && embeddedKeys.length === 1) {
      return props.recipientDataErrorMap?.[`${props.recipientData.bankDetailsFormat}`]?.[`${embeddedKeys[0]}`]
        ? props.recipientDataErrorMap[`${props.recipientData.bankDetailsFormat}`][
          `${embeddedKeys[0]}`
        ] : false
    } else if (embeddedKeys && embeddedKeys.length === 2) {
      return props.recipientDataErrorMap?.[`${props.recipientData.bankDetailsFormat}`]
        ?.[`${embeddedKeys[0]}`]?.[`${embeddedKeys[1]}`]
        ? props.recipientDataErrorMap[`${props.recipientData.bankDetailsFormat}`][
          `${embeddedKeys[0]}`
        ][`${embeddedKeys[1]}`] : false
    }

    return false
  }

  const cleanUpErrorMap = () => {
    initializeErrorMap()
  }

  const initializeRecipientData = (type: string, isFirstRender: boolean) => {
    const newDataMap = isFirstRender ? {} : { ...props.recipientData.details }
    const targetedRequirements = accountRequirements.filter(
      request => request.type === type
    )

    for (const element of targetedRequirements) {
      for (const requiredField of element.fields) {
        for (const subField of requiredField.group) {
          const embeddedKeys = subField.key.split('.')

          if (embeddedKeys && embeddedKeys.length === 1) {
            if (!newDataMap[`${embeddedKeys[0]}`]) {
              if (embeddedKeys[0] === 'legalType') {
                newDataMap[`${embeddedKeys[0]}`] = props.recipientData.details.legalType
              } else if (embeddedKeys[0] === 'type') {
                newDataMap[`${embeddedKeys[0]}`] = props.recipientData.type
              } else {
                newDataMap[`${embeddedKeys[0]}`] = ''
              }
            }
          } else if (embeddedKeys && embeddedKeys.length === 2) {
            if (!newDataMap[`${embeddedKeys[0]}`]) {
              newDataMap[`${embeddedKeys[0]}`] = {}
            }

            if (!newDataMap[`${embeddedKeys[0]}`][`${embeddedKeys[1]}`]) {
              newDataMap[`${embeddedKeys[0]}`][`${embeddedKeys[1]}`] = ''
            }
          }
        }
      }
    }

    props.setRecipientData({
      ...props.recipientData,
      type,
      details: newDataMap,
      bankDetailsFormat: type,
    })
  }

  const getFieldValueFromRecipientData = (propertyKey: string) => {
    if (propertyKey) {
      const embeddedKeys = propertyKey.split('.')
      if (embeddedKeys && embeddedKeys.length === 1) {
        return props.recipientData.details[`${embeddedKeys[0]}`]
          ? props.recipientData.details[`${embeddedKeys[0]}`]
          : ''
      } else if (embeddedKeys && embeddedKeys.length === 2) {
        return props.recipientData.details[`${embeddedKeys[0]}`]?.[`${embeddedKeys[1]}`]
          ? props.recipientData.details[`${embeddedKeys[0]}`][`${embeddedKeys[1]}`]
          : ''
      } else if (embeddedKeys && embeddedKeys.length === 3) {
        return props.recipientData.details[`${embeddedKeys[0]}`]?.[`${embeddedKeys[1]}`]?.[`${embeddedKeys[2]}`]
          ? props.recipientData.details[`${embeddedKeys[0]}`][`${embeddedKeys[1]}`][`${embeddedKeys[2]}`]
          : ''
      } else {
        return ''
      }
    } else {
      return ''
    }
  }

  const cleanUpRecipientData = (type: string) => {
    initializeRecipientData(type, false)
  }

  const changeType = (type: string) => {
    initializeRecipientData(type, true)
  }

  const handleBankDetailFormatChange = (event: ChangeEvent<HTMLInputElement>) => {
    cleanUpErrorMap()
    cleanUpRecipientData(event.target.value)
  }

  const handleAccountHolderNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    props.setRecipientData({
      ...props.recipientData,
      accountHolderName: event.target.value,
    })
  }

  const getStringReadable = (valuesAllowed: { key: string, name: string } [], key: string) => {
    const item = valuesAllowed.find(value => value.key === key)
    return item?.name
  }

  const validateRequiredField = (
    propertyKey: string,
    validationRegexp: string,
  ) => {
    const embeddedKeys = propertyKey.split('.')
    const errorMap = { ...props.recipientDataErrorMap }
    let inputValue = ''
    let isInputValueCorrect = true

    if (propertyKey === 'accountHolderName') {
      inputValue = props.recipientData.accountHolderName
    } else if (embeddedKeys && embeddedKeys.length === 1) {
      inputValue = props.recipientData.details[`${embeddedKeys[0]}`]
    } else if (embeddedKeys && embeddedKeys.length === 2) {
      inputValue = props.recipientData.details[`${embeddedKeys[0]}`]
        ? props.recipientData.details[`${embeddedKeys[0]}`][`${embeddedKeys[1]}`]
        : ''
    }

    if (validationRegexp) {
      const vRegexp = new RegExp(`${validationRegexp}`)
      isInputValueCorrect = vRegexp.test(inputValue)
    }

    if (propertyKey === 'accountHolderName') {
      errorMap[`${props.recipientData.bankDetailsFormat}`].accountHolderName =
        !isInputValueCorrect
    } else if (embeddedKeys && embeddedKeys.length === 1) {
      errorMap[`${props.recipientData.bankDetailsFormat}`][`${embeddedKeys[0]}`] =
        !isInputValueCorrect
    } else if (embeddedKeys && embeddedKeys.length === 2) {
      errorMap[`${props.recipientData.bankDetailsFormat}`][`${embeddedKeys[0]}`][
        `${embeddedKeys[1]}`
      ] = !isInputValueCorrect
    }

    props.setRecipientDataErrorMap(errorMap)
  }

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option: any) => option.name,
  })

  const handleRequiredFieldChange = async (propertyKey: string,
    refreshRequirements: boolean,
    event?: ChangeEvent<HTMLInputElement>,
    value?: string) => {
    const updatedData = { ...props.recipientData.details }
    const embeddedKeys = propertyKey.split('.')

    if (embeddedKeys && embeddedKeys.length === 1) {
      updatedData[`${embeddedKeys[0]}`] = event ? event?.target.value : value
    } else if (embeddedKeys && embeddedKeys.length === 2) {
      if (!updatedData[`${embeddedKeys[0]}`]) {
        updatedData[`${embeddedKeys[0]}`] = {}
      }

      updatedData[`${embeddedKeys[0]}`][`${embeddedKeys[1]}`] =
        event ? event?.target.value : value
    }

    initializeRecipientData(accountRequirements[0].type ?? '', false)
    props.setRecipientData({ ...props.recipientData, details: updatedData })

    if (refreshRequirements && updatedData.address.country && updatedData.legalType) {
      const newObject = _.cloneDeep(props.recipientData)
      newObject.details = updatedData
      await getRefreshedBankInfo(props.recipientData.currency, newObject)
        .then(props.setRequirements)
    }
  }

  useEffect(() => {
    if (accountRequirements && accountRequirements.length > 0) {
      initializeErrorMap()
      initializeRecipientData(accountRequirements[0].type, true)
    }
  }, [])

  useEffect(() => {
    if (accountRequirements && accountRequirements.length > 0) {
      initializeErrorMap()
      initializeRecipientData(props.recipientData.type ?? accountRequirements[0].type, false)
    }
  }, [accountRequirements])

  useEffect(() => {
    changeType(props.recipientData.type ?? accountRequirements[0].type)
  }, [props.recipientData.type])

  return (
    <>
      {accountRequirements && accountRequirements.length > 0
        ? <Stack spacing={2} width={1}>
          <FormControl component='fieldset'>
            <Typography>
              Bank details
            </Typography>
            <RadioGroup
              aria-label='Bank details type'
              name='bankDetailsType'
              onChange={handleBankDetailFormatChange}
              row
              value={props.recipientData.bankDetailsFormat}
            >
              {accountRequirements.map(option =>
                <FormControlLabel
                  control={<Radio />}
                  key={option.type}
                  label={option.title}
                  value={option.type}
                />)}
            </RadioGroup>
          </FormControl>

          {accountRequirements
            .filter(
              requirement =>
                requirement.type === props.recipientData.bankDetailsFormat &&
                requirement.usageInfo
            )
            .map(requirement =>
              <Stack key={requirement.type}>
                <Alert
                  severity='warning'
                  sx={{
                    color: theme => theme.palette.text.primary,
                  }}
                  variant='filled'
                >
                  {requirement.usageInfo}
                </Alert>
              </Stack>)}

          <Stack>
            <FormControl>
              <FormInput
                description='Account Holder Name'
                disabled={false}
                error={
                  props.recipientDataErrorMap?.[`${props.recipientData.bankDetailsFormat}`].accountHolderName
                }
                helperText='Invalid full name / business name'
                onBlur={() =>
                  validateRequiredField('accountHolderName', '^.{1,255}$')}
                onChange={handleAccountHolderNameChange}
                placeholder='John Smith / Microsoft'
                value={props.recipientData.accountHolderName}
              />
            </FormControl>
          </Stack>

          {accountRequirements &&
            accountRequirements.length > 0 &&
            accountRequirements.map(requirement => {
              if (requirement.type === props.recipientData.bankDetailsFormat) {
                return (
                  <Stack
                    id={`required-fields-${requirement.type}`}
                    key={requirement.type}
                    spacing={2}
                  >
                    {requirement?.fields &&
                      requirement.fields.length > 0 &&
                      requirement.fields.map(requiredField => {
                        if (
                          requiredField?.group &&
                          requiredField.group.length > 0
                        ) {
                          return requiredField.group.map(subField => {
                            if (subField.type === 'select' &&
                            subField.valuesAllowed &&
                            subField.valuesAllowed.length > 0) {
                              return (

                                <Stack
                                  key={`${subField.key}-${requirement.type}`}
                                  spacing={4}
                                  width={1}
                                >
                                  {subField.key === 'address.country'
                                    ? <Stack>
                                      <Typography variant='h5'>
                                        Address
                                      </Typography>
                                    </Stack>
                                    : null}
                                  <FormControl>
                                    <Autocomplete
                                      filterOptions={filterOptions}
                                      fullWidth
                                      getOptionLabel={option =>
                                        getStringReadable(subField.valuesAllowed, option as string ?? '') ?? ''}
                                      isOptionEqualToValue={(option, value) => (option?.key ?? '') === value}
                                      onChange={(event, newValue) =>
                                        handleRequiredFieldChange(
                                          subField?.key ?? '',
                                          subField?.refreshRequirementsOnChange,
                                          undefined,
                                          newValue?.key as string | undefined ?? ''
                                        )}
                                      options={subField.valuesAllowed}
                                      renderInput={params =>
                                        <TextField
                                          {...params}
                                          label={subField.name}
                                          placeholder={subField.name}
                                          sx={{
                                            marginTop: 2,
                                          }}
                                        />}
                                      renderOption={(renderProps, option) =>
                                        <Box component='li' {...renderProps} key={`${subField.key}-${option.key}`}>
                                          <Stack alignItems='center' direction='row' spacing={1}>
                                            <Typography>
                                              {option.name}
                                            </Typography>
                                          </Stack>
                                        </Box>}
                                      value={getFieldValueFromRecipientData(
                                        subField.key
                                      )}
                                    />
                                  </FormControl>
                                </Stack>
                              )
                            } else if (subField.type === 'radio') {
                              return (
                                <Stack
                                  key={`${subField.key}-${requirement.type}`}
                                  width={1}
                                >
                                  <FormControl component='fieldset'>
                                    <FormLabel component='legend'>
                                      {' '}
                                      {subField.name}
                                      {' '}
                                    </FormLabel>
                                    <RadioGroup
                                      aria-label='gender'
                                      name='row-radio-buttons-group'
                                      onChange={event =>
                                        handleRequiredFieldChange(
                                          subField.key,
                                          subField.refreshRequirementsOnChange,
                                          event,
                                        )}
                                      row
                                      value={getFieldValueFromRecipientData(
                                        subField.key
                                      )}
                                    >
                                      {subField.valuesAllowed?.map(
                                        radioItem =>
                                          <FormControlLabel
                                            control={<Radio />}
                                            key={`${subField.key}-${radioItem.key}`}
                                            label={radioItem.name}
                                            value={radioItem.key}
                                          />

                                      )}
                                    </RadioGroup>
                                  </FormControl>
                                </Stack>
                              )
                            } else {
                              return (
                                <Stack
                                  key={`${subField.key}-${requirement.type}`}
                                  width={1}
                                >
                                  <FormControl>
                                    <FormInput
                                      description={subField.name}
                                      disabled={false}
                                      error={getErrorFromErrorMap(subField.key)}
                                      helperText={`Invalid ${subField.name}`}
                                      onBlur={() =>
                                        validateRequiredField(
                                          subField.key,
                                          subField.validationRegexp,
                                        )}
                                      onChange={event =>
                                        handleRequiredFieldChange(
                                          subField.key,
                                          subField.refreshRequirementsOnChange,
                                          event,
                                        )}
                                      placeholder={subField.example}
                                      value={getFieldValueFromRecipientData(
                                        subField.key
                                      )}
                                    />
                                  </FormControl>
                                </Stack>
                              )
                            }
                          })
                        }
                      })}
                  </Stack>
                )
              }
            })}
        </Stack>
        : <Stack />}
    </>
  )
}
export default OnboardingDetails
