/* eslint-disable @typescript-eslint/no-extra-parens */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable max-len */
/* eslint-disable sonarjs/no-nested-template-literals */
import axios from 'axios'
import download from 'js-file-download'

import { AnalyticsMonthlyChart, AnalyticsTopArtists } from 'src/models/Analytics'

const PATH = '/earnings' as const

export const getAnalyticsStatementTopReleases = (stores: string[], periods: string[], isrc?: string[], projectId?: string) =>
  axios.get<{
    _id: {
      isrc: string
      projectId: string
    }
    creations: number
    downloads: number
    streams: number
    views: number
    amount: number
  }[]
  >(`${PATH}/top/current?projectId=${projectId ? projectId : 'unset'}
${stores.length > 0 ? '&' : ''}${stores.map((item, index) => `store[${index}]=${item}`).join('&')}
${periods.length > 0 ? '&' : ''}${periods.map((item, index) => `period[${index}]=${item}`).join('&')}
${(isrc && isrc.length > 0) ? '&' : ''}${isrc ? isrc.map((item, index) => `isrc[${index}]=${item}`).join('&') : ''}`)
    .then(response => response.data.map(dto => new AnalyticsMonthlyChart({
      amount: dto.amount,
      _id: dto._id.isrc,
      streams: dto.streams,
      creations: dto.creations,
      views: dto.views,
      downloads: dto.downloads,
      projectId: dto._id.projectId,
    })))

export const getAnalyticsStatement = (stores: string[], isrc?: string[], projectId?: string) =>
  axios.get<AnalyticsMonthlyChart[]>(`${PATH}/total/current?${projectId ? `projectId=${projectId}&` : 'projectId=unset&'}
${stores.map((item, index) => `store[${index}]=${item}`).join('&')}&
${isrc?.map((item, index) => `isrc[${index}]=${item}`).join('&')}`)
    .then(response => response.data.map(dto => new AnalyticsMonthlyChart(dto)))

export const getAnalyticsStatementStores = (periods: string[], isrc?: string[], projectId?: string) =>
  axios.get<AnalyticsMonthlyChart[]>(`${PATH}/stores/current?${projectId ? `projectId=${projectId}&` : 'projectId=unset&'}
${periods.map((item, index) => `period[${index}]=${item}`).join('&')}&
${isrc?.map((item, index) => `isrc[${index}]=${item}`).join('&')}`)
    .then(response => response.data.map(dto => new AnalyticsMonthlyChart(dto)))

export const getTopArtists = () =>
  axios.get<AnalyticsTopArtists[]>(`${PATH}/top/artists`)
    .then(response => response.data.map(dto => new AnalyticsTopArtists(dto)))

export const getAnalyticsStatementTerritory = (stores: string[],
  periods: string[],
  isrc?: string[],
  projectId?: string) =>
  axios.get<AnalyticsMonthlyChart[]>(`${PATH}/territories/current?${projectId ? `projectId=${projectId}&` : 'projectId=unset&'}
${stores.map((item, index) => `store[${index}]=${item}`).join('&')}&
${periods.map((item, index) => `period[${index}]=${item}`).join('&')}&
${isrc?.map((item, index) => `isrc[${index}]=${item}`).join('&')}`)
    .then(response => response.data.map(dto => new AnalyticsMonthlyChart(dto)))

export const getNetOverall = () =>
  axios.get<{
    _id: {
      month: number
      year: number
    }
    amount: number
  }[]>(`${PATH}/net/overall`)
    .then(response => response.data.map(dto => new AnalyticsMonthlyChart({
      amount: dto.amount,
      _id: `${dto._id.year}/${dto._id.month}/01`,
      streams: 0,
      creations: 0,
      views: 0,
      downloads: 0,
      projectId: '',
    })))

export const getNetProject = (isrc?: string[], projectId?: string) =>
  axios.get<{
    _id: {
      month: number
      year: number }
    amount: number
  }[]>(`${PATH}/net/project?${projectId ? `projectId=${projectId}&` : 'projectId=unset&'}
${isrc?.map((item, index) => `isrc[${index}]=${item}`).join('&')}`)
    .then(response => response.data.map(dto => new AnalyticsMonthlyChart({
      amount: dto.amount,
      _id: `${dto._id.year}/${dto._id.month}/01`,
      streams: 0,
      creations: 0,
      views: 0,
      downloads: 0,
      projectId: '',
    })))

export const downloadStatement = (period: string, year: string, month: string) =>
  axios.put<File>(`${PATH}/export/${period}`)
    .then(response => download(response.data, `Statement ${month}-${year}.csv`))

export const downloadStatementProject = (period: string, projectId: string, year: string, month: string) =>
  axios.put<File>(`${PATH}/export-project/${period}/${projectId}`)
    .then(response => download(response.data, `Statement ${month}-${year}.csv`))
