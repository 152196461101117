
import type { SelectChangeEvent } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { Box, FilledInput, MenuItem, Select, Stack, Typography } from '@mui/material'

type Props = {
  setInputSelected: (item: string) => void
  services: string[]
  serviceName: string
  error: boolean
}

const DomainNameSelectField = (props: Props) => {
  const handleChange = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event

    const itemFound = findItem(value)

    if (itemFound) {
      props.setInputSelected(itemFound)
    }
  }

  const findItem = (labelFound: string) => props.services.find(element => element === labelFound)

  return (
    <Stack direction='column' spacing={1} width={1}>
      <Select
        error={props.error}
        id='demo-multiple-checkbox'
        input={<FilledInput sx={{ flexGrow: 1 }} />}
        label='Domain'
        onChange={handleChange}
        renderValue={selected => <Box sx={{ display: 'flex', flexWrap: 'wrap', height: 37, width: 1 }}>
          <Stack alignItems='center' direction='row' height={1} key={selected} spacing={1} width={1}>
            <Typography noWrap variant='body1' width={1}>{selected}</Typography>
          </Stack>
        </Box>}
        sx={{
          height: 37,
          flexGrow: 1,
          marginTop: '4px',
        }}
        value={props.serviceName}
        variant='outlined'
      >
        {props.services.map(service => <MenuItem key={service} selected={props.serviceName === service} value={service}>
          <Stack alignItems='center' direction='row' height={32} spacing={1} >
            <Typography noWrap variant='body1'>{service}</Typography>
          </Stack>
        </MenuItem>)}
      </Select>
    </Stack>
  )
}

export default DomainNameSelectField
