import 'src/styles/Custom/emoji-mart.css'

import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded'
import AcUnitRoundedIcon from '@mui/icons-material/AcUnitRounded'
import DirectionsCarFilledRoundedIcon from '@mui/icons-material/DirectionsCarFilledRounded'
import EmojiFlagsRoundedIcon from '@mui/icons-material/EmojiFlagsRounded'
import EmojiObjectsRoundedIcon from '@mui/icons-material/EmojiObjectsRounded'
import EmojiSymbolsRoundedIcon from '@mui/icons-material/EmojiSymbolsRounded'
import MoodRoundedIcon from '@mui/icons-material/MoodRounded'
import PetsRoundedIcon from '@mui/icons-material/PetsRounded'
import RestaurantRoundedIcon from '@mui/icons-material/RestaurantRounded'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import SportsBasketballRoundedIcon from '@mui/icons-material/SportsBasketballRounded'
import { useTheme } from '@mui/material'
import type { BaseEmoji, CustomIcons } from 'emoji-mart'
import { NimblePicker } from 'emoji-mart'
import data from 'emoji-mart/data/apple.json'

type Props = {
  emojiHandler: (event: BaseEmoji) => void
}

const customIcons: CustomIcons = {
  categories: {
    'search': () => <SearchRoundedIcon />,
    'recent': () => <AccessTimeRoundedIcon />,
    'people': () => <MoodRoundedIcon />,
    'nature': () => <PetsRoundedIcon />,
    'foods': () => <RestaurantRoundedIcon />,
    'activity': () => <SportsBasketballRoundedIcon />,
    'places': () => <DirectionsCarFilledRoundedIcon />,
    'objects': () => <EmojiObjectsRoundedIcon />,
    'symbols': () => <EmojiSymbolsRoundedIcon />,
    'flags': () => <EmojiFlagsRoundedIcon />,
    'custom': () => <AcUnitRoundedIcon />,
  },
}

const EmojiPicker = (props: Props) => {
  const theme = useTheme()
  return (
    <NimblePicker
      color={theme.palette.primary.main}
      data={data}
      icons={customIcons}
      native
      onSelect={props.emojiHandler}
      set='apple'
      sheetSize={20}
      showPreview={false}
      showSkinTones={false}
      style={{ border: 'none' }}
    />
  )
}

export default EmojiPicker
