import CreateNewFolderRoundedIcon from '@mui/icons-material/CreateNewFolderRounded'
import { Card, CardActionArea, Stack, Typography } from '@mui/material'
import { useState } from 'react'

import NewFolderModal from 'src/components/pages/Files/Components/NewFolderModal'
import type { Folder } from 'src/models/Folder'
import type Project from 'src/models/Project'

type Props = {
  location: string
  setFolders: React.Dispatch<React.SetStateAction<Folder[]>>
  project?: Project
}

const NewFolderItem = (props: Props) => {
  const [openModal, setOpenModal] = useState(false)
  return (
    <>
      <NewFolderModal
        close={() => setOpenModal(false)}
        open={openModal}
        parent={props.location}
        project={props.project}
        setFolders={props.setFolders}
      />
      <Card sx={{ border: 'none' }} variant='outlined'>
        <CardActionArea onClick={() => setOpenModal(true)}>
          <Stack
            alignItems='center'
            justifyContent='center'
            padding={2}
          >
            <CreateNewFolderRoundedIcon
              color='primary'
              sx={{
                height: 64,
                width: 64,
              }}
            />
            <Typography
              textAlign='center'
              variant='body2'
            >
              Create folder
            </Typography>
          </Stack>
        </CardActionArea>
      </Card>
    </>
  )
}

export default NewFolderItem
