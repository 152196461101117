/* eslint-disable react/jsx-props-no-spreading */
import type { PlateRenderElementProps, TElement } from '@udecode/plate'
import { createPluginFactory } from '@udecode/plate'

import type { MyValue } from 'src/components/pages/Notes/Modern/typescript/plateTypes'

export const ELEMENT_NUMBERED_LIST = 'numbered-list'

export const NumberedListElement = (props: PlateRenderElementProps<MyValue, TElement>) =>
  <ol
    {...props.attributes}
    {...props.nodeProps}
    className={props.className}
  >
    {props.children}
  </ol>

export const createNumberedListPlugin = createPluginFactory({
  key: ELEMENT_NUMBERED_LIST,
  isElement: true,
  component: NumberedListElement,
})
