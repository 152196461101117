/* eslint-disable react-hooks/exhaustive-deps */
import { Paper, Stack } from '@mui/material'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { addToProjectByLink } from 'src/api/projects'
import Loading from 'src/components/Loading'
import SPACING from 'src/styles/spacing'

const ProjectPrivateShare = () => {
  const navigate = useNavigate()
  const { id, hash } = useParams()

  useEffect(() => {
    if (id && hash) {
      void addToProject(id, hash)
    }
  }, [id, hash])

  const addToProject = async (idItem: string, hashItem: string) => {
    await addToProjectByLink(idItem, hashItem)
      .then(project => {
        navigate(`/project/${project.id}`)
      })
  }

  return (
    <Stack
      sx={{
        padding: SPACING.RESPONSIVE_CONTOUR,
        height: 1,
        width: 1,
      }}
    >
      <Paper
        sx={{
          height: 1,
          width: 1,
          overflow: 'hidden',
          borderRadius: {
            xl: 1,
            lg: 1,
            md: 1,
            sm: 0,
            xs: 0,
          },
        }}
      >
        <Stack height={1} width={1}>
          <Loading />
        </Stack>
      </Paper>
    </Stack>
  )
}

export default ProjectPrivateShare
