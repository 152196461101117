/* eslint-disable react-hooks/exhaustive-deps */
import AddShoppingCartRoundedIcon from '@mui/icons-material/AddShoppingCartRounded'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import CopyrightRoundedIcon from '@mui/icons-material/CopyrightRounded'
import HandymanRoundedIcon from '@mui/icons-material/HandymanRounded'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import PublicRoundedIcon from '@mui/icons-material/PublicRounded'
import PublishRoundedIcon from '@mui/icons-material/PublishRounded'
import SaveRoundedIcon from '@mui/icons-material/SaveRounded'
import StoreRoundedIcon from '@mui/icons-material/StoreRounded'
import { TabContext, TabList } from '@mui/lab'
import { AppBar, Backdrop, Button, Card, Dialog, Divider, IconButton, Slide, Stack, Tab, Toolbar, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

import { onboardingEvent } from 'src/api/organisation'
import BillingModal from 'src/components/pages/Account/Billing/BillingModal'
import { useAuth } from 'src/components/providers/AuthProvider'

type Props = {
  readonly close: () => void
  readonly open: boolean
}

const DistributeModalFree = (props: Props) => {
  const { currentOrganisation } = useAuth()
  const [billingModal, setBillingModalOpen] = useState(false)
  const notRightTier = currentOrganisation?.membershipTier === 'FREE'

  useEffect(() => {
    if (notRightTier) {
      void onboardingEvent('View premium feature')
    }
  }, [notRightTier])

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen
      onClose={props.close}
      open={props.open}
    >
      <Slide direction='up' in={props.open}>
        <Stack height={1}>
          <TabContext value='1'>
            <AppBar position='sticky' sx={{ top: 0, background: theme => theme.palette.background.default }}>
              <Toolbar sx={{ justifyContent: 'space-between' }}>
                <Typography variant='h3'>
                  Distribution
                </Typography>
                <IconButton
                  aria-label='close'
                  onClick={props.close}
                >
                  <CloseRoundedIcon />
                </IconButton>
              </Toolbar>
              <Stack paddingX={2} width={1}>
                <TabList
                  aria-label='project view'
                  sx={{
                    minHeight: '32px',
                    width: 1,
                  }}
                  variant='scrollable'
                >
                  <Tab
                    disabled
                    icon={<MusicNoteRoundedIcon />}
                    label='Recordings'
                    sx={{ minHeight: 32 }}
                  />
                  <Tab
                    disabled
                    icon={<PublicRoundedIcon />}
                    label='Territories'
                    sx={{ minHeight: 32 }}
                  />
                  <Tab
                    disabled
                    icon={<StoreRoundedIcon />}
                    label='Stores'
                    sx={{ minHeight: 32 }}
                  />
                  <Tab
                    disabled
                    icon={<HandymanRoundedIcon />}
                    label='Add-ons'
                    sx={{ minHeight: 32 }}
                  />
                  <Tab
                    disabled
                    icon={<SaveRoundedIcon />}
                    label='Pre-save'
                    sx={{ minHeight: 32 }}
                  />
                  <Tab
                    disabled
                    icon={<CopyrightRoundedIcon />}
                    label='Publishing'
                    sx={{ minHeight: 32 }}
                  />
                  <Tab
                    disabled
                    icon={<AddShoppingCartRoundedIcon />}
                    label='Services'
                    sx={{ minHeight: 32 }}
                  />
                  <Tab
                    disabled
                    icon={<CheckCircleOutlineRoundedIcon />}
                    label='Submit'
                    sx={{ minHeight: 32 }}
                  />
                </TabList>
              </Stack>
              <Divider sx={{ width: 1 }} />
            </AppBar>

            <Stack alignItems='center' height={1} width={1}>
              <Stack
                direction='column'
                height={1}
                overflow='hidden'
                paddingX={2}
                position='relative'
                spacing={2}
                width={1}
              >
                {billingModal && currentOrganisation &&
                <BillingModal
                  account={currentOrganisation}
                  close={() => setBillingModalOpen(false)}
                  open={billingModal}
                />}
                <Backdrop
                  open
                  sx={{
                    position: 'absolute',
                    zIndex: theme => theme.zIndex.drawer - 2,
                  }}
                >
                  <Stack alignItems='center' maxWidth='sm' width={1}>
                    <Card>
                      <Stack alignItems='center' padding={2} spacing={2} width={1}>
                        <PublishRoundedIcon
                          sx={{
                            color: theme => theme.palette.success.main,
                            fontSize: 64,
                          }}
                        />
                        <Stack alignItems='center' width={1}>
                          <Typography textAlign='center' variant='h6'>
                            Get your music heard
                          </Typography>
                          <Typography textAlign='center' variant='body2'>
                            Leverage the power of Distribution Cloud and reach new audiences worldwide
                          </Typography>
                        </Stack>
                        <Button
                          color='success'
                          onClick={() => setBillingModalOpen(true)}
                        >
                          Upgrade
                        </Button>
                      </Stack>
                    </Card>
                  </Stack>
                </Backdrop>
              </Stack>

            </Stack>
          </TabContext>
          <AppBar
            position='fixed'
            sx={{
              top: 'auto',
              bottom: 0,
              background: theme => theme.palette.background.default,
              zIndex: theme => theme.zIndex.drawer - 1,
            }}
          >
            <Divider sx={{ width: 1 }} />
            <Toolbar sx={{ justifyContent: 'flex-end' }}>
              <Stack direction='row' justifyContent='flex-end' spacing={1} width={1}>
                <Button
                  disabled
                  variant='subtle'
                >
                  Previous
                </Button>

                <Button
                  disabled
                >
                  Continue
                </Button>
                <Button
                  disabled
                  variant='contained'
                >
                  Distribute
                </Button>
              </Stack>
            </Toolbar>
          </AppBar>
        </Stack>
      </Slide>
    </Dialog>
  )
}

export default DistributeModalFree
