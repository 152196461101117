import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded'
import CodeRoundedIcon from '@mui/icons-material/CodeRounded'
import ConnectWithoutContactRoundedIcon from '@mui/icons-material/ConnectWithoutContactRounded'
import GroupWorkRoundedIcon from '@mui/icons-material/GroupWorkRounded'
import LinkRoundedIcon from '@mui/icons-material/LinkRounded'
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import StorageRoundedIcon from '@mui/icons-material/StorageRounded'
import { alpha, Box, Button, Card, CardActionArea, Divider, Stack, Typography,  useTheme } from '@mui/material'

type Props = {
  readonly activeBilling: number
  readonly billingCycle: number
  readonly handleBilling: (step: number) => () => void
  readonly creatorPro: number
  readonly month: number
  readonly disabled: boolean
  readonly seats: number
}

const CreatorProCard = (props: Props) => {
  const themeItem = useTheme()
  return (
    <Card
      sx={{
        borderColor: theme => props.activeBilling === props.creatorPro
          ? `${theme.palette.info.main}!important`
          : null,
      }}
      variant='outlined'
    >
      <CardActionArea disabled={!props.disabled} onClick={props.handleBilling(props.creatorPro)} sx={{ height: 1 }} >
        {!props.disabled &&
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: 1,
            bgcolor: theme => alpha(theme.palette.action.disabled, 0.25),
            height: 1,
          }}
        />}
        <Stack alignItems='flex-start' direction='column' height={1} padding={2} spacing={2}>
          <Stack>
            <Typography fontWeight='normal' variant='h2'>
              Pro
            </Typography>
            <Stack alignItems='flex-start' direction='column' spacing={1}>
              <Typography fontFamily='Domaine-Display' lineHeight={1} variant='h1'>
                <span style={{ fontFamily: 'Matter' }}>
                  $
                </span>
                {props.billingCycle === props.month
                  ? <>
                    {((props.billingCycle *
                  props.creatorPro) +
                  Math.max(0, props.billingCycle *
                  (props.seats - 2) *
                  19))}
                  </>
                  : <>
                    {(((props.billingCycle *
                    props.creatorPro) +
                    Math.max(0, props.billingCycle *
                    (props.seats - 2) *
                    19)) / 12).toFixed(2)}
                  </>}
              </Typography>
              {props.billingCycle === props.month
                ? <Typography variant='body1'>
                  / month
                </Typography>
                : <Typography variant='body1'>
                  / month, billed yearly
                </Typography>}
            </Stack>
          </Stack>
          <Typography
            color={themeItem.palette.text.secondary}
            variant='body2'
          >
            Additional tools and storage for scaling teams.
          </Typography>
          <Button
            color='info'
            disabled={!props.disabled}
            fullWidth
          >
            Select Pro
          </Button>
          <Stack direction='column'>
            <Typography lineHeight={1.4} variant='h3'>
              Features
            </Typography>
            <Typography color={themeItem.palette.text.secondary} variant='body2'>
              Everything in Essential and...
            </Typography>
          </Stack>
          <Divider sx={{ width: 1 }} />
          <Stack direction='column' spacing={3}>
            <Stack alignItems='center' direction='row' spacing={1}>
              <StorageRoundedIcon color='action' fontSize='small' />
              <Typography color='text.secondary' variant='body2'>
                5 Tb Storage
              </Typography>
            </Stack>
            <Stack alignItems='center' direction='row' spacing={1}>
              <PeopleAltRoundedIcon color='action' fontSize='small' />
              <Typography color='text.secondary' variant='body2'>
                2 People Included
              </Typography>
            </Stack>
            <Stack alignItems='center' direction='row' spacing={1}>
              <PersonRoundedIcon color='action' fontSize='small' />
              <Typography color='text.secondary' variant='body2'>
                19$ / Additional Person
              </Typography>
            </Stack>
            <Stack alignItems='center' direction='row' spacing={1}>
              <GroupWorkRoundedIcon color='action' fontSize='small' />
              <Typography color='text.secondary' variant='body2'>
                Project Management
              </Typography>
            </Stack>
            <Stack alignItems='center' direction='row' spacing={1}>
              <CodeRoundedIcon color='action' fontSize='small' />
              <Typography color='text.secondary' variant='body2'>
                Pixel Integrations
              </Typography>
            </Stack>
            <Stack alignItems='center' direction='row' spacing={1}>
              <LinkRoundedIcon color='action' fontSize='small' />
              <Typography color='text.secondary' variant='body2'>
                Custom Link Domain
              </Typography>
            </Stack>
            <Stack alignItems='center' direction='row' spacing={1}>
              <AnalyticsRoundedIcon color='action' fontSize='small' />
              <Typography color='text.secondary' variant='body2'>
                Advanced Insights
              </Typography>
            </Stack>
            <Stack alignItems='center' direction='row' spacing={1}>
              <ConnectWithoutContactRoundedIcon color='action' fontSize='small' />
              <Typography color='text.secondary' variant='body2'>
                Export Fans Data
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </CardActionArea>
    </Card>
  )
}

export default CreatorProCard
