import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'
import AlternateEmailRoundedIcon from '@mui/icons-material/AlternateEmailRounded'
import CodeRoundedIcon from '@mui/icons-material/CodeRounded'
import DomainVerificationRoundedIcon from '@mui/icons-material/DomainVerificationRounded'
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded'
import { TabContext, TabList } from '@mui/lab'
import { Divider, Stack, Tab } from '@mui/material'
import { useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'

import TikTokIcon from 'src/components/Icons/TikTokIcon'
import Pixels from 'src/components/pages/Settings/Marketing/DefaultPixels/Pixels'
import Domain from 'src/components/pages/Settings/Marketing/Domain/Domain'
import Profile from 'src/components/pages/Settings/Marketing/Profile/MarketingProfile'
import Social from 'src/components/pages/Settings/Marketing/SocialIcons/SocialIcons'
import Targeting from 'src/components/pages/Settings/Marketing/Targeting/Targeting'
import TikTokEventsApi from 'src/components/pages/Settings/Marketing/TikTok/TikTokEventsApi'
import { NavLink } from 'src/components/SafeRouterLink'

const MarketingSettings = () => {
  const location = useLocation().pathname
  const [currentTab, setCurrentTab] = useState(location)
  return (
    <TabContext value={currentTab}>
      <Stack
        direction='column'
        width={1}
      >
        <Stack
          direction='row'
          width={1}
        >
          <TabList
            aria-label='Current team tab'
            onChange={(event, value: string) => setCurrentTab(value)}
          >
            <Tab
              component={NavLink}
              icon={<AccountCircleRoundedIcon />}
              label='Profile'
              to='/settings/marketing/profile'
              value='/settings/marketing/profile'
            />
            <Tab
              component={NavLink}
              icon={<DomainVerificationRoundedIcon />}
              label='Custom Domain'
              to='/settings/marketing/domain'
              value='/settings/marketing/domain'
            />
            <Tab
              component={NavLink}
              icon={<FacebookRoundedIcon />}
              label='Meta Conversions API'
              to='/settings/marketing/facebook'
              value='/settings/marketing/facebook'
            />
            <Tab
              component={NavLink}
              icon={<TikTokIcon />}
              label='TikTok Events API'
              to='/settings/marketing/tiktok'
              value='/settings/marketing/tiktok'
            />
            <Tab
              component={NavLink}
              icon={<AlternateEmailRoundedIcon />}
              label='Social Icons'
              to='/settings/marketing/social'
              value='/settings/marketing/social'
            />
            <Tab
              component={NavLink}
              icon={<CodeRoundedIcon />}
              label='Tracking Pixels'
              to='/settings/marketing/pixels'
              value='/settings/marketing/pixels'
            />
          </TabList>
        </Stack>
        <Divider />
      </Stack>
      <Stack height={1} overflow='auto' width={1}>
        <Routes>
          <Route element={<Profile />} path='/profile' />
          <Route element={<Domain />} path='/domain' />
          <Route element={<Targeting />} path='/facebook' />
          <Route element={<TikTokEventsApi />} path='/tiktok' />
          <Route element={<Social />} path='/social' />
          <Route element={<Pixels />} path='/pixels' />
        </Routes>
      </Stack>
    </TabContext>
  )
}
export default MarketingSettings
