/* eslint-disable react-hooks/exhaustive-deps */
import { Paper, Stack } from '@mui/material'
import { useLocation } from 'react-router-dom'

import MessagesPanel from 'src/components/pages/Messages/MessagesPanel/MessagesPanel'
import { useMessenger } from 'src/components/providers/MessengerProvider'

type Props = {
  expanded: boolean
  openSmallMessages: React.Dispatch<React.SetStateAction<boolean>>
}

const SmallMessenger = (props: Props) => {
  const { currentConversation } = useMessenger()
  const location = useLocation()
  return (
    <Paper
      sx={{
        position: 'fixed',
        bottom: 0,
        right: 16,
        borderRadius: '8px 8px 0px 0px',
        zIndex: 999,
        display: currentConversation && props.expanded && location.pathname !== '/message' ? 'block' : 'none',
      }}
    >
      <Stack alignItems='center' direction='row' height={460} width={360}>
        <MessagesPanel
          handleSmallMessages={() => props.openSmallMessages(false)}
        />
      </Stack>
    </Paper>
  )
}

export default SmallMessenger
