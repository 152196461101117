import CropLandscapeRoundedIcon from '@mui/icons-material/CropLandscapeRounded'
import CropPortraitRoundedIcon from '@mui/icons-material/CropPortraitRounded'
import CropSquareRoundedIcon from '@mui/icons-material/CropSquareRounded'
import { v4 } from 'uuid'

export const formatIcons = [
  <CropPortraitRoundedIcon
    key={v4()}
    sx={{
      fontSize: 64,
    }}
  />,
  <CropLandscapeRoundedIcon
    key={v4()}
    sx={{
      fontSize: 64,
    }}
  />,
  <CropSquareRoundedIcon
    key={v4()}
    sx={{
      fontSize: 64,
    }}
  />,
]
