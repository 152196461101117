
import { Box, Chip, darken, Menu, MenuItem, Stack } from '@mui/material'
import { useState } from 'react'

import { colorSelection } from 'src/components/pages/Task/TaskMenu/TaskMenuComponents/ColorsForPicker'

type Props = {
  handleValueChange: (vaue: string) => void
  statusName: string
  colors: string[]
  options: string[]
}

const EditDropdownField = (props: Props) => {
  const [anchorElementMore, setAnchorElementMore] = useState<HTMLElement | null>(null)
  const openMoreMenu = Boolean(anchorElementMore)

  const handleMoreClose = () => {
    setAnchorElementMore(null)
  }

  return (
    <Stack
      direction='column'
      flexShrink={0}
      spacing={1}
      width={113}
    >
      <Box sx={{ display: 'flex', flexWrap: 'wrap', height: 1 }}>
        <Stack alignItems='center' direction='row' spacing={1}>
          <Chip
            color={props.statusName.length > 0
              ? 'primary'
              : 'default'}
            label={props.statusName.length > 0
              ? props.statusName
              : 'No value selected'}
            onClick={event => {
              setAnchorElementMore(event.currentTarget)
              event.stopPropagation()
            }}
            sx={{
              background: props.statusName.length > 0
                ? props.options.includes(props.statusName) &&
                    props.colors[props.options.indexOf(props.statusName)]
                  ? props.colors[props.options.indexOf(props.statusName)]
                  : colorSelection[8]
                : null,
              width: 'fit-content',
              '&:hover': {
                cursor: 'pointer',
                background: props.statusName.length > 0
                  ? props.options.includes(props.statusName) &&
                    props.colors[props.options.indexOf(props.statusName)]
                    ? darken(props.colors[props.options.indexOf(props.statusName)], 0.25)
                    : darken(colorSelection[8], 0.25)
                  : null,
              },
            }}
            variant='filled'
          />
        </Stack>
      </Box>
      <Menu
        anchorEl={anchorElementMore}
        onClick={() => handleMoreClose()}
        open={openMoreMenu}
      >
        {props.options.map(status =>
          <MenuItem
            key={status}
            onClick={event => {
              event.stopPropagation()
              setAnchorElementMore(null)
              props.handleValueChange(status)
            }}
            value={status}
          >
            <Stack alignItems='center' direction='row' spacing={1}>
              <Chip
                color='primary'
                label={status}
                sx={{
                  background: props.options.includes(status) &&
                  props.colors[props.options.indexOf(status)]
                    ? props.colors[props.options.indexOf(status)]
                    : colorSelection[8],
                  width: 'fit-content',
                }}
                variant='filled'
              />
            </Stack>
          </MenuItem>)}
      </Menu>
    </Stack>
  )
}

export default EditDropdownField
