import {  Autocomplete, Box, TextField  } from '@mui/material'

import { timezones } from 'src/data/timezonesData'

type Props = {
  setInputSelected: (item: string) => void
  timezoneName: string
}

const findItem = (valueFound: string) => timezones.find(element => element.text === valueFound)

const TimezoneSelectField = (props: Props) => {
  const handleChange = (event: string) => {
    const test = event

    const itemFound = findItem(test)

    if (itemFound) {
      props.setInputSelected(itemFound.text)
    }
  }

  return (
    <Autocomplete
      autoHighlight
      autoSelect
      fullWidth
      getOptionLabel={option => option.text}
      onChange={(_, newValue) => handleChange(newValue?.text ?? '')}
      options={timezones}
      renderInput={params =>
        <TextField
          {...params}
          label='Timezone'
          placeholder='Select a timezone'
          sx={{ marginTop: 0,  height: 42 }}
        />}
      renderOption={(renderProps, option) =>
        <Box component='li' {...renderProps}>
          {option.text}
        </Box>}
      sx={{ marginTop: 0, height: 42 }}
      value={timezones.find(
        timezoneObject => timezoneObject === findItem(props.timezoneName)
      )}
    />
  )
}

export default TimezoneSelectField
