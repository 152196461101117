import { Card, CardActionArea, Skeleton, Stack, useMediaQuery, useTheme } from '@mui/material'

const ProjectGridSkeleton = () => {
  const themeItem = useTheme()
  const mobile = useMediaQuery(themeItem.breakpoints.down('md'))
  return (
    <Card
      sx={{
        width: 'auto',
        minHeight: 200,
        maxHeight: '100%',
        margin: mobile ? 0.5 : 1,
      }}
      variant='outlined'
    >
      <CardActionArea
        sx={{
          height: 1,
          width: 1,
        }}
      >
        <Skeleton
          height='auto'
          sx={{
            borderRadius: 1,
            aspectRatio: '1/1',
            width: 1,
          }}
          variant='rectangular'
          width='100%'
        />
        <Stack alignItems='center' paddingY={2} spacing={1}>
          <Skeleton variant='text' width={180} />
          <Skeleton variant='text' width={120} />
          <Skeleton variant='text' width={100} />
          <Skeleton variant='text' width={50} />
        </Stack>
      </CardActionArea>
    </Card>
  )
}

export default ProjectGridSkeleton
