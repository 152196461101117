import type { Components } from '@mui/material'

const MuiDialog: Components['MuiDialog'] = {
  styleOverrides: {
    root: {
      '& .MuiBackdrop-root': {
        backdropFilter: 'blur(4px)',
        background: 'rgba(0, 0, 0, 0.4)',
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
      },
    },
  },
}

export default MuiDialog
