/* eslint-disable unicorn/filename-case */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable complexity */
/* eslint-disable max-lines */
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded'
import { LoadingButton } from '@mui/lab'
import { Button, Stack, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { reActivateSubscription } from 'src/api/payment'
import BillingModal from 'src/components/pages/Account/Billing/BillingModal'
import ReActivatedModal from 'src/components/pages/Settings/Billing/BillingTabs/ReActivatedModal'
import InviteMemberModal from 'src/components/pages/Settings/Team/TeamTabs/TeamTabsComponents/InviteMember'
import { useAuth } from 'src/components/providers/AuthProvider'
import { Permissions } from 'src/models/Organisation'

const UpgradeCTAButtons = () => {
  const {
    currentOrganisation,
    currentAccountPermissions,
    refreshCurrentOrganisation,
  } = useAuth()

  const [reactivateLoading, setReactivateLoading] = useState(false)
  const [reActivatedModal, setReActivatedModal] = useState(false)
  const [billingModal, setBillingModalOpen] = useState(false)
  const [inviteModal, setInviteModal] = useState(false)
  const maxSeatModalCheck = (currentOrganisation?.seats.length ?? 1) > (currentOrganisation?.seatAllocation ?? 1)

  const search = useLocation().search
  const goto = new URLSearchParams(search).get('billing')

  useEffect(() => {
    if (goto === 'true') {
      setBillingModalOpen(true)
    }
  }, [goto])

  const handleReactivateSubscription = async () => {
    setReactivateLoading(true)
    await reActivateSubscription()
      .then(() => setReactivateLoading(false))
      .then(() => refreshCurrentOrganisation())
      .then(() => setReActivatedModal(true))
  }

  return (
    <>
      {currentOrganisation?.trial && currentOrganisation.stripeCancelled &&
      <LoadingButton
        disabled={!currentAccountPermissions?.includes(Permissions.MANAGE_BILLING)}
        loading={reactivateLoading}
        onClick={handleReactivateSubscription}
        sx={{
          height: 48,
          flexShrink: 0,
          color: 'white!important',
          background: '#FFA500',
          ':hover': {
            background: '#c47f00',
          },
        }}
        variant='contained'
      >
        <Stack alignItems='center' direction='row' spacing={1}>
          <AutoAwesomeRoundedIcon fontSize='large' />
          <Stack direction='column' justifyContent='flex-start'>
            <Typography
              lineHeight={1}
              textAlign='left'
              variant='h6'
            >
              Re-activate trial
            </Typography>
            <Typography
              lineHeight={1}
              textAlign='left'
              variant='body2'
            >
              {`Trial ends in ${dayjs(currentOrganisation.trialEnd).diff(new Date(), 'days')} days`}
            </Typography>
          </Stack>
        </Stack>
      </LoadingButton>}
      {currentOrganisation?.membershipTier === 'FREE' &&
            !maxSeatModalCheck &&
            currentOrganisation.neverTrial &&
            <Button
              disabled={!currentAccountPermissions?.includes(Permissions.MANAGE_BILLING)}
              onClick={() => setBillingModalOpen(true)}
              startIcon={<AutoAwesomeRoundedIcon />}
              sx={{
                color: 'white!important',
                background: '#FFA500',
                ':hover': {
                  background: '#c47f00',
                },
              }}
              variant='contained'
            >
              Start free trial
            </Button>}
      {currentOrganisation?.membershipTier === 'FREE' &&
            !maxSeatModalCheck &&
            !currentOrganisation.neverTrial &&
            <Button
              disabled={!currentAccountPermissions?.includes(Permissions.MANAGE_BILLING)}
              onClick={() => setBillingModalOpen(true)}
              startIcon={<AutoAwesomeRoundedIcon />}
              sx={{
                color: 'white!important',
                background: '#FFA500',
                ':hover': {
                  background: '#c47f00',
                },
              }}
              variant='contained'
            >
              Upgrade
            </Button>}
      {maxSeatModalCheck &&
      <Button
        color='error'
        onClick={() => setBillingModalOpen(true)}
      >
        Upgrade required
      </Button>}
      {billingModal && currentOrganisation &&
      <BillingModal
        account={currentOrganisation}
        close={() => setBillingModalOpen(false)}
        open={billingModal}
      />}
      <InviteMemberModal
        close={() => setInviteModal(false)}
        open={inviteModal}
      />
      <ReActivatedModal
        close={() => setReActivatedModal(false)}
        open={reActivatedModal}
      />
    </>
  )
}
export default UpgradeCTAButtons
