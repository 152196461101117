import type { IconButtonProps, LinkProps } from '@mui/material'
import { Button, darken, IconButton, Link, Tooltip, useTheme } from '@mui/material'
import type { NavLinkProps } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

import { NavLink } from 'src/components/SafeRouterLink'
import type { Optional } from 'src/types/react-app-env'

type NavLinkPropsOptionalTo = Optional<NavLinkProps, 'to'>
type SideNavLinkProps = LinkProps & NavLinkPropsOptionalTo
type IconNavLinkProps =
  & IconButtonProps
  & Omit<NavLinkPropsOptionalTo, 'activeStyle' | 'onClick'>
  & {
    activeStyle?: undefined
    tooltip?: string
    expanded?: boolean
    href?: string
  }

const color = 'primary.main' as const

export const MenuNavLink = ({ isActive, ...props }: SideNavLinkProps & { isActive: boolean }) => {
  const theme = useTheme()
  const toProps = props.to
    ? {
      activeStyle: { backgroundColor: theme.palette.primary.subtleResting, color: theme.palette.primary.main },
      component: NavLink,
      isActive,
    }
    : {}

  return <Link
    color='action.active'
    sx={{
      borderWidth: theme.spacing(0.5),
      borderBottomStyle: 'solid',
      borderColor: 'transparent',
      borderRadius: theme.spacing(0.5),
      '&:hover': {
        color,
      },
    }}
    underline='none'
    {...props}
    {...toProps}
  />
}

export const MessagesNavLink = ({ isActive, ...props }: SideNavLinkProps & { isActive: boolean }) => {
  const theme = useTheme()
  const toProps = props.to
    ? {
      activeStyle: { backgroundColor: theme.palette.primary.contrast2 },
      component: NavLink,
      isActive,
    }
    : {}

  return <Link
    color='action.active'
    sx={{
      borderWidth: theme.spacing(0.5),
      borderBottomStyle: 'solid',
      borderColor: 'transparent',
      borderRadius: theme.spacing(0.5),
      '&:hover': {
        color,
      },
    }}
    underline='none'
    {...props}
    {...toProps}
  />
}

export const IconNavLink = ({ ...props }: IconNavLinkProps) => {
  const location = useLocation()
  const locationPath =
    location.pathname.toString().split('/', 2).join('/') === '/projects' ||
    location.pathname.toString().split('/', 2).join('/') === '/project'
      ? '/project'
      : location.pathname.toString().split('/', 2).join('/')
  const toPath =
    props.to?.toString().split('/', 2).join('/') === '/projects' ||
    props.to?.toString().split('/', 2).join('/') === '/project'
      ? '/project'
      : props.to?.toString().split('/', 2).join('/')

  const isActive = locationPath === toPath

  const theme = useTheme()
  const activeStyle = {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    ':hover': {
      backgroundColor: darken(theme.palette.primary.main, 0.24),
      color: theme.palette.primary.contrastText,
    },
  }

  const activeStyleLarge = {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    ':hover': {
      backgroundColor: darken(theme.palette.primary.main, 0.24),
      color: theme.palette.primary.contrastText,
    },
    '&.MuiButtonBase-root': {
      justifyContent: 'flex-start',
      paddingX: 2,
    },
  }

  const toProps = props.to
    ? {
      activeStyle,
      component: NavLink,
    }
    : {}

  return props.expanded
    ? <Button
      component={NavLink}
      disabled={props.disabled}
      fullWidth
      id={props.id}
      size='large'
      startIcon={props.children}
      sx={
        isActive
          ? activeStyleLarge
          : {
            '&:hover': {
              color,
            },
            '&.MuiButtonBase-root': {
              justifyContent: 'flex-start',
              paddingX: 2,
            },
            color: theme.palette.action.active,
          }
      }
      to={props.to ?? ''}
      variant='text'
    >
      {props.tooltip}
    </Button>
    : <Tooltip placement='right' title={props.tooltip ?? ''}>
      <div>
        <IconButton
          id={props.id}
          size='medium'
          style={{
            borderRadius: '16px',
            textTransform: 'none',
          }}
          sx={isActive
            ? activeStyle
            : {
              '&:hover': {
                color,
              },
            }}
          {...props}
          {...toProps}
        >
          {props.children}
        </IconButton>
      </div>
    </Tooltip>
}

export const IconNavLinkTop = ({ isActive, ...props }: IconNavLinkProps & { isActive: boolean }) => {
  const theme = useTheme()
  const activeStyle = {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    ':hover': {
      color: theme.palette.primary.main,
    },
  }

  const toProps = props.to
    ? {
      activeStyle,
      component: NavLink,
    }
    : {}

  return <Tooltip
    PopperProps={{
      container: document.querySelector('#root') ?? undefined,
    }}
    placement='bottom'
    title={props.tooltip ?? ''}
  >
    <div>
      <IconButton
        size='small'
        style={{
          borderRadius: '16px',
          textTransform: 'none',
        }}
        sx={isActive
          ? activeStyle
          : {
            '&:hover': {
              color,
            },
          }}
        {...props}
        {...toProps}
      >
        {props.children}
      </IconButton>
    </div>
  </Tooltip>
}
