/* eslint-disable unicorn/filename-case */
import { Icon } from '@mui/material'

import melonImage from 'src/components/Icons/seated.png'

const SeatedLogo = () =>
  <Icon sx={{ height: 26, width: 26, fontSize: '1rem' }}>
    <img alt='Seated Logo' height='26px' src={melonImage} width='100%' />
  </Icon>

export default SeatedLogo
