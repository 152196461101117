/* eslint-disable max-lines */
import { Card, Divider, Typography } from '@mui/material'
import { Stack } from '@mui/system'

import AllReleases from 'src/components/pages/Links/EditLink/EditLinkPreview/Components/RenderAllReleases'
import SmartLinkRightTabBioLink from 'src/components/pages/Links/EditLink/EditLinkPreview/SmartLinkRightTabBioLink'
import SmartLinkRightTabSmartLink from 'src/components/pages/Links/EditLink/EditLinkPreview/SmartLinkRightTabSmartLink'
import type { MarketingLink } from 'src/models/Marketing'
import type { BasicOrganisation } from 'src/models/Organisation'

type Props = {
  smartLinkData: MarketingLink
  artworkPreview: string | null | undefined
  organisation: BasicOrganisation | null
}

const EditLinkRightTab = (props: Props) =>
  <>
    <Card
      elevation={0}
      sx={{
        backgroundColor: theme => theme.palette.background.default,
        height: 64,
        width: 1,
        zIndex: 2,
        borderRadius: 0,
        flexShrink: 0,
      }}
    >
      <Stack paddingTop={0.5} paddingX={4}>
        <Typography>
          11:55
        </Typography>
      </Stack>
      <Stack alignItems='center' paddingTop={1} width={1}>
        <Typography>
          {props.smartLinkData.type === 'Biolink'
            ? 'mylinks.ee'
            : props.smartLinkData.domain}
        </Typography>
      </Stack>
    </Card>
    <Divider sx={{ width: 1 }} />

    {props.smartLinkData.type === 'Biolink'
      ? <SmartLinkRightTabBioLink
        artworkPreview={props.artworkPreview}
        organisation={props.organisation}
        smartLinkData={props.smartLinkData}
      />
      : <SmartLinkRightTabSmartLink
        artworkPreview={props.artworkPreview}
        organisation={props.organisation}
        smartLinkData={props.smartLinkData}
      />}

    {props.smartLinkData.showAllReleases &&
    <AllReleases
      link={props.smartLinkData}
      organisation={props.organisation}
    />}
  </>
export default EditLinkRightTab
