import CloseIcon from '@mui/icons-material/Close'
import {  Button, Dialog, IconButton, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import type { ChangeEvent } from 'react'
import { useState } from 'react'
import type { ColorResult } from 'react-color'
import { CirclePicker } from 'react-color'
import { v4 } from 'uuid'

import { savePartialTaskboard } from 'src/api/taskboards'
import { sendTasksboardUpdate } from 'src/api/webSocket'
import { colorSelection } from 'src/components/pages/Task/TaskMenu/TaskMenuComponents/ColorsForPicker'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { TaskBoard, TaskGroup, TasksboardEditField } from 'src/models/Taskboard'
import { TaskBoardEdit } from 'src/models/Taskboard'

type Props = {
  close: () => void
  open: boolean
  taskboard: TaskBoard
  taskSection: TaskGroup
}

const EditTaskSectionModal = (props: Props) => {
  const { currentAccount } = useAuth()
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  const [sectionName, setSectionName] = useState(props.taskSection.title)
  const [sectionColor, setSectionColor] = useState(props.taskSection.color)

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSectionName(event.target.value)
  }

  const handleColorChange = (color: ColorResult) => {
    setSectionColor(color.hex)
  }

  const handleClick = async () => {
    if (props.taskboard.id) {
      const itemClone = Object.assign({}, props.taskSection)

      const oldTitle = itemClone.title
      const oldColor = itemClone.color

      const changeTitle = oldTitle !== sectionName
      const changeColor = oldColor !== sectionColor

      const updatedFields: TasksboardEditField[] = []

      if (changeTitle) {
        updatedFields.push({
          fieldName: 'Title',
          oldValue: oldTitle,
          newValue: sectionName,
        })
      }

      if (changeColor) {
        updatedFields.push({
          fieldName: 'Color',
          oldValue: oldColor,
          newValue: sectionColor,
        })
      }

      props.taskSection.color = sectionColor
      props.taskSection.title = sectionName

      props.taskboard.taskGroups[props.taskboard.taskGroups.findIndex(group => group.id === props.taskSection.id)]
        .color = sectionColor
      props.taskboard.taskGroups[props.taskboard.taskGroups.findIndex(group => group.id === props.taskSection.id)]
        .title = sectionName

      await savePartialTaskboard({ id: props.taskboard.id, ...props.taskboard })
        .then(() => {
          const taskUpdateItem = new TaskBoardEdit({
            id: v4(),
            taskboardId: props.taskboard.id,
            taskId: '',
            accountId: currentAccount.id,
            modificationType: 'Column details',
            itemType: 'Field',
            title: `${itemClone.title}`,
            fields: updatedFields,
          })
          sendTasksboardUpdate(taskUpdateItem)
        })
        .then(() => {
          props.close()
          setSectionName('')
          setSectionColor('')
        })
    }
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      maxWidth='xs'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Stack paddingX={3} paddingY={3} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          width='100%'
        >
          <Typography variant='h3'>Edit Section</Typography>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>
        <Stack alignItems='center' width='100%'>
          <Stack marginBottom={4} marginTop={3} spacing={1} width={338}>
            <CirclePicker
              color={sectionColor}
              colors={colorSelection}
              onChangeComplete={
                handleColorChange
              }
              width='100%'
            />
          </Stack>
          <TextField
            fullWidth
            inputProps={{ maxLength: 50 }}
            label='Section name'
            onChange={handleNameChange}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                void handleClick()
              }
            }}
            placeholder='Name this section'
            value={sectionName}
          />
          <Stack direction='row' justifyContent='flex-end' paddingTop={2} spacing={1} width={1}>
            <Button onClick={props.close} variant='text' >
              Cancel
            </Button>
            <Button onClick={() => handleClick()} >
              Save
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default EditTaskSectionModal

