/* eslint-disable max-lines */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable complexity */
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone'
import LoopRoundedIcon from '@mui/icons-material/LoopRounded'
import { Chip, IconButton, Stack, TableCell, TableRow, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { v4 } from 'uuid'

import { updateTaskItem } from 'src/api/taskboards'
import { sendTasksboardUpdate } from 'src/api/webSocket'
import TaskboardModalItem from 'src/components/pages/Task/TaskDrawer/TaskboardListItemModal'
import { recurringDates } from 'src/components/pages/Task/TaskViews/TaskViewComponents/TaskBoardItem'
import { useAuth } from 'src/components/providers/AuthProvider'
import { NavLink } from 'src/components/SafeRouterLink'
import type { TaskBoard, TaskGroup, TaskItem } from 'src/models/Taskboard'
import { TaskBoardEdit } from 'src/models/Taskboard'

type Props = {
  taskDataSet: TaskItem
  taskboard: TaskBoard
  taskColumn: TaskGroup
  dueDate?: Date | null | undefined
}

const TaskItemDashboard = (props: Props) => {
  const themeColors = useTheme()
  const { currentAccount } = useAuth()
  const matches = useMediaQuery(themeColors.breakpoints.down('md'))
  const [taskData, setTaskData] = useState(props.taskDataSet)
  const dates = recurringDates(taskData.recurring, new Date(props.taskDataSet.dueDate ?? 0))
  const [lastDone, setLastDone] = useState(props.taskDataSet.lastDoneOn)
  const [nextDate, setNextDate] = useState(dates.find(item => item.getTime() > Date.now()))
  const [previousDate, setPreviousDate] = useState([...dates].reverse().find(item => item.getTime() < Date.now()))

  const [status, setTaskStatus] = useState(props.taskDataSet.recurring !== 'None'
    ? lastDone &&
    previousDate &&
    nextDate &&
    new Date(lastDone).getTime() > previousDate.getTime() &&
    new Date(lastDone).getTime() < nextDate.getTime()
      ? 'Done'
      : 'Not started'
    : props.taskDataSet.status)

  useEffect(() => {
    const temporaryDates = recurringDates(props.taskDataSet.recurring, new Date(props.taskDataSet.dueDate ?? 0))
    setNextDate(temporaryDates.find(item => item.getTime() > Date.now()))
    setPreviousDate([...temporaryDates].reverse().find(item => item.getTime() < Date.now()))
  }, [props.taskDataSet.dueDate, props.taskDataSet.recurring])

  useEffect(() => {
    if (props.taskDataSet.recurring === 'Weekly' || props.taskDataSet.recurring === 'Monthly') {
      if (previousDate && lastDone && new Date(lastDone).getTime() < new Date(previousDate).getTime()) {
        setTaskStatus('Not started')
      }
      if (!lastDone) {
        setTaskStatus('Not started')
      }
      if (lastDone && !previousDate) {
        setTaskStatus('Done')
      }
    }
  }, [previousDate, nextDate, lastDone, props.taskDataSet.lastDoneOn, props.taskDataSet.recurring])

  const handleChange = async (event: React.MouseEvent<HTMLElement>,
    newStatus: 'Done' | 'In progress' | 'Not approved' | 'Not started') => {
    event.preventDefault()
    event.stopPropagation()
    setTaskStatus(newStatus)
    setLastDone(new Date())
    const temporaryData = taskData
    temporaryData.status = newStatus
    temporaryData.lastDoneOn = new Date()
    temporaryData.lastUpdated = new Date()
    setTaskData(taskData)

    if (props.taskboard.id) {
      props.taskboard.taskGroups[props.taskboard.taskGroups.findIndex(group => group.id === props.taskColumn.id)]
        .tasks[props.taskboard.taskGroups[props.taskboard.taskGroups
          .findIndex(group => group.id === props.taskColumn.id)]
          .tasks.findIndex(task => task.id === taskData.id)] = taskData
      await updateTaskItem(props.taskboard.id, props.taskColumn.id ?? '', taskData)
        .then(() => {
          const taskUpdateItem = new TaskBoardEdit({
            id: v4(),
            taskboardId: props.taskboard.id,
            taskId: props.taskDataSet.id,
            accountId: currentAccount.id,
            modificationType: 'Task details',
            itemType: 'Field',
            title: `${props.taskDataSet.title}`,
          })
          sendTasksboardUpdate(taskUpdateItem)
        })
    }
  }

  useEffect(() => {
    if (props.taskDataSet.status !== status &&
      props.taskDataSet.recurring !== 'Weekly' &&
      props.taskDataSet.recurring !== 'Monthly') {
      setTaskStatus(props.taskDataSet.status)
    }
  }, [props.taskDataSet.status, props.taskDataSet.recurring, status])

  return (
    <TableRow
      component={NavLink}
      hover
      sx={{
        borderBottom: theme => `1px solid ${theme.palette.divider}`,
        backgroundColor: 'transparent',
        textDecoration: 'none',
        '&:hover .MuiTableCell-root': {
          backgroundColor: theme => theme.palette.background.input,
          textDecoration: 'none',
        },
        width: 1,
        overflow: 'hidden',
        tableLayout: 'fixed',
      }}
      to={props.taskboard.projectId
        ? `/project/${props.taskboard.projectId}?goto=task&taskId=${props.taskDataSet.id}`
        : `/calendar/${props.taskboard.id}?taskId=${props.taskDataSet.id}`}
    >
      <TableCell
        id='button'
        sx={{
          left: 0,
          padding: 0,
          paddingLeft: 1,
        }}
        width={48}
      >
        {(taskData.priority === 'None' && status !== 'Done') &&
        <IconButton
          aria-label='Complete'
          onClick={event => handleChange(event, 'Done')}
          sx={{ color: theme => theme.palette.text.label }}
        >
          <CircleTwoToneIcon fontSize='large' sx={{ color: theme => theme.palette.text.label }} />
        </IconButton>}
        {(taskData.priority === 'Low' && status !== 'Done') &&
        <IconButton
          aria-label='Complete'
          color='primary'
          onClick={event => handleChange(event, 'Done')}
        >
          <CircleTwoToneIcon color='primary' fontSize='large' />
        </IconButton>}
        {(taskData.priority === 'Medium' && status !== 'Done') &&
        <IconButton
          aria-label='Complete'
          onClick={event => handleChange(event, 'Done')}
          sx={{ color: theme => theme.palette.rating }}
        >
          <CircleTwoToneIcon fontSize='large' sx={{ color: theme => theme.palette.rating }} />
        </IconButton>}
        {(taskData.priority === 'High' && status !== 'Done') &&
        <IconButton
          aria-label='Complete'
          color='error'
          onClick={event => handleChange(event, 'Done')}
        >
          <CircleTwoToneIcon color='error' fontSize='large' />
        </IconButton>}
        {status === 'Done' &&
                props.taskDataSet.recurring !== 'Monthly' &&
                props.taskDataSet.recurring !== 'Weekly' &&
                <IconButton
                  aria-label='Complete'
                  color='success'
                  onClick={event => handleChange(event, 'Not started')}
                >
                  <CheckCircleRoundedIcon color='success' fontSize='large' />
                </IconButton>}
        {status === 'Done' &&
                (props.taskDataSet.recurring === 'Monthly' ||
                props.taskDataSet.recurring === 'Weekly') &&
                <Tooltip title='This recurring task is completed and locked'>
                  <div>
                    <IconButton
                      aria-label='Complete'
                      color='success'
                      disabled
                    >
                      <CheckCircleRoundedIcon
                        color='success'
                        fontSize='large'
                        sx={{
                          opacity: 0.5,
                        }}
                      />
                    </IconButton>
                  </div>
                </Tooltip>}

      </TableCell>
      <TableCell
        id='task name'
        sx={{
          left: '48px',
          backgroundColor: 'transparent',
          width: 1,
          wordBreak: 'break-word',
        }}
      >
        {taskData.title}
      </TableCell>

      {!matches &&
      <TableCell id='taskboard' sx={{ minWidth: 64 }}>
        <Stack alignItems='center' direction='row' spacing={1} width={150}>
          <TaskboardModalItem
            taskboard={props.taskboard}
          />
          <Typography sx={{ wordBreak: 'break-word' }} variant='tableCell'>
            {props.taskboard.title}
          </Typography>
        </Stack>
      </TableCell>}

      {!matches &&
      <TableCell id='due date' sx={{ minWidth: 128 }}>
        {props.taskDataSet.dueDate &&
        <Stack alignItems='center' direction='row' spacing={1} width={1}>
          {props.taskDataSet.recurring === 'Weekly' || props.taskDataSet.recurring === 'Monthly'
            ? <Stack alignItems='center' direction='row' spacing={1}>
              <CalendarTodayRoundedIcon
                sx={{
                  color: props.taskDataSet.dueDate && nextDate && new Date(nextDate) > new Date()
                    ? 'text.label'
                    : theme => theme.palette.error.main,
                }}
              />
              <Typography
                color={props.taskDataSet.dueDate && nextDate && new Date(nextDate) > new Date()
                  ? 'text.label'
                  : themeColors.palette.error.main}
                variant='tableCell'
              >
                {nextDate &&
                  dayjs(nextDate).format('MMM DD')}
              </Typography>
              <LoopRoundedIcon
                sx={{
                  color: props.taskDataSet.dueDate && nextDate && new Date(nextDate) > new Date()
                    ? 'text.label'
                    : theme => theme.palette.error.main,
                }}
              />
            </Stack>
            : <Stack alignItems='center' direction='row' spacing={1}>
              <CalendarTodayRoundedIcon
                sx={{
                  color: props.taskDataSet.dueDate && new Date(props.taskDataSet.dueDate) > new Date()
                    ? 'text.label'
                    : theme => theme.palette.error.main,
                }}
              />
              <Typography
                color={props.taskDataSet.dueDate && new Date(props.taskDataSet.dueDate) > new Date()
                  ? 'text.label'
                  : themeColors.palette.error.main}
                variant='tableCell'
              >
                {dayjs(props.taskDataSet.dueDate).format('MMM DD')}
              </Typography>
            </Stack>}
        </Stack>}
      </TableCell>}

      <TableCell id='status' sx={{ minWidth: 32 }}>
        <Stack width={100}>
          {status === 'Done' &&
          <Chip color='success' label={status} variant='filled' />}
          {status === 'In progress' &&
          <Chip color='warning' label={status} variant='filled' />}
          {status === 'Not started' &&
          <Chip color='primary' label={status} variant='filled' />}
          {status === 'Not approved' &&
          <Chip color='error' label={status} variant='filled' />}
        </Stack>
      </TableCell>
    </TableRow>
  )
}

export default TaskItemDashboard
