/* eslint-disable unicorn/filename-case */
/* eslint-disable id-length */
import type { PlateEditor, Value } from '@udecode/plate'
import { findEventRange, select } from '@udecode/plate'

import { uploadFiles } from 'src/components/pages/Notes/Modern/image/uploadFiles'

export const onDropCloud = <V extends Value = Value>(
  editor: PlateEditor<V>,
  event: React.DragEvent
): boolean => {
  const { files } = event.dataTransfer
  if (files.length === 0) return false
  /**
   * Without this, the dropped file replaces the page
   */
  event.preventDefault()
  event.stopPropagation()
  /**
   * When we drop a file, the selection won't move automatically to the drop
   * location. Find the location from the event and upload the files at that
   * location.
   */
  const at = findEventRange(editor, event)
  if (!at) return false

  select(editor, at)
  uploadFiles(editor, files)

  return true
}

export const onPasteCloud = <V extends Value = Value>(
  editor: PlateEditor<V>,
  event: React.ClipboardEvent
): boolean => {
  const { files } = event.clipboardData
  if (files.length === 0) return false

  event.preventDefault()
  event.stopPropagation()
  uploadFiles<V>(editor, files)

  return true
}
