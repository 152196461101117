/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-ignored-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines */
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded'
import CloseIcon from '@mui/icons-material/Close'
import { Autocomplete, Box, Button, Checkbox, Dialog, FormControlLabel, IconButton, InputAdornment, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import type { ChangeEvent, SyntheticEvent } from 'react'
import { useEffect, useState } from 'react'
import { v4 } from 'uuid'

import { getOrganisation } from 'src/api/organisation'
import { getAllTaskboards, newTaskNotification, savePartialTaskboard } from 'src/api/taskboards'
import { sendTasksboardUpdate } from 'src/api/webSocket'
import PrioritySelect from 'src/components/form-elements/PrioritySelectField'
import StatusSelect from 'src/components/form-elements/StatusSelectField'
import TagsInputField from 'src/components/form-elements/TagsInputField'
import SelectAssignee from 'src/components/pages/Task/TaskDrawer/PersonSelect'
import TaskboardModalItem from 'src/components/pages/Task/TaskDrawer/TaskboardListItemModal'
import { useAuth } from 'src/components/providers/AuthProvider'
import type Account from 'src/models/Account'
import type { TaskBoard, TaskGroup } from 'src/models/Taskboard'
import { TaskBoardEdit, TaskItem } from 'src/models/Taskboard'

type Props = {
  close: () => void
  open: boolean
  taskboard?: TaskBoard
  taskgroup?: TaskGroup
  date?: Date
}

const timeSlots = Array.from({ length: 24 * 2 }).map(
  (_, index) =>
    `${index < 20 ? '0' : ''}${Math.floor(index / 2)}:${
      index % 2 === 0 ? '00' : '30'
    }`,
)

const NewTaskModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))
  const { currentOrganisation, currentAccount } = useAuth()

  const [taskboards, setTaskboards] = useState<TaskBoard[]>()
  const [members, setMembers] = useState<Account[]>([])

  const [taskItem, setTaskItem] =
    useState<TaskItem>(new TaskItem({ title: '', id: v4(), assignee: [] }))

  const [valueStart, setValueStart] = useState<Date | null>(props.date ?? null)
  const [valueEnd, setValueEnd] = useState<Date | null>(props.date ?? null)
  const [openTime, setOpenTime]  = useState(!!props.date)
  const [assignee, setAsignee] = useState<Account[]>([])
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [status, setStatus] = useState('Not started')
  const [priority, setPriority] = useState('None')
  const [taskboard, setTaskboard] = useState<TaskBoard | undefined>(props.taskboard)
  const [taskgroup, setTaskGroup] = useState<TaskGroup | undefined>(props.taskgroup)
  const [tags, setTags] = useState<string[]>([])
  const [valueStartTime, setValueStartTime] = useState('')
  const [valueDueTime, setValueDueTime] = useState('')

  useEffect(() => {
    void getAllTaskboards().then(setTaskboards)
  }, [])

  useEffect(() => {
    setTaskItem(old => ({
      ...old,
      startDate: valueStart ? dayjs(new Date(valueStart)).toDate() : undefined,
      dueDate: valueEnd ? dayjs(new Date(valueEnd)).toDate() : undefined,
    }))
  }, [valueStart, valueEnd])

  useEffect(() => {
    setMembers([])
    if (taskboard) {
      void Promise.all(taskboard.members.map(member =>
        void getOrganisation(member)
          .then(account => account?.seats.map(seat =>
            setMembers(previousState => [...previousState, seat.account])))))
    } else if (currentOrganisation) {
      setMembers(currentOrganisation.seats.map(seat => seat.account))
    }
  }, [taskboard])

  const handleChangeStart = (newValue: Date | null) => {
    if (!openTime) {
      setValueEnd(newValue)
      taskItem.dueDate = newValue ? dayjs(new Date(newValue)).toDate() : undefined
    }
    setValueStart(newValue)
    taskItem.startDate = newValue ? dayjs(new Date(newValue)).toDate() : undefined
    setTaskItem(taskItem)
  }

  const handleChangeEnd = (newValue: Date | null) => {
    setValueEnd(newValue)
    taskItem.dueDate = newValue ? dayjs(new Date(newValue)).toDate() : undefined
    setTaskItem(taskItem)
  }

  const handleTimeCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (valueStart) {
      setValueEnd(valueStart)
      taskItem.dueDate = new Date(valueStart)
    }
    setOpenTime(event.target.checked)
    taskItem.allDay = event.target.checked
    setTaskItem(taskItem)
  }

  const handleTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value)
    taskItem.title = event.target.value
    setTaskItem(taskItem)
  }

  const handleStartTimeChange = (event: SyntheticEvent, value: string) => {
    setValueStartTime(value)
    taskItem.startTime = value
    setTaskItem(taskItem)
  }

  const handleDueTimeChange = (event: SyntheticEvent, value: string) => {
    setValueDueTime(value)
    taskItem.dueTime = value
    setTaskItem(taskItem)
  }

  const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value)
    taskItem.description = event.target.value
    setTaskItem(taskItem)
  }

  const handleStatusChange = (value: 'Done' | 'In progress' | 'Not approved' | 'Not started') => {
    setStatus(value)
    taskItem.status = value
    setTaskItem(taskItem)
  }

  const findTaskboard = (valueFound: string) => taskboards?.find(element => element.id === valueFound)

  const handleTaskboardChange = (event: string) => {
    const test = event

    const itemFound = findTaskboard(test)

    if (itemFound) {
      setTaskboard(itemFound)
      setAsignee([])
      setTaskGroup(undefined)
      taskItem.assignee = []
    }
  }

  const handleAssigneeChange = (event: Account[]) => {
    const stringArray = event.map(item => item.id)

    setAsignee(event)
    taskItem.assignee = stringArray
    setTaskItem(taskItem)
  }

  const handlePriorityChange = (value: 'High' | 'Low' | 'Medium' | 'None') => {
    setPriority(value)
    taskItem.priority = value
    setTaskItem(taskItem)
  }

  const handleTagsChange = (values: string[]) => {
    setTags(values)
    taskItem.tags = values
    setTaskItem(taskItem)
  }

  const handleClick = async (data: TaskItem) => {
    if (taskboard && taskgroup && taskboard.id) {
      taskboard.taskGroups[taskboard.taskGroups.findIndex(group => group.id === taskgroup.id)]
        .tasks
        .push(data)
      data.assignee.map(async assigneeItem => {
        if (assigneeItem !== currentAccount.id) {
          await newTaskNotification(assigneeItem, {
            description: data.description,
            taskboard: taskboard.title,
            due_date: data.dueDate ? dayjs(data.dueDate).format('MMMM D') : 'No due date',
            task_name: data.title,
            taskboard_id: taskboard.id ?? '',
            task_id: data.id,
          })
        }
      })
      await savePartialTaskboard({ id: taskboard.id, ...taskboard })
      props.close()
    } else if (taskboard?.id && taskboard.taskGroups.length > 0) {
      taskboard.taskGroups[0]
        .tasks
        .push(data)
      data.assignee.map(async assigneeItem => {
        if (assigneeItem !== currentAccount.id) {
          await newTaskNotification(assigneeItem, {
            description: data.description,
            taskboard: taskboard.title,
            due_date: data.dueDate ? dayjs(data.dueDate).format('MMMM D') : 'No due date',
            task_name: data.title,
            taskboard_id: taskboard.id ?? '',
            task_id: data.id,
          })
        }
      })
      await savePartialTaskboard({ id: taskboard.id, ...taskboard })
      props.close()
    } else if (taskboard?.id) {
      const emptyTaskGroup: TaskGroup = {
        id: v4(),
        title: 'Untitled section',
        color: '#828DF8',
        tasks: [data],
      }
      data.assignee.map(async assigneeItem => {
        if (assigneeItem !== currentAccount.id) {
          await newTaskNotification(assigneeItem, {
            description: data.description,
            taskboard: taskboard.title,
            due_date: data.dueDate ? dayjs(data.dueDate).format('MMMM D') : 'No due date',
            task_name: data.title,
            taskboard_id: taskboard.id ?? '',
            task_id: data.id,
          })
        }
      })
      taskboard.taskGroups.push(emptyTaskGroup)
      await savePartialTaskboard({ id: taskboard.id, ...taskboard })
      props.close()
    }
    const taskUpdateItem = new TaskBoardEdit({
      id: v4(),
      taskboardId: taskboard?.id ?? '',
      taskId: '',
      accountId: currentAccount.id,
      modificationType: 'Task created',
      itemType: 'Field',
      title: `${title}`,
    })
    sendTasksboardUpdate(taskUpdateItem)
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <form
        onSubmit={event => {
          event.preventDefault()
          void handleClick(taskItem)
        }}
      >
        <Stack alignItems='center' height={1} width={1}>
          <Stack
            alignItems='center'
            justifyContent='flex-start'
            paddingTop={3}
            paddingX={2}
            spacing={5}
            width={1}
          >
            <Stack direction='row' justifyContent='space-between' spacing={6} width={1} >
              <TextField
                autoComplete='off'
                fullWidth
                id='task-title'
                inputProps={{ maxLength: 50, style: { fontSize: 22, fontWeight: 500 }, autoComplete: 'off' }}
                onChange={handleTitleChange}
                placeholder='Name this task'
                value={title}
                variant='standard'
              />
              <Stack direction='row' spacing={1} >
                <IconButton onClick={props.close} >
                  <CloseIcon />
                </IconButton>
              </Stack>
            </Stack>

            <Stack direction='row' marginTop='24px!important' spacing={2} width={1} >
              <Stack direction='column'>
                <DatePicker
                  format='MMMM DD, YYYY'
                  onChange={value => handleChangeStart(dayjs(value).toDate())}
                  slotProps={{ textField: { variant: 'outlined' } }}
                  sx={{
                    marginTop: 0,
                  }}
                  value={valueStart ? dayjs(valueStart) : null}
                  views={['year', 'month', 'day']}
                />
              </Stack>
              <Stack direction='column' flexShrink={0} justifyContent='center'>
                <Typography sx={{ marginTop: 0 }} variant='inputLabel'> to </Typography>
              </Stack>
              <Stack direction='row'>
                <DatePicker
                  disabled={!openTime}
                  format='MMMM DD, YYYY'
                  onChange={value => handleChangeEnd(dayjs(value).toDate())}
                  slotProps={{ textField: { variant: 'outlined' } }}
                  sx={{
                    marginTop: 0,
                  }}
                  value={valueEnd ? dayjs(valueEnd) : null}
                  views={['year', 'month', 'day']}
                />
              </Stack>
              <Stack direction='row' flexShrink={0} justifyContent='center'>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={openTime}
                      onChange={handleTimeCheck}
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                    />
                  }
                  label='All day'
                />
              </Stack>
            </Stack>

            <Stack alignItems='center' direction='row' marginTop='16px!important' spacing={2} width={1}>
              <Stack direction='column' height={1} justifyContent='center'>
                <AccessTimeRoundedIcon color='action' fontSize='large' />
              </Stack>
              <Stack direction='row' justifyContent='flex-end' spacing={1} width={1}>
                <Autocomplete
                  defaultValue={valueStartTime}
                  disableClearable
                  id='start-time'
                  onChange={handleStartTimeChange}
                  options={timeSlots}
                  renderInput={params =>
                    <TextField
                      {...params}
                      sx={{ height: 42, marginTop: 0, width: 1 }}
                      variant='standard'
                    />}
                  style={{ height: 42 }}
                  sx={{ marginTop: 0, width: 1, '.MuiInputBase-input': { cursor: 'pointer' }   }}
                />
              </Stack>
              <Stack direction='column' flexShrink={0} height={1} justifyContent='center'>
                <Typography sx={{ marginTop: 0 }} variant='inputLabel'> to </Typography>
              </Stack>
              <Stack alignItems='flex-end' direction='row' justifyContent='flex-end' spacing={1} width={1}>
                <Autocomplete
                  defaultValue={valueDueTime}
                  disableClearable
                  id='due-time'
                  onChange={handleDueTimeChange}
                  options={timeSlots}
                  renderInput={params =>
                    <TextField
                      {...params}
                      sx={{ height: 42, marginTop: 0 }}
                      variant='standard'
                    />}
                  style={{ height: 42 }}
                  sx={{ marginTop: 0, width: 1, '.MuiInputBase-input': { cursor: 'pointer' }  }}
                />
              </Stack>
            </Stack>
            <Stack marginTop='16px!important' spacing={2} width={1}>
              <Stack alignItems='center' direction='row' spacing={1} width={1}>
                <Typography
                  sx={{
                    marginTop: 0,
                    width: 80,
                  }}
                  variant='inputLabel'
                >
                  Taskboard
                </Typography>
                {taskboards &&
                <Autocomplete
                  autoHighlight
                  autoSelect
                  fullWidth
                  getOptionLabel={option => option.title}
                  onChange={(_, newValue) => handleTaskboardChange(newValue?.id ?? '')}
                  options={taskboards}
                  renderInput={params =>
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: <InputAdornment position='start'>
                          <TaskboardModalItem
                            taskboard={taskboard}
                          />
                        </InputAdornment>,
                      }}
                      placeholder='Select a board'
                      sx={{
                        marginTop: 0,
                        background: 'transparent!important',
                        border: 'none',
                        '.MuiInputBase-root': {
                          border: 'none',
                          background: 'transparent!important',
                          borderColor: 'transparent!important',
                          paddingY: 0,
                          marginTop: 0,
                        },
                      }}
                    />}
                  renderOption={(renderProps, option) =>
                    <Box component='li' {...renderProps} >
                      <Stack alignItems='center' direction='row' spacing={1}>
                        <TaskboardModalItem
                          taskboard={option}
                        />
                        <Typography
                          sx={{ color: theme => theme.palette.text.secondary }}
                          variant='body2'
                        >
                          {option.title}
                        </Typography>
                      </Stack>
                    </Box>}
                  sx={{ marginTop: 0, height: 42 }}
                  value={taskboard}
                />}
              </Stack>

              <Stack alignItems='center' direction='row' spacing={1} width={1}>
                <SelectAssignee
                  assignee={assignee}
                  handleAssigneeChange={handleAssigneeChange}
                  members={members}
                />
              </Stack>
              <Stack alignItems='center' direction='row' spacing={1} width={1}>
                <Typography
                  sx={{
                    marginTop: 0,
                    width: 80,
                  }}
                  variant='inputLabel'
                >
                  Status
                </Typography>
                <StatusSelect
                  setInputSelected={handleStatusChange}
                  statusName={status as 'Done' | 'In progress' | 'Not approved' | 'Not started'}
                />
              </Stack>
              <Stack alignItems='center' direction='row' spacing={1} width={1}>
                <Typography
                  sx={{
                    marginTop: 0,
                    width: 80,
                  }}
                  variant='inputLabel'
                >
                  Priority
                </Typography>
                <PrioritySelect
                  priorityName={priority as 'High' | 'Low' | 'Medium' | 'None'}
                  setInputSelected={handlePriorityChange}
                />
              </Stack>

              <Stack alignItems='center' direction='row' spacing={1} width={1}>
                <Typography
                  sx={{
                    marginTop: 0,
                    width: 80,
                  }}
                  variant='inputLabel'
                >
                  Tags
                </Typography>
                <TagsInputField
                  setInputSelected={handleTagsChange}
                  tags={tags}
                />
              </Stack>
            </Stack>

            <TextField
              autoComplete='off'
              fullWidth
              id='description'
              label='Description'
              multiline
              onChange={handleDescriptionChange}
              placeholder='Aa'
              rows={4}
              sx={{
                marginTop: 0,
                '.MuiInputBase-root': {
                  borderColor: 'transparent!important',
                  paddingY: 0,
                  marginTop: 0,
                },
              }}
              value={description}
              variant='standard'
            />
          </Stack>
          <Stack direction='row' justifyContent='flex-end' paddingX={2} paddingY={2} spacing={1} width={1}>
            <Button onClick={props.close} variant='text' >
              Cancel
            </Button>
            <Button
              disabled={!taskboard || !title}
              type='submit'
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </form>
    </Dialog>
  )
}
export default NewTaskModal
