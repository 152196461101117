/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable max-lines */
import 'src/styles/Custom/big-calendar.css'

import AddRoundedIcon from '@mui/icons-material/AddRounded'
import AppleIcon from '@mui/icons-material/Apple'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded'
import LibraryMusicRoundedIcon from '@mui/icons-material/LibraryMusicRounded'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import { Avatar, Button, Card, CardActionArea, Divider, IconButton, List, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import type { SlideProps } from '@mui/material/Slide'
import Slide from '@mui/material/Slide'
import dayjs from 'dayjs'
import { forwardRef, useEffect, useState } from 'react'
import type { EventProps } from 'react-big-calendar'
import { Calendar as BigCalendar } from 'react-big-calendar'
import { createGlobalStyle } from 'styled-components'

import { getIcalHashReleaseSchedule } from 'src/api/organisation'
import { getProjects } from 'src/api/projects'
import SearchField from 'src/components/form-elements/SearchField'
import GoogleCalendarLogo from 'src/components/Icons/GoogleCalendarLogo'
import NewProjectModal from 'src/components/pages/Projects/EditProject/NewProjectModal'
import TaskSyncModal from 'src/components/pages/Task/TaskSync/TaskSyncModal'
import { ToolbarComponent } from 'src/components/pages/Task/TaskViews/TaskViewTypes/Calendar'
import ScheduleSyncTour from 'src/components/pages/Tour/Schedule/ScheduleSyncTour'
import { useAuth } from 'src/components/providers/AuthProvider'
import { NavLink } from 'src/components/SafeRouterLink'
import type Project from 'src/models/Project'
import SPACING from 'src/styles/spacing'
import { dayjsLocalizer } from 'src/utils/dayjsLocalizer'

const localizer = dayjsLocalizer()

const Transition = forwardRef<typeof Slide, SlideProps>((props, ref) =>
  <Slide direction='up' ref={ref} {...props} />)
Transition.displayName = 'Transition'

type CustomHeaderProps = {
  date: Date
  drilldownView: string
  isOffRange: boolean
  label: string
  onDrillDown: () => void
}

export const HeaderProject = (event: CustomHeaderProps) => {
  const themeItem = useTheme()
  const [newTaskModal, setNewTaskModalOpen] = useState(false)
  const isCurrentDate = event.date.getDate() === new Date().getDate() &&
  event.date.getMonth() === new Date().getMonth() &&
  event.date.getFullYear() === new Date().getFullYear()
  return (
    <>
      <Stack
        alignItems='center'
        direction='row'
        spacing={1}
        sx={{
          '.MuiButtonBase-root': {
            opacity: 0,
          },
          ':hover .MuiButtonBase-root': {
            opacity: event.isOffRange
              ? 0
              : 1,
          },
        }}
        width={1}
      >
        <Typography
          color={event.isOffRange
            ? themeItem.palette.text.disabled
            : isCurrentDate
              ? themeItem.palette.primary.contrastText
              : themeItem.palette.text.primary}
          sx={{
            flexShrink: 0,
            padding: '8px',
            borderRadius: '8px',
            background: theme => !isCurrentDate
              ? 'transparent'
              : theme.palette.primary.main,
          }}
          variant='body1'
        >
          {event.label}
        </Typography>
        <Button
          color='inherit'
          disabled={event.isOffRange}
          onClick={() => setNewTaskModalOpen(true)}
          size='small'
          startIcon={<AddRoundedIcon />}
          sx={{
            color: theme => theme.palette.text.label,
            transition: 'opacity 0.1s linear',
          }}
          variant='text'
        >
          New
        </Button>
      </Stack>
      {newTaskModal &&
      <NewProjectModal
        close={() => setNewTaskModalOpen(false)}
        date={new Date(event.date)}
        open={newTaskModal}
      />}
    </>
  )
}

const CustomEvent = (event: EventProps<Project>) =>
  <Card
    sx={{
      widht: 1,
      marginX: 0.5,
      marginBottom: 0.5,
      borderRadius: 0.5,
    }}
    variant='outlined'
  >
    <CardActionArea
      component={NavLink}
      sx={{
        width: 1,
        textDecoration: 'none',
        color: 'inherit',
      }}
      to={`/project/${event.event.id}`}
    >
      <Stack alignItems='center' direction='row' padding={1} spacing={1} width={1}>
        <Avatar
          src={event.event.artworkPreviewUrl}
          sx={{
            width: 32,
            height: 32,
            borderRadius: '4px',
          }}
          variant='rounded'
        >
          <MusicNoteRoundedIcon />
        </Avatar>
        <ListItemText
          primary={event.title}
          secondary={event.event.primaryArtists.map(item => item.name).join(', ')}
        />
      </Stack>
    </CardActionArea>
  </Card>

const ReleaseSchedule = () => {
  const themeItem = useTheme()
  const matches = useMediaQuery(themeItem.breakpoints.down('md'))
  const { currentOrganisation } = useAuth()
  const [projects, setProjects] = useState<Project[]>([])
  const [hash, setHash] = useState('')
  const [openSyncModal, setOpenSyncModal] = useState(false)
  const [syncType, setSyncType] = useState<'apple' | 'google'>('google')
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const openMenu = Boolean(anchorElement)
  const today = Date.now()

  const [searchQuery, setSearchQuery] = useState('')
  const filteredProjects = projects.filter(item =>
    (item.title.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase()) ||
    item.primaryArtists.map(artist => artist.name).join(', ')
      .toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase())) &&
    item.releaseDate.getTime() > today)
    .sort((a, b) => new Date(a.releaseDate).getTime() - new Date(b.releaseDate).getTime())

  useEffect(() => {
    void getProjects()
      .then(setProjects)
  }, [currentOrganisation?.id])

  useEffect(() => {
    void getIcalHashReleaseSchedule()
      .then(setHash)
  }, [currentOrganisation?.id])

  const GlobalStyles = createGlobalStyle`
  html {
    .rbc-month-row>.rbc-row-bg>.rbc-day-bg {
      border-right: 1px solid ${themeItem.palette.border}!important
    }
    .rbc-month-row+.rbc-month-row {
      border-top: 1px solid ${themeItem.palette.border}!important
    }
    .rbc-off-range-bg {
      background: ${themeItem.palette.border}!important
    }
    .rbc-header {
      border-bottom: 1px solid ${themeItem.palette.border}!important
    }
  }
  `

  return (
    <>
      <ScheduleSyncTour />
      <Stack
        sx={{
          paddingBottom: SPACING.RESPONSIVE_CONTOUR,
          paddingRight: SPACING.RESPONSIVE_CONTOUR,
          height: 1,
          width: 1,
        }}
      >
        <Paper
          elevation={0}
          sx={{
            height: 1,
            width: 1,
            overflow: 'hidden',
            borderRadius: {
              xl: 1,
              lg: 1,
              md: 1,
              sm: 0,
              xs: 0,
            },
          }}
        >
          <Stack direction='row' height={1} width={1}>
            {!matches &&
            <Stack flexShrink={0} width={280}>
              <Stack paddingBottom={1} paddingTop={2} paddingX={2} width={1}>
                <SearchField
                  fullWidth
                  onChange={event => setSearchQuery(event.target.value)}
                  sx={{
                    marginTop: 0,
                    '.MuiInputBase-root': {
                      paddingY: 0,
                      marginTop: 0,
                    },
                    '.MuiInputBase-input': {
                      paddingX: '0px!important',
                    },
                  }}
                  value={searchQuery}
                />
              </Stack>
              <List>
                <ListItemButton
                  component={NavLink}
                  sx={{
                    borderRadius: '0px!important',
                    borderBottom: theme => `1px solid ${theme.palette.divider}`,
                    borderTop: theme => `1px solid ${theme.palette.divider}`,
                  }}
                  to='/projects/projects'
                >
                  <ListItemText
                    primary='View all upcoming Releases'
                  />
                  <IconButton size='small'>
                    <ArrowForwardIosRoundedIcon />
                  </IconButton>
                </ListItemButton>
                {filteredProjects.map(project =>
                  <ListItemButton
                    component={NavLink}
                    key={`project-${project.id}`}
                    sx={{
                      borderRadius: '0px!important',
                      borderBottom: theme => `1px solid ${theme.palette.divider}`,
                    }}
                    to={`/project/${project.id}`}
                  >
                    <ListItemAvatar>
                      <Avatar src={project.artworkPreviewUrl} sx={{ borderRadius: '4px' }} variant='rounded'>
                        <LibraryMusicRoundedIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <Stack width={1}>
                      <Typography lineHeight={1.2} variant='h4'>
                        {project.title}
                      </Typography>
                      {project.primaryArtists.length > 0 &&
                      <Typography color='textSecondary' variant='body2'>
                        {project.primaryArtists.map(item => item.name).join(', ')}
                      </Typography>}
                      <Typography color='textLabel' variant='body2'>
                        {dayjs(project.releaseDate).format('LL')}
                      </Typography>
                    </Stack>
                    <IconButton size='small'>
                      <ArrowForwardIosRoundedIcon />
                    </IconButton>
                  </ListItemButton>)}
              </List>
            </Stack>}
            {!matches &&
            <Divider orientation='vertical' />}
            <Stack height={1} overflow='auto' width={1}>
              <Stack
                alignItems='stretch'
                boxSizing='border-box'
                direction='column'
                height={1}
                minWidth={0}
                overflow='auto'
                padding={0}
                width={1}
              >
                <GlobalStyles />
                <Stack alignItems='center' direction='row' justifyContent='space-between' padding={2} spacing={2}>
                  <Stack alignItems='center' direction='row' spacing={2}>
                    <Avatar
                      alt={currentOrganisation?.name}
                      src={currentOrganisation?.logoUrl}
                      style={{
                        width: 64,
                        height: 64,
                        objectFit: 'cover',
                        borderRadius: 8,
                      }}
                    />
                    <Stack alignContent='center' height={1} justifyContent='center'>
                      <ListItemText
                        primary={<Typography lineHeight={1} variant='h3'>
                          Schedule
                        </Typography>}
                        secondary={currentOrganisation?.name}
                      />
                    </Stack>
                  </Stack>
                  <Stack>
                    <Button
                      color='inherit'
                      id='sync-schedule'
                      onClick={event => setAnchorElement(event.currentTarget)}
                      startIcon={<CalendarMonthRoundedIcon />}
                    >
                      Sync Calendar
                    </Button>
                  </Stack>
                </Stack>
                <Divider />
                <Stack height={1} overflow='auto' width={1}>
                  <BigCalendar
                    components={{
                      event: CustomEvent,
                      month: {
                        dateHeader: propsHeader => HeaderProject(propsHeader),
                      },
                      toolbar: ToolbarComponent,
                    }}
                    endAccessor='releaseDate'
                    events={projects}
                    localizer={localizer}
                    showAllEvents
                    showMultiDayTimes
                    startAccessor='releaseDate'
                    style={{ height: '100%', minWidth: '750px' }}
                    titleAccessor='title'
                    tooltipAccessor='title'
                    views={{ month: true }}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Paper>
      </Stack>
      {openSyncModal &&
      <TaskSyncModal
        close={() => setOpenSyncModal(false)}
        hash={hash}
        id={currentOrganisation?.id ?? ''}
        open={openSyncModal}
        provider={syncType}
        type='releaseSchedule'
      />}
      <Menu
        anchorEl={anchorElement}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClick={() => setAnchorElement(null)}
        onClose={() => setAnchorElement(null)}
        open={openMenu}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <MenuItem
          onClick={() => {
            setSyncType('google')
            setOpenSyncModal(true)
          }}
        >
          <ListItemIcon>
            <GoogleCalendarLogo fontSize='medium' />
          </ListItemIcon>
          Add to Google Calendar
        </MenuItem>
        <MenuItem
          onClick={() => {
            setSyncType('apple')
            setOpenSyncModal(true)
          }}
        >
          <ListItemIcon>
            <AppleIcon color='action' fontSize='medium' />
          </ListItemIcon>
          Add to Apple Calendar
        </MenuItem>
      </Menu>
    </>
  )
}

export default ReleaseSchedule
