/* eslint-disable max-lines */
/* eslint-disable sonarjs/no-duplicate-string */
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import CalendarViewMonthRoundedIcon from '@mui/icons-material/CalendarViewMonthRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone'
import FolderRoundedIcon from '@mui/icons-material/FolderRounded'
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded'
import TableViewRoundedIcon from '@mui/icons-material/TableViewRounded'
import ViewKanbanRoundedIcon from '@mui/icons-material/ViewKanbanRounded'
import ViewListRoundedIcon from '@mui/icons-material/ViewListRounded'
import { TabContext, TabList } from '@mui/lab'
import { Avatar, AvatarGroup, Button, Card, Chip, Divider, IconButton, ListItem, ListItemButton, ListItemText, Skeleton, Stack, Tab, Typography } from '@mui/material'

type Props = {
  click: () => void
  update?: boolean
}

const TaskSkeletonModal = (props: Props) =>
  <Stack height={1} spacing={2} width={1}>
    <Card sx={{ width: 1, height: 1 }} variant='outlined'>
      <Stack direction='row' padding={2} spacing={2} width={1}>
        <Skeleton
          animation={false}
          height={64}
          sx={{
            borderRadius: 1,
            flexShrink: 0,
          }}
          variant='rectangular'
          width={64}
        />
        <Stack justifyContent='center' width={1}>
          <Skeleton
            animation={false}
            sx={{
              maxWidth: 128,
            }}
            variant='text'
          />
          <Skeleton
            animation={false}
            sx={{
              maxWidth: 256,
            }}
            variant='text'
          />
        </Stack>
        <Stack alignItems='flex-end' justifyContent='center' width={1}>
          <Button
            endIcon={<ArrowForwardIosRoundedIcon />}
            onClick={props.click}
            sx={{ flexShrink: 0, width: 'fit-content' }}
          >
            {props.update
              ? 'Update board'
              : 'Continue'}
          </Button>
        </Stack>
      </Stack>
      <Stack height={1} sx={{ pointerEvents: 'none' }} width={1}>
        <TabContext value='1'>
          <TabList
            sx={{
              minHeight: 32,
              '.MuiTab-root':
                {
                  textTransform: 'none',
                  fontWeight: 'normal',
                  fontSize: '16px',
                  minHeight: 32,
                },
            }}
          >
            <Tab
              icon={<ViewListRoundedIcon />}
              iconPosition='start'
              label='List'
              sx={{ minHeight: 32 }}
              value='0'
            />
            <Tab
              icon={<ViewKanbanRoundedIcon />}
              iconPosition='start'
              label='Board'
              sx={{ minHeight: 32 }}
              value='1'
            />
            <Tab
              icon={<CalendarViewMonthRoundedIcon />}
              iconPosition='start'
              label='Calendar'
              sx={{ minHeight: 32 }}
              value='calendar'
            />
            <Tab
              icon={<FolderRoundedIcon />}
              iconPosition='start'
              label='Files'
              sx={{ minHeight: 32 }}
              value='files'
            />
            <Tab
              icon={<TableViewRoundedIcon />}
              iconPosition='start'
              label='Dashboard'
              sx={{ minHeight: 32 }}
              value='dashboard'
            />
            <Tab
              icon={<HistoryRoundedIcon />}
              iconPosition='start'
              label='History'
              sx={{ minHeight: 32 }}
              value='history'
            />
          </TabList>
        </TabContext>
        <Divider sx={{ width: 1 }} />
        <Stack direction='row' height={1} overflow='hidden' padding={2} spacing={2} width={1}>
          <Stack flexShrink={0} spacing={2} width={284}>
            <Chip
              color='default'
              label='Not started'
              sx={{ width: 'fit-content' }}
              variant='filled'
            />
            <Card
              sx={{
                maxHeight: 1,
                overflow: 'hidden',
                padding: 2,
                boxShadow: 'none',
                backgroundColor: theme => theme.palette.background.input,
              }}
            >
              <Card
                elevation={6}
                sx={{
                  boxShadow: 'none',
                  backgroundColor: theme => theme.palette.background.default,
                  padding: 1,
                  marginTop: 1,
                  marginBottom: 1,
                  border: theme => `1px solid ${theme.palette.background.input}`,
                  borderColor: theme => theme.palette.background.input }}
              >
                <Stack paddingBottom={1} paddingLeft={0.5} paddingTop={1} spacing={2}>
                  <Stack alignItems='center' direction='row' justifyContent='space-between'>
                    <Stack alignItems='center' direction='row' spacing={1}>
                      <IconButton
                        aria-label='Complete'
                        sx={{ padding: 0 }}
                      >
                        <CircleTwoToneIcon fontSize='large' sx={{ color: theme => theme.palette.text.label }} />
                      </IconButton>

                      <Skeleton
                        animation={false}
                        variant='text'
                        width={128}
                      />
                    </Stack>
                    <Stack alignItems='center' direction='row' paddingRight={1} spacing={1} >
                      <AvatarGroup>
                        <Avatar
                          sx={{ width: 24, height: 24 }}
                        />
                      </AvatarGroup>
                    </Stack>
                  </Stack>
                </Stack>
              </Card>
              <ListItem disablePadding>
                <ListItemButton
                  disableRipple
                  disableTouchRipple
                  sx={{ borderRadius: 2 }}
                >
                  <ListItemText
                    disableTypography
                    primary={<Typography
                      sx={{ color: theme => theme.palette.text.secondary }}
                      variant='body2'
                    >
                      + Add a task
                    </Typography>}
                  />
                </ListItemButton>
              </ListItem>
            </Card>
          </Stack>
          <Stack flexShrink={0} spacing={2} width={284}>
            <Chip
              color='primary'
              label='In progress'
              sx={{ width: 'fit-content' }}
              variant='filled'
            />
            <Card
              sx={{
                maxHeight: 1,
                overflow: 'hidden',
                padding: 2,
                boxShadow: 'none',
                backgroundColor: theme => theme.palette.background.input,
              }}
            >
              <Card
                elevation={6}
                sx={{
                  boxShadow: 'none',
                  backgroundColor: theme => theme.palette.background.default,
                  padding: 1,
                  marginTop: 1,
                  marginBottom: 1,
                  border: theme => `1px solid ${theme.palette.background.input}`,
                  borderColor: theme => theme.palette.background.input }}
              >
                <Stack paddingBottom={1} paddingLeft={0.5} paddingTop={1} spacing={2}>
                  <Stack alignItems='center' direction='row' justifyContent='space-between'>
                    <Stack alignItems='center' direction='row' spacing={1}>
                      <IconButton
                        aria-label='Complete'
                        color='primary'
                        sx={{ padding: 0 }}
                      >
                        <CircleTwoToneIcon color='primary' fontSize='large' />
                      </IconButton>

                      <Skeleton
                        animation={false}
                        variant='text'
                        width={128}
                      />
                    </Stack>
                    <Stack alignItems='center' direction='row' paddingRight={1} spacing={1} >
                      <AvatarGroup>
                        <Avatar
                          sx={{ width: 24, height: 24 }}
                        />
                      </AvatarGroup>
                    </Stack>
                  </Stack>
                </Stack>
              </Card>
              <Card
                elevation={6}
                sx={{
                  boxShadow: 'none',
                  backgroundColor: theme => theme.palette.background.default,
                  padding: 1,
                  marginTop: 1,
                  marginBottom: 1,
                  border: theme => `1px solid ${theme.palette.background.input}`,
                  borderColor: theme => theme.palette.background.input }}
              >
                <Stack paddingBottom={1} paddingLeft={0.5} paddingTop={1} spacing={2}>
                  <Stack alignItems='center' direction='row' justifyContent='space-between'>
                    <Stack alignItems='center' direction='row' spacing={1}>
                      <IconButton
                        aria-label='Complete'
                        sx={{ padding: 0 }}
                      >
                        <CircleTwoToneIcon color='primary' fontSize='large' />
                      </IconButton>

                      <Skeleton
                        animation={false}
                        variant='text'
                        width={128}
                      />
                    </Stack>
                    <Stack alignItems='center' direction='row' paddingRight={1} spacing={1} >
                      <AvatarGroup>
                        <Avatar
                          sx={{ width: 24, height: 24 }}
                        />
                      </AvatarGroup>
                    </Stack>
                  </Stack>
                </Stack>
              </Card>
              <Card
                elevation={6}
                sx={{
                  boxShadow: 'none',
                  backgroundColor: theme => theme.palette.background.default,
                  padding: 1,
                  marginTop: 1,
                  marginBottom: 1,
                  border: theme => `1px solid ${theme.palette.background.input}`,
                  borderColor: theme => theme.palette.background.input }}
              >
                <Stack paddingBottom={1} paddingLeft={0.5} paddingTop={1} spacing={2}>
                  <Stack alignItems='center' direction='row' justifyContent='space-between'>
                    <Stack alignItems='center' direction='row' spacing={1}>
                      <IconButton
                        aria-label='Complete'
                        sx={{ padding: 0 }}
                      >
                        <CircleTwoToneIcon color='primary' fontSize='large' />
                      </IconButton>

                      <Skeleton
                        animation={false}
                        variant='text'
                        width={128}
                      />
                    </Stack>
                    <Stack alignItems='center' direction='row' paddingRight={1} spacing={1} >
                      <AvatarGroup>
                        <Avatar
                          sx={{ width: 24, height: 24 }}
                        />
                      </AvatarGroup>
                    </Stack>
                  </Stack>
                </Stack>
              </Card>
              <ListItem disablePadding>
                <ListItemButton
                  disableRipple
                  disableTouchRipple
                  sx={{ borderRadius: 2 }}
                >
                  <ListItemText
                    disableTypography
                    primary={<Typography
                      sx={{ color: theme => theme.palette.text.secondary }}
                      variant='body2'
                    >
                      + Add a task
                    </Typography>}
                  />
                </ListItemButton>
              </ListItem>
            </Card>
          </Stack>
          <Stack flexShrink={0} spacing={2} width={284}>
            <Chip
              color='success'
              label='Done'
              sx={{ width: 'fit-content' }}
              variant='filled'
            />
            <Card
              sx={{
                maxHeight: 1,
                overflow: 'hidden',
                padding: 2,
                boxShadow: 'none',
                backgroundColor: theme => theme.palette.background.input,
              }}
            >
              <Card
                elevation={6}
                sx={{
                  boxShadow: 'none',
                  backgroundColor: theme => theme.palette.background.default,
                  padding: 1,
                  marginTop: 1,
                  marginBottom: 1,
                  border: theme => `1px solid ${theme.palette.background.input}`,
                  borderColor: theme => theme.palette.background.input }}
              >
                <Stack paddingBottom={1} paddingLeft={0.5} paddingTop={1} spacing={2}>
                  <Stack alignItems='center' direction='row' justifyContent='space-between'>
                    <Stack alignItems='center' direction='row' spacing={1}>
                      <IconButton
                        aria-label='Complete'
                        color='success'
                        sx={{ padding: 0 }}
                      >
                        <CheckCircleRoundedIcon color='success' fontSize='large' />
                      </IconButton>

                      <Skeleton
                        animation={false}
                        variant='text'
                        width={128}
                      />
                    </Stack>
                    <Stack alignItems='center' direction='row' paddingRight={1} spacing={1} >
                      <AvatarGroup>
                        <Avatar
                          sx={{ width: 24, height: 24 }}
                        />
                      </AvatarGroup>
                    </Stack>
                  </Stack>
                </Stack>
              </Card>
              <Card
                elevation={6}
                sx={{
                  boxShadow: 'none',
                  backgroundColor: theme => theme.palette.background.default,
                  padding: 1,
                  marginTop: 1,
                  marginBottom: 1,
                  border: theme => `1px solid ${theme.palette.background.input}`,
                  borderColor: theme => theme.palette.background.input }}
              >
                <Stack paddingBottom={1} paddingLeft={0.5} paddingTop={1} spacing={2}>
                  <Stack alignItems='center' direction='row' justifyContent='space-between'>
                    <Stack alignItems='center' direction='row' spacing={1}>
                      <IconButton
                        aria-label='Complete'
                        color='success'
                        sx={{ padding: 0 }}
                      >
                        <CheckCircleRoundedIcon color='success' fontSize='large' />
                      </IconButton>

                      <Skeleton
                        animation={false}
                        variant='text'
                        width={128}
                      />
                    </Stack>
                    <Stack alignItems='center' direction='row' paddingRight={1} spacing={1} >
                      <AvatarGroup>
                        <Avatar
                          sx={{ width: 24, height: 24 }}
                        />
                      </AvatarGroup>
                    </Stack>
                  </Stack>
                </Stack>
              </Card>
              <ListItem disablePadding>
                <ListItemButton
                  disableRipple
                  disableTouchRipple
                  sx={{ borderRadius: 2 }}
                >
                  <ListItemText
                    disableTypography
                    primary={<Typography
                      sx={{ color: theme => theme.palette.text.secondary }}
                      variant='body2'
                    >
                      + Add a task
                    </Typography>}
                  />
                </ListItemButton>
              </ListItem>
            </Card>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  </Stack>

export default TaskSkeletonModal
