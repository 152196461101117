/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable max-lines */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
import AddTaskRoundedIcon from '@mui/icons-material/AddTaskRounded'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import EmojiPeopleRoundedIcon from '@mui/icons-material/EmojiPeopleRounded'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded'
import ScheduleSendRoundedIcon from '@mui/icons-material/ScheduleSendRounded'
import { TabContext, TabList } from '@mui/lab'
import { AppBar, Avatar, AvatarGroup, Button, Card, CardActionArea, Dialog, Grid, IconButton, Slide, Stack, Tab, Toolbar, Typography } from '@mui/material'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { v4 } from 'uuid'

import { getTaskboardTemplates, savePartialTaskboard } from 'src/api/taskboards'
import { sendTasksboardUpdate } from 'src/api/webSocket'
import SearchField from 'src/components/form-elements/SearchField'
import BillingModal from 'src/components/pages/Account/Billing/BillingModal'
import { taskboardEmpty } from 'src/components/pages/Task/TaskboardTemplates/Empty'
import { taskboardFanEngagement } from 'src/components/pages/Task/TaskboardTemplates/FanEngagement'
import { taskboardMusicRelease } from 'src/components/pages/Task/TaskboardTemplates/MusicRelease'
import { taskboardPitchingSchedule } from 'src/components/pages/Task/TaskboardTemplates/PitchingSchedule'
import { taskboardReleaseRollout } from 'src/components/pages/Task/TaskboardTemplates/ReleaseRollout'
import CardTemplateItem from 'src/components/pages/Task/TaskMenu/TaskMenuComponents/CardTemplateItem'
import TaskSkeletonModal from 'src/components/pages/Task/TaskMenu/TaskMenuModals/TaskSkeletonModal'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { TaskBoard, TaskBoardTemplate } from 'src/models/Taskboard'
import { TaskBoardEdit } from 'src/models/Taskboard'

type Props = {
  close: () => void
  open: boolean
  taskboard: TaskBoard
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>
}

const ImportTemplateModal = (props: Props) => {
  const { currentAccount, currentOrganisation } = useAuth()

  const templateList = _.cloneDeep([
    taskboardEmpty,
    taskboardMusicRelease,
    taskboardFanEngagement,
    taskboardPitchingSchedule,
    taskboardReleaseRollout,
  ])

  const [indexTemplate, setIndexTemplate] = useState(0)
  const [customIndexTemplate, setCustomIndexTemplate] = useState(0)
  const [billingModal, setBillingModalOpen] = useState(false)

  const [filter, setFilter] = useState('')
  const filterTemplateList = templateList.filter(item =>
    item.title.toLowerCase().includes(filter.toLowerCase()))

  const [taskboardTemplates, setTaskboardTemplates] = useState<TaskBoardTemplate[]>([])

  const filterCustomTemplateList = taskboardTemplates.filter(item =>
    item.title.toLowerCase().includes(filter.toLowerCase()))
  const deepCloneTemplate = _.cloneDeep(taskboardEmpty)
  const [templateItem, setTemplateItem] = useState(deepCloneTemplate)

  const [tabForTemplates, setTabForTemplates] = useState('0')

  useEffect(() => {
    void getTaskboardTemplates()
      .then(setTaskboardTemplates)
  }, [])

  const taskboardTemplateRefresh = () => {
    void getTaskboardTemplates()
      .then(setTaskboardTemplates)
  }

  const handleSubmit = async () => {
    if (props.taskboard.id) {
      const oldDate = new Date(templateItem.createdAt)
      const distanceDates = (Date.now() - oldDate.getTime()) /
      (1000 * 3600 * 24)

      for (const taskgroup of templateItem.taskGroups) {
        for (const taskItem of taskgroup.tasks) {
          if (taskItem.dueDate && taskItem.dueDate !== null) {
            taskItem.dueDate =
              new Date(new Date(taskItem.dueDate).setDate(new Date(taskItem.dueDate).getDate() + distanceDates))
          }
        }
      }

      props.taskboard.taskGroups = templateItem.taskGroups
      props.taskboard.customFields = templateItem.customFields
      await savePartialTaskboard({ id: props.taskboard.id, ...props.taskboard })
        .then(() => {
          const taskUpdateItem = new TaskBoardEdit({
            id: v4(),
            taskboardId: props.taskboard.id,
            taskId: '',
            accountId: currentAccount.id,
            modificationType: 'Import template',
            itemType: 'Field',
            title: `${templateItem.title}`,
          })
          sendTasksboardUpdate(taskUpdateItem)
        })
        .then(() => {
          props.setRefresh(old => !old)
          props.close()
        })
    }
  }

  const handleSwitch = (index: number) => {
    setIndexTemplate(index)
    setTemplateItem(filterTemplateList[index])
  }

  const handleSwitchCustom = (index: number) => {
    setCustomIndexTemplate(index)
    setTemplateItem(filterCustomTemplateList[index])
  }

  function iconRender(index: number, colorIcon: string) {
    switch (index) {
      case 0:
        return <CheckBoxOutlineBlankRoundedIcon fontSize='large' sx={{ color: colorIcon }} />
      case 1:
        return <MusicNoteRoundedIcon fontSize='large' sx={{ color: colorIcon }} />
      case 2:
        return <ScheduleSendRoundedIcon fontSize='large' sx={{ color: colorIcon }} />
      case 3:
        return <ScheduleRoundedIcon fontSize='large' sx={{ color: colorIcon }} />
      case 4:
        return <EmojiPeopleRoundedIcon fontSize='large' sx={{ color: colorIcon }} />
      case 5:
        return <MusicNoteRoundedIcon fontSize='large' sx={{ color: colorIcon }} />
      default:
        return <MusicNoteRoundedIcon fontSize='large' sx={{ color: colorIcon }} />
    }
  }

  return (
    <>
      {billingModal && currentOrganisation &&
      <BillingModal
        account={currentOrganisation}
        close={() => setBillingModalOpen(false)}
        open={billingModal}
      />}
      <Dialog
        BackdropProps={{
          timeout: 500,
        }}
        closeAfterTransition
        fullScreen
        onClose={props.close}
        open={props.open}
        sx={{
          '& .MuiPaper-root': {
            transform: 'none!important',
          },
          '.MuiDialog-paper': {
            height: '100%',
          },
        }}
      >
        <Slide direction='up' in={props.open}>
          <Stack
            height={1}
            overflow='auto'
            sx={{
              background: theme => theme.palette.background.input,
            }}
          >
            <AppBar
              position='fixed'
              sx={{
                background: theme => theme.palette.background.default,
                borderRadius: 2,
                zIndex: '99999!important',
                top: 16,
                marginLeft: 2,
                marginRight: 2,
                paddingX: 2,
                width: 'calc(100% - 32px)',
              }}
              variant='outlined'
            >
              <Toolbar
                sx={{
                  justifyContent: 'space-between',
                  paddingX: '8px!important',
                }}
              >
                <Stack
                  alignItems='center'
                  direction='row'
                  justifyContent='space-between'
                  spacing={2}
                  width={1}
                >
                  <Typography variant='h3'>
                    Import template
                  </Typography>
                  <IconButton
                    aria-label='close'
                    onClick={props.close}
                  >
                    <CloseRoundedIcon />
                  </IconButton>
                </Stack>
              </Toolbar>
            </AppBar>
            <Stack alignItems='center' height={1} marginTop={8} width={1}>
              <Stack direction='column' height={1} paddingX={2} paddingY={2} spacing={1} width={1}>
                <Stack direction='row' height={1} spacing={2}>
                  <Grid container height={1} spacing={2} width={1}>
                    <Grid
                      item
                      lg={4}
                      md={6}
                      sm={6}
                      sx={{
                        height: 1,
                      }}
                      xl={4}
                      xs={12}
                    >
                      <Stack
                        alignItems='center'
                        borderRadius={2}
                        height={1}
                        overflow='hidden'
                        sx={{
                          bgcolor: theme => theme.palette.background.input,
                        }}
                        width={1}
                      >
                        <Stack
                          alignItems='center'
                          borderRadius={2}
                          height={1}
                          overflow='auto'
                          padding={2}
                          spacing={2}
                          width={1}
                        >
                          <Stack alignItems='center' spacing={2} width={1}>
                            <AvatarGroup>
                              <Avatar
                                sx={{
                                  bgcolor: theme => theme.palette.primary.main,
                                  width: 48,
                                  height: 48,
                                }}
                              >
                                <ArrowForwardRoundedIcon />
                              </Avatar>
                              <Avatar
                                sx={{
                                  bgcolor: theme => theme.palette.primary.main,
                                  width: 48,
                                  height: 48,

                                }}
                              >
                                <ArrowForwardRoundedIcon />
                              </Avatar>
                              <Avatar
                                sx={{
                                  bgcolor: theme => theme.palette.primary.main,
                                  width: 48,
                                  height: 48,
                                }}
                              >
                                <ArrowForwardRoundedIcon />
                              </Avatar>
                            </AvatarGroup>
                            <Stack alignItems='center' maxWidth={240} width={1}>
                              <Typography lineHeight={1.4} textAlign='center' variant='h3'>
                                Select a template to switch your board
                              </Typography>
                            </Stack>
                            <SearchField
                              onChange={event => setFilter(event.target.value)}
                              value={filter}
                            />
                          </Stack>
                          <TabContext value={tabForTemplates}>
                            <TabList
                              aria-label='Current template tab'
                              onChange={(event, value: string) => setTabForTemplates(value)}
                              sx={{
                                minHeight: 32,
                                '.MuiTab-root':
                  {
                    textTransform: 'none',
                    fontWeight: 'normal',
                    fontSize: '16px',
                    minHeight: 32,
                    background: 'transparent!important',
                  },
                              }}
                              variant='scrollable'
                            >
                              <Tab label='Releese templates' value='0' />
                              <Tab label='Your templates' value='1' />
                            </TabList>
                          </TabContext>
                          {tabForTemplates === '0' && filterTemplateList.map((template, index) =>
                            <Card
                              key={`${template.title}${index.toString()}`}
                              onClick={() => handleSwitch(index)}
                              sx={{
                                borderColor: indexTemplate === index
                                  ? theme => `${theme.palette.primary.main}!important`
                                  : 'default',
                                width: 1,
                                flexShrink: 0,
                              }}
                              variant='outlined'
                            >
                              <CardActionArea>
                                <Stack alignItems='center' direction='row' padding={2} spacing={2}>
                                  {iconRender(index, template.color)}
                                  <Stack direction='column'>
                                    <Typography lineHeight={1.4} variant='h4'>
                                      {template.title}
                                    </Typography>
                                    {template.description &&
                                      <Typography variant='body2'>
                                        {`${template.description.slice(0, 80)}...`}
                                      </Typography>}
                                  </Stack>
                                </Stack>
                              </CardActionArea>
                            </Card>)}
                          {tabForTemplates === '1' &&
                          currentOrganisation?.membershipTier !== 'FREE' &&
                          filterCustomTemplateList.reverse().map((template, index) =>
                            <CardTemplateItem
                              customIndexTemplate={customIndexTemplate}
                              handleSwitchCustom={handleSwitchCustom}
                              index={index}
                              key={template.title}
                              taskboardTemplateRefresh={taskboardTemplateRefresh}
                              template={template}
                            />)}
                          {tabForTemplates === '1' &&
                            currentOrganisation?.membershipTier === 'FREE' &&
                            <Stack alignItems='center' spacing={2}>
                              <Stack alignItems='center'>
                                <AddTaskRoundedIcon
                                  color='success'
                                  sx={{
                                    frontSize: 64,
                                    height: 64,
                                    width: 64,
                                  }}
                                />
                                <Typography textAlign='center' variant='h6'>
                                  Automate your workflows
                                </Typography>
                                <Typography textAlign='center' variant='body2'>
                                  Create custom templates from scratch
                                </Typography>
                              </Stack>
                              <Button
                                color='success'
                                onClick={() => setBillingModalOpen(true)}
                              >
                                Explore plans
                              </Button>
                            </Stack>}
                        </Stack>
                      </Stack>
                    </Grid>

                    <Grid item lg={8} md={6} sm={6} xl={8} xs={12}>
                      <Stack height={1} spacing={1} width={1}>
                        <Stack maxWidth={600}>
                          <Typography lineHeight={1.4} variant='h4'>
                            {templateItem.title}
                          </Typography>
                          {templateItem.description &&
                            <Typography variant='body2'>
                              {templateItem.description}
                            </Typography>}
                        </Stack>
                        <TaskSkeletonModal
                          click={() => handleSubmit()}
                          update
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Slide>
      </Dialog>
    </>
  )
}

export default ImportTemplateModal
