/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable max-lines */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable complexity */
/* eslint-disable react-hooks/exhaustive-deps */
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded'
import ImageRoundedIcon from '@mui/icons-material/ImageRounded'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import { Card, CardActionArea, IconButton, Stack, Typography } from '@mui/material'
import { useState } from 'react'

import { baseUrlLinksApi } from 'src/api/links'
import PreviewFile from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/FilesTab/PreviewFile'
import type { FileItem } from 'src/models/File'

type Props = {
  fileItem: FileItem
  link: string
}

export const renderIcon = (extension: string) => {
  switch (extension.toLowerCase()) {
    case 'jpg':
    case 'png':
    case 'jpeg':
    case 'gif':
    case 'webp':
    case 'bmp':
      return <ImageRoundedIcon color='secondary' fontSize='large' />
    case 'docx':
    case 'doc':
    case 'pdf':
    case 'xml':
    case 'rtf':
    case 'pages':
    case 'odt':
    case 'f2b':
      return <ArticleRoundedIcon color='success' fontSize='large' />
    case 'mp3':
    case 'wav':
    case 'mp2':
    case 'flac':
    case 'ogg':
    case 'aiff':
    case 'aac':
      return <MusicNoteRoundedIcon color='info' fontSize='large' />
    default:
      return <AttachFileIcon color='primary' fontSize='large' />
  }
}

const ViewFileItemPulicTable = (props: Props) => {
  const [openImage, setImageOpen] = useState(false)

  const regexExtension = /\w{3,4}($|\?)/
  const fileExtension = regexExtension.exec(props.link ?? '')

  const preventClick = (event: React.MouseEvent) => {
    event.stopPropagation()
  }

  return (
    <>
      <Card
        elevation={6}
        sx={{
          boxShadow: 'none',
          backgroundColor: theme => theme.palette.background.default,
          border: theme => `1px solid ${theme.palette.divider}`,
          width: 1,
          height: 1,
          borderColor: theme => theme.palette.divider }}
      >
        <CardActionArea
          onClick={() => setImageOpen(true)}
          sx={{
            height: 1,
          }}
        >
          <Stack direction='column'>
            <Stack alignItems='center' direction='row' padding={2} width={1}>
              <Stack alignItems='center' direction='row' marginRight='auto' width={1}>
                {renderIcon(fileExtension?.[0].toString() ?? '')}
                <Stack direction='column' paddingX={2} width={1}>
                  <Typography sx={{ paddingY: 0 }} variant='body1'>
                    {props.fileItem.title}
                  </Typography>
                </Stack>
              </Stack>
              <Stack alignItems='center' direction='row' marginLeft='auto' spacing={1}>
                {props.fileItem && props.link &&
                <IconButton
                  download
                  href={`${baseUrlLinksApi}/files/download?fileName=${encodeURIComponent(props.link)}`}
                  onClick={event => preventClick(event)}
                  size='small'
                  target='_blank'
                >
                  <FileDownloadRoundedIcon />
                </IconButton>}
              </Stack>
            </Stack>
          </Stack>
        </CardActionArea>
      </Card>
      {props.link &&
      <PreviewFile
        close={() => setImageOpen(false)}
        fileType={fileExtension?.[0].toString() ?? 'jpg'}
        image={props.link}
        open={openImage}
        text={props.fileItem?.title}
      />}
    </>
  )
}

export default ViewFileItemPulicTable
