/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable complexity */
/* eslint-disable max-lines */
import AddIcon from '@mui/icons-material/AddRounded'
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded'
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded'
import ChatIcon from '@mui/icons-material/ChatRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import HelpRoundedIcon from '@mui/icons-material/HelpRounded'
import LibraryMusicRoundedIcon from '@mui/icons-material/LibraryMusicRounded'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import NotificationsIcon from '@mui/icons-material/NotificationsRounded'
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded'
import PhonelinkRoundedIcon from '@mui/icons-material/PhonelinkRounded'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded'
import { AppBar, Avatar, Badge, Fab, IconButton, ListItemIcon, Menu, MenuItem, Stack, Toolbar, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'

import HeaderSearchField from 'src/components/form-elements/HeaderSearchField'
import BillingModal from 'src/components/pages/Account/Billing/BillingModal'
import NewLinkModal from 'src/components/pages/Links/LinksList/LinksListModal/NewLinkModal'
import { DrawerState } from 'src/components/pages/Menu/MenuDrawer'
import { IconNavLinkTop } from 'src/components/pages/Menu/MenuNavLink'
import UpgradeCTAButtons from 'src/components/pages/Menu/UpgradeCTA/UpgradeCTAButtons'
import NewProjectModal from 'src/components/pages/Projects/EditProject/NewProjectModal'
import ReActivatedModal from 'src/components/pages/Settings/Billing/BillingTabs/ReActivatedModal'
import InviteMemberModal from 'src/components/pages/Settings/Team/TeamTabs/TeamTabsComponents/InviteMember'
import NewTaskModal from 'src/components/pages/Task/TaskDrawer/NewTaskModal'
import NewDocumentModal from 'src/components/pages/Task/TaskMenu/TaskMenuModals/NewDocumentModal'
import NewTaskboardModal from 'src/components/pages/Task/TaskMenu/TaskMenuModals/NewTaskboardModal'
import CheckmarkTour from 'src/components/pages/Tour/CheckMarkTour'
import SetupTour from 'src/components/pages/Tour/SetupTour'
import { useAuth } from 'src/components/providers/AuthProvider'
import { useMessenger } from 'src/components/providers/MessengerProvider'
import { useNotifications } from 'src/components/providers/NotificationProvider'
import ReleeseLogo from 'src/components/ReleeseLogo'
import { Permissions } from 'src/models/Organisation'

type Props = {
  leftNavigationWidth: string
  drawerState: DrawerState
  toggleDrawer: (toggleState: DrawerState) => () => void
  setDisplay: React.Dispatch<React.SetStateAction<string>>
  display: string
}

const Header = (props: Props) => {
  const themeColors = useTheme()
  const matches = useMediaQuery(themeColors.breakpoints.down('md'))
  const { conversations } = useMessenger()
  const { notifications } = useNotifications()
  const {
    currentOrganisation,
    currentAccount,
    currentAccountPermissions,
  } = useAuth()

  const [reActivatedModal, setReActivatedModal] = useState(false)

  const unreadNotifications = notifications.filter(notif => !notif.read).length

  const unreadConversations = conversations.filter(convo =>
    convo.lastMessage.content !== '' &&
    convo.lastMessage.senderId !== currentAccount.id &&
    !convo.lastMessage.readByAccountIds.includes(currentAccount.id)).length

  const isActive = (toggleState: DrawerState) => props.drawerState === toggleState
  const messagesActive = isActive(DrawerState.Messages)
  const notificationsActive = isActive(DrawerState.Notifications)
  const profileActive = isActive(DrawerState.Profile)

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const [billingModal, setBillingModalOpen] = useState(false)
  const openMenu = Boolean(anchorElement)
  const [newProjectModalOpen, setNewProjectModalOpen] = useState(false)
  const [newLinkModal, setNewLinkModalOpen] = useState(false)
  const [newTaskboardModal, setNewTaskboardModal] = useState(false)
  const [newTaskModal, setNewTaskModal] = useState(false)
  const [inviteModal, setInviteModal] = useState(false)
  const [newDocumentModalOpen, setNewDocumentModalOpen] = useState(false)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorElement(null)
  }

  useEffect(() => {
    document.title = unreadNotifications + unreadConversations === 0
      ? 'Releese'
      : `(${unreadNotifications + unreadConversations}) Releese`
  }, [unreadNotifications, unreadConversations])

  return <>
    <ReActivatedModal
      close={() => setReActivatedModal(false)}
      open={reActivatedModal}
    />
    <AppBar
      elevation={4}
      position='sticky'
      sx={{
        background: theme => theme.palette.background.input,
        height: 56,
        display: {
          xl: 'flex',
          lg: 'flex',
          md: 'flex',
          sm: 'none',
          xs: 'none',
        },
      }}
    >
      <Toolbar
        disableGutters
        sx={{
          minHeight: '56px!important',
        }}
      >
        <Stack alignItems='center' direction='row' justifyContent='space-between' width='100%'>

          <Stack alignItems='center' direction='row'>
            <Tooltip title={props.display === 'small' ? 'Expand' : 'Minimize'}>
              <IconButton
                color='default'
                onClick={() => props.setDisplay(old => old === 'small' ? 'large' : 'small')}
                size='medium'
                sx={{
                  color: theme => theme.palette.action.active,
                  marginLeft: 1,
                }}
              >
                <MenuRoundedIcon />
              </IconButton>
            </Tooltip>

            <ReleeseLogo width={props.leftNavigationWidth} />
            <Stack alignItems='center' direction='row' spacing={1}>
              <HeaderSearchField />
              <Fab
                id='create'
                onClick={handleClick}
                sx={{
                  boxShadow: 0,
                  width: 36,
                  height: 36,
                }}
              >
                <AddIcon />
              </Fab>
            </Stack>
          </Stack>
          <Menu
            anchorEl={anchorElement}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            onClick={handleClose}
            onClose={handleClose}
            open={openMenu}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          >
            <MenuItem
              disabled={!currentAccountPermissions?.includes(Permissions.CREATE_EDIT_REMOVE_PROJECTS)}
              onClick={() => setNewProjectModalOpen(true)}
            >
              <ListItemIcon>
                <LibraryMusicRoundedIcon color='primary' fontSize='medium' />
              </ListItemIcon>
              Release
            </MenuItem>
            <MenuItem
              disabled={!currentAccountPermissions?.includes(Permissions.VIEW_TASKBOARDS)}
              onClick={() => setNewTaskModal(true)}
            >
              <ListItemIcon>
                <TaskAltRoundedIcon color='success' fontSize='medium' />
              </ListItemIcon>
              Task
            </MenuItem>
            <MenuItem
              disabled={!currentAccountPermissions?.includes(Permissions.VIEW_TASKBOARDS)}
              onClick={() => setNewTaskboardModal(true)}
            >
              <ListItemIcon>
                <AssignmentRoundedIcon color='secondary' fontSize='medium' />
              </ListItemIcon>
              Taskboard
            </MenuItem>
            <MenuItem
              disabled={!currentAccountPermissions?.includes(Permissions.VIEW_TASKBOARDS)}
              onClick={() => setNewDocumentModalOpen(true)}
            >
              <ListItemIcon>
                <ArticleRoundedIcon color='info' fontSize='medium' />
              </ListItemIcon>
              Doc
            </MenuItem>
            <MenuItem
              disabled={!currentAccountPermissions?.includes(Permissions.CREATE_EDIT_REMOVE_MARKETING_LINKS)}
              onClick={() => setNewLinkModalOpen(true)}
            >
              <ListItemIcon>
                <PhonelinkRoundedIcon color='warning' fontSize='medium' />
              </ListItemIcon>
              Smart Link
            </MenuItem>
          </Menu>

          <Stack alignItems='center' direction='row' height={1} paddingX={2} spacing={1} zIndex='1201'>
            <UpgradeCTAButtons />

            <IconNavLinkTop
              id='profile'
              isActive={false}
              onClick={props.toggleDrawer(DrawerState.Profile)}
              sx={{
                maxWidth: 300,
              }}
            >
              <Badge
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                badgeContent={<CheckCircleRoundedIcon
                  color='secondary'
                  fontSize='tiny'
                  sx={{
                    backgroundColor: 'white',
                    borderRadius: '50%',
                  }}
                />}
                overlap='circular'
                sx={{
                  '.MuiBadge-badge': {
                    display: currentOrganisation?.verified ? undefined : 'none',
                  },
                }}
              >
                <Avatar
                  src={currentOrganisation?.logoUrl}
                  sx={{ height: 32, width: 32 }}
                />
              </Badge>
              <Typography
                noWrap
                sx={{
                  textTransform: 'none',
                  marginRight: 1,
                  marginLeft: 1,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  width: 1,
                  fontSize: '1rem',
                }}
                variant='h4'
              >
                {currentOrganisation?.name.toString()}
              </Typography>
            </IconNavLinkTop>
            <IconNavLinkTop
              href='https://support.releese.io'
              isActive={false}
              size='small'
              target='_blank'
              tooltip='Support'
            >
              <HelpRoundedIcon />
            </IconNavLinkTop>
            <IconNavLinkTop
              disabled={!currentAccountPermissions?.includes(Permissions.ADD_REMOVE_USERS)}
              isActive={false}
              onClick={() => setInviteModal(true)}
              size='small'
              tooltip='Invite members'
            >
              <PersonAddAlt1RoundedIcon />
            </IconNavLinkTop>
            <IconNavLinkTop
              isActive={profileActive}
              onClick={props.toggleDrawer(DrawerState.Profile)}
              size='small'
              tooltip='Settings'
            >
              <SettingsRoundedIcon />
            </IconNavLinkTop>
            <Badge
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              badgeContent={unreadConversations}
              color='error'
              overlap='circular'
              sx={{
                '.MuiBadge-dot': {
                  display: unreadConversations > 0 ? undefined : 'none',
                  width: 16,
                  height: 16,
                  border: 2,
                  borderColor: 'background.paper',
                  borderRadius: '50%',
                },
              }}
            >
              <IconNavLinkTop
                isActive={messagesActive}
                onClick={props.toggleDrawer(DrawerState.Messages)}
                size='small'
                tooltip='Messages'
              >
                <ChatIcon />
              </IconNavLinkTop>
            </Badge>
            <Badge
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              badgeContent={unreadNotifications}
              color='error'
              overlap='circular'
              sx={{
                '.MuiBadge-dot': {
                  display: unreadNotifications > 0 ? undefined : 'none',
                  width: 16,
                  height: 16,
                  border: 2,
                  borderColor: 'background.paper',
                  borderRadius: '50%',
                },
              }}
            >
              <IconNavLinkTop
                isActive={notificationsActive}
                onClick={props.toggleDrawer(DrawerState.Notifications)}
                size='small'
                tooltip='Notifications'
              >

                <NotificationsIcon />
              </IconNavLinkTop>
            </Badge>
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
    {currentOrganisation?.tutorialCheckmarks && !matches &&
    <CheckmarkTour />}
    <SetupTour />
    {newLinkModal &&
      <NewLinkModal
        close={() => setNewLinkModalOpen(false)}
        open={newLinkModal}
      />}
    {newProjectModalOpen &&
      <NewProjectModal
        close={() => setNewProjectModalOpen(false)}
        open={newProjectModalOpen}
      />}
    {newTaskModal &&
    <NewTaskModal
      close={() => setNewTaskModal(false)}
      open={newTaskModal}
    />}
    {newTaskboardModal &&
    <NewTaskboardModal
      close={() => setNewTaskboardModal(false)}
      open={newTaskboardModal}
    />}
    {billingModal && currentOrganisation &&
    <BillingModal
      account={currentOrganisation}
      close={() => setBillingModalOpen(false)}
      open={billingModal}
    />}
    {newDocumentModalOpen &&
      <NewDocumentModal
        close={() => setNewDocumentModalOpen(false)}
        open={newDocumentModalOpen}
      />}
    <InviteMemberModal
      close={() => setInviteModal(false)}
      open={inviteModal}
    />
  </>
}
export default Header
