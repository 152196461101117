/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable max-lines */
/* eslint-disable unicorn/no-unsafe-regex */
/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable react-hooks/exhaustive-deps */
import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded'
import CreateRoundedIcon from '@mui/icons-material/CreateRounded'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import LinkRoundedIcon from '@mui/icons-material/LinkRounded'
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded'
import PhonelinkRoundedIcon from '@mui/icons-material/PhonelinkRounded'
import RememberMeRoundedIcon from '@mui/icons-material/RememberMeRounded'
import SaveRoundedIcon from '@mui/icons-material/SaveRounded'
import ShareRoundedIcon from '@mui/icons-material/ShareRounded'
import ViewWeekRoundedIcon from '@mui/icons-material/ViewWeekRounded'
import { Alert, Chip, IconButton, ListItemIcon, Menu, MenuItem, Paper, Snackbar, Stack, TableCell, TableRow, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import EditLink from 'src/components/pages/Links/EditLink/EditLink'
import DeleteLinkModal from 'src/components/pages/Links/LinksList/LinksListModal/DeleteLinkModal'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { MarketingLink } from 'src/models/Marketing'
import { Permissions } from 'src/models/Organisation'

type Props = {
  link: MarketingLink
  updateLinks: () => Promise<void>
}

const checkCname = /(?:[\dA-Za-z]{2,61}\.){2}[\dA-Za-z]{2,61}/

const LinksListItem = (props: Props) => {
  const themeColors = useTheme()
  const matches = useMediaQuery(themeColors.breakpoints.down('md'))
  const { currentAccountPermissions } = useAuth()
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const [deleteLinkModal, setDeleteLinkModalOpen] = useState(false)
  const openMenu = Boolean(anchorElement)
  const [linkCopiedToast, setLinkCopiedToastOpen] = useState(false)
  const [editLinkModal, setEditLinkModal] = useState(false)
  const [openTab, setOpenTab] = useState(0)

  const handleCloseToast = (event: Event | React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setLinkCopiedToastOpen(false)
  }

  const search = useLocation().search
  const goto = new URLSearchParams(search).get('marketingEditId')

  useEffect(() => {
    if (goto === props.link.id) {
      setEditLinkModal(true)
    }
  }, [goto])

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorElement(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorElement(null)
  }

  const copyLink = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    event.preventDefault()
    setLinkCopiedToastOpen(true)
    if (checkCname.test(props.link.domain ?? '')) {
      void navigator.clipboard.writeText(`https://${props.link.domain}/${props.link.url}`)
    } else {
      void navigator.clipboard.writeText(`https://${props.link.subdomain}.${props.link.domain}/${props.link.url}`)
    }
  }

  return (
    <>
      {editLinkModal && props.link.id &&
      <EditLink
        close={() => setEditLinkModal(false)}
        link={props.link}
        open={editLinkModal}
        tab={openTab}
        updateLinks={props.updateLinks}
      />}
      <Snackbar
        autoHideDuration={5000}
        message='Link Copied'
        onClose={handleCloseToast}
        open={linkCopiedToast}
      >
        <Alert
          onClose={handleCloseToast}
          severity='success'
          sx={{ width: '100%' }}
          variant='filled'
        >
          Link copied
        </Alert>
      </Snackbar>
      {currentAccountPermissions?.includes(Permissions.CREATE_EDIT_REMOVE_MARKETING_LINKS) &&
      <DeleteLinkModal
        close={() => setDeleteLinkModalOpen(false)}
        link={props.link}
        open={deleteLinkModal}
        updateLinks={props.updateLinks}
      />}
      <Menu
        anchorEl={anchorElement}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClick={handleClose}
        onClose={handleClose}
        open={openMenu}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <MenuItem
          disabled={!currentAccountPermissions?.includes(Permissions.CREATE_EDIT_REMOVE_MARKETING_LINKS)}
          onClick={() => setDeleteLinkModalOpen(true)}
        >
          <ListItemIcon>
            <DeleteRoundedIcon color='error' fontSize='medium' />
          </ListItemIcon>
          Delete Smart Link
        </MenuItem>
      </Menu>
      <TableRow
        hover
        key='Test'
        onClick={() => {
          if (currentAccountPermissions?.includes(Permissions.CREATE_EDIT_REMOVE_MARKETING_LINKS)) {
            setOpenTab(0)
          } else {
            setOpenTab(4)
          }

          setEditLinkModal(true)
        }}
        sx={{
          textDecoration: 'none',
          '&:hover .MuiTableCell-root': {
            cursor: 'pointer',
          },
          '.MuiTableCell-root': { borderBottomColor: 'divider' },
        }}
      >
        <TableCell>
          <Stack alignItems='center' direction='row' paddingY={1} spacing={2}>
            {props.link.artworkUrl
              ? <img
                alt={props.link.title}
                src={props.link.artworkUrl}
                style={{
                  height: 64,
                  width: 64,
                  borderRadius: 8,
                }}
              />
              : <Paper
                elevation={0}
                sx={{
                  height: 64,
                  width: 64,
                  borderRadius: '8px!important',
                  backgroundColor: theme => theme.palette.background.input,
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                }}
              >
                <PhonelinkRoundedIcon
                  sx={{
                    fontSize: 48,
                    color: theme => theme.palette.action.disabled,
                  }}
                />
              </Paper>}
          </Stack>
        </TableCell>
        <TableCell>
          <Stack alignItems='flex-start' direction='column' spacing={1}>
            <Stack alignItems='flex-start' direction='column' spacing={0}>
              {props.link.title}
              {props.link.domain && props.link.subdomain && props.link.url && !checkCname.test(props.link.domain) &&
              <Typography color={themeColors.palette.text.link} variant='body2'>
                {`${props.link.subdomain}.${props.link.domain}/${props.link.url}`}
              </Typography>}
              {checkCname.test(props.link.domain) && props.link.url &&
              <Typography color={themeColors.palette.text.link} variant='body2'>
                {`${props.link.domain}/${props.link.url}`}
              </Typography>}
            </Stack>
          </Stack>
        </TableCell>
        {!matches &&
        <TableCell>
          {props.link.type === 'Smartlink' &&
            <Chip
              color='primary'
              icon={<ViewWeekRoundedIcon
                sx={{
                  rotate: '90deg',
                }}
              />}
              label='Smart link'
              sx={{
                '.MuiSvgIcon-root': {
                  color: 'white',
                  marginLeft: '10px',
                },
              }}
              variant='filled'
            />}
          {props.link.type === 'Presave' &&
            <Chip
              color='success'
              icon={<SaveRoundedIcon />}
              label='Pre-save link'
              sx={{
                '.MuiSvgIcon-root': {
                  color: 'white',
                  marginLeft: '10px',
                },
              }}
              variant='filled'
            />}
          {props.link.type === 'Shortlink' &&
            <Chip
              color='secondary'
              icon={<ShareRoundedIcon />}
              label='Short link'
              sx={{
                '.MuiSvgIcon-root': {
                  color: 'white',
                  marginLeft: '10px',
                },
              }}
              variant='filled'
            />}
          {props.link.type === 'Biolink' &&
          <Chip
            color='info'
            icon={<RememberMeRoundedIcon />}
            label='Bio link'
            sx={{
              '.MuiSvgIcon-root': {
                color: 'white',
                marginLeft: '10px',
              },
            }}
            variant='filled'
          />}
        </TableCell>}
        {!matches &&
        <TableCell>
          <Typography variant='tableCell'>
            {dayjs(props.link.createdAt).format('LL')}
          </Typography>
        </TableCell>}
        <TableCell>
          <Stack alignItems='center' direction='row' spacing={1}>
            <Tooltip title='Edit'>
              <div>
                <IconButton
                  disabled={!currentAccountPermissions?.includes(Permissions.CREATE_EDIT_REMOVE_MARKETING_LINKS)}
                  onClick={event => {
                    event.preventDefault()
                    event.stopPropagation()
                    setOpenTab(0)
                    setEditLinkModal(true)
                  }}
                >
                  <CreateRoundedIcon />
                </IconButton>
              </div>
            </Tooltip>
            {!matches &&
            <Tooltip title='Analytics'>
              <div>
                <IconButton
                  onClick={event => {
                    event.preventDefault()
                    event.stopPropagation()
                    setOpenTab(4)
                    setEditLinkModal(true)
                  }}
                  title='Analytics'
                >
                  <AnalyticsRoundedIcon />
                </IconButton>
              </div>
            </Tooltip>}
            <Tooltip title='Copy link'>
              <div>
                <IconButton
                  disabled={(!props.link.subdomain || !props.link.domain || !props.link.url) &&
                  (!checkCname.test(props.link.domain) && !props.link.url)}
                  onClick={event => copyLink(event)}
                >
                  <LinkRoundedIcon />
                </IconButton>
              </div>
            </Tooltip>
            <Tooltip title='More'>
              <IconButton
                onClick={handleMenuClick}
              >
                <MoreHorizRoundedIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        </TableCell>
      </TableRow>
    </>
  )
}

export default LinksListItem
