import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import { Divider, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import type { DropResult } from 'react-beautiful-dnd'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

import { savePartialProject } from 'src/api/projects'
import AddProjectEmbedModal from 'src/components/pages/Projects/ViewProject/Embed/AddEmbedProject'
import AddReleeseDocumentProject from 'src/components/pages/Projects/ViewProject/Embed/AddReleeseDocumentProject'
import DeleteEmbedModalProject from 'src/components/pages/Projects/ViewProject/Embed/DeleteEmbedProject'
import EditEmbedProject from 'src/components/pages/Projects/ViewProject/Embed/EditEmbedProject'
import { EMBED_TYPES } from 'src/components/pages/Task/TaskViews/TaskViewComponents/AddEmbedTaskboard'
import { GetIconTaskSmall } from 'src/components/pages/Task/TaskViews/TaskViewComponents/GetIconTask'
import type Project from 'src/models/Project'
import type { EmbeddedItem } from 'src/models/Taskboard'

type Props = {
  currentTab: string
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>
  project?: Project
  setProject: React.Dispatch<React.SetStateAction<Project | undefined>>
}

const reorder = (list: EmbeddedItem[], startIndex: number, endIndex: number) => {
  const result = [...list]
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const ViewProjectNavEmbed = (props: Props) => {
  const [anchorElementAddEmbed, setAnchorElementAddEmbed] = useState<HTMLElement | null>(null)
  const openMenuAddEmbed = Boolean(anchorElementAddEmbed)
  const [addEmbedItem, setAddEmbed] = useState('HTML')

  const [addEmbedModal, setAddEmbedModal] = useState(false)
  const [addReleeseEmbedModal, setAddReleeseEmbedModal] = useState(false)
  const [editEmbedModal, setEditEmbedModal] = useState(false)
  const [deleteEmbedModal, setDeleteEmbedModal] = useState(false)

  const [anchorElementEmbed, setAnchorElementEmbed] = useState<HTMLElement | null>(null)
  const openMenuEmbed = Boolean(anchorElementEmbed)
  const [manageEmbedItem, setManageEmbed] = useState('')

  const handleCloseAddEmbed = () => {
    setAnchorElementAddEmbed(null)
  }

  const handleCloseEmbed = () => {
    setAnchorElementEmbed(null)
    setAddEmbed('HTML')
  }

  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      return
    } else if (result.destination.droppableId && props.project?.embeddedItems) {
      props.setProject({
        ...props.project,
        embeddedItems: reorder(props.project.embeddedItems, result.source.index, result.destination.index),
      })
      await savePartialProject({
        ...props.project,
        embeddedItems: reorder(props.project.embeddedItems, result.source.index, result.destination.index),
      })
    }
  }

  return (
    <>
      <List dense id='project-tabs' sx={{ width: 1 }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='embeds' key='embeds'>
            {provided =>
              <Stack {...provided.droppableProps} ref={provided.innerRef} width={1}>
                {props.project?.embeddedItems.map((item, index) =>
                  <Draggable
                    draggableId={item.id}
                    index={index}
                    key={item.id}
                  >
                    {providedDrag =>
                      <ListItem
                        dense
                        disablePadding
                        ref={providedDrag.innerRef}
                        {...providedDrag.draggableProps}
                        {...providedDrag.dragHandleProps}
                        key={item.id}
                        onClick={() => props.setCurrentTab(item.id)}
                      >
                        <ListItemButton
                          selected={props.currentTab === item.id}
                          sx={{
                            borderRadius: 0,
                          }}
                        >
                          <ListItemAvatar sx={{ fontSize: 18 }}>
                            {GetIconTaskSmall(item.embedType)}
                          </ListItemAvatar>
                          <ListItemText
                            primary={<Typography variant='body2'>
                              {item.title}
                            </Typography>}
                            sx={{ wordBreak: 'break-word' }}
                          />
                          <IconButton
                            id={`button-${item.id}`}
                            onClick={event => {
                              event.stopPropagation()
                              event.preventDefault()
                              setManageEmbed(item.id)
                              setAnchorElementEmbed(event.currentTarget)
                            }}
                            size='small'
                            sx={{
                              marginLeft: 1,
                              width: 24,
                              height: 24,
                            }}
                          >
                            <MoreVertRoundedIcon
                              sx={{
                                fontSize: 24,
                              }}
                            />
                          </IconButton>
                        </ListItemButton>
                      </ListItem>}
                  </Draggable>)}
                {provided.placeholder}
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={event => setAnchorElementAddEmbed(event.currentTarget)}
                    sx={{ borderRadius: 0 }}
                  >
                    <ListItemText
                      disableTypography
                      primary={<Typography
                        sx={{ color: theme => theme.palette.text.secondary }}
                        variant='body2'
                      >
                        + Add item
                      </Typography>}
                    />
                  </ListItemButton>
                </ListItem>
              </Stack>}
          </Droppable>
        </DragDropContext>

      </List>

      <Menu
        anchorEl={anchorElementAddEmbed}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClick={handleCloseAddEmbed}
        onClose={handleCloseAddEmbed}
        open={openMenuAddEmbed}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <MenuItem
          onClick={() => {
            setAddEmbed('Releese Doc')
            setAddReleeseEmbedModal(true)
          }}
        >
          <ListItemIcon
            sx={{
              fontSize: '24px',
              minWidth: '24px',
            }}
          >
            <ArticleRoundedIcon color='info' />
          </ListItemIcon>
          Releese Doc
        </MenuItem>
        <Divider sx={{ borderColor: theme => theme.palette.text.label }} />
        {EMBED_TYPES.map(item =>
          <MenuItem
            key={item}
            onClick={() => {
              setAddEmbed(item)
              setAddEmbedModal(true)
            }}
          >
            <ListItemIcon
              sx={{
                fontSize: '24px',
                minWidth: '24px',
              }}
            >
              {GetIconTaskSmall(item)}
            </ListItemIcon>
            {item}
          </MenuItem>)}
      </Menu>

      <Menu
        anchorEl={anchorElementEmbed}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClick={handleCloseEmbed}
        onClose={handleCloseEmbed}
        open={openMenuEmbed}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <MenuItem onClick={() => setEditEmbedModal(true)}>
          <ListItemIcon>
            <EditRoundedIcon fontSize='medium' />
          </ListItemIcon>
          Edit item
        </MenuItem>
        <MenuItem onClick={() => setDeleteEmbedModal(true)}>
          <ListItemIcon>
            <DeleteRoundedIcon color='error' fontSize='medium' />
          </ListItemIcon>
          Delete item
        </MenuItem>
      </Menu>

      {props.project &&
      <>
        {addEmbedModal &&
        <AddProjectEmbedModal
          close={() => setAddEmbedModal(false)}
          open={addEmbedModal}
          project={props.project}
          setProject={props.setProject}
          type={addEmbedItem}
        />}

        {addReleeseEmbedModal &&
        <AddReleeseDocumentProject
          close={() => setAddReleeseEmbedModal(false)}
          open={addReleeseEmbedModal}
          project={props.project}
          setProject={props.setProject}
          type={addEmbedItem}
        />}

        {editEmbedModal &&
        <EditEmbedProject
          close={() => setEditEmbedModal(false)}
          currentTab={props.currentTab}
          embedId={manageEmbedItem}
          open={editEmbedModal}
          project={props.project}
          setCurrentTab={props.setCurrentTab}
          setProject={props.setProject}
        />}

        {deleteEmbedModal &&
        <DeleteEmbedModalProject
          close={() => setDeleteEmbedModal(false)}
          currentTab={props.currentTab}
          embeddedId={manageEmbedItem}
          open={deleteEmbedModal}
          project={props.project}
          setCurrentTab={props.setCurrentTab}
          setProject={props.setProject}
        />}
      </>}
    </>
  )
}

export default ViewProjectNavEmbed
