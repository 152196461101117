/* eslint-disable max-lines */
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded'
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded'
import AutoGraphRoundedIcon from '@mui/icons-material/AutoGraphRounded'
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import InstagramIcon from '@mui/icons-material/Instagram'
import PhoneIphoneRoundedIcon from '@mui/icons-material/PhoneIphoneRounded'
import SaveRoundedIcon from '@mui/icons-material/SaveRounded'
import { Button, Card, CardActionArea, CardContent, CardMedia, Grid, Paper, Stack, Typography, useTheme } from '@mui/material'
import Confetti from 'react-confetti'
import { useNavigate } from 'react-router'

import type Project from 'src/models/Project'

type Props = {
  readonly close: () => void
  readonly project?: Project
}

const CompleteDistribute = (props: Props) => {
  const themeItem = useTheme()
  const navigate = useNavigate()
  const height = window.innerHeight
  const width = window.innerWidth

  const presave = () => {
    props.close()
    navigate(`/project/${props.project?.id}?goto=marketing`)
  }

  const biolink = () => {
    props.close()
    navigate('/?open=bio')
  }

  const asset = () => {
    props.close()
    navigate(`/project/${props.project?.id}?goto=assets`)
  }

  const pitch = () => {
    props.close()
    navigate(`/project/${props.project?.id}?goto=pitch`)
  }

  const analytics = () => {
    props.close()
    navigate('/audience/overview')
  }

  const tasks = () => {
    props.close()
    navigate(`/project/${props.project?.id}?goto=task`)
  }

  const wallet = () => {
    props.close()
    navigate('/wallet')
  }

  return (
    <>
      <Confetti
        height={height}
        numberOfPieces={280}
        recycle={false}
        width={width}
      />
      <Stack
        alignItems='center'
        direction='column'
        height={1}
        justifyContent='center'
        minHeight={300}
        spacing={4}
        width={1}
      >
        <Stack
          alignItems='center'
          height={1}
          justifyContent='center'
          width='100%'
        >
          <Paper
            elevation={0}
            sx={{
              backgroundColor: theme => theme.palette.success.main,
              borderRadius: 16,
            }}
          >
            <Stack
              alignItems='center'
              justifyContent='center'
              padding={2}
            >
              <CheckCircleOutlineRoundedIcon
                sx={{
                  color: theme => theme.palette.primary.contrastText,
                  fontSize: '64px',
                }}
              />
            </Stack>
          </Paper>
          <Typography
            color={themeItem.palette.text.primary}
            lineHeight={1.3}
            sx={{
              marginTop: 1,
              textAlign: 'center',
            }}
            variant='h3'
          >
            {`You have submitted "${props.project?.title}" for moderation`}
          </Typography>
          <Typography
            color={themeItem.palette.text.label}
            sx={{
              textAlign: 'center',
            }}
            variant='body1'
          >
            Congrats on your upcoming release, here are some tools to help make your release a successful one
          </Typography>
        </Stack>
        <Stack width={1}>
          <Grid alignItems='center' container justifyContent='center' spacing={2}>
            <Grid item lg={3} md={3} sm={6} xl={3} xs={12}>
              <Card sx={{ height: 1 }}>
                <CardActionArea onClick={() => presave()}>
                  <CardMedia
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      background: theme => theme.palette.success.main,
                      paddingY: 1,
                    }}
                  >
                    <SaveRoundedIcon
                      sx={{
                        fontSize: 128,
                        color: theme => theme.palette.primary.contrastText,
                      }}
                    />
                  </CardMedia>
                  <CardContent>
                    <Stack spacing={1} width={1}>
                      <Typography lineHeight={1.2} variant='h4'>
                        Share your Pre-save Link
                      </Typography>
                      <Typography color='textSecondary' variant='body2'>
                        Get your music added in your fan&apos;s libraries on release day
                      </Typography>
                    </Stack>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>

            <Grid item lg={3} md={3} sm={6} xl={3} xs={12}>
              <Card>
                <CardActionArea onClick={() => biolink()}>
                  <CardMedia
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      background: theme => theme.palette.secondary.main,
                      paddingY: 1,
                    }}
                  >
                    <PhoneIphoneRoundedIcon
                      sx={{
                        fontSize: 128,
                        color: theme => theme.palette.primary.contrastText,
                      }}
                    />
                  </CardMedia>
                  <CardContent>
                    <Stack spacing={1} width={1}>
                      <Typography lineHeight={1.2} variant='h4'>
                        Update your Bio-link
                      </Typography>
                      <Typography color='textSecondary' variant='body2'>
                        Your release will automatically be added to your Bio-link if you have set it up
                      </Typography>
                    </Stack>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>

            <Grid item lg={3} md={3} sm={6} xl={3} xs={12}>
              <Card>
                <CardActionArea onClick={() => asset()}>
                  <CardMedia
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      background: theme => theme.palette.instagram,
                      paddingY: 1,
                    }}
                  >
                    <InstagramIcon
                      sx={{
                        fontSize: 128,
                        color: theme => theme.palette.primary.contrastText,
                      }}
                    />
                  </CardMedia>
                  <CardContent>
                    <Stack spacing={1} width={1}>
                      <Typography lineHeight={1.2} variant='h4'>
                        Share an Instagram Story
                      </Typography>
                      <Typography color='textSecondary' variant='body2'>
                        Use Releese&apos;s Asset Generator to quickly create a post or story
                      </Typography>
                    </Stack>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>

            <Grid item lg={3} md={3} sm={6} xl={3} xs={12}>
              <Card>
                <CardActionArea onClick={() => pitch()}>
                  <CardMedia
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      background: theme => theme.palette.info.main,
                      paddingY: 1,
                    }}
                  >
                    <CampaignRoundedIcon
                      sx={{
                        fontSize: 128,
                        color: theme => theme.palette.primary.contrastText,
                      }}
                    />
                  </CardMedia>
                  <CardContent>
                    <Stack spacing={1} width={1}>
                      <Typography lineHeight={1.2} variant='h4'>
                        Pitch your Release
                      </Typography>
                      <Typography color='textSecondary' variant='body2'>
                        Submit your music to editorial playlist editors worldwide
                      </Typography>
                    </Stack>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>

            <Grid item lg={3} md={3} sm={6} xl={3} xs={12}>
              <Card>
                <CardActionArea onClick={() => tasks()}>
                  <CardMedia
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      background: theme => theme.palette.warning.main,
                      paddingY: 1,
                    }}
                  >
                    <AutoAwesomeRoundedIcon
                      sx={{
                        fontSize: 128,
                        color: theme => theme.palette.primary.contrastText,
                      }}
                    />
                  </CardMedia>
                  <CardContent>
                    <Stack spacing={1} width={1}>
                      <Typography lineHeight={1.2} variant='h4'>
                        Create Marketing Plan
                      </Typography>
                      <Typography color='textSecondary' variant='body2'>
                        Leverate Releese ArtistIntelligence to create a marketing plan
                      </Typography>
                    </Stack>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>

            <Grid item lg={3} md={3} sm={6} xl={3} xs={12}>
              <Card>
                <CardActionArea onClick={() => analytics()}>
                  <CardMedia
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      background: theme => theme.palette.deezer,
                      paddingY: 1,
                    }}
                  >
                    <AutoGraphRoundedIcon
                      sx={{
                        fontSize: 128,
                        color: theme => theme.palette.primary.contrastText,
                      }}
                    />
                  </CardMedia>
                  <CardContent>
                    <Stack spacing={1} width={1}>
                      <Typography lineHeight={1.2} variant='h4'>
                        Track your Analytics
                      </Typography>
                      <Typography color='textSecondary' variant='body2'>
                        Once live, you can track your release&apos;s daily analytics from the
                        Audience tab
                      </Typography>
                    </Stack>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>

            <Grid item lg={3} md={3} sm={6} xl={3} xs={12}>
              <Card>
                <CardActionArea onClick={() => wallet()}>
                  <CardMedia
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      background: theme => theme.palette.success.main,
                      paddingY: 1,
                    }}
                  >
                    <AccountBalanceWalletRoundedIcon
                      sx={{
                        fontSize: 128,
                        color: theme => theme.palette.primary.contrastText,
                      }}
                    />
                  </CardMedia>
                  <CardContent>
                    <Stack spacing={1} width={1}>
                      <Typography lineHeight={1.2} variant='h4'>
                        Add Payout Method
                      </Typography>
                      <Typography color='textSecondary' variant='body2'>
                        Create your payout method now if you have not previously set it
                      </Typography>
                    </Stack>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </Stack>
        <Button endIcon={<CheckRoundedIcon />} onClick={() => props.close()}>
          Close and finish
        </Button>
      </Stack>
    </>
  )
}

export default CompleteDistribute
