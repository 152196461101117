import type { RouteProps } from 'react-router-dom'
import { Navigate } from 'react-router-dom'

import type { EarlyAuth } from './AuthProvider'
import { useAuth } from './AuthProvider'

const updateRedirectTo = (params: URLSearchParams) => {
  const pathName = window.location.pathname
  if (params.get('redirectTo')) {
    params.delete('redirectTo')
  } else if (pathName.length > 1) {
    params.append('redirectTo', pathName)
  }

  return params.toString()
}

type Props =
  RouteProps & {
    guards?: (() => boolean)[]
    redirect?: string
    elementItem: JSX.Element
  }
const PrivateRoute = (props: Props) => {
  // Note: This is one of the few rare places where the account could be null
  const { currentAccount, currentFirebaseUser } = useAuth() as EarlyAuth
  const search = new URLSearchParams(window.location.search)

  if (!(currentFirebaseUser || process.env.REACT_APP_BYPASS_LOGIN === 'true'))
    return <Navigate to={{
      pathname: '/account/login',
      search: updateRedirectTo(search),
    }}
    />
  if (currentFirebaseUser &&
      !currentFirebaseUser.emailVerified &&
      window.location.pathname !== '/verify')
    return <Navigate to={{
      pathname: '/verify',
      search: updateRedirectTo(search),
    }}
    />
  if (currentFirebaseUser &&
    currentAccount === null &&
    window.location.pathname !== '/InitialSetup/PersonalInformation'
  )
    return <Navigate to={{
      pathname: '/InitialSetup/PersonalInformation',
      search: updateRedirectTo(search),
    }}
    />

  return props.guards?.some(guard => !guard())
    ? <Navigate to={{ pathname: props.redirect ?? '', search: search.toString() }} />
    : props.elementItem
}

export default PrivateRoute
