
import type { Components } from '@mui/material'

import SPACING from 'src/styles/spacing'

const MuiList: Components['MuiList'] = {
  styleOverrides: {
    root: {
      '& .MuiListSubheader-root': {
        fontSize: '0.875rem',
        backgroundColor: 'transparent',
      },
      '& .MuiListItemButton-root': {
        borderRadius: SPACING.themeSpacing(2),
        fontSize: '0.875rem',
      },
      '& .MuiListItemText-root': {
        paddingTop: SPACING.themeSpacing(0),
        paddingBottom: SPACING.themeSpacing(0),
      },
      '& .MuiListItemText-primary': {
        underline: 'none',
      },
      '& .MuiListItemIcon-root': {
        minWidth: 0,
      },
      '& .MuiMenuItem-root': {
        fontSize: '0.875rem',
      },
    },
    dense: {
      '& .MuiListItemButton-dense': {
        borderRadius: '0!important',
        paddingLeft: SPACING.themeSpacing(3),
        paddingRight: SPACING.themeSpacing(3),
      },
      '& .MuiListItemText-root': {
        paddingTop: SPACING.themeSpacing(0),
        paddingBottom: SPACING.themeSpacing(0),
      },
      '& .MuiListItemAvatar-root': {
        minWidth: SPACING.themeSpacing(3.75),
      },
    },
  },
}

export default MuiList
