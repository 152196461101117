import { Card, CardActionArea, CardMedia, Checkbox, FormControlLabel, FormGroup, Grid, Stack, Tooltip, Typography, useTheme } from '@mui/material'

type Props = {
  readonly selected: boolean
  readonly title: string
  readonly subtitle: string
  readonly updateSelection: () => void
  readonly mediaSource: string
  readonly disabled?: boolean
  readonly tooltipDisabled?: string
}

const AddOnItem = (props: Props) => {
  const themeItem = useTheme()
  return (
    <Grid item lg={3} md={4} sm={6} xl={3} xs={12}>
      <Tooltip placement='top' title={props.tooltipDisabled}>
        <Card
          sx={{
            position: 'relative',
            borderColor: theme => props.selected
              ? theme.palette.primary.main
              : undefined,
            filter: props.disabled
              ? 'grayscale(100%)'
              : 'none',
            '&:before': {
              content: '""',
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              backgroundImage: props.disabled
                ? 'linear-gradient(to bottom right,#3c3c3c,#3c3c3c)'
                : 'none',
              opacity: '.6',
              zIndex: 999,
              pointerEvents: 'none',
            },
          }}
          variant='outlined'
        >
          <CardActionArea disabled={props.disabled} onClick={props.updateSelection}>
            <CardMedia
              component='img'
              image={props.mediaSource}
              src={props.mediaSource}
              sx={{
                aspectRatio: '16/9',
                width: '100%',
              }}
            />
            <Stack padding={2} spacing={1}>
              <Typography lineHeight={1.1} variant='h4'>
                {props.title}
              </Typography>
              <Typography color={themeItem.palette.text.label} variant='body2'>
                {props.subtitle}
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox
                    checked={props.selected}
                    size='medium'
                  />}
                  label=''
                />
              </FormGroup>
            </Stack>
          </CardActionArea>
        </Card>
      </Tooltip>
    </Grid>
  )
}

export default AddOnItem
