import axios from 'axios'

import type { NotesCommentDto, NotesDto } from 'src/models/Notes'
import { Notes, NotesComment } from 'src/models/Notes'
import type { NewTaskboardGroup, TaskBoardDto, TaskBoardEditDto, TaskboardGroupDto, TaskBoardTemplateDto, TaskItem, TaskUpdate, WorkspaceDto } from 'src/models/Taskboard'
import { EmbedItemWorkspace } from 'src/models/Taskboard'
import { TaskBoard, TaskBoardEdit,  TaskboardGroup, TaskBoardTemplate, Workspace } from 'src/models/Taskboard'
import { toFormData } from 'src/utils/objectUtils'

const PATH = '/tasks' as const

export const getTaskboardGroups = (workspaceId?: string) =>
  axios.get<TaskboardGroupDto[]>(`${PATH}/groups/${workspaceId ? workspaceId : 'home'}`)
    .then(response => response.data.map(dto => new TaskboardGroup(dto)))

export const getTaskboardsByProjectId = (project_Id: string) =>
  axios.get<TaskBoardDto>(`${PATH}/project/${project_Id}`)
    .then(response => new TaskBoard(response.data))

export const getTaskboard = (id: string) =>
  axios.get<TaskBoardDto>(`${PATH}/${id}`)
    .then(response => new TaskBoard(response.data))

export const getAllTaskboards = () =>
  axios.get<TaskBoardDto[]>(`${PATH}/taskboards/`)
    .then(response => response.data.map(dto => new TaskBoard(dto)))

export const getArchivedBoards = () =>
  axios.get<TaskBoardDto[]>(`${PATH}/archived`)
    .then(response => response.data.map(dto => new TaskBoard(dto)))

export const getArchivedNotes = () =>
  axios.get<NotesDto[]>(`${PATH}/archivedDocs`)
    .then(response => response.data.map(dto => new Notes(dto)))

export const permanentlyWipeBoard = (id: string) =>
  axios.delete<string>(`${PATH}/permanent/${id}`)
    .then(response => response.data)

export const restoreTaskboard = (id: string) =>
  axios.patch<string>(`${PATH}/restore/${id}`)
    .then(response => response.data)

export const restoreNotes = (id: string) =>
  axios.patch<string>(`${PATH}/restoreDoc/${id}`)
    .then(response => response.data)

export const savePartialTaskboard = ({ id, ...taskboard }: TaskBoard & { id: string }) =>
  axios.patch<TaskBoardDto>(`${PATH}/${id}`, taskboard)
    .then(response => new TaskBoard(response.data))

export const updateTaskItem = (taskboardId: string, taskgroupId: string, taskItem: TaskItem) =>
  axios.patch<TaskBoardDto>(`${PATH}/taskItem/${taskboardId}/${taskgroupId}/${taskItem.id}`, taskItem)
    .then(response => new TaskBoard(response.data))

export const sendTaskUpdate = (taskboardId: string, taskgroupId: string, taskItem: TaskItem, taskUpdate: TaskUpdate) =>
  axios.patch<TaskBoardDto>(`${PATH}/taskMessage/${taskboardId}/${taskgroupId}/${taskItem.id}`, taskUpdate)
    .then(response => new TaskBoard(response.data))

export const createTaskboard = (taskboard: TaskBoardTemplate) =>
  axios.post<string>(`${PATH}`, taskboard)
    .then(result => result.data)

export const createTaskboardGroup = (group: NewTaskboardGroup) =>
  axios.post<TaskboardGroupDto>(`${PATH}/group`, group)
    .then(result => new TaskboardGroup(result.data))

export const updateTaskboardGroup = ({ id, ...group }: TaskboardGroup & { id: string }) =>
  axios.patch<string>(`${PATH}/group/${id}`, group)
    .then(result => result.data)

export const deleteTaskboardGroup = (id: string) =>
  axios.delete<string>(`${PATH}/deleteGroup/${id}`)
    .then(result => result.data)

export const deleteTaskboard = (id: string) =>
  axios.delete<string>(`${PATH}/delete/${id}`)
    .then(result => result.data)

export const getTaskboardTemplates = () =>
  axios.get<TaskBoardTemplateDto[]>(`${PATH}/templates`)
    .then(response => response.data.map(dto => new TaskBoardTemplate(dto)))

export const newTaskboardTemplate = (group: TaskBoardTemplate) =>
  axios.post<string>(`${PATH}/template`, group)
    .then(response => response.data)

export const updateTaskboardTemplate = (id: string, group: TaskBoardTemplate) =>
  axios.patch<TaskBoardTemplateDto>(`${PATH}/template/${id}`, group)
    .then(response => new TaskBoardTemplate(response.data))

export const removeTaskboardTemplate = (id: string) =>
  axios.delete<string>(`${PATH}/template/${id}`)
    .then(response => response.data)

export const newTaskNotification = (id: string,
  taskItem: {
    description: string
    taskboard: string
    due_date: string
    task_name: string
    taskboard_id: string
    task_id: string
  }) =>
  axios.post<string>(`${PATH}/notification/${id}`, taskItem)
    .then(response => response.data)

export const newTaskUpdateNotification = (id: string,
  taskItem: {
    first_name: string
    taskboard: string
    task_comment: string
    task_name: string
    taskboard_id: string
    task_id: string
  }) =>
  axios.post<string>(`${PATH}/updatenotification/${id}`, taskItem)
    .then(response => response.data)

export const getTasksboardUpdates = (tasksboardId: string) =>
  axios.get<TaskBoardEditDto[]>(`${PATH}/notifications/${tasksboardId}`)
    .then(response => response.data.map(dto => new TaskBoardEdit(dto)))

export const getNotesComments = (notesId: string) =>
  axios.get<NotesCommentDto[]>(`${PATH}/notesComments/${notesId}`)
    .then(response => response.data.map(dto => new NotesComment(dto)))

export const createReleeseDocument = () =>
  axios.post<NotesDto>(`${PATH}/releesedoc`)
    .then(response => new Notes(response.data))

export const createReleeseSheet = () =>
  axios.post<{ id: string, name: string }>(`${PATH}/releesesheet`)
    .then(response => response.data)

export const getReleeseDocument = (documentId: string) =>
  axios.get<Notes>(`${PATH}/note/${documentId}`)
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    .then(response => response.data)

export const createWorkspace = (group: Workspace) =>
  axios.post<WorkspaceDto>(`${PATH}/workspace`, group)
    .then(result => new Workspace(result.data))

export const getWorkspaces = () =>
  axios.get<WorkspaceDto[]>(`${PATH}/workspace`)
    .then(response => response.data.map(dto => new Workspace(dto)))

export const updateWorkspace = (group: Workspace) =>
  axios.patch<WorkspaceDto>(`${PATH}/workspace/${group.id}`, group)
    .then(result => new Workspace(result.data))

export const deleteWorkspace = (group: Workspace) =>
  axios.delete<string>(`${PATH}/workspace/${group.id}`)
    .then(result => result.data)

export const gptTasks = (prompt: string) =>
  axios.post<string[]>(`${PATH}/releesegpt`, { prompt })
    .then(response => response.data.map(dto => String(dto)))

export const gptText = (prompt: string) =>
  axios.post<string[]>(`${PATH}/releesetextgpt`, { prompt })
    .then(response => response.data.map(dto => String(dto)))

export const updateNotesImage = (image: File, id: string) =>
  axios.post<string>(`${PATH}/docsImage/${id}`, toFormData({ image }))
    .then(response => response.data)

export const getNotesbackground = (id: string) =>
  axios.get<string>(`${PATH}/docsImage/${id}`)
    .then(response => response.data)

export const firstTaskboard = () =>
  axios.post<string>(`${PATH}/firstTaskboard`)
    .then(response => response)

export const getEmbeddedItem = (documentId: string) =>
  axios.get<EmbedItemWorkspace>(`${PATH}/embed/${documentId}`)
    .then(response => new EmbedItemWorkspace(response.data))

export const createEmbeddedItem  = (item: Partial<EmbedItemWorkspace>) =>
  axios.post<EmbedItemWorkspace>(`${PATH}/embed`, { ...item })
    .then(response => new EmbedItemWorkspace(response.data))
