import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import ImageRoundedIcon from '@mui/icons-material/ImageRounded'
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticonRounded'
import SendIcon from '@mui/icons-material/SendRounded'
import { Card, CircularProgress, Divider, IconButton, InputAdornment, Menu, Stack, TextField, Typography } from '@mui/material'
import type { BaseEmoji } from 'emoji-mart'
import type { ChangeEvent } from 'react'
import { useEffect, useState } from 'react'

import { sendImage } from 'src/api/conversations'
import EmojiPicker from 'src/components/form-elements/EmojiPicker'
import { FileUploadIconButton } from 'src/components/form-elements/FileUpload'
import { useMessenger } from 'src/components/providers/MessengerProvider'

const MessagesField = () => {
  const { currentConversation, sendMessage } = useMessenger()

  const [loading, setLoading] = useState(false)
  const [key, setKey] = useState('key')
  const [picturePreview, setPicturePreview] = useState<string | null>()
  const [fileAttachment, setFileAttachment] = useState<File>()
  const [messageText, setMessageText] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const openEmojiMenu = Boolean(anchorElement)
  const handleEmojiClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget)
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
  }
  const handleEmojiClose = () => {
    setAnchorElement(null)
  }
  const addEmoji = (event: BaseEmoji) => {
    const symbol = event.native
    setMessageText(`${messageText + symbol} `)
  }

  const handleMessageSend = async () => {
    if (messageText.length > 0) {
      sendMessage(messageText)
      setMessageText('')
    }
    if (fileAttachment) {
      setLoading(true)
      await sendImage(fileAttachment)
        .then(url => sendMessage(url))
        .finally(() => {
          setFileAttachment(undefined)
          setPicturePreview(null)
          setErrorMessage('')
          setKey(Math.random().toString(36))
          setLoading(false)
        })
    }
  }

  const addMessageFile = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) return
    if ((/\.(jpg|png|jpeg|webp)$/i).test(file.name)) {
      setFileAttachment(file)
      setErrorMessage('')
    } else {
      setErrorMessage('Please upload an image')
    }
  }

  useEffect(() => {
    setFileAttachment(undefined)
    setPicturePreview(null)
    setKey(Math.random().toString(36))
  }, [currentConversation?.id])

  useEffect(() => {
    if (fileAttachment) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setPicturePreview(reader.result as string)
      }
      reader.readAsDataURL(fileAttachment)
    }
  }, [fileAttachment])

  return (
    <>
      <Menu
        anchorEl={anchorElement}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={handleEmojiClose}
        open={openEmojiMenu}
        transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <EmojiPicker emojiHandler={addEmoji} />
      </Menu>
      <Stack spacing={0} width={1}>
        <Divider />
        {errorMessage.length > 0 &&
        <Stack
          direction='row'
          paddingTop={2}
          paddingX={2}
          spacing={1}
          width={1}
        >
          <Typography color='error' variant='body2'>
            {errorMessage}
          </Typography>
        </Stack>}
        {picturePreview &&
        <Stack
          direction='row'
          paddingTop={2}
          paddingX={2}
          spacing={1}
          width={1}
        >
          <Card
            elevation={0}
            sx={{
              width: 'fit-content',
              position: 'relative',
            }}
          >
            <IconButton
              onClick={() => {
                setFileAttachment(undefined)
                setPicturePreview(null)
              }}
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
              }}
            >
              <ClearRoundedIcon />
            </IconButton>
            <img
              alt='preview'
              height={96}
              src={picturePreview}
              style={{
                objectFit: 'cover',
                borderRadius: '16px',
              }}
              width={96}
            />
          </Card>

          {loading &&
            <CircularProgress color='primary' />}
        </Stack>}
        <TextField
          InputProps={{
            endAdornment: <InputAdornment position='end' style={{ height: '100%' }}>
              <IconButton onClick={handleEmojiClick} size='small'>
                <InsertEmoticonIcon />
              </IconButton>
              <FileUploadIconButton
                id='upload-image'
                key={key}
                onChange={addMessageFile}
              >
                <ImageRoundedIcon />
              </FileUploadIconButton>
              <Divider flexItem orientation='vertical' />
              <IconButton
                color='primary'
                disabled={!messageText && !fileAttachment}
                onClick={() => handleMessageSend()}
                size='small'
              >
                <SendIcon />
              </IconButton>
            </InputAdornment>,
          }}
          autoComplete='off'
          fullWidth
          id='messageboard'
          onChange={event => setMessageText(event.target.value)}
          onKeyPress={event => event.key === 'Enter' &&  handleMessageSend()}
          placeholder='Aa'
          sx={{ padding: 2, marginTop: 0 }}
          value={messageText}
        />
      </Stack>
    </>
  )
}

export default MessagesField
