import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import RestoreRoundedIcon from '@mui/icons-material/RestoreRounded'
import { Avatar, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Tooltip, useTheme } from '@mui/material'
import { useState } from 'react'

import PermanentlyDeleteBoard from 'src/components/pages/Task/TaskMenu/TaskMenuModals/PermanentlyDeleteBoard'
import RestoreTaskboardModal from 'src/components/pages/Task/TaskMenu/TaskMenuModals/RestoreTaskboardModal'
import { taskboardIcons } from 'src/components/pages/Task/TaskViews/TaskViewComponents/TaskboardIcons'
import type { TaskBoard } from 'src/models/Taskboard'

type Props = {
  refreshBoards: () => void
  taskboard: TaskBoard
  refreshTaskboardArchiveList: () => void
}

const ManageTaskboardItem = (props: Props) => {
  const theme = useTheme()
  const [restoreTaskboard, setRestoreTaskboard] = useState(false)
  const [deleteTaskboard, setDeleteTaskboard] = useState(false)

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const openMoreMenu = Boolean(anchorElement)

  const handleMoreMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget)
  }

  const handleMoreMenuClose = () => {
    setAnchorElement(null)
  }

  return (
    <>
      <Stack spacing={2} width={1}>
        <MenuItem
          disableRipple
          disableTouchRipple
          key={props.taskboard.id}
          selected={false}
          sx={{
            backgroundColor: 'transparent',
            cursor: 'default',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
          value={props.taskboard.id}
        >
          <ListItemIcon>
            <Avatar
              sx={{
                width: 32,
                height: 32,
                borderRadius: 0.5,
                bgcolor: props.taskboard.color,
                color: theme.palette.primary.contrastText,
                marginRight: 1,
              }}
              variant='rounded'
            >
              {taskboardIcons[props.taskboard.icon]}
            </Avatar>
          </ListItemIcon>
          <ListItemText>
            {props.taskboard.title}
          </ListItemText>
          <Tooltip title='More'>
            <IconButton onClick={event => handleMoreMenuOpen(event)}>
              <MoreVertRoundedIcon />
            </IconButton>
          </Tooltip>
        </MenuItem>
        <Divider />
      </Stack>
      <Menu
        anchorEl={anchorElement}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClick={handleMoreMenuClose}
        onClose={handleMoreMenuClose}
        open={openMoreMenu}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <MenuItem onClick={() => setRestoreTaskboard(true)}>
          <ListItemIcon>
            <RestoreRoundedIcon fontSize='medium' />
          </ListItemIcon>
          Restore taskboard
        </MenuItem>
        <MenuItem onClick={() => setDeleteTaskboard(true)}>
          <ListItemIcon>
            <DeleteRoundedIcon color='error' fontSize='medium' />
          </ListItemIcon>
          Delete taskboard
        </MenuItem>
      </Menu>

      <PermanentlyDeleteBoard
        close={() => setDeleteTaskboard(false)}
        open={deleteTaskboard}
        refreshBoards={props.refreshBoards}
        refreshTaskboardArchiveList={props.refreshTaskboardArchiveList}
        taskboard={props.taskboard}
      />

      <RestoreTaskboardModal
        close={() => setRestoreTaskboard(false)}
        open={restoreTaskboard}
        refreshBoards={props.refreshBoards}
        refreshTaskboardArchiveList={props.refreshTaskboardArchiveList}
        taskboard={props.taskboard}
      />
    </>
  )
}

export default ManageTaskboardItem
