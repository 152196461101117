import ShareRoundedIcon from '@mui/icons-material/ShareRounded'
import { Avatar, CardActionArea, Paper, Stack, Typography, useTheme } from '@mui/material'

type Props = {
  setSelected: React.Dispatch<React.SetStateAction<number>>
  selected: number
}

const ShortLinkCard = (props: Props) => {
  const transitionMode = 'all 0.2s ease'
  const themeItem = useTheme()
  return (
    <Paper
      elevation={0}
      sx={{
        background: 'transparent',
        border: theme => props.selected === 2
          ? `1px solid ${theme.palette.secondary.main}`
          : '1px solid transparent',
        '&:hover': {
          cursor: 'pointer',
          transition: transitionMode,
          background: theme => theme.palette.secondary.subtleResting,
        },
      }}
    >
      <CardActionArea
        onClick={() => props.setSelected(2)}
        sx={{ height: 1, borderRadius: 1 }}
      >
        <Stack alignItems='center' direction='column' padding={2} spacing={1} width={1}>
          <Stack alignItems='center' height='100%' justifyContent='center'>
            <Avatar
              sx={{
                background: theme => theme.palette.secondary.main,
                width: 96,
                height: 96,
              }}
            >
              <ShareRoundedIcon
                sx={{
                  margin: 'auto',
                  fontSize: 64,
                  color: theme => theme.palette.primary.contrastText,
                }}
              />
            </Avatar>
          </Stack>
          <Stack alignItems='center' direction='column' spacing={0}>
            <Typography
              variant='h4'
            >
              Short link
            </Typography>
            <Typography
              align='center'
              color={themeItem.palette.text.secondary}
              variant='body2'
            >
              Seamlessly redirect your audience to any link while collecting data
            </Typography>
          </Stack>
        </Stack>
      </CardActionArea>
    </Paper>
  )
}
export default ShortLinkCard
