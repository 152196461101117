import { Modal, Stack } from '@mui/material'

type Props = {
  image: string
  close: () => void
  open: boolean
}

const ImageView = (props: Props) =>
  <Modal
    BackdropProps={{
      timeout: 500,
    }}
    closeAfterTransition
    onClose={props.close}
    open={props.open}
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Stack maxWidth={900}>
      <img
        alt='Message'
        src={props.image}
        style={{ borderRadius: 8, boxShadow: 'none' }}
        width='100%'
      />
    </Stack>
  </Modal>

export default ImageView
