/* eslint-disable max-len */
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded'
import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, IconButton, Paper, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useNavigate } from 'react-router'

type Props = {
  readonly close: () => void
  readonly open: boolean
  readonly loadingWinBack: boolean
  readonly winbackSuccess: boolean
  readonly handleDowngrade: () => void
  readonly extendPlan: () => Promise<void>
}

const ExtendPlan = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))
  const navigate = useNavigate()

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='sm'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Stack paddingX={3} paddingY={3} spacing={4} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          width='100%'
        >
          <Typography variant='h3'>
            Exclusive offer
          </Typography>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>
        {props.winbackSuccess
          ? <Stack alignItems='center' justifyContent='center' spacing={2} width={1}>
            <Stack width='fit-content'>
              <Paper
                elevation={0}
                sx={{
                  backgroundColor: '#FFA500',
                  borderRadius: 16,
                }}
              >
                <Stack
                  alignItems='center'
                  justifyContent='center'
                  padding={2}
                >
                  <AutoAwesomeRoundedIcon
                    sx={{
                      color: theme => theme.palette.primary.contrastText,
                      fontSize: '96px',
                    }}
                  />
                </Stack>
              </Paper>
            </Stack>
            <Typography lineHeight={1.2} textAlign='center' variant='h3'>
              Congratulations, you have unlocked 2 free months of Releese!
            </Typography>
            <Button
              onClick={() => {
                props.close()
                navigate('/?donesub')
              }}
              sx={{
                color: 'white!important',
                background: '#FFA500',
                ':hover': {
                  background: '#c47f00',
                },
              }}
            >
              Go back to my dashboard
            </Button>
          </Stack>
          : <>
            <Stack alignItems='center' justifyContent='center' width={1}>
              <Stack width='fit-content'>
                <Paper
                  elevation={0}
                  sx={{
                    backgroundColor: '#FFA500',
                    borderRadius: 16,
                  }}
                >
                  <Stack
                    alignItems='center'
                    justifyContent='center'
                    padding={2}
                  >
                    <AutoAwesomeRoundedIcon
                      sx={{
                        color: theme => theme.palette.primary.contrastText,
                        fontSize: '96px',
                      }}
                    />
                  </Stack>
                </Paper>
              </Stack>
              <Typography textAlign='center' variant='h3'>
                Extend your plan by 60 days for free
              </Typography>
              <Typography textAlign='center' variant='body1'>
                You haven&apos;t earned enough through Releese to justify the cost, we get it.
                How about we extend your plan by 60 days for free? We know you will love the platform like our thousands of
                members do.
              </Typography>
            </Stack>
            <Stack direction='row' justifyContent='flex-end' spacing={1} width='100%'>
              <Button
                color='error'
                onClick={() => props.handleDowngrade()}
                variant='text'
              >
                Cancel subscription
              </Button>
              <LoadingButton
                color='success'
                loading={props.loadingWinBack}
                onClick={() => void props.extendPlan()}
                sx={{
                  color: 'white!important',
                  background: '#FFA500',
                  ':hover': {
                    background: '#c47f00',
                  },
                }}
                variant='contained'
              >
                Extend plan by 60 days
              </LoadingButton>
            </Stack>
          </>}
      </Stack>
    </Dialog>
  )
}

export default ExtendPlan
