import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, IconButton, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'

import { updateFolder } from 'src/api/folder'
import { Folder } from 'src/models/Folder'

type Props = {
  close: () => void
  open: boolean
  setFolder: React.Dispatch<React.SetStateAction<Folder>>
  folder: Folder
}

const RenameFolderModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  const [title, setTitle] = useState(props.folder.title)
  const [loading, setLoading] = useState(false)

  const newFolderFunction = async () => {
    if (props.folder.id) {
      setLoading(true)

      await updateFolder(new Folder({
        title,
      }), props.folder.id)
        .then(newFolder => {
          props.setFolder(newFolder)
        })
        .then(() => props.close())
        .finally(() => setLoading(false))
    }
  }

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='xs'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <form
        onSubmit={event => {
          event.preventDefault()
          void newFolderFunction()
        }}
      >
        <Stack height={1} paddingX={3} paddingY={3} spacing={2} width={1}>
          <Stack
            alignItems='center'
            direction='row'
            justifyContent='space-between'
            width={1}
          >
            <Typography variant='h3'>
              Rename Folder
            </Typography>
            <IconButton onClick={() => props.close()}>
              <CloseIcon color='disabled' />
            </IconButton>
          </Stack>

          <Stack>
            <TextField
              label='Title'
              onChange={event => setTitle(event.target.value)}
              placeholder='Folder Name'
              value={title}
            />
          </Stack>

          <Stack direction='row' justifyContent='flex-end' spacing={1} width={1}>
            <Button
              onClick={props.close}
              variant='text'
            >
              Cancel
            </Button>
            <LoadingButton
              disabled={title.length === 0}
              loading={loading}
              type='submit'
              variant='contained'
            >
              Update
            </LoadingButton>
          </Stack>
        </Stack>
      </form>
    </Dialog>
  )
}
export default RenameFolderModal
