import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import { Button, Paper, Stack, Typography, useTheme } from '@mui/material'

import ViewRecordingsListItem from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/RecordingsTab/ViewRecordingItem'
import { NavLink } from 'src/components/SafeRouterLink'
import type Project from 'src/models/Project'
import type Recording from 'src/models/Recording'

type Props = {
  recordings: Recording[]
  close: () => void
  projectId: string
  updateRecordingsSubtle: () => Promise<void>
  updateRecordings: () => Promise<void>
  project: Project
}

const RecordingsDistribute = (props: Props) => {
  const themeItem = useTheme()
  return (
    <Stack spacing={4} width={1}>
      <Stack spacing={1} width={1}>
        <Typography variant='h3'>
          Recordings
        </Typography>
      </Stack>
      {props.recordings.length > 0
        ? <Stack alignItems='center' direction='column' spacing={2} width={1}>
          {props.recordings.map(recording =>
            <ViewRecordingsListItem
              distribution
              key={recording.id}
              project={props.project}
              recording={recording}
              updateRecordings={props.updateRecordings}
              updateRecordingsSubtle={props.updateRecordingsSubtle}
            />)}
        </Stack>
        : <Stack
          alignItems='center'
          height={1}
          justifyContent='center'
          marginTop='128px!important'
          width='100%'
        >
          <Paper
            elevation={0}
            sx={{
              backgroundColor: theme => theme.palette.primary.main,
              borderRadius: 16,
            }}
          >
            <Stack
              alignItems='center'
              justifyContent='center'
              padding={4}
            >
              <MusicNoteRoundedIcon
                sx={{
                  color: theme => theme.palette.primary.contrastText,
                  fontSize: '144px',
                }}
              />
            </Stack>
          </Paper>
          <Typography
            color={themeItem.palette.text.secondary}
            sx={{
              marginTop: 1,
            }}
            variant='h4'
          >
            No Recordings Found
          </Typography>
          <Typography
            color={themeItem.palette.text.label}
            sx={{
              textAlign: 'center',
            }}
            variant='body1'
          >
            Upload recordings in your Project to distribute them
          </Typography>
          <Button
            color='primary'
            component={NavLink}
            onClick={() => props.close()}
            sx={{
              marginTop: 1,
            }}
            to={`/project/${props.projectId}?goto=recordings`}
          >
            Return to Project
          </Button>
        </Stack>}
    </Stack>
  )
}

export default RecordingsDistribute
